import { HermesMetadata } from './HermesMetadata';
import { MAKERS_MARK_HERMES_METADATA } from './makersMarkHermesMetadata';
import { NESTLE_HERMES_METADATA } from './nestleHermesMetadata';

export const HERMES_METADATA: Record<string, HermesMetadata> = {
    nestle: NESTLE_HERMES_METADATA,
    makersMark: MAKERS_MARK_HERMES_METADATA,
};

export const BRAND_NAMES: Record<string, string> = {
    nestle: 'Nestlé',
    makersMark: "Maker's Mark",
};
