import { FlyToInterpolator } from '@deck.gl/core';
import {
    EntityResolutionItem,
    HermesCompetitiveProduct,
    HermesMarketingItem,
    HermesMetadata,
    MetadataItem,
} from './HermesMetadata';
import { PRODUCT_DATA } from '../mockData/product';
import { makersMarkProductList } from '../mockData/makersMarkProductList';

const ENTITY_RESOLUTION: EntityResolutionItem[] = [
    { title: "Maker's Mark Original Bourbon Whiskey", sub: 'Stores' },
    { title: "Maker's Mark Bourbon Whiskey", sub: 'Products' },
    { title: "Maker's Mark Straight Bourbon", sub: 'Product availability' },
    { title: "Maker's Mark Bourbon Giftbox", sub: 'Sell-in orders' },
];

const METADATA: MetadataItem[] = [
    { left: 'Category', right: 'Whiskey' },
    { left: 'Subcategory', right: 'Bourbon Whisky' },
    { left: 'Manufacturer', right: 'Beam Suntory' },
    { left: 'Brand Family', right: "Maker's Mark" },
    { left: 'Spirits Price Tier', right: 'Premium' },
];

const COMPETITIVE_PRODUCTS: HermesCompetitiveProduct[] = [
    {
        name: 'Knob Creek Straight Bourbon 12 Year',
        price: '$59.99',
        star: '4.9',
        image: '/images/pipeline/knob-creek.svg',
    },
    {
        name: 'Bulleit Bourbon',
        price: '$49.99',
        star: '4.8',
        image: '/images/pipeline/bulleit.svg',
    },
    {
        name: "Michter's US★1 Kentucky Straight Bourbon",
        price: '$54.99',
        star: '4.8',
        image: '/images/pipeline/michter.svg',
    },
    {
        name: 'Jim Beam Bourbon Whiskey',
        price: '$31.99',
        star: '4.8',
        image: '/images/pipeline/jim.svg',
    },
    {
        name: '1792 Single Barrel Kentucky Straight Bourbon Whiskey',
        price: '$43.99',
        star: '4.8',
        image: '/images/pipeline/1972.svg',
    },
    {
        name: 'Four Roses Bourbon, Kentucky Straight Bourbon Whiskey',
        price: '$29.99',
        star: '4.7',
        image: '/images/pipeline/fourroses.svg',
    },
    {
        name: 'Widow Jane 10 Year Straight Bourbon',
        price: '$69.99',
        star: '4.8',
        image: '/images/pipeline/widow.svg',
    },
];

const MARKETING: HermesMarketingItem[] = [
    {
        title: 'It is what it isn’t',
        subtitle: 'Image',
        image: '/images/pipeline/jimbeamad.svg',
    },
    {
        title: 'Wax-dipping device',
        subtitle: 'Video',
        image: '/images/pipeline/jimbeamwax.svg',
    },
    {
        title: 'Make your Manhattan',
        subtitle: 'Video',
        image: '/images/pipeline/makersman.svg',
    },
    {
        title: 'Must be Ladies’ Night',
        subtitle: 'Image',
        image: '/images/pipeline/makersnight.svg',
    },
];

const VIEWPORT = {
    longitude: -74,
    latitude: 40.75,
    zoom: 9.3,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const MARKS = [
    { value: 0, label: 'New Years' },
    { value: 44, label: 'Valentine’s Day' },
    { value: 90, label: 'Behavior change' },
    { value: 140, label: 'Memorial Day' },
    { value: 149, label: 'Behavior change' },
    { value: 173, label: 'Pride 2023' },
    { value: 239, label: 'Current Time' },
];

export const MARK_TO_VAL = { 0: 1, 44: 2, 90: 3, 140: 4, 149: 5, 173: 6, 239: 7 };

export const MAKERS_MARK_HERMES_METADATA: HermesMetadata = {
    hermes: {
        organization: 'B&L',
    },
    ontology: {
        productList: makersMarkProductList,
        product: {
            productName: "Maker's Mark Original Bourbon Whiskey",
            productSku: '37491d56155cb028ef3dc0c6aac26b6a',
            country: 'United States',
            characteristics: {
                details:
                    "This one changed the way we think of bourbon, all because one man changed the way he thought about making it. Bill Samuels, Sr., simply wanted a whisky he would enjoy drinking. Never bitter or sharp, Maker's Mark® is made with soft red winter wheat, instead of the usual rye, for a one-of-a-kind, full-flavored bourbon.",
                entityResolution: ENTITY_RESOLUTION,
                metadata: METADATA,
            },
            contextual: {
                productData: {
                    type: 'data',
                    data: PRODUCT_DATA.map(item => ({
                        productName: item.product,
                        latitude: item.store_latitude,
                        longitude: item.store_longitude,
                        description: item.description,
                        neighborhood: item.neighborhood,
                    })),
                },
                performance: {
                    totalRevenue: '$1,467,345',
                    avaragePrice: '$34.99',
                    productsOrdered: '2,342',
                },
                competitiveProducts: COMPETITIVE_PRODUCTS,
                marketing: MARKETING,
            },
        },
        viewport: VIEWPORT,
        slider: {
            marks: MARKS,
            markToVal: MARK_TO_VAL,
        },
    },
    chat: {
        suggestions: [
            'How can I resolve the issue with the segment data from Snowflake?',
            'What happened in the connection phase for this dataset?',
            'What are other ways I can improve the dataset?',
        ],
    },
};
