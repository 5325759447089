export const ECStores = [
    {
        store_latitdue: -0.245869,
        store_longitude: -78.441524,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.1999897,
        store_longitude: -78.4322506,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.331335,
        store_longitude: -78.451576,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.250422,
        store_longitude: -78.447151,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.327109,
        store_longitude: -78.4767,
    },
    {
        store_latitdue: -0.347125,
        store_longitude: -78.483596,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.212845,
        store_longitude: -78.426809,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.325777,
        store_longitude: -78.449962,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.204389,
        store_longitude: -78.400204,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.345069,
        store_longitude: -78.483973,
    },
    {
        store_latitdue: -0.2015464,
        store_longitude: -78.4322118,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.339227,
        store_longitude: -78.439162,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.321159,
        store_longitude: -78.438758,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.353699,
        store_longitude: -78.475556,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.2956909,
        store_longitude: -78.4663377,
    },
    {
        store_latitdue: -0.213299,
        store_longitude: -78.405603,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2791406,
        store_longitude: -78.4739565,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.312373,
        store_longitude: -78.480022,
    },

    {
        store_latitdue: -0.278981,
        store_longitude: -78.493362,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.375314,
        store_longitude: -78.374285,
    },
    {
        store_latitdue: -0.310354,
        store_longitude: -78.415185,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.5171242,
        store_longitude: -78.56108,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3375369,
        store_longitude: -78.4402797,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.332027,
        store_longitude: -78.4478606,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.281192,
        store_longitude: -78.47059,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.333127,
        store_longitude: -78.44384,
    },
    {
        store_latitdue: -0.2101909,
        store_longitude: -78.4001244,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.330693,
        store_longitude: -78.454925,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.271538,
        store_longitude: -78.465656,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2597541,
        store_longitude: -78.450575,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.212534,
        store_longitude: -78.407154,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.211779,
        store_longitude: -78.400151,
    },
    {
        store_latitdue: -0.345112,
        store_longitude: -78.483453,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.219529,
        store_longitude: -78.420111,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3756971,
        store_longitude: -78.5052521,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3362591,
        store_longitude: -78.4519079,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2166316,
        store_longitude: -78.4043261,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.340334,
        store_longitude: -78.483643,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.202049,
        store_longitude: -78.399478,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.201959,
        store_longitude: -78.4303,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2051399,
        store_longitude: -78.3658587,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.58677,
        store_longitude: -78.52,
    },
    {
        store_latitdue: -0.177242914,
        store_longitude: -78.3960247,
    },
    {
        store_latitdue: -0.230821,
        store_longitude: -78.4493,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.298013,
        store_longitude: -78.475729,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.328265,
        store_longitude: -78.426508,
    },
    {
        store_latitdue: -0.337722,
        store_longitude: -78.444477,
    },
    {
        store_latitdue: -0.32306,
        store_longitude: -78.46868,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3750325,
        store_longitude: -78.482972,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.379734,
        store_longitude: -78.3736,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3002928,
        store_longitude: -78.4785339,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.306831,
        store_longitude: -78.417989,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.306963,
        store_longitude: -78.417247,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.257925,
        store_longitude: -78.451645,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.34192,
        store_longitude: -78.488273,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2857643,
        store_longitude: -78.4418085,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.358898,
        store_longitude: -78.480691,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.4902973,
        store_longitude: -78.5705561,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.278987,
        store_longitude: -78.4940064,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.490476,
        store_longitude: -78.57059,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.1836472,
        store_longitude: -78.3873098,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.303541,
        store_longitude: -78.419085,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.516283,
        store_longitude: -78.5696,
    },
    {
        store_latitdue: -0.22357,
        store_longitude: -78.427619,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3671147,
        store_longitude: -78.3755558,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.2946913,
        store_longitude: -78.4516198,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.344491,
        store_longitude: -78.482787,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.4097269,
        store_longitude: -78.5468694,
    },
    {
        store_latitdue: -0.5034959,
        store_longitude: -78.5673503,
    },
    {
        store_latitdue: -0.3139537,
        store_longitude: -78.4510102,
    },
    {
        store_latitdue: -0.21374,
        store_longitude: -78.43263,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2156757,
        store_longitude: -78.4170511,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.341509,
        store_longitude: -78.437223,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.343779,
        store_longitude: -78.480942,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3395492,
        store_longitude: -78.4495904,
    },
    {
        store_latitdue: -0.286574,
        store_longitude: -78.440026,
    },
    {
        store_latitdue: -0.365835,
        store_longitude: -78.492384,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.1772771,
        store_longitude: -78.3895813,
    },
    {
        store_latitdue: -0.304887,
        store_longitude: -78.4224754,
    },
    {
        store_latitdue: -0.4681469,
        store_longitude: -78.58543,
    },
    {
        store_latitdue: -0.269935,
        store_longitude: -78.464039,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3075591,
        store_longitude: -78.4157901,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.364089,
        store_longitude: -78.436453,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.185089,
        store_longitude: -78.491,
    },
    {
        store_latitdue: -0.330131,
        store_longitude: -78.446487,
    },
    {
        store_latitdue: -0.4215652,
        store_longitude: -78.5468379,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.411177,
        store_longitude: -78.54728,
    },

    {
        store_latitdue: -0.2673328,
        store_longitude: -78.4707305,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3601799,
        store_longitude: -78.4807936,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.416719,
        store_longitude: -78.554725,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.2985626,
        store_longitude: -78.4228114,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.513357,
        store_longitude: -78.5754,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.321223,
        store_longitude: -78.460372,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.32314,
        store_longitude: -78.459066,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3214,
        store_longitude: -78.4604,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3175674,
        store_longitude: -78.4606857,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.340063,
        store_longitude: -78.449426,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.332213,
        store_longitude: -78.446321,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3276054,
        store_longitude: -78.4456055,
    },
    {
        store_latitdue: -0.328547,
        store_longitude: -78.446611,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.328835,
        store_longitude: -78.446853,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.343675,
        store_longitude: -78.443371,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.332082,
        store_longitude: -78.436,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },
    {
        store_latitdue: -0.342198,
        store_longitude: -78.47984,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.342836,
        store_longitude: -78.478075,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.342883,
        store_longitude: -78.477778,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3498748,
        store_longitude: -78.4728864,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3005881,
        store_longitude: -78.5039914,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.338986,
        store_longitude: -78.569179,
    },
    {
        store_latitdue: -0.3007957,
        store_longitude: -78.4693915,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.29116862,
        store_longitude: -78.46579313,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.297775,
        store_longitude: -78.464721,
    },
    {
        store_latitdue: -0.2699819,
        store_longitude: -78.4635919,
    },
    {
        store_latitdue: -0.303396,
        store_longitude: -78.4143,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.340165,
        store_longitude: -78.386,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.36838,
        store_longitude: -78.3718,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.278096,
        store_longitude: -78.496558,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.275823,
        store_longitude: -78.481948,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2724656,
        store_longitude: -78.4819642,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.27447,
        store_longitude: -78.473164,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2830825,
        store_longitude: -78.4666757,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.271412,
        store_longitude: -78.464944,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.270571,
        store_longitude: -78.465638,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2700471,
        store_longitude: -78.4652928,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2683089,
        store_longitude: -78.4631939,
    },
    {
        store_latitdue: -0.256307,
        store_longitude: -78.449997,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.2959633,
        store_longitude: -78.4382323,
    },
    {
        store_latitdue: -0.19822,
        store_longitude: -78.4880251,
    },
    {
        store_latitdue: -0.1963675,
        store_longitude: -78.436734,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2296639,
        store_longitude: -78.4406923,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.229002,
        store_longitude: -78.4083,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.207418,
        store_longitude: -78.383906,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2100189,
        store_longitude: -78.3867453,
    },

    {
        store_latitdue: -0.201725,
        store_longitude: -78.375448,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3323032,
        store_longitude: -78.4486454,
    },
    {
        store_latitdue: -0.23421,
        store_longitude: -78.4442,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.3754856,
        store_longitude: -78.4762509,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3781298,
        store_longitude: -78.5034289,
    },

    {
        store_latitdue: -0.381302,
        store_longitude: -78.498888,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3603187,
        store_longitude: -78.4965961,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2771801,
        store_longitude: -78.4759155,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.274549,
        store_longitude: -78.481594,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.280307,
        store_longitude: -78.497268,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.196221,
        store_longitude: -78.445707,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.294064,
        store_longitude: -78.472445,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.334801,
        store_longitude: -78.449505,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.13796626,
        store_longitude: -78.4793319,
    },
    {
        store_latitdue: -0.229338636,
        store_longitude: -78.44883465,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.250384,
        store_longitude: -78.448015,
    },
    {
        store_latitdue: -0.320223,
        store_longitude: -78.463322,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.216588,
        store_longitude: -78.404,
    },
    {
        store_latitdue: -0.328914,
        store_longitude: -78.448718,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3660868,
        store_longitude: -78.475504,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2052406,
        store_longitude: -78.4446254,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.190914,
        store_longitude: -78.383138,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.231377,
        store_longitude: -78.449288,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.334166,
        store_longitude: -78.444301,
    },
    {
        store_latitdue: -0.2957676,
        store_longitude: -78.47404,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.5199208,
        store_longitude: -78.5656502,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.519876,
        store_longitude: -78.574184,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.41122,
        store_longitude: -78.549797,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.29505,
        store_longitude: -78.475532,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2260718,
        store_longitude: -78.389488,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -2.305326,
        store_longitude: -78.120766,
        neighborhood: 'MACAS',
    },
    {
        store_latitdue: -0.334142,
        store_longitude: -78.415739,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.333571,
        store_longitude: -78.416118,
    },
    {
        store_latitdue: -0.278658,
        store_longitude: -78.481579,
    },
    {
        store_latitdue: -0.2109659,
        store_longitude: -78.3978066,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3319552,
        store_longitude: -78.4894786,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3330498,
        store_longitude: -78.4439962,
    },
    {
        store_latitdue: -0.3273329,
        store_longitude: -78.4471651,
    },
    {
        store_latitdue: -0.268467,
        store_longitude: -78.485072,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.270265,
        store_longitude: -78.485238,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2111108,
        store_longitude: -78.4264183,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.3386726,
        store_longitude: -78.3512418,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3381756,
        store_longitude: -78.3519809,
    },
    {
        store_latitdue: -0.336471,
        store_longitude: -78.354243,
    },
    {
        store_latitdue: -0.3334515,
        store_longitude: -78.3567722,
    },
    {
        store_latitdue: -0.221168,
        store_longitude: -78.3748,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.508497,
        store_longitude: -78.561417,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.2704706,
        store_longitude: -78.4881437,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3329255,
        store_longitude: -78.3571809,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3376411,
        store_longitude: -78.349415,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.26399,
        store_longitude: -78.482944,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.368616,
        store_longitude: -78.4381,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.338191,
        store_longitude: -78.3519,
    },
    {
        store_latitdue: -0.176146,
        store_longitude: -78.397112,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2709181,
        store_longitude: -78.464648,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.376022,
        store_longitude: -78.496931,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.2919522,
        store_longitude: -78.4013123,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.326256,
        store_longitude: -78.4761,
    },
    {
        store_latitdue: -0.2126038,
        store_longitude: -78.4032895,
    },
    {
        store_latitdue: -0.2409259,
        store_longitude: -78.510933,
    },
    {
        store_latitdue: -0.2529151,
        store_longitude: -78.478636,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.342831,
        store_longitude: -78.483225,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2969001,
        store_longitude: -78.4278593,
    },
    {
        store_latitdue: -0.348964,
        store_longitude: -78.429894,
    },
    {
        store_latitdue: -0.3653727,
        store_longitude: -78.4227363,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3481924,
        store_longitude: -78.4352109,
    },
    {
        store_latitdue: -0.355893,
        store_longitude: -78.437358,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3486814,
        store_longitude: -78.4293487,
    },
    {
        store_latitdue: -0.370788,
        store_longitude: -78.4217,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.347549,
        store_longitude: -78.4332404,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.348765,
        store_longitude: -78.4354,
    },
    {
        store_latitdue: -0.369945,
        store_longitude: -78.419917,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2974539,
        store_longitude: -78.3947337,
    },
    {
        store_latitdue: -0.2973168,
        store_longitude: -78.39482,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.311683,
        store_longitude: -78.400079,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.295149,
        store_longitude: -78.405248,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.324733,
        store_longitude: -78.3967356,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.308503,
        store_longitude: -78.415127,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3555066,
        store_longitude: -78.4604176,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.308367,
        store_longitude: -78.398953,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.293752,
        store_longitude: -78.39737,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.29344,
        store_longitude: -78.398987,
    },
    {
        store_latitdue: -0.248839,
        store_longitude: -78.47956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.309082,
        store_longitude: -78.412868,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.29587078,
        store_longitude: -78.4001083,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2935774,
        store_longitude: -78.3984866,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.295149,
        store_longitude: -78.405248,
    },
    {
        store_latitdue: -0.2469296,
        store_longitude: -78.4830275,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288793,
        store_longitude: -78.3997,
    },
    {
        store_latitdue: -0.293382,
        store_longitude: -78.40494,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.367198,
        store_longitude: -78.437887,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.325434,
        store_longitude: -78.420232,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.336184,
        store_longitude: -78.451132,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.363851,
        store_longitude: -78.436118,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.329132,
        store_longitude: -78.429819,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3494634,
        store_longitude: -78.4434855,
    },
    {
        store_latitdue: -0.3656346,
        store_longitude: -78.4379803,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.367489,
        store_longitude: -78.434043,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.5003183,
        store_longitude: -78.5680224,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.331226,
        store_longitude: -78.438091,
    },
    {
        store_latitdue: -0.331321,
        store_longitude: -78.422583,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.32161,
        store_longitude: -78.459192,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.32497,
        store_longitude: -78.461974,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.319147,
        store_longitude: -78.4627,
    },
    {
        store_latitdue: -0.318747,
        store_longitude: -78.4608,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.32308,
        store_longitude: -78.45905,
    },
    {
        store_latitdue: -0.318739,
        store_longitude: -78.461531,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.289784,
        store_longitude: -78.480536,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.331481,
        store_longitude: -78.471225,
    },
    {
        store_latitdue: -0.332689,
        store_longitude: -78.471077,
    },
    {
        store_latitdue: -0.33142,
        store_longitude: -78.470566,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.338141,
        store_longitude: -78.480637,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3380654,
        store_longitude: -78.480915,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.335278,
        store_longitude: -78.4646,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.330962,
        store_longitude: -78.471082,
    },
    {
        store_latitdue: -0.2847052,
        store_longitude: -78.4851023,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2901692,
        store_longitude: -78.4806774,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3383513,
        store_longitude: -78.4785388,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2896097,
        store_longitude: -78.470754,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3377072,
        store_longitude: -78.4714913,
    },
    {
        store_latitdue: -0.291317,
        store_longitude: -78.486064,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.28671,
        store_longitude: -78.490455,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.336964,
        store_longitude: -78.4623,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.309124,
        store_longitude: -78.451375,
    },
    {
        store_latitdue: -0.338782,
        store_longitude: -78.477337,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.289948,
        store_longitude: -78.437951,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.331105,
        store_longitude: -78.470486,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2833897,
        store_longitude: -78.4876006,
    },
    {
        store_latitdue: -0.2927131,
        store_longitude: -78.436499,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.338613,
        store_longitude: -78.478031,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.336236,
        store_longitude: -78.464808,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.336351,
        store_longitude: -78.464086,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.30014,
        store_longitude: -78.450832,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2856697,
        store_longitude: -78.4415225,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3082136,
        store_longitude: -78.4936868,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.296188,
        store_longitude: -78.4408,
    },
    {
        store_latitdue: -0.210766,
        store_longitude: -78.3862,
    },
    {
        store_latitdue: -0.301116,
        store_longitude: -78.487825,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2987638,
        store_longitude: -78.4380203,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.303618,
        store_longitude: -78.492622,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.400612,
        store_longitude: -78.5308,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.211325407,
        store_longitude: -78.37660217,
    },
    {
        store_latitdue: -0.298014,
        store_longitude: -78.439637,
    },
    {
        store_latitdue: -0.3984315,
        store_longitude: -78.5400887,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.302773,
        store_longitude: -78.489288,
    },
    {
        store_latitdue: -0.301949,
        store_longitude: -78.489528,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.301185,
        store_longitude: -78.488987,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2914399,
        store_longitude: -78.4843663,
    },
    {
        store_latitdue: -0.300381,
        store_longitude: -78.4392,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.22421,
        store_longitude: -78.381368,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2992956,
        store_longitude: -78.4427629,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.28079,
        store_longitude: -78.4646,
    },
    {
        store_latitdue: -0.302978,
        store_longitude: -78.490821,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3048628,
        store_longitude: -78.4959757,
    },
    {
        store_latitdue: -0.3042698,
        store_longitude: -78.4848207,
    },
    {
        store_latitdue: -0.315912,
        store_longitude: -78.496524,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.315546,
        store_longitude: -78.48406,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.293894,
        store_longitude: -78.442793,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.214347,
        store_longitude: -78.381854,
    },
    {
        store_latitdue: -0.2987487,
        store_longitude: -78.4378718,
    },
    {
        store_latitdue: -0.3000857,
        store_longitude: -78.4571807,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2999163,
        store_longitude: -78.4512909,
    },
    {
        store_latitdue: -0.306766,
        store_longitude: -78.4940237,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.296378,
        store_longitude: -78.440493,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3047168,
        store_longitude: -78.4886785,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.203982,
        store_longitude: -78.37828,
    },
    {
        store_latitdue: -0.2120091,
        store_longitude: -78.3768446,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3252789,
        store_longitude: -78.4752982,
    },
    {
        store_latitdue: -0.2189018,
        store_longitude: -78.3751733,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3393296,
        store_longitude: -78.4835129,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.301179,
        store_longitude: -78.488358,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.211035,
        store_longitude: -78.385776,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.220181,
        store_longitude: -78.3735,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3022573,
        store_longitude: -78.4900046,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.341632,
        store_longitude: -78.454203,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.368889,
        store_longitude: -78.451469,
    },
    {
        store_latitdue: -0.354661,
        store_longitude: -78.462606,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.358249,
        store_longitude: -78.456783,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.203266,
        store_longitude: -78.384083,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3687844,
        store_longitude: -78.452148,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.365927,
        store_longitude: -78.455083,
    },

    {
        store_latitdue: -0.3134985,
        store_longitude: -78.4584824,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.289341,
        store_longitude: -78.470169,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.307222,
        store_longitude: -78.454711,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.321905,
        store_longitude: -78.462279,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3420177,
        store_longitude: -78.4542051,
    },
    {
        store_latitdue: -0.374337,
        store_longitude: -78.451679,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.3383552,
        store_longitude: -78.4522831,
    },
    {
        store_latitdue: -0.195285,
        store_longitude: -78.397212,
    },
    {
        store_latitdue: -0.290801,
        store_longitude: -78.474394,
    },

    {
        store_latitdue: -0.378213,
        store_longitude: -78.451701,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.287423,
        store_longitude: -78.4711,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2122184,
        store_longitude: -78.4032157,
    },
    {
        store_latitdue: -0.224226,
        store_longitude: -78.396097,
    },
    {
        store_latitdue: -0.2279563,
        store_longitude: -78.3992831,
    },
    {
        store_latitdue: -0.338173,
        store_longitude: -78.445,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2108898,
        store_longitude: -78.4108761,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2987439,
        store_longitude: -78.4804179,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2939267,
        store_longitude: -78.4790226,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2177982,
        store_longitude: -78.4112106,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.291437,
        store_longitude: -78.477836,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2985062,
        store_longitude: -78.4796096,
    },
    {
        store_latitdue: -0.265171,
        store_longitude: -78.464053,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2947493,
        store_longitude: -78.4780025,
    },
    {
        store_latitdue: -0.2959998,
        store_longitude: -78.4791187,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3043816,
        store_longitude: -78.4575707,
    },
    {
        store_latitdue: -0.333867,
        store_longitude: -78.484123,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.326419,
        store_longitude: -78.451882,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3308057,
        store_longitude: -78.4733755,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.312214,
        store_longitude: -78.472777,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.329442,
        store_longitude: -78.498944,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3323302,
        store_longitude: -78.488902,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2910863,
        store_longitude: -78.4811964,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.325594,
        store_longitude: -78.456763,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3307654,
        store_longitude: -78.4770269,
    },
    {
        store_latitdue: -0.3419113,
        store_longitude: -78.4847302,
    },
    {
        store_latitdue: -0.3233654,
        store_longitude: -78.4740323,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.330138,
        store_longitude: -78.495231,
    },
    {
        store_latitdue: -0.2987942,
        store_longitude: -78.4605716,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3142105,
        store_longitude: -78.4733877,
    },
    {
        store_latitdue: -0.314303,
        store_longitude: -78.4735057,
    },
    {
        store_latitdue: -0.305983,
        store_longitude: -78.4706,
    },
    {
        store_latitdue: -0.3246772,
        store_longitude: -78.480693,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.5145065,
        store_longitude: -78.569315,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.374478,
        store_longitude: -78.5485,
    },
    {
        store_latitdue: -0.514202,
        store_longitude: -78.57559,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.51907,
        store_longitude: -78.5749,
    },
    {
        store_latitdue: -0.571828,
        store_longitude: -78.59516,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.593159,
        store_longitude: -78.627617,
        neighborhood: 'EL CHAUPI',
    },
    {
        store_latitdue: -0.487293,
        store_longitude: -78.58839,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.4888648,
        store_longitude: -78.5715691,
    },
    {
        store_latitdue: -0.509957,
        store_longitude: -78.572214,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.377339,
        store_longitude: -78.505235,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.403054,
        store_longitude: -78.54407,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.3967622,
        store_longitude: -78.5308212,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.461485,
        store_longitude: -78.564911,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.4672584,
        store_longitude: -78.5879701,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.405347,
        store_longitude: -78.5495,
    },
    {
        store_latitdue: -0.405467,
        store_longitude: -78.5459,
    },
    {
        store_latitdue: -0.387558,
        store_longitude: -78.511398,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.388972,
        store_longitude: -78.5168,
    },
    {
        store_latitdue: -0.40541,
        store_longitude: -78.5468,
    },
    {
        store_latitdue: -0.449089,
        store_longitude: -78.559043,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.461102,
        store_longitude: -78.5638,
    },
    {
        store_latitdue: -0.3659537,
        store_longitude: -78.4758601,
    },
    {
        store_latitdue: -0.376723,
        store_longitude: -78.488592,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.4109877,
        store_longitude: -78.5483266,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.405091,
        store_longitude: -78.54827,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.376381,
        store_longitude: -78.4872,
    },
    {
        store_latitdue: -0.468203,
        store_longitude: -78.5846,
    },
    {
        store_latitdue: -0.47012,
        store_longitude: -78.5842,
    },
    {
        store_latitdue: -0.213299,
        store_longitude: -78.405603,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.4694563,
        store_longitude: -78.5889763,
        neighborhood: 'ALOAG',
    },

    {
        store_latitdue: -0.467548,
        store_longitude: -78.58451,
    },
    {
        store_latitdue: -0.468201,
        store_longitude: -78.584984,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.468863,
        store_longitude: -78.588031,
    },
    {
        store_latitdue: -0.468575,
        store_longitude: -78.586739,
    },
    {
        store_latitdue: -0.466714,
        store_longitude: -78.58631,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.469172,
        store_longitude: -78.58346,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.366149,
        store_longitude: -78.492529,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.366309,
        store_longitude: -78.4910724,
    },
    {
        store_latitdue: -0.364982,
        store_longitude: -78.492156,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.373085,
        store_longitude: -78.4881768,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.365534,
        store_longitude: -78.476543,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.369189,
        store_longitude: -78.49616,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.51509,
        store_longitude: -78.5681,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.516532,
        store_longitude: -78.5701992,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.276782,
        store_longitude: -78.470534,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.5132551,
        store_longitude: -78.5674073,
    },
    {
        store_latitdue: -0.5150018,
        store_longitude: -78.5717395,
    },
    {
        store_latitdue: -0.358193,
        store_longitude: -78.4033,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.292707,
        store_longitude: -78.425641,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.293186,
        store_longitude: -78.426206,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.302241,
        store_longitude: -78.417952,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.503577,
        store_longitude: -78.5608728,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5254186,
        store_longitude: -78.5790667,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3421278,
        store_longitude: -78.4311303,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.348141,
        store_longitude: -78.428803,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.350527,
        store_longitude: -78.43736,
    },
    {
        store_latitdue: -0.346482,
        store_longitude: -78.4306,
    },
    {
        store_latitdue: -0.513509,
        store_longitude: -78.565742,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.288379,
        store_longitude: -78.407996,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.308823,
        store_longitude: -78.414022,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.291643,
        store_longitude: -78.400824,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3001121,
        store_longitude: -78.3935663,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.405442446,
        store_longitude: -78.54648431,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.2889955,
        store_longitude: -78.4082405,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.292573,
        store_longitude: -78.4005,
    },
    {
        store_latitdue: -0.242494,
        store_longitude: -78.485192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2924617,
        store_longitude: -78.4006141,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2468529,
        store_longitude: -78.4828028,
    },
    {
        store_latitdue: -0.2906879,
        store_longitude: -78.4116603,
    },
    {
        store_latitdue: -0.3366498,
        store_longitude: -78.4418345,
    },

    {
        store_latitdue: -0.363937,
        store_longitude: -78.435973,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.333361,
        store_longitude: -78.453897,
    },
    {
        store_latitdue: -0.324312,
        store_longitude: -78.4115,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.503991,
        store_longitude: -78.570995,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5113303,
        store_longitude: -78.5652086,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.50993,
        store_longitude: -78.567817,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3264559,
        store_longitude: -78.4619798,
    },
    {
        store_latitdue: -0.3196174,
        store_longitude: -78.4636268,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.323765,
        store_longitude: -78.465873,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3229693,
        store_longitude: -78.4611218,
    },
    {
        store_latitdue: -0.5079296,
        store_longitude: -78.5654204,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.232014,
        store_longitude: -78.448837,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.510382,
        store_longitude: -78.563037,
    },
    {
        store_latitdue: -0.32233,
        store_longitude: -78.464272,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.502231,
        store_longitude: -78.569245,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.210765,
        store_longitude: -78.4141,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.5003571,
        store_longitude: -78.5678185,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2917411,
        store_longitude: -78.4814491,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.30611,
        store_longitude: -78.4955,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2895365,
        store_longitude: -78.4447116,
    },
    {
        store_latitdue: -0.281335,
        store_longitude: -78.489603,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.509672,
        store_longitude: -78.5652,
    },
    {
        store_latitdue: -0.29074,
        store_longitude: -78.484411,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.505891,
        store_longitude: -78.562564,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2824979,
        store_longitude: -78.4764479,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3391597,
        store_longitude: -78.4727056,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.195501,
        store_longitude: -78.4994,
    },
    {
        store_latitdue: -0.286353,
        store_longitude: -78.486019,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.205327,
        store_longitude: -78.3777,
    },
    {
        store_latitdue: -0.1982079,
        store_longitude: -78.3823013,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2110094,
        store_longitude: -78.38284,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.315459,
        store_longitude: -78.484034,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.304856,
        store_longitude: -78.432503,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.21876,
        store_longitude: -78.378732,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3019115,
        store_longitude: -78.4823157,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.298799,
        store_longitude: -78.444119,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.221027,
        store_longitude: -78.380689,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.513139,
        store_longitude: -78.568852,
    },
    {
        store_latitdue: -0.510484,
        store_longitude: -78.567556,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.303173,
        store_longitude: -78.4495044,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.300434,
        store_longitude: -78.458379,
    },

    {
        store_latitdue: -0.306628,
        store_longitude: -78.4381439,
    },
    {
        store_latitdue: -0.3372623,
        store_longitude: -78.4525773,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.1799582,
        store_longitude: -78.3882744,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.515011,
        store_longitude: -78.5719121,
    },
    {
        store_latitdue: -0.324569,
        store_longitude: -78.461499,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.324418,
        store_longitude: -78.463843,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.322072,
        store_longitude: -78.46151,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.364946,
        store_longitude: -78.45715,
    },
    {
        store_latitdue: -0.360733,
        store_longitude: -78.456391,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.507728,
        store_longitude: -78.571838,
    },
    {
        store_latitdue: -0.509378,
        store_longitude: -78.572463,
    },
    {
        store_latitdue: -0.2912043,
        store_longitude: -78.4737811,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3676462,
        store_longitude: -78.4529686,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.291011,
        store_longitude: -78.474557,
    },
    {
        store_latitdue: -0.3331465,
        store_longitude: -78.4436068,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.509607,
        store_longitude: -78.5722,
    },
    {
        store_latitdue: -0.282303,
        store_longitude: -78.4788,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.209092,
        store_longitude: -78.404375,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.212279,
        store_longitude: -78.4057,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.292107,
        store_longitude: -78.476611,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2108928,
        store_longitude: -78.4053854,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.290476,
        store_longitude: -78.475816,
    },
    {
        store_latitdue: -0.5086867,
        store_longitude: -78.5725302,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.298103,
        store_longitude: -78.480157,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.5023931,
        store_longitude: -78.5621618,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.201373,
        store_longitude: -78.421648,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.308256,
        store_longitude: -78.452977,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3430868,
        store_longitude: -78.4316884,
    },

    {
        store_latitdue: -0.376245,
        store_longitude: -78.506191,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.305883,
        store_longitude: -78.470294,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3723369,
        store_longitude: -78.3732758,
    },
    {
        store_latitdue: -0.2014038,
        store_longitude: -78.4486746,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2629457,
        store_longitude: -78.4858843,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.337335,
        store_longitude: -78.4818,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3290123,
        store_longitude: -78.55943,
    },
    {
        store_latitdue: -0.377183,
        store_longitude: -78.503957,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.328715,
        store_longitude: -78.4499,
    },
    {
        store_latitdue: -0.511426,
        store_longitude: -78.565428,
    },
    {
        store_latitdue: -0.37352,
        store_longitude: -78.4751,
    },
    {
        store_latitdue: -0.246032,
        store_longitude: -78.5177,
    },
    {
        store_latitdue: -0.510746,
        store_longitude: -78.562347,
    },
    {
        store_latitdue: -0.510695,
        store_longitude: -78.5624,
    },
    {
        store_latitdue: -0.511442,
        store_longitude: -78.562442,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.516346,
        store_longitude: -78.561493,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5204555,
        store_longitude: -78.5823339,
    },
    {
        store_latitdue: -0.517301,
        store_longitude: -78.571336,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.520299,
        store_longitude: -78.576344,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5188132,
        store_longitude: -78.5840886,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.518713,
        store_longitude: -78.5841,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.516055,
        store_longitude: -78.574442,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5115766,
        store_longitude: -78.5705426,
    },
    {
        store_latitdue: -0.506485,
        store_longitude: -78.56935955,
    },
    {
        store_latitdue: -0.511925,
        store_longitude: -78.568046,
    },
    {
        store_latitdue: -0.512145,
        store_longitude: -78.566589,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3764551,
        store_longitude: -78.4873994,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2944838,
        store_longitude: -78.4262896,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.519682,
        store_longitude: -78.5855,
        neighborhood: 'ALOASÍ',
    },

    {
        store_latitdue: -0.378838,
        store_longitude: -78.507964,
    },
    {
        store_latitdue: -0.377117,
        store_longitude: -78.50543,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.378248,
        store_longitude: -78.5051,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.376148,
        store_longitude: -78.486501,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3748884,
        store_longitude: -78.4873716,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.289943,
        store_longitude: -78.474113,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2957145,
        store_longitude: -78.4729003,
    },
    {
        store_latitdue: -0.253328,
        store_longitude: -78.504624,
    },
    {
        store_latitdue: -0.326778,
        store_longitude: -78.4493,
    },
    {
        store_latitdue: -0.386512,
        store_longitude: -78.522642,
    },
    {
        store_latitdue: -0.395562221,
        store_longitude: -78.529566,
    },
    {
        store_latitdue: -0.405538,
        store_longitude: -78.544788,
    },
    {
        store_latitdue: -0.403913,
        store_longitude: -78.5391,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.387676,
        store_longitude: -78.52358,
    },

    {
        store_latitdue: -0.508514,
        store_longitude: -78.572546,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2962177,
        store_longitude: -78.4791971,
    },
    {
        store_latitdue: -0.379752,
        store_longitude: -78.5005,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.5118339,
        store_longitude: -78.5658756,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.291226,
        store_longitude: -78.4037,
    },
    {
        store_latitdue: -0.2921115,
        store_longitude: -78.4006113,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3749755,
        store_longitude: -78.3733783,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.501869,
        store_longitude: -78.572418,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.388864,
        store_longitude: -78.524422,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.385662,
        store_longitude: -78.521235,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.516295,
        store_longitude: -78.57611,
    },
    {
        store_latitdue: -0.355114,
        store_longitude: -78.4147,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.305566,
        store_longitude: -78.450735,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.290174,
        store_longitude: -78.403902,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2986683,
        store_longitude: -78.4295979,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.285432,
        store_longitude: -78.441794,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.288556,
        store_longitude: -78.475232,
    },
    {
        store_latitdue: -0.513432,
        store_longitude: -78.566842,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3973594,
        store_longitude: -78.531427,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.376775,
        store_longitude: -78.5056,
    },
    {
        store_latitdue: -0.51045115,
        store_longitude: -78.5731341,
    },
    {
        store_latitdue: -0.516039,
        store_longitude: -78.574408,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.2241135,
        store_longitude: -78.3834663,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.512538,
        store_longitude: -78.571259,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.403463,
        store_longitude: -78.5356,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.3755244,
        store_longitude: -78.505654,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.512053,
        store_longitude: -78.57136,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.383993,
        store_longitude: -78.5113,
    },
    {
        store_latitdue: -0.3860697,
        store_longitude: -78.5128141,
    },
    {
        store_latitdue: -0.3814848,
        store_longitude: -78.5054972,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.410131,
        store_longitude: -78.552879,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.414833,
        store_longitude: -78.554396,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.505601,
        store_longitude: -78.550002,
    },
    {
        store_latitdue: -0.242817,
        store_longitude: -78.508236,
    },
    {
        store_latitdue: -0.219133,
        store_longitude: -78.412116,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.222391,
        store_longitude: -78.408302,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.227566,
        store_longitude: -78.407149,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.228803,
        store_longitude: -78.409117,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.219197,
        store_longitude: -78.405741,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.212238,
        store_longitude: -78.3999,
    },
    {
        store_latitdue: -0.193107,
        store_longitude: -78.395813,
    },
    {
        store_latitdue: -0.197358,
        store_longitude: -78.398896,
    },
    {
        store_latitdue: -0.205299,
        store_longitude: -78.37763,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2101378,
        store_longitude: -78.374398,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.216136,
        store_longitude: -78.3794,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.216833,
        store_longitude: -78.3988,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2057278,
        store_longitude: -78.4527446,
    },
    {
        store_latitdue: -0.203917,
        store_longitude: -78.4459585,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.205523,
        store_longitude: -78.4447,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.205512,
        store_longitude: -78.4448,
    },
    {
        store_latitdue: -0.2049359,
        store_longitude: -78.4423595,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.200849,
        store_longitude: -78.436146,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.19876,
        store_longitude: -78.4197233,
    },
    {
        store_latitdue: -0.21129,
        store_longitude: -78.3855,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.210349,
        store_longitude: -78.399471,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.231003,
        store_longitude: -78.45004,
    },

    {
        store_latitdue: -0.231408,
        store_longitude: -78.449443,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.216474,
        store_longitude: -78.433807,
    },
    {
        store_latitdue: -0.202143,
        store_longitude: -78.375303,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2018679,
        store_longitude: -78.3796801,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.204293,
        store_longitude: -78.377979,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2184611,
        store_longitude: -78.3977466,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.294053,
        store_longitude: -78.473313,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3691478,
        store_longitude: -78.50256,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.33897,
        store_longitude: -78.43999,
    },
    {
        store_latitdue: -0.343528,
        store_longitude: -78.438094,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3572005,
        store_longitude: -78.4409881,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.349166,
        store_longitude: -78.439862,
    },
    {
        store_latitdue: -0.332772,
        store_longitude: -78.4449,
    },
    {
        store_latitdue: -0.1748886,
        store_longitude: -78.4805973,
    },
    {
        store_latitdue: -0.2482248,
        store_longitude: -78.488167,
    },
    {
        store_latitdue: -0.347058,
        store_longitude: -78.4292,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.357646,
        store_longitude: -78.440907,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.351857,
        store_longitude: -78.441438,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.345477,
        store_longitude: -78.444063,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.342792,
        store_longitude: -78.443636,
    },
    {
        store_latitdue: -0.339712,
        store_longitude: -78.446569,
    },
    {
        store_latitdue: -0.340968,
        store_longitude: -78.449797,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.340645,
        store_longitude: -78.453889,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.339124,
        store_longitude: -78.453395,
    },
    {
        store_latitdue: -0.3667791,
        store_longitude: -78.4898287,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.529749,
        store_longitude: -78.580324,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.503103,
        store_longitude: -78.574208,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.5055213,
        store_longitude: -78.5661989,
    },
    {
        store_latitdue: -0.354138,
        store_longitude: -78.43738,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.349349,
        store_longitude: -78.434852,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.348439,
        store_longitude: -78.428919,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.349133,
        store_longitude: -78.429971,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3344007,
        store_longitude: -78.4417559,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.342201133,
        store_longitude: -78.4385141,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.339816,
        store_longitude: -78.447119,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.330976,
        store_longitude: -78.456159,
    },
    {
        store_latitdue: -0.3320095,
        store_longitude: -78.4556866,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.403767,
        store_longitude: -78.535891,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.1591773,
        store_longitude: -78.4375183,
    },
    {
        store_latitdue: -0.293345,
        store_longitude: -78.477224,
    },
    {
        store_latitdue: -0.335476,
        store_longitude: -78.4868,
    },
    {
        store_latitdue: -0.305259,
        store_longitude: -78.496498,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.304358,
        store_longitude: -78.4778,
    },
    {
        store_latitdue: -0.333319,
        store_longitude: -78.484365,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3324916,
        store_longitude: -78.479012,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.336069,
        store_longitude: -78.480418,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3374407,
        store_longitude: -78.484489,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3451482,
        store_longitude: -78.4851111,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.346054,
        store_longitude: -78.485751,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.347479,
        store_longitude: -78.4825,
    },
    {
        store_latitdue: -0.3466125,
        store_longitude: -78.480545,
    },
    {
        store_latitdue: -0.345959,
        store_longitude: -78.479723,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3307476,
        store_longitude: -78.4770046,
    },
    {
        store_latitdue: -0.330161,
        store_longitude: -78.475403,
    },
    {
        store_latitdue: -0.3306724,
        store_longitude: -78.4738817,
    },
    {
        store_latitdue: -0.333846,
        store_longitude: -78.473961,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.33531,
        store_longitude: -78.474396,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.338963,
        store_longitude: -78.472511,
    },
    {
        store_latitdue: -0.33469,
        store_longitude: -78.471002,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.295768,
        store_longitude: -78.470839,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.298145,
        store_longitude: -78.468953,
    },
    {
        store_latitdue: -0.299214,
        store_longitude: -78.466232,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.4801671,
        store_longitude: -78.4801671,
    },
    {
        store_latitdue: -0.297823,
        store_longitude: -78.480605,
    },
    {
        store_latitdue: -0.317493,
        store_longitude: -78.459427,
    },
    {
        store_latitdue: -0.3103423,
        store_longitude: -78.4720215,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.27897,
        store_longitude: -78.495809,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.283221,
        store_longitude: -78.493972,
    },
    {
        store_latitdue: -0.28233,
        store_longitude: -78.491348,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.292602,
        store_longitude: -78.488525,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.29853,
        store_longitude: -78.484892,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.290978,
        store_longitude: -78.484642,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.266189,
        store_longitude: -78.4801,
    },
    {
        store_latitdue: -0.2680356,
        store_longitude: -78.4762711,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.26682,
        store_longitude: -78.472179,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2645438,
        store_longitude: -78.4730297,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.267063,
        store_longitude: -78.464201,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3058737,
        store_longitude: -78.4706116,
    },
    {
        store_latitdue: -0.295786,
        store_longitude: -78.472712,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.285825,
        store_longitude: -78.4832,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.286786,
        store_longitude: -78.474015,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.279124,
        store_longitude: -78.495329,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2836614,
        store_longitude: -78.4871993,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.291115,
        store_longitude: -78.489534,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.297116,
        store_longitude: -78.476843,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.297527,
        store_longitude: -78.47612,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.292611,
        store_longitude: -78.4769,
    },
    {
        store_latitdue: -0.3062648,
        store_longitude: -78.4939085,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.304634,
        store_longitude: -78.493744,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2403697,
        store_longitude: -78.4840467,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240407,
        store_longitude: -78.484006,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.278135,
        store_longitude: -78.47497,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.274333,
        store_longitude: -78.472514,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.276939,
        store_longitude: -78.470003,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.253121,
        store_longitude: -78.477284,
    },
    {
        store_latitdue: -0.280362,
        store_longitude: -78.481111,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.280928,
        store_longitude: -78.4772,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.277546,
        store_longitude: -78.475298,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.278461,
        store_longitude: -78.479839,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.274539,
        store_longitude: -78.472555,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.331927,
        store_longitude: -78.4420327,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.321947,
        store_longitude: -78.450062,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.310077,
        store_longitude: -78.45283,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.30401,
        store_longitude: -78.430478,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.293405,
        store_longitude: -78.436077,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3643741,
        store_longitude: -78.4382658,
    },
    {
        store_latitdue: -0.330086,
        store_longitude: -78.448553,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3333339,
        store_longitude: -78.4461433,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.329508,
        store_longitude: -78.44895,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.327147,
        store_longitude: -78.453019,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.326086,
        store_longitude: -78.453081,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.379154,
        store_longitude: -78.373845,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.371021,
        store_longitude: -78.373283,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3714778,
        store_longitude: -78.3752554,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.368359,
        store_longitude: -78.45383,
    },
    {
        store_latitdue: -0.324555,
        store_longitude: -78.419786,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.330809,
        store_longitude: -78.423282,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.328027,
        store_longitude: -78.429018,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3333713,
        store_longitude: -78.4360656,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.37807,
        store_longitude: -78.3739,
    },
    {
        store_latitdue: -0.3053304,
        store_longitude: -78.4582715,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.315273,
        store_longitude: -78.459455,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.315263,
        store_longitude: -78.45939,
    },
    {
        store_latitdue: -0.303607,
        store_longitude: -78.460577,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.307204,
        store_longitude: -78.455494,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2947903,
        store_longitude: -78.4519681,
    },
    {
        store_latitdue: -0.259128,
        store_longitude: -78.451775,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.257176,
        store_longitude: -78.4515,
    },
    {
        store_latitdue: -0.262448,
        store_longitude: -78.451357,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.28619,
        store_longitude: -78.440658,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.285979,
        store_longitude: -78.4403,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.289395,
        store_longitude: -78.438214,
    },
    {
        store_latitdue: -0.287285,
        store_longitude: -78.440047,
    },
    {
        store_latitdue: -0.306432,
        store_longitude: -78.419034,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.30734,
        store_longitude: -78.41819,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.307728,
        store_longitude: -78.415343,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2929308,
        store_longitude: -78.4001566,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.292734,
        store_longitude: -78.3997249,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.291521,
        store_longitude: -78.398407,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.320437,
        store_longitude: -78.46184,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.320621,
        store_longitude: -78.462354,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3208522,
        store_longitude: -78.4644076,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.32155,
        store_longitude: -78.462911,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.323917,
        store_longitude: -78.464869,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.324064,
        store_longitude: -78.465214,
    },
    {
        store_latitdue: -0.322781,
        store_longitude: -78.4686,
    },
    {
        store_latitdue: -0.3212196,
        store_longitude: -78.4629644,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.322824,
        store_longitude: -78.46256,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3313645,
        store_longitude: -78.4490628,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.329972,
        store_longitude: -78.4484,
    },
    {
        store_latitdue: -0.326764,
        store_longitude: -78.451177,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.325529,
        store_longitude: -78.4518,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2856584,
        store_longitude: -78.4514584,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.32965,
        store_longitude: -78.42502,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.330872,
        store_longitude: -78.425832,
    },

    {
        store_latitdue: -0.3042499,
        store_longitude: -78.4594925,
    },
    {
        store_latitdue: -0.205971,
        store_longitude: -78.400473,
    },
    {
        store_latitdue: -0.211237,
        store_longitude: -78.376682,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.203296,
        store_longitude: -78.37621,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.178766,
        store_longitude: -78.394723,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.200177,
        store_longitude: -78.420647,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.215109,
        store_longitude: -78.415819,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.340887,
        store_longitude: -78.438956,
    },
    {
        store_latitdue: -0.4976892,
        store_longitude: -78.5714668,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.382823,
        store_longitude: -78.50356,
    },
    {
        store_latitdue: -0.338509,
        store_longitude: -78.448856,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.521065,
        store_longitude: -78.570471,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.330751,
        store_longitude: -78.473586,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.335994,
        store_longitude: -78.471468,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.333726,
        store_longitude: -78.4706,
    },
    {
        store_latitdue: -0.2918044,
        store_longitude: -78.4893027,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.368587,
        store_longitude: -78.373221,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.363945,
        store_longitude: -78.376355,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.507344,
        store_longitude: -78.5690425,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.515018,
        store_longitude: -78.5649,
    },
    {
        store_latitdue: -0.515671,
        store_longitude: -78.573404,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.207677,
        store_longitude: -78.388216,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.222419,
        store_longitude: -78.3812,
    },

    {
        store_latitdue: -0.21687,
        store_longitude: -78.4332,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.198912,
        store_longitude: -78.4349,
    },
    {
        store_latitdue: -0.222679,
        store_longitude: -78.370883,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.4045028,
        store_longitude: -78.5468944,
    },
    {
        store_latitdue: -0.343518,
        store_longitude: -78.430085,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.356633,
        store_longitude: -78.441351,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.340144,
        store_longitude: -78.447089,
    },
    {
        store_latitdue: -0.329807,
        store_longitude: -78.47728,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.267822,
        store_longitude: -78.4636,
    },
    {
        store_latitdue: -0.278995,
        store_longitude: -78.473925,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.372565,
        store_longitude: -78.373842,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3253779,
        store_longitude: -78.4174955,
    },
    {
        store_latitdue: -0.33109,
        store_longitude: -78.4225,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.228733,
        store_longitude: -78.408215,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.196413,
        store_longitude: -78.441962,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.504724,
        store_longitude: -78.570677,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.459587,
        store_longitude: -78.5634,
    },
    {
        store_latitdue: -0.272094,
        store_longitude: -78.456073,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.27398,
        store_longitude: -78.456931,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2740861,
        store_longitude: -78.4569332,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.296939,
        store_longitude: -78.4802564,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.33959,
        store_longitude: -78.470899,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.278366,
        store_longitude: -78.4744,
    },
    {
        store_latitdue: -0.278344,
        store_longitude: -78.474435,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3282538,
        store_longitude: -78.4267272,
    },
    {
        store_latitdue: -0.331764,
        store_longitude: -78.424521,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3355233,
        store_longitude: -78.47465,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.4704267,
        store_longitude: -78.5848681,
    },
    {
        store_latitdue: -0.2211452,
        store_longitude: -78.4087483,
    },
    {
        store_latitdue: -0.198535,
        store_longitude: -78.435973,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.345023,
        store_longitude: -78.429236,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.344627,
        store_longitude: -78.429439,
    },
    {
        store_latitdue: -0.33642,
        store_longitude: -78.450609,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.356592,
        store_longitude: -78.439669,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.513604,
        store_longitude: -78.566054,
    },
    {
        store_latitdue: -0.32324,
        store_longitude: -78.465731,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3175528,
        store_longitude: -78.4605151,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2990692,
        store_longitude: -78.4286265,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3710078,
        store_longitude: -78.3733384,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.5042657,
        store_longitude: -78.5755627,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.433977,
        store_longitude: -78.540675,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.281981,
        store_longitude: -78.491373,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.282298,
        store_longitude: -78.491652,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2917,
        store_longitude: -78.4833,
    },
    {
        store_latitdue: -0.268904,
        store_longitude: -78.463367,
    },
    {
        store_latitdue: -0.337448,
        store_longitude: -78.481782,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3065438,
        store_longitude: -78.4380331,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.215657,
        store_longitude: -78.373668,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.216975,
        store_longitude: -78.399897,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.200891,
        store_longitude: -78.431345,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.294569,
        store_longitude: -78.437258,
    },
    {
        store_latitdue: -0.329476,
        store_longitude: -78.425241,
    },
    {
        store_latitdue: -0.220395,
        store_longitude: -78.373236,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.50292,
        store_longitude: -78.5649,
    },
    {
        store_latitdue: -0.343838,
        store_longitude: -78.429924,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.325244,
        store_longitude: -78.41847,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.342616,
        store_longitude: -78.484916,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2907428,
        store_longitude: -78.474353,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2529,
        store_longitude: -78.493289,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.224862,
        store_longitude: -78.340251,
    },
    {
        store_latitdue: -0.3307226,
        store_longitude: -78.4576551,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.215377,
        store_longitude: -78.406576,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3484089,
        store_longitude: -78.4806249,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.346287,
        store_longitude: -78.442446,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.304231,
        store_longitude: -78.4576,
    },
    {
        store_latitdue: -0.5065868,
        store_longitude: -78.5673169,
    },
    {
        store_latitdue: -0.251343,
        store_longitude: -78.478891,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.288185,
        store_longitude: -78.478634,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.318473,
        store_longitude: -78.4502486,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.195073,
        store_longitude: -78.397086,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.389249,
        store_longitude: -78.535035,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.214059,
        store_longitude: -78.405,
    },
    {
        store_latitdue: -0.23037,
        store_longitude: -78.40026,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.339169,
        store_longitude: -78.441312,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.513783,
        store_longitude: -78.56289,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5161569,
        store_longitude: -78.5798374,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.515571,
        store_longitude: -78.564643,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.466264,
        store_longitude: -78.584666,
    },
    {
        store_latitdue: -0.508483,
        store_longitude: -78.5615065,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.210734,
        store_longitude: -78.393822,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.223702,
        store_longitude: -78.396741,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.29097,
        store_longitude: -78.472219,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.349922,
        store_longitude: -78.481284,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3309319,
        store_longitude: -78.4459561,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.517347,
        store_longitude: -78.5845587,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.5112893,
        store_longitude: -78.5640601,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.355297,
        store_longitude: -78.47829,
    },
    {
        store_latitdue: -0.356389,
        store_longitude: -78.479096,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.203023,
        store_longitude: -78.442074,
    },
    {
        store_latitdue: -0.287854,
        store_longitude: -78.4713,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.326207,
        store_longitude: -78.4471,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.404673,
        store_longitude: -78.545058,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.297716,
        store_longitude: -78.482891,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.331616,
        store_longitude: -78.4557407,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.314549,
        store_longitude: -78.48,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3743073,
        store_longitude: -78.3734737,
    },
    {
        store_latitdue: -0.2144219,
        store_longitude: -78.3884044,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.217921,
        store_longitude: -78.402873,
    },
    {
        store_latitdue: -0.316858,
        store_longitude: -78.495518,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.318282,
        store_longitude: -78.496447,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3302786,
        store_longitude: -78.47345,
    },
    {
        store_latitdue: -0.285113,
        store_longitude: -78.48507,
    },
    {
        store_latitdue: -0.296605,
        store_longitude: -78.477639,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.298877,
        store_longitude: -78.427147,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.304882,
        store_longitude: -78.432076,
    },
    {
        store_latitdue: -0.290835,
        store_longitude: -78.489332,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.300778,
        store_longitude: -78.474642,
    },
    {
        store_latitdue: -0.327329,
        store_longitude: -78.409963,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.332668,
        store_longitude: -78.436457,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.331411,
        store_longitude: -78.446838,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.276592,
        store_longitude: -78.46926,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.300564,
        store_longitude: -78.420648,
    },
    {
        store_latitdue: -0.2002872,
        store_longitude: -78.434913,
    },
    {
        store_latitdue: -0.380587,
        store_longitude: -78.506945,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.274575,
        store_longitude: -78.469024,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2868553,
        store_longitude: -78.4489673,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.326203,
        store_longitude: -78.452657,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.513608,
        store_longitude: -78.57168,
    },
    {
        store_latitdue: -0.333977,
        store_longitude: -78.448437,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.4042091,
        store_longitude: -78.5425703,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.3853842,
        store_longitude: -78.5219102,
        neighborhood: 'UYUMBICHO',
    },

    {
        store_latitdue: -0.2224585,
        store_longitude: -78.4081262,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2028007,
        store_longitude: -78.4000419,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.461113,
        store_longitude: -78.564,
    },
    {
        store_latitdue: -0.460837,
        store_longitude: -78.566274,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.20751,
        store_longitude: -78.3865,
    },
    {
        store_latitdue: -0.5155124,
        store_longitude: -78.5668073,
    },

    {
        store_latitdue: -0.513743,
        store_longitude: -78.568324,
    },
    {
        store_latitdue: -0.379616,
        store_longitude: -78.495966,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.589883,
        store_longitude: -78.6007759,
    },
    {
        store_latitdue: -0.211218,
        store_longitude: -78.3853395,
    },
    {
        store_latitdue: -0.347797,
        store_longitude: -78.481857,
    },
    {
        store_latitdue: -0.32172,
        store_longitude: -78.4657,
    },
    {
        store_latitdue: -0.499518,
        store_longitude: -78.568729,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.525651,
        store_longitude: -78.554498,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.527487,
        store_longitude: -78.5816431,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.20806,
        store_longitude: -78.3863,
    },

    {
        store_latitdue: -0.3663546,
        store_longitude: -78.4909693,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.49896,
        store_longitude: -78.5504,
    },
    {
        store_latitdue: -0.505907,
        store_longitude: -78.569817,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.519157,
        store_longitude: -78.57524,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.280723,
        store_longitude: -78.481978,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.321742,
        store_longitude: -78.463411,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.5174198,
        store_longitude: -78.584702,
        neighborhood: 'ALOASÍ',
    },

    {
        store_latitdue: -0.5181081,
        store_longitude: -78.583907,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.3134268,
        store_longitude: -78.4513277,
    },
    {
        store_latitdue: -0.405286,
        store_longitude: -78.5451365,
    },
    {
        store_latitdue: -0.4683459,
        store_longitude: -78.5876673,
    },
    {
        store_latitdue: -0.512568,
        store_longitude: -78.570207,
    },
    {
        store_latitdue: -0.2046408,
        store_longitude: -78.3781595,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3851172,
        store_longitude: -78.5216724,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.503087,
        store_longitude: -78.575255,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.3176152,
        store_longitude: -78.4664007,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.5035633,
        store_longitude: -78.5607883,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.379459,
        store_longitude: -78.4962697,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.28038,
        store_longitude: -78.475161,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.386216,
        store_longitude: -78.509241,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2801464,
        store_longitude: -78.4701445,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.515457,
        store_longitude: -78.561995,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2030452,
        store_longitude: -78.3789972,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.333791,
        store_longitude: -78.4452,
    },
    {
        store_latitdue: -0.461853,
        store_longitude: -78.563938,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.503467,
        store_longitude: -78.567294,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.506743,
        store_longitude: -78.576086,
    },
    {
        store_latitdue: -0.512252,
        store_longitude: -78.568671,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3768308,
        store_longitude: -78.505659,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.469209,
        store_longitude: -78.5891,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.40348,
        store_longitude: -78.5356,
    },
    {
        store_latitdue: -0.3742561,
        store_longitude: -78.4878663,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.327858,
        store_longitude: -78.447497,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.50284,
        store_longitude: -78.571813,
    },
    {
        store_latitdue: -0.381814,
        store_longitude: -78.5057,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.419946,
        store_longitude: -78.549674,
    },
    {
        store_latitdue: -0.462113,
        store_longitude: -78.5645,
    },
    {
        store_latitdue: -0.2164758,
        store_longitude: -78.3987239,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.402966,
        store_longitude: -78.549777,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.514877,
        store_longitude: -78.571453,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.521079,
        store_longitude: -78.585,
    },
    {
        store_latitdue: -0.3353213,
        store_longitude: -78.5433463,
    },
    {
        store_latitdue: -0.5020998,
        store_longitude: -78.5722725,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3750728,
        store_longitude: -78.5052826,
    },
    {
        store_latitdue: -0.381895,
        store_longitude: -78.511454,
    },
    {
        store_latitdue: -0.382746,
        store_longitude: -78.5036,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.475949,
        store_longitude: -78.579876,
    },
    {
        store_latitdue: -0.366149,
        store_longitude: -78.492529,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.512598,
        store_longitude: -78.567369,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5088558,
        store_longitude: -78.5682321,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.503767,
        store_longitude: -78.5692,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.258239,
        store_longitude: -78.45019,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.2876833,
        store_longitude: -78.4381614,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.405547,
        store_longitude: -78.5449,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.3855221,
        store_longitude: -78.5138496,
    },

    {
        store_latitdue: -0.34764,
        store_longitude: -78.43306,
    },
    {
        store_latitdue: -0.5202119,
        store_longitude: -78.5870819,
    },
    {
        store_latitdue: -0.3114496,
        store_longitude: -78.5341407,
    },
    {
        store_latitdue: -0.4118686,
        store_longitude: -78.5502219,
    },
    {
        store_latitdue: -0.519293,
        store_longitude: -78.566017,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2319409,
        store_longitude: -78.4498772,
    },
    {
        store_latitdue: -0.2890014,
        store_longitude: -78.4796498,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.280104,
        store_longitude: -78.49681,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.5096481,
        store_longitude: -78.5634017,
    },
    {
        store_latitdue: -0.287285,
        store_longitude: -78.440047,
    },
    {
        store_latitdue: -0.367248,
        store_longitude: -78.454004,
    },
    {
        store_latitdue: -0.3095774,
        store_longitude: -78.4527956,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.272715,
        store_longitude: -78.491567,
    },
    {
        store_latitdue: -0.4662521,
        store_longitude: -78.5835734,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.293272,
        store_longitude: -78.4358,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.298399,
        store_longitude: -78.445545,
    },
    {
        store_latitdue: -0.201492,
        store_longitude: -78.4324,
    },
    {
        store_latitdue: -0.378184,
        store_longitude: -78.505029,
    },
    {
        store_latitdue: -0.282691,
        store_longitude: -78.4921,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.571772,
        store_longitude: -78.594881,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.602386,
        store_longitude: -78.641536,
        neighborhood: 'EL CHAUPI',
    },
    {
        store_latitdue: -0.514414,
        store_longitude: -78.5694,
    },
    {
        store_latitdue: -0.504763,
        store_longitude: -78.543622,
    },
    {
        store_latitdue: 0.50816998,
        store_longitude: -77.86603184,
    },
    {
        store_latitdue: -0.212703,
        store_longitude: -78.429869,
    },
    {
        store_latitdue: -0.3789654,
        store_longitude: -78.5006864,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3830037,
        store_longitude: -78.5201096,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.3660408,
        store_longitude: -78.49353,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.504571,
        store_longitude: -78.570178,
    },
    {
        store_latitdue: -0.41259,
        store_longitude: -78.552071,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.525753,
        store_longitude: -78.582149,
    },

    {
        store_latitdue: -0.3109186,
        store_longitude: -78.4000591,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3833447,
        store_longitude: -78.5101479,
    },
    {
        store_latitdue: -0.5028854,
        store_longitude: -78.5877032,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.470309,
        store_longitude: -78.583746,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.35713,
        store_longitude: -78.4411,
    },
    {
        store_latitdue: -0.616489,
        store_longitude: -78.629248,
    },
    {
        store_latitdue: -0.616921,
        store_longitude: -78.629446,
    },
    {
        store_latitdue: -0.4704863,
        store_longitude: -78.5838211,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.282161,
        store_longitude: -78.4914,
    },
    {
        store_latitdue: -0.327833,
        store_longitude: -78.450279,
    },
    {
        store_latitdue: -0.3753456,
        store_longitude: -78.4372282,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.373511,
        store_longitude: -78.3745261,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3312881,
        store_longitude: -78.4224307,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2946324,
        store_longitude: -78.4763669,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.307944,
        store_longitude: -78.4164,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3365884,
        store_longitude: -78.4787875,
    },
    {
        store_latitdue: -0.334868,
        store_longitude: -78.4855,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.160025,
        store_longitude: -78.4746,
    },
    {
        store_latitdue: -0.50630394,
        store_longitude: -78.5694035,
    },

    {
        store_latitdue: -0.502098,
        store_longitude: -78.5713,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.3565307,
        store_longitude: -78.4414411,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3369525,
        store_longitude: -78.4583412,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.307371,
        store_longitude: -78.4554,
    },
    {
        store_latitdue: -0.347615,
        store_longitude: -78.4822,
    },
    {
        store_latitdue: -0.3666773,
        store_longitude: -78.375653,
    },
    {
        store_latitdue: -0.331345,
        store_longitude: -78.4449,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.293356,
        store_longitude: -78.4653,
    },

    {
        store_latitdue: -0.433844,
        store_longitude: -78.5396,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.376863,
        store_longitude: -78.4959,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.22213,
        store_longitude: -78.3796,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.21768,
        store_longitude: -78.3774,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3849515,
        store_longitude: -78.5215643,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.5300075,
        store_longitude: -78.5601141,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.205129,
        store_longitude: -78.3777,
    },
    {
        store_latitdue: -0.206486,
        store_longitude: -78.4442,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.297374,
        store_longitude: -78.4562,
    },
    {
        store_latitdue: -0.473137,
        store_longitude: -78.584,
        neighborhood: 'ALOAG',
    },

    {
        store_latitdue: -0.3714334,
        store_longitude: -78.4750011,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3688513,
        store_longitude: -78.5022976,
    },
    {
        store_latitdue: -0.289745,
        store_longitude: -78.467823,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.199997,
        store_longitude: -78.389565,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3092773,
        store_longitude: -78.4932164,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3091624,
        store_longitude: -78.4927093,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.204889,
        store_longitude: -78.4503,
    },
    {
        store_latitdue: -0.324293,
        store_longitude: -78.4647944,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3469873,
        store_longitude: -78.4837682,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3200691,
        store_longitude: -78.462818,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2952563,
        store_longitude: -78.4069361,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3668,
        store_longitude: -78.3712,
        neighborhood: 'PÍNTAG',
    },

    {
        store_latitdue: -0.5101,
        store_longitude: -78.5644,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.369288,
        store_longitude: -78.5032,
    },
    {
        store_latitdue: -0.198736,
        store_longitude: -78.4355,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2057,
        store_longitude: -78.4449,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2913067,
        store_longitude: -78.4296134,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.250465,
        store_longitude: -78.4485,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.32776406,
        store_longitude: -78.4169666,
    },
    {
        store_latitdue: -0.51769,
        store_longitude: -78.5827,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.218001,
        store_longitude: -78.4114,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.206492,
        store_longitude: -78.428,
    },

    {
        store_latitdue: -0.3019263,
        store_longitude: -78.4182157,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.5178499,
        store_longitude: -78.5657071,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5073597,
        store_longitude: -78.5597436,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.2713567,
        store_longitude: -78.4659232,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.267302,
        store_longitude: -78.4632,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2099985,
        store_longitude: -78.3821852,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3291663,
        store_longitude: -78.4562868,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3554733,
        store_longitude: -78.4409694,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.321708,
        store_longitude: -78.4605,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.293452,
        store_longitude: -78.4503,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.50517189,
        store_longitude: -78.5702285,
    },
    {
        store_latitdue: -0.3761122,
        store_longitude: -78.4886874,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3448543,
        store_longitude: -78.4310784,
    },
    {
        store_latitdue: -0.3807986,
        store_longitude: -78.4914283,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.260139,
        store_longitude: -78.4808,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.347322,
        store_longitude: -78.4805,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.321932892,
        store_longitude: -78.46399459,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2907653,
        store_longitude: -78.4093001,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.136433,
        store_longitude: -78.5072,
    },
    {
        store_latitdue: -0.366343,
        store_longitude: -78.4756,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.305398,
        store_longitude: -78.496602,
    },

    {
        store_latitdue: -0.3366828,
        store_longitude: -78.467553,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3666889,
        store_longitude: -78.3756014,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.5114712,
        store_longitude: -78.5670327,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.381018,
        store_longitude: -78.5061,
    },
    {
        store_latitdue: -0.382017,
        store_longitude: -78.5125,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3383301,
        store_longitude: -78.4420427,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.294003,
        store_longitude: -78.476,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.298939,
        store_longitude: -78.4796,
    },
    {
        store_latitdue: -0.300072,
        store_longitude: -78.4745,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3223554,
        store_longitude: -78.4648644,
    },
    {
        store_latitdue: -0.318073,
        store_longitude: -78.4721,
    },

    {
        store_latitdue: -0.349438,
        store_longitude: -78.4435,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3436795,
        store_longitude: -78.4310357,
    },
    {
        store_latitdue: -0.219066,
        store_longitude: -78.3749,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.219486,
        store_longitude: -78.4124,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.501568,
        store_longitude: -78.5664,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.3655531,
        store_longitude: -78.5057971,
    },
    {
        store_latitdue: -0.282724,
        store_longitude: -78.4918,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3072106,
        store_longitude: -78.414547,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3070073,
        store_longitude: -78.4384848,
    },

    {
        store_latitdue: -0.2680901,
        store_longitude: -78.4641445,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.298552,
        store_longitude: -78.4663,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.23077,
        store_longitude: -78.448799,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.186908,
        store_longitude: -78.39109,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.205698,
        store_longitude: -78.445,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.375146,
        store_longitude: -78.4887,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2265813,
        store_longitude: -78.3403071,
    },
    {
        store_latitdue: -0.304672,
        store_longitude: -78.4886,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3719236,
        store_longitude: -78.3733272,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.2989063,
        store_longitude: -78.427976,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.1771208,
        store_longitude: -78.396013,
    },
    {
        store_latitdue: -0.201667,
        store_longitude: -78.430016,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.212592,
        store_longitude: -78.4042,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.300872,
        store_longitude: -78.4192,
    },
    {
        store_latitdue: -0.3383638,
        store_longitude: -78.3838766,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.333069,
        store_longitude: -78.4439,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2965303,
        store_longitude: -78.4655628,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.33269993,
        store_longitude: -78.4212591,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.376144,
        store_longitude: -78.3735,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.373904,
        store_longitude: -78.4212,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2706971,
        store_longitude: -78.4656848,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3401357,
        store_longitude: -78.488217,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2838905,
        store_longitude: -78.4955338,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3810083,
        store_longitude: -78.5013954,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.325357,
        store_longitude: -78.4189084,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.32684618,
        store_longitude: -78.4748378,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.336391,
        store_longitude: -78.434,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.1940993,
        store_longitude: -78.3965511,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3255466,
        store_longitude: -78.4500076,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3398883,
        store_longitude: -78.4718211,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2298821,
        store_longitude: -78.4422704,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.322287,
        store_longitude: -78.4745,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.291456,
        store_longitude: -78.401,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2988794,
        store_longitude: -78.4281498,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.304068,
        store_longitude: -78.4174,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.5026325,
        store_longitude: -78.589689,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.2113099,
        store_longitude: -78.3855983,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3474901,
        store_longitude: -78.4588269,
    },
    {
        store_latitdue: -0.38288,
        store_longitude: -78.5108,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3767387,
        store_longitude: -78.4999259,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.385982,
        store_longitude: -78.4998,
    },
    {
        store_latitdue: -0.292637,
        store_longitude: -78.4884,
    },
    {
        store_latitdue: -0.296925,
        store_longitude: -78.4848,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.263088,
        store_longitude: -78.485963,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.26843,
        store_longitude: -78.4851,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.224096,
        store_longitude: -78.428,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.217941,
        store_longitude: -78.405087,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3234659,
        store_longitude: -78.4612355,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.213248,
        store_longitude: -78.3721,
    },
    {
        store_latitdue: -0.212436502,
        store_longitude: -78.40317696,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.512583,
        store_longitude: -78.568891,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2071192,
        store_longitude: -78.3888923,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3728081,
        store_longitude: -78.4516722,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.474891,
        store_longitude: -78.5807,
    },
    {
        store_latitdue: -0.501856,
        store_longitude: -78.561768,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2862444,
        store_longitude: -78.4862312,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.285766,
        store_longitude: -78.486563,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.283401,
        store_longitude: -78.4843,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.218543,
        store_longitude: -78.3998,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.213404,
        store_longitude: -78.4034,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.32484,
        store_longitude: -78.463873,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.33472314,
        store_longitude: -78.4475555,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3084159,
        store_longitude: -78.4527295,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3471077,
        store_longitude: -78.4585092,
    },
    {
        store_latitdue: -0.3022796,
        store_longitude: -78.4857936,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.202838,
        store_longitude: -78.457386,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.21921,
        store_longitude: -78.4113,
    },
    {
        store_latitdue: -0.189826,
        store_longitude: -78.3849,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3586947,
        store_longitude: -78.4810304,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3336264,
        store_longitude: -78.4455344,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.332079,
        store_longitude: -78.4891,
    },
    {
        store_latitdue: -0.3778315,
        store_longitude: -78.3734427,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.294171,
        store_longitude: -78.436766,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2963236,
        store_longitude: -78.4001338,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3697786,
        store_longitude: -78.3744652,
        neighborhood: 'PÍNTAG',
    },

    {
        store_latitdue: -0.5157072,
        store_longitude: -78.5683728,
    },

    {
        store_latitdue: -0.3136822,
        store_longitude: -78.4518357,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2861067,
        store_longitude: -78.499268,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3603521,
        store_longitude: -78.456469,
    },
    {
        store_latitdue: -0.1995565,
        store_longitude: -78.4320323,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.504185,
        store_longitude: -78.5545478,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5145001,
        store_longitude: -78.5661437,
    },
    {
        store_latitdue: -0.358105,
        store_longitude: -78.456105,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.3025751,
        store_longitude: -78.4705548,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2013477,
        store_longitude: -78.3920455,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3463391,
        store_longitude: -78.4808926,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.2311395,
        store_longitude: -78.4494961,
    },
    {
        store_latitdue: -0.332071,
        store_longitude: -78.4243,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.37647,
        store_longitude: -78.4088,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3731601,
        store_longitude: -78.4811615,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3934171,
        store_longitude: -78.4334388,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.356229,
        store_longitude: -78.4566957,
    },

    {
        store_latitdue: -0.282323,
        store_longitude: -78.4915199,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2913948,
        store_longitude: -78.5236273,
    },
    {
        store_latitdue: -0.179381,
        store_longitude: -78.4391,
    },
    {
        store_latitdue: -0.163074,
        store_longitude: -78.3172,
    },
    {
        store_latitdue: -0.213025,
        store_longitude: -78.4039,
    },
    {
        store_latitdue: -0.319761,
        store_longitude: -78.4602,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.31957,
        store_longitude: -78.4349,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.372781,
        store_longitude: -78.484794,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.4035022,
        store_longitude: -78.5432531,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.2084977,
        store_longitude: -78.3885527,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.210378,
        store_longitude: -78.3862,
    },

    {
        store_latitdue: -0.5247137,
        store_longitude: -78.5564209,
    },
    {
        store_latitdue: -0.22032989,
        store_longitude: -78.3699645,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3321869,
        store_longitude: -78.4861449,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3295397,
        store_longitude: -78.4252392,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3294689,
        store_longitude: -78.4984766,
    },
    {
        store_latitdue: -0.5083288,
        store_longitude: -78.5401684,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.321941,
        store_longitude: -78.485355,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.4059677,
        store_longitude: -78.5455481,
    },

    {
        store_latitdue: -0.379586,
        store_longitude: -78.504079,
    },
    {
        store_latitdue: -0.2804199,
        store_longitude: -78.492977,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.304121,
        store_longitude: -78.4785,
    },

    {
        store_latitdue: -0.232192,
        store_longitude: -78.4497,
    },

    {
        store_latitdue: -0.230847,
        store_longitude: -78.4534,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.312485,
        store_longitude: -78.48,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.4056243,
        store_longitude: -78.5468353,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.512669,
        store_longitude: -78.566071,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.214696,
        store_longitude: -78.3766,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.30512,
        store_longitude: -78.4364,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3489566,
        store_longitude: -78.4809536,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.2973408,
        store_longitude: -78.3959518,
        neighborhood: 'LA MERCED',
    },

    {
        store_latitdue: -0.3799096,
        store_longitude: -78.4884938,
    },

    {
        store_latitdue: -0.2045341,
        store_longitude: -78.4420207,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2084162,
        store_longitude: -78.3944813,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.33937,
        store_longitude: -78.442261,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.376043,
        store_longitude: -78.496936,
    },
    {
        store_latitdue: -0.5008202,
        store_longitude: -78.558986,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.279763,
        store_longitude: -78.4761,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3401145,
        store_longitude: -78.4394884,
    },
    {
        store_latitdue: -0.3368071,
        store_longitude: -78.4650198,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2211395,
        store_longitude: -78.4445694,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.519766,
        store_longitude: -78.574126,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2105457,
        store_longitude: -78.373253,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3292951,
        store_longitude: -78.4293215,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.304331,
        store_longitude: -78.4848,
    },
    {
        store_latitdue: -0.2918032,
        store_longitude: -78.4013393,
    },

    {
        store_latitdue: -0.179904,
        store_longitude: -78.394247,
    },

    {
        store_latitdue: -0.1970841,
        store_longitude: -78.4535664,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.19958394,
        store_longitude: -78.4299051,
    },
    {
        store_latitdue: -0.2938537,
        store_longitude: -78.4762891,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.27004513,
        store_longitude: -78.4640884,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.272885,
        store_longitude: -78.4859,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2735182,
        store_longitude: -78.4860898,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3455948,
        store_longitude: -78.4804541,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.504922,
        store_longitude: -78.570393,
    },
    {
        store_latitdue: -0.3449859,
        store_longitude: -78.4295222,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2321208,
        store_longitude: -78.4504201,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.3001547,
        store_longitude: -78.4476183,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.51259582,
        store_longitude: -78.5691379,
    },
    {
        store_latitdue: -0.271536,
        store_longitude: -78.4648,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.28103,
        store_longitude: -78.4913599,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2040939,
        store_longitude: -78.4489768,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.401128,
        store_longitude: -78.5484,
    },

    {
        store_latitdue: -0.369959,
        store_longitude: -78.375141,
    },
    {
        store_latitdue: -0.5198,
        store_longitude: -78.580633,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.2942055,
        store_longitude: -78.409848,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.1913382,
        store_longitude: -78.383975,
    },
    {
        store_latitdue: -0.2970368,
        store_longitude: -78.4546052,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.299403,
        store_longitude: -78.4557,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2104359,
        store_longitude: -78.3862665,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2088185,
        store_longitude: -78.3994555,
    },

    {
        store_latitdue: -0.2231072,
        store_longitude: -78.4012184,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3295755,
        store_longitude: -78.4495702,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3348887,
        store_longitude: -78.4465517,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3675605,
        store_longitude: -78.3713353,
    },
    {
        store_latitdue: -0.4607761,
        store_longitude: -78.5636351,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3662139,
        store_longitude: -78.4914058,
    },
    {
        store_latitdue: -0.5020877,
        store_longitude: -78.5833963,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.2320596,
        store_longitude: -78.4961232,
    },
    {
        store_latitdue: -0.2314649,
        store_longitude: -78.4491658,
    },
    {
        store_latitdue: -0.320962,
        store_longitude: -78.4613742,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.34625,
        store_longitude: -78.4937,
    },

    {
        store_latitdue: -0.370013,
        store_longitude: -78.3751,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.35864,
        store_longitude: -78.4813067,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3749133,
        store_longitude: -78.4822389,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.2868319,
        store_longitude: -78.4818826,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.27514949,
        store_longitude: -78.4819412,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3706539,
        store_longitude: -78.3732303,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.269074,
        store_longitude: -78.4635688,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2012155,
        store_longitude: -78.37668,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3172597,
        store_longitude: -78.443555,
    },

    {
        store_latitdue: -0.3288946,
        store_longitude: -78.4740229,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.5123267,
        store_longitude: -78.5712449,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.268747,
        store_longitude: -78.4646,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2989316,
        store_longitude: -78.4665674,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3543191,
        store_longitude: -78.4633928,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3305041,
        store_longitude: -78.4265874,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3039979,
        store_longitude: -78.4366612,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3050694,
        store_longitude: -78.4718908,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.225018,
        store_longitude: -78.3924,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2324423,
        store_longitude: -78.4445612,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.6132492,
        store_longitude: -78.622717,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.4353534,
        store_longitude: -78.5422556,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.3423303,
        store_longitude: -78.4328797,
    },

    {
        store_latitdue: -0.311123,
        store_longitude: -78.4157,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.1655273,
        store_longitude: -78.4757072,
    },
    {
        store_latitdue: -0.3018767,
        store_longitude: -78.4384894,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3255262,
        store_longitude: -78.4620136,
    },
    {
        store_latitdue: -0.177393,
        store_longitude: -78.3586,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.3060344,
        store_longitude: -78.4866235,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2886235,
        store_longitude: -78.4392403,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.472621,
        store_longitude: -78.58241,
    },
    {
        store_latitdue: -0.3753654,
        store_longitude: -78.5057938,
    },
    {
        store_latitdue: -0.263075,
        store_longitude: -78.486956,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2692067,
        store_longitude: -78.484917,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.269946,
        store_longitude: -78.4641,
    },
    {
        store_latitdue: -0.3622433,
        store_longitude: -78.4233114,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3283822,
        store_longitude: -78.4449835,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.32902275,
        store_longitude: -78.4695998,
    },

    {
        store_latitdue: -0.4099549,
        store_longitude: -78.5471259,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.5057834,
        store_longitude: -78.5688458,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3315194,
        store_longitude: -78.4368601,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.4664169,
        store_longitude: -78.5838639,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.2916211,
        store_longitude: -78.4772461,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3327441,
        store_longitude: -78.4358179,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.5211975,
        store_longitude: -78.5736537,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2479953,
        store_longitude: -78.4822539,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.346949,
        store_longitude: -78.4301993,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.334692,
        store_longitude: -78.4447281,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.51089298,
        store_longitude: -78.5747451,
    },
    {
        store_latitdue: -0.21688524,
        store_longitude: -78.3848692,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2709149,
        store_longitude: -78.4644359,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2795039,
        store_longitude: -78.4704494,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3255351,
        store_longitude: -78.4490266,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3735954,
        store_longitude: -78.4730758,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2764857,
        store_longitude: -78.479789,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2163172,
        store_longitude: -78.3740934,
    },
    {
        store_latitdue: -0.3416459,
        store_longitude: -78.3851791,
    },
    {
        store_latitdue: -0.4121233,
        store_longitude: -78.5518497,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.329242,
        store_longitude: -78.4558393,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.336483,
        store_longitude: -78.4682,
    },
    {
        store_latitdue: -0.3414302,
        store_longitude: -78.4320602,
    },
    {
        store_latitdue: -0.197478,
        store_longitude: -78.43767,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.5116245,
        store_longitude: -78.5668429,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.3814178,
        store_longitude: -78.5058051,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3800774,
        store_longitude: -78.50041,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2919902,
        store_longitude: -78.465628,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3559015,
        store_longitude: -78.4381103,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.353747,
        store_longitude: -78.475606,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.4685539,
        store_longitude: -78.5867771,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.2990229,
        store_longitude: -78.4788714,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3343972,
        store_longitude: -78.4569693,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3285599,
        store_longitude: -78.4266449,
    },
    {
        store_latitdue: -0.3833249,
        store_longitude: -78.5102025,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3345818,
        store_longitude: -78.4665314,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -2.18622,
        store_longitude: -79.8229,
    },
    {
        store_latitdue: -0.255864,
        store_longitude: -78.5346445,
    },

    {
        store_latitdue: -0.3127034,
        store_longitude: -78.4802297,
    },

    {
        store_latitdue: -0.282916,
        store_longitude: -79.1753,
    },
    {
        store_latitdue: -0.5101997,
        store_longitude: -78.5692465,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.5243535,
        store_longitude: -78.5559341,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2930076,
        store_longitude: -78.4855943,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.5096613,
        store_longitude: -78.5678205,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3454148,
        store_longitude: -78.4442322,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.288057,
        store_longitude: -78.3994104,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2132443,
        store_longitude: -78.3903439,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3219992,
        store_longitude: -78.4604502,
    },
    {
        store_latitdue: -0.3386727,
        store_longitude: -78.4531912,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3311405,
        store_longitude: -78.4332131,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3285746,
        store_longitude: -78.4480326,
    },
    {
        store_latitdue: -0.3489854215,
        store_longitude: -78.4327697754,
    },
    {
        store_latitdue: -0.410246,
        store_longitude: -78.5529,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.3343772,
        store_longitude: -78.4423021,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2954083,
        store_longitude: -78.442807,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3885135,
        store_longitude: -78.509034,
    },
    {
        store_latitdue: -0.28045065,
        store_longitude: -78.4732925,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2170594,
        store_longitude: -78.3705823,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3379613,
        store_longitude: -78.4749794,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3126543,
        store_longitude: -78.4612308,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.279064,
        store_longitude: -78.4938905,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.298566,
        store_longitude: -78.4381524,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3711717,
        store_longitude: -78.3732783,
    },
    {
        store_latitdue: -0.4133117,
        store_longitude: -78.5532983,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.5054321,
        store_longitude: -78.5661139,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.289875,
        store_longitude: -78.47419,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.346282,
        store_longitude: -78.4937,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3319806,
        store_longitude: -78.4472056,
    },
    {
        store_latitdue: -0.2178518,
        store_longitude: -78.4008627,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3368151,
        store_longitude: -78.447484,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.37483626,
        store_longitude: -78.5052185,
    },
    {
        store_latitdue: -0.26299,
        store_longitude: -78.4841,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.268094,
        store_longitude: -78.463021,
    },

    {
        store_latitdue: -0.2943379524,
        store_longitude: -78.4369429573,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3373991,
        store_longitude: -78.4413433,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.4771412,
        store_longitude: -78.5851375,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.2867309,
        store_longitude: -78.4890075,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.25979885,
        store_longitude: -78.4667968,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2736916,
        store_longitude: -78.4648483,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.22597566,
        store_longitude: -78.4291915,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.218409,
        store_longitude: -78.402519,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.32584,
        store_longitude: -78.547,
    },

    {
        store_latitdue: -0.267955,
        store_longitude: -78.4632,
    },
    {
        store_latitdue: -0.2663698,
        store_longitude: -78.4801561,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2177572,
        store_longitude: -78.3895776,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2322095,
        store_longitude: -78.4499991,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.1748886,
        store_longitude: -78.4805973,
    },
    {
        store_latitdue: -0.503056,
        store_longitude: -78.573688,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.5974552,
        store_longitude: -78.6356297,
        neighborhood: 'EL CHAUPI',
    },

    {
        store_latitdue: -0.3407756,
        store_longitude: -78.4506768,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2016888,
        store_longitude: -78.431017,
    },
    {
        store_latitdue: -0.2124483,
        store_longitude: -78.3876814,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.5189371,
        store_longitude: -78.5842458,
        neighborhood: 'ALOASÍ',
    },

    {
        store_latitdue: -0.5148078,
        store_longitude: -78.5671662,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.4116561,
        store_longitude: -78.5502924,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.3093803,
        store_longitude: -78.4560501,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3619456,
        store_longitude: -78.4133867,
    },
    {
        store_latitdue: -0.29813516,
        store_longitude: -78.4396209,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3103687,
        store_longitude: -78.4106866,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.303873,
        store_longitude: -78.4533,
    },
    {
        store_latitdue: -0.4085103,
        store_longitude: -78.5464321,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.5175672,
        store_longitude: -78.5727563,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5705731,
        store_longitude: -78.5970494,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.4662519,
        store_longitude: -78.5848027,
        neighborhood: 'ALOAG',
    },

    {
        store_latitdue: -0.508764,
        store_longitude: -78.5651,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.41217,
        store_longitude: -78.551943,
    },
    {
        store_latitdue: -0.51831,
        store_longitude: -78.566556,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.3763117,
        store_longitude: -78.5087283,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3690917,
        store_longitude: -78.474311,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.26459,
        store_longitude: -78.478,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.205572,
        store_longitude: -78.4469,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.2327023,
        store_longitude: -78.4447557,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2275511,
        store_longitude: -78.3990502,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.1760378,
        store_longitude: -78.3969691,
    },
    {
        store_latitdue: -0.2293705,
        store_longitude: -78.4491946,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2105255,
        store_longitude: -78.3830838,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.099223,
        store_longitude: -78.5201378,
    },
    {
        store_latitdue: -0.2280307,
        store_longitude: -78.5065407,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3398,
        store_longitude: -78.4831,
    },
    {
        store_latitdue: -0.346666,
        store_longitude: -78.4849,
    },
    {
        store_latitdue: -0.3465842,
        store_longitude: -78.4849849,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.342628,
        store_longitude: -78.4849,
    },
    {
        store_latitdue: -0.3283045,
        store_longitude: -78.4736574,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.373708,
        store_longitude: -78.3733,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.2981593,
        store_longitude: -78.406564,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3242572,
        store_longitude: -78.4644533,
    },
    {
        store_latitdue: -0.321676,
        store_longitude: -78.4623,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3464265,
        store_longitude: -78.485212,
    },
    {
        store_latitdue: -0.320049,
        store_longitude: -78.4642,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.319171,
        store_longitude: -78.460013,
    },
    {
        store_latitdue: -0.3049561,
        store_longitude: -78.4586524,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.329098,
        store_longitude: -78.44628,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3277492,
        store_longitude: -78.4464835,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2956379,
        store_longitude: -78.474388,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2951031,
        store_longitude: -78.4752449,
    },
    {
        store_latitdue: -0.3333281,
        store_longitude: -78.4431005,
    },
    {
        store_latitdue: -0.3067802,
        store_longitude: -78.4962445,
    },
    {
        store_latitdue: -0.3315838,
        store_longitude: -78.4517942,
    },
    {
        store_latitdue: -0.3291273,
        store_longitude: -78.4486418,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3281294,
        store_longitude: -78.4488293,
    },
    {
        store_latitdue: -0.327851,
        store_longitude: -78.447701,
    },
    {
        store_latitdue: -2.1249,
        store_longitude: -80.0059,
    },
    {
        store_latitdue: -0.334828,
        store_longitude: -78.467516,
    },

    {
        store_latitdue: -0.340031,
        store_longitude: -78.454944,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.341278,
        store_longitude: -78.454,
    },
    {
        store_latitdue: -0.345927,
        store_longitude: -78.441509,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.394436,
        store_longitude: -78.43319,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.347046,
        store_longitude: -78.444565,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.368374,
        store_longitude: -78.4531,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.346297,
        store_longitude: -78.442209,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.34019,
        store_longitude: -78.4479,
    },
    {
        store_latitdue: -0.338194,
        store_longitude: -78.445089,
    },

    {
        store_latitdue: -0.384308,
        store_longitude: -78.408625,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2013547,
        store_longitude: -78.3754646,
    },
    {
        store_latitdue: -0.197081,
        store_longitude: -78.431,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.19417,
        store_longitude: -78.3966,
    },
    {
        store_latitdue: -0.1821334,
        store_longitude: -78.3850729,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.211134,
        store_longitude: -78.42656,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2123126,
        store_longitude: -78.4057005,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.220144,
        store_longitude: -78.380104,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2121857,
        store_longitude: -78.404006,
    },
    {
        store_latitdue: -0.2091552,
        store_longitude: -78.401616,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.211148,
        store_longitude: -78.402544,
    },
    {
        store_latitdue: -0.211564,
        store_longitude: -78.387224,
    },
    {
        store_latitdue: -0.203671,
        store_longitude: -78.376844,
    },
    {
        store_latitdue: -0.204304,
        store_longitude: -78.377663,
    },
    {
        store_latitdue: -0.199159,
        store_longitude: -78.431815,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.202292,
        store_longitude: -78.430473,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.198252,
        store_longitude: -78.4367,
    },
    {
        store_latitdue: -0.2148966,
        store_longitude: -78.406096,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.212158,
        store_longitude: -78.405887,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.327143,
        store_longitude: -78.449784,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2159982,
        store_longitude: -78.4042129,
    },
    {
        store_latitdue: -0.2116327,
        store_longitude: -78.3850033,
    },
    {
        store_latitdue: -0.2199228,
        store_longitude: -78.4210714,
    },
    {
        store_latitdue: -0.2136778,
        store_longitude: -78.4050837,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.231377,
        store_longitude: -78.449288,
    },
    {
        store_latitdue: -0.201899,
        store_longitude: -78.4309,
    },
    {
        store_latitdue: -0.263182,
        store_longitude: -78.487883,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.291906,
        store_longitude: -78.481551,
    },

    {
        store_latitdue: -0.293555,
        store_longitude: -78.477118,
    },
    {
        store_latitdue: -0.2748172,
        store_longitude: -78.4702085,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.276563,
        store_longitude: -78.4704,
    },

    {
        store_latitdue: -0.280913,
        store_longitude: -78.4646,
    },
    {
        store_latitdue: -0.201356,
        store_longitude: -78.4327,
    },

    {
        store_latitdue: -0.201628,
        store_longitude: -78.431775,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.321324,
        store_longitude: -78.460404,
    },

    {
        store_latitdue: -0.2120244,
        store_longitude: -78.3729752,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2127503,
        store_longitude: -78.4033467,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.211965,
        store_longitude: -78.405796,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2124305,
        store_longitude: -78.4033031,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.220212,
        store_longitude: -78.442069,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2173307,
        store_longitude: -78.4547385,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2158436,
        store_longitude: -78.404333,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3946918,
        store_longitude: -78.3731637,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3241376,
        store_longitude: -78.449476,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.291893,
        store_longitude: -78.4566,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3293833,
        store_longitude: -78.4288595,
    },
    {
        store_latitdue: -0.326334,
        store_longitude: -78.4622,
    },
    {
        store_latitdue: -0.3086471,
        store_longitude: -78.451254,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.298793,
        store_longitude: -78.4281,
    },
    {
        store_latitdue: -0.3013507,
        store_longitude: -78.4591161,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2987906,
        store_longitude: -78.4550959,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.251687,
        store_longitude: -78.480263,
    },
    {
        store_latitdue: -0.288942,
        store_longitude: -78.490668,
    },
    {
        store_latitdue: -0.290347,
        store_longitude: -78.48084,
    },
    {
        store_latitdue: -0.292892,
        store_longitude: -78.4768,
    },
    {
        store_latitdue: -0.283846,
        store_longitude: -78.485355,
    },

    {
        store_latitdue: -0.320846,
        store_longitude: -78.4381321,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.329923,
        store_longitude: -78.432611,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.275344,
        store_longitude: -78.4735,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.280129,
        store_longitude: -78.471288,
    },

    {
        store_latitdue: -0.3212581,
        store_longitude: -78.4867181,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.322005,
        store_longitude: -78.4390081,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.309977,
        store_longitude: -78.4717,
    },
    {
        store_latitdue: -0.303331,
        store_longitude: -78.4144152,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2961,
        store_longitude: -78.4411,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.312316,
        store_longitude: -78.4000746,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3752801,
        store_longitude: -78.4092597,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.1394539,
        store_longitude: -78.4966785,
    },
    {
        store_latitdue: -0.29958,
        store_longitude: -78.458947,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.520542,
        store_longitude: -78.577598,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.3844471,
        store_longitude: -78.4888739,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.414141,
        store_longitude: -78.551282,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.384131,
        store_longitude: -78.503671,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.380073,
        store_longitude: -78.497448,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.373934,
        store_longitude: -78.509374,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3813304,
        store_longitude: -78.4884174,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3808768,
        store_longitude: -78.4910324,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.387967,
        store_longitude: -78.50974,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.531993,
        store_longitude: -78.5649,
    },
    {
        store_latitdue: -0.37106,
        store_longitude: -78.485251,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.51602,
        store_longitude: -78.5791,
    },
    {
        store_latitdue: -0.380145,
        store_longitude: -78.494835,
    },
    {
        store_latitdue: -0.375305,
        store_longitude: -78.475,
    },
    {
        store_latitdue: -0.503625,
        store_longitude: -78.566404,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.373364,
        store_longitude: -78.4790989,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.525409,
        store_longitude: -78.554623,
    },
    {
        store_latitdue: -0.402162,
        store_longitude: -78.546613,
    },
    {
        store_latitdue: -0.404188,
        store_longitude: -78.542419,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.409955,
        store_longitude: -78.546868,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.38227,
        store_longitude: -78.500196,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.403238,
        store_longitude: -78.535246,
    },
    {
        store_latitdue: -0.383,
        store_longitude: -78.510962,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.403156,
        store_longitude: -78.4317,
    },
    {
        store_latitdue: -0.35789,
        store_longitude: -78.469696,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.40187,
        store_longitude: -78.531799,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.204226,
        store_longitude: -78.37755,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3778109,
        store_longitude: -78.4994533,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.39671,
        store_longitude: -78.53071,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.530068,
        store_longitude: -78.566,
    },
    {
        store_latitdue: -0.401054,
        store_longitude: -78.548213,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.513468,
        store_longitude: -78.567744,
    },
    {
        store_latitdue: -0.269114,
        store_longitude: -78.540676,
    },
    {
        store_latitdue: -0.365909,
        store_longitude: -78.4937,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.513787,
        store_longitude: -78.575496,
    },

    {
        store_latitdue: -0.163061,
        store_longitude: -78.32134,
    },
    {
        store_latitdue: -0.3747866,
        store_longitude: -78.4846223,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.466574,
        store_longitude: -78.583964,
        neighborhood: 'ALOAG',
    },

    {
        store_latitdue: -0.516707,
        store_longitude: -78.579377,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.466619,
        store_longitude: -78.585442,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.514278,
        store_longitude: -78.5673,
    },
    {
        store_latitdue: -0.499909,
        store_longitude: -78.555,
    },
    {
        store_latitdue: -0.3677425,
        store_longitude: -78.4998639,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.368446,
        store_longitude: -78.499344,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.367442,
        store_longitude: -78.498418,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.531145,
        store_longitude: -78.562466,
    },
    {
        store_latitdue: -0.52996,
        store_longitude: -78.5597,
    },
    {
        store_latitdue: -0.527991,
        store_longitude: -78.556863,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.4689513,
        store_longitude: -78.5882843,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.340454,
        store_longitude: -78.4707,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3729723,
        store_longitude: -78.4517006,
    },

    {
        store_latitdue: -0.2167539,
        store_longitude: -78.41788,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3313418,
        store_longitude: -78.4646802,
    },

    {
        store_latitdue: -0.315575,
        store_longitude: -78.5525739,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.5104727,
        store_longitude: -78.5729259,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5204271,
        store_longitude: -78.5726502,
    },
    {
        store_latitdue: -0.2799611,
        store_longitude: -78.495467,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.38925,
        store_longitude: -78.52634,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.280147,
        store_longitude: -78.498911,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.335228,
        store_longitude: -78.4661,
    },
    {
        store_latitdue: -0.3252526,
        store_longitude: -78.4189912,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3274448,
        store_longitude: -78.496742,
    },
    {
        store_latitdue: -0.5045855,
        store_longitude: -78.5588308,
    },
    {
        store_latitdue: -0.3317244,
        store_longitude: -78.4484362,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.4118691,
        store_longitude: -78.5518413,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.320504,
        store_longitude: -78.462414,
    },
    {
        store_latitdue: -0.331887,
        store_longitude: -78.4471,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2083202,
        store_longitude: -78.3917148,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.4489987,
        store_longitude: -78.5590372,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5060809,
        store_longitude: -78.5603409,
    },
    {
        store_latitdue: -0.358822,
        store_longitude: -78.4809,
    },

    {
        store_latitdue: -0.32671,
        store_longitude: -78.4751,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.319703,
        store_longitude: -78.4626,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.5085283,
        store_longitude: -78.5421767,
    },

    {
        store_latitdue: -0.366791,
        store_longitude: -78.5001,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.264446,
        store_longitude: -78.475,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.34228,
        store_longitude: -78.48497,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3448312,
        store_longitude: -78.4406838,
    },
    {
        store_latitdue: -0.5025044,
        store_longitude: -78.566928,
    },
    {
        store_latitdue: -0.2057742,
        store_longitude: -78.3771165,
    },
    {
        store_latitdue: -0.29904,
        store_longitude: -78.4551,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.529381,
        store_longitude: -78.562633,
    },

    {
        store_latitdue: -0.3143105,
        store_longitude: -78.4414491,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.5086702,
        store_longitude: -78.5695571,
    },

    {
        store_latitdue: -0.092623,
        store_longitude: -78.451184,
    },

    {
        store_latitdue: -0.508487,
        store_longitude: -78.568415,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.60178,
        store_longitude: -78.6419,
        neighborhood: 'EL CHAUPI',
    },
    {
        store_latitdue: -0.334069461,
        store_longitude: -78.44432068,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.364789,
        store_longitude: -78.370413,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.21875,
        store_longitude: -78.4112032,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.5222436,
        store_longitude: -78.5834334,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.5156973,
        store_longitude: -78.5682999,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3416195,
        store_longitude: -78.4845402,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3360368,
        store_longitude: -78.4714056,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3264805,
        store_longitude: -78.5582121,
    },
    {
        store_latitdue: -0.338251,
        store_longitude: -78.4405,
    },
    {
        store_latitdue: -0.332563,
        store_longitude: -78.473609,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.220962517,
        store_longitude: -78.37500302,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.571703,
        store_longitude: -78.59459,
    },

    {
        store_latitdue: -0.501899,
        store_longitude: -78.5736691,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.3040622,
        store_longitude: -78.4174441,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.26441,
        store_longitude: -78.4754,
    },
    {
        store_latitdue: -0.217679,
        store_longitude: -78.4118,
    },

    {
        store_latitdue: -0.2058819,
        store_longitude: -78.3803648,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3775859,
        store_longitude: -78.3740281,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.2469365,
        store_longitude: -78.4829715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229455,
        store_longitude: -78.4088,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3955999,
        store_longitude: -78.5297863,
        neighborhood: 'UYUMBICHO',
    },

    {
        store_latitdue: -0.33795,
        store_longitude: -78.548,
    },
    {
        store_latitdue: -0.3860888,
        store_longitude: -78.5223186,
    },
    {
        store_latitdue: -0.5186658,
        store_longitude: -78.5664247,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2989388,
        store_longitude: -78.4720868,
    },
    {
        store_latitdue: -0.3792851,
        store_longitude: -78.4986004,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.318367,
        store_longitude: -78.5519,
    },
    {
        store_latitdue: -0.2040346,
        store_longitude: -78.4472425,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.4663128,
        store_longitude: -78.5848832,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.2864246,
        store_longitude: -78.4466982,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.365915,
        store_longitude: -78.4926,
    },
    {
        store_latitdue: -0.291257,
        store_longitude: -78.4848,
    },

    {
        store_latitdue: -0.2613504,
        store_longitude: -78.44998,
    },
    {
        store_latitdue: -0.5219675,
        store_longitude: -78.5705302,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.279869,
        store_longitude: -78.4755787,
    },
    {
        store_latitdue: -0.5069912,
        store_longitude: -78.5621259,
    },

    {
        store_latitdue: -0.319232,
        store_longitude: -78.5721,
    },
    {
        store_latitdue: -0.326056,
        store_longitude: -78.418,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.325471,
        store_longitude: -78.4202255,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.283453,
        store_longitude: -78.4874,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.320834,
        store_longitude: -78.4603,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2866611,
        store_longitude: -78.4737477,
    },
    {
        store_latitdue: -0.3114496,
        store_longitude: -78.5341407,
    },
    {
        store_latitdue: -0.290209,
        store_longitude: -78.4290665,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2163435,
        store_longitude: -78.374142,
    },
    {
        store_latitdue: -0.2925118,
        store_longitude: -78.4880647,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3664586,
        store_longitude: -78.4352435,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.320164,
        store_longitude: -78.4605,
    },
    {
        store_latitdue: -0.329591,
        store_longitude: -78.4545,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3131487,
        store_longitude: -78.4785508,
    },
    {
        store_latitdue: -0.2406697,
        store_longitude: -78.4836513,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.34504,
        store_longitude: -78.431,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.286137,
        store_longitude: -78.4371,
    },
    {
        store_latitdue: -0.208009,
        store_longitude: -78.384003,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.5013966,
        store_longitude: -78.5663684,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3261087,
        store_longitude: -78.4485011,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2032704,
        store_longitude: -78.4475351,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.287821,
        store_longitude: -78.4391,
    },

    {
        store_latitdue: -0.257434,
        store_longitude: -78.4515,
        neighborhood: 'GUANGOPOLO',
    },

    {
        store_latitdue: -0.5138891,
        store_longitude: -78.5646989,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.2257042,
        store_longitude: -78.4477296,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.187926,
        store_longitude: -78.3795574,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3202673,
        store_longitude: -78.4602263,
    },
    {
        store_latitdue: -0.377016,
        store_longitude: -78.5075,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.388331,
        store_longitude: -78.509331,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.387431,
        store_longitude: -78.524,
    },
    {
        store_latitdue: -0.325606,
        store_longitude: -78.4619,
    },
    {
        store_latitdue: -0.525791,
        store_longitude: -78.554469,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.5284205,
        store_longitude: -78.5804964,
    },
    {
        store_latitdue: -0.3074608,
        store_longitude: -78.3977902,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3649158,
        store_longitude: -78.4382127,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.307578,
        store_longitude: -78.3973341,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.206956,
        store_longitude: -78.3981,
    },
    {
        store_latitdue: -0.373925,
        store_longitude: -78.4199,
    },
    {
        store_latitdue: -0.263471,
        store_longitude: -78.4794112,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.294393,
        store_longitude: -78.4777,
    },
    {
        store_latitdue: -0.365163,
        store_longitude: -78.3722784,
    },
    {
        store_latitdue: -0.527833,
        store_longitude: -78.5567,
    },
    {
        store_latitdue: -0.299448,
        store_longitude: -78.4419,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.265881,
        store_longitude: -78.4716,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.244649,
        store_longitude: -78.5155,
    },
    {
        store_latitdue: -0.337151,
        store_longitude: -78.4415,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.1992881,
        store_longitude: -78.3812726,
    },
    {
        store_latitdue: -0.512586,
        store_longitude: -78.5693233,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.364431,
        store_longitude: -78.4363,
    },
    {
        store_latitdue: -0.3815737,
        store_longitude: -78.5098943,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.20907,
        store_longitude: -78.3877,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3768347,
        store_longitude: -78.504839,
    },
    {
        store_latitdue: -0.34788,
        store_longitude: -78.42999,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.373539,
        store_longitude: -78.509244,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3073748,
        store_longitude: -78.4878647,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.330288,
        store_longitude: -78.4265,
    },
    {
        store_latitdue: -0.3746582,
        store_longitude: -78.5095193,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.505972,
        store_longitude: -78.559452,
    },
    {
        store_latitdue: -0.5055775,
        store_longitude: -78.5581069,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.3409281,
        store_longitude: -78.3828219,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.264428,
        store_longitude: -78.4753,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3848639,
        store_longitude: -78.5111172,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3759654,
        store_longitude: -78.4866286,
    },
    {
        store_latitdue: -0.376325,
        store_longitude: -78.488,
    },
    {
        store_latitdue: -0.288031,
        store_longitude: -78.4926,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.327986,
        store_longitude: -78.4726,
    },
    {
        store_latitdue: -0.156001,
        store_longitude: -78.47635,
    },
    {
        store_latitdue: -0.466351,
        store_longitude: -78.5857741,
        neighborhood: 'ALOAG',
    },

    {
        store_latitdue: -0.450243,
        store_longitude: -78.559276,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.263646,
        store_longitude: -78.498603,
    },
    {
        store_latitdue: -0.2900306,
        store_longitude: -78.4806576,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.308231,
        store_longitude: -78.4137,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3290342,
        store_longitude: -78.4468136,
    },

    {
        store_latitdue: -0.3669105,
        store_longitude: -78.4911114,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3833267,
        store_longitude: -78.5002768,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2125998,
        store_longitude: -78.4042993,
    },
    {
        store_latitdue: -0.5172304,
        store_longitude: -78.5837653,
    },
    {
        store_latitdue: -0.5674575,
        store_longitude: -78.5915195,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.34359,
        store_longitude: -78.4472,
    },
    {
        store_latitdue: -0.3083972,
        store_longitude: -78.4526629,
    },
    {
        store_latitdue: -0.291168,
        store_longitude: -78.4658,
    },
    {
        store_latitdue: -0.34764,
        store_longitude: -78.43306,
    },
    {
        store_latitdue: -0.3339068,
        store_longitude: -78.4409102,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.306655,
        store_longitude: -78.418294,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.263964,
        store_longitude: -78.464953,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.268035,
        store_longitude: -78.463155,
    },
    {
        store_latitdue: -0.284663,
        store_longitude: -78.4839115,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.345802,
        store_longitude: -78.4307,
    },
    {
        store_latitdue: -0.249821,
        store_longitude: -78.443165,
    },
    {
        store_latitdue: -0.227932,
        store_longitude: -78.448244,
    },
    {
        store_latitdue: -0.214309,
        store_longitude: -78.4028,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.226015,
        store_longitude: -78.3976,
    },
    {
        store_latitdue: -0.222013,
        store_longitude: -78.371421,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.211296,
        store_longitude: -78.4025,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2119738,
        store_longitude: -78.4048824,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2140786,
        store_longitude: -78.4051766,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2908876,
        store_longitude: -78.4740455,
    },
    {
        store_latitdue: -0.291876,
        store_longitude: -78.4768,
    },
    {
        store_latitdue: -0.281192,
        store_longitude: -78.47059,
    },
    {
        store_latitdue: -0.268033,
        store_longitude: -78.4631,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.287201,
        store_longitude: -78.4418,
    },

    {
        store_latitdue: -0.324992,
        store_longitude: -78.4190515,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.30857,
        store_longitude: -78.415183,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.376851,
        store_longitude: -78.3741,
    },

    {
        store_latitdue: -0.3113781,
        store_longitude: -78.4729781,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.320868,
        store_longitude: -78.4388,
    },
    {
        store_latitdue: -0.331859,
        store_longitude: -78.4894,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.327806,
        store_longitude: -78.4479,
    },

    {
        store_latitdue: -0.328593,
        store_longitude: -78.4467,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.327143,
        store_longitude: -78.449784,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.331473,
        store_longitude: -78.4544556,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.32281,
        store_longitude: -78.4601,
    },
    {
        store_latitdue: -0.3460505,
        store_longitude: -78.4443756,
    },
    {
        store_latitdue: -0.3884639,
        store_longitude: -78.5250345,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.2498705,
        store_longitude: -78.5291706,
    },

    {
        store_latitdue: -0.359637,
        store_longitude: -78.4398,
    },
    {
        store_latitdue: -0.353537,
        store_longitude: -78.4373,
    },
    {
        store_latitdue: -0.5042142,
        store_longitude: -78.5712821,
    },
    {
        store_latitdue: -0.517992,
        store_longitude: -78.571948,
    },
    {
        store_latitdue: -0.312368,
        store_longitude: -78.4746,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.334426,
        store_longitude: -78.3559,
    },
    {
        store_latitdue: -0.298471,
        store_longitude: -78.4454,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.330056,
        store_longitude: -78.43173,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3383272,
        store_longitude: -78.3514473,
    },
    {
        store_latitdue: -0.340793,
        store_longitude: -78.3828891,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.335579,
        store_longitude: -78.3837,
    },

    {
        store_latitdue: -0.3384492,
        store_longitude: -78.352491,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3202055,
        store_longitude: -78.4634303,
    },
    {
        store_latitdue: -0.32781,
        store_longitude: -78.4503,
    },
    {
        store_latitdue: -0.336679,
        store_longitude: -78.46663,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.338055,
        store_longitude: -78.4454,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.374621,
        store_longitude: -78.460964,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.338517,
        store_longitude: -78.482102,
    },
    {
        store_latitdue: -0.301302,
        store_longitude: -78.482138,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2801935,
        store_longitude: -78.4928058,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.294266,
        store_longitude: -78.479056,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2924429,
        store_longitude: -78.4886386,
    },

    {
        store_latitdue: -0.208365,
        store_longitude: -78.395882,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2181329,
        store_longitude: -78.4115446,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2184535,
        store_longitude: -78.3845434,
    },
    {
        store_latitdue: -0.214092,
        store_longitude: -78.40406,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.1805315,
        store_longitude: -78.3936405,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2116687,
        store_longitude: -78.4028838,
    },
    {
        store_latitdue: -0.208558,
        store_longitude: -78.374,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.292531,
        store_longitude: -78.465508,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.337514,
        store_longitude: -78.465793,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.292343,
        store_longitude: -78.4006,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.3338595,
        store_longitude: -78.4159274,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.5037858,
        store_longitude: -78.5512567,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.271573,
        store_longitude: -78.46511,
    },

    {
        store_latitdue: -0.214497,
        store_longitude: -78.4051,
    },

    {
        store_latitdue: -0.2946092,
        store_longitude: -78.4777883,
    },
    {
        store_latitdue: -0.2228484,
        store_longitude: -78.3789901,
    },

    {
        store_latitdue: -0.317134,
        store_longitude: -78.460349,
    },
    {
        store_latitdue: -0.294584,
        store_longitude: -78.4577,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.331924,
        store_longitude: -78.4246,
    },

    {
        store_latitdue: -0.6165855,
        store_longitude: -78.6289035,
    },
    {
        store_latitdue: -0.3762298,
        store_longitude: -78.4953958,
    },
    {
        store_latitdue: -0.25192,
        store_longitude: -78.4802,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.186688,
        store_longitude: -78.3907,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2237979,
        store_longitude: -78.5133206,
    },
    {
        store_latitdue: -0.337126,
        store_longitude: -78.4134,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.324943,
        store_longitude: -78.4694,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.32182,
        store_longitude: -78.46576,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.331171,
        store_longitude: -78.4704,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.308256,
        store_longitude: -78.452977,
    },
    {
        store_latitdue: -0.303767,
        store_longitude: -78.4605,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3691371,
        store_longitude: -78.3722028,
    },
    {
        store_latitdue: -0.403327,
        store_longitude: -78.5438588,
    },
    {
        store_latitdue: -0.196071,
        store_longitude: -78.3842,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2172,
        store_longitude: -78.4016,
    },
    {
        store_latitdue: -0.210461,
        store_longitude: -78.3864,
    },
    {
        store_latitdue: -0.373796,
        store_longitude: -78.4199,
    },

    {
        store_latitdue: -0.309459,
        store_longitude: -78.4621,
    },

    {
        store_latitdue: -0.3769324,
        store_longitude: -78.5057045,
    },

    {
        store_latitdue: -0.34089,
        store_longitude: -78.466,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3463802,
        store_longitude: -78.4108864,
    },
    {
        store_latitdue: -0.3768617,
        store_longitude: -78.505919,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3552617,
        store_longitude: -78.45827,
    },
    {
        store_latitdue: -0.386583,
        store_longitude: -78.5038,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.278529,
        store_longitude: -78.4708,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.282142,
        store_longitude: -78.485829,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.345861,
        store_longitude: -78.4307,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.224601,
        store_longitude: -78.3772,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2057948,
        store_longitude: -78.3772534,
    },
    {
        store_latitdue: -0.6164714,
        store_longitude: -78.6286667,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.337801,
        store_longitude: -78.3516,
    },
    {
        store_latitdue: -0.3381,
        store_longitude: -78.4558,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2185353,
        store_longitude: -78.3757458,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3666166,
        store_longitude: -78.4902938,
    },

    {
        store_latitdue: -0.300726,
        store_longitude: -78.464,
    },
    {
        store_latitdue: -0.2994431,
        store_longitude: -78.4792119,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.370724,
        store_longitude: -78.3734,
    },
    {
        store_latitdue: -0.276158,
        store_longitude: -78.4746,
    },
    {
        store_latitdue: -0.297864,
        store_longitude: -78.4808,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0.0400758,
        store_longitude: -78.1458169,
    },
    {
        store_latitdue: -0.3538605,
        store_longitude: -78.4761073,
    },
    {
        store_latitdue: -0.361219,
        store_longitude: -78.4228,
    },

    {
        store_latitdue: -0.211715,
        store_longitude: -78.387559,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.1958321,
        store_longitude: -78.3838398,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3088719,
        store_longitude: -78.4512078,
    },
    {
        store_latitdue: -0.385841,
        store_longitude: -78.5129,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.27977,
        store_longitude: -78.4988,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.207946,
        store_longitude: -78.5064,
    },
    {
        store_latitdue: -0.377,
        store_longitude: -78.4517,
        neighborhood: 'COTOGCHOA',
    },

    {
        store_latitdue: -0.2022072,
        store_longitude: -78.4303714,
    },
    {
        store_latitdue: -0.309272,
        store_longitude: -78.452596,
    },
    {
        store_latitdue: -0.347851,
        store_longitude: -78.43,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2799958,
        store_longitude: -78.496795,
    },
    {
        store_latitdue: -0.216655,
        store_longitude: -78.4368,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.333188,
        store_longitude: -78.4438,
    },
    {
        store_latitdue: -0.2904515,
        store_longitude: -78.4458862,
    },

    {
        store_latitdue: -0.2251982,
        store_longitude: -78.396378,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.356835,
        store_longitude: -78.4045426,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.33179,
        store_longitude: -78.4244,
    },
    {
        store_latitdue: -0.410207,
        store_longitude: -78.552904,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.345238,
        store_longitude: -78.4372,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3264153,
        store_longitude: -78.4499143,
    },
    {
        store_latitdue: -0.128564,
        store_longitude: -78.5139,
    },
    {
        store_latitdue: -0.163392,
        store_longitude: -78.463407,
    },
    {
        store_latitdue: -0.194296,
        store_longitude: -78.3966,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.615271,
        store_longitude: -78.6262,
    },
    {
        store_latitdue: -0.380253,
        store_longitude: -78.4992,
    },
    {
        store_latitdue: -0.2931139,
        store_longitude: -78.4779869,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.285678,
        store_longitude: -78.497443,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.000839,
        store_longitude: -78.451232,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.5295129,
        store_longitude: -78.5803003,
    },

    {
        store_latitdue: -0.374747,
        store_longitude: -78.4875,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3657291,
        store_longitude: -78.4749094,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.28949,
        store_longitude: -78.4886,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.377517,
        store_longitude: -78.5035,
    },
    {
        store_latitdue: -0.433579,
        store_longitude: -78.540095,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.33819,
        store_longitude: -78.456085,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.280733,
        store_longitude: -78.4652,
    },

    {
        store_latitdue: -0.2726908,
        store_longitude: -78.4457612,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2453696,
        store_longitude: -78.4839983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208533,
        store_longitude: -78.3929,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.5184078,
        store_longitude: -78.5734695,
    },
    {
        store_latitdue: -0.2790147,
        store_longitude: -78.4955815,
    },
    {
        store_latitdue: -0.2157701,
        store_longitude: -78.4068806,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2149159,
        store_longitude: -78.4064195,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.37047,
        store_longitude: -78.375,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.512409,
        store_longitude: -78.567216,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.374656,
        store_longitude: -78.4774,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.215695,
        store_longitude: -78.4288397,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.335276,
        store_longitude: -78.4845,
    },
    {
        store_latitdue: -0.299367,
        store_longitude: -78.4425,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.37275,
        store_longitude: -78.3744,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.113838,
        store_longitude: -78.4319,
    },
    {
        store_latitdue: -0.320084,
        store_longitude: -78.4602,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.377736,
        store_longitude: -78.3735,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3746154,
        store_longitude: -78.4767721,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3340048,
        store_longitude: -78.4673495,
    },
    {
        store_latitdue: -0.3563446,
        store_longitude: -78.4564221,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2113598,
        store_longitude: -78.5213601,
    },
    {
        store_latitdue: -0.3128228,
        store_longitude: -78.458468,
    },
    {
        store_latitdue: -0.2084333,
        store_longitude: -78.3885086,
    },

    {
        store_latitdue: -0.5030858,
        store_longitude: -78.5883098,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.247486,
        store_longitude: -78.4826,
    },
    {
        store_latitdue: -0.298921,
        store_longitude: -78.5057,
    },
    {
        store_latitdue: -0.30692473,
        store_longitude: -78.4175186,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.31007,
        store_longitude: -78.4936,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.4069992,
        store_longitude: -78.5471257,
    },
    {
        store_latitdue: -0.3748834,
        store_longitude: -78.4813566,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3720308,
        store_longitude: -78.3733496,
    },
    {
        store_latitdue: -0.3202673,
        store_longitude: -78.4602263,
    },

    {
        store_latitdue: -0.405297,
        store_longitude: -78.5457,
    },
    {
        store_latitdue: -0.352517,
        store_longitude: -78.4413754,
    },
    {
        store_latitdue: -0.2001282,
        store_longitude: -78.3758337,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2142533,
        store_longitude: -78.4056717,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3322013,
        store_longitude: -78.4650932,
    },

    {
        store_latitdue: -0.2715772,
        store_longitude: -78.446236,
    },

    {
        store_latitdue: -0.383366,
        store_longitude: -78.5136,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.277642,
        store_longitude: -78.4795,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.341666,
        store_longitude: -78.4402,
    },

    {
        store_latitdue: -0.28641,
        store_longitude: -78.4404,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.370573,
        store_longitude: -78.375223,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.295698,
        store_longitude: -78.4529,
    },
    {
        store_latitdue: -0.309469,
        store_longitude: -78.4529,
    },
    {
        store_latitdue: -0.3264788,
        store_longitude: -78.4492793,
    },
    {
        store_latitdue: -0.300186,
        store_longitude: -78.4589,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3239898,
        store_longitude: -78.4756491,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2120037,
        store_longitude: -78.4281287,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.4112239,
        store_longitude: -78.5481898,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.5034394,
        store_longitude: -78.5551226,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.208217,
        store_longitude: -78.388857,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.280257,
        store_longitude: -78.462,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },

    {
        store_latitdue: -0.2147037,
        store_longitude: -78.381336,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2813372,
        store_longitude: -78.476597,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2081091,
        store_longitude: -78.3886857,
    },
    {
        store_latitdue: -0.310387,
        store_longitude: -78.4710015,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2290688,
        store_longitude: -78.3785833,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.286049,
        store_longitude: -78.4408,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.5084095,
        store_longitude: -78.5402387,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.515687,
        store_longitude: -78.568007,
    },
    {
        store_latitdue: -0.3744571,
        store_longitude: -78.451491,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.519862,
        store_longitude: -78.5684,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3297771,
        store_longitude: -78.4476544,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.4055187,
        store_longitude: -78.546269,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.290571,
        store_longitude: -78.506121,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.335682,
        store_longitude: -78.4713,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2941456,
        store_longitude: -78.4792162,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.226234,
        store_longitude: -78.3813,
    },
    {
        store_latitdue: -0.303784,
        store_longitude: -78.542601,
    },
    {
        store_latitdue: -0.340812,
        store_longitude: -78.4136,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.339695,
        store_longitude: -78.480575,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3347806,
        store_longitude: -78.4742635,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.304173,
        store_longitude: -78.4943,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.20461,
        store_longitude: -78.4502,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.386204,
        store_longitude: -78.5113,
    },
    {
        store_latitdue: -0.3400324,
        store_longitude: -78.4479594,
    },
    {
        store_latitdue: -0.3308439,
        store_longitude: -78.4753126,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3246955,
        store_longitude: -78.4564837,
    },
    {
        store_latitdue: -0.3002663,
        store_longitude: -78.3885784,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2981885,
        store_longitude: -78.506308,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2077185,
        store_longitude: -78.3757642,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3835461,
        store_longitude: -78.5002862,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3834266,
        store_longitude: -78.5036334,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2700027,
        store_longitude: -78.486822,
    },
    {
        store_latitdue: -0.335795,
        store_longitude: -78.467825,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2010226,
        store_longitude: -78.4325937,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.5123834,
        store_longitude: -78.5656371,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.35353,
        store_longitude: -78.4764,
    },

    {
        store_latitdue: -0.176082,
        store_longitude: -78.497,
    },
    {
        store_latitdue: -0.328098,
        store_longitude: -78.4573,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2799205,
        store_longitude: -78.4823203,
    },
    {
        store_latitdue: -0.4902861,
        store_longitude: -78.5706442,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3643395,
        store_longitude: -78.37639,
    },
    {
        store_latitdue: -0.34211,
        store_longitude: -78.454321,
    },
    {
        store_latitdue: -0.271144,
        store_longitude: -78.4663,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2044493,
        store_longitude: -78.3779421,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.337137,
        store_longitude: -78.4773,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.310229,
        store_longitude: -78.4844,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3319566,
        store_longitude: -78.487007,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.0923102,
        store_longitude: -78.4477338,
    },
    {
        store_latitdue: -0.2164646,
        store_longitude: -78.3742688,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3262176,
        store_longitude: -78.476432,
    },
    {
        store_latitdue: -0.0769124,
        store_longitude: -78.4662,
    },
    {
        store_latitdue: -0.248201,
        store_longitude: -78.4815,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246743,
        store_longitude: -78.4827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.4112352,
        store_longitude: -78.5533435,
    },
    {
        store_latitdue: -0.221093,
        store_longitude: -78.393272,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.35868,
        store_longitude: -78.4812009,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.219874,
        store_longitude: -78.4412469,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.3456616,
        store_longitude: -78.4285622,
    },
    {
        store_latitdue: -0.404237,
        store_longitude: -78.5398,
    },
    {
        store_latitdue: -0.3411095,
        store_longitude: -78.4552071,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3572841,
        store_longitude: -78.4378509,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.19551634,
        store_longitude: -78.39664,
    },
    {
        store_latitdue: -0.320415,
        store_longitude: -78.4623,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.292143,
        store_longitude: -78.47,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3693262,
        store_longitude: -78.4963129,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.294761,
        store_longitude: -78.4516,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.475298,
        store_longitude: -78.5804,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.3038381,
        store_longitude: -78.450383,
    },

    {
        store_latitdue: -0.213815,
        store_longitude: -78.3399,
        neighborhood: 'PIFO',
    },

    {
        store_latitdue: -0.301148,
        store_longitude: -78.391825,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.12862,
        store_longitude: -78.493165,
    },
    {
        store_latitdue: -0.3529124,
        store_longitude: -78.4374226,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.216653,
        store_longitude: -78.437,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.37096,
        store_longitude: -78.3747,
        neighborhood: 'PÍNTAG',
    },

    {
        store_latitdue: -0.3379496,
        store_longitude: -78.4670706,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.213803,
        store_longitude: -78.3898,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3417001,
        store_longitude: -78.43248,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.298686,
        store_longitude: -78.4805,
    },
    {
        store_latitdue: -0.263006,
        store_longitude: -78.4865,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.214175,
        store_longitude: -78.3917,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.338131,
        store_longitude: -78.4813,
    },
    {
        store_latitdue: -0.3312151,
        store_longitude: -78.4043151,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3253682,
        store_longitude: -78.4186367,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.413428,
        store_longitude: -79.276686,
    },
    {
        store_latitdue: -0.307856,
        store_longitude: -78.415,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.420947,
        store_longitude: -78.5478,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.2054851,
        store_longitude: -78.3774456,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.40484,
        store_longitude: -78.5465,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.2831222,
        store_longitude: -78.4880355,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.340543,
        store_longitude: -78.455088,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.377387,
        store_longitude: -78.3736,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.519931,
        store_longitude: -78.5709539,
    },
    {
        store_latitdue: -0.2263108,
        store_longitude: -78.4479049,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.303406,
        store_longitude: -78.4191,
    },
    {
        store_latitdue: -0.210856,
        store_longitude: -78.3769,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.309601,
        store_longitude: -78.452994,
    },
    {
        store_latitdue: -0.300196,
        store_longitude: -78.4474,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.327024,
        store_longitude: -78.4745,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.312454,
        store_longitude: -78.409618,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.323839,
        store_longitude: -78.4517,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.26296,
        store_longitude: -78.4867,
    },
    {
        store_latitdue: -0.2943125,
        store_longitude: -78.4790411,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3292028,
        store_longitude: -78.4750626,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.358522,
        store_longitude: -78.4404,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3269302,
        store_longitude: -78.4465884,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3323299,
        store_longitude: -78.4225801,
    },
    {
        store_latitdue: -0.205528,
        store_longitude: -78.4506,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.3279814,
        store_longitude: -78.4343635,
    },
    {
        store_latitdue: -0.1774277,
        store_longitude: -78.4859406,
    },

    {
        store_latitdue: -0.5064385,
        store_longitude: -78.5424663,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.2109622,
        store_longitude: -78.403438,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.340868,
        store_longitude: -78.4828,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.305468,
        store_longitude: -78.4873,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.214369,
        store_longitude: -78.4044,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.353274,
        store_longitude: -78.4068,
        neighborhood: 'PÍNTAG',
    },

    {
        store_latitdue: -0.377297,
        store_longitude: -78.5068,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.370158,
        store_longitude: -78.4206,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.114551,
        store_longitude: -78.4441,
    },
    {
        store_latitdue: -0.2704274,
        store_longitude: -78.4884146,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2723726,
        store_longitude: -78.4561011,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3434883,
        store_longitude: -78.3825213,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.201145,
        store_longitude: -78.432436,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.433108,
        store_longitude: -78.540134,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.354165,
        store_longitude: -78.4425,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3863768,
        store_longitude: -78.522592,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.3044462,
        store_longitude: -78.4577988,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.20681868,
        store_longitude: -78.3803482,
    },
    {
        store_latitdue: -0.319687,
        store_longitude: -78.4601938,
    },
    {
        store_latitdue: -0.2179838,
        store_longitude: -78.385121,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.371717,
        store_longitude: -78.3733,
    },
    {
        store_latitdue: -0.5203058,
        store_longitude: -78.5877517,
    },
    {
        store_latitdue: -0.257194,
        store_longitude: -78.5518,
    },
    {
        store_latitdue: -0.3342409,
        store_longitude: -78.4415187,
    },
    {
        store_latitdue: -0.194865,
        store_longitude: -78.3971,
    },
    {
        store_latitdue: -0.2959028,
        store_longitude: -78.4768722,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2231119,
        store_longitude: -78.404173,
    },
    {
        store_latitdue: -0.365896,
        store_longitude: -78.4924989,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.5172726,
        store_longitude: -78.5714448,
    },
    {
        store_latitdue: -0.5163331,
        store_longitude: -78.5694971,
    },
    {
        store_latitdue: -0.2996416,
        store_longitude: -78.4593445,
    },
    {
        store_latitdue: -0.5103342,
        store_longitude: -78.5648594,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.36917,
        store_longitude: -78.4969,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.1841607,
        store_longitude: -78.3876751,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.201758,
        store_longitude: -78.3997,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3665346,
        store_longitude: -78.4538111,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.338444,
        store_longitude: -78.3839,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.298406,
        store_longitude: -78.4568,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.339713,
        store_longitude: -78.4535,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.26798,
        store_longitude: -78.4641,
    },
    {
        store_latitdue: -0.1964249,
        store_longitude: -78.4418891,
    },
    {
        store_latitdue: -0.2980662,
        store_longitude: -78.4563221,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.3034255,
        store_longitude: -78.5696339,
    },
    {
        store_latitdue: -0.213009,
        store_longitude: -78.3973,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.512938,
        store_longitude: -78.5702,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3329287,
        store_longitude: -78.4061983,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.332371,
        store_longitude: -78.4781,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.172299,
        store_longitude: -78.4916,
    },
    {
        store_latitdue: -0.302832,
        store_longitude: -78.483293,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.203061,
        store_longitude: -78.3755237,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.293703,
        store_longitude: -78.475193,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.291704,
        store_longitude: -78.401164,
    },
    {
        store_latitdue: -0.387823622,
        store_longitude: -78.51297384,
    },
    {
        store_latitdue: -0.3272303,
        store_longitude: -78.447324,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.333503,
        store_longitude: -78.4659,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.259914,
        store_longitude: -78.4526,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.260854,
        store_longitude: -78.4501,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.336122,
        store_longitude: -78.4696,
    },
    {
        store_latitdue: -0.3658621,
        store_longitude: -78.4217653,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.5294061,
        store_longitude: -78.5802578,
    },
    {
        store_latitdue: -0.292998,
        store_longitude: -78.4762,
    },

    {
        store_latitdue: -0.525035,
        store_longitude: -78.579013,
        neighborhood: 'ALOASÍ',
    },

    {
        store_latitdue: -0.2025016,
        store_longitude: -78.3793855,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.5158081,
        store_longitude: -78.5702328,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.279918,
        store_longitude: -78.4758,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.370893,
        store_longitude: -78.3735,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.377223,
        store_longitude: -78.4881,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.512786,
        store_longitude: -78.5682272,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.19621,
        store_longitude: -78.4466,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },

    {
        store_latitdue: -0.199399,
        store_longitude: -78.4199,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.3043681,
        store_longitude: -78.433777,
    },
    {
        store_latitdue: -0.213087,
        store_longitude: -78.3833,
    },
    {
        store_latitdue: -0.331611,
        store_longitude: -78.4464,
    },

    {
        store_latitdue: -0.332394,
        store_longitude: -78.477957,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3127207,
        store_longitude: -78.4619846,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3787868,
        store_longitude: -78.3738872,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.469119,
        store_longitude: -78.5845,
    },
    {
        store_latitdue: -0.378403,
        store_longitude: -78.487004,
    },
    {
        store_latitdue: -0.0857717,
        store_longitude: -78.5078677,
    },
    {
        store_latitdue: -0.376289,
        store_longitude: -78.4954,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.310361,
        store_longitude: -78.4721,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.373598,
        store_longitude: -78.3739,
    },
    {
        store_latitdue: -0.3102378,
        store_longitude: -78.4719739,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3128228,
        store_longitude: -78.458468,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.328762,
        store_longitude: -78.3843,
        neighborhood: 'PÍNTAG',
    },

    {
        store_latitdue: -0.213257,
        store_longitude: -78.4035,
    },
    {
        store_latitdue: -0.2274606,
        store_longitude: -78.409281,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2130685,
        store_longitude: -78.405952,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.201445,
        store_longitude: -78.4323,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.3086126,
        store_longitude: -78.4150039,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.372959,
        store_longitude: -78.479217,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.207439,
        store_longitude: -78.3841,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.207291,
        store_longitude: -78.384,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.294514,
        store_longitude: -78.4653,
    },
    {
        store_latitdue: -0.3494735,
        store_longitude: -78.4300255,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.509405,
        store_longitude: -78.5647,
    },

    {
        store_latitdue: -0.616695,
        store_longitude: -78.6288,
    },
    {
        store_latitdue: -0.321279,
        store_longitude: -78.4634,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.365936,
        store_longitude: -78.4137144,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.366054,
        store_longitude: -78.413515,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3830709,
        store_longitude: -78.4085134,
    },
    {
        store_latitdue: -0.292511,
        store_longitude: -78.4754,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.270031,
        store_longitude: -78.4640271,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.263027,
        store_longitude: -78.48595,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2274449,
        store_longitude: -78.4071571,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.216687,
        store_longitude: -78.4094,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3383882,
        store_longitude: -78.4814089,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.5212781,
        store_longitude: -78.5736696,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.380962,
        store_longitude: -78.5001,
    },
    {
        store_latitdue: -0.2052,
        store_longitude: -78.3777,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.5204573,
        store_longitude: -78.5721379,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3748927,
        store_longitude: -78.5044395,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2183733,
        store_longitude: -78.4110441,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.356395,
        store_longitude: -78.4372,
    },
    {
        store_latitdue: -0.2853057,
        store_longitude: -78.4414066,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.299311,
        store_longitude: -78.4426,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.296196,
        store_longitude: -78.3993,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.509073,
        store_longitude: -78.564294,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.346943,
        store_longitude: -78.4304,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.372108,
        store_longitude: -78.4750856,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.288041,
        store_longitude: -78.4897,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2253899,
        store_longitude: -78.4290674,
    },
    {
        store_latitdue: -0.3292096,
        store_longitude: -78.4984935,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.299584,
        store_longitude: -78.424699,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.508397,
        store_longitude: -78.569,
    },
    {
        store_latitdue: -0.3747848,
        store_longitude: -78.4749218,
    },
    {
        store_latitdue: -0.4009785,
        store_longitude: -78.5317133,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.1789977,
        store_longitude: -78.394419,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.228519,
        store_longitude: -78.4065,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.337808,
        store_longitude: -78.4722,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3308179,
        store_longitude: -78.4740939,
    },

    {
        store_latitdue: -0.2034793,
        store_longitude: -78.4223019,
    },

    {
        store_latitdue: -0.378867,
        store_longitude: -78.5196,
    },
    {
        store_latitdue: -0.286699,
        store_longitude: -78.4909,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.310502,
        store_longitude: -78.4673,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2955157,
        store_longitude: -78.4788048,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.279225,
        store_longitude: -78.473,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.279288,
        store_longitude: -78.4741,
    },
    {
        store_latitdue: -0.204232,
        store_longitude: -78.3767,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.213311,
        store_longitude: -78.3748,
    },
    {
        store_latitdue: -0.216316,
        store_longitude: -78.4072,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2110156,
        store_longitude: -78.3825638,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.30341,
        store_longitude: -78.4546,
    },
    {
        store_latitdue: -0.3223269,
        store_longitude: -78.4397001,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.370977,
        store_longitude: -78.3752,
        neighborhood: 'PÍNTAG',
    },

    {
        store_latitdue: -0.212882,
        store_longitude: -78.3774,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3650581,
        store_longitude: -78.4221328,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.202708,
        store_longitude: -78.4485,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.203995,
        store_longitude: -78.4459,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.3056839,
        store_longitude: -78.452215,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.332525,
        store_longitude: -78.4356463,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.293252,
        store_longitude: -78.4771,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.5197778,
        store_longitude: -78.5753962,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.33184516,
        store_longitude: -78.4870223,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.348154,
        store_longitude: -78.4804,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.32821,
        store_longitude: -78.471421,
    },
    {
        store_latitdue: -0.27215,
        store_longitude: -78.4559,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.305973,
        store_longitude: -78.4175,
    },

    {
        store_latitdue: -0.3177365,
        store_longitude: -78.460674,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3324197,
        store_longitude: -78.4238533,
    },
    {
        store_latitdue: -0.268026,
        store_longitude: -78.4641,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.322256,
        store_longitude: -78.4744,
    },
    {
        store_latitdue: -0.346462,
        store_longitude: -78.4854,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.346181,
        store_longitude: -78.49407,
    },
    {
        store_latitdue: -0.3671557,
        store_longitude: -78.4537978,
    },
    {
        store_latitdue: -0.30800238,
        store_longitude: -78.3989257,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2958273,
        store_longitude: -78.4396112,
    },
    {
        store_latitdue: -0.2973937,
        store_longitude: -78.4399973,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.380299,
        store_longitude: -78.5074,
    },

    {
        store_latitdue: -0.263849,
        store_longitude: -78.4508,
    },

    {
        store_latitdue: -0.32684,
        store_longitude: -78.4751,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3338083,
        store_longitude: -78.4413039,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.34087,
        store_longitude: -78.43968,
    },
    {
        store_latitdue: -0.3117547,
        store_longitude: -78.4090268,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.291936,
        store_longitude: -78.4852,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.218057,
        store_longitude: -78.4114,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.332097,
        store_longitude: -78.4242,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3309584,
        store_longitude: -78.4257435,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.333963,
        store_longitude: -78.4474,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.348218,
        store_longitude: -78.44,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3408732,
        store_longitude: -78.4541139,
    },
    {
        store_latitdue: -0.2789805,
        store_longitude: -78.4574072,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.411389,
        store_longitude: -78.5480542,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.2019946,
        store_longitude: -78.4300772,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.193905,
        store_longitude: -78.4235,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.20968,
        store_longitude: -78.3779,
    },

    {
        store_latitdue: -0.3338222,
        store_longitude: -78.4493463,
    },
    {
        store_latitdue: -0.32375,
        store_longitude: -78.372,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.326992546,
        store_longitude: -78.34659394,
    },
    {
        store_latitdue: -0.284338,
        store_longitude: -78.4424,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.4499838,
        store_longitude: -78.5594745,
    },
    {
        store_latitdue: -0.5151218,
        store_longitude: -78.5678072,
    },
    {
        store_latitdue: -0.347635,
        store_longitude: -78.43,
    },
    {
        store_latitdue: -0.3675637,
        store_longitude: -78.4334928,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.4010623,
        store_longitude: -78.5305437,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.383216,
        store_longitude: -78.503642,
    },
    {
        store_latitdue: -0.211091,
        store_longitude: -78.3866,
    },
    {
        store_latitdue: -0.208383,
        store_longitude: -78.38857,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2893159,
        store_longitude: -78.4382556,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: 0.0087019,
        store_longitude: -78.4600922,
    },

    {
        store_latitdue: -0.380697,
        store_longitude: -78.5212,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.201461,
        store_longitude: -78.4325,
    },
    {
        store_latitdue: -0.185027,
        store_longitude: -78.3886,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.1758584,
        store_longitude: -78.3969972,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.329271,
        store_longitude: -78.4488,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.378608,
        store_longitude: -78.4969,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3828862,
        store_longitude: -78.5200613,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.2322628,
        store_longitude: -78.4489544,
    },
    {
        store_latitdue: -0.347133,
        store_longitude: -78.459499,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.192925,
        store_longitude: -78.3864,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.1885027,
        store_longitude: -78.4223386,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.3799107,
        store_longitude: -78.4953953,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3715949,
        store_longitude: -78.373748,
    },
    {
        store_latitdue: -0.291531,
        store_longitude: -78.4776,
    },
    {
        store_latitdue: -0.2640129,
        store_longitude: -78.4645019,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3673385,
        store_longitude: -78.4989029,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.5005742,
        store_longitude: -78.5590275,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.337196,
        store_longitude: -78.444252,
    },
    {
        store_latitdue: -0.255411,
        store_longitude: -78.447396,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.5220436,
        store_longitude: -78.5797737,
    },
    {
        store_latitdue: -0.300899,
        store_longitude: -78.4746,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2663257,
        store_longitude: -78.4801382,
    },

    {
        store_latitdue: -0.329103,
        store_longitude: -78.4299,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3887377,
        store_longitude: -78.5128429,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.295674502,
        store_longitude: -78.40608215,
        neighborhood: 'LA MERCED',
    },

    {
        store_latitdue: -0.340077,
        store_longitude: -78.4807906,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.29465813,
        store_longitude: -78.4415597,
    },
    {
        store_latitdue: -0.2807679,
        store_longitude: -78.4644029,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3342841,
        store_longitude: -78.4482986,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3278177,
        store_longitude: -78.4503621,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3482243,
        store_longitude: -78.4807382,
    },

    {
        store_latitdue: -0.305168,
        store_longitude: -78.4877222,
    },
    {
        store_latitdue: -0.3314752,
        store_longitude: -78.3480095,
    },

    {
        store_latitdue: -0.322099,
        store_longitude: -78.439627,
    },

    {
        store_latitdue: -0.5127309,
        store_longitude: -78.5715064,
    },
    {
        store_latitdue: -0.4027608,
        store_longitude: -78.5495448,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.520906,
        store_longitude: -78.589128,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.5219809,
        store_longitude: -78.5836678,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.5109467,
        store_longitude: -78.575035,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.382332,
        store_longitude: -78.4906,
    },

    {
        store_latitdue: -0.2152361,
        store_longitude: -78.4036966,
    },
    {
        store_latitdue: -0.2112668,
        store_longitude: -78.420335,
    },

    {
        store_latitdue: -0.220617,
        store_longitude: -78.4555,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.230285,
        store_longitude: -78.451294,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.199179,
        store_longitude: -78.388654,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2905747,
        store_longitude: -78.4515302,
    },
    {
        store_latitdue: -0.511025,
        store_longitude: -78.562538,
    },
    {
        store_latitdue: -0.5040772,
        store_longitude: -78.5500659,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.50352,
        store_longitude: -78.552026,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.26314699,
        store_longitude: -78.4843063,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3242268,
        store_longitude: -78.4500966,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3537377,
        store_longitude: -78.4762221,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.35508,
        store_longitude: -78.480378,
    },
    {
        store_latitdue: -0.2050215,
        store_longitude: -78.3812885,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2675842,
        store_longitude: -78.4729541,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.261159,
        store_longitude: -78.449992,
        neighborhood: 'GUANGOPOLO',
    },

    {
        store_latitdue: -0.4112951,
        store_longitude: -78.548068,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.3388868,
        store_longitude: -78.4458523,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.5030536,
        store_longitude: -78.5680274,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.213342,
        store_longitude: -78.4036636,
    },

    {
        store_latitdue: -0.3717268,
        store_longitude: -78.4549164,
        neighborhood: 'COTOGCHOA',
    },
    {
        store_latitdue: -0.2718774,
        store_longitude: -78.4644199,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.226555,
        store_longitude: -78.368431,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.214384,
        store_longitude: -78.388794,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2182877,
        store_longitude: -78.376477,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.1961613,
        store_longitude: -78.3772189,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.33195582,
        store_longitude: -78.4795379,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.46851789,
        store_longitude: -78.5887527,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.3337825,
        store_longitude: -78.4417747,
    },
    {
        store_latitdue: -0.3337425,
        store_longitude: -78.4449464,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2989364,
        store_longitude: -78.4441031,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.5119998,
        store_longitude: -78.5701704,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.48864562,
        store_longitude: -78.57030608,
        neighborhood: 'ALOAG',
    },

    {
        store_latitdue: -0.37667,
        store_longitude: -78.505579,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2869526,
        store_longitude: -78.4516514,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3441158,
        store_longitude: -78.4296348,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2198018,
        store_longitude: -78.3740172,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.200099,
        store_longitude: -78.43233,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.2263625,
        store_longitude: -78.448,
    },
    {
        store_latitdue: -0.35465663,
        store_longitude: -78.4322977,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2012861,
        store_longitude: -78.3767324,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.220934,
        store_longitude: -78.372673,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3565749,
        store_longitude: -78.4398698,
    },

    {
        store_latitdue: -0.2864941,
        store_longitude: -78.4734825,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.5153154,
        store_longitude: -78.5669287,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.376864,
        store_longitude: -78.487916,
    },
    {
        store_latitdue: -0.203263,
        store_longitude: -78.391396,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.229735,
        store_longitude: -78.448758,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.320517,
        store_longitude: -78.460173,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.51488,
        store_longitude: -78.564897,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.4039793,
        store_longitude: -78.4319588,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.38370823,
        store_longitude: -78.5106582,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.279391,
        store_longitude: -78.4815,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3272259,
        store_longitude: -78.4475465,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3307324,
        store_longitude: -78.4236299,
    },

    {
        store_latitdue: -0.3865746,
        store_longitude: -78.5089434,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2332754,
        store_longitude: -78.4488514,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.2991015,
        store_longitude: -78.47999,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.27542,
        store_longitude: -78.477662,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.290042,
        store_longitude: -78.445606,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.31023551,
        store_longitude: -78.4108313,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.2528017,
        store_longitude: -78.4796067,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2683135,
        store_longitude: -78.4630592,
    },

    {
        store_latitdue: -0.2516631,
        store_longitude: -78.4803382,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2020149,
        store_longitude: -78.4257563,
    },

    {
        store_latitdue: -0.4895462,
        store_longitude: -78.5887529,
        neighborhood: 'ALOAG',
    },

    {
        store_latitdue: -0.2120048,
        store_longitude: -78.3846398,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.968877,
        store_longitude: -78.6169,
    },
    {
        store_latitdue: -0.2010443,
        store_longitude: -78.4329392,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.3464317,
        store_longitude: -78.430505,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.203894,
        store_longitude: -78.3783003,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.359012,
        store_longitude: -78.480249,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.338521,
        store_longitude: -78.4785,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3247827,
        store_longitude: -78.4106347,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.379878,
        store_longitude: -78.503587,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.336017,
        store_longitude: -78.4422,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2000517,
        store_longitude: -78.3987183,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.214913,
        store_longitude: -78.4299,
    },
    {
        store_latitdue: -0.1874741,
        store_longitude: -78.3862803,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2590937,
        store_longitude: -78.4507365,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.32057115,
        store_longitude: -78.4423446,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.565855,
        store_longitude: -78.5907453,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.466279,
        store_longitude: -78.5842,
        neighborhood: 'ALOAG',
    },
    {
        store_latitdue: -0.4059871,
        store_longitude: -78.5454273,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.5029736,
        store_longitude: -78.5718542,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.3766127,
        store_longitude: -78.5050559,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.2110299,
        store_longitude: -78.4205057,
    },
    {
        store_latitdue: -0.234395,
        store_longitude: -78.4442,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2028177,
        store_longitude: -78.428141,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.30375596,
        store_longitude: -78.4304809,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.46785357,
        store_longitude: -78.5854415,
        neighborhood: 'ALOAG',
    },

    {
        store_latitdue: -0.3347993,
        store_longitude: -78.4658148,
    },

    {
        store_latitdue: -0.3770853,
        store_longitude: -78.5302731,
        neighborhood: 'CUTUGLAHUA',
    },

    {
        store_latitdue: -0.5198826,
        store_longitude: -78.5807055,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.515191,
        store_longitude: -78.575829,
        neighborhood: 'MACHACHI',
    },
    {
        store_latitdue: -0.4028829,
        store_longitude: -78.5459771,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.300831,
        store_longitude: -78.4388,
    },
    {
        store_latitdue: -0.29194462,
        store_longitude: -78.476743,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2826871,
        store_longitude: -78.4920017,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.5197782,
        store_longitude: -78.5847366,
        neighborhood: 'ALOASÍ',
    },

    {
        store_latitdue: -0.3303506,
        store_longitude: -78.3691019,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.1853012,
        store_longitude: -78.3886485,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.328639,
        store_longitude: -78.426193,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3859786925,
        store_longitude: -78.5085006058,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2628083,
        store_longitude: -78.4807586,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.373798,
        store_longitude: -78.475,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3371834,
        store_longitude: -78.4413987,
    },

    {
        store_latitdue: -0.2957386,
        store_longitude: -78.4758841,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.216569,
        store_longitude: -78.4043,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3434318,
        store_longitude: -78.4821226,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3297883,
        store_longitude: -78.4476532,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.201325854,
        store_longitude: -78.4299259632,
    },
    {
        store_latitdue: -0.2239626,
        store_longitude: -78.3779243,
    },

    {
        store_latitdue: -0.196343,
        store_longitude: -78.4453,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.1887052529,
        store_longitude: -78.4430045124,
        neighborhood: 'NAYÓN',
    },

    {
        store_latitdue: -0.2026847,
        store_longitude: -78.4242058,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.33432,
        store_longitude: -78.4707749,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3574382,
        store_longitude: -78.377805,
        neighborhood: 'PÍNTAG',
    },

    {
        store_latitdue: -0.502761,
        store_longitude: -78.572745,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.2791288,
        store_longitude: -78.4703136,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3428502,
        store_longitude: -78.4845837,
    },
    {
        store_latitdue: -0.222896,
        store_longitude: -78.3851669,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.198554,
        store_longitude: -78.435872,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.2175125,
        store_longitude: -78.377474,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3234869,
        store_longitude: -78.5633705,
    },

    {
        store_latitdue: -0.2038841,
        store_longitude: -78.3783083,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.224226,
        store_longitude: -78.396097,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.22207,
        store_longitude: -78.396095,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.5218013,
        store_longitude: -78.5801177,
        neighborhood: 'ALOASÍ',
    },

    {
        store_latitdue: -0.38638411,
        store_longitude: -78.5228883,
        neighborhood: 'UYUMBICHO',
    },

    {
        store_latitdue: -0.3389962,
        store_longitude: -78.5691027,
    },

    {
        store_latitdue: -0.3755361,
        store_longitude: -78.4868165,
    },

    {
        store_latitdue: -0.3083675685,
        store_longitude: -78.4482492465,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.163425,
        store_longitude: -78.4641,
    },

    {
        store_latitdue: -0.223082,
        store_longitude: -78.489374,
    },

    {
        store_latitdue: -0.301121,
        store_longitude: -78.3913,
    },

    {
        store_latitdue: -0.516659,
        store_longitude: -78.5792728,
        neighborhood: 'ALOASÍ',
    },

    {
        store_latitdue: -0.3040349,
        store_longitude: -78.5435057,
    },

    {
        store_latitdue: -0.176218,
        store_longitude: -78.3601,
    },
    {
        store_latitdue: -0.0450907,
        store_longitude: -78.2931513,
        neighborhood: 'GUAYLLABAMBA',
    },

    {
        store_latitdue: -0.2996472,
        store_longitude: -78.4382689,
    },

    {
        store_latitdue: -0.3749949535,
        store_longitude: -78.374318406,
        neighborhood: 'PÍNTAG',
    },

    {
        store_latitdue: -0.291502,
        store_longitude: -78.400577,
        neighborhood: 'LA MERCED',
    },

    {
        store_latitdue: -0.3485891324,
        store_longitude: -78.4835359454,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.308073,
        store_longitude: -78.5431309,
    },

    {
        store_latitdue: -0.32293,
        store_longitude: -78.4406,
    },

    {
        store_latitdue: -0.251267,
        store_longitude: -78.496408,
    },

    {
        store_latitdue: -0.185647,
        store_longitude: -76.690473,
    },

    {
        store_latitdue: -0.208555,
        store_longitude: -78.3838248,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2158918,
        store_longitude: -78.3999058,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2080699,
        store_longitude: -78.3848331,
    },
    {
        store_latitdue: -0.2239582,
        store_longitude: -78.4275388,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2407497,
        store_longitude: -78.4837466,
    },

    {
        store_latitdue: -0.2925058,
        store_longitude: -78.4775606,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2939619,
        store_longitude: -78.4729274,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.27802,
        store_longitude: -78.475099,
    },

    {
        store_latitdue: -0.2991014,
        store_longitude: -78.4741609,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.108671,
        store_longitude: -78.2975,
    },

    {
        store_latitdue: -0.3820398,
        store_longitude: -78.4085654,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3435326,
        store_longitude: -78.4433802,
    },
    {
        store_latitdue: -0.6016176,
        store_longitude: -78.6416748,
        neighborhood: 'EL CHAUPI',
    },
    {
        store_latitdue: -0.30692166,
        store_longitude: -78.4405288,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.35518,
        store_longitude: -78.549574,
    },
    {
        store_latitdue: -0.5123926,
        store_longitude: -78.5637667,
    },
    {
        store_latitdue: -0.2992144,
        store_longitude: -78.506992,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2967807,
        store_longitude: -78.456418,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2122785,
        store_longitude: -78.3796731,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2137156,
        store_longitude: -78.4137387,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2805132,
        store_longitude: -78.4758071,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2952103,
        store_longitude: -78.4095642,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2642888,
        store_longitude: -78.4772422,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3412812,
        store_longitude: -78.4463659,
    },
    {
        store_latitdue: -0.3123351,
        store_longitude: -78.4799533,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.1543853,
        store_longitude: -78.4721703,
    },

    {
        store_latitdue: -0.522233,
        store_longitude: -78.585019,
        neighborhood: 'ALOASÍ',
    },
    {
        store_latitdue: -0.281539,
        store_longitude: -78.4706,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2984829,
        store_longitude: -78.4804791,
    },
    {
        store_latitdue: -0.23307,
        store_longitude: -78.4494683,
    },

    {
        store_latitdue: -0.32720255,
        store_longitude: -78.4736785,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3748885,
        store_longitude: -78.4765903,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3331795,
        store_longitude: -78.4443604,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.36935614,
        store_longitude: -78.4107542,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.3568074,
        store_longitude: -78.4371708,
    },

    {
        store_latitdue: -0.32776492,
        store_longitude: -78.4725265,
    },
    {
        store_latitdue: -0.3858489,
        store_longitude: -78.5230581,
        neighborhood: 'UYUMBICHO',
    },
    {
        store_latitdue: -0.4041823,
        store_longitude: -78.5427794,
        neighborhood: 'TAMBILLO',
    },
    {
        store_latitdue: -0.3249042,
        store_longitude: -78.46228681,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3244983,
        store_longitude: -78.4613966,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.2228717,
        store_longitude: -78.3788452,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2052459,
        store_longitude: -78.3753804,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.3423306,
        store_longitude: -78.4852824,
        neighborhood: 'AMAGUAÑA',
    },

    {
        store_latitdue: -0.3579394,
        store_longitude: -78.4562957,
        neighborhood: 'COTOGCHOA',
    },

    {
        store_latitdue: -0.218522,
        store_longitude: -78.4548,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.2163266,
        store_longitude: -78.4073384,
    },
    {
        store_latitdue: -0.2612434,
        store_longitude: -78.4500811,
        neighborhood: 'GUANGOPOLO',
    },
    {
        store_latitdue: -0.329002,
        store_longitude: -78.4571,
    },

    {
        store_latitdue: -0.3004084,
        store_longitude: -78.4415875,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: 0.188423135,
        store_longitude: -78.2253599,
    },

    {
        store_latitdue: -0.1991792,
        store_longitude: -78.3989036,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.30914402,
        store_longitude: -78.5480499,
    },
    {
        store_latitdue: -0.228136,
        store_longitude: -78.408138,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.166235,
        store_longitude: -78.489152,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3257451,
        store_longitude: -78.4779782,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.18861015,
        store_longitude: -78.392723,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2175407,
        store_longitude: -78.3773059,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.1234422,
        store_longitude: -78.5073306,
    },
    {
        store_latitdue: -0.1993722,
        store_longitude: -78.4281013,
    },
    {
        store_latitdue: -0.1992448,
        store_longitude: -78.3985786,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.3071173,
        store_longitude: -78.4430074,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.30092101,
        store_longitude: -78.4398293,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3875968,
        store_longitude: -78.5247729,
    },
    {
        store_latitdue: -0.2630511,
        store_longitude: -78.4840583,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.33202473,
        store_longitude: -78.4437668,
    },
    {
        store_latitdue: -0.280733,
        store_longitude: -78.470354,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.3255023,
        store_longitude: -78.4881774,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.3007468,
        store_longitude: -78.4610985,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2955349,
        store_longitude: -78.4440235,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3237248,
        store_longitude: -78.4499108,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2865299,
        store_longitude: -78.4524924,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2982998574,
        store_longitude: -78.411401622,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.4041823,
        store_longitude: -78.5426632,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.3011466481,
        store_longitude: -78.4388228506,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.2979645858,
        store_longitude: -78.4114146978,
        neighborhood: 'LA MERCED',
    },
    {
        store_latitdue: -0.2922648,
        store_longitude: -78.4854589,
    },
    {
        store_latitdue: -0.3322731,
        store_longitude: -78.4458816,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3031536,
        store_longitude: -78.465941,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.171412,
        store_longitude: -78.473026,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3063003,
        store_longitude: -78.4382015,
    },

    {
        store_latitdue: -0.2972341,
        store_longitude: -78.4699005,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.3415756,
        store_longitude: -78.4847375,
    },
    {
        store_latitdue: -0.30196273,
        store_longitude: -78.438446,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3385708,
        store_longitude: -78.3838477,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3688225,
        store_longitude: -78.3731386,
    },
    {
        store_latitdue: -0.2200406,
        store_longitude: -78.3731613,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.21595,
        store_longitude: -78.404201,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.27042,
        store_longitude: -78.458358,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.340417,
        store_longitude: -78.4874,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.308806,
        store_longitude: -78.4526,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.3667267,
        store_longitude: -78.4213099,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.511764,
        store_longitude: -78.5705,
    },

    {
        store_latitdue: -0.322909,
        store_longitude: -78.440623,
    },
    {
        store_latitdue: -0.2987364,
        store_longitude: -78.4380708,
        neighborhood: 'ALANGASÍ',
    },
    {
        store_latitdue: -0.374542,
        store_longitude: -78.4777571,
        neighborhood: 'AMAGUAÑA',
    },
    {
        store_latitdue: -0.2880184,
        store_longitude: -78.493752,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.4609108,
        store_longitude: -78.5639735,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.2192405,
        store_longitude: -78.4116253,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2174777,
        store_longitude: -78.4103871,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.4334644627,
        store_longitude: -78.5398478296,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.458964,
        store_longitude: -78.563177,
        neighborhood: 'MACHACHI',
    },

    {
        store_latitdue: -0.372485,
        store_longitude: -78.3739,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.3248299,
        store_longitude: -78.4406179,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.20012657,
        store_longitude: -78.3896865,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.2143049,
        store_longitude: -78.4034109,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.1181879,
        store_longitude: -78.5049445,
    },

    {
        store_latitdue: -0.242116,
        store_longitude: -79.1955,
    },
    {
        store_latitdue: -0.32790571,
        store_longitude: -78.447536,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.2317561,
        store_longitude: -78.4871536,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.280077,
        store_longitude: -78.4866,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2983843,
        store_longitude: -78.5099523,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.277071,
        store_longitude: -78.465891,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2940311,
        store_longitude: -78.4777208,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -1.0352428,
        store_longitude: -80.4580769,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.996261,
        store_longitude: -80.466783,
    },
    {
        store_latitdue: -1.047725,
        store_longitude: -80.451239,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.023857,
        store_longitude: -80.46403,
    },
    {
        store_latitdue: -1.01545,
        store_longitude: -80.4677,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.056997,
        store_longitude: -80.466177,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.045536,
        store_longitude: -80.471273,
    },
    {
        store_latitdue: -1.056648,
        store_longitude: -80.462344,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.046509,
        store_longitude: -80.479292,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.051366,
        store_longitude: -80.46328,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.053368,
        store_longitude: -80.4788,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0445216,
        store_longitude: -80.4813467,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0316293,
        store_longitude: -80.4543001,
    },
    {
        store_latitdue: -1.054193,
        store_longitude: -80.476618,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.057983,
        store_longitude: -80.46093,
    },
    {
        store_latitdue: -1.057687,
        store_longitude: -80.461045,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.058208,
        store_longitude: -80.461481,
    },
    {
        store_latitdue: -1.03685,
        store_longitude: -80.4726,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0421703,
        store_longitude: -80.4605946,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.037032,
        store_longitude: -80.466813,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0338577,
        store_longitude: -80.4880735,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0383871,
        store_longitude: -80.4648214,
    },
    {
        store_latitdue: -1.0504237,
        store_longitude: -80.4496927,
    },
    {
        store_latitdue: -1.03609,
        store_longitude: -80.4924,
    },
    {
        store_latitdue: -1.036143,
        store_longitude: -80.49244,
    },
    {
        store_latitdue: -1.056641,
        store_longitude: -80.466033,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.029156,
        store_longitude: -80.470488,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05617,
        store_longitude: -80.4667,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.04764,
        store_longitude: -80.4678,
    },
    {
        store_latitdue: -1.05824,
        store_longitude: -80.4824,
    },
    {
        store_latitdue: -1.0368168,
        store_longitude: -80.4658908,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0448289,
        store_longitude: -80.4763761,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.047678,
        store_longitude: -80.469266,
    },
    {
        store_latitdue: -1.042664,
        store_longitude: -80.468966,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0194207,
        store_longitude: -80.4725859,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.036061,
        store_longitude: -80.492571,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0662235,
        store_longitude: -80.4524646,
    },
    {
        store_latitdue: -1.06721,
        store_longitude: -80.4513,
    },
    {
        store_latitdue: -1.0652631,
        store_longitude: -80.4531694,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06222,
        store_longitude: -80.4645,
    },
    {
        store_latitdue: -1.063458,
        store_longitude: -80.463416,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.07622,
        store_longitude: -80.449,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.079598,
        store_longitude: -80.452884,
    },
    {
        store_latitdue: -1.0620447,
        store_longitude: -80.4582944,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.062681,
        store_longitude: -80.466105,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.059578,
        store_longitude: -80.467442,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.1128303,
        store_longitude: -80.4138657,
    },
    {
        store_latitdue: -1.110996,
        store_longitude: -80.414738,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.072759,
        store_longitude: -80.479388,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.062017,
        store_longitude: -80.458728,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06571,
        store_longitude: -80.4673,
    },
    {
        store_latitdue: -1.070102,
        store_longitude: -80.47384,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0617555,
        store_longitude: -80.4612177,
    },
    {
        store_latitdue: -1.0640692,
        store_longitude: -80.449606,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05674,
        store_longitude: -80.472395,
    },
    {
        store_latitdue: -1.060311,
        store_longitude: -80.462128,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.061296,
        store_longitude: -80.461978,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.064906,
        store_longitude: -80.448603,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06073,
        store_longitude: -80.4741,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0639241,
        store_longitude: -80.4756067,
    },
    {
        store_latitdue: -1.10522,
        store_longitude: -80.4172,
    },
    {
        store_latitdue: -1.070519,
        store_longitude: -80.473267,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.12644,
        store_longitude: -80.406471,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.1169377,
        store_longitude: -80.4039537,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.10994,
        store_longitude: -80.4153,
    },
    {
        store_latitdue: -1.059304,
        store_longitude: -80.469144,
    },
    {
        store_latitdue: -1.097168,
        store_longitude: -80.411911,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.099086,
        store_longitude: -80.411351,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05736,
        store_longitude: -80.482,
    },
    {
        store_latitdue: -1.06784,
        store_longitude: -80.4499,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.065212,
        store_longitude: -80.467347,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06443,
        store_longitude: -80.4512,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.073593,
        store_longitude: -80.453977,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.063144,
        store_longitude: -80.476092,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.064084,
        store_longitude: -80.452359,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0647289,
        store_longitude: -80.4523389,
    },
    {
        store_latitdue: -1.0623869,
        store_longitude: -80.4588567,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06825,
        store_longitude: -80.448053,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0957278,
        store_longitude: -80.4117339,
    },
    {
        store_latitdue: -1.100437,
        store_longitude: -80.421824,
    },
    {
        store_latitdue: -1.06351,
        store_longitude: -80.4592,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.15801,
        store_longitude: -80.2868,
    },
    {
        store_latitdue: -1.153042,
        store_longitude: -80.281175,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.157514,
        store_longitude: -80.29714,
    },
    {
        store_latitdue: -1.154956,
        store_longitude: -80.2961658,
    },
    {
        store_latitdue: -1.1583299,
        store_longitude: -80.2861732,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.172695,
        store_longitude: -80.311989,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.177124,
        store_longitude: -80.313148,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.1845571,
        store_longitude: -80.3133261,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.155349,
        store_longitude: -80.284727,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.154869,
        store_longitude: -80.284322,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.157519685,
        store_longitude: -80.28623198,
    },
    {
        store_latitdue: -1.116547,
        store_longitude: -80.238106,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -1.187143,
        store_longitude: -80.313029,
    },
    {
        store_latitdue: -0.83932,
        store_longitude: -80.4864,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.83987,
        store_longitude: -80.4859,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.8400657,
        store_longitude: -80.4856725,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.854864,
        store_longitude: -80.4699,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.8376535,
        store_longitude: -80.4881545,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.779805,
        store_longitude: -80.5153,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.8247632,
        store_longitude: -80.4946635,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.865305,
        store_longitude: -80.467505,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.828496,
        store_longitude: -80.4949,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.697096,
        store_longitude: -80.093416,
    },
    {
        store_latitdue: -0.703436,
        store_longitude: -80.089056,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6985221,
        store_longitude: -80.092455,
    },
    {
        store_latitdue: -0.69600539,
        store_longitude: -80.0969624,
    },
    {
        store_latitdue: -0.70028,
        store_longitude: -80.08639,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.69714,
        store_longitude: -80.093582,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.697077,
        store_longitude: -80.087437,
    },
    {
        store_latitdue: -0.697949,
        store_longitude: -80.080515,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6945003,
        store_longitude: -80.0974207,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.695493,
        store_longitude: -80.0946,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6949757,
        store_longitude: -80.100212,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6982256,
        store_longitude: -80.0978815,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7058871,
        store_longitude: -80.1019653,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7043489,
        store_longitude: -80.0936475,
    },
    {
        store_latitdue: -0.6990835,
        store_longitude: -80.0950235,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6949148,
        store_longitude: -80.0856097,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6927203,
        store_longitude: -80.0975404,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.703292,
        store_longitude: -80.093198,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.69606,
        store_longitude: -80.09705,
    },
    {
        store_latitdue: -0.6966228,
        store_longitude: -80.0836773,
    },
    {
        store_latitdue: -0.706287,
        store_longitude: -80.0947,
    },
    {
        store_latitdue: -0.6960651,
        store_longitude: -80.095656,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.700246,
        store_longitude: -80.1142,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6949421,
        store_longitude: -80.0939154,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.699025,
        store_longitude: -80.0911,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7036709,
        store_longitude: -80.0927039,
    },
    {
        store_latitdue: -0.7015968,
        store_longitude: -80.1094763,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.704343,
        store_longitude: -80.166897,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.6947911,
        store_longitude: -80.0942118,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6952534,
        store_longitude: -80.0942044,
    },
    {
        store_latitdue: -0.405615,
        store_longitude: -79.9042,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.7059345,
        store_longitude: -80.09404,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.053722,
        store_longitude: -80.279036,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -1.051897,
        store_longitude: -80.284228,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -1.031507,
        store_longitude: -80.339447,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.02923,
        store_longitude: -80.3458,
    },
    {
        store_latitdue: -1.049468,
        store_longitude: -80.296197,
    },
    {
        store_latitdue: -1.0548475,
        store_longitude: -80.2669409,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -1.051915,
        store_longitude: -80.284019,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -1.025739097,
        store_longitude: -80.35067901,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.049699,
        store_longitude: -80.296185,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.160096,
        store_longitude: -80.391674,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7076524,
        store_longitude: -80.1021199,
    },
    {
        store_latitdue: -0.86145,
        store_longitude: -80.53479,
    },
    {
        store_latitdue: -0.857398,
        store_longitude: -80.5334653,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.85233,
        store_longitude: -80.53206,
    },
    {
        store_latitdue: -0.84979,
        store_longitude: -80.53123,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.8695457,
        store_longitude: -80.5398184,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.8731221,
        store_longitude: -80.5409547,
    },
    {
        store_latitdue: -0.4047553,
        store_longitude: -79.9073223,
    },
    {
        store_latitdue: -0.404627,
        store_longitude: -79.906528,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.404753,
        store_longitude: -79.90744,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.40444,
        store_longitude: -79.906118,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.05553,
        store_longitude: -80.4433,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0536032,
        store_longitude: -80.4433098,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.062672,
        store_longitude: -80.438165,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.074607,
        store_longitude: -80.43208,
    },

    {
        store_latitdue: -1.072307,
        store_longitude: -80.441725,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0672614,
        store_longitude: -80.4476507,
    },
    {
        store_latitdue: -1.057589,
        store_longitude: -80.447808,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.058751,
        store_longitude: -80.441459,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.056252,
        store_longitude: -80.444158,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05507,
        store_longitude: -80.443606,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0364732,
        store_longitude: -80.4922296,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0522649,
        store_longitude: -80.4417255,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0562995,
        store_longitude: -80.4397061,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.069745,
        store_longitude: -80.445,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0558004,
        store_longitude: -80.4407061,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.055258,
        store_longitude: -80.4402624,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.052677,
        store_longitude: -80.436417,
    },
    {
        store_latitdue: -1.0547032,
        store_longitude: -80.4393574,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05396,
        store_longitude: -80.4424,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.050721,
        store_longitude: -80.4500221,
    },
    {
        store_latitdue: -1.061104,
        store_longitude: -80.437744,
    },
    {
        store_latitdue: -1.0608695,
        store_longitude: -80.4380536,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.068883,
        store_longitude: -80.434888,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0708391,
        store_longitude: -80.4337151,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.92839,
        store_longitude: -80.2066,
    },
    {
        store_latitdue: -0.9279465,
        store_longitude: -80.2057649,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.927544,
        store_longitude: -80.206067,
    },
    {
        store_latitdue: -0.928053,
        store_longitude: -80.206651,
    },
    {
        store_latitdue: -0.9771,
        store_longitude: -80.28632,
    },
    {
        store_latitdue: -0.983037,
        store_longitude: -80.302076,
        neighborhood: 'PUEBLO NUEVO',
    },
    {
        store_latitdue: -0.8442537,
        store_longitude: -80.1648163,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.8494624,
        store_longitude: -80.1574704,
    },
    {
        store_latitdue: -0.845689,
        store_longitude: -80.166389,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.844708,
        store_longitude: -80.159983,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.9546584,
        store_longitude: -80.6998962,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9558594,
        store_longitude: -80.6975454,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9676226,
        store_longitude: -80.6925009,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.969776,
        store_longitude: -80.6991,
    },
    {
        store_latitdue: -0.9515442,
        store_longitude: -80.7028169,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9799815,
        store_longitude: -80.7033484,
    },
    {
        store_latitdue: -0.98868,
        store_longitude: -80.6859,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.994012,
        store_longitude: -80.6858,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9630849,
        store_longitude: -80.6891159,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.978377,
        store_longitude: -80.6924,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9695658,
        store_longitude: -80.6999131,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9564502,
        store_longitude: -80.7107665,
    },
    {
        store_latitdue: -0.9602433,
        store_longitude: -80.7031225,
    },
    {
        store_latitdue: -0.9705916,
        store_longitude: -80.7112569,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9749244,
        store_longitude: -80.7004118,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9655699,
        store_longitude: -80.6834039,
    },
    {
        store_latitdue: -0.977895,
        store_longitude: -80.6893,
    },
    {
        store_latitdue: -0.9570105,
        store_longitude: -80.7167206,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9561393,
        store_longitude: -80.7223232,
    },
    {
        store_latitdue: -0.953646,
        store_longitude: -80.7164,
    },
    {
        store_latitdue: -0.9666181,
        store_longitude: -80.6845954,
    },
    {
        store_latitdue: -0.9718072,
        store_longitude: -80.6890593,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9691409,
        store_longitude: -80.7134044,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.961631,
        store_longitude: -80.7169378,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.992152,
        store_longitude: -80.6877,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.958749,
        store_longitude: -80.7105,
    },
    {
        store_latitdue: -0.9793924,
        store_longitude: -80.6983216,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9805558,
        store_longitude: -80.6994156,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9537247,
        store_longitude: -80.7068246,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.394676,
        store_longitude: -80.211967,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.396696,
        store_longitude: -80.211121,
    },
    {
        store_latitdue: -1.348649,
        store_longitude: -80.285232,
        neighborhood: 'BELLAVISTA',
    },
    {
        store_latitdue: -1.0361337,
        store_longitude: -80.4932483,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05136,
        store_longitude: -80.484859,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.036082,
        store_longitude: -80.492384,
    },
    {
        store_latitdue: -1.03614,
        store_longitude: -80.4926,
    },
    {
        store_latitdue: -1.036054,
        store_longitude: -80.492274,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0552153,
        store_longitude: -80.450727,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0600699,
        store_longitude: -80.4454521,
    },
    {
        store_latitdue: -1.0554881,
        store_longitude: -80.447954,
    },
    {
        store_latitdue: -1.059005,
        store_longitude: -80.452168,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0572103,
        store_longitude: -80.4575091,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05747,
        store_longitude: -80.4441,
    },
    {
        store_latitdue: -1.057526,
        store_longitude: -80.44385,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.057649,
        store_longitude: -80.45874,
    },
    {
        store_latitdue: -1.05715,
        store_longitude: -80.4587,
    },
    {
        store_latitdue: -1.05599,
        store_longitude: -80.4572,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.02429,
        store_longitude: -80.4763,
    },
    {
        store_latitdue: -1.05623,
        store_longitude: -80.4574,
    },
    {
        store_latitdue: -1.056887364,
        store_longitude: -80.44336495,
    },
    {
        store_latitdue: -1.055042,
        store_longitude: -80.4567825,
    },
    {
        store_latitdue: -1.4599531,
        store_longitude: -80.4599531,
    },
    {
        store_latitdue: -1.05622,
        store_longitude: -80.4588,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.055694,
        store_longitude: -80.458398,
    },
    {
        store_latitdue: -1.0586,
        store_longitude: -80.4548,
    },
    {
        store_latitdue: -1.0537082,
        store_longitude: -80.4609759,
    },
    {
        store_latitdue: -1.053622,
        store_longitude: -80.449378,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0561041,
        store_longitude: -80.450958,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0531311,
        store_longitude: -80.449384,
    },
    {
        store_latitdue: -1.0518125,
        store_longitude: -80.4553242,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.03505,
        store_longitude: -80.4930986,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05832,
        store_longitude: -80.4467,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.995252,
        store_longitude: -80.4169,
    },
    {
        store_latitdue: -0.9244406,
        store_longitude: -80.4515922,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.92484,
        store_longitude: -80.4532,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9254,
        store_longitude: -80.45034,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9248224,
        store_longitude: -80.4507813,
    },
    {
        store_latitdue: -0.9199785,
        store_longitude: -80.4518435,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.001095,
        store_longitude: -80.396414,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.88658,
        store_longitude: -80.4595,
    },
    {
        store_latitdue: -0.925635,
        store_longitude: -80.4511,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.886711,
        store_longitude: -80.459272,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9251064,
        store_longitude: -80.4520356,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.846894,
        store_longitude: -80.5022,
        neighborhood: 'CRUCITA',
    },

    {
        store_latitdue: -0.983438,
        store_longitude: -80.307377,
    },
    {
        store_latitdue: -0.9927947,
        store_longitude: -80.4139896,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.9236736,
        store_longitude: -80.4508492,
    },
    {
        store_latitdue: -0.923182,
        store_longitude: -80.452747,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.922592,
        store_longitude: -80.450052,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.01054,
        store_longitude: -80.3797,
    },
    {
        store_latitdue: -1.0060606,
        store_longitude: -80.3922959,
    },
    {
        store_latitdue: -0.968243,
        store_longitude: -80.429319,
    },
    {
        store_latitdue: -0.919749,
        store_longitude: -80.445982,
    },
    {
        store_latitdue: -1.07627,
        store_longitude: -80.4473,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.07773,
        store_longitude: -80.4398,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0733851,
        store_longitude: -80.4462986,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.077575,
        store_longitude: -80.4392777,
    },
    {
        store_latitdue: -1.086959,
        store_longitude: -80.434141,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.082315,
        store_longitude: -80.435644,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.086326,
        store_longitude: -80.433184,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.08558,
        store_longitude: -80.4331,
    },
    {
        store_latitdue: -1.09104,
        store_longitude: -80.43257,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.760699,
        store_longitude: -80.510887,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.762517,
        store_longitude: -80.5094,
    },
    {
        store_latitdue: -0.78061,
        store_longitude: -80.5144,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.7805823,
        store_longitude: -80.5175213,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.0519582,
        store_longitude: -80.4531269,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05071,
        store_longitude: -80.4505,
    },
    {
        store_latitdue: -1.0288196,
        store_longitude: -80.3428117,
    },
    {
        store_latitdue: -1.047577,
        store_longitude: -80.454434,
    },
    {
        store_latitdue: -1.052275,
        store_longitude: -80.447589,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0506759,
        store_longitude: -80.450176,
    },
    {
        store_latitdue: -1.0513415,
        store_longitude: -80.4481585,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.049714,
        store_longitude: -80.448915,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.051141,
        store_longitude: -80.449104,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.061642,
        store_longitude: -80.245307,
    },
    {
        store_latitdue: -1.0620817,
        store_longitude: -80.2448957,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -1.064014,
        store_longitude: -80.242123,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -1.205593,
        store_longitude: -80.371768,
    },
    {
        store_latitdue: -1.20548,
        store_longitude: -80.3719,
    },
    {
        store_latitdue: -1.209663,
        store_longitude: -80.367015,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.2074649,
        store_longitude: -80.3713021,
    },
    {
        store_latitdue: -1.20585,
        store_longitude: -80.3717,
    },
    {
        store_latitdue: -1.2075222,
        store_longitude: -80.3728657,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.21043,
        store_longitude: -80.3674,
    },
    {
        store_latitdue: -1.204869,
        store_longitude: -80.374705,
    },
    {
        store_latitdue: -1.20983,
        store_longitude: -80.3696,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.20643,
        store_longitude: -80.3716,
    },
    {
        store_latitdue: -1.2063812,
        store_longitude: -80.3714178,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.2061706,
        store_longitude: -80.3713212,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.206156,
        store_longitude: -80.371336,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.2063625,
        store_longitude: -80.3712344,
    },
    {
        store_latitdue: -0.788363,
        store_longitude: -80.233653,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.774538,
        store_longitude: -80.233422,
    },
    {
        store_latitdue: -0.784964,
        store_longitude: -80.2360463,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.8203196,
        store_longitude: -80.2147534,
        neighborhood: 'ÁNGEL PEDRO GILER',
    },
    {
        store_latitdue: -1.28183,
        store_longitude: -80.419,
    },
    {
        store_latitdue: -1.05414,
        store_longitude: -80.2804,
    },
    {
        store_latitdue: -0.6945667,
        store_longitude: -80.0938888,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6959241,
        store_longitude: -80.0962759,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0396167,
        store_longitude: -80.4664016,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7034774,
        store_longitude: -80.0924204,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.059206,
        store_longitude: -80.469311,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9280325,
        store_longitude: -80.2073263,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.8457024,
        store_longitude: -80.164217,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.844967,
        store_longitude: -80.1635,
    },
    {
        store_latitdue: -1.062713,
        store_longitude: -80.465296,
    },
    {
        store_latitdue: -1.0545031,
        store_longitude: -80.4743064,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.796218,
        store_longitude: -80.125183,
        neighborhood: 'CANUTO',
    },
    {
        store_latitdue: -0.798897,
        store_longitude: -80.1292,
    },
    {
        store_latitdue: -0.8469305,
        store_longitude: -80.1594053,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.06383,
        store_longitude: -80.4372,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06193,
        store_longitude: -80.4377,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.059739,
        store_longitude: -80.439911,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.700934,
        store_longitude: -80.095794,
    },
    {
        store_latitdue: -1.064116,
        store_longitude: -80.445802,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0619821,
        store_longitude: -80.4584683,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8462913,
        store_longitude: -80.165546,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.928941,
        store_longitude: -80.204441,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.8432096,
        store_longitude: -80.1660993,
    },
    {
        store_latitdue: -1.133755,
        store_longitude: -80.254081,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.159455,
        store_longitude: -80.283989,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -0.696672,
        store_longitude: -80.090085,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6947052,
        store_longitude: -80.0944973,
    },
    {
        store_latitdue: -0.927724,
        store_longitude: -80.206992,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.928888,
        store_longitude: -80.205,
    },
    {
        store_latitdue: -1.061748,
        store_longitude: -80.459154,
    },
    {
        store_latitdue: -0.6948746,
        store_longitude: -80.0941043,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.76232,
        store_longitude: -80.5102533,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.13492,
        store_longitude: -80.395018,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.064499,
        store_longitude: -80.464061,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05623,
        store_longitude: -80.4573,
    },
    {
        store_latitdue: -1.05708,
        store_longitude: -80.4572,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7989691,
        store_longitude: -80.1292684,
    },
    {
        store_latitdue: -0.7995719,
        store_longitude: -80.1292936,
        neighborhood: 'CANUTO',
    },
    {
        store_latitdue: -0.997844,
        store_longitude: -80.466713,
    },
    {
        store_latitdue: -1.0522545,
        store_longitude: -80.4486435,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.118336,
        store_longitude: -80.409418,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0621842,
        store_longitude: -80.4582763,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.032496,
        store_longitude: -80.493433,
    },
    {
        store_latitdue: -1.03357,
        store_longitude: -80.492,
    },
    {
        store_latitdue: -1.17959,
        store_longitude: -80.3906,
    },
    {
        store_latitdue: -1.064482,
        store_longitude: -80.567191,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.059083,
        store_longitude: -80.482643,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.072745666,
        store_longitude: -80.49759769,
    },
    {
        store_latitdue: -1.0690051,
        store_longitude: -80.4993217,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.069867,
        store_longitude: -80.498271,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.07246,
        store_longitude: -80.4977,
    },
    {
        store_latitdue: -0.9277705,
        store_longitude: -80.2071527,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -1.05812,
        store_longitude: -80.4581,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0512118,
        store_longitude: -80.4501596,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.033309,
        store_longitude: -80.468358,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.052463,
        store_longitude: -80.444252,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.053895,
        store_longitude: -80.44996,
    },
    {
        store_latitdue: -1.123116,
        store_longitude: -80.408614,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06258,
        store_longitude: -80.452399,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.064133,
        store_longitude: -80.465457,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0589095,
        store_longitude: -80.4703927,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.035921,
        store_longitude: -80.494909,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.03491,
        store_longitude: -80.4935,
    },
    {
        store_latitdue: -1.072486,
        store_longitude: -80.4939366,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.071233,
        store_longitude: -80.489448,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0647546,
        store_longitude: -80.5677568,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.050892,
        store_longitude: -80.486567,
    },
    {
        store_latitdue: -1.0512633,
        store_longitude: -80.4854211,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.069178,
        store_longitude: -80.500664,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05711,
        store_longitude: -80.4587,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.846269,
        store_longitude: -80.1636,
    },
    {
        store_latitdue: -0.4045405,
        store_longitude: -79.906397,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.2799896,
        store_longitude: -80.4186398,
    },
    {
        store_latitdue: -1.111653,
        store_longitude: -80.405994,
    },
    {
        store_latitdue: -1.06085,
        store_longitude: -80.4639,
    },
    {
        store_latitdue: -1.065704,
        store_longitude: -80.468059,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8448519,
        store_longitude: -80.1650575,
    },
    {
        store_latitdue: -1.0533897,
        store_longitude: -80.4616281,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.206046,
        store_longitude: -80.371703,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.140231,
        store_longitude: -80.267223,
    },
    {
        store_latitdue: -1.047361,
        store_longitude: -80.475827,
    },
    {
        store_latitdue: -0.404085,
        store_longitude: -79.902278,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.07981,
        store_longitude: -80.5344,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0589,
        store_longitude: -80.4591,
    },
    {
        store_latitdue: -0.800329,
        store_longitude: -80.1267,
        neighborhood: 'CANUTO',
    },
    {
        store_latitdue: -0.69512,
        store_longitude: -80.0937,
    },
    {
        store_latitdue: -1.27842,
        store_longitude: -80.4219,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.3958991,
        store_longitude: -80.2096074,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.031899,
        store_longitude: -80.468779,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.202628,
        store_longitude: -80.369063,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.177451,
        store_longitude: -80.387601,
    },
    {
        store_latitdue: -1.139292,
        store_longitude: -80.265945,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.1116182,
        store_longitude: -80.212434,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -0.7052819,
        store_longitude: -80.1635394,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -1.0508977,
        store_longitude: -80.2838678,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -0.0883654,
        store_longitude: -80.0883654,
    },
    {
        store_latitdue: -1.04143,
        store_longitude: -80.3203,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.014012,
        store_longitude: -80.368682,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.6950303,
        store_longitude: -80.0915191,
    },
    {
        store_latitdue: -1.059205,
        store_longitude: -80.455244,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.87559,
        store_longitude: -80.54243,
    },
    {
        store_latitdue: -0.917017,
        store_longitude: -80.489307,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0647653,
        store_longitude: -80.5697071,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.80025,
        store_longitude: -80.1267,
    },
    {
        store_latitdue: -0.6975353,
        store_longitude: -80.094305,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.935775,
        store_longitude: -80.202462,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.6947687,
        store_longitude: -80.0947387,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7895826,
        store_longitude: -80.2350832,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.7885416,
        store_longitude: -80.2344475,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -1.27978,
        store_longitude: -80.414,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.398635,
        store_longitude: -80.211468,
    },
    {
        store_latitdue: -1.207124,
        store_longitude: -80.371082,
    },
    {
        store_latitdue: -1.030508,
        store_longitude: -80.340118,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.925829,
        store_longitude: -80.454645,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.101387,
        store_longitude: -80.410296,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.704681,
        store_longitude: -80.099965,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.8782,
        store_longitude: -80.1884,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.843744085,
        store_longitude: -80.17407032,
    },
    {
        store_latitdue: -0.840568,
        store_longitude: -80.1644,
    },
    {
        store_latitdue: -0.9566023,
        store_longitude: -80.4672183,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.97631,
        store_longitude: -80.469899,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.962161,
        store_longitude: -80.469149,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.075818,
        store_longitude: -80.50447,
    },
    {
        store_latitdue: -1.053938,
        store_longitude: -80.47896,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.079867,
        store_longitude: -80.534268,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.707389,
        store_longitude: -80.093823,
    },
    {
        store_latitdue: -1.3951751,
        store_longitude: -80.2115676,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -0.789384,
        store_longitude: -80.234004,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.781526,
        store_longitude: -80.2361,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.821665,
        store_longitude: -80.31891,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.79766,
        store_longitude: -80.24089,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.925515,
        store_longitude: -80.453,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.96508,
        store_longitude: -80.4382,
    },
    {
        store_latitdue: -0.7017167,
        store_longitude: -80.094927,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.054723,
        store_longitude: -80.4524903,
    },
    {
        store_latitdue: -1.057394,
        store_longitude: -80.472252,
    },
    {
        store_latitdue: -0.8780068,
        store_longitude: -80.542167,
    },
    {
        store_latitdue: -0.8586535,
        store_longitude: -80.5336993,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.857319,
        store_longitude: -80.5354,
    },
    {
        store_latitdue: -0.8537763,
        store_longitude: -80.4674899,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.701712,
        store_longitude: -80.085106,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9291895,
        store_longitude: -80.2072725,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -1.055993,
        store_longitude: -80.4571351,
    },
    {
        store_latitdue: -1.0521413,
        store_longitude: -80.4544341,
    },
    {
        store_latitdue: -1.051831,
        store_longitude: -80.286399,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -1.001168,
        store_longitude: -80.397651,
    },
    {
        store_latitdue: -1.081267,
        store_longitude: -80.437575,
    },
    {
        store_latitdue: -0.7073385,
        store_longitude: -80.0967761,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.695708,
        store_longitude: -80.102392,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9587634,
        store_longitude: -80.4678565,
    },
    {
        store_latitdue: -0.979291,
        store_longitude: -80.470667,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0639675,
        store_longitude: -80.4481563,
    },
    {
        store_latitdue: -0.854632,
        store_longitude: -80.4662,
    },

    {
        store_latitdue: -0.8836891,
        store_longitude: -80.4590036,
    },
    {
        store_latitdue: -0.8542763,
        store_longitude: -80.4682923,
    },
    {
        store_latitdue: -0.70079,
        store_longitude: -80.087,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.2461,
        store_longitude: -80.4246,
    },
    {
        store_latitdue: -1.04489,
        store_longitude: -80.481,
    },
    {
        store_latitdue: -1.009708,
        store_longitude: -80.379984,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.96827,
        store_longitude: -80.4281,
    },
    {
        store_latitdue: -0.9260984,
        store_longitude: -80.4519183,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.92395,
        store_longitude: -80.45156,
    },
    {
        store_latitdue: -0.660502,
        store_longitude: -80.2644,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2765288,
        store_longitude: -80.0368612,
        neighborhood: 'CONVENTO',
    },
    {
        store_latitdue: -0.276867,
        store_longitude: -80.036418,
        neighborhood: 'CONVENTO',
    },
    {
        store_latitdue: -1.04278,
        store_longitude: -80.4931,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.690477,
        store_longitude: -80.3250012,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.6694421,
        store_longitude: -80.3496355,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.7010822,
        store_longitude: -80.0738233,
    },
    {
        store_latitdue: -0.6970107,
        store_longitude: -80.0875079,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.702368,
        store_longitude: -80.08886,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7054081,
        store_longitude: -80.0902449,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.845575,
        store_longitude: -80.164396,
    },
    {
        store_latitdue: -0.850245,
        store_longitude: -80.163921,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.93116,
        store_longitude: -80.205134,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -1.0622897,
        store_longitude: -80.219262,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -0.405648,
        store_longitude: -79.905485,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.5033404,
        store_longitude: -80.0391682,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.5829888,
        store_longitude: -80.0409345,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.867504,
        store_longitude: -80.5373,
    },
    {
        store_latitdue: -0.844643,
        store_longitude: -80.159954,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.9475665,
        store_longitude: -80.4615528,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.050844,
        store_longitude: -80.452096,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05928,
        store_longitude: -80.4438,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7866632,
        store_longitude: -80.2341136,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.6958016,
        store_longitude: -80.1004601,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6948975,
        store_longitude: -80.0938715,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.777991,
        store_longitude: -80.236645,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.7038837,
        store_longitude: -80.0974128,
    },
    {
        store_latitdue: -0.6946278,
        store_longitude: -80.0945978,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.8358442,
        store_longitude: -80.4899905,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.2803859,
        store_longitude: -80.4185195,
    },
    {
        store_latitdue: -0.877032,
        store_longitude: -80.386126,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0599396,
        store_longitude: -80.4451425,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0440113,
        store_longitude: -80.4741526,
    },
    {
        store_latitdue: -0.9305073,
        store_longitude: -80.2071965,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.9474845,
        store_longitude: -80.4836648,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.930694,
        store_longitude: -80.448,
    },
    {
        store_latitdue: -1.071959,
        store_longitude: -80.497986,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.052212,
        store_longitude: -80.485054,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0299825,
        store_longitude: -80.3423925,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.0519465,
        store_longitude: -80.4481672,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.054399,
        store_longitude: -80.443054,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.1356454,
        store_longitude: -80.3945851,
    },
    {
        store_latitdue: -0.987636,
        store_longitude: -80.423622,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -1.124491,
        store_longitude: -80.4045551,
    },
    {
        store_latitdue: -0.869576,
        store_longitude: -80.527989,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.696727,
        store_longitude: -80.0826579,
    },
    {
        store_latitdue: -0.998344,
        store_longitude: -80.309719,
    },
    {
        store_latitdue: -0.982805,
        store_longitude: -80.305861,
        neighborhood: 'PUEBLO NUEVO',
    },
    {
        store_latitdue: -0.97446,
        store_longitude: -80.420208,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.4015149,
        store_longitude: -79.9091398,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.127765,
        store_longitude: -80.403078,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.878207,
        store_longitude: -80.542439,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.113706,
        store_longitude: -80.201596,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -1.1247813,
        store_longitude: -80.4063917,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.12427,
        store_longitude: -80.4053,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06191,
        store_longitude: -80.475283,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.85421,
        store_longitude: -80.46755,
    },
    {
        store_latitdue: -0.843118,
        store_longitude: -80.345747,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.7715527,
        store_longitude: -80.2571429,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -1.0124503,
        store_longitude: -80.4578004,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.056391,
        store_longitude: -80.260926,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -0.6922808,
        store_longitude: -80.0931906,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7070624,
        store_longitude: -80.1229556,
    },
    {
        store_latitdue: -0.6888864,
        store_longitude: -80.0947051,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.69022,
        store_longitude: -80.0936,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6894547,
        store_longitude: -80.0984178,
    },
    {
        store_latitdue: -0.6912915,
        store_longitude: -80.3216769,
    },
    {
        store_latitdue: -0.6948803,
        store_longitude: -80.0913402,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9512735,
        store_longitude: -80.4414393,
    },
    {
        store_latitdue: -1.0899441,
        store_longitude: -80.4166993,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6893331,
        store_longitude: -80.0951865,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0596599,
        store_longitude: -80.4649843,
    },
    {
        store_latitdue: -1.06363,
        store_longitude: -80.4513,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.03438,
        store_longitude: -80.4921,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0421607,
        store_longitude: -80.3569728,
    },
    {
        store_latitdue: -1.0248893,
        store_longitude: -80.3904527,
    },
    {
        store_latitdue: -0.9269732,
        store_longitude: -80.4553478,
    },
    {
        store_latitdue: -0.837597,
        store_longitude: -80.4884,
    },
    {
        store_latitdue: -0.76953,
        store_longitude: -80.22113,
        neighborhood: 'BACHILLERO',
    },
    {
        store_latitdue: -0.27383,
        store_longitude: -80.0363,
        neighborhood: 'CONVENTO',
    },
    {
        store_latitdue: -1.2810501,
        store_longitude: -80.4184445,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.404209,
        store_longitude: -79.9052,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.0201997,
        store_longitude: -80.3860878,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.0565567,
        store_longitude: -80.4652665,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.076278,
        store_longitude: -80.4508174,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.110118,
        store_longitude: -80.222482,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -0.8571386,
        store_longitude: -80.5353805,
    },
    {
        store_latitdue: -0.9553673,
        store_longitude: -80.4612218,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.1466359,
        store_longitude: -80.2973907,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.17879,
        store_longitude: -80.3903,
    },
    {
        store_latitdue: -1.2887967,
        store_longitude: -80.4105454,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.8477554,
        store_longitude: -80.5025804,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.058562,
        store_longitude: -80.454826,
    },
    {
        store_latitdue: -0.414841,
        store_longitude: -80.052213,
    },
    {
        store_latitdue: -1.0510287,
        store_longitude: -80.4856176,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.13462,
        store_longitude: -80.3953,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0678643,
        store_longitude: -80.46606,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0564248,
        store_longitude: -80.4850265,
    },
    {
        store_latitdue: -1.064153,
        store_longitude: -80.46502,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.065891,
        store_longitude: -80.46646,
    },
    {
        store_latitdue: -1.022395,
        store_longitude: -80.463563,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6928953,
        store_longitude: -80.09963,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.80914,
        store_longitude: -80.2206,
        neighborhood: 'ÁNGEL PEDRO GILER',
    },
    {
        store_latitdue: -0.938347,
        store_longitude: -80.239644,
    },
    {
        store_latitdue: -0.6905289,
        store_longitude: -80.3251821,
    },
    {
        store_latitdue: -0.85387,
        store_longitude: -80.47205,
    },
    {
        store_latitdue: -0.955406,
        store_longitude: -80.723,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.848073,
        store_longitude: -80.1573,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.6235486,
        store_longitude: -80.4257957,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.6044906,
        store_longitude: -80.4004226,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.5925616,
        store_longitude: -80.4066312,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.6251513,
        store_longitude: -80.4254356,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.60306,
        store_longitude: -80.4256,
    },
    {
        store_latitdue: -0.603035,
        store_longitude: -80.42571,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.625499,
        store_longitude: -80.425486,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.656051,
        store_longitude: -80.405798,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },

    {
        store_latitdue: -0.9186673,
        store_longitude: -80.451276,
    },
    {
        store_latitdue: -0.595646,
        store_longitude: -80.408748,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.592372,
        store_longitude: -80.4057,
    },
    {
        store_latitdue: -0.5239383,
        store_longitude: -80.4415692,
        neighborhood: 'CANOA',
    },

    {
        store_latitdue: -0.9553795,
        store_longitude: -80.7232171,
    },
    {
        store_latitdue: -0.596407,
        store_longitude: -80.406506,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -1.058522,
        store_longitude: -80.441476,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.065167,
        store_longitude: -80.45296,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.59592,
        store_longitude: -80.4092,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.622837,
        store_longitude: -80.425807,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.6221385,
        store_longitude: -80.4269443,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -1.05756,
        store_longitude: -80.4483,
    },
    {
        store_latitdue: -0.976377,
        store_longitude: -80.6867,
    },
    {
        store_latitdue: -1.039203,
        store_longitude: -80.493282,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7725023,
        store_longitude: -80.5140811,
    },
    {
        store_latitdue: -0.46121,
        store_longitude: -80.4545,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.6949031,
        store_longitude: -80.0946139,
    },
    {
        store_latitdue: -1.0608658,
        store_longitude: -80.4381153,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.836733,
        store_longitude: -80.172749,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.856457,
        store_longitude: -80.4710745,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.05492,
        store_longitude: -80.2805,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -0.9810404,
        store_longitude: -80.7041846,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9849605,
        store_longitude: -80.706377,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.987095,
        store_longitude: -80.7075,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.5940054,
        store_longitude: -80.4087126,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9612915,
        store_longitude: -80.7193194,
    },
    {
        store_latitdue: -0.955298,
        store_longitude: -80.7187,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9580149,
        store_longitude: -80.7061767,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.956688,
        store_longitude: -80.7064,
    },
    {
        store_latitdue: -0.9597065,
        store_longitude: -80.7142555,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9566961,
        store_longitude: -80.7146962,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9675722,
        store_longitude: -80.6964391,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9795328,
        store_longitude: -80.7029073,
    },
    {
        store_latitdue: -0.9658305,
        store_longitude: -80.7127334,
    },
    {
        store_latitdue: -0.9690509,
        store_longitude: -80.7045949,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9606942,
        store_longitude: -80.7109488,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9529435,
        store_longitude: -80.7074058,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.69388,
        store_longitude: -80.0904,
    },
    {
        store_latitdue: -1.062055,
        store_longitude: -80.4591445,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7067324,
        store_longitude: -80.0942596,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.705573,
        store_longitude: -80.0908,
    },
    {
        store_latitdue: -0.9313141,
        store_longitude: -80.2064003,
    },
    {
        store_latitdue: -0.6860192,
        store_longitude: -80.0635136,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.08053,
        store_longitude: -80.4381,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.962839,
        store_longitude: -80.7128,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.06218,
        store_longitude: -80.457,
    },
    {
        store_latitdue: -1.027936,
        store_longitude: -80.3504773,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.8591848,
        store_longitude: -80.5317971,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.9473703,
        store_longitude: -80.6398326,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9626894,
        store_longitude: -80.7176813,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.80562,
        store_longitude: -80.2537,
    },
    {
        store_latitdue: -1.06181,
        store_longitude: -80.2452,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -0.959761,
        store_longitude: -80.7189,
    },
    {
        store_latitdue: -1.061629,
        store_longitude: -80.245594,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -0.86585,
        store_longitude: -80.1918,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.9591205,
        store_longitude: -80.7186563,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.029562,
        store_longitude: -80.467968,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0620911,
        store_longitude: -80.4586829,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6945919,
        store_longitude: -80.0944451,
    },
    {
        store_latitdue: -1.0780818,
        store_longitude: -80.4393454,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8376989,
        store_longitude: -80.4882397,
        neighborhood: 'CHARAPOTÓ',
    },

    {
        store_latitdue: -0.695007,
        store_longitude: -80.0941392,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.028712,
        store_longitude: -80.353429,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.99401,
        store_longitude: -80.467794,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.059598,
        store_longitude: -80.473414,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.4659036,
        store_longitude: -80.453802,
    },
    {
        store_latitdue: -0.924952,
        store_longitude: -80.446369,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.7965176,
        store_longitude: -80.1261272,
        neighborhood: 'CANUTO',
    },
    {
        store_latitdue: -0.700468,
        store_longitude: -80.0873,
    },
    {
        store_latitdue: -0.405184,
        store_longitude: -79.905637,
    },
    {
        store_latitdue: -1.09246,
        store_longitude: -80.4315,
    },
    {
        store_latitdue: -0.404455,
        store_longitude: -79.906135,
    },
    {
        store_latitdue: -1.034467,
        store_longitude: -80.494148,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6948702,
        store_longitude: -80.0909512,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9507,
        store_longitude: -80.4423,
    },
    {
        store_latitdue: -1.072229,
        store_longitude: -80.492552,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8682855,
        store_longitude: -80.5087732,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.785477,
        store_longitude: -80.235859,
    },
    {
        store_latitdue: -0.868028,
        store_longitude: -80.507878,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.140342,
        store_longitude: -80.391045,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.012362,
        store_longitude: -80.46567,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.20595,
        store_longitude: -80.3718,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.1764988,
        store_longitude: -80.3129594,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.05567,
        store_longitude: -80.4479,
    },
    {
        store_latitdue: -1.530176,
        store_longitude: -80.231179,
        neighborhood: 'LASCANO',
    },
    {
        store_latitdue: -1.0576022,
        store_longitude: -80.475062,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8373416,
        store_longitude: -80.4889187,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.694642,
        store_longitude: -80.093121,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.88026,
        store_longitude: -80.545,
    },
    {
        store_latitdue: -1.0165341,
        store_longitude: -80.4656535,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0810349,
        store_longitude: -80.4386737,
    },
    {
        store_latitdue: -1.0489139,
        store_longitude: -80.4698923,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.911899,
        store_longitude: -80.425612,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9206518,
        store_longitude: -80.4483547,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9779604,
        store_longitude: -80.6963078,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.1574009,
        store_longitude: -80.2859331,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.062406,
        store_longitude: -80.452545,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0621891,
        store_longitude: -80.4582253,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.057594,
        store_longitude: -80.459071,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9229639,
        store_longitude: -80.4794581,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.6974089,
        store_longitude: -80.0839119,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.886771,
        store_longitude: -80.4584122,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.2805,
        store_longitude: -80.4185,
    },
    {
        store_latitdue: -0.408322,
        store_longitude: -79.903935,
    },
    {
        store_latitdue: -0.878971,
        store_longitude: -80.5438,
    },
    {
        store_latitdue: -0.694042,
        store_longitude: -80.095109,
    },
    {
        store_latitdue: -1.045244,
        store_longitude: -80.472305,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.783903,
        store_longitude: -80.238,
    },
    {
        store_latitdue: -0.947332,
        store_longitude: -80.4837,
    },
    {
        store_latitdue: -0.827707,
        store_longitude: -80.4951,
    },
    {
        store_latitdue: -0.772419,
        store_longitude: -80.5138252,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.922886,
        store_longitude: -80.4795,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.853942,
        store_longitude: -80.4684,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.694478,
        store_longitude: -80.0938493,
    },
    {
        store_latitdue: -0.97792,
        store_longitude: -80.7043,
    },
    {
        store_latitdue: -1.2296376,
        store_longitude: -80.4209422,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.01417,
        store_longitude: -80.3808,
    },
    {
        store_latitdue: -1.0011418,
        store_longitude: -80.3951982,
    },
    {
        store_latitdue: -0.8972877,
        store_longitude: -80.4878024,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.6952489,
        store_longitude: -80.0865023,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0448395,
        store_longitude: -80.4736347,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0522291,
        store_longitude: -80.4440823,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0655722,
        store_longitude: -80.4576781,
    },

    {
        store_latitdue: -0.4050134,
        store_longitude: -79.9057419,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.9305454,
        store_longitude: -80.2069984,
    },
    {
        store_latitdue: -1.2058927,
        store_longitude: -80.3717588,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0787,
        store_longitude: -80.455312,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.058238,
        store_longitude: -80.457879,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8438511,
        store_longitude: -80.4798823,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.9960762,
        store_longitude: -80.4175227,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.9616742,
        store_longitude: -80.7135565,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.21059,
        store_longitude: -80.3674,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0538606,
        store_longitude: -80.4518421,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.050338,
        store_longitude: -80.48938,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.051482,
        store_longitude: -80.48768,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.047661,
        store_longitude: -80.455069,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.843923,
        store_longitude: -80.169179,
    },
    {
        store_latitdue: -1.031738,
        store_longitude: -80.460993,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.982472,
        store_longitude: -80.2977,
        neighborhood: 'PUEBLO NUEVO',
    },
    {
        store_latitdue: -0.810947,
        store_longitude: -80.2641,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -1.058575,
        store_longitude: -80.465049,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.055492,
        store_longitude: -80.44028,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.09427,
        store_longitude: -80.425425,
    },
    {
        store_latitdue: -1.0609635,
        store_longitude: -80.4897101,
    },
    {
        store_latitdue: -1.03928,
        store_longitude: -80.4663,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.039096,
        store_longitude: -80.468502,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.95852,
        store_longitude: -80.4429,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.038956,
        store_longitude: -80.491722,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9978917,
        store_longitude: -80.3107048,
    },
    {
        store_latitdue: -0.407267,
        store_longitude: -79.904948,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.0639,
        store_longitude: -80.4459,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0620896,
        store_longitude: -80.4588901,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.844274,
        store_longitude: -80.497663,
    },
    {
        store_latitdue: -1.18172,
        store_longitude: -80.3903,
    },
    {
        store_latitdue: -1.062837,
        store_longitude: -80.460833,
    },
    {
        store_latitdue: -1.041568,
        store_longitude: -80.468483,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0593132,
        store_longitude: -80.4756206,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.837473,
        store_longitude: -80.4865,
    },
    {
        store_latitdue: -1.2082833,
        store_longitude: -80.3817385,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.8604664,
        store_longitude: -80.3692952,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.648272,
        store_longitude: -80.409559,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.636873,
        store_longitude: -80.423342,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.84371,
        store_longitude: -80.47999,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.83874,
        store_longitude: -80.48776,
    },
    {
        store_latitdue: -0.20818,
        store_longitude: -80.259843,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.203671,
        store_longitude: -80.261292,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.205753,
        store_longitude: -80.261564,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.463859,
        store_longitude: -80.454487,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.59155,
        store_longitude: -80.405346,
    },
    {
        store_latitdue: -0.5961649,
        store_longitude: -80.4086131,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.60386,
        store_longitude: -80.402,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.6020583,
        store_longitude: -80.4029217,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.597189,
        store_longitude: -80.408411,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.602974,
        store_longitude: -80.4257929,
    },
    {
        store_latitdue: -0.8378379,
        store_longitude: -80.4888332,
    },
    {
        store_latitdue: -0.758809,
        store_longitude: -80.5112,
    },
    {
        store_latitdue: -0.654019,
        store_longitude: -80.406551,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.83745,
        store_longitude: -80.48891,
    },
    {
        store_latitdue: -0.7845253,
        store_longitude: -80.5143348,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.2087259,
        store_longitude: -80.2590327,
    },
    {
        store_latitdue: -1.052914,
        store_longitude: -80.449525,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.598234,
        store_longitude: -80.404303,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.625372,
        store_longitude: -80.425446,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.622536,
        store_longitude: -80.425438,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.463677,
        store_longitude: -80.456281,
    },

    {
        store_latitdue: -0.6017865,
        store_longitude: -80.4261881,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.855524537,
        store_longitude: -80.47123756,
    },
    {
        store_latitdue: -0.2091361,
        store_longitude: -80.257266,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.20929,
        store_longitude: -80.2591,
        neighborhood: 'JAMA',
    },

    {
        store_latitdue: -0.2025732,
        store_longitude: -80.262098,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.0634158,
        store_longitude: -80.4573793,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.83676,
        store_longitude: -80.5043,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.760398,
        store_longitude: -80.511861,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.2062,
        store_longitude: -80.364469,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.96081482,
        store_longitude: -80.44131261,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.465596,
        store_longitude: -80.455674,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.465276,
        store_longitude: -80.455129,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -1.2782708,
        store_longitude: -80.4243066,
    },
    {
        store_latitdue: -1.054723,
        store_longitude: -80.4524903,
    },
    {
        store_latitdue: -0.6995968,
        store_longitude: -80.089998,
    },

    {
        store_latitdue: -1.193054,
        store_longitude: -80.385408,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.3792828,
        store_longitude: -80.1861094,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.9529277,
        store_longitude: -80.7078055,
    },
    {
        store_latitdue: -0.952569,
        store_longitude: -80.7089,
    },
    {
        store_latitdue: -0.9518188,
        store_longitude: -80.7047302,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.005285,
        store_longitude: -80.377584,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -1.030579,
        store_longitude: -80.339604,
    },
    {
        store_latitdue: -1.079592,
        store_longitude: -80.438508,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7595147,
        store_longitude: -80.5103085,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.7595815,
        store_longitude: -80.5111219,
    },
    {
        store_latitdue: -0.786665,
        store_longitude: -80.5144,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.0710576,
        store_longitude: -80.4340429,
    },
    {
        store_latitdue: -1.0507609,
        store_longitude: -80.4499365,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.463527,
        store_longitude: -80.4568579,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -1.059891,
        store_longitude: -80.490053,
    },
    {
        store_latitdue: -1.05355,
        store_longitude: -80.26237,
    },
    {
        store_latitdue: -0.9885982,
        store_longitude: -80.4697728,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.99266,
        store_longitude: -80.4143,
    },
    {
        store_latitdue: -0.826995,
        store_longitude: -80.521937,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.991804,
        store_longitude: -80.464904,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.653879,
        store_longitude: -80.405487,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -1.06244,
        store_longitude: -80.4752,
    },
    {
        store_latitdue: -0.9915826,
        store_longitude: -80.4671279,
    },
    {
        store_latitdue: -1.1111391,
        store_longitude: -80.414004,
    },
    {
        store_latitdue: -0.8284977,
        store_longitude: -80.4952491,
    },
    {
        store_latitdue: -0.9815151,
        store_longitude: -80.698509,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.07112,
        store_longitude: -80.4757,
    },
    {
        store_latitdue: -0.8376919,
        store_longitude: -80.4879483,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.956369,
        store_longitude: -80.711856,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9558171,
        store_longitude: -80.7128764,
    },
    {
        store_latitdue: -0.9577838,
        store_longitude: -80.7090636,
    },
    {
        store_latitdue: -0.958845227,
        store_longitude: -80.71003261,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9557918,
        store_longitude: -80.7126189,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.956642,
        store_longitude: -80.7098,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.954632,
        store_longitude: -80.7201,
    },
    {
        store_latitdue: -0.954391,
        store_longitude: -80.7199,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9582729,
        store_longitude: -80.7126194,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.960493,
        store_longitude: -80.7168895,
    },
    {
        store_latitdue: -0.9551598,
        store_longitude: -80.7142588,
    },
    {
        store_latitdue: -0.9544249,
        store_longitude: -80.7131524,
    },
    {
        store_latitdue: -0.95483,
        store_longitude: -80.7109,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95118,
        store_longitude: -80.71068,
    },
    {
        store_latitdue: -0.95462844,
        store_longitude: -80.7089188,
    },
    {
        store_latitdue: -0.956456,
        store_longitude: -80.708,
    },
    {
        store_latitdue: -1.4101737,
        store_longitude: -80.3940908,
        neighborhood: 'NOBOA',
    },
    {
        store_latitdue: -1.410594,
        store_longitude: -80.392545,
        neighborhood: 'NOBOA',
    },
    {
        store_latitdue: -0.9556456,
        store_longitude: -80.7155495,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95413,
        store_longitude: -80.7147,
    },
    {
        store_latitdue: -0.9528028,
        store_longitude: -80.7072039,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95495,
        store_longitude: -80.716481,
    },
    {
        store_latitdue: -0.846965,
        store_longitude: -80.169653,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.982093,
        store_longitude: -80.305971,
        neighborhood: 'PUEBLO NUEVO',
    },
    {
        store_latitdue: -0.959691,
        store_longitude: -80.716116,
    },
    {
        store_latitdue: -0.9693343,
        store_longitude: -80.7094307,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9549771,
        store_longitude: -80.7184855,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9549652,
        store_longitude: -80.7191426,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.952281,
        store_longitude: -80.72,
    },
    {
        store_latitdue: -0.9583424,
        store_longitude: -80.7457358,
    },
    {
        store_latitdue: -0.957406,
        store_longitude: -80.7523,
    },
    {
        store_latitdue: -0.464695,
        store_longitude: -80.456509,
    },
    {
        store_latitdue: -0.4637196,
        store_longitude: -80.4556409,
        neighborhood: 'CANOA',
    },

    {
        store_latitdue: -0.626869,
        store_longitude: -80.425006,
    },

    {
        store_latitdue: -0.653694,
        store_longitude: -80.405687,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.9591055,
        store_longitude: -80.7248206,
    },
    {
        store_latitdue: -0.953298,
        store_longitude: -80.721573,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.984412,
        store_longitude: -80.7076,
    },
    {
        store_latitdue: -0.9593949,
        store_longitude: -80.724985,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.589967,
        store_longitude: -80.407123,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.5889515,
        store_longitude: -80.4078211,
    },
    {
        store_latitdue: -0.586944,
        store_longitude: -80.40653,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.6047266,
        store_longitude: -80.402048,
    },
    {
        store_latitdue: -0.591834,
        store_longitude: -80.408441,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.5916544,
        store_longitude: -80.4048604,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9472529,
        store_longitude: -80.6382047,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9623623,
        store_longitude: -80.6367511,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9619077,
        store_longitude: -80.6366769,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9489332,
        store_longitude: -80.6388298,
    },
    {
        store_latitdue: -0.9591878,
        store_longitude: -80.6350676,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9550888,
        store_longitude: -80.6369441,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.956056,
        store_longitude: -80.6376,
    },
    {
        store_latitdue: -0.9560251,
        store_longitude: -80.6384944,
    },
    {
        store_latitdue: -0.948386,
        store_longitude: -80.6419747,
    },
    {
        store_latitdue: -0.9463291,
        store_longitude: -80.6388014,
    },
    {
        store_latitdue: -0.9460884,
        store_longitude: -80.6419698,
    },
    {
        store_latitdue: -0.9602563,
        store_longitude: -80.71802,
    },
    {
        store_latitdue: -0.9609999,
        store_longitude: -80.7191801,
    },
    {
        store_latitdue: -0.9591194,
        store_longitude: -80.7153012,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9588663,
        store_longitude: -80.7162218,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9605815,
        store_longitude: -80.7170272,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.28081,
        store_longitude: -80.4185,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.967478,
        store_longitude: -80.7229,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.96898,
        store_longitude: -80.7204671,
    },
    {
        store_latitdue: -0.9580064,
        store_longitude: -80.7085168,
    },
    {
        store_latitdue: -0.9619336,
        store_longitude: -80.7116789,
    },
    {
        store_latitdue: -0.9639914,
        store_longitude: -80.7132175,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9713313,
        store_longitude: -80.7104518,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9688874,
        store_longitude: -80.707426,
    },
    {
        store_latitdue: -0.965738,
        store_longitude: -80.7146,
    },
    {
        store_latitdue: -0.9657386,
        store_longitude: -80.7135061,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9656968,
        store_longitude: -80.7133421,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9697084,
        store_longitude: -80.7145706,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9523258,
        store_longitude: -80.7027642,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9515565,
        store_longitude: -80.7025789,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9522858,
        store_longitude: -80.7003755,
    },
    {
        store_latitdue: -0.9526318,
        store_longitude: -80.6977941,
    },
    {
        store_latitdue: -0.954347,
        store_longitude: -80.6981839,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9544229,
        store_longitude: -80.6993159,
    },
    {
        store_latitdue: -0.9540426,
        store_longitude: -80.696401,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9546673,
        store_longitude: -80.6971609,
    },
    {
        store_latitdue: -0.952612,
        store_longitude: -80.695,
    },
    {
        store_latitdue: -0.9570035,
        store_longitude: -80.7051079,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9577106,
        store_longitude: -80.7069386,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9568933,
        store_longitude: -80.7052694,
    },
    {
        store_latitdue: -0.9581654,
        store_longitude: -80.7049954,
    },
    {
        store_latitdue: -0.9601027,
        store_longitude: -80.7059045,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95612,
        store_longitude: -80.7065,
    },
    {
        store_latitdue: -0.9571006,
        store_longitude: -80.7049237,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9701166,
        store_longitude: -80.7124758,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9707072,
        store_longitude: -80.7130112,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9646163,
        store_longitude: -80.7089588,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.94472,
        store_longitude: -80.6374,
    },
    {
        store_latitdue: -0.9651514,
        store_longitude: -80.7104458,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.966327,
        store_longitude: -80.7112,
    },
    {
        store_latitdue: -0.9506568,
        store_longitude: -80.7041168,
    },
    {
        store_latitdue: -0.9534096,
        store_longitude: -80.7066679,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9506431,
        store_longitude: -80.7041977,
    },
    {
        store_latitdue: -0.951379,
        store_longitude: -80.7043,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.953196,
        store_longitude: -80.7037,
    },
    {
        store_latitdue: -0.9619,
        store_longitude: -80.688,
    },
    {
        store_latitdue: -0.961479,
        store_longitude: -80.6984,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.96262,
        store_longitude: -80.6963,
    },
    {
        store_latitdue: -0.9631097,
        store_longitude: -80.6969186,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.964221,
        store_longitude: -80.6976385,
    },
    {
        store_latitdue: -0.9607431,
        store_longitude: -80.6983997,
    },
    {
        store_latitdue: -0.9632694,
        store_longitude: -80.6981641,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.96342,
        store_longitude: -80.6994539,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9635241,
        store_longitude: -80.700433,
    },
    {
        store_latitdue: -0.9627767,
        store_longitude: -80.7025534,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9598873,
        store_longitude: -80.684242,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95521,
        store_longitude: -80.7186,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9866773,
        store_longitude: -80.7030323,
    },
    {
        store_latitdue: -0.981158,
        store_longitude: -80.7069,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.986809,
        store_longitude: -80.7022,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9862771,
        store_longitude: -80.7021946,
    },
    {
        store_latitdue: -0.9842779,
        store_longitude: -80.7049211,
    },
    {
        store_latitdue: -0.983885,
        store_longitude: -80.706,
    },
    {
        store_latitdue: -0.9821104,
        store_longitude: -80.7045918,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9699538,
        store_longitude: -80.7058971,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9728398,
        store_longitude: -80.7090873,
    },
    {
        store_latitdue: -0.9691642,
        store_longitude: -80.70445,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9715702,
        store_longitude: -80.7050539,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9727582,
        store_longitude: -80.7037398,
    },
    {
        store_latitdue: -0.9738696,
        store_longitude: -80.708726,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9709215,
        store_longitude: -80.7066559,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.99646193,
        store_longitude: -80.69476399,
    },
    {
        store_latitdue: -0.9809245,
        store_longitude: -80.6972202,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9818731,
        store_longitude: -80.6993942,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.978922,
        store_longitude: -80.6979,
    },
    {
        store_latitdue: -0.955994,
        store_longitude: -80.6764,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9771708,
        store_longitude: -80.7033643,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.975035,
        store_longitude: -80.7052,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9758389,
        store_longitude: -80.7048931,
    },
    {
        store_latitdue: -0.9756822,
        store_longitude: -80.7034221,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9743824,
        store_longitude: -80.7018729,
    },
    {
        store_latitdue: -0.9746407,
        store_longitude: -80.7057038,
    },
    {
        store_latitdue: -0.9769003,
        store_longitude: -80.7053259,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9698864,
        store_longitude: -80.6901924,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.965716,
        store_longitude: -80.6935,
    },
    {
        store_latitdue: -0.9645413,
        store_longitude: -80.6956487,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9636397,
        store_longitude: -80.6952733,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9675414,
        store_longitude: -80.6938509,
    },
    {
        store_latitdue: -0.9681267,
        store_longitude: -80.6963177,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.964431,
        store_longitude: -80.6895717,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9602463,
        store_longitude: -80.6872702,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9620542,
        store_longitude: -80.6883303,
    },
    {
        store_latitdue: -0.9625301,
        store_longitude: -80.6883139,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9705243,
        store_longitude: -80.6959831,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9703915,
        store_longitude: -80.6983859,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9709088,
        store_longitude: -80.6963254,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9667017,
        store_longitude: -80.7004054,
    },
    {
        store_latitdue: -0.985639,
        store_longitude: -80.7016,
    },
    {
        store_latitdue: -0.981991,
        store_longitude: -80.7015,
    },
    {
        store_latitdue: -0.984069,
        store_longitude: -80.70111,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9848524,
        store_longitude: -80.7001865,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9830099,
        store_longitude: -80.7003412,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.985328,
        store_longitude: -80.6996,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.760058,
        store_longitude: -80.512435,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.760493,
        store_longitude: -80.511504,
    },
    {
        store_latitdue: -0.9785162,
        store_longitude: -80.7027921,
    },
    {
        store_latitdue: -0.9744686,
        store_longitude: -80.7013884,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9741288,
        store_longitude: -80.7009426,
    },
    {
        store_latitdue: -0.973585,
        store_longitude: -80.7009122,
    },
    {
        store_latitdue: -1.07055,
        store_longitude: -80.442,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9533073,
        store_longitude: -80.6534482,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.960324,
        store_longitude: -80.6694,
    },
    {
        store_latitdue: -0.9567675,
        store_longitude: -80.6670724,
    },
    {
        store_latitdue: -0.9630172,
        store_longitude: -80.6712257,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9824245,
        store_longitude: -80.7082506,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.984951,
        store_longitude: -80.7092,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.981321,
        store_longitude: -80.7109,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.987712,
        store_longitude: -80.7083779,
    },
    {
        store_latitdue: -0.9680106,
        store_longitude: -80.6833092,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9654208,
        store_longitude: -80.6831991,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9647888,
        store_longitude: -80.6822739,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9656362,
        store_longitude: -80.6792586,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9660352,
        store_longitude: -80.6828656,
    },
    {
        store_latitdue: -0.976771,
        store_longitude: -80.6903523,
    },
    {
        store_latitdue: -0.9778831,
        store_longitude: -80.6907086,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9768532,
        store_longitude: -80.6963605,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9838473,
        store_longitude: -80.6899285,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9844739,
        store_longitude: -80.6900889,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9729003,
        store_longitude: -80.6922696,
    },
    {
        store_latitdue: -0.9808308,
        store_longitude: -80.6925422,
    },
    {
        store_latitdue: -0.9769917,
        store_longitude: -80.6904988,
    },
    {
        store_latitdue: -1.00046,
        store_longitude: -80.6937,
    },
    {
        store_latitdue: -0.9824442,
        store_longitude: -80.6807953,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9832519,
        store_longitude: -80.6791044,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.981737,
        store_longitude: -80.6813,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9815971,
        store_longitude: -80.6801753,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9806954,
        store_longitude: -80.6787747,
    },
    {
        store_latitdue: -0.986826,
        store_longitude: -80.6925,
    },
    {
        store_latitdue: -0.9868596,
        store_longitude: -80.6873634,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9788366,
        store_longitude: -80.6809147,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9855319,
        store_longitude: -80.6846715,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9715106,
        store_longitude: -80.6801706,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9722617,
        store_longitude: -80.6756895,
    },
    {
        store_latitdue: -0.9680718,
        store_longitude: -80.6783106,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9692388,
        store_longitude: -80.6779815,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9817273,
        store_longitude: -80.6858748,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9812571,
        store_longitude: -80.6870268,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9807145,
        store_longitude: -80.6855196,
    },
    {
        store_latitdue: -0.9812083,
        store_longitude: -80.6870899,
    },
    {
        store_latitdue: -0.9809798,
        store_longitude: -80.6875299,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9835794,
        store_longitude: -80.6886756,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9811879,
        store_longitude: -80.6871449,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9815117,
        store_longitude: -80.6883957,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9741516,
        store_longitude: -80.7115949,
    },
    {
        store_latitdue: -0.9683281,
        store_longitude: -80.7095532,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9693656,
        store_longitude: -80.7079177,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9718414,
        store_longitude: -80.7082649,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9723,
        store_longitude: -80.706,
    },
    {
        store_latitdue: -0.4634583,
        store_longitude: -80.4555709,
    },
    {
        store_latitdue: -0.9550355,
        store_longitude: -80.7220048,
    },
    {
        store_latitdue: -0.956702,
        store_longitude: -80.7227644,
    },
    {
        store_latitdue: -1.05671,
        store_longitude: -80.4404,
    },
    {
        store_latitdue: -0.7556107,
        store_longitude: -80.5099501,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.9779635,
        store_longitude: -80.7018187,
    },
    {
        store_latitdue: -0.8637694,
        store_longitude: -80.5376885,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.9839671,
        store_longitude: -80.7057939,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0573979,
        store_longitude: -80.4585305,
    },
    {
        store_latitdue: -0.622854,
        store_longitude: -80.4312,
    },
    {
        store_latitdue: -1.124906,
        store_longitude: -80.406787,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.038921,
        store_longitude: -80.468031,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.2784866666,
        store_longitude: -80.0359483,
        neighborhood: 'CONVENTO',
    },
    {
        store_latitdue: -0.403598,
        store_longitude: -79.906987,
    },
    {
        store_latitdue: -0.9660159,
        store_longitude: -80.6856294,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.6207939,
        store_longitude: -80.426339,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.922823,
        store_longitude: -80.451153,
    },
    {
        store_latitdue: -0.829317,
        store_longitude: -80.526023,
    },
    {
        store_latitdue: -1.20867,
        store_longitude: -80.3701,
    },
    {
        store_latitdue: -0.8706337,
        store_longitude: -80.5401691,
    },
    {
        store_latitdue: -1.053446,
        store_longitude: -80.455089,
    },
    {
        store_latitdue: -0.917461,
        store_longitude: -80.442388,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.404388,
        store_longitude: -79.905883,
    },
    {
        store_latitdue: -0.983989,
        store_longitude: -80.456999,
    },
    {
        store_latitdue: -0.924429,
        store_longitude: -80.4514908,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0528,
        store_longitude: -80.4475,
    },
    {
        store_latitdue: -0.77829,
        store_longitude: -80.2338,
    },
    {
        store_latitdue: -1.09445,
        store_longitude: -80.4319,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.110553385,
        store_longitude: -80.22241581,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -0.99028,
        store_longitude: -80.458524,
    },
    {
        store_latitdue: -1.1475686,
        store_longitude: -80.2972156,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.0127762,
        store_longitude: -80.4708909,
    },
    {
        store_latitdue: -1.05546,
        store_longitude: -80.4609,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.03735,
        store_longitude: -80.4716,
    },
    {
        store_latitdue: -1.03166,
        store_longitude: -80.3967,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -1.159179,
        store_longitude: -80.284714,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -0.84428,
        store_longitude: -80.16792,
    },

    {
        store_latitdue: -1.04009,
        store_longitude: -80.4963,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0661872,
        store_longitude: -80.4465159,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.111057,
        store_longitude: -80.41524,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.058073,
        store_longitude: -80.451129,
    },
    {
        store_latitdue: -1.07326,
        store_longitude: -80.4461,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0318379,
        store_longitude: -80.4629202,
    },
    {
        store_latitdue: -0.781398,
        store_longitude: -80.5166,
    },
    {
        store_latitdue: -1.0651,
        store_longitude: -80.4513,
    },
    {
        store_latitdue: -1.0721,
        store_longitude: -80.4749,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.119237,
        store_longitude: -80.242459,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -0.995309,
        store_longitude: -80.3491,
    },
    {
        store_latitdue: -0.4000978,
        store_longitude: -79.9079379,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.10463,
        store_longitude: -80.419268,
    },
    {
        store_latitdue: -1.1286709,
        store_longitude: -80.2449642,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -1.010328457,
        store_longitude: -80.45755797,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0386632,
        store_longitude: -80.4651424,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7992952,
        store_longitude: -80.5019743,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.79973,
        store_longitude: -80.128632,
    },
    {
        store_latitdue: -1.4000151,
        store_longitude: -80.2113689,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -0.699297,
        store_longitude: -80.088367,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.827629,
        store_longitude: -80.4975,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.0575442,
        store_longitude: -80.4443502,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7606172,
        store_longitude: -80.5115212,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.9683,
        store_longitude: -80.42927,
    },
    {
        store_latitdue: -0.96491,
        store_longitude: -80.4384,
    },
    {
        store_latitdue: -0.868238,
        store_longitude: -80.5078,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.0578815,
        store_longitude: -80.4461248,
    },
    {
        store_latitdue: -1.057777,
        store_longitude: -80.446182,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.057002,
        store_longitude: -80.452714,
    },
    {
        store_latitdue: -0.85668,
        store_longitude: -80.5346,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.05229,
        store_longitude: -80.4549,
    },
    {
        store_latitdue: -1.0573726,
        store_longitude: -80.4739149,
    },
    {
        store_latitdue: -1.10379,
        store_longitude: -80.4199,
    },
    {
        store_latitdue: -1.06408,
        store_longitude: -80.565577,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.20807,
        store_longitude: -80.3734,
    },
    {
        store_latitdue: -1.069668,
        store_longitude: -80.489292,
    },
    {
        store_latitdue: -0.1962297,
        store_longitude: -80.2662034,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.4100884,
        store_longitude: -80.3941063,
    },
    {
        store_latitdue: -0.197203,
        store_longitude: -80.265896,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.0687,
        store_longitude: -80.49,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0126862,
        store_longitude: -80.3711524,
    },
    {
        store_latitdue: -0.6423291,
        store_longitude: -80.4228808,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.6955946,
        store_longitude: -80.0898357,
    },
    {
        store_latitdue: -1.005372226,
        store_longitude: -80.36914891,
    },
    {
        store_latitdue: -0.695766,
        store_longitude: -80.0897,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.07309,
        store_longitude: -80.4805,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8682702,
        store_longitude: -80.5107733,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.0336,
        store_longitude: -80.4921,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.204712215,
        store_longitude: -80.26331871,
    },
    {
        store_latitdue: -1.0647433,
        store_longitude: -80.5650185,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.4616222,
        store_longitude: -80.4531754,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -1.0826854,
        store_longitude: -80.4400489,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9250667,
        store_longitude: -80.4504541,
    },
    {
        store_latitdue: -0.987149,
        store_longitude: -80.4539,
    },
    {
        store_latitdue: -1.062135167,
        store_longitude: -80.4383491,
    },
    {
        store_latitdue: -1.06504,
        store_longitude: -80.464,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.854867,
        store_longitude: -80.4669,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.0699058,
        store_longitude: -80.4975196,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9177176,
        store_longitude: -80.442515,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.984273,
        store_longitude: -80.438232,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9762302,
        store_longitude: -80.4603957,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0529342,
        store_longitude: -80.4603014,
    },
    {
        store_latitdue: -0.699816,
        store_longitude: -80.0931,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.591707,
        store_longitude: -80.4081434,
    },
    {
        store_latitdue: -1.4101368,
        store_longitude: -80.3938281,
        neighborhood: 'NOBOA',
    },
    {
        store_latitdue: -0.588886,
        store_longitude: -80.40838,
    },
    {
        store_latitdue: -1.06024,
        store_longitude: -80.4463,
    },
    {
        store_latitdue: -1.0594712,
        store_longitude: -80.4589001,
    },
    {
        store_latitdue: -0.86862,
        store_longitude: -80.526,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.85796,
        store_longitude: -80.5348,
    },
    {
        store_latitdue: -0.464442,
        store_longitude: -80.456571,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -1.09695015,
        store_longitude: -80.41221239,
    },
    {
        store_latitdue: -0.970387,
        store_longitude: -80.4234,
    },
    {
        store_latitdue: -1.0477938,
        store_longitude: -80.4751774,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.837538,
        store_longitude: -80.4888772,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.198068,
        store_longitude: -80.266188,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.03066,
        store_longitude: -80.339,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.05354,
        store_longitude: -80.482788,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.84256,
        store_longitude: -80.1633,
    },
    {
        store_latitdue: -0.84227,
        store_longitude: -80.163954,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0516672,
        store_longitude: -80.483561,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8844166,
        store_longitude: -80.0974937,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: -0.8841472,
        store_longitude: -80.0982595,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: -0.69871,
        store_longitude: -80.0916,
    },
    {
        store_latitdue: -0.8434801,
        store_longitude: -80.1659464,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.6288552,
        store_longitude: -80.2381413,
        neighborhood: 'GUALE',
    },
    {
        store_latitdue: -1.53097,
        store_longitude: -80.2297,
        neighborhood: 'LASCANO',
    },
    {
        store_latitdue: -1.53087,
        store_longitude: -80.2287,
    },
    {
        store_latitdue: -1.0794609,
        store_longitude: -80.5236724,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.848269,
        store_longitude: -80.163381,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.044529,
        store_longitude: -80.014962,
    },
    {
        store_latitdue: -1.0551656,
        store_longitude: -80.0134543,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -1.0562991,
        store_longitude: -80.0130163,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -1.056768,
        store_longitude: -80.012699,
    },
    {
        store_latitdue: -1.056813,
        store_longitude: -80.012623,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -1.069746,
        store_longitude: -79.973906,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -1.069865,
        store_longitude: -79.973898,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -1.070364,
        store_longitude: -79.972772,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -1.044474,
        store_longitude: -80.015105,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -1.0570151,
        store_longitude: -80.0121838,
    },
    {
        store_latitdue: -1.056351,
        store_longitude: -80.012962,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -0.465313,
        store_longitude: -80.455809,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.8955247,
        store_longitude: -80.5021831,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.1923191,
        store_longitude: -80.2950546,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.056815,
        store_longitude: -80.107964,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -1.040436,
        store_longitude: -80.085507,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -1.0702682,
        store_longitude: -80.4313466,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0126483,
        store_longitude: -80.4573685,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0546929,
        store_longitude: -80.481645,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.041603,
        store_longitude: -80.499527,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.04178,
        store_longitude: -80.4997,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7049846,
        store_longitude: -80.096653,
    },
    {
        store_latitdue: -1.1393807,
        store_longitude: -80.4068399,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -0.6947386,
        store_longitude: -80.0964144,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.05724,
        store_longitude: -80.4661,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7045283,
        store_longitude: -80.1664181,
    },
    {
        store_latitdue: -1.01728,
        store_longitude: -80.4614,
    },
    {
        store_latitdue: -1.0380238,
        store_longitude: -80.4654895,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.695292,
        store_longitude: -80.09716,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.049656,
        store_longitude: -80.445467,
    },
    {
        store_latitdue: -1.075017,
        store_longitude: -80.455204,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.068399646,
        store_longitude: -80.4452078,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.048888,
        store_longitude: -80.444246,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.37876,
        store_longitude: -80.2665,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -0.852257,
        store_longitude: -80.4688,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.06707,
        store_longitude: -80.4535,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.040194,
        store_longitude: -80.319459,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.4050475,
        store_longitude: -79.906111,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.3944237,
        store_longitude: -80.2125237,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.048713,
        store_longitude: -80.443639,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.185522,
        store_longitude: -80.29264,
    },
    {
        store_latitdue: -1.0570297,
        store_longitude: -80.1079907,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -1.3949866,
        store_longitude: -80.2093207,
    },
    {
        store_latitdue: -0.260309,
        store_longitude: -80.298798,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.4045376,
        store_longitude: -79.9061299,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.406468,
        store_longitude: -79.905673,
    },
    {
        store_latitdue: -1.06725198,
        store_longitude: -80.59573896,
    },
    {
        store_latitdue: -0.1848167,
        store_longitude: -80.28976,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.0741009,
        store_longitude: -80.4306247,
    },
    {
        store_latitdue: -1.0531657,
        store_longitude: -80.4434917,
    },
    {
        store_latitdue: -1.058963,
        store_longitude: -80.442902,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.840939,
        store_longitude: -80.1644,
    },
    {
        store_latitdue: -0.75074,
        store_longitude: -80.5083,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.8732208,
        store_longitude: -80.5409739,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.5476131,
        store_longitude: -80.0409236,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.53105093,
        store_longitude: -80.2313411,
        neighborhood: 'LASCANO',
    },
    {
        store_latitdue: -1.047374,
        store_longitude: -80.475876,
    },

    {
        store_latitdue: -0.9900428,
        store_longitude: -80.470345,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.019528,
        store_longitude: -80.045903,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -1.052729,
        store_longitude: -80.455652,
    },
    {
        store_latitdue: -0.9172069,
        store_longitude: -80.4418237,
    },
    {
        store_latitdue: -1.39593,
        store_longitude: -80.2115,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -0.7848425,
        store_longitude: -80.2344056,
    },
    {
        store_latitdue: -0.7009867,
        store_longitude: -80.1136064,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9235897,
        store_longitude: -80.45164,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.5858305,
        store_longitude: -80.4095973,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.843861,
        store_longitude: -80.163053,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0161543,
        store_longitude: -80.4701419,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06263,
        store_longitude: -80.3246,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.422353,
        store_longitude: -79.932631,
    },
    {
        store_latitdue: -0.72472,
        store_longitude: -80.1237,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.70446,
        store_longitude: -80.167009,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.70111,
        store_longitude: -80.095886,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0937261,
        store_longitude: -80.0241027,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -0.6926216,
        store_longitude: -80.0974695,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.153724,
        store_longitude: -80.282597,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -0.1981065,
        store_longitude: -80.2651855,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.9739,
        store_longitude: -80.4203,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -1.07254,
        store_longitude: -80.4416,
    },
    {
        store_latitdue: -1.0557049,
        store_longitude: -80.4569948,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0695159,
        store_longitude: -80.4842616,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.080008,
        store_longitude: -80.4384626,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05647,
        store_longitude: -80.4587,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0328745,
        store_longitude: -80.3358215,
    },
    {
        store_latitdue: -1.2068417,
        store_longitude: -80.3740624,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0350387,
        store_longitude: -80.4645193,
    },
    {
        store_latitdue: -1.06316,
        store_longitude: -80.461,
    },
    {
        store_latitdue: -0.7027201,
        store_longitude: -80.1120146,
    },
    {
        store_latitdue: -0.6042601,
        store_longitude: -80.4015232,
    },
    {
        store_latitdue: -0.9236736,
        store_longitude: -80.4508492,
    },
    {
        store_latitdue: -0.846151,
        store_longitude: -80.162393,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.5876619,
        store_longitude: -80.406048,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -1.0576502,
        store_longitude: -80.4728322,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8561158,
        store_longitude: -80.1868081,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.83732,
        store_longitude: -80.1751,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0423582,
        store_longitude: -80.4953953,
    },
    {
        store_latitdue: -0.986488,
        store_longitude: -80.3130211,
        neighborhood: 'PUEBLO NUEVO',
    },
    {
        store_latitdue: -0.752217,
        store_longitude: -80.509594,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.023993,
        store_longitude: -80.057564,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -0.6988264,
        store_longitude: -80.112001,
    },
    {
        store_latitdue: -1.0463232,
        store_longitude: -80.4500262,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.062902816,
        store_longitude: -80.46008365,
    },
    {
        store_latitdue: -0.697196,
        store_longitude: -80.077774,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.5693463,
        store_longitude: -80.1776814,
        neighborhood: 'BOYACÁ',
    },
    {
        store_latitdue: -0.5690621,
        store_longitude: -80.1772511,
        neighborhood: 'BOYACÁ',
    },
    {
        store_latitdue: -1.39629,
        store_longitude: -80.2092,
    },
    {
        store_latitdue: -0.9243728,
        store_longitude: -80.4513117,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.84481,
        store_longitude: -80.169,
    },
    {
        store_latitdue: -0.3997796,
        store_longitude: -79.9066469,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.2796411,
        store_longitude: -80.4185981,
    },
    {
        store_latitdue: -1.03438,
        store_longitude: -80.467,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0565774,
        store_longitude: -80.4586063,
    },
    {
        store_latitdue: -1.05592,
        store_longitude: -80.0132,
    },
    {
        store_latitdue: -1.051713672,
        store_longitude: -80.48291538,
    },
    {
        store_latitdue: -1.06545,
        store_longitude: -80.4527,
    },
    {
        store_latitdue: -0.774989,
        store_longitude: -80.2398727,
    },
    {
        store_latitdue: -0.697586,
        store_longitude: -80.0839,
    },
    {
        store_latitdue: -1.03476,
        store_longitude: -80.465,
    },
    {
        store_latitdue: -0.857751,
        store_longitude: -80.5335513,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.2631864,
        store_longitude: -80.3062266,
    },
    {
        store_latitdue: -0.79506,
        store_longitude: -80.24117,
    },
    {
        store_latitdue: -0.9221593,
        store_longitude: -80.4453168,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.922251,
        store_longitude: -80.4455,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.852064,
        store_longitude: -80.1662,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.977671,
        store_longitude: -80.2926,
    },
    {
        store_latitdue: -0.5936034,
        store_longitude: -80.4085461,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.8422986,
        store_longitude: -80.169484,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.042282,
        store_longitude: -80.469602,
    },
    {
        store_latitdue: -0.980384,
        store_longitude: -80.6970748,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.205915,
        store_longitude: -80.3714457,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.695645,
        store_longitude: -80.0948,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.1098879,
        store_longitude: -80.4155691,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0595895,
        store_longitude: -80.4444179,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0202178,
        store_longitude: -80.4700326,
    },
    {
        store_latitdue: -1.0583571,
        store_longitude: -80.4438505,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0572682,
        store_longitude: -80.4564939,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.16383,
        store_longitude: -80.2795,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -0.995972,
        store_longitude: -80.468673,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9501223,
        store_longitude: -80.4422465,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.856782,
        store_longitude: -80.471,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.9949216,
        store_longitude: -80.4733925,
    },
    {
        store_latitdue: -0.956093,
        store_longitude: -80.47,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.184165,
        store_longitude: -80.2895,
    },
    {
        store_latitdue: -0.601468,
        store_longitude: -80.4032817,
    },
    {
        store_latitdue: -1.07252,
        store_longitude: -80.445,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.703262,
        store_longitude: -80.095089,
    },
    {
        store_latitdue: -1.047166,
        store_longitude: -80.441893,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.39724,
        store_longitude: -80.2098,
    },
    {
        store_latitdue: -0.650744,
        store_longitude: -80.270594,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.6504953,
        store_longitude: -80.2709755,
    },
    {
        store_latitdue: -0.6851942,
        store_longitude: -80.2502761,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.6981467,
        store_longitude: -80.0748624,
    },
    {
        store_latitdue: -0.780296,
        store_longitude: -80.235322,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.696844,
        store_longitude: -80.0854,
    },
    {
        store_latitdue: -0.69695,
        store_longitude: -80.09605,
    },
    {
        store_latitdue: -0.9937809,
        store_longitude: -80.415995,
    },
    {
        store_latitdue: -1.03985,
        store_longitude: -80.4725,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0628398,
        store_longitude: -80.462474,
    },
    {
        store_latitdue: -0.81067,
        store_longitude: -80.2266,
    },
    {
        store_latitdue: -1.0614462,
        store_longitude: -80.487239,
    },
    {
        store_latitdue: -0.844665,
        store_longitude: -80.176849,
    },
    {
        store_latitdue: -0.844644,
        store_longitude: -80.159972,
    },
    {
        store_latitdue: -1.27951,
        store_longitude: -80.4167,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.04255,
        store_longitude: -80.4772,
    },
    {
        store_latitdue: -0.6903153,
        store_longitude: -80.0933689,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.05224,
        store_longitude: -80.4479,
    },
    {
        store_latitdue: -0.688652,
        store_longitude: -80.0972,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.07595,
        store_longitude: -80.5132,
    },
    {
        store_latitdue: -1.134497803,
        store_longitude: -80.40077865,
    },
    {
        store_latitdue: -0.871121,
        store_longitude: -80.5002348,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.15921,
        store_longitude: -80.3923,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6951686,
        store_longitude: -80.093456,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6222507,
        store_longitude: -80.4257411,
    },
    {
        store_latitdue: -1.1132791,
        store_longitude: -80.4144136,
    },
    {
        store_latitdue: -0.9954376,
        store_longitude: -80.4157237,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.4622883,
        store_longitude: -80.455033,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -1.0326753,
        store_longitude: -80.4931349,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0048727,
        store_longitude: -80.4058089,
    },
    {
        store_latitdue: -0.598454,
        store_longitude: -80.4057,
    },
    {
        store_latitdue: -0.6956307,
        store_longitude: -80.0923044,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0693053,
        store_longitude: -80.4425194,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9249912,
        store_longitude: -80.4509585,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.838634,
        store_longitude: -80.1711,
    },
    {
        store_latitdue: -0.868273,
        store_longitude: -80.503777,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.85016,
        store_longitude: -80.1644,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.829646,
        store_longitude: -80.1649,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0311523,
        store_longitude: -80.2416463,
        neighborhood: 'CHIRIJOS',
    },
    {
        store_latitdue: -1.1023074,
        store_longitude: -80.4207025,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.697855,
        store_longitude: -80.0855,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7027089,
        store_longitude: -80.09324,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.82918,
        store_longitude: -80.1644482,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.4564992,
        store_longitude: -80.1455579,
    },
    {
        store_latitdue: -0.4562743,
        store_longitude: -80.1449519,
        neighborhood: 'ELOY ALFARO',
    },
    {
        store_latitdue: -1.03351,
        store_longitude: -80.4549,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9817748,
        store_longitude: -80.6990418,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.998282,
        store_longitude: -80.684,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.699541,
        store_longitude: -80.0808,
    },
    {
        store_latitdue: -0.959276,
        store_longitude: -80.707,
    },
    {
        store_latitdue: -0.9583518,
        store_longitude: -80.706365,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9588797,
        store_longitude: -80.70765,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9592751,
        store_longitude: -80.7057487,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9583224,
        store_longitude: -80.7050667,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9636417,
        store_longitude: -80.7162303,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9604019,
        store_longitude: -80.7107846,
    },
    {
        store_latitdue: -0.9688365,
        store_longitude: -80.6923139,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9813117,
        store_longitude: -80.6869417,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.967724,
        store_longitude: -80.6972,
    },
    {
        store_latitdue: -0.9672118,
        store_longitude: -80.6980524,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9705185,
        store_longitude: -80.6984706,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9637566,
        store_longitude: -80.7184857,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9677455,
        store_longitude: -80.7180177,
    },
    {
        store_latitdue: -0.9688889,
        store_longitude: -80.7206027,
    },
    {
        store_latitdue: -0.96740269,
        store_longitude: -80.71708869,
    },
    {
        store_latitdue: -1.060954,
        store_longitude: -80.4372591,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05426,
        store_longitude: -80.4495,
    },
    {
        store_latitdue: -0.9775784,
        store_longitude: -80.7061383,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9760177,
        store_longitude: -80.704123,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.979751,
        store_longitude: -80.7053,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9775449,
        store_longitude: -80.7064979,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9802068,
        store_longitude: -80.7061072,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9751655,
        store_longitude: -80.7046138,
    },
    {
        store_latitdue: -0.975554,
        store_longitude: -80.7055,
    },
    {
        store_latitdue: -0.9717293,
        store_longitude: -80.7044135,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.971695,
        store_longitude: -80.7051168,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9728893,
        store_longitude: -80.7057437,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9726752,
        store_longitude: -80.7030775,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9838891,
        store_longitude: -80.7068783,
    },
    {
        store_latitdue: -0.981986,
        store_longitude: -80.7081,
    },
    {
        store_latitdue: -0.984647,
        store_longitude: -80.7059,
    },
    {
        store_latitdue: -0.9851362,
        store_longitude: -80.7056315,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9640069,
        store_longitude: -80.6988392,
    },
    {
        store_latitdue: -0.6955855,
        store_longitude: -80.0922536,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.08033,
        store_longitude: -80.4403,
    },
    {
        store_latitdue: -0.987958304,
        store_longitude: -80.70356423,
    },
    {
        store_latitdue: -0.98263,
        store_longitude: -80.6886,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.98044,
        store_longitude: -80.6882,
    },
    {
        store_latitdue: -0.982595,
        store_longitude: -80.6884,
    },
    {
        store_latitdue: -0.978738,
        store_longitude: -80.6822,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.898956,
        store_longitude: -79.4925,
    },
    {
        store_latitdue: -0.951401,
        store_longitude: -80.7016,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9653309,
        store_longitude: -80.6910421,
    },
    {
        store_latitdue: -0.966074,
        store_longitude: -80.6807,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.971117,
        store_longitude: -80.6774,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.22591,
        store_longitude: -78.33938,
    },
    {
        store_latitdue: -1.0730143,
        store_longitude: -80.4966681,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9659535,
        store_longitude: -80.6774836,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.056009,
        store_longitude: -80.471634,
    },
    {
        store_latitdue: -0.961036325,
        store_longitude: -80.67557526,
    },
    {
        store_latitdue: -1.02364,
        store_longitude: -80.0151,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.9540149,
        store_longitude: -80.719869,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9539328,
        store_longitude: -80.7161178,
    },
    {
        store_latitdue: -0.848956,
        store_longitude: -80.1579,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.697811,
        store_longitude: -80.0896,
    },
    {
        store_latitdue: -0.972635707,
        store_longitude: -80.70921616,
    },
    {
        store_latitdue: -0.844649,
        store_longitude: -80.4998,
    },
    {
        store_latitdue: -1.03936,
        store_longitude: -80.4951,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9680425,
        store_longitude: -80.6949922,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.964982,
        store_longitude: -80.6969,
    },
    {
        store_latitdue: -1.0451874,
        store_longitude: -80.4742395,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.953383,
        store_longitude: -80.708,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9532589,
        store_longitude: -80.7115561,
    },
    {
        store_latitdue: -1.01152,
        store_longitude: -80.4591,
    },
    {
        store_latitdue: -1.14708,
        store_longitude: -80.3819,
    },
    {
        store_latitdue: -0.964252,
        store_longitude: -80.719,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9625344,
        store_longitude: -80.71062,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9907549,
        store_longitude: -80.4607761,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.981049,
        store_longitude: -80.6857,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.877672,
        store_longitude: -80.543,
    },
    {
        store_latitdue: -0.9662344,
        store_longitude: -80.6813719,
    },
    {
        store_latitdue: -0.9971715,
        store_longitude: -80.6862096,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9968665,
        store_longitude: -80.6839897,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.992819,
        store_longitude: -80.6886,
    },
    {
        store_latitdue: -0.9677406,
        store_longitude: -80.7061514,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9758154,
        store_longitude: -80.6996771,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.97161,
        store_longitude: -80.7111,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0689213,
        store_longitude: -80.4472981,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.967543,
        store_longitude: -80.6986,
    },
    {
        store_latitdue: -0.966100006,
        store_longitude: -80.68453061,
    },
    {
        store_latitdue: -0.644295,
        store_longitude: -80.277466,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.958411,
        store_longitude: -80.7168939,
    },
    {
        store_latitdue: -0.8580425,
        store_longitude: -80.5321066,
    },
    {
        store_latitdue: -0.9928149,
        store_longitude: -80.6881894,
    },
    {
        store_latitdue: -0.9912139,
        store_longitude: -80.6877779,
    },
    {
        store_latitdue: -0.9931081,
        store_longitude: -80.686846,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.6733971,
        store_longitude: -80.2515499,
    },
    {
        store_latitdue: -1.39651,
        store_longitude: -80.2084,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.0376032,
        store_longitude: -80.4849789,
    },
    {
        store_latitdue: -0.9881778,
        store_longitude: -80.7076181,
    },
    {
        store_latitdue: -0.863113,
        store_longitude: -80.5374,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.856974,
        store_longitude: -80.187391,
    },
    {
        store_latitdue: -0.9787107,
        store_longitude: -80.7010963,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.980991,
        store_longitude: -80.6781,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9620979,
        store_longitude: -80.6987105,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9541631,
        store_longitude: -80.7034003,
    },
    {
        store_latitdue: -0.953987,
        store_longitude: -80.7029,
    },

    {
        store_latitdue: -0.9606497,
        store_longitude: -80.720116,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.7001687,
        store_longitude: -80.0880956,
    },
    {
        store_latitdue: -0.9831819,
        store_longitude: -80.6792455,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9957835,
        store_longitude: -80.6825,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.953543,
        store_longitude: -80.702,
    },
    {
        store_latitdue: -0.9560891,
        store_longitude: -80.7195908,
    },
    {
        store_latitdue: -0.961566,
        store_longitude: -80.7183,
    },
    {
        store_latitdue: -0.9749056,
        store_longitude: -80.7100741,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9631984,
        store_longitude: -80.6992143,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9531156,
        store_longitude: -80.7077416,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9498124,
        store_longitude: -80.6460446,
    },
    {
        store_latitdue: -0.950399,
        store_longitude: -80.6459,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9478959,
        store_longitude: -80.6394218,
    },
    {
        store_latitdue: -0.9476221,
        store_longitude: -80.639449,
    },
    {
        store_latitdue: -0.966325,
        store_longitude: -80.6846,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9496156,
        store_longitude: -80.6416915,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.964216,
        store_longitude: -80.6389198,
    },
    {
        store_latitdue: -0.923631,
        store_longitude: -80.4510141,
    },
    {
        store_latitdue: -0.9573808,
        store_longitude: -80.7174137,
    },
    {
        store_latitdue: -1.0538373,
        store_longitude: -80.4588147,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0595768,
        store_longitude: -80.4810133,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9530663,
        store_longitude: -80.6382676,
        neighborhood: 'JARAMIJÓ',
    },

    {
        store_latitdue: -0.9583499,
        store_longitude: -80.6703682,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.958253,
        store_longitude: -80.6707,
    },

    {
        store_latitdue: -1.07399,
        store_longitude: -80.4327,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.962643,
        store_longitude: -80.6904743,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9520469,
        store_longitude: -80.6377556,
    },
    {
        store_latitdue: -0.9548254,
        store_longitude: -80.6373791,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.697464,
        store_longitude: -80.0855789,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.09186,
        store_longitude: -80.4316,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9582038,
        store_longitude: -80.6761833,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0614557,
        store_longitude: -80.4631513,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.955339,
        store_longitude: -80.7232,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.17512,
        store_longitude: -80.3128,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -0.5690907,
        store_longitude: -80.1772749,
    },
    {
        store_latitdue: -0.924438,
        store_longitude: -80.4515,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.6484304,
        store_longitude: -80.1526339,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.9524713,
        store_longitude: -80.6947926,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9804402,
        store_longitude: -80.6880885,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0585914,
        store_longitude: -80.4849624,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05481253,
        store_longitude: -80.4590177,
    },
    {
        store_latitdue: -1.49639,
        store_longitude: -80.2268,
    },
    {
        store_latitdue: -0.975113,
        store_longitude: -80.6791,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9826834,
        store_longitude: -80.6855471,
    },
    {
        store_latitdue: -0.9917932,
        store_longitude: -80.6878095,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.023094,
        store_longitude: -80.583177,
    },
    {
        store_latitdue: -1.0250055,
        store_longitude: -80.5852403,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0236351,
        store_longitude: -80.5805078,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.956207,
        store_longitude: -80.7192,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.023612,
        store_longitude: -80.580357,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.05681,
        store_longitude: -80.4465,
    },
    {
        store_latitdue: -0.9719456,
        store_longitude: -80.7075376,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9815426,
        store_longitude: -80.6841366,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9598338,
        store_longitude: -80.6714883,
    },
    {
        store_latitdue: -0.9307412,
        store_longitude: -80.4476345,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.035000099,
        store_longitude: -80.34225232,
    },
    {
        store_latitdue: -0.9846786,
        store_longitude: -80.6992144,
    },
    {
        store_latitdue: -0.969403,
        store_longitude: -80.7048,
    },
    {
        store_latitdue: -0.6972994,
        store_longitude: -80.0881009,
    },
    {
        store_latitdue: -0.7079229,
        store_longitude: -80.1024758,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.1872487,
        store_longitude: -80.2950615,
    },
    {
        store_latitdue: -0.588498,
        store_longitude: -80.3900772,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9752658,
        store_longitude: -80.7008102,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.06041,
        store_longitude: -80.4622,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.2037201,
        store_longitude: -80.2635295,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.2037201,
        store_longitude: -80.2635295,
    },
    {
        store_latitdue: -1.06027,
        store_longitude: -80.489,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7062365,
        store_longitude: -80.0989282,
    },
    {
        store_latitdue: -0.7050741,
        store_longitude: -80.0887374,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.06376,
        store_longitude: -80.474,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.698068,
        store_longitude: -80.075141,
    },
    {
        store_latitdue: -1.05391,
        store_longitude: -80.491,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0287774,
        store_longitude: -80.3425858,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.6058365,
        store_longitude: -80.3996404,
    },
    {
        store_latitdue: -1.180046,
        store_longitude: -80.375526,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.4055685,
        store_longitude: -79.9054052,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.976347,
        store_longitude: -80.6866,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.03505,
        store_longitude: -80.4871,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05596,
        store_longitude: -80.4575,
    },
    {
        store_latitdue: -1.1231514,
        store_longitude: -80.4086459,
    },
    {
        store_latitdue: -0.2778325,
        store_longitude: -80.036589,
    },
    {
        store_latitdue: -0.669593,
        store_longitude: -80.3494,
    },
    {
        store_latitdue: -0.9609109,
        store_longitude: -80.6737356,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.058458,
        store_longitude: -80.455149,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.2561325,
        store_longitude: -79.165538,
    },
    {
        store_latitdue: -1.0196,
        store_longitude: -80.4613,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.978557,
        store_longitude: -80.7016,
    },
    {
        store_latitdue: -0.6663806,
        store_longitude: -80.0467226,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0647861,
        store_longitude: -80.466979,
    },
    {
        store_latitdue: -0.186363,
        store_longitude: -80.2882094,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.0402576,
        store_longitude: -80.319523,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.928031,
        store_longitude: -80.2064,
    },
    {
        store_latitdue: -1.023924,
        store_longitude: -80.578716,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.7876556,
        store_longitude: -80.5140567,
    },
    {
        store_latitdue: -0.8488233,
        store_longitude: -80.1613367,
    },
    {
        store_latitdue: -0.9967965,
        store_longitude: -80.4134256,
    },
    {
        store_latitdue: -0.990331,
        store_longitude: -80.6858,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -2.2418,
        store_longitude: -79.9188,
    },
    {
        store_latitdue: -1.39544,
        store_longitude: -80.2112,
    },
    {
        store_latitdue: -0.704786,
        store_longitude: -80.0922,
    },
    {
        store_latitdue: -1.02452,
        store_longitude: -80.5806,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.701236,
        store_longitude: -80.0958076,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.05186,
        store_longitude: -80.460952,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9591323,
        store_longitude: -80.7061522,
    },
    {
        store_latitdue: -1.0403,
        store_longitude: -80.4616,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8528022,
        store_longitude: -80.5314419,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.991806,
        store_longitude: -80.4144,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.961265,
        store_longitude: -80.7173,
    },
    {
        store_latitdue: -0.656143,
        store_longitude: -80.404915,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.962249,
        store_longitude: -80.7108,
    },
    {
        store_latitdue: -0.9848719,
        store_longitude: -80.7008181,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0404849,
        store_longitude: -80.4617602,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.1570799,
        store_longitude: -80.4129611,
    },
    {
        store_latitdue: -1.53033,
        store_longitude: -80.2298,
        neighborhood: 'LASCANO',
    },
    {
        store_latitdue: -0.6285474,
        store_longitude: -80.4306183,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.846816,
        store_longitude: -80.1635303,
    },
    {
        store_latitdue: -1.0724957,
        store_longitude: -80.4960365,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.959152,
        store_longitude: -80.6817444,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9867176,
        store_longitude: -80.703699,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.054723,
        store_longitude: -80.4524903,
    },
    {
        store_latitdue: -0.988457,
        store_longitude: -80.7071,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.824889,
        store_longitude: -80.4966,
    },
    {
        store_latitdue: -1.04771,
        store_longitude: -80.4441,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9803949,
        store_longitude: -80.7002785,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.5238021,
        store_longitude: -80.4411636,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -1.1389,
        store_longitude: -80.3919,
    },
    {
        store_latitdue: -0.9801208,
        store_longitude: -80.6886159,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.067811,
        store_longitude: -80.4914574,
    },
    {
        store_latitdue: -1.07684,
        store_longitude: -80.4547,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.047618,
        store_longitude: -80.472373,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6943298,
        store_longitude: -80.0939196,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9584859,
        store_longitude: -80.7044177,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.950605,
        store_longitude: -80.6404,
    },
    {
        store_latitdue: -0.920295,
        store_longitude: -80.4529,
    },
    {
        store_latitdue: -0.984681,
        store_longitude: -80.6783,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9605371,
        store_longitude: -80.6686578,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.2036524,
        store_longitude: -80.2628101,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.2038848,
        store_longitude: -80.2636466,
    },
    {
        store_latitdue: -1.0112905,
        store_longitude: -80.3774587,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.187659,
        store_longitude: -80.2874227,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.6032411,
        store_longitude: -80.4027328,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -1.17921,
        store_longitude: -80.3888,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.06207,
        store_longitude: -80.4593,
    },
    {
        store_latitdue: -1.07015,
        store_longitude: -80.4977,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6990566,
        store_longitude: -80.3170347,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -1.06249,
        store_longitude: -80.4527,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.702715,
        store_longitude: -80.1125,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.8574943,
        store_longitude: -80.5336424,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.19136,
        store_longitude: -80.4027,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0123929,
        store_longitude: -80.4572075,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.5863312,
        store_longitude: -80.4059698,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.60003,
        store_longitude: -80.4057,
    },
    {
        store_latitdue: -0.7607361,
        store_longitude: -80.5119712,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.9752265,
        store_longitude: -80.6963641,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.981807,
        store_longitude: -80.6908,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.04834,
        store_longitude: -80.4889,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.925805,
        store_longitude: -80.4512,
    },
    {
        store_latitdue: -1.02515,
        store_longitude: -80.3532,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.0532976,
        store_longitude: -80.4557518,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.847739,
        store_longitude: -80.1573,
    },
    {
        store_latitdue: -1.03249,
        store_longitude: -80.2471,
        neighborhood: 'CHIRIJOS',
    },
    {
        store_latitdue: -1.02564,
        store_longitude: -80.4957552,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.5929658,
        store_longitude: -80.4087347,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9765661,
        store_longitude: -80.6826684,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.950306,
        store_longitude: -80.7028,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.995471,
        store_longitude: -80.3394,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.259581,
        store_longitude: -79.1772,
    },
    {
        store_latitdue: -1.05361,
        store_longitude: -80.4881,
    },
    {
        store_latitdue: -0.9662279,
        store_longitude: -80.7197579,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9605035,
        store_longitude: -80.7185829,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.955484,
        store_longitude: -80.7001,
    },
    {
        store_latitdue: -0.402424,
        store_longitude: -79.908843,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.9573674,
        store_longitude: -80.7042836,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9524761,
        store_longitude: -80.7016724,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.946228,
        store_longitude: -80.730703,
    },
    {
        store_latitdue: -0.977381,
        store_longitude: -80.6913,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.962166,
        store_longitude: -80.6975275,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.955529,
        store_longitude: -80.701,
    },
    {
        store_latitdue: -0.951848,
        store_longitude: -80.7047,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9553797,
        store_longitude: -80.7233362,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.955539,
        store_longitude: -80.7226,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9535953,
        store_longitude: -80.7103622,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9562741,
        store_longitude: -80.7087727,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.975843,
        store_longitude: -80.7066,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.969633,
        store_longitude: -80.6957,
    },
    {
        store_latitdue: -0.9687467,
        store_longitude: -80.71018,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.97432,
        store_longitude: -80.7106,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9728555,
        store_longitude: -80.707344,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.1450676,
        store_longitude: -80.0723934,
    },
    {
        store_latitdue: -0.9720975,
        store_longitude: -80.6799513,
    },
    {
        store_latitdue: -0.9645535,
        store_longitude: -80.6749705,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.964115,
        store_longitude: -80.6849,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9807868,
        store_longitude: -80.6842739,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.966896,
        store_longitude: -80.6761,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9612035,
        store_longitude: -80.6698079,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9816799,
        store_longitude: -80.7014724,
    },
    {
        store_latitdue: -0.9813621,
        store_longitude: -80.7019831,
    },
    {
        store_latitdue: -0.9467852,
        store_longitude: -80.639711,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9591381,
        store_longitude: -80.6351566,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.977992,
        store_longitude: -80.7012376,
    },
    {
        store_latitdue: -0.9800092,
        store_longitude: -80.6887371,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.980461,
        store_longitude: -80.6885,
    },
    {
        store_latitdue: -1.0592,
        store_longitude: -80.4549,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -0.986108,
        store_longitude: -80.6867,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9971641,
        store_longitude: -80.6839517,
    },
    {
        store_latitdue: -0.988153,
        store_longitude: -80.686,
    },
    {
        store_latitdue: -0.9611,
        store_longitude: -80.72,
    },
    {
        store_latitdue: -0.9642448,
        store_longitude: -80.7116133,
    },
    {
        store_latitdue: -0.984103,
        store_longitude: -80.7010204,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9818199,
        store_longitude: -80.6850776,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.967903,
        store_longitude: -80.6794,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.7833109,
        store_longitude: -80.2346691,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.965859,
        store_longitude: -80.7136,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.968688,
        store_longitude: -80.6952,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.926266,
        store_longitude: -80.4535,
    },
    {
        store_latitdue: -0.9256634,
        store_longitude: -80.4538255,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.06221,
        store_longitude: -80.4581,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05698,
        store_longitude: -80.4587,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.5887892,
        store_longitude: -80.4077062,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.845154,
        store_longitude: -80.1776,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.697497,
        store_longitude: -80.067031,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0352606,
        store_longitude: -80.4972346,
    },
    {
        store_latitdue: -0.9871248,
        store_longitude: -80.679561,
    },
    {
        store_latitdue: -0.9550456,
        store_longitude: -80.7215175,
    },
    {
        store_latitdue: -0.993856,
        store_longitude: -80.4665,
    },
    {
        store_latitdue: -0.988266,
        store_longitude: -80.6837,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9907161,
        store_longitude: -80.6790145,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9900244,
        store_longitude: -80.6814427,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9819646,
        store_longitude: -80.6772788,
    },
    {
        store_latitdue: -0.98228,
        store_longitude: -80.6875,
    },
    {
        store_latitdue: -0.9824575,
        store_longitude: -80.6784046,
    },
    {
        store_latitdue: -2.11189,
        store_longitude: -79.9634,
    },
    {
        store_latitdue: -0.9866512,
        store_longitude: -80.6772928,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0625,
        store_longitude: -80.4584,
    },
    {
        store_latitdue: -0.2079316,
        store_longitude: -80.2616467,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.70676,
        store_longitude: -80.0923,
    },
    {
        store_latitdue: -1.0791,
        store_longitude: -80.45926,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9598748,
        store_longitude: -80.6691968,
    },
    {
        store_latitdue: -0.592557,
        store_longitude: -80.4063,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.848427,
        store_longitude: -80.5307,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.045,
        store_longitude: -80.4661,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9589393,
        store_longitude: -80.7156137,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.982114,
        store_longitude: -80.68,
    },
    {
        store_latitdue: -0.954975,
        store_longitude: -80.7222486,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.06452,
        store_longitude: -80.4536,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.054856,
        store_longitude: -80.45626,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6643463,
        store_longitude: -80.0456096,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.2117534,
        store_longitude: -80.262479,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.3798004,
        store_longitude: -80.1872123,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.3778124,
        store_longitude: -80.1852196,
    },
    {
        store_latitdue: -0.381642,
        store_longitude: -80.194455,
    },
    {
        store_latitdue: -0.946903,
        store_longitude: -80.6554,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.378962,
        store_longitude: -80.1875,
    },
    {
        store_latitdue: -0.9554053,
        store_longitude: -80.7142499,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.06366,
        store_longitude: -80.4885,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9577913,
        store_longitude: -80.4633054,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0754026,
        store_longitude: -80.4421701,
    },
    {
        store_latitdue: -1.24436,
        store_longitude: -80.4251,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.8463385,
        store_longitude: -80.178324,
    },
    {
        store_latitdue: -0.69932,
        store_longitude: -80.082188,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0441439,
        store_longitude: -80.4953523,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05473,
        store_longitude: -80.4487,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.978722,
        store_longitude: -80.6883,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.05399,
        store_longitude: -80.4891,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.699508,
        store_longitude: -80.074554,
    },
    {
        store_latitdue: -0.783762,
        store_longitude: -80.5144,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.992978,
        store_longitude: -80.451727,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9691915,
        store_longitude: -80.7043782,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0623,
        store_longitude: -80.4587,
    },
    {
        store_latitdue: -0.9692475,
        store_longitude: -80.7047218,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.6987272,
        store_longitude: -80.1001404,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0381268,
        store_longitude: -80.4655685,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9615231,
        store_longitude: -80.6902572,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.983897,
        store_longitude: -80.7068,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.959366,
        store_longitude: -80.4625,
    },
    {
        store_latitdue: -0.7905579,
        store_longitude: -80.2343357,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.4047818,
        store_longitude: -79.9040768,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.697749,
        store_longitude: -80.0802,
    },
    {
        store_latitdue: -1.0707527,
        store_longitude: -80.496614,
    },
    {
        store_latitdue: -1.060442,
        store_longitude: -80.48166,
    },
    {
        store_latitdue: -0.9515107,
        store_longitude: -80.7019235,
    },
    {
        store_latitdue: -1.2798722,
        store_longitude: -80.4186499,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.047904,
        store_longitude: -80.44914,
    },
    {
        store_latitdue: -0.853811,
        store_longitude: -80.173169,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.05643,
        store_longitude: -80.4469,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.4808987,
        store_longitude: -80.2750648,
        neighborhood: 'ARQUITECTO SIXTO DURÁN BALLÉN',
    },
    {
        store_latitdue: -0.968243,
        store_longitude: -80.6931802,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0023977,
        store_longitude: -80.4761903,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.668033,
        store_longitude: -80.250554,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -1.10988,
        store_longitude: -80.2234,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -1.03679,
        store_longitude: -80.4625,
    },
    {
        store_latitdue: -0.988357,
        store_longitude: -80.4694,
    },
    {
        store_latitdue: -1.0548179,
        store_longitude: -80.4600773,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0631607,
        store_longitude: -80.4616203,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.55738,
        store_longitude: -80.4322,
    },
    {
        store_latitdue: -0.983556,
        store_longitude: -80.7013,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9531498,
        store_longitude: -80.4708184,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.8550337,
        store_longitude: -80.5321714,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.054339,
        store_longitude: -80.4728939,
    },
    {
        store_latitdue: -1.07213,
        store_longitude: -80.433,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9263395,
        store_longitude: -80.2037822,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.698472,
        store_longitude: -80.0667,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0591428,
        store_longitude: -80.4875748,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.04354,
        store_longitude: -80.4605,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.03799,
        store_longitude: -80.4652,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.4049949,
        store_longitude: -79.905755,
    },
    {
        store_latitdue: -0.4043475,
        store_longitude: -79.9057392,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.05469,
        store_longitude: -80.4751,
    },
    {
        store_latitdue: -0.983184,
        store_longitude: -80.4554,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0476847,
        store_longitude: -80.4556685,
    },
    {
        store_latitdue: -1.0588353,
        store_longitude: -80.4748635,
    },
    {
        store_latitdue: -0.9940519,
        store_longitude: -80.4165812,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -1.0547813,
        store_longitude: -80.4856017,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.4055951,
        store_longitude: -79.9065293,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.050222,
        store_longitude: -80.442709,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06378,
        store_longitude: -80.2403,
    },
    {
        store_latitdue: -1.207915,
        store_longitude: -80.370277,
    },
    {
        store_latitdue: -0.6051317,
        store_longitude: -80.4015642,
    },
    {
        store_latitdue: -1.05847,
        store_longitude: -80.4773,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.921511,
        store_longitude: -80.4492,
    },
    {
        store_latitdue: -1.0777422,
        store_longitude: -80.5158998,
    },
    {
        store_latitdue: -1.0631996,
        store_longitude: -80.2347617,
    },
    {
        store_latitdue: -1.054723,
        store_longitude: -80.4524903,
    },
    {
        store_latitdue: -1.0576022,
        store_longitude: -80.475062,
    },
    {
        store_latitdue: -0.6941599,
        store_longitude: -80.0938069,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.8450739,
        store_longitude: -80.1652052,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.8383,
        store_longitude: -80.4894,
    },
    {
        store_latitdue: -1.07491,
        store_longitude: -80.5043,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.1930863,
        store_longitude: -80.331968,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0498555,
        store_longitude: -80.4446408,
    },
    {
        store_latitdue: -0.979587,
        store_longitude: -80.685,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9523813,
        store_longitude: -80.7102246,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.07202,
        store_longitude: -80.4455,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.16426,
        store_longitude: -80.3069,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -2.154016,
        store_longitude: -79.911993,
    },
    {
        store_latitdue: -1.06125,
        store_longitude: -80.4386,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0167773,
        store_longitude: -80.3626195,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.2597953,
        store_longitude: -80.4210563,
    },
    {
        store_latitdue: -1.41262,
        store_longitude: -80.2272,
    },
    {
        store_latitdue: -0.9694787,
        store_longitude: -80.6971387,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.8469635,
        store_longitude: -80.1639198,
    },
    {
        store_latitdue: -0.69514727,
        store_longitude: -80.0948486,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.1832431,
        store_longitude: -80.2890602,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.951412,
        store_longitude: -80.6946,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.961474,
        store_longitude: -80.6903,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9733602,
        store_longitude: -80.6794306,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.701311,
        store_longitude: -80.0911934,
        neighborhood: 'CHONE',
    },

    {
        store_latitdue: -0.9389494,
        store_longitude: -80.4775559,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9656106,
        store_longitude: -80.713321,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.13625,
        store_longitude: -80.4026,
    },
    {
        store_latitdue: -0.5802301,
        store_longitude: -80.4051241,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.983245,
        store_longitude: -80.69074,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.959038,
        store_longitude: -80.6672,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.990423,
        store_longitude: -80.6838,
    },
    {
        store_latitdue: -0.956823,
        store_longitude: -80.7161,
    },
    {
        store_latitdue: -1.07191,
        store_longitude: -80.4457,
    },

    {
        store_latitdue: -0.212299,
        store_longitude: -80.263,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.210432,
        store_longitude: -80.2599,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.6477514,
        store_longitude: -80.4096242,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.7044731,
        store_longitude: -80.1664261,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.7017041,
        store_longitude: -80.0803053,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.00382,
        store_longitude: -80.4776,
    },
    {
        store_latitdue: -0.960811,
        store_longitude: -80.7209,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.5919148,
        store_longitude: -80.4049027,
    },
    {
        store_latitdue: -0.88128621,
        store_longitude: -80.4885435,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.5694577,
        store_longitude: -80.1774908,
    },
    {
        store_latitdue: -0.9236736,
        store_longitude: -80.4508492,
    },
    {
        store_latitdue: -0.9917988,
        store_longitude: -80.6853568,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.991546,
        store_longitude: -80.6877,
    },
    {
        store_latitdue: -1.07008,
        store_longitude: -80.4455,
    },
    {
        store_latitdue: -0.6011297,
        store_longitude: -80.406716,
    },
    {
        store_latitdue: -1.0489,
        store_longitude: -80.4705,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0565566,
        store_longitude: -80.4765909,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.054723,
        store_longitude: -80.4524903,
    },
    {
        store_latitdue: -1.10225,
        store_longitude: -80.4204,
    },
    {
        store_latitdue: -0.923893,
        store_longitude: -80.2006,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.69690654,
        store_longitude: -80.0936472,
    },
    {
        store_latitdue: -0.878106,
        store_longitude: -80.5423,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.936968,
        store_longitude: -80.4787,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.836854,
        store_longitude: -80.4899,
    },
    {
        store_latitdue: -1.0433728,
        store_longitude: -80.4967428,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7860111,
        store_longitude: -80.2338584,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.9702802,
        store_longitude: -80.6784655,
    },
    {
        store_latitdue: -0.920357,
        store_longitude: -80.4526,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0422007,
        store_longitude: -80.4852661,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0598171,
        store_longitude: -80.440078,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.39429,
        store_longitude: -80.2116,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -0.847507,
        store_longitude: -80.1627,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.699363,
        store_longitude: -80.0783,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.961562,
        store_longitude: -80.6901,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.957479,
        store_longitude: -80.7126,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9757587,
        store_longitude: -80.7003571,
    },
    {
        store_latitdue: -0.8355826,
        store_longitude: -80.1734799,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.5866743,
        store_longitude: -80.409624,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.974383,
        store_longitude: -80.7099,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.968028,
        store_longitude: -80.7071,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.6945626,
        store_longitude: -80.0946885,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.03379,
        store_longitude: -80.4882,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.979028,
        store_longitude: -80.6828,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.2307277,
        store_longitude: -80.4213577,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.05546,
        store_longitude: -79.9951,
    },
    {
        store_latitdue: -0.7030279,
        store_longitude: -80.1347866,
    },
    {
        store_latitdue: -1.036581,
        store_longitude: -80.485622,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0306,
        store_longitude: -80.3394,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.3959001,
        store_longitude: -80.2102749,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.53058,
        store_longitude: -80.2301,
    },
    {
        store_latitdue: -0.8387184,
        store_longitude: -80.1771799,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0801581,
        store_longitude: -80.4383808,
    },
    {
        store_latitdue: -0.9515509,
        store_longitude: -80.7028282,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0533,
        store_longitude: -80.4801,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.70284605,
        store_longitude: -80.09537458,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.05423,
        store_longitude: -80.4561,
    },
    {
        store_latitdue: -0.695808,
        store_longitude: -80.0756395,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.960251,
        store_longitude: -80.6679,
    },
    {
        store_latitdue: -1.07964,
        store_longitude: -80.4577317,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.079597,
        store_longitude: -80.457966,
    },
    {
        store_latitdue: -0.953901,
        store_longitude: -80.7113,
    },
    {
        store_latitdue: -0.9809017,
        store_longitude: -80.6970071,
    },
    {
        store_latitdue: -0.7029478,
        store_longitude: -80.0906846,
    },
    {
        store_latitdue: -0.968375,
        store_longitude: -80.6774,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.051708,
        store_longitude: -80.268171,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -0.974665,
        store_longitude: -80.7028,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9505368,
        store_longitude: -80.6386927,
    },
    {
        store_latitdue: -0.9632189,
        store_longitude: -80.6353548,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.972105,
        store_longitude: -80.7108,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9687741,
        store_longitude: -80.7078035,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9800848,
        store_longitude: -80.7020061,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.966101,
        store_longitude: -80.7099,
    },
    {
        store_latitdue: -0.9662233,
        store_longitude: -80.7096745,
    },
    {
        store_latitdue: -0.965543,
        store_longitude: -80.7134,
    },
    {
        store_latitdue: -0.967835,
        store_longitude: -80.712,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.980915,
        store_longitude: -80.7074,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.969951,
        store_longitude: -80.7066,
    },
    {
        store_latitdue: -0.465363,
        store_longitude: -80.4563788,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.4634421,
        store_longitude: -80.4568433,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.464543,
        store_longitude: -80.454318,
    },

    {
        store_latitdue: -0.203283,
        store_longitude: -80.263244,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.1854609,
        store_longitude: -80.2921478,
    },
    {
        store_latitdue: -0.18619,
        store_longitude: -80.2931,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.18491,
        store_longitude: -80.291669,
    },
    {
        store_latitdue: -0.5867517,
        store_longitude: -80.4045067,
    },
    {
        store_latitdue: -0.3784918,
        store_longitude: -80.1839432,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.37882,
        store_longitude: -80.269,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.380218,
        store_longitude: -80.2665551,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.3407773,
        store_longitude: -80.253155,
    },

    {
        store_latitdue: -0.6530044,
        store_longitude: -80.4075207,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.76269,
        store_longitude: -80.5097,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.1847947,
        store_longitude: -80.2896471,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.6752054,
        store_longitude: -80.2510484,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.5981745,
        store_longitude: -80.4068664,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.787697,
        store_longitude: -80.5141,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.9621587,
        store_longitude: -80.6376032,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.839048,
        store_longitude: -80.4865,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.837421,
        store_longitude: -80.4889,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.825872,
        store_longitude: -80.4963,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.8297287,
        store_longitude: -80.4964521,
    },
    {
        store_latitdue: -0.947665,
        store_longitude: -80.6387,
    },
    {
        store_latitdue: -0.9910855,
        store_longitude: -80.6877589,
    },
    {
        store_latitdue: -0.99212,
        store_longitude: -80.6804,
    },
    {
        store_latitdue: -0.964309,
        store_longitude: -80.6844,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9624571,
        store_longitude: -80.6987828,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9503077,
        store_longitude: -80.7096039,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.950137,
        store_longitude: -80.7101,
    },
    {
        store_latitdue: -0.950178,
        store_longitude: -80.7121,
    },
    {
        store_latitdue: -0.9633586,
        store_longitude: -80.6968618,
    },
    {
        store_latitdue: -0.9596755,
        store_longitude: -80.7205307,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95318,
        store_longitude: -80.7081,
    },
    {
        store_latitdue: -0.9549317,
        store_longitude: -80.7171904,
    },
    {
        store_latitdue: -0.9551621,
        store_longitude: -80.7219316,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.956762,
        store_longitude: -80.7248,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.05504,
        store_longitude: -80.4436,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9572163,
        store_longitude: -80.7110343,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9563265,
        store_longitude: -80.7112327,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.462561,
        store_longitude: -80.4549,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.7012775,
        store_longitude: -80.1084841,
    },
    {
        store_latitdue: -1.2287095,
        store_longitude: -80.4179778,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.05352,
        store_longitude: -80.4534,
    },
    {
        store_latitdue: -0.983421,
        store_longitude: -80.711,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.858004,
        store_longitude: -80.5322,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.977482,
        store_longitude: -80.7093,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0678,
        store_longitude: -80.4507,
    },
    {
        store_latitdue: -0.86084578,
        store_longitude: -80.52379946,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.0509439,
        store_longitude: -80.4490961,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0792067,
        store_longitude: -80.528185,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.04477,
        store_longitude: -80.4966,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8770569,
        store_longitude: -80.1879543,
    },
    {
        store_latitdue: -1.0754579,
        store_longitude: -80.5041342,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9714289,
        store_longitude: -80.6792453,
    },
    {
        store_latitdue: -0.997989,
        store_longitude: -80.4196,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.954806,
        store_longitude: -80.7113,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.1210516,
        store_longitude: -80.4046259,
    },
    {
        store_latitdue: -1.06373,
        store_longitude: -80.4467,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.348617193,
        store_longitude: -80.28646534,
    },
    {
        store_latitdue: -1.2713928,
        store_longitude: -80.4198157,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.124703591,
        store_longitude: -80.22977687,
    },
    {
        store_latitdue: -0.8720862,
        store_longitude: -80.5355802,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.06388,
        store_longitude: -80.5949,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.962951,
        store_longitude: -80.6966,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.697626,
        store_longitude: -80.0921,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.8742123,
        store_longitude: -80.5417421,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.951893,
        store_longitude: -80.6387,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9660886,
        store_longitude: -80.697213,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.7636882,
        store_longitude: -80.507691,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.0544172,
        store_longitude: -80.4497256,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6047266,
        store_longitude: -80.402048,
    },
    {
        store_latitdue: -0.20391,
        store_longitude: -80.262704,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.5917089,
        store_longitude: -80.4048074,
    },

    {
        store_latitdue: -0.6484571,
        store_longitude: -80.0395208,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.5829662,
        store_longitude: -80.0411528,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.5505861,
        store_longitude: -80.0421656,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.056041,
        store_longitude: -80.013086,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -1.110258,
        store_longitude: -80.223169,
        neighborhood: 'HONORATO VÁSQUEZ',
    },

    {
        store_latitdue: -0.857365,
        store_longitude: -80.1951,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0607,
        store_longitude: -80.4825,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9549831,
        store_longitude: -80.6366242,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.05425,
        store_longitude: -80.4875,
    },
    {
        store_latitdue: -0.828167,
        store_longitude: -80.498447,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.95226,
        store_longitude: -80.6998,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.66773,
        store_longitude: -80.0475,
    },
    {
        store_latitdue: -1.1774,
        store_longitude: -80.3874,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.05691,
        store_longitude: -80.468,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.2786,
        store_longitude: -80.4189,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.780299,
        store_longitude: -80.2311,
    },
    {
        store_latitdue: -0.76596918,
        store_longitude: -80.21349181,
    },
    {
        store_latitdue: -0.9605966,
        store_longitude: -80.7167485,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0664801,
        store_longitude: -80.4727041,
    },
    {
        store_latitdue: -0.774234,
        store_longitude: -80.2439,
    },
    {
        store_latitdue: -0.688631,
        store_longitude: -80.0988,
    },
    {
        store_latitdue: -0.9965195,
        store_longitude: -80.6855946,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9771265,
        store_longitude: -80.6879314,
    },
    {
        store_latitdue: -0.870751,
        store_longitude: -80.5402,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.958776,
        store_longitude: -80.7169,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.461945,
        store_longitude: -80.456334,
    },
    {
        store_latitdue: -0.956421,
        store_longitude: -80.7122,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.62907,
        store_longitude: -80.239,
        neighborhood: 'GUALE',
    },
    {
        store_latitdue: -0.626992,
        store_longitude: -80.4267,
    },
    {
        store_latitdue: -0.982212,
        store_longitude: -80.6853,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0160202,
        store_longitude: -80.3860459,
    },
    {
        store_latitdue: -1.20588,
        store_longitude: -80.3741,
    },
    {
        store_latitdue: -0.9594479,
        store_longitude: -80.6355077,
    },
    {
        store_latitdue: -1.04294,
        store_longitude: -80.4689,
    },
    {
        store_latitdue: -0.0276998,
        store_longitude: -79.9680417,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -1.0563614,
        store_longitude: -80.485017,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.97785,
        store_longitude: -80.6855,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.585856,
        store_longitude: -80.4052,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.3784028,
        store_longitude: -80.1847049,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.37852,
        store_longitude: -80.18448,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -1.05334,
        store_longitude: -80.4589,
    },
    {
        store_latitdue: -1.13584,
        store_longitude: -80.4013,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.07619,
        store_longitude: -80.4404,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0598653,
        store_longitude: -80.4626433,
    },
    {
        store_latitdue: -0.694917,
        store_longitude: -80.0999,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0345393,
        store_longitude: -80.4673307,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9676533,
        store_longitude: -80.7089101,
    },
    {
        store_latitdue: -0.9542681,
        store_longitude: -80.6980977,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.205873,
        store_longitude: -80.37149,
    },
    {
        store_latitdue: -0.6955609,
        store_longitude: -80.1018665,
        neighborhood: 'CHONE',
    },

    {
        store_latitdue: -1.14388,
        store_longitude: -80.3691,
    },
    {
        store_latitdue: -1.07946,
        store_longitude: -80.5266,
    },
    {
        store_latitdue: -1.09246,
        store_longitude: -80.4315,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.14371,
        store_longitude: -80.3688,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.984174,
        store_longitude: -80.7069,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.8489501,
        store_longitude: -80.157613,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.7035572,
        store_longitude: -80.0910419,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9383781,
        store_longitude: -80.1995995,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.6950811,
        store_longitude: -80.0912998,
    },
    {
        store_latitdue: -1.0567337,
        store_longitude: -80.481548,
    },
    {
        store_latitdue: -0.79949,
        store_longitude: -80.129,
        neighborhood: 'CANUTO',
    },
    {
        store_latitdue: -1.05699,
        store_longitude: -80.4824,
    },
    {
        store_latitdue: -0.691876,
        store_longitude: -80.1031,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.705688,
        store_longitude: -80.1001,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.708025,
        store_longitude: -80.0981,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9480131,
        store_longitude: -80.466002,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.029073,
        store_longitude: -80.345716,
    },
    {
        store_latitdue: -0.999345,
        store_longitude: -80.4104,
    },
    {
        store_latitdue: -0.778215,
        store_longitude: -80.233887,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.69053,
        store_longitude: -80.1027,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6927113,
        store_longitude: -80.1059803,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.695237,
        store_longitude: -80.1009,
    },
    {
        store_latitdue: -0.8676373,
        store_longitude: -80.5390696,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.0502854,
        store_longitude: -80.4430092,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.901548,
        store_longitude: -80.412842,
    },
    {
        store_latitdue: -0.691038,
        store_longitude: -80.1029,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.979232,
        store_longitude: -80.696,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.05395,
        store_longitude: -80.4419,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.026237,
        store_longitude: -80.240799,
        neighborhood: 'CHIRIJOS',
    },
    {
        store_latitdue: -1.0801581,
        store_longitude: -80.4383808,
    },
    {
        store_latitdue: -0.6995581,
        store_longitude: -80.1042277,
    },
    {
        store_latitdue: -1.0330192,
        store_longitude: -80.3384682,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.03226,
        store_longitude: -80.3361,
    },
    {
        store_latitdue: -0.701691,
        store_longitude: -80.094958,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9597247,
        store_longitude: -80.6978769,
    },
    {
        store_latitdue: -1.05338,
        store_longitude: -80.437,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.036672,
        store_longitude: -80.4625024,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.986153,
        store_longitude: -80.4243,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.9757937,
        store_longitude: -80.7022439,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.699993,
        store_longitude: -80.1127,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0564855,
        store_longitude: -80.4812029,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7041825,
        store_longitude: -80.0896482,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.954135,
        store_longitude: -80.7038,
    },
    {
        store_latitdue: -0.982743,
        store_longitude: -80.7053,
    },
    {
        store_latitdue: -0.986283,
        store_longitude: -80.7073,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.981735,
        store_longitude: -80.7056,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.404544,
        store_longitude: -79.9066,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.87082,
        store_longitude: -80.54032,
    },
    {
        store_latitdue: -0.960937023,
        store_longitude: -80.70980876,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9686883,
        store_longitude: -80.695294,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.04768,
        store_longitude: -80.4553,
    },
    {
        store_latitdue: -1.05498,
        store_longitude: -80.4504,
    },
    {
        store_latitdue: -1.06602,
        store_longitude: -80.2683,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -1.05592,
        store_longitude: -80.0132,
    },
    {
        store_latitdue: -0.6020962,
        store_longitude: -80.4042288,
    },
    {
        store_latitdue: -0.6532528,
        store_longitude: -80.4081775,
    },
    {
        store_latitdue: -0.5916546,
        store_longitude: -80.4048451,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -1.20732,
        store_longitude: -80.3712,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.15766,
        store_longitude: -80.2869,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -0.96705136,
        store_longitude: -80.7145678,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9856448,
        store_longitude: -80.6993552,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.975949,
        store_longitude: -80.7011,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9776468,
        store_longitude: -80.4312042,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -1.025566,
        store_longitude: -80.3509023,
    },
    {
        store_latitdue: -0.2097884,
        store_longitude: -80.2568991,
    },
    {
        store_latitdue: -0.94635,
        store_longitude: -80.6434,
    },
    {
        store_latitdue: -0.78852796,
        store_longitude: -80.2335433,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.841416,
        store_longitude: -80.1692,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.948868,
        store_longitude: -80.6594,
    },
    {
        store_latitdue: -0.8513305,
        store_longitude: -80.183629,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0560371,
        store_longitude: -80.4701068,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.450072,
        store_longitude: -79.99522,
        neighborhood: 'ZAPALLO',
    },
    {
        store_latitdue: -0.925167,
        store_longitude: -80.4517,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9920295,
        store_longitude: -80.6857921,
    },
    {
        store_latitdue: -1.0522,
        store_longitude: -80.4445,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.685495,
        store_longitude: -80.2502,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: 0.932615,
        store_longitude: -79.6736,
    },
    {
        store_latitdue: -0.951368,
        store_longitude: -80.7003,
    },
    {
        store_latitdue: -0.9671537,
        store_longitude: -80.7181693,
    },
    {
        store_latitdue: -0.700757,
        store_longitude: -80.0871,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.886324,
        store_longitude: -80.4996,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.4793063,
        store_longitude: -80.3308076,
    },
    {
        store_latitdue: -0.479852,
        store_longitude: -80.3309,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -1.03024,
        store_longitude: -80.3421,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.379327,
        store_longitude: -80.1864,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -1.09634,
        store_longitude: -80.4122,
    },
    {
        store_latitdue: -0.707518,
        store_longitude: -80.0961,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0630847,
        store_longitude: -80.4881667,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0580337,
        store_longitude: -80.4856185,
    },
    {
        store_latitdue: -0.858355,
        store_longitude: -80.5312,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.04255,
        store_longitude: -80.4692,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05522,
        store_longitude: -80.2795195,
    },
    {
        store_latitdue: -0.64751438,
        store_longitude: -80.2964329,
    },
    {
        store_latitdue: -0.1871704,
        store_longitude: -80.2960022,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.9518604,
        store_longitude: -80.6471339,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.0659436,
        store_longitude: -80.4490856,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06851,
        store_longitude: -80.4511,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9628224,
        store_longitude: -80.6998222,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.950001,
        store_longitude: -80.442365,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.95488,
        store_longitude: -80.7088,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.05665,
        store_longitude: -80.4652,
    },
    {
        store_latitdue: -1.06972,
        store_longitude: -80.4747,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -0.203204,
        store_longitude: -80.2632,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.48458,
        store_longitude: -80.238,
        neighborhood: 'LASCANO',
    },
    {
        store_latitdue: -0.81141,
        store_longitude: -80.2646,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.9879492,
        store_longitude: -80.700442,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.975604,
        store_longitude: -80.701,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.921531,
        store_longitude: -80.4468,
        neighborhood: 'ROCAFUERTE',
    },

    {
        store_latitdue: -0.9558749,
        store_longitude: -80.7127225,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.06072,
        store_longitude: -80.474,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7016418,
        store_longitude: -80.0848567,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.986272,
        store_longitude: -80.6835,
    },
    {
        store_latitdue: -0.989948,
        store_longitude: -80.6815,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9705915,
        store_longitude: -80.6987219,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9474173,
        store_longitude: -80.7316397,
    },
    {
        store_latitdue: -0.991146,
        store_longitude: -80.466908,
    },
    {
        store_latitdue: -1.0473879,
        store_longitude: -80.4578468,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.641624,
        store_longitude: -80.3124,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -1.0587446,
        store_longitude: -80.4554882,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.96038063,
        store_longitude: -80.6825208,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.96031105,
        store_longitude: -80.6699829,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.95363985,
        store_longitude: -80.7155827,
    },
    {
        store_latitdue: -1.0535,
        store_longitude: -80.4547,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.1904825,
        store_longitude: -80.2897242,
        neighborhood: 'JAMA',
    },

    {
        store_latitdue: -1.09725,
        store_longitude: -80.4243,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05503,
        store_longitude: -80.4582,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8471456,
        store_longitude: -80.163342,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.06241,
        store_longitude: -80.4584,
    },
    {
        store_latitdue: -1.06308,
        store_longitude: -80.5931,
    },
    {
        store_latitdue: -0.986649,
        store_longitude: -80.6795,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.600829,
        store_longitude: -80.4049,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -1.05881,
        store_longitude: -80.453066,
    },
    {
        store_latitdue: -1.530175,
        store_longitude: -80.231183,
    },
    {
        store_latitdue: -1.12521,
        store_longitude: -80.4052,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.404664,
        store_longitude: -79.9068,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.07267,
        store_longitude: -80.4808,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8703,
        store_longitude: -80.54004,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.09846,
        store_longitude: -80.4267,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.058724,
        store_longitude: -80.470191,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9197798,
        store_longitude: -80.4430654,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.873976,
        store_longitude: -80.5381,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.033612,
        store_longitude: -80.455228,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.039677,
        store_longitude: -80.466355,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.034924,
        store_longitude: -80.493041,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0571634,
        store_longitude: -80.4476923,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0569036,
        store_longitude: -80.43904,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06013,
        store_longitude: -80.4465,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8416966,
        store_longitude: -80.15895,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.05493,
        store_longitude: -80.4646,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0528773,
        store_longitude: -80.4608507,
    },
    {
        store_latitdue: -0.8546336,
        store_longitude: -80.5326696,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.9234313,
        store_longitude: -80.4529943,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.036225,
        store_longitude: -80.495554,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.652880952,
        store_longitude: -80.40701096,
    },
    {
        store_latitdue: -0.970696,
        store_longitude: -80.4372606,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9610887,
        store_longitude: -80.6385162,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.100967,
        store_longitude: -80.4213667,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06606,
        store_longitude: -80.4462,
    },
    {
        store_latitdue: -0.8383072,
        store_longitude: -80.1603916,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.94460777,
        store_longitude: -80.4582774,
    },
    {
        store_latitdue: -0.928223,
        store_longitude: -80.206704,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.3808103,
        store_longitude: -80.1894017,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.973994,
        store_longitude: -80.6805,
    },
    {
        store_latitdue: -0.9755284,
        store_longitude: -80.6790124,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.973007,
        store_longitude: -80.6812,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.845395,
        store_longitude: -80.1599,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.699977,
        store_longitude: -80.1011,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.966706,
        store_longitude: -80.7142,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.653132,
        store_longitude: -80.4064,
    },
    {
        store_latitdue: -0.6361106,
        store_longitude: -80.3253521,
    },
    {
        store_latitdue: -0.9815625,
        store_longitude: -80.6849473,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9919536,
        store_longitude: -80.631685,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.078791,
        store_longitude: -80.461091,
    },
    {
        store_latitdue: -0.953862131,
        store_longitude: -80.70374298,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.8716939,
        store_longitude: -80.1892322,
    },
    {
        store_latitdue: -1.05337,
        store_longitude: -80.462,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.693535,
        store_longitude: -80.1,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6948204,
        store_longitude: -80.0945214,
    },
    {
        store_latitdue: -0.986655,
        store_longitude: -80.6882,
    },
    {
        store_latitdue: -0.9273199,
        store_longitude: -80.207461,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.997952,
        store_longitude: -80.419595,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.92718517,
        store_longitude: -80.2074813,
        neighborhood: 'JUNÍN',
    },

    {
        store_latitdue: -0.959951,
        store_longitude: -80.6665231,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.985621,
        store_longitude: -80.6782,
    },
    {
        store_latitdue: -1.04177,
        store_longitude: -80.4997,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0317,
        store_longitude: -80.24,
    },
    {
        store_latitdue: -1.07545974,
        store_longitude: -80.4562282,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.075446,
        store_longitude: -80.4564189,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.963324,
        store_longitude: -80.698849,
    },
    {
        store_latitdue: -1.0548805,
        store_longitude: -80.4681759,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.704284,
        store_longitude: -80.0987,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.953153,
        store_longitude: -80.7017202,
    },
    {
        store_latitdue: -1.0568192,
        store_longitude: -80.4386432,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9630451,
        store_longitude: -80.6878382,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9241028,
        store_longitude: -80.4473122,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.849168,
        store_longitude: -80.1585,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.698261,
        store_longitude: -80.110055,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.05895,
        store_longitude: -80.4871,
    },
    {
        store_latitdue: -0.9658055,
        store_longitude: -80.6934856,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9524349,
        store_longitude: -80.4868372,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.01695,
        store_longitude: -80.4708,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.951491,
        store_longitude: -80.48613,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0521167,
        store_longitude: -80.268501,
    },
    {
        store_latitdue: -1.06142,
        store_longitude: -80.246,
    },
    {
        store_latitdue: -0.973967,
        store_longitude: -80.4201,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.969054,
        store_longitude: -80.4258,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.965272,
        store_longitude: -80.4378652,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.851800232,
        store_longitude: -80.16333808,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.78673,
        store_longitude: -80.234223,
    },
    {
        store_latitdue: -1.039956,
        store_longitude: -80.4673785,
    },
    {
        store_latitdue: -0.667817,
        store_longitude: -80.0477,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.910785,
        store_longitude: -80.5011,
    },
    {
        store_latitdue: -1.03445,
        store_longitude: -80.4942,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0618638,
        store_longitude: -80.4384767,
    },
    {
        store_latitdue: -0.970171,
        store_longitude: -80.4234,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.9582438,
        store_longitude: -80.6772429,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.7891829,
        store_longitude: -80.2337321,
    },
    {
        store_latitdue: -0.7863659,
        store_longitude: -80.2328976,
    },
    {
        store_latitdue: -1.058493,
        store_longitude: -80.4698393,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6988451,
        store_longitude: -80.0936552,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.1858687,
        store_longitude: -80.291578,
    },
    {
        store_latitdue: -1.0314475,
        store_longitude: -80.3968829,
    },
    {
        store_latitdue: -0.9504128,
        store_longitude: -80.658882,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.023186499,
        store_longitude: -80.58242669,
    },
    {
        store_latitdue: -1.39627,
        store_longitude: -80.2093,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.3965968,
        store_longitude: -80.2085285,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.39474,
        store_longitude: -80.210999,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.0622513,
        store_longitude: -80.4522564,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.84552133,
        store_longitude: -80.1654205,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.9878248,
        store_longitude: -80.7011183,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.404254,
        store_longitude: -79.9055,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.9699395,
        store_longitude: -80.6989357,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.07445,
        store_longitude: -80.442449,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7974025,
        store_longitude: -80.1252361,
    },
    {
        store_latitdue: -1.04942,
        store_longitude: -80.4592,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.643617,
        store_longitude: -80.422707,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.8470088,
        store_longitude: -80.1632394,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0623721,
        store_longitude: -80.4591109,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.275319,
        store_longitude: -80.037,
        neighborhood: 'CONVENTO',
    },
    {
        store_latitdue: -1.0462985,
        store_longitude: -80.2658421,
    },
    {
        store_latitdue: -1.124175,
        store_longitude: -80.4079644,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05896,
        store_longitude: -80.4581,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0369358,
        store_longitude: -80.4701487,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.1391424,
        store_longitude: -80.3915746,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6972338,
        store_longitude: -80.0859203,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6989762,
        store_longitude: -80.0808517,
    },
    {
        store_latitdue: -1.05125415,
        store_longitude: -80.4580154,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0506588,
        store_longitude: -80.4870761,
    },
    {
        store_latitdue: -1.0173236,
        store_longitude: -80.4657278,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9600038,
        store_longitude: -80.6667074,
    },
    {
        store_latitdue: -1.03933,
        store_longitude: -80.4666,
    },
    {
        store_latitdue: -0.796041,
        store_longitude: -80.5054,
    },
    {
        store_latitdue: -0.4055213,
        store_longitude: -79.9043738,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.9708853,
        store_longitude: -80.7130926,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.987081,
        store_longitude: -80.7018,
    },
    {
        store_latitdue: -1.0135527,
        store_longitude: -80.4688143,
    },
    {
        store_latitdue: -1.0752081,
        store_longitude: -80.4404283,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9451785,
        store_longitude: -80.6377063,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.0576223,
        store_longitude: -80.4668319,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06376,
        store_longitude: -80.4498,
    },
    {
        store_latitdue: -0.9572107,
        store_longitude: -80.7092135,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9249046,
        store_longitude: -80.4535354,
    },
    {
        store_latitdue: -0.5916811,
        store_longitude: -80.4043021,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.96529573,
        store_longitude: -80.7133331,
    },
    {
        store_latitdue: -1.05292,
        store_longitude: -80.4795,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.18766846,
        store_longitude: -80.2878069,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.184095,
        store_longitude: -80.2893,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.0522316,
        store_longitude: -80.4502201,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9455304,
        store_longitude: -80.6379617,
    },
    {
        store_latitdue: -0.9626802,
        store_longitude: -80.635354,
    },
    {
        store_latitdue: -0.9537976,
        store_longitude: -80.7080809,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.39596903,
        store_longitude: -80.2095718,
    },
    {
        store_latitdue: -1.0417304,
        store_longitude: -80.4854001,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.404557,
        store_longitude: -79.9062,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -1.01851,
        store_longitude: -80.4616,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7778731,
        store_longitude: -80.515514,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.135047,
        store_longitude: -80.394972,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.02413054,
        store_longitude: -80.5837733,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.8456873,
        store_longitude: -80.1630665,
    },
    {
        store_latitdue: -0.696552,
        store_longitude: -80.0916903,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9262889,
        store_longitude: -80.4536078,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.9730791,
        store_longitude: -80.6883425,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9700824,
        store_longitude: -80.6904518,
    },
    {
        store_latitdue: -0.7853039,
        store_longitude: -80.5142843,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.6950725,
        store_longitude: -80.0935399,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.2095202,
        store_longitude: -80.2580884,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.973449567,
        store_longitude: -80.7104965,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0860335,
        store_longitude: -80.4322807,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.964046,
        store_longitude: -80.6641,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9520841,
        store_longitude: -80.7003982,
    },
    {
        store_latitdue: -0.2767576,
        store_longitude: -80.0367868,
    },
    {
        store_latitdue: -1.0507529,
        store_longitude: -80.3176416,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.39587,
        store_longitude: -80.205063,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.0798378,
        store_longitude: -80.4544049,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.95919896,
        store_longitude: -80.6358182,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.0528398,
        store_longitude: -80.4837801,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9708474,
        store_longitude: -80.7130727,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0146027,
        store_longitude: -80.4640114,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05792,
        store_longitude: -80.4823,
    },
    {
        store_latitdue: -0.9620126,
        store_longitude: -80.6904075,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0453569,
        store_longitude: -80.4496235,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05727994,
        store_longitude: -80.4455642,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.149488224,
        store_longitude: -80.39547081,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.952588,
        store_longitude: -80.6347,
    },
    {
        store_latitdue: -1.28034,
        store_longitude: -80.4186,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.96023571,
        store_longitude: -80.7253718,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9534708,
        store_longitude: -80.7165916,
    },
    {
        store_latitdue: -0.9933797,
        store_longitude: -80.6872185,
    },
    {
        store_latitdue: -0.978843,
        store_longitude: -80.6901,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.02585,
        store_longitude: -80.2241,
        neighborhood: 'CHIRIJOS',
    },
    {
        store_latitdue: -1.06999,
        store_longitude: -80.4327,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.95100718,
        store_longitude: -80.7107543,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.98418152,
        store_longitude: -80.7064361,
    },
    {
        store_latitdue: -0.9751068,
        store_longitude: -80.7082834,
    },
    {
        store_latitdue: -0.9625921,
        store_longitude: -80.687923,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.980809,
        store_longitude: -80.6986155,
    },
    {
        store_latitdue: -0.432661,
        store_longitude: -80.453125,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.9608919,
        store_longitude: -80.6683197,
    },
    {
        store_latitdue: -1.0688057,
        store_longitude: -80.491663,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8491726,
        store_longitude: -80.5023394,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.8148759,
        store_longitude: -80.5191637,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.9635706,
        store_longitude: -80.717327,
    },
    {
        store_latitdue: -0.9714828,
        store_longitude: -80.6989641,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.047454,
        store_longitude: -80.443642,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.5998237,
        store_longitude: -80.42502,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.828043,
        store_longitude: -80.495154,
    },
    {
        store_latitdue: -0.9550032,
        store_longitude: -80.7221374,
    },
    {
        store_latitdue: -0.9718256,
        store_longitude: -80.7067061,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0034858,
        store_longitude: -80.4652708,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.2075381,
        store_longitude: -80.3711103,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.7009043,
        store_longitude: -80.1106329,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0109543,
        store_longitude: -80.4570697,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.983939,
        store_longitude: -80.4555,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.028204,
        store_longitude: -80.344884,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.06769,
        store_longitude: -80.4346,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.208659,
        store_longitude: -80.2601,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -1.1012556,
        store_longitude: -80.4214462,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9721592,
        store_longitude: -80.6813117,
    },
    {
        store_latitdue: -1.2794198,
        store_longitude: -80.4190044,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.7840969,
        store_longitude: -80.5150134,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.986101,
        store_longitude: -80.707,
    },
    {
        store_latitdue: -0.98309023,
        store_longitude: -80.7070577,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0379574,
        store_longitude: -80.3329318,
    },
    {
        store_latitdue: -1.20746,
        store_longitude: -80.3713,
    },
    {
        store_latitdue: -0.8482015,
        store_longitude: -80.1546585,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.693713,
        store_longitude: -80.0949,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.983912,
        store_longitude: -80.705737,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9636909,
        store_longitude: -80.6849673,
    },
    {
        store_latitdue: -1.0340972,
        store_longitude: -80.4893031,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.3774058,
        store_longitude: -80.2698634,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -1.0517394,
        store_longitude: -80.4489104,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.07164,
        store_longitude: -80.4457,
    },
    {
        store_latitdue: -0.950225,
        store_longitude: -80.7095,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9731323,
        store_longitude: -80.70968,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9810867,
        store_longitude: -80.69752,
    },
    {
        store_latitdue: -0.9631692,
        store_longitude: -80.7013491,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9392447,
        store_longitude: -80.4433244,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.6281533,
        store_longitude: -80.4257612,
    },
    {
        store_latitdue: -0.760321,
        store_longitude: -80.511876,
        neighborhood: 'CHARAPOTÓ',
    },

    {
        store_latitdue: -0.95604395,
        store_longitude: -80.6629836,
    },
    {
        store_latitdue: -0.9280129,
        store_longitude: -80.2073059,
    },
    {
        store_latitdue: -1.058794,
        store_longitude: -80.465969,
    },
    {
        store_latitdue: -0.70452,
        store_longitude: -80.0898,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.054109,
        store_longitude: -80.478315,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9802799,
        store_longitude: -80.7020903,
    },
    {
        store_latitdue: -0.9739866,
        store_longitude: -80.6962293,
    },
    {
        store_latitdue: -1.03235,
        store_longitude: -80.4548,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.03651,
        store_longitude: -80.4924,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8997769,
        store_longitude: -80.4826244,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.6016653,
        store_longitude: -80.403961,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -2.08764,
        store_longitude: -79.9414,
    },
    {
        store_latitdue: -1.209271,
        store_longitude: -80.370337,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.9841127,
        store_longitude: -80.7082805,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9805762,
        store_longitude: -80.7034177,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.696048,
        store_longitude: -80.1195,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.696805,
        store_longitude: -80.1183,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.697736,
        store_longitude: -80.0891,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0333,
        store_longitude: -80.4648,
    },

    {
        store_latitdue: -0.9528486,
        store_longitude: -80.7131662,
    },
    {
        store_latitdue: -0.789222,
        store_longitude: -80.2343,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -1.05849,
        store_longitude: -80.473,
    },
    {
        store_latitdue: -1.0513625,
        store_longitude: -80.4893516,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0704447,
        store_longitude: -80.4806763,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6990107,
        store_longitude: -80.0950366,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0481777,
        store_longitude: -80.4561938,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.040048,
        store_longitude: -80.4673828,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -1.1380719,
        store_longitude: -80.4002212,
    },
    {
        store_latitdue: -1.065517,
        store_longitude: -80.4631449,
    },
    {
        store_latitdue: -0.777458,
        store_longitude: -80.5167283,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -1.04827,
        store_longitude: -80.488,
    },
    {
        store_latitdue: -0.705743,
        store_longitude: -80.0997,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.058759,
        store_longitude: -80.4747079,
    },
    {
        store_latitdue: -1.0478054,
        store_longitude: -80.4882988,
    },
    {
        store_latitdue: -0.8575392,
        store_longitude: -80.5335591,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.0624297,
        store_longitude: -80.458641,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9506395,
        store_longitude: -80.7038391,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0173729,
        store_longitude: -80.4652878,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9650461,
        store_longitude: -80.699188,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9805386,
        store_longitude: -80.693013,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.463762,
        store_longitude: -80.456812,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.9524927,
        store_longitude: -80.647209,
    },
    {
        store_latitdue: -0.9527766,
        store_longitude: -80.7077174,
    },
    {
        store_latitdue: -0.80595,
        store_longitude: -80.2551,
    },
    {
        store_latitdue: -0.6982245,
        store_longitude: -80.0979041,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.3757333,
        store_longitude: -80.1819832,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -1.05433,
        store_longitude: -80.4936,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0786571,
        store_longitude: -80.4550506,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0678962,
        store_longitude: -80.452748,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6949103,
        store_longitude: -80.0938376,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.13265025,
        store_longitude: -80.4026565,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9779684,
        store_longitude: -80.6983523,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.6967925,
        store_longitude: -80.1182744,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9601572,
        store_longitude: -80.7181337,
    },
    {
        store_latitdue: -1.0776117,
        store_longitude: -80.4417883,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05859838,
        store_longitude: -80.4611098,
    },
    {
        store_latitdue: -1.0593766,
        store_longitude: -80.4841878,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.95273,
        store_longitude: -80.7026,
    },
    {
        store_latitdue: -0.96641278,
        store_longitude: -80.7037963,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.97006,
        store_longitude: -80.7059,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.980953,
        store_longitude: -80.6783982,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9696936,
        store_longitude: -80.7067607,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.35089983,
        store_longitude: -80.5824899,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.0460312,
        store_longitude: -80.4957342,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.989747,
        store_longitude: -80.6799,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.64354,
        store_longitude: -80.4228259,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -1.065477,
        store_longitude: -80.4552154,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9844001,
        store_longitude: -80.701318,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.994102,
        store_longitude: -80.685,
    },
    {
        store_latitdue: -1.0710576,
        store_longitude: -80.4340429,
    },
    {
        store_latitdue: -0.6390445,
        store_longitude: -80.4182786,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.781374,
        store_longitude: -80.2289,
    },
    {
        store_latitdue: -0.7814747,
        store_longitude: -80.2288148,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -1.024617,
        store_longitude: -80.240354,
        neighborhood: 'CHIRIJOS',
    },
    {
        store_latitdue: 0.0089027,
        store_longitude: -79.393577,
    },
    {
        store_latitdue: -1.2041971,
        store_longitude: -80.3733802,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0647246697,
        store_longitude: -80.5677698668,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.7012275,
        store_longitude: -80.0796736,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9905498,
        store_longitude: -80.6798358,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9644824,
        store_longitude: -80.6941509,
    },
    {
        store_latitdue: -1.0158116,
        store_longitude: -80.4654565,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6412571,
        store_longitude: -80.4248409,
    },
    {
        store_latitdue: -0.9781575,
        store_longitude: -80.7049544,
    },
    {
        store_latitdue: -0.9634998,
        store_longitude: -80.668582,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.404606,
        store_longitude: -79.910901,
        neighborhood: 'FLAVIO ALFARO',
    },

    {
        store_latitdue: -1.0593294,
        store_longitude: -80.4638043,
    },
    {
        store_latitdue: -1.0807286,
        store_longitude: -80.4996926,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9213558,
        store_longitude: -80.4503222,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.938147,
        store_longitude: -80.1999,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.9667234,
        store_longitude: -80.6764128,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9663186,
        store_longitude: -80.4416472,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.962315321,
        store_longitude: -80.71166229,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.70462,
        store_longitude: -80.1623,
    },
    {
        store_latitdue: -0.7853411,
        store_longitude: -80.5151685,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.960548,
        store_longitude: -80.7190586,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00078,
        store_longitude: -80.6852,
    },
    {
        store_latitdue: -0.9521883,
        store_longitude: -80.7269306,
    },
    {
        store_latitdue: -1.023135,
        store_longitude: -80.579849,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -0.959292,
        store_longitude: -80.715675,
    },
    {
        store_latitdue: -1.0250625,
        store_longitude: -80.5828491,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.186686,
        store_longitude: -80.2936,
    },
    {
        store_latitdue: -0.4611902,
        store_longitude: -80.4543226,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.9984361,
        store_longitude: -80.3094547,
        neighborhood: 'PUEBLO NUEVO',
    },
    {
        store_latitdue: -1.2602781,
        store_longitude: -80.421096,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.8446172,
        store_longitude: -80.1597648,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.848077,
        store_longitude: -80.1643,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.411298,
        store_longitude: -80.3915034,
        neighborhood: 'NOBOA',
    },
    {
        store_latitdue: -0.7044332,
        store_longitude: -80.0892589,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.700451,
        store_longitude: -80.111817,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6726997,
        store_longitude: -80.1309882,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.700881,
        store_longitude: -80.0922,
    },
    {
        store_latitdue: -0.8479944,
        store_longitude: -80.5321184,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.216519,
        store_longitude: -80.41557,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -1.2209122,
        store_longitude: -80.4206707,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.70427,
        store_longitude: -80.1673,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.703056,
        store_longitude: -80.0869,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.20777,
        store_longitude: -80.3800086,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.765949,
        store_longitude: -80.2134,
        neighborhood: 'BACHILLERO',
    },
    {
        store_latitdue: -1.4487003,
        store_longitude: -80.4487003,
    },
    {
        store_latitdue: -1.0536168,
        store_longitude: -80.4793168,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.699754,
        store_longitude: -80.3177,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.6779535,
        store_longitude: -80.3603119,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -1.041278,
        store_longitude: -80.468459,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.410505,
        store_longitude: -80.39283,
        neighborhood: 'NOBOA',
    },
    {
        store_latitdue: -1.040284,
        store_longitude: -80.4724911,
    },
    {
        store_latitdue: -1.0171486,
        store_longitude: -80.4651049,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9584852,
        store_longitude: -80.7170451,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9765893,
        store_longitude: -80.6953019,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9574981,
        store_longitude: -80.7204648,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9776816,
        store_longitude: -80.6940778,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00265286,
        store_longitude: -80.7098667,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.952263,
        store_longitude: -80.7066,
    },
    {
        store_latitdue: -0.980346,
        store_longitude: -80.6974718,
    },
    {
        store_latitdue: -0.9552427566,
        store_longitude: -80.7022666522,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.9681699,
        store_longitude: -80.682533,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.979369,
        store_longitude: -80.6848581,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.976535,
        store_longitude: -80.6795,
    },
    {
        store_latitdue: -0.96902,
        store_longitude: -80.67682,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.966712,
        store_longitude: -80.664925,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9456615,
        store_longitude: -80.6430131,
    },
    {
        store_latitdue: -0.6414033,
        store_longitude: -80.4251576,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.8518614,
        store_longitude: -80.5303939,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.846811,
        store_longitude: -80.350329,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.04781,
        store_longitude: -80.4472,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.047709,
        store_longitude: -80.447803,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9480739,
        store_longitude: -80.638815,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.97459,
        store_longitude: -80.6786,
    },
    {
        store_latitdue: -0.5217533,
        store_longitude: -80.4425317,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.5917071,
        store_longitude: -80.4048632,
        neighborhood: 'SAN VICENTE',
    },

    {
        store_latitdue: -0.5231991,
        store_longitude: -80.4416556,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.6361499,
        store_longitude: -80.4196119,
    },

    {
        store_latitdue: -0.455827,
        store_longitude: -80.4525,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.4625683,
        store_longitude: -80.45623,
    },
    {
        store_latitdue: -0.64611,
        store_longitude: -80.2972,
    },
    {
        store_latitdue: -1.06383,
        store_longitude: -80.2404,
    },
    {
        store_latitdue: -0.925035,
        store_longitude: -80.454,
    },
    {
        store_latitdue: -1.0546275,
        store_longitude: -80.2649059,
        neighborhood: 'ALHAJUELA',
    },
    {
        store_latitdue: -0.7075409,
        store_longitude: -80.0966029,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9236736,
        store_longitude: -80.4508492,
    },

    {
        store_latitdue: -0.4626936,
        store_longitude: -80.4550334,
    },
    {
        store_latitdue: -0.9631734,
        store_longitude: -80.7026516,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.60318,
        store_longitude: -80.43017,
    },
    {
        store_latitdue: -1.05189,
        store_longitude: -80.4488,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.7647287,
        store_longitude: -80.5076329,
    },
    {
        store_latitdue: -1.0570874,
        store_longitude: -80.476334,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.055072,
        store_longitude: -80.458228,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.2158933,
        store_longitude: -80.3475988,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.3945845,
        store_longitude: -80.2113558,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.3970068,
        store_longitude: -80.2118191,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.394602,
        store_longitude: -80.212113,
    },
    {
        store_latitdue: -1.3674033,
        store_longitude: -80.2782483,
    },
    {
        store_latitdue: -1.0534584,
        store_longitude: -80.4494559,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -1.0151296,
        store_longitude: -80.4788588,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -1.0507706,
        store_longitude: -80.4437277,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.766128,
        store_longitude: -80.2147,
    },
    {
        store_latitdue: -0.4074434,
        store_longitude: -79.90275,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.4065003,
        store_longitude: -79.9050268,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.4048108,
        store_longitude: -79.9077312,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.8857648,
        store_longitude: -80.0973306,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: -0.5515982,
        store_longitude: -80.0425209,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.619312,
        store_longitude: -80.4265,
    },
    {
        store_latitdue: -1.04289,
        store_longitude: -80.4979,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.6930081,
        store_longitude: -80.0934241,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.84889741,
        store_longitude: -80.5322849,
    },
    {
        store_latitdue: -0.9559419,
        store_longitude: -80.4697852,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.6961151,
        store_longitude: -80.1191012,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.8166803,
        store_longitude: -80.2157382,
        neighborhood: 'ÁNGEL PEDRO GILER',
    },
    {
        store_latitdue: -0.7792748,
        store_longitude: -80.5166856,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.186079,
        store_longitude: -80.2929504,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.9674932,
        store_longitude: -80.6917683,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9859427,
        store_longitude: -80.6842105,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.6413485,
        store_longitude: -80.4238152,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.98786227,
        store_longitude: -80.6874454,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.987203,
        store_longitude: -80.6871,
    },
    {
        store_latitdue: -0.9891411,
        store_longitude: -80.6854233,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9906129,
        store_longitude: -80.6865084,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9856992,
        store_longitude: -80.6850169,
    },
    {
        store_latitdue: -0.95527,
        store_longitude: -80.7122468,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.652825,
        store_longitude: -80.4051,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },
    {
        store_latitdue: -0.976036,
        store_longitude: -80.7026,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.8382936,
        store_longitude: -80.4865786,
    },
    {
        store_latitdue: -0.758166,
        store_longitude: -80.5108,
    },
    {
        store_latitdue: -0.9619622587,
        store_longitude: -80.7028546929,
    },

    {
        store_latitdue: -0.981956,
        store_longitude: -80.6906,
    },
    {
        store_latitdue: -0.9676533,
        store_longitude: -80.7089101,
    },
    {
        store_latitdue: -0.9578352,
        store_longitude: -80.7188207,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9868674,
        store_longitude: -80.6859007,
    },
    {
        store_latitdue: -0.65076,
        store_longitude: -80.4074,
    },
    {
        store_latitdue: -0.9514232,
        store_longitude: -80.7115249,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.641264,
        store_longitude: -80.4217,
    },
    {
        store_latitdue: -0.6412561,
        store_longitude: -80.4210138,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },

    {
        store_latitdue: -0.9690823,
        store_longitude: -80.6481698,
    },
    {
        store_latitdue: -0.9585232,
        store_longitude: -80.6669242,
    },
    {
        store_latitdue: -0.9713388,
        store_longitude: -80.6402123,
    },
    {
        store_latitdue: -0.9676333,
        store_longitude: -80.6945633,
    },
    {
        store_latitdue: -0.959139,
        store_longitude: -80.6659417,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9653286,
        store_longitude: -80.663095,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9632046,
        store_longitude: -80.6733955,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9661761,
        store_longitude: -80.6727308,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9560848,
        store_longitude: -80.7144423,
    },
    {
        store_latitdue: -0.9552861,
        store_longitude: -80.7178341,
    },

    {
        store_latitdue: -0.9708281,
        store_longitude: -80.7118261,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9694186,
        store_longitude: -80.7081609,
    },
    {
        store_latitdue: -0.970236,
        store_longitude: -80.6761,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.4634135,
        store_longitude: -80.4525114,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.46289,
        store_longitude: -80.4507,
    },
    {
        store_latitdue: -0.46286,
        store_longitude: -80.4509,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -2.26646,
        store_longitude: -79.8928,
    },

    {
        store_latitdue: -0.4615199,
        store_longitude: -80.4553444,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.960587,
        store_longitude: -80.6384,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9762692,
        store_longitude: -80.7047433,
    },
    {
        store_latitdue: -0.954637,
        store_longitude: -80.7149723,
    },
    {
        store_latitdue: -0.96624118,
        store_longitude: -80.6855773,
    },
    {
        store_latitdue: -0.521602,
        store_longitude: -80.4387,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.462335,
        store_longitude: -80.457119,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.9713606,
        store_longitude: -80.6887329,
    },
    {
        store_latitdue: -0.8288247,
        store_longitude: -80.4950504,
    },
    {
        store_latitdue: -0.96874,
        store_longitude: -80.7104,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.4629498,
        store_longitude: -80.4547226,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.01114,
        store_longitude: -78.4475,
    },

    {
        store_latitdue: -0.52385878,
        store_longitude: -80.4414901,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.8375817,
        store_longitude: -80.4880264,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.980083,
        store_longitude: -80.6868,
    },

    {
        store_latitdue: -0.9720887,
        store_longitude: -80.706883,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.5993221,
        store_longitude: -80.4061,
    },
    {
        store_latitdue: -0.953327,
        store_longitude: -80.7075,
    },
    {
        store_latitdue: -0.9552951,
        store_longitude: -80.7078451,
    },
    {
        store_latitdue: -0.9639598,
        store_longitude: -80.6383533,
    },
    {
        store_latitdue: -0.9711991,
        store_longitude: -80.6386018,
    },
    {
        store_latitdue: -0.6045308,
        store_longitude: -80.4007404,
    },
    {
        store_latitdue: -0.9491687,
        store_longitude: -80.6402107,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.605115,
        store_longitude: -80.4051715,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9545637,
        store_longitude: -80.7071036,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9814891,
        store_longitude: -80.6986908,
    },
    {
        store_latitdue: -0.9716264,
        store_longitude: -80.6390299,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.954007,
        store_longitude: -80.7098,
    },
    {
        store_latitdue: -0.9539849,
        store_longitude: -80.7114633,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9601161,
        store_longitude: -80.6823804,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.600211,
        store_longitude: -80.4056,
    },
    {
        store_latitdue: -0.6499807,
        store_longitude: -80.2949514,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9818498,
        store_longitude: -80.6866573,
    },

    {
        store_latitdue: -0.98966059,
        store_longitude: -80.68411485,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -0.953032,
        store_longitude: -80.699,
    },
    {
        store_latitdue: -0.9645989,
        store_longitude: -80.6963025,
    },
    {
        store_latitdue: -1.2782708,
        store_longitude: -80.4243066,
    },
    {
        store_latitdue: -0.9850124,
        store_longitude: -80.7019815,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9626005,
        store_longitude: -80.6711789,
    },
    {
        store_latitdue: -0.4523063,
        store_longitude: -80.3105924,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.965681,
        store_longitude: -80.664,
    },
    {
        store_latitdue: -0.9488082,
        store_longitude: -80.6981137,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.5042221,
        store_longitude: -80.4211344,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.50433,
        store_longitude: -80.4211,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -0.3774479,
        store_longitude: -80.1994706,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.971984,
        store_longitude: -80.713,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.6942237,
        store_longitude: -80.0954393,
    },
    {
        store_latitdue: -0.64682778,
        store_longitude: -80.3188025,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9804187,
        store_longitude: -80.6974811,
    },
    {
        store_latitdue: -0.9517325,
        store_longitude: -80.6943276,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.984312,
        store_longitude: -80.7009,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.951037,
        store_longitude: -80.6984402,
    },
    {
        store_latitdue: -0.965396,
        store_longitude: -80.6639,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.985269,
        store_longitude: -80.7029,
    },
    {
        store_latitdue: -0.9596181,
        store_longitude: -80.6814016,
    },

    {
        store_latitdue: -0.987334,
        store_longitude: -80.7062,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.6318743,
        store_longitude: -80.3613074,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.98332947,
        store_longitude: -80.7033455,
    },
    {
        store_latitdue: -0.381723,
        store_longitude: -80.1944,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.380221,
        store_longitude: -80.1858422,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.3801544,
        store_longitude: -80.1857323,
    },
    {
        store_latitdue: -0.963302,
        store_longitude: -80.6639,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.9818597,
        store_longitude: -80.7019913,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.6227444,
        store_longitude: -80.3829023,
    },

    {
        store_latitdue: -0.3777449,
        store_longitude: -80.1831778,
    },
    {
        store_latitdue: -0.951518,
        store_longitude: -80.7012,
    },
    {
        store_latitdue: -0.9534453,
        store_longitude: -80.6947755,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9892833,
        store_longitude: -80.7049066,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.64564,
        store_longitude: -80.2974,
    },
    {
        store_latitdue: -0.6453541,
        store_longitude: -80.2960426,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9585501,
        store_longitude: -80.6692073,
    },
    {
        store_latitdue: -0.9514604,
        store_longitude: -80.7023329,
    },
    {
        store_latitdue: -0.949978,
        store_longitude: -80.7043,
    },
    {
        store_latitdue: -0.9547463,
        store_longitude: -80.7008112,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.954623,
        store_longitude: -80.7002,
    },
    {
        store_latitdue: -0.9511438,
        store_longitude: -80.6998512,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.962011,
        store_longitude: -80.6878818,
    },
    {
        store_latitdue: -0.9587278499,
        store_longitude: -80.6705707521,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.952709,
        store_longitude: -80.6977,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9633621,
        store_longitude: -80.6893727,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.59168,
        store_longitude: -80.40502,
    },
    {
        store_latitdue: -0.96602,
        store_longitude: -80.6913022,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.961771,
        store_longitude: -80.6892,
    },
    {
        store_latitdue: -0.59667,
        store_longitude: -80.4091,
    },
    {
        store_latitdue: -0.96682,
        store_longitude: -80.6957,
    },
    {
        store_latitdue: -0.9554519,
        store_longitude: -80.6990899,
    },
    {
        store_latitdue: -0.380596,
        store_longitude: -80.187855,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.9686905,
        store_longitude: -80.6792855,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9695994,
        store_longitude: -80.6791421,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9708491,
        store_longitude: -80.6766102,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9530324,
        store_longitude: -80.7076105,
    },
    {
        store_latitdue: -0.986802,
        store_longitude: -80.7001,
    },
    {
        store_latitdue: -0.967888,
        store_longitude: -80.6798,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9706854,
        store_longitude: -80.6776696,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9633127,
        store_longitude: -80.6953702,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9474337,
        store_longitude: -80.6567299,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.999776,
        store_longitude: -80.711514,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.953058,
        store_longitude: -80.7018,
    },
    {
        store_latitdue: -0.626561,
        store_longitude: -80.4251,
    },
    {
        store_latitdue: -0.952954,
        store_longitude: -80.7056754,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.982237,
        store_longitude: -80.7069387,
    },
    {
        store_latitdue: -0.9776688,
        store_longitude: -80.7116776,
    },
    {
        store_latitdue: -0.9556256,
        store_longitude: -80.7008586,
    },
    {
        store_latitdue: -0.9802741,
        store_longitude: -80.7020466,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9474166,
        store_longitude: -80.6393356,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.7842084,
        store_longitude: -80.5144142,
        neighborhood: 'CHARAPOTÓ',
    },

    {
        store_latitdue: -0.9545005,
        store_longitude: -80.7028789,
    },
    {
        store_latitdue: -0.985379,
        store_longitude: -80.7054,
    },
    {
        store_latitdue: -0.9830205,
        store_longitude: -80.7109841,
    },
    {
        store_latitdue: -0.6266835,
        store_longitude: -80.4254457,
    },
    {
        store_latitdue: -0.9475044,
        store_longitude: -80.640133,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.95294,
        store_longitude: -80.7099366,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9564493,
        store_longitude: -80.7003584,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9670243,
        store_longitude: -80.6843703,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.967686,
        store_longitude: -80.6827,
    },
    {
        store_latitdue: -0.966985,
        store_longitude: -80.6987,
    },
    {
        store_latitdue: -0.9669339,
        store_longitude: -80.6870771,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9623598,
        store_longitude: -80.6999244,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.9479815,
        store_longitude: -80.6394358,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -0.966451,
        store_longitude: -80.6881,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9550637,
        store_longitude: -80.7223336,
    },
    {
        store_latitdue: -0.9675477,
        store_longitude: -80.6842985,
    },
    {
        store_latitdue: 0.930808,
        store_longitude: -79.6777,
    },
    {
        store_latitdue: -0.5949652,
        store_longitude: -80.4087436,
    },

    {
        store_latitdue: -0.592869,
        store_longitude: -80.4087,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.587025,
        store_longitude: -80.4102334,
    },
    {
        store_latitdue: -1.01492,
        store_longitude: -80.6838,
    },
    {
        store_latitdue: -0.9850327,
        store_longitude: -80.6778693,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.970329,
        store_longitude: -80.6797,
    },
    {
        store_latitdue: -0.97484,
        store_longitude: -80.675,
    },
    {
        store_latitdue: 0.0755613,
        store_longitude: -80.053036,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: -0.591954,
        store_longitude: -80.4067751,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.591977,
        store_longitude: -80.405091,
    },
    {
        store_latitdue: -0.9501109,
        store_longitude: -80.7103176,
    },
    {
        store_latitdue: -0.9726588,
        store_longitude: -80.6785097,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.97308,
        store_longitude: -80.6774,
    },
    {
        store_latitdue: -0.5820453,
        store_longitude: -80.4061722,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.5822471,
        store_longitude: -80.4071499,
    },
    {
        store_latitdue: -0.979935,
        store_longitude: -80.7051,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9589912,
        store_longitude: -80.6740038,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.963788,
        store_longitude: -80.6689102,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.96344,
        store_longitude: -80.6699,
    },
    {
        store_latitdue: -0.962919,
        store_longitude: -80.673,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9614299,
        store_longitude: -80.7051383,
    },
    {
        store_latitdue: -0.9623835,
        store_longitude: -80.6722562,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95948117,
        store_longitude: -80.6702728,
    },
    {
        store_latitdue: -0.983738,
        store_longitude: -80.6798,
    },
    {
        store_latitdue: -2.1605123,
        store_longitude: -79.7880926,
    },
    {
        store_latitdue: -0.9620289,
        store_longitude: -80.6692476,
    },
    {
        store_latitdue: -0.9622823,
        store_longitude: -80.6697156,
    },
    {
        store_latitdue: -0.9632525,
        store_longitude: -80.6698284,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9611656,
        store_longitude: -80.6688375,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.981756,
        store_longitude: -80.6834,
    },
    {
        store_latitdue: -0.9627492,
        store_longitude: -80.6735613,
    },
    {
        store_latitdue: -0.9623865,
        store_longitude: -80.7181441,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9804297,
        store_longitude: -80.70154,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9624911,
        store_longitude: -80.6713027,
    },
    {
        store_latitdue: -0.2039655,
        store_longitude: -80.2628839,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.7595203,
        store_longitude: -80.5111445,
        neighborhood: 'CHARAPOTÓ',
    },
    {
        store_latitdue: -0.9663023,
        store_longitude: -80.7076853,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.2078788,
        store_longitude: -80.2587036,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.2078141,
        store_longitude: -80.2581221,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.9602518,
        store_longitude: -80.7265559,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9644067,
        store_longitude: -80.7099366,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.7457258,
        store_longitude: -80.5077665,
    },
    {
        store_latitdue: -0.2101883,
        store_longitude: -80.2579614,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.260309,
        store_longitude: -80.298798,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.210352,
        store_longitude: -80.2578867,
    },
    {
        store_latitdue: -0.2050823,
        store_longitude: -80.2613779,
    },

    {
        store_latitdue: -0.2083713,
        store_longitude: -80.2591058,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.9536099,
        store_longitude: -80.6503535,
    },
    {
        store_latitdue: -0.20316,
        store_longitude: -80.2608,
    },
    {
        store_latitdue: -0.2033689,
        store_longitude: -80.2611532,
    },
    {
        store_latitdue: -0.37955754,
        store_longitude: -80.1863479,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -0.643795,
        store_longitude: -80.297707,
        neighborhood: 'SAN VICENTE',
    },

    {
        store_latitdue: -0.9832666,
        store_longitude: -80.703829,
    },
    {
        store_latitdue: -0.98574,
        store_longitude: -80.7054,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.984532,
        store_longitude: -80.7054,
    },
    {
        store_latitdue: -0.9541212,
        store_longitude: -80.7022706,
    },
    {
        store_latitdue: -0.9705,
        store_longitude: -80.706,
    },
    {
        store_latitdue: -0.9564933,
        store_longitude: -80.7280417,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.1097956593,
        store_longitude: -80.2224251092,
        neighborhood: 'HONORATO VÁSQUEZ',
    },
    {
        store_latitdue: -0.8457634,
        store_longitude: -80.164848,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.402358,
        store_longitude: -79.9089624,
        neighborhood: 'FLAVIO ALFARO',
    },

    {
        store_latitdue: -1.018863,
        store_longitude: -80.465148,
    },
    {
        store_latitdue: -1.057808113,
        store_longitude: -80.45865713,
    },
    {
        store_latitdue: -0.9468583,
        store_longitude: -80.6407125,
    },
    {
        store_latitdue: -0.9630776,
        store_longitude: -80.7129173,
    },

    {
        store_latitdue: -0.968433,
        store_longitude: -80.6762,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9534169,
        store_longitude: -80.717214,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.999587,
        store_longitude: -80.6975571,
    },
    {
        store_latitdue: -0.986667,
        store_longitude: -80.6882353,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9711344,
        store_longitude: -80.6449606,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.054948292,
        store_longitude: -80.26679091,
    },
    {
        store_latitdue: -0.818964,
        store_longitude: -80.215597,
        neighborhood: 'ÁNGEL PEDRO GILER',
    },
    {
        store_latitdue: -0.8771794,
        store_longitude: -80.5417421,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.4600775,
        store_longitude: -80.2589174,
        neighborhood: 'ARQUITECTO SIXTO DURÁN BALLÉN',
    },
    {
        store_latitdue: -0.7007887,
        store_longitude: -80.0816282,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.452014995,
        store_longitude: -80.25639423,
        neighborhood: 'ARQUITECTO SIXTO DURÁN BALLÉN',
    },
    {
        store_latitdue: -0.6984353,
        store_longitude: -80.0727922,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0328598,
        store_longitude: -80.2477598,
    },
    {
        store_latitdue: -1.0359778,
        store_longitude: -80.2515474,
    },
    {
        store_latitdue: -0.702442,
        store_longitude: -80.0835,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.698121,
        store_longitude: -80.0864996,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.694915,
        store_longitude: -80.0853,
    },
    {
        store_latitdue: -0.6984249,
        store_longitude: -80.0666283,
    },
    {
        store_latitdue: 0.88399,
        store_longitude: -79.8207,
    },
    {
        store_latitdue: -0.697531,
        store_longitude: -80.0882,
    },
    {
        store_latitdue: -0.69904,
        store_longitude: -80.0838,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6945289,
        store_longitude: -80.0902921,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6938973,
        store_longitude: -80.0871544,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.694753,
        store_longitude: -80.0895,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.455689,
        store_longitude: -80.2588763,
    },
    {
        store_latitdue: -1.0599274,
        store_longitude: -80.2127599,
        neighborhood: 'SAN PLÁCIDO',
    },
    {
        store_latitdue: -1.481396,
        store_longitude: -80.2764198,
    },
    {
        store_latitdue: -1.629927,
        store_longitude: -80.2384954,
    },
    {
        store_latitdue: -0.7891648,
        store_longitude: -80.2345859,
    },
    {
        store_latitdue: -0.78842,
        store_longitude: -80.2386,
    },
    {
        store_latitdue: -0.8629734,
        store_longitude: -80.5330308,
    },
    {
        store_latitdue: -1.059557,
        store_longitude: -80.25319,
    },

    {
        store_latitdue: -1.1314017,
        store_longitude: -80.40378,
    },
    {
        store_latitdue: -1.109825492,
        store_longitude: -80.41568756,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06184,
        store_longitude: -80.4601,
    },
    {
        store_latitdue: -1.12582,
        store_longitude: -80.4044,
    },
    {
        store_latitdue: -1.03483,
        store_longitude: -80.492252,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.1136873,
        store_longitude: -80.4139404,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0622007,
        store_longitude: -80.4593294,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.12244,
        store_longitude: -80.4068,
    },
    {
        store_latitdue: -1.0622125,
        store_longitude: -80.4575749,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05988,
        store_longitude: -80.465238,
    },
    {
        store_latitdue: -1.0617205048,
        store_longitude: -80.4369860888,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0597428,
        store_longitude: -80.4633712,
    },
    {
        store_latitdue: -1.0711572282,
        store_longitude: -80.4326207936,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.09302,
        store_longitude: -80.4311,
    },
    {
        store_latitdue: -1.0623701,
        store_longitude: -80.4582403,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.08514,
        store_longitude: -80.4333,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0608679,
        store_longitude: -80.4389043,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.102487405,
        store_longitude: -80.42089837,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0476744,
        store_longitude: -80.4553863,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.069267,
        store_longitude: -80.433252,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0201,
        store_longitude: -80.4652,
    },
    {
        store_latitdue: -1.0568894,
        store_longitude: -80.450482,
    },
    {
        store_latitdue: -1.10262,
        store_longitude: -80.4204,
    },
    {
        store_latitdue: -1.059564586,
        store_longitude: -80.44497877,
    },
    {
        store_latitdue: -1.08446025,
        store_longitude: -80.4304885,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05372392,
        store_longitude: -80.4571966,
    },
    {
        store_latitdue: -0.6989615,
        store_longitude: -80.0890323,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.205711,
        store_longitude: -80.371523,
    },
    {
        store_latitdue: -0.697626,
        store_longitude: -80.0938,
    },
    {
        store_latitdue: -0.695999,
        store_longitude: -80.0935727,
    },
    {
        store_latitdue: -0.7011408,
        store_longitude: -80.0959768,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0718368,
        store_longitude: -80.4961992,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0563706,
        store_longitude: -80.4486529,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0640514,
        store_longitude: -80.4542812,
    },
    {
        store_latitdue: -1.0729221,
        store_longitude: -80.4967858,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.208104,
        store_longitude: -80.3813675,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.6641398,
        store_longitude: -80.0216748,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.2065221,
        store_longitude: -80.3771616,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0662463,
        store_longitude: -80.45133,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.224817,
        store_longitude: -80.4201047,
    },
    {
        store_latitdue: -1.18534,
        store_longitude: -80.3819,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.6921876,
        store_longitude: -80.0913734,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6924184,
        store_longitude: -80.0880438,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.692465,
        store_longitude: -80.087834,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.69233639,
        store_longitude: -80.0882077,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6922301,
        store_longitude: -80.0951155,
    },
    {
        store_latitdue: -0.692166,
        store_longitude: -80.0949099,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0727518576,
        store_longitude: -80.4970008507,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06326,
        store_longitude: -80.453,
    },
    {
        store_latitdue: -1.0660555,
        store_longitude: -80.4518567,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.695042,
        store_longitude: -80.097384,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7020881896,
        store_longitude: -80.1113605499,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0564242871,
        store_longitude: -80.6233454661,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.693464,
        store_longitude: -80.0923,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6927678,
        store_longitude: -80.0899394,
        neighborhood: 'CHONE',
    },

    {
        store_latitdue: -0.689443,
        store_longitude: -80.0940542,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6911313,
        store_longitude: -80.0834447,
    },

    {
        store_latitdue: -1.08025,
        store_longitude: -80.4997,
    },
    {
        store_latitdue: -1.0406276,
        store_longitude: -80.471772,
    },
    {
        store_latitdue: -1.080255,
        store_longitude: -80.529993,
    },
    {
        store_latitdue: -1.0524081,
        store_longitude: -80.4864773,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.11163,
        store_longitude: -80.414093,
    },
    {
        store_latitdue: -1.0365228,
        store_longitude: -80.4771278,
    },
    {
        store_latitdue: -1.0727977,
        store_longitude: -80.5002029,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0654404,
        store_longitude: -80.565469,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0650692,
        store_longitude: -80.4495754,
    },
    {
        store_latitdue: -1.070537,
        store_longitude: -79.972469,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -0.4044306,
        store_longitude: -79.9054513,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.922364,
        store_longitude: -80.4437,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0720628,
        store_longitude: -79.9718117,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -0.84675,
        store_longitude: -80.16294,
    },
    {
        store_latitdue: -0.4061189187,
        store_longitude: -79.901619181,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.8496733,
        store_longitude: -80.1615033,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.0392707,
        store_longitude: -79.636847,
    },
    {
        store_latitdue: -0.407321,
        store_longitude: -79.9048,
        neighborhood: 'FLAVIO ALFARO',
    },

    {
        store_latitdue: -1.3960091,
        store_longitude: -80.2091683,
        neighborhood: 'OLMEDO',
    },

    {
        store_latitdue: -0.4056006,
        store_longitude: -79.9064701,
    },

    {
        store_latitdue: -1.2025656349,
        store_longitude: -80.3685518803,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0293035,
        store_longitude: -80.3444392,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.7011876,
        store_longitude: -80.0936867,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7005215,
        store_longitude: -80.0949807,
    },
    {
        store_latitdue: -0.8387579,
        store_longitude: -80.1588106,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.027054,
        store_longitude: -80.348303,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.6967816,
        store_longitude: -80.0914277,
    },

    {
        store_latitdue: -1.04449,
        store_longitude: -80.4984,
    },
    {
        store_latitdue: -1.0367793,
        store_longitude: -80.4926114,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0449581,
        store_longitude: -80.4381557,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0456859,
        store_longitude: -80.4798651,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0440397,
        store_longitude: -80.4761425,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.10464,
        store_longitude: -80.4089,
    },
    {
        store_latitdue: -1.0524638,
        store_longitude: -80.448382,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.038376066,
        store_longitude: -80.4845363958,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0495275,
        store_longitude: -80.4496428,
    },
    {
        store_latitdue: -1.0352731,
        store_longitude: -80.4678048,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.052605,
        store_longitude: -80.483084,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.3280949,
        store_longitude: -78.4718796,
    },
    {
        store_latitdue: -1.03651,
        store_longitude: -80.4616,
    },
    {
        store_latitdue: -1.05686,
        store_longitude: -80.4418,
    },
    {
        store_latitdue: -0.6951004,
        store_longitude: -80.0973618,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.05463,
        store_longitude: -80.4438,
    },
    {
        store_latitdue: -0.701883,
        store_longitude: -80.0892085,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7008084,
        store_longitude: -80.0967095,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.08276343,
        store_longitude: -80.436737,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0889445,
        store_longitude: -80.4331898,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.703765,
        store_longitude: -80.1358,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.6945038,
        store_longitude: -80.0946464,
    },
    {
        store_latitdue: -0.6948979,
        store_longitude: -80.0932872,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6942875,
        store_longitude: -80.0944425,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.8245916,
        store_longitude: -80.1806594,
        neighborhood: 'ÁNGEL PEDRO GILER',
    },
    {
        store_latitdue: -0.7125429,
        store_longitude: -80.0961158,
    },
    {
        store_latitdue: -0.8465601922,
        store_longitude: -80.1660407335,
        neighborhood: 'CALCETA',
    },

    {
        store_latitdue: -0.7007389,
        store_longitude: -80.1068968,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.2060222,
        store_longitude: -80.3713605,
    },
    {
        store_latitdue: -1.20159,
        store_longitude: -80.3754,
    },
    {
        store_latitdue: -0.8309246,
        store_longitude: -80.1537378,
        neighborhood: 'CALCETA',
    },

    {
        store_latitdue: -1.0060907,
        store_longitude: -80.4036187,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -1.0492765,
        store_longitude: -80.4440419,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9980993,
        store_longitude: -80.4661125,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0472715,
        store_longitude: -80.4518989,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.078872,
        store_longitude: -80.455591,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0751277,
        store_longitude: -80.4450917,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05802,
        store_longitude: -80.4704,
    },
    {
        store_latitdue: -1.072043,
        store_longitude: -80.480449,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.050452,
        store_longitude: -80.4514464,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.07074,
        store_longitude: -80.443,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.068254,
        store_longitude: -80.479838,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.447088,
        store_longitude: -79.994,
        neighborhood: 'ZAPALLO',
    },
    {
        store_latitdue: -0.4464888,
        store_longitude: -79.994864,
        neighborhood: 'ZAPALLO',
    },
    {
        store_latitdue: -0.4011858,
        store_longitude: -80.0798494,
        neighborhood: 'ELOY ALFARO',
    },
    {
        store_latitdue: -0.400519747,
        store_longitude: -80.07945113,
    },
    {
        store_latitdue: -0.401449,
        store_longitude: -80.0801027,
        neighborhood: 'ELOY ALFARO',
    },
    {
        store_latitdue: -1.0343155,
        store_longitude: -80.4665222,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.4441059,
        store_longitude: -80.0782072,
        neighborhood: 'ELOY ALFARO',
    },
    {
        store_latitdue: -0.9546338,
        store_longitude: -80.4704643,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0680055,
        store_longitude: -80.4501383,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0622,
        store_longitude: -80.4585,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05987,
        store_longitude: -80.4843,
    },
    {
        store_latitdue: -1.0582165,
        store_longitude: -80.4837398,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9583055,
        store_longitude: -80.4428059,
    },
    {
        store_latitdue: -1.0579401,
        store_longitude: -80.483162,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05843067,
        store_longitude: -80.4883651,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0565219,
        store_longitude: -80.4857028,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.895896,
        store_longitude: -80.5019,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.057001,
        store_longitude: -80.447463,
    },
    {
        store_latitdue: -1.06974,
        store_longitude: -80.4525,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0659051,
        store_longitude: -80.4437133,
    },
    {
        store_latitdue: -1.04719,
        store_longitude: -80.456,
    },
    {
        store_latitdue: -0.86725,
        store_longitude: -80.5195091,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -1.06882753,
        store_longitude: -80.4537606,
    },
    {
        store_latitdue: -1.05858,
        store_longitude: -80.4702,
    },
    {
        store_latitdue: -1.05852,
        store_longitude: -80.470124,
    },
    {
        store_latitdue: -1.0710591,
        store_longitude: -80.4891411,
    },
    {
        store_latitdue: -0.708201,
        store_longitude: -80.0978,
    },
    {
        store_latitdue: -0.928908,
        store_longitude: -80.2044,
    },
    {
        store_latitdue: -0.92510873,
        store_longitude: -80.2047729,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.7077051,
        store_longitude: -80.0940775,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.70171,
        store_longitude: -80.0937,
    },
    {
        store_latitdue: -0.7010323,
        store_longitude: -80.0964585,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.70309203,
        store_longitude: -80.0959854,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.705729,
        store_longitude: -80.102524,
    },
    {
        store_latitdue: -0.9574358,
        store_longitude: -80.4646104,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.455858,
        store_longitude: -80.14452,
    },
    {
        store_latitdue: -0.5821603,
        store_longitude: -80.0406641,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.4443923,
        store_longitude: -80.0783244,
    },
    {
        store_latitdue: -1.00766,
        store_longitude: -80.4835,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9573193,
        store_longitude: -80.4676009,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.7051522,
        store_longitude: -80.1022486,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.5836841,
        store_longitude: -80.0407211,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.7055443,
        store_longitude: -80.102594,
    },
    {
        store_latitdue: -0.6989071,
        store_longitude: -80.0678043,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7010844,
        store_longitude: -80.0738265,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6981246,
        store_longitude: -80.0735154,
    },
    {
        store_latitdue: -0.6978321,
        store_longitude: -80.0724332,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7248598,
        store_longitude: -80.1002972,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.70787058,
        store_longitude: -80.0912761,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.402275,
        store_longitude: -80.079837,
        neighborhood: 'ELOY ALFARO',
    },
    {
        store_latitdue: -1.06208,
        store_longitude: -80.4619,
    },
    {
        store_latitdue: -0.7022731,
        store_longitude: -80.0993653,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.5505798,
        store_longitude: -80.0421902,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.9742251,
        store_longitude: -80.6861756,
    },
    {
        store_latitdue: -0.952826,
        store_longitude: -80.471225,
    },
    {
        store_latitdue: -1.13921,
        store_longitude: -80.29722,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -0.7848263,
        store_longitude: -80.2360241,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.81162993,
        store_longitude: -80.2116322,
    },
    {
        store_latitdue: -0.78847,
        store_longitude: -80.2324,
    },
    {
        store_latitdue: -1.2069,
        store_longitude: -80.3686488,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.1337887,
        store_longitude: -80.2504501,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -1.1926834,
        store_longitude: -80.3260669,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0052565,
        store_longitude: -80.3921709,
        neighborhood: 'RIOCHICO',
    },
    {
        store_latitdue: -0.6996941,
        store_longitude: -80.0987205,
    },
    {
        store_latitdue: -0.7016882,
        store_longitude: -80.1091064,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.6935672,
        store_longitude: -80.1031248,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7645722,
        store_longitude: -80.2135263,
        neighborhood: 'BACHILLERO',
    },
    {
        store_latitdue: -0.788529,
        store_longitude: -80.2336284,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.865552,
        store_longitude: -80.5382,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.77471,
        store_longitude: -80.238034,
    },
    {
        store_latitdue: -0.999825,
        store_longitude: -80.4056,
    },
    {
        store_latitdue: -0.8571423,
        store_longitude: -80.5332894,
        neighborhood: 'CRUCITA',
    },
    {
        store_latitdue: -0.70015366,
        store_longitude: -80.1048752,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.15734,
        store_longitude: -80.3008,
    },
    {
        store_latitdue: -0.6988574,
        store_longitude: -80.1005472,
    },
    {
        store_latitdue: -0.770181,
        store_longitude: -80.258705,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -1.0489025,
        store_longitude: -80.3031789,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.0450091,
        store_longitude: -80.3393654,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -1.0543397,
        store_longitude: -80.2807368,
    },
    {
        store_latitdue: -1.2065481,
        store_longitude: -80.3713444,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.20807,
        store_longitude: -80.3708,
    },
    {
        store_latitdue: -1.03995,
        store_longitude: -80.4672,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.03017,
        store_longitude: -80.4763,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0405716,
        store_longitude: -80.4718866,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.2057541452,
        store_longitude: -80.3712090374,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.0590493,
        store_longitude: -80.478387,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.2067795123,
        store_longitude: -80.3726703563,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -1.06372,
        store_longitude: -80.4475,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0652492,
        store_longitude: -80.4466647,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -1.05167,
        store_longitude: -80.4826,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.10635318,
        store_longitude: -80.4080772,
    },
    {
        store_latitdue: -1.0566216,
        store_longitude: -80.4577925,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.04806,
        store_longitude: -80.4427,
    },
    {
        store_latitdue: -1.050164,
        store_longitude: -80.447614,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0450116,
        store_longitude: -80.4967271,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0477,
        store_longitude: -80.4887,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06348,
        store_longitude: -80.4608,
    },
    {
        store_latitdue: -1.0119394,
        store_longitude: -80.4656465,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.01212,
        store_longitude: -80.4651,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.059096,
        store_longitude: -80.443251,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.03159,
        store_longitude: -80.4646,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0660681,
        store_longitude: -80.4650926,
    },
    {
        store_latitdue: -1.06501,
        store_longitude: -80.455,
    },
    {
        store_latitdue: -1.062696,
        store_longitude: -80.464158,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05422031,
        store_longitude: -80.4774498,
    },
    {
        store_latitdue: -1.03063878,
        store_longitude: -80.4696922,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.088375,
        store_longitude: -78.41939,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -1.04929,
        store_longitude: -80.489,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05376,
        store_longitude: -80.4868,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05298,
        store_longitude: -80.4841,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0346245,
        store_longitude: -80.4681941,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.054,
        store_longitude: -80.4874,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.02196,
        store_longitude: -80.479,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.2350042,
        store_longitude: -78.3365914,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -1.0116872,
        store_longitude: -80.4691794,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.69067,
        store_longitude: -80.325,
    },

    {
        store_latitdue: -1.030721,
        store_longitude: -80.464841,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05571,
        store_longitude: -80.4845,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -1.0560145,
        store_longitude: -80.4577218,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05868,
        store_longitude: -80.4422,
    },
    {
        store_latitdue: -1.05643,
        store_longitude: -80.4593,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.078311,
        store_longitude: -80.454314,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.076752,
        store_longitude: -80.440455,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06216,
        store_longitude: -80.4579,
    },
    {
        store_latitdue: -1.0724424,
        store_longitude: -80.4460106,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0601951,
        store_longitude: -80.4712591,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.077028,
        store_longitude: -80.450345,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06321421,
        store_longitude: -80.4615661,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.059563,
        store_longitude: -80.463143,
    },
    {
        store_latitdue: -0.989509,
        store_longitude: -80.4576,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.075775,
        store_longitude: -80.45068,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.065027,
        store_longitude: -80.4557213,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0622741,
        store_longitude: -80.4690636,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.01167,
        store_longitude: -80.4643,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.994759,
        store_longitude: -80.4671504,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0732403,
        store_longitude: -80.4439503,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -1.0583638,
        store_longitude: -80.4748861,
    },
    {
        store_latitdue: -1.057165,
        store_longitude: -80.472997,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0733049,
        store_longitude: -80.4442995,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0556976,
        store_longitude: -80.4450711,
    },
    {
        store_latitdue: -1.0511,
        store_longitude: -80.4469,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0511062,
        store_longitude: -80.4822248,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05856,
        store_longitude: -80.4749,
    },
    {
        store_latitdue: -1.061666,
        store_longitude: -80.47507,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06364,
        store_longitude: -80.4477,
    },

    {
        store_latitdue: -1.2865259,
        store_longitude: -80.418082,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.6907747,
        store_longitude: -80.0809313,
        neighborhood: 'CHONE',
    },

    {
        store_latitdue: -1.093721,
        store_longitude: -80.4065084,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0480487,
        store_longitude: -80.4759754,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0494524,
        store_longitude: -80.445326,
    },
    {
        store_latitdue: -1.0466887,
        store_longitude: -80.4791342,
    },
    {
        store_latitdue: -1.04702,
        store_longitude: -80.4702,
    },
    {
        store_latitdue: -1.0496478,
        store_longitude: -80.4690076,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.05903,
        store_longitude: -80.4519,
    },
    {
        store_latitdue: -1.07922,
        store_longitude: -80.5338,
    },
    {
        store_latitdue: -1.06474,
        store_longitude: -80.5676,
    },
    {
        store_latitdue: -1.0687593,
        store_longitude: -80.4801526,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.07062,
        store_longitude: -80.4971,
    },
    {
        store_latitdue: -1.0652533,
        store_longitude: -80.5808341,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.05210816,
        store_longitude: -80.4888458,
        neighborhood: 'PORTOVIEJO',
    },

    {
        store_latitdue: -1.0247,
        store_longitude: -80.4762,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0399022,
        store_longitude: -80.4726975,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.04505,
        store_longitude: -80.4653,
    },
    {
        store_latitdue: -1.056460921,
        store_longitude: -80.4655858129,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0733805,
        store_longitude: -80.4325723,
    },

    {
        store_latitdue: -0.701174,
        store_longitude: -80.0886,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.7260221,
        store_longitude: -80.0996684,
        neighborhood: 'CHONE',
    },

    {
        store_latitdue: -0.7264001,
        store_longitude: -80.0996138,
    },
    {
        store_latitdue: -1.2802833,
        store_longitude: -80.4175905,
        neighborhood: 'SUCRE',
    },
    {
        store_latitdue: -0.9562795,
        store_longitude: -80.7076959,
    },
    {
        store_latitdue: -0.78605431,
        store_longitude: -80.2375335,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.9244136,
        store_longitude: -80.4517147,
    },
    {
        store_latitdue: -0.9638976,
        store_longitude: -80.4616486,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.694111,
        store_longitude: -80.105438,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.1581671,
        store_longitude: -80.2864624,
        neighborhood: 'AYACUCHO',
    },
    {
        store_latitdue: -0.961374,
        store_longitude: -80.7267,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.920735,
        store_longitude: -80.4533,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -0.6988463,
        store_longitude: -80.0895694,
    },
    {
        store_latitdue: -0.703862,
        store_longitude: -80.097298,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.70096,
        store_longitude: -80.081872,
    },

    {
        store_latitdue: -0.9275258,
        store_longitude: -80.4521951,
    },
    {
        store_latitdue: -1.07482,
        store_longitude: -80.4324,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.102273,
        store_longitude: -80.4098461,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9545004,
        store_longitude: -80.7022255,
    },
    {
        store_latitdue: -0.95927709,
        store_longitude: -80.7124786,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9775625,
        store_longitude: -80.7034661,
    },
    {
        store_latitdue: -0.9444776,
        store_longitude: -80.6416465,
        neighborhood: 'JARAMIJÓ',
    },
    {
        store_latitdue: -1.0093175,
        store_longitude: -80.3623262,
        neighborhood: 'ABDÓN CALDERÓN',
    },
    {
        store_latitdue: -0.45172223,
        store_longitude: -79.9951243,
        neighborhood: 'ZAPALLO',
    },
    {
        store_latitdue: -0.7010305,
        store_longitude: -80.0743763,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.0774727,
        store_longitude: -80.4516979,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9768411,
        store_longitude: -80.6895213,
    },
    {
        store_latitdue: -0.96867,
        store_longitude: -80.7136,
    },
    {
        store_latitdue: -0.7819812,
        store_longitude: -80.2352418,
        neighborhood: 'TOSAGUA',
    },
    {
        store_latitdue: -0.58564,
        store_longitude: -80.4061267,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.9786448,
        store_longitude: -80.690908,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.02527,
        store_longitude: -80.5846,
    },
    {
        store_latitdue: -0.9596861,
        store_longitude: -80.7038248,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.04441,
        store_longitude: -80.4962,
    },
    {
        store_latitdue: -1.061621,
        store_longitude: -80.245669,
    },
    {
        store_latitdue: -0.9759725,
        store_longitude: -80.7039451,
    },
    {
        store_latitdue: -0.974454,
        store_longitude: -80.7058,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9682626,
        store_longitude: -80.6777714,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9605815,
        store_longitude: -80.7170272,
    },
    {
        store_latitdue: -0.6913662,
        store_longitude: -80.1014235,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.692446,
        store_longitude: -80.090277,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.9775184,
        store_longitude: -80.6876148,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.965367,
        store_longitude: -80.6738,
    },
    {
        store_latitdue: -1.0238545,
        store_longitude: -80.5813435,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0346468,
        store_longitude: -80.5001083,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.8463187,
        store_longitude: -80.1634761,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -1.2087508,
        store_longitude: -80.370531,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.84479,
        store_longitude: -80.1628,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.8346604,
        store_longitude: -80.1585443,
        neighborhood: 'CALCETA',
    },
    {
        store_latitdue: -0.798222,
        store_longitude: -80.12861,
        neighborhood: 'CANUTO',
    },
    {
        store_latitdue: -0.704317,
        store_longitude: -80.1355,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.7246093,
        store_longitude: -80.1003674,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.04081,
        store_longitude: -80.3188,
    },
    {
        store_latitdue: -1.1661071,
        store_longitude: -80.3077075,
        neighborhood: 'AYACUCHO',
    },

    {
        store_latitdue: -0.619201,
        store_longitude: -80.4266749,
        neighborhood: 'BAHÍA DE CARÁQUEZ',
    },

    {
        store_latitdue: -0.5695112,
        store_longitude: -80.1773169,
        neighborhood: 'BOYACÁ',
    },
    {
        store_latitdue: -1.2097368,
        store_longitude: -80.3726003,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.9269778,
        store_longitude: -80.204873,
    },

    {
        store_latitdue: -0.6889432,
        store_longitude: -80.1003837,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -1.07041,
        store_longitude: -80.4421,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9522763,
        store_longitude: -80.6997965,
    },
    {
        store_latitdue: -0.084626,
        store_longitude: -78.436756,
    },
    {
        store_latitdue: -0.59361732,
        store_longitude: -80.4080657,
        neighborhood: 'SAN VICENTE',
    },
    {
        store_latitdue: -0.3801797,
        store_longitude: -80.1859442,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: -1.062779716,
        store_longitude: -80.45954447,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9288963,
        store_longitude: -80.2053915,
        neighborhood: 'JUNÍN',
    },
    {
        store_latitdue: -0.922483,
        store_longitude: -80.4510664,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: -1.0571,
        store_longitude: -80.4867,
    },

    {
        store_latitdue: -0.9838952,
        store_longitude: -80.7010962,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.960422,
        store_longitude: -80.7255,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0754669,
        store_longitude: -80.4506001,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0760581983,
        store_longitude: -80.4445405557,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.9910114,
        store_longitude: -80.4672598,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.06883,
        store_longitude: -80.4514,
    },
    {
        store_latitdue: -1.076818,
        store_longitude: -80.456804,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0533866,
        store_longitude: -80.4594082,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.3958106,
        store_longitude: -80.2114507,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: -1.347685,
        store_longitude: -80.287454,
    },
    {
        store_latitdue: -1.3498019,
        store_longitude: -80.5802748,
    },
    {
        store_latitdue: -1.25933,
        store_longitude: -80.58,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34904,
        store_longitude: -80.5807,
    },
    {
        store_latitdue: -0.967642,
        store_longitude: -80.7031,
    },
    {
        store_latitdue: 0.6112939,
        store_longitude: -80.020852,
    },
    {
        store_latitdue: 0.0756834,
        store_longitude: -80.0524556,
    },
    {
        store_latitdue: -1.5581828136,
        store_longitude: -80.8088056768,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.0021068,
        store_longitude: -80.6975773,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3566357,
        store_longitude: -80.5864337,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.5641987,
        store_longitude: -80.8183487,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5581691,
        store_longitude: -80.8088612,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.0797480197747,
        store_longitude: -80.669822001168,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -1.6262311,
        store_longitude: -80.5601535,
        neighborhood: 'PEDRO PABLO GÓMEZ',
    },
    {
        store_latitdue: -1.0669442,
        store_longitude: -80.9060593,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.94920456,
        store_longitude: -80.7281265,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.562297,
        store_longitude: -80.812232,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5475158,
        store_longitude: -80.8049344,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.439407525,
        store_longitude: -80.54191651,
        neighborhood: 'AMÉRICA',
    },
    {
        store_latitdue: -1.4669932,
        store_longitude: -80.552341,
        neighborhood: 'EL ANEGADO',
    },
    {
        store_latitdue: -1.6749138,
        store_longitude: -80.8121178,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.46847,
        store_longitude: -80.7587,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.0023238,
        store_longitude: -80.725668,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.962282,
        store_longitude: -80.8101191,
    },
    {
        store_latitdue: -1.0130632,
        store_longitude: -80.6845843,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.34909509,
        store_longitude: -80.581026,
    },
    {
        store_latitdue: -1.736947,
        store_longitude: -80.3657988,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.5502636,
        store_longitude: -80.4270757,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.6156816,
        store_longitude: -80.397578,
        neighborhood: 'CAMPOZANO',
    },
    {
        store_latitdue: -1.2363068548,
        store_longitude: -80.8143953606,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.011444065,
        store_longitude: -80.67052289,
    },

    {
        store_latitdue: -0.9518652,
        store_longitude: -80.7238498,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0441636,
        store_longitude: -80.6577194,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9522007,
        store_longitude: -80.7291972,
    },
    {
        store_latitdue: -0.9787878,
        store_longitude: -80.718819,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.960811,
        store_longitude: -80.728784,
    },
    {
        store_latitdue: -0.94872695,
        store_longitude: -80.738861,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.9943238,
        store_longitude: -80.693145,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.258815133,
        store_longitude: -80.58533377,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.0764295,
        store_longitude: -80.6460121,
    },
    {
        store_latitdue: -0.9785789,
        store_longitude: -80.7128833,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.5953122,
        store_longitude: -80.5474776,
        neighborhood: 'PEDRO PABLO GÓMEZ',
    },
    {
        store_latitdue: -0.949751,
        store_longitude: -80.7261,
    },
    {
        store_latitdue: -1.6274565,
        store_longitude: -80.5601161,
        neighborhood: 'PEDRO PABLO GÓMEZ',
    },
    {
        store_latitdue: -1.076254,
        store_longitude: -80.650077,
    },
    {
        store_latitdue: -1.9384765,
        store_longitude: -80.6982242,
    },
    {
        store_latitdue: -0.9495266,
        store_longitude: -80.7249038,
    },
    {
        store_latitdue: -0.971434935,
        store_longitude: -80.71870305,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0435631,
        store_longitude: -80.6545229,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.05117,
        store_longitude: -80.8365,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.695802,
        store_longitude: -80.1074,
    },
    {
        store_latitdue: -1.3621322,
        store_longitude: -80.5973112,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.348336,
        store_longitude: -80.575928,
    },

    {
        store_latitdue: -1.2069,
        store_longitude: -80.3686488,
        neighborhood: 'SANTA ANA DE VUELTA LARGA',
    },
    {
        store_latitdue: -0.9558431,
        store_longitude: -80.7407401,
    },
    {
        store_latitdue: -0.9840755,
        store_longitude: -80.7126088,
    },
    {
        store_latitdue: -1.3499764,
        store_longitude: -80.5638094,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.9602,
        store_longitude: -80.7354,
    },
    {
        store_latitdue: -1.0000568,
        store_longitude: -80.6954221,
    },
    {
        store_latitdue: -1.5638401,
        store_longitude: -80.8147821,
    },
    {
        store_latitdue: -0.992623,
        store_longitude: -80.707,
    },
    {
        store_latitdue: -1.04676,
        store_longitude: -80.6625,
    },
    {
        store_latitdue: -1.3554522,
        store_longitude: -80.5877163,
    },
    {
        store_latitdue: -1.058337,
        store_longitude: -80.838753,
    },
    {
        store_latitdue: -0.9479584,
        store_longitude: -80.7261986,
    },
    {
        store_latitdue: -1.346367,
        store_longitude: -80.570105,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.0469842,
        store_longitude: -80.6595802,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0803364,
        store_longitude: -80.649302,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.592165,
        store_longitude: -80.84253,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.0314023,
        store_longitude: -80.6944894,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00037021,
        store_longitude: -80.7362508,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0502024,
        store_longitude: -80.6608523,
    },
    {
        store_latitdue: -0.9992494,
        store_longitude: -80.7268664,
    },
    {
        store_latitdue: -1.3448601,
        store_longitude: -80.5735565,
    },
    {
        store_latitdue: -1.349538,
        store_longitude: -80.738808,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.362025,
        store_longitude: -80.595245,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.9851928,
        store_longitude: -80.7021309,
    },
    {
        store_latitdue: -1.5542909,
        store_longitude: -80.4256254,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5601953,
        store_longitude: -80.8120287,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.95220473,
        store_longitude: -80.7372593,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.995546825,
        store_longitude: -80.76050423,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.971989,
        store_longitude: -80.7219,
    },
    {
        store_latitdue: -0.9620458,
        store_longitude: -80.7551061,
    },
    {
        store_latitdue: -1.555799,
        store_longitude: -80.804574,
    },
    {
        store_latitdue: -0.9766866,
        store_longitude: -80.7109656,
    },
    {
        store_latitdue: -1.0022869,
        store_longitude: -80.7255471,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0476041,
        store_longitude: -80.832727,
    },
    {
        store_latitdue: -0.961348,
        store_longitude: -80.7301,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0059362,
        store_longitude: -80.7178651,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.56264,
        store_longitude: -80.8161,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.9736783,
        store_longitude: -80.71308,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.980056,
        store_longitude: -80.713677,
    },
    {
        store_latitdue: -1.3454546,
        store_longitude: -80.5792997,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.661959,
        store_longitude: -80.460805,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.357999,
        store_longitude: -80.596962,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.9582171,
        store_longitude: -80.7404575,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.04259,
        store_longitude: -80.6618,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9935716,
        store_longitude: -80.7360402,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9800093,
        store_longitude: -80.7163974,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.97762,
        store_longitude: -80.7264,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.3489989,
        store_longitude: -80.7355449,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.0726572,
        store_longitude: -80.6408097,
    },
    {
        store_latitdue: -0.9744814,
        store_longitude: -80.7140101,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00348,
        store_longitude: -80.7131,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0025086,
        store_longitude: -80.6963072,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.95912906,
        store_longitude: -80.8102722,
    },
    {
        store_latitdue: -1.355028,
        store_longitude: -80.575978,
    },
    {
        store_latitdue: -1.3457993,
        store_longitude: -80.579244,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.9554494,
        store_longitude: -80.7470195,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.023525244,
        store_longitude: -80.6795424,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3585689,
        store_longitude: -80.5932846,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3522035,
        store_longitude: -80.5810854,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.946225,
        store_longitude: -80.7227,
    },
    {
        store_latitdue: -0.9464822,
        store_longitude: -80.7307867,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9727125,
        store_longitude: -80.7287268,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0485654,
        store_longitude: -80.6590769,
    },
    {
        store_latitdue: -0.952264,
        store_longitude: -80.7434127,
    },
    {
        store_latitdue: -0.95667,
        store_longitude: -80.811384,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9911152,
        store_longitude: -80.7066956,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.077812,
        store_longitude: -80.6694746,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.021367,
        store_longitude: -80.684197,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9504602,
        store_longitude: -80.723744,
    },
    {
        store_latitdue: -1.3470019,
        store_longitude: -80.5685897,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.000176,
        store_longitude: -80.7092415,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.02419,
        store_longitude: -80.6818,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9974461,
        store_longitude: -80.6961125,
    },
    {
        store_latitdue: -1.305810853,
        store_longitude: -80.58093224,
    },
    {
        store_latitdue: -0.9725725,
        store_longitude: -80.7276803,
    },
    {
        store_latitdue: -1.0470188,
        store_longitude: -80.6567951,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0503722,
        store_longitude: -80.6612131,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3414801,
        store_longitude: -80.5794473,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.560912,
        store_longitude: -80.811453,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.0023066,
        store_longitude: -80.693068,
    },
    {
        store_latitdue: -1.345229222,
        store_longitude: -80.57074116,
    },
    {
        store_latitdue: -0.9559079,
        store_longitude: -80.7303071,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9508407,
        store_longitude: -80.726008,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.56094,
        store_longitude: -80.8145,
    },
    {
        store_latitdue: -1.6269,
        store_longitude: -80.5604,
    },
    {
        store_latitdue: -0.94935,
        store_longitude: -80.7298,
    },
    {
        store_latitdue: -0.996727,
        store_longitude: -80.7036,
    },
    {
        store_latitdue: -0.950825,
        store_longitude: -80.7213,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.05124,
        store_longitude: -80.6601,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9951631,
        store_longitude: -80.7069051,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0123959,
        store_longitude: -80.6835778,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.955263,
        store_longitude: -80.7359348,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00421,
        store_longitude: -80.7134,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9877473,
        store_longitude: -80.7282557,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.07749,
        store_longitude: -80.6997,
    },
    {
        store_latitdue: -1.67057544,
        store_longitude: -80.3917586,
        neighborhood: 'CAMPOZANO',
    },
    {
        store_latitdue: -0.973402,
        store_longitude: -80.7137,
    },
    {
        store_latitdue: -1.55787,
        store_longitude: -80.8127,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.33918,
        store_longitude: -80.5765,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3582532,
        store_longitude: -80.7382029,
    },
    {
        store_latitdue: -1.0101142,
        store_longitude: -80.7177647,
    },
    {
        store_latitdue: -0.9571701,
        store_longitude: -80.7419102,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.3539089,
        store_longitude: -80.5830345,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.35392,
        store_longitude: -80.5832,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.10597157,
        store_longitude: -80.5866012,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -0.9890752,
        store_longitude: -80.6996961,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9972829,
        store_longitude: -80.7422616,
    },
    {
        store_latitdue: -1.3581566,
        store_longitude: -80.7381326,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.46998,
        store_longitude: -80.5516,
    },
    {
        store_latitdue: -1.5532216,
        store_longitude: -80.8083693,
    },
    {
        store_latitdue: -1.3479608,
        store_longitude: -80.5833567,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.9477469,
        store_longitude: -80.726073,
    },
    {
        store_latitdue: -1.553178,
        store_longitude: -80.43227,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.0846995,
        store_longitude: -80.6771708,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.643301,
        store_longitude: -80.8271467,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.3480244,
        store_longitude: -80.576316,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.9666282,
        store_longitude: -80.6658611,
    },
    {
        store_latitdue: -0.9970198,
        store_longitude: -80.6953124,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3489221,
        store_longitude: -80.735196,
    },

    {
        store_latitdue: -1.552242,
        store_longitude: -80.4270885,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.05044138,
        store_longitude: -80.6536865,
    },
    {
        store_latitdue: -1.3596498,
        store_longitude: -80.5797014,
    },
    {
        store_latitdue: -1.00088,
        store_longitude: -80.7247,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.47128,
        store_longitude: -80.5344,
        neighborhood: 'EL ANEGADO',
    },
    {
        store_latitdue: -0.9486013,
        store_longitude: -80.724752,
    },
    {
        store_latitdue: -1.0054799,
        store_longitude: -80.7181377,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.5606979,
        store_longitude: -80.8135527,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.08109867,
        store_longitude: -80.6745681,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.957545,
        store_longitude: -80.750617,
    },
    {
        store_latitdue: -1.5819956,
        store_longitude: -80.4650684,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -0.995874,
        store_longitude: -80.6973,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.989417,
        store_longitude: -80.7083,
    },
    {
        store_latitdue: -0.96541374,
        store_longitude: -80.7334506,
    },

    {
        store_latitdue: -1.06494,
        store_longitude: -80.4465,
    },
    {
        store_latitdue: -1.0032299,
        store_longitude: -80.6928913,
    },
    {
        store_latitdue: -1.0003632,
        store_longitude: -80.7408852,
    },
    {
        store_latitdue: -0.9634289,
        store_longitude: -80.6883917,
    },
    {
        store_latitdue: -1.3338833,
        store_longitude: -80.57583,
    },
    {
        store_latitdue: -0.9504727,
        store_longitude: -80.7460951,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0807425,
        store_longitude: -80.6951639,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0012917,
        store_longitude: -80.693762,
    },
    {
        store_latitdue: -1.005918741,
        store_longitude: -80.68818664,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.958938,
        store_longitude: -80.734601,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00436037,
        store_longitude: -80.70905257,
    },
    {
        store_latitdue: -1.55849553,
        store_longitude: -80.8061385,
    },
    {
        store_latitdue: -1.59574,
        store_longitude: -80.8449,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.04587,
        store_longitude: -80.6554,
    },
    {
        store_latitdue: -1.00155,
        store_longitude: -80.7091,
    },
    {
        store_latitdue: -1.5559754,
        store_longitude: -80.4278036,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.00538029,
        store_longitude: -80.7294809,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -1.56118,
        store_longitude: -80.80447,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.1075955815613,
        store_longitude: -80.585433533415,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -1.01151,
        store_longitude: -80.719,
    },
    {
        store_latitdue: -0.950232,
        store_longitude: -80.7347,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0829425,
        store_longitude: -80.679861,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.56285,
        store_longitude: -80.8166,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.3480179,
        store_longitude: -80.5806368,
    },
    {
        store_latitdue: -1.3446967,
        store_longitude: -80.5785417,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.55769944,
        store_longitude: -80.8110308,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.0468310661,
        store_longitude: -80.6550265476,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.01576,
        store_longitude: -80.6802,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9518724,
        store_longitude: -80.7255984,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.08075,
        store_longitude: -80.6982,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00084,
        store_longitude: -80.7088,
    },

    {
        store_latitdue: -0.9585727,
        store_longitude: -80.8100867,
    },
    {
        store_latitdue: -0.2318146,
        store_longitude: -79.1827756,
    },
    {
        store_latitdue: -0.9552914,
        store_longitude: -80.7355697,
    },
    {
        store_latitdue: -0.966165,
        store_longitude: -80.7226,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.553007,
        store_longitude: -80.4290206,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -0.9558254,
        store_longitude: -80.7343449,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9743643,
        store_longitude: -80.7113092,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9471406,
        store_longitude: -80.735118,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.98146902,
        store_longitude: -80.7188165,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -1.08507916,
        store_longitude: -80.67711413,
    },
    {
        store_latitdue: -1.557144,
        store_longitude: -80.804414,
    },
    {
        store_latitdue: -1.3511635,
        store_longitude: -80.5799276,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.6090258,
        store_longitude: -80.8331614,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.009989,
        store_longitude: -80.6852316,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -0.9739236,
        store_longitude: -80.7118043,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.62723338,
        store_longitude: -80.5603179,
        neighborhood: 'PEDRO PABLO GÓMEZ',
    },
    {
        store_latitdue: -1.5541653333333,
        store_longitude: -80.426909366667,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.0029801,
        store_longitude: -80.7128483,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -0.956864,
        store_longitude: -80.8109067,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.957059,
        store_longitude: -80.7344,
    },
    {
        store_latitdue: -1.5617358,
        store_longitude: -80.8152651,
        neighborhood: 'PUERTO LÓPEZ',
    },

    {
        store_latitdue: -0.9536221,
        store_longitude: -80.7140694,
    },
    {
        store_latitdue: -0.9491397,
        store_longitude: -80.7323844,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.08339738,
        store_longitude: -80.6805038,
    },
    {
        store_latitdue: -1.5581542,
        store_longitude: -80.8124647,
    },
    {
        store_latitdue: -1.0433758,
        store_longitude: -80.6628347,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.5620402,
        store_longitude: -80.8075171,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.3603744,
        store_longitude: -80.5968443,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.956248,
        store_longitude: -80.737175,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.98084026,
        store_longitude: -80.6979217,
    },
    {
        store_latitdue: -1.558834,
        store_longitude: -80.421309,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.552825,
        store_longitude: -80.4322491,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -0.988868735,
        store_longitude: -80.8477222,
    },
    {
        store_latitdue: -0.978422,
        store_longitude: -80.6685,
    },

    {
        store_latitdue: -0.9832599,
        store_longitude: -80.726497,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.95645988,
        store_longitude: -80.73617435,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9621207,
        store_longitude: -80.7196398,
    },
    {
        store_latitdue: -0.984101,
        store_longitude: -80.7225,
    },
    {
        store_latitdue: -0.9829301,
        store_longitude: -80.7218562,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0522109,
        store_longitude: -80.8374003,
    },
    {
        store_latitdue: -1.0655252,
        store_longitude: -80.4666244,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -1.0122297,
        store_longitude: -80.6709189,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.35881,
        store_longitude: -80.5897,
    },
    {
        store_latitdue: -1.56258,
        store_longitude: -80.816868,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.0181751,
        store_longitude: -80.6910283,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -1.352034,
        store_longitude: -80.7397918,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -0.9846685,
        store_longitude: -80.7264444,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.35057377,
        store_longitude: -80.5617828,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.0008796,
        store_longitude: -80.7022588,
    },
    {
        store_latitdue: -0.9779794,
        store_longitude: -80.6708409,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3503242,
        store_longitude: -80.5792234,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.000685,
        store_longitude: -80.6942513,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.94600432,
        store_longitude: -80.735929,
    },
    {
        store_latitdue: -0.956875,
        store_longitude: -80.7463,
    },
    {
        store_latitdue: -1.0047108,
        store_longitude: -80.6997137,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3462902,
        store_longitude: -80.57013,
    },
    {
        store_latitdue: -1.3462291,
        store_longitude: -80.5786134,
        neighborhood: 'JIPIJAPA',
    },

    {
        store_latitdue: -1.5588353,
        store_longitude: -80.8130035,
    },
    {
        store_latitdue: -1.5558572,
        store_longitude: -80.4309926,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -0.974028,
        store_longitude: -80.7132,
    },
    {
        store_latitdue: -1.55441,
        store_longitude: -80.4277,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.0097871,
        store_longitude: -80.7201542,
    },
    {
        store_latitdue: -1.0748569,
        store_longitude: -80.6433377,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9601021,
        store_longitude: -80.7378005,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -1.34843,
        store_longitude: -80.5849,
    },
    {
        store_latitdue: -0.9902971,
        store_longitude: -80.7080302,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.947501,
        store_longitude: -80.725776,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.946282,
        store_longitude: -80.732838,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9963825,
        store_longitude: -80.7262266,
    },
    {
        store_latitdue: -1.34462,
        store_longitude: -80.577,
    },
    {
        store_latitdue: -1.00068,
        store_longitude: -80.7248,
    },
    {
        store_latitdue: -0.9913622,
        store_longitude: -80.6980948,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.952768,
        store_longitude: -80.7282,
    },
    {
        store_latitdue: -0.972616,
        store_longitude: -80.7284,
    },
    {
        store_latitdue: -0.947537,
        store_longitude: -80.7302129,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9907263,
        store_longitude: -80.7258686,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.4770938,
        store_longitude: -80.7675807,
    },
    {
        store_latitdue: -1.346546,
        store_longitude: -80.5808344,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.04506,
        store_longitude: -80.6589,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.663487,
        store_longitude: -80.459934,
    },
    {
        store_latitdue: -1.6666161,
        store_longitude: -80.4580478,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.665446,
        store_longitude: -80.457694,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.66305,
        store_longitude: -80.460227,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.6664893,
        store_longitude: -80.4581469,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.6660934,
        store_longitude: -80.4585308,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.66282,
        store_longitude: -80.4604,
    },
    {
        store_latitdue: -1.6662861,
        store_longitude: -80.4585398,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.666415,
        store_longitude: -80.458061,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.661843,
        store_longitude: -80.460862,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.0082761,
        store_longitude: -80.6863519,
    },
    {
        store_latitdue: -1.0098391,
        store_longitude: -80.6853396,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3472195,
        store_longitude: -80.5797416,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.33817,
        store_longitude: -80.5715,
    },
    {
        store_latitdue: -1.34943,
        store_longitude: -80.5794,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3479315,
        store_longitude: -80.5804693,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34373,
        store_longitude: -80.5781,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3637021,
        store_longitude: -80.5950959,
    },
    {
        store_latitdue: -1.3591662,
        store_longitude: -80.5895459,
    },
    {
        store_latitdue: -1.3481523,
        store_longitude: -80.5764296,
    },
    {
        store_latitdue: -1.347043,
        store_longitude: -80.5784235,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34481,
        store_longitude: -80.5675,
    },
    {
        store_latitdue: -1.343881,
        store_longitude: -80.578734,
    },
    {
        store_latitdue: -1.34952,
        store_longitude: -80.5793,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3447369,
        store_longitude: -80.5681449,
    },
    {
        store_latitdue: -1.35412,
        store_longitude: -80.5756,
    },
    {
        store_latitdue: -1.34839,
        store_longitude: -80.5851,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34623,
        store_longitude: -80.5682,
    },
    {
        store_latitdue: -1.3435076,
        store_longitude: -80.5757419,
    },
    {
        store_latitdue: -1.343946,
        store_longitude: -80.570173,
    },
    {
        store_latitdue: -1.3492637,
        store_longitude: -80.5807585,
    },
    {
        store_latitdue: -1.36081,
        store_longitude: -80.5932,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3453003,
        store_longitude: -80.5815336,
    },
    {
        store_latitdue: -1.3493582,
        store_longitude: -80.5816199,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.352182,
        store_longitude: -80.578176,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.348090987,
        store_longitude: -80.5821051,
    },
    {
        store_latitdue: -1.3345433,
        store_longitude: -80.5766596,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3388336,
        store_longitude: -80.5775563,
    },
    {
        store_latitdue: -1.35228684,
        store_longitude: -80.5771529,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.356289,
        store_longitude: -80.589636,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.2069,
        store_longitude: -80.3686488,
    },
    {
        store_latitdue: -1.35923394,
        store_longitude: -80.59019375,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.358045,
        store_longitude: -80.590599,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3611987,
        store_longitude: -80.5968002,
    },
    {
        store_latitdue: -1.360351,
        store_longitude: -80.596274,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3591081,
        store_longitude: -80.5896581,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.35573,
        store_longitude: -80.589,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.36101,
        store_longitude: -80.5924,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.354837,
        store_longitude: -80.587703,
    },
    {
        store_latitdue: -1.34971,
        store_longitude: -80.5801,
    },
    {
        store_latitdue: -1.3516739,
        store_longitude: -80.5900696,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34963,
        store_longitude: -80.5824,
    },
    {
        store_latitdue: -1.3402508,
        store_longitude: -80.5815376,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34074,
        store_longitude: -80.5768,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.35094,
        store_longitude: -80.5807,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.47146,
        store_longitude: -80.5351,
    },
    {
        store_latitdue: -1.34372,
        store_longitude: -80.5789,
    },
    {
        store_latitdue: -1.36147,
        store_longitude: -80.5799,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.36084,
        store_longitude: -80.5925,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3592731,
        store_longitude: -80.5877324,
    },
    {
        store_latitdue: -1.3510354,
        store_longitude: -80.5837525,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.35102,
        store_longitude: -80.5823,
    },
    {
        store_latitdue: -1.356408,
        store_longitude: -80.588818,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3447452,
        store_longitude: -80.568973,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34691,
        store_longitude: -80.5685,
    },
    {
        store_latitdue: -1.3450758,
        store_longitude: -80.5683249,
    },
    {
        store_latitdue: -1.3446986,
        store_longitude: -80.5787619,
    },
    {
        store_latitdue: -1.35911,
        store_longitude: -80.5911001,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.345555975,
        store_longitude: -80.57863087,
    },
    {
        store_latitdue: -1.3542793,
        store_longitude: -80.584956,
    },
    {
        store_latitdue: -1.3606367,
        store_longitude: -80.5932694,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.356080175,
        store_longitude: -80.58709321,
    },
    {
        store_latitdue: -1.3613504,
        store_longitude: -80.5805934,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34801,
        store_longitude: -80.5806,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.36046,
        store_longitude: -80.5983,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.344618,
        store_longitude: -80.5767964,
    },
    {
        store_latitdue: -1.3487862,
        store_longitude: -80.5763509,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.20566,
        store_longitude: -80.5813,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3568232,
        store_longitude: -80.5947014,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.347213456,
        store_longitude: -80.57419952,
    },
    {
        store_latitdue: -1.3621,
        store_longitude: -80.5801,
    },
    {
        store_latitdue: -1.3447696,
        store_longitude: -80.5668362,
    },
    {
        store_latitdue: -1.3612537,
        store_longitude: -80.5960032,
    },
    {
        store_latitdue: -1.363683,
        store_longitude: -80.59508,
    },
    {
        store_latitdue: -1.48003,
        store_longitude: -80.7674,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.47639,
        store_longitude: -80.7671,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.47289,
        store_longitude: -80.7634,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.472946,
        store_longitude: -80.764118,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.47393,
        store_longitude: -80.7638,
    },
    {
        store_latitdue: -1.47289,
        store_longitude: -80.7638,
    },
    {
        store_latitdue: -1.4805983,
        store_longitude: -80.7677417,
    },

    {
        store_latitdue: -1.47943,
        store_longitude: -80.76877,
    },
    {
        store_latitdue: -1.4730199,
        store_longitude: -80.7654754,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.47267,
        store_longitude: -80.7628,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.4784591,
        store_longitude: -80.7680678,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.467357,
        store_longitude: -80.758719,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.47324,
        store_longitude: -80.7657,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.473397,
        store_longitude: -80.7644655,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.4688283,
        store_longitude: -80.7589668,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.4757195,
        store_longitude: -80.7668857,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.48507,
        store_longitude: -80.7743,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.33773,
        store_longitude: -80.7377,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.4834224,
        store_longitude: -80.7731299,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -0.9766742,
        store_longitude: -80.7141887,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.99755,
        store_longitude: -80.7379,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.975819,
        store_longitude: -80.7152,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00312,
        store_longitude: -80.7251,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9910068,
        store_longitude: -80.726981,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9775441,
        store_longitude: -80.7175243,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.977389,
        store_longitude: -80.7172841,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.983843,
        store_longitude: -80.724314,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9882891,
        store_longitude: -80.726334,
    },
    {
        store_latitdue: -0.9819813,
        store_longitude: -80.723301,
    },
    {
        store_latitdue: -0.9973191,
        store_longitude: -80.7365073,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.999448,
        store_longitude: -80.7267,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9831152,
        store_longitude: -80.7238741,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9997173,
        store_longitude: -80.7091543,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9901792,
        store_longitude: -80.7079286,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9886133,
        store_longitude: -80.7086367,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9917341,
        store_longitude: -80.7033027,
    },
    {
        store_latitdue: -0.999579,
        store_longitude: -80.7116,
    },
    {
        store_latitdue: -0.9906046,
        store_longitude: -80.7033842,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00805,
        store_longitude: -80.7154,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00049,
        store_longitude: -80.7245,
    },
    {
        store_latitdue: -0.983812,
        store_longitude: -80.7244,
    },
    {
        store_latitdue: -0.9875175,
        store_longitude: -80.7263128,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.992491,
        store_longitude: -80.7029,
    },
    {
        store_latitdue: -0.991510019,
        store_longitude: -80.722956,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.986403,
        store_longitude: -80.7283,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.987119,
        store_longitude: -80.7283,
    },
    {
        store_latitdue: -1.0013863,
        store_longitude: -80.7290129,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9984256,
        store_longitude: -80.7413541,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0045996,
        store_longitude: -80.711965,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9945158,
        store_longitude: -80.7068661,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.982817,
        store_longitude: -80.7235,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9591082,
        store_longitude: -80.6662094,
    },
    {
        store_latitdue: -1.00103,
        store_longitude: -80.7108,
    },
    {
        store_latitdue: -2.1691269,
        store_longitude: -79.8042069,
    },
    {
        store_latitdue: -1.00308,
        store_longitude: -80.7106,
    },
    {
        store_latitdue: -1.0057,
        store_longitude: -80.7096,
    },
    {
        store_latitdue: -0.990338,
        store_longitude: -80.7047,
    },
    {
        store_latitdue: -0.9987988,
        store_longitude: -80.7274503,
    },
    {
        store_latitdue: -0.99496,
        store_longitude: -80.7057,
    },
    {
        store_latitdue: -0.9896566,
        store_longitude: -80.7078866,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.984101,
        store_longitude: -80.7225,
    },
    {
        store_latitdue: -0.992632,
        store_longitude: -80.7028,
    },
    {
        store_latitdue: -0.9822518,
        store_longitude: -80.7215615,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9910102,
        store_longitude: -80.7018013,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.983836,
        store_longitude: -80.7243,
    },
    {
        store_latitdue: -0.9903456,
        store_longitude: -80.7074099,
    },
    {
        store_latitdue: -0.992076993,
        store_longitude: -80.70516638,
    },
    {
        store_latitdue: -1.00167,
        store_longitude: -80.7102,
    },
    {
        store_latitdue: -1.0065229,
        store_longitude: -80.7129109,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0018212,
        store_longitude: -80.7123448,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9974188,
        store_longitude: -80.7070699,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.011625,
        store_longitude: -80.7189783,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.009365,
        store_longitude: -80.7194522,
    },
    {
        store_latitdue: -0.9852416,
        store_longitude: -80.7250553,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00026,
        store_longitude: -80.7407,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0017228,
        store_longitude: -80.7282937,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9959528,
        store_longitude: -80.7259562,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.7893587,
        store_longitude: -80.2345984,
    },
    {
        store_latitdue: -0.9843398,
        store_longitude: -80.7239537,
    },
    {
        store_latitdue: -0.9571884,
        store_longitude: -80.742097,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.983066,
        store_longitude: -80.7231,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.982514,
        store_longitude: -80.7235676,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00537,
        store_longitude: -80.7296,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.997019,
        store_longitude: -80.7372,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9708337,
        store_longitude: -80.7094961,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0034786,
        store_longitude: -80.6880614,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0452175,
        store_longitude: -80.6610142,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.07807,
        store_longitude: -80.6971,
    },
    {
        store_latitdue: -1.04298,
        store_longitude: -80.656,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.077656,
        store_longitude: -80.6975804,
    },
    {
        store_latitdue: -0.9891933,
        store_longitude: -80.69694,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9889682,
        store_longitude: -80.6964285,
    },
    {
        store_latitdue: -1.02118,
        store_longitude: -80.6814,
    },
    {
        store_latitdue: -1.0426937,
        store_longitude: -80.6562032,
    },
    {
        store_latitdue: -0.9979148,
        store_longitude: -80.7405624,
    },
    {
        store_latitdue: -1.0760991,
        store_longitude: -80.6690812,
    },
    {
        store_latitdue: -1.07786,
        store_longitude: -80.7,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.07978,
        store_longitude: -80.6703,
    },
    {
        store_latitdue: -1.0793811,
        store_longitude: -80.6703247,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.04998,
        store_longitude: -80.6624,
    },
    {
        store_latitdue: -0.99078,
        store_longitude: -80.6984,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.04198,
        store_longitude: -80.6607,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0779105,
        store_longitude: -80.698697,
    },
    {
        store_latitdue: -1.0849623,
        store_longitude: -80.6961386,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0519527,
        store_longitude: -80.6604926,
    },
    {
        store_latitdue: -1.0449526,
        store_longitude: -80.6558443,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0459772,
        store_longitude: -80.6553239,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0783044,
        store_longitude: -80.6985034,
    },
    {
        store_latitdue: -1.0450188,
        store_longitude: -80.6559647,
    },
    {
        store_latitdue: -1.0067993,
        store_longitude: -80.6879138,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0507618,
        store_longitude: -80.6609735,
    },
    {
        store_latitdue: -1.00242,
        store_longitude: -80.7091,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0447177,
        store_longitude: -80.6568363,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0770164,
        store_longitude: -80.6978047,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0430623,
        store_longitude: -80.6575187,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.03958,
        store_longitude: -80.6645,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0783463,
        store_longitude: -80.6989086,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.04931,
        store_longitude: -80.6616,
    },
    {
        store_latitdue: -1.04033,
        store_longitude: -80.6613,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0417398,
        store_longitude: -80.6600442,
    },
    {
        store_latitdue: -1.04635,
        store_longitude: -80.656,
    },
    {
        store_latitdue: -1.0448375,
        store_longitude: -80.6605935,
    },
    {
        store_latitdue: -1.08235,
        store_longitude: -80.697,
    },
    {
        store_latitdue: -0.996493,
        store_longitude: -80.6947,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0435658,
        store_longitude: -80.6545312,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.07798,
        store_longitude: -80.6707,
    },
    {
        store_latitdue: -0.9994056,
        store_longitude: -80.6916651,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0015334,
        store_longitude: -80.707955,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.07827,
        store_longitude: -80.6962,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0499399,
        store_longitude: -80.6609142,
    },
    {
        store_latitdue: -1.0433641,
        store_longitude: -80.65637,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.1070323,
        store_longitude: -80.5845874,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -1.1072259,
        store_longitude: -80.5857375,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -1.1067563,
        store_longitude: -80.5850722,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -1.1056443,
        store_longitude: -80.5846057,
    },
    {
        store_latitdue: -1.0044569,
        store_longitude: -80.6908535,
    },
    {
        store_latitdue: -1.0029704,
        store_longitude: -80.6930624,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00061,
        store_longitude: -80.6926,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.082404,
        store_longitude: -80.6725691,
    },
    {
        store_latitdue: -1.04594,
        store_longitude: -80.6617,
    },
    {
        store_latitdue: -0.9974673,
        store_longitude: -80.6982713,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.996921,
        store_longitude: -80.6969114,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0846376,
        store_longitude: -80.6785574,
    },
    {
        store_latitdue: -1.0420476,
        store_longitude: -80.6611709,
    },
    {
        store_latitdue: -0.997365,
        store_longitude: -80.7056,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0831718,
        store_longitude: -80.6799709,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0001008,
        store_longitude: -80.6986728,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0026,
        store_longitude: -80.6934,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.08138,
        store_longitude: -80.674,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -1.04588,
        store_longitude: -80.6571,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0390516,
        store_longitude: -80.6635374,
    },
    {
        store_latitdue: -1.077738,
        store_longitude: -80.6695916,
    },
    {
        store_latitdue: -0.9888849,
        store_longitude: -80.7086238,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9518625,
        store_longitude: -80.6956828,
    },
    {
        store_latitdue: -1.04624,
        store_longitude: -80.66,
    },
    {
        store_latitdue: -0.9987466,
        store_longitude: -80.7116212,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9987108,
        store_longitude: -80.6946709,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0026,
        store_longitude: -80.6945,
    },
    {
        store_latitdue: -0.9988561,
        store_longitude: -80.6957112,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00340903,
        store_longitude: -80.6931893,
    },
    {
        store_latitdue: -1.1052938,
        store_longitude: -80.5845836,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -1.0459722,
        store_longitude: -80.6544293,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.627378311,
        store_longitude: -80.56028081,
    },
    {
        store_latitdue: -1.079507,
        store_longitude: -80.6508887,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00374,
        store_longitude: -80.6921,
    },
    {
        store_latitdue: -1.0019949,
        store_longitude: -80.6966525,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0426513,
        store_longitude: -80.6613199,
    },
    {
        store_latitdue: -0.9940621,
        store_longitude: -80.6940699,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0433459,
        store_longitude: -80.6551671,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0799446,
        store_longitude: -80.670792,
    },
    {
        store_latitdue: -1.05282,
        store_longitude: -80.6604,
    },
    {
        store_latitdue: -1.0426003,
        store_longitude: -80.66129,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0411676,
        store_longitude: -80.6575693,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9981936,
        store_longitude: -80.6964265,
    },
    {
        store_latitdue: -0.9676533,
        store_longitude: -80.7089101,
    },
    {
        store_latitdue: -1.05348,
        store_longitude: -80.6598,
    },
    {
        store_latitdue: -1.00212,
        store_longitude: -80.6981,
    },
    {
        store_latitdue: -1.0851818,
        store_longitude: -80.6784973,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9957235,
        store_longitude: -80.6933958,
    },
    {
        store_latitdue: -1.0018489,
        store_longitude: -80.6926278,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.04665,
        store_longitude: -80.6576,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0390656,
        store_longitude: -80.6611114,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.999005,
        store_longitude: -80.6917,
    },
    {
        store_latitdue: -1.04995,
        store_longitude: -80.6606,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9967383,
        store_longitude: -80.6920402,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00274,
        store_longitude: -80.7115,
    },
    {
        store_latitdue: -1.07793,
        store_longitude: -80.698,
    },
    {
        store_latitdue: -0.9995298,
        store_longitude: -80.6905954,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.04871,
        store_longitude: -80.6597,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9970173,
        store_longitude: -80.695271,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0046202,
        store_longitude: -80.6875034,
    },
    {
        store_latitdue: -1.0795257,
        store_longitude: -80.6706755,
    },
    {
        store_latitdue: -1.0050651,
        store_longitude: -80.7094455,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0035082,
        store_longitude: -80.6923145,
    },
    {
        store_latitdue: -1.0030225,
        store_longitude: -80.7080415,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9983491,
        store_longitude: -80.6949983,
    },
    {
        store_latitdue: -1.00138,
        store_longitude: -80.6942,
    },
    {
        store_latitdue: -0.9983221,
        store_longitude: -80.690676,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0003981,
        store_longitude: -80.695206,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.965332,
        store_longitude: -80.713,
    },
    {
        store_latitdue: -0.9963477,
        store_longitude: -80.694449,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.996689,
        store_longitude: -80.694995,
    },
    {
        store_latitdue: -1.0020602,
        store_longitude: -80.6923746,
    },
    {
        store_latitdue: -1.10777,
        store_longitude: -80.5838,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -1.5524,
        store_longitude: -80.4292,
    },
    {
        store_latitdue: -1.55458,
        store_longitude: -80.428362,
    },
    {
        store_latitdue: -1.5545,
        store_longitude: -80.4286,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5563714,
        store_longitude: -80.4324893,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5523157,
        store_longitude: -80.4309551,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5541814,
        store_longitude: -80.4277872,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.55282,
        store_longitude: -80.4317,
    },
    {
        store_latitdue: -1.55365,
        store_longitude: -80.4305,
    },
    {
        store_latitdue: -1.5546149,
        store_longitude: -80.4314786,
    },
    {
        store_latitdue: -1.5543674,
        store_longitude: -80.4279079,
    },
    {
        store_latitdue: -1.5550256,
        store_longitude: -80.4283958,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.55266,
        store_longitude: -80.4315,
    },
    {
        store_latitdue: -1.55529,
        store_longitude: -80.4302,
    },
    {
        store_latitdue: -1.5539567,
        store_longitude: -80.4311731,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.55431,
        store_longitude: -80.4276,
    },
    {
        store_latitdue: -1.6165784,
        store_longitude: -80.5571087,
        neighborhood: 'PEDRO PABLO GÓMEZ',
    },
    {
        store_latitdue: -1.5563317,
        store_longitude: -80.4277219,
    },
    {
        store_latitdue: -1.55422,
        store_longitude: -80.427,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5523942,
        store_longitude: -80.4317828,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5679344,
        store_longitude: -80.4472148,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.58794,
        store_longitude: -80.3973,
        neighborhood: 'CAMPOZANO',
    },
    {
        store_latitdue: -1.5529706,
        store_longitude: -80.4262982,
    },
    {
        store_latitdue: -1.5545778,
        store_longitude: -80.4284928,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5544737,
        store_longitude: -80.4203531,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.551201,
        store_longitude: -80.427979,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.557169,
        store_longitude: -80.4286131,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5550978,
        store_longitude: -80.4295336,
    },
    {
        store_latitdue: -1.348113,
        store_longitude: -80.734769,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.35058,
        store_longitude: -80.7379,
    },
    {
        store_latitdue: -1.34789,
        store_longitude: -80.735627,
    },
    {
        store_latitdue: -1.35157,
        store_longitude: -80.7384,
    },
    {
        store_latitdue: -1.34842,
        store_longitude: -80.7354,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.3491769,
        store_longitude: -80.7360513,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.35696,
        store_longitude: -80.7385,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.6066307,
        store_longitude: -80.8372618,
    },
    {
        store_latitdue: -1.349993,
        store_longitude: -80.732657,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.28895,
        store_longitude: -80.7471,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.357693,
        store_longitude: -80.738358,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.35214,
        store_longitude: -80.74,
    },
    {
        store_latitdue: -1.350673,
        store_longitude: -80.735045,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.3473222,
        store_longitude: -80.7345679,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.34926,
        store_longitude: -80.738919,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.357641,
        store_longitude: -80.738359,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.35254,
        store_longitude: -80.736382,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.3576253,
        store_longitude: -80.7382511,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.3473,
        store_longitude: -80.7356,
    },
    {
        store_latitdue: -1.353193,
        store_longitude: -80.736885,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.3580064,
        store_longitude: -80.7382804,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.35123,
        store_longitude: -80.7365,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.2905,
        store_longitude: -80.7476,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.34314,
        store_longitude: -80.7358,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.5606164,
        store_longitude: -80.8110365,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.562062,
        store_longitude: -80.815594,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.55331,
        store_longitude: -80.8084,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.561131,
        store_longitude: -80.81159,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.562246,
        store_longitude: -80.811463,
    },
    {
        store_latitdue: -1.562508,
        store_longitude: -80.812201,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.54048,
        store_longitude: -80.8018717,
    },
    {
        store_latitdue: -1.558423,
        store_longitude: -80.81034,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5637,
        store_longitude: -80.8154,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5573817,
        store_longitude: -80.8096633,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.563303,
        store_longitude: -80.814278,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5403917,
        store_longitude: -80.8018867,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.56062,
        store_longitude: -80.8111,
    },
    {
        store_latitdue: -1.557966,
        store_longitude: -80.81129,
    },
    {
        store_latitdue: -1.559118,
        store_longitude: -80.808226,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.636075,
        store_longitude: -80.827019,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.5594099,
        store_longitude: -80.8045026,
    },
    {
        store_latitdue: -1.5619506,
        store_longitude: -80.8109761,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5603674,
        store_longitude: -80.8113846,
    },
    {
        store_latitdue: -1.554094,
        store_longitude: -80.808116,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5576196,
        store_longitude: -80.8037609,
    },
    {
        store_latitdue: -1.5600103,
        store_longitude: -80.8085685,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5577,
        store_longitude: -80.8047,
    },
    {
        store_latitdue: -1.56272,
        store_longitude: -80.8116,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5619335,
        store_longitude: -80.8110316,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5599514,
        store_longitude: -80.8071121,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5389303,
        store_longitude: -80.8043489,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.56174,
        store_longitude: -80.8151,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.56186,
        store_longitude: -80.8093,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.56335,
        store_longitude: -80.8122,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.593759,
        store_longitude: -80.844379,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.5889835,
        store_longitude: -80.8414646,
    },
    {
        store_latitdue: -1.5919677,
        store_longitude: -80.8392295,
    },
    {
        store_latitdue: -1.6751661,
        store_longitude: -80.8118902,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.650763,
        store_longitude: -80.823664,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.6525802,
        store_longitude: -80.8201956,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.59294,
        store_longitude: -80.8388,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.67685,
        store_longitude: -80.8108,
    },
    {
        store_latitdue: -1.59256,
        store_longitude: -80.8386,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.35138,
        store_longitude: -80.737564,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.5541164,
        store_longitude: -80.4272951,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -0.9971449,
        store_longitude: -80.695492,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0443388,
        store_longitude: -80.659901,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9833874,
        store_longitude: -80.7240436,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9917813,
        store_longitude: -80.7278197,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.04241,
        store_longitude: -80.6581,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0435473,
        store_longitude: -80.6578628,
    },
    {
        store_latitdue: -0.9955468,
        store_longitude: -80.6990974,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0050655,
        store_longitude: -80.7111884,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.049968,
        store_longitude: -80.6607931,
    },
    {
        store_latitdue: -1.0443656,
        store_longitude: -80.6598268,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0154395,
        store_longitude: -80.6815948,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.045945,
        store_longitude: -80.661595,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0434995,
        store_longitude: -80.6571459,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.07887,
        store_longitude: -80.6972,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.35007,
        store_longitude: -80.5788,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3475497,
        store_longitude: -80.5770351,
    },
    {
        store_latitdue: -1.34051,
        store_longitude: -80.577054,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3498078,
        store_longitude: -80.5794002,
    },
    {
        store_latitdue: -1.3456075,
        store_longitude: -80.5793331,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3478958,
        store_longitude: -80.5791883,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34287006,
        store_longitude: -80.57922413,
    },
    {
        store_latitdue: -1.3505605,
        store_longitude: -80.5815145,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.33907,
        store_longitude: -80.5763,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.593127,
        store_longitude: -80.84359,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.3545485,
        store_longitude: -80.5848531,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.5624901,
        store_longitude: -80.8138165,
    },
    {
        store_latitdue: -1.34897,
        store_longitude: -80.7358,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.478696,
        store_longitude: -80.768313,
    },
    {
        store_latitdue: -1.475397,
        store_longitude: -80.766549,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.349383,
        store_longitude: -80.736642,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.350648,
        store_longitude: -80.734494,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.554211,
        store_longitude: -80.4280282,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.3481684,
        store_longitude: -80.5762388,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.00095,
        store_longitude: -80.7283,
    },
    {
        store_latitdue: -1.47,
        store_longitude: -80.533,
    },
    {
        store_latitdue: -1.205681,
        store_longitude: -80.580971,
    },
    {
        store_latitdue: -1.0232037,
        store_longitude: -80.6817155,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.5915484,
        store_longitude: -80.8426898,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.4713778,
        store_longitude: -80.535617,
        neighborhood: 'EL ANEGADO',
    },
    {
        store_latitdue: -1.6659511,
        store_longitude: -80.4588763,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.677878,
        store_longitude: -80.81269,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.0148871,
        store_longitude: -80.683688,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.4714459,
        store_longitude: -80.5334631,
    },
    {
        store_latitdue: -1.3442466,
        store_longitude: -80.5766287,
    },
    {
        store_latitdue: -1.67294,
        store_longitude: -80.8084,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.20335,
        store_longitude: -80.583,
    },
    {
        store_latitdue: -1.2603217,
        store_longitude: -80.585196,
    },
    {
        store_latitdue: -1.10609,
        store_longitude: -80.5845,
    },
    {
        store_latitdue: -1.5611,
        store_longitude: -80.8127,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.4716343,
        store_longitude: -80.5354149,
        neighborhood: 'EL ANEGADO',
    },
    {
        store_latitdue: -1.64746332,
        store_longitude: -80.8257522,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.3495511,
        store_longitude: -80.5790415,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.347745,
        store_longitude: -80.577441,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.34637,
        store_longitude: -80.5683,
    },
    {
        store_latitdue: -1.35864,
        store_longitude: -80.5879,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.01576,
        store_longitude: -80.6827,
    },
    {
        store_latitdue: -1.0158911,
        store_longitude: -80.6828595,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.01867,
        store_longitude: -80.6799,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0824904,
        store_longitude: -80.680065,
    },
    {
        store_latitdue: -1.01197,
        store_longitude: -80.6856,
    },
    {
        store_latitdue: -1.02311,
        store_longitude: -80.6813,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.10995,
        store_longitude: -80.5828,
    },
    {
        store_latitdue: -1.0156,
        store_longitude: -80.6802,
    },
    {
        store_latitdue: -1.651926,
        store_longitude: -80.8235731,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.48325,
        store_longitude: -80.774402,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.01732,
        store_longitude: -80.6803,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0212948,
        store_longitude: -80.6842039,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.6507383,
        store_longitude: -80.8237639,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.0134324,
        store_longitude: -80.6835548,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0225506,
        store_longitude: -80.6815732,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.5603676,
        store_longitude: -80.8063503,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.34886,
        store_longitude: -80.5813,
    },
    {
        store_latitdue: -1.6702799,
        store_longitude: -80.4557549,
        neighborhood: 'CASCOL',
    },
    {
        store_latitdue: -1.557085,
        store_longitude: -80.804321,
    },
    {
        store_latitdue: -1.652066,
        store_longitude: -80.823442,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.593869,
        store_longitude: -80.84304,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.0822764,
        store_longitude: -80.6739826,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.08419,
        store_longitude: -80.6813,
    },
    {
        store_latitdue: -1.03959,
        store_longitude: -80.6639,
    },
    {
        store_latitdue: -1.03963,
        store_longitude: -80.664,
    },
    {
        store_latitdue: -1.003772,
        store_longitude: -80.6913741,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.04487,
        store_longitude: -80.6808,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.02402,
        store_longitude: -80.6747,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00291,
        store_longitude: -80.6929,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.049,
        store_longitude: -80.6594,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.01252,
        store_longitude: -80.6854,
    },
    {
        store_latitdue: -1.0490959,
        store_longitude: -80.6599422,
    },
    {
        store_latitdue: -1.0017,
        store_longitude: -80.692,
    },
    {
        store_latitdue: -1.0412922,
        store_longitude: -80.657603,
    },
    {
        store_latitdue: -1.0436,
        store_longitude: -80.6586,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9988288,
        store_longitude: -80.697227,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.05344,
        store_longitude: -80.6596,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9996134,
        store_longitude: -80.6923697,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0393026,
        store_longitude: -80.6643353,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.996322,
        store_longitude: -80.6944,
    },
    {
        store_latitdue: -0.999172,
        store_longitude: -80.6987,
    },
    {
        store_latitdue: -1.0795368,
        store_longitude: -80.6500928,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0814327,
        store_longitude: -80.6725142,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0806724,
        store_longitude: -80.6729645,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0797505,
        store_longitude: -80.6965112,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0789357,
        store_longitude: -80.699148,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.04597,
        store_longitude: -80.6578,
    },
    {
        store_latitdue: -1.0779725,
        store_longitude: -80.6986413,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0844413,
        store_longitude: -80.6764482,
    },
    {
        store_latitdue: -1.20308,
        store_longitude: -80.5831,
    },
    {
        store_latitdue: -1.2589836,
        store_longitude: -80.5817384,
    },
    {
        store_latitdue: -1.554553,
        store_longitude: -80.4276861,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.5542067,
        store_longitude: -80.4275851,
    },
    {
        store_latitdue: -1.08283,
        store_longitude: -80.6817482,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3460306,
        store_longitude: -80.5700138,
    },
    {
        store_latitdue: -1.3481992,
        store_longitude: -80.5765837,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3585,
        store_longitude: -80.5877,
    },
    {
        store_latitdue: -1.35639,
        store_longitude: -80.5887,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3554179,
        store_longitude: -80.5884538,
    },
    {
        store_latitdue: -1.363011,
        store_longitude: -80.5798088,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3577267,
        store_longitude: -80.577701,
    },
    {
        store_latitdue: -1.3648313,
        store_longitude: -80.5951704,
    },
    {
        store_latitdue: -1.36139,
        store_longitude: -80.5946,
    },
    {
        store_latitdue: -1.35973,
        store_longitude: -80.5903,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3577401,
        store_longitude: -80.5932015,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.35796,
        store_longitude: -80.5931,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.36033,
        store_longitude: -80.598,
    },
    {
        store_latitdue: -1.47103,
        store_longitude: -80.533,
        neighborhood: 'EL ANEGADO',
    },
    {
        store_latitdue: -1.4711562,
        store_longitude: -80.5519081,
        neighborhood: 'EL ANEGADO',
    },
    {
        store_latitdue: -1.4919954,
        store_longitude: -80.5427068,
        neighborhood: 'EL ANEGADO',
    },
    {
        store_latitdue: -1.6259866,
        store_longitude: -80.5602183,
        neighborhood: 'PEDRO PABLO GÓMEZ',
    },
    {
        store_latitdue: -1.60941,
        store_longitude: -80.5503,
        neighborhood: 'PEDRO PABLO GÓMEZ',
    },
    {
        store_latitdue: -1.47137,
        store_longitude: -80.5341,
    },
    {
        store_latitdue: -1.574674,
        store_longitude: -80.51384,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.556103,
        store_longitude: -80.4322054,
    },
    {
        store_latitdue: -0.9966861,
        store_longitude: -80.7268904,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0027826,
        store_longitude: -80.725852,
    },
    {
        store_latitdue: -1.0022789,
        store_longitude: -80.7254674,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0025924,
        store_longitude: -80.7244146,
    },
    {
        store_latitdue: -1.00011,
        store_longitude: -80.7253,
    },
    {
        store_latitdue: -1.00442,
        store_longitude: -80.7289,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00008,
        store_longitude: -80.7273,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.982294,
        store_longitude: -80.7236,
    },
    {
        store_latitdue: -1.66169,
        store_longitude: -80.4607,
    },
    {
        store_latitdue: -1.3573058,
        store_longitude: -80.5904992,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.55873,
        store_longitude: -80.8092,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5612721,
        store_longitude: -80.8106391,
    },
    {
        store_latitdue: -1.53932,
        store_longitude: -80.8034,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5398021,
        store_longitude: -80.8020356,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.551025,
        store_longitude: -80.810933,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.555932,
        store_longitude: -80.809174,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5580338,
        store_longitude: -80.8105707,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.67727,
        store_longitude: -80.8118,
    },
    {
        store_latitdue: -1.67217,
        store_longitude: -80.808707,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.6721378,
        store_longitude: -80.8084524,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.609267,
        store_longitude: -80.8327325,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.5608005,
        store_longitude: -80.814187,
    },
    {
        store_latitdue: -1.5952631,
        store_longitude: -80.844976,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.5627909,
        store_longitude: -80.8177812,
    },
    {
        store_latitdue: -1.4689787,
        store_longitude: -80.762216,
    },
    {
        store_latitdue: -1.34856,
        store_longitude: -80.7349689,
    },
    {
        store_latitdue: -1.3401963,
        store_longitude: -80.7387864,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.30838,
        store_longitude: -80.7636,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.55255,
        store_longitude: -80.8063,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.58961276,
        store_longitude: -80.80957305,
    },
    {
        store_latitdue: -1.6522457,
        store_longitude: -80.8208334,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.5595163,
        store_longitude: -80.8084329,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.9982796,
        store_longitude: -80.7389075,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9795627,
        store_longitude: -80.7162875,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.9751462,
        store_longitude: -80.7145988,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.977854,
        store_longitude: -80.7118556,
    },
    {
        store_latitdue: -0.99365,
        store_longitude: -80.7041,
    },
    {
        store_latitdue: -0.9781992,
        store_longitude: -80.7117362,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9782254,
        store_longitude: -80.7123169,
    },
    {
        store_latitdue: -0.977946,
        store_longitude: -80.7138,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.97656,
        store_longitude: -80.7164,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9750303,
        store_longitude: -80.716417,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.980418,
        store_longitude: -80.7164,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9771751,
        store_longitude: -80.7111542,
    },
    {
        store_latitdue: -0.994515,
        store_longitude: -80.7052,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.995357,
        store_longitude: -80.7052,
    },
    {
        store_latitdue: -0.990765,
        store_longitude: -80.7072,
    },
    {
        store_latitdue: -0.992106,
        store_longitude: -80.7061,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.987836,
        store_longitude: -80.7085,
    },
    {
        store_latitdue: -0.9974359,
        store_longitude: -80.7378215,
    },
    {
        store_latitdue: -0.993626,
        store_longitude: -80.7300807,
    },
    {
        store_latitdue: -0.9919087,
        store_longitude: -80.727635,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0025338,
        store_longitude: -80.7425762,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.943184,
        store_longitude: -80.7356325,
    },
    {
        store_latitdue: -0.998401,
        store_longitude: -80.7409,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9978324,
        store_longitude: -80.7398897,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00384,
        store_longitude: -80.7157,
    },
    {
        store_latitdue: -0.999535,
        store_longitude: -80.7263,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.995973328,
        store_longitude: -80.72758892,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9825112,
        store_longitude: -80.7256102,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.99444879,
        store_longitude: -80.7046544,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.981148,
        store_longitude: -80.7169,
    },
    {
        store_latitdue: -1.0006403,
        store_longitude: -80.7248372,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9790997,
        store_longitude: -80.71357,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0056829,
        store_longitude: -80.6872605,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0387702,
        store_longitude: -80.6664731,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.998285,
        store_longitude: -80.6948,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0000037,
        store_longitude: -80.6954396,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9995026,
        store_longitude: -80.6959596,
    },
    {
        store_latitdue: -1.04558,
        store_longitude: -80.6585,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.996137,
        store_longitude: -80.6945,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.591135,
        store_longitude: -80.496427,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.36367,
        store_longitude: -80.5956,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.978935,
        store_longitude: -80.7151,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.002342,
        store_longitude: -80.7265934,
    },
    {
        store_latitdue: -1.0003377,
        store_longitude: -80.698586,
    },
    {
        store_latitdue: -1.02297,
        store_longitude: -80.6852,
    },
    {
        store_latitdue: -0.9906468,
        store_longitude: -80.6980729,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0116281,
        store_longitude: -80.7191765,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9874462,
        store_longitude: -80.7096235,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.999185,
        store_longitude: -80.6997,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.051,
        store_longitude: -80.6588,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9926035,
        store_longitude: -80.7016081,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0510781,
        store_longitude: -80.6582895,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9883476,
        store_longitude: -80.7108536,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9881563,
        store_longitude: -80.7105663,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.3420452,
        store_longitude: -80.5820809,
    },
    {
        store_latitdue: -1.5526114,
        store_longitude: -80.4315494,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.54954,
        store_longitude: -80.4267,
    },
    {
        store_latitdue: -1.55405666,
        store_longitude: -80.4290504,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.554304,
        store_longitude: -80.426921,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.3421489,
        store_longitude: -80.5769864,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.2527482,
        store_longitude: -80.5806934,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.257236,
        store_longitude: -80.5812833,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.957476,
        store_longitude: -80.7557,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.35004,
        store_longitude: -80.7324,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.3489017,
        store_longitude: -80.7353467,
    },
    {
        store_latitdue: -1.34117,
        store_longitude: -80.738,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.55575716,
        store_longitude: -80.8136379,
    },
    {
        store_latitdue: -1.552211,
        store_longitude: -80.811191,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5931,
        store_longitude: -80.843,
    },
    {
        store_latitdue: -1.5626,
        store_longitude: -80.815,
    },
    {
        store_latitdue: -1.3536014,
        store_longitude: -80.5772909,
    },
    {
        store_latitdue: -1.3392738,
        store_longitude: -80.7401188,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.3430629,
        store_longitude: -80.7367504,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.35811,
        store_longitude: -80.5883,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3540349,
        store_longitude: -80.5770456,
    },
    {
        store_latitdue: -0.9487245,
        store_longitude: -80.7449131,
    },
    {
        store_latitdue: -0.9434172,
        store_longitude: -80.728975,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9426708,
        store_longitude: -80.7369835,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.943297,
        store_longitude: -80.7266089,
    },
    {
        store_latitdue: -0.9445058,
        store_longitude: -80.7366709,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.950788,
        store_longitude: -80.7286,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.943667,
        store_longitude: -80.726819,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.943792,
        store_longitude: -80.7257,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.949877,
        store_longitude: -80.727094,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9469245,
        store_longitude: -80.7467749,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9494561,
        store_longitude: -80.7271245,
    },
    {
        store_latitdue: -0.9850755,
        store_longitude: -80.8459702,
        neighborhood: 'SANTA MARIANITA',
    },
    {
        store_latitdue: -0.9444708,
        store_longitude: -80.7274348,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.989019,
        store_longitude: -80.8478,
        neighborhood: 'SANTA MARIANITA',
    },
    {
        store_latitdue: -0.947441,
        store_longitude: -80.7392,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.946656,
        store_longitude: -80.7384,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9568613,
        store_longitude: -80.7389561,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9483242,
        store_longitude: -80.7406275,
    },
    {
        store_latitdue: -0.94505,
        store_longitude: -80.7366,
    },
    {
        store_latitdue: -0.9571759,
        store_longitude: -80.7547238,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9566328,
        store_longitude: -80.7395896,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9573597,
        store_longitude: -80.7400788,
    },
    {
        store_latitdue: -0.958224,
        store_longitude: -80.739,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9476645,
        store_longitude: -80.7298962,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9431777,
        store_longitude: -80.7357204,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9426694,
        store_longitude: -80.7353392,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.959697,
        store_longitude: -80.7379,
    },
    {
        store_latitdue: -0.9577644,
        store_longitude: -80.7486577,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.960959,
        store_longitude: -80.8097907,
    },
    {
        store_latitdue: -0.96158,
        store_longitude: -80.81062,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.124298,
        store_longitude: -80.886252,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -1.05844,
        store_longitude: -80.8393,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9576582,
        store_longitude: -80.7491511,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.05758,
        store_longitude: -80.8394,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.108135,
        store_longitude: -80.894248,
    },
    {
        store_latitdue: -0.9561621,
        store_longitude: -80.7556696,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9583336,
        store_longitude: -80.8116678,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9485834,
        store_longitude: -80.7264852,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -2.09599,
        store_longitude: -79.9074,
    },
    {
        store_latitdue: -0.9434913,
        store_longitude: -80.7275262,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.957357,
        store_longitude: -80.739,
    },
    {
        store_latitdue: -0.9442386,
        store_longitude: -80.728593,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.064944,
        store_longitude: -80.904378,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.958574,
        store_longitude: -80.7454798,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95867446,
        store_longitude: -80.81100254,
    },
    {
        store_latitdue: -0.9582046,
        store_longitude: -80.8109145,
    },
    {
        store_latitdue: -0.966251,
        store_longitude: -80.7582,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0683,
        store_longitude: -80.9065,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.961363,
        store_longitude: -80.818182,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9603519,
        store_longitude: -80.8110812,
    },
    {
        store_latitdue: -0.9581874,
        store_longitude: -80.7417279,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9735743,
        store_longitude: -80.6968371,
    },
    {
        store_latitdue: -0.9568942,
        store_longitude: -80.7463776,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9590876,
        store_longitude: -80.7439918,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0670049,
        store_longitude: -80.9056017,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.9676533,
        store_longitude: -80.7089101,
    },
    {
        store_latitdue: -0.9564,
        store_longitude: -80.769,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.981474,
        store_longitude: -80.6987,
    },
    {
        store_latitdue: -0.9649161,
        store_longitude: -80.8088147,
    },
    {
        store_latitdue: -0.976143,
        store_longitude: -80.7299413,
    },
    {
        store_latitdue: -0.9586563,
        store_longitude: -80.7315212,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9488819,
        store_longitude: -80.7250887,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.956216,
        store_longitude: -80.7332,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9820833,
        store_longitude: -80.7231871,
    },
    {
        store_latitdue: -0.9546741,
        store_longitude: -80.7333191,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.954724,
        store_longitude: -80.7356,
    },
    {
        store_latitdue: -0.955147,
        store_longitude: -80.736,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9628645,
        store_longitude: -80.7274433,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.961449,
        store_longitude: -80.728,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.94963,
        store_longitude: -80.7264,
    },
    {
        store_latitdue: -0.955332,
        store_longitude: -80.7361,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9519161,
        store_longitude: -80.734964,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9499,
        store_longitude: -80.726,
    },
    {
        store_latitdue: -0.9614259,
        store_longitude: -80.7296251,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.958393,
        store_longitude: -80.7366,
    },
    {
        store_latitdue: -0.954721,
        store_longitude: -80.7362,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.961934,
        store_longitude: -80.7301,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9499366,
        store_longitude: -80.7270978,
    },
    {
        store_latitdue: -0.9617342,
        store_longitude: -80.7303861,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9871207,
        store_longitude: -80.7131844,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9858456,
        store_longitude: -80.7129219,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.958836,
        store_longitude: -80.7367,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -0.9494892,
        store_longitude: -80.7263409,
    },
    {
        store_latitdue: -0.959009,
        store_longitude: -80.7367,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9844931,
        store_longitude: -80.7226027,
    },
    {
        store_latitdue: -0.958094,
        store_longitude: -80.7362,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9860017,
        store_longitude: -80.7129298,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9839542,
        store_longitude: -80.7127939,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9621341,
        store_longitude: -80.727665,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9576248,
        store_longitude: -80.7356168,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.984057,
        store_longitude: -80.7125,
    },
    {
        store_latitdue: -0.957908,
        store_longitude: -80.7351,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9642413,
        store_longitude: -80.7314427,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.982104,
        store_longitude: -80.7122,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.955169,
        store_longitude: -80.7396,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95523,
        store_longitude: -80.7394,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.965653,
        store_longitude: -80.7295,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.964008,
        store_longitude: -80.7315,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.96056,
        store_longitude: -80.7354,
    },
    {
        store_latitdue: -0.9639025,
        store_longitude: -80.7295257,
    },
    {
        store_latitdue: -0.9462131,
        store_longitude: -80.7338112,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.958582,
        store_longitude: -80.7351,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9846589,
        store_longitude: -80.7236135,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.955698,
        store_longitude: -80.7447,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.951052,
        store_longitude: -80.7251,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9523577,
        store_longitude: -80.7260553,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.952567,
        store_longitude: -80.7264,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.953354,
        store_longitude: -80.7274,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.950796,
        store_longitude: -80.7431,
    },
    {
        store_latitdue: -0.952836044,
        store_longitude: -80.74170149,
    },
    {
        store_latitdue: -0.9510604,
        store_longitude: -80.742137,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.949309,
        store_longitude: -80.7357,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.952984,
        store_longitude: -80.737,
    },
    {
        store_latitdue: -0.9580981,
        store_longitude: -80.7337782,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9561845,
        store_longitude: -80.7344014,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.956028,
        store_longitude: -80.7343,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9763663,
        store_longitude: -80.7209581,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9766335,
        store_longitude: -80.7211669,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: 0.351139,
        store_longitude: -78.1407,
    },
    {
        store_latitdue: -1.00088,
        store_longitude: -80.7247,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.980018,
        store_longitude: -80.7226,
    },
    {
        store_latitdue: -0.9534864,
        store_longitude: -80.7319376,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.974726,
        store_longitude: -80.7186,
    },
    {
        store_latitdue: -0.9790613,
        store_longitude: -80.7240892,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.972831,
        store_longitude: -80.7289,
    },
    {
        store_latitdue: -0.9737388,
        store_longitude: -80.7190635,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9492711,
        store_longitude: -80.7254524,
    },
    {
        store_latitdue: -0.949714,
        store_longitude: -80.7263,
    },
    {
        store_latitdue: -0.9723647,
        store_longitude: -80.7260025,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9711101,
        store_longitude: -80.7247587,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9713388,
        store_longitude: -80.7279815,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9709747,
        store_longitude: -80.7234745,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9696359,
        store_longitude: -80.7164933,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.969501,
        store_longitude: -80.7215,
    },
    {
        store_latitdue: -0.9703622,
        store_longitude: -80.7182019,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9710386,
        store_longitude: -80.7182453,
    },
    {
        store_latitdue: -0.971078,
        store_longitude: -80.7219,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9740346,
        store_longitude: -80.7117713,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9707804,
        store_longitude: -80.7191506,
    },
    {
        store_latitdue: -0.9715746,
        store_longitude: -80.7203858,
    },
    {
        store_latitdue: -0.9700305,
        store_longitude: -80.7235397,
    },
    {
        store_latitdue: -0.972866,
        store_longitude: -80.7201171,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9703416,
        store_longitude: -80.7241772,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9703379,
        store_longitude: -80.72402,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.954286,
        store_longitude: -80.7277,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9946157,
        store_longitude: -80.7069744,
    },
    {
        store_latitdue: -0.971812,
        store_longitude: -80.7163,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.990795,
        store_longitude: -80.6989,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.02225,
        store_longitude: -80.04093,
    },
    {
        store_latitdue: -0.9886797,
        store_longitude: -80.7005104,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0452169,
        store_longitude: -80.6554052,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.00541,
        store_longitude: -80.6993,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3389,
        store_longitude: -80.5682,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3450305,
        store_longitude: -80.5817554,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.55295,
        store_longitude: -80.4322,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.3462436,
        store_longitude: -80.5719164,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3488573,
        store_longitude: -80.5809044,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.0816886,
        store_longitude: -80.6979287,
    },
    {
        store_latitdue: -1.3616549,
        store_longitude: -80.5966081,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.6351119,
        store_longitude: -80.8257862,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.60905,
        store_longitude: -80.833,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.5627918,
        store_longitude: -80.8053145,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.357226,
        store_longitude: -80.5905956,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3489064,
        store_longitude: -80.57493,
    },
    {
        store_latitdue: -0.949413,
        store_longitude: -80.7297,
    },
    {
        store_latitdue: -0.9557214,
        store_longitude: -80.740747,
    },
    {
        store_latitdue: -0.999587,
        store_longitude: -80.6975571,
    },
    {
        store_latitdue: -0.946279,
        store_longitude: -80.732928,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.946496,
        store_longitude: -80.7425,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9616091,
        store_longitude: -80.7673108,
    },
    {
        store_latitdue: -0.9564426,
        store_longitude: -80.763547,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9581431,
        store_longitude: -80.7402367,
    },
    {
        store_latitdue: -0.9612577,
        store_longitude: -80.8174307,
    },
    {
        store_latitdue: -0.9579629,
        store_longitude: -80.7392323,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.121096,
        store_longitude: -80.8868428,
    },
    {
        store_latitdue: -0.957502,
        store_longitude: -80.7454,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9582123,
        store_longitude: -80.742793,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.051447,
        store_longitude: -80.837197,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.943154,
        store_longitude: -80.7356,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.973585,
        store_longitude: -80.8313033,
        neighborhood: 'SANTA MARIANITA',
    },
    {
        store_latitdue: -1.09605,
        store_longitude: -80.8978,
    },
    {
        store_latitdue: -0.945397,
        store_longitude: -80.7525,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.945275,
        store_longitude: -80.7432,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.092534,
        store_longitude: -80.898583,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.956389,
        store_longitude: -80.7474551,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9708391,
        store_longitude: -80.8093992,
    },
    {
        store_latitdue: -0.949618,
        store_longitude: -80.7264,
    },
    {
        store_latitdue: -0.9583106,
        store_longitude: -80.7308178,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.945835,
        store_longitude: -80.7364955,
    },
    {
        store_latitdue: -0.9467215,
        store_longitude: -80.7365704,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9761826,
        store_longitude: -80.7207229,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.976874,
        store_longitude: -80.711,
    },
    {
        store_latitdue: -0.9784743,
        store_longitude: -80.7133546,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.951360963,
        store_longitude: -80.74262991,
    },
    {
        store_latitdue: -0.9656482,
        store_longitude: -80.7295885,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.955244,
        store_longitude: -80.7417,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9477581,
        store_longitude: -80.7366724,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9836215,
        store_longitude: -80.7261948,
    },
    {
        store_latitdue: -0.95477,
        store_longitude: -80.735,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.984984,
        store_longitude: -80.7006,
    },
    {
        store_latitdue: -0.950743,
        store_longitude: -80.7213,
    },
    {
        store_latitdue: -0.9506077,
        store_longitude: -80.7217576,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9547273,
        store_longitude: -80.7345961,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.948407,
        store_longitude: -80.6413,
    },
    {
        store_latitdue: -0.951707,
        store_longitude: -80.7318,
    },
    {
        store_latitdue: -0.952891,
        store_longitude: -80.7296,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95507,
        store_longitude: -80.7356,
    },
    {
        store_latitdue: -0.947989,
        store_longitude: -80.735949,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9535922,
        store_longitude: -80.7278194,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.954691,
        store_longitude: -80.7287,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.953687,
        store_longitude: -80.7276,
    },
    {
        store_latitdue: -0.95553,
        store_longitude: -80.73028,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9524503,
        store_longitude: -80.7290675,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9461337,
        store_longitude: -80.7353306,
    },
    {
        store_latitdue: -0.9472891,
        store_longitude: -80.7238017,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9463653,
        store_longitude: -80.7341175,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9688925,
        store_longitude: -80.721589,
    },
    {
        store_latitdue: -0.9674598,
        store_longitude: -80.7271213,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9988858,
        store_longitude: -80.6985509,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0448697,
        store_longitude: -80.6565527,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.02444,
        store_longitude: -80.686,
    },
    {
        store_latitdue: -1.02352,
        store_longitude: -80.6798,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9449446,
        store_longitude: -80.7490726,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.343642,
        store_longitude: -80.576344,
    },
    {
        store_latitdue: -1.55387,
        store_longitude: -80.4328,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.26429,
        store_longitude: -80.5854,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.35783,
        store_longitude: -80.5756,
    },
    {
        store_latitdue: -1.55932895,
        store_longitude: -80.8054139,
    },
    {
        store_latitdue: -1.3500428,
        store_longitude: -80.7370372,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.30864,
        store_longitude: -80.763,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -0.9822469,
        store_longitude: -80.7153616,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.982072,
        store_longitude: -80.7153,
    },
    {
        store_latitdue: -0.96136853,
        store_longitude: -80.7561421,
    },
    {
        store_latitdue: -0.9610465,
        store_longitude: -80.8115873,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.958102,
        store_longitude: -80.7455,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9568792,
        store_longitude: -80.746088,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.947417286,
        store_longitude: -80.74259154,
    },
    {
        store_latitdue: -0.9591083,
        store_longitude: -80.8143517,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9650236,
        store_longitude: -80.7545302,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.003934,
        store_longitude: -80.851896,
        neighborhood: 'SANTA MARIANITA',
    },
    {
        store_latitdue: -0.9875456,
        store_longitude: -80.8475552,
        neighborhood: 'SANTA MARIANITA',
    },
    {
        store_latitdue: -0.945826,
        store_longitude: -80.749,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9811222,
        store_longitude: -80.7139008,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9768271,
        store_longitude: -80.7134287,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9788537,
        store_longitude: -80.7188684,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.982174,
        store_longitude: -80.7234287,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9489925,
        store_longitude: -80.7346343,
    },
    {
        store_latitdue: -0.9526782,
        store_longitude: -80.7288238,
    },
    {
        store_latitdue: -0.9842698,
        store_longitude: -80.7224625,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.94815,
        store_longitude: -80.7336,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9801718,
        store_longitude: -80.7256321,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.04331,
        store_longitude: -80.6593,
    },
    {
        store_latitdue: -0.996287,
        store_longitude: -80.7068,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00014,
        store_longitude: -80.7115,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3471553,
        store_longitude: -80.736144,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -0.9874548,
        store_longitude: -80.7097044,
    },
    {
        store_latitdue: -0.943855,
        store_longitude: -80.7367,
    },
    {
        store_latitdue: -0.960021,
        store_longitude: -80.8117,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9878361,
        store_longitude: -80.8476411,
    },
    {
        store_latitdue: -0.9617181,
        store_longitude: -80.8104541,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.947774,
        store_longitude: -80.7326,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9577204,
        store_longitude: -80.7398864,
    },
    {
        store_latitdue: -0.9529387,
        store_longitude: -80.7342525,
    },
    {
        store_latitdue: -0.9542782,
        store_longitude: -80.7313347,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9532169,
        store_longitude: -80.731968,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.95356,
        store_longitude: -80.7413013,
    },
    {
        store_latitdue: -0.9534218,
        store_longitude: -80.7413558,
    },
    {
        store_latitdue: -0.99828,
        store_longitude: -80.699,
    },
    {
        store_latitdue: -1.0221664,
        store_longitude: -80.6832132,
    },
    {
        store_latitdue: -0.9600267,
        store_longitude: -80.8177683,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0001526,
        store_longitude: -80.7378134,
    },
    {
        store_latitdue: -0.9541713,
        store_longitude: -80.7246408,
    },
    {
        store_latitdue: -1.001126893,
        store_longitude: -80.73726513,
    },
    {
        store_latitdue: -0.9519383,
        store_longitude: -80.7311967,
    },
    {
        store_latitdue: -0.9678973,
        store_longitude: -80.7272369,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.953712307,
        store_longitude: -80.74135335,
    },
    {
        store_latitdue: -0.9792034,
        store_longitude: -80.7268037,
    },
    {
        store_latitdue: -0.99866,
        store_longitude: -80.6897,
    },
    {
        store_latitdue: -0.9873183,
        store_longitude: -80.7094534,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0316676,
        store_longitude: -80.6951044,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.55475,
        store_longitude: -80.4289,
    },
    {
        store_latitdue: -1.34185,
        store_longitude: -80.5772,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3507927,
        store_longitude: -80.5793109,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.5625628,
        store_longitude: -80.8168166,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.35134,
        store_longitude: -80.58,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.98212,
        store_longitude: -80.7118,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.6001728,
        store_longitude: -80.4058214,
        neighborhood: 'CAMPOZANO',
    },
    {
        store_latitdue: -1.60542,
        store_longitude: -80.4219,
        neighborhood: 'CAMPOZANO',
    },
    {
        store_latitdue: -1.0435782,
        store_longitude: -80.6608472,
    },
    {
        store_latitdue: -1.5818898,
        store_longitude: -80.4649372,
    },
    {
        store_latitdue: -0.996569,
        store_longitude: -80.6994,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.33867,
        store_longitude: -80.5763,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.9531205,
        store_longitude: -80.7285857,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.349819,
        store_longitude: -80.578246,
    },
    {
        store_latitdue: -0.9464521,
        store_longitude: -80.7277734,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.34827,
        store_longitude: -80.5765,
    },
    {
        store_latitdue: -0.94776,
        store_longitude: -80.7326,
    },
    {
        store_latitdue: -0.9481862,
        store_longitude: -80.7308541,
    },
    {
        store_latitdue: -1.5904,
        store_longitude: -80.537523,
    },
    {
        store_latitdue: -0.951154,
        store_longitude: -80.7243,
    },
    {
        store_latitdue: -0.960184,
        store_longitude: -80.728379,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9611517,
        store_longitude: -80.7279309,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9970968,
        store_longitude: -80.7604612,
    },
    {
        store_latitdue: -1.65232,
        store_longitude: -80.821781,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -0.990708,
        store_longitude: -80.7546297,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9610486,
        store_longitude: -80.7404182,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.997181,
        store_longitude: -80.7609,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.359451,
        store_longitude: -80.73775,
    },
    {
        store_latitdue: -1.3574735,
        store_longitude: -80.738033,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -0.967009,
        store_longitude: -80.7352,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0687202,
        store_longitude: -80.9074294,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.9592566,
        store_longitude: -80.8107465,
    },
    {
        store_latitdue: -0.9593706,
        store_longitude: -80.8109879,
    },
    {
        store_latitdue: -1.108538,
        store_longitude: -80.894094,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -1.091686,
        store_longitude: -80.897452,
    },
    {
        store_latitdue: -1.0839718,
        store_longitude: -80.8999333,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.9587831,
        store_longitude: -80.7440429,
    },
    {
        store_latitdue: -0.9587831,
        store_longitude: -80.7440429,
    },
    {
        store_latitdue: -0.957547,
        store_longitude: -80.7523146,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9896387,
        store_longitude: -80.6942376,
    },
    {
        store_latitdue: -0.94882,
        store_longitude: -80.7319,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.973276,
        store_longitude: -80.7121,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.945472,
        store_longitude: -80.732,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.3481539,
        store_longitude: -80.5763466,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -0.9522354,
        store_longitude: -80.724932,
    },
    {
        store_latitdue: -0.9493772,
        store_longitude: -80.7263957,
    },
    {
        store_latitdue: -0.9506722,
        store_longitude: -80.7235066,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.961145,
        store_longitude: -80.728,
    },
    {
        store_latitdue: -1.6775116,
        store_longitude: -80.8132114,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -0.9721124,
        store_longitude: -80.7225137,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9698988,
        store_longitude: -80.7259574,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.963218,
        store_longitude: -80.7298289,
    },
    {
        store_latitdue: -2.0853,
        store_longitude: -79.9732,
    },
    {
        store_latitdue: -0.9993321,
        store_longitude: -80.7236098,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9847118,
        store_longitude: -80.8462695,
        neighborhood: 'SANTA MARIANITA',
    },
    {
        store_latitdue: -1.066512,
        store_longitude: -80.905213,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -1.0561,
        store_longitude: -80.4852,
    },
    {
        store_latitdue: -1.0001,
        store_longitude: -80.7087,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -1.03344,
        store_longitude: -80.8853,
        neighborhood: 'SANTA MARIANITA',
    },
    {
        store_latitdue: -0.994366,
        store_longitude: -80.6959,
    },
    {
        store_latitdue: -0.9559964,
        store_longitude: -80.7345747,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.950375,
        store_longitude: -80.7307,
    },
    {
        store_latitdue: -0.949651,
        store_longitude: -80.7316,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9465139,
        store_longitude: -80.7289683,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9524194,
        store_longitude: -80.7327761,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.94949,
        store_longitude: -80.7269,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.949512987,
        store_longitude: -80.72979921,
    },
    {
        store_latitdue: -0.9695324,
        store_longitude: -80.7234966,
    },
    {
        store_latitdue: -1.3516671,
        store_longitude: -80.7381113,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.56158,
        store_longitude: -80.8133,
    },
    {
        store_latitdue: -0.955101,
        store_longitude: -80.735,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9965967,
        store_longitude: -80.6923325,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.9638708,
        store_longitude: -80.7465169,
    },
    {
        store_latitdue: -0.943219,
        store_longitude: -80.7299,
    },
    {
        store_latitdue: -1.07734,
        store_longitude: -80.6698,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.34788,
        store_longitude: -80.5763,
    },
    {
        store_latitdue: -0.9512314,
        store_longitude: -80.7328086,
    },
    {
        store_latitdue: -0.949935,
        store_longitude: -80.7241,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9529404,
        store_longitude: -80.7268214,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.3615245,
        store_longitude: -80.5964084,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.549699,
        store_longitude: -80.4288501,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -0.96258342,
        store_longitude: -80.7331314,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.55809,
        store_longitude: -80.8103,
    },
    {
        store_latitdue: -0.9986799,
        store_longitude: -80.7609943,
    },
    {
        store_latitdue: -0.9664178,
        store_longitude: -80.7209219,
    },
    {
        store_latitdue: -0.979589,
        store_longitude: -80.7256,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9565683,
        store_longitude: -80.8112917,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.961357,
        store_longitude: -80.8174,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.98596681,
        store_longitude: -80.7269017,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.955974,
        store_longitude: -80.745,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.996507,
        store_longitude: -80.6991,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3486752,
        store_longitude: -80.5805725,
    },
    {
        store_latitdue: -0.94683,
        store_longitude: -80.7263,
    },
    {
        store_latitdue: -1.10903,
        store_longitude: -80.5837,
    },
    {
        store_latitdue: -1.5552533,
        store_longitude: -80.4267236,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.54651,
        store_longitude: -80.4287,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.4844007,
        store_longitude: -80.7755628,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -0.9661432,
        store_longitude: -80.7589069,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9564405,
        store_longitude: -80.811472,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9578339,
        store_longitude: -80.8111929,
    },
    {
        store_latitdue: -1.065937,
        store_longitude: -80.904215,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.9458223,
        store_longitude: -80.7254692,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.4644665,
        store_longitude: -80.4564186,
        neighborhood: 'CANOA',
    },
    {
        store_latitdue: -1.55467,
        store_longitude: -80.433,
    },
    {
        store_latitdue: -0.952129,
        store_longitude: -80.7294,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9514002,
        store_longitude: -80.7222345,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.848111,
        store_longitude: -80.156265,
    },
    {
        store_latitdue: -0.96062,
        store_longitude: -80.7458,
    },
    {
        store_latitdue: -0.9442236,
        store_longitude: -80.7285878,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.1070957,
        store_longitude: -80.584239,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -1.6654097,
        store_longitude: -80.4591364,
    },
    {
        store_latitdue: -0.9473822,
        store_longitude: -80.7337869,
    },
    {
        store_latitdue: -1.663857335,
        store_longitude: -80.45914209,
    },
    {
        store_latitdue: -0.960782,
        store_longitude: -80.73,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.563104,
        store_longitude: -80.804777,
    },
    {
        store_latitdue: -1.25788,
        store_longitude: -80.5853,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.3603943,
        store_longitude: -80.5835566,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.5909986,
        store_longitude: -80.8426013,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -0.958036,
        store_longitude: -80.7299,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0849024,
        store_longitude: -80.8994403,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -1.0842444,
        store_longitude: -80.8993985,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.9582399,
        store_longitude: -80.7344105,
    },
    {
        store_latitdue: -0.9510155,
        store_longitude: -80.7212863,
    },
    {
        store_latitdue: -1.59222,
        store_longitude: -80.8404,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.124499,
        store_longitude: -80.886298,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -1.0306948,
        store_longitude: -80.6950004,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -0.956748,
        store_longitude: -80.7346,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.950411,
        store_longitude: -80.7291,
    },
    {
        store_latitdue: -0.96207207,
        store_longitude: -80.7573471,
    },
    {
        store_latitdue: -1.55828,
        store_longitude: -80.8035,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5545505,
        store_longitude: -80.4269184,
    },
    {
        store_latitdue: -1.56387,
        store_longitude: -80.7989,
    },
    {
        store_latitdue: -0.9540459,
        store_longitude: -80.7360287,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9448104,
        store_longitude: -80.733424,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9701568,
        store_longitude: -80.7240306,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.995384,
        store_longitude: -80.6941,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.35358,
        store_longitude: -80.5781,
    },
    {
        store_latitdue: -0.955325,
        store_longitude: -80.7361,
    },
    {
        store_latitdue: -1.0014088,
        store_longitude: -80.7415499,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9680292,
        store_longitude: -80.7230595,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9801477,
        store_longitude: -80.7223215,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.988441548,
        store_longitude: -80.73589682,
    },
    {
        store_latitdue: -1.0023972,
        store_longitude: -80.7285093,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0020076,
        store_longitude: -80.7242614,
    },
    {
        store_latitdue: -1.039696389,
        store_longitude: -80.66426844,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.5893027,
        store_longitude: -80.8422579,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -0.9468489,
        store_longitude: -80.7345954,
    },
    {
        store_latitdue: -0.9852106,
        store_longitude: -80.7240545,
    },
    {
        store_latitdue: -1.3565358,
        store_longitude: -80.5882131,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.56363,
        store_longitude: -80.8122,
    },
    {
        store_latitdue: -1.5626104,
        store_longitude: -80.8108738,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.9425047,
        store_longitude: -80.7352347,
    },
    {
        store_latitdue: -1.20649,
        store_longitude: -80.5805,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.56154,
        store_longitude: -80.8137,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.946821,
        store_longitude: -80.7356899,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.3454097,
        store_longitude: -80.5820206,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.082745,
        store_longitude: -80.6797847,
    },
    {
        store_latitdue: -1.56016016,
        store_longitude: -80.812004,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.97979,
        store_longitude: -80.7455,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.56185,
        store_longitude: -80.8064,
    },
    {
        store_latitdue: -1.556723,
        store_longitude: -80.4297674,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -0.946228,
        store_longitude: -80.730703,
    },
    {
        store_latitdue: -0.9768432,
        store_longitude: -80.8117163,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.962487,
        store_longitude: -80.81,
    },
    {
        store_latitdue: -0.951001,
        store_longitude: -80.7222228,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.98494011,
        store_longitude: -80.7229232,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9623782,
        store_longitude: -80.7300043,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9989271,
        store_longitude: -80.7119552,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0106541,
        store_longitude: -80.7135305,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.5484349,
        store_longitude: -80.4269268,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.3510036,
        store_longitude: -80.7377938,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -1.5624125,
        store_longitude: -80.8147239,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.9562348,
        store_longitude: -80.7430079,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9518836,
        store_longitude: -80.7113828,
    },
    {
        store_latitdue: -0.9575095,
        store_longitude: -80.746436,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9725853,
        store_longitude: -80.7226098,
    },
    {
        store_latitdue: -0.949632,
        store_longitude: -80.7269,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.04453,
        store_longitude: -80.6596,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.5628,
        store_longitude: -80.8159,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.3462745,
        store_longitude: -80.578866,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.60487,
        store_longitude: -80.4209,
        neighborhood: 'CAMPOZANO',
    },
    {
        store_latitdue: -0.968865,
        store_longitude: -80.7569,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9895661,
        store_longitude: -80.6986957,
    },
    {
        store_latitdue: -2.193847,
        store_longitude: -79.890195,
    },
    {
        store_latitdue: -1.562854,
        store_longitude: -80.812444,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.55829453,
        store_longitude: -80.8119735,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.357522,
        store_longitude: -80.738273,
        neighborhood: 'PUERTO CAYO',
    },
    {
        store_latitdue: -0.9504788,
        store_longitude: -80.7261625,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.00157,
        store_longitude: -80.7141,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.3592856,
        store_longitude: -80.5955762,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.67555,
        store_longitude: -80.8126,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -0.9448893,
        store_longitude: -80.7327653,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.345587176,
        store_longitude: -80.57612051,
    },
    {
        store_latitdue: -1.3468214,
        store_longitude: -80.5771192,
    },
    {
        store_latitdue: -1.5541018,
        store_longitude: -80.4274286,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.0509413,
        store_longitude: -80.658478,
    },
    {
        store_latitdue: -1.553352,
        store_longitude: -80.4292535,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.0668138,
        store_longitude: -80.8522078,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9729552,
        store_longitude: -80.808811,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9510145,
        store_longitude: -80.7346659,
    },
    {
        store_latitdue: -0.9508738,
        store_longitude: -80.7348259,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9519943,
        store_longitude: -80.7236821,
    },
    {
        store_latitdue: -1.04986602,
        store_longitude: -80.66249054,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0063858,
        store_longitude: -80.7110041,
    },
    {
        store_latitdue: -1.2603254,
        store_longitude: -80.5799474,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.5561402,
        store_longitude: -80.4280261,
    },
    {
        store_latitdue: -1.5603122,
        store_longitude: -80.8138647,
    },
    {
        store_latitdue: -1.4713773,
        store_longitude: -80.5349073,
    },
    {
        store_latitdue: -0.9794857,
        store_longitude: -80.7140275,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.5573184,
        store_longitude: -80.4304724,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -0.951331,
        store_longitude: -80.7278,
    },
    {
        store_latitdue: -1.5527469,
        store_longitude: -80.8091915,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.956447,
        store_longitude: -80.7487095,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9491905,
        store_longitude: -80.7364463,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.96061527,
        store_longitude: -80.8110046,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.993896,
        store_longitude: -80.7057,
    },
    {
        store_latitdue: -1.35721,
        store_longitude: -80.5899,
    },
    {
        store_latitdue: -1.0666997,
        store_longitude: -80.9084048,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.9484525,
        store_longitude: -80.7305464,
    },
    {
        store_latitdue: -0.9821585,
        store_longitude: -80.7252791,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9468489,
        store_longitude: -80.7345954,
    },
    {
        store_latitdue: -1.5923809,
        store_longitude: -80.8429531,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -0.9458272,
        store_longitude: -80.7269416,
        neighborhood: 'MANTA',
    },

    {
        store_latitdue: -1.55604,
        store_longitude: -80.4258122,
        neighborhood: 'PAJÁN',
    },
    {
        store_latitdue: -1.1093116,
        store_longitude: -80.5835609,
        neighborhood: 'LA PILA',
    },
    {
        store_latitdue: -0.9518762,
        store_longitude: -80.7251125,
    },
    {
        store_latitdue: -0.9959533,
        store_longitude: -80.6940194,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.0826503,
        store_longitude: -80.6815533,
    },
    {
        store_latitdue: -1.3442946,
        store_longitude: -80.5789398,
    },
    {
        store_latitdue: -1.343367,
        store_longitude: -80.580373,
        neighborhood: 'JIPIJAPA',
    },
    {
        store_latitdue: -1.0643546,
        store_longitude: -80.9066471,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.9967655,
        store_longitude: -80.7422989,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.9873104,
        store_longitude: -80.7256726,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -1.0500527,
        store_longitude: -80.6606801,
    },
    {
        store_latitdue: -0.946228,
        store_longitude: -80.730703,
    },
    {
        store_latitdue: -1.55923205,
        store_longitude: -80.8107801,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.9828089,
        store_longitude: -80.7208632,
        neighborhood: 'MANTA',
    },
    {
        store_latitdue: -0.977719,
        store_longitude: -80.723,
    },
    {
        store_latitdue: -1.346296075,
        store_longitude: -80.5804564,
    },
    {
        store_latitdue: -0.9839685,
        store_longitude: -80.7128072,
    },
    {
        store_latitdue: -1.0129991,
        store_longitude: -80.6842567,
    },
    {
        store_latitdue: -1.0821783,
        store_longitude: -80.6802519,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.553713,
        store_longitude: -80.811474,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5596518,
        store_longitude: -80.8127652,
    },
    {
        store_latitdue: -1.4026941,
        store_longitude: -80.7542936,
        neighborhood: 'MACHALILLA',
    },
    {
        store_latitdue: -1.6722912,
        store_longitude: -80.8092161,
        neighborhood: 'SALANGO',
    },
    {
        store_latitdue: -1.54766,
        store_longitude: -80.8102,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.5584415,
        store_longitude: -80.812801,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -0.997286771,
        store_longitude: -80.69489851,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.55338925,
        store_longitude: -80.42446898,
    },
    {
        store_latitdue: -0.9470155,
        store_longitude: -80.7357301,
    },
    {
        store_latitdue: -1.00310953,
        store_longitude: -80.6882827,
    },
    {
        store_latitdue: -0.9402223,
        store_longitude: -80.7318832,
    },
    {
        store_latitdue: -1.0769898,
        store_longitude: -80.6985099,
        neighborhood: 'MONTECRISTI',
    },
    {
        store_latitdue: -1.5578676,
        store_longitude: -80.812803,
        neighborhood: 'PUERTO LÓPEZ',
    },
    {
        store_latitdue: -1.00238,
        store_longitude: -80.716252,
    },
    {
        store_latitdue: -1.47797,
        store_longitude: -80.767,
        neighborhood: 'MACHALILLA',
    },

    {
        store_latitdue: -0.211567,
        store_longitude: -78.511329,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21038,
        store_longitude: -78.510115,
    },
    {
        store_latitdue: -0.210177,
        store_longitude: -78.51269,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.104414,
        store_longitude: -78.4946,
    },
    {
        store_latitdue: -0.188404,
        store_longitude: -78.514809,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0915391,
        store_longitude: -78.5061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143788,
        store_longitude: -78.517777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0110972,
        store_longitude: -78.4475914,
    },
    {
        store_latitdue: -0.0908663,
        store_longitude: -78.5227204,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1692263,
        store_longitude: -78.4879926,
    },
    {
        store_latitdue: -0.1240995,
        store_longitude: -78.5053038,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0616551,
        store_longitude: -78.4570177,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2213767,
        store_longitude: -78.5163806,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.227922,
        store_longitude: -78.507639,
    },
    {
        store_latitdue: -0.12212,
        store_longitude: -78.5142,
    },
    {
        store_latitdue: -0.124505,
        store_longitude: -78.50858,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.055471,
        store_longitude: -78.455,
    },
    {
        store_latitdue: -0.0063834,
        store_longitude: -78.4444237,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1794225,
        store_longitude: -78.5137905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1903713,
        store_longitude: -78.5099039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.064765,
        store_longitude: -78.461252,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.3051062,
        store_longitude: -78.5402529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.798296,
        store_longitude: -80.128745,
    },
    {
        store_latitdue: -0.083566,
        store_longitude: -78.504601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0827865,
        store_longitude: -78.502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0725751,
        store_longitude: -78.4799,
    },
    {
        store_latitdue: -0.063172,
        store_longitude: -78.471199,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0036484,
        store_longitude: -78.4395,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.12404173146933,
        store_longitude: -78.469874197617,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143227,
        store_longitude: -78.5081,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.011234,
        store_longitude: -78.4425,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.2074278,
        store_longitude: -78.5265078,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096962,
        store_longitude: -78.419932,
    },
    {
        store_latitdue: -0.059269,
        store_longitude: -78.4620012,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0009539,
        store_longitude: -78.4445,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1089983,
        store_longitude: -78.4545798,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.200055,
        store_longitude: -78.499234,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1885987,
        store_longitude: -78.5125674,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127448,
        store_longitude: -78.511869,
    },
    {
        store_latitdue: -0.181907,
        store_longitude: -78.5161,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852123,
        store_longitude: -78.5159451,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0909663,
        store_longitude: -78.5222315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.097206666666667,
        store_longitude: -78.518011666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118144,
        store_longitude: -78.5123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0905465,
        store_longitude: -78.5203951,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1281933,
        store_longitude: -78.4635375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0555807,
        store_longitude: -78.4550127,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.104025,
        store_longitude: -78.4914,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.185479,
        store_longitude: -78.495375,
    },
    {
        store_latitdue: -0.2198592,
        store_longitude: -78.5116963,
    },
    {
        store_latitdue: -0.0809999,
        store_longitude: -78.5027292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0904377,
        store_longitude: -78.520517,
    },
    {
        store_latitdue: -0.084351666666667,
        store_longitude: -78.505466666667,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2143106,
        store_longitude: -78.5213558,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0903481,
        store_longitude: -78.5128509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.192861,
        store_longitude: -78.5062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205165,
        store_longitude: -78.5093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1010765,
        store_longitude: -78.5066518,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.100239,
        store_longitude: -78.5042,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0273759,
        store_longitude: -78.438,
    },
    {
        store_latitdue: -0.21536,
        store_longitude: -78.5194,
    },
    {
        store_latitdue: -0.20637,
        store_longitude: -78.5016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.133635,
        store_longitude: -78.5005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0903589,
        store_longitude: -78.5283932,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0817,
        store_longitude: -78.50404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0393436,
        store_longitude: -78.4431136,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2255645,
        store_longitude: -78.5117086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18660864,
        store_longitude: -78.50989648,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1757744,
        store_longitude: -78.4975486,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1186712,
        store_longitude: -78.4940498,
    },
    {
        store_latitdue: -0.1220748,
        store_longitude: -78.5200376,
    },
    {
        store_latitdue: -0.10996666666667,
        store_longitude: -78.489486666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.158258,
        store_longitude: -78.4882,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129137,
        store_longitude: -78.5146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0892631,
        store_longitude: -78.5143,
    },
    {
        store_latitdue: -0.1262042,
        store_longitude: -78.5130145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1234646,
        store_longitude: -78.51057684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103099,
        store_longitude: -78.510098,
    },
    {
        store_latitdue: -0.07414,
        store_longitude: -78.479695,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126896,
        store_longitude: -78.5107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0963705,
        store_longitude: -78.4992,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128598,
        store_longitude: -78.4954,
    },
    {
        store_latitdue: -0.096241,
        store_longitude: -78.515424,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1156294,
        store_longitude: -78.5011465,
    },
    {
        store_latitdue: -0.326288,
        store_longitude: -78.4758,
    },
    {
        store_latitdue: -0.1173872,
        store_longitude: -78.5035239,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.122345,
        store_longitude: -78.5201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216895,
        store_longitude: -78.519089,
    },
    {
        store_latitdue: -0.2259359,
        store_longitude: -78.5113938,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2063705,
        store_longitude: -78.5071617,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1125455,
        store_longitude: -78.4462203,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.001172,
        store_longitude: -78.449499,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0920066,
        store_longitude: -78.5136836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0997403,
        store_longitude: -78.4834156,
    },
    {
        store_latitdue: -0.118729,
        store_longitude: -78.516,
    },
    {
        store_latitdue: -0.0141523,
        store_longitude: -78.4496,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2135101,
        store_longitude: -78.5044853,
    },
    {
        store_latitdue: -0.212378,
        store_longitude: -78.512382,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095868,
        store_longitude: -78.509056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0928552,
        store_longitude: -78.4988,
    },
    {
        store_latitdue: -0.5092677,
        store_longitude: -78.5092677,
    },
    {
        store_latitdue: -0.18486333333333,
        store_longitude: -78.492683333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1426956,
        store_longitude: -78.4928641,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1923319,
        store_longitude: -78.5083642,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2194828,
        store_longitude: -78.5081205,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0846407,
        store_longitude: -78.5136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0949666,
        store_longitude: -78.5081719,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0730661,
        store_longitude: -78.4832,
    },
    {
        store_latitdue: -0.0014886,
        store_longitude: -78.4464,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0948065,
        store_longitude: -78.5065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0968021,
        store_longitude: -78.5088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116618,
        store_longitude: -78.502575,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.010638,
        store_longitude: -78.443199,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.00117012,
        store_longitude: -78.44306764,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2244359,
        store_longitude: -78.5081657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129086,
        store_longitude: -78.509,
    },
    {
        store_latitdue: -0.148111,
        store_longitude: -78.506,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093808,
        store_longitude: -78.498016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0072371,
        store_longitude: -78.4447566,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0082045,
        store_longitude: -78.4472,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1461146,
        store_longitude: -78.4931639,
    },
    {
        store_latitdue: -0.083912,
        store_longitude: -78.505242,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0808978,
        store_longitude: -78.5038677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.316885,
        store_longitude: -78.552002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1262405,
        store_longitude: -78.465648,
    },
    {
        store_latitdue: -0.082405,
        store_longitude: -78.505122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1249929,
        store_longitude: -78.5228089,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0796946,
        store_longitude: -78.4105708,
    },
    {
        store_latitdue: -0.209635,
        store_longitude: -78.510605,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0949567,
        store_longitude: -78.5000607,
    },
    {
        store_latitdue: -0.082958,
        store_longitude: -78.505347,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0011918,
        store_longitude: -78.514409,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.000822,
        store_longitude: -78.515363,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: 0.000121,
        store_longitude: -78.5156,
    },
    {
        store_latitdue: 0.000526,
        store_longitude: -78.5136,
    },
    {
        store_latitdue: -0.0058711,
        store_longitude: -78.5132278,
    },
    {
        store_latitdue: -0.0386681,
        store_longitude: -78.442361,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.042967,
        store_longitude: -78.444753,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0068157,
        store_longitude: -78.4418934,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.000114,
        store_longitude: -78.512276,
    },
    {
        store_latitdue: -0.080422,
        store_longitude: -78.502265,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084313,
        store_longitude: -78.50379,
    },
    {
        store_latitdue: -0.08294,
        store_longitude: -78.5038,
    },
    {
        store_latitdue: -0.0834446,
        store_longitude: -78.5073443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2081719,
        store_longitude: -78.5123726,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117158808,
        store_longitude: -78.50336551,
    },
    {
        store_latitdue: -0.0954404,
        store_longitude: -78.5185197,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126389,
        store_longitude: -78.522959,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0681806,
        store_longitude: -78.510658,
    },
    {
        store_latitdue: -0.0933408,
        store_longitude: -78.5028774,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.000414,
        store_longitude: -78.513901,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0826571,
        store_longitude: -78.5058,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0538565,
        store_longitude: -78.4548926,
    },
    {
        store_latitdue: -0.0069912,
        store_longitude: -78.5135,
    },
    {
        store_latitdue: -0.094368,
        store_longitude: -78.5044,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0168995,
        store_longitude: -78.4749317,
    },
    {
        store_latitdue: 0.0137104,
        store_longitude: -78.4387,
    },
    {
        store_latitdue: -0.00077,
        store_longitude: -78.5168,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0944324,
        store_longitude: -78.5067,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123375,
        store_longitude: -78.5022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0722774,
        store_longitude: -78.4717134,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.095777,
        store_longitude: -78.514185,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213488,
        store_longitude: -78.5047,
    },

    {
        store_latitdue: -0.22155833333333,
        store_longitude: -78.516258333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216842,
        store_longitude: -78.5226,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187454,
        store_longitude: -78.505399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115313,
        store_longitude: -78.496003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1221427,
        store_longitude: -78.5106159,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0018313,
        store_longitude: -78.4431,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2157514,
        store_longitude: -78.509015,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.058583,
        store_longitude: -78.345703,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.083778333333333,
        store_longitude: -78.509725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0176747,
        store_longitude: -78.475,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.20992,
        store_longitude: -78.5116,
    },
    {
        store_latitdue: -0.1138246,
        store_longitude: -78.5063509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.087475,
        store_longitude: -78.5114,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218225,
        store_longitude: -78.5104,
    },
    {
        store_latitdue: 0.012906,
        store_longitude: -78.477118,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1563685,
        store_longitude: -78.5039015,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120862,
        store_longitude: -78.5066,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0960172,
        store_longitude: -78.5278879,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102956,
        store_longitude: -78.513,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0887598,
        store_longitude: -78.5054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0633402,
        store_longitude: -78.5192757,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.0974254,
        store_longitude: -78.5190689,
    },
    {
        store_latitdue: -0.095815,
        store_longitude: -78.527199,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0001479,
        store_longitude: -78.5142,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.1146167,
        store_longitude: -78.495781,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0537927,
        store_longitude: -78.4546413,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0143291,
        store_longitude: -78.4749,
    },
    {
        store_latitdue: 0.0109944,
        store_longitude: -78.4781488,
    },
    {
        store_latitdue: -0.05739,
        store_longitude: -78.4579686,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.088617,
        store_longitude: -78.5160466,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21789,
        store_longitude: -78.5108,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.183591,
        store_longitude: -78.5102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092179,
        store_longitude: -78.49956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090983,
        store_longitude: -78.521537,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0001935,
        store_longitude: -78.4514,
    },
    {
        store_latitdue: -0.2076272,
        store_longitude: -78.5189786,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.203119,
        store_longitude: -78.504,
    },
    {
        store_latitdue: -0.130681,
        store_longitude: -78.498064,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0898435,
        store_longitude: -78.5155309,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123365,
        store_longitude: -78.52065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.099215,
        store_longitude: -78.520496666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2119456,
        store_longitude: -78.5128296,
    },
    {
        store_latitdue: -0.126596,
        store_longitude: -78.4959,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17293758735676,
        store_longitude: -78.496609357825,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0944779,
        store_longitude: -78.5049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.009873,
        store_longitude: -78.446413,
    },
    {
        store_latitdue: -0.0081855,
        store_longitude: -78.4468143,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.090378,
        store_longitude: -78.52024,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0813826,
        store_longitude: -78.5024779,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0072087,
        store_longitude: -78.513666,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0560126,
        store_longitude: -78.4550408,
    },
    {
        store_latitdue: -0.1939652,
        store_longitude: -78.4986906,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12923833333333,
        store_longitude: -78.500346666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142567,
        store_longitude: -78.494803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1435538,
        store_longitude: -78.4964501,
    },
    {
        store_latitdue: -0.202033,
        store_longitude: -78.499764,
    },
    {
        store_latitdue: -0.124276,
        store_longitude: -78.5021,
    },
    {
        store_latitdue: -0.0058646,
        store_longitude: -78.4441883,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2112741,
        store_longitude: -78.5198984,
    },
    {
        store_latitdue: -0.0824329,
        store_longitude: -78.5074,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142974,
        store_longitude: -78.493,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2145996,
        store_longitude: -78.504561,
    },
    {
        store_latitdue: -0.0946615,
        store_longitude: -78.5154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1169459,
        store_longitude: -78.5099978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095745,
        store_longitude: -78.518303,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0202885,
        store_longitude: -78.4370528,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.011848,
        store_longitude: -78.481763,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2092464,
        store_longitude: -78.5167959,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187134,
        store_longitude: -78.495013,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103465,
        store_longitude: -78.521563,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1291448,
        store_longitude: -78.5106606,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0121382,
        store_longitude: -78.4343679,
    },
    {
        store_latitdue: -0.2098625,
        store_longitude: -78.5224855,
    },
    {
        store_latitdue: -0.2156412,
        store_longitude: -78.5165187,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.088892,
        store_longitude: -78.52246,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0008056,
        store_longitude: -78.5145,
    },
    {
        store_latitdue: -0.2110901,
        store_longitude: -78.5199377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.202916,
        store_longitude: -78.522243,
    },
    {
        store_latitdue: -0.210045,
        store_longitude: -78.5028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2154988,
        store_longitude: -78.5099327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17715559,
        store_longitude: -78.511861,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1539267,
        store_longitude: -78.4920905,
    },
    {
        store_latitdue: -0.0933127,
        store_longitude: -78.5258,
    },
    {
        store_latitdue: -0.059526,
        store_longitude: -78.4577606,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.001079,
        store_longitude: -78.4431,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0160336,
        store_longitude: -78.4394532,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2044469,
        store_longitude: -78.5047813,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0744501,
        store_longitude: -78.4810064,
    },
    {
        store_latitdue: -0.21149,
        store_longitude: -78.5221,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123562,
        store_longitude: -78.5203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.181472,
        store_longitude: -78.5073,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140442,
        store_longitude: -78.5081,
    },
    {
        store_latitdue: -0.092664,
        store_longitude: -78.4784,
    },
    {
        store_latitdue: -0.219389,
        store_longitude: -78.5192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2136071,
        store_longitude: -78.504098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.000915,
        store_longitude: -78.5144,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0504205,
        store_longitude: -78.4485941,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.003586,
        store_longitude: -78.458145,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.216833,
        store_longitude: -78.5086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.097648,
        store_longitude: -78.50405,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105137,
        store_longitude: -78.4944,
    },
    {
        store_latitdue: -0.20393,
        store_longitude: -78.5068,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115625,
        store_longitude: -78.490713333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.006784,
        store_longitude: -78.4462,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.139293,
        store_longitude: -78.505,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214578,
        store_longitude: -78.503891,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188877,
        store_longitude: -78.5182,
    },
    {
        store_latitdue: -0.093027,
        store_longitude: -78.510031,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1186237,
        store_longitude: -78.5137619,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.011468,
        store_longitude: -78.479182,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.216659,
        store_longitude: -78.5057,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0938776,
        store_longitude: -78.5296325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0899784,
        store_longitude: -78.503188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.111298,
        store_longitude: -78.5168,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187283,
        store_longitude: -78.515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2111288,
        store_longitude: -78.5231365,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2250729,
        store_longitude: -78.4974875,
    },
    {
        store_latitdue: -0.113736,
        store_longitude: -78.492401,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0880778,
        store_longitude: -78.5101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1978437,
        store_longitude: -78.5002991,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0850146,
        store_longitude: -78.5051153,
    },
    {
        store_latitdue: -0.211763,
        store_longitude: -78.5254,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.010423,
        store_longitude: -78.444892,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0630744,
        store_longitude: -78.4686444,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.186047,
        store_longitude: -78.515597,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193271,
        store_longitude: -78.506397,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.005957,
        store_longitude: -78.449953,
    },

    {
        store_latitdue: 0.01081604976207,
        store_longitude: -78.481789324433,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1856309,
        store_longitude: -78.5077507,
    },
    {
        store_latitdue: 0.0017911,
        store_longitude: -78.4591,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.104057,
        store_longitude: -78.484,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0697989413,
        store_longitude: -78.4694849537,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.04361,
        store_longitude: -78.443398,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.220474,
        store_longitude: -78.5163,
    },
    {
        store_latitdue: -0.1902776,
        store_longitude: -78.5093868,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1848114,
        store_longitude: -78.5077575,
    },
    {
        store_latitdue: -0.1343746,
        store_longitude: -78.5008768,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0918817,
        store_longitude: -78.502419,
    },
    {
        store_latitdue: -0.092686,
        store_longitude: -78.502573,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0000124,
        store_longitude: -78.4491415,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.130242,
        store_longitude: -78.5149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0951822,
        store_longitude: -78.5123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1419766,
        store_longitude: -78.4963917,
    },
    {
        store_latitdue: -0.2031977,
        store_longitude: -78.4901728,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1171705,
        store_longitude: -78.503418,
    },
    {
        store_latitdue: -0.1417287,
        store_longitude: -78.5024186,
    },
    {
        store_latitdue: -0.1565043,
        store_longitude: -78.4909827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113607,
        store_longitude: -78.4916,
    },
    {
        store_latitdue: -0.0815028,
        store_longitude: -78.298,
    },
    {
        store_latitdue: -0.159293,
        store_longitude: -78.5003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107187,
        store_longitude: -78.4954,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115788,
        store_longitude: -78.4949,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0429682,
        store_longitude: -78.4547791,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0060027,
        store_longitude: -78.4498,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.210686,
        store_longitude: -78.5171,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21530447,
        store_longitude: -78.51901981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1547579,
        store_longitude: -78.4988321,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0013967,
        store_longitude: -78.4430841,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0600409,
        store_longitude: -78.4645,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.129122,
        store_longitude: -78.4996263,
    },
    {
        store_latitdue: -0.1386434,
        store_longitude: -78.495471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0846307,
        store_longitude: -78.5132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0991767,
        store_longitude: -78.5184,
    },
    {
        store_latitdue: -0.1236601,
        store_longitude: -78.49798,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2106634,
        store_longitude: -78.5168998,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1254073,
        store_longitude: -78.5107998,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0422307,
        store_longitude: -78.4477301,
    },
    {
        store_latitdue: -0.130339,
        store_longitude: -78.5138,
    },

    {
        store_latitdue: -0.0823255,
        store_longitude: -78.5045,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0554397,
        store_longitude: -78.4543728,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0190507,
        store_longitude: -78.4392178,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1753416,
        store_longitude: -78.5005977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0930591,
        store_longitude: -78.5028,
    },
    {
        store_latitdue: -0.2116481,
        store_longitude: -78.5035044,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1913154,
        store_longitude: -78.510281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.098333333333333,
        store_longitude: -78.489703333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1304453,
        store_longitude: -78.5151586,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0119379,
        store_longitude: -78.4461005,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.18759,
        store_longitude: -78.5073334,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1259378,
        store_longitude: -78.5105442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13015404,
        store_longitude: -78.5107904,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0598191,
        store_longitude: -78.4644996,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0078082,
        store_longitude: -78.4422,
    },
    {
        store_latitdue: -0.195798,
        store_longitude: -78.5054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1398724,
        store_longitude: -78.5005159,
    },
    {
        store_latitdue: -0.1402509,
        store_longitude: -78.50051551,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095223,
        store_longitude: -78.515055,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0078926,
        store_longitude: -78.4481,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.00751743,
        store_longitude: -78.44019613,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2070782,
        store_longitude: -78.5267488,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208791,
        store_longitude: -78.504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1249939,
        store_longitude: -78.515476,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1004689,
        store_longitude: -78.5220053,
    },
    {
        store_latitdue: -0.124057,
        store_longitude: -78.505288,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102852,
        store_longitude: -78.5131,
    },
    {
        store_latitdue: -0.3014213,
        store_longitude: -78.523064,
    },
    {
        store_latitdue: -0.0844646,
        store_longitude: -78.5064282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0035685,
        store_longitude: -78.4433384,
    },
    {
        store_latitdue: -0.2100863,
        store_longitude: -78.5027057,
    },
    {
        store_latitdue: -0.1401468,
        store_longitude: -78.5005128,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130704,
        store_longitude: -78.5137,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178601,
        store_longitude: -78.5135,
    },
    {
        store_latitdue: -0.194529,
        store_longitude: -78.510251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1299783,
        store_longitude: -78.5029462,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1396098,
        store_longitude: -78.5003594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0834519,
        store_longitude: -78.5047116,
    },
    {
        store_latitdue: -0.123811,
        store_longitude: -78.504377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085429,
        store_longitude: -78.508729,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12254999360458,
        store_longitude: -78.51051132047,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2107511,
        store_longitude: -78.5080674,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.194939,
        store_longitude: -78.5088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1853407,
        store_longitude: -78.5077159,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12207,
        store_longitude: -78.5066,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0930585,
        store_longitude: -78.5027,
    },
    {
        store_latitdue: -0.0077288,
        store_longitude: -78.4553848,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.209322,
        store_longitude: -78.510037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140987,
        store_longitude: -78.4983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130252,
        store_longitude: -78.513205,
    },
    {
        store_latitdue: -0.060279,
        store_longitude: -78.4578158,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0931197,
        store_longitude: -78.5028,
    },
    {
        store_latitdue: -0.140273,
        store_longitude: -78.5033,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14351666666667,
        store_longitude: -78.495526666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10437833333333,
        store_longitude: -78.524785,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.055688,
        store_longitude: -78.454622,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.179653,
        store_longitude: -78.5169,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1960641,
        store_longitude: -78.5061043,
    },
    {
        store_latitdue: -0.12403,
        store_longitude: -78.5065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.153183001,
        store_longitude: -78.50442609,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0939314,
        store_longitude: -78.5029951,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0061164,
        store_longitude: -78.4498301,
    },
    {
        store_latitdue: 0.0037952,
        store_longitude: -78.4545011,
    },
    {
        store_latitdue: -0.2094059,
        store_longitude: -78.5274287,
    },
    {
        store_latitdue: -0.0934881,
        store_longitude: -78.5102744,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20535929,
        store_longitude: -78.50322837,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20747,
        store_longitude: -78.51,
    },
    {
        store_latitdue: -0.1866477,
        store_longitude: -78.5082482,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1474775,
        store_longitude: -78.5054498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0947711,
        store_longitude: -78.502579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0672258,
        store_longitude: -78.465,
    },
    {
        store_latitdue: -0.042906666666667,
        store_longitude: -78.444646666667,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.209926,
        store_longitude: -78.5139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.183074,
        store_longitude: -78.511689,
    },
    {
        store_latitdue: -0.3191386,
        store_longitude: -78.4601029,
    },
    {
        store_latitdue: -0.0894958,
        store_longitude: -78.5234067,
    },
    {
        store_latitdue: -0.2106632,
        store_longitude: -78.5118211,
    },
    {
        store_latitdue: -0.138028,
        store_longitude: -78.504134,
    },
    {
        store_latitdue: -0.090647,
        store_longitude: -78.5215,
    },
    {
        store_latitdue: -0.089038,
        store_longitude: -78.52123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210238,
        store_longitude: -78.5126,
    },
    {
        store_latitdue: -0.1726125,
        store_longitude: -78.501326,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },
    {
        store_latitdue: -0.120435,
        store_longitude: -78.494,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1267267,
        store_longitude: -78.521563,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24504,
        store_longitude: -78.5225,
    },
    {
        store_latitdue: -0.0533086,
        store_longitude: -78.2821,
    },
    {
        store_latitdue: -0.1591805,
        store_longitude: -78.5031123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093034515157342,
        store_longitude: -78.523517455906,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.007798,
        store_longitude: -78.439049,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0033160904422402,
        store_longitude: -78.497928511351,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0628864,
        store_longitude: -78.4595162,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.209563,
        store_longitude: -78.504502,
    },
    {
        store_latitdue: -0.1146568,
        store_longitude: -78.4997975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1377109,
        store_longitude: -78.5104016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13040084,
        store_longitude: -78.5127448,
    },
    {
        store_latitdue: -0.11859,
        store_longitude: -78.497861666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11556,
        store_longitude: -78.510087,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.171463,
        store_longitude: -78.476,
    },
    {
        store_latitdue: -0.1060348,
        store_longitude: -78.4949543,
    },
    {
        store_latitdue: -0.0737747,
        store_longitude: -78.4784041,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0534233,
        store_longitude: -78.4532,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2118727,
        store_longitude: -78.52301162,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1591637,
        store_longitude: -78.4904222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08937421,
        store_longitude: -78.51404142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124649,
        store_longitude: -78.5208,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090448,
        store_longitude: -78.507332,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2254415,
        store_longitude: -78.5117177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210252,
        store_longitude: -78.5124,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238876,
        store_longitude: -78.5040935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0958859,
        store_longitude: -78.5142,
    },
    {
        store_latitdue: -0.113282,
        store_longitude: -78.45006,
    },
    {
        store_latitdue: -0.211473,
        store_longitude: -78.522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220534,
        store_longitude: -78.517787,
    },
    {
        store_latitdue: -0.2199006,
        store_longitude: -78.5171188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14269765,
        store_longitude: -78.49975987,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0928064,
        store_longitude: -78.523621,
    },
    {
        store_latitdue: -0.0934837,
        store_longitude: -78.5255,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0960037,
        store_longitude: -78.5274797,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113721,
        store_longitude: -78.4975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189339,
        store_longitude: -78.5129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1962231,
        store_longitude: -78.5039361,
    },
    {
        store_latitdue: -0.103237,
        store_longitude: -78.5109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0985224,
        store_longitude: -78.5019981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.224876,
        store_longitude: -78.511893,
    },
    {
        store_latitdue: -0.2242757,
        store_longitude: -78.508552,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21256818,
        store_longitude: -78.51956407,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19047,
        store_longitude: -78.513395,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188808,
        store_longitude: -78.5174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1863932,
        store_longitude: -78.51084181,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1591786,
        store_longitude: -78.5002534,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0839025,
        store_longitude: -78.5089,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1902582,
        store_longitude: -78.4962779,
    },
    {
        store_latitdue: -0.1842349,
        store_longitude: -78.5088741,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0644521,
        store_longitude: -78.4612,
    },
    {
        store_latitdue: -0.2127799,
        store_longitude: -78.52385422,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2085386,
        store_longitude: -78.5051092,
    },
    {
        store_latitdue: -0.1856876,
        store_longitude: -78.511306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1432567,
        store_longitude: -78.5066934,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093405,
        store_longitude: -78.516306666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.006927,
        store_longitude: -78.444702,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.214652,
        store_longitude: -78.5037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21158,
        store_longitude: -78.5245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102877,
        store_longitude: -78.5133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123218,
        store_longitude: -78.5181,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12854833333333,
        store_longitude: -78.49831,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139722,
        store_longitude: -78.5003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122096,
        store_longitude: -78.5049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19901756197214,
        store_longitude: -78.497945358977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13965314,
        store_longitude: -78.50057387,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0902985,
        store_longitude: -78.5204,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0696448,
        store_longitude: -78.4734,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.052165,
        store_longitude: -78.4582884,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0101052,
        store_longitude: -78.4438,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0883267,
        store_longitude: -78.5155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215506,
        store_longitude: -78.5134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144578,
        store_longitude: -78.4985792,
    },
    {
        store_latitdue: -0.140102,
        store_longitude: -78.5005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213963,
        store_longitude: -78.5236,
    },
    {
        store_latitdue: -0.22057833333333,
        store_longitude: -78.517266666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193307,
        store_longitude: -78.5016166,
    },
    {
        store_latitdue: -0.1291599,
        store_longitude: -78.50088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0873686,
        store_longitude: -78.5213,
    },
    {
        store_latitdue: 0.0021455,
        store_longitude: -78.4504,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.217332,
        store_longitude: -78.5216,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18918263,
        store_longitude: -78.51396208,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188302,
        store_longitude: -78.5156,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.185448,
        store_longitude: -78.5127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186922,
        store_longitude: -78.5117,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.196174,
        store_longitude: -78.5089,
    },
    {
        store_latitdue: -0.1874308,
        store_longitude: -78.513243,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147929,
        store_longitude: -78.506,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226371,
        store_longitude: -78.510895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1932796,
        store_longitude: -78.4969312,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13720084,
        store_longitude: -78.50005687,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1903362,
        store_longitude: -78.5133742,
    },
    {
        store_latitdue: -0.111993,
        store_longitude: -78.5067,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0958056,
        store_longitude: -78.5129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20537385,
        store_longitude: -78.50323243,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.219505,
        store_longitude: -78.5188,
    },
    {
        store_latitdue: -0.208228,
        store_longitude: -78.5250752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2756864,
        store_longitude: -78.5502816,
    },
    {
        store_latitdue: -0.117547,
        store_longitude: -78.5039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0908744,
        store_longitude: -78.5297388,
    },

    {
        store_latitdue: -0.2052233,
        store_longitude: -78.5248395,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2176283,
        store_longitude: -78.5088429,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223543,
        store_longitude: -78.5137,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115873,
        store_longitude: -78.5014,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0955018,
        store_longitude: -78.5135882,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1254155,
        store_longitude: -78.5205416,
    },
    {
        store_latitdue: -0.0905066,
        store_longitude: -78.521,
    },
    {
        store_latitdue: -0.0874187,
        store_longitude: -78.507319,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103149,
        store_longitude: -78.510671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118844,
        store_longitude: -78.5157,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12274,
        store_longitude: -78.4955,
    },
    {
        store_latitdue: -0.2137851,
        store_longitude: -78.5210891,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129424,
        store_longitude: -78.5142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123472,
        store_longitude: -78.510532,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1056371,
        store_longitude: -78.4958194,
    },
    {
        store_latitdue: -0.096284807659686,
        store_longitude: -78.451665602624,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.001089,
        store_longitude: -78.511398,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0676483,
        store_longitude: -78.461701,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1174587,
        store_longitude: -78.5038618,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.228146,
        store_longitude: -78.5105,
    },
    {
        store_latitdue: -0.2149,
        store_longitude: -78.50812,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18738393,
        store_longitude: -78.51673889,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140644,
        store_longitude: -78.5083,
    },
    {
        store_latitdue: -0.136379,
        store_longitude: -78.5071,
    },
    {
        store_latitdue: -0.0622283,
        store_longitude: -78.458798,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1424956,
        store_longitude: -78.5027958,
    },
    {
        store_latitdue: -0.10644,
        store_longitude: -78.4995,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.009267,
        store_longitude: -78.446,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.224736,
        store_longitude: -78.5123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215861,
        store_longitude: -78.5113,
    },
    {
        store_latitdue: -0.2201775,
        store_longitude: -78.5164702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1213135,
        store_longitude: -78.4990967,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1237648,
        store_longitude: -78.5016655,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0838525,
        store_longitude: -78.5052,
    },
    {
        store_latitdue: -0.091491,
        store_longitude: -78.514039,
    },
    {
        store_latitdue: 0.0105342,
        store_longitude: -78.4796,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0737862,
        store_longitude: -78.4786441,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1884568,
        store_longitude: -78.5124397,
    },

    {
        store_latitdue: -0.143222,
        store_longitude: -78.4945,
    },
    {
        store_latitdue: -0.1269713,
        store_longitude: -78.5071736,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1532872,
        store_longitude: -78.5046512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0600044,
        store_longitude: -78.4635,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2159322,
        store_longitude: -78.513833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21262786,
        store_longitude: -78.51069057,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21169833333333,
        store_longitude: -78.516153333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121312,
        store_longitude: -78.5022662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115734,
        store_longitude: -78.509,
    },
    {
        store_latitdue: -0.0897828,
        store_longitude: -78.5143,
    },
    {
        store_latitdue: -0.2079802,
        store_longitude: -78.5057141,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1891138,
        store_longitude: -78.5116292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139692,
        store_longitude: -78.4989,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1231056,
        store_longitude: -78.4940044,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082993333333333,
        store_longitude: -78.505173333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1069816,
        store_longitude: -78.4945568,
    },
    {
        store_latitdue: -0.1235869,
        store_longitude: -78.4946465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114829,
        store_longitude: -78.4974,
    },
    {
        store_latitdue: -0.113263,
        store_longitude: -78.5106,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.180365,
        store_longitude: -78.51488,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1136142,
        store_longitude: -78.5061601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.011655,
        store_longitude: -78.44662,
    },
    {
        store_latitdue: -0.2192651,
        store_longitude: -78.5192978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.173016,
        store_longitude: -78.4981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1777398,
        store_longitude: -78.5125261,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.200618,
        store_longitude: -78.4971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0851931,
        store_longitude: -78.5061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1591905,
        store_longitude: -78.5003441,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1142465,
        store_longitude: -78.5194538,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083906,
        store_longitude: -78.50532,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112219,
        store_longitude: -78.5103432,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1593499,
        store_longitude: -78.5050921,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.151546,
        store_longitude: -78.4945,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0100173,
        store_longitude: -78.4377,
    },
    {
        store_latitdue: -0.00279136,
        store_longitude: -78.44302378,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0067649,
        store_longitude: -78.4419041,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.156307,
        store_longitude: -78.491413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0630739,
        store_longitude: -78.4686808,
    },
    {
        store_latitdue: -0.14310078,
        store_longitude: -78.5061008,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1282576,
        store_longitude: -78.5126418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.134606,
        store_longitude: -78.4976,
    },
    {
        store_latitdue: -0.087771,
        store_longitude: -78.522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090706666666667,
        store_longitude: -78.527205,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0890601,
        store_longitude: -78.5064,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10097833333333,
        store_longitude: -78.521963333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121602,
        store_longitude: -78.5185,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121008,
        store_longitude: -78.5157,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090586,
        store_longitude: -78.5057,
    },
    {
        store_latitdue: -0.2100652,
        store_longitude: -78.5249834,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225627,
        store_longitude: -78.5116,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207956,
        store_longitude: -78.526907,
    },
    {
        store_latitdue: -0.22794342,
        store_longitude: -78.50770347,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19902,
        store_longitude: -78.4995,
    },
    {
        store_latitdue: -0.12833823,
        store_longitude: -78.51816595,
    },
    {
        store_latitdue: -0.14059666666667,
        store_longitude: -78.508296666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1254457,
        store_longitude: -78.5108042,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143711,
        store_longitude: -78.5028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1043761,
        store_longitude: -78.493115,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0579138,
        store_longitude: -78.467102,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.184933,
        store_longitude: -78.4928,
    },
    {
        store_latitdue: -0.196606,
        store_longitude: -78.5091,
    },
    {
        store_latitdue: -0.1398724,
        store_longitude: -78.5005159,
    },
    {
        store_latitdue: -0.0965922,
        store_longitude: -78.4996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116365,
        store_longitude: -78.4949,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0015016,
        store_longitude: -78.443,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.209613,
        store_longitude: -78.523478,
    },
    {
        store_latitdue: -0.2163664,
        store_longitude: -78.50836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212008,
        store_longitude: -78.5101,
    },
    {
        store_latitdue: -0.210115,
        store_longitude: -78.5153,
    },
    {
        store_latitdue: -0.176118,
        store_longitude: -78.4967,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.192332,
        store_longitude: -78.5004,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.159771,
        store_longitude: -78.4883,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.180944,
        store_longitude: -78.5134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1125439,
        store_longitude: -78.4461952,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.102625,
        store_longitude: -78.513169,
    },
    {
        store_latitdue: -0.11409,
        store_longitude: -78.5106,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0542362,
        store_longitude: -78.4562,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0559279,
        store_longitude: -78.4588,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.182473,
        store_longitude: -78.510249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199224,
        store_longitude: -78.498,
    },
    {
        store_latitdue: -0.1155812,
        store_longitude: -78.5012441,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1436908,
        store_longitude: -78.5025465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130657,
        store_longitude: -78.4942,
    },
    {
        store_latitdue: -0.0849573,
        store_longitude: -78.5058533,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2078019,
        store_longitude: -78.5115863,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105117,
        store_longitude: -78.5132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0976786,
        store_longitude: -78.5151435,
    },
    {
        store_latitdue: -0.104896,
        store_longitude: -78.5133,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.091855,
        store_longitude: -78.506902,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113419,
        store_longitude: -78.519,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1135045,
        store_longitude: -78.5160485,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0536129,
        store_longitude: -78.454,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2127777,
        store_longitude: -78.5229413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213303,
        store_longitude: -78.5041,
    },
    {
        store_latitdue: -0.2112453,
        store_longitude: -78.515557,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2026699,
        store_longitude: -78.5222532,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2166,
        store_longitude: -78.50957,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.202278,
        store_longitude: -78.5092,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.137015,
        store_longitude: -78.500922,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.097033333333333,
        store_longitude: -78.511153333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094899,
        store_longitude: -78.5000658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0146946,
        store_longitude: -78.4355188,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.109896,
        store_longitude: -78.4998,
    },
    {
        store_latitdue: -0.2114862,
        store_longitude: -78.5221141,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18918628,
        store_longitude: -78.51801492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12858654,
        store_longitude: -78.51234697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11502,
        store_longitude: -78.4978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0976038,
        store_longitude: -78.515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094501666666667,
        store_longitude: -78.502761666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0101648,
        store_longitude: -78.4438169,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.210125,
        store_longitude: -78.512431,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2251502,
        store_longitude: -78.5120248,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.172792,
        store_longitude: -78.4958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12029,
        store_longitude: -78.4969,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1144292,
        store_longitude: -78.507122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0851031,
        store_longitude: -78.5107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091482,
        store_longitude: -78.5140508,
    },
    {
        store_latitdue: -0.0914426,
        store_longitude: -78.5092342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208898,
        store_longitude: -78.5189,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117728,
        store_longitude: -78.5115,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0930989,
        store_longitude: -78.5102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.165566,
        store_longitude: -78.4886,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0546787,
        store_longitude: -78.4578306,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.21367806,
        store_longitude: -78.5096055,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218903,
        store_longitude: -78.5169,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216581,
        store_longitude: -78.5161,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216037,
        store_longitude: -78.5196,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2070789,
        store_longitude: -78.526724,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2280379,
        store_longitude: -78.5094166,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148702,
        store_longitude: -78.5046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1916996,
        store_longitude: -78.4999478,
    },
    {
        store_latitdue: -0.192399,
        store_longitude: -78.4957,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128783,
        store_longitude: -78.514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0965178,
        store_longitude: -78.5174342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0834569,
        store_longitude: -78.5073,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0897744,
        store_longitude: -78.5068201,
    },
    {
        store_latitdue: -0.0929362,
        store_longitude: -78.5011693,
    },
    {
        store_latitdue: -0.059961411170661,
        store_longitude: -78.4633141011,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.074483,
        store_longitude: -78.4811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226597,
        store_longitude: -78.508,
    },
    {
        store_latitdue: -0.150049,
        store_longitude: -78.5049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.177182,
        store_longitude: -78.512438,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1262431,
        store_longitude: -78.5104049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1206795,
        store_longitude: -78.5090989,
    },
    {
        store_latitdue: -0.0946798,
        store_longitude: -78.5068298,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.057693,
        store_longitude: -78.4648289,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.213208,
        store_longitude: -78.5091,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2067431,
        store_longitude: -78.5216751,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.194118,
        store_longitude: -78.4981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.201452,
        store_longitude: -78.499715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117502,
        store_longitude: -78.5039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085523,
        store_longitude: -78.503952,
    },
    {
        store_latitdue: -0.0941133,
        store_longitude: -78.5030972,
    },
    {
        store_latitdue: -0.098328,
        store_longitude: -78.529988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0668706,
        store_longitude: -78.4659,
    },
    {
        store_latitdue: -0.028624,
        store_longitude: -78.4524874,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2211296,
        store_longitude: -78.5071983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18444173,
        store_longitude: -78.50930596,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189061,
        store_longitude: -78.5106,
    },
    {
        store_latitdue: -0.0045472,
        store_longitude: -78.4474,
    },
    {
        store_latitdue: -0.1803661,
        store_longitude: -78.5114842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12798609,
        store_longitude: -78.51840686,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143921,
        store_longitude: -78.4974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129222,
        store_longitude: -78.497,
    },
    {
        store_latitdue: -0.0962014,
        store_longitude: -78.5177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21071364,
        store_longitude: -78.52410001,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15943,
        store_longitude: -78.5044,
    },
    {
        store_latitdue: -0.13504,
        store_longitude: -78.502278,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1470236,
        store_longitude: -78.5019942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146227,
        store_longitude: -78.5058,
    },
    {
        store_latitdue: -0.3277201,
        store_longitude: -78.5480785,
    },
    {
        store_latitdue: -0.095217,
        store_longitude: -78.518526,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0736944,
        store_longitude: -78.478744,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0668998,
        store_longitude: -78.465714,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -1.5939062,
        store_longitude: -78.9839755,
        neighborhood: 'GUARANDA',
    },

    {
        store_latitdue: -0.207942,
        store_longitude: -78.4821,
    },
    {
        store_latitdue: -0.1284284,
        store_longitude: -78.5097259,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125255,
        store_longitude: -78.5156,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1336866,
        store_longitude: -78.4943079,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11286189,
        store_longitude: -78.50895118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0962764,
        store_longitude: -78.5122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0906744,
        store_longitude: -78.5129536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0552133,
        store_longitude: -78.459,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.010417,
        store_longitude: -78.4467368,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2178292,
        store_longitude: -78.5097373,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13487021,
        store_longitude: -78.50076427,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },
    {
        store_latitdue: -0.1276193,
        store_longitude: -78.5117026,
    },
    {
        store_latitdue: -0.06645,
        store_longitude: -78.459201666667,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0068229,
        store_longitude: -78.4445957,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.211135,
        store_longitude: -78.5099,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2054711,
        store_longitude: -78.5085022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1902375,
        store_longitude: -78.51115456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1211951,
        store_longitude: -78.5120275,
    },
    {
        store_latitdue: -0.19146502,
        store_longitude: -78.5103759,
    },
    {
        store_latitdue: -0.199266,
        store_longitude: -78.499517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1294256,
        store_longitude: -78.5161843,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123994,
        store_longitude: -78.5051,
    },
    {
        store_latitdue: -0.1254215,
        store_longitude: -78.5006638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0944208,
        store_longitude: -78.4993,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0840372,
        store_longitude: -78.5054,
    },
    {
        store_latitdue: -0.097943,
        store_longitude: -78.5188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0970781,
        store_longitude: -78.5212,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0949865,
        store_longitude: -78.5246,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08122,
        store_longitude: -78.501093333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210978,
        store_longitude: -78.5205,
    },
    {
        store_latitdue: -0.2139865,
        store_longitude: -78.5211614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1892468,
        store_longitude: -78.5138623,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128358,
        store_longitude: -78.5031,
    },
    {
        store_latitdue: -0.1002201,
        store_longitude: -78.5216516,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0941253,
        store_longitude: -78.5036832,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095756666666667,
        store_longitude: -78.512896666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084886,
        store_longitude: -78.5078683,
    },
    {
        store_latitdue: -0.092577,
        store_longitude: -78.507526,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190105,
        store_longitude: -78.5004,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1933786,
        store_longitude: -78.4969791,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0911684,
        store_longitude: -78.5160165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1181402,
        store_longitude: -78.5199322,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1025671,
        store_longitude: -78.5246937,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1458326,
        store_longitude: -78.5067109,
    },
    {
        store_latitdue: -0.0014981,
        store_longitude: -78.5141267,
    },
    {
        store_latitdue: -0.21171698346734,
        store_longitude: -78.510580155998,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0066236,
        store_longitude: -78.4495025,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.12918666666667,
        store_longitude: -78.497753333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095441,
        store_longitude: -78.5147,
    },
    {
        store_latitdue: -0.11549619,
        store_longitude: -78.5100597,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0859714,
        store_longitude: -78.5065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0924706,
        store_longitude: -78.5062623,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0817492,
        store_longitude: -78.5028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09989386,
        store_longitude: -78.46829463,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105277,
        store_longitude: -78.494453,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0374827,
        store_longitude: -78.4416567,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.196787,
        store_longitude: -78.501913,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2181054,
        store_longitude: -78.5098816,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1498882,
        store_longitude: -78.5049774,
    },
    {
        store_latitdue: -0.131456,
        store_longitude: -78.512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.05662678,
        store_longitude: -78.4535944,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2137344,
        store_longitude: -78.5229318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2225002,
        store_longitude: -78.5113239,
    },
    {
        store_latitdue: -0.19256265833974,
        store_longitude: -78.497851649299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126536,
        store_longitude: -78.5085,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139928,
        store_longitude: -78.4754,
    },
    {
        store_latitdue: -0.2072146,
        store_longitude: -78.525883,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2076979,
        store_longitude: -78.5246974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21212785,
        store_longitude: -78.5204029,
    },
    {
        store_latitdue: -0.17921665,
        store_longitude: -78.5136718,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1273247,
        store_longitude: -78.5022801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1300148,
        store_longitude: -78.5112548,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093402,
        store_longitude: -78.510312,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0916425,
        store_longitude: -78.5146476,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0810125,
        store_longitude: -78.5033677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09256891,
        store_longitude: -78.49896046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.079712,
        store_longitude: -78.480663,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206315,
        store_longitude: -78.5203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1768378,
        store_longitude: -78.493449,
    },
    {
        store_latitdue: -0.226977,
        store_longitude: -78.5257,
    },
    {
        store_latitdue: -0.142734,
        store_longitude: -78.512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1528674,
        store_longitude: -78.49108,
    },
    {
        store_latitdue: -0.013182985608472,
        store_longitude: -78.448722971338,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0008251,
        store_longitude: -78.4445921,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.188357,
        store_longitude: -78.5125,
    },
    {
        store_latitdue: -0.186014,
        store_longitude: -78.5155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0871677,
        store_longitude: -78.5089531,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1020246,
        store_longitude: -78.5088349,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0088198,
        store_longitude: -78.445831,
    },
    {
        store_latitdue: -0.0002033,
        store_longitude: -78.4473085,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2106632,
        store_longitude: -78.5118211,
    },
    {
        store_latitdue: -0.185586,
        store_longitude: -78.509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13927833333333,
        store_longitude: -78.495063333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1295586,
        store_longitude: -78.5114935,
    },
    {
        store_latitdue: -0.124405,
        store_longitude: -78.5097,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.100459,
        store_longitude: -78.521,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090751578100026,
        store_longitude: -78.521731356159,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0892472,
        store_longitude: -78.511285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0964241,
        store_longitude: -78.519976,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0865753,
        store_longitude: -78.5067,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11466766241938,
        store_longitude: -78.495122920722,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2088237,
        store_longitude: -78.5259111,
    },
    {
        store_latitdue: -0.2119167,
        store_longitude: -78.5241507,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20934215,
        store_longitude: -78.50487903,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2046152,
        store_longitude: -78.4997741,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1968136,
        store_longitude: -78.5017624,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083913333333333,
        store_longitude: -78.508951666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2141919,
        store_longitude: -78.5228152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186412,
        store_longitude: -78.5019,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1251297,
        store_longitude: -78.5156443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14819,
        store_longitude: -78.497155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112627,
        store_longitude: -78.5085,
    },
    {
        store_latitdue: -0.0903303,
        store_longitude: -78.5148242,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0689198,
        store_longitude: -78.4617804,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0891091,
        store_longitude: -78.5102118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0927769,
        store_longitude: -78.5053,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2077167,
        store_longitude: -78.5244987,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1262396,
        store_longitude: -78.516621,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08693465,
        store_longitude: -78.43024147,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0874219,
        store_longitude: -78.5087701,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21879908,
        store_longitude: -78.50950997,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.217581,
        store_longitude: -78.5128,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129904,
        store_longitude: -78.514985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1379199,
        store_longitude: -78.509747,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12650878,
        store_longitude: -78.51310235,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0848086,
        store_longitude: -78.5077131,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.08625802,
        store_longitude: -78.50832588,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125233,
        store_longitude: -78.466592,
    },
    {
        store_latitdue: 0.0002136,
        store_longitude: -78.5115,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0107613,
        store_longitude: -78.4481,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2117188,
        store_longitude: -78.5209697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1742958,
        store_longitude: -78.5024546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1357818,
        store_longitude: -78.49718,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0698385,
        store_longitude: -78.4738995,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1328848,
        store_longitude: -78.4951681,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0903259,
        store_longitude: -78.5206946,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0908249,
        store_longitude: -78.5225,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0068337,
        store_longitude: -78.4446091,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.088906,
        store_longitude: -78.52243,
    },
    {
        store_latitdue: -0.0923089,
        store_longitude: -78.5005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2248813,
        store_longitude: -78.5127816,
    },
    {
        store_latitdue: -0.188522,
        store_longitude: -78.5027187,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.111555,
        store_longitude: -78.4879,
    },
    {
        store_latitdue: -0.0964429,
        store_longitude: -78.449717,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.114949,
        store_longitude: -78.519,
    },
    {
        store_latitdue: -0.1054017,
        store_longitude: -78.4957537,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189638,
        store_longitude: -78.4980693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2108282,
        store_longitude: -78.5031593,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.155834,
        store_longitude: -78.499,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.201345,
        store_longitude: -78.5002748,
    },
    {
        store_latitdue: -0.148046,
        store_longitude: -78.5061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0635482,
        store_longitude: -78.4291964,
    },
    {
        store_latitdue: -0.05869146,
        store_longitude: -78.45607367,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0925762,
        store_longitude: -78.5248612,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0946972,
        store_longitude: -78.5082161,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.00046121422201395,
        store_longitude: -78.452255856246,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2129829,
        store_longitude: -78.5036398,
    },
    {
        store_latitdue: -0.20623,
        store_longitude: -78.500771666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1775444,
        store_longitude: -78.4889116,
    },
    {
        store_latitdue: -0.1963607,
        store_longitude: -78.503115,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140015,
        store_longitude: -78.499901,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1031582,
        store_longitude: -78.5122846,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.072558333333333,
        store_longitude: -78.479548333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2020401,
        store_longitude: -78.4979689,
    },
    {
        store_latitdue: -0.184007,
        store_longitude: -78.4943,
    },
    {
        store_latitdue: -0.13374672,
        store_longitude: -78.50091553,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11866545,
        store_longitude: -78.495697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121324,
        store_longitude: -78.5179,
    },
    {
        store_latitdue: -0.062864,
        store_longitude: -78.466993,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2085245,
        store_longitude: -78.5050331,
    },
    {
        store_latitdue: -0.2120672,
        store_longitude: -78.5229062,
    },
    {
        store_latitdue: -0.228234,
        store_longitude: -78.5094,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12912812,
        store_longitude: -78.51743923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0959446,
        store_longitude: -78.5124912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210792,
        store_longitude: -78.5196538,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21255666666667,
        store_longitude: -78.516661666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1875701,
        store_longitude: -78.4956088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1849913,
        store_longitude: -78.4924671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1299549,
        store_longitude: -78.5106156,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0991861,
        store_longitude: -78.515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0954217,
        store_longitude: -78.5126235,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.104856,
        store_longitude: -78.493202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105427,
        store_longitude: -78.494652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0087743,
        store_longitude: -78.4463961,
    },
    {
        store_latitdue: -0.0375837,
        store_longitude: -78.4413,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2126369,
        store_longitude: -78.5102858,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2125288,
        store_longitude: -78.5118628,
    },
    {
        store_latitdue: -0.11566,
        store_longitude: -78.50105,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1247519,
        store_longitude: -78.4678179,
    },
    {
        store_latitdue: -0.09754653,
        store_longitude: -78.5086655,
    },
    {
        store_latitdue: -0.1218508,
        store_longitude: -78.5153945,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0519265,
        store_longitude: -78.4541043,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.21759,
        store_longitude: -78.5067,
    },
    {
        store_latitdue: -0.2078454,
        store_longitude: -78.5080545,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120333,
        store_longitude: -78.5075,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143178,
        store_longitude: -78.494986,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1475052,
        store_longitude: -78.4935523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0974575,
        store_longitude: -78.5118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.100309,
        store_longitude: -78.5209,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0519711,
        store_longitude: -78.4541462,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.10384,
        store_longitude: -78.492996666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1281968,
        store_longitude: -78.4635516,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.00138079,
        store_longitude: -78.4475433,
    },
    {
        store_latitdue: -0.21124,
        store_longitude: -78.502123333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212785,
        store_longitude: -78.514766666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2131176,
        store_longitude: -78.5062122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189484,
        store_longitude: -78.4981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.176596,
        store_longitude: -78.493355,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1949857827276,
        store_longitude: -78.497906383127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1877652,
        store_longitude: -78.4944217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144581,
        store_longitude: -78.508,
    },
    {
        store_latitdue: -0.1277645,
        store_longitude: -78.5035582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127277,
        store_longitude: -78.4985,
    },
    {
        store_latitdue: -0.125122,
        store_longitude: -78.523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08618,
        store_longitude: -78.5015658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21816,
        store_longitude: -78.5067,
    },
    {
        store_latitdue: -0.2190323,
        store_longitude: -78.5073382,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213162,
        store_longitude: -78.5196,
    },
    {
        store_latitdue: -0.138935,
        store_longitude: -78.502176666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.179591,
        store_longitude: -78.510802,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1259759,
        store_longitude: -78.5165044,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0013756,
        store_longitude: -78.459187,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0931942,
        store_longitude: -78.5047935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0233579,
        store_longitude: -78.4370786,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.20713841,
        store_longitude: -78.50875813,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2767315,
        store_longitude: -78.5473889,
    },
    {
        store_latitdue: -0.2070282,
        store_longitude: -78.5046924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18985,
        store_longitude: -78.5101,
    },
    {
        store_latitdue: -0.1946425,
        store_longitude: -78.5099752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2237335,
        store_longitude: -78.3433829,
    },
    {
        store_latitdue: -0.1367608,
        store_longitude: -78.5071696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0850463,
        store_longitude: -78.5085699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0644853,
        store_longitude: -78.4612957,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.227079,
        store_longitude: -78.5077,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212607,
        store_longitude: -78.5167437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2164468,
        store_longitude: -78.5129763,
    },
    {
        store_latitdue: -0.2173234,
        store_longitude: -78.5215853,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189119,
        store_longitude: -78.518204,
    },
    {
        store_latitdue: -0.1171753,
        store_longitude: -78.4958871,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09121199,
        store_longitude: -78.51579875,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091006,
        store_longitude: -78.522106,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0916948,
        store_longitude: -78.5068995,
    },
    {
        store_latitdue: -0.0947293,
        store_longitude: -78.5265356,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210624,
        store_longitude: -78.519082,
    },
    {
        store_latitdue: -0.18719666666667,
        store_longitude: -78.498026666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1977472,
        store_longitude: -78.4966594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123435,
        store_longitude: -78.4967367,
    },
    {
        store_latitdue: -0.0007968,
        store_longitude: -78.4453,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0089117,
        store_longitude: -78.4464233,
    },
    {
        store_latitdue: -0.1963854,
        store_longitude: -78.4991464,
    },
    {
        store_latitdue: -0.3059582,
        store_longitude: -78.5356761,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1806006,
        store_longitude: -78.514003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129315,
        store_longitude: -78.5015,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2109279,
        store_longitude: -78.3858899,
    },
    {
        store_latitdue: -0.116643,
        store_longitude: -78.483562,
    },
    {
        store_latitdue: -0.1227287,
        store_longitude: -78.516981,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.074432,
        store_longitude: -78.481323,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138716,
        store_longitude: -78.4941421,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1445275,
        store_longitude: -78.4921789,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09134329855442,
        store_longitude: -78.524419767782,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1050473,
        store_longitude: -78.4918916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2131647,
        store_longitude: -78.5149993,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1558741,
        store_longitude: -78.4928401,
    },
    {
        store_latitdue: -0.1446683,
        store_longitude: -78.5008231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0955924,
        store_longitude: -78.5008013,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.227537,
        store_longitude: -78.5060921,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2158687,
        store_longitude: -78.5112605,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22668833333333,
        store_longitude: -78.506818333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1564078,
        store_longitude: -78.491627,
    },
    {
        store_latitdue: -0.1269638,
        store_longitude: -78.4941057,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0058467,
        store_longitude: -78.4632,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.05951661,
        store_longitude: -78.45779279,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1855053,
        store_longitude: -78.509046,
    },
    {
        store_latitdue: -0.120622,
        store_longitude: -78.511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2097369,
        store_longitude: -78.5214046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2359982,
        store_longitude: -78.483396,
    },
    {
        store_latitdue: -0.0804884,
        store_longitude: -78.4121,
    },
    {
        store_latitdue: -0.1269934,
        store_longitude: -78.4941342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1382411,
        store_longitude: -78.4984003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12374157,
        store_longitude: -78.520401,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1270367,
        store_longitude: -78.5255988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0907807,
        store_longitude: -78.5098621,
    },
    {
        store_latitdue: -0.0962068,
        store_longitude: -78.529604,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1052642,
        store_longitude: -78.4943974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21258828230202,
        store_longitude: -78.510260721669,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21298333333333,
        store_longitude: -78.50422,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207843,
        store_longitude: -78.519169,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18942,
        store_longitude: -78.495425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13973993,
        store_longitude: -78.5077569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1427093,
        store_longitude: -78.5117856,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107805,
        store_longitude: -78.5018767,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11628666666667,
        store_longitude: -78.500975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1287218,
        store_longitude: -78.513516,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0886621,
        store_longitude: -78.5034029,
    },
    {
        store_latitdue: -0.0522467,
        store_longitude: -78.4550471,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.20914369728416,
        store_longitude: -78.509652782232,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21954333333333,
        store_longitude: -78.517493333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20772,
        store_longitude: -78.5062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1433151,
        store_longitude: -78.4961178,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20192,
        store_longitude: -78.4869,
    },
    {
        store_latitdue: -0.103206,
        store_longitude: -78.512282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.16002561,
        store_longitude: -78.50500892,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1872531,
        store_longitude: -78.4979488,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14929,
        store_longitude: -78.491495,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1456762,
        store_longitude: -78.4941138,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0886994,
        store_longitude: -78.5108085,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090776,
        store_longitude: -78.52198,
    },
    {
        store_latitdue: 0.0015786,
        store_longitude: -78.4408835,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.20642,
        store_longitude: -78.502703333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2060025,
        store_longitude: -78.5018014,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212336,
        store_longitude: -78.5044279,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1127886,
        store_longitude: -78.5061587,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1009094,
        store_longitude: -78.5060512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2159229,
        store_longitude: -78.5183903,
    },
    {
        store_latitdue: -0.171935,
        store_longitude: -78.494065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123977,
        store_longitude: -78.505093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12828864,
        store_longitude: -78.5034942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21296635,
        store_longitude: -78.51007899,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0384182,
        store_longitude: -78.4426893,
    },
    {
        store_latitdue: -0.1030652,
        store_longitude: -78.5124237,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.05842,
        store_longitude: -78.4615,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.01220866,
        store_longitude: -78.43441248,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.20778833333333,
        store_longitude: -78.520003333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1246794,
        store_longitude: -78.5131516,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1312605,
        store_longitude: -78.5171122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2194353,
        store_longitude: -78.5178074,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1154953,
        store_longitude: -78.4981012,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14047666666667,
        store_longitude: -78.508111666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0848113,
        store_longitude: -78.5064677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20051804836839,
        store_longitude: -78.499732045457,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0019787,
        store_longitude: -78.4589434,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0044816,
        store_longitude: -78.4431134,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0034265,
        store_longitude: -78.4381652,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.188438,
        store_longitude: -78.5036,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13124104,
        store_longitude: -78.4981261,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1102505,
        store_longitude: -78.4454362,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0843206,
        store_longitude: -78.5094488,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082646666666667,
        store_longitude: -78.443566666667,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0.0046812,
        store_longitude: -78.4593881,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1950513,
        store_longitude: -78.5014412,
    },
    {
        store_latitdue: -0.1844804,
        store_longitude: -78.5083854,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1187513,
        store_longitude: -78.4713204,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1476215,
        store_longitude: -78.4951873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09641262,
        store_longitude: -78.5258015,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.063961,
        store_longitude: -78.46155,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.075683,
        store_longitude: -78.478367,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2514023,
        store_longitude: -78.520964,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14928,
        store_longitude: -78.491496666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.006256395,
        store_longitude: -78.444425048,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.213805,
        store_longitude: -78.50346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21176833333333,
        store_longitude: -78.520913333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1954322,
        store_longitude: -78.5064411,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1444008,
        store_longitude: -78.500694,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08874032,
        store_longitude: -78.51273778,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0845238,
        store_longitude: -78.5057747,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10529162362218,
        store_longitude: -78.495218474418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0087492,
        store_longitude: -78.4534631,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.018394,
        store_longitude: -78.433465,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.214546,
        store_longitude: -78.504721,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22403362,
        store_longitude: -78.5103759,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.12690054,
        store_longitude: -78.49413195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1174683,
        store_longitude: -78.50422068,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0848579,
        store_longitude: -78.5056725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0652924,
        store_longitude: -78.4526262,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0012275716289878,
        store_longitude: -78.458472462371,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.111927,
        store_longitude: -78.506605,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.212743,
        store_longitude: -78.5176,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.194404,
        store_longitude: -78.484687,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.05177199,
        store_longitude: -78.4561691,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.143354,
        store_longitude: -78.5155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0872024,
        store_longitude: -78.5079609,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11345192,
        store_longitude: -78.50569003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1015744,
        store_longitude: -78.520556,
    },
    {
        store_latitdue: -0.0034433333333333,
        store_longitude: -78.44643,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.172322,
        store_longitude: -78.4925201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.282906,
        store_longitude: -78.5581,
    },
    {
        store_latitdue: -0.1235026,
        store_longitude: -78.5073823,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090018,
        store_longitude: -78.50347,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0913933,
        store_longitude: -78.5247192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.008792,
        store_longitude: -78.445814,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0087664,
        store_longitude: -78.4457912,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.22961594,
        store_longitude: -78.50987842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2114773,
        store_longitude: -78.522112,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20244871,
        store_longitude: -78.50632773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1797321,
        store_longitude: -78.5167777,
    },
    {
        store_latitdue: -0.178566,
        store_longitude: -78.513535,
    },
    {
        store_latitdue: -0.1236233,
        store_longitude: -78.5000816,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1273292,
        store_longitude: -78.4987425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12647242,
        store_longitude: -78.5254923,
    },
    {
        store_latitdue: -0.0982659,
        store_longitude: -78.5027,
    },
    {
        store_latitdue: -0.2132381,
        store_longitude: -78.5102437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21263166666667,
        store_longitude: -78.515986666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1435019,
        store_longitude: -78.4960593,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14603333333333,
        store_longitude: -78.49803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0857403,
        store_longitude: -78.5033633,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22335666666667,
        store_longitude: -78.512596666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2071288,
        store_longitude: -78.5112435,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2104664,
        store_longitude: -78.5117575,
    },
    {
        store_latitdue: -0.1243427,
        store_longitude: -78.4986191,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1897,
        store_longitude: -78.5016,
    },
    {
        store_latitdue: -0.1944803,
        store_longitude: -78.4997805,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.191568,
        store_longitude: -78.498702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1900492,
        store_longitude: -78.5006932,
    },
    {
        store_latitdue: -0.1895388,
        store_longitude: -78.4967202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190439,
        store_longitude: -78.499876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1918395,
        store_longitude: -78.4987928,
    },
    {
        store_latitdue: -0.193164,
        store_longitude: -78.499369,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193021,
        store_longitude: -78.500737,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1920709,
        store_longitude: -78.4970191,
    },
    {
        store_latitdue: -0.19533162005246,
        store_longitude: -78.499089572579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190159,
        store_longitude: -78.5006,
    },
    {
        store_latitdue: -0.128075,
        store_longitude: -78.495387,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.05195,
        store_longitude: -78.4562,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.273386,
        store_longitude: -78.563755,
    },
    {
        store_latitdue: -0.123948,
        store_longitude: -78.514198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120866,
        store_longitude: -78.5095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121494,
        store_longitude: -78.5106,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123924,
        store_longitude: -78.504641,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1205162,
        store_longitude: -78.5110729,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123235,
        store_longitude: -78.511035,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120109,
        store_longitude: -78.508371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122093,
        store_longitude: -78.50453,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124136,
        store_longitude: -78.506241,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119874,
        store_longitude: -78.5079,
    },
    {
        store_latitdue: -0.120173,
        store_longitude: -78.5084,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123167,
        store_longitude: -78.510605,
    },
    {
        store_latitdue: -0.12364,
        store_longitude: -78.510587,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1179233,
        store_longitude: -78.5071268,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11961,
        store_longitude: -78.507465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1215067,
        store_longitude: -78.5052609,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11788,
        store_longitude: -78.499199,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0940019,
        store_longitude: -78.5160113,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09488,
        store_longitude: -78.513821,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0979549,
        store_longitude: -78.5189886,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120416,
        store_longitude: -78.49147,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093682,
        store_longitude: -78.514318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096924,
        store_longitude: -78.516996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0944276,
        store_longitude: -78.5120373,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093984,
        store_longitude: -78.512019,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0958852,
        store_longitude: -78.5180744,
    },
    {
        store_latitdue: 0.00028137,
        store_longitude: -78.4509,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.000667,
        store_longitude: -78.444886,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.000320822,
        store_longitude: -78.4466,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1236377,
        store_longitude: -78.4717444,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.000873,
        store_longitude: -78.4431,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.001916,
        store_longitude: -78.443061,
    },
    {
        store_latitdue: 0.001032,
        store_longitude: -78.445756,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.002093,
        store_longitude: -78.45112,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.002033,
        store_longitude: -78.450927,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.002298,
        store_longitude: -78.450324,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.004866,
        store_longitude: -78.457265,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.007765,
        store_longitude: -78.462053,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0115187,
        store_longitude: -78.44,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0081263,
        store_longitude: -78.4634102,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.067458,
        store_longitude: -78.463516,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.210578,
        store_longitude: -78.508452,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209077,
        store_longitude: -78.5056688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2099265,
        store_longitude: -78.5094375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209934,
        store_longitude: -78.509963,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209404956,
        store_longitude: -78.5092663765,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2075536,
        store_longitude: -78.5080198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208476,
        store_longitude: -78.5068,
    },
    {
        store_latitdue: -0.21243,
        store_longitude: -78.50742,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21556,
        store_longitude: -78.50627,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2191797,
        store_longitude: -78.513028,
    },
    {
        store_latitdue: -0.2123216,
        store_longitude: -78.5081567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.200307,
        store_longitude: -78.4997021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213015,
        store_longitude: -78.506908,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184617,
        store_longitude: -78.508051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184457,
        store_longitude: -78.5085,
    },
    {
        store_latitdue: -0.1880678,
        store_longitude: -78.5059588,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184734,
        store_longitude: -78.508904,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189885,
        store_longitude: -78.5097,
    },
    {
        store_latitdue: -0.186181,
        store_longitude: -78.507363,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1841837,
        store_longitude: -78.5090425,
    },
    {
        store_latitdue: -0.1904969,
        store_longitude: -78.5088588,
    },
    {
        store_latitdue: -0.188136,
        store_longitude: -78.506083,
    },
    {
        store_latitdue: -0.05468,
        store_longitude: -78.453586666667,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1900597,
        store_longitude: -78.5097179,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121992,
        store_longitude: -78.500556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124217,
        store_longitude: -78.5021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11359,
        store_longitude: -78.49173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12109,
        store_longitude: -78.502469,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1236521,
        store_longitude: -78.502033,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122208,
        store_longitude: -78.4985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124073,
        store_longitude: -78.5022,
    },
    {
        store_latitdue: -0.123422,
        store_longitude: -78.500448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12426833333333,
        store_longitude: -78.502083333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1192423,
        store_longitude: -78.5006482,
    },
    {
        store_latitdue: -0.123588,
        store_longitude: -78.50016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0957506,
        store_longitude: -78.488805,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2051587,
        store_longitude: -78.5245386,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123635,
        store_longitude: -78.499132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113383,
        store_longitude: -78.49977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107478,
        store_longitude: -78.506318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.108005,
        store_longitude: -78.501726,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.111937,
        store_longitude: -78.507005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0912859,
        store_longitude: -78.5134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1107908,
        store_longitude: -78.5068918,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1068896,
        store_longitude: -78.4972438,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1095847,
        store_longitude: -78.507147,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112667,
        store_longitude: -78.499748,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105348,
        store_longitude: -78.499119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1096,
        store_longitude: -78.502871,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1142484,
        store_longitude: -78.4975173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107536,
        store_longitude: -78.50629,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204756,
        store_longitude: -78.5044,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102847,
        store_longitude: -78.496125,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1076894,
        store_longitude: -78.5019478,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.228774,
        store_longitude: -78.5076905,
    },
    {
        store_latitdue: -0.182495,
        store_longitude: -78.5038329,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19459333333333,
        store_longitude: -78.510018333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118856,
        store_longitude: -78.514848,
    },
    {
        store_latitdue: -0.106122,
        store_longitude: -78.494907,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1067642,
        store_longitude: -78.4953272,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.109928,
        store_longitude: -78.496611,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1150951,
        store_longitude: -78.4950209,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1124274,
        store_longitude: -78.496252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1143913,
        store_longitude: -78.4950366,
    },
    {
        store_latitdue: -0.112925,
        store_longitude: -78.49504,
    },
    {
        store_latitdue: -0.140948,
        store_longitude: -78.5091,
    },
    {
        store_latitdue: -0.226386,
        store_longitude: -78.506712,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2271822,
        store_longitude: -78.5103862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225146,
        store_longitude: -78.511955,
    },
    {
        store_latitdue: -0.2273768,
        store_longitude: -78.5101384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.222971,
        store_longitude: -78.514351,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229765,
        store_longitude: -78.507653,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22965509,
        store_longitude: -78.50960474,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226905,
        store_longitude: -78.510656,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22712942,
        store_longitude: -78.51041923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2247766,
        store_longitude: -78.512345,
    },
    {
        store_latitdue: -0.22946833333333,
        store_longitude: -78.509888333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225354,
        store_longitude: -78.5116,
    },
    {
        store_latitdue: -0.17854333333333,
        store_longitude: -78.488986666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1416119,
        store_longitude: -78.5076026,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1272031,
        store_longitude: -78.5132549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22423,
        store_longitude: -78.511408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1890698,
        store_longitude: -78.5126626,
    },
    {
        store_latitdue: -0.191051,
        store_longitude: -78.49971,
    },
    {
        store_latitdue: -0.186581,
        store_longitude: -78.51633,
    },
    {
        store_latitdue: -0.188114,
        store_longitude: -78.512389,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184378,
        store_longitude: -78.51385,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187345,
        store_longitude: -78.516602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1892678,
        store_longitude: -78.5139744,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1892621,
        store_longitude: -78.512844,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184684,
        store_longitude: -78.513841,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189011,
        store_longitude: -78.5119,
    },
    {
        store_latitdue: -0.188223,
        store_longitude: -78.505,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1885118,
        store_longitude: -78.5125396,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1882504,
        store_longitude: -78.5163916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184311,
        store_longitude: -78.51259,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },
    {
        store_latitdue: -0.187743,
        store_longitude: -78.513327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1454461,
        store_longitude: -78.5055002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1475346,
        store_longitude: -78.505212,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14455588,
        store_longitude: -78.50038569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14577,
        store_longitude: -78.500663,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145312,
        store_longitude: -78.506733,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142796,
        store_longitude: -78.50494,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.141713,
        store_longitude: -78.502411,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1448423,
        store_longitude: -78.4997776,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1460091,
        store_longitude: -78.5044449,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14191,
        store_longitude: -78.502811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1463243,
        store_longitude: -78.5053901,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1592449,
        store_longitude: -78.5031335,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143131,
        store_longitude: -78.5062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148188,
        store_longitude: -78.506504,
    },
    {
        store_latitdue: -0.14266,
        store_longitude: -78.504414,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.150045,
        store_longitude: -78.5049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1433395,
        store_longitude: -78.50753,
    },
    {
        store_latitdue: -0.2222853,
        store_longitude: -78.5155881,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.228762,
        store_longitude: -78.5106,
    },
    {
        store_latitdue: -0.180061,
        store_longitude: -78.5113,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094037,
        store_longitude: -78.505227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090827,
        store_longitude: -78.50753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094761,
        store_longitude: -78.502897,
    },
    {
        store_latitdue: -0.093622,
        store_longitude: -78.504959,
    },
    {
        store_latitdue: -0.096583,
        store_longitude: -78.502377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0918009,
        store_longitude: -78.5017,
    },
    {
        store_latitdue: -0.091962,
        store_longitude: -78.50146,
    },
    {
        store_latitdue: -0.092167,
        store_longitude: -78.505729,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093179,
        store_longitude: -78.500197,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094812,
        store_longitude: -78.502965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093297,
        store_longitude: -78.504418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094477,
        store_longitude: -78.505948,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089237,
        store_longitude: -78.505351,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13165,
        store_longitude: -78.5006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0950067,
        store_longitude: -78.5152899,
    },
    {
        store_latitdue: -0.112306,
        store_longitude: -78.5091,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.009406,
        store_longitude: -78.445133,
    },
    {
        store_latitdue: -0.0262418,
        store_longitude: -78.451852,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.011663,
        store_longitude: -78.44756,
    },
    {
        store_latitdue: -0.0230274,
        store_longitude: -78.4525035,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0092687,
        store_longitude: -78.44568,
    },
    {
        store_latitdue: -0.012836,
        store_longitude: -78.448581,
    },
    {
        store_latitdue: -0.013966,
        store_longitude: -78.447039,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.010831,
        store_longitude: -78.444378,
    },
    {
        store_latitdue: -0.011227,
        store_longitude: -78.447314,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.055876,
        store_longitude: -78.454917,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.011608,
        store_longitude: -78.44394,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.20962,
        store_longitude: -78.51612,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21104166666667,
        store_longitude: -78.520431666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21031,
        store_longitude: -78.516289,
    },
    {
        store_latitdue: -0.211194,
        store_longitude: -78.515748,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212507,
        store_longitude: -78.5163262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210935,
        store_longitude: -78.514694,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208883,
        store_longitude: -78.521799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2128023,
        store_longitude: -78.5176478,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.010043,
        store_longitude: -78.443925,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0536258,
        store_longitude: -78.4539484,
    },
    {
        store_latitdue: -0.212535,
        store_longitude: -78.515357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207665,
        store_longitude: -78.520273333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2114877,
        store_longitude: -78.5200924,
    },
    {
        store_latitdue: -0.2117391,
        store_longitude: -78.5161265,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2126166,
        store_longitude: -78.5167986,
    },
    {
        store_latitdue: -0.11172427,
        store_longitude: -78.48988796,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2127314,
        store_longitude: -78.5135418,
    },
    {
        store_latitdue: -0.210635,
        store_longitude: -78.5172,
    },
    {
        store_latitdue: -0.1754246,
        store_longitude: -78.4986754,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.155868,
        store_longitude: -78.493299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.155902,
        store_longitude: -78.489017,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.175839,
        store_longitude: -78.497724,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144857,
        store_longitude: -78.5081866,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1748235,
        store_longitude: -78.495084,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.169394,
        store_longitude: -78.4902,
    },
    {
        store_latitdue: -0.1340745,
        store_longitude: -78.5050157,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.173239,
        store_longitude: -78.4944173,
    },
    {
        store_latitdue: -0.143618,
        store_longitude: -78.496868,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1427953,
        store_longitude: -78.494787,
    },
    {
        store_latitdue: -0.142977,
        store_longitude: -78.4937877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142536,
        store_longitude: -78.497602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1435538,
        store_longitude: -78.4964501,
    },
    {
        store_latitdue: -0.14932,
        store_longitude: -78.49146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2137029,
        store_longitude: -78.5140149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.067841,
        store_longitude: -78.464593,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1390865,
        store_longitude: -78.494207,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142893,
        store_longitude: -78.493481,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.141529,
        store_longitude: -78.496473,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142091,
        store_longitude: -78.4964,
    },
    {
        store_latitdue: -0.140021,
        store_longitude: -78.499873,
    },
    {
        store_latitdue: -0.141839,
        store_longitude: -78.49961,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1391266,
        store_longitude: -78.4942015,
    },
    {
        store_latitdue: -0.096826,
        store_longitude: -78.511953,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },
    {
        store_latitdue: -0.09147,
        store_longitude: -78.505994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0946653,
        store_longitude: -78.5081413,
    },
    {
        store_latitdue: -0.09361,
        store_longitude: -78.50732,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094634,
        store_longitude: -78.49968,
    },
    {
        store_latitdue: -0.09491741,
        store_longitude: -78.50839687,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093762,
        store_longitude: -78.508706,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0926174,
        store_longitude: -78.5072661,
    },
    {
        store_latitdue: -0.095441,
        store_longitude: -78.507948,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096981,
        store_longitude: -78.508647,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.097464,
        store_longitude: -78.511791,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092855,
        store_longitude: -78.510724,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.160476,
        store_longitude: -78.5071717,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093678,
        store_longitude: -78.508621,
    },
    {
        store_latitdue: -0.09621,
        store_longitude: -78.509358,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090574,
        store_longitude: -78.506968,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096435,
        store_longitude: -78.509915,
    },
    {
        store_latitdue: -0.092856666666667,
        store_longitude: -78.51085,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0490314,
        store_longitude: -78.4561677,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0490539,
        store_longitude: -78.4562599,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0303908,
        store_longitude: -78.4510811,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0333179,
        store_longitude: -78.4395654,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.221485,
        store_longitude: -78.516414,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2206922,
        store_longitude: -78.5173381,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1919562,
        store_longitude: -78.5075542,
    },
    {
        store_latitdue: -0.1883144,
        store_longitude: -78.5152508,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189208,
        store_longitude: -78.5115,
    },
    {
        store_latitdue: -0.1892394,
        store_longitude: -78.5114884,
    },
    {
        store_latitdue: -0.193337,
        store_longitude: -78.50426,
    },
    {
        store_latitdue: -0.190858,
        store_longitude: -78.510246,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193171,
        store_longitude: -78.504731,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.191976,
        store_longitude: -78.509406,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.192142,
        store_longitude: -78.507685,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1893093,
        store_longitude: -78.5115352,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190493,
        store_longitude: -78.5101,
    },
    {
        store_latitdue: -0.156252,
        store_longitude: -78.490097,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1900561,
        store_longitude: -78.5098235,
    },
    {
        store_latitdue: -0.1913793,
        store_longitude: -78.5101979,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.195336,
        store_longitude: -78.5075553,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1909423,
        store_longitude: -78.509065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1920683,
        store_longitude: -78.5086929,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1177823,
        store_longitude: -78.5043833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119673,
        store_longitude: -78.502486,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1204118,
        store_longitude: -78.5060094,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114468,
        store_longitude: -78.500872,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117429,
        store_longitude: -78.4993,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118111,
        store_longitude: -78.504732,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1186102,
        store_longitude: -78.4999045,
    },
    {
        store_latitdue: -0.118232,
        store_longitude: -78.504978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113997,
        store_longitude: -78.499687,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117908,
        store_longitude: -78.504329,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115539,
        store_longitude: -78.500866,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116566,
        store_longitude: -78.5028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1206795,
        store_longitude: -78.5090989,
    },
    {
        store_latitdue: -0.1173745,
        store_longitude: -78.4992689,
    },
    {
        store_latitdue: -0.0945954,
        store_longitude: -78.4995142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102967,
        store_longitude: -78.512574,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094723,
        store_longitude: -78.502427,
    },
    {
        store_latitdue: -0.103092,
        store_longitude: -78.512225,
    },
    {
        store_latitdue: -0.1005578,
        store_longitude: -78.5076777,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0958618,
        store_longitude: -78.5019384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1032432,
        store_longitude: -78.5108042,
    },
    {
        store_latitdue: -0.1013229,
        store_longitude: -78.5070057,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.100952,
        store_longitude: -78.506265,
    },
    {
        store_latitdue: -0.103583,
        store_longitude: -78.513397,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.101196,
        store_longitude: -78.510115,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1029693,
        store_longitude: -78.5129497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09528,
        store_longitude: -78.50039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0978854,
        store_longitude: -78.5012267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094816,
        store_longitude: -78.499862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1061351,
        store_longitude: -78.4948151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10041,
        store_longitude: -78.504799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10445,
        store_longitude: -78.5124,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.053726,
        store_longitude: -78.4552,
    },
    {
        store_latitdue: -0.0538945,
        store_longitude: -78.4551683,
    },
    {
        store_latitdue: -0.056233,
        store_longitude: -78.454813,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0551711,
        store_longitude: -78.4526122,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.054563,
        store_longitude: -78.453396,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.053785,
        store_longitude: -78.452668,
    },
    {
        store_latitdue: -0.0548497,
        store_longitude: -78.4552123,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.054447,
        store_longitude: -78.457544,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.052152,
        store_longitude: -78.458265,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.051613,
        store_longitude: -78.458499,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.054572,
        store_longitude: -78.458194,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2197534,
        store_longitude: -78.507921,
    },
    {
        store_latitdue: -0.0520825,
        store_longitude: -78.457,
    },
    {
        store_latitdue: -0.055273,
        store_longitude: -78.462893,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.050976,
        store_longitude: -78.459615,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.060847,
        store_longitude: -78.464563,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.146322,
        store_longitude: -78.481003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2123329,
        store_longitude: -78.5024297,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.053968333333333,
        store_longitude: -78.45524,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.21671,
        store_longitude: -78.515891,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209787,
        store_longitude: -78.5222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086971204739071,
        store_longitude: -78.430537233667,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.210453,
        store_longitude: -78.5015,
    },
    {
        store_latitdue: -0.086877180826581,
        store_longitude: -78.430291301462,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10867366,
        store_longitude: -78.4907811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184783,
        store_longitude: -78.507907,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199807,
        store_longitude: -78.499211,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2051127,
        store_longitude: -78.5024145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.166336,
        store_longitude: -78.49151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17833,
        store_longitude: -78.491791,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1649935,
        store_longitude: -78.4905193,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2141261,
        store_longitude: -78.522427,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1511554,
        store_longitude: -78.5008972,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095424,
        store_longitude: -78.51261,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123632,
        store_longitude: -78.5021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11672,
        store_longitude: -78.495887,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1402222,
        store_longitude: -78.5003709,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1460622,
        store_longitude: -78.50052237,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09911,
        store_longitude: -78.52037,
    },
    {
        store_latitdue: -0.026192,
        store_longitude: -78.447555,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1119665,
        store_longitude: -78.5067467,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21333,
        store_longitude: -78.5101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.217801,
        store_longitude: -78.5102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213539,
        store_longitude: -78.5097,
    },
    {
        store_latitdue: -0.21383008,
        store_longitude: -78.5110132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2169479,
        store_longitude: -78.508802,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2135796,
        store_longitude: -78.5104725,
    },
    {
        store_latitdue: -0.2169933,
        store_longitude: -78.5110569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21449,
        store_longitude: -78.50996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213127,
        store_longitude: -78.5112,
    },
    {
        store_latitdue: -0.21334,
        store_longitude: -78.50994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2140715,
        store_longitude: -78.5097737,
    },
    {
        store_latitdue: -0.21653958,
        store_longitude: -78.51184,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216845,
        store_longitude: -78.509787,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.157736,
        store_longitude: -78.499889,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1592061,
        store_longitude: -78.5031096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1547621,
        store_longitude: -78.5009475,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.159193,
        store_longitude: -78.5002883,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156299,
        store_longitude: -78.500934,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.154649,
        store_longitude: -78.500856,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1556418,
        store_longitude: -78.5011544,
    },
    {
        store_latitdue: -0.1586448,
        store_longitude: -78.5002913,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1601619,
        store_longitude: -78.5005657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1579117,
        store_longitude: -78.5009474,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.160139,
        store_longitude: -78.506803,
    },
    {
        store_latitdue: -0.1574974,
        store_longitude: -78.504307,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.158731,
        store_longitude: -78.500417,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.159294,
        store_longitude: -78.50745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.159906,
        store_longitude: -78.50789,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1552,
        store_longitude: -78.505118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15624,
        store_longitude: -78.5007836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15903,
        store_longitude: -78.501656,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.159319,
        store_longitude: -78.503656,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.160188,
        store_longitude: -78.5062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.132967,
        store_longitude: -78.496207,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1305994,
        store_longitude: -78.5012757,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1393551,
        store_longitude: -78.4964722,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.137089,
        store_longitude: -78.499269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1298212,
        store_longitude: -78.5014252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131502,
        store_longitude: -78.4965,
    },
    {
        store_latitdue: -0.130149,
        store_longitude: -78.4954,
    },
    {
        store_latitdue: -0.133408,
        store_longitude: -78.497265,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131471,
        store_longitude: -78.4999957,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130471,
        store_longitude: -78.49807,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.136876,
        store_longitude: -78.4991,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.137349,
        store_longitude: -78.500292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1330137,
        store_longitude: -78.4963443,
    },
    {
        store_latitdue: -0.1338819,
        store_longitude: -78.5007661,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13758,
        store_longitude: -78.4975,
    },
    {
        store_latitdue: -0.115538,
        store_longitude: -78.509235,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114379,
        store_longitude: -78.5196,
    },
    {
        store_latitdue: -0.11805,
        store_longitude: -78.51196,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116202,
        store_longitude: -78.509911,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113557,
        store_longitude: -78.509399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1118738,
        store_longitude: -78.5074389,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11437,
        store_longitude: -78.507738,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1102127,
        store_longitude: -78.5173883,
    },
    {
        store_latitdue: -0.115487,
        store_longitude: -78.509921,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114354,
        store_longitude: -78.507292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1133067,
        store_longitude: -78.5107579,
    },
    {
        store_latitdue: -0.114888,
        store_longitude: -78.508598,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118705,
        store_longitude: -78.511929,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1130656,
        store_longitude: -78.5088594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1159129,
        store_longitude: -78.5178472,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0108528,
        store_longitude: -78.4401158,
    },
    {
        store_latitdue: 0.01375,
        store_longitude: -78.439769,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.001589,
        store_longitude: -78.442112,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0016481,
        store_longitude: -78.441955,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.01549,
        store_longitude: -78.4393047,
    },
    {
        store_latitdue: 0.019181,
        store_longitude: -78.437624,
    },
    {
        store_latitdue: 0.012167,
        store_longitude: -78.4398,
    },
    {
        store_latitdue: -0.000942461,
        store_longitude: -78.5189,
    },
    {
        store_latitdue: -0.000861,
        store_longitude: -78.51454,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.2065263,
        store_longitude: -78.5221368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2073864,
        store_longitude: -78.5207607,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2090705,
        store_longitude: -78.5187751,
    },
    {
        store_latitdue: -0.205724,
        store_longitude: -78.521025,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205219,
        store_longitude: -78.523035,
    },
    {
        store_latitdue: -0.2069535,
        store_longitude: -78.518222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20659,
        store_longitude: -78.521829,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206915,
        store_longitude: -78.5204149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205017,
        store_longitude: -78.523161,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.206414,
        store_longitude: -78.521578,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205933,
        store_longitude: -78.521555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2076795,
        store_longitude: -78.519941,
    },
    {
        store_latitdue: -0.208856,
        store_longitude: -78.518862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209011,
        store_longitude: -78.521484,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205711,
        store_longitude: -78.525028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2062508,
        store_longitude: -78.521162,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207378,
        store_longitude: -78.520358,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2080356,
        store_longitude: -78.518734,
    },
    {
        store_latitdue: -0.207105,
        store_longitude: -78.521013,
    },
    {
        store_latitdue: -0.208436,
        store_longitude: -78.519,
    },
    {
        store_latitdue: -0.208619,
        store_longitude: -78.5196,
    },
    {
        store_latitdue: -0.2040106,
        store_longitude: -78.5251578,
    },
    {
        store_latitdue: -0.205951,
        store_longitude: -78.5016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206248,
        store_longitude: -78.500027,
    },
    {
        store_latitdue: -0.206753,
        store_longitude: -78.500529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2073876,
        store_longitude: -78.5016646,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2060639,
        store_longitude: -78.5011799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2079008,
        store_longitude: -78.5002214,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2059025,
        store_longitude: -78.5017676,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1338087,
        store_longitude: -78.5026802,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.134063,
        store_longitude: -78.504863,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1289744,
        store_longitude: -78.5030212,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1387411,
        store_longitude: -78.50522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12398,
        store_longitude: -78.504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125018,
        store_longitude: -78.51129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13376239,
        store_longitude: -78.5027807,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.133801,
        store_longitude: -78.502262,
    },
    {
        store_latitdue: -0.123987,
        store_longitude: -78.505011,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139477,
        store_longitude: -78.505257,
    },
    {
        store_latitdue: -0.13253,
        store_longitude: -78.502313,
    },
    {
        store_latitdue: -0.125341,
        store_longitude: -78.506455,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.124799,
        store_longitude: -78.507075,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124094,
        store_longitude: -78.50668,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124834,
        store_longitude: -78.510396,
    },
    {
        store_latitdue: -0.1248001,
        store_longitude: -78.5072855,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086199,
        store_longitude: -78.502645,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0854848,
        store_longitude: -78.5062353,
    },
    {
        store_latitdue: -0.0831061,
        store_longitude: -78.504,
    },
    {
        store_latitdue: -0.081044,
        store_longitude: -78.500992,
    },
    {
        store_latitdue: -0.079691,
        store_longitude: -78.500939,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.088532,
        store_longitude: -78.50008,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0853916,
        store_longitude: -78.5046458,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084287,
        store_longitude: -78.505604,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085434,
        store_longitude: -78.504237,
    },
    {
        store_latitdue: -0.0925638,
        store_longitude: -78.4989,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.057467,
        store_longitude: -78.457666,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0575718,
        store_longitude: -78.4567679,
    },
    {
        store_latitdue: -0.060935,
        store_longitude: -78.457324,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.063479,
        store_longitude: -78.460549,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.058751,
        store_longitude: -78.456013,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.064197,
        store_longitude: -78.460348,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.063882,
        store_longitude: -78.461585,
    },
    {
        store_latitdue: -0.061237,
        store_longitude: -78.456939,
    },
    {
        store_latitdue: -0.0594838,
        store_longitude: -78.4579118,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.057638031430542,
        store_longitude: -78.467411259189,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.064108,
        store_longitude: -78.461856,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0594874,
        store_longitude: -78.457894,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0621695,
        store_longitude: -78.45854,
    },
    {
        store_latitdue: -0.062762,
        store_longitude: -78.459227,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0637486,
        store_longitude: -78.4613957,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0608364,
        store_longitude: -78.4648523,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.068752,
        store_longitude: -78.469306,
    },
    {
        store_latitdue: -0.2161085,
        store_longitude: -78.50821,
    },
    {
        store_latitdue: -0.21602,
        store_longitude: -78.50817,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21842,
        store_longitude: -78.50816,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2173379,
        store_longitude: -78.5092011,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2166285,
        store_longitude: -78.5142802,
    },
    {
        store_latitdue: -0.21698,
        store_longitude: -78.50778,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218352,
        store_longitude: -78.511562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2207807,
        store_longitude: -78.5110147,
    },
    {
        store_latitdue: -0.2178515,
        store_longitude: -78.5101801,
    },
    {
        store_latitdue: -0.218996,
        store_longitude: -78.507637,
    },
    {
        store_latitdue: -0.1943899,
        store_longitude: -78.4957696,
    },
    {
        store_latitdue: -0.198702,
        store_longitude: -78.498396,
    },
    {
        store_latitdue: -0.19974913448095,
        store_longitude: -78.498484147713,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1959346,
        store_longitude: -78.4994684,
    },
    {
        store_latitdue: -0.200243,
        store_longitude: -78.497521,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148408,
        store_longitude: -78.4904927,
    },
    {
        store_latitdue: -0.196138,
        store_longitude: -78.4977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.196605,
        store_longitude: -78.498675,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20047831814736,
        store_longitude: -78.49941235967,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.196329,
        store_longitude: -78.4976,
    },
    {
        store_latitdue: -0.196374,
        store_longitude: -78.498657,
    },
    {
        store_latitdue: -0.14739,
        store_longitude: -78.493115,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148205,
        store_longitude: -78.497037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146704,
        store_longitude: -78.493165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145429,
        store_longitude: -78.497568,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15101,
        store_longitude: -78.490656,
    },
    {
        store_latitdue: -0.152907,
        store_longitude: -78.4913005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.149292,
        store_longitude: -78.49146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.149699,
        store_longitude: -78.493431,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1473,
        store_longitude: -78.4929534,
    },
    {
        store_latitdue: -0.14714,
        store_longitude: -78.492134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1449524,
        store_longitude: -78.4940595,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1473995,
        store_longitude: -78.4935523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090808,
        store_longitude: -78.521904,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0916249,
        store_longitude: -78.5248412,
    },
    {
        store_latitdue: -0.0907397,
        store_longitude: -78.5212501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0905499,
        store_longitude: -78.520762,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090665,
        store_longitude: -78.521556,
    },
    {
        store_latitdue: -0.096491,
        store_longitude: -78.529325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0920125,
        store_longitude: -78.5250771,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.001471,
        store_longitude: -78.446169,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.004248,
        store_longitude: -78.44736,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.005192,
        store_longitude: -78.444059,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0029182,
        store_longitude: -78.4421789,
    },
    {
        store_latitdue: -0.004922,
        store_longitude: -78.447077,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.004683,
        store_longitude: -78.444599,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.004307,
        store_longitude: -78.444521,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.006272,
        store_longitude: -78.445253,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2135908,
        store_longitude: -78.5041046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209659,
        store_longitude: -78.503709,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2100487,
        store_longitude: -78.5030222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2092396,
        store_longitude: -78.5052289,
    },
    {
        store_latitdue: -0.209477,
        store_longitude: -78.501752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2134741,
        store_longitude: -78.5043944,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2143541,
        store_longitude: -78.5054347,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2138971,
        store_longitude: -78.5035889,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2147,
        store_longitude: -78.505841,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190472,
        store_longitude: -78.495976,
    },
    {
        store_latitdue: -0.093334,
        store_longitude: -78.478996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1895465,
        store_longitude: -78.4927372,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1921507,
        store_longitude: -78.4970258,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189766,
        store_longitude: -78.4954133,
    },
    {
        store_latitdue: -0.184104,
        store_longitude: -78.494203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.191433,
        store_longitude: -78.494562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1919167,
        store_longitude: -78.4935795,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187599,
        store_longitude: -78.493806,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190961,
        store_longitude: -78.495994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1906338,
        store_longitude: -78.4953945,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1921304,
        store_longitude: -78.4954452,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115522,
        store_longitude: -78.499,
    },
    {
        store_latitdue: -0.120734,
        store_longitude: -78.495656,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117351,
        store_longitude: -78.4978979,
    },
    {
        store_latitdue: -0.118992,
        store_longitude: -78.4940133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11628,
        store_longitude: -78.497389,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1188996,
        store_longitude: -78.499299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1233344,
        store_longitude: -78.494836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1156506,
        store_longitude: -78.497373,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11707,
        store_longitude: -78.497905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11532,
        store_longitude: -78.497468,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1180011,
        store_longitude: -78.4957493,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1200611,
        store_longitude: -78.4956501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1168312,
        store_longitude: -78.4972801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11498333333333,
        store_longitude: -78.497706666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1235832,
        store_longitude: -78.4946524,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121546,
        store_longitude: -78.518328,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1202194,
        store_longitude: -78.5157408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126921,
        store_longitude: -78.522929,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125975,
        store_longitude: -78.522844,
    },
    {
        store_latitdue: -0.12593,
        store_longitude: -78.522841,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123367,
        store_longitude: -78.520314,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127707,
        store_longitude: -78.522753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121396,
        store_longitude: -78.518049,
    },
    {
        store_latitdue: -0.0736489,
        store_longitude: -78.480424,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122074,
        store_longitude: -78.52008,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126934,
        store_longitude: -78.522994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124433,
        store_longitude: -78.520328,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1215453,
        store_longitude: -78.5145061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1231198,
        store_longitude: -78.5175142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126245,
        store_longitude: -78.5229676,
    },
    {
        store_latitdue: -0.1230981,
        store_longitude: -78.517443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123915,
        store_longitude: -78.520302,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105173,
        store_longitude: -78.49419,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.072428,
        store_longitude: -78.479961,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0721991,
        store_longitude: -78.4797511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0727742,
        store_longitude: -78.4828461,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0727217,
        store_longitude: -78.4794625,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.07222,
        store_longitude: -78.480132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.073586,
        store_longitude: -78.478936,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1049963,
        store_longitude: -78.4932649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.073636,
        store_longitude: -78.478987,
    },
    {
        store_latitdue: -0.074744,
        store_longitude: -78.484454,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.000836,
        store_longitude: -78.515719,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0744553,
        store_longitude: -78.4810375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214712,
        store_longitude: -78.515863,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2157011,
        store_longitude: -78.5164313,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21842,
        store_longitude: -78.517008333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2157549,
        store_longitude: -78.515914,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218747,
        store_longitude: -78.517285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2182145,
        store_longitude: -78.5161487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218901,
        store_longitude: -78.518582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215087,
        store_longitude: -78.516232,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.219482,
        store_longitude: -78.517747,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214747,
        store_longitude: -78.5232287,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.219954,
        store_longitude: -78.517073,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2186065,
        store_longitude: -78.5139927,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21635,
        store_longitude: -78.516437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2171542,
        store_longitude: -78.514963,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218955,
        store_longitude: -78.516238333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218939,
        store_longitude: -78.5186033,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.173944,
        store_longitude: -78.488246,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1796585,
        store_longitude: -78.4893695,
    },
    {
        store_latitdue: -0.180118,
        store_longitude: -78.492662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188587,
        store_longitude: -78.4916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.179979,
        store_longitude: -78.491018,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1743873,
        store_longitude: -78.4883083,
    },
    {
        store_latitdue: -0.178546,
        store_longitude: -78.4891631,
    },
    {
        store_latitdue: -0.1741112,
        store_longitude: -78.4941563,
    },
    {
        store_latitdue: -0.113324,
        store_longitude: -78.5091,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1368595,
        store_longitude: -78.5071508,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142634,
        store_longitude: -78.512075,
    },
    {
        store_latitdue: -0.142173,
        store_longitude: -78.514276,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.137523,
        store_longitude: -78.50875,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1407754,
        store_longitude: -78.5096864,
    },
    {
        store_latitdue: -0.1030674,
        store_longitude: -78.5122621,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14083,
        store_longitude: -78.509655,
    },
    {
        store_latitdue: -0.141284,
        store_longitude: -78.512399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142705,
        store_longitude: -78.516448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082797,
        store_longitude: -78.509377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0836838,
        store_longitude: -78.5048,
    },

    {
        store_latitdue: -0.0815763,
        store_longitude: -78.5045,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084165,
        store_longitude: -78.50705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0836506,
        store_longitude: -78.50996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0871751,
        store_longitude: -78.5073655,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.080425,
        store_longitude: -78.501999,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0821077,
        store_longitude: -78.50427,
    },
    {
        store_latitdue: -0.08402,
        store_longitude: -78.505478,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.000575,
        store_longitude: -78.5145,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0010123,
        store_longitude: -78.516877,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.215718,
        store_longitude: -78.5217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21104,
        store_longitude: -78.5232,
    },
    {
        store_latitdue: -0.211392,
        store_longitude: -78.522043,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2124361,
        store_longitude: -78.5240171,
    },
    {
        store_latitdue: -0.213201,
        store_longitude: -78.522371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215976,
        store_longitude: -78.521355,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21579608,
        store_longitude: -78.52155484,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21155,
        store_longitude: -78.523297,
    },
    {
        store_latitdue: -0.2104374,
        store_longitude: -78.5116872,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2153727,
        store_longitude: -78.5216583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.203445,
        store_longitude: -78.5266617,
    },
    {
        store_latitdue: -0.21336,
        store_longitude: -78.524738,
    },
    {
        store_latitdue: -0.2154471,
        store_longitude: -78.5190006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2145426,
        store_longitude: -78.5214323,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2121661,
        store_longitude: -78.5189199,
    },
    {
        store_latitdue: -0.211779,
        store_longitude: -78.522279,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2136956,
        store_longitude: -78.521791,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2149742,
        store_longitude: -78.5197643,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2153771,
        store_longitude: -78.5189792,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.195968,
        store_longitude: -78.505207,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.202342,
        store_longitude: -78.5083,
    },
    {
        store_latitdue: -0.196655,
        store_longitude: -78.5059834,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.200987,
        store_longitude: -78.510835,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.202508,
        store_longitude: -78.506302,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.197914,
        store_longitude: -78.508311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131001,
        store_longitude: -78.517225,
    },
    {
        store_latitdue: -0.1300094,
        store_longitude: -78.5136773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129472,
        store_longitude: -78.514216,
    },
    {
        store_latitdue: -0.129174,
        store_longitude: -78.51194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125653,
        store_longitude: -78.5110553,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1305574,
        store_longitude: -78.5160681,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129281,
        store_longitude: -78.515851,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1300834,
        store_longitude: -78.510981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127191,
        store_longitude: -78.515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129591,
        store_longitude: -78.51149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129049,
        store_longitude: -78.513295,
    },
    {
        store_latitdue: -0.1308055,
        store_longitude: -78.5130227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1272731,
        store_longitude: -78.5146311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1280529,
        store_longitude: -78.5185504,
    },
    {
        store_latitdue: -0.128418,
        store_longitude: -78.519619,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089147,
        store_longitude: -78.513954,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.087527,
        store_longitude: -78.5091,
    },
    {
        store_latitdue: -0.0863563,
        store_longitude: -78.510543,
    },
    {
        store_latitdue: -0.088511,
        store_longitude: -78.512225,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086735,
        store_longitude: -78.510902,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0876116,
        store_longitude: -78.511304,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0888435,
        store_longitude: -78.512762,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0880123,
        store_longitude: -78.510465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0081022,
        store_longitude: -78.4403,
    },
    {
        store_latitdue: -0.005199,
        store_longitude: -78.443952,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0078111,
        store_longitude: -78.4449554,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0048544,
        store_longitude: -78.4437594,
    },
    {
        store_latitdue: -0.008061,
        store_longitude: -78.445082,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.00458,
        store_longitude: -78.443733,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.000826,
        store_longitude: -78.442799,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.009753,
        store_longitude: -78.444528,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.011006666666667,
        store_longitude: -78.446815,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.006822,
        store_longitude: -78.444545,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0099161,
        store_longitude: -78.4436934,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.003607,
        store_longitude: -78.448719,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.209015,
        store_longitude: -78.524946,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207417,
        store_longitude: -78.526325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208243,
        store_longitude: -78.522951,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209516,
        store_longitude: -78.527583,
    },
    {
        store_latitdue: -0.20898,
        store_longitude: -78.5228551,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207423,
        store_longitude: -78.525025,
    },
    {
        store_latitdue: -0.208635,
        store_longitude: -78.523202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2068423,
        store_longitude: -78.5247022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2072989,
        store_longitude: -78.5250144,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2121977,
        store_longitude: -78.5241925,
    },
    {
        store_latitdue: -0.2120099,
        store_longitude: -78.5241878,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209017,
        store_longitude: -78.5169,
    },
    {
        store_latitdue: -0.209943,
        store_longitude: -78.525336,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207349,
        store_longitude: -78.525006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209962,
        store_longitude: -78.524587,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209856,
        store_longitude: -78.526384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210817,
        store_longitude: -78.524088,
    },
    {
        store_latitdue: -0.2095041,
        store_longitude: -78.527485,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207165,
        store_longitude: -78.504565,
    },
    {
        store_latitdue: -0.207121,
        store_longitude: -78.506232,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2074138,
        store_longitude: -78.5080037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206254,
        store_longitude: -78.5058,
    },
    {
        store_latitdue: -0.2078632,
        store_longitude: -78.5040105,
    },
    {
        store_latitdue: -0.206501,
        store_longitude: -78.506857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.203136,
        store_longitude: -78.503803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2077142,
        store_longitude: -78.5071536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204628,
        store_longitude: -78.502675,
    },
    {
        store_latitdue: -0.206946,
        store_longitude: -78.505355,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20439032,
        store_longitude: -78.50480865,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204605,
        store_longitude: -78.5027,
    },
    {
        store_latitdue: -0.125602,
        store_longitude: -78.510976,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1239958,
        store_longitude: -78.5130695,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125951,
        store_longitude: -78.51226,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12716782,
        store_longitude: -78.51000348,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12794713,
        store_longitude: -78.51201558,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1287928,
        store_longitude: -78.5113869,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127991,
        store_longitude: -78.513058,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1266599,
        store_longitude: -78.5096169,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085336,
        store_longitude: -78.508717,
    },
    {
        store_latitdue: -0.128675,
        store_longitude: -78.509455,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129449,
        store_longitude: -78.508797,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129823,
        store_longitude: -78.510935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128504,
        store_longitude: -78.509648,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1287961,
        store_longitude: -78.5093283,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1259014,
        store_longitude: -78.5138418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1287543,
        store_longitude: -78.5080154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08484,
        store_longitude: -78.51022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0855442,
        store_longitude: -78.509958,
    },
    {
        store_latitdue: -0.083883,
        store_longitude: -78.508899,
    },
    {
        store_latitdue: -0.084426,
        store_longitude: -78.508877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085837,
        store_longitude: -78.508261,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0853907,
        store_longitude: -78.5080936,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086218,
        store_longitude: -78.508127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0841201,
        store_longitude: -78.5079748,
    },
    {
        store_latitdue: -0.105623,
        store_longitude: -78.496546,
    },
    {
        store_latitdue: -0.084773,
        store_longitude: -78.509569,
    },
    {
        store_latitdue: -0.0857728,
        store_longitude: -78.5108675,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0847165,
        store_longitude: -78.5059312,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0856752,
        store_longitude: -78.5095679,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085122,
        store_longitude: -78.5139579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083793,
        store_longitude: -78.50931,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085419,
        store_longitude: -78.513736,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085291,
        store_longitude: -78.513405,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0854523,
        store_longitude: -78.5162063,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0845011,
        store_longitude: -78.5147252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852471,
        store_longitude: -78.5067,
    },
    {
        store_latitdue: -0.06915806,
        store_longitude: -78.471651,
    },
    {
        store_latitdue: -0.06347,
        store_longitude: -78.470002,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.071322,
        store_longitude: -78.473539,
    },
    {
        store_latitdue: -0.063052,
        store_longitude: -78.469841,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.071582,
        store_longitude: -78.472125,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.062971,
        store_longitude: -78.468457,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.070431,
        store_longitude: -78.470992,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.067578,
        store_longitude: -78.461225,
    },
    {
        store_latitdue: -0.0571208,
        store_longitude: -78.461436,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.063035,
        store_longitude: -78.4699,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.067399,
        store_longitude: -78.461359,
    },
    {
        store_latitdue: -0.072242,
        store_longitude: -78.47178,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.062952,
        store_longitude: -78.468054,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.224279,
        store_longitude: -78.507379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2253529,
        store_longitude: -78.5071146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.221726,
        store_longitude: -78.5094386,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2232588,
        store_longitude: -78.5084035,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2246233,
        store_longitude: -78.5081236,
    },
    {
        store_latitdue: -0.2257351,
        store_longitude: -78.5084424,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2259926,
        store_longitude: -78.5085762,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1834268,
        store_longitude: -78.5118071,
    },
    {
        store_latitdue: -0.179128,
        store_longitude: -78.510801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.179536,
        store_longitude: -78.517186,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1841721,
        store_longitude: -78.5111844,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1791002,
        store_longitude: -78.5112124,
    },
    {
        store_latitdue: -0.181535,
        store_longitude: -78.512271,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178976,
        store_longitude: -78.513607,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.182297,
        store_longitude: -78.513236,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178866,
        store_longitude: -78.512487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.177287,
        store_longitude: -78.511115,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.179528,
        store_longitude: -78.516763,
    },
    {
        store_latitdue: -0.179855,
        store_longitude: -78.5165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.177381,
        store_longitude: -78.511876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17775833333333,
        store_longitude: -78.511235,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.177622,
        store_longitude: -78.5125,
    },
    {
        store_latitdue: -0.181893,
        store_longitude: -78.516163,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178048,
        store_longitude: -78.508287,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178012,
        store_longitude: -78.510222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1798245,
        store_longitude: -78.5127422,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127751,
        store_longitude: -78.495444,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129307,
        store_longitude: -78.4964,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129557,
        store_longitude: -78.510188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12897,
        store_longitude: -78.494139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126529,
        store_longitude: -78.496095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1243337,
        store_longitude: -78.4985489,
    },
    {
        store_latitdue: -0.1276458,
        store_longitude: -78.4965524,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127492,
        store_longitude: -78.501923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126631,
        store_longitude: -78.496037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1265875,
        store_longitude: -78.4942982,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122257,
        store_longitude: -78.498497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1290857,
        store_longitude: -78.4993428,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126568,
        store_longitude: -78.494208,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1291203,
        store_longitude: -78.4977514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126545,
        store_longitude: -78.501983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096011,
        store_longitude: -78.517806,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102647,
        store_longitude: -78.524435,
    },
    {
        store_latitdue: -0.101482,
        store_longitude: -78.520474,
    },
    {
        store_latitdue: -0.1024933,
        store_longitude: -78.52472,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.098437,
        store_longitude: -78.518346,
    },
    {
        store_latitdue: -0.0957573,
        store_longitude: -78.5198,
    },
    {
        store_latitdue: -0.102082,
        store_longitude: -78.52123,
    },
    {
        store_latitdue: -0.0958207,
        store_longitude: -78.5204274,
    },
    {
        store_latitdue: -0.0076895,
        store_longitude: -78.4474224,
    },
    {
        store_latitdue: -0.008162,
        store_longitude: -78.447406,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.014964,
        store_longitude: -78.44741,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0086608,
        store_longitude: -78.4460912,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.007053,
        store_longitude: -78.448336,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0058878,
        store_longitude: -78.4505475,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.007342,
        store_longitude: -78.448007,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0423349,
        store_longitude: -78.4444,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2252758,
        store_longitude: -78.3407751,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.1983825,
        store_longitude: -78.5002016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1108436,
        store_longitude: -78.4940226,
    },
    {
        store_latitdue: -0.0951001,
        store_longitude: -78.5002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2095646,
        store_longitude: -78.5052906,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1896893,
        store_longitude: -78.4955356,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1030412,
        store_longitude: -78.510277,
    },
    {
        store_latitdue: -0.0689636,
        store_longitude: -78.46165,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.06794,
        store_longitude: -78.461322,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.19784,
        store_longitude: -78.5114,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.196475,
        store_longitude: -78.5098,
    },
    {
        store_latitdue: -0.1963178,
        store_longitude: -78.5038506,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12938156,
        store_longitude: -78.51165143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1297103,
        store_longitude: -78.5113089,
    },
    {
        store_latitdue: -0.129309,
        store_longitude: -78.5149,
    },
    {
        store_latitdue: -0.0852663,
        store_longitude: -78.5116207,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0863384,
        store_longitude: -78.507693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0860777,
        store_longitude: -78.507841,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2171454,
        store_longitude: -78.5149282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214999,
        store_longitude: -78.515659,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1337528,
        store_longitude: -78.5026107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210386,
        store_longitude: -78.511929,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1397556,
        store_longitude: -78.5003015,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092013808898628,
        store_longitude: -78.525001471862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0908926,
        store_longitude: -78.5230081,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.060543,
        store_longitude: -78.466715,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.05925,
        store_longitude: -78.462556,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.209199,
        store_longitude: -78.505497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2296653,
        store_longitude: -78.5094399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18812585,
        store_longitude: -78.5160267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1582273,
        store_longitude: -78.4369649,
    },
    {
        store_latitdue: -0.0947086,
        store_longitude: -78.5023084,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093242,
        store_longitude: -78.502763,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2190712,
        store_longitude: -78.5175252,
    },
    {
        store_latitdue: -0.177651,
        store_longitude: -78.489464,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0839492,
        store_longitude: -78.5076573,
    },
    {
        store_latitdue: -0.0817,
        store_longitude: -78.50404,
    },
    {
        store_latitdue: -0.11351518,
        store_longitude: -78.48973958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083585,
        store_longitude: -78.504609,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.000611,
        store_longitude: -78.513133,
    },
    {
        store_latitdue: -0.000798,
        store_longitude: -78.513931,
    },
    {
        store_latitdue: -0.2090562,
        store_longitude: -78.509702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189418,
        store_longitude: -78.503873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187192,
        store_longitude: -78.498022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119912,
        store_longitude: -78.507809,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095031,
        store_longitude: -78.508155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091171,
        store_longitude: -78.5062752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0000874,
        store_longitude: -78.4583802,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.000973,
        store_longitude: -78.450762,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.183689,
        store_longitude: -78.5102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.183589,
        store_longitude: -78.5102,
    },
    {
        store_latitdue: -0.1023,
        store_longitude: -78.5182143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0997562,
        store_longitude: -78.5209,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0937358,
        store_longitude: -78.5166064,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213218,
        store_longitude: -78.523107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187813,
        store_longitude: -78.509391,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1907428,
        store_longitude: -78.5083538,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0894079,
        store_longitude: -78.5143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1292764,
        store_longitude: -78.49604,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0079061,
        store_longitude: -78.4472852,
    },
    {
        store_latitdue: -0.0089882,
        store_longitude: -78.4458201,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.184828,
        store_longitude: -78.5077,
    },
    {
        store_latitdue: -0.124111,
        store_longitude: -78.502129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120278,
        store_longitude: -78.499761,
    },
    {
        store_latitdue: -0.1056379,
        store_longitude: -78.4949091,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.054608333333333,
        store_longitude: -78.455505,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2100816,
        store_longitude: -78.52215635,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2198395,
        store_longitude: -78.5187965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.194971,
        store_longitude: -78.5029,
    },
    {
        store_latitdue: -0.1818835,
        store_longitude: -78.5160581,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0925029,
        store_longitude: -78.5026,
    },
    {
        store_latitdue: -0.155381,
        store_longitude: -78.503102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.177786,
        store_longitude: -78.511906,
    },
    {
        store_latitdue: -0.123631,
        store_longitude: -78.5005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1177232,
        store_longitude: -78.4999357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1295874,
        store_longitude: -78.5014717,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1392852,
        store_longitude: -78.50901,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1484384,
        store_longitude: -78.5061382,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129265,
        store_longitude: -78.513088,
    },
    {
        store_latitdue: -0.0964748,
        store_longitude: -78.4496419,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.140013,
        store_longitude: -78.499821,
    },
    {
        store_latitdue: -0.088663,
        store_longitude: -78.503302,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.097943,
        store_longitude: -78.5159,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126881,
        store_longitude: -78.5131,
    },
    {
        store_latitdue: -0.0914319,
        store_longitude: -78.5121312,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0900001,
        store_longitude: -78.5163942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0892388,
        store_longitude: -78.513835,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0853073,
        store_longitude: -78.5144369,
    },
    {
        store_latitdue: -0.0889538,
        store_longitude: -78.5132648,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086733,
        store_longitude: -78.510902,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0887709,
        store_longitude: -78.5104,
    },
    {
        store_latitdue: -0.0872561,
        store_longitude: -78.5056437,
    },
    {
        store_latitdue: -0.123884,
        store_longitude: -78.520211,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.088381,
        store_longitude: -78.499603,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091293,
        store_longitude: -78.4992,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0076132,
        store_longitude: -78.4475269,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.007515,
        store_longitude: -78.440326666667,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.00689,
        store_longitude: -78.4449178,
    },
    {
        store_latitdue: -0.2105708,
        store_longitude: -78.5134352,
    },
    {
        store_latitdue: -0.084790539242296,
        store_longitude: -78.429608095145,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.2257119,
        store_longitude: -78.5117288,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2090821,
        store_longitude: -78.5215235,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2159869,
        store_longitude: -78.5159012,
    },
    {
        store_latitdue: -0.196477,
        store_longitude: -78.499,
    },
    {
        store_latitdue: -0.18431277,
        store_longitude: -78.51258143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2102317,
        store_longitude: -78.5088032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1886421,
        store_longitude: -78.5137703,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130221,
        store_longitude: -78.503215,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123866,
        store_longitude: -78.508479,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083832,
        store_longitude: -78.505096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0957675,
        store_longitude: -78.5257432,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092471,
        store_longitude: -78.509805,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110351,
        store_longitude: -78.508305,
    },
    {
        store_latitdue: -0.0867174,
        store_longitude: -78.504915,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095749,
        store_longitude: -78.519096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18829228,
        store_longitude: -78.50505862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0924821,
        store_longitude: -78.4990141,
    },
    {
        store_latitdue: -0.050143,
        store_longitude: -78.460405,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0049101,
        store_longitude: -78.4461577,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0574522,
        store_longitude: -78.4580573,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.05667,
        store_longitude: -78.455053,
    },
    {
        store_latitdue: -0.062952,
        store_longitude: -78.469698,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.207956,
        store_longitude: -78.526907,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0444455,
        store_longitude: -78.4494,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1385835,
        store_longitude: -78.5075049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13052617,
        store_longitude: -78.49821484,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.133543,
        store_longitude: -78.498665,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.06288243,
        store_longitude: -78.46413241,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.053802,
        store_longitude: -78.454774,
    },
    {
        store_latitdue: -0.211793,
        store_longitude: -78.5202,
    },
    {
        store_latitdue: -0.216655,
        store_longitude: -78.5086361,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2157489,
        store_longitude: -78.5101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20719574,
        store_longitude: -78.5207435,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225568,
        store_longitude: -78.5116,
    },
    {
        store_latitdue: -0.210992,
        store_longitude: -78.511948,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.191374,
        store_longitude: -78.497326,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1224039,
        store_longitude: -78.512963,
    },
    {
        store_latitdue: -0.128083,
        store_longitude: -78.5129,
    },
    {
        store_latitdue: -0.094449,
        store_longitude: -78.499321,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08415205,
        store_longitude: -78.50927793,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.008202,
        store_longitude: -78.438877,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.008393,
        store_longitude: -78.438785,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.012619,
        store_longitude: -78.448259,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0687692,
        store_longitude: -78.4618292,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2157722,
        store_longitude: -78.5129022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20616,
        store_longitude: -78.504178,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129592,
        store_longitude: -78.513827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.055342,
        store_longitude: -78.463506,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.12817484,
        store_longitude: -78.46353379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0015458,
        store_longitude: -78.4471396,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.215918,
        store_longitude: -78.5091851,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2111273,
        store_longitude: -78.5104531,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1850523,
        store_longitude: -78.5137644,
    },
    {
        store_latitdue: -0.1281919,
        store_longitude: -78.4635559,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1904322,
        store_longitude: -78.509233,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0870648,
        store_longitude: -78.4307101,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.218987,
        store_longitude: -78.5099706,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1908849,
        store_longitude: -78.5082404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1459421,
        store_longitude: -78.4982448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1762087,
        store_longitude: -78.4954649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0075068,
        store_longitude: -78.4498058,
    },
    {
        store_latitdue: -0.09614,
        store_longitude: -78.512813333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2188,
        store_longitude: -78.50774,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187074,
        store_longitude: -78.5165,
    },
    {
        store_latitdue: -0.2146,
        store_longitude: -78.523299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2132946,
        store_longitude: -78.5060906,
    },
    {
        store_latitdue: -0.1649703,
        store_longitude: -78.479709,
    },
    {
        store_latitdue: -0.1260992,
        store_longitude: -78.51395,
    },
    {
        store_latitdue: -0.0878176,
        store_longitude: -78.5100076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.076042,
        store_longitude: -78.478718,
    },
    {
        store_latitdue: -0.204475,
        store_longitude: -78.5047,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084704,
        store_longitude: -78.514815,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0951297,
        store_longitude: -78.5120095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2119557,
        store_longitude: -78.5029974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209698,
        store_longitude: -78.516928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207511,
        store_longitude: -78.511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1235,
        store_longitude: -78.4988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.100423,
        store_longitude: -78.5226,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0883232,
        store_longitude: -78.5131184,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.007941,
        store_longitude: -78.4478145,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.171575,
        store_longitude: -78.488793333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0876917,
        store_longitude: -78.5084131,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.217671,
        store_longitude: -78.5077,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126377,
        store_longitude: -78.508805,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1147392,
        store_longitude: -78.5190333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18972744,
        store_longitude: -78.51808483,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1888314,
        store_longitude: -78.5181988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126808,
        store_longitude: -78.515108,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12824718,
        store_longitude: -78.51774783,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1284324,
        store_longitude: -78.5192652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0998914,
        store_longitude: -78.5212182,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090981,
        store_longitude: -78.5225,
    },
    {
        store_latitdue: -0.0343292,
        store_longitude: -78.4402809,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.217297,
        store_longitude: -78.521544,
    },
    {
        store_latitdue: -0.217042,
        store_longitude: -78.521994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.137572,
        store_longitude: -78.5053,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115223,
        store_longitude: -78.49883,
    },
    {
        store_latitdue: -0.1052146,
        store_longitude: -78.5267109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0966316,
        store_longitude: -78.5198403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089173,
        store_longitude: -78.502419,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0849978,
        store_longitude: -78.5053753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103548,
        store_longitude: -78.522003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.074383,
        store_longitude: -78.477556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220322,
        store_longitude: -78.509562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12687149,
        store_longitude: -78.51290583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092039,
        store_longitude: -78.507,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0861031,
        store_longitude: -78.5041015,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1594319,
        store_longitude: -78.5042292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210058,
        store_longitude: -78.514885,
    },
    {
        store_latitdue: -0.0929678,
        store_longitude: -78.510842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2132722,
        store_longitude: -78.5061914,
    },
    {
        store_latitdue: -0.188013,
        store_longitude: -78.5165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.052453,
        store_longitude: -78.45588,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0170902,
        store_longitude: -78.4385,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0038514,
        store_longitude: -78.4410793,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0638379,
        store_longitude: -78.4615218,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0612161,
        store_longitude: -78.4578149,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.063176,
        store_longitude: -78.459873,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2164852,
        store_longitude: -78.5130597,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0026061,
        store_longitude: -78.4430789,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0064683,
        store_longitude: -78.4496139,
    },
    {
        store_latitdue: -0.12073,
        store_longitude: -78.494,
    },
    {
        store_latitdue: -2.89748,
        store_longitude: -78.9977,
    },
    {
        store_latitdue: 0.0058179,
        store_longitude: -78.44975,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.208092,
        store_longitude: -78.5048,
    },
    {
        store_latitdue: -0.2292277,
        store_longitude: -78.5071013,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.062858,
        store_longitude: -78.468238,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.067272,
        store_longitude: -78.4651,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0670212,
        store_longitude: -78.4638221,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.072322,
        store_longitude: -78.471792,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.010016,
        store_longitude: -78.448723,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.110983,
        store_longitude: -78.494064,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0044732,
        store_longitude: -78.4408599,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.226359,
        store_longitude: -78.506719,
    },
    {
        store_latitdue: -0.2085411,
        store_longitude: -78.5034661,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.00841319,
        store_longitude: -78.4469,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1272076,
        store_longitude: -78.5130235,
    },
    {
        store_latitdue: -0.063059,
        store_longitude: -78.520103,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.0695411,
        store_longitude: -78.4695879,
    },
    {
        store_latitdue: -0.09077,
        store_longitude: -78.5214838,
    },
    {
        store_latitdue: -0.0958421,
        store_longitude: -78.5180647,
    },
    {
        store_latitdue: -0.0843874,
        store_longitude: -78.5067343,
    },
    {
        store_latitdue: -0.122264,
        store_longitude: -78.5127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0886319,
        store_longitude: -78.5145983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0905679,
        store_longitude: -78.5215517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128837,
        store_longitude: -78.5123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1277861,
        store_longitude: -78.518686,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.136429,
        store_longitude: -78.5072,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089169,
        store_longitude: -78.5053252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0148798,
        store_longitude: -78.4759,
    },
    {
        store_latitdue: -0.1232424,
        store_longitude: -78.5105263,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092575,
        store_longitude: -78.498901,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1359757,
        store_longitude: -78.4971647,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093189,
        store_longitude: -78.529501,
    },
    {
        store_latitdue: -0.0954148,
        store_longitude: -78.5300973,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0634444,
        store_longitude: -78.5194847,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.11498057,
        store_longitude: -78.49500763,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125901,
        store_longitude: -78.5061,
    },
    {
        store_latitdue: -0.123774,
        store_longitude: -78.496957,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091137,
        store_longitude: -78.529542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0972402,
        store_longitude: -78.5112278,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1096228,
        store_longitude: -78.4985824,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0912472,
        store_longitude: -78.5240894,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.014657,
        store_longitude: -78.4495,
    },
    {
        store_latitdue: -0.0337289,
        store_longitude: -78.4475921,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.02792,
        store_longitude: -78.4378,
    },
    {
        store_latitdue: 0.002744,
        store_longitude: -78.445718,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.12421309947968,
        store_longitude: -78.507104516029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14001,
        store_longitude: -78.499812,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2055479,
        store_longitude: -78.4999048,
    },
    {
        store_latitdue: -0.1267109,
        store_longitude: -78.5124344,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0865768,
        store_longitude: -78.5111994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189504,
        store_longitude: -78.5107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0992664,
        store_longitude: -78.5139981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11369688,
        store_longitude: -78.48954565,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.049021666666667,
        store_longitude: -78.456225,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2247039,
        store_longitude: -78.5125105,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.194887,
        store_longitude: -78.5087,
    },
    {
        store_latitdue: -0.1265036,
        store_longitude: -78.49411725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103247,
        store_longitude: -78.5107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0130451,
        store_longitude: -78.4770201,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.19993221172256,
        store_longitude: -78.499102633311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110171,
        store_longitude: -78.518196,
    },
    {
        store_latitdue: -0.0968862,
        store_longitude: -78.5090272,
    },
    {
        store_latitdue: -0.0947438,
        store_longitude: -78.4996906,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207997,
        store_longitude: -78.526916,
    },
    {
        store_latitdue: -0.134043,
        store_longitude: -78.505,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140706,
        store_longitude: -78.5104,
    },
    {
        store_latitdue: -0.184104,
        store_longitude: -78.494203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143899,
        store_longitude: -78.4975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0026762,
        store_longitude: -78.4451,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1807394,
        store_longitude: -78.496056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.141851,
        store_longitude: -78.5027,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223719,
        store_longitude: -78.511292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1562796,
        store_longitude: -78.5010039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0910372,
        store_longitude: -78.5154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1540681,
        store_longitude: -78.4920497,
    },
    {
        store_latitdue: -0.102921,
        store_longitude: -78.520905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204565,
        store_longitude: -78.5039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.215925,
        store_longitude: -78.429073333333,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.069696,
        store_longitude: -78.469721,
        neighborhood: 'CUMBAYÁ',
    },
    {
        store_latitdue: -0.055867,
        store_longitude: -78.458182,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0844327,
        store_longitude: -78.506486,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1406587,
        store_longitude: -78.508522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0034772,
        store_longitude: -78.4538962,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.012911,
        store_longitude: -78.4495,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2082304,
        store_longitude: -78.500799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206151,
        store_longitude: -78.500681,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207962,
        store_longitude: -78.5054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206911,
        store_longitude: -78.500227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206657,
        store_longitude: -78.5060725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190733,
        store_longitude: -78.4959,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19155228,
        store_longitude: -78.49725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1432393,
        store_longitude: -78.508309,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13489,
        store_longitude: -78.493657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138968,
        store_longitude: -78.493535,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13712,
        store_longitude: -78.493305,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145958,
        store_longitude: -78.491561,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1456199,
        store_longitude: -78.505461,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1297143,
        store_longitude: -78.5135464,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128892,
        store_longitude: -78.5195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126472,
        store_longitude: -78.5151881,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1312554,
        store_longitude: -78.5110471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130497,
        store_longitude: -78.5116,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1311761,
        store_longitude: -78.5103135,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1258375,
        store_longitude: -78.5101662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128753,
        store_longitude: -78.5147,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1257458,
        store_longitude: -78.5162564,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128343,
        store_longitude: -78.5154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1285284,
        store_longitude: -78.5083197,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1286798,
        store_longitude: -78.5160445,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12578,
        store_longitude: -78.512026,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1303135,
        store_longitude: -78.512633,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1251141,
        store_longitude: -78.5154739,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129697,
        store_longitude: -78.512131,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130029,
        store_longitude: -78.511023,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12626562,
        store_longitude: -78.5109895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1270764,
        store_longitude: -78.5098264,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130233,
        store_longitude: -78.513167,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1283195,
        store_longitude: -78.5178764,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1286307,
        store_longitude: -78.5134924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129724,
        store_longitude: -78.511352,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1257754,
        store_longitude: -78.5101048,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12863004,
        store_longitude: -78.5116565,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1313392,
        store_longitude: -78.512405,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131154,
        store_longitude: -78.5129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184908,
        store_longitude: -78.493,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.162704,
        store_longitude: -78.4870363,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.00563535,
        store_longitude: -78.4570388,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.130182,
        store_longitude: -78.5170131,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0151067,
        store_longitude: -78.435495,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0973867,
        store_longitude: -78.5141296,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1880329,
        store_longitude: -78.5149949,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0870739,
        store_longitude: -78.500748,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.056759,
        store_longitude: -78.4616797,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0577904,
        store_longitude: -78.5198837,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.0984947,
        store_longitude: -78.5295154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114805,
        store_longitude: -78.508603,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.191831,
        store_longitude: -78.4935417,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20096,
        store_longitude: -78.4973414,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1899696,
        store_longitude: -78.5080632,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193589,
        store_longitude: -78.4965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1234922,
        store_longitude: -78.513374,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0075005,
        store_longitude: -78.5139179,
        neighborhood: 'CALACALÍ',
    },

    {
        store_latitdue: 0.0033676,
        store_longitude: -78.5403424,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0913529,
        store_longitude: -78.5027227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09237802,
        store_longitude: -78.5029144,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0917526,
        store_longitude: -78.507355,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0896985,
        store_longitude: -78.5036,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.192546,
        store_longitude: -78.497922,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0178424,
        store_longitude: -78.4749,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0146752,
        store_longitude: -78.4799809,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0638431,
        store_longitude: -78.5082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0620037,
        store_longitude: -78.5066,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0534724,
        store_longitude: -78.5165172,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.0646278,
        store_longitude: -78.5093527,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0828029,
        store_longitude: -78.5288179,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2177585,
        store_longitude: -78.5106393,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2181113,
        store_longitude: -78.5120938,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2169056,
        store_longitude: -78.5077648,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22238916,
        store_longitude: -78.5147018,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2188479,
        store_longitude: -78.5137033,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.219375,
        store_longitude: -78.5087,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2169517,
        store_longitude: -78.5111804,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22032281,
        store_longitude: -78.5097062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2231974,
        store_longitude: -78.5132019,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215991,
        store_longitude: -78.5126,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220471,
        store_longitude: -78.5121,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2195233,
        store_longitude: -78.5117111,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2208489,
        store_longitude: -78.5132315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2186443,
        store_longitude: -78.5079661,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.155256,
        store_longitude: -78.488582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1554086,
        store_longitude: -78.4919981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.159642,
        store_longitude: -78.487634,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.157896,
        store_longitude: -78.4883,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1557396,
        store_longitude: -78.4899375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1578966,
        store_longitude: -78.4883386,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.158347,
        store_longitude: -78.488187,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.160515,
        store_longitude: -78.5098472,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238868,
        store_longitude: -78.5122892,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.160139,
        store_longitude: -78.506803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145159,
        store_longitude: -78.503537,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.162869,
        store_longitude: -78.4978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126826,
        store_longitude: -78.5049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.160098,
        store_longitude: -78.5042363,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1550613,
        store_longitude: -78.5042275,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.160398,
        store_longitude: -78.509556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.159285,
        store_longitude: -78.507,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1592354,
        store_longitude: -78.5082996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0934013,
        store_longitude: -78.5049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0969628,
        store_longitude: -78.5143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094535,
        store_longitude: -78.513568,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0923539,
        store_longitude: -78.5067,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089189,
        store_longitude: -78.506544,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096348,
        store_longitude: -78.513737,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093099,
        store_longitude: -78.502078,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0958917,
        store_longitude: -78.5165525,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0937464,
        store_longitude: -78.5015549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09575,
        store_longitude: -78.51304,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095199,
        store_longitude: -78.507584,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092933,
        store_longitude: -78.5108695,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0963456,
        store_longitude: -78.5143073,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0921903,
        store_longitude: -78.5024,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189564,
        store_longitude: -78.5131,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1866517,
        store_longitude: -78.5146555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1881836,
        store_longitude: -78.5161596,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1884029,
        store_longitude: -78.5147132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188744,
        store_longitude: -78.517276,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1888339,
        store_longitude: -78.5089608,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15657,
        store_longitude: -78.4914534,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1562787,
        store_longitude: -78.4885487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1559218,
        store_longitude: -78.489037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1559504,
        store_longitude: -78.4893164,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103511,
        store_longitude: -78.502491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1037033,
        store_longitude: -78.5002379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.111268,
        store_longitude: -78.49418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1036836,
        store_longitude: -78.4931327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0791239,
        store_longitude: -78.5032958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127218,
        store_longitude: -78.513,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11575868,
        store_longitude: -78.4949798,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11698462,
        store_longitude: -78.4978942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116727,
        store_longitude: -78.4943,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117745,
        store_longitude: -78.49583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114567,
        store_longitude: -78.496502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1148465,
        store_longitude: -78.494953,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1174386,
        store_longitude: -78.4971607,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114436,
        store_longitude: -78.4942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1158612,
        store_longitude: -78.4961315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11573623,
        store_longitude: -78.4949252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115446,
        store_longitude: -78.4964,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1169858,
        store_longitude: -78.4920414,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11859814,
        store_longitude: -78.4999309,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1055612,
        store_longitude: -78.4904791,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124677,
        store_longitude: -78.494,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1200456,
        store_longitude: -78.504081,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112713,
        store_longitude: -78.494642,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11931557,
        store_longitude: -78.4939575,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11877919,
        store_longitude: -78.494365,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1192627,
        store_longitude: -78.4983077,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1165493,
        store_longitude: -78.4943262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1118129,
        store_longitude: -78.4928974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11953,
        store_longitude: -78.4958133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1197641,
        store_longitude: -78.4955207,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105205,
        store_longitude: -78.497764,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.159724,
        store_longitude: -78.4913,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110161,
        store_longitude: -78.4913,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.106525,
        store_longitude: -78.494153,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1028483,
        store_longitude: -78.4893166,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102672,
        store_longitude: -78.4974006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1040476,
        store_longitude: -78.4930811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1077125,
        store_longitude: -78.5035257,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0910324593,
        store_longitude: -78.5173636302,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.104345,
        store_longitude: -78.4973,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.106042,
        store_longitude: -78.491917,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107544,
        store_longitude: -78.5063117,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10486358,
        store_longitude: -78.4910166,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110888,
        store_longitude: -78.494025,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1078673,
        store_longitude: -78.4969064,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0950122,
        store_longitude: -78.4992552,
    },
    {
        store_latitdue: -0.0838233,
        store_longitude: -78.5044,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.203593,
        store_longitude: -78.499197,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1241939,
        store_longitude: -78.5071378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1497561,
        store_longitude: -78.5031845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148205,
        store_longitude: -78.497037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216459,
        store_longitude: -78.5193,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2152938,
        store_longitude: -78.5216195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12791444,
        store_longitude: -78.5043948,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2125856,
        store_longitude: -78.5168607,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2160588,
        store_longitude: -78.5145733,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1243331,
        store_longitude: -78.5097723,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147078,
        store_longitude: -78.4909,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1427337,
        store_longitude: -78.4948,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146776,
        store_longitude: -78.49377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1908572,
        store_longitude: -78.508184,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1973928,
        store_longitude: -78.5062416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17228087,
        store_longitude: -78.4924087,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1896053,
        store_longitude: -78.5179542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2137237,
        store_longitude: -78.5194827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208732,
        store_longitude: -78.524875,
    },
    {
        store_latitdue: -0.2038899,
        store_longitude: -78.5251927,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.196335,
        store_longitude: -78.4989,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1883362,
        store_longitude: -78.5112898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184992,
        store_longitude: -78.5117,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189897,
        store_longitude: -78.510082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1849837,
        store_longitude: -78.5135905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1837677,
        store_longitude: -78.5138985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1893234,
        store_longitude: -78.5113086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1893405,
        store_longitude: -78.5128905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0912451,
        store_longitude: -78.2841,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.189306,
        store_longitude: -78.518208,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1887242,
        store_longitude: -78.5134805,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1903112,
        store_longitude: -78.5129204,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190346,
        store_longitude: -78.5098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1886082,
        store_longitude: -78.5109079,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18837325,
        store_longitude: -78.5147247,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186014,
        store_longitude: -78.5155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1875438,
        store_longitude: -78.5120471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1900942,
        store_longitude: -78.5169359,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186804,
        store_longitude: -78.5178,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184402,
        store_longitude: -78.51381,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.190684,
        store_longitude: -78.5085,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1845418,
        store_longitude: -78.5119958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1544611,
        store_longitude: -78.4892412,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1488575,
        store_longitude: -78.4895658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1499782,
        store_longitude: -78.4904822,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1500662,
        store_longitude: -78.4908581,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146609,
        store_longitude: -78.4929594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13649164,
        store_longitude: -78.4934692,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.180957,
        store_longitude: -78.4938436,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1482208,
        store_longitude: -78.4910965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1586633,
        store_longitude: -78.4877548,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.154532,
        store_longitude: -78.4897,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1523543,
        store_longitude: -78.492567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.154097,
        store_longitude: -78.4893,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147795,
        store_longitude: -78.4909,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1570699,
        store_longitude: -78.488495,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15719,
        store_longitude: -78.4883,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.158347,
        store_longitude: -78.488187,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139515,
        store_longitude: -78.4978559,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156631,
        store_longitude: -78.4885,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113795,
        store_longitude: -78.4912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1113276,
        store_longitude: -78.4940874,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114535,
        store_longitude: -78.4922138,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1434462,
        store_longitude: -78.4958707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143176,
        store_longitude: -78.494942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143272,
        store_longitude: -78.4949,
    },
    {
        store_latitdue: -0.145206,
        store_longitude: -78.508176,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143351,
        store_longitude: -78.495005,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1427926,
        store_longitude: -78.4977589,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1429107,
        store_longitude: -78.4931305,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144305,
        store_longitude: -78.4979,
    },
    {
        store_latitdue: -0.146185,
        store_longitude: -78.49486,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.141579,
        store_longitude: -78.492357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1443138,
        store_longitude: -78.494529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.195247,
        store_longitude: -78.508779,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.195945,
        store_longitude: -78.5053,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.196001,
        store_longitude: -78.5046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1979571,
        store_longitude: -78.5086098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1954622,
        store_longitude: -78.506972,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1959575,
        store_longitude: -78.5055183,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1953585,
        store_longitude: -78.5070603,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1946002,
        store_longitude: -78.5108069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193299,
        store_longitude: -78.506876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1942502,
        store_longitude: -78.5074838,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19063717,
        store_longitude: -78.4954833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12031,
        store_longitude: -78.506049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1181509,
        store_longitude: -78.5047818,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118218,
        store_longitude: -78.505461,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122046,
        store_longitude: -78.50335,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115528,
        store_longitude: -78.5011,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117284,
        store_longitude: -78.5034,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127967,
        store_longitude: -78.514465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2272731,
        store_longitude: -78.5073771,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22547,
        store_longitude: -78.507129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2236955,
        store_longitude: -78.5074589,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226286,
        store_longitude: -78.507,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2247559,
        store_longitude: -78.5071658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112247,
        store_longitude: -78.5084,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118527,
        store_longitude: -78.5133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115297,
        store_longitude: -78.4953,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0259339,
        store_longitude: -78.4497192,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0958655,
        store_longitude: -78.5177535,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0978907,
        store_longitude: -78.5188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093627761,
        store_longitude: -78.51729784,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094903,
        store_longitude: -78.5153,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0960205,
        store_longitude: -78.5145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0972008,
        store_longitude: -78.5181574,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0982029,
        store_longitude: -78.5211132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12935847,
        store_longitude: -78.4961399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.132802,
        store_longitude: -78.494,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.183679,
        store_longitude: -78.5101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13934,
        store_longitude: -78.507614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140884,
        store_longitude: -78.509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1368333,
        store_longitude: -78.5085847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.134046,
        store_longitude: -78.5064,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1415302,
        store_longitude: -78.5056127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085224478,
        store_longitude: -78.50623917,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0844054,
        store_longitude: -78.5037444,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0832157,
        store_longitude: -78.5095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0963895,
        store_longitude: -78.5260163,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.16241773,
        store_longitude: -78.4872436,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0953334,
        store_longitude: -78.5130693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0016144,
        store_longitude: -78.4587664,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0023917,
        store_longitude: -78.4609,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.18983915,
        store_longitude: -78.4991977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187259,
        store_longitude: -78.5027,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188619,
        store_longitude: -78.50342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184435,
        store_longitude: -78.508602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.194795,
        store_longitude: -78.49799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.185036,
        store_longitude: -78.509118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1909959,
        store_longitude: -78.4976799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.183408,
        store_longitude: -78.510458,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18604458,
        store_longitude: -78.5012236,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.195388,
        store_longitude: -78.499217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.181044,
        store_longitude: -78.5126959,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1864345,
        store_longitude: -78.5089986,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1842279,
        store_longitude: -78.5090192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186417,
        store_longitude: -78.5084,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188136,
        store_longitude: -78.495982,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1904959,
        store_longitude: -78.4998928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1868561,
        store_longitude: -78.5062673,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1859422,
        store_longitude: -78.5054333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1935476,
        store_longitude: -78.5040178,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1966921,
        store_longitude: -78.5026402,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1251334,
        store_longitude: -78.5180932,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1225106,
        store_longitude: -78.5131579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1762051,
        store_longitude: -78.493562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.180247,
        store_longitude: -78.49593,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17572632,
        store_longitude: -78.49816,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18734,
        store_longitude: -78.4981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1873459,
        store_longitude: -78.4913794,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1861039,
        store_longitude: -78.4949659,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1119551,
        store_longitude: -78.5081159,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114253,
        store_longitude: -78.5068,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113758,
        store_longitude: -78.5061847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1118905,
        store_longitude: -78.5080523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112444,
        store_longitude: -78.508632,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115988,
        store_longitude: -78.510688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1123417,
        store_longitude: -78.50847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1117957,
        store_longitude: -78.5079963,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113406,
        store_longitude: -78.5106763,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207139,
        store_longitude: -78.5091,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208922,
        store_longitude: -78.50962,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206623,
        store_longitude: -78.509804,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2081666,
        store_longitude: -78.5133388,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2018771,
        store_longitude: -78.522026,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20436612,
        store_longitude: -78.5254622,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2030229,
        store_longitude: -78.5016129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.019829,
        store_longitude: -78.436889,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.006117,
        store_longitude: -78.459841,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0020378,
        store_longitude: -78.4539874,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1922649,
        store_longitude: -78.5063227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.000663,
        store_longitude: -78.443057,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.155306,
        store_longitude: -78.505252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.151905,
        store_longitude: -78.49899,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1499489,
        store_longitude: -78.5059677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147853,
        store_longitude: -78.496993,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09051257,
        store_longitude: -78.5275039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0912684,
        store_longitude: -78.5241995,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0878657,
        store_longitude: -78.518395,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091106,
        store_longitude: -78.514134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.087298,
        store_longitude: -78.5125312,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0884842,
        store_longitude: -78.5124914,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0888092,
        store_longitude: -78.5127523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0911474,
        store_longitude: -78.5234987,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0904429,
        store_longitude: -78.5281094,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1026059,
        store_longitude: -78.534081,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2268826,
        store_longitude: -78.5076318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2287718,
        store_longitude: -78.5071358,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11426,
        store_longitude: -78.4936,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1026142,
        store_longitude: -78.4837267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0003845,
        store_longitude: -78.4429,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0588252,
        store_longitude: -78.4554583,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.054294,
        store_longitude: -78.453843,
    },
    {
        store_latitdue: -0.0575841,
        store_longitude: -78.4569983,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0433623,
        store_longitude: -78.4438908,
        neighborhood: 'POMASQUI',
    },

    {
        store_latitdue: -0.0540444,
        store_longitude: -78.4549,
        neighborhood: 'POMASQUI',
    },

    {
        store_latitdue: -0.0604352,
        store_longitude: -78.4673262,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1071824,
        store_longitude: -78.4908026,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10836964,
        store_longitude: -78.4874331,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1012036,
        store_longitude: -78.4864508,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103592,
        store_longitude: -78.4868,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10417035,
        store_longitude: -78.4899978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1049711,
        store_longitude: -78.4907463,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1031959,
        store_longitude: -78.4850849,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.072137,
        store_longitude: -78.480309,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1265566,
        store_longitude: -78.4953306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1282719,
        store_longitude: -78.4964428,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122371,
        store_longitude: -78.4981437,
    },
    {
        store_latitdue: -0.168936,
        store_longitude: -78.4901382,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.167389,
        store_longitude: -78.4894,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.166473,
        store_longitude: -78.491567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1658216,
        store_longitude: -78.487857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10655,
        store_longitude: -78.5246,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0978331,
        store_longitude: -78.5055019,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0152399,
        store_longitude: -78.4388,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0153294,
        store_longitude: -78.4393,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0069402,
        store_longitude: -78.440397,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.019025,
        store_longitude: -78.435101,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0036364,
        store_longitude: -78.4392,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0192975,
        store_longitude: -78.4365089,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0137487,
        store_longitude: -78.4397634,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0101588,
        store_longitude: -78.4402,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0122214,
        store_longitude: -78.4399181,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0037505,
        store_longitude: -78.4389,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1848,
        store_longitude: -78.492979,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.179465,
        store_longitude: -78.489308,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.18279334,
        store_longitude: -78.4935073,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1561237,
        store_longitude: -78.4886538,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184459,
        store_longitude: -78.4935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1853798,
        store_longitude: -78.493669,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0007343,
        store_longitude: -78.444801,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0022688,
        store_longitude: -78.4612915,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0128647,
        store_longitude: -78.4485597,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0086713,
        store_longitude: -78.4457575,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0047996,
        store_longitude: -78.4522,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0021744,
        store_longitude: -78.4409,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.002668,
        store_longitude: -78.44312,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.006541,
        store_longitude: -78.44438,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.0088336,
        store_longitude: -78.4462454,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0142161,
        store_longitude: -78.4471,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0087968,
        store_longitude: -78.4532348,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0063351,
        store_longitude: -78.4494007,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0071018,
        store_longitude: -78.4488752,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0072551,
        store_longitude: -78.4477479,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.004807,
        store_longitude: -78.452245,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0093509,
        store_longitude: -78.4470267,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0057812,
        store_longitude: -78.4526,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.000647,
        store_longitude: -78.443001,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0082896,
        store_longitude: -78.4568445,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0006249,
        store_longitude: -78.4453824,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0085867,
        store_longitude: -78.4467901,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.002715,
        store_longitude: -78.4430321,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0016459,
        store_longitude: -78.4549066,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0071143,
        store_longitude: -78.4477719,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0019007,
        store_longitude: -78.4418388,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0075277,
        store_longitude: -78.4466124,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0035882,
        store_longitude: -78.4431764,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2162162162,
        store_longitude: -78.5050634873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1294372,
        store_longitude: -78.4941693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128931,
        store_longitude: -78.5011,
    },

    {
        store_latitdue: -0.12891,
        store_longitude: -78.4986,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138816,
        store_longitude: -78.4967,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1253425448,
        store_longitude: -78.5020264238,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1232944,
        store_longitude: -78.5031717,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124198,
        store_longitude: -78.4988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123441,
        store_longitude: -78.5005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129566,
        store_longitude: -78.5016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1268724,
        store_longitude: -78.4985438,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1292873,
        store_longitude: -78.5011658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129151,
        store_longitude: -78.4974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.134742,
        store_longitude: -78.497416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126942,
        store_longitude: -78.503609,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12913,
        store_longitude: -78.5014,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1236927,
        store_longitude: -78.4978177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1239045,
        store_longitude: -78.5047108,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123777,
        store_longitude: -78.502382,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1007827,
        store_longitude: -78.5063037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1007697,
        store_longitude: -78.5097194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10321134,
        store_longitude: -78.5118305,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144812,
        store_longitude: -78.5015,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146185,
        store_longitude: -78.498289,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17945,
        store_longitude: -78.495532,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123883,
        store_longitude: -78.512286,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1175255,
        store_longitude: -78.5038845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1050411,
        store_longitude: -78.493894,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213314,
        store_longitude: -78.513572,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21248,
        store_longitude: -78.50843,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2118551,
        store_longitude: -78.5106213,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2110146,
        store_longitude: -78.5025392,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2093324,
        store_longitude: -78.5123658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220425,
        store_longitude: -78.512196,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2148880242,
        store_longitude: -78.510469012,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.211768,
        store_longitude: -78.513723,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210006,
        store_longitude: -78.512344,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20784,
        store_longitude: -78.511654,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0011425,
        store_longitude: -78.5112225,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.210955,
        store_longitude: -78.501966,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0606688,
        store_longitude: -78.4303,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.213601,
        store_longitude: -78.5051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0660401,
        store_longitude: -78.429646,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.2078622,
        store_longitude: -78.5003711,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212638,
        store_longitude: -78.5136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2120758,
        store_longitude: -78.5096514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2117525436,
        store_longitude: -78.5202359408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2152176,
        store_longitude: -78.5080097,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210006,
        store_longitude: -78.512344,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213286,
        store_longitude: -78.5039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.000645,
        store_longitude: -78.509773,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.1041897,
        store_longitude: -78.494857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220521,
        store_longitude: -78.508988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086432,
        store_longitude: -78.520245,
    },
    {
        store_latitdue: -0.2205264,
        store_longitude: -78.5171028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2210946109,
        store_longitude: -78.5168469697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21652,
        store_longitude: -78.522765,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216226,
        store_longitude: -78.5234016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146083,
        store_longitude: -78.505707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1497106,
        store_longitude: -78.5069745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1769193,
        store_longitude: -78.512983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.176685,
        store_longitude: -78.51148,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14337,
        store_longitude: -78.507571,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.179239,
        store_longitude: -78.512892,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0864413,
        store_longitude: -78.4187,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.119908,
        store_longitude: -78.508588,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12216,
        store_longitude: -78.5142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1199018367,
        store_longitude: -78.5080896182,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123992,
        store_longitude: -78.519003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199854,
        store_longitude: -78.4984,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2045977,
        store_longitude: -78.499863,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2020678,
        store_longitude: -78.498703,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.198568,
        store_longitude: -78.4985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20178,
        store_longitude: -78.5007,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.198256,
        store_longitude: -78.4982,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.202912,
        store_longitude: -78.4982,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199043,
        store_longitude: -78.4978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1980477,
        store_longitude: -78.4982041,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199989,
        store_longitude: -78.4981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.201571,
        store_longitude: -78.5001,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1954695,
        store_longitude: -78.4972806,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204829,
        store_longitude: -78.499262,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1215257,
        store_longitude: -78.5160945,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1282413,
        store_longitude: -78.5239067,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12116702,
        store_longitude: -78.5161858,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124622,
        store_longitude: -78.5203,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1240848,
        store_longitude: -78.5228008,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1251061,
        store_longitude: -78.5234267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2115339,
        store_longitude: -78.5022416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2115506,
        store_longitude: -78.5041161,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.185062,
        store_longitude: -78.490985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2033283,
        store_longitude: -78.5008671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206518,
        store_longitude: -78.499468,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1179202,
        store_longitude: -78.5179965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1184804,
        store_longitude: -78.5161714,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0055699,
        store_longitude: -78.4456089,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0036489,
        store_longitude: -78.4404,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.126202,
        store_longitude: -78.5217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214095,
        store_longitude: -78.503789,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205337,
        store_longitude: -78.5024,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1213171,
        store_longitude: -78.5102582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.046365,
        store_longitude: -78.448393,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2125789,
        store_longitude: -78.5168404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2163717,
        store_longitude: -78.5117658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0500471,
        store_longitude: -78.516835,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.207719,
        store_longitude: -78.5216101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2089759,
        store_longitude: -78.525034,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2114453,
        store_longitude: -78.5232753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208428,
        store_longitude: -78.518875,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209705,
        store_longitude: -78.521245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20987,
        store_longitude: -78.5208,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209707,
        store_longitude: -78.5236,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206026,
        store_longitude: -78.519846,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2097169,
        store_longitude: -78.5221713,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2119162,
        store_longitude: -78.5202325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2088975,
        store_longitude: -78.523055,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2092608,
        store_longitude: -78.5226174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2043166,
        store_longitude: -78.523491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212074,
        store_longitude: -78.520132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.026765,
        store_longitude: -78.447896,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2014556072,
        store_longitude: -78.50023326,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10746358,
        store_longitude: -78.5067977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.087293,
        store_longitude: -78.443673,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1186282,
        store_longitude: -78.5138378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12826,
        store_longitude: -78.4941035,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10139,
        store_longitude: -78.5072,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1011581,
        store_longitude: -78.5092905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21129204,
        store_longitude: -78.5240598,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2110279,
        store_longitude: -78.5192486,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.010768333333333,
        store_longitude: -78.444376666667,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0011426210403442,
        store_longitude: -78.514448404312,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.12925,
        store_longitude: -78.5002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1119037,
        store_longitude: -78.5079232,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124755,
        store_longitude: -78.5020183,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.069518088831042,
        store_longitude: -78.470505598734,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.11242,
        store_longitude: -78.49777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.00059401,
        store_longitude: -78.44313675,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.21620666666667,
        store_longitude: -78.513966666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10759874,
        store_longitude: -78.44611203,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.2016119,
        store_longitude: -78.5106163,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1226329,
        store_longitude: -78.4971912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0991507,
        store_longitude: -78.5028042,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.099928,
        store_longitude: -78.521152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10529666666667,
        store_longitude: -78.500286666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1028433,
        store_longitude: -78.4961146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0002723,
        store_longitude: -78.4381397,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.212282,
        store_longitude: -78.519,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2066239,
        store_longitude: -78.5011093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1795708,
        store_longitude: -78.5108311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1860117,
        store_longitude: -78.5052291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.194439,
        store_longitude: -78.509966,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19621166666667,
        store_longitude: -78.504441666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0868419,
        store_longitude: -78.4304428,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1202951,
        store_longitude: -78.4997988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096283,
        store_longitude: -78.5311956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0956503,
        store_longitude: -78.5182,
    },
    {
        store_latitdue: -0.1310017,
        store_longitude: -78.4646811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1498365,
        store_longitude: -78.5073055,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1937048,
        store_longitude: -78.5009572,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08498021,
        store_longitude: -78.44046962,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.131445,
        store_longitude: -78.5172,
    },
    {
        store_latitdue: -0.2730353,
        store_longitude: -78.5471094,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.013246,
        store_longitude: -78.4495,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0013159,
        store_longitude: -78.4579863,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.15135833333333,
        store_longitude: -78.497915,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147125,
        store_longitude: -78.475236666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22937333333333,
        store_longitude: -78.509716666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.171975772,
        store_longitude: -78.500049134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21858022,
        store_longitude: -78.51392331,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0125965,
        store_longitude: -78.4482,
    },
    {
        store_latitdue: -0.114295,
        store_longitude: -78.497603333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113073,
        store_longitude: -78.4998,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1392098,
        store_longitude: -78.5014093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139586,
        store_longitude: -78.497727,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102453,
        store_longitude: -78.449997,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0.0043478,
        store_longitude: -78.4618899,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.11147,
        store_longitude: -78.489197,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.00586953,
        store_longitude: -78.44498097,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.141346,
        store_longitude: -78.501136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145429,
        store_longitude: -78.506,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14182995,
        store_longitude: -78.50268238,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0904377,
        store_longitude: -78.520517,
    },
    {
        store_latitdue: -0.0282456,
        store_longitude: -78.4462909,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.161533,
        store_longitude: -78.319809,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.296927,
        store_longitude: -78.479019,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.1768487,
        store_longitude: -78.4886598,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1586795,
        store_longitude: -78.493579,
    },
    {
        store_latitdue: -0.128879,
        store_longitude: -78.514925,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0551709,
        store_longitude: -78.4552,
    },
    {
        store_latitdue: -0.1247876,
        store_longitude: -78.5063152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1453635,
        store_longitude: -78.5049972,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303283744,
        store_longitude: -78.56032,
    },
    {
        store_latitdue: -0.1215106,
        store_longitude: -78.5114097,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2184896,
        store_longitude: -78.5142116,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216389,
        store_longitude: -78.489216,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1456417,
        store_longitude: -78.5056633,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1464257,
        store_longitude: -78.5021455,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210380015,
        store_longitude: -78.51170538,
    },
    {
        store_latitdue: -0.12846617,
        store_longitude: -78.51585706,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14539,
        store_longitude: -78.506,
    },
    {
        store_latitdue: -0.130366,
        store_longitude: -78.512,
    },
    {
        store_latitdue: -0.1344267,
        store_longitude: -78.46549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156497,
        store_longitude: -78.49086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086258,
        store_longitude: -78.4722842,
    },
    {
        store_latitdue: -0.21432645,
        store_longitude: -78.5055389,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0009332,
        store_longitude: -78.511138,
    },
    {
        store_latitdue: 0.0082123,
        store_longitude: -78.4591732,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0253439,
        store_longitude: -78.4469508,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.00671,
        store_longitude: -78.4492,
    },
    {
        store_latitdue: 0.0172491,
        store_longitude: -78.4772321,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0344303,
        store_longitude: -78.4457098,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1188876,
        store_longitude: -78.5157031,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0817996,
        store_longitude: -78.4774481,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126959,
        store_longitude: -78.504789,
    },
    {
        store_latitdue: -0.1595965,
        store_longitude: -78.4879785,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1218059,
        store_longitude: -78.5074527,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0925982,
        store_longitude: -78.524742,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2155097,
        store_longitude: -78.5134175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.211368,
        store_longitude: -78.5136275,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218602,
        store_longitude: -78.510159,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1913416,
        store_longitude: -78.507189,
    },
    {
        store_latitdue: -0.1358747,
        store_longitude: -78.5003298,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11991,
        store_longitude: -78.5023,
    },
    {
        store_latitdue: -0.1270252,
        store_longitude: -78.5002777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144561,
        store_longitude: -78.5007732,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.133745,
        store_longitude: -78.500771666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0974573,
        store_longitude: -78.5190713,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.104473,
        store_longitude: -78.496818,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2193671,
        store_longitude: -78.5131876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118758,
        store_longitude: -78.4714,
    },
    {
        store_latitdue: -0.225669,
        store_longitude: -78.5116485,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094029,
        store_longitude: -78.503192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.106845,
        store_longitude: -78.4971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0955213,
        store_longitude: -78.5080586,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0395408,
        store_longitude: -78.4430646,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1554009,
        store_longitude: -78.5031835,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145268230699,
        store_longitude: -78.500979943201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12394833333333,
        store_longitude: -78.520673333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.056921,
        store_longitude: -78.462238,
    },
    {
        store_latitdue: -0.1269437,
        store_longitude: -78.5036766,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1047593,
        store_longitude: -78.5250857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1145322,
        store_longitude: -78.5198283,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1574182,
        store_longitude: -78.4918898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12981304898858,
        store_longitude: -78.497960949317,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11358,
        store_longitude: -78.497313333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.081727,
        store_longitude: -78.504701,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0892717,
        store_longitude: -78.4401,
    },
    {
        store_latitdue: -0.1735437,
        store_longitude: -78.4865421,
    },
    {
        store_latitdue: -0.12410941533744,
        store_longitude: -78.520244574174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1257153,
        store_longitude: -78.5134854,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0831477,
        store_longitude: -78.5044718,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1414751,
        store_longitude: -78.4756776,
    },
    {
        store_latitdue: -0.092305,
        store_longitude: -78.448041666667,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0913556,
        store_longitude: -78.5246036,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0896549,
        store_longitude: -78.5147989,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123987,
        store_longitude: -78.512408,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0028416,
        store_longitude: -78.4427009,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1926184,
        store_longitude: -78.4984898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123377,
        store_longitude: -78.506132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0007388,
        store_longitude: -78.5136561,
    },
    {
        store_latitdue: -0.0628141,
        store_longitude: -78.4644876,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.00124021,
        store_longitude: -78.5145452,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.1270121,
        store_longitude: -78.5253526,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0917429,
        store_longitude: -78.5083501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12405132,
        store_longitude: -78.46990308,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1290357,
        store_longitude: -78.5115842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193313,
        store_longitude: -78.497316,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1552861,
        store_longitude: -78.5010494,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.154089,
        store_longitude: -78.5023943,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.06186269,
        store_longitude: -78.470375,
    },
    {
        store_latitdue: -0.059998333333333,
        store_longitude: -78.463661666667,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0606907,
        store_longitude: -78.4572669,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.000738,
        store_longitude: -78.513973,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.1446417,
        store_longitude: -78.5079778,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1770602,
        store_longitude: -78.50231282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186664,
        store_longitude: -78.514666,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1938551,
        store_longitude: -78.4959456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20804754,
        store_longitude: -78.49652627,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.191206,
        store_longitude: -78.50342,
    },
    {
        store_latitdue: -0.0575766,
        store_longitude: -78.457016,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0114561,
        store_longitude: -78.4399455,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.10428658,
        store_longitude: -78.4900665,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1324493748,
        store_longitude: -78.5157482699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.153155,
        store_longitude: -78.492248333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0887205,
        store_longitude: -78.505,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11619833333333,
        store_longitude: -78.488891666667,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: 0.0043173,
        store_longitude: -78.4575702,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.191088,
        store_longitude: -78.510205,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12666206,
        store_longitude: -78.49613441,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19485685,
        store_longitude: -78.50058384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1440368193,
        store_longitude: -78.5184428841,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0737421,
        store_longitude: -78.4790899,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22199166666667,
        store_longitude: -78.515841666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143184,
        store_longitude: -78.5149504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1951165,
        store_longitude: -78.4999644,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1233749,
        store_longitude: -78.5186561,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2505579,
        store_longitude: -78.5342738,
    },
    {
        store_latitdue: -0.0385063,
        store_longitude: -78.4426519,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.016911,
        store_longitude: -78.477722,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1239838,
        store_longitude: -78.4643602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1237297,
        store_longitude: -78.5215858,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3379349189,
        store_longitude: -78.3870702982,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.1250451,
        store_longitude: -78.4673137,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.088991666666667,
        store_longitude: -78.427863333333,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.00432481,
        store_longitude: -78.43576295,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.10954971425235,
        store_longitude: -78.459543250501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.00986233,
        store_longitude: -78.4462922,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.064926666666667,
        store_longitude: -78.46082,
        neighborhood: 'POMASQUI',
    },

    {
        store_latitdue: -0.111879,
        store_longitude: -78.5066,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1850177,
        store_longitude: -78.5090526,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.139916,
        store_longitude: -78.503335,
    },

    {
        store_latitdue: -0.015223,
        store_longitude: -78.439651,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.002913,
        store_longitude: -78.44763,
    },

    {
        store_latitdue: -0.0816563,
        store_longitude: -78.5055,
    },

    {
        store_latitdue: -0.0873571,
        store_longitude: -78.5218,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085999,
        store_longitude: -78.502546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.010008,
        store_longitude: -78.446357,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.095674,
        store_longitude: -78.51812,
    },
    {
        store_latitdue: -0.165217,
        store_longitude: -78.477782,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0077225,
        store_longitude: -78.4402,
    },
    {
        store_latitdue: -0.189691,
        store_longitude: -78.4955302,
    },
    {
        store_latitdue: 0.0397672,
        store_longitude: -78.1448,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.161536,
        store_longitude: -78.319794,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.095623054,
        store_longitude: -78.51478301,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0825587,
        store_longitude: -78.5060514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0911915,
        store_longitude: -78.5242,
    },
    {
        store_latitdue: -0.0880555,
        store_longitude: -78.5178,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0062416,
        store_longitude: -78.4496801,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.09102,
        store_longitude: -78.5231833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0023787,
        store_longitude: -78.4608154,
    },
    {
        store_latitdue: 0.015304,
        store_longitude: -78.4394,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0160039,
        store_longitude: -78.4755,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0904951,
        store_longitude: -78.5228,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.06552957,
        store_longitude: -78.5096282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118029,
        store_longitude: -78.5045,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1607202,
        store_longitude: -78.4929534,
    },
    {
        store_latitdue: 0.003388,
        store_longitude: -78.498069,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.085091666666667,
        store_longitude: -78.504993333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.003386,
        store_longitude: -78.448427,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0028589,
        store_longitude: -78.4427,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0017489,
        store_longitude: -78.4401,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.009695,
        store_longitude: -78.4399786,
    },
    {
        store_latitdue: -0.12398,
        store_longitude: -78.519058333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1445659,
        store_longitude: -78.5080107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1586822,
        store_longitude: -78.5004318,
    },
    {
        store_latitdue: -0.1199479,
        store_longitude: -78.5037526,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.00080939847975969,
        store_longitude: -78.519167415798,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.12435,
        store_longitude: -78.50987,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0516277,
        store_longitude: -78.4534031,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.08947736,
        store_longitude: -78.52341025,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0896418,
        store_longitude: -78.5148425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0102493,
        store_longitude: -78.4475994,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.08475,
        store_longitude: -78.505908,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0841157,
        store_longitude: -78.5085863,
    },
    {
        store_latitdue: -0.0574895,
        store_longitude: -78.4649781,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0539959,
        store_longitude: -78.4533741,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.053678333333333,
        store_longitude: -78.46326,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0754923,
        store_longitude: -78.4782506,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0543812,
        store_longitude: -78.4574,
    },
    {
        store_latitdue: -0.053355,
        store_longitude: -78.454838333333,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0518683,
        store_longitude: -78.4561,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1179712,
        store_longitude: -78.5071794,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19475448,
        store_longitude: -78.5094061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12406833333333,
        store_longitude: -78.5054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.00640278,
        store_longitude: -78.4498214,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1048138,
        store_longitude: -78.4933602,
    },
    {
        store_latitdue: -0.210649,
        store_longitude: -78.503481,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131689,
        store_longitude: -78.501,
    },
    {
        store_latitdue: -0.22702,
        store_longitude: -78.506351,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2102059,
        store_longitude: -78.514795,
    },
    {
        store_latitdue: -0.2240842,
        store_longitude: -78.5075018,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2074883,
        store_longitude: -78.505009,
    },
    {
        store_latitdue: -0.19849436357617,
        store_longitude: -78.500486752018,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0913015,
        store_longitude: -78.5146977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208034,
        store_longitude: -78.5192291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1405093,
        store_longitude: -78.5081639,
    },
    {
        store_latitdue: -0.0892993,
        store_longitude: -78.5228751,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.109094,
        store_longitude: -78.4968,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.072218,
        store_longitude: -78.480185,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11578728,
        store_longitude: -78.49921914,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0857717,
        store_longitude: -78.5063297,
    },
    {
        store_latitdue: -0.13664085,
        store_longitude: -78.50731437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.073572,
        store_longitude: -78.480258,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209681,
        store_longitude: -78.522154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2041166,
        store_longitude: -78.502144,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2083015,
        store_longitude: -78.5082817,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1960918,
        store_longitude: -78.5045924,
    },
    {
        store_latitdue: -0.15185548458248,
        store_longitude: -78.498535109684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11528166666667,
        store_longitude: -78.500806666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12393833333333,
        store_longitude: -78.519018333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0068669,
        store_longitude: -78.4401825,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0160545,
        store_longitude: -78.4712,
    },
    {
        store_latitdue: -1.0415292,
        store_longitude: -78.585114,
    },
    {
        store_latitdue: -0.0957476,
        store_longitude: -78.5207,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18859166666667,
        store_longitude: -78.492175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19314,
        store_longitude: -78.49529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1856346216,
        store_longitude: -78.4921512029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207348534,
        store_longitude: -78.52607229,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096045,
        store_longitude: -78.5201284,
    },
    {
        store_latitdue: -0.2186237135902,
        store_longitude: -78.514283029363,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2068101,
        store_longitude: -78.5247276,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2043643,
        store_longitude: -78.5254448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2130218,
        store_longitude: -78.5061249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090445,
        store_longitude: -78.5209288,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20663968,
        store_longitude: -78.50527777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1419449,
        store_longitude: -78.5139072,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1458214,
        store_longitude: -78.4924565,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1270478,
        store_longitude: -78.5255985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0958884,
        store_longitude: -78.5127,
    },
    {
        store_latitdue: -0.085227,
        store_longitude: -78.509831,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.108425553,
        store_longitude: -78.4840536,
    },
    {
        store_latitdue: -0.055634253658354,
        store_longitude: -78.455308377743,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0541492,
        store_longitude: -78.4558595,
    },
    {
        store_latitdue: -0.04909,
        store_longitude: -78.45623318,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.11390651,
        store_longitude: -78.49600005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0681988,
        store_longitude: -78.4638696,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.21671,
        store_longitude: -78.515891,
    },
    {
        store_latitdue: -0.213573,
        store_longitude: -78.5133326,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2504032,
        store_longitude: -78.5169967,
    },
    {
        store_latitdue: -0.173975,
        store_longitude: -78.4883756,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1531584,
        store_longitude: -78.4924285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126125,
        store_longitude: -78.5145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1027743,
        store_longitude: -78.5100706,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1041253,
        store_longitude: -78.4944498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11468175,
        store_longitude: -78.49579114,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.054497,
        store_longitude: -78.453795,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2148491,
        store_longitude: -78.5158995,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21920549,
        store_longitude: -78.51309483,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3017189,
        store_longitude: -78.5229529,
    },
    {
        store_latitdue: -0.2098039,
        store_longitude: -78.5034795,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0911489,
        store_longitude: -78.5226745,
    },
    {
        store_latitdue: -0.08787,
        store_longitude: -78.5220417,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21742929238826,
        store_longitude: -78.512722318992,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22096637170762,
        store_longitude: -78.514772532508,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199766,
        store_longitude: -78.498781,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18574948,
        store_longitude: -78.5084762,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19618409,
        store_longitude: -78.4979632,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2076736,
        store_longitude: -78.502787,
    },
    {
        store_latitdue: -0.13374701142311,
        store_longitude: -78.502096496522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1230606,
        store_longitude: -78.5105491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12091666666667,
        store_longitude: -78.515703333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1046191,
        store_longitude: -78.500195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0844906,
        store_longitude: -78.5083497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.111986,
        store_longitude: -78.506613,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.06811542,
        store_longitude: -78.46331202,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0039718,
        store_longitude: -78.4376976,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1029125,
        store_longitude: -78.49620497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186064,
        store_longitude: -78.508217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2059553,
        store_longitude: -78.5025691,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0032045,
        store_longitude: -78.4412,
    },
    {
        store_latitdue: -0.128566,
        store_longitude: -78.509569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1280317,
        store_longitude: -78.5171701,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0858358,
        store_longitude: -78.5035008,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.016900599002838,
        store_longitude: -78.474959246814,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1106773,
        store_longitude: -78.5080421,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223559,
        store_longitude: -78.503468,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20637333333333,
        store_longitude: -78.509061666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2076387,
        store_longitude: -78.480314,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20540148,
        store_longitude: -78.52286865,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20867333333333,
        store_longitude: -78.522043333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1886785,
        store_longitude: -78.492308,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11367467020555,
        store_longitude: -78.489531633026,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19432206172496,
        store_longitude: -78.495810320601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19798107,
        store_longitude: -78.4983062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1602479,
        store_longitude: -78.496361,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1274821,
        store_longitude: -78.4964427,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0633773,
        store_longitude: -78.5190716,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.0029494,
        store_longitude: -78.4418762,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.000801,
        store_longitude: -78.514558,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.1959684,
        store_longitude: -78.505777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1997926,
        store_longitude: -78.498714,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1300462,
        store_longitude: -78.5123332,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1272505,
        store_longitude: -78.5127437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12406201567501,
        store_longitude: -78.50527273491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1180545,
        store_longitude: -78.5025814,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0440209,
        store_longitude: -78.4431927,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0109549,
        store_longitude: -78.4776057,
    },
    {
        store_latitdue: -0.1138156,
        store_longitude: -78.4983622,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18815953,
        store_longitude: -78.5060576,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188655,
        store_longitude: -78.5101867,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1402069,
        store_longitude: -78.5013404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140026,
        store_longitude: -78.510127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17251666666667,
        store_longitude: -78.492591666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11454442,
        store_longitude: -78.49657363,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096923,
        store_longitude: -78.510653,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1280178,
        store_longitude: -78.498366,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10308548,
        store_longitude: -78.510231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1618569,
        store_longitude: -78.4965188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.000386,
        store_longitude: -78.456698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20775896497071,
        store_longitude: -78.510159552097,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20772757474333,
        store_longitude: -78.510021250695,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2068989,
        store_longitude: -78.524708,
    },
    {
        store_latitdue: -0.2092683,
        store_longitude: -78.5226293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120211,
        store_longitude: -78.4878,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.137244,
        store_longitude: -78.500139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2590125,
        store_longitude: -78.53946476,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0914816,
        store_longitude: -78.5084447,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0144938,
        store_longitude: -78.4803447,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.21627925,
        store_longitude: -78.5165212,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2187955,
        store_longitude: -78.509071,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1257707,
        store_longitude: -78.5100781,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123874,
        store_longitude: -78.5093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1250232,
        store_longitude: -78.5046929,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125414,
        store_longitude: -78.504705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.06154696,
        store_longitude: -78.5182547,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.0866,
        store_longitude: -78.50211,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1217134,
        store_longitude: -78.5113127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1726662734,
        store_longitude: -78.4925005585,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2077327,
        store_longitude: -78.5069885,
    },

    {
        store_latitdue: -0.138881,
        store_longitude: -78.4968277,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11550333333333,
        store_longitude: -78.498888333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12399852275848,
        store_longitude: -78.502110745758,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1276614,
        store_longitude: -78.523029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0696352,
        store_longitude: -78.4705564,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.055524199269712,
        store_longitude: -78.455205280334,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.22909174673259,
        store_longitude: -78.50703779608,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205585,
        store_longitude: -78.380695,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2187607,
        store_longitude: -78.5059888,
    },
    {
        store_latitdue: -0.2130345,
        store_longitude: -78.5046274,
    },
    {
        store_latitdue: -0.212137,
        store_longitude: -78.509817,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090993,
        store_longitude: -78.523129,
    },
    {
        store_latitdue: -0.0799985,
        store_longitude: -78.5035785,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0924545,
        store_longitude: -78.5280559,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0051572,
        store_longitude: -78.4465248,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.01545,
        store_longitude: -78.4793,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.11588206,
        store_longitude: -78.49247752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0074765,
        store_longitude: -78.4405291,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.21907666666667,
        store_longitude: -78.510065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124857,
        store_longitude: -78.50705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110157,
        store_longitude: -78.5184,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122416,
        store_longitude: -78.4968,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13300994876772,
        store_longitude: -78.498026076704,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130368,
        store_longitude: -78.5143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092958,
        store_longitude: -78.43478,
    },
    {
        store_latitdue: -0.1400727,
        store_longitude: -78.50045,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238273,
        store_longitude: -78.4987231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12574333333333,
        store_longitude: -78.501593333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12297333333333,
        store_longitude: -78.498773333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0913552,
        store_longitude: -78.5241556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0900627,
        store_longitude: -78.5222403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0045313,
        store_longitude: -78.4423664,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1236727,
        store_longitude: -78.5132158,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1369112,
        store_longitude: -78.497186,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0181505,
        store_longitude: -78.4673,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2102733,
        store_longitude: -78.5124453,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2249814,
        store_longitude: -78.5119879,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20627112,
        store_longitude: -78.5059323,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20621843,
        store_longitude: -78.50276,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1762484991923,
        store_longitude: -78.496920419857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2452607,
        store_longitude: -78.5114205,
    },
    {
        store_latitdue: -0.1306991,
        store_longitude: -78.4980918,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0946453,
        store_longitude: -78.5068432,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0924259,
        store_longitude: -78.5217175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0102411,
        store_longitude: -78.4605,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0135852,
        store_longitude: -78.4397,
    },
    {
        store_latitdue: -0.2073653,
        store_longitude: -78.5045887,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2007195,
        store_longitude: -78.5000769,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123853,
        store_longitude: -78.50218,
    },
    {
        store_latitdue: -0.123085,
        store_longitude: -78.49876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1394484,
        store_longitude: -78.5089082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1201736,
        store_longitude: -78.5008179,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0161979,
        store_longitude: -78.4362734,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.20970333333333,
        store_longitude: -78.522426666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188172,
        store_longitude: -78.516295,
    },
    {
        store_latitdue: -0.084461,
        store_longitude: -78.5153181,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1154562,
        store_longitude: -78.5092393,
    },
    {
        store_latitdue: -0.10404460597783,
        store_longitude: -78.453627638519,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.21199035923928,
        store_longitude: -78.507831227034,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215298,
        store_longitude: -78.51609,
    },
    {
        store_latitdue: -0.21860095,
        store_longitude: -78.51422587,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2153143,
        store_longitude: -78.5075198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1648965,
        store_longitude: -78.4872421,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1126356,
        store_longitude: -78.5085293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21655,
        store_longitude: -78.514172,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.155975,
        store_longitude: -78.489544,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20695058,
        store_longitude: -78.50495996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20661405,
        store_longitude: -78.5069213,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1559385,
        store_longitude: -78.5038304,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20725681,
        store_longitude: -78.50632628,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0788215,
        store_longitude: -78.5277046,
    },
    {
        store_latitdue: -0.0786423,
        store_longitude: -78.5286519,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0850064,
        store_longitude: -78.5321151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1178702,
        store_longitude: -78.5181967,
    },
    {
        store_latitdue: -0.11971,
        store_longitude: -78.5187,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0918794,
        store_longitude: -78.5134553,
    },
    {
        store_latitdue: -0.11208341,
        store_longitude: -78.5104446,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0939819,
        store_longitude: -78.5073168,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123292,
        store_longitude: -78.519975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2259963,
        store_longitude: -78.509086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.195333,
        store_longitude: -78.507254,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2771412,
        store_longitude: -78.5526299,
    },
    {
        store_latitdue: -0.1239589,
        store_longitude: -78.512933,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13619288,
        store_longitude: -78.50100653,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0828637,
        store_longitude: -78.5062916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0827204,
        store_longitude: -78.5104349,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0919422,
        store_longitude: -78.5299309,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091566173359752,
        store_longitude: -78.528444422409,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11523666666667,
        store_longitude: -78.495403333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0551615,
        store_longitude: -78.4527366,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.06165888,
        store_longitude: -78.457901,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2016231,
        store_longitude: -78.4873153,
    },
    {
        store_latitdue: -0.220464,
        store_longitude: -78.5121307,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15496,
        store_longitude: -78.5033569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20280777,
        store_longitude: -78.5054519,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1165381,
        store_longitude: -78.4957307,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08408718,
        store_longitude: -78.5073062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.018940670415759,
        store_longitude: -78.434990057722,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.25214333333333,
        store_longitude: -78.521081666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21067391417698,
        store_longitude: -78.517030411135,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13156131,
        store_longitude: -78.50000361,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0618182,
        store_longitude: -78.5185174,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.23272056598216,
        store_longitude: -78.524382803589,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.07023233,
        store_longitude: -78.510971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.071385609917343,
        store_longitude: -78.511036299169,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0280998,
        store_longitude: -78.4465,
    },
    {
        store_latitdue: -0.1053,
        store_longitude: -78.492202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.005927,
        store_longitude: -78.4494,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.0080794841051102,
        store_longitude: -78.444829322398,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0012792,
        store_longitude: -78.4399299,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.056047523394227,
        store_longitude: -78.455011574551,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.062832,
        store_longitude: -78.466764,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.219405,
        store_longitude: -78.507596666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226397,
        store_longitude: -78.528311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21468669,
        store_longitude: -78.50504089,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21472543,
        store_longitude: -78.50499222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21471597,
        store_longitude: -78.50499334,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140202,
        store_longitude: -78.476625,
    },
    {
        store_latitdue: -0.1160203,
        store_longitude: -78.4966406,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115222,
        store_longitude: -78.4943,
    },
    {
        store_latitdue: -0.1280473,
        store_longitude: -78.5185745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112125,
        store_longitude: -78.5068,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.099521,
        store_longitude: -78.5204175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1102933,
        store_longitude: -78.5177133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089675,
        store_longitude: -78.515189,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1058334,
        store_longitude: -78.4975407,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.088603023849118,
        store_longitude: -78.499254669556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0914972,
        store_longitude: -78.5084194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0968167,
        store_longitude: -78.5152417,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0538896,
        store_longitude: -78.455157,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.003494,
        store_longitude: -78.4502479,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0101319,
        store_longitude: -78.4436545,
    },
    {
        store_latitdue: -0.0068794,
        store_longitude: -78.4431345,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.0091579,
        store_longitude: -78.4492622,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0064542389547673,
        store_longitude: -78.444423595013,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.20753160584718,
        store_longitude: -78.520280281082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293715,
        store_longitude: -78.53512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1940352,
        store_longitude: -78.4981578,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1959135,
        store_longitude: -78.4953912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19623342901468,
        store_longitude: -78.497453341261,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17783,
        store_longitude: -78.4896,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1881722,
        store_longitude: -78.5162641,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145029,
        store_longitude: -78.493982,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13533651,
        store_longitude: -78.5003051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11861,
        store_longitude: -78.494268333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1300189,
        store_longitude: -78.5013725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0967573,
        store_longitude: -78.4528118,
    },
    {
        store_latitdue: -0.134083,
        store_longitude: -78.504939,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852721,
        store_longitude: -78.467927,
    },
    {
        store_latitdue: -0.1032397,
        store_longitude: -78.5110098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09357496,
        store_longitude: -78.50496657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0926125,
        store_longitude: -78.5231883,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.00017778016626835,
        store_longitude: -78.514433568344,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: 0.0049732,
        store_longitude: -78.4458146,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.106445,
        store_longitude: -78.497421666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0109,
        store_longitude: -78.4780167,
    },
    {
        store_latitdue: 0.017209723591805,
        store_longitude: -78.474921192974,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.223676,
        store_longitude: -78.5092,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2150488,
        store_longitude: -78.5079351,
    },
    {
        store_latitdue: -0.227299788,
        store_longitude: -78.507270572,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2079556,
        store_longitude: -78.5055485,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21756139118224,
        store_longitude: -78.512759869918,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.177313,
        store_longitude: -78.491063,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21451431792229,
        store_longitude: -78.51649241522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19050282,
        store_longitude: -78.5091476,
    },
    {
        store_latitdue: -0.1786639,
        store_longitude: -78.5088192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1898692,
        store_longitude: -78.5102855,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189093,
        store_longitude: -78.509387,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20704759,
        store_longitude: -78.50468726,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19343374762684,
        store_longitude: -78.496297895908,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118565,
        store_longitude: -78.497858333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1296099,
        store_longitude: -78.5138434,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1331812,
        store_longitude: -78.49591347,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13774135,
        store_longitude: -78.497023,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0864756,
        store_longitude: -78.5090325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11520666666667,
        store_longitude: -78.494993333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0015852,
        store_longitude: -78.4411376,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.05287427,
        store_longitude: -78.44944219,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1871466,
        store_longitude: -78.4955444,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14217666666667,
        store_longitude: -78.492508333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12637550476938,
        store_longitude: -78.504901248962,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13069521,
        store_longitude: -78.5161438,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12916822,
        store_longitude: -78.49957673,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0633894,
        store_longitude: -78.516449,
        neighborhood: 'NONO',
    },
    {
        store_latitdue: -0.0638102,
        store_longitude: -78.5130452,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1029661,
        store_longitude: -78.510035,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20768025889993,
        store_longitude: -78.50991086103,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21397138,
        store_longitude: -78.50349307,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1906471,
        store_longitude: -78.5112672,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.154073,
        store_longitude: -78.492981,
    },
    {
        store_latitdue: -0.1942182,
        store_longitude: -78.4960098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143104,
        store_longitude: -78.5141,
    },
    {
        store_latitdue: -0.13107005,
        store_longitude: -78.5164251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1197956,
        store_longitude: -78.5187606,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.098508,
        store_longitude: -78.5159509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.007532837,
        store_longitude: -78.447726886,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.16636975,
        store_longitude: -78.46859741,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15666633,
        store_longitude: -78.46637163,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.019242423081266,
        store_longitude: -78.437261173253,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.21757300011814,
        store_longitude: -78.514820560813,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214586,
        store_longitude: -78.504132,
    },
    {
        store_latitdue: -0.1599545,
        store_longitude: -78.4965193,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1406831,
        store_longitude: -78.50931,
    },
    {
        store_latitdue: -0.1511993,
        store_longitude: -78.4938096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.141091,
        store_longitude: -78.509296,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13049241949966,
        store_longitude: -78.515634404426,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1282686,
        store_longitude: -78.5037597,
    },
    {
        store_latitdue: -0.09556,
        store_longitude: -78.513398,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0647515,
        store_longitude: -78.5094264,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082135526463389,
        store_longitude: -78.502140752971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093492149,
        store_longitude: -78.516354483,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212455,
        store_longitude: -78.4972,
    },
    {
        store_latitdue: -0.0014563137665391,
        store_longitude: -78.446849444881,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.052655,
        store_longitude: -78.4529217,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.077833,
        store_longitude: -78.479695,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.051521205,
        store_longitude: -78.45490169,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.21858323,
        store_longitude: -78.51415315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.158963,
        store_longitude: -78.48895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.16778995,
        store_longitude: -78.4748291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14063833333333,
        store_longitude: -78.508545,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1455239,
        store_longitude: -78.5053104,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12349264,
        store_longitude: -78.51073042,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0790511,
        store_longitude: -78.5282281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08268,
        store_longitude: -78.505863,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0899907,
        store_longitude: -78.5153449,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2007227,
        store_longitude: -78.3626798,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.0051882,
        store_longitude: -78.4409418,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.014476678334177,
        store_longitude: -78.480267422274,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.055311969481409,
        store_longitude: -78.462407262996,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2081048,
        store_longitude: -78.5191437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2231549,
        store_longitude: -78.5084978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20709,
        store_longitude: -78.499163333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.192121,
        store_longitude: -78.499175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2085502,
        store_longitude: -78.5049222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1416814,
        store_longitude: -78.5129461,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1479444,
        store_longitude: -78.4908281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12818821705878,
        store_longitude: -78.503578165546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084731,
        store_longitude: -78.5168505,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08520551,
        store_longitude: -78.50629546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094738,
        store_longitude: -78.508177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089594456367195,
        store_longitude: -78.498390689492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22321055,
        store_longitude: -78.5140914,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2149264,
        store_longitude: -78.5083112,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20199219,
        store_longitude: -78.50054197,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20196123,
        store_longitude: -78.50058873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2020264,
        store_longitude: -78.5005364,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1605194,
        store_longitude: -78.478322,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19151539,
        store_longitude: -78.49736075,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19087252,
        store_longitude: -78.49694095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21123,
        store_longitude: -78.502203333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1704044,
        store_longitude: -78.4790051,
    },
    {
        store_latitdue: -0.12066621333361,
        store_longitude: -78.507441636175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12121,
        store_longitude: -78.498146666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1303032,
        store_longitude: -78.5139699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1118069,
        store_longitude: -78.5071597,
    },
    {
        store_latitdue: -0.27073333333333,
        store_longitude: -78.522733333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2074479,
        store_longitude: -78.509202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1987789,
        store_longitude: -78.4994742,
    },
    {
        store_latitdue: -0.178020509,
        store_longitude: -78.50999457,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.173615,
        store_longitude: -78.49866,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17093017,
        store_longitude: -78.4941787,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11987964,
        store_longitude: -78.5081861,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13016833333333,
        store_longitude: -78.498218333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0850837,
        store_longitude: -78.5104098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0710451,
        store_longitude: -78.5111078,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084660281427205,
        store_longitude: -78.506004391238,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21679603960365,
        store_longitude: -78.515029270202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20816787611693,
        store_longitude: -78.519227430224,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.196154,
        store_longitude: -78.505775,
    },
    {
        store_latitdue: -0.1845598,
        store_longitude: -78.5138477,
    },
    {
        store_latitdue: -0.176828,
        store_longitude: -78.51147,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1037569809705,
        store_longitude: -78.497684178874,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119525,
        store_longitude: -78.507198333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085005,
        store_longitude: -78.50476,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0038,
        store_longitude: -78.447204,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.037623,
        store_longitude: -78.4422626,
    },
    {
        store_latitdue: -0.072093,
        store_longitude: -78.48015,
    },
    {
        store_latitdue: -0.0670117,
        store_longitude: -78.4640307,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2064235,
        store_longitude: -78.5027794,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2100966,
        store_longitude: -78.5027766,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1299109,
        store_longitude: -78.4942001,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17664166666667,
        store_longitude: -78.488768333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199274,
        store_longitude: -78.499279,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146179,
        store_longitude: -78.47324,
    },
    {
        store_latitdue: -0.090839462354779,
        store_longitude: -78.522397717461,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.098181666666667,
        store_longitude: -78.501811666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0856447,
        store_longitude: -78.5167065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10630833333333,
        store_longitude: -78.497428333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115268,
        store_longitude: -78.494467,
    },
    {
        store_latitdue: -0.1281997,
        store_longitude: -78.4634865,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21173166666667,
        store_longitude: -78.503573333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.181877809,
        store_longitude: -78.516044446,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.180815231,
        store_longitude: -78.515508603,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11506,
        store_longitude: -78.498448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13364500741982,
        store_longitude: -78.503828104478,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1809564,
        store_longitude: -78.5120457,
    },
    {
        store_latitdue: -0.14487,
        store_longitude: -78.498781666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1370764,
        store_longitude: -78.510428,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.01221057508902,
        store_longitude: -78.434694119024,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.056254514493048,
        store_longitude: -78.454133234918,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.2057,
        store_longitude: -78.501493333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124971,
        store_longitude: -78.520371,
    },
    {
        store_latitdue: -0.0546128,
        store_longitude: -78.4533346,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.218966,
        store_longitude: -78.5154042,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.179536698,
        store_longitude: -78.517094727,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19172309657957,
        store_longitude: -78.497436918354,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1973827,
        store_longitude: -78.5068039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.203987,
        store_longitude: -78.5071,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1350431,
        store_longitude: -78.4987893,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0832412,
        store_longitude: -78.5029332,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.053865,
        store_longitude: -78.455097,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.11859166666667,
        store_longitude: -78.499931666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0969411,
        store_longitude: -78.5162151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2065157,
        store_longitude: -78.5028266,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15622587,
        store_longitude: -78.48995249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20123159978539,
        store_longitude: -78.500215765089,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11887833333333,
        store_longitude: -78.494363333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13019140809774,
        store_longitude: -78.503214642406,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1263868,
        store_longitude: -78.4986721,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238445,
        store_longitude: -78.5036892,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1224325,
        store_longitude: -78.5130214,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083133,
        store_longitude: -78.5046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0862982,
        store_longitude: -78.5019,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22710801,
        store_longitude: -78.51039591,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.224243,
        store_longitude: -78.5138,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2186046,
        store_longitude: -78.5090538,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15601902734488,
        store_longitude: -78.488815370947,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121637,
        store_longitude: -78.5049738,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205541,
        store_longitude: -78.503271,
    },
    {
        store_latitdue: -0.0124259,
        store_longitude: -78.445881,
    },
    {
        store_latitdue: -0.2203198,
        store_longitude: -78.5084793,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2123977,
        store_longitude: -78.522644,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2060406,
        store_longitude: -78.52293843,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12064672,
        store_longitude: -78.49855278,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1117158,
        store_longitude: -78.5078928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089176,
        store_longitude: -78.51405,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091214929707348,
        store_longitude: -78.529287977144,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095668094,
        store_longitude: -78.488627064,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.008165,
        store_longitude: -78.438541,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.016879225149751,
        store_longitude: -78.477726029232,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2183504,
        store_longitude: -78.5200571,
    },
    {
        store_latitdue: -0.190175,
        store_longitude: -78.511138333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1547167,
        store_longitude: -78.4993717,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178873675,
        store_longitude: -78.512072534,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1141154,
        store_longitude: -78.5050372,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178044,
        store_longitude: -78.5096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10974409,
        store_longitude: -78.48830862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12922344,
        store_longitude: -78.49661848,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.087934,
        store_longitude: -78.520628,
    },
    {
        store_latitdue: -0.1146736,
        store_longitude: -78.5198051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0073366666666667,
        store_longitude: -78.439621666667,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.17395333333333,
        store_longitude: -78.488445,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1336438,
        store_longitude: -78.4943238,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1103884074837,
        store_longitude: -78.489732099697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.087786773219705,
        store_longitude: -78.500775592402,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1907664,
        store_longitude: -78.510861,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2017404,
        store_longitude: -78.48020756,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0110736,
        store_longitude: -78.4475415,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.11250166666667,
        store_longitude: -78.496806666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20660121,
        store_longitude: -78.51089656,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.16779856,
        store_longitude: -78.48959731,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13941225595772,
        store_longitude: -78.509138552472,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.067994,
        store_longitude: -78.510559,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.0140683,
        store_longitude: -78.4494858,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1360996,
        store_longitude: -78.5003263,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1249412202,
        store_longitude: -78.4954838455,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.097850421443582,
        store_longitude: -78.489609220996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13380983378738,
        store_longitude: -78.50186557509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091234291903675,
        store_longitude: -78.529535830021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091217611916363,
        store_longitude: -78.529522335157,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11372166666667,
        store_longitude: -78.489546666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0014885840937495,
        store_longitude: -78.459245860577,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2164842,
        store_longitude: -78.5130076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22615795,
        store_longitude: -78.50650655,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20609666666667,
        store_longitude: -78.50179,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.16074333333333,
        store_longitude: -78.498373333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12374833333333,
        store_longitude: -78.51306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0894829,
        store_longitude: -78.4996276,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.09332406,
        store_longitude: -78.5028384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.06889,
        store_longitude: -78.461918333333,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.055848914198577,
        store_longitude: -78.459177380428,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.109494,
        store_longitude: -78.4966,
    },
    {
        store_latitdue: -0.11366,
        store_longitude: -78.489516666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.16737426,
        store_longitude: -78.48946375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.195057,
        store_longitude: -78.4971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129121,
        store_longitude: -78.484071,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11571,
        store_longitude: -78.501156666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1002102848,
        store_longitude: -78.504246107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.073084,
        store_longitude: -78.478771,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.05421399,
        store_longitude: -78.45661407,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1549781,
        store_longitude: -78.5041936,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.12118,
        store_longitude: -78.5098,
    },
    {
        store_latitdue: -0.13639489654452,
        store_longitude: -78.507138714194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.149812,
        store_longitude: -78.490485,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0885046,
        store_longitude: -78.5000952,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2075221,
        store_longitude: -78.499729,
    },

    {
        store_latitdue: 0.1208885,
        store_longitude: -79.2542936,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.20690082572401,
        store_longitude: -78.499118741602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.1217419,
        store_longitude: -79.2537403,
    },
    {
        store_latitdue: 0.1222003,
        store_longitude: -79.2530466,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2307087,
        store_longitude: -78.5375371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.067838333333333,
        store_longitude: -78.433901666667,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.06435,
        store_longitude: -78.5092,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08416109,
        store_longitude: -78.50699284,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0924822,
        store_longitude: -78.5062465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0395406,
        store_longitude: -78.4428579,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.064765,
        store_longitude: -78.461252,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.1226426,
        store_longitude: -79.2527291,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.1226542,
        store_longitude: -79.2535765,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.1227055,
        store_longitude: -79.2551142,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.1224226,
        store_longitude: -79.2531231,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.122289,
        store_longitude: -79.25523,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.123307988,
        store_longitude: -79.2555084,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.20647166666667,
        store_longitude: -78.521475,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.22814166666667,
        store_longitude: -78.510463333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1586514,
        store_longitude: -78.4935431,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13941782992333,
        store_longitude: -78.500508461148,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127351872,
        store_longitude: -78.498795903,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0932529,
        store_longitude: -78.5102684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1050297,
        store_longitude: -78.4956148,
    },
    {
        store_latitdue: -0.0036564139024287,
        store_longitude: -78.448850557144,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.2227995,
        store_longitude: -78.5090101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21827442,
        store_longitude: -78.50815529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1360707,
        store_longitude: -78.4971116,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1276731,
        store_longitude: -78.496602,
    },
    {
        store_latitdue: -0.0869356,
        store_longitude: -78.4302821,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.159206,
        store_longitude: -78.5033,
    },

    {
        store_latitdue: -0.1351981,
        store_longitude: -78.5008252,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: 0.08507,
        store_longitude: -79.048,
    },

    {
        store_latitdue: -0.1219525757,
        store_longitude: -78.4878452495,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2032630404,
        store_longitude: -78.5037587414,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1172487,
        store_longitude: -78.5035586,
    },
    {
        store_latitdue: -0.0910613,
        store_longitude: -78.5063022,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1226426881,
        store_longitude: -78.497209711,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1913416,
        store_longitude: -78.507189,
    },

    {
        store_latitdue: -0.0930818,
        store_longitude: -78.5041998,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.009896,
        store_longitude: -78.444521,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.2128307,
        store_longitude: -78.5180307,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: 0.9808245,
        store_longitude: -79.6569187,
    },

    {
        store_latitdue: -0.219337,
        store_longitude: -78.513245,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0192736,
        store_longitude: -78.4372558,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: -0.1796903,
        store_longitude: -78.4946156,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.328561,
        store_longitude: -78.426655,
    },

    {
        store_latitdue: -0.222017,
        store_longitude: -78.5201,
    },
    {
        store_latitdue: -0.2001385,
        store_longitude: -78.4994716,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.211027,
        store_longitude: -78.498942,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1851172,
        store_longitude: -78.5140827,
    },

    {
        store_latitdue: -0.213201,
        store_longitude: -78.522371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1892342,
        store_longitude: -78.5139327,
    },

    {
        store_latitdue: -0.207053,
        store_longitude: -78.504562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.010668,
        store_longitude: -78.481882,
    },

    {
        store_latitdue: -0.2916754,
        store_longitude: -78.4011662,
    },
    {
        store_latitdue: -0.2108362402,
        store_longitude: -78.514819555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.284773,
        store_longitude: -78.5695,
    },

    {
        store_latitdue: -0.0554654,
        store_longitude: -78.4547805,
    },

    {
        store_latitdue: -0.144807,
        store_longitude: -78.499959,
    },
    {
        store_latitdue: -0.0900238,
        store_longitude: -78.5033656,
    },

    {
        store_latitdue: -0.225674,
        store_longitude: -78.524377,
    },

    {
        store_latitdue: -0.1296912,
        store_longitude: -78.5143587,
    },

    {
        store_latitdue: -0.245016,
        store_longitude: -78.4926298,
    },

    {
        store_latitdue: -0.1272266,
        store_longitude: -78.5157477,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2233627,
        store_longitude: -78.5108632,
    },

    {
        store_latitdue: -0.1632892,
        store_longitude: -78.4969769,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.213188,
        store_longitude: -78.4954,
    },

    {
        store_latitdue: -0.295269,
        store_longitude: -78.5368,
    },

    {
        store_latitdue: -0.1258598,
        store_longitude: -78.5100026,
    },

    {
        store_latitdue: -0.1156993,
        store_longitude: -78.5101086,
    },

    {
        store_latitdue: -0.1783965,
        store_longitude: -78.507575,
    },
    {
        store_latitdue: -0.123915,
        store_longitude: -78.521557,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.094057,
        store_longitude: -78.507003,
    },

    {
        store_latitdue: -0.0067486,
        store_longitude: -78.5135106,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.125728,
        store_longitude: -78.5112,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.012648,
        store_longitude: -78.4485,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1656388,
        store_longitude: -78.4875313,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0107106,
        store_longitude: -78.4452,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0080979,
        store_longitude: -78.4449,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.206221,
        store_longitude: -78.5018,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.171772,
        store_longitude: -78.494142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1444957,
        store_longitude: -78.5005824,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.167459,
        store_longitude: -78.483955,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0104484,
        store_longitude: -78.4765,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1308155,
        store_longitude: -78.516827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238656,
        store_longitude: -78.5077359,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090846,
        store_longitude: -78.5221063,
    },
    {
        store_latitdue: -0.0799632,
        store_longitude: -78.5001526,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0912522,
        store_longitude: -78.5152359,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0866685,
        store_longitude: -78.5091319,
    },
    {
        store_latitdue: -0.0901781,
        store_longitude: -78.5031577,
    },

    {
        store_latitdue: -0.20275,
        store_longitude: -78.4295,
    },

    {
        store_latitdue: -0.12169944,
        store_longitude: -78.5187282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12141878250986,
        store_longitude: -78.510576719418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0936692,
        store_longitude: -78.4389179,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.2169703,
        store_longitude: -78.3988386,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.130738,
        store_longitude: -78.496567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0891749,
        store_longitude: -78.5066572,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12712,
        store_longitude: -78.469522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.067174667492509,
        store_longitude: -78.463948778808,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.13818455860019,
        store_longitude: -78.508776286617,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.088023561984301,
        store_longitude: -78.510470604524,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.195667,
        store_longitude: -78.499046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1909935,
        store_longitude: -78.496073,
    },
    {
        store_latitdue: -0.2639411,
        store_longitude: -78.5170949,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094791359928474,
        store_longitude: -78.50054542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14279833333333,
        store_longitude: -78.505046666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.180556,
        store_longitude: -78.513929,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116252,
        store_longitude: -78.43685,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0534851,
        store_longitude: -78.4534941,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.162163,
        store_longitude: -78.482152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18329112,
        store_longitude: -78.5101675,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.274091,
        store_longitude: -78.5682,
    },
    {
        store_latitdue: -0.094988,
        store_longitude: -78.524364,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094391666666667,
        store_longitude: -78.479935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12568666666667,
        store_longitude: -78.511198333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139091,
        store_longitude: -78.494002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129368,
        store_longitude: -78.494683,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156025,
        store_longitude: -78.492737,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1903919,
        store_longitude: -78.3891383,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.123823,
        store_longitude: -78.513013,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127758,
        store_longitude: -78.512515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1945797,
        store_longitude: -78.4790737,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2062696,
        store_longitude: -78.5027784,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1044998,
        store_longitude: -78.4932567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20931208,
        store_longitude: -78.5050667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20178775,
        store_longitude: -78.48941999,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1987908,
        store_longitude: -78.4963092,
    },
    {
        store_latitdue: -0.122051,
        store_longitude: -78.510638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.06696,
        store_longitude: -78.4639,
    },
    {
        store_latitdue: -0.115868,
        store_longitude: -78.4597,
    },
    {
        store_latitdue: -0.1718991,
        store_longitude: -78.4739224,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148883,
        store_longitude: -78.474365,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193103,
        store_longitude: -78.4952,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.174014196,
        store_longitude: -78.48946381,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119208,
        store_longitude: -78.5172,
    },
    {
        store_latitdue: -0.1885428,
        store_longitude: -78.5171398,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.177858,
        store_longitude: -78.49205,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.00057,
        store_longitude: -78.45702,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.042548333333333,
        store_longitude: -78.455681666667,
        neighborhood: 'POMASQUI',
    },

    {
        store_latitdue: -0.0964143,
        store_longitude: -78.5175757,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089784,
        store_longitude: -78.414349,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101681,
        store_longitude: -78.4383,
    },
    {
        store_latitdue: -0.189052,
        store_longitude: -78.512546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0902021,
        store_longitude: -78.506,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.06998497,
        store_longitude: -78.46964103,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0857843,
        store_longitude: -78.5095348,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0695769,
        store_longitude: -78.4701795,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.088527,
        store_longitude: -78.5091763,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1460083,
        store_longitude: -78.4925124,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.185431,
        store_longitude: -78.511145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1298844,
        store_longitude: -78.5111698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1293057,
        store_longitude: -78.5116402,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0853412,
        store_longitude: -78.5105384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0905174,
        store_longitude: -78.5203266,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.088373,
        store_longitude: -78.419417,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1857438,
        store_longitude: -78.4945446,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144066,
        store_longitude: -78.482287,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1432525,
        store_longitude: -78.4762612,
    },
    {
        store_latitdue: -0.092313333333333,
        store_longitude: -78.509713333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0964363,
        store_longitude: -78.5175674,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0775428,
        store_longitude: -78.4405735,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.077402,
        store_longitude: -78.436361,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0838368,
        store_longitude: -78.5093848,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102879,
        store_longitude: -78.492173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.093548,
        store_longitude: -78.504887,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178987,
        store_longitude: -78.517464,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0043818,
        store_longitude: -78.4408975,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.088735,
        store_longitude: -78.505096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105349,
        store_longitude: -78.494716,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.005957,
        store_longitude: -78.449953,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.1154229,
        store_longitude: -78.5094378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0948003,
        store_longitude: -78.516216,
    },
    {
        store_latitdue: -0.1562253,
        store_longitude: -78.4896349,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103517,
        store_longitude: -78.4930299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1193516,
        store_longitude: -78.4924877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189722,
        store_longitude: -78.501461,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083415,
        store_longitude: -78.46637,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.8460029,
        store_longitude: -78.6690608,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.9518678,
        store_longitude: -78.6893478,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.895328,
        store_longitude: -78.64813,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.938558,
        store_longitude: -78.6071002,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9278605,
        store_longitude: -78.6106575,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.919818,
        store_longitude: -78.6013,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8836044,
        store_longitude: -78.67442375,
    },
    {
        store_latitdue: -1.0661922,
        store_longitude: -78.5982211,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.066206,
        store_longitude: -78.5988356,
    },
    {
        store_latitdue: -1.0659464,
        store_longitude: -78.5986669,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.04539,
        store_longitude: -78.6028,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.7440792,
        store_longitude: -78.6105843,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.732794,
        store_longitude: -78.6096,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.729271,
        store_longitude: -78.608672,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7414941,
        store_longitude: -78.6186423,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -1.0686876,
        store_longitude: -78.5974873,
    },
    {
        store_latitdue: -1.06551,
        store_longitude: -78.5988,
    },
    {
        store_latitdue: -0.927103,
        store_longitude: -78.6084143,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9254699,
        store_longitude: -78.6040683,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9284314,
        store_longitude: -78.6052543,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9465415,
        store_longitude: -78.6108277,
    },
    {
        store_latitdue: -0.9402853,
        store_longitude: -78.6123023,
    },
    {
        store_latitdue: -0.938894,
        store_longitude: -78.6077,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.940603,
        store_longitude: -78.6063147,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9384344,
        store_longitude: -78.6040791,
    },
    {
        store_latitdue: -0.936272,
        store_longitude: -78.610031,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9399258,
        store_longitude: -78.6057151,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9265015,
        store_longitude: -78.6201459,
    },
    {
        store_latitdue: -0.934489867,
        store_longitude: -78.61762854,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8354713,
        store_longitude: -78.6664027,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -1.0410841,
        store_longitude: -78.5833604,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0433084,
        store_longitude: -78.5873481,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0427079,
        store_longitude: -78.5905014,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0439862,
        store_longitude: -78.5904828,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04438,
        store_longitude: -78.5888,
    },
    {
        store_latitdue: -1.0411003,
        store_longitude: -78.5907738,
    },
    {
        store_latitdue: -1.0390314,
        store_longitude: -78.5905251,
    },
    {
        store_latitdue: -1.0387145,
        store_longitude: -78.5907843,
    },
    {
        store_latitdue: -1.0395802,
        store_longitude: -78.590877,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.733463,
        store_longitude: -78.6164,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7255331,
        store_longitude: -78.6268357,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.729251,
        store_longitude: -78.635708,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7411507,
        store_longitude: -78.6437543,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7283983,
        store_longitude: -78.6348867,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.9501036,
        store_longitude: -78.6101612,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9226552,
        store_longitude: -78.660264,
    },
    {
        store_latitdue: -0.9252732,
        store_longitude: -78.6197349,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.725882,
        store_longitude: -78.6390948,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.9422999,
        store_longitude: -78.606228,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.714125,
        store_longitude: -78.635926,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.93041,
        store_longitude: -78.6191,
    },
    {
        store_latitdue: -1.0422812,
        store_longitude: -78.5882698,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0420906,
        store_longitude: -78.5881976,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.92935539,
        store_longitude: -78.6206102,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8388006,
        store_longitude: -78.6621298,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -1.0411048,
        store_longitude: -78.5836527,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9390391,
        store_longitude: -78.6075434,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93065,
        store_longitude: -78.62056,
    },
    {
        store_latitdue: -0.9124105,
        store_longitude: -78.6025301,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9147322,
        store_longitude: -78.6061299,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9216465,
        store_longitude: -78.6107512,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.922148,
        store_longitude: -78.610575,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9237528,
        store_longitude: -78.6119628,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.895223,
        store_longitude: -78.6116,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8651765,
        store_longitude: -78.6239662,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.8520647,
        store_longitude: -78.613535,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.9200943,
        store_longitude: -78.6133728,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.938547,
        store_longitude: -78.6156,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9562491,
        store_longitude: -78.6946086,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.80733424,
        store_longitude: -78.6223678,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9324,
        store_longitude: -78.6151,
    },
    {
        store_latitdue: -0.8148323,
        store_longitude: -78.6388153,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.818105,
        store_longitude: -78.638015,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8958148,
        store_longitude: -78.6101835,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8602075,
        store_longitude: -78.5842045,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.8785225,
        store_longitude: -78.6134604,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93595699357641,
        store_longitude: -78.614912433791,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8086587,
        store_longitude: -78.6440832,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8087997,
        store_longitude: -78.6418174,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.806278,
        store_longitude: -78.6466286,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.934797,
        store_longitude: -78.613395,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8148641,
        store_longitude: -78.6386769,
    },
    {
        store_latitdue: -0.857791,
        store_longitude: -78.590992,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.8044785,
        store_longitude: -78.6458566,
    },
    {
        store_latitdue: -0.9291358208,
        store_longitude: -78.6143171042,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8076533,
        store_longitude: -78.6223491,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9698067,
        store_longitude: -78.6090279,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9696109,
        store_longitude: -78.6090251,
    },
    {
        store_latitdue: -0.975147,
        store_longitude: -78.601549,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.974856,
        store_longitude: -78.601525,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.975382,
        store_longitude: -78.602672,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9562685,
        store_longitude: -78.5758501,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9792423,
        store_longitude: -78.5922659,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9791111,
        store_longitude: -78.5899128,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -1.0376957,
        store_longitude: -78.5932421,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.925679,
        store_longitude: -78.627283,
    },
    {
        store_latitdue: -0.9295268,
        store_longitude: -78.6259293,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9476097,
        store_longitude: -78.7053464,
    },
    {
        store_latitdue: -0.9941861,
        store_longitude: -78.6982243,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -1.07312,
        store_longitude: -78.7033,
        neighborhood: 'CUSUBAMBA',
    },
    {
        store_latitdue: -0.957803,
        store_longitude: -78.698407,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -1.03801,
        store_longitude: -78.585377,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.03574833,
        store_longitude: -78.5885202,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0384,
        store_longitude: -78.58927,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.043083,
        store_longitude: -78.592845,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.08609,
        store_longitude: -78.547,
    },
    {
        store_latitdue: -1.09,
        store_longitude: -78.5461735,
        neighborhood: 'SAN ANDRÉS',
    },
    {
        store_latitdue: -1.125077,
        store_longitude: -78.548411,
        neighborhood: 'SAN ANDRÉS',
    },
    {
        store_latitdue: -1.1355657,
        store_longitude: -78.5430635,
        neighborhood: 'SAN ANDRÉS',
    },
    {
        store_latitdue: -1.0635605,
        store_longitude: -78.571558,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.931414,
        store_longitude: -78.623,
    },
    {
        store_latitdue: -0.964073,
        store_longitude: -78.6984,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9562613,
        store_longitude: -78.698313,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9277252,
        store_longitude: -78.6260247,
    },
    {
        store_latitdue: -1.0495644,
        store_longitude: -78.5951535,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.1510303,
        store_longitude: -78.5437105,
        neighborhood: 'PÍLLARO',
    },
    {
        store_latitdue: -1.1255599,
        store_longitude: -78.5296092,
        neighborhood: 'SAN ANDRÉS',
    },
    {
        store_latitdue: -0.965815,
        store_longitude: -78.612721,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.954884,
        store_longitude: -78.62042,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9119818,
        store_longitude: -78.6231187,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.819554999,
        store_longitude: -78.58414385,
    },
    {
        store_latitdue: -0.898861,
        store_longitude: -78.620996,
    },
    {
        store_latitdue: -0.899907,
        store_longitude: -78.618322,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8897567,
        store_longitude: -78.6186833,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.91821,
        store_longitude: -78.6314,
    },
    {
        store_latitdue: -0.7569218,
        store_longitude: -78.6869713,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.892544,
        store_longitude: -78.6407,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8818654,
        store_longitude: -78.6453756,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.878292,
        store_longitude: -78.6411,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.04808,
        store_longitude: -78.5767,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0431494,
        store_longitude: -78.5811451,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0517039,
        store_longitude: -78.5685612,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0338903,
        store_longitude: -78.5650469,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.01792,
        store_longitude: -78.5893,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.025962,
        store_longitude: -78.5768969,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9590428,
        store_longitude: -78.6158648,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.954928,
        store_longitude: -78.636579,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.964514,
        store_longitude: -78.640494,
    },
    {
        store_latitdue: -0.9111613,
        store_longitude: -78.6337502,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.88357,
        store_longitude: -78.6406,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0306548,
        store_longitude: -78.5724924,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -0.988966,
        store_longitude: -78.621993,
    },
    {
        store_latitdue: -0.925248,
        store_longitude: -78.6235,
    },
    {
        store_latitdue: -0.884534,
        store_longitude: -78.6444032,
    },
    {
        store_latitdue: -1.047701,
        store_longitude: -78.5781256,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.033787276,
        store_longitude: -78.56492333,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.0337473,
        store_longitude: -78.5884868,
    },
    {
        store_latitdue: -1.0269618,
        store_longitude: -78.5846627,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0556083,
        store_longitude: -78.5112605,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.026846,
        store_longitude: -78.566568,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -0.9014276,
        store_longitude: -78.6418869,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9086408,
        store_longitude: -78.6381968,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0551112,
        store_longitude: -78.5188312,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.78122657,
        store_longitude: -78.6424789,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7933021,
        store_longitude: -78.6697457,
        neighborhood: 'TANICUCHÍ',
    },

    {
        store_latitdue: -1.17442,
        store_longitude: -78.5436152,
        neighborhood: 'PÍLLARO',
    },
    {
        store_latitdue: -1.169856,
        store_longitude: -78.5443901,
        neighborhood: 'PÍLLARO',
    },
    {
        store_latitdue: -0.922597,
        store_longitude: -78.610921,
    },
    {
        store_latitdue: -0.962931,
        store_longitude: -78.6073,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9587955,
        store_longitude: -78.6073856,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.959056,
        store_longitude: -78.6002114,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.95916,
        store_longitude: -78.60026,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.95448,
        store_longitude: -78.60162,
    },
    {
        store_latitdue: -0.9540367,
        store_longitude: -78.60236,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9487665,
        store_longitude: -78.6029288,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.951885,
        store_longitude: -78.604095,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9551403,
        store_longitude: -78.6109136,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9529111,
        store_longitude: -78.6162538,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.945781,
        store_longitude: -78.617372,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.946774,
        store_longitude: -78.6160754,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9389358,
        store_longitude: -78.6188838,
    },
    {
        store_latitdue: -0.9391696,
        store_longitude: -78.6203198,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.838432,
        store_longitude: -78.6678,
    },
    {
        store_latitdue: -0.838702,
        store_longitude: -78.669262,
    },
    {
        store_latitdue: -0.8385276,
        store_longitude: -78.6696288,
    },
    {
        store_latitdue: -1.0450876,
        store_longitude: -78.5857277,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.048199,
        store_longitude: -78.5863226,
    },
    {
        store_latitdue: -1.0555738,
        store_longitude: -78.584355,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.048593,
        store_longitude: -78.59121264,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0441994,
        store_longitude: -78.5917539,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.045563,
        store_longitude: -78.586994,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.7031186,
        store_longitude: -78.8924926,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7047721,
        store_longitude: -78.8889547,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.9551765,
        store_longitude: -78.6012186,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9535486,
        store_longitude: -78.6103339,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.955306,
        store_longitude: -78.604392,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9608877,
        store_longitude: -78.6000567,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.960972,
        store_longitude: -78.600116,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8424839,
        store_longitude: -78.6732018,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.838304,
        store_longitude: -78.6677917,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.832256,
        store_longitude: -78.6695,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.706282,
        store_longitude: -78.8865177,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.8356769,
        store_longitude: -78.6671799,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -1.0461341,
        store_longitude: -78.5947549,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.7073955,
        store_longitude: -78.8889448,
    },
    {
        store_latitdue: -1.047213,
        store_longitude: -78.5848657,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.838761,
        store_longitude: -78.6703,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.9537266,
        store_longitude: -78.5989691,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93995726,
        store_longitude: -78.6172332,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0496132,
        store_longitude: -78.595672,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.045093,
        store_longitude: -78.5869523,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.8333704,
        store_longitude: -78.686716,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.9562934,
        store_longitude: -78.6109765,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9096602,
        store_longitude: -78.6211029,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.040843,
        store_longitude: -78.590823,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.941004,
        store_longitude: -78.6118,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9496593,
        store_longitude: -78.610492,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9560055,
        store_longitude: -78.6311229,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8035573,
        store_longitude: -78.6209717,
    },
    {
        store_latitdue: -1.0461545,
        store_longitude: -78.5924882,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9542318,
        store_longitude: -78.6967475,
    },
    {
        store_latitdue: -0.7211135,
        store_longitude: -78.6308872,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -1.043946863,
        store_longitude: -78.58923512,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.7545154,
        store_longitude: -78.6104466,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -1.09379,
        store_longitude: -78.6294,
        neighborhood: 'MULALILLO',
    },
    {
        store_latitdue: -0.9092607,
        store_longitude: -78.6522592,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9330043,
        store_longitude: -78.6125587,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9541271,
        store_longitude: -78.6957437,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9322723,
        store_longitude: -78.6194013,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9469305,
        store_longitude: -78.6015635,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9316317,
        store_longitude: -78.6194138,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0332155,
        store_longitude: -78.482924,
    },
    {
        store_latitdue: -0.918808,
        store_longitude: -78.697035,
        neighborhood: 'LA VICTORIA',
    },
    {
        store_latitdue: -0.9577652,
        store_longitude: -78.6983129,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9375402,
        store_longitude: -78.6130072,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9551602,
        store_longitude: -78.6969009,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.927579,
        store_longitude: -78.626736,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.95326779,
        store_longitude: -78.69639155,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.931282,
        store_longitude: -78.620107,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.924241,
        store_longitude: -78.619744,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9318608,
        store_longitude: -78.6308127,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.042797533,
        store_longitude: -78.58512442,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9337796,
        store_longitude: -78.6765594,
        neighborhood: 'ONCE DE NOVIEMBRE',
    },
    {
        store_latitdue: 0.197057,
        store_longitude: -78.1907,
    },
    {
        store_latitdue: -0.940837,
        store_longitude: -78.612102,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.929121,
        store_longitude: -78.626006,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.756162212,
        store_longitude: -78.68708748,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -1.0325963,
        store_longitude: -78.565358,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.042139,
        store_longitude: -78.585102,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9316002,
        store_longitude: -78.6195215,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.84186226,
        store_longitude: -78.6715628,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.7588138,
        store_longitude: -78.6259574,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -1.0457954,
        store_longitude: -78.5901702,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04503,
        store_longitude: -78.5847,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9308401,
        store_longitude: -78.6263309,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -0.9392849,
        store_longitude: -78.6084345,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8756236,
        store_longitude: -78.6293258,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9580368,
        store_longitude: -78.6970068,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.710163,
        store_longitude: -78.609778,
    },
    {
        store_latitdue: -0.74845,
        store_longitude: -78.7169009,
        neighborhood: 'CANCHAGUA',
    },
    {
        store_latitdue: -0.753978,
        store_longitude: -78.6886,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.7541805,
        store_longitude: -78.6867775,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.758951,
        store_longitude: -78.62738,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9580126,
        store_longitude: -78.6020457,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.837857,
        store_longitude: -78.665,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.7565292,
        store_longitude: -78.6870713,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.7541767,
        store_longitude: -78.6868397,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -1.0840574,
        store_longitude: -78.5828881,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.8307314,
        store_longitude: -78.6678212,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8383136,
        store_longitude: -78.6667092,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -1.0415282166,
        store_longitude: -78.5838773676,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.922477,
        store_longitude: -78.6157,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9320811,
        store_longitude: -78.6104441,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.898373,
        store_longitude: -78.618116,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -0.7562865354,
        store_longitude: -78.6124263515,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9177211,
        store_longitude: -78.6127525,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0720753,
        store_longitude: -78.7030874,
        neighborhood: 'CUSUBAMBA',
    },
    {
        store_latitdue: -1.0443583554,
        store_longitude: -78.5857582038,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9314555765,
        store_longitude: -78.6228068648,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8873588,
        store_longitude: -78.6560664,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.7556296,
        store_longitude: -78.6863073,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.7442426,
        store_longitude: -78.6118297,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -1.0624484,
        store_longitude: -78.5830909,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0656661,
        store_longitude: -78.5988092,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.9540804625,
        store_longitude: -78.6951742462,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9442494,
        store_longitude: -78.6156325,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9370809,
        store_longitude: -78.6237011,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9364505,
        store_longitude: -78.6221357,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -0.77993308,
        store_longitude: -78.57511217,
        neighborhood: 'MULALÓ',
    },

    {
        store_latitdue: -1.0505139,
        store_longitude: -78.5877475,
        neighborhood: 'SAN MIGUEL',
    },

    {
        store_latitdue: -0.9606268,
        store_longitude: -78.6953009,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.7808437,
        store_longitude: -78.5762386,
        neighborhood: 'MULALÓ',
    },

    {
        store_latitdue: -0.8763678,
        store_longitude: -78.5992378,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.9968179,
        store_longitude: -78.5869656,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9663331,
        store_longitude: -78.6004825,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8387256365,
        store_longitude: -78.6678298563,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.7556926,
        store_longitude: -78.6062552,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.748,
        store_longitude: -78.718583,
        neighborhood: 'CANCHAGUA',
    },
    {
        store_latitdue: -0.9790377,
        store_longitude: -78.6174045,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.84784,
        store_longitude: -78.6606,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.9569014,
        store_longitude: -78.7016577,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9537532037,
        store_longitude: -78.6869872224,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -1.253207,
        store_longitude: -78.637394,
    },
    {
        store_latitdue: -0.966769,
        store_longitude: -78.6984,
    },

    {
        store_latitdue: -0.732245,
        store_longitude: -78.6198789,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },

    {
        store_latitdue: -0.9412233,
        store_longitude: -78.5798834,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.045073,
        store_longitude: -78.584798,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9320221,
        store_longitude: -78.6105291,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9295657,
        store_longitude: -78.6145628,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -0.91779,
        store_longitude: -78.6592056,
        neighborhood: 'ONCE DE NOVIEMBRE',
    },
    {
        store_latitdue: -0.8376116,
        store_longitude: -78.6660895,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8449531684,
        store_longitude: -78.6623248582,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -1.050364,
        store_longitude: -78.572113,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.06867,
        store_longitude: -78.5964,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.926857,
        store_longitude: -78.601896,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0447763,
        store_longitude: -78.5904361,
    },
    {
        store_latitdue: -0.96,
        store_longitude: -78.6135,
    },

    {
        store_latitdue: -1.0439045,
        store_longitude: -78.5927422,
    },
    {
        store_latitdue: -1.03077,
        store_longitude: -78.5854,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9138658,
        store_longitude: -78.6154671,
    },
    {
        store_latitdue: -0.9583345,
        store_longitude: -78.6965972,
        neighborhood: 'PUJILÍ',
    },

    {
        store_latitdue: -0.93044032,
        store_longitude: -78.6260969,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -1.0319323,
        store_longitude: -78.5658007,
    },
    {
        store_latitdue: -0.911778,
        store_longitude: -78.604859,
    },
    {
        store_latitdue: -0.72185,
        store_longitude: -78.62864,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },

    {
        store_latitdue: -0.9350882,
        store_longitude: -78.628509,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -1.04151,
        store_longitude: -78.5867,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9376143,
        store_longitude: -78.6061193,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9367514,
        store_longitude: -78.6064398,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -0.9295731,
        store_longitude: -78.618347,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8756922,
        store_longitude: -78.6808074,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.732879,
        store_longitude: -78.6397,
    },
    {
        store_latitdue: -0.7269814907,
        store_longitude: -78.6259183436,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },

    {
        store_latitdue: -0.7420293,
        store_longitude: -78.6464843,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7411731,
        store_longitude: -78.6438004,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7267717,
        store_longitude: -78.63876,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.9755258,
        store_longitude: -78.6178426,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9363111,
        store_longitude: -78.6091742,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8879715,
        store_longitude: -78.6164555,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.952805,
        store_longitude: -78.611375,
    },
    {
        store_latitdue: -0.95574,
        store_longitude: -78.6973,
    },

    {
        store_latitdue: -0.9401193,
        store_longitude: -78.6113919,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9377162,
        store_longitude: -78.6128065,
    },
    {
        store_latitdue: -0.936546,
        store_longitude: -78.612421,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -0.9394617,
        store_longitude: -78.609742,
    },
    {
        store_latitdue: -0.9561696,
        store_longitude: -78.6961435,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.8294577,
        store_longitude: -78.6677246,
        neighborhood: 'SAQUISILÍ',
    },

    {
        store_latitdue: -0.8499343,
        store_longitude: -78.6805936,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.90779,
        store_longitude: -78.6487,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0374774,
        store_longitude: -78.5868103,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04227,
        store_longitude: -78.5882,
    },
    {
        store_latitdue: -1.047796,
        store_longitude: -78.593406,
    },
    {
        store_latitdue: -0.7557722,
        store_longitude: -78.61008,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.75596,
        store_longitude: -78.6101,
    },

    {
        store_latitdue: -0.756384,
        store_longitude: -78.613927,
        neighborhood: 'TANICUCHÍ',
    },

    {
        store_latitdue: -0.929262,
        store_longitude: -78.611788,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9067509,
        store_longitude: -78.6348423,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.91856,
        store_longitude: -78.69579,
        neighborhood: 'LA VICTORIA',
    },
    {
        store_latitdue: -2.7472249,
        store_longitude: -78.8503049,
    },
    {
        store_latitdue: -0.9581532,
        store_longitude: -78.6092596,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9407538,
        store_longitude: -78.6121247,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8836883,
        store_longitude: -78.6065214,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.86548,
        store_longitude: -78.5955,
    },
    {
        store_latitdue: -0.95548682,
        store_longitude: -78.6044618,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9302443,
        store_longitude: -78.6171609,
    },
    {
        store_latitdue: -1.038486,
        store_longitude: -78.588899,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.7554431,
        store_longitude: -78.686282,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.9359222,
        store_longitude: -78.6307097,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.892507,
        store_longitude: -78.6405274,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9567304,
        store_longitude: -78.6201967,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9112188,
        store_longitude: -78.69217,
    },
    {
        store_latitdue: -1.0448197,
        store_longitude: -78.5835273,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9670192,
        store_longitude: -78.6146591,
    },
    {
        store_latitdue: -0.8768862,
        store_longitude: -78.6810871,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -1.031991,
        store_longitude: -78.596,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.7921723,
        store_longitude: -78.6001078,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -1.043392,
        store_longitude: -78.592796,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0673126,
        store_longitude: -78.5973731,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.7537699,
        store_longitude: -78.6098409,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9396451,
        store_longitude: -78.5931975,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9401701,
        store_longitude: -78.6124766,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8818576,
        store_longitude: -78.6414377,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8421858,
        store_longitude: -78.6641387,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.880252,
        store_longitude: -78.6413,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0981124,
        store_longitude: -78.6039758,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -0.93479,
        store_longitude: -78.61343,
    },

    {
        store_latitdue: -1.0806408,
        store_longitude: -78.549408,
        neighborhood: 'SAN ANDRÉS',
    },
    {
        store_latitdue: -1.1178982,
        store_longitude: -78.5423738,
        neighborhood: 'SAN ANDRÉS',
    },
    {
        store_latitdue: -1.1463071,
        store_longitude: -78.5426132,
        neighborhood: 'PRESIDENTE URBINA',
    },
    {
        store_latitdue: -1.1593528,
        store_longitude: -78.5531962,
        neighborhood: 'PRESIDENTE URBINA',
    },
    {
        store_latitdue: -1.0179518,
        store_longitude: -78.5805212,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9525804,
        store_longitude: -78.6114108,
    },

    {
        store_latitdue: -0.8848833,
        store_longitude: -78.6755117,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -1.0435418,
        store_longitude: -78.5888616,
    },
    {
        store_latitdue: -1.0405599,
        store_longitude: -78.5817875,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.043496,
        store_longitude: -78.583579,
    },
    {
        store_latitdue: -0.927428,
        store_longitude: -78.6197203,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.045681,
        store_longitude: -78.5902214,
    },
    {
        store_latitdue: -0.907248,
        store_longitude: -78.6514,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.2703254,
        store_longitude: -78.5403375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.926449,
        store_longitude: -78.601108,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.768752,
        store_longitude: -78.6836046,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.9723056,
        store_longitude: -78.6081815,
    },
    {
        store_latitdue: -0.904283,
        store_longitude: -78.595412,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9664129,
        store_longitude: -78.6983361,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.902101,
        store_longitude: -78.6369527,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0658413,
        store_longitude: -78.5988387,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.91811305,
        store_longitude: -78.6324012,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9290550403,
        store_longitude: -78.6089312252,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.935465,
        store_longitude: -78.616479,
    },
    {
        store_latitdue: -1.04698,
        store_longitude: -78.5884,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0710338,
        store_longitude: -78.5967778,
    },
    {
        store_latitdue: -0.8836695,
        store_longitude: -78.6756616,
    },
    {
        store_latitdue: -0.9579652,
        store_longitude: -78.6946802,
    },
    {
        store_latitdue: -0.9614368,
        store_longitude: -78.6119365,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.06598,
        store_longitude: -78.5986,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.9312734,
        store_longitude: -78.6232348,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.052398,
        store_longitude: -78.5985816,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9725649,
        store_longitude: -78.6132738,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9697479,
        store_longitude: -78.6429383,
    },
    {
        store_latitdue: -0.8655079,
        store_longitude: -78.5957971,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.7588364,
        store_longitude: -78.6264575,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9499945,
        store_longitude: -78.6162413,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9698159,
        store_longitude: -78.6074135,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.068731,
        store_longitude: -78.596039,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.865422,
        store_longitude: -78.608944,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.941437,
        store_longitude: -78.604736,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.041211,
        store_longitude: -78.584815,
    },
    {
        store_latitdue: -0.938188,
        store_longitude: -78.588469,
    },
    {
        store_latitdue: -1.0418741,
        store_longitude: -78.5906425,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.92671,
        store_longitude: -78.6153,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9614237,
        store_longitude: -78.6109144,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9058567,
        store_longitude: -78.6357034,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0435,
        store_longitude: -78.5853,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.95026,
        store_longitude: -78.688432,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.8905345,
        store_longitude: -78.6278951,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9530416,
        store_longitude: -78.6038593,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8842739,
        store_longitude: -78.6749537,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.940604,
        store_longitude: -78.612161,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93247,
        store_longitude: -78.62338,
    },
    {
        store_latitdue: -1.0472867,
        store_longitude: -78.5865962,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9266692,
        store_longitude: -78.6106049,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9256862,
        store_longitude: -78.6063729,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9194794,
        store_longitude: -78.6205245,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9255143,
        store_longitude: -78.6308178,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.919586,
        store_longitude: -78.6332,
        neighborhood: 'LATACUNGA',
    },

    {
        store_latitdue: -0.8408511,
        store_longitude: -78.6657471,
    },
    {
        store_latitdue: -1.0439656,
        store_longitude: -78.5886448,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0402124,
        store_longitude: -78.5801431,
    },
    {
        store_latitdue: -1.0197277,
        store_longitude: -78.5580585,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.0145848,
        store_longitude: -78.554069,
    },
    {
        store_latitdue: -1.027161589,
        store_longitude: -78.56658702,
    },
    {
        store_latitdue: -1.026938,
        store_longitude: -78.566557,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.033116,
        store_longitude: -78.564839,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.0347211,
        store_longitude: -78.5635699,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.033903,
        store_longitude: -78.565092,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.0302605,
        store_longitude: -78.5717153,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.0342112,
        store_longitude: -78.5762972,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.037968407,
        store_longitude: -78.58247965,
    },
    {
        store_latitdue: -1.042203,
        store_longitude: -78.58313,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0421534,
        store_longitude: -78.5850459,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0427041,
        store_longitude: -78.5890134,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0429736,
        store_longitude: -78.588313,
    },
    {
        store_latitdue: -1.0413469,
        store_longitude: -78.5851197,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.928928,
        store_longitude: -78.614652,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9294803,
        store_longitude: -78.6144827,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9311761,
        store_longitude: -78.6167748,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9293266,
        store_longitude: -78.6187686,
    },
    {
        store_latitdue: -0.928657,
        store_longitude: -78.619454,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9289129,
        store_longitude: -78.6193479,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9298012,
        store_longitude: -78.6190019,
    },
    {
        store_latitdue: -0.9300574,
        store_longitude: -78.6190015,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930448,
        store_longitude: -78.6189932,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.929987,
        store_longitude: -78.614337,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9276,
        store_longitude: -78.61585,
    },
    {
        store_latitdue: -0.9269189,
        store_longitude: -78.6149554,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.951188,
        store_longitude: -78.707789,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.951178,
        store_longitude: -78.707738,
    },
    {
        store_latitdue: -0.9618483,
        store_longitude: -78.7036862,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.971139,
        store_longitude: -78.715678,
    },
    {
        store_latitdue: -0.971639,
        store_longitude: -78.7171435,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.971211,
        store_longitude: -78.715829,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.966671,
        store_longitude: -78.7078166,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.965421,
        store_longitude: -78.7026,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9656367,
        store_longitude: -78.7013051,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.953124,
        store_longitude: -78.697035,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.95323456,
        store_longitude: -78.6963772,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.953426,
        store_longitude: -78.690263,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9835499,
        store_longitude: -78.6837915,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.934128,
        store_longitude: -78.6082,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9330898,
        store_longitude: -78.6065898,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9322912,
        store_longitude: -78.6065433,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.931042,
        store_longitude: -78.6058,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9304528,
        store_longitude: -78.6058922,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9297577,
        store_longitude: -78.6077434,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.790018101,
        store_longitude: -78.59855175,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.9282442,
        store_longitude: -78.6042548,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9316941,
        store_longitude: -78.6039954,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93226,
        store_longitude: -78.60383,
    },
    {
        store_latitdue: -0.93392,
        store_longitude: -78.604545,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9328938,
        store_longitude: -78.6082017,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.931492,
        store_longitude: -78.608209,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93055129,
        store_longitude: -78.61053587,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9315826,
        store_longitude: -78.6085295,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930497,
        store_longitude: -78.610179,
    },
    {
        store_latitdue: -0.9306406,
        store_longitude: -78.609214,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.931111,
        store_longitude: -78.60934,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9297781,
        store_longitude: -78.6076864,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9276815,
        store_longitude: -78.6049939,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92875,
        store_longitude: -78.610482,
    },
    {
        store_latitdue: -0.927688,
        store_longitude: -78.610741,
    },
    {
        store_latitdue: -0.927731,
        store_longitude: -78.611081,
    },
    {
        store_latitdue: -0.927259,
        store_longitude: -78.611806,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930129,
        store_longitude: -78.6115,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9294187,
        store_longitude: -78.6116473,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.91585,
        store_longitude: -78.6288,
    },
    {
        store_latitdue: -0.9160727,
        store_longitude: -78.6284989,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9150374,
        store_longitude: -78.6301525,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9282424,
        store_longitude: -78.6217167,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9278132,
        store_longitude: -78.6213984,
    },
    {
        store_latitdue: -0.9280253,
        store_longitude: -78.6214212,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9299998,
        store_longitude: -78.6202144,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93005,
        store_longitude: -78.62028,
    },
    {
        store_latitdue: -0.929850247,
        store_longitude: -78.62150546,
    },
    {
        store_latitdue: -1.0401324,
        store_longitude: -78.5800849,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.03447,
        store_longitude: -78.5674,
    },
    {
        store_latitdue: -0.927179,
        store_longitude: -78.613567,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9287389,
        store_longitude: -78.614714,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.926438,
        store_longitude: -78.620019,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9293725,
        store_longitude: -78.6137953,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.934045,
        store_longitude: -78.612042,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930809,
        store_longitude: -78.6096,
    },
    {
        store_latitdue: -0.93061,
        store_longitude: -78.6083,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9309914,
        store_longitude: -78.6059941,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.934103,
        store_longitude: -78.60822,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9288499,
        store_longitude: -78.6112339,
    },
    {
        store_latitdue: -1.041317,
        store_longitude: -78.573564,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.925623,
        store_longitude: -78.6212,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9253321,
        store_longitude: -78.6200391,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9252888,
        store_longitude: -78.6199782,
    },
    {
        store_latitdue: -0.931938,
        store_longitude: -78.608671,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.04157,
        store_longitude: -78.59,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9511649,
        store_longitude: -78.7075998,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.951348,
        store_longitude: -78.7072,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.959096,
        store_longitude: -78.700876,
    },
    {
        store_latitdue: -0.9613716,
        store_longitude: -78.7035218,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9315537,
        store_longitude: -78.619457,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9317486,
        store_longitude: -78.6193744,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9540738,
        store_longitude: -78.6967932,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9542617,
        store_longitude: -78.6974734,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -1.04024,
        store_longitude: -78.5803,
    },
    {
        store_latitdue: -1.03843,
        store_longitude: -78.5785,
    },
    {
        store_latitdue: -0.9547734,
        store_longitude: -78.6932868,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.954605,
        store_longitude: -78.6927,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.951429,
        store_longitude: -78.694153,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -1.041435,
        store_longitude: -78.572931,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0421526,
        store_longitude: -78.5880093,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9265603,
        store_longitude: -78.6153418,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.03451,
        store_longitude: -78.5667,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -0.9307523,
        store_longitude: -78.6178244,
    },
    {
        store_latitdue: -0.930773,
        store_longitude: -78.616189,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9475902,
        store_longitude: -78.7051244,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.972183,
        store_longitude: -78.718798,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9295656,
        store_longitude: -78.6146025,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.929419,
        store_longitude: -78.611354,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9291165,
        store_longitude: -78.607176,
    },
    {
        store_latitdue: -0.926776,
        store_longitude: -78.622086,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.040344,
        store_longitude: -78.576588,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.93088,
        store_longitude: -78.615614,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.927785,
        store_longitude: -78.616,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.039723,
        store_longitude: -78.579075,
    },
    {
        store_latitdue: -0.9273815,
        store_longitude: -78.6144886,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92735,
        store_longitude: -78.6143,
    },
    {
        store_latitdue: -0.932842,
        store_longitude: -78.618198,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9573248,
        store_longitude: -78.6997333,
    },
    {
        store_latitdue: -0.9514006,
        store_longitude: -78.6976232,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.927864,
        store_longitude: -78.618937,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.95417,
        store_longitude: -78.697698,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -1.0158917,
        store_longitude: -78.561915,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.0468825,
        store_longitude: -78.589834,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0563166,
        store_longitude: -78.5909739,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.05575,
        store_longitude: -78.5884777,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0558432,
        store_longitude: -78.584257,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0559884,
        store_longitude: -78.584091,
    },
    {
        store_latitdue: 0.8532299,
        store_longitude: -79.8516157,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: -1.045706,
        store_longitude: -78.581344,
    },
    {
        store_latitdue: -1.045265,
        store_longitude: -78.588607,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0457144,
        store_longitude: -78.588789,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0541134,
        store_longitude: -78.5891489,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0522595,
        store_longitude: -78.5992427,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9328788,
        store_longitude: -78.622113,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9330017,
        store_longitude: -78.6220122,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.939277,
        store_longitude: -78.61897,
    },
    {
        store_latitdue: -0.932512,
        store_longitude: -78.624473,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93261,
        store_longitude: -78.624361,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9310978,
        store_longitude: -78.6223807,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930129,
        store_longitude: -78.6229,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.927135,
        store_longitude: -78.624736,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9286062,
        store_longitude: -78.6260214,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9295302,
        store_longitude: -78.6259143,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930048267,
        store_longitude: -78.62532989,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8554431,
        store_longitude: -78.6471262,
        neighborhood: 'CHANTILÍN',
    },
    {
        store_latitdue: -0.839995,
        store_longitude: -78.6660417,
    },
    {
        store_latitdue: -0.8406188,
        store_longitude: -78.6658136,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8398814,
        store_longitude: -78.6658781,
    },
    {
        store_latitdue: -0.8397707,
        store_longitude: -78.665928,
    },
    {
        store_latitdue: -0.839132,
        store_longitude: -78.666,
    },
    {
        store_latitdue: -0.838348,
        store_longitude: -78.666208,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.838434,
        store_longitude: -78.6661,
    },
    {
        store_latitdue: -0.8382997,
        store_longitude: -78.6667305,
    },
    {
        store_latitdue: -0.937532,
        store_longitude: -78.6128,
    },
    {
        store_latitdue: -0.8383419,
        store_longitude: -78.6669123,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.223082,
        store_longitude: -78.489374,
    },
    {
        store_latitdue: -0.838383,
        store_longitude: -78.6677,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.836049,
        store_longitude: -78.667297,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.835042,
        store_longitude: -78.668421,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.837701,
        store_longitude: -78.666067,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.855611,
        store_longitude: -78.6494,
        neighborhood: 'CHANTILÍN',
    },
    {
        store_latitdue: -0.838111,
        store_longitude: -78.6648,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.927444,
        store_longitude: -78.6258499,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8638469,
        store_longitude: -78.6237312,
    },
    {
        store_latitdue: -0.804619,
        store_longitude: -78.621036,
    },
    {
        store_latitdue: -0.789789,
        store_longitude: -78.598563,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.78079,
        store_longitude: -78.5765,
    },
    {
        store_latitdue: -0.780796,
        store_longitude: -78.5772846,
    },
    {
        store_latitdue: -0.780791,
        store_longitude: -78.576603,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.805235,
        store_longitude: -78.6213183,
    },
    {
        store_latitdue: -0.8042365,
        store_longitude: -78.6207099,
    },
    {
        store_latitdue: -0.7812423,
        store_longitude: -78.5752396,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.780494,
        store_longitude: -78.573476,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.788798,
        store_longitude: -78.578,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.8070927,
        store_longitude: -78.5887189,
        neighborhood: 'JOSEGUANGO BAJO',
    },
    {
        store_latitdue: -0.8079228,
        store_longitude: -78.5880695,
        neighborhood: 'JOSEGUANGO BAJO',
    },
    {
        store_latitdue: -0.81671317,
        store_longitude: -78.59131253,
        neighborhood: 'JOSEGUANGO BAJO',
    },
    {
        store_latitdue: -0.8023919,
        store_longitude: -78.6690224,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.7977519,
        store_longitude: -78.6694209,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.763836,
        store_longitude: -78.686,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.748724,
        store_longitude: -78.72216,
    },
    {
        store_latitdue: -0.748658,
        store_longitude: -78.7218,
    },
    {
        store_latitdue: -0.7482415,
        store_longitude: -78.7218222,
        neighborhood: 'CANCHAGUA',
    },
    {
        store_latitdue: -0.7537338,
        store_longitude: -78.6895165,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.7550294,
        store_longitude: -78.6871607,
    },
    {
        store_latitdue: -0.758243,
        store_longitude: -78.68686,
    },
    {
        store_latitdue: -0.756902,
        store_longitude: -78.686142,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.756503,
        store_longitude: -78.6862618,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.7566947,
        store_longitude: -78.6870705,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.7560652,
        store_longitude: -78.6874151,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.75821,
        store_longitude: -78.6869,
    },
    {
        store_latitdue: -0.7561277,
        store_longitude: -78.6874121,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.759796,
        store_longitude: -78.6467063,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -1.04871,
        store_longitude: -78.5928,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.055617,
        store_longitude: -78.588809,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.05589,
        store_longitude: -78.5903,
    },
    {
        store_latitdue: -1.0588326,
        store_longitude: -78.582069,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0577929,
        store_longitude: -78.5798986,
    },
    {
        store_latitdue: -1.04988,
        store_longitude: -78.585677,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.928055,
        store_longitude: -78.6268,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.931138,
        store_longitude: -78.6264,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8605122,
        store_longitude: -78.6190294,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.781249,
        store_longitude: -78.576032,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.798562,
        store_longitude: -78.585937,
        neighborhood: 'JOSEGUANGO BAJO',
    },
    {
        store_latitdue: -0.8076301,
        store_longitude: -78.588855,
        neighborhood: 'JOSEGUANGO BAJO',
    },
    {
        store_latitdue: -0.816108,
        store_longitude: -78.584282,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.756065,
        store_longitude: -78.68732,
    },
    {
        store_latitdue: -0.802797,
        store_longitude: -78.669,
    },
    {
        store_latitdue: -1.05714,
        store_longitude: -78.588,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9325849,
        store_longitude: -78.6244343,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9311378,
        store_longitude: -78.6261159,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.85683,
        store_longitude: -78.616882,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.857087,
        store_longitude: -78.615096,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -1.0633826,
        store_longitude: -78.5714976,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9318157,
        store_longitude: -78.623469,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9612316,
        store_longitude: -78.6110705,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9296657,
        store_longitude: -78.6264866,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930756,
        store_longitude: -78.624295,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9254565,
        store_longitude: -78.6272994,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8456247,
        store_longitude: -78.6447465,
        neighborhood: 'CHANTILÍN',
    },
    {
        store_latitdue: -0.7812234,
        store_longitude: -78.5767434,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.760381,
        store_longitude: -78.687,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.7541636,
        store_longitude: -78.6872166,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.265986,
        store_longitude: -78.5281,
    },
    {
        store_latitdue: -0.7932679,
        store_longitude: -78.669577,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.759849,
        store_longitude: -78.646491,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.80457102,
        store_longitude: -78.62110984,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.8044552,
        store_longitude: -78.6215051,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.8041915,
        store_longitude: -78.6217891,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.8041383,
        store_longitude: -78.6212876,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.817938,
        store_longitude: -78.584347,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -0.757314,
        store_longitude: -78.686156,
    },
    {
        store_latitdue: -0.748544,
        store_longitude: -78.722449,
        neighborhood: 'CANCHAGUA',
    },
    {
        store_latitdue: -1.0504509,
        store_longitude: -78.5717903,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.8395361,
        store_longitude: -78.6658908,
    },
    {
        store_latitdue: -0.833226,
        store_longitude: -78.6677,
    },
    {
        store_latitdue: -0.9275585,
        store_longitude: -78.6261272,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8324732,
        store_longitude: -78.6684121,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8029007,
        store_longitude: -78.5865492,
        neighborhood: 'JOSEGUANGO BAJO',
    },
    {
        store_latitdue: -1.0472676,
        store_longitude: -78.5791045,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9324665,
        store_longitude: -78.6247453,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9304157,
        store_longitude: -78.6249045,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0470115,
        store_longitude: -78.5865764,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.756165,
        store_longitude: -78.69004,
        neighborhood: 'TOACASO',
    },
    {
        store_latitdue: -0.92777,
        store_longitude: -78.624502,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.928375,
        store_longitude: -78.6240133,
    },
    {
        store_latitdue: -0.9261035,
        store_longitude: -78.6271609,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.046919,
        store_longitude: -78.594017,
    },
    {
        store_latitdue: -1.0468361,
        store_longitude: -78.5934557,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0464716,
        store_longitude: -78.5925725,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04582,
        store_longitude: -78.5911,
    },
    {
        store_latitdue: -1.0432774,
        store_longitude: -78.5910364,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.041063,
        store_longitude: -78.59194,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0418,
        store_longitude: -78.592,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0418889,
        store_longitude: -78.592925,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0452099,
        store_longitude: -78.6087936,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0456473,
        store_longitude: -78.6027014,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0452628,
        store_longitude: -78.6022783,
    },
    {
        store_latitdue: -1.0454305,
        store_longitude: -78.6025534,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0459846,
        store_longitude: -78.6000013,
    },
    {
        store_latitdue: -1.044052,
        store_longitude: -78.5918263,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.043836,
        store_longitude: -78.591975,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.936968,
        store_longitude: -78.612903,
    },
    {
        store_latitdue: -0.936988,
        store_longitude: -78.612899,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.936795,
        store_longitude: -78.613931,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.938454,
        store_longitude: -78.614982,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9384382,
        store_longitude: -78.6155855,
    },
    {
        store_latitdue: -0.9365,
        store_longitude: -78.61619,
    },
    {
        store_latitdue: -0.935174,
        store_longitude: -78.616613,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9358822,
        store_longitude: -78.6172966,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9365,
        store_longitude: -78.6173,
    },
    {
        store_latitdue: -0.935278,
        store_longitude: -78.618119,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9399445,
        store_longitude: -78.6116662,
    },
    {
        store_latitdue: -0.933495,
        store_longitude: -78.6163833,
    },
    {
        store_latitdue: -0.9334992,
        store_longitude: -78.6163987,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9254929,
        store_longitude: -78.6205722,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9320051,
        store_longitude: -78.6141225,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.932755,
        store_longitude: -78.613988,
    },
    {
        store_latitdue: -0.9359926,
        store_longitude: -78.6147663,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.797148,
        store_longitude: -78.6574,
    },
    {
        store_latitdue: -0.7891062,
        store_longitude: -78.6573112,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7810927,
        store_longitude: -78.6420842,
    },
    {
        store_latitdue: -0.7927584,
        store_longitude: -78.646265,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.788527,
        store_longitude: -78.64403,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7828742,
        store_longitude: -78.6417887,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7816122,
        store_longitude: -78.644304,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.781265,
        store_longitude: -78.642797,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7803185,
        store_longitude: -78.6398886,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.781357,
        store_longitude: -78.6386,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7772835,
        store_longitude: -78.6409003,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.766079,
        store_longitude: -78.6318162,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.763816,
        store_longitude: -78.6302,
    },
    {
        store_latitdue: -0.762394,
        store_longitude: -78.6289,
    },
    {
        store_latitdue: -0.760285,
        store_longitude: -78.6271,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.759506,
        store_longitude: -78.626337,
    },
    {
        store_latitdue: -0.7589357,
        store_longitude: -78.625971,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7590361,
        store_longitude: -78.6259236,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.753912,
        store_longitude: -78.62312,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.776538,
        store_longitude: -78.637932,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.778173,
        store_longitude: -78.639404,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.83833,
        store_longitude: -78.6671,
    },
    {
        store_latitdue: -0.925632,
        store_longitude: -78.605498,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9257318,
        store_longitude: -78.6051579,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9238092,
        store_longitude: -78.6018612,
    },
    {
        store_latitdue: -0.937455,
        store_longitude: -78.5745,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.936839,
        store_longitude: -78.57459,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.939538,
        store_longitude: -78.5847455,
    },
    {
        store_latitdue: -0.9392832,
        store_longitude: -78.5894461,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93908659,
        store_longitude: -78.58972789,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9394801,
        store_longitude: -78.592827,
    },
    {
        store_latitdue: -0.9394616,
        store_longitude: -78.5938491,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9396896,
        store_longitude: -78.5935434,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9394323,
        store_longitude: -78.5938636,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9331802,
        store_longitude: -78.6037593,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9296631,
        store_longitude: -78.5824726,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92713,
        store_longitude: -78.5865,
    },
    {
        store_latitdue: -0.9261959,
        store_longitude: -78.5952966,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9283255,
        store_longitude: -78.5988075,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.934249,
        store_longitude: -78.602,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.926054,
        store_longitude: -78.607088,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92625,
        store_longitude: -78.608032,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.2358576,
        store_longitude: -78.5317615,
    },
    {
        store_latitdue: -0.736203,
        store_longitude: -78.6107231,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.733285,
        store_longitude: -78.6099263,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7323736,
        store_longitude: -78.6093857,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.728531,
        store_longitude: -78.608457,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7104219,
        store_longitude: -78.5964821,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7103294,
        store_longitude: -78.5964811,
    },
    {
        store_latitdue: -0.6999677,
        store_longitude: -78.5876004,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.702594,
        store_longitude: -78.584078,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.710633,
        store_longitude: -78.5975,
    },
    {
        store_latitdue: -0.7122385,
        store_longitude: -78.6042238,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.711447,
        store_longitude: -78.6113,
    },
    {
        store_latitdue: -0.724122,
        store_longitude: -78.6101,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7344362,
        store_longitude: -78.5748821,
        neighborhood: 'MULALÓ',
    },
    {
        store_latitdue: -1.205946,
        store_longitude: -78.5344131,
        neighborhood: 'SAN MIGUELITO',
    },
    {
        store_latitdue: -1.045663,
        store_longitude: -78.5924763,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.937082,
        store_longitude: -78.5745,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9414071,
        store_longitude: -78.5788633,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.775693,
        store_longitude: -78.6424,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.766192,
        store_longitude: -78.631689,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.777395,
        store_longitude: -78.6386189,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9370107,
        store_longitude: -78.6137287,
    },
    {
        store_latitdue: -0.9366821,
        store_longitude: -78.6139283,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9393293,
        store_longitude: -78.6246245,
    },
    {
        store_latitdue: -1.042213,
        store_longitude: -78.590645,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0419028,
        store_longitude: -78.5907636,
    },
    {
        store_latitdue: -0.757433,
        store_longitude: -78.624872,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.703355,
        store_longitude: -78.5923,
    },
    {
        store_latitdue: -0.71381,
        store_longitude: -78.5995,
    },
    {
        store_latitdue: -0.739105,
        store_longitude: -78.6104848,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9356742,
        store_longitude: -78.617207,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9329634,
        store_longitude: -78.6182976,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.79838,
        store_longitude: -78.6467,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.787302,
        store_longitude: -78.644289,
    },
    {
        store_latitdue: -0.781583,
        store_longitude: -78.6443991,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.939142,
        store_longitude: -78.588969,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.7183212,
        store_longitude: -78.616293,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.9345999,
        store_longitude: -78.6134065,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.76651668,
        store_longitude: -78.6357879,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.925401,
        store_longitude: -78.5897,
    },
    {
        store_latitdue: -1.0449044,
        store_longitude: -78.592491,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.8355038,
        store_longitude: -78.6673629,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.9577606,
        store_longitude: -78.6064556,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.043693,
        store_longitude: -78.595695,
    },
    {
        store_latitdue: -0.773567,
        store_longitude: -78.6299,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.724206,
        store_longitude: -78.619652,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -1.0453913,
        store_longitude: -78.6085568,
    },
    {
        store_latitdue: -0.9371581,
        store_longitude: -78.6128732,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0432217,
        store_longitude: -78.580995,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.7997712,
        store_longitude: -78.6505866,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -1.0427243,
        store_longitude: -78.5844935,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04458045,
        store_longitude: -78.58712,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.045172,
        store_longitude: -78.587088,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0443083,
        store_longitude: -78.587981,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.044128,
        store_longitude: -78.58796,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0433889,
        store_longitude: -78.5850015,
    },
    {
        store_latitdue: -1.043722,
        store_longitude: -78.587312,
    },
    {
        store_latitdue: -1.0434552,
        store_longitude: -78.5873887,
    },
    {
        store_latitdue: -1.0436851,
        store_longitude: -78.5863197,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04377,
        store_longitude: -78.5858,
    },
    {
        store_latitdue: -0.9739723,
        store_longitude: -78.6122343,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9757056,
        store_longitude: -78.617845,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9854577,
        store_longitude: -78.620669,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.98644,
        store_longitude: -78.6211,
    },
    {
        store_latitdue: -0.9537283,
        store_longitude: -78.6265422,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.952217,
        store_longitude: -78.6221,
    },
    {
        store_latitdue: -0.9486,
        store_longitude: -78.62174,
    },
    {
        store_latitdue: -0.9364531,
        store_longitude: -78.6275337,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9336373,
        store_longitude: -78.63048,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9332942,
        store_longitude: -78.6316709,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.932703,
        store_longitude: -78.6303709,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.932114,
        store_longitude: -78.629665,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9318548,
        store_longitude: -78.6309633,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9314044,
        store_longitude: -78.6318057,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9316644,
        store_longitude: -78.6330027,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.931516,
        store_longitude: -78.629362,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.845407,
        store_longitude: -78.664489,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.845282,
        store_longitude: -78.666115,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.845506,
        store_longitude: -78.666995,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.845601,
        store_longitude: -78.6676634,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8406156,
        store_longitude: -78.6705637,
    },
    {
        store_latitdue: -0.852906,
        store_longitude: -78.6828504,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8607647,
        store_longitude: -78.6890481,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.8609359,
        store_longitude: -78.6897864,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.8566985,
        store_longitude: -78.6908373,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.83864,
        store_longitude: -78.6693,
    },
    {
        store_latitdue: -0.838482,
        store_longitude: -78.6684,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8388025,
        store_longitude: -78.6701396,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.9204829,
        store_longitude: -78.6341866,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92021,
        store_longitude: -78.636108,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9197999,
        store_longitude: -78.6372912,
    },
    {
        store_latitdue: -0.9189312,
        store_longitude: -78.6394091,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9177375,
        store_longitude: -78.6406846,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.924382,
        store_longitude: -78.6320864,
    },
    {
        store_latitdue: -0.9245989,
        store_longitude: -78.6331795,
    },
    {
        store_latitdue: -0.924731,
        store_longitude: -78.634217,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92488346,
        store_longitude: -78.633482,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9244946,
        store_longitude: -78.635408,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9242068,
        store_longitude: -78.6309738,
    },
    {
        store_latitdue: -0.9282878,
        store_longitude: -78.629544,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9254583,
        store_longitude: -78.63059,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9211043,
        store_longitude: -78.6318863,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.920164,
        store_longitude: -78.6321,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9199501,
        store_longitude: -78.631417,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.959744,
        store_longitude: -78.613478,
    },
    {
        store_latitdue: -0.9179936,
        store_longitude: -78.6315593,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9165664,
        store_longitude: -78.6322702,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.916385,
        store_longitude: -78.632,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9242323,
        store_longitude: -78.6309191,
    },
    {
        store_latitdue: -0.9168811,
        store_longitude: -78.6319645,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9226511,
        store_longitude: -78.6303428,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.7351426,
        store_longitude: -78.6123155,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7268264,
        store_longitude: -78.62602,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.724299,
        store_longitude: -78.6274,
    },
    {
        store_latitdue: -0.723605,
        store_longitude: -78.6280348,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.721789,
        store_longitude: -78.6287,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7219673,
        store_longitude: -78.6288416,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7220979,
        store_longitude: -78.6291329,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7376948,
        store_longitude: -78.6426399,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.747303,
        store_longitude: -78.643344,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7466489,
        store_longitude: -78.6434223,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.744984,
        store_longitude: -78.644,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.809441,
        store_longitude: -78.641777,
    },
    {
        store_latitdue: -0.732779,
        store_longitude: -78.6395,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.72475,
        store_longitude: -78.636342,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7263477,
        store_longitude: -78.635978,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7285379,
        store_longitude: -78.6348202,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.72775,
        store_longitude: -78.6344,
    },
    {
        store_latitdue: -0.7279961,
        store_longitude: -78.6347757,
    },
    {
        store_latitdue: -1.043632,
        store_longitude: -78.585226,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0449716,
        store_longitude: -78.587055,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0450504,
        store_longitude: -78.587064,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.91657131,
        store_longitude: -78.6313781,
    },
    {
        store_latitdue: -1.043595,
        store_longitude: -78.5881973,
    },
    {
        store_latitdue: -1.0442138,
        store_longitude: -78.5872103,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04367,
        store_longitude: -78.585977,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.02673137,
        store_longitude: -78.609642,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9316241,
        store_longitude: -78.6327589,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.933392,
        store_longitude: -78.6273991,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9333722,
        store_longitude: -78.6273559,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9326361,
        store_longitude: -78.6288581,
    },
    {
        store_latitdue: -0.9331773,
        store_longitude: -78.634884,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9336769,
        store_longitude: -78.630997,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9348579,
        store_longitude: -78.6302868,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.934697,
        store_longitude: -78.62994,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9199606,
        store_longitude: -78.6322552,
    },
    {
        store_latitdue: -0.9238571,
        store_longitude: -78.6303025,
    },
    {
        store_latitdue: -0.9201195,
        store_longitude: -78.637081,
    },
    {
        store_latitdue: -0.838473,
        store_longitude: -78.667946,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.709879,
        store_longitude: -78.633404,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7066721,
        store_longitude: -78.6417547,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.726611,
        store_longitude: -78.635749,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.9328339,
        store_longitude: -78.6302145,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92178,
        store_longitude: -78.63268,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.922632,
        store_longitude: -78.631531,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9300694,
        store_longitude: -78.6290682,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.726253,
        store_longitude: -78.6335,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.9349817,
        store_longitude: -78.6275736,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.935565,
        store_longitude: -78.6312,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8409024,
        store_longitude: -78.671865,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.916817,
        store_longitude: -78.634392,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9272634,
        store_longitude: -78.630815,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92815,
        store_longitude: -78.6296,
    },
    {
        store_latitdue: -0.731968,
        store_longitude: -78.62188,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.727576,
        store_longitude: -78.634277,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -1.044925,
        store_longitude: -78.588728,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0436617,
        store_longitude: -78.586105,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9541142,
        store_longitude: -78.6333514,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.933808,
        store_longitude: -78.6312,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.84194,
        store_longitude: -78.6719,
    },
    {
        store_latitdue: -0.8349024,
        store_longitude: -78.6697549,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8351448,
        store_longitude: -78.6697484,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8461448,
        store_longitude: -78.6770661,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.844679,
        store_longitude: -78.6681147,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.840252,
        store_longitude: -78.6695,
    },
    {
        store_latitdue: -0.9204961,
        store_longitude: -78.6357533,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.926104,
        store_longitude: -78.629264,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9195714,
        store_longitude: -78.6333863,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9203051,
        store_longitude: -78.6335608,
    },
    {
        store_latitdue: -0.926886,
        store_longitude: -78.630095,
    },
    {
        store_latitdue: -1.0448188,
        store_longitude: -78.5857825,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9214964,
        store_longitude: -78.6317861,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9290266,
        store_longitude: -78.6293258,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9252747,
        store_longitude: -78.6295967,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.84171,
        store_longitude: -78.6712,
    },
    {
        store_latitdue: -0.8385585,
        store_longitude: -78.6689106,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.9339953,
        store_longitude: -78.6145698,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.974976,
        store_longitude: -78.6180676,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9235698,
        store_longitude: -78.6306314,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92442079,
        store_longitude: -78.6309742,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.936128,
        store_longitude: -78.627682,
    },
    {
        store_latitdue: -0.926864,
        store_longitude: -78.6364,
    },
    {
        store_latitdue: -1.0448508,
        store_longitude: -78.5902346,
    },
    {
        store_latitdue: -0.8235212,
        store_longitude: -78.5952279,
        neighborhood: 'JOSEGUANGO BAJO',
    },
    {
        store_latitdue: -0.9351838,
        store_longitude: -78.631015,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9340505,
        store_longitude: -78.6318892,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.7209782,
        store_longitude: -78.6307926,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.7280883,
        store_longitude: -78.6346874,
    },
    {
        store_latitdue: -0.9334715,
        store_longitude: -78.636741,
    },
    {
        store_latitdue: -0.838486,
        store_longitude: -78.6688,
    },
    {
        store_latitdue: -1.0121887,
        store_longitude: -78.6131389,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0127905,
        store_longitude: -78.6130675,
    },
    {
        store_latitdue: -1.0273825,
        store_longitude: -78.6089673,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.01180539,
        store_longitude: -78.6029398,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.014148,
        store_longitude: -78.598208,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0338,
        store_longitude: -78.5854,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0363712,
        store_longitude: -78.5857229,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.038079,
        store_longitude: -78.585283,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0315335,
        store_longitude: -78.5888525,
    },
    {
        store_latitdue: -1.02909,
        store_longitude: -78.59,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0374594,
        store_longitude: -78.5865445,
    },
    {
        store_latitdue: -1.036647,
        store_longitude: -78.58913,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.03413,
        store_longitude: -78.5935,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0323829,
        store_longitude: -78.5941714,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.02962,
        store_longitude: -78.5949,
    },
    {
        store_latitdue: -1.02752,
        store_longitude: -78.594,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0292239,
        store_longitude: -78.5917222,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9619837,
        store_longitude: -78.6149827,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.959475,
        store_longitude: -78.6158,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9589451,
        store_longitude: -78.6157216,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.958795,
        store_longitude: -78.615743,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.95831,
        store_longitude: -78.6144,
    },
    {
        store_latitdue: -0.950231,
        store_longitude: -78.615342,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9508043,
        store_longitude: -78.6164249,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.950661,
        store_longitude: -78.616779,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9505646,
        store_longitude: -78.6161036,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9472523,
        store_longitude: -78.6169424,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.945225,
        store_longitude: -78.616104,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.945007,
        store_longitude: -78.616071,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.945062,
        store_longitude: -78.616676,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.938931,
        store_longitude: -78.6201725,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.938221,
        store_longitude: -78.62125,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9365585,
        store_longitude: -78.6206378,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.936424,
        store_longitude: -78.621804,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9346648,
        store_longitude: -78.6227746,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.933959,
        store_longitude: -78.622032,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.962004,
        store_longitude: -78.6149746,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8189333,
        store_longitude: -78.6378333,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8192133,
        store_longitude: -78.6392005,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8216556,
        store_longitude: -78.642671,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8196992,
        store_longitude: -78.6400174,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8214388165,
        store_longitude: -78.6421810324,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8220659,
        store_longitude: -78.6430728,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8216116,
        store_longitude: -78.6415458,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.827066,
        store_longitude: -78.6455,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.81776,
        store_longitude: -78.6438,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.815311,
        store_longitude: -78.640463,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8151582,
        store_longitude: -78.640518,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.817993,
        store_longitude: -78.6398,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.808654,
        store_longitude: -78.6419792,
    },
    {
        store_latitdue: -0.809438,
        store_longitude: -78.6419,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.806138,
        store_longitude: -78.638074,
    },
    {
        store_latitdue: -0.805135,
        store_longitude: -78.6372,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.807352,
        store_longitude: -78.63966,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8064552,
        store_longitude: -78.6399165,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8031,
        store_longitude: -78.6463627,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.803648,
        store_longitude: -78.6461,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.92066,
        store_longitude: -78.614149,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9183782,
        store_longitude: -78.6129647,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9238458,
        store_longitude: -78.6132292,
    },
    {
        store_latitdue: -0.923704,
        store_longitude: -78.612,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.918867,
        store_longitude: -78.610265,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9109522,
        store_longitude: -78.6044285,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.899447,
        store_longitude: -78.591158,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8976424,
        store_longitude: -78.5889238,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9094416,
        store_longitude: -78.5990985,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.909481,
        store_longitude: -78.59913,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9142649,
        store_longitude: -78.601013,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8986115,
        store_longitude: -78.6105293,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8952022,
        store_longitude: -78.6122788,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8953297,
        store_longitude: -78.6122479,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8908491,
        store_longitude: -78.6069053,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.890064,
        store_longitude: -78.606583,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.889955,
        store_longitude: -78.608295,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.895272,
        store_longitude: -78.611669,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.7043327,
        store_longitude: -78.891269,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.705431,
        store_longitude: -78.891878,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7081299,
        store_longitude: -78.8902745,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7072852,
        store_longitude: -78.8875631,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7050732,
        store_longitude: -78.8891759,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.705283,
        store_longitude: -78.8884,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7042641,
        store_longitude: -78.8881288,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.703372,
        store_longitude: -78.889352,
    },
    {
        store_latitdue: -0.7032189,
        store_longitude: -78.8893272,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7056924,
        store_longitude: -78.8899896,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7053586,
        store_longitude: -78.8900982,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.704812,
        store_longitude: -78.8904,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7045984,
        store_longitude: -78.8904501,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7044006,
        store_longitude: -78.8904161,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.704912,
        store_longitude: -78.889126,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7037724,
        store_longitude: -78.8892553,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -1.02671611,
        store_longitude: -78.6096775,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0181061,
        store_longitude: -78.5804898,
    },
    {
        store_latitdue: -1.03862,
        store_longitude: -78.5905,
    },
    {
        store_latitdue: -1.03353,
        store_longitude: -78.5927,
    },
    {
        store_latitdue: -1.03336,
        store_longitude: -78.5952,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0270557,
        store_longitude: -78.594306,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.024374,
        store_longitude: -78.591512,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.01738,
        store_longitude: -78.5914,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.950015,
        store_longitude: -78.6172,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93795,
        store_longitude: -78.620495,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.936667,
        store_longitude: -78.622608,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.901822,
        store_longitude: -78.611689,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8924918,
        store_longitude: -78.6113004,
    },
    {
        store_latitdue: -0.893679,
        store_longitude: -78.6104,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.922175,
        store_longitude: -78.6157,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9168748,
        store_longitude: -78.597187,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.809405,
        store_longitude: -78.6417,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.933418,
        store_longitude: -78.622575,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0312883,
        store_longitude: -78.58868,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.038284,
        store_longitude: -78.585981,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.01798,
        store_longitude: -78.5832,
    },
    {
        store_latitdue: -1.0318498,
        store_longitude: -78.5897615,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.93505,
        store_longitude: -78.622783,
    },
    {
        store_latitdue: -0.936714,
        store_longitude: -78.6203961,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93998551,
        store_longitude: -78.6197738,
    },
    {
        store_latitdue: -0.7036842,
        store_longitude: -78.8891569,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.704025,
        store_longitude: -78.8892,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7038829,
        store_longitude: -78.8908311,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.94077166666667,
        store_longitude: -78.612138333333,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.02383208,
        store_longitude: -78.61557,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.818237,
        store_longitude: -78.636366,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.825354,
        store_longitude: -78.641157,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.814005,
        store_longitude: -78.640796,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.810959,
        store_longitude: -78.6414,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8077992,
        store_longitude: -78.6487298,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.81153,
        store_longitude: -78.6425,
    },
    {
        store_latitdue: -0.92835,
        store_longitude: -78.61277,
    },
    {
        store_latitdue: -0.7045535,
        store_longitude: -78.8886277,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.9016447,
        store_longitude: -78.5922268,
    },
    {
        store_latitdue: -0.80959248,
        store_longitude: -78.6436996,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.7028,
        store_longitude: -78.8894,
    },
    {
        store_latitdue: -0.9344848,
        store_longitude: -78.6216853,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.01185,
        store_longitude: -78.603,
    },
    {
        store_latitdue: -1.0307618,
        store_longitude: -78.6032909,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9331608,
        store_longitude: -78.6234107,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9162891,
        store_longitude: -78.6075409,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.950732,
        store_longitude: -78.617,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.033602,
        store_longitude: -78.5939178,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.934511,
        store_longitude: -78.623669,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.818169,
        store_longitude: -78.636069,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.8187649,
        store_longitude: -78.6455653,
        neighborhood: 'GUAYTACAMA',
    },
    {
        store_latitdue: -0.7043109,
        store_longitude: -78.8912014,
        neighborhood: 'SIGCHOS',
    },
    {
        store_latitdue: -0.7124858,
        store_longitude: -78.597018,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.705557,
        store_longitude: -78.8904,
    },

    {
        store_latitdue: -1.0356587869,
        store_longitude: -78.5849863798,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.96668,
        store_longitude: -78.613448,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9667262,
        store_longitude: -78.6139226,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9662698,
        store_longitude: -78.6121283,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9661,
        store_longitude: -78.6117,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9510368,
        store_longitude: -80.7214886,
    },
    {
        store_latitdue: -0.9776488,
        store_longitude: -78.6042994,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9749149,
        store_longitude: -78.606961,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9709697,
        store_longitude: -78.6066723,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.976111,
        store_longitude: -78.5972,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.961157,
        store_longitude: -78.5841883,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9520644,
        store_longitude: -78.5913927,
    },
    {
        store_latitdue: -0.9578269,
        store_longitude: -78.5824118,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9834361,
        store_longitude: -78.5869298,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.986318,
        store_longitude: -78.5820743,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9994058,
        store_longitude: -78.568327,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.998158,
        store_longitude: -78.568461,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.986211,
        store_longitude: -78.586979,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9995517,
        store_longitude: -78.5865415,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.961451,
        store_longitude: -78.611369,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.958487,
        store_longitude: -78.6066,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9587511,
        store_longitude: -78.6116591,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9528075,
        store_longitude: -78.61051,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9387294,
        store_longitude: -78.6138019,
    },
    {
        store_latitdue: -0.954294,
        store_longitude: -78.603392,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9515167,
        store_longitude: -78.6037717,
    },
    {
        store_latitdue: -0.953604,
        store_longitude: -78.6025,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9585778,
        store_longitude: -78.601619,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9565233,
        store_longitude: -78.694714,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9557564,
        store_longitude: -78.6953693,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.955833,
        store_longitude: -78.6955,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.957101,
        store_longitude: -78.696996,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9558667,
        store_longitude: -78.6974681,
    },
    {
        store_latitdue: -0.9585563,
        store_longitude: -78.6992613,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.957954,
        store_longitude: -78.6977,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.959143,
        store_longitude: -78.6951477,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.959937,
        store_longitude: -78.698106,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.96009,
        store_longitude: -78.698005,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9606939,
        store_longitude: -78.6994025,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9634086,
        store_longitude: -78.6943597,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9608256,
        store_longitude: -78.6953097,
    },
    {
        store_latitdue: -0.96018,
        store_longitude: -78.69514,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.958359,
        store_longitude: -78.6979,
    },
    {
        store_latitdue: -0.9161949,
        store_longitude: -78.6363109,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9143734,
        store_longitude: -78.6411186,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9112176,
        store_longitude: -78.6508087,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9095344,
        store_longitude: -78.651704,
    },
    {
        store_latitdue: -0.9050465,
        store_longitude: -78.6520049,
    },
    {
        store_latitdue: -0.8874584,
        store_longitude: -78.6559551,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.8936446,
        store_longitude: -78.6490086,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8937504,
        store_longitude: -78.6486493,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9049876,
        store_longitude: -78.6416718,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9071519,
        store_longitude: -78.6393475,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9083804,
        store_longitude: -78.6342027,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9011442,
        store_longitude: -78.6373027,
    },
    {
        store_latitdue: -0.897524,
        store_longitude: -78.6386,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.876734,
        store_longitude: -78.64243,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8834383,
        store_longitude: -78.6380675,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8848164,
        store_longitude: -78.6400518,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9050249,
        store_longitude: -78.6244298,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.910593,
        store_longitude: -78.6241,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9116621,
        store_longitude: -78.6248596,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.912281,
        store_longitude: -78.62343,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.913493,
        store_longitude: -78.6219,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.914182,
        store_longitude: -78.62158,
    },
    {
        store_latitdue: -0.914182,
        store_longitude: -78.621698,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9155342,
        store_longitude: -78.6195773,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.918205333,
        store_longitude: -78.62146997,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9181894,
        store_longitude: -78.6251512,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.917581,
        store_longitude: -78.625636,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9184661,
        store_longitude: -78.6203934,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.91902,
        store_longitude: -78.6204233,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.921852,
        store_longitude: -78.621413,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.921751,
        store_longitude: -78.621454,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.923808,
        store_longitude: -78.620635,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92424166666667,
        store_longitude: -78.622406666667,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.923597,
        store_longitude: -78.6225,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.923326,
        store_longitude: -78.622659,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9251292,
        store_longitude: -78.6221671,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.967093,
        store_longitude: -78.6145,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.966412,
        store_longitude: -78.612668,
    },
    {
        store_latitdue: -0.9861666,
        store_longitude: -78.5883491,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9822488,
        store_longitude: -78.5825409,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.95842023,
        store_longitude: -78.6015343,
    },
    {
        store_latitdue: -0.9642367,
        store_longitude: -78.6001518,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.963724,
        store_longitude: -78.607431,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9556734,
        store_longitude: -78.6053246,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9593116,
        store_longitude: -78.6034205,
    },
    {
        store_latitdue: -0.923105,
        store_longitude: -78.622715,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.902922,
        store_longitude: -78.6369022,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.902602168,
        store_longitude: -78.63698007,
    },
    {
        store_latitdue: -0.90065333333333,
        store_longitude: -78.637383333333,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.899637,
        store_longitude: -78.637776,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8960298,
        store_longitude: -78.6391284,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8963767,
        store_longitude: -78.6390283,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8927907,
        store_longitude: -78.6407392,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.892434,
        store_longitude: -78.6407347,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.884665,
        store_longitude: -78.644439,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.883209,
        store_longitude: -78.644932,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.881955,
        store_longitude: -78.6453,
    },
    {
        store_latitdue: -0.912378,
        store_longitude: -78.6249,
    },
    {
        store_latitdue: -0.9182303,
        store_longitude: -78.6254752,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.929716,
        store_longitude: -78.615078,
    },
    {
        store_latitdue: -0.9181906,
        store_longitude: -78.62121,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9814952,
        store_longitude: -78.6701096,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.972035,
        store_longitude: -78.67442,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.988298,
        store_longitude: -78.5964011,
        neighborhood: 'BELISARIO QUEVEDO',
    },
    {
        store_latitdue: -0.9513547,
        store_longitude: -78.6125197,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9527266,
        store_longitude: -78.61157,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9553398,
        store_longitude: -78.6055695,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8795361,
        store_longitude: -78.6421373,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.91914,
        store_longitude: -78.620341,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.962641,
        store_longitude: -78.6072,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.951593,
        store_longitude: -78.6097,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.881137,
        store_longitude: -78.6436,
    },
    {
        store_latitdue: -0.918714,
        store_longitude: -78.622101,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.965269,
        store_longitude: -78.612078,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.1011989,
        store_longitude: -78.5929988,
    },
    {
        store_latitdue: -0.953385,
        store_longitude: -78.6052,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.955708,
        store_longitude: -78.696282,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.95706,
        store_longitude: -78.6969,
    },
    {
        store_latitdue: -0.962586,
        store_longitude: -78.698577,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9156534,
        store_longitude: -78.637261,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9115199,
        store_longitude: -78.6502503,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.905644,
        store_longitude: -78.6410635,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9008154,
        store_longitude: -78.6420418,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.88031,
        store_longitude: -78.641457,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.952793,
        store_longitude: -78.6113,
    },
    {
        store_latitdue: -0.9660899,
        store_longitude: -78.6935464,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9667618,
        store_longitude: -78.6929891,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.964992,
        store_longitude: -78.6982675,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9569349,
        store_longitude: -78.6976632,
    },
    {
        store_latitdue: -1.073198,
        store_longitude: -78.703145,
        neighborhood: 'CUSUBAMBA',
    },
    {
        store_latitdue: -0.9083392,
        store_longitude: -78.6340805,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.90080974,
        store_longitude: -78.6421414,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.88477666666667,
        store_longitude: -78.637703333333,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9584615,
        store_longitude: -78.6976683,
    },
    {
        store_latitdue: -0.8810134,
        store_longitude: -78.6417264,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.887316,
        store_longitude: -78.6421145,
    },
    {
        store_latitdue: -0.960212,
        store_longitude: -78.613747,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.890416,
        store_longitude: -78.6528661,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.95629,
        store_longitude: -78.61109,
    },
    {
        store_latitdue: -0.894717,
        store_longitude: -78.6394,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9612162,
        store_longitude: -78.6080704,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9223183,
        store_longitude: -78.6219498,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.920834,
        store_longitude: -78.621816,
    },
    {
        store_latitdue: -0.89572166666667,
        store_longitude: -78.647958333333,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.045116,
        store_longitude: -78.5909538,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.075495,
        store_longitude: -78.604748,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.0804634,
        store_longitude: -78.6084931,
        neighborhood: 'MULALILLO',
    },
    {
        store_latitdue: -1.092909,
        store_longitude: -78.62859,
        neighborhood: 'MULALILLO',
    },
    {
        store_latitdue: -1.0934362,
        store_longitude: -78.6285065,
        neighborhood: 'MULALILLO',
    },
    {
        store_latitdue: -1.098355,
        store_longitude: -78.6040976,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -1.0968566,
        store_longitude: -78.5985413,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -1.0977712,
        store_longitude: -78.5957935,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -1.1080812,
        store_longitude: -78.5916713,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -1.1126006,
        store_longitude: -78.5936322,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -1.08874,
        store_longitude: -78.5813,
    },
    {
        store_latitdue: -1.09189,
        store_longitude: -78.5798,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.084315,
        store_longitude: -78.582873,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.9470825,
        store_longitude: -78.6110132,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9387294,
        store_longitude: -78.6138019,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93994,
        store_longitude: -78.6118167,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9400073,
        store_longitude: -78.6119442,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9369142,
        store_longitude: -78.612476,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9396602,
        store_longitude: -78.6106712,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9387828,
        store_longitude: -78.6099186,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93858,
        store_longitude: -78.61013,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9389168,
        store_longitude: -78.6075591,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93846,
        store_longitude: -78.60674,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9380575,
        store_longitude: -78.6061627,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9372766,
        store_longitude: -78.6059284,
    },
    {
        store_latitdue: -0.9372896,
        store_longitude: -78.6059201,
    },
    {
        store_latitdue: -0.9393687,
        store_longitude: -78.6048711,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9431167,
        store_longitude: -78.6065133,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9449362,
        store_longitude: -78.6062777,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9451812,
        store_longitude: -78.6016964,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9368253,
        store_longitude: -78.6032297,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9362736,
        store_longitude: -78.6045296,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.937432,
        store_longitude: -78.608476,
    },
    {
        store_latitdue: -0.9412261,
        store_longitude: -78.6119766,
    },
    {
        store_latitdue: -0.9279105,
        store_longitude: -78.6558599,
    },
    {
        store_latitdue: -0.892876,
        store_longitude: -78.6707,
    },
    {
        store_latitdue: -0.8845218,
        store_longitude: -78.6748124,
    },
    {
        store_latitdue: -0.884912,
        store_longitude: -78.6752,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.931026,
        store_longitude: -78.675291,
        neighborhood: 'ONCE DE NOVIEMBRE',
    },
    {
        store_latitdue: -0.9188808,
        store_longitude: -78.6969936,
        neighborhood: 'LA VICTORIA',
    },
    {
        store_latitdue: -0.945896,
        store_longitude: -78.6971,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9462891,
        store_longitude: -78.6969981,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.943766,
        store_longitude: -78.6841932,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.943911,
        store_longitude: -78.6835,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9454858,
        store_longitude: -78.683198,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.948076,
        store_longitude: -78.6872,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.952467,
        store_longitude: -78.683816,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9490526,
        store_longitude: -78.6878803,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.900571,
        store_longitude: -78.620996,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8927651,
        store_longitude: -78.6174933,
    },
    {
        store_latitdue: -0.898833,
        store_longitude: -78.618177,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8916839,
        store_longitude: -78.6172595,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.889317,
        store_longitude: -78.6223851,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.888788,
        store_longitude: -78.616521,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8863669,
        store_longitude: -78.6160107,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8849151,
        store_longitude: -78.6154408,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8838409,
        store_longitude: -78.6150718,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8829111,
        store_longitude: -78.6149399,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.882106,
        store_longitude: -78.614588,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8811691,
        store_longitude: -78.6144627,
    },
    {
        store_latitdue: -0.8720372,
        store_longitude: -78.6113877,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.867913,
        store_longitude: -78.609608,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.865633,
        store_longitude: -78.608995,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.864886,
        store_longitude: -78.595637,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.865908,
        store_longitude: -78.595518,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.863625,
        store_longitude: -78.5951,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.863899,
        store_longitude: -78.596462,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.758774,
        store_longitude: -78.610121,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.755373,
        store_longitude: -78.610016,
    },
    {
        store_latitdue: -0.754193024,
        store_longitude: -78.61035103,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.754061,
        store_longitude: -78.6103,
    },
    {
        store_latitdue: -0.7536501,
        store_longitude: -78.6099491,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7538667,
        store_longitude: -78.6099117,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.754423,
        store_longitude: -78.609994,
    },
    {
        store_latitdue: -0.93048,
        store_longitude: -78.6251,
    },
    {
        store_latitdue: -0.74471,
        store_longitude: -78.610097,
    },
    {
        store_latitdue: -0.749317,
        store_longitude: -78.6161,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7494151,
        store_longitude: -78.6160667,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7570422,
        store_longitude: -78.6162167,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.756871,
        store_longitude: -78.6157,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9259487,
        store_longitude: -78.621988,
    },
    {
        store_latitdue: -1.076784,
        store_longitude: -78.606429,
    },
    {
        store_latitdue: -1.077825,
        store_longitude: -78.6080467,
    },
    {
        store_latitdue: -1.1074246,
        store_longitude: -78.5915692,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -0.940604,
        store_longitude: -78.612161,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.938805,
        store_longitude: -78.6074,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9381871,
        store_longitude: -78.6106783,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9493265,
        store_longitude: -78.6123782,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.89954,
        store_longitude: -78.6209,
    },
    {
        store_latitdue: -0.8978626,
        store_longitude: -78.618026,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9311105,
        store_longitude: -78.6753926,
        neighborhood: 'ONCE DE NOVIEMBRE',
    },
    {
        store_latitdue: -0.8850237,
        store_longitude: -78.6754254,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -1.1024331,
        store_longitude: -78.6078917,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -0.938331,
        store_longitude: -78.6133179,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.939605,
        store_longitude: -78.6098906,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8898486,
        store_longitude: -78.618166,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.886984,
        store_longitude: -78.61609,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.08112,
        store_longitude: -78.6242,
    },
    {
        store_latitdue: -0.890614,
        store_longitude: -78.6169,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.7537627,
        store_longitude: -78.6100293,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7480438,
        store_longitude: -78.609621,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.8844176,
        store_longitude: -78.6754799,
        neighborhood: 'POALÓ',
    },
    {
        store_latitdue: -0.9385643,
        store_longitude: -78.6070022,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9370089,
        store_longitude: -78.6049227,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9365809,
        store_longitude: -78.6066595,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.938358,
        store_longitude: -78.6064,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.872265436,
        store_longitude: -78.63565936,
    },
    {
        store_latitdue: -0.947653,
        store_longitude: -78.6971,
    },
    {
        store_latitdue: -0.8940236,
        store_longitude: -78.6177587,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8657993,
        store_longitude: -78.5955331,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.8927319,
        store_longitude: -78.6215952,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.7289279,
        store_longitude: -78.6086047,
    },
    {
        store_latitdue: -0.7598418,
        store_longitude: -78.6179089,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9424751,
        store_longitude: -78.6036819,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.938217,
        store_longitude: -78.6126,
    },
    {
        store_latitdue: -0.86361,
        store_longitude: -78.60823,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.889714,
        store_longitude: -78.6169536,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0930319,
        store_longitude: -78.6282509,
        neighborhood: 'MULALILLO',
    },
    {
        store_latitdue: -0.881095,
        store_longitude: -78.6142,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.1059848,
        store_longitude: -78.5920244,
        neighborhood: 'ANTONIO JOSÉ HOLGUÍN',
    },
    {
        store_latitdue: -0.9490949,
        store_longitude: -78.6887394,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.886149,
        store_longitude: -78.615903,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.865333,
        store_longitude: -78.6090084,
        neighborhood: 'ALÁQUEZ',
    },
    {
        store_latitdue: -0.7563472,
        store_longitude: -78.6110252,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.8818878,
        store_longitude: -78.614648,
    },
    {
        store_latitdue: -0.75280904,
        store_longitude: -78.6151962,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.757474,
        store_longitude: -78.618,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.9557814,
        store_longitude: -78.6959415,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.955283,
        store_longitude: -78.696658,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.744361,
        store_longitude: -78.6111,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.934445,
        store_longitude: -78.613345,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.918997,
        store_longitude: -78.621995,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930169,
        store_longitude: -78.616652,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930292,
        store_longitude: -78.618411,
    },
    {
        store_latitdue: -0.9324454,
        store_longitude: -78.6173416,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9203635,
        store_longitude: -78.6329343,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.037365,
        store_longitude: -78.588879,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0428871,
        store_longitude: -78.5906824,
    },
    {
        store_latitdue: -1.0655415,
        store_longitude: -78.5992278,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.06683564,
        store_longitude: -78.5980148,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.0670955,
        store_longitude: -78.59794,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.0684398,
        store_longitude: -78.5965067,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.0489706,
        store_longitude: -78.594349,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0471278,
        store_longitude: -78.592511,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9364926,
        store_longitude: -78.6147606,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9307421,
        store_longitude: -78.6204319,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9098795,
        store_longitude: -78.6339146,
    },
    {
        store_latitdue: -0.920421,
        store_longitude: -78.6217,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93183973148506,
        store_longitude: -78.618456733661,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.067179,
        store_longitude: -78.597565,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -0.9327209,
        store_longitude: -78.6254587,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.042353,
        store_longitude: -78.5897265,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04463,
        store_longitude: -78.5904,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0670974,
        store_longitude: -78.5977502,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.0670239,
        store_longitude: -78.5977314,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.051113,
        store_longitude: -78.597075,
    },
    {
        store_latitdue: -1.067,
        store_longitude: -78.5977,
    },
    {
        store_latitdue: -1.04752,
        store_longitude: -78.593148,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0475846,
        store_longitude: -78.592899,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9304341,
        store_longitude: -78.6189093,
    },
    {
        store_latitdue: -0.9208087,
        store_longitude: -78.6320065,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0493441,
        store_longitude: -78.5948542,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: 0.93109592,
        store_longitude: -78.61950784,
        neighborhood: 'URBINA',
    },
    {
        store_latitdue: -0.928126,
        store_longitude: -78.6295284,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.045174,
        store_longitude: -78.590308,
    },
    {
        store_latitdue: -1.0408676,
        store_longitude: -78.5899861,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0386489,
        store_longitude: -78.5905614,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.8489813,
        store_longitude: -78.6596903,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.948528,
        store_longitude: -78.6103,
    },
    {
        store_latitdue: -0.946856,
        store_longitude: -78.609451,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.835466,
        store_longitude: -78.6669,
    },
    {
        store_latitdue: -0.9293572,
        store_longitude: -78.6300869,
    },
    {
        store_latitdue: -0.92801,
        store_longitude: -78.633227,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9345673,
        store_longitude: -78.6175728,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.0655603,
        store_longitude: -78.5987034,
        neighborhood: 'PANSALEO',
    },
    {
        store_latitdue: -1.046885,
        store_longitude: -78.593318,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0447766,
        store_longitude: -78.5905248,
    },
    {
        store_latitdue: -1.0434892,
        store_longitude: -78.5929821,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.04377,
        store_longitude: -78.5959,
    },
    {
        store_latitdue: -1.0451391,
        store_longitude: -78.6027026,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0478672,
        store_longitude: -78.5931643,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9560407,
        store_longitude: -78.6110564,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.93753,
        store_longitude: -78.61278,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9261164,
        store_longitude: -78.6303107,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9347687,
        store_longitude: -78.6133097,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.8436655,
        store_longitude: -78.6633794,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.841097,
        store_longitude: -78.665966,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.84023,
        store_longitude: -78.6658,
    },
    {
        store_latitdue: -0.8401276,
        store_longitude: -78.6663452,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8399428,
        store_longitude: -78.665679,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8392156,
        store_longitude: -78.6658294,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.839581,
        store_longitude: -78.668351,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8386827,
        store_longitude: -78.6687857,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8385828,
        store_longitude: -78.6685998,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.83741,
        store_longitude: -78.667,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.8354901,
        store_longitude: -78.6671651,
    },
    {
        store_latitdue: -0.83495932,
        store_longitude: -78.6673507,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.9315467,
        store_longitude: -78.60675,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.930675,
        store_longitude: -78.607625,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9321817,
        store_longitude: -78.6021727,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9324901,
        store_longitude: -78.6024139,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9316408,
        store_longitude: -78.6024524,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92863,
        store_longitude: -78.600888,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.928681,
        store_longitude: -78.6013,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9285846,
        store_longitude: -78.601596,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.928764,
        store_longitude: -78.601949,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9288091,
        store_longitude: -78.6022776,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.92671064,
        store_longitude: -78.6120271,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.75621,
        store_longitude: -78.611165,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.757985,
        store_longitude: -78.6101,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.75615,
        store_longitude: -78.61009,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -0.7065302,
        store_longitude: -78.5949208,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.74353302,
        store_longitude: -78.61279985,
        neighborhood: 'TANICUCHÍ',
    },
    {
        store_latitdue: -1.0260147,
        store_longitude: -78.5669882,
        neighborhood: 'MULLIQUINDIL',
    },
    {
        store_latitdue: -1.0468318,
        store_longitude: -78.593817,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0431348,
        store_longitude: -78.5892328,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.044454,
        store_longitude: -78.58643,
    },
    {
        store_latitdue: -1.04117,
        store_longitude: -78.5852,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -1.0411093,
        store_longitude: -78.58399,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.94629,
        store_longitude: -78.62018,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9384,
        store_longitude: -78.61333,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9260453,
        store_longitude: -78.6219696,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -0.9553396,
        store_longitude: -78.6960324,
        neighborhood: 'PUJILÍ',
    },
    {
        store_latitdue: -0.9290094,
        store_longitude: -78.6292414,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.043325,
        store_longitude: -78.590042,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.9316,
        store_longitude: -78.60859,
    },
    {
        store_latitdue: -1.0404512,
        store_longitude: -78.5821269,
        neighborhood: 'SAN MIGUEL',
    },
    {
        store_latitdue: -0.92992,
        store_longitude: -78.6243,
    },

    {
        store_latitdue: -0.8392836,
        store_longitude: -78.6668264,
        neighborhood: 'SAQUISILÍ',
    },
    {
        store_latitdue: -0.7099799,
        store_longitude: -78.6091931,
        neighborhood: 'SAN JUAN DE PASTOCALLE',
    },
    {
        store_latitdue: -0.930824,
        store_longitude: -78.61009,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: -1.282283,
        store_longitude: -78.6410582,
        neighborhood: 'SANTA ROSA',
    },
    {
        store_latitdue: -1.23825,
        store_longitude: -78.6223,
        neighborhood: 'AMBATO',
    },
    {
        store_latitdue: 0.97271,
        store_longitude: -79.6545,
    },
    {
        store_latitdue: 0.972185,
        store_longitude: -79.6547,
    },
    {
        store_latitdue: 0.963064,
        store_longitude: -79.654,
    },
    {
        store_latitdue: 0.9724801,
        store_longitude: -79.654909,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8690351,
        store_longitude: -79.8496672,
    },
    {
        store_latitdue: 0.8802059,
        store_longitude: -79.8154158,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.8844146,
        store_longitude: -79.8087564,
        neighborhood: 'TONSUPA',
    },

    {
        store_latitdue: 0.8843241,
        store_longitude: -79.8088699,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.97807,
        store_longitude: -79.65968,
    },
    {
        store_latitdue: 0.9778,
        store_longitude: -79.65696,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97942,
        store_longitude: -79.65734,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.978384,
        store_longitude: -79.654838,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.953363,
        store_longitude: -79.6556008,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9753816,
        store_longitude: -79.65838,
    },
    {
        store_latitdue: 0.9743446,
        store_longitude: -79.6593575,
    },
    {
        store_latitdue: 0.971604,
        store_longitude: -79.66375,
    },

    {
        store_latitdue: 0.869067,
        store_longitude: -79.8495,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8689674,
        store_longitude: -79.8497008,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 1.2855287,
        store_longitude: -78.8344307,
    },
    {
        store_latitdue: 1.281999,
        store_longitude: -78.8260237,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.28152,
        store_longitude: -78.8309,
    },
    {
        store_latitdue: 1.2855454,
        store_longitude: -78.8345427,
    },
    {
        store_latitdue: 1.2873911,
        store_longitude: -78.8335545,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2820684,
        store_longitude: -78.8228211,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.287756,
        store_longitude: -78.836055,
    },
    {
        store_latitdue: 1.2826314,
        store_longitude: -78.8311237,
    },
    {
        store_latitdue: 1.2844513,
        store_longitude: -78.8332882,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2854343,
        store_longitude: -78.8327665,
    },
    {
        store_latitdue: 1.281911,
        store_longitude: -78.832,
    },
    {
        store_latitdue: 1.2844125,
        store_longitude: -78.8324847,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.98244899,
        store_longitude: -79.65671003,
    },

    {
        store_latitdue: 0.98055,
        store_longitude: -79.65812,
    },
    {
        store_latitdue: 0.979324,
        store_longitude: -79.6552,
    },
    {
        store_latitdue: 0.9525518,
        store_longitude: -79.6508431,
    },
    {
        store_latitdue: 0.97138,
        store_longitude: -79.66186,
    },
    {
        store_latitdue: 0.9691031,
        store_longitude: -79.6643971,
    },
    {
        store_latitdue: 0.97205,
        store_longitude: -79.65999,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97135,
        store_longitude: -79.66239,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97135,
        store_longitude: -79.66168,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97062,
        store_longitude: -79.66386,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97158,
        store_longitude: -79.65478,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9714028,
        store_longitude: -79.6531344,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.968588,
        store_longitude: -79.6602,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.96679721,
        store_longitude: -79.6547611,
    },
    {
        store_latitdue: 0.962875,
        store_longitude: -79.6511,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.957126,
        store_longitude: -79.6556,
    },
    {
        store_latitdue: 0.96181,
        store_longitude: -79.6588,
    },
    {
        store_latitdue: 0.96596,
        store_longitude: -79.66096,
    },
    {
        store_latitdue: 0.96089,
        store_longitude: -79.65743,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9603415,
        store_longitude: -79.6532595,
    },

    {
        store_latitdue: 0.960567,
        store_longitude: -79.6616,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.95975,
        store_longitude: -79.65448,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 1.28614,
        store_longitude: -78.835,
    },
    {
        store_latitdue: 1.28569,
        store_longitude: -78.8348,
    },
    {
        store_latitdue: 1.2822304,
        store_longitude: -78.8279306,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2830577,
        store_longitude: -78.8272198,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2806744,
        store_longitude: -78.8254733,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2919592,
        store_longitude: -78.8377834,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.967619,
        store_longitude: -79.6612,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9271118,
        store_longitude: -79.6653757,
    },
    {
        store_latitdue: 0.963861,
        store_longitude: -79.6567,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.920336,
        store_longitude: -79.6822,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2907433,
        store_longitude: -78.8403095,
    },
    {
        store_latitdue: 1.2808535,
        store_longitude: -78.8275562,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9560779,
        store_longitude: -79.6521122,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.965874,
        store_longitude: -79.6611156,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.96597034,
        store_longitude: -79.6543273,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.956509,
        store_longitude: -79.6519,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9785819,
        store_longitude: -79.6556262,
    },
    {
        store_latitdue: 0.616,
        store_longitude: -80.0135,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.6371731,
        store_longitude: -80.0023017,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.9636002,
        store_longitude: -79.6506797,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9642347,
        store_longitude: -79.655835,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2845692,
        store_longitude: -78.8341655,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2910661,
        store_longitude: -78.836149,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: -0.300409,
        store_longitude: -76.8565,
    },
    {
        store_latitdue: 0.8809846,
        store_longitude: -79.8142249,
        neighborhood: 'TONSUPA',
    },

    {
        store_latitdue: 0.86895,
        store_longitude: -79.84808,
    },

    {
        store_latitdue: 0.87009,
        store_longitude: -79.84726,
    },
    {
        store_latitdue: 0.97144,
        store_longitude: -79.65399,
    },
    {
        store_latitdue: 0.9706239,
        store_longitude: -79.654095,
    },
    {
        store_latitdue: 0.97268,
        store_longitude: -79.65505,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.927645,
        store_longitude: -79.670553,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0870927,
        store_longitude: -78.9883681,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.086745,
        store_longitude: -78.9901311,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.252177,
        store_longitude: -78.9877,
    },
    {
        store_latitdue: 1.0867007,
        store_longitude: -78.9894707,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.087171,
        store_longitude: -78.988762,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.95434,
        store_longitude: -79.65391,
    },
    {
        store_latitdue: 0.9527108,
        store_longitude: -79.6562342,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.952694,
        store_longitude: -79.653889,
    },
    {
        store_latitdue: 0.95203,
        store_longitude: -79.65633,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9549,
        store_longitude: -79.6557,
    },
    {
        store_latitdue: 0.95266,
        store_longitude: -79.65206,
    },
    {
        store_latitdue: 0.931075,
        store_longitude: -79.6668,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9289955,
        store_longitude: -79.6688264,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9291,
        store_longitude: -79.6681,
    },
    {
        store_latitdue: 0.934318,
        store_longitude: -79.6588,
    },
    {
        store_latitdue: 0.93438,
        store_longitude: -79.6591,
    },
    {
        store_latitdue: 0.93242202,
        store_longitude: -79.66159802,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9322756,
        store_longitude: -79.6614854,
    },
    {
        store_latitdue: 0.9323939,
        store_longitude: -79.6663051,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9304303,
        store_longitude: -79.6672798,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.93161,
        store_longitude: -79.67053,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9318951,
        store_longitude: -79.6713916,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.928503,
        store_longitude: -79.6696,
    },
    {
        store_latitdue: 0.9300614,
        store_longitude: -79.6719178,
    },
    {
        store_latitdue: 0.933437,
        store_longitude: -79.6727,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9336062,
        store_longitude: -79.6708971,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.930186,
        store_longitude: -79.6738,
    },
    {
        store_latitdue: 0.933665,
        store_longitude: -79.6745,
    },
    {
        store_latitdue: 0.93455,
        store_longitude: -79.6742,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9349284,
        store_longitude: -79.6732317,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9332583,
        store_longitude: -79.6739383,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9370238,
        store_longitude: -79.6761425,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9263667,
        store_longitude: -79.6760642,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0877016,
        store_longitude: -78.9886364,
    },
    {
        store_latitdue: 1.09124,
        store_longitude: -78.9871,
    },
    {
        store_latitdue: 1.0875069,
        store_longitude: -78.9907965,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.08829,
        store_longitude: -78.9869,
    },
    {
        store_latitdue: 1.0879818,
        store_longitude: -78.9934083,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.0874612,
        store_longitude: -78.9908527,
    },
    {
        store_latitdue: 1.0877352,
        store_longitude: -78.9906391,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.0868268,
        store_longitude: -78.9888436,
    },
    {
        store_latitdue: 1.089698,
        store_longitude: -78.9863809,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.0866633,
        store_longitude: -78.9901399,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.0893124,
        store_longitude: -78.9869511,
    },
    {
        store_latitdue: 1.0896,
        store_longitude: -78.9868,
    },
    {
        store_latitdue: 1.09796,
        store_longitude: -79.1628,
    },
    {
        store_latitdue: 1.0960611,
        store_longitude: -79.161964,
    },
    {
        store_latitdue: 1.0966927,
        store_longitude: -79.1609092,
        neighborhood: 'LA TOLA',
    },
    {
        store_latitdue: 1.0445358,
        store_longitude: -79.2799086,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 1.0432377,
        store_longitude: -79.2785046,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 0.9262912,
        store_longitude: -79.6655574,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9261895,
        store_longitude: -79.6618495,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.951127,
        store_longitude: -79.653555,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9267962,
        store_longitude: -79.6599027,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.930562997,
        store_longitude: -79.65958997,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.929194,
        store_longitude: -79.66,
    },
    {
        store_latitdue: 0.9232835,
        store_longitude: -79.666159,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9226528,
        store_longitude: -79.6596457,
    },
    {
        store_latitdue: 0.9235339,
        store_longitude: -79.6599262,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.964836,
        store_longitude: -79.6534359,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.96243,
        store_longitude: -79.6296,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.906228,
        store_longitude: -79.6906388,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.7395709,
        store_longitude: -79.5823059,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.6551904,
        store_longitude: -79.5420036,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.8173208,
        store_longitude: -79.6106585,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.6587337,
        store_longitude: -79.5414069,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.6565354,
        store_longitude: -79.5431003,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.6595875,
        store_longitude: -79.5406574,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.6584999,
        store_longitude: -79.5412658,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.658947,
        store_longitude: -79.5405,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.655275,
        store_longitude: -79.5433,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.918122996,
        store_longitude: -79.67953898,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.919200992,
        store_longitude: -79.68057398,
    },
    {
        store_latitdue: 0.916507971,
        store_longitude: -79.679126,
    },
    {
        store_latitdue: 0.9175661,
        store_longitude: -79.678775,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9184131,
        store_longitude: -79.6811395,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.917569036,
        store_longitude: -79.67945399,
    },
    {
        store_latitdue: 0.91957,
        store_longitude: -79.67557,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.92042,
        store_longitude: -79.68256,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9213017,
        store_longitude: -79.6831908,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9211887,
        store_longitude: -79.6833886,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.920197014,
        store_longitude: -79.68230803,
    },
    {
        store_latitdue: 0.9249375,
        store_longitude: -79.6725129,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9147292,
        store_longitude: -79.6864419,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9173225,
        store_longitude: -79.6829829,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.917866,
        store_longitude: -79.6859676,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.91409928,
        store_longitude: -79.69082952,
    },
    {
        store_latitdue: 0.9139455,
        store_longitude: -79.6857198,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.89709,
        store_longitude: -79.71123,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.89709,
        store_longitude: -79.71134,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.89132,
        store_longitude: -79.70867,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.89095,
        store_longitude: -79.71122,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8862331,
        store_longitude: -79.7012442,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.8212735,
        store_longitude: -79.7032928,
    },
    {
        store_latitdue: 0.962624,
        store_longitude: -79.629629,
    },
    {
        store_latitdue: 0.96199,
        store_longitude: -79.6302,
    },
    {
        store_latitdue: 0.9622284,
        store_longitude: -79.6302023,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.96161,
        store_longitude: -79.6303,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.9617056,
        store_longitude: -79.6294175,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.961706,
        store_longitude: -79.6288179,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.9670734,
        store_longitude: -79.6220115,
    },
    {
        store_latitdue: 1.0642709,
        store_longitude: -79.3682114,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: 1.065103,
        store_longitude: -79.3691129,
        neighborhood: 'ROCAFUERTE',
    },

    {
        store_latitdue: 0.970415,
        store_longitude: -79.6529,
    },
    {
        store_latitdue: 0.9269593,
        store_longitude: -79.6697608,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97044,
        store_longitude: -79.65293,
    },
    {
        store_latitdue: 0.957456,
        store_longitude: -79.6524,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9656354,
        store_longitude: -79.6506186,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9526981,
        store_longitude: -79.6506661,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9728303,
        store_longitude: -79.6542576,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9723395,
        store_longitude: -79.6542344,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9725193,
        store_longitude: -79.6541727,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97291,
        store_longitude: -79.65459,
    },
    {
        store_latitdue: 0.8745094,
        store_longitude: -79.8377501,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8803798,
        store_longitude: -79.8311196,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8786106,
        store_longitude: -79.8125212,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.4856952,
        store_longitude: -80.0434107,
        neighborhood: 'BOLÍVAR',
    },

    {
        store_latitdue: 0.87814895,
        store_longitude: -79.8145979,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.8734063,
        store_longitude: -79.839379,
    },
    {
        store_latitdue: 0.8818269,
        store_longitude: -79.8193747,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.881470536,
        store_longitude: -79.8214325,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8807948,
        store_longitude: -79.8179615,
    },
    {
        store_latitdue: 0.8873355,
        store_longitude: -79.8168378,
    },
    {
        store_latitdue: 0.8851638,
        store_longitude: -79.8091506,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.8581482,
        store_longitude: -79.8757328,
        neighborhood: 'SÚA',
    },
    {
        store_latitdue: 0.8453361,
        store_longitude: -79.9251449,
        neighborhood: 'TONCHIGÜE',
    },

    {
        store_latitdue: 0.8407775,
        store_longitude: -79.9187706,
        neighborhood: 'TONCHIGÜE',
    },

    {
        store_latitdue: 0.297698361,
        store_longitude: -79.81172924,
    },
    {
        store_latitdue: 0.8418194,
        store_longitude: -79.9201145,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.8417497,
        store_longitude: -79.9210801,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.87741,
        store_longitude: -79.8153,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.8274075,
        store_longitude: -79.9488097,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.82808,
        store_longitude: -79.9475,
    },
    {
        store_latitdue: 0.8259121,
        store_longitude: -79.948876,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.8273241,
        store_longitude: -79.9450208,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.82784445,
        store_longitude: -79.945436,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.829611,
        store_longitude: -79.9456,
    },
    {
        store_latitdue: 0.905068,
        store_longitude: -79.6902,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9081413,
        store_longitude: -79.6849821,
    },
    {
        store_latitdue: 0.9052342,
        store_longitude: -79.6881585,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.908683,
        store_longitude: -79.6865,
    },
    {
        store_latitdue: 0.909073,
        store_longitude: -79.6874,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.907583,
        store_longitude: -79.6896297,
    },
    {
        store_latitdue: 0.911029,
        store_longitude: -79.68472,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9080942,
        store_longitude: -79.6866705,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0499104,
        store_longitude: -79.3146581,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 1.0495925,
        store_longitude: -79.31421533,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 1.0670604,
        store_longitude: -79.4145434,
    },
    {
        store_latitdue: 1.0686172,
        store_longitude: -79.4155114,
    },
    {
        store_latitdue: 1.07245,
        store_longitude: -79.4121,
    },
    {
        store_latitdue: 1.0735622,
        store_longitude: -79.4128319,
        neighborhood: 'RIOVERDE',
    },
    {
        store_latitdue: 1.072107,
        store_longitude: -79.4126,
    },

    {
        store_latitdue: 0.9308622,
        store_longitude: -79.4344472,
        neighborhood: 'CHONTADURO',
    },
    {
        store_latitdue: 1.056469,
        store_longitude: -79.4454,
        neighborhood: 'RIOVERDE',
    },
    {
        store_latitdue: 0.9665846,
        store_longitude: -79.6554295,
    },
    {
        store_latitdue: 0.972376,
        store_longitude: -79.6623,
    },
    {
        store_latitdue: 1.28566,
        store_longitude: -78.8339,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9820721,
        store_longitude: -79.654033,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9287814,
        store_longitude: -79.6716007,
    },

    {
        store_latitdue: 0.92688,
        store_longitude: -79.66875,
    },
    {
        store_latitdue: 0.8477849,
        store_longitude: -79.9209628,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 1.087182,
        store_longitude: -78.9883,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.8263602,
        store_longitude: -79.9466144,
        neighborhood: 'TONCHIGÜE',
    },

    {
        store_latitdue: 0.9157978,
        store_longitude: -79.6876714,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.654971,
        store_longitude: -79.5432,
    },
    {
        store_latitdue: 0.8729285,
        store_longitude: -79.6335216,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.9241556,
        store_longitude: -79.65931,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.928327963,
        store_longitude: -79.66180497,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.926614,
        store_longitude: -79.662214,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9722,
        store_longitude: -79.65397,
    },
    {
        store_latitdue: 0.97287,
        store_longitude: -79.65488,
    },
    {
        store_latitdue: 0.980842,
        store_longitude: -79.6555,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.969836,
        store_longitude: -79.6563,
    },
    {
        store_latitdue: 0.982393,
        store_longitude: -79.654396,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.870425,
        store_longitude: -79.8476,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.966664,
        store_longitude: -79.6556,
    },
    {
        store_latitdue: 1.05718,
        store_longitude: -79.2093,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 0.8926024,
        store_longitude: -79.7123824,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0904476,
        store_longitude: -78.986519,
        neighborhood: 'BORBÓN',
    },

    {
        store_latitdue: 1.0918505,
        store_longitude: -78.9860813,
    },
    {
        store_latitdue: 0.90879,
        store_longitude: -79.6811,
    },
    {
        store_latitdue: 0.9814343,
        store_longitude: -79.6557844,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9266513,
        store_longitude: -79.662234,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9672275,
        store_longitude: -79.6537445,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6534196,
        store_longitude: -79.5418576,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.9177324,
        store_longitude: -79.6876492,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9651951,
        store_longitude: -79.6543355,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9554853,
        store_longitude: -79.6532367,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.883842,
        store_longitude: -79.8156,
    },
    {
        store_latitdue: 0.9020472,
        store_longitude: -79.7046382,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.949164,
        store_longitude: -79.6541,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.284373,
        store_longitude: -78.8323018,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.95185395,
        store_longitude: -79.656985,
    },
    {
        store_latitdue: 0.93008,
        store_longitude: -79.6691,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9130587,
        store_longitude: -79.6896657,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.8685475,
        store_longitude: -79.63138,
        neighborhood: 'SAN MATEO',
    },

    {
        store_latitdue: 0.9082108,
        store_longitude: -79.685914,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.871972,
        store_longitude: -79.8377,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.922753,
        store_longitude: -79.6597,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.95826,
        store_longitude: -79.6549,
    },

    {
        store_latitdue: 0.9300427,
        store_longitude: -79.6656035,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.931279,
        store_longitude: -79.6696,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9118574,
        store_longitude: -79.6842738,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.866917,
        store_longitude: -79.8497,
    },
    {
        store_latitdue: 0.92153,
        store_longitude: -79.6972833,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.92154,
        store_longitude: -79.65999,
    },
    {
        store_latitdue: 0.9746816,
        store_longitude: -79.6581821,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.95011,
        store_longitude: -79.65825,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.94909,
        store_longitude: -79.6574,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9163666,
        store_longitude: -79.6823122,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.96206697,
        store_longitude: -79.658957,
    },
    {
        store_latitdue: 0.9062,
        store_longitude: -79.691,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.967173,
        store_longitude: -79.6604,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.974856,
        store_longitude: -79.6576,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.933703,
        store_longitude: -79.660208,
    },

    {
        store_latitdue: 0.931465,
        store_longitude: -79.6665899,
    },
    {
        store_latitdue: 0.92291,
        store_longitude: -79.65934,
    },

    {
        store_latitdue: 0.9154385,
        store_longitude: -79.688968,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2800744,
        store_longitude: -78.8294999,
    },
    {
        store_latitdue: 1.0871293,
        store_longitude: -78.9902528,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.9211444,
        store_longitude: -79.6684187,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9093772,
        store_longitude: -79.6832311,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.8859239,
        store_longitude: -79.7016336,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.9710766,
        store_longitude: -79.6532517,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9113045,
        store_longitude: -79.6843215,
    },
    {
        store_latitdue: 0.86999,
        store_longitude: -79.84711,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 1.2775381,
        store_longitude: -78.8141162,
        neighborhood: 'SAN LORENZO',
    },

    {
        store_latitdue: 0.980732,
        store_longitude: -79.6554,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6148593,
        store_longitude: -80.0141914,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.6559881,
        store_longitude: -79.9194396,
        neighborhood: 'SAN FRANCISCO',
    },
    {
        store_latitdue: 0.92145,
        store_longitude: -79.68516,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.869337,
        store_longitude: -79.8494,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 1.284168,
        store_longitude: -78.8334,
    },
    {
        store_latitdue: 0.825549,
        store_longitude: -79.943,
    },
    {
        store_latitdue: 0.920788,
        store_longitude: -79.676252,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2896371,
        store_longitude: -78.8340125,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.8708083,
        store_longitude: -79.8441867,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.96796,
        store_longitude: -79.65289,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.656509,
        store_longitude: -79.5419548,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.9225088,
        store_longitude: -79.6829868,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.910815,
        store_longitude: -79.6836,
    },
    {
        store_latitdue: 0.980677206,
        store_longitude: -79.6521127,
    },
    {
        store_latitdue: 0.9812803,
        store_longitude: -79.6550787,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6111955,
        store_longitude: -80.0169788,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.6112552,
        store_longitude: -80.0170518,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.6149690047,
        store_longitude: -80.01411249,
    },
    {
        store_latitdue: 0.8825263,
        store_longitude: -79.8224364,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.929011,
        store_longitude: -79.6704,
    },
    {
        store_latitdue: 0.9272336,
        store_longitude: -79.6691416,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.1884763,
        store_longitude: -78.7581082,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0.8732578,
        store_longitude: -79.8395593,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.6153845,
        store_longitude: -80.0138956,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.8709012241,
        store_longitude: -79.84333207,
    },
    {
        store_latitdue: 0.8692985,
        store_longitude: -79.8494213,
    },

    {
        store_latitdue: 0.8254696,
        store_longitude: -79.9455929,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.877973,
        store_longitude: -79.8256544,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.882563,
        store_longitude: -79.8200319,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.931258,
        store_longitude: -79.6725,
    },

    {
        store_latitdue: 0.930888,
        store_longitude: -79.6758,
    },
    {
        store_latitdue: 0.982593,
        store_longitude: -79.6560171,
    },
    {
        store_latitdue: 0.8832086,
        store_longitude: -79.8206211,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.7116772,
        store_longitude: -79.6887423,
    },
    {
        store_latitdue: 0.267444,
        store_longitude: -79.954954,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.5059938,
        store_longitude: -80.022533,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.0875172316,
        store_longitude: -79.95296672,
    },
    {
        store_latitdue: 0.506237,
        store_longitude: -80.0226438,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.2760398,
        store_longitude: -79.9490487,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.6071842,
        store_longitude: -79.9080364,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.97412,
        store_longitude: -79.6687,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97798,
        store_longitude: -79.6599,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.962922,
        store_longitude: -79.6527,
    },
    {
        store_latitdue: 0.9314711,
        store_longitude: -79.6725781,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8268509,
        store_longitude: -79.9482354,
        neighborhood: 'TONCHIGÜE',
    },

    {
        store_latitdue: 0.91934,
        store_longitude: -79.6899,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97404,
        store_longitude: -79.6531,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9318272,
        store_longitude: -79.6732332,
    },
    {
        store_latitdue: 0.6108645,
        store_longitude: -80.0174468,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.90157,
        store_longitude: -79.70416,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9813528,
        store_longitude: -79.6546727,
    },
    {
        store_latitdue: 0.9107521,
        store_longitude: -79.6864822,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8719456,
        store_longitude: -79.8387111,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.870842,
        store_longitude: -79.848257,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8690031,
        store_longitude: -79.8439052,
    },
    {
        store_latitdue: 0.93115,
        store_longitude: -79.6617,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6094796,
        store_longitude: -80.0224886,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.9720979,
        store_longitude: -79.6535875,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.89489,
        store_longitude: -79.7171,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 1.2878301,
        store_longitude: -78.835422,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.654664,
        store_longitude: -79.5433,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 1.044144,
        store_longitude: -79.28,
    },
    {
        store_latitdue: 0.8772501,
        store_longitude: -79.8156574,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.9494906,
        store_longitude: -79.6581437,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9124378,
        store_longitude: -79.6892792,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0872934,
        store_longitude: -78.9889517,
    },
    {
        store_latitdue: 0.9141695,
        store_longitude: -79.6856658,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8775834,
        store_longitude: -79.819926,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.9175164,
        store_longitude: -79.6834774,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2917805,
        store_longitude: -78.8361139,
    },
    {
        store_latitdue: 0.9044784,
        store_longitude: -79.6853159,
    },
    {
        store_latitdue: 1.28721,
        store_longitude: -78.8314,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2821091,
        store_longitude: -78.8254825,
        neighborhood: 'SAN LORENZO',
    },

    {
        store_latitdue: 0.93133,
        store_longitude: -79.668,
    },
    {
        store_latitdue: 0.962871,
        store_longitude: -79.661516,
    },
    {
        store_latitdue: 1.0899558,
        store_longitude: -78.986375,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.8780847,
        store_longitude: -79.8188296,
        neighborhood: 'TONSUPA',
    },

    {
        store_latitdue: 0.9304818,
        store_longitude: -79.6732436,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9721201,
        store_longitude: -79.6539287,
    },
    {
        store_latitdue: 0.7408611,
        store_longitude: -79.5829402,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.6592716,
        store_longitude: -79.5411278,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.9752896,
        store_longitude: -79.6576771,
    },
    {
        store_latitdue: 1.0493789,
        store_longitude: -79.3135947,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 0.907783,
        store_longitude: -79.683469,
    },
    {
        store_latitdue: 0.8801324,
        store_longitude: -79.819531,
    },

    {
        store_latitdue: 0.9066089,
        store_longitude: -79.6916844,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8659008,
        store_longitude: -79.8500203,
        neighborhood: 'ATACAMES',
    },

    {
        store_latitdue: 1.0878006,
        store_longitude: -78.9905648,
        neighborhood: 'BORBÓN',
    },

    {
        store_latitdue: 0.932197,
        store_longitude: -79.6657,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.61932,
        store_longitude: -80.0119,
    },
    {
        store_latitdue: 0.9660316,
        store_longitude: -79.6502619,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.803182,
        store_longitude: -79.6227,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.9722369,
        store_longitude: -79.6538514,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.871559,
        store_longitude: -79.8422546,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8692897,
        store_longitude: -79.8379484,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.927552,
        store_longitude: -79.6702841,
    },
    {
        store_latitdue: 0.9215449,
        store_longitude: -79.6766599,
    },
    {
        store_latitdue: 0.959566,
        store_longitude: -79.655,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.929885,
        store_longitude: -79.6694,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2848496,
        store_longitude: -78.8338009,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.829478,
        store_longitude: -79.9456,
    },
    {
        store_latitdue: 0.808531,
        store_longitude: -79.6153,
        neighborhood: 'SAN MATEO',
    },

    {
        store_latitdue: 0.9516518,
        store_longitude: -79.6582568,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9012227,
        store_longitude: -79.7029181,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0944318,
        store_longitude: -79.1632756,
        neighborhood: 'LA TOLA',
    },
    {
        store_latitdue: 1.064007,
        store_longitude: -79.3702028,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: 0.96186,
        store_longitude: -79.6271,
    },
    {
        store_latitdue: 0.9150313,
        store_longitude: -79.6921816,
    },
    {
        store_latitdue: 0.8653526,
        store_longitude: -79.8558987,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.93269,
        store_longitude: -79.6615,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 1.2915344,
        store_longitude: -78.8378282,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.86903036,
        store_longitude: -79.8473857,
    },
    {
        store_latitdue: 0.870236,
        store_longitude: -79.8472531,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9661082,
        store_longitude: -79.6609522,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.90708,
        store_longitude: -79.690018,
    },

    {
        store_latitdue: 0.9575438,
        store_longitude: -79.6538507,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9687527,
        store_longitude: -79.6542394,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.4666824,
        store_longitude: -79.5368807,
        neighborhood: 'ROSA ZÁRATE',
    },

    {
        store_latitdue: 0.8696398,
        store_longitude: -79.8492705,
    },
    {
        store_latitdue: 0.9609409,
        store_longitude: -79.6533506,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9277197,
        store_longitude: -79.6685384,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.909474,
        store_longitude: -79.6863847,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.877638,
        store_longitude: -79.8248,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.6147976,
        store_longitude: -80.0141697,
    },
    {
        store_latitdue: 0.8168278,
        store_longitude: -79.6097733,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.9565184,
        store_longitude: -79.6548155,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.969836,
        store_longitude: -79.6556997,
    },
    {
        store_latitdue: 0.8846751,
        store_longitude: -79.80782,
        neighborhood: 'TONSUPA',
    },

    {
        store_latitdue: 0.908305,
        store_longitude: -79.6808,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0849414,
        store_longitude: -78.9970983,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.9542019,
        store_longitude: -79.6538945,
    },
    {
        store_latitdue: 0.9636705,
        store_longitude: -79.6556406,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6333391,
        store_longitude: -80.0045697,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.6549883,
        store_longitude: -80.0659963,
        neighborhood: 'SAN FRANCISCO',
    },
    {
        store_latitdue: 0.655838,
        store_longitude: -79.9190605,
        neighborhood: 'SAN FRANCISCO',
    },
    {
        store_latitdue: 0.6547941,
        store_longitude: -80.0660858,
        neighborhood: 'SAN FRANCISCO',
    },
    {
        store_latitdue: 0.8760687,
        store_longitude: -79.6357224,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.737273,
        store_longitude: -79.9280822,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.9309166,
        store_longitude: -79.6656042,
    },
    {
        store_latitdue: 0.94211,
        store_longitude: -79.65008,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9367894,
        store_longitude: -79.6757563,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.90866,
        store_longitude: -79.693389,
    },
    {
        store_latitdue: 1.064882,
        store_longitude: -79.3702024,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: 0.93252,
        store_longitude: -79.675,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9061588,
        store_longitude: -79.6808566,
    },
    {
        store_latitdue: 0.8954142,
        store_longitude: -79.806472,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.9501687,
        store_longitude: -79.6526986,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8260296,
        store_longitude: -79.9433816,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.8737543,
        store_longitude: -79.8391469,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 1.283896,
        store_longitude: -78.8291484,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.900285,
        store_longitude: -79.7071,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2799265,
        store_longitude: -78.8408651,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.958741,
        store_longitude: -79.6393,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9728499,
        store_longitude: -79.6547641,
    },
    {
        store_latitdue: 0.655178,
        store_longitude: -79.542781,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.8940695,
        store_longitude: -79.8065565,
        neighborhood: 'TONSUPA',
    },

    {
        store_latitdue: 0.942941,
        store_longitude: -79.6495,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.911167,
        store_longitude: -79.686,
    },
    {
        store_latitdue: 0.8849819,
        store_longitude: -79.8194827,
    },
    {
        store_latitdue: 0.963674,
        store_longitude: -79.6540293,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2875658,
        store_longitude: -78.8361612,
    },
    {
        store_latitdue: 0.9561962,
        store_longitude: -79.6545955,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6371764,
        store_longitude: -80.0017985,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.9328539,
        store_longitude: -79.6614274,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8841946,
        store_longitude: -79.8206079,
    },
    {
        store_latitdue: 0.7120726,
        store_longitude: -79.6864838,
        neighborhood: 'CORONEL CARLOS CONCHA TORRES',
    },
    {
        store_latitdue: 1.05128,
        store_longitude: -79.3122768,
    },

    {
        store_latitdue: 0.6559722,
        store_longitude: -79.5423009,
    },
    {
        store_latitdue: 0.96186,
        store_longitude: -79.6256,
    },
    {
        store_latitdue: 0.8968704,
        store_longitude: -79.7096497,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.964061,
        store_longitude: -79.6539,
    },
    {
        store_latitdue: 1.0724748,
        store_longitude: -79.4116095,
    },
    {
        store_latitdue: 0.856324,
        store_longitude: -79.876562,
        neighborhood: 'SÚA',
    },
    {
        store_latitdue: 1.2673340191,
        store_longitude: -78.8407976532,
    },
    {
        store_latitdue: 1.2920736,
        store_longitude: -78.8392678,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2901959,
        store_longitude: -78.8333767,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2862566,
        store_longitude: -78.8295016,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.930547,
        store_longitude: -79.6589,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8258296,
        store_longitude: -79.9436489,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 1.2808912,
        store_longitude: -78.82706,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9260228,
        store_longitude: -79.6684552,
    },
    {
        store_latitdue: 0.936021,
        store_longitude: -79.674584,
    },

    {
        store_latitdue: 1.2811724,
        store_longitude: -78.8319625,
    },
    {
        store_latitdue: 0.8704804,
        store_longitude: -79.8418298,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.929819,
        store_longitude: -79.6696,
    },
    {
        store_latitdue: 0.931244,
        store_longitude: -79.672382,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.917225,
        store_longitude: -79.6903599,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.968691,
        store_longitude: -79.656,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.961353,
        store_longitude: -79.6307,
    },
    {
        store_latitdue: 0.8765349,
        store_longitude: -79.6369306,
        neighborhood: 'SAN MATEO',
    },

    {
        store_latitdue: 0.922595998,
        store_longitude: -79.6778404,
    },

    {
        store_latitdue: 1.28206,
        store_longitude: -78.8323,
    },
    {
        store_latitdue: 0.9659748,
        store_longitude: -79.6549874,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9589443,
        store_longitude: -79.6554295,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9499687,
        store_longitude: -79.6505175,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2862796,
        store_longitude: -78.8300191,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.6109505,
        store_longitude: -80.0199114,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.6175192,
        store_longitude: -80.0126788,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.929919,
        store_longitude: -79.659,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.925694,
        store_longitude: -79.6694,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8460501,
        store_longitude: -79.9253339,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.9698287,
        store_longitude: -79.6556609,
    },
    {
        store_latitdue: 0.98280296,
        store_longitude: -79.6566499,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 1.044329,
        store_longitude: -79.2799589,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 1.28131,
        store_longitude: -78.8226,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.8880551,
        store_longitude: -79.8091958,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.920993,
        store_longitude: -79.692116,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.048679,
        store_longitude: -79.0768431,
        neighborhood: 'ANCHAYACU',
    },
    {
        store_latitdue: 0.962312,
        store_longitude: -79.6552,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2846798,
        store_longitude: -78.8318687,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.8356257,
        store_longitude: -79.6236543,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.878004,
        store_longitude: -79.8134,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 1.281900167,
        store_longitude: -78.8275146,
    },
    {
        store_latitdue: 0.8492838,
        store_longitude: -79.9225061,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.9118532,
        store_longitude: -79.6818044,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2805581,
        store_longitude: -78.8218593,
    },
    {
        store_latitdue: 1.27999,
        store_longitude: -78.8292,
    },
    {
        store_latitdue: 0.9701967,
        store_longitude: -79.6528057,
    },
    {
        store_latitdue: 0.9812116,
        store_longitude: -79.6655263,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.93145,
        store_longitude: -79.6777,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9649188,
        store_longitude: -79.6559587,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6279075,
        store_longitude: -79.5463611,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.7762807,
        store_longitude: -79.5976278,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.775369,
        store_longitude: -79.5998,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.8266746,
        store_longitude: -79.9483353,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.932749,
        store_longitude: -79.6673,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.909269,
        store_longitude: -79.6882251,
    },
    {
        store_latitdue: 0.933699,
        store_longitude: -79.6731893,
    },
    {
        store_latitdue: 0.932898,
        store_longitude: -79.6777,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9721439,
        store_longitude: -79.6546267,
    },

    {
        store_latitdue: 0.8995131,
        store_longitude: -79.800155,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.914062,
        store_longitude: -79.6908,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.892991,
        store_longitude: -79.7117,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.109587157,
        store_longitude: -78.91879455,
    },
    {
        store_latitdue: 0.8782672,
        store_longitude: -79.8198793,
        neighborhood: 'ATACAMES',
    },

    {
        store_latitdue: 0.8787701,
        store_longitude: -79.8203004,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 1.0668995,
        store_longitude: -79.4151988,
    },
    {
        store_latitdue: 1.0500745,
        store_longitude: -79.3110249,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 0.932147,
        store_longitude: -79.6686,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9622879,
        store_longitude: -79.6527173,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2855334,
        store_longitude: -78.8310303,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.873499,
        store_longitude: -79.8372,
        neighborhood: 'ATACAMES',
    },

    {
        store_latitdue: 1.28132,
        store_longitude: -78.8324,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.970259,
        store_longitude: -79.6528,
    },
    {
        store_latitdue: 0.8997838,
        store_longitude: -79.7079174,
    },
    {
        store_latitdue: 0.901924,
        store_longitude: -79.7088,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.966173,
        store_longitude: -79.6559,
    },
    {
        store_latitdue: 0.9119284,
        store_longitude: -79.6886347,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.926575977,
        store_longitude: -79.661709,
    },

    {
        store_latitdue: 1.0726797,
        store_longitude: -79.412959,
    },
    {
        store_latitdue: 1.0868206,
        store_longitude: -78.9886891,
    },
    {
        store_latitdue: 0.65483,
        store_longitude: -80.0673,
        neighborhood: 'SAN FRANCISCO',
    },
    {
        store_latitdue: 0.9313329,
        store_longitude: -79.6696227,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0881073,
        store_longitude: -78.990715,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.9116221,
        store_longitude: -79.6875678,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9646308,
        store_longitude: -79.6541466,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2821122,
        store_longitude: -78.8321403,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.96595,
        store_longitude: -79.66143,
    },
    {
        store_latitdue: 0.8359428,
        store_longitude: -79.6225015,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.88394,
        store_longitude: -79.8089,
    },
    {
        store_latitdue: 0.8908105,
        store_longitude: -79.7125957,
    },
    {
        store_latitdue: 0.866077,
        store_longitude: -79.8508,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8799848,
        store_longitude: -79.8143993,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.97274,
        store_longitude: -79.65422,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9636407,
        store_longitude: -79.627402,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.917281,
        store_longitude: -79.6982,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.609272,
        store_longitude: -80.0172,
    },
    {
        store_latitdue: 1.0432877,
        store_longitude: -79.2803827,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 0.8751459,
        store_longitude: -79.8172594,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.849441,
        store_longitude: -79.8802,
        neighborhood: 'SÚA',
    },
    {
        store_latitdue: 0.9632703,
        store_longitude: -79.628849,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.8885588,
        store_longitude: -79.7178628,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.915453,
        store_longitude: -79.697879,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.901902,
        store_longitude: -79.7081,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9155423,
        store_longitude: -79.6925764,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8803063,
        store_longitude: -79.8125302,
    },
    {
        store_latitdue: 1.0445721,
        store_longitude: -79.2798087,
        neighborhood: 'LAGARTO',
    },

    {
        store_latitdue: 0.91653,
        store_longitude: -79.6896,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8094527,
        store_longitude: -79.8662842,
    },
    {
        store_latitdue: 0.982998,
        store_longitude: -79.6568,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.896967,
        store_longitude: -79.6981,
    },
    {
        store_latitdue: 0.9768459,
        store_longitude: -79.6553522,
    },
    {
        store_latitdue: 0.95165,
        store_longitude: -79.6551,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9589134,
        store_longitude: -79.6594303,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9704158,
        store_longitude: -79.6513375,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.960778,
        store_longitude: -79.6514,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.902361,
        store_longitude: -79.7085,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97058642,
        store_longitude: -79.6647333,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.910614,
        store_longitude: -79.6824,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.914842,
        store_longitude: -79.6887,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9142932,
        store_longitude: -79.6921877,
    },
    {
        store_latitdue: 0.912046,
        store_longitude: -79.6846,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9639276,
        store_longitude: -79.6522203,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9298213,
        store_longitude: -79.6655877,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9109564,
        store_longitude: -79.6829439,
    },
    {
        store_latitdue: 1.09216,
        store_longitude: -78.9871,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.878376,
        store_longitude: -79.8202,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.6271609,
        store_longitude: -80.0067958,
    },
    {
        store_latitdue: 1.092113,
        store_longitude: -78.9869,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.0652117,
        store_longitude: -79.3714769,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: 0.6114962,
        store_longitude: -80.0197254,
    },
    {
        store_latitdue: 0.6097318,
        store_longitude: -80.0225863,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.6094962,
        store_longitude: -80.0226475,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.8778879,
        store_longitude: -79.8191466,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.8690252,
        store_longitude: -79.8495046,
    },
    {
        store_latitdue: 1.0496118,
        store_longitude: -79.3139195,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 1.0497452,
        store_longitude: -79.3145329,
    },
    {
        store_latitdue: 0.9317848,
        store_longitude: -79.6616047,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.930765,
        store_longitude: -79.6605,
    },
    {
        store_latitdue: 0.9173761,
        store_longitude: -79.6872135,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2850671,
        store_longitude: -78.834411,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9629393,
        store_longitude: -79.6593034,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9202833,
        store_longitude: -79.6825132,
    },
    {
        store_latitdue: 0.914182,
        store_longitude: -79.6884,
    },
    {
        store_latitdue: 0.96597,
        store_longitude: -79.65074,
    },
    {
        store_latitdue: 0.6107809,
        store_longitude: -80.0174325,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.916896,
        store_longitude: -79.6918,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9678209,
        store_longitude: -79.651307,
    },

    {
        store_latitdue: 0.8887073,
        store_longitude: -79.8192368,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.9744865,
        store_longitude: -79.6532326,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0880432,
        store_longitude: -78.9894333,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.881645,
        store_longitude: -79.82412,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8785388,
        store_longitude: -79.8132309,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.875249,
        store_longitude: -79.8164,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.9327769,
        store_longitude: -79.6675981,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.977818,
        store_longitude: -79.6545,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9155427,
        store_longitude: -79.6913175,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.96356397,
        store_longitude: -79.657912,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9083495,
        store_longitude: -79.6865253,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6116296,
        store_longitude: -80.0201521,
    },
    {
        store_latitdue: 1.28228,
        store_longitude: -78.8241,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.885614,
        store_longitude: -79.8066,
    },
    {
        store_latitdue: 0.88697,
        store_longitude: -79.8133,
    },
    {
        store_latitdue: 0.776706,
        store_longitude: -79.5995821,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.922786295,
        store_longitude: -79.6706314,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9368306,
        store_longitude: -79.6734353,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9660738,
        store_longitude: -79.6231712,
    },
    {
        store_latitdue: 0.911065,
        store_longitude: -79.6907,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.917737,
        store_longitude: -79.6912,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8101772,
        store_longitude: -79.8658977,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.890798,
        store_longitude: -79.7909,
    },
    {
        store_latitdue: 0.897104,
        store_longitude: -79.8038,
    },
    {
        store_latitdue: 0.918461,
        store_longitude: -79.6816,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2922938,
        store_longitude: -78.8390074,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.0636407,
        store_longitude: -79.3710458,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: 0.9665764,
        store_longitude: -79.6554576,
    },
    {
        store_latitdue: 0.869023,
        store_longitude: -79.8494,
    },
    {
        store_latitdue: 0.956951,
        store_longitude: -79.652,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.964707,
        store_longitude: -79.6522,
    },
    {
        store_latitdue: 0.6113227,
        store_longitude: -80.0198142,
        neighborhood: 'MUISNE',
    },

    {
        store_latitdue: 0.8204154,
        store_longitude: -79.7037579,
    },
    {
        store_latitdue: 0.870706,
        store_longitude: -79.8443,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 1.0673834,
        store_longitude: -79.4154103,
        neighborhood: 'RIOVERDE',
    },

    {
        store_latitdue: 0.951954,
        store_longitude: -79.6587,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.893893,
        store_longitude: -79.7107,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2812729,
        store_longitude: -78.8377513,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9639008,
        store_longitude: -79.6529422,
    },
    {
        store_latitdue: 0.912336,
        store_longitude: -79.6918,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9719181,
        store_longitude: -79.6544444,
    },
    {
        store_latitdue: 0.873145,
        store_longitude: -79.8355,
        neighborhood: 'ATACAMES',
    },

    {
        store_latitdue: 0.8927515,
        store_longitude: -79.6643664,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.9320064,
        store_longitude: -79.4351402,
        neighborhood: 'CHONTADURO',
    },
    {
        store_latitdue: 0.5060527,
        store_longitude: -80.0225737,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.949485,
        store_longitude: -79.6505,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.8743622,
        store_longitude: -79.8367454,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.7157818,
        store_longitude: -79.5530303,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.609629,
        store_longitude: -80.0175718,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.897037,
        store_longitude: -79.7146,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.965934,
        store_longitude: -79.6619,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.7400134,
        store_longitude: -79.5843669,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.891219,
        store_longitude: -79.8123,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 1.0517246,
        store_longitude: -79.3112501,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 0.918215,
        store_longitude: -79.6872,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.869022,
        store_longitude: -79.8496,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.962082,
        store_longitude: -79.656,
    },
    {
        store_latitdue: 0.9350583,
        store_longitude: -79.676708,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.914625,
        store_longitude: -79.6899,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8416982,
        store_longitude: -79.9210362,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.8924187,
        store_longitude: -79.6647123,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.916441,
        store_longitude: -79.6842,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9141531,
        store_longitude: -79.68531,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6075167,
        store_longitude: -79.9077679,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.8954302,
        store_longitude: -79.7139774,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.87694,
        store_longitude: -79.8266,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.856563,
        store_longitude: -79.8772,
        neighborhood: 'SÚA',
    },
    {
        store_latitdue: 0.965119,
        store_longitude: -79.6488,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9614391,
        store_longitude: -79.6630373,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8964032,
        store_longitude: -79.6999699,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.8214664,
        store_longitude: -79.70325,
        neighborhood: 'TABIAZO',
    },
    {
        store_latitdue: 0.866499,
        store_longitude: -79.8547,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9116476,
        store_longitude: -79.6821627,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8649881,
        store_longitude: -79.8469648,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8649889,
        store_longitude: -79.8468104,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8661286,
        store_longitude: -79.84679,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8969926,
        store_longitude: -79.7105327,
    },
    {
        store_latitdue: 0.8881738,
        store_longitude: -79.632172,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.9796796,
        store_longitude: -79.6553065,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.29058,
        store_longitude: -78.8359,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.822315,
        store_longitude: -79.703754,
    },
    {
        store_latitdue: 0.6559192,
        store_longitude: -79.5420807,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.878778,
        store_longitude: -79.8183,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.921627,
        store_longitude: -79.6841,
    },
    {
        store_latitdue: 1.28297,
        store_longitude: -78.8374,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.929441,
        store_longitude: -79.6743,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8719359,
        store_longitude: -79.8415731,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.915052,
        store_longitude: -79.6914,
    },
    {
        store_latitdue: 0.8962237,
        store_longitude: -79.7030849,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.9285666,
        store_longitude: -79.671137,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0431249,
        store_longitude: -79.2801904,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 1.06479,
        store_longitude: -79.3691,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: 0.885621,
        store_longitude: -79.8078,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 1.2818581,
        store_longitude: -78.8268002,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.927158,
        store_longitude: -79.6693,
    },
    {
        store_latitdue: 0.616582,
        store_longitude: -80.0134578,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.8853374,
        store_longitude: -79.8169499,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.8769892,
        store_longitude: -79.6374996,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.9735316575,
        store_longitude: -79.65366281,
    },

    {
        store_latitdue: 0.965396,
        store_longitude: -79.6505,
    },
    {
        store_latitdue: 0.91078,
        store_longitude: -79.6843,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.950577,
        store_longitude: -79.6524,
    },
    {
        store_latitdue: 0.9136766,
        store_longitude: -79.685788,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9757536,
        store_longitude: -79.6539891,
    },
    {
        store_latitdue: 0.957063,
        store_longitude: -79.6549,
    },
    {
        store_latitdue: 0.905557,
        store_longitude: -79.691,
    },
    {
        store_latitdue: 0.914282,
        store_longitude: -79.6908,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: -0.2302305,
        store_longitude: -79.1655635,
    },
    {
        store_latitdue: 0.884781,
        store_longitude: -79.8076,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 1.2882222,
        store_longitude: -78.8356929,
    },

    {
        store_latitdue: 0.9296589,
        store_longitude: -79.6737187,
    },
    {
        store_latitdue: 0.9219107,
        store_longitude: -79.6604425,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.955337,
        store_longitude: -79.6513,
    },
    {
        store_latitdue: 0.918885,
        store_longitude: -79.6877,
    },

    {
        store_latitdue: 0.9644044,
        store_longitude: -79.6496574,
    },
    {
        store_latitdue: 0.8772262,
        store_longitude: -79.8269065,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9303885,
        store_longitude: -79.6600347,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.917734,
        store_longitude: -79.6868,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9740741,
        store_longitude: -79.6692153,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.959395,
        store_longitude: -79.6559,
    },

    {
        store_latitdue: 1.2885508,
        store_longitude: -78.8346787,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.930682,
        store_longitude: -79.6654,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.907682,
        store_longitude: -79.6808,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9272433,
        store_longitude: -79.6621979,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9588526,
        store_longitude: -79.6545992,
    },
    {
        store_latitdue: 0.890457,
        store_longitude: -79.6659,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 1.28084,
        store_longitude: -78.8249,
    },
    {
        store_latitdue: 0.870845,
        store_longitude: -79.8407,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.967149,
        store_longitude: -79.6537,
    },
    {
        store_latitdue: 1.2871116,
        store_longitude: -78.8318444,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9564415,
        store_longitude: -79.6536753,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9324317,
        store_longitude: -79.6616562,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 1.0889339,
        store_longitude: -78.9928699,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.904985,
        store_longitude: -79.6795,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.95954,
        store_longitude: -79.6525,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8908326,
        store_longitude: -79.6307565,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.6158005,
        store_longitude: -80.0137757,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.9192471,
        store_longitude: -79.6812334,
    },
    {
        store_latitdue: 0.9225321,
        store_longitude: -79.6765907,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0685863,
        store_longitude: -79.4156107,
        neighborhood: 'RIOVERDE',
    },
    {
        store_latitdue: 1.29044,
        store_longitude: -78.8396,
    },
    {
        store_latitdue: 0.961828,
        store_longitude: -79.6507,
    },
    {
        store_latitdue: 0.880159,
        store_longitude: -79.8152,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.8283251,
        store_longitude: -79.948804,
        neighborhood: 'TONCHIGÜE',
    },

    {
        store_latitdue: 0.9284862,
        store_longitude: -79.6709957,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8706694,
        store_longitude: -79.8469941,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.917322,
        store_longitude: -79.6977,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2827088,
        store_longitude: -78.83627,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9247975,
        store_longitude: -79.6655671,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.285826,
        store_longitude: -78.83583,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9200291,
        store_longitude: -79.6845369,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8752584,
        store_longitude: -79.8288066,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 1.0677352,
        store_longitude: -79.4139148,
    },
    {
        store_latitdue: 0.655742,
        store_longitude: -79.5433,
    },
    {
        store_latitdue: 0.933901,
        store_longitude: -79.6597,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: -0.3132993,
        store_longitude: -78.5339003,
    },
    {
        store_latitdue: 0.921067,
        store_longitude: -79.6825,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8172257,
        store_longitude: -79.6105571,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.3022356506,
        store_longitude: -79.69240024,
    },
    {
        store_latitdue: 0.868861,
        store_longitude: -79.8492,
        neighborhood: 'ATACAMES',
    },

    {
        store_latitdue: 0.928993,
        store_longitude: -79.6715,
    },
    {
        store_latitdue: 0.8935485,
        store_longitude: -79.7150638,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.967512,
        store_longitude: -79.6604,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.970783,
        store_longitude: -79.6530597,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.924721,
        store_longitude: -79.6666,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.08656,
        store_longitude: -78.99,
    },
    {
        store_latitdue: 0.930826,
        store_longitude: -79.6777,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.905393,
        store_longitude: -79.6888,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.775964,
        store_longitude: -79.5998,
    },
    {
        store_latitdue: 0.896313,
        store_longitude: -79.6999995,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.8713346,
        store_longitude: -79.6328723,
    },
    {
        store_latitdue: 0.7110267,
        store_longitude: -79.6891452,
    },

    {
        store_latitdue: 0.927973299,
        store_longitude: -79.67072446,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.953352,
        store_longitude: -79.651,
    },
    {
        store_latitdue: 0.810931,
        store_longitude: -79.8665,
    },

    {
        store_latitdue: 0.8848139,
        store_longitude: -79.8090128,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.618019,
        store_longitude: -80.0126,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.93108,
        store_longitude: -79.6669,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.825802,
        store_longitude: -79.9462,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.5063081,
        store_longitude: -80.0226558,
    },
    {
        store_latitdue: 0.5068251,
        store_longitude: -80.0229155,
    },
    {
        store_latitdue: 0.5071764,
        store_longitude: -80.0209782,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.269187,
        store_longitude: -79.9567,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.891316,
        store_longitude: -79.7097,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.493702,
        store_longitude: -79.972,
    },
    {
        store_latitdue: 0.4940754,
        store_longitude: -79.9706607,
        neighborhood: 'SAN GREGORIO',
    },
    {
        store_latitdue: 1.01443,
        store_longitude: -79.5108,
    },
    {
        store_latitdue: 0.967845,
        store_longitude: -79.6619,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.96996,
        store_longitude: -79.6552,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.2765975,
        store_longitude: -79.9485388,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.4172429,
        store_longitude: -80.0018597,
    },
    {
        store_latitdue: 0.901013429,
        store_longitude: -79.6891379,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.901008,
        store_longitude: -79.6894,
        neighborhood: 'VUELTA LARGA',
    },

    {
        store_latitdue: 1.2835376,
        store_longitude: -78.8363663,
    },
    {
        store_latitdue: 0.3753516,
        store_longitude: -79.9584555,
        neighborhood: 'DAULE',
    },
    {
        store_latitdue: 0.376293,
        store_longitude: -79.9591928,
        neighborhood: 'DAULE',
    },
    {
        store_latitdue: 0.432516,
        store_longitude: -80.0119,
    },
    {
        store_latitdue: 0.95862,
        store_longitude: -79.6645,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.51984,
        store_longitude: -79.9534,
    },
    {
        store_latitdue: 0.8281153,
        store_longitude: -79.9493118,
    },
    {
        store_latitdue: 0.92786,
        store_longitude: -79.673714,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.905857,
        store_longitude: -79.6886,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.894885,
        store_longitude: -79.7116,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.97309,
        store_longitude: -79.6601,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.961134433,
        store_longitude: -79.6528625,
    },
    {
        store_latitdue: 0.269239,
        store_longitude: -79.9566,
    },
    {
        store_latitdue: 0.926819,
        store_longitude: -79.6686,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9313635,
        store_longitude: -79.667554,
    },
    {
        store_latitdue: 0.8957187,
        store_longitude: -79.8060122,
        neighborhood: 'TONSUPA',
    },

    {
        store_latitdue: 0.877185,
        store_longitude: -79.827,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8691683,
        store_longitude: -79.84933,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9663536,
        store_longitude: -79.6544902,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8114736,
        store_longitude: -79.8657033,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.9171115,
        store_longitude: -79.6785163,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6409772,
        store_longitude: -79.9508706,
    },
    {
        store_latitdue: 0.9566683,
        store_longitude: -79.653281,
    },
    {
        store_latitdue: 0.4938946,
        store_longitude: -79.9710552,
        neighborhood: 'SAN GREGORIO',
    },
    {
        store_latitdue: -0.187439,
        store_longitude: -78.505206,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.930914,
        store_longitude: -79.6684,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.04316,
        store_longitude: -79.2807,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 0.737538,
        store_longitude: -79.928093,
    },
    {
        store_latitdue: 0.72272,
        store_longitude: -79.9186,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.8916384,
        store_longitude: -79.7086604,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9742263,
        store_longitude: -79.6590929,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.927471,
        store_longitude: -79.6703,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9293215,
        store_longitude: -79.6717561,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8737164,
        store_longitude: -79.8216114,
    },
    {
        store_latitdue: 0.9034969,
        store_longitude: -79.6855515,
    },
    {
        store_latitdue: 0.9201889,
        store_longitude: -79.6818147,
    },
    {
        store_latitdue: 0.9626248,
        store_longitude: -79.6301769,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 1.2855383,
        store_longitude: -78.8319158,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.913232,
        store_longitude: -79.6898814,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.969133,
        store_longitude: -79.6594,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2821564,
        store_longitude: -78.8222496,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.95101,
        store_longitude: -79.6585,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8953251,
        store_longitude: -79.7028087,
    },
    {
        store_latitdue: 0.877247,
        store_longitude: -79.823325,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9617791,
        store_longitude: -79.6296982,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.958587507,
        store_longitude: -79.6483039,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.852175,
        store_longitude: -79.8763,
        neighborhood: 'SÚA',
    },
    {
        store_latitdue: 0.927078,
        store_longitude: -79.6654,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.09607,
        store_longitude: -79.162,
        neighborhood: 'LA TOLA',
    },
    {
        store_latitdue: 1.2897898,
        store_longitude: -78.8360343,
    },
    {
        store_latitdue: 0.9134309,
        store_longitude: -79.6908616,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8223115,
        store_longitude: -79.7036754,
        neighborhood: 'TABIAZO',
    },
    {
        store_latitdue: 0.976212,
        store_longitude: -79.6556,
    },
    {
        store_latitdue: 1.0679941,
        store_longitude: -79.4135738,
        neighborhood: 'RIOVERDE',
    },
    {
        store_latitdue: 0.91179,
        store_longitude: -79.6852,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.825274,
        store_longitude: -79.947,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.9301822,
        store_longitude: -79.6712815,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.981005,
        store_longitude: -79.6557,
    },
    {
        store_latitdue: 0.712553,
        store_longitude: -79.5475,
    },
    {
        store_latitdue: 1.28896,
        store_longitude: -78.8346,
        neighborhood: 'SAN LORENZO',
    },

    {
        store_latitdue: 0.9102021,
        store_longitude: -79.6879645,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.654827,
        store_longitude: -79.5433786,
    },
    {
        store_latitdue: 0.966274,
        store_longitude: -79.648,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0879665,
        store_longitude: -78.9933959,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.281312,
        store_longitude: -78.822659,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.8258314,
        store_longitude: -79.9486315,
    },
    {
        store_latitdue: 0.921380102,
        store_longitude: -79.6830825,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0725372,
        store_longitude: -79.4129509,
    },
    {
        store_latitdue: 0.906419,
        store_longitude: -79.6906389,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8657428,
        store_longitude: -79.8512348,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8733657,
        store_longitude: -79.8499091,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8764481,
        store_longitude: -79.820545,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.6269787,
        store_longitude: -80.0076271,
    },
    {
        store_latitdue: 1.2828578,
        store_longitude: -78.835709,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.8740713,
        store_longitude: -79.8486529,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9591374,
        store_longitude: -79.6519695,
    },
    {
        store_latitdue: 0.951017,
        store_longitude: -79.6504,
    },
    {
        store_latitdue: 0.914541482,
        store_longitude: -79.6819839,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9194987,
        store_longitude: -79.6825479,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.7761805,
        store_longitude: -79.5978914,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.6551488,
        store_longitude: -79.5433725,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.894354,
        store_longitude: -79.7136,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9194642,
        store_longitude: -79.6825515,
    },
    {
        store_latitdue: 0.874519,
        store_longitude: -79.82,
        neighborhood: 'TONSUPA',
    },

    {
        store_latitdue: 0.8695775,
        store_longitude: -79.8476394,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9694051,
        store_longitude: -79.6552507,
    },
    {
        store_latitdue: 0.9550729,
        store_longitude: -79.6554416,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.896076,
        store_longitude: -79.806,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.9615929,
        store_longitude: -79.6591011,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.932957,
        store_longitude: -79.6777,
    },
    {
        store_latitdue: 0.8807889,
        store_longitude: -79.8208911,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.880266,
        store_longitude: -79.8129243,
    },
    {
        store_latitdue: 0.9327199,
        store_longitude: -79.6716616,
    },
    {
        store_latitdue: 1.097632258,
        store_longitude: -79.1604959,
        neighborhood: 'LA TOLA',
    },
    {
        store_latitdue: 0.8818,
        store_longitude: -79.8119,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: -0.0553085,
        store_longitude: -78.4635,
    },
    {
        store_latitdue: 0.895037,
        store_longitude: -79.7129,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.975213,
        store_longitude: -79.6584,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 1.2875658,
        store_longitude: -78.8361612,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.95877,
        store_longitude: -79.6588,
    },
    {
        store_latitdue: 0.956422,
        store_longitude: -79.6621092,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.827476,
        store_longitude: -79.9429,
    },
    {
        store_latitdue: 0.9101104,
        store_longitude: -79.6842202,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8246997,
        store_longitude: -79.949463,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.97053039,
        store_longitude: -79.653244,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8972065,
        store_longitude: -79.7155333,
    },
    {
        store_latitdue: 0.8722526,
        store_longitude: -79.6332445,
        neighborhood: 'SAN MATEO',
    },

    {
        store_latitdue: 0.92282,
        store_longitude: -79.6766,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.919474,
        store_longitude: -79.6851,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.578961,
        store_longitude: -79.6395,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.5791602,
        store_longitude: -79.6391982,
    },
    {
        store_latitdue: 0.5783179,
        store_longitude: -79.6383701,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.5788322,
        store_longitude: -79.6396718,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.5785272,
        store_longitude: -79.638965,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.024368,
        store_longitude: -78.8965247,
    },
    {
        store_latitdue: 0.921743,
        store_longitude: -79.6613,
    },
    {
        store_latitdue: 0.9306134,
        store_longitude: -79.6776981,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0148162,
        store_longitude: -79.5107616,
        neighborhood: 'CAMARONES',
    },
    {
        store_latitdue: 0.903536,
        store_longitude: -79.7097,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.964433388,
        store_longitude: -79.65296498,
    },
    {
        store_latitdue: 0.976562,
        store_longitude: -79.6574,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.95967,
        store_longitude: -79.66281,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9696209,
        store_longitude: -79.6582488,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.6231724,
        store_longitude: -79.907634,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.95814,
        store_longitude: -79.6613,
    },
    {
        store_latitdue: 1.088020205,
        store_longitude: -78.9908218,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.8204637,
        store_longitude: -79.7029505,
        neighborhood: 'TABIAZO',
    },
    {
        store_latitdue: 0.9219914,
        store_longitude: -79.6836428,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.929362,
        store_longitude: -79.6679,
    },
    {
        store_latitdue: 0.926568,
        store_longitude: -79.667,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.925311,
        store_longitude: -79.672,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9222798,
        store_longitude: -79.6832503,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.656236,
        store_longitude: -79.542097,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.7141871,
        store_longitude: -79.5487236,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.6255256,
        store_longitude: -80.0082531,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.8804036,
        store_longitude: -79.8247169,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.917673,
        store_longitude: -79.6969,
    },
    {
        store_latitdue: 0.927814,
        store_longitude: -79.6676085,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.87472,
        store_longitude: -79.8158,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.88857,
        store_longitude: -79.81468,
    },
    {
        store_latitdue: 0.96646735,
        store_longitude: -79.62946208,
    },
    {
        store_latitdue: 0.9264499,
        store_longitude: -79.6674908,
    },
    {
        store_latitdue: 0.7441989,
        store_longitude: -79.8487972,
    },
    {
        store_latitdue: 0.9041559,
        store_longitude: -79.686575,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.054488493,
        store_longitude: -79.2171549,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 1.043758,
        store_longitude: -79.280177,
        neighborhood: 'LAGARTO',
    },

    {
        store_latitdue: 0.977303,
        store_longitude: -79.6612,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: -0.3286422,
        store_longitude: -78.5271713,
    },
    {
        store_latitdue: 0.9722735,
        store_longitude: -79.653856,
    },
    {
        store_latitdue: 0.9210239,
        store_longitude: -79.6825989,
    },
    {
        store_latitdue: 0.894132,
        store_longitude: -79.709254,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.1086224072,
        store_longitude: -79.96409266,
    },
    {
        store_latitdue: 0.915666,
        store_longitude: -79.6912,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0966933,
        store_longitude: -79.1631545,
        neighborhood: 'LA TOLA',
    },
    {
        store_latitdue: 0.9162076,
        store_longitude: -79.6902951,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6107869,
        store_longitude: -80.017481,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.949863,
        store_longitude: -79.6513096,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: -0.343539,
        store_longitude: -78.5607,
    },
    {
        store_latitdue: 0.9525657,
        store_longitude: -79.6562857,
    },
    {
        store_latitdue: 0.9283645,
        store_longitude: -79.6694162,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8896064,
        store_longitude: -79.7214436,
    },
    {
        store_latitdue: 0.8952047,
        store_longitude: -79.6995363,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.9214323,
        store_longitude: -79.6919912,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6154022,
        store_longitude: -80.0226606,
        neighborhood: 'MUISNE',
    },

    {
        store_latitdue: 0.907555,
        store_longitude: -79.6882,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.876044,
        store_longitude: -79.823685,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.962458,
        store_longitude: -79.6581,
    },
    {
        store_latitdue: 0.9269744,
        store_longitude: -79.6694213,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.928205,
        store_longitude: -79.6598,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0506115,
        store_longitude: -79.3107907,
    },
    {
        store_latitdue: 0.9681929,
        store_longitude: -79.6611661,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.981049,
        store_longitude: -79.6565,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.3206074404,
        store_longitude: -79.65425912,
    },
    {
        store_latitdue: 0.8921438,
        store_longitude: -79.7117526,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.4336643,
        store_longitude: -80.0123248,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.61465,
        store_longitude: -80.0149,
    },
    {
        store_latitdue: 0.9101271,
        store_longitude: -79.6814536,
    },
    {
        store_latitdue: 0.9022206,
        store_longitude: -79.7081417,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.897775,
        store_longitude: -79.714812,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.970062,
        store_longitude: -79.6548229,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.950962,
        store_longitude: -79.6505244,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8273898,
        store_longitude: -79.9423015,
    },
    {
        store_latitdue: 0.8800327,
        store_longitude: -79.8156177,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.7169683,
        store_longitude: -79.5514633,
    },
    {
        store_latitdue: 0.6565858,
        store_longitude: -79.5426195,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },

    {
        store_latitdue: 0.931227,
        store_longitude: -79.6776756,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9273346,
        store_longitude: -79.6603608,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9291234,
        store_longitude: -79.6671212,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: -0.208164,
        store_longitude: -79.6803081,
    },

    {
        store_latitdue: 0.61454,
        store_longitude: -80.0147,
    },
    {
        store_latitdue: 0.624708671,
        store_longitude: -79.9081134,
        neighborhood: 'MUISNE',
    },

    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.909151,
        store_longitude: -79.6873992,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.8957735,
        store_longitude: -79.8063334,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.6593871,
        store_longitude: -79.5422171,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.894043,
        store_longitude: -79.7168,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.956169915,
        store_longitude: -79.6527671,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9210616,
        store_longitude: -79.683504,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.282874,
        store_longitude: -78.8335806,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9356422,
        store_longitude: -79.6723358,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.917588,
        store_longitude: -79.696885,
    },
    {
        store_latitdue: 0.917681,
        store_longitude: -79.6967,
    },
    {
        store_latitdue: 0.9145249,
        store_longitude: -79.699916,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.24046,
        store_longitude: -78.7722,
    },
    {
        store_latitdue: 0.956839979,
        store_longitude: -79.62471771,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.97751264,
        store_longitude: -79.6606364,
    },
    {
        store_latitdue: 0.9028989,
        store_longitude: -79.7083976,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.269845,
        store_longitude: -79.9568418,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.9667296,
        store_longitude: -79.6554211,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0738798,
        store_longitude: -79.4151711,
        neighborhood: 'RIOVERDE',
    },
    {
        store_latitdue: 0.91491,
        store_longitude: -79.69223,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9531471,
        store_longitude: -79.6554369,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9312037,
        store_longitude: -79.4346316,
        neighborhood: 'CHONTADURO',
    },
    {
        store_latitdue: 0.9609353,
        store_longitude: -79.6532051,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8897528,
        store_longitude: -79.8080096,
        neighborhood: 'TONSUPA',
    },

    {
        store_latitdue: 0.9537901,
        store_longitude: -79.6539237,
    },
    {
        store_latitdue: -0.9256474,
        store_longitude: -80.4787733,
    },
    {
        store_latitdue: 0.0156176,
        store_longitude: -79.3914,
    },

    {
        store_latitdue: 0.659256,
        store_longitude: -79.5398,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 1.0825781,
        store_longitude: -79.1708797,
        neighborhood: 'LA TOLA',
    },
    {
        store_latitdue: 0.9722965,
        store_longitude: -79.6620455,
    },
    {
        store_latitdue: 1.2846338,
        store_longitude: -78.82915,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9690105,
        store_longitude: -79.6545612,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8804539,
        store_longitude: -79.8124334,
    },
    {
        store_latitdue: 0.87299484,
        store_longitude: -79.828999,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 1.0963969,
        store_longitude: -79.1631775,
    },
    {
        store_latitdue: 0.2665643,
        store_longitude: -79.9535981,
    },
    {
        store_latitdue: 0.9349435,
        store_longitude: -79.6750596,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9278765,
        store_longitude: -79.6600527,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.92913,
        store_longitude: -79.6674,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8443372,
        store_longitude: -79.9263813,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.9827455,
        store_longitude: -79.655375,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.2703561,
        store_longitude: -79.9575363,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.9208552,
        store_longitude: -79.6818697,
    },
    {
        store_latitdue: 0.958432,
        store_longitude: -79.654551,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.6563906,
        store_longitude: -79.5434311,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.7755569,
        store_longitude: -79.5997135,
        neighborhood: 'CHINCA',
    },
    {
        store_latitdue: 0.6145409,
        store_longitude: -80.014213,
        neighborhood: 'MUISNE',
    },

    {
        store_latitdue: 0.98127,
        store_longitude: -79.6536,
    },
    {
        store_latitdue: 0.9182464,
        store_longitude: -79.6987658,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.958036,
        store_longitude: -79.6512,
    },
    {
        store_latitdue: 0.9602788,
        store_longitude: -79.646313,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.874621331,
        store_longitude: -79.820549,
    },
    {
        store_latitdue: 0.8202149,
        store_longitude: -79.7033937,
        neighborhood: 'TABIAZO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.9141371,
        store_longitude: -79.6906978,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9680983,
        store_longitude: -79.6549585,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.046561213,
        store_longitude: -79.4589614,
        neighborhood: 'RIOVERDE',
    },

    {
        store_latitdue: 0.8529044503,
        store_longitude: -79.87440625,
        neighborhood: 'SÚA',
    },
    {
        store_latitdue: 0.9007136,
        store_longitude: -79.7067474,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.870061,
        store_longitude: -79.8472,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8803183,
        store_longitude: -79.8106786,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.8970554,
        store_longitude: -79.7111401,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.289088,
        store_longitude: -78.8361,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.9586558,
        store_longitude: -79.6601674,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.071461997,
        store_longitude: -80.0527038,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.9293484,
        store_longitude: -79.6648495,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.906601,
        store_longitude: -79.6813856,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.3446489,
        store_longitude: -78.126154,
    },
    {
        store_latitdue: 0.9112919,
        store_longitude: -79.6817656,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9076553,
        store_longitude: -79.680436,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8259801,
        store_longitude: -79.943447,
        neighborhood: 'TONCHIGÜE',
    },

    {
        store_latitdue: 1.2890345,
        store_longitude: -78.8414712,
    },
    {
        store_latitdue: 0.3169017464,
        store_longitude: -79.47144995,
    },
    {
        store_latitdue: 0.319031,
        store_longitude: -79.472024,
    },
    {
        store_latitdue: 0.318664,
        store_longitude: -79.472671,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.319362,
        store_longitude: -79.4744,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.319931,
        store_longitude: -79.4752,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3212732,
        store_longitude: -79.4757795,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3228439,
        store_longitude: -79.4765111,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3226912,
        store_longitude: -79.4766897,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3226214,
        store_longitude: -79.4774559,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3230713,
        store_longitude: -79.4755343,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3231096,
        store_longitude: -79.4753535,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3222253,
        store_longitude: -79.474838,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.32243,
        store_longitude: -79.472693,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.322144687,
        store_longitude: -79.4723052,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.321309,
        store_longitude: -79.4717,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3234118,
        store_longitude: -79.4733764,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3238844,
        store_longitude: -79.4738979,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3239321,
        store_longitude: -79.4739523,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3172555,
        store_longitude: -79.4720963,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3218709,
        store_longitude: -79.4759899,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.321026,
        store_longitude: -79.4778701,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3216585,
        store_longitude: -79.4795593,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3221284,
        store_longitude: -79.4787744,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3266575,
        store_longitude: -79.4633005,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.329390108,
        store_longitude: -79.4626464,
    },
    {
        store_latitdue: 0.3292057,
        store_longitude: -79.4618125,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3291947,
        store_longitude: -79.4617644,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3227858,
        store_longitude: -79.4534943,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3289334,
        store_longitude: -79.4591171,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.333027,
        store_longitude: -79.4722,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0710744,
        store_longitude: -80.0457642,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0704919,
        store_longitude: -80.0513648,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0714349,
        store_longitude: -80.0450455,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0715878,
        store_longitude: -80.0510018,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.072242,
        store_longitude: -80.052306,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0730272,
        store_longitude: -80.0513463,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0730973,
        store_longitude: -80.0494,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.072227,
        store_longitude: -80.055403,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.072676,
        store_longitude: -80.048741,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.071554869,
        store_longitude: -80.0451507,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.31801,
        store_longitude: -79.46978,
    },
    {
        store_latitdue: 0.324386,
        store_longitude: -79.4731,
    },

    {
        store_latitdue: 0.3298652,
        store_longitude: -79.4724003,
    },
    {
        store_latitdue: 0.3289177,
        store_longitude: -79.4738635,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3252543,
        store_longitude: -79.4736828,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3243868,
        store_longitude: -79.4745211,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3298891,
        store_longitude: -79.4758069,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.330338,
        store_longitude: -79.4755203,
    },
    {
        store_latitdue: 0.3283109,
        store_longitude: -79.4730293,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.335385,
        store_longitude: -79.4759838,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3287002,
        store_longitude: -79.4751685,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3153325,
        store_longitude: -79.4663888,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3015178,
        store_longitude: -79.456641,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.301731,
        store_longitude: -79.4561,
    },
    {
        store_latitdue: 0.315227,
        store_longitude: -79.4659,
    },
    {
        store_latitdue: 0.0739664,
        store_longitude: -80.0371319,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.075248,
        store_longitude: -80.035727,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0736573,
        store_longitude: -80.0463754,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0730366,
        store_longitude: -80.0455257,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0727219,
        store_longitude: -80.0469,
    },
    {
        store_latitdue: 0.072801503,
        store_longitude: -80.04693255,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.070166,
        store_longitude: -80.0507613,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0738344,
        store_longitude: -80.0493952,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0737288,
        store_longitude: -80.0498811,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.3203841,
        store_longitude: -79.4711319,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0741303,
        store_longitude: -80.0460004,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0675208,
        store_longitude: -80.059461,
    },
    {
        store_latitdue: 0.072185,
        store_longitude: -80.055654,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.325979612,
        store_longitude: -79.4741642,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.9657042,
        store_longitude: -79.6602417,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9130485,
        store_longitude: -79.6898532,
    },
    {
        store_latitdue: 0.9152673,
        store_longitude: -79.6928165,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8880884,
        store_longitude: -79.8091495,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.0799943,
        store_longitude: -80.054,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0753222,
        store_longitude: -80.0503365,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.6112651,
        store_longitude: -80.0169606,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.969863,
        store_longitude: -79.6527,
    },
    {
        store_latitdue: 0.8700749,
        store_longitude: -79.8475689,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.6274136,
        store_longitude: -80.0066866,
    },
    {
        store_latitdue: 0.91408,
        store_longitude: -79.6933,
    },
    {
        store_latitdue: 0.9726026772,
        store_longitude: -79.6547969575,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.96939,
        store_longitude: -79.6552,
    },
    {
        store_latitdue: 0.9085992,
        store_longitude: -79.6829782,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0974339,
        store_longitude: -79.1610712,
    },
    {
        store_latitdue: 0.827994,
        store_longitude: -79.9455,
    },
    {
        store_latitdue: 0.82685916,
        store_longitude: -79.944509,
        neighborhood: 'TONCHIGÜE',
    },

    {
        store_latitdue: 0.9727432,
        store_longitude: -79.6668084,
    },
    {
        store_latitdue: 0.962853,
        store_longitude: -79.6563,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9339226,
        store_longitude: -79.6734334,
    },
    {
        store_latitdue: 0.826776,
        store_longitude: -79.9444,
        neighborhood: 'TONCHIGÜE',
    },

    {
        store_latitdue: 0.929808,
        store_longitude: -79.6731017,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.929265,
        store_longitude: -79.6757,
    },
    {
        store_latitdue: 0.935418,
        store_longitude: -79.6774,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.926282048,
        store_longitude: -79.6759948,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.927396,
        store_longitude: -79.6745,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8168047,
        store_longitude: -80.046601,
        neighborhood: 'GALERA',
    },
    {
        store_latitdue: -0.816805,
        store_longitude: -80.046688,
    },

    {
        store_latitdue: 0.814719,
        store_longitude: -80.0472,
    },
    {
        store_latitdue: 0.9280514,
        store_longitude: -79.675958,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.4669288,
        store_longitude: -79.5366158,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.4662476,
        store_longitude: -79.5374345,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.4649765,
        store_longitude: -79.5383553,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.827333,
        store_longitude: -79.9451675,
    },
    {
        store_latitdue: 0.9735708,
        store_longitude: -79.6689954,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9625457,
        store_longitude: -79.652565,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8940916,
        store_longitude: -79.6652703,
        neighborhood: 'VUELTA LARGA',
    },
    {
        store_latitdue: 0.8112044,
        store_longitude: -79.8648629,
    },
    {
        store_latitdue: 0.4804101,
        store_longitude: -79.5369255,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.9340466,
        store_longitude: -79.674264,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9278944,
        store_longitude: -79.6749999,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8145486,
        store_longitude: -79.6026205,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.9801540752,
        store_longitude: -79.6562081203,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8757113,
        store_longitude: -79.6353067,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.9594128,
        store_longitude: -79.6536906,
    },
    {
        store_latitdue: -0.2027467505,
        store_longitude: -78.3306094632,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: 1.2793671,
        store_longitude: -78.8161836,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2810419,
        store_longitude: -78.8419726,
    },
    {
        store_latitdue: 0.828879,
        store_longitude: -79.9437,
    },
    {
        store_latitdue: 0.9297812,
        store_longitude: -79.4352919,
    },
    {
        store_latitdue: -2.7424692,
        store_longitude: -78.8474803,
    },
    {
        store_latitdue: 1.069022656,
        store_longitude: -79.41252168,
    },
    {
        store_latitdue: 1.2627903,
        store_longitude: -78.7945939,
    },
    {
        store_latitdue: 1.2404871,
        store_longitude: -78.7716065,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.2400718,
        store_longitude: -78.7693709,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.7735679,
        store_longitude: -80.0883225,
        neighborhood: 'GALERA',
    },
    {
        store_latitdue: 0.8706751,
        store_longitude: -79.6325989,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 1.06762,
        store_longitude: -79.4141,
    },
    {
        store_latitdue: 0.8767557,
        store_longitude: -79.6381549,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 1.0884496,
        store_longitude: -78.9915458,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.0555355,
        store_longitude: -78.9825795,
    },
    {
        store_latitdue: 1.042392,
        store_longitude: -78.9211,
    },
    {
        store_latitdue: 1.0704169,
        store_longitude: -78.9089412,
    },
    {
        store_latitdue: 1.0706776,
        store_longitude: -78.9096314,
        neighborhood: 'MALDONADO',
    },
    {
        store_latitdue: 1.070381,
        store_longitude: -78.9099,
    },
    {
        store_latitdue: 1.06782,
        store_longitude: -78.9057,
    },

    {
        store_latitdue: 1.0966445,
        store_longitude: -79.1632453,
    },
    {
        store_latitdue: 1.1528244,
        store_longitude: -78.8262487,
        neighborhood: 'CONCEPCIÓN',
    },
    {
        store_latitdue: 1.0188245,
        store_longitude: -78.9087594,
    },
    {
        store_latitdue: 1.0200799,
        store_longitude: -78.9092904,
        neighborhood: 'COLÓN ELOY DEL MARÍA',
    },
    {
        store_latitdue: -2.26913,
        store_longitude: -79.8865,
    },

    {
        store_latitdue: 1.0199419,
        store_longitude: -78.9093718,
        neighborhood: 'COLÓN ELOY DEL MARÍA',
    },
    {
        store_latitdue: 0.985634,
        store_longitude: -78.8954,
        neighborhood: 'COLÓN ELOY DEL MARÍA',
    },
    {
        store_latitdue: 0.9309147,
        store_longitude: -78.8607121,
        neighborhood: 'SELVA ALEGRE',
    },
    {
        store_latitdue: 0.958543,
        store_longitude: -78.865441,
        neighborhood: 'TIMBIRÉ',
    },
    {
        store_latitdue: 0.979334,
        store_longitude: -78.8907,
    },
    {
        store_latitdue: 0.961159,
        store_longitude: -78.8666,
        neighborhood: 'TIMBIRÉ',
    },
    {
        store_latitdue: 0.8351116,
        store_longitude: -79.6253863,
        neighborhood: 'SAN MATEO',
    },

    {
        store_latitdue: 1.0667717,
        store_longitude: -79.4150177,
    },
    {
        store_latitdue: 0.867354,
        store_longitude: -79.630814,
    },
    {
        store_latitdue: 0.8881363,
        store_longitude: -79.6315549,
    },
    {
        store_latitdue: 1.0959403,
        store_longitude: -79.1634788,
        neighborhood: 'LA TOLA',
    },
    {
        store_latitdue: 1.0494393,
        store_longitude: -79.3130823,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 0.9858486,
        store_longitude: -79.3197497,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 1.285627722,
        store_longitude: -78.8347778,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.27933,
        store_longitude: -78.8231,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.0534325,
        store_longitude: -78.9804638,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.0667333333333,
        store_longitude: -79.376656666667,
        neighborhood: 'ROCAFUERTE',
    },
    {
        store_latitdue: 1.08751,
        store_longitude: -78.9889643,
        neighborhood: 'BORBÓN',
    },

    {
        store_latitdue: 1.2897897,
        store_longitude: -78.8369293,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.281683,
        store_longitude: -78.8289,
    },
    {
        store_latitdue: 1.0681819,
        store_longitude: -79.4149531,
    },
    {
        store_latitdue: 0.9779501,
        store_longitude: -79.6570196,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.08502,
        store_longitude: -78.9986,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.281789,
        store_longitude: -78.8288,
    },
    {
        store_latitdue: 1.0851072,
        store_longitude: -78.9964757,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.9275504,
        store_longitude: -79.6622947,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9733403,
        store_longitude: -79.6559309,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.2843973,
        store_longitude: -78.8332943,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.0543096,
        store_longitude: -78.9801642,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 0.9792468,
        store_longitude: -79.6550892,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.929696,
        store_longitude: -79.6671,
    },
    {
        store_latitdue: 1.0897658,
        store_longitude: -78.9865925,
        neighborhood: 'BORBÓN',
    },

    {
        store_latitdue: 1.28299,
        store_longitude: -78.8313,
    },
    {
        store_latitdue: 1.28228,
        store_longitude: -78.8235,
    },
    {
        store_latitdue: 1.284,
        store_longitude: -78.8328,
    },

    {
        store_latitdue: 1.28151,
        store_longitude: -78.831,
    },

    {
        store_latitdue: 0.96081,
        store_longitude: -79.6550767,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9302058,
        store_longitude: -79.6586531,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9144132,
        store_longitude: -79.6839884,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9150132,
        store_longitude: -79.683745,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.3089998385,
        store_longitude: -79.66098946,
    },

    {
        store_latitdue: 1.03524,
        store_longitude: -79.4759,
        neighborhood: 'RIOVERDE',
    },
    {
        store_latitdue: 0.930371,
        store_longitude: -79.662,
    },
    {
        store_latitdue: 0.956801,
        store_longitude: -79.630673,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.9284184,
        store_longitude: -79.6737715,
    },
    {
        store_latitdue: 0.91071,
        store_longitude: -79.68309,
    },
    {
        store_latitdue: 0.934914,
        store_longitude: -79.6758,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.966545,
        store_longitude: -79.6546,
    },
    {
        store_latitdue: 0.9912809,
        store_longitude: -79.6235997,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.935258,
        store_longitude: -79.6758,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9623808,
        store_longitude: -79.6292016,
        neighborhood: 'TACHINA',
    },
    {
        store_latitdue: 0.912169,
        store_longitude: -79.6857,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.93422665,
        store_longitude: -79.6737618,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.969603,
        store_longitude: -79.6581,
    },

    {
        store_latitdue: 0.9687525,
        store_longitude: -79.6557368,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9288449,
        store_longitude: -79.675422,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.964698,
        store_longitude: -79.6558,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.96147,
        store_longitude: -79.62756,
    },
    {
        store_latitdue: 0.2754355,
        store_longitude: -79.9493795,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.8908932,
        store_longitude: -79.7125204,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.2760066313,
        store_longitude: -79.94807527,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.8709751,
        store_longitude: -79.8366339,
    },
    {
        store_latitdue: 0.4937679,
        store_longitude: -79.9724907,
        neighborhood: 'SAN GREGORIO',
    },
    {
        store_latitdue: 0.559421,
        store_longitude: -79.9511086,
        neighborhood: 'SAN GREGORIO',
    },
    {
        store_latitdue: 0.323722649,
        store_longitude: -79.9633777,
        neighborhood: 'SÁLIMA',
    },
    {
        store_latitdue: 0.4168466,
        store_longitude: -80.0023237,
    },
    {
        store_latitdue: 0.4061745765,
        store_longitude: -80.01325574,
    },
    {
        store_latitdue: 0.8728775,
        store_longitude: -79.8350899,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8730626,
        store_longitude: -79.8363051,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8740656,
        store_longitude: -79.8342521,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.868516,
        store_longitude: -79.8377,
    },
    {
        store_latitdue: 0.9722228,
        store_longitude: -79.6549497,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8724002,
        store_longitude: -79.8223162,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.87123,
        store_longitude: -79.8374,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.8938634,
        store_longitude: -79.7105707,
    },
    {
        store_latitdue: 0.267989,
        store_longitude: -79.956072,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.8817661,
        store_longitude: -79.8099018,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.4462994,
        store_longitude: -80.0429305,
        neighborhood: 'BOLÍVAR',
    },

    {
        store_latitdue: 0.8706275873,
        store_longitude: -79.8381053245,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.4579482,
        store_longitude: -80.0058471,
    },
    {
        store_latitdue: 0.3256272,
        store_longitude: -79.9632206,
        neighborhood: 'SÁLIMA',
    },
    {
        store_latitdue: 0.8703437,
        store_longitude: -79.8474252,
    },
    {
        store_latitdue: -0.26859,
        store_longitude: -79.9558,
    },
    {
        store_latitdue: -0.239332,
        store_longitude: -79.1645,
    },
    {
        store_latitdue: 0.876855,
        store_longitude: -79.8266,
    },
    {
        store_latitdue: 0.890631,
        store_longitude: -79.712464,
    },
    {
        store_latitdue: 0.324644,
        store_longitude: -79.9650162,
    },
    {
        store_latitdue: 0.9069538,
        store_longitude: -79.6915572,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8845823,
        store_longitude: -79.8129716,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.963498,
        store_longitude: -79.6550674,
    },
    {
        store_latitdue: 0.4040974,
        store_longitude: -79.9781284,
        neighborhood: 'DAULE',
    },
    {
        store_latitdue: 0.8682582,
        store_longitude: -79.8205216,
    },
    {
        store_latitdue: 0.872794,
        store_longitude: -79.8338,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.872023,
        store_longitude: -79.8344,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.964325,
        store_longitude: -79.6522264,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.2697348,
        store_longitude: -79.9570755,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.966303,
        store_longitude: -79.6508,
    },
    {
        store_latitdue: 0.3823503,
        store_longitude: -79.9652524,
        neighborhood: 'DAULE',
    },
    {
        store_latitdue: 0.8809221,
        store_longitude: -79.8207297,
    },
    {
        store_latitdue: 0.228074,
        store_longitude: -79.8837,
        neighborhood: 'COJIMÍES',
    },
    {
        store_latitdue: 0.870996,
        store_longitude: -79.8487837,
    },
    {
        store_latitdue: 0.883544,
        store_longitude: -79.818,
    },
    {
        store_latitdue: 0.1560815,
        store_longitude: -79.9371442,
    },
    {
        store_latitdue: 0.267223,
        store_longitude: -79.9529174,
    },
    {
        store_latitdue: 0.134914988,
        store_longitude: -79.9859511,
        neighborhood: 'COJIMÍES',
    },
    {
        store_latitdue: 0.1347231,
        store_longitude: -79.9860718,
        neighborhood: 'COJIMÍES',
    },
    {
        store_latitdue: 0.140664,
        store_longitude: -79.9803,
        neighborhood: 'COJIMÍES',
    },
    {
        store_latitdue: 0.1569325,
        store_longitude: -79.9399534,
    },
    {
        store_latitdue: 0.228564,
        store_longitude: -79.883022,
        neighborhood: 'COJIMÍES',
    },
    {
        store_latitdue: -0.1831429,
        store_longitude: -79.9165626,
    },
    {
        store_latitdue: 0.871454,
        store_longitude: -79.8351,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.1785482,
        store_longitude: -79.9196251,
        neighborhood: 'COJIMÍES',
    },
    {
        store_latitdue: 0.446812601,
        store_longitude: -80.0432599336,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.2754052676,
        store_longitude: -79.9493989,
        neighborhood: 'SAN JOSÉ DE CHAMANGA',
    },
    {
        store_latitdue: 0.331906,
        store_longitude: -79.471825,
    },
    {
        store_latitdue: 0.6125827,
        store_longitude: -80.0157454,
    },
    {
        store_latitdue: 0.6254661,
        store_longitude: -80.0083532,
        neighborhood: 'MUISNE',
    },

    {
        store_latitdue: 0.6377427,
        store_longitude: -80.0421208,
        neighborhood: 'SAN FRANCISCO',
    },
    {
        store_latitdue: 0.6375591,
        store_longitude: -80.0435541,
        neighborhood: 'SAN FRANCISCO',
    },

    {
        store_latitdue: 0.578515,
        store_longitude: -79.6389,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.655633843,
        store_longitude: -79.54262054,
    },
    {
        store_latitdue: 0.8649589,
        store_longitude: -79.8562338,
        neighborhood: 'ATACAMES',
    },

    {
        store_latitdue: 0.7957947,
        store_longitude: -79.9386773,
    },
    {
        store_latitdue: -0.1895735,
        store_longitude: -78.4723915,
    },
    {
        store_latitdue: 0.8511049,
        store_longitude: -79.8765136,
        neighborhood: 'SÚA',
    },
    {
        store_latitdue: 0.6561066,
        store_longitude: -79.5426047,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.869965,
        store_longitude: -79.8459,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.7957833,
        store_longitude: -79.938937,
    },
    {
        store_latitdue: 0.6518306,
        store_longitude: -79.5432579,
    },
    {
        store_latitdue: 0.6551215,
        store_longitude: -79.5433532,
    },
    {
        store_latitdue: -1.014844032,
        store_longitude: -79.47088755,
    },
    {
        store_latitdue: 0.6288191,
        store_longitude: -80.0090571,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.6742129,
        store_longitude: -79.540145,
    },
    {
        store_latitdue: 0.0721595,
        store_longitude: -80.0427666,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: -2.71247,
        store_longitude: -80.2555,
    },
    {
        store_latitdue: 0.0715772,
        store_longitude: -80.0443742,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0724625,
        store_longitude: -80.0467477,
        neighborhood: 'PEDERNALES',
    },

    {
        store_latitdue: 0.0758871,
        store_longitude: -80.0363329,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0786917,
        store_longitude: -80.0377302,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0718593,
        store_longitude: -80.0467791,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0709255,
        store_longitude: -80.0525645,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0710976,
        store_longitude: -80.0425188,
    },
    {
        store_latitdue: 0.3273957,
        store_longitude: -79.4724921,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3299359,
        store_longitude: -79.4722752,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.325704,
        store_longitude: -79.472858,
    },
    {
        store_latitdue: 0.2256959,
        store_longitude: -79.4126372,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3012998,
        store_longitude: -79.4566792,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3118862,
        store_longitude: -79.463522,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.311068,
        store_longitude: -79.4677,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3101853,
        store_longitude: -79.4661779,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3244202,
        store_longitude: -79.472126,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3267105,
        store_longitude: -79.4679768,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.328739,
        store_longitude: -79.4668028,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3281524,
        store_longitude: -79.4652014,
        neighborhood: 'ROSA ZÁRATE',
    },

    {
        store_latitdue: 0.0522917,
        store_longitude: -79.9728943,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0714679,
        store_longitude: -80.038503,
    },
    {
        store_latitdue: 0.076321,
        store_longitude: -80.03432,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0740942,
        store_longitude: -80.0511,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0779592,
        store_longitude: -80.0334103,
    },
    {
        store_latitdue: 0.0731354,
        store_longitude: -80.0389025,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0718967,
        store_longitude: -80.0515667,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0685286,
        store_longitude: -80.0539706,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0674938,
        store_longitude: -80.0538,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0686883,
        store_longitude: -80.05353,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.32946429,
        store_longitude: -79.4726994,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.324524105,
        store_longitude: -79.47366333,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.307636,
        store_longitude: -79.4719765,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3065487,
        store_longitude: -79.4719805,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3077983,
        store_longitude: -79.4696417,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3155495,
        store_longitude: -79.4728682,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3247299,
        store_longitude: -79.4645009,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.327973,
        store_longitude: -79.466682,
    },
    {
        store_latitdue: 0.3013275,
        store_longitude: -79.457357,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0761497,
        store_longitude: -80.036017,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0660184,
        store_longitude: -80.0549986,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.3073862,
        store_longitude: -79.4709783,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.314544,
        store_longitude: -79.4646662,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.324628,
        store_longitude: -79.4607,
    },
    {
        store_latitdue: 0.3247307,
        store_longitude: -79.4613525,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3229012,
        store_longitude: -79.4680544,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.074234,
        store_longitude: -80.036069,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.06849,
        store_longitude: -80.0589166,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.3271237,
        store_longitude: -79.4532734,
    },
    {
        store_latitdue: 0.30161,
        store_longitude: -79.275649,
    },
    {
        store_latitdue: 0.897296,
        store_longitude: -79.712,
    },

    {
        store_latitdue: 0.901519,
        store_longitude: -79.70727,
    },
    {
        store_latitdue: 0.901625,
        store_longitude: -79.7071,
    },
    {
        store_latitdue: 0.962039,
        store_longitude: -79.6584,
    },
    {
        store_latitdue: 0.9600518,
        store_longitude: -79.6595797,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.960295615,
        store_longitude: -79.6531641,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8940056,
        store_longitude: -79.7128639,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9079482,
        store_longitude: -79.6846385,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.981857326,
        store_longitude: -79.6573913,
    },
    {
        store_latitdue: 0.98298218,
        store_longitude: -79.65185144,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.983159,
        store_longitude: -79.6582,
    },
    {
        store_latitdue: 0.896755,
        store_longitude: -79.7084,
    },
    {
        store_latitdue: 0.980468,
        store_longitude: -79.6584,
    },
    {
        store_latitdue: 0.92807,
        store_longitude: -79.67196,
    },

    {
        store_latitdue: 1.0812987,
        store_longitude: -79.0312959,
        neighborhood: 'BORBÓN',
    },
    {
        store_latitdue: 1.0486803,
        store_longitude: -79.0767867,
        neighborhood: 'ANCHAYACU',
    },
    {
        store_latitdue: 1.04877358,
        store_longitude: -79.07654306,
        neighborhood: 'ANCHAYACU',
    },

    {
        store_latitdue: 1.0486834,
        store_longitude: -79.077181,
        neighborhood: 'ANCHAYACU',
    },
    {
        store_latitdue: 1.0946428,
        store_longitude: -79.1632938,
        neighborhood: 'LA TOLA',
    },
    {
        store_latitdue: 1.09705,
        store_longitude: -79.1616,
        neighborhood: 'LA TOLA',
    },

    {
        store_latitdue: 1.0574238,
        store_longitude: -79.2097695,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 0.3522239403,
        store_longitude: -79.20979962,
    },

    {
        store_latitdue: 1.0975823,
        store_longitude: -79.1608054,
        neighborhood: 'LA TOLA',
    },

    {
        store_latitdue: 0.9822903,
        store_longitude: -79.6550127,
    },

    {
        store_latitdue: 0.9651897,
        store_longitude: -79.6542868,
    },
    {
        store_latitdue: 0.9090828,
        store_longitude: -79.6860558,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0487056167,
        store_longitude: -79.0763768926,
        neighborhood: 'ANCHAYACU',
    },

    {
        store_latitdue: 0.973046,
        store_longitude: -79.652,
    },
    {
        store_latitdue: 0.8918078,
        store_longitude: -79.7085529,
    },
    {
        store_latitdue: 0.90846,
        store_longitude: -79.68845,
    },

    {
        store_latitdue: 0.911462,
        store_longitude: -79.6841,
    },
    {
        store_latitdue: 0.8995157,
        store_longitude: -79.7094575,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9642086,
        store_longitude: -79.6585199,
    },

    {
        store_latitdue: 0.9335333,
        store_longitude: -79.6759852,
    },

    {
        store_latitdue: 0.9305336,
        store_longitude: -79.4341648,
    },
    {
        store_latitdue: 0.9072305,
        store_longitude: -79.6872263,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.0756318,
        store_longitude: -80.0472987,
    },
    {
        store_latitdue: 0.9605356922,
        store_longitude: -79.66014271,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.930301,
        store_longitude: -79.672291,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.9720524,
        store_longitude: -79.6548201,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.891022,
        store_longitude: -79.7128,
    },
    {
        store_latitdue: 0.9193927,
        store_longitude: -79.6807958,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.919101,
        store_longitude: -79.6805638,
    },
    {
        store_latitdue: 0.8814081,
        store_longitude: -79.8121937,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 0.87912,
        store_longitude: -79.817,
    },
    {
        store_latitdue: 0.877162,
        store_longitude: -79.826,
    },
    {
        store_latitdue: 0.9725203,
        store_longitude: -79.654944,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.0421594,
        store_longitude: -79.2789092,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 1.0441788,
        store_longitude: -79.2872784,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 1.04914831,
        store_longitude: -79.31282508,
        neighborhood: 'MONTALVO',
    },
    {
        store_latitdue: 0.9205229,
        store_longitude: -79.6826645,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 1.06702,
        store_longitude: -79.4152,
    },
    {
        store_latitdue: 0.8782611,
        store_longitude: -79.8140349,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 1.2884872901,
        store_longitude: -78.835034743,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.0571438,
        store_longitude: -79.2760695,
        neighborhood: 'LAGARTO',
    },
    {
        store_latitdue: 0.9183214137,
        store_longitude: -79.6749682393,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.91977731,
        store_longitude: -79.68179449,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.8780955,
        store_longitude: -79.818902,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 1.28565,
        store_longitude: -78.8294,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.290125,
        store_longitude: -78.83786,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 1.0417582,
        store_longitude: -79.2788087,
    },
    {
        store_latitdue: 0.897685,
        store_longitude: -79.804316,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: 1.2852667,
        store_longitude: -78.8345785,
        neighborhood: 'SAN LORENZO',
    },

    {
        store_latitdue: 1.2833319,
        store_longitude: -78.8401274,
        neighborhood: 'SAN LORENZO',
    },
    {
        store_latitdue: 0.8811,
        store_longitude: -79.813997,
    },
    {
        store_latitdue: 1.285468,
        store_longitude: -78.834728,
    },
    {
        store_latitdue: 0.9568364,
        store_longitude: -79.6527688,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.933982,
        store_longitude: -79.6596,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.7231771,
        store_longitude: -79.9196013,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.5777723,
        store_longitude: -79.6372297,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.86513,
        store_longitude: -79.8563,
    },
    {
        store_latitdue: 0.7959797,
        store_longitude: -79.9385907,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.6158293,
        store_longitude: -80.0138414,
    },
    {
        store_latitdue: 0.741248,
        store_longitude: -79.5829,
        neighborhood: 'CHINCA',
    },

    {
        store_latitdue: 0.8465047,
        store_longitude: -79.9255801,
        neighborhood: 'TONCHIGÜE',
    },
    {
        store_latitdue: 0.6591795,
        store_longitude: -79.539554,
    },
    {
        store_latitdue: 0.8675263506,
        store_longitude: -79.8468823358,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.6102648577,
        store_longitude: -80.0144129949,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.65512833333333,
        store_longitude: -79.542666666667,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.9270359,
        store_longitude: -79.6669115,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.811465,
        store_longitude: -79.8666107,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.7438287,
        store_longitude: -79.849113,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.743911,
        store_longitude: -79.8488,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.7949058,
        store_longitude: -79.8656501,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.914091,
        store_longitude: -79.6858,
    },
    {
        store_latitdue: 0.920659,
        store_longitude: -79.6851,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.9210402,
        store_longitude: -79.6854935,
    },
    {
        store_latitdue: 0.9184081,
        store_longitude: -79.6902607,
    },
    {
        store_latitdue: 0.6540727,
        store_longitude: -79.5432417,
        neighborhood: 'VICHE',
    },
    {
        store_latitdue: 0.6183892,
        store_longitude: -80.0125874,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.925758,
        store_longitude: -79.6693,
    },
    {
        store_latitdue: 0.8577016,
        store_longitude: -79.8767464,
        neighborhood: 'SÚA',
    },
    {
        store_latitdue: 0.6537991,
        store_longitude: -80.0673516,
    },
    {
        store_latitdue: 0.6371789,
        store_longitude: -80.0441539,
        neighborhood: 'SAN FRANCISCO',
    },
    {
        store_latitdue: 0.6094086,
        store_longitude: -80.0174073,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.8104207,
        store_longitude: -79.8672743,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.6524683,
        store_longitude: -80.0666515,
        neighborhood: 'SAN FRANCISCO',
    },
    {
        store_latitdue: 0.9164882,
        store_longitude: -79.6858381,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.8897237,
        store_longitude: -79.6329345,
        neighborhood: 'SAN MATEO',
    },
    {
        store_latitdue: 0.9210688,
        store_longitude: -79.6811928,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.62475,
        store_longitude: -80.0089,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.3047375905,
        store_longitude: -79.69187986,
    },
    {
        store_latitdue: 0.92409,
        store_longitude: -79.6897,
    },

    {
        store_latitdue: 0.9200227,
        store_longitude: -79.6875638,
    },
    {
        store_latitdue: 0.328545,
        store_longitude: -79.462861,
    },
    {
        store_latitdue: 0.3322865,
        store_longitude: -79.4726657,
    },
    {
        store_latitdue: 0.33127,
        store_longitude: -79.4734,
        neighborhood: 'ROSA ZÁRATE',
    },

    {
        store_latitdue: 0.3267099,
        store_longitude: -79.4679728,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.322975,
        store_longitude: -79.4378,
    },
    {
        store_latitdue: 0.315333,
        store_longitude: -79.4666763,
        neighborhood: 'ROSA ZÁRATE',
    },

    {
        store_latitdue: 0.2183266,
        store_longitude: -80.0295557,
        neighborhood: 'COJIMÍES',
    },
    {
        store_latitdue: 0.2181474,
        store_longitude: -80.0295764,
        neighborhood: 'COJIMÍES',
    },
    {
        store_latitdue: 0.0769813,
        store_longitude: -80.0397729,
    },
    {
        store_latitdue: 0.0721844,
        store_longitude: -80.0516,
    },
    {
        store_latitdue: 0.074185,
        store_longitude: -80.0485269,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0766788,
        store_longitude: -80.0350215,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0759409,
        store_longitude: -80.0429435,
        neighborhood: 'PEDERNALES',
    },

    {
        store_latitdue: 0.3297766,
        store_longitude: -79.4757764,
    },
    {
        store_latitdue: 0.333917,
        store_longitude: -79.4781207,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3163239,
        store_longitude: -79.471754,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.320773,
        store_longitude: -79.4792,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.322704,
        store_longitude: -79.4766,
    },
    {
        store_latitdue: 0.3229629,
        store_longitude: -79.4383529,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3282208,
        store_longitude: -79.45985213,
    },

    {
        store_latitdue: 0.330545,
        store_longitude: -79.4615,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3305497,
        store_longitude: -79.4616895,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3293963,
        store_longitude: -79.4626463,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.330096,
        store_longitude: -79.4649,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.330703,
        store_longitude: -79.467,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0785484,
        store_longitude: -80.0414997,
    },
    {
        store_latitdue: 0.0742662,
        store_longitude: -80.0450688,
    },
    {
        store_latitdue: 0.0742386,
        store_longitude: -80.0484455,
    },
    {
        store_latitdue: 0.071755,
        store_longitude: -80.0519,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0704789,
        store_longitude: -80.0552304,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.335379,
        store_longitude: -79.4697,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3193983,
        store_longitude: -79.4748117,
    },
    {
        store_latitdue: 0.3346738,
        store_longitude: -79.475356,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3312726,
        store_longitude: -79.4733063,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3195825,
        store_longitude: -79.4744227,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3204305,
        store_longitude: -79.4796079,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.317428,
        store_longitude: -79.471697,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3215856,
        store_longitude: -79.478518,
    },
    {
        store_latitdue: 0.3218215,
        store_longitude: -79.4729908,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.330154,
        store_longitude: -79.463605,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.329758,
        store_longitude: -79.4677,
    },
    {
        store_latitdue: 0.331207,
        store_longitude: -79.4671,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.329296,
        store_longitude: -79.4612,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.07502,
        store_longitude: -80.04596,
    },
    {
        store_latitdue: -0.2753309,
        store_longitude: -79.4635327,
    },
    {
        store_latitdue: 0.075685,
        store_longitude: -80.0477667,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.3248248,
        store_longitude: -79.4647663,
    },
    {
        store_latitdue: 0.3233218,
        store_longitude: -79.4752496,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.324992,
        store_longitude: -79.4519,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0748528,
        store_longitude: -80.0473576,
    },
    {
        store_latitdue: 0.0734281,
        store_longitude: -80.0513752,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.142852,
        store_longitude: -79.401,
    },

    {
        store_latitdue: 0.9136745,
        store_longitude: -79.6891382,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.8785049,
        store_longitude: -79.8211521,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.557903,
        store_longitude: -79.562,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.5577453,
        store_longitude: -79.5615396,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.9491,
        store_longitude: -79.65036,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.57768,
        store_longitude: -79.6403,
    },
    {
        store_latitdue: 0.4650748,
        store_longitude: -79.5383526,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.494844,
        store_longitude: -79.9697999,
        neighborhood: 'SAN GREGORIO',
    },

    {
        store_latitdue: 0.583343,
        store_longitude: -79.5666,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.715486,
        store_longitude: -79.5334,
    },
    {
        store_latitdue: 0.7184142,
        store_longitude: -79.5647285,
    },
    {
        store_latitdue: 0.8661484,
        store_longitude: -79.8503824,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9653561,
        store_longitude: -79.4196839,
        neighborhood: 'CHONTADURO',
    },
    {
        store_latitdue: 0.9653701,
        store_longitude: -79.4201398,
        neighborhood: 'CHONTADURO',
    },
    {
        store_latitdue: 0.578367,
        store_longitude: -79.6385,
    },
    {
        store_latitdue: 0.5788183,
        store_longitude: -79.6396792,
        neighborhood: 'CUBE',
    },
    {
        store_latitdue: 0.811231,
        store_longitude: -79.6105,
    },
    {
        store_latitdue: 0.6075492674,
        store_longitude: -79.9077513629,
        neighborhood: 'MUISNE',
    },
    {
        store_latitdue: 0.9314238,
        store_longitude: -79.4365282,
        neighborhood: 'CHONTADURO',
    },

    {
        store_latitdue: 0.4853845,
        store_longitude: -80.0428499,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.8688843,
        store_longitude: -79.8477155,
        neighborhood: 'ATACAMES',
    },
    {
        store_latitdue: 0.9299875609,
        store_longitude: -79.4345172395,
        neighborhood: 'CHONTADURO',
    },
    {
        store_latitdue: 0.9306538,
        store_longitude: -79.4345216,
        neighborhood: 'CHONTADURO',
    },
    {
        store_latitdue: 0.950077,
        store_longitude: -79.6581,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.5613255,
        store_longitude: -79.9525181,
        neighborhood: 'SAN GREGORIO',
    },

    {
        store_latitdue: 1.03246,
        store_longitude: -79.4279,
        neighborhood: 'RIOVERDE',
    },
    {
        store_latitdue: 0.94842,
        store_longitude: -79.6505,
        neighborhood: 'ESMERALDAS',
    },

    {
        store_latitdue: 0.718035,
        store_longitude: -79.5646,
        neighborhood: 'CHINCA',
    },

    {
        store_latitdue: 0.49432299,
        store_longitude: -79.9749524,
    },
    {
        store_latitdue: 0.88006,
        store_longitude: -79.8156,
    },
    {
        store_latitdue: 0.9306293,
        store_longitude: -79.4358284,
        neighborhood: 'CHONTADURO',
    },
    {
        store_latitdue: 0.95149,
        store_longitude: -79.6503159,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.373993,
        store_longitude: -79.48996,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.374012,
        store_longitude: -79.490014,
    },
    {
        store_latitdue: 0.973281,
        store_longitude: -79.6618,
    },
    {
        store_latitdue: 0.371722,
        store_longitude: -79.4906,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 1.0716264,
        store_longitude: -79.4116343,
        neighborhood: 'RIOVERDE',
    },
    {
        store_latitdue: 0.373897282,
        store_longitude: -79.4896781,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.3282263,
        store_longitude: -79.4675707,
    },
    {
        store_latitdue: -0.8977452,
        store_longitude: -79.4904127,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0239333,
        store_longitude: -79.4676366,
    },
    {
        store_latitdue: -1.0483675,
        store_longitude: -79.4670634,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8718801,
        store_longitude: -79.485398,
    },
    {
        store_latitdue: -1.0512617,
        store_longitude: -79.6295406,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.895466,
        store_longitude: -79.4893,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.05135,
        store_longitude: -79.4717,
    },
    {
        store_latitdue: -1.04572,
        store_longitude: -79.4782133,
    },
    {
        store_latitdue: -0.894414,
        store_longitude: -79.4859865,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8898267,
        store_longitude: -79.4847267,
    },
    {
        store_latitdue: -0.8893,
        store_longitude: -79.4842,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0376798,
        store_longitude: -79.4726725,
    },
    {
        store_latitdue: -1.03829,
        store_longitude: -79.4857,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0442268,
        store_longitude: -79.4775644,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8905047,
        store_longitude: -79.489706,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.04416,
        store_longitude: -79.4811,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05139,
        store_longitude: -79.6327,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0311515,
        store_longitude: -79.469448,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0545625,
        store_longitude: -79.4634009,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0585961,
        store_longitude: -79.640974,
    },
    {
        store_latitdue: -1.024348,
        store_longitude: -79.4678,
    },
    {
        store_latitdue: -1.04421,
        store_longitude: -79.4803,
    },
    {
        store_latitdue: -0.8924499,
        store_longitude: -79.4931298,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.890932,
        store_longitude: -79.489118,
    },
    {
        store_latitdue: -1.04399,
        store_longitude: -79.4819,
    },

    {
        store_latitdue: -0.896075,
        store_longitude: -79.4891,
    },
    {
        store_latitdue: -0.8979052,
        store_longitude: -79.4891338,
    },
    {
        store_latitdue: -0.88661,
        store_longitude: -79.4889,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0420617,
        store_longitude: -79.6483247,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0420103,
        store_longitude: -79.6382727,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8889821,
        store_longitude: -79.4844761,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.044029,
        store_longitude: -79.6367,
    },
    {
        store_latitdue: -1.0404224,
        store_longitude: -79.6381336,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.9133455,
        store_longitude: -79.4974321,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8952185,
        store_longitude: -79.4869115,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.043215,
        store_longitude: -79.467007,
    },
    {
        store_latitdue: -1.042357,
        store_longitude: -79.470944,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0408768,
        store_longitude: -79.4830499,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.899121,
        store_longitude: -79.492911,
    },
    {
        store_latitdue: -0.8923781,
        store_longitude: -79.4887391,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.039035,
        store_longitude: -79.484976,
    },
    {
        store_latitdue: -1.0408117,
        store_longitude: -79.6443853,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0406031,
        store_longitude: -79.4785188,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0388719,
        store_longitude: -79.4789015,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04142,
        store_longitude: -79.639106,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8872396,
        store_longitude: -79.4914345,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8987653,
        store_longitude: -79.4883677,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.04123,
        store_longitude: -79.6387,
    },
    {
        store_latitdue: -1.4352933,
        store_longitude: -79.3476983,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -0.8961915,
        store_longitude: -79.4967013,
    },
    {
        store_latitdue: -1.035635,
        store_longitude: -79.469964,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0400479,
        store_longitude: -79.4783168,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0452449,
        store_longitude: -79.4757856,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0706946,
        store_longitude: -79.4963182,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0341998,
        store_longitude: -79.4683254,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0419285,
        store_longitude: -79.4734651,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8845298,
        store_longitude: -79.4939882,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8728251,
        store_longitude: -79.4880744,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.040020255,
        store_longitude: -79.63805711,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8976401,
        store_longitude: -79.489744,
    },
    {
        store_latitdue: -1.0503311,
        store_longitude: -79.6301022,
    },
    {
        store_latitdue: -1.0524418,
        store_longitude: -79.6446157,
    },
    {
        store_latitdue: -1.0315401,
        store_longitude: -79.4701024,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0392766,
        store_longitude: -79.6367498,
    },
    {
        store_latitdue: -1.0483417,
        store_longitude: -79.6395737,
    },
    {
        store_latitdue: -0.876439,
        store_longitude: -79.494309,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.047349,
        store_longitude: -79.479051,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0465291,
        store_longitude: -79.4667889,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0419248,
        store_longitude: -79.6391243,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.034090492,
        store_longitude: -79.66447294,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0421882,
        store_longitude: -79.4824272,
    },
    {
        store_latitdue: -1.0522,
        store_longitude: -79.4628,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.890659,
        store_longitude: -79.48958,
    },
    {
        store_latitdue: -1.05316,
        store_longitude: -79.6431,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.903072,
        store_longitude: -79.49375,
    },
    {
        store_latitdue: -1.04478,
        store_longitude: -79.48,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9003866,
        store_longitude: -79.4927216,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0419836,
        store_longitude: -79.6389606,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8870015,
        store_longitude: -79.4939468,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.898753,
        store_longitude: -79.4851,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0454685,
        store_longitude: -79.4827836,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0436525,
        store_longitude: -79.6341812,
    },
    {
        store_latitdue: -0.8913092,
        store_longitude: -79.4825422,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8759242,
        store_longitude: -79.4933324,
    },
    {
        store_latitdue: -0.898030193,
        store_longitude: -79.48456415,
    },
    {
        store_latitdue: -1.0378941,
        store_longitude: -79.4875323,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8913028,
        store_longitude: -79.4908164,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0630295,
        store_longitude: -79.486151,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0475075,
        store_longitude: -79.632671,
    },
    {
        store_latitdue: -1.04986,
        store_longitude: -79.6415,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8898032,
        store_longitude: -79.4829838,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0453513,
        store_longitude: -79.4809787,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.040903,
        store_longitude: -79.63558,
    },
    {
        store_latitdue: -1.0394678,
        store_longitude: -79.637398,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0565149,
        store_longitude: -79.4624898,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.88065,
        store_longitude: -79.489117,
    },
    {
        store_latitdue: -1.0462794,
        store_longitude: -79.6386899,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.084261903,
        store_longitude: -79.51737178,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.044644,
        store_longitude: -79.479813,
    },
    {
        store_latitdue: -0.8953,
        store_longitude: -79.4916,
    },
    {
        store_latitdue: -0.263198,
        store_longitude: -79.1635,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -1.04424,
        store_longitude: -79.482,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8918832,
        store_longitude: -79.4887581,
    },
    {
        store_latitdue: -1.0431402899,
        store_longitude: -79.4817304239,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.894527,
        store_longitude: -79.4847,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.044506609,
        store_longitude: -79.48340354,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8978229,
        store_longitude: -79.490191,
    },
    {
        store_latitdue: -1.0533486,
        store_longitude: -79.4646731,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8967699,
        store_longitude: -79.4908456,
    },
    {
        store_latitdue: -1.04626631,
        store_longitude: -79.4738998,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0362867,
        store_longitude: -79.4734756,
    },
    {
        store_latitdue: -0.8961019,
        store_longitude: -79.4887394,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.899867,
        store_longitude: -79.4821211,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.05274,
        store_longitude: -79.6421,
    },
    {
        store_latitdue: -1.04778,
        store_longitude: -79.6411,
    },
    {
        store_latitdue: -1.047947,
        store_longitude: -79.638493,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0444812,
        store_longitude: -79.6352766,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0460777,
        store_longitude: -79.6421703,
    },
    {
        store_latitdue: -1.044846,
        store_longitude: -79.637748,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0400698,
        store_longitude: -79.637971,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.039281,
        store_longitude: -79.637125,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0455772,
        store_longitude: -79.4828813,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.044045,
        store_longitude: -79.467297,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.042076,
        store_longitude: -79.47511,
    },
    {
        store_latitdue: -1.0403733,
        store_longitude: -79.4706658,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0337393,
        store_longitude: -79.4691185,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0376832,
        store_longitude: -79.4726082,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.07207,
        store_longitude: -79.4981,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.896822,
        store_longitude: -79.489539,
    },
    {
        store_latitdue: -0.89439,
        store_longitude: -79.486733,
    },
    {
        store_latitdue: -0.9118825,
        store_longitude: -79.4929221,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0427078,
        store_longitude: -79.6361309,
    },
    {
        store_latitdue: -1.0383678,
        store_longitude: -79.636043,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.039681,
        store_longitude: -79.4893058,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0378036,
        store_longitude: -79.4860366,
    },
    {
        store_latitdue: -1.04038,
        store_longitude: -79.4873,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0244172,
        store_longitude: -79.4673317,
    },
    {
        store_latitdue: -1.04043,
        store_longitude: -79.473927,
    },
    {
        store_latitdue: -1.0409803,
        store_longitude: -79.4828683,
    },
    {
        store_latitdue: -0.8962314,
        store_longitude: -79.489514,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0337718,
        store_longitude: -79.6324683,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.036332,
        store_longitude: -79.471191,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.889903,
        store_longitude: -79.482978,
    },
    {
        store_latitdue: -1.0438971,
        store_longitude: -79.6344691,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.044253,
        store_longitude: -79.637551,
    },
    {
        store_latitdue: -1.043484,
        store_longitude: -79.470284,
    },
    {
        store_latitdue: -1.037594,
        store_longitude: -79.47053,
    },
    {
        store_latitdue: -0.897004,
        store_longitude: -79.489628,
    },
    {
        store_latitdue: -1.0373058,
        store_longitude: -79.4676952,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.040559,
        store_longitude: -79.467469,
    },
    {
        store_latitdue: -1.041106,
        store_longitude: -79.473235,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.047757,
        store_longitude: -79.640973,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.9045463,
        store_longitude: -79.492391,
    },
    {
        store_latitdue: -1.047662,
        store_longitude: -79.6408352,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8962724,
        store_longitude: -79.4865222,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.04123,
        store_longitude: -79.4676,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8837518,
        store_longitude: -79.4936918,
    },
    {
        store_latitdue: -1.042256,
        store_longitude: -79.6476595,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0451172,
        store_longitude: -79.4721962,
    },
    {
        store_latitdue: -0.9017652,
        store_longitude: -79.485296,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.039178,
        store_longitude: -79.482297,
    },
    {
        store_latitdue: -1.042503,
        store_longitude: -79.474362,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8761132,
        store_longitude: -79.4904707,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.902667,
        store_longitude: -79.4855,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.909046,
        store_longitude: -79.4953,
    },
    {
        store_latitdue: -1.039311,
        store_longitude: -79.467659,
    },
    {
        store_latitdue: -1.0397812,
        store_longitude: -79.484585,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03454837,
        store_longitude: -79.6077167,
    },
    {
        store_latitdue: -0.8969348,
        store_longitude: -79.4895918,
    },
    {
        store_latitdue: -0.8723607,
        store_longitude: -79.4891622,
    },
    {
        store_latitdue: -0.894586,
        store_longitude: -79.48376,
    },
    {
        store_latitdue: -0.8986291,
        store_longitude: -79.4933373,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8780843,
        store_longitude: -79.4890122,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.04906,
        store_longitude: -79.6383,
    },
    {
        store_latitdue: -0.89392,
        store_longitude: -79.488273,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8858013,
        store_longitude: -79.4959898,
    },
    {
        store_latitdue: -1.0390944,
        store_longitude: -79.6368842,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.728871,
        store_longitude: -79.471223,
    },
    {
        store_latitdue: -0.8971633,
        store_longitude: -79.4903597,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8925358,
        store_longitude: -79.487674,
    },
    {
        store_latitdue: -1.039132,
        store_longitude: -79.473991,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.889535,
        store_longitude: -79.483242,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8900579,
        store_longitude: -79.4826067,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8840393,
        store_longitude: -79.4890642,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.890004,
        store_longitude: -79.489398,
    },
    {
        store_latitdue: -0.9126657,
        store_longitude: -79.4936584,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.03911,
        store_longitude: -79.4668,
    },
    {
        store_latitdue: -0.8847974,
        store_longitude: -79.4931518,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.03791,
        store_longitude: -79.4698,
    },
    {
        store_latitdue: -0.8911678,
        store_longitude: -79.4914244,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.042151,
        store_longitude: -79.474979,
    },
    {
        store_latitdue: -1.0375603,
        store_longitude: -79.4727917,
    },
    {
        store_latitdue: -1.0440356,
        store_longitude: -79.6428193,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8991452,
        store_longitude: -79.489818,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0434167,
        store_longitude: -79.4730491,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.038714,
        store_longitude: -79.6440591,
    },
    {
        store_latitdue: -0.8868347,
        store_longitude: -79.4916836,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8951246,
        store_longitude: -79.492646,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0464172,
        store_longitude: -79.4769719,
    },
    {
        store_latitdue: -1.039792,
        store_longitude: -79.4877529,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.045434,
        store_longitude: -79.467842,
    },
    {
        store_latitdue: -1.0428159,
        store_longitude: -79.6410176,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.074628,
        store_longitude: -79.4989378,
    },
    {
        store_latitdue: -1.019355,
        store_longitude: -79.465094,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04149,
        store_longitude: -79.6388,
    },
    {
        store_latitdue: -0.870016796,
        store_longitude: -79.48801606,
    },
    {
        store_latitdue: -1.02471,
        store_longitude: -79.4681,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.893403,
        store_longitude: -79.4832,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0519246,
        store_longitude: -79.4661169,
    },
    {
        store_latitdue: -0.900977,
        store_longitude: -79.4882879,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8926141,
        store_longitude: -79.4904298,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8973258,
        store_longitude: -79.4879934,
    },
    {
        store_latitdue: -0.882174,
        store_longitude: -79.4893,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.898023,
        store_longitude: -79.4877,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.04174,
        store_longitude: -79.6388,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0419365,
        store_longitude: -79.639162,
    },
    {
        store_latitdue: -1.0459013,
        store_longitude: -79.6388122,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0448732,
        store_longitude: -79.6377336,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0438325,
        store_longitude: -79.6391051,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.047035,
        store_longitude: -79.6319174,
    },
    {
        store_latitdue: -1.05124,
        store_longitude: -79.6292,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.04482,
        store_longitude: -79.4695,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04329,
        store_longitude: -79.4698,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0460737,
        store_longitude: -79.4744183,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.044734,
        store_longitude: -79.478427,
    },
    {
        store_latitdue: -1.04445,
        store_longitude: -79.4801,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0434323,
        store_longitude: -79.474743,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.057075,
        store_longitude: -79.4888021,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0414108,
        store_longitude: -79.6395279,
        neighborhood: 'VELASCO IBARRA',
    },

    {
        store_latitdue: -1.0346127,
        store_longitude: -79.4745325,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0447117,
        store_longitude: -79.4863854,
    },
    {
        store_latitdue: -1.031460871,
        store_longitude: -79.46912565,
    },
    {
        store_latitdue: -1.0438418,
        store_longitude: -79.4662803,
    },
    {
        store_latitdue: -1.042625,
        store_longitude: -79.46772,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04353,
        store_longitude: -79.4681,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.040997,
        store_longitude: -79.469217,
    },
    {
        store_latitdue: -1.03901,
        store_longitude: -79.4681,
    },
    {
        store_latitdue: -1.036852,
        store_longitude: -79.468762,
    },
    {
        store_latitdue: -1.043225,
        store_longitude: -79.467604,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0254101,
        store_longitude: -79.4678814,
    },
    {
        store_latitdue: -0.90354512,
        store_longitude: -79.4941091,
    },
    {
        store_latitdue: -0.6204708,
        store_longitude: -79.4272746,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.90921,
        store_longitude: -79.4946,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8944482,
        store_longitude: -79.4934887,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8942894,
        store_longitude: -79.4946267,
    },
    {
        store_latitdue: -0.895583,
        store_longitude: -79.4900354,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8969526,
        store_longitude: -79.4856338,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.885535,
        store_longitude: -79.4960412,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8867719,
        store_longitude: -79.4889016,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8999963,
        store_longitude: -79.491921,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.897276,
        store_longitude: -79.4912,
    },
    {
        store_latitdue: -0.898489,
        store_longitude: -79.491694,
    },
    {
        store_latitdue: -0.8977847,
        store_longitude: -79.491638,
    },
    {
        store_latitdue: -0.897081,
        store_longitude: -79.4898,
    },
    {
        store_latitdue: -1.0498106,
        store_longitude: -79.6302212,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0441416,
        store_longitude: -79.6373797,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0440205,
        store_longitude: -79.6374709,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.05068,
        store_longitude: -79.6393,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0439032,
        store_longitude: -79.6428338,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.04082,
        store_longitude: -79.6416,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0405,
        store_longitude: -79.6426,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0486853,
        store_longitude: -79.4811453,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04051,
        store_longitude: -79.4783,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05746,
        store_longitude: -79.485,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05789,
        store_longitude: -79.4844,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05257,
        store_longitude: -79.4661,
    },
    {
        store_latitdue: -1.044243,
        store_longitude: -79.471696,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.042059,
        store_longitude: -79.471648,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04761,
        store_longitude: -79.4708,
    },
    {
        store_latitdue: -1.05742,
        store_longitude: -79.4871,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0819654,
        store_longitude: -79.5205397,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.04585,
        store_longitude: -79.4816,
    },
    {
        store_latitdue: -1.0350158,
        store_longitude: -79.4743577,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.044301,
        store_longitude: -79.473394,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0256186,
        store_longitude: -79.4657552,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8962528,
        store_longitude: -79.485114,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8762415,
        store_longitude: -79.4903395,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.913217,
        store_longitude: -79.4945,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.901192,
        store_longitude: -79.4905,
    },
    {
        store_latitdue: -0.891111,
        store_longitude: -79.4911782,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8013622,
        store_longitude: -79.4349925,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.801561,
        store_longitude: -79.4374,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.897053,
        store_longitude: -79.4889592,
    },
    {
        store_latitdue: -1.2064813,
        store_longitude: -79.3134237,
    },
    {
        store_latitdue: -0.886075348,
        store_longitude: -79.49176704,
    },
    {
        store_latitdue: -1.0457036,
        store_longitude: -79.6421771,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.039697,
        store_longitude: -79.6414,
    },
    {
        store_latitdue: -1.0417,
        store_longitude: -79.6394,
    },
    {
        store_latitdue: -1.05895,
        store_longitude: -79.4874338,
    },
    {
        store_latitdue: -1.0469302,
        store_longitude: -79.4729747,
    },
    {
        store_latitdue: -1.0471229,
        store_longitude: -79.4733512,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05568,
        store_longitude: -79.4657,
    },
    {
        store_latitdue: -1.042287,
        store_longitude: -79.4832536,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.034496,
        store_longitude: -79.470718,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.897957,
        store_longitude: -79.4836,
    },
    {
        store_latitdue: -0.8955468,
        store_longitude: -79.4913738,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.901556,
        store_longitude: -79.4877689,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.889146,
        store_longitude: -79.496,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0528658,
        store_longitude: -79.4621704,
    },
    {
        store_latitdue: -1.07341,
        store_longitude: -79.4973,
    },
    {
        store_latitdue: -0.898243,
        store_longitude: -79.4948,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9101702,
        store_longitude: -79.4970126,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8943934,
        store_longitude: -79.4899332,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0443395,
        store_longitude: -79.6374802,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0445815,
        store_longitude: -79.6377593,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0395,
        store_longitude: -79.481682,
    },
    {
        store_latitdue: -1.037843,
        store_longitude: -79.467858,
    },
    {
        store_latitdue: -0.879504,
        store_longitude: -79.4901,
    },
    {
        store_latitdue: -0.8942447,
        store_longitude: -79.488291,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9014807,
        store_longitude: -79.4855694,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.895638,
        store_longitude: -79.4894546,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8982103,
        store_longitude: -79.491432,
    },
    {
        store_latitdue: -1.03863,
        store_longitude: -79.6347,
    },
    {
        store_latitdue: -1.04347,
        store_longitude: -79.474764,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.087531,
        store_longitude: -79.4954827,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.28723,
        store_longitude: -79.3265,
    },
    {
        store_latitdue: -0.8868344,
        store_longitude: -79.4881132,
    },
    {
        store_latitdue: -0.8950036,
        store_longitude: -79.4798433,
    },
    {
        store_latitdue: -0.909073,
        store_longitude: -79.4950573,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.895095,
        store_longitude: -79.4826,
    },
    {
        store_latitdue: -0.9133892,
        store_longitude: -79.4973864,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9126184,
        store_longitude: -79.4977595,
    },
    {
        store_latitdue: -0.877309,
        store_longitude: -79.4886456,
    },
    {
        store_latitdue: -0.8742193,
        store_longitude: -79.494431,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8894069,
        store_longitude: -79.486727,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8882453,
        store_longitude: -79.4914277,
    },
    {
        store_latitdue: -0.891129,
        store_longitude: -79.483009,
    },
    {
        store_latitdue: -1.041156,
        store_longitude: -79.6570435,
    },
    {
        store_latitdue: -1.0440536,
        store_longitude: -79.6376839,
    },
    {
        store_latitdue: -1.0473927,
        store_longitude: -79.4802934,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04048,
        store_longitude: -79.4907,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0409934,
        store_longitude: -79.651355,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.894884,
        store_longitude: -79.4835,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.872377,
        store_longitude: -79.4827,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.888322,
        store_longitude: -79.4832,
    },
    {
        store_latitdue: -1.03771914,
        store_longitude: -79.4854175,
    },
    {
        store_latitdue: -1.084567,
        store_longitude: -79.565892,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.0840243,
        store_longitude: -79.5672149,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.07986,
        store_longitude: -79.5904,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0507882,
        store_longitude: -79.4718225,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.041849,
        store_longitude: -79.6390937,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0423,
        store_longitude: -79.6462,
    },
    {
        store_latitdue: -1.0424921,
        store_longitude: -79.6397376,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.9008977,
        store_longitude: -79.4858169,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8950823,
        store_longitude: -79.4851042,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.917368,
        store_longitude: -79.4936,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8905822,
        store_longitude: -79.4838419,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.043483,
        store_longitude: -79.467516,
    },
    {
        store_latitdue: -1.05862,
        store_longitude: -79.4899,
    },
    {
        store_latitdue: -1.0590694,
        store_longitude: -79.4619974,
    },
    {
        store_latitdue: -1.0517467,
        store_longitude: -79.4964312,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.08161,
        store_longitude: -79.5191,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.03935,
        store_longitude: -79.6348,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0390887,
        store_longitude: -79.635316,
    },
    {
        store_latitdue: -0.8747659,
        store_longitude: -79.4918304,
    },
    {
        store_latitdue: -0.7311623,
        store_longitude: -79.470762,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.03454457,
        store_longitude: -79.6077167,
    },
    {
        store_latitdue: -1.0525146,
        store_longitude: -79.6279302,
    },
    {
        store_latitdue: -0.8976957,
        store_longitude: -79.4903913,
    },
    {
        store_latitdue: -0.9012382,
        store_longitude: -79.4869082,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0387,
        store_longitude: -79.4699,
    },
    {
        store_latitdue: -1.1269396,
        store_longitude: -79.436707,
    },
    {
        store_latitdue: -1.04669,
        store_longitude: -79.4874,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.88136,
        store_longitude: -79.4923,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.7654502,
        store_longitude: -79.464331,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.031985,
        store_longitude: -79.4716,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0304893,
        store_longitude: -79.4686532,
    },
    {
        store_latitdue: -1.0438101,
        store_longitude: -79.4820772,
    },
    {
        store_latitdue: -1.0450835,
        store_longitude: -79.4779468,
    },
    {
        store_latitdue: -1.05683,
        store_longitude: -79.6411,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.881311,
        store_longitude: -79.4915,
    },
    {
        store_latitdue: -0.89524,
        store_longitude: -79.4863,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.02858,
        store_longitude: -79.466857,
    },
    {
        store_latitdue: -0.895738,
        store_longitude: -79.485109,
    },
    {
        store_latitdue: -1.1854467,
        store_longitude: -79.5054221,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.18692,
        store_longitude: -79.5109,
    },
    {
        store_latitdue: -1.18623,
        store_longitude: -79.5031,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.186411,
        store_longitude: -79.5031728,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1857608,
        store_longitude: -79.5032138,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1862222,
        store_longitude: -79.5042093,
    },
    {
        store_latitdue: -1.1916962,
        store_longitude: -79.5076917,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1876859,
        store_longitude: -79.5121469,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1861157,
        store_longitude: -79.5034406,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1902629,
        store_longitude: -79.5071161,
    },
    {
        store_latitdue: -1.1934009,
        store_longitude: -79.5080401,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.188618,
        store_longitude: -79.5158083,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.184577342,
        store_longitude: -79.51410489,
    },
    {
        store_latitdue: -1.1878898,
        store_longitude: -79.5051532,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.19638,
        store_longitude: -79.5133,
    },
    {
        store_latitdue: -1.1817498,
        store_longitude: -79.5024888,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.188494,
        store_longitude: -79.5065,
    },
    {
        store_latitdue: -1.1893549,
        store_longitude: -79.5064165,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.186503,
        store_longitude: -79.5040004,
    },
    {
        store_latitdue: -1.18683,
        store_longitude: -79.5053,
    },
    {
        store_latitdue: -1.17945,
        store_longitude: -79.5141,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1909836,
        store_longitude: -79.5076908,
    },
    {
        store_latitdue: -1.18681,
        store_longitude: -79.5102,
    },
    {
        store_latitdue: -1.19077,
        store_longitude: -79.5077,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1822,
        store_longitude: -79.5012,
    },
    {
        store_latitdue: -1.18298,
        store_longitude: -79.5047,
    },
    {
        store_latitdue: -1.1831201,
        store_longitude: -79.5045133,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.18643,
        store_longitude: -79.5039,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.04173,
        store_longitude: -79.4737,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0521525,
        store_longitude: -79.6391438,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.894096,
        store_longitude: -79.4825,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8794696,
        store_longitude: -79.4902977,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.1852982,
        store_longitude: -79.5061158,
    },
    {
        store_latitdue: -1.0425247,
        store_longitude: -79.6449493,
    },
    {
        store_latitdue: -1.05016,
        store_longitude: -79.6319,
    },
    {
        store_latitdue: -0.899819,
        store_longitude: -79.4856,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.880876,
        store_longitude: -79.491889,
    },
    {
        store_latitdue: -1.05144,
        store_longitude: -79.6297,
    },
    {
        store_latitdue: -1.08189,
        store_longitude: -79.5172,
    },
    {
        store_latitdue: -1.0428274,
        store_longitude: -79.6351788,
    },
    {
        store_latitdue: -1.0278358,
        store_longitude: -79.6230666,
        neighborhood: 'GUAYAS',
    },
    {
        store_latitdue: -0.89879,
        store_longitude: -79.4825,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0281,
        store_longitude: -79.4699,
    },
    {
        store_latitdue: -1.0394046,
        store_longitude: -79.4904547,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8979296,
        store_longitude: -79.492765,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.896073,
        store_longitude: -79.4891299,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.666695,
        store_longitude: -79.448986,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8975903463,
        store_longitude: -79.4868380204,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.89941,
        store_longitude: -79.4811,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.889639,
        store_longitude: -79.4821,
    },
    {
        store_latitdue: -1.0493764,
        store_longitude: -79.4811163,
    },
    {
        store_latitdue: -0.724195,
        store_longitude: -79.5185335,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.898585,
        store_longitude: -79.4851,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0417,
        store_longitude: -79.6388,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.041467,
        store_longitude: -79.6389767,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8960677,
        store_longitude: -79.481443,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8960714,
        store_longitude: -79.4892064,
    },
    {
        store_latitdue: -1.04645,
        store_longitude: -79.487,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8937806,
        store_longitude: -79.4912529,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.1887671,
        store_longitude: -79.5060043,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.04367,
        store_longitude: -79.4818,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.6431902,
        store_longitude: -79.4446654,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.892078,
        store_longitude: -79.4915,
    },
    {
        store_latitdue: -0.8460838,
        store_longitude: -79.4872287,
    },
    {
        store_latitdue: -0.735057,
        store_longitude: -79.46986,
    },
    {
        store_latitdue: -1.04536,
        store_longitude: -79.4835,
    },
    {
        store_latitdue: -1.0419314,
        store_longitude: -79.6496173,
    },
    {
        store_latitdue: -0.890855,
        store_longitude: -79.4891,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8998371,
        store_longitude: -79.4868334,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8956357,
        store_longitude: -79.4888882,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.1832,
        store_longitude: -79.4995,
    },
    {
        store_latitdue: -1.0499389,
        store_longitude: -79.4737337,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03885,
        store_longitude: -79.4782,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0443417,
        store_longitude: -79.4806013,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0514439,
        store_longitude: -79.641795,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0406664,
        store_longitude: -79.6381047,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8947333,
        store_longitude: -79.4956591,
    },
    {
        store_latitdue: -0.8942219,
        store_longitude: -79.48778,
    },
    {
        store_latitdue: -0.881684,
        store_longitude: -79.492,
    },
    {
        store_latitdue: -1.06243,
        store_longitude: -79.4865,
    },
    {
        store_latitdue: -1.06124,
        store_longitude: -79.6411,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.81266559,
        store_longitude: -79.4696366,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.05205,
        store_longitude: -79.4804,
    },
    {
        store_latitdue: -0.8905518,
        store_longitude: -79.4838032,
    },
    {
        store_latitdue: -0.8899974,
        store_longitude: -79.483223,
    },
    {
        store_latitdue: -0.880732,
        store_longitude: -79.4939,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.049851,
        store_longitude: -79.483237,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0523966,
        store_longitude: -79.6308189,
    },
    {
        store_latitdue: -1.0602329,
        store_longitude: -79.6444124,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8857721,
        store_longitude: -79.4872619,
    },
    {
        store_latitdue: -1.052,
        store_longitude: -79.4795,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.036630975,
        store_longitude: -79.47340131,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03132,
        store_longitude: -79.6306,
    },
    {
        store_latitdue: -0.894594,
        store_longitude: -79.4935,
    },
    {
        store_latitdue: -0.89789,
        store_longitude: -79.485062,
    },
    {
        store_latitdue: -1.047402503,
        store_longitude: -79.47654723,
    },
    {
        store_latitdue: -1.052359,
        store_longitude: -79.484731,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0365708,
        store_longitude: -79.4678079,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0460944,
        store_longitude: -79.4879179,
        neighborhood: 'QUEVEDO',
    },

    {
        store_latitdue: -1.04306,
        store_longitude: -79.4768,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.7543254,
        store_longitude: -79.4649352,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.7935561,
        store_longitude: -79.5338295,
    },
    {
        store_latitdue: -1.0388706,
        store_longitude: -79.4861202,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0836324,
        store_longitude: -79.5230909,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.08284,
        store_longitude: -79.5228,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.0381,
        store_longitude: -79.4738,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.894927,
        store_longitude: -79.4908,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.04231,
        store_longitude: -79.4766,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.06215,
        store_longitude: -79.6407,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8719726,
        store_longitude: -79.4822189,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.911578,
        store_longitude: -79.4932674,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8807687,
        store_longitude: -79.4935369,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0530898,
        store_longitude: -79.628248,
    },
    {
        store_latitdue: -1.0529981,
        store_longitude: -79.6296894,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.04068,
        store_longitude: -79.6471,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8791404,
        store_longitude: -79.4937982,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8978058,
        store_longitude: -79.4819337,
    },
    {
        store_latitdue: -0.896578,
        store_longitude: -79.4961,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0397092,
        store_longitude: -79.6368653,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.04048281,
        store_longitude: -79.4773292,
    },
    {
        store_latitdue: -1.0453635,
        store_longitude: -79.6388825,
    },
    {
        store_latitdue: -1.0505697,
        store_longitude: -79.4722369,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04212,
        store_longitude: -79.4813,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0429477,
        store_longitude: -79.4800187,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0582439,
        store_longitude: -79.488584,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04707,
        store_longitude: -79.4796,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0437382,
        store_longitude: -79.6377139,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8822755,
        store_longitude: -79.4891883,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8012722,
        store_longitude: -79.4348222,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.8838183,
        store_longitude: -79.4903437,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.889974,
        store_longitude: -79.4837,
    },
    {
        store_latitdue: -1.02715,
        store_longitude: -79.4668,
    },
    {
        store_latitdue: -1.054398,
        store_longitude: -79.466278,
    },
    {
        store_latitdue: -0.982749,
        store_longitude: -79.4715,
    },
    {
        store_latitdue: -1.030267,
        store_longitude: -79.468645,
    },
    {
        store_latitdue: -1.0361142,
        store_longitude: -79.4785733,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03961384,
        store_longitude: -79.472351,
    },
    {
        store_latitdue: -0.8760666,
        store_longitude: -79.4906791,
    },
    {
        store_latitdue: -0.6665467,
        store_longitude: -79.4490583,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.044571,
        store_longitude: -79.479938,
    },
    {
        store_latitdue: -1.041867,
        store_longitude: -79.4755871,
    },
    {
        store_latitdue: -1.052966,
        store_longitude: -79.6285737,
    },
    {
        store_latitdue: -1.18585,
        store_longitude: -79.5037,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.05296,
        store_longitude: -79.6248,
    },
    {
        store_latitdue: -1.0575046,
        store_longitude: -79.6229585,
    },
    {
        store_latitdue: -0.894709,
        store_longitude: -79.4849379,
    },
    {
        store_latitdue: -1.573099,
        store_longitude: -79.4682674,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.0599745,
        store_longitude: -79.6203123,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8964584,
        store_longitude: -79.4862315,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },

    {
        store_latitdue: -0.8999227,
        store_longitude: -79.4885552,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0311,
        store_longitude: -79.4703,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8960762,
        store_longitude: -79.4949438,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.03437,
        store_longitude: -79.4715,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0348899,
        store_longitude: -79.455635,
    },
    {
        store_latitdue: -0.874492,
        store_longitude: -79.494836,
    },
    {
        store_latitdue: -0.8729068,
        store_longitude: -79.4884105,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0420499,
        store_longitude: -79.4822824,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0458221,
        store_longitude: -79.471102,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.75304359,
        store_longitude: -79.4654922,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.892436,
        store_longitude: -79.4819376,
    },
    {
        store_latitdue: -0.8983196,
        store_longitude: -79.492313,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.03778,
        store_longitude: -79.47,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0475531,
        store_longitude: -79.471836,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.037981,
        store_longitude: -79.4874803,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04202,
        store_longitude: -79.6527,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8862676,
        store_longitude: -79.4945507,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.03886,
        store_longitude: -79.4872,
    },
    {
        store_latitdue: -1.0418682,
        store_longitude: -79.6393808,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0635,
        store_longitude: -79.4917,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8843072,
        store_longitude: -79.4918001,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9019322,
        store_longitude: -79.4951194,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8772675,
        store_longitude: -79.4953026,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.872043,
        store_longitude: -79.4899,
    },
    {
        store_latitdue: -1.03827,
        store_longitude: -79.4738,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0489,
        store_longitude: -79.4706,
    },
    {
        store_latitdue: -0.878031,
        store_longitude: -79.491386,
    },
    {
        store_latitdue: -1.0488733,
        store_longitude: -79.4700003,
    },
    {
        store_latitdue: -0.894687,
        store_longitude: -79.4817873,
    },
    {
        store_latitdue: -1.00617,
        store_longitude: -79.4674,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8899043,
        store_longitude: -79.4813364,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.04022,
        store_longitude: -79.4725,
    },
    {
        store_latitdue: -1.0708,
        store_longitude: -79.4993,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05573,
        store_longitude: -79.6409,
    },
    {
        store_latitdue: -1.043441,
        store_longitude: -79.467905,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8953837,
        store_longitude: -79.4961407,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8777889,
        store_longitude: -79.4942978,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.038436,
        store_longitude: -79.480808,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0508469,
        store_longitude: -79.6428058,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.886724,
        store_longitude: -79.4952774,
    },
    {
        store_latitdue: -0.8966974,
        store_longitude: -79.4846018,
    },
    {
        store_latitdue: -1.0400239,
        store_longitude: -79.4811452,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0382396,
        store_longitude: -79.4745739,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0467861,
        store_longitude: -79.4714331,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.042408,
        store_longitude: -79.472769,
    },
    {
        store_latitdue: -1.0401299,
        store_longitude: -79.6544235,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8873795,
        store_longitude: -79.4951921,
    },
    {
        store_latitdue: -1.042187,
        store_longitude: -79.466405,
    },
    {
        store_latitdue: -1.0498007,
        store_longitude: -79.6321536,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0414522,
        store_longitude: -79.6427871,
    },
    {
        store_latitdue: -1.04085,
        store_longitude: -79.4751,
    },
    {
        store_latitdue: -0.9015027,
        store_longitude: -79.4887588,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.91006618,
        store_longitude: -79.4972991,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.7602817,
        store_longitude: -79.4644491,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8897446,
        store_longitude: -79.49581,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.18851816,
        store_longitude: -79.5155868,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.042918,
        store_longitude: -79.6429051,
    },
    {
        store_latitdue: -0.889119,
        store_longitude: -79.4967,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8952414,
        store_longitude: -79.4916486,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.04352,
        store_longitude: -79.4728,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.038997,
        store_longitude: -79.4806208,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.897541,
        store_longitude: -79.4835,
    },
    {
        store_latitdue: -1.0380747,
        store_longitude: -79.6334319,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.043782,
        store_longitude: -79.634322,
    },
    {
        store_latitdue: -0.8896568,
        store_longitude: -79.4907437,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.06049,
        store_longitude: -79.641,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0401301,
        store_longitude: -79.6544312,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.02563,
        store_longitude: -79.4599,
    },
    {
        store_latitdue: -1.03844181,
        store_longitude: -79.63615938,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.044255,
        store_longitude: -79.637571,
    },
    {
        store_latitdue: -0.8969605,
        store_longitude: -79.4896068,
    },
    {
        store_latitdue: -1.038034,
        store_longitude: -79.487397,
    },
    {
        store_latitdue: -0.8900572,
        store_longitude: -79.4811733,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0396097,
        store_longitude: -79.4750546,
    },
    {
        store_latitdue: -1.005633,
        store_longitude: -79.457849,
    },
    {
        store_latitdue: -1.0457663,
        store_longitude: -79.4728996,
    },
    {
        store_latitdue: -1.0441416,
        store_longitude: -79.4811461,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8984565,
        store_longitude: -79.4951513,
    },
    {
        store_latitdue: -0.8881065,
        store_longitude: -79.492151,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0486604,
        store_longitude: -79.4663195,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8723205,
        store_longitude: -79.4853801,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9016976,
        store_longitude: -79.4856634,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0569367,
        store_longitude: -79.461527,
    },
    {
        store_latitdue: -1.1850058,
        store_longitude: -79.5029766,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.0543097,
        store_longitude: -79.6462344,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.04784,
        store_longitude: -79.6437,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0326371,
        store_longitude: -79.6344622,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8868329,
        store_longitude: -79.4947644,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.051449,
        store_longitude: -79.6450205,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0373039,
        store_longitude: -79.6351069,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0431039,
        store_longitude: -79.6330039,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.8010318,
        store_longitude: -79.437695,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0554693,
        store_longitude: -79.4660501,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03453619,
        store_longitude: -79.6077167,
    },
    {
        store_latitdue: -0.64324,
        store_longitude: -79.4427,
    },
    {
        store_latitdue: -1.03336,
        store_longitude: -79.4714736,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.6673381,
        store_longitude: -79.4498388,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.1868203,
        store_longitude: -79.5099717,
    },
    {
        store_latitdue: -1.0380454,
        store_longitude: -79.4850301,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.896356,
        store_longitude: -79.4864202,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.046317,
        store_longitude: -79.4714,
    },
    {
        store_latitdue: -1.043994,
        store_longitude: -79.634659,
    },
    {
        store_latitdue: -0.76971,
        store_longitude: -79.4658467,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.02593,
        store_longitude: -79.4702,
    },
    {
        store_latitdue: -1.04808445,
        store_longitude: -79.4712567,
    },
    {
        store_latitdue: -0.6672498,
        store_longitude: -79.449342,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9627944,
        store_longitude: -79.4210423,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.955496,
        store_longitude: -79.3485,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.019385,
        store_longitude: -79.462376,
    },
    {
        store_latitdue: -0.9526712,
        store_longitude: -79.3517389,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.027521,
        store_longitude: -79.452531,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.2865328,
        store_longitude: -79.3151716,
    },
    {
        store_latitdue: -1.432922,
        store_longitude: -79.462306,
    },
    {
        store_latitdue: -1.1198911,
        store_longitude: -79.4361546,
    },
    {
        store_latitdue: -1.5736,
        store_longitude: -79.467,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.02943,
        store_longitude: -79.4576,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.35802,
        store_longitude: -79.4008,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -0.942185,
        store_longitude: -79.2603,
    },
    {
        store_latitdue: -1.4315815,
        store_longitude: -79.2783218,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.9467119,
        store_longitude: -79.329411,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.022205,
        store_longitude: -79.4507167,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4422818,
        store_longitude: -79.4631502,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4436938,
        store_longitude: -79.4587783,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4446477,
        store_longitude: -79.4619592,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.02918,
        store_longitude: -79.4465,
    },
    {
        store_latitdue: -1.2057146,
        store_longitude: -79.3158745,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.5493143,
        store_longitude: -79.5325952,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -0.9415077,
        store_longitude: -79.2313594,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.5509283,
        store_longitude: -79.5344103,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -0.940573,
        store_longitude: -79.2292,
    },
    {
        store_latitdue: -1.1162351,
        store_longitude: -79.4362143,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.955689,
        store_longitude: -79.3507,
    },
    {
        store_latitdue: -1.02806,
        store_longitude: -79.444,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.016704,
        store_longitude: -79.462903,
    },
    {
        store_latitdue: -1.44485,
        store_longitude: -79.4681,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.941374185,
        store_longitude: -79.22363439,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.934616,
        store_longitude: -79.2221,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.4444434,
        store_longitude: -79.4587515,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.207402496,
        store_longitude: -79.31449576,
    },
    {
        store_latitdue: -1.0290466,
        store_longitude: -79.448409,
    },
    {
        store_latitdue: -0.9511609,
        store_longitude: -79.3629685,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0124384,
        store_longitude: -79.4466734,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.030505147,
        store_longitude: -79.45324364,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.939828,
        store_longitude: -79.237352,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.56992,
        store_longitude: -79.4723,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.27095,
        store_longitude: -79.3215,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.5481,
        store_longitude: -79.5304,
    },
    {
        store_latitdue: -1.4429548,
        store_longitude: -79.4605391,
    },
    {
        store_latitdue: -1.02016,
        store_longitude: -79.4592,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0288678,
        store_longitude: -79.4533829,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.5499751,
        store_longitude: -79.5352485,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.4468054,
        store_longitude: -79.4628407,
    },
    {
        store_latitdue: -1.5499159,
        store_longitude: -79.5352557,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.4317127,
        store_longitude: -79.2802471,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.9518836,
        store_longitude: -79.3495598,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9488235,
        store_longitude: -79.3650971,
    },
    {
        store_latitdue: -1.1174477,
        store_longitude: -79.4362458,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.5730584,
        store_longitude: -79.468416,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.434986,
        store_longitude: -79.279093,
    },
    {
        store_latitdue: -1.4515088,
        store_longitude: -79.3233192,
    },
    {
        store_latitdue: -0.9657566,
        store_longitude: -79.4225045,
    },
    {
        store_latitdue: -1.0244499,
        store_longitude: -79.4604688,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4510603,
        store_longitude: -79.4664305,
    },
    {
        store_latitdue: -1.2336742,
        store_longitude: -79.2447124,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.54911,
        store_longitude: -79.5323,
    },
    {
        store_latitdue: -0.9375495,
        store_longitude: -79.22380901,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.44252,
        store_longitude: -79.4640302,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.934903,
        store_longitude: -79.2222,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.1371972,
        store_longitude: -79.0803998,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -1.21091,
        store_longitude: -79.3155,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.5474185,
        store_longitude: -79.5297114,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.57209,
        store_longitude: -79.4679,
    },
    {
        store_latitdue: -1.1374597,
        store_longitude: -79.0805547,
    },
    {
        store_latitdue: -1.4439944,
        store_longitude: -79.4625422,
    },
    {
        store_latitdue: -0.91318,
        store_longitude: -79.408,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.923466,
        store_longitude: -79.2263,
    },
    {
        store_latitdue: -1.5705,
        store_longitude: -79.4733,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.1374638,
        store_longitude: -79.0801726,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -1.3590687,
        store_longitude: -79.4171295,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.0919037,
        store_longitude: -79.3309019,
    },
    {
        store_latitdue: -0.9443036,
        store_longitude: -79.2238595,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.116554,
        store_longitude: -79.4362775,
    },
    {
        store_latitdue: -1.4314928,
        store_longitude: -79.2782642,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.942084,
        store_longitude: -79.2231,
    },
    {
        store_latitdue: -1.3682122,
        store_longitude: -79.4706802,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.43945,
        store_longitude: -79.4601,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.2343052,
        store_longitude: -79.2391579,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.4425,
        store_longitude: -79.4602,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.943978,
        store_longitude: -79.2332,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.42627,
        store_longitude: -79.5619,
    },
    {
        store_latitdue: -1.5488414,
        store_longitude: -79.5319025,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -0.950122,
        store_longitude: -79.3101399,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.1369245,
        store_longitude: -79.0808572,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -0.9435633,
        store_longitude: -79.2914292,
    },
    {
        store_latitdue: -0.93793427,
        store_longitude: -79.2354583,
    },
    {
        store_latitdue: -1.5526229,
        store_longitude: -79.5336528,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.4442108,
        store_longitude: -79.4633134,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.1373488,
        store_longitude: -79.0818464,
    },
    {
        store_latitdue: -1.173592,
        store_longitude: -79.2227165,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -0.9408685,
        store_longitude: -79.2235356,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9417972,
        store_longitude: -79.2354106,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9408949,
        store_longitude: -79.2331575,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.3570595,
        store_longitude: -79.4009819,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.4426599,
        store_longitude: -79.4627593,
    },
    {
        store_latitdue: -1.4429954,
        store_longitude: -79.4627756,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9361857,
        store_longitude: -79.2230135,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.116296,
        store_longitude: -79.435355,
    },
    {
        store_latitdue: -0.948403,
        store_longitude: -79.3551,
    },
    {
        store_latitdue: -0.9396905,
        store_longitude: -79.2378125,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.944981,
        store_longitude: -79.237631,
    },
    {
        store_latitdue: -1.57437,
        store_longitude: -79.4681,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.45107,
        store_longitude: -79.4654,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4503104,
        store_longitude: -79.4617824,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.5721642,
        store_longitude: -79.4666123,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.4330454,
        store_longitude: -79.4552111,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.0240887,
        store_longitude: -79.4459962,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.940757,
        store_longitude: -79.224785,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.4436268,
        store_longitude: -79.4583792,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.017564,
        store_longitude: -79.454363,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.030097,
        store_longitude: -79.45641,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9519607,
        store_longitude: -79.3574129,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.7988556,
        store_longitude: -79.3526721,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.25702,
        store_longitude: -79.3732,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.54789,
        store_longitude: -79.5307,
    },
    {
        store_latitdue: -1.445271,
        store_longitude: -79.4637734,
    },
    {
        store_latitdue: -1.0242378,
        store_longitude: -79.4530352,
    },
    {
        store_latitdue: -0.9516816,
        store_longitude: -79.3593946,
    },
    {
        store_latitdue: -0.9373,
        store_longitude: -79.224,
    },
    {
        store_latitdue: -1.2060798,
        store_longitude: -79.313017,
    },
    {
        store_latitdue: -1.02022,
        store_longitude: -79.4550223,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.45279,
        store_longitude: -79.4718,
    },
    {
        store_latitdue: -0.944106,
        store_longitude: -79.3233,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.024874,
        store_longitude: -79.46237,
    },
    {
        store_latitdue: -1.20212065,
        store_longitude: -79.2304694,
    },
    {
        store_latitdue: -1.026214025,
        store_longitude: -79.45876438,
    },
    {
        store_latitdue: -1.005935,
        store_longitude: -79.441482,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.033017,
        store_longitude: -79.4628,
    },
    {
        store_latitdue: -1.4338369,
        store_longitude: -79.4613159,
    },
    {
        store_latitdue: -1.00383,
        store_longitude: -79.442,
    },
    {
        store_latitdue: -0.952981,
        store_longitude: -79.360376,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.4495481,
        store_longitude: -79.4642497,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4383469,
        store_longitude: -79.4621376,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.1135106,
        store_longitude: -79.4367429,
    },
    {
        store_latitdue: -1.0321651,
        store_longitude: -79.4620868,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9433871,
        store_longitude: -79.2294843,
    },
    {
        store_latitdue: -1.4313764,
        store_longitude: -79.2814131,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.2342726,
        store_longitude: -79.2363089,
    },
    {
        store_latitdue: -1.2866668,
        store_longitude: -79.31555,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.03444,
        store_longitude: -79.4629,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.014145,
        store_longitude: -79.457487,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.30794607,
        store_longitude: -79.4747114,
    },
    {
        store_latitdue: -1.4353183,
        store_longitude: -79.2843476,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.9416667,
        store_longitude: -79.2255809,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.028594,
        store_longitude: -79.444231,
    },
    {
        store_latitdue: -1.01584,
        store_longitude: -79.4591,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.948803,
        store_longitude: -79.351756,
    },
    {
        store_latitdue: -0.944731,
        store_longitude: -79.2499,
    },
    {
        store_latitdue: -1.23713,
        store_longitude: -79.2365,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.4432176,
        store_longitude: -79.4586258,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.009568,
        store_longitude: -79.4431697,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9564972,
        store_longitude: -79.3563938,
    },
    {
        store_latitdue: -1.56998,
        store_longitude: -79.4676,
    },
    {
        store_latitdue: -1.4518952,
        store_longitude: -79.4623343,
    },
    {
        store_latitdue: -1.360873,
        store_longitude: -79.4165106,
    },
    {
        store_latitdue: -1.2346734,
        store_longitude: -79.2406651,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.4426066,
        store_longitude: -79.4639087,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.22056,
        store_longitude: -79.3203,
    },
    {
        store_latitdue: -0.9430596,
        store_longitude: -79.2279201,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9362398,
        store_longitude: -79.2230048,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.4425207,
        store_longitude: -79.4627458,
    },
    {
        store_latitdue: -0.9582816,
        store_longitude: -79.3964374,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9433763,
        store_longitude: -79.2748543,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.57345,
        store_longitude: -79.4723,
    },
    {
        store_latitdue: -1.18304,
        store_longitude: -79.246,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.5488658,
        store_longitude: -79.5320432,
    },
    {
        store_latitdue: -1.0235836,
        store_longitude: -79.4608733,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.1152648,
        store_longitude: -79.4353107,
    },
    {
        store_latitdue: -0.941576,
        store_longitude: -79.23564,
    },
    {
        store_latitdue: -0.9657566,
        store_longitude: -79.4225045,
    },
    {
        store_latitdue: -1.0283846,
        store_longitude: -79.3038543,
    },
    {
        store_latitdue: -1.5730579,
        store_longitude: -79.4633829,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.943037,
        store_longitude: -79.234438,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.799313,
        store_longitude: -79.432929,
    },
    {
        store_latitdue: -1.44438,
        store_longitude: -79.4605,
    },
    {
        store_latitdue: -0.9295197,
        store_longitude: -79.2221779,
    },
    {
        store_latitdue: -1.5735866,
        store_longitude: -79.4637131,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.1168004,
        store_longitude: -79.4363003,
    },
    {
        store_latitdue: -0.93345,
        store_longitude: -79.2214537,
    },
    {
        store_latitdue: -1.039809,
        store_longitude: -79.462072,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02837,
        store_longitude: -79.44884,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.943288,
        store_longitude: -79.2344,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.955742649,
        store_longitude: -79.35325323,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.024895,
        store_longitude: -79.461899,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9409959,
        store_longitude: -79.2264077,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9486318,
        store_longitude: -79.3552672,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.2338167,
        store_longitude: -79.2384634,
    },
    {
        store_latitdue: -1.4475,
        store_longitude: -79.4672,
    },
    {
        store_latitdue: -0.963854,
        store_longitude: -79.3057,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.848512,
        store_longitude: -79.370565,
    },
    {
        store_latitdue: -0.9524629,
        store_longitude: -79.3514236,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.431814,
        store_longitude: -79.458021,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9389224,
        store_longitude: -79.2366172,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9460793,
        store_longitude: -79.2237946,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.57458,
        store_longitude: -79.4727,
    },
    {
        store_latitdue: -0.9515915,
        store_longitude: -79.3562317,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.3616233,
        store_longitude: -79.4165191,
    },
    {
        store_latitdue: -1.0219215,
        store_longitude: -79.4547239,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3613493,
        store_longitude: -79.4163795,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -0.9390608,
        store_longitude: -79.2250517,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.942655,
        store_longitude: -79.2576,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.2328665,
        store_longitude: -79.2403131,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -0.9464586,
        store_longitude: -79.226391,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9518788,
        store_longitude: -79.3493384,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.4369347,
        store_longitude: -79.4576152,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.018725258,
        store_longitude: -79.45983346,
    },
    {
        store_latitdue: -1.1064594,
        store_longitude: -79.2682551,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.02517,
        store_longitude: -79.4617,
    },
    {
        store_latitdue: -0.9432632,
        store_longitude: -79.2649064,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.945602,
        store_longitude: -79.234224,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.2036655,
        store_longitude: -79.3127833,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -0.9455955,
        store_longitude: -79.2346844,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.967349,
        store_longitude: -79.4238,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -1.0323108,
        store_longitude: -79.4545758,
    },
    {
        store_latitdue: -1.5666456,
        store_longitude: -79.47343,
    },
    {
        store_latitdue: -1.442315544,
        store_longitude: -79.46532401,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.955944,
        store_longitude: -79.353108,
    },
    {
        store_latitdue: -1.02347,
        store_longitude: -79.4596,
    },
    {
        store_latitdue: -0.9489101,
        store_longitude: -79.3497782,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.025862,
        store_longitude: -79.444891,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0241955,
        store_longitude: -79.4610127,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.96647179,
        store_longitude: -79.3006134,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9398997,
        store_longitude: -79.2218366,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9476295,
        store_longitude: -79.3528759,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.027576,
        store_longitude: -79.459697,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.43455,
        store_longitude: -79.2841,
    },
    {
        store_latitdue: -1.0338414,
        store_longitude: -79.4615055,
    },
    {
        store_latitdue: -1.041958,
        store_longitude: -79.45139,
    },
    {
        store_latitdue: -1.137865,
        store_longitude: -79.0771183,
    },
    {
        store_latitdue: -1.2880602,
        store_longitude: -79.3164831,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.551012432,
        store_longitude: -79.53146387,
    },
    {
        store_latitdue: -1.4441944,
        store_longitude: -79.460779,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.03445,
        store_longitude: -79.4622,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.97776961,
        store_longitude: -79.7562484,
        neighborhood: 'TARIFA',
    },
    {
        store_latitdue: -1.3608017,
        store_longitude: -79.4166179,
    },
    {
        store_latitdue: -1.2827323,
        store_longitude: -79.4286377,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.005156,
        store_longitude: -79.446041,
    },
    {
        store_latitdue: -1.36185,
        store_longitude: -79.4165,
    },
    {
        store_latitdue: -1.0358627,
        store_longitude: -79.460506,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9451234,
        store_longitude: -79.2472361,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.02593,
        store_longitude: -79.4545,
    },
    {
        store_latitdue: -1.4424489,
        store_longitude: -79.4640568,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.946005,
        store_longitude: -79.3350164,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.4717,
        store_longitude: -79.3989,
    },
    {
        store_latitdue: -1.43622,
        store_longitude: -79.4576,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4394314,
        store_longitude: -79.4712268,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.5733395,
        store_longitude: -79.4718889,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.4335574,
        store_longitude: -79.278946,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.2632843,
        store_longitude: -79.4280059,
    },
    {
        store_latitdue: -1.020948,
        store_longitude: -79.452608,
    },
    {
        store_latitdue: -1.0170803,
        store_longitude: -79.4597808,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4404123,
        store_longitude: -79.2882926,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.996131,
        store_longitude: -79.4714,
    },
    {
        store_latitdue: -0.9425235,
        store_longitude: -79.2231545,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.4425669,
        store_longitude: -79.4600658,
    },
    {
        store_latitdue: -1.2047491,
        store_longitude: -79.3119377,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.57327,
        store_longitude: -79.4648,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.9458661,
        store_longitude: -79.3345211,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0397614,
        store_longitude: -79.4618482,
    },
    {
        store_latitdue: -0.951639,
        store_longitude: -79.3491817,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.355197752,
        store_longitude: -79.39550303,
    },
    {
        store_latitdue: -1.57381,
        store_longitude: -79.4667,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.9639257,
        store_longitude: -79.4204605,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -1.1898082,
        store_longitude: -79.5076847,
    },
    {
        store_latitdue: -1.11588,
        store_longitude: -79.435283,
    },
    {
        store_latitdue: -1.4418755,
        store_longitude: -79.4599421,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4453334,
        store_longitude: -79.4668514,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4438624,
        store_longitude: -79.4605119,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9432593,
        store_longitude: -79.2351302,
    },
    {
        store_latitdue: -1.4374791,
        store_longitude: -79.4576745,
    },
    {
        store_latitdue: -0.9393637,
        store_longitude: -79.223835,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0247,
        store_longitude: -79.4594,
    },
    {
        store_latitdue: -0.8821377,
        store_longitude: -79.3338767,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.5711956,
        store_longitude: -79.4724895,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -0.939934,
        store_longitude: -79.2266877,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0318683,
        store_longitude: -79.4621213,
    },
    {
        store_latitdue: -0.8665499,
        store_longitude: -79.3209646,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.5636028,
        store_longitude: -79.4742147,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.57369,
        store_longitude: -79.4668,
    },
    {
        store_latitdue: -1.43709,
        store_longitude: -79.2766,
    },
    {
        store_latitdue: -1.0261286,
        store_longitude: -79.4625659,
    },
    {
        store_latitdue: -0.9434599,
        store_longitude: -79.2651126,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0352228,
        store_longitude: -79.4633353,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4494189,
        store_longitude: -79.4613071,
    },
    {
        store_latitdue: -0.947518,
        store_longitude: -79.2656518,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0307364,
        store_longitude: -79.4492463,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4510317,
        store_longitude: -79.3333715,
    },
    {
        store_latitdue: -1.4456127,
        store_longitude: -79.463889,
    },
    {
        store_latitdue: -0.9643105,
        store_longitude: -79.4212046,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.9439046,
        store_longitude: -79.2426316,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.4331806,
        store_longitude: -79.2762923,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.4300333,
        store_longitude: -79.2846691,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.941652,
        store_longitude: -79.2312,
    },
    {
        store_latitdue: -1.41888076,
        store_longitude: -79.4673943,
    },
    {
        store_latitdue: -0.940866,
        store_longitude: -79.223389,
    },
    {
        store_latitdue: -0.941782,
        store_longitude: -79.225559,
    },
    {
        store_latitdue: -1.4305394,
        store_longitude: -79.2839606,
    },
    {
        store_latitdue: -0.8666317,
        store_longitude: -79.3201978,
    },
    {
        store_latitdue: -1.4798938,
        store_longitude: -79.3742814,
    },
    {
        store_latitdue: -0.9525096,
        store_longitude: -79.3514295,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0255546,
        store_longitude: -79.4489305,
    },
    {
        store_latitdue: -1.0099562,
        store_longitude: -79.4435911,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.125892,
        store_longitude: -79.436411,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.1178156,
        store_longitude: -79.436286,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.1142291,
        store_longitude: -79.4335381,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.5717962,
        store_longitude: -79.4747028,
    },
    {
        store_latitdue: -1.0350601,
        store_longitude: -79.4516538,
    },
    {
        store_latitdue: -1.0290192,
        store_longitude: -79.4613195,
    },
    {
        store_latitdue: -1.2840988,
        store_longitude: -79.313706,
    },
    {
        store_latitdue: -1.5481954,
        store_longitude: -79.5342694,
    },
    {
        store_latitdue: -0.9474893,
        store_longitude: -79.2478326,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9445819,
        store_longitude: -79.2336817,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9418166,
        store_longitude: -79.2335556,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9338788,
        store_longitude: -79.2222502,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0222311,
        store_longitude: -79.4382816,
    },
    {
        store_latitdue: -0.9409107,
        store_longitude: -79.2211449,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.2334641,
        store_longitude: -79.2395777,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.43189,
        store_longitude: -79.2795,
    },
    {
        store_latitdue: -1.4310437,
        store_longitude: -79.2794854,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.4307003,
        store_longitude: -79.2807181,
    },
    {
        store_latitdue: -1.4297055,
        store_longitude: -79.283891,
    },
    {
        store_latitdue: -1.0247033,
        store_longitude: -79.4383423,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.5706407,
        store_longitude: -79.4674242,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.552982,
        store_longitude: -79.533551,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.44959,
        store_longitude: -79.46,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.443636,
        store_longitude: -79.465223,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4433478,
        store_longitude: -79.4659582,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4429759,
        store_longitude: -79.4643278,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4420753,
        store_longitude: -79.4584427,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4467132,
        store_longitude: -79.4604978,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9531019,
        store_longitude: -79.3542961,
    },
    {
        store_latitdue: -0.944886,
        store_longitude: -79.3526888,
    },
    {
        store_latitdue: -0.9493892,
        store_longitude: -79.3561566,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9655468,
        store_longitude: -79.4232102,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.941331,
        store_longitude: -79.230412,
    },
    {
        store_latitdue: -0.9926527,
        store_longitude: -79.4442674,
    },
    {
        store_latitdue: -1.016606,
        store_longitude: -79.460351,
    },
    {
        store_latitdue: -1.015273,
        store_longitude: -79.459674,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0249114,
        store_longitude: -79.4613793,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0240223,
        store_longitude: -79.4608937,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.966319,
        store_longitude: -79.3009,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.992472,
        store_longitude: -79.4389,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02125,
        store_longitude: -79.4565,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9133683,
        store_longitude: -79.4092046,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.913562,
        store_longitude: -79.4093,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0184478,
        store_longitude: -79.4552284,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.944217,
        store_longitude: -79.2283,
    },
    {
        store_latitdue: -1.5537231,
        store_longitude: -79.5332688,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.11642,
        store_longitude: -79.4301,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.036232,
        store_longitude: -79.453777,
    },
    {
        store_latitdue: -1.0235767,
        store_longitude: -79.459645,
    },
    {
        store_latitdue: -0.948165,
        store_longitude: -79.2476505,
    },
    {
        store_latitdue: -0.9336493,
        store_longitude: -79.2189574,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.03579,
        store_longitude: -79.4627,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.23457407,
        store_longitude: -79.239746,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.567484,
        store_longitude: -79.4732471,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -0.9410811,
        store_longitude: -79.2211544,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.233346,
        store_longitude: -79.238927,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.20497,
        store_longitude: -79.3160887,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.2119844,
        store_longitude: -79.3207355,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.4440496,
        store_longitude: -79.4578795,
    },
    {
        store_latitdue: -0.9466853,
        store_longitude: -79.2589995,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.5738316,
        store_longitude: -79.4658344,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.5466964,
        store_longitude: -79.5304278,
    },
    {
        store_latitdue: -1.45028,
        store_longitude: -79.4708,
    },
    {
        store_latitdue: -0.9498572,
        store_longitude: -79.3099253,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9496904,
        store_longitude: -79.3098693,
    },
    {
        store_latitdue: -0.8678581,
        store_longitude: -79.3189721,
    },
    {
        store_latitdue: -0.957729,
        store_longitude: -79.3507,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.952159,
        store_longitude: -79.345551,
    },
    {
        store_latitdue: -0.8412322,
        store_longitude: -79.3698183,
    },
    {
        store_latitdue: -1.2858385,
        store_longitude: -79.315259,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.3614489,
        store_longitude: -79.4165287,
    },
    {
        store_latitdue: -1.4419895,
        store_longitude: -79.459536,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.0253794,
        store_longitude: -79.4631859,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0289441,
        store_longitude: -79.4498912,
    },
    {
        store_latitdue: -0.945979,
        store_longitude: -79.335339,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.022642,
        store_longitude: -79.459547,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.949298,
        store_longitude: -79.2597,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.03712,
        store_longitude: -79.4626,
    },
    {
        store_latitdue: -0.931592,
        store_longitude: -79.22212,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.2060788,
        store_longitude: -79.3137342,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -0.956723,
        store_longitude: -79.352,
    },
    {
        store_latitdue: -1.207222,
        store_longitude: -79.3146895,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -0.9531876,
        store_longitude: -79.3554355,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.4475,
        store_longitude: -79.4603,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.1135155,
        store_longitude: -79.4358974,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.2357047,
        store_longitude: -79.2394921,
    },
    {
        store_latitdue: -1.4392833,
        store_longitude: -79.4597092,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.04152477,
        store_longitude: -79.47785079,
    },
    {
        store_latitdue: -0.9419688,
        store_longitude: -79.2372068,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9996,
        store_longitude: -79.4428324,
    },
    {
        store_latitdue: -1.1741783,
        store_longitude: -79.2234129,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.2035353,
        store_longitude: -79.3127881,
    },
    {
        store_latitdue: -1.0060513,
        store_longitude: -79.4403555,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.450665,
        store_longitude: -79.463146,
    },
    {
        store_latitdue: -1.0232656,
        store_longitude: -79.4386683,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4470438,
        store_longitude: -79.461482,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.029254,
        store_longitude: -79.451899,
    },
    {
        store_latitdue: -1.571866,
        store_longitude: -79.471883,
    },
    {
        store_latitdue: -1.45059,
        store_longitude: -79.4714,
    },
    {
        store_latitdue: -0.9453042,
        store_longitude: -79.2342734,
    },
    {
        store_latitdue: -1.2357759,
        store_longitude: -79.2376737,
    },
    {
        store_latitdue: -1.2695963,
        store_longitude: -79.3208273,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -0.9545003,
        store_longitude: -79.3475228,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.946817,
        store_longitude: -79.2299,
    },
    {
        store_latitdue: -1.445905,
        store_longitude: -79.461086,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.938394,
        store_longitude: -79.2162296,
    },
    {
        store_latitdue: -1.0212289,
        store_longitude: -79.4592742,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0320555,
        store_longitude: -79.4536878,
    },
    {
        store_latitdue: -1.0358447,
        store_longitude: -79.4639387,
    },
    {
        store_latitdue: -0.9406241,
        store_longitude: -79.3249849,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.036826,
        store_longitude: -79.453644,
    },
    {
        store_latitdue: -1.025858,
        store_longitude: -79.461133,
    },
    {
        store_latitdue: -1.1742503,
        store_longitude: -79.2237191,
    },
    {
        store_latitdue: -1.0224044,
        store_longitude: -79.4580611,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.048969,
        store_longitude: -79.3032003,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.1741698,
        store_longitude: -79.4353301,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.025363,
        store_longitude: -79.454077,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.5722403,
        store_longitude: -79.4717094,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.4482601,
        store_longitude: -79.4644447,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.038707,
        store_longitude: -79.4581,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.031221,
        store_longitude: -79.459495,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.13944,
        store_longitude: -79.2681,
    },
    {
        store_latitdue: -1.5701535,
        store_longitude: -79.474641,
    },
    {
        store_latitdue: -0.927437,
        store_longitude: -79.2258,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.5705945,
        store_longitude: -79.4726338,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -0.9483275,
        store_longitude: -79.3344982,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.008984,
        store_longitude: -79.44311,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.797578,
        store_longitude: -79.352482,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9435184,
        store_longitude: -79.2793403,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.57455,
        store_longitude: -79.4648,
    },
    {
        store_latitdue: -0.946382,
        store_longitude: -79.3293,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.1563983,
        store_longitude: -79.2571672,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -0.950737,
        store_longitude: -79.35628,
    },
    {
        store_latitdue: -0.9612349,
        store_longitude: -79.4216021,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.94073337,
        store_longitude: -79.2282867,
    },
    {
        store_latitdue: -1.4453022,
        store_longitude: -79.4589906,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.5714651,
        store_longitude: -79.4763896,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.4482273,
        store_longitude: -79.4635268,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.020038,
        store_longitude: -79.459206,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.940057,
        store_longitude: -79.237442,
    },
    {
        store_latitdue: -1.0281621,
        store_longitude: -79.4583841,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0389819,
        store_longitude: -79.4618924,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4474676,
        store_longitude: -79.4619908,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.01053,
        store_longitude: -79.4441,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4497805,
        store_longitude: -79.4615662,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4507741,
        store_longitude: -79.4606496,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.027821,
        store_longitude: -79.456841,
    },
    {
        store_latitdue: -1.447006,
        store_longitude: -79.4661726,
    },
    {
        store_latitdue: -1.0257373,
        store_longitude: -79.4545149,
    },
    {
        store_latitdue: -1.43462,
        store_longitude: -79.4579,
    },
    {
        store_latitdue: -1.2709933,
        store_longitude: -79.3219692,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.0299987,
        store_longitude: -79.4489627,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.5521797,
        store_longitude: -79.5322524,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.261669,
        store_longitude: -79.2746,
    },
    {
        store_latitdue: -0.947344,
        store_longitude: -79.3516,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.443378,
        store_longitude: -79.4588,
    },
    {
        store_latitdue: -0.936257,
        store_longitude: -79.2261837,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0474222,
        store_longitude: -79.4729072,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9413398,
        store_longitude: -79.2285002,
    },
    {
        store_latitdue: -1.029055,
        store_longitude: -79.457783,
    },
    {
        store_latitdue: -1.44525,
        store_longitude: -79.4661,
    },
    {
        store_latitdue: -2.0724222,
        store_longitude: -79.9217142,
    },
    {
        store_latitdue: -1.1096758,
        store_longitude: -79.2659848,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.029009,
        store_longitude: -79.456922,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.960887,
        store_longitude: -79.4205,
    },
    {
        store_latitdue: -1.024464,
        store_longitude: -79.45857,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.952822,
        store_longitude: -79.355303,
    },
    {
        store_latitdue: -1.025785,
        store_longitude: -79.440415,
    },
    {
        store_latitdue: -1.02575,
        store_longitude: -79.4543,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.021961,
        store_longitude: -79.459126,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.2898068,
        store_longitude: -79.4244898,
    },
    {
        store_latitdue: -1.43659,
        store_longitude: -79.4596,
    },
    {
        store_latitdue: -1.444477,
        store_longitude: -79.463686,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4333249,
        store_longitude: -79.455231,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.44527,
        store_longitude: -79.4596,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.963322,
        store_longitude: -79.420839,
    },
    {
        store_latitdue: -1.02469079,
        store_longitude: -79.451934,
    },
    {
        store_latitdue: -0.940087,
        store_longitude: -79.2316,
    },
    {
        store_latitdue: -1.1754304,
        store_longitude: -79.2264383,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.0168569,
        store_longitude: -79.462661,
        neighborhood: 'QUEVEDO',
    },

    {
        store_latitdue: -1.3543176,
        store_longitude: -79.4023999,
    },
    {
        store_latitdue: -0.9667744,
        store_longitude: -79.4246365,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -1.01767922,
        store_longitude: -79.4579207,
    },
    {
        store_latitdue: -1.26552,
        store_longitude: -79.3947,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -0.944467,
        store_longitude: -79.2487464,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.024674,
        store_longitude: -79.448706,
    },
    {
        store_latitdue: -1.429951,
        store_longitude: -79.2840128,
    },
    {
        store_latitdue: -1.0304556,
        store_longitude: -79.4609686,
    },
    {
        store_latitdue: -1.032,
        store_longitude: -79.45262,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.11609,
        store_longitude: -79.4359,
    },
    {
        store_latitdue: -1.0235991,
        store_longitude: -79.4595268,
    },
    {
        store_latitdue: -1.029043,
        store_longitude: -79.450278,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0232,
        store_longitude: -79.4493,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.24278,
        store_longitude: -79.2489,
    },
    {
        store_latitdue: -1.4478236,
        store_longitude: -79.4650169,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.0368969,
        store_longitude: -79.4566827,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.119681,
        store_longitude: -79.436114,
    },
    {
        store_latitdue: -1.5524504,
        store_longitude: -79.5351994,
    },
    {
        store_latitdue: -1.4450899,
        store_longitude: -79.4590066,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9428771,
        store_longitude: -79.2550441,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.1368,
        store_longitude: -79.0834,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -1.56927,
        store_longitude: -79.4724,
    },
    {
        store_latitdue: -0.9473156,
        store_longitude: -79.223866,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.5715184,
        store_longitude: -79.465322,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.9336239,
        store_longitude: -79.2224174,
    },
    {
        store_latitdue: -1.4449022,
        store_longitude: -79.4592967,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.443426,
        store_longitude: -79.4578714,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.839447,
        store_longitude: -79.3696,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.949825,
        store_longitude: -79.355165,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9489557,
        store_longitude: -79.3517326,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.444911,
        store_longitude: -79.466576,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4510296,
        store_longitude: -79.4615626,
    },
    {
        store_latitdue: -0.9319638,
        store_longitude: -79.2227784,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.44772,
        store_longitude: -79.4658,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.0284304,
        store_longitude: -79.4628548,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0285414,
        store_longitude: -79.4603742,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9471517,
        store_longitude: -79.2286115,
    },
    {
        store_latitdue: -1.44876,
        store_longitude: -79.4659503,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4477486,
        store_longitude: -79.4660875,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.2358984,
        store_longitude: -79.2396399,
    },
    {
        store_latitdue: -1.1151154,
        store_longitude: -79.435342,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.4469,
        store_longitude: -79.4621,
    },
    {
        store_latitdue: -1.4478,
        store_longitude: -79.4661,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.551260346,
        store_longitude: -79.53481088,
    },
    {
        store_latitdue: -1.451489317,
        store_longitude: -79.46317694,
    },
    {
        store_latitdue: -1.21596,
        store_longitude: -79.423,
    },
    {
        store_latitdue: -1.2337209,
        store_longitude: -79.239721,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -0.9413804,
        store_longitude: -79.2230948,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.02695,
        store_longitude: -79.4603,
    },
    {
        store_latitdue: -1.0404466,
        store_longitude: -79.4562483,
    },
    {
        store_latitdue: -1.445908563,
        store_longitude: -79.45945532,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4322674,
        store_longitude: -79.2781921,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.9430763,
        store_longitude: -79.2610399,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0315628,
        store_longitude: -79.4615377,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3603771,
        store_longitude: -79.4167546,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -0.9506191,
        store_longitude: -79.3499617,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.56741,
        store_longitude: -79.473,
    },
    {
        store_latitdue: -0.9428964,
        store_longitude: -79.2279431,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.114979,
        store_longitude: -79.433541,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.939521,
        store_longitude: -79.223759,
    },
    {
        store_latitdue: -0.955271,
        store_longitude: -79.3562,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.017106,
        store_longitude: -79.454808,
    },
    {
        store_latitdue: -0.9414133,
        store_longitude: -79.2304808,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9426972,
        store_longitude: -79.2608221,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.037443,
        store_longitude: -79.4574,
    },
    {
        store_latitdue: -1.02266,
        store_longitude: -79.451627,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9410894,
        store_longitude: -79.2228484,
    },
    {
        store_latitdue: -0.946286,
        store_longitude: -79.2253,
    },
    {
        store_latitdue: -1.0311043,
        store_longitude: -79.4621511,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.57915,
        store_longitude: -79.4667,
    },
    {
        store_latitdue: -1.569999594,
        store_longitude: -79.46876067,
    },
    {
        store_latitdue: -1.4432033,
        store_longitude: -79.459005,
    },
    {
        store_latitdue: -0.9632198,
        store_longitude: -79.4208496,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.929517,
        store_longitude: -79.2221,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.01318,
        store_longitude: -79.4423,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9483193,
        store_longitude: -79.3338172,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.029347,
        store_longitude: -79.460925,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4394156,
        store_longitude: -79.4693352,
    },
    {
        store_latitdue: -1.445999,
        store_longitude: -79.4582487,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.0234072,
        store_longitude: -79.4544875,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.116,
        store_longitude: -79.4353,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.9320665,
        store_longitude: -79.2188364,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9428633,
        store_longitude: -79.3255301,
    },
    {
        store_latitdue: -1.4331982,
        store_longitude: -79.4552007,
    },
    {
        store_latitdue: -1.56551,
        store_longitude: -79.4743,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -0.9469755,
        store_longitude: -79.348314,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.173397,
        store_longitude: -79.22244,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.44627,
        store_longitude: -79.463,
    },
    {
        store_latitdue: -1.1143792,
        store_longitude: -79.4366199,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.945125,
        store_longitude: -79.3302,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.02846,
        store_longitude: -79.4621,
    },
    {
        store_latitdue: -0.94991,
        store_longitude: -79.3099,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.57357,
        store_longitude: -79.4725,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.2862398,
        store_longitude: -79.3177195,
    },
    {
        store_latitdue: -0.9423912,
        store_longitude: -79.2258592,
    },
    {
        store_latitdue: -1.5723905,
        store_longitude: -79.4680184,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.4303653,
        store_longitude: -79.2816822,
    },
    {
        store_latitdue: -1.026178,
        store_longitude: -79.448795,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02374,
        store_longitude: -79.4584,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.2680793,
        store_longitude: -79.4258635,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.02462,
        store_longitude: -79.4522,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9444014,
        store_longitude: -79.2273532,
    },
    {
        store_latitdue: -0.965068,
        store_longitude: -79.420311,
    },
    {
        store_latitdue: -1.023992,
        store_longitude: -79.445306,
    },
    {
        store_latitdue: -1.5726142,
        store_longitude: -79.468929,
    },
    {
        store_latitdue: -1.1156678,
        store_longitude: -79.4375964,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.9445712,
        store_longitude: -79.2354307,
    },
    {
        store_latitdue: -1.031604,
        store_longitude: -79.456639,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.26503,
        store_longitude: -79.3952,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -0.9462175,
        store_longitude: -79.3538546,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.95479589,
        store_longitude: -79.3530654,
    },
    {
        store_latitdue: -0.949052,
        store_longitude: -79.2511,
    },
    {
        store_latitdue: -0.945266,
        store_longitude: -79.35145,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.4343823,
        store_longitude: -79.2776968,
    },
    {
        store_latitdue: -0.944881,
        store_longitude: -79.3362,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0266932,
        store_longitude: -79.4517326,
    },
    {
        store_latitdue: -1.0222857,
        store_longitude: -79.4619275,
    },
    {
        store_latitdue: -1.2865611,
        store_longitude: -79.313767,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.027474994,
        store_longitude: -79.4499408,
    },
    {
        store_latitdue: -1.00512,
        store_longitude: -79.4465,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.038254,
        store_longitude: -79.462351,
    },
    {
        store_latitdue: -0.9488433,
        store_longitude: -79.352432,
    },
    {
        store_latitdue: -1.1353546,
        store_longitude: -79.0845435,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.951929,
        store_longitude: -79.3517,
    },
    {
        store_latitdue: -0.9433394,
        store_longitude: -79.2325868,
    },
    {
        store_latitdue: -1.02946,
        store_longitude: -79.4567,
    },
    {
        store_latitdue: -0.9416629,
        store_longitude: -79.2327181,
    },
    {
        store_latitdue: -1.1371916,
        store_longitude: -79.0807093,
    },
    {
        store_latitdue: -1.0253614,
        store_longitude: -79.4497785,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9394756,
        store_longitude: -79.2278069,
    },
    {
        store_latitdue: -1.025417,
        store_longitude: -79.454187,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.948681,
        store_longitude: -79.334,
    },
    {
        store_latitdue: -0.943428,
        store_longitude: -79.233689,
    },
    {
        store_latitdue: -0.9367026,
        store_longitude: -79.2240597,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.7177366,
        store_longitude: -79.1155505,
        neighborhood: 'PUCAYACU',
    },
    {
        store_latitdue: -1.3543661,
        store_longitude: -79.4023612,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -0.940703,
        store_longitude: -79.221157,
    },
    {
        store_latitdue: -0.94176471,
        store_longitude: -79.2354583,
    },
    {
        store_latitdue: -0.947379,
        store_longitude: -79.3361,
    },
    {
        store_latitdue: -1.0285836,
        store_longitude: -79.460495,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.027681,
        store_longitude: -79.453233,
    },
    {
        store_latitdue: -1.030647,
        store_longitude: -79.4601471,
    },
    {
        store_latitdue: -0.9423984,
        store_longitude: -79.2230668,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.94419,
        store_longitude: -79.2256,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9522275,
        store_longitude: -79.3447127,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.3590687,
        store_longitude: -79.4171295,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.21583,
        store_longitude: -79.3175,
    },
    {
        store_latitdue: -1.4441018,
        store_longitude: -79.4589516,
    },
    {
        store_latitdue: -1.4444478,
        store_longitude: -79.4584603,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.024893,
        store_longitude: -79.462002,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.023588,
        store_longitude: -79.448033,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0227278,
        store_longitude: -79.4557265,
    },
    {
        store_latitdue: -1.03696,
        store_longitude: -79.45669,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4333145,
        store_longitude: -79.2838169,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.025562,
        store_longitude: -79.459414,
    },
    {
        store_latitdue: -0.8048377,
        store_longitude: -79.1607766,
        neighborhood: 'GUASAGANDA',
    },
    {
        store_latitdue: -0.940453,
        store_longitude: -79.2291,
    },
    {
        store_latitdue: -1.02637,
        store_longitude: -79.452048,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.864626,
        store_longitude: -79.375146,
    },
    {
        store_latitdue: -1.030829,
        store_longitude: -79.456725,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4473972,
        store_longitude: -79.46541,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4425675,
        store_longitude: -79.4591059,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.44723,
        store_longitude: -79.4636,
    },
    {
        store_latitdue: -1.03111,
        store_longitude: -79.4542,
    },
    {
        store_latitdue: -1.005682,
        store_longitude: -79.446606,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.18891,
        store_longitude: -79.5006,
    },
    {
        store_latitdue: -0.9264922,
        store_longitude: -79.2218674,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.4316772,
        store_longitude: -79.2805487,
    },
    {
        store_latitdue: -1.4446649,
        store_longitude: -79.4678429,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.1742107,
        store_longitude: -79.2222274,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.4347489,
        store_longitude: -79.2820124,
    },
    {
        store_latitdue: -1.1164426,
        store_longitude: -79.4362429,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.135914,
        store_longitude: -79.0819662,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -1.2356652,
        store_longitude: -79.2379754,
    },
    {
        store_latitdue: -1.1896333,
        store_longitude: -79.5066357,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.4334736,
        store_longitude: -79.2840088,
    },
    {
        store_latitdue: -1.1736164,
        store_longitude: -79.2212603,
    },
    {
        store_latitdue: -1.27024,
        store_longitude: -79.319,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.269817,
        store_longitude: -79.3189195,
    },
    {
        store_latitdue: -1.2882206,
        store_longitude: -79.316841,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.02732,
        store_longitude: -79.4628,
    },
    {
        store_latitdue: -1.1365763,
        store_longitude: -79.0816822,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -1.1357084,
        store_longitude: -79.0798032,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -1.20586,
        store_longitude: -79.3145,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.20843,
        store_longitude: -79.3109,
    },
    {
        store_latitdue: -0.757124,
        store_longitude: -79.1389,
        neighborhood: 'GUASAGANDA',
    },
    {
        store_latitdue: -1.34296,
        store_longitude: -79.4168,
    },
    {
        store_latitdue: -1.3605442,
        store_longitude: -79.4165835,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -0.3302,
        store_longitude: -78.426763,
    },
    {
        store_latitdue: -1.3596141,
        store_longitude: -79.4167564,
    },
    {
        store_latitdue: -1.4283727,
        store_longitude: -79.3756129,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9505536,
        store_longitude: -79.3565638,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.965194,
        store_longitude: -79.4205,
    },
    {
        store_latitdue: -1.4442528,
        store_longitude: -79.4589745,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9551646,
        store_longitude: -79.3487773,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.945968,
        store_longitude: -79.3344,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0157551,
        store_longitude: -79.4602612,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.44889,
        store_longitude: -79.462,
    },
    {
        store_latitdue: -0.799237,
        store_longitude: -79.3550073,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.8482029,
        store_longitude: -79.3707506,
    },
    {
        store_latitdue: -1.2068,
        store_longitude: -79.3127,
    },
    {
        store_latitdue: -0.9133205,
        store_longitude: -79.4090268,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.01232,
        store_longitude: -79.4464,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.950481,
        store_longitude: -79.35732,
    },
    {
        store_latitdue: -0.9505528,
        store_longitude: -79.3550897,
    },
    {
        store_latitdue: -0.840094,
        store_longitude: -79.369715,
    },
    {
        store_latitdue: -1.4313356,
        store_longitude: -79.2783972,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.9506636,
        store_longitude: -79.3555022,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.035016,
        store_longitude: -79.455524,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.931288,
        store_longitude: -79.2225,
    },
    {
        store_latitdue: -1.1231146,
        store_longitude: -79.4378841,
    },
    {
        store_latitdue: -1.123219273,
        store_longitude: -79.43706936,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.028197,
        store_longitude: -79.455848,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.11645,
        store_longitude: -79.4338,
    },
    {
        store_latitdue: -1.02171,
        store_longitude: -79.4556,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.12004,
        store_longitude: -79.437,
    },
    {
        store_latitdue: -1.17371,
        store_longitude: -79.223,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.20915,
        store_longitude: -79.3129,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.11887,
        store_longitude: -79.4302,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.0683462,
        store_longitude: -79.431529,
    },
    {
        store_latitdue: -1.02432,
        store_longitude: -79.4487,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04026,
        store_longitude: -79.4562,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.26848,
        store_longitude: -79.4257,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.04026,
        store_longitude: -79.4541,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03835,
        store_longitude: -79.4594,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0332,
        store_longitude: -79.4543,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03497,
        store_longitude: -79.4518,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.025798,
        store_longitude: -79.445316,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.943437,
        store_longitude: -79.3236,
    },
    {
        store_latitdue: -1.02693,
        store_longitude: -79.4467,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02854,
        store_longitude: -79.4499,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9463869,
        store_longitude: -79.2438089,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9411271,
        store_longitude: -79.2223326,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.92737,
        store_longitude: -79.053204,
        neighborhood: 'TINGO',
    },
    {
        store_latitdue: -1.02082,
        store_longitude: -79.46771,
    },
    {
        store_latitdue: -0.9401034,
        store_longitude: -79.2395224,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.946809,
        store_longitude: -79.2715,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.02794,
        store_longitude: -79.4558,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9447492,
        store_longitude: -79.3244257,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.940648,
        store_longitude: -79.2296,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9514039,
        store_longitude: -79.2708175,
    },
    {
        store_latitdue: -0.9456295,
        store_longitude: -79.3254178,
    },
    {
        store_latitdue: -0.718066,
        store_longitude: -79.1193,
        neighborhood: 'PUCAYACU',
    },
    {
        store_latitdue: -0.718131,
        store_longitude: -79.119,
        neighborhood: 'PUCAYACU',
    },
    {
        store_latitdue: -0.7179613,
        store_longitude: -79.1193271,
        neighborhood: 'PUCAYACU',
    },
    {
        store_latitdue: -0.7184997,
        store_longitude: -79.1188153,
        neighborhood: 'PUCAYACU',
    },
    {
        store_latitdue: -0.939109,
        store_longitude: -79.2228,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.94257909,
        store_longitude: -79.2230987,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0242409,
        store_longitude: -79.4590012,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9406684,
        store_longitude: -79.2210388,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9422785,
        store_longitude: -79.2231588,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.137173,
        store_longitude: -79.081055,
    },
    {
        store_latitdue: -1.1942045,
        store_longitude: -79.2573703,
    },
    {
        store_latitdue: -1.0080587,
        store_longitude: -79.4440476,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.35649,
        store_longitude: -79.401,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -0.94865,
        store_longitude: -79.2301717,
    },
    {
        store_latitdue: -1.1164,
        store_longitude: -79.4303,
    },
    {
        store_latitdue: -1.228256,
        store_longitude: -79.3421383,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.038852,
        store_longitude: -79.456534,
    },
    {
        store_latitdue: -1.023677,
        store_longitude: -79.452648,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.44653,
        store_longitude: -79.4613,
    },
    {
        store_latitdue: -1.43322,
        store_longitude: -79.2832,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.43291,
        store_longitude: -79.2766,
    },
    {
        store_latitdue: -1.19729,
        store_longitude: -79.5075,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1168917,
        store_longitude: -79.4383894,
    },
    {
        store_latitdue: -1.0682535,
        store_longitude: -79.4317927,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.5748394,
        store_longitude: -79.4633813,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.5706861,
        store_longitude: -79.4753149,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.3645135,
        store_longitude: -79.37663,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.5715677,
        store_longitude: -79.4679922,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.570479,
        store_longitude: -79.468181,
    },
    {
        store_latitdue: -0.9422586,
        store_longitude: -79.268294,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.44429,
        store_longitude: -79.4677,
    },
    {
        store_latitdue: -1.4362564,
        store_longitude: -79.4646912,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.434899001,
        store_longitude: -79.45764118,
    },
    {
        store_latitdue: -1.46028,
        store_longitude: -79.4719,
    },
    {
        store_latitdue: -1.434846,
        store_longitude: -79.453925,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4372347,
        store_longitude: -79.4569552,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4377084,
        store_longitude: -79.4578141,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4436786,
        store_longitude: -79.4605861,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4435978,
        store_longitude: -79.4584238,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4378,
        store_longitude: -79.4603,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.44399,
        store_longitude: -79.4703,
    },
    {
        store_latitdue: -1.11511,
        store_longitude: -79.4354,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.4486815,
        store_longitude: -79.4634813,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.47939,
        store_longitude: -79.3991,
    },
    {
        store_latitdue: -1.4395031,
        store_longitude: -79.4698019,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4386357,
        store_longitude: -79.4705842,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.5475502,
        store_longitude: -79.5326416,
    },
    {
        store_latitdue: -1.5557,
        store_longitude: -79.5312,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.5479813,
        store_longitude: -79.5335346,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -0.951429,
        store_longitude: -79.3358,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.948063,
        store_longitude: -79.3481716,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.943687,
        store_longitude: -79.332961,
    },
    {
        store_latitdue: -0.9485654,
        store_longitude: -79.2255334,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.946126,
        store_longitude: -79.3345,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9527156,
        store_longitude: -79.3515278,
    },
    {
        store_latitdue: -0.944468,
        store_longitude: -79.3356,
    },
    {
        store_latitdue: -0.9466874,
        store_longitude: -79.2415776,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.05965,
        store_longitude: -79.4884,
    },
    {
        store_latitdue: -0.9433236,
        store_longitude: -79.3242624,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.951713,
        store_longitude: -79.3489,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.1738127,
        store_longitude: -79.2235408,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.00891,
        store_longitude: -79.4435,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9520884,
        store_longitude: -79.3641703,
    },
    {
        store_latitdue: -0.958267,
        store_longitude: -79.3966,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9644911,
        store_longitude: -79.4232257,
    },
    {
        store_latitdue: -0.9646079,
        store_longitude: -79.4231614,
    },
    {
        store_latitdue: -0.9657566,
        store_longitude: -79.4225045,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -1.0075,
        store_longitude: -79.4419,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.945536,
        store_longitude: -79.3543,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9518905,
        store_longitude: -79.3494108,
    },
    {
        store_latitdue: -0.945957,
        store_longitude: -79.3349,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.00884,
        store_longitude: -79.4438,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0230468,
        store_longitude: -79.462968,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.948097,
        store_longitude: -79.3479,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.5486185,
        store_longitude: -79.5363376,
    },
    {
        store_latitdue: -1.5648688,
        store_longitude: -79.4734069,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.261934,
        store_longitude: -79.4279624,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.36089,
        store_longitude: -79.4165,
    },
    {
        store_latitdue: -1.5500122,
        store_longitude: -79.5343708,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.5652253,
        store_longitude: -79.4733518,
    },
    {
        store_latitdue: -1.5647087,
        store_longitude: -79.4734302,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.54762,
        store_longitude: -79.5338,
    },
    {
        store_latitdue: -0.965893,
        store_longitude: -79.4227,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -1.55385,
        store_longitude: -79.5333,
    },
    {
        store_latitdue: -1.5505,
        store_longitude: -79.5358,
    },
    {
        store_latitdue: -1.5483541,
        store_longitude: -79.5312834,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -0.9425983,
        store_longitude: -79.226305,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.028629,
        store_longitude: -79.443822,
    },
    {
        store_latitdue: -1.0263121,
        store_longitude: -79.4454162,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4248985,
        store_longitude: -79.5598061,
        neighborhood: 'PUERTO PECHICHE',
    },
    {
        store_latitdue: -1.02403,
        store_longitude: -79.4416,
    },
    {
        store_latitdue: -1.5701536,
        store_longitude: -79.4731075,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.285293718,
        store_longitude: -79.31416032,
    },
    {
        store_latitdue: -1.4344907,
        store_longitude: -79.2776829,
    },
    {
        store_latitdue: -1.43095,
        store_longitude: -79.2751,
    },
    {
        store_latitdue: -0.9508812,
        store_longitude: -79.2460583,
    },
    {
        store_latitdue: -0.94544446,
        store_longitude: -79.2286682,
    },
    {
        store_latitdue: -0.926063,
        store_longitude: -79.223,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9329634,
        store_longitude: -79.2223736,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.943476,
        store_longitude: -79.2288,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9428179,
        store_longitude: -79.2291301,
    },
    {
        store_latitdue: -1.4465292,
        store_longitude: -79.4620996,
    },
    {
        store_latitdue: -0.9471952,
        store_longitude: -79.3013788,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9400302,
        store_longitude: -79.2271135,
    },
    {
        store_latitdue: -0.945929,
        store_longitude: -79.2238,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.946172,
        store_longitude: -79.2402,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9434286,
        store_longitude: -79.2239252,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: 0.326617,
        store_longitude: -78.1354,
    },
    {
        store_latitdue: -0.941324,
        store_longitude: -79.2294,
    },
    {
        store_latitdue: -1.4425115,
        store_longitude: -79.4640727,
    },
    {
        store_latitdue: -1.206,
        store_longitude: -79.3136,
    },
    {
        store_latitdue: -1.2577245,
        store_longitude: -79.3736209,
    },
    {
        store_latitdue: -1.2003052,
        store_longitude: -79.3334025,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.1087225,
        store_longitude: -79.266329,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.26545,
        store_longitude: -79.3915217,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -0.945485,
        store_longitude: -79.257065,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9280999,
        store_longitude: -79.3060685,
    },
    {
        store_latitdue: -1.03235,
        store_longitude: -79.4583,
    },
    {
        store_latitdue: -1.036561,
        store_longitude: -79.453489,
    },
    {
        store_latitdue: -1.03732,
        store_longitude: -79.4522,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.2579293,
        store_longitude: -79.373528,
    },
    {
        store_latitdue: -1.2078001,
        store_longitude: -79.3179566,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.2823137,
        store_longitude: -79.3133797,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.18777,
        store_longitude: -79.5014,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.2069273,
        store_longitude: -79.3122739,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.448335,
        store_longitude: -79.4644347,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.2119844,
        store_longitude: -79.3207355,
    },
    {
        store_latitdue: -1.03152,
        store_longitude: -79.4523,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02879,
        store_longitude: -79.4491,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0225124,
        store_longitude: -79.4604035,
    },
    {
        store_latitdue: -1.0248708,
        store_longitude: -79.4481074,
    },
    {
        store_latitdue: -1.20078,
        store_longitude: -79.4202,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.7074,
        store_longitude: -79.3924,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.940267,
        store_longitude: -79.2238,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9475681,
        store_longitude: -79.2292147,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.443764,
        store_longitude: -79.4622462,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.43541,
        store_longitude: -79.4553,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.030865764,
        store_longitude: -79.46373389,
    },
    {
        store_latitdue: -1.0181143,
        store_longitude: -79.4603523,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4468036,
        store_longitude: -79.460748,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.0259622,
        store_longitude: -79.4634697,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4501257,
        store_longitude: -79.4607075,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.951153,
        store_longitude: -79.3615283,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.44941,
        store_longitude: -79.4626,
    },
    {
        store_latitdue: -1.01788,
        store_longitude: -79.4555,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9420174,
        store_longitude: -79.3401241,
    },
    {
        store_latitdue: -1.059427,
        store_longitude: -79.3251834,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.02289,
        store_longitude: -79.451,
    },
    {
        store_latitdue: -1.02932,
        store_longitude: -79.4621,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9442156,
        store_longitude: -79.2263272,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9539889,
        store_longitude: -79.3496589,
    },
    {
        store_latitdue: -1.01484394,
        store_longitude: -79.4615249,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.01698,
        store_longitude: -79.4598,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.023496,
        store_longitude: -79.4611946,
    },
    {
        store_latitdue: -1.02392,
        store_longitude: -79.4625,
    },
    {
        store_latitdue: -0.953143,
        store_longitude: -79.3571493,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.5723085,
        store_longitude: -79.4673813,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.5494876,
        store_longitude: -79.5317307,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.57095,
        store_longitude: -79.4736,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.11832,
        store_longitude: -79.4312,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.1148,
        store_longitude: -79.4334,
    },
    {
        store_latitdue: -1.55042,
        store_longitude: -79.5336,
    },
    {
        store_latitdue: -1.4386024,
        store_longitude: -79.4582751,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.5712965,
        store_longitude: -79.4730022,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.62542,
        store_longitude: -79.561,
    },
    {
        store_latitdue: -0.939956,
        store_longitude: -79.2228,
    },
    {
        store_latitdue: -1.57188,
        store_longitude: -79.4718,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.42935,
        store_longitude: -79.2782,
    },
    {
        store_latitdue: -0.9358577,
        store_longitude: -79.2248949,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9013485,
        store_longitude: -79.1622262,
    },
    {
        store_latitdue: -0.917711,
        store_longitude: -79.1676547,
        neighborhood: 'TINGO',
    },
    {
        store_latitdue: -0.9362323,
        store_longitude: -79.1837754,
        neighborhood: 'TINGO',
    },
    {
        store_latitdue: -0.717774,
        store_longitude: -79.1165,
    },
    {
        store_latitdue: -0.7573391,
        store_longitude: -79.1390842,
        neighborhood: 'GUASAGANDA',
    },
    {
        store_latitdue: -0.7184936,
        store_longitude: -79.1192741,
        neighborhood: 'PUCAYACU',
    },
    {
        store_latitdue: -1.036103,
        store_longitude: -79.452221,
    },
    {
        store_latitdue: -0.9479959,
        store_longitude: -79.347767,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.4497917,
        store_longitude: -79.4608133,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.940876,
        store_longitude: -79.2262,
    },
    {
        store_latitdue: -0.940831948,
        store_longitude: -79.22316136,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9931743,
        store_longitude: -79.43815,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9443362,
        store_longitude: -79.2264252,
    },
    {
        store_latitdue: -1.26722,
        store_longitude: -79.3823,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.35361,
        store_longitude: -79.4175,
    },
    {
        store_latitdue: -1.0406643,
        store_longitude: -79.454518,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.2088274,
        store_longitude: -79.3108812,
    },
    {
        store_latitdue: -1.1720916,
        store_longitude: -79.219791,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.1744169,
        store_longitude: -79.222923,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.024079,
        store_longitude: -79.4489386,
    },
    {
        store_latitdue: -1.20035,
        store_longitude: -79.3336,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.0294893,
        store_longitude: -79.4547252,
    },
    {
        store_latitdue: -1.0356705,
        store_longitude: -79.4523501,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0369934,
        store_longitude: -79.4627431,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.224673,
        store_longitude: -79.3373484,
    },
    {
        store_latitdue: -1.029433,
        store_longitude: -79.462532,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03149,
        store_longitude: -79.462742,
    },
    {
        store_latitdue: -0.798353,
        store_longitude: -79.353401,
    },
    {
        store_latitdue: -1.44511,
        store_longitude: -79.463,
    },
    {
        store_latitdue: -1.02817,
        store_longitude: -79.447,
    },
    {
        store_latitdue: -0.7983831,
        store_longitude: -79.3527626,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.4425803,
        store_longitude: -79.4598574,
    },
    {
        store_latitdue: -1.4507633,
        store_longitude: -79.4614159,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9425317,
        store_longitude: -79.2261423,
    },
    {
        store_latitdue: -1.4467284,
        store_longitude: -79.4611031,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.44836,
        store_longitude: -79.4616,
    },
    {
        store_latitdue: -1.0150801,
        store_longitude: -79.4572774,
    },
    {
        store_latitdue: -1.03002,
        store_longitude: -79.4583,
    },
    {
        store_latitdue: -1.0245999,
        store_longitude: -79.4625929,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9466876,
        store_longitude: -79.334678,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9565005,
        store_longitude: -79.4243656,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.953451,
        store_longitude: -79.353058,
    },
    {
        store_latitdue: -1.02913,
        store_longitude: -79.4576,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4436214,
        store_longitude: -79.4598899,
    },
    {
        store_latitdue: -0.946485,
        store_longitude: -79.3364,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.41995603,
        store_longitude: -79.4673943,
    },
    {
        store_latitdue: -1.567674,
        store_longitude: -79.4734665,
    },
    {
        store_latitdue: -1.57127,
        store_longitude: -79.4744,
    },
    {
        store_latitdue: -1.57435,
        store_longitude: -79.4729,
    },
    {
        store_latitdue: -1.5507106,
        store_longitude: -79.5315354,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.5531193,
        store_longitude: -79.5342288,
    },
    {
        store_latitdue: -1.3555349,
        store_longitude: -79.4012217,
    },
    {
        store_latitdue: -1.1356306,
        store_longitude: -79.0847744,
    },
    {
        store_latitdue: -1.3540095,
        store_longitude: -79.4036145,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.35605,
        store_longitude: -79.4019,
    },
    {
        store_latitdue: -1.3573981,
        store_longitude: -79.3927391,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.2122091,
        store_longitude: -79.4240714,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.0241003,
        store_longitude: -79.4415484,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.01245,
        store_longitude: -79.4465,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9464531,
        store_longitude: -79.2372115,
    },
    {
        store_latitdue: -1.3663016,
        store_longitude: -79.90783,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -0.946922,
        store_longitude: -79.2299,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.933747,
        store_longitude: -79.222453,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9104191,
        store_longitude: -79.1622522,
        neighborhood: 'TINGO',
    },
    {
        store_latitdue: -0.9478654,
        store_longitude: -79.2501721,
    },
    {
        store_latitdue: -1.25736,
        store_longitude: -79.3734,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.1164248,
        store_longitude: -79.4362428,
    },
    {
        store_latitdue: -1.11771,
        store_longitude: -79.4328,
    },
    {
        store_latitdue: -1.20604,
        store_longitude: -79.3127,
    },
    {
        store_latitdue: -1.20482,
        store_longitude: -79.3174,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.034235,
        store_longitude: -79.459334,
    },
    {
        store_latitdue: -1.0355866,
        store_longitude: -79.4588681,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.2368248,
        store_longitude: -79.2400172,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.43476,
        store_longitude: -79.4593,
    },
    {
        store_latitdue: -1.43609743,
        store_longitude: -79.4652378,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.02922,
        store_longitude: -79.4529,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4380234,
        store_longitude: -79.4603825,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.02932,
        store_longitude: -79.4631,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0230813,
        store_longitude: -79.4523177,
    },
    {
        store_latitdue: -1.0163969,
        store_longitude: -79.4583896,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02742,
        store_longitude: -79.4473,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9453266,
        store_longitude: -79.3505206,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.944745,
        store_longitude: -79.3277,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.013923,
        store_longitude: -79.456738,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9667341,
        store_longitude: -79.4247261,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.9580379,
        store_longitude: -79.4213118,
    },
    {
        store_latitdue: -1.44891499,
        store_longitude: -79.4666541,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.48886374,
        store_longitude: -79.4739174,
    },
    {
        store_latitdue: -1.01347,
        store_longitude: -79.4428,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.44849,
        store_longitude: -79.4666,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.989002,
        store_longitude: -79.4466361,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.57247,
        store_longitude: -79.4775,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.4337685,
        store_longitude: -79.2806156,
    },
    {
        store_latitdue: -1.3542023,
        store_longitude: -79.4041945,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.43334,
        store_longitude: -79.2824,
    },
    {
        store_latitdue: -1.4250288,
        store_longitude: -79.5599804,
        neighborhood: 'PUERTO PECHICHE',
    },
    {
        store_latitdue: -1.4250879,
        store_longitude: -79.5600696,
    },
    {
        store_latitdue: -1.4257589,
        store_longitude: -79.5609159,
        neighborhood: 'PUERTO PECHICHE',
    },
    {
        store_latitdue: -1.425615,
        store_longitude: -79.5607226,
        neighborhood: 'PUERTO PECHICHE',
    },
    {
        store_latitdue: -1.550962,
        store_longitude: -79.53509,
    },
    {
        store_latitdue: -1.4254555,
        store_longitude: -79.5606443,
        neighborhood: 'PUERTO PECHICHE',
    },
    {
        store_latitdue: -0.9173446,
        store_longitude: -79.1673679,
        neighborhood: 'TINGO',
    },
    {
        store_latitdue: -1.549622,
        store_longitude: -79.5307186,
    },
    {
        store_latitdue: -1.1051746,
        store_longitude: -79.2693046,
    },
    {
        store_latitdue: -0.9421364,
        store_longitude: -79.3255726,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.02265,
        store_longitude: -79.4537,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.2856465,
        store_longitude: -79.3154132,
    },
    {
        store_latitdue: -1.00683,
        store_longitude: -79.4414,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.945344,
        store_longitude: -79.2657,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.5733225,
        store_longitude: -79.4673899,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.11659,
        store_longitude: -79.4363,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.571456,
        store_longitude: -79.474451,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.432338,
        store_longitude: -79.2779347,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.43051,
        store_longitude: -79.2757,
    },
    {
        store_latitdue: -1.4305784,
        store_longitude: -79.2795889,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.57307,
        store_longitude: -79.4745,
    },
    {
        store_latitdue: -1.1487097,
        store_longitude: -79.434332,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.0335733,
        store_longitude: -79.4494016,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.939249,
        store_longitude: -79.2397,
    },
    {
        store_latitdue: -0.940022,
        store_longitude: -79.2501,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.940581,
        store_longitude: -79.2431199,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9436351,
        store_longitude: -79.3338444,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9498563,
        store_longitude: -79.3443305,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.208225,
        store_longitude: -79.31354,
    },
    {
        store_latitdue: -0.287523,
        store_longitude: -78.564948,
    },
    {
        store_latitdue: -1.4524162,
        store_longitude: -79.4697932,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.28527,
        store_longitude: -79.3145,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.4523816,
        store_longitude: -79.4701436,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.7083761,
        store_longitude: -79.395759,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -1.43077,
        store_longitude: -79.2773,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.425608,
        store_longitude: -79.560639,
    },
    {
        store_latitdue: -1.4280274,
        store_longitude: -79.3745105,
    },
    {
        store_latitdue: -1.05917,
        store_longitude: -79.3253,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.032164,
        store_longitude: -79.456481,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.45111,
        store_longitude: -79.4736,
    },
    {
        store_latitdue: -1.4360547,
        store_longitude: -79.4575815,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.02225,
        store_longitude: -79.4521,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02466,
        store_longitude: -79.459,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9318682,
        store_longitude: -79.2230205,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0259265,
        store_longitude: -79.4543644,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.115622,
        store_longitude: -79.4363332,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.553196095,
        store_longitude: -79.53403745,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.11447,
        store_longitude: -79.4363,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.11531078,
        store_longitude: -79.4345932,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.12615,
        store_longitude: -79.439,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.4510368,
        store_longitude: -79.467795,
    },
    {
        store_latitdue: -0.940033,
        store_longitude: -79.238138,
    },
    {
        store_latitdue: -0.9410022,
        store_longitude: -79.2265098,
    },
    {
        store_latitdue: -0.9447883,
        store_longitude: -79.2653733,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.942706,
        store_longitude: -79.234565,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9451267,
        store_longitude: -79.3197051,
    },
    {
        store_latitdue: -1.26991,
        store_longitude: -79.3216,
    },
    {
        store_latitdue: -1.2045167,
        store_longitude: -79.3056742,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -0.9312513,
        store_longitude: -79.2144704,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.1371813,
        store_longitude: -79.0812069,
    },
    {
        store_latitdue: -0.93415784,
        store_longitude: -79.2221069,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0353109,
        store_longitude: -79.4558979,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4383996,
        store_longitude: -79.4620501,
    },
    {
        store_latitdue: -1.4448811,
        store_longitude: -79.4644304,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.44417,
        store_longitude: -79.4736,
    },
    {
        store_latitdue: -1.449246,
        store_longitude: -79.4656306,
    },
    {
        store_latitdue: -1.03726,
        store_longitude: -79.4733,
    },
    {
        store_latitdue: -0.799172,
        store_longitude: -79.3551,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.2007396,
        store_longitude: -79.4201726,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.43302,
        store_longitude: -79.2764,
    },
    {
        store_latitdue: -1.57151,
        store_longitude: -79.4647,
    },
    {
        store_latitdue: -1.57153,
        store_longitude: -79.469,
    },
    {
        store_latitdue: -1.43097,
        store_longitude: -79.2779,
    },
    {
        store_latitdue: -0.9399044,
        store_longitude: -79.2266287,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.03267,
        store_longitude: -79.4597,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04028,
        store_longitude: -79.4558,
    },
    {
        store_latitdue: -1.4498621,
        store_longitude: -79.4657247,
    },
    {
        store_latitdue: -0.992015,
        store_longitude: -79.4373,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9467083,
        store_longitude: -79.3343383,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.020603,
        store_longitude: -79.45923,
    },
    {
        store_latitdue: -1.0045072,
        store_longitude: -79.4458248,
    },
    {
        store_latitdue: -1.57351,
        store_longitude: -79.464,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.265286182,
        store_longitude: -79.42740045,
    },
    {
        store_latitdue: -1.4303813,
        store_longitude: -79.282253,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.1158153,
        store_longitude: -79.4371434,
    },
    {
        store_latitdue: -1.429605,
        store_longitude: -79.285271,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.5458678,
        store_longitude: -79.5313558,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.4519574,
        store_longitude: -79.4671321,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.120977,
        store_longitude: -79.434686,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.1152883,
        store_longitude: -79.4353575,
    },
    {
        store_latitdue: -1.54290204,
        store_longitude: -79.5074665,
    },
    {
        store_latitdue: -0.87915557,
        store_longitude: -79.1605067,
        neighborhood: 'TINGO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.2143947,
        store_longitude: -79.3241864,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.43903,
        store_longitude: -79.4589,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.4301116,
        store_longitude: -79.2854308,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.4456569,
        store_longitude: -79.4664773,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.21047,
        store_longitude: -79.3155,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.11395,
        store_longitude: -79.441,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.947015,
        store_longitude: -79.3528,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.432243,
        store_longitude: -79.4682936,
    },
    {
        store_latitdue: -1.5738528,
        store_longitude: -79.4725526,
    },
    {
        store_latitdue: -1.4426625,
        store_longitude: -79.4584491,
    },
    {
        store_latitdue: -0.949603,
        store_longitude: -79.352926,
    },
    {
        store_latitdue: -1.258876,
        store_longitude: -79.4284388,
    },
    {
        store_latitdue: -1.27166,
        store_longitude: -79.4347,
    },
    {
        store_latitdue: -1.1165016,
        store_longitude: -79.436305,
    },
    {
        store_latitdue: -2.1862349,
        store_longitude: -79.9013632,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -1.442424832,
        store_longitude: -79.47821169,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9180772,
        store_longitude: -79.1679663,
        neighborhood: 'TINGO',
    },
    {
        store_latitdue: -0.2686143,
        store_longitude: -78.5400598,
    },
    {
        store_latitdue: -1.1744517,
        store_longitude: -79.2240724,
    },
    {
        store_latitdue: -1.2052589,
        store_longitude: -79.3175627,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9452382,
        store_longitude: -79.2567933,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.953239,
        store_longitude: -79.354415,
    },
    {
        store_latitdue: -1.28709,
        store_longitude: -79.3158,
    },
    {
        store_latitdue: -1.01288,
        store_longitude: -79.4418,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.43284,
        store_longitude: -79.2791,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.26241,
        store_longitude: -79.428,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.2081584,
        store_longitude: -79.3163795,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.0017755276,
        store_longitude: -79.4380984269,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.950573,
        store_longitude: -79.3498,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.04017,
        store_longitude: -79.4629,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3889395,
        store_longitude: -79.4369923,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.56185,
        store_longitude: -79.7584,
    },
    {
        store_latitdue: -1.27132,
        store_longitude: -79.3226,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.0078632,
        store_longitude: -79.4456286,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9678874,
        store_longitude: -79.4217382,
    },
    {
        store_latitdue: -1.11619,
        store_longitude: -79.4343,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.47842,
        store_longitude: -79.3997,
    },
    {
        store_latitdue: -0.9424052,
        store_longitude: -79.2231752,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.02667403,
        store_longitude: -79.4494476,
    },
    {
        store_latitdue: -0.936265,
        store_longitude: -79.2215,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.944235,
        store_longitude: -79.24287,
    },
    {
        store_latitdue: -0.7923601,
        store_longitude: -79.1591542,
        neighborhood: 'GUASAGANDA',
    },
    {
        store_latitdue: -1.206552,
        store_longitude: -79.3127933,
    },
    {
        store_latitdue: -1.56165,
        store_longitude: -79.4753,
    },
    {
        store_latitdue: -0.803492,
        store_longitude: -79.1608103,
        neighborhood: 'GUASAGANDA',
    },
    {
        store_latitdue: -1.5514432,
        store_longitude: -79.5352023,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.5676386,
        store_longitude: -79.4702398,
    },
    {
        store_latitdue: -1.42947,
        store_longitude: -79.2788,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.433163166,
        store_longitude: -79.27623748,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.9468245,
        store_longitude: -79.2379306,
    },
    {
        store_latitdue: -0.94809,
        store_longitude: -79.351592,
    },
    {
        store_latitdue: -1.0266064,
        store_longitude: -79.4662885,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.948033,
        store_longitude: -79.3494,
    },
    {
        store_latitdue: -0.9455279,
        store_longitude: -79.2385092,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.5551533,
        store_longitude: -79.5319017,
    },
    {
        store_latitdue: -1.43352,
        store_longitude: -79.2768,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -0.922994,
        store_longitude: -79.2256,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.20606,
        store_longitude: -79.3141,
    },
    {
        store_latitdue: -0.9441207,
        store_longitude: -79.3362556,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.943411,
        store_longitude: -79.323,
    },
    {
        store_latitdue: -0.9223221,
        store_longitude: -79.2236449,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9441154,
        store_longitude: -79.225236,
    },
    {
        store_latitdue: -1.01963,
        store_longitude: -79.4597,
    },
    {
        store_latitdue: -1.44958,
        store_longitude: -79.462,
    },
    {
        store_latitdue: -1.1833426,
        store_longitude: -79.2418049,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.03986,
        store_longitude: -79.4544,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4325418,
        store_longitude: -79.2776199,
    },
    {
        store_latitdue: -1.5734106,
        store_longitude: -79.4670795,
    },
    {
        store_latitdue: -0.951023,
        store_longitude: -79.35611,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.7980274,
        store_longitude: -79.3529217,
    },
    {
        store_latitdue: -0.941544,
        store_longitude: -79.2267,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.438711,
        store_longitude: -79.2853255,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.35667264,
        store_longitude: -79.4012985,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.04946,
        store_longitude: -79.8175,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.286467,
        store_longitude: -79.318011,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.4444082,
        store_longitude: -79.4576895,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9416163,
        store_longitude: -79.2339155,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.01544,
        store_longitude: -79.4607,
    },
    {
        store_latitdue: -1.004886,
        store_longitude: -79.4411819,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0007313,
        store_longitude: -79.4413609,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -2.12989,
        store_longitude: -79.9142,
    },
    {
        store_latitdue: -1.55040651,
        store_longitude: -79.5346856,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -0.9518946,
        store_longitude: -79.2477532,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.0326788,
        store_longitude: -79.4534781,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.954174,
        store_longitude: -79.3557,
    },
    {
        store_latitdue: -1.4437532,
        store_longitude: -79.462117,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.3656997,
        store_longitude: -79.4714404,
    },
    {
        store_latitdue: -0.920801,
        store_longitude: -79.1696,
        neighborhood: 'TINGO',
    },
    {
        store_latitdue: -1.1088451,
        store_longitude: -79.2660744,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -1.02993,
        store_longitude: -79.4526,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0227877,
        store_longitude: -79.4530358,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.033862,
        store_longitude: -79.462822,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.1943058,
        store_longitude: -79.2574084,
    },
    {
        store_latitdue: -1.43111,
        store_longitude: -79.5669,
    },
    {
        store_latitdue: -0.942347,
        store_longitude: -79.3339,
    },
    {
        store_latitdue: -1.44546,
        store_longitude: -79.4585,
    },
    {
        store_latitdue: -0.939882,
        store_longitude: -79.3258,
    },
    {
        store_latitdue: -1.4245269,
        store_longitude: -79.5599929,
    },
    {
        store_latitdue: -0.9525444,
        store_longitude: -79.3615291,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.43806,
        store_longitude: -79.4631,
    },
    {
        store_latitdue: -0.2719503,
        store_longitude: -78.4642685,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.9440537,
        store_longitude: -79.2422965,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.3023544,
        store_longitude: -78.6324075,
    },
    {
        store_latitdue: -0.93443536,
        store_longitude: -79.2223815,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.4462588,
        store_longitude: -79.4586984,
    },
    {
        store_latitdue: -1.568912,
        store_longitude: -79.4679686,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.9476813,
        store_longitude: -79.2452059,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.94255743,
        store_longitude: -79.2616689,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.03352,
        store_longitude: -79.4625,
    },
    {
        store_latitdue: -1.0203316,
        store_longitude: -79.4609448,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02508559,
        store_longitude: -79.4417846,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3651241,
        store_longitude: -79.4724739,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.11462,
        store_longitude: -79.4325,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.946384,
        store_longitude: -79.2439,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.944464,
        store_longitude: -79.244093,
    },
    {
        store_latitdue: -1.45361,
        store_longitude: -79.4681,
    },
    {
        store_latitdue: -1.26353,
        store_longitude: -79.4278,
    },
    {
        store_latitdue: -0.9361056,
        store_longitude: -79.224395,
    },
    {
        store_latitdue: -1.4318113,
        store_longitude: -79.2795356,
    },
    {
        store_latitdue: -0.9400769,
        store_longitude: -79.2296433,
    },
    {
        store_latitdue: -1.011358,
        store_longitude: -79.441849,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.943297,
        store_longitude: -79.2547,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9430462,
        store_longitude: -79.2328172,
    },
    {
        store_latitdue: -0.942347,
        store_longitude: -79.2693,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9433604,
        store_longitude: -79.271472,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.942889,
        store_longitude: -79.236598,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.678892397,
        store_longitude: -79.50245219,
    },
    {
        store_latitdue: -0.9481256,
        store_longitude: -79.317638,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.9414937,
        store_longitude: -79.2332642,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.944532,
        store_longitude: -79.232,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.942311096,
        store_longitude: -79.22923239,
    },
    {
        store_latitdue: -0.945899,
        store_longitude: -79.2227956,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.94958,
        store_longitude: -79.2286,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.1357316,
        store_longitude: -79.079775,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -1.4433048,
        store_longitude: -79.4634111,
    },
    {
        store_latitdue: -1.116206,
        store_longitude: -79.434142,
    },
    {
        store_latitdue: -1.4421351,
        store_longitude: -79.4604847,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.4428565,
        store_longitude: -79.4644104,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.19306,
        store_longitude: -79.5,
    },
    {
        store_latitdue: -0.96930772,
        store_longitude: -79.425415,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -1.2058937,
        store_longitude: -79.314776,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.5484276,
        store_longitude: -79.5313194,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -2.1152085,
        store_longitude: -80.004356,
    },
    {
        store_latitdue: -1.32982242,
        store_longitude: -79.374382,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.3320353,
        store_longitude: -79.37567,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.45167,
        store_longitude: -79.4722,
    },
    {
        store_latitdue: -1.4483472,
        store_longitude: -79.4651473,
    },
    {
        store_latitdue: -1.2519395,
        store_longitude: -79.310973,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.44254,
        store_longitude: -79.4641,
    },
    {
        store_latitdue: -1.3569,
        store_longitude: -79.4001,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -0.9212517,
        store_longitude: -79.2213543,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.2991185,
        store_longitude: -79.3389316,
    },
    {
        store_latitdue: -1.553117,
        store_longitude: -79.535123,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -0.9411949,
        store_longitude: -79.2316042,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.94468569,
        store_longitude: -79.2258453,
    },
    {
        store_latitdue: -1.210177843,
        store_longitude: -79.31883458,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.034889101,
        store_longitude: -79.45836696,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9440323,
        store_longitude: -79.4234163,
    },
    {
        store_latitdue: -0.9529569,
        store_longitude: -79.3587806,
    },
    {
        store_latitdue: -4.33218,
        store_longitude: -79.5594,
    },
    {
        store_latitdue: -1.4253853,
        store_longitude: -79.5605905,
        neighborhood: 'PUERTO PECHICHE',
    },
    {
        store_latitdue: -1.54863,
        store_longitude: -79.5319,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -0.9430687,
        store_longitude: -79.2358448,
    },
    {
        store_latitdue: -1.109201,
        store_longitude: -79.4323389,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.963466,
        store_longitude: -79.4204,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.9402765,
        store_longitude: -79.2238291,
    },
    {
        store_latitdue: -1.36042,
        store_longitude: -79.4166,
    },
    {
        store_latitdue: -0.9628806,
        store_longitude: -79.42084,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -1.1162657,
        store_longitude: -79.4319788,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.017446,
        store_longitude: -79.463,
    },
    {
        store_latitdue: -0.9408206,
        store_longitude: -79.2208435,
    },
    {
        store_latitdue: -1.4309169,
        store_longitude: -79.282972,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.4468826,
        store_longitude: -79.46368467,
    },
    {
        store_latitdue: -1.5731002,
        store_longitude: -79.4688591,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.573113,
        store_longitude: -79.468238,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -0.98751,
        store_longitude: -79.3061,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0114938,
        store_longitude: -79.444651,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.2667865,
        store_longitude: -79.3819402,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -0.9446523,
        store_longitude: -79.3266047,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0092022,
        store_longitude: -79.4436072,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.931234708,
        store_longitude: -79.2221958563,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.22181,
        store_longitude: -79.2722,
    },
    {
        store_latitdue: -1.5726681,
        store_longitude: -79.4680129,
        neighborhood: 'RICAURTE',
    },
    {
        store_latitdue: -1.2064777,
        store_longitude: -79.3116423,
    },
    {
        store_latitdue: -1.4347809,
        store_longitude: -79.4591227,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.03805,
        store_longitude: -79.4591,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.1380623,
        store_longitude: -79.0822889,
        neighborhood: 'EL CORAZÓN',
    },
    {
        store_latitdue: -1.5473715,
        store_longitude: -79.5288238,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.3612023,
        store_longitude: -79.9002165,
    },
    {
        store_latitdue: -1.36666,
        store_longitude: -79.9063333,
    },
    {
        store_latitdue: -1.0181001,
        store_longitude: -79.4713823,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.043119447,
        store_longitude: -79.48013087,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.017936,
        store_longitude: -79.471,
    },
    {
        store_latitdue: -0.988233,
        store_longitude: -79.470353,
    },
    {
        store_latitdue: -1.188974,
        store_longitude: -79.755768,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3663752,
        store_longitude: -79.9064515,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -0.896147,
        store_longitude: -79.489525,
    },
    {
        store_latitdue: -1.3587707,
        store_longitude: -79.9038001,
    },
    {
        store_latitdue: -1.3659598,
        store_longitude: -79.9076512,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3662733,
        store_longitude: -79.907785,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.35803,
        store_longitude: -79.9034,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.36696,
        store_longitude: -79.9066,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0487133,
        store_longitude: -79.8177178,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.05836625,
        store_longitude: -79.9840736,
    },
    {
        store_latitdue: -1.0370301,
        store_longitude: -79.9196206,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.0397652,
        store_longitude: -79.9199076,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.01439,
        store_longitude: -79.4729,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0050947,
        store_longitude: -79.4735588,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3674,
        store_longitude: -79.907,
    },
    {
        store_latitdue: -1.01842,
        store_longitude: -79.470354,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -2.1176257,
        store_longitude: -79.936225,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -1.36727,
        store_longitude: -79.9073,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.36808,
        store_longitude: -79.9072,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.1004539,
        store_longitude: -79.651854,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0150323,
        store_longitude: -79.467161,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0058961,
        store_longitude: -79.4710315,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0254879,
        store_longitude: -79.4628329,
    },
    {
        store_latitdue: -1.049361,
        store_longitude: -79.817437,
    },
    {
        store_latitdue: -1.0496673,
        store_longitude: -79.820301,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.0280194,
        store_longitude: -79.4699589,
    },
    {
        store_latitdue: -1.0361618,
        store_longitude: -79.8332695,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -0.9941788,
        store_longitude: -79.4705732,
    },
    {
        store_latitdue: -1.0538628,
        store_longitude: -79.8098259,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -0.9947597,
        store_longitude: -79.4706143,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0502599,
        store_longitude: -79.8195424,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.0183072,
        store_longitude: -79.4712985,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.36739075,
        store_longitude: -79.9069366,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.005498,
        store_longitude: -79.46698,
    },
    {
        store_latitdue: -1.0264045,
        store_longitude: -79.4666354,
    },
    {
        store_latitdue: -1.0039308,
        store_longitude: -79.4724082,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.36927,
        store_longitude: -79.9072,
    },
    {
        store_latitdue: -1.001064,
        store_longitude: -79.471818,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3593564,
        store_longitude: -79.904096,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0999554,
        store_longitude: -79.6515994,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.3609829,
        store_longitude: -79.9025216,
    },
    {
        store_latitdue: -0.9866927,
        store_longitude: -79.4714206,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.01693,
        store_longitude: -79.4695,
    },
    {
        store_latitdue: -1.5516591,
        store_longitude: -80.0077958,
        neighborhood: 'COLIMES',
    },
    {
        store_latitdue: -0.9926171,
        store_longitude: -79.470855,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.063739836,
        store_longitude: -79.64102004,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0114896,
        store_longitude: -79.4667131,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.002069,
        store_longitude: -79.4723447,
    },
    {
        store_latitdue: -1.36345,
        store_longitude: -79.9063,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0513217,
        store_longitude: -79.8634989,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -0.9925505,
        store_longitude: -79.4707307,
    },
    {
        store_latitdue: -1.0266117,
        store_longitude: -79.4685667,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0294747,
        store_longitude: -79.4678121,
    },
    {
        store_latitdue: -1.36692211,
        store_longitude: -79.90713213,
    },
    {
        store_latitdue: -1.0311097,
        store_longitude: -79.4705459,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00599,
        store_longitude: -79.467,
    },
    {
        store_latitdue: -1.04903,
        store_longitude: -79.8171,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.0108059,
        store_longitude: -79.4728331,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.36615,
        store_longitude: -79.9058,
    },
    {
        store_latitdue: -1.0181273,
        store_longitude: -79.4712519,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3528208,
        store_longitude: -79.8945666,
    },
    {
        store_latitdue: -0.991449,
        store_longitude: -79.4734,
    },
    {
        store_latitdue: -1.00547,
        store_longitude: -79.4645,
    },
    {
        store_latitdue: -1.0088983,
        store_longitude: -79.463641,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.36696,
        store_longitude: -79.9066,
    },
    {
        store_latitdue: -1.3716121,
        store_longitude: -79.9035174,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.05055,
        store_longitude: -79.465317,
    },
    {
        store_latitdue: -1.023263,
        store_longitude: -79.47084,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02703,
        store_longitude: -79.4659,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02926,
        store_longitude: -79.470573,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.005802,
        store_longitude: -79.472321,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.92216,
        store_longitude: -79.7273091,
        neighborhood: 'GUAYAS',
    },
    {
        store_latitdue: -1.01803,
        store_longitude: -79.4715,
    },
    {
        store_latitdue: -0.988904,
        store_longitude: -79.4716312,
    },
    {
        store_latitdue: -0.9943373,
        store_longitude: -79.473904,
    },
    {
        store_latitdue: -1.000682,
        store_longitude: -79.470983,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.030414,
        store_longitude: -79.471355,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0097282,
        store_longitude: -79.4727924,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.010134,
        store_longitude: -79.470386,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.186926,
        store_longitude: -79.7553132,
    },
    {
        store_latitdue: -1.020241,
        store_longitude: -79.4680975,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.18916,
        store_longitude: -79.7557,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.368344,
        store_longitude: -79.901889,
    },
    {
        store_latitdue: -1.005475,
        store_longitude: -79.470836,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0525611,
        store_longitude: -79.8086339,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.011482,
        store_longitude: -79.466747,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3625515,
        store_longitude: -79.9047958,
    },
    {
        store_latitdue: -1.003443,
        store_longitude: -79.4722947,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3619388,
        store_longitude: -79.9040063,
    },
    {
        store_latitdue: -1.019431,
        store_longitude: -79.467871,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9815404,
        store_longitude: -79.4728249,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.022855,
        store_longitude: -79.470913,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.366634,
        store_longitude: -79.9025971,
    },
    {
        store_latitdue: -0.99497,
        store_longitude: -79.471382,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9874192,
        store_longitude: -79.4732882,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.029468,
        store_longitude: -79.4709,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.049547,
        store_longitude: -79.82015,
    },
    {
        store_latitdue: -1.008459,
        store_longitude: -79.464627,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00606,
        store_longitude: -79.465009,
    },
    {
        store_latitdue: -1.369378,
        store_longitude: -79.907228,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.01339,
        store_longitude: -79.4669,
    },
    {
        store_latitdue: -1.362581,
        store_longitude: -79.90445,
    },
    {
        store_latitdue: -1.010153,
        store_longitude: -79.474338,
    },
    {
        store_latitdue: -1.003697,
        store_longitude: -79.468419,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0081,
        store_longitude: -79.4647,
    },
    {
        store_latitdue: -1.18708,
        store_longitude: -79.7557,
    },
    {
        store_latitdue: -1.0363546,
        store_longitude: -79.9192763,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.01887,
        store_longitude: -79.4676,
    },
    {
        store_latitdue: -0.9902054,
        store_longitude: -79.4713889,
    },
    {
        store_latitdue: -1.01893079,
        store_longitude: -79.4717254,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0066376,
        store_longitude: -79.4693004,
    },
    {
        store_latitdue: -1.0068969,
        store_longitude: -79.4737734,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.01604,
        store_longitude: -79.4712,
    },
    {
        store_latitdue: -1.0263601,
        store_longitude: -79.4699887,
    },
    {
        store_latitdue: -1.017303,
        store_longitude: -79.466506,
    },
    {
        store_latitdue: -1.18891,
        store_longitude: -79.7556,
    },
    {
        store_latitdue: -1.0149823,
        store_longitude: -79.4677121,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.995845,
        store_longitude: -79.4717,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0087,
        store_longitude: -79.4649,
    },
    {
        store_latitdue: -1.00608,
        store_longitude: -79.466425,
    },
    {
        store_latitdue: -0.99429,
        store_longitude: -79.4722,
    },
    {
        store_latitdue: -1.0103601,
        store_longitude: -79.4754507,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.017918,
        store_longitude: -79.471327,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.01797,
        store_longitude: -79.471287,
    },
    {
        store_latitdue: -1.0180726,
        store_longitude: -79.4686766,
    },
    {
        store_latitdue: -1.0143118,
        store_longitude: -79.4767898,
    },
    {
        store_latitdue: -1.0492618,
        store_longitude: -79.816903,
    },
    {
        store_latitdue: -1.3675851,
        store_longitude: -79.906254,
    },
    {
        store_latitdue: -1.3650079,
        store_longitude: -79.9062066,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.1869122,
        store_longitude: -79.755295,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0494378,
        store_longitude: -79.8167998,
    },
    {
        store_latitdue: -1.0496609,
        store_longitude: -79.8188127,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.3648,
        store_longitude: -79.9063,
    },
    {
        store_latitdue: -1.0257289,
        store_longitude: -79.4697153,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.025497,
        store_longitude: -79.4692451,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00988,
        store_longitude: -79.4763,
    },
    {
        store_latitdue: -1.0099875,
        store_longitude: -79.4764032,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00592,
        store_longitude: -79.4705,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.009021,
        store_longitude: -79.4752999,
    },
    {
        store_latitdue: -1.01665,
        store_longitude: -79.4702,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.943607,
        store_longitude: -79.485473,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9949786,
        store_longitude: -79.473824,
    },
    {
        store_latitdue: -1.013269,
        store_longitude: -79.471756,
    },
    {
        store_latitdue: -1.00976,
        store_longitude: -79.4728,
    },
    {
        store_latitdue: -0.9917634,
        store_longitude: -79.4710759,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9821546,
        store_longitude: -79.4706782,
    },
    {
        store_latitdue: -1.0054073,
        store_longitude: -79.4705739,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0055708,
        store_longitude: -79.4706241,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0082448,
        store_longitude: -79.4643645,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.1872803,
        store_longitude: -79.7553758,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0539038,
        store_longitude: -79.8097751,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -0.931424,
        store_longitude: -79.7269,
        neighborhood: 'GUAYAS',
    },
    {
        store_latitdue: -0.931571,
        store_longitude: -79.7273,
        neighborhood: 'GUAYAS',
    },

    {
        store_latitdue: -1.36597,
        store_longitude: -79.907,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3625867,
        store_longitude: -79.9003692,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3696961,
        store_longitude: -79.9019054,
    },
    {
        store_latitdue: -1.027608,
        store_longitude: -79.472789,
    },
    {
        store_latitdue: -1.0278834,
        store_longitude: -79.4721479,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02326,
        store_longitude: -79.4712,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.022985,
        store_longitude: -79.469254,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0178302,
        store_longitude: -79.4684507,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00891,
        store_longitude: -79.4636,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.983998,
        store_longitude: -79.4705864,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00604,
        store_longitude: -79.4651,
    },
    {
        store_latitdue: -1.01686,
        store_longitude: -79.4703,
    },
    {
        store_latitdue: -0.998298,
        store_longitude: -79.470295,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00107,
        store_longitude: -79.4782,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3688202,
        store_longitude: -79.9030133,
    },
    {
        store_latitdue: -1.009397,
        store_longitude: -79.7599618,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.0124179,
        store_longitude: -79.758081,
    },
    {
        store_latitdue: -1.012538,
        store_longitude: -79.758506,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.3614617,
        store_longitude: -79.9060683,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.35815,
        store_longitude: -79.9023,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3672481,
        store_longitude: -79.9027731,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3648774,
        store_longitude: -79.9062856,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0992526,
        store_longitude: -79.6512646,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.02656,
        store_longitude: -79.47,
    },
    {
        store_latitdue: -1.01561,
        store_longitude: -79.4726,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.006142,
        store_longitude: -79.4650256,
    },
    {
        store_latitdue: -1.0049633,
        store_longitude: -79.4662473,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.36745,
        store_longitude: -79.9070483,
    },
    {
        store_latitdue: -1.3676782,
        store_longitude: -79.9028282,
    },
    {
        store_latitdue: -1.3844751,
        store_longitude: -79.9089608,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0206545,
        store_longitude: -79.4698045,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.993054,
        store_longitude: -79.4773245,
    },
    {
        store_latitdue: -1.0108432,
        store_longitude: -79.4758815,
    },
    {
        store_latitdue: -1.364719,
        store_longitude: -79.9060094,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -3.24406,
        store_longitude: -79.9428,
    },
    {
        store_latitdue: -1.0071394,
        store_longitude: -79.4750787,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.364881,
        store_longitude: -79.9063169,
    },
    {
        store_latitdue: -1.02819,
        store_longitude: -79.466,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.009104,
        store_longitude: -79.760024,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.046069416,
        store_longitude: -79.82253465,
    },
    {
        store_latitdue: -1.02741,
        store_longitude: -79.466398,
    },
    {
        store_latitdue: -0.997696,
        store_longitude: -79.478148,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.009755,
        store_longitude: -79.75981,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.3578896,
        store_longitude: -79.8995508,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3590942,
        store_longitude: -79.9005071,
    },
    {
        store_latitdue: -1.3664276,
        store_longitude: -79.8996789,
    },
    {
        store_latitdue: -1.367944,
        store_longitude: -79.898306,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3690899,
        store_longitude: -79.896172,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.04962171,
        store_longitude: -79.8175025,
    },
    {
        store_latitdue: -1.0293868,
        store_longitude: -79.4684138,
    },
    {
        store_latitdue: -1.0374804,
        store_longitude: -79.8990664,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.0514668,
        store_longitude: -79.8151888,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -0.99725,
        store_longitude: -79.4714,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00877,
        store_longitude: -79.4767,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.045965,
        store_longitude: -79.474617,
    },
    {
        store_latitdue: -1.0074047,
        store_longitude: -79.4781969,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.368209235,
        store_longitude: -79.90505115,
    },
    {
        store_latitdue: -1.35805,
        store_longitude: -79.9032,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.360119469,
        store_longitude: -79.89999716,
    },
    {
        store_latitdue: -1.3608933,
        store_longitude: -79.8986083,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3681745,
        store_longitude: -79.8991625,
    },
    {
        store_latitdue: -1.0212847,
        store_longitude: -79.4674549,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.99682,
        store_longitude: -79.4738,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.5447394,
        store_longitude: -80.0100414,
        neighborhood: 'COLIMES',
    },
    {
        store_latitdue: -1.0475614,
        store_longitude: -79.8205538,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.3379089,
        store_longitude: -79.8780328,
    },
    {
        store_latitdue: -1.015998,
        store_longitude: -79.469255,
    },
    {
        store_latitdue: -1.046653,
        store_longitude: -79.884166,
    },
    {
        store_latitdue: -1.0465281,
        store_longitude: -79.882648,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.37015,
        store_longitude: -79.9074,
    },
    {
        store_latitdue: -1.3565925,
        store_longitude: -79.8992999,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.00828,
        store_longitude: -79.4779,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0113834,
        store_longitude: -79.4726364,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.011123,
        store_longitude: -79.472115,
    },
    {
        store_latitdue: -1.009170063,
        store_longitude: -79.47295825,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0102,
        store_longitude: -79.4713,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.371453,
        store_longitude: -79.904928,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0396074,
        store_longitude: -79.9201158,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.0509539,
        store_longitude: -79.8639198,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.36426,
        store_longitude: -79.9061,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -2.68026,
        store_longitude: -79.613238,
    },
    {
        store_latitdue: -0.984391,
        store_longitude: -79.470027,
    },
    {
        store_latitdue: -1.017797,
        store_longitude: -79.470191,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.37194,
        store_longitude: -79.9037,
    },
    {
        store_latitdue: -1.04005258,
        store_longitude: -79.6695223,
    },
    {
        store_latitdue: -1.00379,
        store_longitude: -79.4727,
    },
    {
        store_latitdue: -1.0061944,
        store_longitude: -79.4676684,
    },
    {
        store_latitdue: -1.00726,
        store_longitude: -79.4661,
    },
    {
        store_latitdue: -1.0176991,
        store_longitude: -79.4709901,
    },
    {
        store_latitdue: -1.3707235,
        store_longitude: -79.9059328,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.06486129,
        store_longitude: -79.6412811,
    },
    {
        store_latitdue: -1.0116882,
        store_longitude: -79.4666414,
    },
    {
        store_latitdue: -1.0281957,
        store_longitude: -79.4682577,
    },
    {
        store_latitdue: -0.998474,
        store_longitude: -79.472372,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05385,
        store_longitude: -79.8096,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -0.9919924,
        store_longitude: -79.4767108,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.998363,
        store_longitude: -79.472573,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9910954,
        store_longitude: -79.4764422,
    },
    {
        store_latitdue: -1.01827692,
        store_longitude: -79.4711227,
    },
    {
        store_latitdue: -1.008245,
        store_longitude: -79.477264,
    },
    {
        store_latitdue: -1.00959,
        store_longitude: -79.4751,
    },
    {
        store_latitdue: -1.100931,
        store_longitude: -79.652566,
    },
    {
        store_latitdue: -1.361223,
        store_longitude: -79.8996132,
    },
    {
        store_latitdue: -1.3689325,
        store_longitude: -79.901049,
    },
    {
        store_latitdue: -1.0492951,
        store_longitude: -79.8123935,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.35991,
        store_longitude: -79.9040467,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3603991,
        store_longitude: -79.9042344,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.369042,
        store_longitude: -79.901792,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.1019835,
        store_longitude: -79.653223,
    },
    {
        store_latitdue: -1.01653,
        store_longitude: -79.4695,
    },
    {
        store_latitdue: -1.01115,
        store_longitude: -79.4701,
    },
    {
        store_latitdue: -1.3624311,
        store_longitude: -79.9026528,
    },
    {
        store_latitdue: -1.0351448,
        store_longitude: -79.6661545,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.017161,
        store_longitude: -79.470738,
    },
    {
        store_latitdue: -0.981679,
        store_longitude: -79.4702,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03509,
        store_longitude: -79.6607,
    },
    {
        store_latitdue: -1.0948409,
        store_longitude: -79.6475772,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0066,
        store_longitude: -79.4787,
    },
    {
        store_latitdue: -1.00307,
        store_longitude: -79.4682,
    },
    {
        store_latitdue: -1.0520333,
        store_longitude: -79.48082,
    },
    {
        store_latitdue: -1.04353444,
        store_longitude: -79.7844207,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.0259667,
        store_longitude: -79.4681354,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0083857,
        store_longitude: -79.4773256,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.008,
        store_longitude: -79.4692,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3660263,
        store_longitude: -79.906199,
    },
    {
        store_latitdue: -1.0514518,
        store_longitude: -79.8123875,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.04922,
        store_longitude: -79.8202,
    },
    {
        store_latitdue: -1.01396,
        store_longitude: -79.4668,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0134962,
        store_longitude: -79.4742815,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3703044813,
        store_longitude: -79.9046748877,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.36406,
        store_longitude: -79.9036,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3383046,
        store_longitude: -79.8781384,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0101723,
        store_longitude: -79.4721925,
    },
    {
        store_latitdue: -1.44183,
        store_longitude: -79.4607,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.362615,
        store_longitude: -79.9025172,
    },
    {
        store_latitdue: -1.36583,
        store_longitude: -79.9068,
    },
    {
        store_latitdue: -1.00707,
        store_longitude: -79.4753,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.1857555,
        store_longitude: -79.7551898,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.02788,
        store_longitude: -79.4707,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.993173,
        store_longitude: -79.4774,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00478022,
        store_longitude: -79.4675552,
    },
    {
        store_latitdue: -0.9872238,
        store_longitude: -79.471688,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9725397,
        store_longitude: -79.4698166,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05071,
        store_longitude: -79.791,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.0384166,
        store_longitude: -79.6800057,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.9836471,
        store_longitude: -79.4692876,
    },
    {
        store_latitdue: -0.9925528,
        store_longitude: -79.4730972,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.37061822,
        store_longitude: -79.9034042,
    },
    {
        store_latitdue: -1.029875,
        store_longitude: -79.471309,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3625062,
        store_longitude: -79.9017499,
    },
    {
        store_latitdue: -1.0311049,
        store_longitude: -79.471696,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.010352458,
        store_longitude: -80.25083773,
    },
    {
        store_latitdue: -1.277974,
        store_longitude: -78.624788,
    },
    {
        store_latitdue: -1.36554,
        store_longitude: -79.9063,
    },
    {
        store_latitdue: -1.36994,
        store_longitude: -79.9054,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -0.99645102,
        store_longitude: -79.4759597,
    },
    {
        store_latitdue: -1.02826081,
        store_longitude: -79.4709563,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3753792,
        store_longitude: -79.9070661,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.05134,
        store_longitude: -79.8629,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -0.99464188,
        store_longitude: -79.4699048,
    },
    {
        store_latitdue: -1.3862893,
        store_longitude: -79.9085648,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0180399,
        store_longitude: -79.471144,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.020965,
        store_longitude: -79.467848,
    },
    {
        store_latitdue: -1.01124,
        store_longitude: -79.4709,
    },
    {
        store_latitdue: -1.0547947,
        store_longitude: -79.466741,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.009517,
        store_longitude: -79.759077,
    },
    {
        store_latitdue: -1.366228,
        store_longitude: -79.905364,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.369506,
        store_longitude: -79.903656,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.1855,
        store_longitude: -79.75533,
    },
    {
        store_latitdue: -1.0307711,
        store_longitude: -79.4714142,
    },
    {
        store_latitdue: -1.3685127,
        store_longitude: -79.9028866,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.2081212624,
        store_longitude: -79.3161873746,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.00668,
        store_longitude: -79.4756,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0070696,
        store_longitude: -79.4753003,
    },
    {
        store_latitdue: -1.0344832,
        store_longitude: -79.6650085,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0182603,
        store_longitude: -79.4712505,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0175,
        store_longitude: -79.471,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0177499,
        store_longitude: -79.4663176,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0450453,
        store_longitude: -79.8784679,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.0150068,
        store_longitude: -79.4678377,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0131799,
        store_longitude: -79.4666253,
    },
    {
        store_latitdue: -1.05832887,
        store_longitude: -79.6409312,
    },
    {
        store_latitdue: -1.026462,
        store_longitude: -79.466572,
    },
    {
        store_latitdue: -1.0175204,
        store_longitude: -79.4696561,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3584252,
        store_longitude: -79.9024744,
    },
    {
        store_latitdue: -1.35807,
        store_longitude: -79.9034,
    },
    {
        store_latitdue: -1.00844,
        store_longitude: -79.4699,
    },
    {
        store_latitdue: -1.311252,
        store_longitude: -79.852825,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0271729,
        store_longitude: -79.4720395,
    },
    {
        store_latitdue: -1.00466039,
        store_longitude: -79.4715677,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0056097,
        store_longitude: -79.4670006,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9930339,
        store_longitude: -79.4766738,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03743595,
        store_longitude: -79.7513429,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.0157852,
        store_longitude: -79.4679002,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.003872,
        store_longitude: -79.4713273,
    },
    {
        store_latitdue: -1.0061586,
        store_longitude: -79.4678744,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.017906,
        store_longitude: -79.47093,
    },
    {
        store_latitdue: -1.0143307,
        store_longitude: -79.4730906,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.002318915,
        store_longitude: -79.46982185,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3671133,
        store_longitude: -79.9033269,
    },
    {
        store_latitdue: -1.04942,
        store_longitude: -79.8169,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.02491,
        store_longitude: -79.448,
    },
    {
        store_latitdue: -1.0061696,
        store_longitude: -79.4676213,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3618763,
        store_longitude: -79.8972694,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0288481,
        store_longitude: -79.4665372,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.020809,
        store_longitude: -79.471873,
    },
    {
        store_latitdue: -1.0291416,
        store_longitude: -79.4687883,
    },
    {
        store_latitdue: -1.0272468,
        store_longitude: -79.4476898,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.008177,
        store_longitude: -79.470472,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.366857,
        store_longitude: -79.9056276,
    },
    {
        store_latitdue: -1.0073,
        store_longitude: -79.4706,
    },
    {
        store_latitdue: -0.8941571,
        store_longitude: -79.4896918,
    },
    {
        store_latitdue: -1.02299,
        store_longitude: -79.469,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.061742043,
        store_longitude: -79.4901422,
    },
    {
        store_latitdue: -1.01321017,
        store_longitude: -79.4645614,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0816505,
        store_longitude: -79.5186621,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.0796909,
        store_longitude: -79.5008625,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.0522849,
        store_longitude: -79.8114308,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.0479101396,
        store_longitude: -79.8155105487,
    },
    {
        store_latitdue: -1.05222508,
        store_longitude: -79.8144507,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.02415,
        store_longitude: -79.44,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0500358,
        store_longitude: -79.7910683,
    },
    {
        store_latitdue: -1.0802785,
        store_longitude: -79.510389,
    },
    {
        store_latitdue: -1.1922248,
        store_longitude: -79.756636,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0094171,
        store_longitude: -79.4779475,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3703868,
        store_longitude: -79.9074097,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.05129,
        store_longitude: -79.4671,
    },
    {
        store_latitdue: -1.0332702,
        store_longitude: -79.4707573,
    },
    {
        store_latitdue: 0.5127491,
        store_longitude: -77.8650416,
    },
    {
        store_latitdue: -1.37,
        store_longitude: -79.9036,
    },
    {
        store_latitdue: -1.02918,
        store_longitude: -79.4723,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0337992,
        store_longitude: -79.471466,
    },
    {
        store_latitdue: -0.989041,
        store_longitude: -79.473929,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0535744,
        store_longitude: -79.4647001,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.370617,
        store_longitude: -79.903406,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.371453,
        store_longitude: -79.904928,
    },
    {
        store_latitdue: -1.3713496,
        store_longitude: -79.904752,
    },
    {
        store_latitdue: -0.9888934,
        store_longitude: -79.4757664,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.36561,
        store_longitude: -79.9039,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3668252,
        store_longitude: -79.9058891,
    },
    {
        store_latitdue: -1.367,
        store_longitude: -79.9068,
    },
    {
        store_latitdue: -1.028661843,
        store_longitude: -79.46795969,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.024288096,
        store_longitude: -79.46432483,
    },
    {
        store_latitdue: -1.0247,
        store_longitude: -79.4681,
    },
    {
        store_latitdue: -1.3668197,
        store_longitude: -79.9025943,
    },
    {
        store_latitdue: -1.0182411,
        store_longitude: -79.4715732,
    },
    {
        store_latitdue: -1.0386083,
        store_longitude: -79.4774642,
    },
    {
        store_latitdue: -1.0269311,
        store_longitude: -79.4661818,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0411553,
        store_longitude: -79.4763426,
    },
    {
        store_latitdue: -1.028639,
        store_longitude: -79.46638,
    },
    {
        store_latitdue: -1.3550214,
        store_longitude: -79.895941,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.3581574,
        store_longitude: -79.9054513,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0101927,
        store_longitude: -79.4748813,
    },
    {
        store_latitdue: -1.3633866,
        store_longitude: -79.9062881,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.0257853,
        store_longitude: -79.4659769,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.3604105,
        store_longitude: -79.902648,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.014884,
        store_longitude: -79.4734335,
    },
    {
        store_latitdue: -1.02622115,
        store_longitude: -79.4657669,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.047895,
        store_longitude: -79.637746,
    },
    {
        store_latitdue: -1.04695,
        store_longitude: -79.639005,
    },
    {
        store_latitdue: -0.945135,
        store_longitude: -79.3288566,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0303822,
        store_longitude: -79.4624062,
    },
    {
        store_latitdue: -1.044509,
        store_longitude: -79.63782,
    },
    {
        store_latitdue: -1.04457,
        store_longitude: -79.6428,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0409636,
        store_longitude: -79.6468172,
    },
    {
        store_latitdue: -1.006129,
        store_longitude: -79.467401,
    },
    {
        store_latitdue: -1.040327,
        store_longitude: -79.671636,
    },
    {
        store_latitdue: -1.1891647,
        store_longitude: -79.50624,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1881349,
        store_longitude: -79.5053989,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.04389,
        store_longitude: -79.4708,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0443193,
        store_longitude: -79.4711076,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.041628,
        store_longitude: -79.6472478,
    },
    {
        store_latitdue: -1.0405647,
        store_longitude: -79.4705486,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.056227,
        store_longitude: -79.4790572,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.03691565,
        store_longitude: -79.6348037,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0034098,
        store_longitude: -79.4689989,
    },
    {
        store_latitdue: -1.0393066,
        store_longitude: -79.6401476,
        neighborhood: 'VELASCO IBARRA',
    },

    {
        store_latitdue: -1.0356365,
        store_longitude: -79.6336687,
    },
    {
        store_latitdue: -1.0554806,
        store_longitude: -79.4787364,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.05096515,
        store_longitude: -79.8635244,
    },
    {
        store_latitdue: -1.024838,
        store_longitude: -79.7153539,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0457267,
        store_longitude: -79.870685,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.12396,
        store_longitude: -79.4364,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.0407538,
        store_longitude: -79.6917941,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0366093,
        store_longitude: -79.8321721,
    },
    {
        store_latitdue: -1.04651,
        store_longitude: -79.883,
    },
    {
        store_latitdue: -1.0387456,
        store_longitude: -79.6427427,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.03882861,
        store_longitude: -79.8294982,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.03809,
        store_longitude: -79.8301,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -0.9261112,
        store_longitude: -79.4151801,
    },
    {
        store_latitdue: -1.622907,
        store_longitude: -79.561326,
        neighborhood: 'SAN JUAN',
    },
    {
        store_latitdue: -0.9450449,
        store_longitude: -79.3528557,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.023111441,
        store_longitude: -79.45768112,
    },
    {
        store_latitdue: -1.0317757,
        store_longitude: -79.459387,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.029109,
        store_longitude: -79.4632025,
    },
    {
        store_latitdue: -1.0284,
        store_longitude: -79.4583,
    },
    {
        store_latitdue: -1.0406873,
        store_longitude: -79.4562861,
    },
    {
        store_latitdue: -0.9636523,
        store_longitude: -79.4217771,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -1.00079,
        store_longitude: -79.4455,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9928586,
        store_longitude: -79.4387663,
    },
    {
        store_latitdue: -1.0098819,
        store_longitude: -79.4465455,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.94252914,
        store_longitude: -79.2603149,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.43595,
        store_longitude: -79.4577,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -1.55563,
        store_longitude: -79.5318,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.0244149,
        store_longitude: -79.4445749,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9393941,
        store_longitude: -79.2268574,
    },
    {
        store_latitdue: -1.4321563,
        store_longitude: -79.2801047,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.5707482,
        store_longitude: -79.47272,
    },
    {
        store_latitdue: -1.23367667,
        store_longitude: -79.2383041,
        neighborhood: 'SAN LUIS DE PAMBIL',
    },
    {
        store_latitdue: -1.0190533,
        store_longitude: -79.461745,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.050056,
        store_longitude: -79.820102,
    },
    {
        store_latitdue: -1.114605,
        store_longitude: -79.436085,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.5706416,
        store_longitude: -79.473116,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -1.2103136,
        store_longitude: -79.3188885,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.3552,
        store_longitude: -79.3249,
    },
    {
        store_latitdue: -1.0261091,
        store_longitude: -79.4545056,
    },
    {
        store_latitdue: 0.02599,
        store_longitude: -78.897901,
    },
    {
        store_latitdue: -0.892743,
        store_longitude: -79.488924,
    },
    {
        store_latitdue: -0.8966785,
        store_longitude: -79.4856594,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.944842,
        store_longitude: -79.233645,
    },
    {
        store_latitdue: -0.9434349,
        store_longitude: -79.2359237,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.03096,
        store_longitude: -79.47223,
    },
    {
        store_latitdue: -0.718152,
        store_longitude: -79.1177,
        neighborhood: 'PUCAYACU',
    },
    {
        store_latitdue: -1.3735416,
        store_longitude: -79.8994666,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -0.8856977,
        store_longitude: -79.4942614,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8968269,
        store_longitude: -79.4854163,
    },
    {
        store_latitdue: -1.0378323,
        store_longitude: -79.4621102,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.1092,
        store_longitude: -79.4329,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -1.039369,
        store_longitude: -79.467126,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.016617,
        store_longitude: -79.4700563,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.01455,
        store_longitude: -79.4727,
    },
    {
        store_latitdue: -1.03442,
        store_longitude: -79.4627,
    },
    {
        store_latitdue: -0.9020153,
        store_longitude: -79.4954348,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.035325554,
        store_longitude: -79.46679803,
    },
    {
        store_latitdue: -1.01786,
        store_longitude: -79.4686,
    },
    {
        store_latitdue: -0.9004229,
        store_longitude: -79.4928624,
    },

    {
        store_latitdue: -1.0165154,
        store_longitude: -79.4605648,
    },
    {
        store_latitdue: -0.9010265,
        store_longitude: -79.492723,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.02286,
        store_longitude: -79.4517937,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.24072,
        store_longitude: -78.533024,
    },
    {
        store_latitdue: -1.0457811,
        store_longitude: -79.6388153,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0177525,
        store_longitude: -79.467122,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0322678,
        store_longitude: -79.4705681,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.45667,
        store_longitude: -79.4744,
    },
    {
        store_latitdue: -1.4437072,
        store_longitude: -79.4768358,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: 0.0739742,
        store_longitude: -80.0498639,
    },
    {
        store_latitdue: -1.17737,
        store_longitude: -79.2206,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -0.9485911,
        store_longitude: -79.350535,
    },
    {
        store_latitdue: -0.94382895156741,
        store_longitude: -79.326447201893,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.94409276,
        store_longitude: -79.32647111,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.8771491,
        store_longitude: -79.4953749,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8897587,
        store_longitude: -79.4884061,
    },
    {
        store_latitdue: -1.023821,
        store_longitude: -79.447119,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9411963,
        store_longitude: -79.2503566,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.9449687,
        store_longitude: -79.2403475,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.022675,
        store_longitude: -79.457256,
    },
    {
        store_latitdue: -1.5710209,
        store_longitude: -79.4773376,
    },
    {
        store_latitdue: -1.5697165,
        store_longitude: -79.4768747,
    },
    {
        store_latitdue: -1.2095216,
        store_longitude: -79.318441,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.2136007,
        store_longitude: -79.3224649,
    },
    {
        store_latitdue: -1.1835583,
        store_longitude: -79.2431739,
        neighborhood: 'MORASPUNGO',
    },
    {
        store_latitdue: -0.94074,
        store_longitude: -79.2233,
    },
    {
        store_latitdue: -0.9299319,
        store_longitude: -79.2221083,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.009467,
        store_longitude: -79.442736,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.032372,
        store_longitude: -79.458406,
    },
    {
        store_latitdue: -1.036826,
        store_longitude: -79.453644,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.006183,
        store_longitude: -79.445679,
    },
    {
        store_latitdue: -0.9863893,
        store_longitude: -79.4710736,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9969526569,
        store_longitude: -79.4410082013,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.028289,
        store_longitude: -79.471273,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.036781,
        store_longitude: -79.45591,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.881937,
        store_longitude: -79.4919909,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.88961827,
        store_longitude: -79.4897097,
    },
    {
        store_latitdue: -1.0352968,
        store_longitude: -79.4556308,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0408816,
        store_longitude: -79.4509401,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0251583,
        store_longitude: -79.4415967,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.891693,
        store_longitude: -79.4884,
    },
    {
        store_latitdue: -0.8900996,
        store_longitude: -79.4845479,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9512453,
        store_longitude: -79.3599893,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.449836,
        store_longitude: -79.4633135,
    },
    {
        store_latitdue: -0.89323222,
        store_longitude: -79.4889526,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.44624,
        store_longitude: -79.4646,
    },
    {
        store_latitdue: -1.5485907,
        store_longitude: -79.5286566,
    },
    {
        store_latitdue: -0.945552,
        store_longitude: -79.3529,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.89257586,
        store_longitude: -79.4928054,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.17185,
        store_longitude: -79.219,
    },
    {
        store_latitdue: -0.948114,
        store_longitude: -79.3501352,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.4446265,
        store_longitude: -79.4659814,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9415877,
        store_longitude: -79.3265284,
    },
    {
        store_latitdue: -2.2194206429,
        store_longitude: -80.9502526,
    },
    {
        store_latitdue: -0.956664,
        store_longitude: -79.3524,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.0519801,
        store_longitude: -79.6449923,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.0519883,
        store_longitude: -79.9396287,
        neighborhood: 'SAN SEBASTIÁN',
    },
    {
        store_latitdue: -0.870212,
        store_longitude: -79.4881,
    },
    {
        store_latitdue: -0.8921067,
        store_longitude: -79.49046598,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.936203344,
        store_longitude: -79.47602779,
    },
    {
        store_latitdue: -1.2625932,
        store_longitude: -79.4281459,
        neighborhood: 'ZAPOTAL',
    },
    {
        store_latitdue: -1.0809109,
        store_longitude: -79.6413531,
    },
    {
        store_latitdue: -0.9434163,
        store_longitude: -79.2543645,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.2665115,
        store_longitude: -79.2980673,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -1.1288973,
        store_longitude: -79.2703886,
    },
    {
        store_latitdue: -0.7976079,
        store_longitude: -79.3525823,
    },
    {
        store_latitdue: -0.8919527,
        store_longitude: -79.4908193,
    },
    {
        store_latitdue: -0.8669229,
        store_longitude: -79.3223389,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -0.8645945,
        store_longitude: -79.375372,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.2864557,
        store_longitude: -79.3151336,
    },
    {
        store_latitdue: -1.04352873,
        store_longitude: -79.7844207,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.2311768,
        store_longitude: -79.4222935,
        neighborhood: 'ZAPOTAL',
    },

    {
        store_latitdue: -0.9518715,
        store_longitude: -79.3490028,
    },
    {
        store_latitdue: -0.9408259,
        store_longitude: -79.2215905,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.95454,
        store_longitude: -79.353052,
    },
    {
        store_latitdue: -0.9529548,
        store_longitude: -79.3601839,
    },
    {
        store_latitdue: -0.947417,
        store_longitude: -79.2240679,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.0837103,
        store_longitude: -79.2809301,
    },
    {
        store_latitdue: -0.943312,
        store_longitude: -79.2236743,
        neighborhood: 'LA MANÁ',
    },

    {
        store_latitdue: -0.90713713,
        store_longitude: -79.4462478,
    },
    {
        store_latitdue: -0.9491911,
        store_longitude: -79.228174,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.017219,
        store_longitude: -79.454819,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9441383,
        store_longitude: -79.2274524,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.0194012,
        store_longitude: -79.4620763,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4334192,
        store_longitude: -79.2840182,
        neighborhood: 'ECHEANDÍA',
    },
    {
        store_latitdue: -1.015755,
        store_longitude: -79.452745,
    },
    {
        store_latitdue: -0.9464773,
        store_longitude: -79.2277183,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.94621008,
        store_longitude: -79.2284393,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.944413,
        store_longitude: -79.2751,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -0.8980097,
        store_longitude: -79.4938697,
    },
    {
        store_latitdue: -0.97252,
        store_longitude: -79.471,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.90710455,
        store_longitude: -79.4401967,
    },
    {
        store_latitdue: -0.944319,
        store_longitude: -79.487744,
    },
    {
        store_latitdue: -1.04773,
        store_longitude: -79.4775,
    },
    {
        store_latitdue: -1.00344,
        store_longitude: -79.4434,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.4265195,
        store_longitude: -79.5619475,
        neighborhood: 'PUERTO PECHICHE',
    },
    {
        store_latitdue: -1.005112,
        store_longitude: -79.4454393,
    },
    {
        store_latitdue: -1.0067242,
        store_longitude: -79.4446766,
    },
    {
        store_latitdue: -1.3709783,
        store_longitude: -79.8946517,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -0.760189,
        store_longitude: -79.464183,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.9428119,
        store_longitude: -79.2346572,
        neighborhood: 'LA MANÁ',
    },
    {
        store_latitdue: -1.2065,
        store_longitude: -79.3123,
    },
    {
        store_latitdue: -0.9673394,
        store_longitude: -79.4254943,
    },
    {
        store_latitdue: -0.965903,
        store_longitude: -79.4239,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.9965333,
        store_longitude: -79.4377882,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9662556,
        store_longitude: -79.4205064,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.7556024,
        store_longitude: -79.4645376,
    },
    {
        store_latitdue: -0.6192678,
        store_longitude: -79.4255558,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.809899,
        store_longitude: -79.160407,
    },
    {
        store_latitdue: -1.434436,
        store_longitude: -79.4678835,
        neighborhood: 'VENTANAS',
    },
    {
        store_latitdue: -0.9627895,
        store_longitude: -79.4236615,
    },
    {
        store_latitdue: -1.015232,
        store_longitude: -79.477067,
    },
    {
        store_latitdue: -0.7188327,
        store_longitude: -79.1204219,
        neighborhood: 'PUCAYACU',
    },
    {
        store_latitdue: -0.7704466,
        store_longitude: -79.4653272,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8732394,
        store_longitude: -79.4923847,
    },
    {
        store_latitdue: -1.21777,
        store_longitude: -79.3207,
    },
    {
        store_latitdue: -1.02655355,
        store_longitude: -79.4549596,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.016544,
        store_longitude: -79.467212,
    },
    {
        store_latitdue: -1.04472,
        store_longitude: -79.4709,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0424,
        store_longitude: -79.4728,
    },
    {
        store_latitdue: -1.0129255,
        store_longitude: -79.4750943,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00588,
        store_longitude: -79.4795,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.040095,
        store_longitude: -79.478321,
    },
    {
        store_latitdue: -1.0499887,
        store_longitude: -79.8203593,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.0457927,
        store_longitude: -79.4676461,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.8736578,
        store_longitude: -79.4933808,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.0281275,
        store_longitude: -79.4735097,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.991284,
        store_longitude: -79.4744,
    },

    {
        store_latitdue: -1.05014404,
        store_longitude: -79.4659996,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0582726,
        store_longitude: -79.4635742,
    },
    {
        store_latitdue: -1.0535695,
        store_longitude: -79.4647302,
    },
    {
        store_latitdue: -1.1027686,
        store_longitude: -79.465564,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.3676736,
        store_longitude: -79.893028,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.033052,
        store_longitude: -79.4704957,
    },
    {
        store_latitdue: -0.8894783,
        store_longitude: -79.4845492,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.942525,
        store_longitude: -79.485418,
    },
    {
        store_latitdue: -0.872117484,
        store_longitude: -79.48270226,
    },
    {
        store_latitdue: -1.3653,
        store_longitude: -79.9063,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -1.040094,
        store_longitude: -79.487128,
    },
    {
        store_latitdue: -0.8716424,
        store_longitude: -79.4880781,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.3661627,
        store_longitude: -79.9062128,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: -0.874815,
        store_longitude: -79.4916,
    },
    {
        store_latitdue: -1.32978,
        store_longitude: -79.3751,
        neighborhood: 'LAS NAVES',
    },
    {
        store_latitdue: -0.8880602,
        store_longitude: -79.4864589,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.36662,
        store_longitude: -79.9026,
    },
    {
        store_latitdue: -1.184143,
        store_longitude: -79.5030422,
    },
    {
        store_latitdue: -1.190131,
        store_longitude: -79.505256,
    },
    {
        store_latitdue: -1.182284,
        store_longitude: -79.5009157,
    },
    {
        store_latitdue: -1.18917,
        store_longitude: -79.5072,
    },
    {
        store_latitdue: -1.1864237,
        store_longitude: -79.5039247,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.18619,
        store_longitude: -79.5037,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -1.1858111,
        store_longitude: -79.5033176,
        neighborhood: 'MOCACHE',
    },
    {
        store_latitdue: -2.88724,
        store_longitude: -79.008,
    },
    {
        store_latitdue: -1.18557,
        store_longitude: -79.5037,
    },
    {
        store_latitdue: -2.13187,
        store_longitude: -79.9037,
    },
    {
        store_latitdue: -1.5700157,
        store_longitude: -79.473027,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: 0.006257,
        store_longitude: -79.3986323,
    },
    {
        store_latitdue: -1.1841431,
        store_longitude: -79.501159,
    },
    {
        store_latitdue: -1.23757,
        store_longitude: -79.6697,
    },
    {
        store_latitdue: -1.045402323,
        store_longitude: -79.4825931,
    },
    {
        store_latitdue: -1.0462292,
        store_longitude: -79.4856061,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0057745,
        store_longitude: -79.4700957,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00617,
        store_longitude: -79.469409,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.9996278,
        store_longitude: -79.4731265,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.00622,
        store_longitude: -79.4677,
    },

    {
        store_latitdue: -1.208775954,
        store_longitude: -79.31615711,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.2068772,
        store_longitude: -79.3114801,
        neighborhood: 'QUINSALOMA',
    },
    {
        store_latitdue: -1.206855,
        store_longitude: -79.3111467,
    },
    {
        store_latitdue: -1.43287932,
        store_longitude: -79.4570465,
    },
    {
        store_latitdue: -1.0055404,
        store_longitude: -79.4661263,
    },
    {
        store_latitdue: -0.9429997,
        store_longitude: -79.2708375,
    },
    {
        store_latitdue: -0.9018272,
        store_longitude: -79.4902504,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.902956,
        store_longitude: -79.492,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.01523,
        store_longitude: -79.4685,
    },
    {
        store_latitdue: -1.0367614,
        store_longitude: -79.6682885,
    },
    {
        store_latitdue: -1.4359983,
        store_longitude: -79.2813533,
    },
    {
        store_latitdue: -1.04096,
        store_longitude: -79.6914,
    },

    {
        store_latitdue: -0.9525761,
        store_longitude: -79.3634774,
    },
    {
        store_latitdue: -1.042758,
        store_longitude: -79.63524,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -1.4697631,
        store_longitude: -79.9237731,
        neighborhood: 'BALZAR',
    },
    {
        store_latitdue: 0.8874318,
        store_longitude: -79.8086623,
        neighborhood: 'TONSUPA',
    },
    {
        store_latitdue: -0.897633,
        store_longitude: -79.490545,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.467405,
        store_longitude: -79.922791,
    },
    {
        store_latitdue: -1.5443465,
        store_longitude: -80.0116837,
    },
    {
        store_latitdue: -1.5451766,
        store_longitude: -80.0125329,
    },
    {
        store_latitdue: -1.5453429,
        store_longitude: -80.0095078,
    },
    {
        store_latitdue: -1.54561,
        store_longitude: -80.0099,
    },
    {
        store_latitdue: -0.9835597,
        store_longitude: -79.5722256,
        neighborhood: 'GUAYAS',
    },
    {
        store_latitdue: -1.0278201,
        store_longitude: -79.6233789,
    },

    {
        store_latitdue: -1.5527403,
        store_longitude: -80.0074343,
        neighborhood: 'COLIMES',
    },
    {
        store_latitdue: -1.5447483,
        store_longitude: -80.01313,
        neighborhood: 'COLIMES',
    },
    {
        store_latitdue: -1.5451435,
        store_longitude: -80.0116615,
        neighborhood: 'COLIMES',
    },
    {
        store_latitdue: -1.544368,
        store_longitude: -80.010719,
    },
    {
        store_latitdue: -1.546846448,
        store_longitude: -80.01072619,
        neighborhood: 'COLIMES',
    },
    {
        store_latitdue: -0.98594605,
        store_longitude: -79.5712509,
        neighborhood: 'GUAYAS',
    },
    {
        store_latitdue: -1.0117533,
        store_longitude: -79.4713722,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0316,
        store_longitude: -79.4489,
    },
    {
        store_latitdue: -1.045375,
        store_longitude: -79.474632,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0412894,
        store_longitude: -79.4835381,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.027159,
        store_longitude: -79.453145,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0448,
        store_longitude: -79.4758,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.043616,
        store_longitude: -79.4743757,
    },
    {
        store_latitdue: -1.00464,
        store_longitude: -79.4649,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.04673,
        store_longitude: -79.4715,
    },
    {
        store_latitdue: -1.0454878,
        store_longitude: -79.4716986,
    },
    {
        store_latitdue: -1.02803,
        store_longitude: -79.4600816,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.93776,
        store_longitude: -79.6895089,
        neighborhood: 'GUAYAS',
    },
    {
        store_latitdue: -1.0076121,
        store_longitude: -79.7606455,
        neighborhood: 'EL ROSARIO',
    },
    {
        store_latitdue: -1.0545639,
        store_longitude: -79.4650743,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.703263,
        store_longitude: -80.09622,
    },
    {
        store_latitdue: -1.0485096,
        store_longitude: -79.4686529,
    },
    {
        store_latitdue: -0.7289102,
        store_longitude: -79.467576,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.800922,
        store_longitude: -79.4374301,
        neighborhood: 'VALENCIA',
    },
    {
        store_latitdue: -1.72283,
        store_longitude: -80.3826,
    },
    {
        store_latitdue: -1.5577498,
        store_longitude: -79.5314327,
        neighborhood: 'PUEBLOVIEJO',
    },
    {
        store_latitdue: -1.4282562,
        store_longitude: -79.374581,
    },
    {
        store_latitdue: -0.7449587,
        store_longitude: -79.467697,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8998221,
        store_longitude: -79.4918752,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.00625,
        store_longitude: -79.4677,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.025552,
        store_longitude: -79.465746,
    },
    {
        store_latitdue: -0.8925798,
        store_longitude: -79.4882817,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.8926675,
        store_longitude: -79.4893802,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -1.02436983,
        store_longitude: -79.4678726,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.02520634,
        store_longitude: -79.4671046,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -1.0495922,
        store_longitude: -79.8195516,
        neighborhood: 'PICHINCHA',
    },
    {
        store_latitdue: -1.827057,
        store_longitude: -80.7529029,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.23382,
        store_longitude: -80.9292,
    },
    {
        store_latitdue: -2.22509,
        store_longitude: -80.8522,
    },
    {
        store_latitdue: -2.22639,
        store_longitude: -80.907214,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.731674,
        store_longitude: -80.78347,
    },
    {
        store_latitdue: -2.3171418,
        store_longitude: -80.8542509,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.3238183,
        store_longitude: -80.8548683,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },

    {
        store_latitdue: -1.8267232,
        store_longitude: -80.7531358,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.216212,
        store_longitude: -80.955307,
    },
    {
        store_latitdue: -1.868404,
        store_longitude: -80.738865,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8263131,
        store_longitude: -80.7523543,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.9350773,
        store_longitude: -80.687391,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.93559,
        store_longitude: -80.68947,
    },

    {
        store_latitdue: -2.2171373,
        store_longitude: -80.9558778,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.8800833,
        store_longitude: -80.7297133,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.206557,
        store_longitude: -80.968346,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.6289087,
        store_longitude: -80.399029,
    },
    {
        store_latitdue: -1.9383363,
        store_longitude: -80.6973997,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.3184067,
        store_longitude: -80.55978,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.7747283,
        store_longitude: -80.7646783,
    },
    {
        store_latitdue: -2.3179938,
        store_longitude: -80.5609553,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.3180646,
        store_longitude: -80.5607218,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.9586,
        store_longitude: -80.6296,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.1437,
        store_longitude: -80.7744,
    },
    {
        store_latitdue: -2.209434,
        store_longitude: -80.960814,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.208638,
        store_longitude: -80.957844,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.9367695,
        store_longitude: -80.6871986,
    },
    {
        store_latitdue: -1.8619351,
        store_longitude: -80.7416916,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.87355,
        store_longitude: -80.6851955,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.0108105,
        store_longitude: -80.6292657,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.9439879,
        store_longitude: -80.7247389,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2379824,
        store_longitude: -80.906473,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2409449,
        store_longitude: -80.8903955,
    },
    {
        store_latitdue: -2.2235,
        store_longitude: -80.8517,
    },
    {
        store_latitdue: -2.324128071,
        store_longitude: -80.85480848,
    },
    {
        store_latitdue: -2.222604076,
        store_longitude: -80.9170399,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.200988,
        store_longitude: -80.97826,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.86944,
        store_longitude: -80.7383,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.62918,
        store_longitude: -80.4004259,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.0025689,
        store_longitude: -80.5904332,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.978691,
        store_longitude: -80.752966,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.0242,
        store_longitude: -80.7336,
    },
    {
        store_latitdue: -1.998068,
        store_longitude: -80.587766,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.7038044,
        store_longitude: -80.3170254,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.025155,
        store_longitude: -80.7329833,
    },
    {
        store_latitdue: -2.0262883,
        store_longitude: -80.733475,
    },
    {
        store_latitdue: -2.236382,
        store_longitude: -80.913278,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2407602,
        store_longitude: -80.9024671,
    },
    {
        store_latitdue: -2.2155595,
        store_longitude: -80.9561122,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.9790865,
        store_longitude: -80.7528179,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2338,
        store_longitude: -80.9274,
    },
    {
        store_latitdue: -2.6276209,
        store_longitude: -80.393045,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.232809,
        store_longitude: -80.911008,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2355986,
        store_longitude: -80.912646,
    },
    {
        store_latitdue: -2.2171314,
        store_longitude: -80.9557666,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.23527928,
        store_longitude: -80.9000587,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.23016,
        store_longitude: -80.9069,
    },
    {
        store_latitdue: -2.2185307,
        store_longitude: -80.9594107,
    },
    {
        store_latitdue: -2.63697,
        store_longitude: -80.383,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.02392196,
        store_longitude: -80.7338409,
    },
    {
        store_latitdue: -2.22078,
        store_longitude: -80.9574,
    },
    {
        store_latitdue: -2.2070177,
        store_longitude: -80.9742423,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.20704,
        store_longitude: -80.973462,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.23506,
        store_longitude: -80.8868,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2258322,
        store_longitude: -80.9067581,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2356073,
        store_longitude: -80.9312798,
    },
    {
        store_latitdue: -2.23432,
        store_longitude: -80.914386,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.827239,
        store_longitude: -80.7527733,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6356112,
        store_longitude: -80.3867343,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2288604,
        store_longitude: -80.9144681,
    },
    {
        store_latitdue: -2.63563,
        store_longitude: -80.389,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2308721,
        store_longitude: -80.9086844,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.3764981,
        store_longitude: -80.7038998,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.24389,
        store_longitude: -80.933,
    },
    {
        store_latitdue: -2.70282,
        store_longitude: -80.2488024,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.942094,
        store_longitude: -80.724877,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.238016,
        store_longitude: -80.9308504,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.83619,
        store_longitude: -80.7483,
    },
    {
        store_latitdue: -2.2367696,
        store_longitude: -80.8966453,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.82734,
        store_longitude: -80.7518,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.24094,
        store_longitude: -80.9053,
    },
    {
        store_latitdue: -2.31396,
        store_longitude: -80.7061,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.572664,
        store_longitude: -80.4871289,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2318039,
        store_longitude: -80.8906475,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.205274,
        store_longitude: -80.976735,
    },
    {
        store_latitdue: -2.4709989,
        store_longitude: -80.562446,
    },
    {
        store_latitdue: -2.4015083,
        store_longitude: -80.6795266,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.40139,
        store_longitude: -80.6896,
    },
    {
        store_latitdue: -2.316211864,
        store_longitude: -80.56579525,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.2315655,
        store_longitude: -80.93337,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.532322,
        store_longitude: -80.391174,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2216862,
        store_longitude: -80.9332629,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.7033369,
        store_longitude: -80.3164799,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.5336724,
        store_longitude: -80.3882563,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6354,
        store_longitude: -80.3879,
    },
    {
        store_latitdue: -2.63479442,
        store_longitude: -80.38783698,
    },
    {
        store_latitdue: -2.6582911,
        store_longitude: -80.3709708,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.713771,
        store_longitude: -80.3106,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.628307,
        store_longitude: -80.393706,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6308206,
        store_longitude: -80.393628,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2939303,
        store_longitude: -80.70623,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2176579,
        store_longitude: -80.9577322,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2251956,
        store_longitude: -80.9082272,
    },
    {
        store_latitdue: -1.762148,
        store_longitude: -80.767229,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2297347,
        store_longitude: -80.9291877,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },

    {
        store_latitdue: -2.705354,
        store_longitude: -80.2464705,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.699692,
        store_longitude: -80.321518,
    },
    {
        store_latitdue: -2.71155,
        store_longitude: -80.24398,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.719259,
        store_longitude: -80.24607,
    },
    {
        store_latitdue: -2.626407,
        store_longitude: -80.401573,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.0234144,
        store_longitude: -80.7274347,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2247802,
        store_longitude: -80.8934008,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2203399,
        store_longitude: -80.8947745,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.21388,
        store_longitude: -80.9488,
    },
    {
        store_latitdue: -2.219431,
        store_longitude: -80.898959,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2310154,
        store_longitude: -80.8955009,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6985306,
        store_longitude: -80.3237975,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2271586,
        store_longitude: -80.8480282,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -1.945295,
        store_longitude: -80.7263859,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.210261,
        store_longitude: -80.963706,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.24078,
        store_longitude: -80.8559,
    },
    {
        store_latitdue: -2.231603,
        store_longitude: -80.889557,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2362782,
        store_longitude: -80.9047541,
    },
    {
        store_latitdue: -2.695244,
        store_longitude: -80.326255,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.233741437,
        store_longitude: -80.89716447,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.3093126,
        store_longitude: -80.7711365,
    },
    {
        store_latitdue: -2.233165,
        store_longitude: -80.9178102,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24316,
        store_longitude: -80.9311,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.33546,
        store_longitude: -80.2718,
    },
    {
        store_latitdue: -2.7137176,
        store_longitude: -80.2506421,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.93598,
        store_longitude: -80.6851,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.713966,
        store_longitude: -80.251471,
    },
    {
        store_latitdue: -2.23373,
        store_longitude: -80.9263,
    },
    {
        store_latitdue: -2.406244,
        store_longitude: -80.365851,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.2305786,
        store_longitude: -80.9147746,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2424374,
        store_longitude: -80.9190679,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.872983,
        store_longitude: -80.686078,
    },
    {
        store_latitdue: -2.2321807,
        store_longitude: -80.9077889,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2436114,
        store_longitude: -80.9016034,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.84013,
        store_longitude: -80.7478,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.7115307,
        store_longitude: -80.2430661,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.3121604,
        store_longitude: -80.7049745,
    },
    {
        store_latitdue: -2.23635,
        store_longitude: -80.9015,
    },
    {
        store_latitdue: -2.71222,
        store_longitude: -80.2439133,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.62689,
        store_longitude: -80.3855,
    },
    {
        store_latitdue: -2.02199,
        store_longitude: -80.7366,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.827062,
        store_longitude: -80.753016,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.827404,
        store_longitude: -80.753187,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2279783,
        store_longitude: -80.8584183,
    },
    {
        store_latitdue: -2.22064,
        store_longitude: -80.9585,
    },
    {
        store_latitdue: -2.241714762,
        store_longitude: -80.90742472,
    },
    {
        store_latitdue: -1.9985655,
        store_longitude: -80.585981,
    },
    {
        store_latitdue: -2.222534,
        store_longitude: -80.909541,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.228848,
        store_longitude: -80.909388,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9396653,
        store_longitude: -80.6970429,
    },
    {
        store_latitdue: -2.3084091,
        store_longitude: -80.2866178,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.7049232,
        store_longitude: -80.2513304,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.235,
        store_longitude: -80.8899,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6303787,
        store_longitude: -80.3994499,
        neighborhood: 'GENERAL VILLAMIL',
    },

    {
        store_latitdue: -2.021113,
        store_longitude: -80.736966,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2337606,
        store_longitude: -80.9156874,
    },
    {
        store_latitdue: -2.20521,
        store_longitude: -80.9774,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2039265,
        store_longitude: -80.9765678,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.3759,
        store_longitude: -80.2328,
    },
    {
        store_latitdue: -2.020245,
        store_longitude: -80.73676,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2006256,
        store_longitude: -80.9756819,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.4079012,
        store_longitude: -80.3620096,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.6341319,
        store_longitude: -80.3814004,
    },
    {
        store_latitdue: -2.2412129,
        store_longitude: -80.9075854,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6239724,
        store_longitude: -80.3929065,
    },
    {
        store_latitdue: -2.206767,
        store_longitude: -80.950154,
    },
    {
        store_latitdue: -1.947895,
        store_longitude: -80.726927,
    },
    {
        store_latitdue: -2.24057,
        store_longitude: -80.8959,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.2359307,
        store_longitude: -80.9208258,
    },
    {
        store_latitdue: -2.0264666,
        store_longitude: -80.734385,
    },
    {
        store_latitdue: -2.2141151,
        store_longitude: -80.9520416,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.9798186,
        store_longitude: -80.7527497,
    },
    {
        store_latitdue: -2.22085,
        store_longitude: -80.9137,
    },

    {
        store_latitdue: -2.559197,
        store_longitude: -80.509209,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7085195,
        store_longitude: -80.2495083,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2479,
        store_longitude: -80.9057,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.219912,
        store_longitude: -80.95692,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2191059,
        store_longitude: -80.9621562,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2223935,
        store_longitude: -80.9307571,
        neighborhood: 'SALINAS',
    },

    {
        store_latitdue: -2.7049393,
        store_longitude: -80.2511525,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.731012,
        store_longitude: -80.783655,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.24249,
        store_longitude: -80.9323,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2104583,
        store_longitude: -80.9654049,
    },
    {
        store_latitdue: -2.2205993,
        store_longitude: -80.8594132,
    },
    {
        store_latitdue: -2.20926,
        store_longitude: -80.9625,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.220156,
        store_longitude: -80.945206,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.4677537,
        store_longitude: -80.5661054,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.6366377,
        store_longitude: -80.3759503,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.213708,
        store_longitude: -80.9454,
    },
    {
        store_latitdue: -2.220773,
        store_longitude: -80.9575883,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.203928,
        store_longitude: -80.976904,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.24362,
        store_longitude: -80.933,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.642236,
        store_longitude: -80.384876,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.22449,
        store_longitude: -80.8625,
    },
    {
        store_latitdue: -2.20762,
        store_longitude: -80.9585,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.979033,
        store_longitude: -80.752998,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2267992,
        store_longitude: -80.848949,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -1.87367,
        store_longitude: -80.7361,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.054546,
        store_longitude: -80.733419,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.5767641,
        store_longitude: -80.488381,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.23881,
        store_longitude: -80.919054,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2390953,
        store_longitude: -80.9192746,
    },
    {
        store_latitdue: -1.94212,
        store_longitude: -80.614,
    },
    {
        store_latitdue: -1.8288159,
        store_longitude: -80.7525696,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2374375,
        store_longitude: -80.8529003,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2292291,
        store_longitude: -80.9025658,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.35985,
        store_longitude: -80.7176,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.3343952,
        store_longitude: -80.2710033,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -1.8293314,
        store_longitude: -80.7510288,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.223115,
        store_longitude: -80.9155699,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.3596643,
        store_longitude: -80.7179938,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.23831,
        store_longitude: -80.9166,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.36729,
        store_longitude: -80.7124,
    },
    {
        store_latitdue: -2.7095615,
        store_longitude: -80.2440465,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.24887,
        store_longitude: -80.8612,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2247278,
        store_longitude: -80.8479533,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.232239,
        store_longitude: -80.895955,
    },
    {
        store_latitdue: -2.223,
        store_longitude: -80.862,
    },
    {
        store_latitdue: -2.4070609,
        store_longitude: -80.3659695,
    },
    {
        store_latitdue: -2.239341,
        store_longitude: -80.90209,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2384526,
        store_longitude: -80.9171013,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.234939,
        store_longitude: -80.890097,
    },
    {
        store_latitdue: -2.2857344,
        store_longitude: -80.719956,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2296,
        store_longitude: -80.8891,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.4024996,
        store_longitude: -80.6809073,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.708111,
        store_longitude: -80.2438315,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.212632,
        store_longitude: -80.948176,
    },
    {
        store_latitdue: -2.21296,
        store_longitude: -80.9483,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.20425,
        store_longitude: -80.9767,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.209314,
        store_longitude: -80.962899,
    },
    {
        store_latitdue: -2.27342,
        store_longitude: -80.6954056,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.7125155,
        store_longitude: -80.2523649,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.23885,
        store_longitude: -80.9306,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.243,
        store_longitude: -80.9083,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.224453,
        store_longitude: -80.914869,
    },
    {
        store_latitdue: -1.8323786,
        store_longitude: -80.7516784,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.9437381,
        store_longitude: -80.7252304,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.61103,
        store_longitude: -80.3006567,
    },
    {
        store_latitdue: -2.2518241,
        store_longitude: -80.5769283,
    },
    {
        store_latitdue: -2.24255594,
        store_longitude: -80.91301668,
    },
    {
        store_latitdue: -2.24800106,
        store_longitude: -80.9188342,
    },
    {
        store_latitdue: -2.3130992,
        store_longitude: -80.7739417,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -2.24172069,
        store_longitude: -80.9104547,
    },
    {
        store_latitdue: -2.22504,
        store_longitude: -80.9045,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.234641253,
        store_longitude: -80.90167557,
    },
    {
        store_latitdue: -1.940916,
        store_longitude: -80.7215,
    },
    {
        store_latitdue: -2.0237925,
        store_longitude: -80.7333985,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2206914,
        store_longitude: -80.9585263,
    },
    {
        store_latitdue: -2.2349045,
        store_longitude: -80.9327965,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.21368,
        store_longitude: -80.9467,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.6333324,
        store_longitude: -80.3932917,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.23176,
        store_longitude: -80.9012,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.241380671,
        store_longitude: -80.9131746,
    },
    {
        store_latitdue: -2.209694,
        store_longitude: -80.951841,
    },
    {
        store_latitdue: -2.6308688,
        store_longitude: -80.3863405,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7129287,
        store_longitude: -80.2516898,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2441672,
        store_longitude: -80.9088624,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.23077,
        store_longitude: -80.905252,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2317036,
        store_longitude: -80.9334036,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.6201225,
        store_longitude: -80.3934364,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.23993,
        store_longitude: -80.9167,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.61043,
        store_longitude: -80.3049,
    },
    {
        store_latitdue: -2.211875,
        store_longitude: -80.957549,
    },
    {
        store_latitdue: -2.2123018,
        store_longitude: -80.9582303,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2480432,
        store_longitude: -80.9160306,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.233247,
        store_longitude: -80.903613,
    },
    {
        store_latitdue: -2.25479,
        store_longitude: -80.9102,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.22752,
        store_longitude: -80.8571,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2372925,
        store_longitude: -80.8892812,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6289087,
        store_longitude: -80.399029,
        neighborhood: 'GENERAL VILLAMIL',
    },

    {
        store_latitdue: -2.23997,
        store_longitude: -80.8879,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7157267,
        store_longitude: -80.2473261,
    },
    {
        store_latitdue: -1.879164723,
        store_longitude: -80.73394686,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.5748061,
        store_longitude: -80.4843478,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6271866,
        store_longitude: -80.3929983,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2415022,
        store_longitude: -80.8904698,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.98482,
        store_longitude: -80.6762,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.233677,
        store_longitude: -80.915486,
    },
    {
        store_latitdue: -2.3166012,
        store_longitude: -80.8890121,
        neighborhood: 'ANCONCITO',
    },
    {
        store_latitdue: -2.23837,
        store_longitude: -80.924,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.222047,
        store_longitude: -80.917052,
    },
    {
        store_latitdue: -1.872375403,
        store_longitude: -80.7363952,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2275114,
        store_longitude: -80.8583552,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.0549763,
        store_longitude: -80.7335385,
    },
    {
        store_latitdue: -2.3482184,
        store_longitude: -80.2786738,
    },
    {
        store_latitdue: -2.242507,
        store_longitude: -80.891087,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.349038,
        store_longitude: -80.2793042,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },

    {
        store_latitdue: -2.212905,
        store_longitude: -80.946864,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.21926,
        store_longitude: -80.9587,
    },
    {
        store_latitdue: -2.244173,
        store_longitude: -80.9190307,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7109335,
        store_longitude: -80.2475234,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7114302,
        store_longitude: -80.2432514,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.8682257,
        store_longitude: -80.737072,
    },
    {
        store_latitdue: -1.985751691,
        store_longitude: -80.67813161,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.7596417,
        store_longitude: -80.7697514,
        neighborhood: 'MANGLARALTO',
    },

    {
        store_latitdue: -2.2379432,
        store_longitude: -80.916637,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6290488,
        store_longitude: -80.3937392,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.239642423,
        store_longitude: -80.90572329,
    },
    {
        store_latitdue: -2.227576,
        store_longitude: -80.91407,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9558184,
        store_longitude: -80.654331,
    },
    {
        store_latitdue: -2.2554357,
        store_longitude: -80.9074123,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2369697,
        store_longitude: -80.931329,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.1340209,
        store_longitude: -80.7695427,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2454052,
        store_longitude: -80.9060795,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.22629,
        store_longitude: -80.8524,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.3159766,
        store_longitude: -80.854405,
    },
    {
        store_latitdue: -2.24168,
        store_longitude: -80.8959,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.21779,
        store_longitude: -80.8949,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.631908747,
        store_longitude: -80.387136,
    },
    {
        store_latitdue: -2.24597,
        store_longitude: -80.9157,
    },
    {
        store_latitdue: -2.140782,
        store_longitude: -80.776665,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.02084,
        store_longitude: -80.6735,
    },
    {
        store_latitdue: -2.713369,
        store_longitude: -80.246596,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2420761,
        store_longitude: -80.8835431,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.3635892,
        store_longitude: -80.7110255,
    },
    {
        store_latitdue: -2.2324721,
        store_longitude: -80.9076792,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.3329,
        store_longitude: -80.2713,
    },
    {
        store_latitdue: -2.2335223,
        store_longitude: -80.8900075,
    },
    {
        store_latitdue: -2.3584183,
        store_longitude: -80.6569533,
    },
    {
        store_latitdue: -2.3139675,
        store_longitude: -80.882892,
        neighborhood: 'ANCONCITO',
    },
    {
        store_latitdue: -2.232189,
        store_longitude: -80.908507,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.2342099,
        store_longitude: -80.9256304,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.24431,
        store_longitude: -80.9086,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2402149,
        store_longitude: -80.9299794,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.3138823,
        store_longitude: -80.776413,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -2.70763,
        store_longitude: -80.2444,
    },
    {
        store_latitdue: -1.8001382,
        store_longitude: -80.7565548,
        neighborhood: 'MANGLARALTO',
    },

    {
        store_latitdue: -2.22754,
        store_longitude: -80.8873,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.62482,
        store_longitude: -80.3989,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.31782,
        store_longitude: -80.5617,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.7081621,
        store_longitude: -80.2477696,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.0246164,
        store_longitude: -80.73383,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.9497547,
        store_longitude: -80.7278847,
    },
    {
        store_latitdue: -1.9985266,
        store_longitude: -80.7222937,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2343917,
        store_longitude: -80.9131154,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.639059,
        store_longitude: -80.3844277,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2049709,
        store_longitude: -80.9583621,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.45733,
        store_longitude: -80.2828,
    },
    {
        store_latitdue: -2.478878,
        store_longitude: -80.2206,
    },
    {
        store_latitdue: -2.480762,
        store_longitude: -80.219759,
    },
    {
        store_latitdue: -2.4799964,
        store_longitude: -80.2195516,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.19834,
        store_longitude: -80.9847,
        neighborhood: 'SALINAS',
    },

    {
        store_latitdue: -2.7030909,
        store_longitude: -80.2449965,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7071405,
        store_longitude: -80.2465171,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7094815,
        store_longitude: -80.2478748,
    },
    {
        store_latitdue: -2.2351016,
        store_longitude: -80.9123507,
    },
    {
        store_latitdue: -1.9357861,
        store_longitude: -80.6865715,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.4805047,
        store_longitude: -80.2198974,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.4955007,
        store_longitude: -80.1966865,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.226815,
        store_longitude: -80.8525948,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2272833,
        store_longitude: -80.8476149,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.230089,
        store_longitude: -80.914689,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2316,
        store_longitude: -80.916,
    },
    {
        store_latitdue: -2.2437046,
        store_longitude: -80.9132474,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9977122,
        store_longitude: -80.5862924,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2414508,
        store_longitude: -80.88369,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24163,
        store_longitude: -80.885,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.494656,
        store_longitude: -80.197341,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.4941819,
        store_longitude: -80.1979555,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.2194315,
        store_longitude: -80.9599371,
    },
    {
        store_latitdue: -1.868489,
        store_longitude: -80.73803,
    },
    {
        store_latitdue: -2.2248985,
        store_longitude: -80.8521423,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.23757348,
        store_longitude: -80.9326636,
    },
    {
        store_latitdue: -2.2447279,
        store_longitude: -80.8910279,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -1.86692,
        store_longitude: -80.7407,
    },
    {
        store_latitdue: -1.9416011,
        store_longitude: -80.6986392,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.210776,
        store_longitude: -80.972334,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.9765983,
        store_longitude: -80.7513183,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.4717244,
        store_longitude: -80.5618698,
    },
    {
        store_latitdue: -2.154647,
        store_longitude: -80.780371,
    },
    {
        store_latitdue: -2.200778,
        store_longitude: -80.98001,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.6109219,
        store_longitude: -80.301836,
    },
    {
        store_latitdue: -2.2238,
        store_longitude: -80.9445,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.6373425,
        store_longitude: -80.378127,
    },
    {
        store_latitdue: -1.84955,
        store_longitude: -80.745995,
    },
    {
        store_latitdue: -2.2100351,
        store_longitude: -80.9650653,
    },
    {
        store_latitdue: -2.316902,
        store_longitude: -80.645491,
    },
    {
        store_latitdue: -2.2385333,
        store_longitude: -80.916997,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9793894,
        store_longitude: -80.7518706,
    },
    {
        store_latitdue: -2.201586,
        store_longitude: -80.978155,
    },
    {
        store_latitdue: -2.23528,
        store_longitude: -80.9335,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2354254,
        store_longitude: -80.9363337,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.830277,
        store_longitude: -80.751743,
    },
    {
        store_latitdue: -2.21296,
        store_longitude: -80.9472,
    },
    {
        store_latitdue: -2.242064,
        store_longitude: -80.900316,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7101039,
        store_longitude: -80.2493509,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.8493949,
        store_longitude: -80.7454802,
        neighborhood: 'MANGLARALTO',
    },

    {
        store_latitdue: -2.635333,
        store_longitude: -80.381645,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2342638,
        store_longitude: -80.890246,
    },
    {
        store_latitdue: -2.0422417,
        store_longitude: -80.7279648,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.14387,
        store_longitude: -80.7756,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.20846,
        store_longitude: -80.9671,
    },
    {
        store_latitdue: -1.940566,
        store_longitude: -80.724388,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.15044656,
        store_longitude: -80.76603245,
    },
    {
        store_latitdue: -2.2103805,
        store_longitude: -80.963657,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2060533,
        store_longitude: -80.9726143,
    },
    {
        store_latitdue: -2.02115,
        store_longitude: -80.669351,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.6282825,
        store_longitude: -80.3871421,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.946629,
        store_longitude: -80.726556,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8531597,
        store_longitude: -80.7448232,
    },
    {
        store_latitdue: -2.0533614,
        store_longitude: -80.7330389,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.1505414,
        store_longitude: -80.7767571,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.0448291,
        store_longitude: -80.7300457,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.236458524,
        store_longitude: -80.90140075,
    },
    {
        store_latitdue: -1.8283628,
        store_longitude: -80.7526722,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.20336,
        store_longitude: -80.9768,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.20338,
        store_longitude: -80.977,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.242798,
        store_longitude: -80.886463,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.4011576,
        store_longitude: -80.6801011,
    },
    {
        store_latitdue: -2.20402,
        store_longitude: -80.9773,
        neighborhood: 'SALINAS',
    },

    {
        store_latitdue: -1.9413129,
        store_longitude: -80.7255887,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8822382,
        store_longitude: -80.7346749,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.7054884,
        store_longitude: -80.2453206,
    },
    {
        store_latitdue: -2.7141036,
        store_longitude: -80.2452036,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.8276041,
        store_longitude: -80.7533865,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.20623238,
        store_longitude: -80.97567533,
    },
    {
        store_latitdue: -2.239786,
        store_longitude: -80.9201,
    },
    {
        store_latitdue: -2.62821,
        store_longitude: -80.392,
    },
    {
        store_latitdue: -2.2348619,
        store_longitude: -80.8880238,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.355345749,
        store_longitude: -80.65569836,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.2306406,
        store_longitude: -80.9001863,
    },

    {
        store_latitdue: -1.7987867,
        store_longitude: -80.7587817,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2134,
        store_longitude: -80.9484,
    },
    {
        store_latitdue: -2.2391,
        store_longitude: -80.898,
    },
    {
        store_latitdue: -1.997662,
        store_longitude: -80.587427,
    },
    {
        store_latitdue: -2.22476137,
        store_longitude: -80.9261298,
    },
    {
        store_latitdue: -2.23911,
        store_longitude: -80.9167,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2037,
        store_longitude: -80.9768,
    },
    {
        store_latitdue: -2.231593,
        store_longitude: -80.9340609,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.885673,
        store_longitude: -80.733987,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.23699,
        store_longitude: -80.9328,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2412218,
        store_longitude: -80.9160677,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2274406,
        store_longitude: -80.8471328,
    },
    {
        store_latitdue: -2.2133848,
        store_longitude: -80.9537315,
    },

    {
        store_latitdue: -2.4791908,
        store_longitude: -80.2210223,
    },
    {
        store_latitdue: -2.7118723,
        store_longitude: -80.2648123,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6236018,
        store_longitude: -80.3916915,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.634902,
        store_longitude: -80.3936621,
    },
    {
        store_latitdue: -2.2039601,
        store_longitude: -80.977135,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.214523,
        store_longitude: -80.945254,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.230855,
        store_longitude: -80.9109226,
    },
    {
        store_latitdue: -2.2399805,
        store_longitude: -80.9367501,
    },
    {
        store_latitdue: -2.63058,
        store_longitude: -80.3993,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.23209,
        store_longitude: -80.8412,
    },
    {
        store_latitdue: -2.711496,
        store_longitude: -80.244199,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.4079263,
        store_longitude: -80.3656651,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.6271344,
        store_longitude: -80.3938501,
    },
    {
        store_latitdue: -2.23198,
        store_longitude: -80.899,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6253716,
        store_longitude: -80.3938298,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.234509,
        store_longitude: -80.9320802,
    },
    {
        store_latitdue: -2.6294282,
        store_longitude: -80.3883494,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.242261,
        store_longitude: -80.9051411,
    },
    {
        store_latitdue: -2.23800233,
        store_longitude: -80.9052085,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24647,
        store_longitude: -80.9304,
    },
    {
        store_latitdue: -2.2323759,
        store_longitude: -80.9364585,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.63347,
        store_longitude: -80.3927,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.1552261,
        store_longitude: -80.7799827,
    },
    {
        store_latitdue: -2.6280716,
        store_longitude: -80.3975029,
    },
    {
        store_latitdue: -2.29384,
        store_longitude: -80.8472,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.6375096,
        store_longitude: -80.3891908,
    },
    {
        store_latitdue: -2.21087,
        store_longitude: -80.9561527,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.33529,
        store_longitude: -80.2713,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.0425982,
        store_longitude: -80.7310201,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.23867438,
        store_longitude: -80.92029663,
    },
    {
        store_latitdue: -2.71077,
        store_longitude: -80.2511,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6358349,
        store_longitude: -80.3889124,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2437395,
        store_longitude: -80.9120028,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2204694,
        store_longitude: -80.939522,
    },
    {
        store_latitdue: -1.9759267,
        store_longitude: -80.7494824,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.3140649,
        store_longitude: -80.7040828,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.6280769,
        store_longitude: -80.3892198,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2395877,
        store_longitude: -80.9101144,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.968673403,
        store_longitude: -80.65236496,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2179985,
        store_longitude: -80.9561144,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.629544,
        store_longitude: -80.394569,
        neighborhood: 'GENERAL VILLAMIL',
    },

    {
        store_latitdue: -2.629765,
        store_longitude: -80.386596,
    },
    {
        store_latitdue: -2.55869,
        store_longitude: -80.5094,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.8282585,
        store_longitude: -80.7521919,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8275691,
        store_longitude: -80.7536315,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6152594,
        store_longitude: -80.3987708,
    },
    {
        store_latitdue: -2.2318032,
        store_longitude: -80.9367715,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.63081,
        store_longitude: -80.385,
    },
    {
        store_latitdue: -2.7059456,
        store_longitude: -80.2451598,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.62281,
        store_longitude: -80.39081,
    },
    {
        store_latitdue: -2.6352079,
        store_longitude: -80.390137,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.3332614,
        store_longitude: -80.269449,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.20962,
        store_longitude: -80.9637,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.20638,
        store_longitude: -80.9636,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.6260322,
        store_longitude: -80.3872087,
    },
    {
        store_latitdue: -2.23651,
        store_longitude: -80.9014,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.0240943,
        store_longitude: -80.7289169,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.797578075,
        store_longitude: -80.75689471,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.21141,
        store_longitude: -80.9611,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.225568,
        store_longitude: -80.934982,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.249697,
        store_longitude: -80.9166,
    },

    {
        store_latitdue: -2.6714829,
        store_longitude: -80.3625243,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.303751,
        store_longitude: -80.281373,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.20636,
        store_longitude: -80.9712,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.7155837,
        store_longitude: -80.2513008,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.712901,
        store_longitude: -80.31375,
    },

    {
        store_latitdue: -2.21061,
        store_longitude: -80.9504,
    },
    {
        store_latitdue: -2.3094677,
        store_longitude: -80.2865177,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.6744606,
        store_longitude: -80.3594584,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.22531,
        store_longitude: -80.8923,
    },
    {
        store_latitdue: -2.2326911,
        store_longitude: -80.8976888,
    },
    {
        store_latitdue: -2.32455,
        store_longitude: -80.8547,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.7124569,
        store_longitude: -80.2450846,
    },
    {
        store_latitdue: -2.2337331,
        store_longitude: -80.9096045,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.8294337,
        store_longitude: -80.7525747,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8280814,
        store_longitude: -80.7529041,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.3578216,
        store_longitude: -80.65613,
    },
    {
        store_latitdue: -2.6194672,
        store_longitude: -80.3901392,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.3833566,
        store_longitude: -80.6974437,
    },
    {
        store_latitdue: -2.02336,
        store_longitude: -80.7303,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.23789,
        store_longitude: -80.8939,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.242398781,
        store_longitude: -80.91934925,
    },
    {
        store_latitdue: -2.5760349,
        store_longitude: -80.4855315,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.243182607,
        store_longitude: -80.9284837,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.798396,
        store_longitude: -80.757956,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.7141459,
        store_longitude: -80.2461205,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7142536,
        store_longitude: -80.2460449,
    },
    {
        store_latitdue: -2.210321,
        store_longitude: -80.969584,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.02034,
        store_longitude: -80.7209,
    },
    {
        store_latitdue: -1.79778,
        store_longitude: -80.7589,
    },
    {
        store_latitdue: -2.1399193,
        store_longitude: -80.7721742,
    },
    {
        store_latitdue: -1.874040188,
        store_longitude: -80.73629649,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.7969498,
        store_longitude: -80.7592303,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.404251035,
        store_longitude: -80.67486555,
    },
    {
        store_latitdue: -2.23748,
        store_longitude: -80.903,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.3165415,
        store_longitude: -80.8812919,
        neighborhood: 'ANCONCITO',
    },
    {
        store_latitdue: -2.24144381,
        store_longitude: -80.93563197,
    },
    {
        store_latitdue: -2.24299518,
        store_longitude: -80.91480878,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24883784,
        store_longitude: -80.90758854,
    },
    {
        store_latitdue: -2.2485893,
        store_longitude: -80.9081154,
    },
    {
        store_latitdue: -2.24859,
        store_longitude: -80.9076,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2426,
        store_longitude: -80.8926,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.321306,
        store_longitude: -80.562851,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.32218,
        store_longitude: -80.5578,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.9497496,
        store_longitude: -80.7267797,
    },
    {
        store_latitdue: -2.2364789,
        store_longitude: -80.8620656,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.241252,
        store_longitude: -80.909399,
    },
    {
        store_latitdue: -2.238714,
        store_longitude: -80.920712,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6263991,
        store_longitude: -80.3943363,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.634996,
        store_longitude: -80.327571,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.6323551,
        store_longitude: -80.379992,
    },
    {
        store_latitdue: -2.232179394,
        store_longitude: -80.9331935,
    },
    {
        store_latitdue: -2.0277671,
        store_longitude: -80.7340629,
    },
    {
        store_latitdue: -1.9351,
        store_longitude: -80.6878,
    },
    {
        store_latitdue: -1.8259902,
        store_longitude: -80.7524303,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.82577,
        store_longitude: -80.75259,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.0251567,
        store_longitude: -80.7285774,
    },
    {
        store_latitdue: -2.2370138,
        store_longitude: -80.8589023,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.7026884,
        store_longitude: -80.2480313,
    },
    {
        store_latitdue: -2.2486189,
        store_longitude: -80.9090065,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6428489,
        store_longitude: -80.381138,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2323109,
        store_longitude: -80.9335887,
    },
    {
        store_latitdue: -2.6276045,
        store_longitude: -80.3887118,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.8276326,
        store_longitude: -80.7523857,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8536077,
        store_longitude: -80.7448761,
    },
    {
        store_latitdue: -2.1460684,
        store_longitude: -80.772041,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2340841,
        store_longitude: -80.863365,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.281324,
        store_longitude: -80.858993,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -1.73048,
        store_longitude: -80.784,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8290386,
        store_longitude: -80.7527883,
    },
    {
        store_latitdue: -2.24234,
        store_longitude: -80.8909,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6330148,
        store_longitude: -80.3831075,
    },
    {
        store_latitdue: -2.245451,
        store_longitude: -80.910848,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.96579,
        store_longitude: -80.6502,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.70854,
        store_longitude: -80.2498,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.61803,
        store_longitude: -80.3793485,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.24247,
        store_longitude: -80.9302,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.23388,
        store_longitude: -80.9286,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },

    {
        store_latitdue: -2.2383504,
        store_longitude: -80.930571,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.21233344,
        store_longitude: -80.9502105,
    },
    {
        store_latitdue: -2.23968,
        store_longitude: -80.9326,
    },
    {
        store_latitdue: -2.2307592,
        store_longitude: -80.9359102,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.232109,
        store_longitude: -80.933773,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.137266,
        store_longitude: -80.7733271,
    },
    {
        store_latitdue: -2.6293405,
        store_longitude: -80.3909839,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.881894,
        store_longitude: -80.734572,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.949979,
        store_longitude: -80.727654,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2488237,
        store_longitude: -80.9255677,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2083,
        store_longitude: -80.9743,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.1434794,
        store_longitude: -80.7763184,
    },
    {
        store_latitdue: -2.21709,
        store_longitude: -80.959695,
    },
    {
        store_latitdue: -2.7113846,
        store_longitude: -80.2437229,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2245174,
        store_longitude: -79.8876868,
    },
    {
        store_latitdue: -2.3165503,
        store_longitude: -80.8806338,
        neighborhood: 'ANCONCITO',
    },
    {
        store_latitdue: -2.31257,
        store_longitude: -80.7749,
    },
    {
        store_latitdue: -2.1370729,
        store_longitude: -80.7724935,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.1369706,
        store_longitude: -80.7726184,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.33267,
        store_longitude: -80.2712,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.2355145,
        store_longitude: -80.9072377,
    },
    {
        store_latitdue: -2.238243,
        store_longitude: -80.902781,
    },
    {
        store_latitdue: -2.0264329,
        store_longitude: -80.733184,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2474896,
        store_longitude: -80.9056007,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2473046,
        store_longitude: -80.9055733,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.63299,
        store_longitude: -80.387,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2156733,
        store_longitude: -80.9485733,
    },
    {
        store_latitdue: -2.3976655,
        store_longitude: -80.3620986,
    },
    {
        store_latitdue: -2.71246,
        store_longitude: -80.251,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6152473,
        store_longitude: -80.3970441,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.87279,
        store_longitude: -80.736259,
    },
    {
        store_latitdue: -2.6223,
        store_longitude: -80.3915,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.707777,
        store_longitude: -80.247943,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.23056,
        store_longitude: -80.8962,
    },
    {
        store_latitdue: -2.2333831,
        store_longitude: -80.8901901,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2351923,
        store_longitude: -80.8997699,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7068741,
        store_longitude: -80.2513343,
        neighborhood: 'POSORJA',
    },

    {
        store_latitdue: -2.4047574,
        store_longitude: -80.6755686,
    },
    {
        store_latitdue: -2.24036087,
        store_longitude: -80.9179115,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.8538437,
        store_longitude: -80.744788,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2357362,
        store_longitude: -80.889724,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.245973,
        store_longitude: -80.915696,
    },
    {
        store_latitdue: -1.934273,
        store_longitude: -80.6872288,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.9379435,
        store_longitude: -80.6909791,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.7182911,
        store_longitude: -80.2982228,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.882683,
        store_longitude: -80.734565,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6248292,
        store_longitude: -80.390512,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.02148,
        store_longitude: -80.6696,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.235742,
        store_longitude: -80.8911116,
    },
    {
        store_latitdue: -2.2170853,
        store_longitude: -80.9647146,
    },
    {
        store_latitdue: -1.910882,
        store_longitude: -80.652421,
    },
    {
        store_latitdue: -2.21016,
        store_longitude: -80.9578,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.1378829,
        store_longitude: -80.7740783,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2288274,
        store_longitude: -80.9137644,
    },
    {
        store_latitdue: -2.2407613,
        store_longitude: -80.8868139,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.059628,
        store_longitude: -80.7346,
    },
    {
        store_latitdue: -2.62242,
        store_longitude: -80.3907,
    },
    {
        store_latitdue: -2.2390993,
        store_longitude: -80.8641208,
        neighborhood: 'SANTA ELENA',
    },

    {
        store_latitdue: -2.2485007,
        store_longitude: -80.5822517,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.63437,
        store_longitude: -80.3795,
    },
    {
        store_latitdue: -2.2101868,
        store_longitude: -80.9576553,
        neighborhood: 'SALINAS',
    },

    {
        store_latitdue: -2.237418556,
        store_longitude: -80.92990737,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2377851,
        store_longitude: -80.9290374,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.7162509,
        store_longitude: -80.2447378,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2350467,
        store_longitude: -80.8587648,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2344197,
        store_longitude: -80.8533727,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.232488,
        store_longitude: -80.90242,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6205257,
        store_longitude: -80.390557,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.76181,
        store_longitude: -80.7673,
        neighborhood: 'MANGLARALTO',
    },

    {
        store_latitdue: -2.015055,
        store_longitude: -80.593993,
    },
    {
        store_latitdue: -2.4014369,
        store_longitude: -80.6816774,
    },
    {
        store_latitdue: -2.3568328,
        store_longitude: -80.6545273,
    },
    {
        store_latitdue: -1.99850486,
        store_longitude: -80.5873993,
    },
    {
        store_latitdue: -2.049817,
        store_longitude: -80.73291,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2320828,
        store_longitude: -80.910412,
    },
    {
        store_latitdue: -2.7163264,
        store_longitude: -80.2461724,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.23704,
        store_longitude: -80.9251,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.7977311,
        store_longitude: -80.757532,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2098096,
        store_longitude: -80.9651319,
    },
    {
        store_latitdue: -2.2302302,
        store_longitude: -80.9103742,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.22802,
        store_longitude: -80.8582,
    },
    {
        store_latitdue: -2.2924014,
        store_longitude: -80.8484317,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.0241682,
        store_longitude: -80.7331541,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.7108455,
        store_longitude: -80.3145868,
    },
    {
        store_latitdue: -2.232669,
        store_longitude: -80.914197,
    },
    {
        store_latitdue: -2.145948,
        store_longitude: -80.773477,
    },
    {
        store_latitdue: -2.2385992,
        store_longitude: -80.9252475,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.4011315,
        store_longitude: -80.6798323,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.8809,
        store_longitude: -80.7342,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.23597544,
        store_longitude: -80.8525729,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2920291,
        store_longitude: -80.8464841,
    },
    {
        store_latitdue: -2.23543,
        store_longitude: -80.9308,
    },
    {
        store_latitdue: -2.24906,
        store_longitude: -80.9278,
    },
    {
        store_latitdue: -1.940932,
        store_longitude: -80.722072,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.91151,
        store_longitude: -80.6531,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2516329,
        store_longitude: -80.5793754,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -1.978055,
        store_longitude: -80.752611,
    },
    {
        store_latitdue: -2.23142,
        store_longitude: -80.9263,
    },
    {
        store_latitdue: -2.2358614,
        store_longitude: -80.9035414,
    },
    {
        store_latitdue: -2.2233633,
        store_longitude: -80.958462,
    },
    {
        store_latitdue: -2.2100266,
        store_longitude: -80.9672118,
    },
    {
        store_latitdue: -2.2277,
        store_longitude: -80.9063,
    },
    {
        store_latitdue: -2.2272824,
        store_longitude: -80.8474978,
    },

    {
        store_latitdue: -1.946,
        store_longitude: -80.7264,
    },
    {
        store_latitdue: -2.2354083,
        store_longitude: -80.931107,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },

    {
        store_latitdue: -2.2100491,
        store_longitude: -80.9652284,
    },
    {
        store_latitdue: -2.22358,
        store_longitude: -80.8522,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -1.9978575,
        store_longitude: -80.5857975,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.284622,
        store_longitude: -80.8577709,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -1.9482847,
        store_longitude: -80.7266848,
    },
    {
        store_latitdue: -2.232531,
        store_longitude: -80.916192,
    },
    {
        store_latitdue: -2.021134,
        store_longitude: -80.671503,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2249112,
        store_longitude: -80.8557408,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.23822155,
        store_longitude: -80.8944199,
    },
    {
        store_latitdue: -2.23385,
        store_longitude: -80.8908,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6323516,
        store_longitude: -80.3904378,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6313059,
        store_longitude: -80.3883798,
    },
    {
        store_latitdue: -1.79674,
        store_longitude: -80.7572,
    },
    {
        store_latitdue: -2.231091,
        store_longitude: -80.856474,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.0494211,
        store_longitude: -80.7315865,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.224951,
        store_longitude: -80.89329,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2424526,
        store_longitude: -80.8908854,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.7120441,
        store_longitude: -80.2463429,
    },
    {
        store_latitdue: -2.61737,
        store_longitude: -80.3798,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2428615,
        store_longitude: -80.9248275,
    },
    {
        store_latitdue: -2.23734,
        store_longitude: -80.9342,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.97669,
        store_longitude: -80.7513,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.236869,
        store_longitude: -80.893074,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2360087,
        store_longitude: -80.883695,
    },
    {
        store_latitdue: -2.3809486,
        store_longitude: -80.6988258,
    },
    {
        store_latitdue: -2.6302502,
        store_longitude: -80.3893878,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7185632,
        store_longitude: -80.2463014,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.0266016,
        store_longitude: -80.733825,
    },
    {
        store_latitdue: -2.3164683,
        store_longitude: -80.8866583,
    },
    {
        store_latitdue: -2.22588,
        store_longitude: -80.9057,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2213468,
        store_longitude: -80.8959459,
    },
    {
        store_latitdue: -2.22211,
        store_longitude: -80.9109,
    },
    {
        store_latitdue: -2.6118794,
        store_longitude: -80.3062716,
    },
    {
        store_latitdue: -2.7056531,
        store_longitude: -80.2522345,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.93697,
        store_longitude: -80.686991,
    },
    {
        store_latitdue: -2.380039948,
        store_longitude: -80.7118441,
    },
    {
        store_latitdue: -2.23868,
        store_longitude: -80.8856196,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.244745,
        store_longitude: -80.91274,
    },

    {
        store_latitdue: -1.79839984,
        store_longitude: -80.75653767,
    },
    {
        store_latitdue: -2.22201,
        store_longitude: -80.9067,
    },
    {
        store_latitdue: -1.79734,
        store_longitude: -80.7566324,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6343858,
        store_longitude: -80.3276159,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -1.8268173,
        store_longitude: -80.7530655,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.82692,
        store_longitude: -80.7532,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2006156,
        store_longitude: -80.9803477,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.9450294,
        store_longitude: -80.7262638,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.84897813,
        store_longitude: -80.7451407,
    },
    {
        store_latitdue: -2.2384221,
        store_longitude: -80.8901534,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7139339,
        store_longitude: -80.2440251,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.24759,
        store_longitude: -80.9293,
    },
    {
        store_latitdue: -2.57291,
        store_longitude: -80.4887,
    },
    {
        store_latitdue: -2.233474,
        store_longitude: -80.917529,
    },
    {
        store_latitdue: -1.7980661,
        store_longitude: -80.7564012,
    },
    {
        store_latitdue: -1.8854559,
        store_longitude: -80.7339936,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.9984446,
        store_longitude: -80.588453,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.6432141,
        store_longitude: -80.3817155,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2229923,
        store_longitude: -80.8458737,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2311686,
        store_longitude: -80.8555444,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.70025722,
        store_longitude: -80.2817344,
    },
    {
        store_latitdue: -2.2344225,
        store_longitude: -80.9085154,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9470152,
        store_longitude: -80.7266268,
    },
    {
        store_latitdue: -2.2097234,
        store_longitude: -80.9693216,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.868596996,
        store_longitude: -80.7387452,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.762405,
        store_longitude: -80.767038,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.0266569,
        store_longitude: -80.7326328,
    },
    {
        store_latitdue: -2.235645,
        store_longitude: -80.890075,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.01069,
        store_longitude: -80.6291,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.40086,
        store_longitude: -80.6808,
    },

    {
        store_latitdue: -2.2230022,
        store_longitude: -80.9070101,
    },
    {
        store_latitdue: -2.3580532,
        store_longitude: -80.6564217,
    },
    {
        store_latitdue: -1.8488,
        store_longitude: -80.7451,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.241449,
        store_longitude: -80.90658,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.042202,
        store_longitude: -80.726318,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.0305821,
        store_longitude: -80.7329345,
    },
    {
        store_latitdue: -2.627562,
        store_longitude: -80.3874344,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.714449,
        store_longitude: -80.245448,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.708041,
        store_longitude: -80.2438026,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2420602,
        store_longitude: -80.913336,
    },
    {
        store_latitdue: -2.22336,
        store_longitude: -80.9076,
    },
    {
        store_latitdue: -2.6376021,
        store_longitude: -80.3895347,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.207,
        store_longitude: -80.9735,
    },
    {
        store_latitdue: -1.9403276,
        store_longitude: -80.7252556,
    },
    {
        store_latitdue: -2.2344857,
        store_longitude: -80.8536693,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.57315067,
        store_longitude: -80.489217,
    },
    {
        store_latitdue: -2.630928,
        store_longitude: -80.398601,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2248671,
        store_longitude: -80.9078491,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.247051,
        store_longitude: -80.915383,
    },
    {
        store_latitdue: -2.0243929,
        store_longitude: -80.7190682,
    },
    {
        store_latitdue: -2.14296,
        store_longitude: -80.7716,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.22733,
        store_longitude: -80.9026,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.0639842,
        store_longitude: -80.7346867,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2342319,
        store_longitude: -80.8583143,
    },
    {
        store_latitdue: -2.06387,
        store_longitude: -80.7341,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2475652,
        store_longitude: -80.9137353,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9327245,
        store_longitude: -80.7162816,
        neighborhood: 'MANGLARALTO',
    },

    {
        store_latitdue: -1.8296906,
        store_longitude: -80.7523461,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2117801,
        store_longitude: -80.9479418,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2126681,
        store_longitude: -80.9496648,
    },
    {
        store_latitdue: -2.2344,
        store_longitude: -80.9318,
    },
    {
        store_latitdue: -2.1408212,
        store_longitude: -80.7764662,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -1.9349,
        store_longitude: -80.6877,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.934768,
        store_longitude: -80.685966,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6518,
        store_longitude: -80.3776,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.998255433,
        store_longitude: -80.72288275,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.610601,
        store_longitude: -80.302604,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.47159,
        store_longitude: -80.5622,
    },
    {
        store_latitdue: -2.24267,
        store_longitude: -80.9072,
    },
    {
        store_latitdue: -2.20406,
        store_longitude: -80.9769,
    },
    {
        store_latitdue: -2.2388862,
        store_longitude: -80.9110463,
    },
    {
        store_latitdue: -2.239154,
        store_longitude: -80.9070176,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24017,
        store_longitude: -80.8986,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.7154254,
        store_longitude: -80.2541422,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.33416,
        store_longitude: -80.2708,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.7037827,
        store_longitude: -80.248251,
    },
    {
        store_latitdue: -2.3122,
        store_longitude: -80.7724,
    },
    {
        store_latitdue: -2.712306,
        store_longitude: -80.245539,
    },
    {
        store_latitdue: -2.235594046,
        store_longitude: -80.92506054,
    },
    {
        store_latitdue: -2.6351001,
        store_longitude: -80.387481,
    },
    {
        store_latitdue: -2.021385,
        store_longitude: -80.7202083,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.70378,
        store_longitude: -80.2513,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.94082,
        store_longitude: -80.7238,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2138189,
        store_longitude: -80.9477785,
    },
    {
        store_latitdue: -2.14298,
        store_longitude: -80.7747,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.6374059,
        store_longitude: -80.3893264,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2048247,
        store_longitude: -80.9768145,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.6374442,
        store_longitude: -80.3891973,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6355358,
        store_longitude: -80.390216,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.23057,
        store_longitude: -80.9048,
    },
    {
        store_latitdue: -2.651283,
        store_longitude: -80.3775743,
    },
    {
        store_latitdue: -2.53323,
        store_longitude: -80.3875,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2267461,
        store_longitude: -80.9135871,
    },
    {
        store_latitdue: -2.2337491,
        store_longitude: -80.9043084,
    },
    {
        store_latitdue: -2.20815,
        store_longitude: -80.9501,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.24605,
        store_longitude: -80.9088,
    },
    {
        store_latitdue: -2.2156869,
        store_longitude: -80.9567861,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.4078197,
        store_longitude: -80.365843,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.7093998,
        store_longitude: -80.2499691,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.3216767,
        store_longitude: -80.8534124,
    },
    {
        store_latitdue: -2.22814,
        store_longitude: -80.9095,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.02136,
        store_longitude: -80.7206,
    },
    {
        store_latitdue: -2.22982,
        store_longitude: -80.9029,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2216107,
        store_longitude: -80.9658147,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.3162135,
        store_longitude: -80.8822902,
        neighborhood: 'ANCONCITO',
    },
    {
        store_latitdue: -2.2395265,
        store_longitude: -80.9247043,
    },
    {
        store_latitdue: -2.23036694,
        store_longitude: -80.9147109,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24068,
        store_longitude: -80.928,
    },
    {
        store_latitdue: -2.6295423,
        store_longitude: -80.3959812,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.62429,
        store_longitude: -80.3918,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.7977151,
        store_longitude: -80.7577687,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.7986,
        store_longitude: -80.7566,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.24319,
        store_longitude: -80.9155,
    },
    {
        store_latitdue: -2.7112497,
        store_longitude: -80.2477785,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.224145,
        store_longitude: -80.918645,
    },

    {
        store_latitdue: -1.82875,
        store_longitude: -80.7521,
    },
    {
        store_latitdue: -2.02074694,
        store_longitude: -80.7370147,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.62836,
        store_longitude: -80.3946,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.882678,
        store_longitude: -80.7345,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.40163429,
        store_longitude: -80.68135142,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.9344916,
        store_longitude: -80.7167791,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6355817,
        store_longitude: -80.3931756,
    },
    {
        store_latitdue: -2.2347219,
        store_longitude: -80.8970155,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2373669,
        store_longitude: -80.901233,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.23759,
        store_longitude: -80.9116,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -1.8272513,
        store_longitude: -80.7523747,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.23032,
        store_longitude: -80.9017,
    },
    {
        store_latitdue: -2.23204,
        store_longitude: -80.9045,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6325213,
        store_longitude: -80.3933657,
        neighborhood: 'GENERAL VILLAMIL',
    },

    {
        store_latitdue: -2.243547,
        store_longitude: -80.918659,
    },
    {
        store_latitdue: -2.70510732,
        store_longitude: -80.2516508,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.22989749,
        store_longitude: -80.9019362,
    },
    {
        store_latitdue: -2.024642507,
        store_longitude: -80.73229501,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.1418398,
        store_longitude: -80.7756721,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -1.8293452,
        store_longitude: -80.6958835,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.219204,
        store_longitude: -80.898462,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.2362552,
        store_longitude: -80.9316525,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.23101,
        store_longitude: -80.9131,
    },
    {
        store_latitdue: -2.7190071,
        store_longitude: -80.2460787,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.309,
        store_longitude: -80.2864,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.236695,
        store_longitude: -80.8619333,
    },
    {
        store_latitdue: -2.657005,
        store_longitude: -80.374876,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.8493385,
        store_longitude: -80.7457675,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.634969,
        store_longitude: -80.388752,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.24188,
        store_longitude: -80.8989,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.2405565,
        store_longitude: -80.9151048,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2413755,
        store_longitude: -80.9146179,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2406778,
        store_longitude: -80.9091519,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2384545,
        store_longitude: -80.9179691,
    },
    {
        store_latitdue: -2.0215211,
        store_longitude: -80.6689666,
    },
    {
        store_latitdue: -1.9965953,
        store_longitude: -80.5862199,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.239545545,
        store_longitude: -80.85870108,
    },
    {
        store_latitdue: -1.9798771,
        store_longitude: -80.751954,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.1407475,
        store_longitude: -80.7766853,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.3163006,
        store_longitude: -80.8557161,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.2502969,
        store_longitude: -80.8639075,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.32166,
        store_longitude: -80.563,
    },
    {
        store_latitdue: -2.237039,
        store_longitude: -80.9211813,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.222823,
        store_longitude: -80.845959,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2311348,
        store_longitude: -80.8563228,
    },
    {
        store_latitdue: -2.23190059,
        store_longitude: -80.85880181,
    },
    {
        store_latitdue: -2.480297,
        store_longitude: -80.220399,
    },
    {
        store_latitdue: -1.7278421,
        store_longitude: -80.7825418,
    },
    {
        store_latitdue: -2.2369142,
        store_longitude: -80.9136184,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.94075286,
        store_longitude: -80.6994705,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8493671,
        store_longitude: -80.7453501,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.23566,
        store_longitude: -80.898597,
    },
    {
        store_latitdue: 0.3256545,
        store_longitude: -78.1281062,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: -2.232796666,
        store_longitude: -80.90426088,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.40882,
        store_longitude: -80.3641,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.7080207,
        store_longitude: -80.2505822,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7153185,
        store_longitude: -80.2709656,
    },
    {
        store_latitdue: -2.709037,
        store_longitude: -80.249558,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.709767,
        store_longitude: -80.248681,
    },
    {
        store_latitdue: -2.625703,
        store_longitude: -80.401263,
    },
    {
        store_latitdue: -2.626726,
        store_longitude: -80.39972,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7155211,
        store_longitude: -80.2470002,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.626696,
        store_longitude: -80.398369,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7132163,
        store_longitude: -80.2467722,
    },
    {
        store_latitdue: -2.7112043,
        store_longitude: -80.2546681,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.63383,
        store_longitude: -80.3869,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.40871,
        store_longitude: -80.3662,
    },
    {
        store_latitdue: -2.57391,
        store_longitude: -80.4883,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7122355,
        store_longitude: -80.2486011,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.4095,
        store_longitude: -80.364012,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.7173715,
        store_longitude: -80.2470299,
    },
    {
        store_latitdue: -2.6323614,
        store_longitude: -80.3248162,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.23615,
        store_longitude: -80.9025,
    },
    {
        store_latitdue: -2.2082439,
        store_longitude: -80.9554489,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.632706,
        store_longitude: -80.389139,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.1416243,
        store_longitude: -80.7757272,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.628178,
        store_longitude: -80.391871,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6323807,
        store_longitude: -80.3927122,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2081046,
        store_longitude: -80.9501095,
    },
    {
        store_latitdue: -2.22358,
        store_longitude: -80.9079,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6324,
        store_longitude: -80.386,
    },
    {
        store_latitdue: -2.6355112,
        store_longitude: -80.3906955,
    },
    {
        store_latitdue: -2.633596,
        store_longitude: -80.39123,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.630692,
        store_longitude: -80.395367,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.311496,
        store_longitude: -80.7707706,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -2.4100478,
        store_longitude: -80.3644353,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.62805,
        store_longitude: -80.3928,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2400595,
        store_longitude: -80.9159676,
    },
    {
        store_latitdue: -2.0549803,
        store_longitude: -80.7327692,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.573043,
        store_longitude: -80.489114,
    },
    {
        store_latitdue: -1.8288419,
        store_longitude: -80.7521781,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.4063369,
        store_longitude: -80.3667552,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.6105378,
        store_longitude: -80.3026195,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.63454,
        store_longitude: -80.39105,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.630122,
        store_longitude: -80.395411,
    },
    {
        store_latitdue: -2.2238591,
        store_longitude: -80.9098513,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.629534,
        store_longitude: -80.390068,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.231469,
        store_longitude: -80.9139,
    },
    {
        store_latitdue: -2.627641,
        store_longitude: -80.395149,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6218,
        store_longitude: -80.3938,
    },
    {
        store_latitdue: -2.23327,
        store_longitude: -80.9084,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.629452,
        store_longitude: -80.388241,
    },
    {
        store_latitdue: -2.7096481,
        store_longitude: -80.2487062,
    },
    {
        store_latitdue: -2.7044,
        store_longitude: -80.2466,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7066645,
        store_longitude: -80.2465618,
    },
    {
        store_latitdue: -2.63239,
        store_longitude: -80.3914,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.5731685,
        store_longitude: -80.4882567,
    },
    {
        store_latitdue: -2.2277174,
        store_longitude: -80.8512835,
    },
    {
        store_latitdue: -2.5746052,
        store_longitude: -80.4851833,
    },
    {
        store_latitdue: -2.6223426,
        store_longitude: -80.3902031,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.91129,
        store_longitude: -80.6533,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.9978857,
        store_longitude: -80.7230307,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.3160545,
        store_longitude: -80.8794158,
        neighborhood: 'ANCONCITO',
    },
    {
        store_latitdue: -2.225811,
        store_longitude: -80.9223481,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.22607,
        store_longitude: -80.9041,
    },

    {
        store_latitdue: -2.2436087,
        store_longitude: -80.9016038,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.709617,
        store_longitude: -80.248718,
    },
    {
        store_latitdue: -2.7035774,
        store_longitude: -80.244351,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.705764,
        store_longitude: -80.249914,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.8277739,
        store_longitude: -80.7532025,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.70391,
        store_longitude: -80.2452,
        neighborhood: 'POSORJA',
    },

    {
        store_latitdue: -2.236506,
        store_longitude: -80.888675,
    },
    {
        store_latitdue: -2.2272893,
        store_longitude: -80.850217,
    },
    {
        store_latitdue: -2.214419,
        store_longitude: -80.9482119,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.238511,
        store_longitude: -80.907119,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7063898,
        store_longitude: -80.2444521,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.209798,
        store_longitude: -80.972306,
    },
    {
        store_latitdue: -2.2189542,
        store_longitude: -80.9586495,
    },
    {
        store_latitdue: -2.6309427,
        store_longitude: -80.3939429,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.9995172,
        store_longitude: -80.5891819,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.882766,
        store_longitude: -80.734481,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.714689,
        store_longitude: -80.246624,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6313438,
        store_longitude: -80.3909229,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.8566446,
        store_longitude: -80.7437305,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2248988,
        store_longitude: -80.9174246,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.208391,
        store_longitude: -80.9586282,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2157627,
        store_longitude: -80.9464049,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.94315,
        store_longitude: -80.6149,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.937930289,
        store_longitude: -80.69646665,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.41037,
        store_longitude: -80.3648,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.718118,
        store_longitude: -80.245495,
    },
    {
        store_latitdue: -2.2523715,
        store_longitude: -80.9176678,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.4001134,
        store_longitude: -80.6903761,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.7406238,
        store_longitude: -80.7756765,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.0156004,
        store_longitude: -80.5943082,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.9416605,
        store_longitude: -80.7237079,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.93661,
        store_longitude: -80.6855,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.4070577,
        store_longitude: -80.3642949,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -1.881821,
        store_longitude: -80.734529,
    },
    {
        store_latitdue: -2.63568215,
        store_longitude: -80.3909234,
    },
    {
        store_latitdue: -2.05369,
        store_longitude: -80.7332,
        neighborhood: 'COLONCHE',
    },

    {
        store_latitdue: -2.234801,
        store_longitude: -80.895399,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.32226,
        store_longitude: -80.5616,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.6273907,
        store_longitude: -80.3911413,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.998182,
        store_longitude: -80.5883263,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.699450705,
        store_longitude: -80.32135426,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2468673,
        store_longitude: -80.9182748,
    },
    {
        store_latitdue: -1.978692,
        store_longitude: -80.7529724,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2446283,
        store_longitude: -80.9351775,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2235531,
        store_longitude: -80.9130662,
    },
    {
        store_latitdue: -1.87682,
        store_longitude: -80.7309,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.7062827,
        store_longitude: -80.2458831,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.30227,
        store_longitude: -80.7002,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.6262,
        store_longitude: -80.4018,
    },
    {
        store_latitdue: -2.713062196,
        store_longitude: -80.24932025,
    },
    {
        store_latitdue: -2.6241376,
        store_longitude: -80.3905841,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.70629802,
        store_longitude: -80.2435969,
    },
    {
        store_latitdue: -2.23316,
        store_longitude: -80.9281,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2152382,
        store_longitude: -80.9482978,
    },
    {
        store_latitdue: -2.7158687,
        store_longitude: -80.2552759,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.23149,
        store_longitude: -80.8993,
    },
    {
        store_latitdue: -2.2141184,
        store_longitude: -80.9472072,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.1406,
        store_longitude: -80.7767,
    },
    {
        store_latitdue: -1.7967302,
        store_longitude: -80.7566692,
    },
    {
        store_latitdue: -2.24156,
        store_longitude: -80.9075,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.71473,
        store_longitude: -80.246953,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.8269596,
        store_longitude: -80.752984,
    },
    {
        store_latitdue: -2.5749766,
        store_longitude: -80.4875341,
    },

    {
        store_latitdue: -2.21164,
        store_longitude: -80.957,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2325435,
        store_longitude: -80.8635897,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.23502,
        store_longitude: -80.9338,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.5741,
        store_longitude: -80.4855,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.0218089,
        store_longitude: -80.7203575,
    },
    {
        store_latitdue: -1.8299937,
        store_longitude: -80.7526847,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.61012,
        store_longitude: -80.3022,
    },
    {
        store_latitdue: -2.4051989,
        store_longitude: -80.3678896,
    },
    {
        store_latitdue: -1.7989538,
        store_longitude: -80.759352,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.9376,
        store_longitude: -80.6865,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2301242,
        store_longitude: -80.9043636,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2347328,
        store_longitude: -80.9300597,
    },
    {
        store_latitdue: -2.2347288,
        store_longitude: -80.9325449,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },

    {
        store_latitdue: -2.2335695,
        store_longitude: -80.8938222,
    },
    {
        store_latitdue: -2.70331,
        store_longitude: -80.2523,
        neighborhood: 'POSORJA',
    },

    {
        store_latitdue: -1.9421803,
        store_longitude: -80.7254546,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.21478,
        store_longitude: -80.9463867,
    },
    {
        store_latitdue: -2.21389631,
        store_longitude: -80.94855816,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.224167096,
        store_longitude: -80.93786632,
    },
    {
        store_latitdue: -2.3138175,
        store_longitude: -80.892701,
        neighborhood: 'ANCONCITO',
    },
    {
        store_latitdue: -2.46887,
        store_longitude: -80.5654,
    },

    {
        store_latitdue: -2.226647,
        store_longitude: -80.907057,
    },
    {
        store_latitdue: -2.2102272,
        store_longitude: -80.9652228,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2254761,
        store_longitude: -80.9082175,
    },
    {
        store_latitdue: -1.8691365,
        store_longitude: -80.738326,
    },
    {
        store_latitdue: -2.6357933,
        store_longitude: -80.3874556,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.87653,
        store_longitude: -80.7287,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.9393445,
        store_longitude: -80.6982545,
    },
    {
        store_latitdue: -2.6387103,
        store_longitude: -80.3762398,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6337344,
        store_longitude: -80.3280399,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.5591707,
        store_longitude: -80.5093684,
        neighborhood: 'GENERAL VILLAMIL',
    },

    {
        store_latitdue: -2.7039267,
        store_longitude: -80.2480529,
    },
    {
        store_latitdue: -2.2533561,
        store_longitude: -80.5774874,
    },
    {
        store_latitdue: -2.2518276,
        store_longitude: -80.5795733,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2518,
        store_longitude: -80.5796,
    },
    {
        store_latitdue: -2.2232857,
        store_longitude: -80.9488552,
    },
    {
        store_latitdue: -2.6106065,
        store_longitude: -80.3027662,
    },
    {
        store_latitdue: -2.717201747,
        store_longitude: -80.25144442,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7096877,
        store_longitude: -80.2439468,
    },
    {
        store_latitdue: -2.3359269,
        store_longitude: -80.2708545,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.2426635,
        store_longitude: -80.9065214,
    },
    {
        store_latitdue: -2.242059,
        store_longitude: -80.906567,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2406083,
        store_longitude: -80.9018891,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2409471,
        store_longitude: -80.9135142,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.23513,
        store_longitude: -80.9154856,
    },
    {
        store_latitdue: -2.22591,
        store_longitude: -80.9111,
    },
    {
        store_latitdue: -2.232023893,
        store_longitude: -80.90457219,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.63336,
        store_longitude: -80.3953,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7093267,
        store_longitude: -80.2497828,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6250425,
        store_longitude: -80.3926474,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.86634,
        store_longitude: -80.740105,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.7972989,
        store_longitude: -80.7563988,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2253443,
        store_longitude: -80.9073568,
    },
    {
        store_latitdue: -1.83043,
        store_longitude: -80.7508,
    },
    {
        store_latitdue: -2.221875,
        store_longitude: -80.906255,
    },
    {
        store_latitdue: -2.32142,
        store_longitude: -80.5625,
    },
    {
        store_latitdue: -1.9382681,
        store_longitude: -80.6965758,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.7321267,
        store_longitude: -80.7833606,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8674988,
        store_longitude: -80.7393193,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.9413845,
        store_longitude: -80.7009055,
    },
    {
        store_latitdue: -2.6357895,
        store_longitude: -80.3804314,
    },
    {
        store_latitdue: -2.627805,
        store_longitude: -80.395534,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.850732,
        store_longitude: -80.7458269,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.3569699,
        store_longitude: -80.7200299,
    },
    {
        store_latitdue: -2.380974222,
        store_longitude: -80.69946203,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.6435449,
        store_longitude: -80.3822099,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.62761,
        store_longitude: -80.3896,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.716953,
        store_longitude: -80.2473951,
    },
    {
        store_latitdue: -2.40265,
        store_longitude: -80.6816,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.3997972,
        store_longitude: -80.6939998,
    },
    {
        store_latitdue: -2.6231185,
        store_longitude: -80.3857478,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.958169195,
        store_longitude: -80.65568579,
    },
    {
        store_latitdue: -1.9598579,
        store_longitude: -80.631644,
    },
    {
        store_latitdue: -2.2367501,
        store_longitude: -80.9032023,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.31109,
        store_longitude: -80.774,
    },
    {
        store_latitdue: -2.20799,
        store_longitude: -80.9571,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.1442982,
        store_longitude: -80.7729234,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2228164,
        store_longitude: -80.9122176,
    },
    {
        store_latitdue: -2.1397716,
        store_longitude: -80.7761212,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.0222646,
        store_longitude: -80.7267688,
    },
    {
        store_latitdue: -2.1576506,
        store_longitude: -80.7772308,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -1.94488,
        store_longitude: -80.7261,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2458634,
        store_longitude: -80.927489,
    },
    {
        store_latitdue: -2.0220508,
        store_longitude: -80.7367645,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2424803,
        store_longitude: -80.9375564,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.316193,
        store_longitude: -80.8535222,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.6224002,
        store_longitude: -80.3909983,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.63926103,
        store_longitude: -80.3758049,
    },
    {
        store_latitdue: -1.9968482,
        store_longitude: -80.5846063,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.71363,
        store_longitude: -80.2477,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.573027,
        store_longitude: -80.488076,
    },
    {
        store_latitdue: -2.23195,
        store_longitude: -80.898,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2361021,
        store_longitude: -80.926874,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.0212249,
        store_longitude: -80.720509,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.1417979,
        store_longitude: -80.7732131,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.3336479,
        store_longitude: -80.2702846,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.7169059,
        store_longitude: -80.2516455,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.227484,
        store_longitude: -80.8587413,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.7156629,
        store_longitude: -80.3020603,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.8682768,
        store_longitude: -80.7389322,
    },
    {
        store_latitdue: -2.24267,
        store_longitude: -80.9174,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.19884,
        store_longitude: -80.9814,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.226581,
        store_longitude: -80.914606,
    },
    {
        store_latitdue: -2.232943,
        store_longitude: -80.910736,
    },
    {
        store_latitdue: -2.711444,
        store_longitude: -80.244851,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.3974246,
        store_longitude: -80.6950489,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.88373,
        store_longitude: -80.7344,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.873465303,
        store_longitude: -80.73486236,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.625012,
        store_longitude: -80.388654,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.9970159,
        store_longitude: -80.5881951,
    },
    {
        store_latitdue: -2.2056059,
        store_longitude: -80.973632,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.63367,
        store_longitude: -80.386,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7094076,
        store_longitude: -80.2442886,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.797738987,
        store_longitude: -80.75690134,
    },

    {
        store_latitdue: -1.934812,
        store_longitude: -80.684791,
    },
    {
        store_latitdue: -2.2267993,
        store_longitude: -80.8616509,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2365823,
        store_longitude: -80.8959027,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6375246,
        store_longitude: -80.389556,
    },
    {
        store_latitdue: -1.82182,
        store_longitude: -80.7554,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.826899,
        store_longitude: -80.752996,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.7974659,
        store_longitude: -80.7584388,
    },
    {
        store_latitdue: -1.95901,
        store_longitude: -80.6321,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.203905,
        store_longitude: -80.977264,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.02002865,
        store_longitude: -80.7372057,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.998658798,
        store_longitude: -80.58606892,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2353243,
        store_longitude: -80.8580077,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.1415523,
        store_longitude: -80.7769795,
    },
    {
        store_latitdue: -2.4115795,
        store_longitude: -80.3635366,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -1.93565,
        store_longitude: -80.6881,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.25047953,
        store_longitude: -80.90770819,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.25501203,
        store_longitude: -79.9001312,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.652761,
        store_longitude: -80.376172,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.9428877,
        store_longitude: -80.6142332,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2057341,
        store_longitude: -80.9734494,
    },
    {
        store_latitdue: -1.7976779,
        store_longitude: -80.7564912,
    },
    {
        store_latitdue: -2.2912868,
        store_longitude: -80.8469307,
    },
    {
        store_latitdue: -2.2261954,
        store_longitude: -80.9068053,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.46767,
        store_longitude: -80.5649,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.3587989,
        store_longitude: -80.718688,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.2065817,
        store_longitude: -80.9769267,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.23941,
        store_longitude: -80.8869,
    },
    {
        store_latitdue: -2.61138,
        store_longitude: -80.3029,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.20389,
        store_longitude: -80.9764,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2497092,
        store_longitude: -80.9029448,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24096,
        store_longitude: -80.9275,
    },
    {
        store_latitdue: -2.218898,
        store_longitude: -80.96562,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.1424133,
        store_longitude: -80.771985,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.24365,
        store_longitude: -80.908936,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.14097,
        store_longitude: -80.7764,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.4018043,
        store_longitude: -80.6812234,
    },
    {
        store_latitdue: -2.2362507,
        store_longitude: -80.9067701,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.011747409,
        store_longitude: -80.62859778,
    },
    {
        store_latitdue: -2.57328,
        store_longitude: -80.4848,
        neighborhood: 'GENERAL VILLAMIL',
    },

    {
        store_latitdue: -2.24323,
        store_longitude: -80.8963,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2380919,
        store_longitude: -80.9324537,
    },
    {
        store_latitdue: -2.23915,
        store_longitude: -80.8842,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.22826,
        store_longitude: -80.9128,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2394659,
        store_longitude: -80.7709161,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2067735,
        store_longitude: -80.9501825,
    },
    {
        store_latitdue: -2.2425446,
        store_longitude: -80.9029317,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7134032,
        store_longitude: -80.2456885,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.400747,
        store_longitude: -80.6803272,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.2123017,
        store_longitude: -80.970885,
    },
    {
        store_latitdue: -1.9444039,
        store_longitude: -80.7251756,
    },
    {
        store_latitdue: -1.9352588,
        store_longitude: -80.6877327,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2313312,
        store_longitude: -80.9078391,
    },
    {
        store_latitdue: -2.90617,
        store_longitude: -79.0441,
        neighborhood: 'CUENCA',
    },
    {
        store_latitdue: -2.242059,
        store_longitude: -80.906567,
    },
    {
        store_latitdue: -2.3038,
        store_longitude: -80.2821,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.02623419,
        store_longitude: -80.7293894,
    },
    {
        store_latitdue: -1.9383236,
        store_longitude: -80.6966083,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.87163,
        store_longitude: -80.7365,
    },
    {
        store_latitdue: -2.2405429,
        store_longitude: -80.8860232,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.23866,
        store_longitude: -80.8552,
    },
    {
        store_latitdue: -2.6391854,
        store_longitude: -80.3843311,
    },
    {
        store_latitdue: -2.6322522,
        store_longitude: -80.3813642,
    },
    {
        store_latitdue: -2.2525662,
        store_longitude: -80.9106394,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.1405835,
        store_longitude: -80.7726232,
    },
    {
        store_latitdue: -2.2362266,
        store_longitude: -80.9035184,
    },
    {
        store_latitdue: -2.2288375,
        store_longitude: -80.9048682,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6311386,
        store_longitude: -80.3935146,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2942642,
        store_longitude: -80.7071735,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.635798,
        store_longitude: -80.392277,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.610933,
        store_longitude: -80.303667,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.5578208,
        store_longitude: -80.5088866,
    },
    {
        store_latitdue: -2.244086995,
        store_longitude: -80.89204793,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2301133,
        store_longitude: -80.9232774,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.939804,
        store_longitude: -80.700127,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.7229965,
        store_longitude: -80.2910301,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.0266779,
        store_longitude: -80.7333191,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.3127536,
        store_longitude: -80.7728736,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -1.9983382,
        store_longitude: -80.5877626,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.235871004,
        store_longitude: -80.9014129,
    },
    {
        store_latitdue: -2.250281188,
        store_longitude: -80.90858861,
    },
    {
        store_latitdue: -2.2376155,
        store_longitude: -80.8696457,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.209175007,
        store_longitude: -80.97318376,
    },
    {
        store_latitdue: -2.2235,
        store_longitude: -80.8965,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.731082,
        store_longitude: -80.783533,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6167418,
        store_longitude: -80.3791787,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.0423986,
        store_longitude: -80.7266138,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.1428797,
        store_longitude: -80.7769242,
    },

    {
        store_latitdue: -1.8679075,
        store_longitude: -80.7390922,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.67452677,
        store_longitude: -80.2887833,
    },
    {
        store_latitdue: -2.1989262,
        store_longitude: -80.9816797,
    },
    {
        store_latitdue: -2.6248011,
        store_longitude: -80.3937852,
    },
    {
        store_latitdue: -2.28685164,
        store_longitude: -80.9049911,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.9787857,
        store_longitude: -80.7532215,
    },
    {
        store_latitdue: -2.2137422,
        store_longitude: -80.9447053,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.9348,
        store_longitude: -80.6868,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8275112,
        store_longitude: -80.7523983,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.0616754,
        store_longitude: -80.7356112,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.5728079,
        store_longitude: -80.4892622,
    },
    {
        store_latitdue: -2.4032248,
        store_longitude: -80.36781,
    },
    {
        store_latitdue: -1.9805451,
        store_longitude: -80.752495,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.7141131,
        store_longitude: -80.2533526,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6320521,
        store_longitude: -80.3875629,
    },
    {
        store_latitdue: -2.64339,
        store_longitude: -80.3821,
    },
    {
        store_latitdue: -2.5727323,
        store_longitude: -80.4867706,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.6151425,
        store_longitude: -79.9746094,
    },
    {
        store_latitdue: -1.9786,
        store_longitude: -80.7538,
    },
    {
        store_latitdue: -2.2416705,
        store_longitude: -80.9259512,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.22122,
        store_longitude: -80.9082,
    },
    {
        store_latitdue: -2.20949,
        store_longitude: -80.9611935,
    },
    {
        store_latitdue: -2.2432048,
        store_longitude: -80.9313583,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.1407883,
        store_longitude: -80.7767883,
    },
    {
        store_latitdue: -2.1366438,
        store_longitude: -80.7687303,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: 0.952975,
        store_longitude: -79.6457,
    },
    {
        store_latitdue: -2.2054144,
        store_longitude: -80.9724793,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.6323988,
        store_longitude: -80.3847746,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6365022,
        store_longitude: -80.3891194,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.9401778,
        store_longitude: -80.6978515,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2936403,
        store_longitude: -80.8490748,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.3350421,
        store_longitude: -80.27157,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.33454,
        store_longitude: -80.2716,
    },
    {
        store_latitdue: -2.3097918,
        store_longitude: -80.2864398,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },

    {
        store_latitdue: -1.9425176,
        store_longitude: -80.7227617,
    },
    {
        store_latitdue: -2.3348943,
        store_longitude: -80.2714319,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.331574,
        store_longitude: -80.272137,
    },
    {
        store_latitdue: -2.1416973,
        store_longitude: -80.7756602,
    },
    {
        store_latitdue: -2.2087133,
        store_longitude: -80.9670933,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.62034,
        store_longitude: -80.3924,
    },
    {
        store_latitdue: -2.2216905,
        store_longitude: -80.8937257,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.23675,
        store_longitude: -80.902078,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9921,
        store_longitude: -80.583897,
    },
    {
        store_latitdue: -2.403498,
        store_longitude: -80.3686509,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.2306406,
        store_longitude: -80.9001863,
    },
    {
        store_latitdue: -1.9405399,
        store_longitude: -80.720944,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2378353,
        store_longitude: -80.9068531,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.233137876,
        store_longitude: -80.91690283,
    },
    {
        store_latitdue: -1.88196,
        store_longitude: -80.7345,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.23803,
        store_longitude: -80.889,
    },
    {
        store_latitdue: -1.8236667,
        store_longitude: -80.7512929,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2531544,
        store_longitude: -80.927256,
    },
    {
        store_latitdue: -2.1474515,
        store_longitude: -80.7711668,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2963862,
        store_longitude: -80.8489048,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -1.9404206,
        store_longitude: -80.6963394,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.31364,
        store_longitude: -80.7751367,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -1.98355,
        store_longitude: -80.75721,
    },
    {
        store_latitdue: -2.24232,
        store_longitude: -80.8875,
    },
    {
        store_latitdue: -2.6322599,
        store_longitude: -80.3820063,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.0216366,
        store_longitude: -80.6703615,
    },

    {
        store_latitdue: -2.2088,
        store_longitude: -80.968,
    },
    {
        store_latitdue: -2.6366838,
        store_longitude: -80.3807521,
    },
    {
        store_latitdue: -2.2328154,
        store_longitude: -80.9285757,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.9776487,
        store_longitude: -80.7541701,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.6286281,
        store_longitude: -80.3979064,
    },
    {
        store_latitdue: -2.6339252,
        store_longitude: -80.3912073,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.24491,
        store_longitude: -80.9276,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.5727073,
        store_longitude: -80.4878475,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.22654,
        store_longitude: -80.8504,
    },
    {
        store_latitdue: -2.2203,
        store_longitude: -80.8999,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.0505,
        store_longitude: -80.7334,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.731711,
        store_longitude: -80.7835,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2273776,
        store_longitude: -80.8587519,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.144321,
        store_longitude: -80.7772116,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2222802,
        store_longitude: -80.9141257,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7033753,
        store_longitude: -80.2443612,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6362471,
        store_longitude: -80.3913493,
    },
    {
        store_latitdue: -2.2315,
        store_longitude: -80.8902,
    },
    {
        store_latitdue: -2.2298887,
        store_longitude: -80.9162437,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2457018,
        store_longitude: -80.8576126,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.22114109,
        store_longitude: -80.9661483,
        neighborhood: 'SALINAS',
    },

    {
        store_latitdue: -2.635016,
        store_longitude: -80.382645,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.283118,
        store_longitude: -80.8579281,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.2302213,
        store_longitude: -80.9148594,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.209446,
        store_longitude: -80.974081,
    },
    {
        store_latitdue: -2.6426232,
        store_longitude: -80.382788,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.7312075,
        store_longitude: -80.7838853,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.797654,
        store_longitude: -80.7566537,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.1420758,
        store_longitude: -80.7747924,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -1.939401,
        store_longitude: -80.6973286,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.23485,
        store_longitude: -80.888,
    },
    {
        store_latitdue: -1.9791,
        store_longitude: -80.753,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.7600145,
        store_longitude: -80.7688159,
    },
    {
        store_latitdue: -1.828148,
        store_longitude: -80.693751,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2265852,
        store_longitude: -80.9104643,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.71148493,
        store_longitude: -80.2470605,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2074119,
        store_longitude: -80.95004,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.3335654,
        store_longitude: -80.2712476,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -1.727408,
        store_longitude: -80.782223,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2072251,
        store_longitude: -80.9723007,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.5340199,
        store_longitude: -80.3884159,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.23166,
        store_longitude: -80.894,
    },
    {
        store_latitdue: -2.6212047,
        store_longitude: -80.3907625,
    },
    {
        store_latitdue: -2.22992324,
        store_longitude: -80.9042358,
    },

    {
        store_latitdue: -2.1388911,
        store_longitude: -80.7761022,
    },
    {
        store_latitdue: -2.2349458,
        store_longitude: -80.9156563,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.8495067467,
        store_longitude: -80.745116584,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.0556817,
        store_longitude: -80.7341817,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2320614,
        store_longitude: -80.9365916,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.70304253,
        store_longitude: -80.2443444,
    },
    {
        store_latitdue: -2.716953,
        store_longitude: -80.247395,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6331298,
        store_longitude: -80.3289658,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.701665,
        store_longitude: -80.249553,
    },
    {
        store_latitdue: -1.99761,
        store_longitude: -80.587266,
    },
    {
        store_latitdue: -2.710115,
        store_longitude: -80.2492866,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.635811,
        store_longitude: -80.388913,
    },
    {
        store_latitdue: -1.93448,
        store_longitude: -80.6881,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.4025258,
        store_longitude: -80.6793334,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.24894714,
        store_longitude: -80.9103851,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.3345976,
        store_longitude: -80.2716042,
    },
    {
        store_latitdue: -2.0427738,
        store_longitude: -80.7271958,
    },
    {
        store_latitdue: -2.6106148,
        store_longitude: -80.3029595,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -1.8736346,
        store_longitude: -80.7357685,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.629497,
        store_longitude: -80.390078,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.246818,
        store_longitude: -80.908831,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.22689,
        store_longitude: -80.8528,
    },
    {
        store_latitdue: -2.0271337,
        store_longitude: -80.7306674,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.225185,
        store_longitude: -80.8508117,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.20846,
        store_longitude: -80.9669,
    },
    {
        store_latitdue: -2.221538782,
        store_longitude: -80.91149902,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6347128,
        store_longitude: -80.3875872,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.241804,
        store_longitude: -80.898196,
    },
    {
        store_latitdue: -2.0248614,
        store_longitude: -80.728295,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2246252,
        store_longitude: -80.8558116,
    },
    {
        store_latitdue: -2.3116522,
        store_longitude: -80.7732553,
    },
    {
        store_latitdue: -2.1423,
        store_longitude: -80.7754,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.239318,
        store_longitude: -80.89589,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2342,
        store_longitude: -80.8688,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -3.24492,
        store_longitude: -79.9416,
    },
    {
        store_latitdue: -2.63016,
        store_longitude: -80.3885183,
    },
    {
        store_latitdue: -1.94106,
        store_longitude: -80.6963,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6347421,
        store_longitude: -80.3908881,
    },
    {
        store_latitdue: -2.6285468,
        store_longitude: -80.3960475,
    },
    {
        store_latitdue: -2.2318434,
        store_longitude: -80.8989956,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.715179,
        store_longitude: -80.302043,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7139574,
        store_longitude: -80.3130708,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.8820635,
        store_longitude: -80.7347968,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.406779,
        store_longitude: -80.3660331,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.7070613,
        store_longitude: -80.249607,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.950779,
        store_longitude: -80.72816,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6121034,
        store_longitude: -80.3052828,
    },
    {
        store_latitdue: -2.3958435,
        store_longitude: -80.2143572,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.3956839,
        store_longitude: -80.215217,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.2338909,
        store_longitude: -80.8969652,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9416477,
        store_longitude: -80.7231569,
    },
    {
        store_latitdue: -2.7031906,
        store_longitude: -80.2521715,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.24075,
        store_longitude: -80.9246,
    },
    {
        store_latitdue: -3.2582188,
        store_longitude: -79.9582165,
    },
    {
        store_latitdue: -2.6347,
        store_longitude: -80.3878,
    },
    {
        store_latitdue: -1.9458622,
        store_longitude: -80.7263899,
    },

    {
        store_latitdue: -2.623719,
        store_longitude: -80.394618,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2363004,
        store_longitude: -80.916353,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2252759,
        store_longitude: -80.9058986,
    },
    {
        store_latitdue: -2.6335772,
        store_longitude: -80.383468,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.759611,
        store_longitude: -80.7683501,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2119517,
        store_longitude: -80.9468514,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.132875,
        store_longitude: -80.767905,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2242729,
        store_longitude: -80.9076837,
    },
    {
        store_latitdue: -2.6356995,
        store_longitude: -80.3821147,
    },
    {
        store_latitdue: -2.009742173,
        store_longitude: -80.63336035,
    },
    {
        store_latitdue: -1.964396688,
        store_longitude: -80.64931958,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.38254,
        store_longitude: -80.7187,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.3850028,
        store_longitude: -80.7193808,
        neighborhood: 'CHANDUY',
    },

    {
        store_latitdue: -2.7093764,
        store_longitude: -80.2456542,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.224447,
        store_longitude: -80.917912,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9578143,
        store_longitude: -80.6549237,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -1.7322,
        store_longitude: -80.7828,
    },
    {
        store_latitdue: -1.86816084,
        store_longitude: -80.7391052,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.830022,
        store_longitude: -80.7526703,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.7123822,
        store_longitude: -80.2506229,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.242498,
        store_longitude: -80.9261308,
    },
    {
        store_latitdue: -2.2142649,
        store_longitude: -80.9483436,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2098934,
        store_longitude: -80.9648747,
    },
    {
        store_latitdue: -1.9418005,
        store_longitude: -80.7232259,
    },
    {
        store_latitdue: -1.789657,
        store_longitude: -80.7580356,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.9337774,
        store_longitude: -80.6874652,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8264728,
        store_longitude: -80.7530017,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.235878557,
        store_longitude: -80.92462296,
    },
    {
        store_latitdue: -1.8685855,
        store_longitude: -80.7368023,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.220148,
        store_longitude: -80.9597751,
    },
    {
        store_latitdue: -2.205342,
        store_longitude: -80.976769,
    },
    {
        store_latitdue: -1.947997,
        store_longitude: -80.7267574,
    },
    {
        store_latitdue: -1.97944104,
        store_longitude: -80.7542648,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2309428,
        store_longitude: -80.8412107,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.21430227,
        store_longitude: -80.9494184,
    },
    {
        store_latitdue: -1.86975,
        store_longitude: -80.7388,
    },
    {
        store_latitdue: -2.348479,
        store_longitude: -80.2787811,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.2256297,
        store_longitude: -80.9090046,
    },
    {
        store_latitdue: -2.6453016,
        store_longitude: -80.3796957,
    },
    {
        store_latitdue: -2.62700176,
        store_longitude: -80.3941421,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6308634,
        store_longitude: -80.3853957,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6405864,
        store_longitude: -80.3763938,
    },
    {
        store_latitdue: -2.13918,
        store_longitude: -80.7755,
    },
    {
        store_latitdue: -2.5722407,
        store_longitude: -80.4897721,
    },

    {
        store_latitdue: -2.23189,
        store_longitude: -80.9049,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.23109,
        store_longitude: -80.9078,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.7088889,
        store_longitude: -80.2473323,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.642097537,
        store_longitude: -80.38479632,
    },
    {
        store_latitdue: -2.2360507,
        store_longitude: -80.9194395,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.709301,
        store_longitude: -80.2500222,
    },
    {
        store_latitdue: -2.2836009,
        store_longitude: -80.18905,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -1.9790371,
        store_longitude: -80.7530329,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2342832,
        store_longitude: -80.8928753,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.22729487,
        store_longitude: -80.90854917,
    },
    {
        store_latitdue: -2.1415424,
        store_longitude: -80.7721695,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.629623,
        store_longitude: -80.390042,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.9383484,
        store_longitude: -80.7243067,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2287148,
        store_longitude: -80.9350136,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -1.7415018,
        store_longitude: -80.7760557,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.6224963,
        store_longitude: -80.3905701,
    },
    {
        store_latitdue: -2.23783,
        store_longitude: -80.9338,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.6349569,
        store_longitude: -80.3873538,
    },
    {
        store_latitdue: -2.63503384,
        store_longitude: -80.3903121,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6110933,
        store_longitude: -80.3966809,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.62541339,
        store_longitude: -80.3971271,
    },
    {
        store_latitdue: -2.2379307,
        store_longitude: -80.9074499,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.47931,
        store_longitude: -78.0001,
    },
    {
        store_latitdue: -2.3815418,
        store_longitude: -80.6982725,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.0429534,
        store_longitude: -80.7275072,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.57515,
        store_longitude: -80.4886,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.0426905,
        store_longitude: -80.723526,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.2114561,
        store_longitude: -79.9026156,
    },
    {
        store_latitdue: -2.7079429,
        store_longitude: -80.2436672,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7191136,
        store_longitude: -80.2549527,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2216999,
        store_longitude: -80.9084291,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.9451008,
        store_longitude: -80.7258804,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.247005,
        store_longitude: -80.929422,
    },
    {
        store_latitdue: -2.711459,
        store_longitude: -80.2589527,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.233384,
        store_longitude: -80.9058377,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.2850911,
        store_longitude: -80.8576868,
    },
    {
        store_latitdue: -2.233193,
        store_longitude: -80.885551,
    },
    {
        store_latitdue: -2.6283858,
        store_longitude: -80.3948955,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2335896,
        store_longitude: -80.936779,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.632994,
        store_longitude: -80.3950434,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2442628,
        store_longitude: -80.925664,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.6210352,
        store_longitude: -80.3937203,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.4005073,
        store_longitude: -80.6903377,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.2302558,
        store_longitude: -80.9299888,
    },
    {
        store_latitdue: -2.287463,
        store_longitude: -80.8575368,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.35321,
        store_longitude: -80.28257,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -3.9519128,
        store_longitude: -79.2207501,
    },
    {
        store_latitdue: -2.62488,
        store_longitude: -80.3988,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.80025,
        store_longitude: -80.7563,
    },
    {
        store_latitdue: -1.8851169,
        store_longitude: -80.7340098,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2052684,
        store_longitude: -80.9771781,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.941052493,
        store_longitude: -80.72025352,
    },
    {
        store_latitdue: -2.096291,
        store_longitude: -79.960423,
    },
    {
        store_latitdue: -2.209998283,
        store_longitude: -80.96514104,
    },
    {
        store_latitdue: -2.30298,
        store_longitude: -80.7011,
    },
    {
        store_latitdue: -2.70784068,
        store_longitude: -80.2442398,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2533999,
        store_longitude: -80.9142841,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.240129,
        store_longitude: -80.9361528,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2405048,
        store_longitude: -80.9163983,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.200738,
        store_longitude: -79.139015,
    },
    {
        store_latitdue: -1.95107,
        store_longitude: -80.7285,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.4014873,
        store_longitude: -80.6806416,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.0406,
        store_longitude: -80.7236,
    },
    {
        store_latitdue: -2.24171252,
        store_longitude: -80.9015178,
    },
    {
        store_latitdue: -2.6171138,
        store_longitude: -80.3978936,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2200643,
        store_longitude: -80.9451906,
        neighborhood: 'SALINAS',
    },

    {
        store_latitdue: -2.3094298,
        store_longitude: -80.7710046,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -1.73158224,
        store_longitude: -80.7836887,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2335994,
        store_longitude: -80.9270447,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.0266048,
        store_longitude: -80.7332295,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.0406474,
        store_longitude: -80.7238462,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.242719,
        store_longitude: -80.912897,
    },
    {
        store_latitdue: -2.2146258,
        store_longitude: -80.9497866,
    },
    {
        store_latitdue: -2.637212,
        store_longitude: -80.385452,
        neighborhood: 'GENERAL VILLAMIL',
    },

    {
        store_latitdue: -2.2417817,
        store_longitude: -80.9144842,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6294442,
        store_longitude: -80.3946978,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2429427,
        store_longitude: -80.9303246,
    },
    {
        store_latitdue: -2.22790093,
        store_longitude: -80.8584864,
    },
    {
        store_latitdue: -2.6272287,
        store_longitude: -80.3836677,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.6265995,
        store_longitude: -80.4019532,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2271961,
        store_longitude: -80.914271,
    },

    {
        store_latitdue: -1.9419362,
        store_longitude: -80.7246208,
        neighborhood: 'MANGLARALTO',
    },

    {
        store_latitdue: -1.8268834,
        store_longitude: -80.7527581,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.222168,
        store_longitude: -80.8494403,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.22974696,
        store_longitude: -80.8957672,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2083232,
        store_longitude: -80.9504875,
    },
    {
        store_latitdue: -2.70558691,
        store_longitude: -80.2474975,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2256828,
        store_longitude: -80.8565331,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.22303938,
        store_longitude: -80.8552703,
    },

    {
        store_latitdue: -2.62787652,
        store_longitude: -80.3958358,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.722041,
        store_longitude: -80.2913829,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.24929206,
        store_longitude: -80.92916464,
    },
    {
        store_latitdue: -2.2466534,
        store_longitude: -80.9312498,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2446103,
        store_longitude: -80.9092208,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.5730473,
        store_longitude: -80.4904722,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.39382,
        store_longitude: -80.2146869,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -1.8746297,
        store_longitude: -80.7356506,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8290701,
        store_longitude: -80.7528848,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2238326,
        store_longitude: -80.9093246,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.3233686,
        store_longitude: -80.8507071,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.2331733,
        store_longitude: -80.9169217,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2391471,
        store_longitude: -80.8620297,
        neighborhood: 'SANTA ELENA',
    },

    {
        store_latitdue: -2.2336977,
        store_longitude: -80.8569365,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.0277059,
        store_longitude: -80.7195541,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.6345979,
        store_longitude: -80.3838659,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.797189183,
        store_longitude: -80.75701758,
    },
    {
        store_latitdue: -2.7167045,
        store_longitude: -80.2475307,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.8465595,
        store_longitude: -80.7409353,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2257648392,
        store_longitude: -80.8913713893,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6314166,
        store_longitude: -80.3905307,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2367928,
        store_longitude: -80.909742,
    },
    {
        store_latitdue: -2.21448,
        store_longitude: -80.9559,
    },
    {
        store_latitdue: -2.7045087,
        store_longitude: -80.2452403,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.7047914,
        store_longitude: -80.2456857,
    },
    {
        store_latitdue: -2.6404635,
        store_longitude: -80.3831852,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.7145254,
        store_longitude: -80.2459293,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.23657,
        store_longitude: -80.9342,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.2479269,
        store_longitude: -80.9264474,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.234821,
        store_longitude: -80.9030962,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2484740261,
        store_longitude: -80.9103785455,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2481186,
        store_longitude: -80.9025064,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2408359,
        store_longitude: -80.8902844,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.6214225,
        store_longitude: -80.3921359,
    },
    {
        store_latitdue: -2.7165731,
        store_longitude: -80.2974377,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },

    {
        store_latitdue: -2.2357043,
        store_longitude: -80.8548158,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.636709,
        store_longitude: -80.3810292,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -1.8839231,
        store_longitude: -80.7343244,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.215242978,
        store_longitude: -80.95758306,
    },
    {
        store_latitdue: -2.2304667,
        store_longitude: -80.8908023,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.1335128,
        store_longitude: -79.9387625,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.711767086,
        store_longitude: -80.24531861,
    },
    {
        store_latitdue: -2.71704,
        store_longitude: -80.2474,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2271323,
        store_longitude: -80.8423203,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.7142910688,
        store_longitude: -80.2437226841,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -1.8721999,
        store_longitude: -80.7364402,
        neighborhood: 'MANGLARALTO',
    },

    {
        store_latitdue: -2.3229931,
        store_longitude: -80.854607,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.2512,
        store_longitude: -80.9109,
    },
    {
        store_latitdue: -2.2314258628,
        store_longitude: -80.8994733289,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.942015,
        store_longitude: -80.7244163,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2348279,
        store_longitude: -80.9082636,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2209,
        store_longitude: -80.9106,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.22640322,
        store_longitude: -80.85822002,
    },
    {
        store_latitdue: -2.2376833,
        store_longitude: -80.8930404,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.75958,
        store_longitude: -80.7684,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.2097342,
        store_longitude: -80.9652182,
    },
    {
        store_latitdue: -2.4052397,
        store_longitude: -80.6707671,
    },
    {
        store_latitdue: -2.1424116,
        store_longitude: -80.7750144,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2398184,
        store_longitude: -80.8994155,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.6416712,
        store_longitude: -80.3808308,
    },
    {
        store_latitdue: -2.6363514,
        store_longitude: -80.3889334,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.55843,
        store_longitude: -80.5097,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.22385,
        store_longitude: -80.8513,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.7189935,
        store_longitude: -80.2550542,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2406576,
        store_longitude: -80.9022114,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.94587,
        store_longitude: -80.7264,
    },
    {
        store_latitdue: -2.6338751,
        store_longitude: -80.3790713,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.23157477,
        store_longitude: -80.9113302,
    },
    {
        store_latitdue: -1.940451,
        store_longitude: -80.7252695,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -0.191464,
        store_longitude: -78.4858,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.1415422,
        store_longitude: -80.7746826,
    },
    {
        store_latitdue: -2.235881339,
        store_longitude: -80.91755006,
    },
    {
        store_latitdue: -2.7093503,
        store_longitude: -80.2424494,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.22788119,
        store_longitude: -80.9071578,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.8825883,
        store_longitude: -80.7350666,
    },
    {
        store_latitdue: -1.9405983,
        store_longitude: -80.7231407,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.8305559,
        store_longitude: -80.7506474,
        neighborhood: 'MANGLARALTO',
    },

    {
        store_latitdue: -1.77793981,
        store_longitude: -80.7682013,
    },
    {
        store_latitdue: -2.709446,
        store_longitude: -80.250232,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.230187793,
        store_longitude: -80.84407659,
    },
    {
        store_latitdue: -2.23567,
        store_longitude: -80.8982117,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2344688,
        store_longitude: -80.8966035,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.82807,
        store_longitude: -80.7515,
    },

    {
        store_latitdue: -2.22598,
        store_longitude: -80.9165,
    },
    {
        store_latitdue: -2.23768071,
        store_longitude: -80.9010243,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.22626,
        store_longitude: -80.9015,
    },
    {
        store_latitdue: -2.1403532,
        store_longitude: -80.7766987,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2360577,
        store_longitude: -80.8514695,
    },
    {
        store_latitdue: -2.2261158,
        store_longitude: -80.902425,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.7423,
        store_longitude: -80.7761,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.88169,
        store_longitude: -80.7346,
    },
    {
        store_latitdue: -2.6338857,
        store_longitude: -80.389273,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.22096,
        store_longitude: -80.9393,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.23349965,
        store_longitude: -80.9133517,
    },
    {
        store_latitdue: -2.023735432,
        store_longitude: -80.73422083,
    },
    {
        store_latitdue: -2.3805059,
        store_longitude: -80.7000457,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.25449,
        store_longitude: -80.578,
    },
    {
        store_latitdue: -2.23166,
        store_longitude: -80.8923,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24654,
        store_longitude: -80.9086,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.610644,
        store_longitude: -80.3022671,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.3410656,
        store_longitude: -80.5229201,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.84309,
        store_longitude: -80.7467,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.784577873,
        store_longitude: -79.91151684,
    },
    {
        store_latitdue: -2.3242792,
        store_longitude: -80.8547885,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },
    {
        store_latitdue: -2.5331647,
        store_longitude: -80.3917424,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.0234093,
        store_longitude: -80.7330458,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.0107474,
        store_longitude: -80.6297879,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.0255931,
        store_longitude: -80.7350219,
        neighborhood: 'COLONCHE',
    },

    {
        store_latitdue: -2.3416383,
        store_longitude: -80.5228483,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -2.24937,
        store_longitude: -80.9166,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.3975686,
        store_longitude: -80.6950039,
    },

    {
        store_latitdue: -2.7111286,
        store_longitude: -80.2483898,
    },
    {
        store_latitdue: -2.2258868,
        store_longitude: -80.9019948,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.24615,
        store_longitude: -79.8939,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -1.9759834,
        store_longitude: -80.7496782,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.06138,
        store_longitude: -80.7339,
    },
    {
        store_latitdue: -2.14384,
        store_longitude: -80.7738,
    },
    {
        store_latitdue: -2.2353248458,
        store_longitude: -80.8967086673,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.2403397,
        store_longitude: -80.927028,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.242661,
        store_longitude: -80.9107029,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -2.165923,
        store_longitude: -80.820576,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.165923,
        store_longitude: -80.820576,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.2377808828,
        store_longitude: -80.8955955505,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.8822516,
        store_longitude: -80.7327133,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.0243454,
        store_longitude: -80.7337422,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.0296007,
        store_longitude: -80.734684,
        neighborhood: 'COLONCHE',
    },
    {
        store_latitdue: -2.222576,
        store_longitude: -80.9128762,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -1.9419968,
        store_longitude: -80.7230767,
    },
    {
        store_latitdue: -2.70275,
        store_longitude: -80.2479,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.2328162,
        store_longitude: -80.9364931,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.3346,
        store_longitude: -80.271,
    },
    {
        store_latitdue: -1.9955276,
        store_longitude: -80.5845219,
    },

    {
        store_latitdue: -2.23919,
        store_longitude: -80.8977,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -2.6363502,
        store_longitude: -80.3815057,
    },
    {
        store_latitdue: -2.63804122,
        store_longitude: -80.3916728,
        neighborhood: 'GENERAL VILLAMIL',
    },

    {
        store_latitdue: -2.6298924,
        store_longitude: -80.3950326,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2395094,
        store_longitude: -80.9312609,
        neighborhood: 'JOSÉ LUIS TAMAYO',
    },
    {
        store_latitdue: -2.23064,
        store_longitude: -80.8949,
    },
    {
        store_latitdue: -2.222557,
        store_longitude: -80.917674,
    },
    {
        store_latitdue: -2.317002,
        store_longitude: -80.591758,
        neighborhood: 'CHANDUY',
    },
    {
        store_latitdue: -1.79955,
        store_longitude: -79.53784,
    },
    {
        store_latitdue: -2.633864,
        store_longitude: -80.382027,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2352378,
        store_longitude: -80.9338525,
    },
    {
        store_latitdue: -2.23824,
        store_longitude: -80.8991,
    },
    {
        store_latitdue: -2.2460335,
        store_longitude: -80.8599172,
    },
    {
        store_latitdue: -2.6284372,
        store_longitude: -80.3940635,
    },
    {
        store_latitdue: -2.2950799,
        store_longitude: -80.8483591,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },

    {
        store_latitdue: -2.3342736,
        store_longitude: -80.2703436,
        neighborhood: 'JUAN GÓMEZ RENDÓN',
    },
    {
        store_latitdue: -2.216106725,
        store_longitude: -80.31860821,
        neighborhood: 'SIMÓN BOLÍVAR',
    },
    {
        store_latitdue: -2.40636,
        store_longitude: -80.3668437,
    },
    {
        store_latitdue: -2.20560351,
        store_longitude: -80.9720269,
    },
    {
        store_latitdue: -2.1623871,
        store_longitude: -80.8113087,
        neighborhood: 'SANTA ELENA',
    },
    {
        store_latitdue: -2.223279,
        store_longitude: -80.917429,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.94754,
        store_longitude: -80.7271,
    },
    {
        store_latitdue: -2.22089,
        store_longitude: -80.8941,
    },

    {
        store_latitdue: -2.7136484,
        store_longitude: -80.2444966,
        neighborhood: 'POSORJA',
    },
    {
        store_latitdue: -2.6402948,
        store_longitude: -80.3833711,
        neighborhood: 'GENERAL VILLAMIL',
    },
    {
        store_latitdue: -2.2144347,
        store_longitude: -80.9466106,
    },
    {
        store_latitdue: -2.21249,
        store_longitude: -80.9579,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.23111,
        store_longitude: -80.8955,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: -1.934578,
        store_longitude: -80.686257,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.705194,
        store_longitude: -80.245964,
    },
    {
        store_latitdue: -2.23893,
        store_longitude: -80.889,
    },

    {
        store_latitdue: -0.249269,
        store_longitude: -79.185912,
    },
    {
        store_latitdue: -2.1309528,
        store_longitude: -79.93806,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: -2.6395311,
        store_longitude: -80.3833932,
    },
    {
        store_latitdue: -2.6104647,
        store_longitude: -80.3055613,
        neighborhood: 'MORRO',
    },
    {
        store_latitdue: -2.2121046,
        store_longitude: -80.9508131,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.20505,
        store_longitude: -80.9769,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2058203,
        store_longitude: -80.9730372,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -2.2039601,
        store_longitude: -80.977135,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: -1.9107037,
        store_longitude: -80.653154,
    },
    {
        store_latitdue: -2.3235,
        store_longitude: -80.8545803,
        neighborhood: 'SAN JOSÉ DE ANCÓN',
    },

    {
        store_latitdue: -1.8658698,
        store_longitude: -80.7402285,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.7992424,
        store_longitude: -80.7564266,
    },

    {
        store_latitdue: -2.1654981,
        store_longitude: -79.8408523,
    },
    {
        store_latitdue: -1.8276333,
        store_longitude: -80.7527951,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -2.20375,
        store_longitude: -80.9769,
    },

    {
        store_latitdue: -1.8655318,
        store_longitude: -80.7405377,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.93909,
        store_longitude: -80.7245,
        neighborhood: 'MANGLARALTO',
    },
    {
        store_latitdue: -1.49833,
        store_longitude: -77.9975,
    },
    {
        store_latitdue: -0.331764,
        store_longitude: -78.5242546,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.4880485,
        store_longitude: -77.9987302,
    },
    {
        store_latitdue: -0.915598,
        store_longitude: -77.8094,
    },
    {
        store_latitdue: -0.3479768,
        store_longitude: -77.5208527,
    },
    {
        store_latitdue: -0.3399638,
        store_longitude: -77.8099727,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.3393521,
        store_longitude: -77.8088953,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.5026,
        store_longitude: -78.0577,
    },
    {
        store_latitdue: -0.339195,
        store_longitude: -77.8085,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.3352488,
        store_longitude: -77.8129032,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.423333,
        store_longitude: -77.8474,
        neighborhood: 'SAN FRANCISCO DE BORJA',
    },
    {
        store_latitdue: 0.075756,
        store_longitude: -76.886039,
    },
    {
        store_latitdue: -0.4538,
        store_longitude: -77.8892,
    },
    {
        store_latitdue: -0.57989,
        store_longitude: -77.868149,
    },
    {
        store_latitdue: -0.4646,
        store_longitude: -77.8892,
    },
    {
        store_latitdue: -0.278894,
        store_longitude: -77.5336,
    },
    {
        store_latitdue: -0.31,
        store_longitude: -77.7855,
        neighborhood: 'SANTA ROSA',
    },
    {
        store_latitdue: -2.22204,
        store_longitude: -80.9114,
    },
    {
        store_latitdue: -1.0089106,
        store_longitude: -77.81561,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9838874,
        store_longitude: -77.815251,
    },
    {
        store_latitdue: -0.9843366,
        store_longitude: -77.8159343,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.985349,
        store_longitude: -77.8213,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9996724,
        store_longitude: -77.8114335,
    },
    {
        store_latitdue: -0.9995177,
        store_longitude: -77.8108716,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9997007,
        store_longitude: -77.8111299,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00395,
        store_longitude: -77.8174,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0073154,
        store_longitude: -77.8182249,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.04748,
        store_longitude: -77.4893,
    },
    {
        store_latitdue: -1.00265,
        store_longitude: -77.812,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.993679,
        store_longitude: -77.8088163,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.995107,
        store_longitude: -77.8078448,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9848507,
        store_longitude: -77.8391413,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.2677578,
        store_longitude: -77.8893077,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -0.9993819,
        store_longitude: -77.811911,
    },
    {
        store_latitdue: -0.9870731,
        store_longitude: -77.812763,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.98867,
        store_longitude: -77.8101,
    },
    {
        store_latitdue: -0.9539111,
        store_longitude: -77.8165667,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9879264,
        store_longitude: -77.813543,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9865467,
        store_longitude: -77.8299829,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00134,
        store_longitude: -77.8115,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0015215,
        store_longitude: -77.8115664,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9937957,
        store_longitude: -77.814371,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9967671,
        store_longitude: -77.8137848,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9967906,
        store_longitude: -77.8145254,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9974865,
        store_longitude: -77.814073,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9980654,
        store_longitude: -77.814144,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00615,
        store_longitude: -77.8154,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.988044,
        store_longitude: -77.816,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9874041,
        store_longitude: -77.8182556,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9884644,
        store_longitude: -77.8152403,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9885694,
        store_longitude: -77.8154428,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9883868,
        store_longitude: -77.8154772,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9108525,
        store_longitude: -77.8073931,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.912549,
        store_longitude: -77.8087,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9128376,
        store_longitude: -77.8077817,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.910023,
        store_longitude: -77.8079216,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.913012,
        store_longitude: -77.8089,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.907024,
        store_longitude: -77.8069,
    },
    {
        store_latitdue: -0.9112733,
        store_longitude: -77.809497,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.912118,
        store_longitude: -77.8097,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9100554,
        store_longitude: -77.806889,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9109288,
        store_longitude: -77.80792,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.915245,
        store_longitude: -77.8071,
    },
    {
        store_latitdue: -0.912005,
        store_longitude: -77.8067,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.911255,
        store_longitude: -77.8068,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.91783,
        store_longitude: -77.8109,
    },
    {
        store_latitdue: -0.9161036,
        store_longitude: -77.807886,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9106282,
        store_longitude: -77.807979,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9166087,
        store_longitude: -77.8122368,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9131785,
        store_longitude: -77.80993,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.4973076,
        store_longitude: -78.0609139,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4996029,
        store_longitude: -78.0625188,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4981548,
        store_longitude: -78.0609829,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4995286,
        store_longitude: -78.0659707,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.5031655,
        store_longitude: -78.0619193,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4593588,
        store_longitude: -78.1164643,
    },
    {
        store_latitdue: -1.4988969,
        store_longitude: -78.0609584,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4602908,
        store_longitude: -78.113755,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.5006951,
        store_longitude: -78.0670029,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.503283,
        store_longitude: -78.0628177,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.5037454,
        store_longitude: -78.0620827,
    },
    {
        store_latitdue: -1.5010752,
        store_longitude: -78.0611595,
    },
    {
        store_latitdue: -1.5017847,
        store_longitude: -78.0614191,
    },
    {
        store_latitdue: -1.5003365,
        store_longitude: -78.0611812,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.5006151,
        store_longitude: -78.0671452,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.5030572,
        store_longitude: -78.0631685,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.5003007,
        store_longitude: -78.067665,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4848977,
        store_longitude: -78.007889,
    },
    {
        store_latitdue: -1.4828198,
        store_longitude: -77.999286,
    },
    {
        store_latitdue: -1.4909941,
        store_longitude: -78.005419,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4870288,
        store_longitude: -78.000214,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.488954766,
        store_longitude: -78.01123015,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4976433,
        store_longitude: -78.020915,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4979712,
        store_longitude: -78.0210409,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4277797,
        store_longitude: -77.9987508,
        neighborhood: 'FÁTIMA',
    },
    {
        store_latitdue: -1.42803,
        store_longitude: -77.9987,
        neighborhood: 'FÁTIMA',
    },
    {
        store_latitdue: -1.4878983,
        store_longitude: -77.995437,
    },
    {
        store_latitdue: -1.4788006,
        store_longitude: -77.9998711,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4882126,
        store_longitude: -77.999252,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4938442,
        store_longitude: -78.0146377,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4872094,
        store_longitude: -77.9955206,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4871271,
        store_longitude: -77.9958028,
    },
    {
        store_latitdue: -1.5433001,
        store_longitude: -78.0354412,
        neighborhood: 'MADRE TIERRA',
    },
    {
        store_latitdue: -1.48825,
        store_longitude: -77.9951,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4887081,
        store_longitude: -77.994723,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4893023,
        store_longitude: -77.9942418,
    },
    {
        store_latitdue: -1.4895607,
        store_longitude: -77.993699,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9977716,
        store_longitude: -77.8122539,
    },
    {
        store_latitdue: -1.48353,
        store_longitude: -78.0095,
    },
    {
        store_latitdue: -1.4807738,
        store_longitude: -78.0030976,
    },
    {
        store_latitdue: -1.482952,
        store_longitude: -78.0018663,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4854133,
        store_longitude: -78.008993,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4984656,
        store_longitude: -78.0043697,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4862886,
        store_longitude: -78.005216,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4866614,
        store_longitude: -78.0060222,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4750691,
        store_longitude: -77.9997265,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4902611,
        store_longitude: -77.9918543,
    },
    {
        store_latitdue: -1.49308,
        store_longitude: -77.98892,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5080538,
        store_longitude: -77.94207,
        neighborhood: 'VERACRUZ',
    },
    {
        store_latitdue: -1.4872058,
        store_longitude: -77.9949903,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4872474,
        store_longitude: -77.9950417,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.48687,
        store_longitude: -77.9952,
    },
    {
        store_latitdue: -1.4870821,
        store_longitude: -78.0064762,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4800228,
        store_longitude: -78.000121,
    },
    {
        store_latitdue: -1.48046,
        store_longitude: -78.0002,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4875743,
        store_longitude: -77.9980539,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.04307,
        store_longitude: -77.7953,
        neighborhood: 'PUERTO NAPO',
    },
    {
        store_latitdue: -1.4935886,
        store_longitude: -78.0122402,
    },
    {
        store_latitdue: -1.4878202,
        store_longitude: -77.9982082,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.042672,
        store_longitude: -77.792647,
    },
    {
        store_latitdue: -1.042472,
        store_longitude: -77.794815,
    },
    {
        store_latitdue: -1.4883558,
        store_longitude: -78.0077244,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.497223,
        store_longitude: -77.998745,
    },
    {
        store_latitdue: -1.5022532,
        store_longitude: -77.9992591,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4954325,
        store_longitude: -78.0007585,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.483538,
        store_longitude: -78.009413,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4835696,
        store_longitude: -78.0094839,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4827032,
        store_longitude: -78.0098207,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4761109,
        store_longitude: -78.003412,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4754534,
        store_longitude: -77.9994518,
    },
    {
        store_latitdue: -1.4910813,
        store_longitude: -77.9961055,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.49018,
        store_longitude: -77.9989,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.48577,
        store_longitude: -78.0032,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4856256,
        store_longitude: -78.0036362,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5233309,
        store_longitude: -78.0030479,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.5113634,
        store_longitude: -78.0086045,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.51099,
        store_longitude: -78.013,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.5117759,
        store_longitude: -78.014302,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.48029,
        store_longitude: -77.9984,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.494111,
        store_longitude: -77.988213,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4858769,
        store_longitude: -78.0029414,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4949368,
        store_longitude: -78.0132473,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4959297,
        store_longitude: -78.0125788,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.23316,
        store_longitude: -77.687386,
    },
    {
        store_latitdue: -1.2693607,
        store_longitude: -77.889384,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.2642043,
        store_longitude: -77.8879076,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.17516,
        store_longitude: -77.8597,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.16895,
        store_longitude: -77.8556,
    },
    {
        store_latitdue: -1.03517,
        store_longitude: -77.6668,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -1.035124,
        store_longitude: -77.665975,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -1.03475,
        store_longitude: -77.6673,
    },
    {
        store_latitdue: -1.0072064,
        store_longitude: -77.8114607,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0071486,
        store_longitude: -77.8124588,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.007126,
        store_longitude: -77.8129708,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.009422,
        store_longitude: -77.8128962,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0055583,
        store_longitude: -77.8144254,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0055991,
        store_longitude: -77.8153314,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0006034,
        store_longitude: -77.8094493,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00048,
        store_longitude: -77.8071,
    },
    {
        store_latitdue: -0.988546,
        store_longitude: -77.8267,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9554071,
        store_longitude: -77.8610841,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9862425,
        store_longitude: -77.8296048,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.2685696,
        store_longitude: -77.889436,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.2656742,
        store_longitude: -77.8905067,
    },
    {
        store_latitdue: -1.2293,
        store_longitude: -77.8818,
    },
    {
        store_latitdue: -0.984887,
        store_longitude: -77.8150313,
    },
    {
        store_latitdue: -0.9468124,
        store_longitude: -77.8170543,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.98371,
        store_longitude: -77.8414,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9780832,
        store_longitude: -77.853647,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4286533,
        store_longitude: -77.9987227,
        neighborhood: 'FÁTIMA',
    },
    {
        store_latitdue: -1.45283,
        store_longitude: -77.9951,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.45303,
        store_longitude: -77.9946,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4838844,
        store_longitude: -78.00633,
    },
    {
        store_latitdue: -0.99447,
        store_longitude: -77.810293,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4798481,
        store_longitude: -78.002012,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4762012,
        store_longitude: -78.0026275,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4871236,
        store_longitude: -77.9952073,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4872074,
        store_longitude: -77.9951938,
    },
    {
        store_latitdue: -0.9835251,
        store_longitude: -77.8104636,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4855985,
        store_longitude: -77.9992639,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.988797,
        store_longitude: -77.8163,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.98614,
        store_longitude: -77.824568,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9909906,
        store_longitude: -77.816804,
    },
    {
        store_latitdue: -0.9921706,
        store_longitude: -77.813002,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.996644,
        store_longitude: -77.8104653,
    },
    {
        store_latitdue: -0.999351,
        store_longitude: -77.8103085,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00081,
        store_longitude: -77.8149,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4973797,
        store_longitude: -77.9848936,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9997621,
        store_longitude: -77.809425,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.48709,
        store_longitude: -77.9992,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4877765,
        store_longitude: -77.9981715,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4892064,
        store_longitude: -77.9941973,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4890056,
        store_longitude: -77.9986926,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4881497,
        store_longitude: -77.9955216,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4884037,
        store_longitude: -77.995549,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4900455,
        store_longitude: -78.0118645,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.49986,
        store_longitude: -78.02324,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.497609,
        store_longitude: -78.0206037,
    },
    {
        store_latitdue: -1.5012607,
        store_longitude: -78.0066504,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0055932,
        store_longitude: -77.819156,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49347,
        store_longitude: -78.0097,
    },
    {
        store_latitdue: -1.4906785,
        store_longitude: -78.0061046,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4901555,
        store_longitude: -78.0061766,
    },
    {
        store_latitdue: -1.4903213,
        store_longitude: -78.0061046,
    },
    {
        store_latitdue: -1.033324,
        store_longitude: -77.667812,
    },
    {
        store_latitdue: -1.0066729,
        store_longitude: -77.8167319,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00649,
        store_longitude: -77.8143,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00782,
        store_longitude: -77.8117,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.18928,
        store_longitude: -77.8723,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.4879591,
        store_longitude: -78.0121081,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4888944,
        store_longitude: -77.9956698,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4885637,
        store_longitude: -77.9948935,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4929193,
        store_longitude: -78.0119462,
    },
    {
        store_latitdue: -1.51104,
        store_longitude: -78.0133,
    },
    {
        store_latitdue: -1.5055667,
        store_longitude: -78.0148609,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.503354,
        store_longitude: -78.0121668,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5030026,
        store_longitude: -78.0144277,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.4183053,
        store_longitude: -77.8440573,
    },
    {
        store_latitdue: -0.9182647,
        store_longitude: -77.8112568,
    },
    {
        store_latitdue: -1.4906461,
        store_longitude: -78.0752323,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.910235,
        store_longitude: -77.8078,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.912099,
        store_longitude: -77.8085,
    },
    {
        store_latitdue: -0.913413,
        store_longitude: -77.8066,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.4980315,
        store_longitude: -78.0654177,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4993028,
        store_longitude: -78.0621136,
    },
    {
        store_latitdue: -1.4850762,
        store_longitude: -78.0837957,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4807971,
        store_longitude: -78.0011987,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.48614,
        store_longitude: -78.005,
    },
    {
        store_latitdue: -1.4848453,
        store_longitude: -77.9999367,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4788478,
        store_longitude: -78.0012014,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.690594,
        store_longitude: -77.3109,
    },
    {
        store_latitdue: -0.996649,
        store_longitude: -77.8129,
    },
    {
        store_latitdue: -0.9823429,
        store_longitude: -77.8191718,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4792,
        store_longitude: -78.0058,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4761998,
        store_longitude: -78.0038614,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4830553,
        store_longitude: -77.9987801,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4825124,
        store_longitude: -77.999355,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4876515,
        store_longitude: -77.995402,
    },
    {
        store_latitdue: -1.4864722,
        store_longitude: -78.003113,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4920044,
        store_longitude: -78.0067907,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.49227,
        store_longitude: -78.0125,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4899834,
        store_longitude: -78.0065212,
    },
    {
        store_latitdue: -1.0069,
        store_longitude: -77.8202,
    },
    {
        store_latitdue: -0.9991449,
        store_longitude: -77.8124529,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.1893966,
        store_longitude: -77.8727666,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.1895178,
        store_longitude: -77.8737853,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.0080744,
        store_longitude: -77.8114397,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4879495,
        store_longitude: -78.0116233,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4880274,
        store_longitude: -78.0125993,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4921959,
        store_longitude: -78.010466,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.49347,
        store_longitude: -78.0121,
    },
    {
        store_latitdue: -1.5034446,
        store_longitude: -78.010862,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5037939,
        store_longitude: -78.0148169,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5177,
        store_longitude: -78.0024,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.0013225,
        store_longitude: -77.812697,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00116,
        store_longitude: -77.8123,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.999229,
        store_longitude: -77.8123,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.8980281,
        store_longitude: -77.802972,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.8992815,
        store_longitude: -77.8037498,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9154779,
        store_longitude: -77.8104778,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.4958172,
        store_longitude: -78.0661875,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: 0.024177,
        store_longitude: -78.8962,
    },
    {
        store_latitdue: -0.331885,
        store_longitude: -77.8047,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.4234821,
        store_longitude: -77.8456375,
        neighborhood: 'SAN FRANCISCO DE BORJA',
    },
    {
        store_latitdue: -0.4634,
        store_longitude: -77.9177,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -0.3468527,
        store_longitude: -77.5202301,
    },
    {
        store_latitdue: -1.4636129,
        store_longitude: -77.9939939,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.169769,
        store_longitude: -77.855904,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.166458,
        store_longitude: -77.855248,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -0.9844885,
        store_longitude: -77.8188907,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9969068,
        store_longitude: -77.8104355,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4794262,
        store_longitude: -78.0069088,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0424511,
        store_longitude: -77.7944171,
        neighborhood: 'PUERTO NAPO',
    },
    {
        store_latitdue: -2.30453,
        store_longitude: -78.1188,
    },
    {
        store_latitdue: -0.9948,
        store_longitude: -77.8078,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4930256,
        store_longitude: -78.0053132,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0013713,
        store_longitude: -77.812685,
    },
    {
        store_latitdue: -1.0000684,
        store_longitude: -77.8117554,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9576015,
        store_longitude: -77.8634605,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4903544,
        store_longitude: -78.0061656,
    },
    {
        store_latitdue: -1.48877,
        store_longitude: -78.0767,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.9728246,
        store_longitude: -77.813367,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9923214,
        store_longitude: -77.8314511,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9135006,
        store_longitude: -77.809207,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.5231339,
        store_longitude: -78.0033178,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -0.923491,
        store_longitude: -77.8151,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.98336328,
        store_longitude: -77.8183078,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.33676,
        store_longitude: -77.8127,
    },
    {
        store_latitdue: -1.48476,
        store_longitude: -78.0042,
    },
    {
        store_latitdue: -1.170667,
        store_longitude: -77.856045,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.48294,
        store_longitude: -78.0042,
    },
    {
        store_latitdue: -1.47435,
        store_longitude: -78.0004,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4813493,
        store_longitude: -78.0038322,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9990457,
        store_longitude: -77.8118447,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00394,
        store_longitude: -77.8137,
    },
    {
        store_latitdue: -1.4922322,
        store_longitude: -78.0070336,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.944551,
        store_longitude: -77.8161,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.915626,
        store_longitude: -77.8071,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.2632723,
        store_longitude: -77.8866733,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.4987553,
        store_longitude: -77.9864511,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.996676,
        store_longitude: -77.8139,
    },
    {
        store_latitdue: -1.04314,
        store_longitude: -77.7946,
        neighborhood: 'PUERTO NAPO',
    },
    {
        store_latitdue: -0.997046,
        store_longitude: -77.8117,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49432,
        store_longitude: -78.0133,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9107618,
        store_longitude: -77.8068277,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.42961,
        store_longitude: -77.9986,
    },
    {
        store_latitdue: -1.48502,
        store_longitude: -77.9973,
    },
    {
        store_latitdue: -1.245317,
        store_longitude: -78.621686,
    },
    {
        store_latitdue: -1.5080433,
        store_longitude: -77.9410622,
        neighborhood: 'VERACRUZ',
    },
    {
        store_latitdue: -0.9996598,
        store_longitude: -77.8134721,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49082,
        store_longitude: -78.0087,
    },
    {
        store_latitdue: -0.3300876,
        store_longitude: -77.8162183,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.3289599,
        store_longitude: -77.8155641,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.422674,
        store_longitude: -77.847,
        neighborhood: 'SAN FRANCISCO DE BORJA',
    },
    {
        store_latitdue: -1.2666858,
        store_longitude: -77.8897576,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.2636535,
        store_longitude: -77.891094,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -0.329414,
        store_longitude: -77.8151,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.3334976,
        store_longitude: -77.8060477,
    },
    {
        store_latitdue: -0.340497,
        store_longitude: -77.8108,
    },
    {
        store_latitdue: -1.4993534,
        store_longitude: -78.0165374,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.46203,
        store_longitude: -78.1102,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.5035852,
        store_longitude: -78.0583435,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4993133,
        store_longitude: -78.0654747,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4791035,
        store_longitude: -77.9999961,
    },
    {
        store_latitdue: -0.910375,
        store_longitude: -77.8092,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.2635261,
        store_longitude: -77.88821,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -0.9905846,
        store_longitude: -77.8156871,
    },
    {
        store_latitdue: -1.4797041,
        store_longitude: -78.0068895,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.990094,
        store_longitude: -77.8166955,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4997498,
        store_longitude: -77.9868098,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5092641,
        store_longitude: -77.9799528,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.3290702,
        store_longitude: -77.8158096,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.4917724,
        store_longitude: -78.0101348,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.98528,
        store_longitude: -77.8196,
    },
    {
        store_latitdue: -0.998234,
        store_longitude: -77.8148336,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4903074,
        store_longitude: -78.0716656,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4628813,
        store_longitude: -77.9941638,
    },
    {
        store_latitdue: -1.49031,
        store_longitude: -77.995916,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4982967,
        store_longitude: -78.0640521,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.0354656,
        store_longitude: -77.6663741,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -1.48122739,
        store_longitude: -78.0002441,
    },
    {
        store_latitdue: -1.4861129,
        store_longitude: -78.0005009,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.460529,
        store_longitude: -77.8866,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -1.499713,
        store_longitude: -78.0119328,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9561079,
        store_longitude: -77.8161105,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.984545,
        store_longitude: -77.8302,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4271925,
        store_longitude: -77.9987314,
        neighborhood: 'FÁTIMA',
    },
    {
        store_latitdue: -1.42759,
        store_longitude: -77.9987,
    },
    {
        store_latitdue: -0.9999026,
        store_longitude: -77.8134587,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9997181,
        store_longitude: -77.8133511,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.3311892,
        store_longitude: -77.8162665,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.485902,
        store_longitude: -77.9950948,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.48816,
        store_longitude: -77.9958,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.999673,
        store_longitude: -77.8122,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4875185,
        store_longitude: -77.9945115,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.493415,
        store_longitude: -78.0151488,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.024855,
        store_longitude: -77.6726953,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -1.042593,
        store_longitude: -77.794905,
        neighborhood: 'PUERTO NAPO',
    },
    {
        store_latitdue: -1.4954722,
        store_longitude: -78.003251,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.463331,
        store_longitude: -77.9182,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -1.0053365,
        store_longitude: -77.813657,
    },
    {
        store_latitdue: -1.5066918,
        store_longitude: -77.9806572,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.985915,
        store_longitude: -77.8186,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4790303,
        store_longitude: -78.0042987,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.91526,
        store_longitude: -77.8134,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.70124,
        store_longitude: -77.3137,
    },
    {
        store_latitdue: -0.9897,
        store_longitude: -77.8159,
    },
    {
        store_latitdue: -1.4608,
        store_longitude: -78.1136,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.4947548,
        store_longitude: -78.071451,
    },
    {
        store_latitdue: -1.5086421,
        store_longitude: -77.940787,
        neighborhood: 'VERACRUZ',
    },
    {
        store_latitdue: -1.48455,
        store_longitude: -78.0839,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.915907,
        store_longitude: -77.8134,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9877229,
        store_longitude: -77.818235,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.00393,
        store_longitude: -77.8178,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4904,
        store_longitude: -78.0062,
    },
    {
        store_latitdue: -1.48419,
        store_longitude: -78.007,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.8922801,
        store_longitude: -77.7976394,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.896891,
        store_longitude: -77.7952,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.896812,
        store_longitude: -77.7962,
    },
    {
        store_latitdue: 0.50816998,
        store_longitude: -77.86603184,
    },
    {
        store_latitdue: -1.50281,
        store_longitude: -78.0151,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.00359,
        store_longitude: -77.8154,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4875111,
        store_longitude: -77.9974031,
    },
    {
        store_latitdue: -1.4908985,
        store_longitude: -78.000802,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: 0.50816998,
        store_longitude: -77.86603184,
    },
    {
        store_latitdue: -0.97159739,
        store_longitude: -77.8179997,
    },
    {
        store_latitdue: -1.4737763,
        store_longitude: -78.0045134,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.990448,
        store_longitude: -77.8049,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9971638,
        store_longitude: -77.8126405,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.3205149,
        store_longitude: -77.8890639,
        neighborhood: 'SAN JOSÉ',
    },
    {
        store_latitdue: -1.4746022,
        store_longitude: -78.0050865,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.51601,
        store_longitude: -78.0146,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.54305,
        store_longitude: -78.0357,
        neighborhood: 'MADRE TIERRA',
    },
    {
        store_latitdue: -0.999237,
        store_longitude: -77.8118,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.90995,
        store_longitude: -77.808,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.5005271,
        store_longitude: -78.0120251,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.26526,
        store_longitude: -77.8905,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.0048032,
        store_longitude: -77.8104378,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.484814,
        store_longitude: -77.9975036,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5014413,
        store_longitude: -78.0015611,
    },
    {
        store_latitdue: -1.48648,
        store_longitude: -78.0001,
    },
    {
        store_latitdue: -0.9831572,
        store_longitude: -77.8122468,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0013938,
        store_longitude: -77.8135604,
    },
    {
        store_latitdue: -0.9717059,
        store_longitude: -77.8412104,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9452147,
        store_longitude: -77.8181896,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.48702,
        store_longitude: -77.9962,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4882976,
        store_longitude: -78.0772176,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4761724,
        store_longitude: -78.0052051,
    },
    {
        store_latitdue: -1.0059129,
        store_longitude: -77.8193813,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.5138826,
        store_longitude: -77.9292832,
        neighborhood: 'VERACRUZ',
    },
    {
        store_latitdue: -1.5156576,
        store_longitude: -78.0073732,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -0.9998127,
        store_longitude: -77.812539,
    },
    {
        store_latitdue: -1.5062549,
        store_longitude: -77.9805319,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.03123,
        store_longitude: -77.8024,
        neighborhood: 'PUERTO NAPO',
    },
    {
        store_latitdue: -0.9882449,
        store_longitude: -77.8151509,
    },
    {
        store_latitdue: -0.340378,
        store_longitude: -77.813502,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.4984861,
        store_longitude: -78.0167754,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4975971,
        store_longitude: -77.9848546,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.999924,
        store_longitude: -77.8121,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.5058684,
        store_longitude: -78.0092018,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.4817806,
        store_longitude: -78.0036176,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.989234,
        store_longitude: -77.8157,
    },
    {
        store_latitdue: -1.5181582,
        store_longitude: -78.0043651,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -0.997966,
        store_longitude: -77.8081,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4904578,
        store_longitude: -77.9930548,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.979696,
        store_longitude: -77.8512,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4466963,
        store_longitude: -77.997132,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4534668,
        store_longitude: -77.9945039,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4889014,
        store_longitude: -77.9935964,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9867455,
        store_longitude: -77.821916,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9996724,
        store_longitude: -77.8114335,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00051,
        store_longitude: -77.811,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.48713,
        store_longitude: -78.003031,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4944,
        store_longitude: -78.0134,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5021479,
        store_longitude: -78.001991,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9734731,
        store_longitude: -77.8407424,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9845098,
        store_longitude: -77.8214472,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4983,
        store_longitude: -78.0623,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.9871113,
        store_longitude: -77.8193787,
    },
    {
        store_latitdue: -1.0072791,
        store_longitude: -77.813294,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0067717,
        store_longitude: -77.8155013,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.912837,
        store_longitude: -77.8076,
    },
    {
        store_latitdue: -0.463018,
        store_longitude: -77.918,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -1.4781696,
        store_longitude: -78.0043982,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0012663,
        store_longitude: -77.8115396,
    },
    {
        store_latitdue: -0.902002,
        store_longitude: -77.8058923,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9137064,
        store_longitude: -77.8078986,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.528378,
        store_longitude: -78.0077992,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.4858875,
        store_longitude: -77.9986052,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4901058,
        store_longitude: -78.0086096,
    },
    {
        store_latitdue: -1.4821602,
        store_longitude: -78.0093693,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4874336,
        store_longitude: -77.9948399,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.91335,
        store_longitude: -77.8091,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.913832,
        store_longitude: -77.808,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.00132,
        store_longitude: -77.8142,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.914345,
        store_longitude: -77.8098,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.4923254,
        store_longitude: -78.0123952,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.46073,
        store_longitude: -78.1143,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.26778,
        store_longitude: -77.8891,
    },
    {
        store_latitdue: -0.4601887,
        store_longitude: -77.8893054,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -1.4881,
        store_longitude: -77.9986,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4752715,
        store_longitude: -78.0004288,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.49016,
        store_longitude: -78.0067,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.48652,
        store_longitude: -77.9953,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9123808,
        store_longitude: -77.8086927,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.48725,
        store_longitude: -77.9963,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4920936,
        store_longitude: -77.9946519,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0043963,
        store_longitude: -77.8206866,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4871,
        store_longitude: -77.996,
    },
    {
        store_latitdue: -0.988286,
        store_longitude: -77.8137,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.492358,
        store_longitude: -78.0123481,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4286018531,
        store_longitude: -77.9988764993,
        neighborhood: 'FÁTIMA',
    },
    {
        store_latitdue: -1.5115476,
        store_longitude: -78.0497835,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.99627,
        store_longitude: -77.8083,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.973925,
        store_longitude: -77.8406,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.99982,
        store_longitude: -77.8069,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.988483,
        store_longitude: -77.8153,
    },
    {
        store_latitdue: -1.49306,
        store_longitude: -78.0063,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.47897,
        store_longitude: -78.0051,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.00333,
        store_longitude: -77.8177,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.26524,
        store_longitude: -77.89,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.4861072,
        store_longitude: -78.0014823,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9764563,
        store_longitude: -77.8416487,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.494511,
        store_longitude: -78.013363,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9847926,
        store_longitude: -77.8149162,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0352514,
        store_longitude: -77.6666297,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -1.46047,
        store_longitude: -78.1149,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.5236896,
        store_longitude: -78.0038301,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -0.915592,
        store_longitude: -77.8099,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.48792,
        store_longitude: -77.9959,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.909816,
        store_longitude: -77.8075,
    },
    {
        store_latitdue: -1.0013283,
        store_longitude: -77.8110017,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.334497,
        store_longitude: -77.8147,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.4902729,
        store_longitude: -78.0070287,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.51151,
        store_longitude: -78.0144,
    },
    {
        store_latitdue: -1.51096,
        store_longitude: -78.0142,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.48340783,
        store_longitude: -78.005129,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.00053,
        store_longitude: -77.8118,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9101198,
        store_longitude: -77.8066519,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.4631619,
        store_longitude: -77.9178405,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -0.340049,
        store_longitude: -77.8102,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.9875141,
        store_longitude: -77.8194939,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9464809,
        store_longitude: -77.8151364,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.997057,
        store_longitude: -77.8132,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49611,
        store_longitude: -78.066,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.48939,
        store_longitude: -78.011986,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.340518,
        store_longitude: -77.8112,
    },
    {
        store_latitdue: -1.4958908,
        store_longitude: -77.9861312,
    },
    {
        store_latitdue: -0.913191,
        store_longitude: -77.8077,
    },
    {
        store_latitdue: -0.946476,
        store_longitude: -77.8164,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49651,
        store_longitude: -77.9856,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0059,
        store_longitude: -77.8192,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49052,
        store_longitude: -78.0064,
    },
    {
        store_latitdue: -1.493885505,
        store_longitude: -78.00347686,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4913958,
        store_longitude: -78.0109845,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4887225,
        store_longitude: -77.9956634,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.999592,
        store_longitude: -77.8109,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4887984,
        store_longitude: -78.0768794,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.997763,
        store_longitude: -77.8123,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9562379,
        store_longitude: -77.8177425,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.983803,
        store_longitude: -77.8415,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0067,
        store_longitude: -77.8151,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4983765,
        store_longitude: -77.9854401,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4889341,
        store_longitude: -78.0104313,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4908925,
        store_longitude: -77.9960931,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.50241,
        store_longitude: -78.0005,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.906083,
        store_longitude: -77.8076,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.48784,
        store_longitude: -77.9996,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.035183,
        store_longitude: -77.666433,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -1.4787676,
        store_longitude: -77.9991933,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9911169,
        store_longitude: -77.8163549,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4873408,
        store_longitude: -77.993928,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4883185,
        store_longitude: -78.0044208,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.50524342,
        store_longitude: -78.0603866,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.16646,
        store_longitude: -77.85525,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.48564,
        store_longitude: -78.0062,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.491483,
        store_longitude: -77.991057,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4572,
        store_longitude: -78.1096,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.2658909,
        store_longitude: -77.888803,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -0.9135679,
        store_longitude: -77.806868,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.99835,
        store_longitude: -77.8134,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.48522651,
        store_longitude: -78.0063858,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4846652,
        store_longitude: -77.9980046,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4788613,
        store_longitude: -78.003117,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.912262,
        store_longitude: -77.8085,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.5090862,
        store_longitude: -77.9389044,
        neighborhood: 'VERACRUZ',
    },
    {
        store_latitdue: -1.5153217,
        store_longitude: -78.0146567,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -0.910124,
        store_longitude: -77.8063,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.00549,
        store_longitude: -77.8192,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4611705,
        store_longitude: -78.1115231,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.4786924,
        store_longitude: -78.0043721,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.996058,
        store_longitude: -77.8134,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.992526,
        store_longitude: -77.8144,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9831785,
        store_longitude: -77.8126087,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4833324,
        store_longitude: -78.0831439,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.913202,
        store_longitude: -77.8069,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.50345,
        store_longitude: -78.0117,
    },
    {
        store_latitdue: -1.4788604,
        store_longitude: -78.0043457,
    },
    {
        store_latitdue: -1.00554,
        store_longitude: -77.819,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4829,
        store_longitude: -78.0055,
    },
    {
        store_latitdue: -0.9986484,
        store_longitude: -77.8096521,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.988578,
        store_longitude: -77.8143,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9838,
        store_longitude: -77.8126,
    },
    {
        store_latitdue: -0.423485,
        store_longitude: -77.8481,
        neighborhood: 'SAN FRANCISCO DE BORJA',
    },
    {
        store_latitdue: -1.4914964,
        store_longitude: -78.0678912,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.26405,
        store_longitude: -77.8878,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -0.999801,
        store_longitude: -77.8135,
    },
    {
        store_latitdue: -1.4610836,
        store_longitude: -78.1137409,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -0.998474,
        store_longitude: -77.8097,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.910579,
        store_longitude: -77.808,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.49492,
        store_longitude: -78.0659,
    },
    {
        store_latitdue: -0.741973,
        store_longitude: -90.3192,
    },
    {
        store_latitdue: -1.48697,
        store_longitude: -77.9945,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.992784,
        store_longitude: -77.8136,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0046,
        store_longitude: -77.8153,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4930791,
        store_longitude: -77.9889222,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4938903,
        store_longitude: -77.9883004,
    },
    {
        store_latitdue: -1.4988526,
        store_longitude: -78.0034196,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9983925,
        store_longitude: -77.813348,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.23324,
        store_longitude: -77.687539,
        neighborhood: 'ARAJUNO',
    },
    {
        store_latitdue: -1.4932952,
        store_longitude: -78.0681409,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.987375,
        store_longitude: -77.8189,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.47873,
        store_longitude: -78.0022,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.50973,
        store_longitude: -78.0083,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.4789906,
        store_longitude: -78.0043963,
    },
    {
        store_latitdue: -1.4284677,
        store_longitude: -77.9985618,
        neighborhood: 'FÁTIMA',
    },
    {
        store_latitdue: -1.452496,
        store_longitude: -77.9952529,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.496983,
        store_longitude: -77.9966182,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4895743,
        store_longitude: -77.9940023,
    },
    {
        store_latitdue: -0.984915,
        store_longitude: -77.8379,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.89584,
        store_longitude: -77.7955,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.988468,
        store_longitude: -77.8152,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.333746,
        store_longitude: -77.806206,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.9863087,
        store_longitude: -77.8303604,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.48508,
        store_longitude: -78.0841,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.339219,
        store_longitude: -77.812572,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.0007783,
        store_longitude: -77.8124054,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.463292,
        store_longitude: -77.9182,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -1.4872256,
        store_longitude: -77.9962438,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9867445,
        store_longitude: -77.8208974,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.48676262,
        store_longitude: -77.9951583,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.983888,
        store_longitude: -77.8224,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9932822,
        store_longitude: -77.814168,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4740302,
        store_longitude: -78.0046953,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4921204,
        store_longitude: -77.9922829,
    },
    {
        store_latitdue: -0.9024022,
        store_longitude: -77.805714,
    },
    {
        store_latitdue: -0.895612,
        store_longitude: -77.7964,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.99852,
        store_longitude: -77.8134389,
    },
    {
        store_latitdue: -1.491584,
        store_longitude: -78.0076764,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9909878,
        store_longitude: -77.8167297,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49391,
        store_longitude: -78.0035,
    },
    {
        store_latitdue: -1.00107705,
        store_longitude: -77.8127822,
    },
    {
        store_latitdue: -0.9842883,
        store_longitude: -77.8169693,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.892564,
        store_longitude: -77.7971,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.4963368,
        store_longitude: -78.01203,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4824625,
        store_longitude: -77.9983226,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4761014,
        store_longitude: -78.0004383,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4783482,
        store_longitude: -77.9999372,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9152415,
        store_longitude: -77.8109432,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.5238855,
        store_longitude: -78.0031279,
    },
    {
        store_latitdue: -0.958462,
        store_longitude: -77.8156,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.50344,
        store_longitude: -78.0124,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.3328532,
        store_longitude: -77.8052908,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.5086068,
        store_longitude: -77.9387936,
        neighborhood: 'VERACRUZ',
    },
    {
        store_latitdue: -1.48922,
        store_longitude: -77.998,
    },
    {
        store_latitdue: -1.0038921,
        store_longitude: -77.8137792,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.008797,
        store_longitude: -77.820976,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: 0.50816998,
        store_longitude: -77.86603184,
    },
    {
        store_latitdue: -1.00554,
        store_longitude: -77.8153,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.909925,
        store_longitude: -77.8081,
    },
    {
        store_latitdue: -1.5027167,
        store_longitude: -78.014605,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.916169,
        store_longitude: -77.8114,
    },
    {
        store_latitdue: -0.99478268,
        store_longitude: -77.8133125,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0054077,
        store_longitude: -77.8142531,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4988,
        store_longitude: -78.0082,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0190769,
        store_longitude: -77.811084,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.035369,
        store_longitude: -77.66655,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -0.98705538,
        store_longitude: -77.8128247,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9922905,
        store_longitude: -77.8147893,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.5053175,
        store_longitude: -78.0148921,
    },
    {
        store_latitdue: -1.0041593,
        store_longitude: -77.8122022,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4922633,
        store_longitude: -78.0107641,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4900569,
        store_longitude: -78.0032286,
    },
    {
        store_latitdue: -0.972807,
        store_longitude: -77.8549,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.00702,
        store_longitude: -77.8132,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0049077,
        store_longitude: -77.819445,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4984414,
        store_longitude: -78.0215246,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.97851,
        store_longitude: -77.8537,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4977741,
        store_longitude: -78.0143577,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.987047,
        store_longitude: -77.8125,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4997051,
        store_longitude: -78.0610447,
    },
    {
        store_latitdue: -0.998213,
        store_longitude: -77.8134,
    },
    {
        store_latitdue: -1.50829,
        store_longitude: -77.9405,
        neighborhood: 'VERACRUZ',
    },
    {
        store_latitdue: -0.9881282,
        store_longitude: -77.8136173,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.16749,
        store_longitude: -77.8555,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.4961671,
        store_longitude: -77.9908538,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0072474,
        store_longitude: -77.80955,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9079608,
        store_longitude: -77.8102367,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.4905731,
        store_longitude: -78.0061078,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.00717762,
        store_longitude: -77.8128613,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4795659,
        store_longitude: -77.9999887,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4873464,
        store_longitude: -77.9944956,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.04271,
        store_longitude: -77.7929,
        neighborhood: 'PUERTO NAPO',
    },
    {
        store_latitdue: -0.9920636,
        store_longitude: -77.8147157,
    },
    {
        store_latitdue: -0.98424384,
        store_longitude: -77.822486,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4894143,
        store_longitude: -77.9957859,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.48262,
        store_longitude: -78.0021,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4787777,
        store_longitude: -78.004448,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.994789,
        store_longitude: -77.8084,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9926462,
        store_longitude: -77.8076067,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49102,
        store_longitude: -77.9962,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4942622,
        store_longitude: -78.0628178,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.9848583,
        store_longitude: -77.8179655,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.978499,
        store_longitude: -77.8513,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9919602,
        store_longitude: -77.833002,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.98789,
        store_longitude: -77.8161,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.3402847,
        store_longitude: -77.8112857,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.915266,
        store_longitude: -77.8085,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.49348,
        store_longitude: -77.9903,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.00023,
        store_longitude: -77.8072,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9960156,
        store_longitude: -77.8142522,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.45619,
        store_longitude: -78.1202,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.4787489,
        store_longitude: -77.9982991,
    },
    {
        store_latitdue: -1.4848388,
        store_longitude: -77.9948388,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4854478,
        store_longitude: -77.9938034,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.50279,
        store_longitude: -78.0257,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9180197,
        store_longitude: -77.8104505,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.487349,
        store_longitude: -78.0030056,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4907928,
        store_longitude: -78.0056442,
    },
    {
        store_latitdue: -1.4893393,
        store_longitude: -78.000295,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.2639247,
        store_longitude: -77.8878044,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.00775,
        store_longitude: -77.8134,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.996077,
        store_longitude: -77.8135,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4981752,
        store_longitude: -78.0641431,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4847302,
        store_longitude: -78.0037383,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4952285,
        store_longitude: -78.0622682,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.1709601,
        store_longitude: -77.8559878,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.0030028,
        store_longitude: -77.8134527,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4977269,
        store_longitude: -77.9955748,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4865497,
        store_longitude: -77.9950541,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.4742,
        store_longitude: -76.9877,
    },
    {
        store_latitdue: -1.49852,
        store_longitude: -78.0039,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4806238,
        store_longitude: -77.9981479,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.005255,
        store_longitude: -77.8152846,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.996981,
        store_longitude: -77.8086,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.5242725,
        store_longitude: -78.0035647,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.1619191,
        store_longitude: -77.8545056,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.49987,
        store_longitude: -78.0055,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4949699,
        store_longitude: -78.0181352,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5113,
        store_longitude: -78.0107,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.26436,
        store_longitude: -77.89,
        neighborhood: 'SANTA CLARA',
    },
    {
        store_latitdue: -1.491551,
        store_longitude: -77.9957215,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4546974,
        store_longitude: -77.9947713,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.4617437,
        store_longitude: -77.8897233,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -0.4645978,
        store_longitude: -77.8898432,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -1.4947874,
        store_longitude: -77.9954007,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4974369,
        store_longitude: -77.9845901,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5059506,
        store_longitude: -78.0478283,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.47268,
        store_longitude: -78.0046035,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4986405,
        store_longitude: -78.0159808,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5236873,
        store_longitude: -78.0039058,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.51601,
        store_longitude: -78.0146,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -0.9995667,
        store_longitude: -77.8132294,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9942721,
        store_longitude: -77.8116227,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4858663,
        store_longitude: -78.0073522,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.48219501,
        store_longitude: -78.010987,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5019078,
        store_longitude: -78.008837,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5014248,
        store_longitude: -78.0056182,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0053297,
        store_longitude: -77.8203365,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.994074,
        store_longitude: -77.8137602,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.033316,
        store_longitude: -77.673749,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -0.994536,
        store_longitude: -77.8112,
    },
    {
        store_latitdue: -1.12106,
        store_longitude: -77.8195,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.49026,
        store_longitude: -78.0086,
    },
    {
        store_latitdue: -1.4983777,
        store_longitude: -78.0166334,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9995542,
        store_longitude: -77.8141013,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.3431391,
        store_longitude: -77.9216894,
        neighborhood: 'SAN JOSÉ',
    },
    {
        store_latitdue: -1.49859,
        store_longitude: -77.9768,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.00487,
        store_longitude: -77.813164,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4938366,
        store_longitude: -77.9884536,
    },
    {
        store_latitdue: -1.4684043,
        store_longitude: -77.9924366,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4959378,
        store_longitude: -77.9915118,
    },
    {
        store_latitdue: -0.9980339,
        store_longitude: -77.8159367,
    },
    {
        store_latitdue: -1.4797582,
        store_longitude: -78.0001825,
    },
    {
        store_latitdue: -0.981314,
        store_longitude: -77.8472,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4917858,
        store_longitude: -78.0027084,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.99952936,
        store_longitude: -77.810646,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0057622,
        store_longitude: -77.8100605,
    },
    {
        store_latitdue: -1.4858267,
        store_longitude: -77.9931854,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4983617,
        store_longitude: -77.9911861,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4816698,
        store_longitude: -78.0000077,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.3392597,
        store_longitude: -77.808702,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9099,
        store_longitude: -77.8066,
    },
    {
        store_latitdue: -1.48577392,
        store_longitude: -77.9991836,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9127855,
        store_longitude: -77.8081128,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.9988149,
        store_longitude: -77.8136704,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.033382,
        store_longitude: -77.6740562,
        neighborhood: 'PUERTO MISAHUALLÍ',
    },
    {
        store_latitdue: -0.4621603,
        store_longitude: -77.9050474,
    },
    {
        store_latitdue: -1.4794262,
        store_longitude: -78.0069088,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9921559,
        store_longitude: -77.8144855,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.5006837,
        store_longitude: -78.0075963,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4877202,
        store_longitude: -78.007472,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4892022,
        store_longitude: -77.9943326,
    },
    {
        store_latitdue: -0.4182158,
        store_longitude: -77.8439218,
    },
    {
        store_latitdue: -1.4964278,
        store_longitude: -78.0607469,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4614748,
        store_longitude: -78.1121308,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.16895,
        store_longitude: -77.8555,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -1.48637151,
        store_longitude: -77.9988555,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.995346,
        store_longitude: -77.8082616,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.48673,
        store_longitude: -78.0061,
    },
    {
        store_latitdue: -0.99332821,
        store_longitude: -77.8129196,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.45768,
        store_longitude: -78.1108,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -0.9744061,
        store_longitude: -77.8475639,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.48742,
        store_longitude: -77.9973,
    },
    {
        store_latitdue: -1.49348,
        store_longitude: -77.9957,
    },
    {
        store_latitdue: -1.4967179,
        store_longitude: -78.0637855,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.998036,
        store_longitude: -77.813801,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4906301,
        store_longitude: -78.0071639,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9159292,
        store_longitude: -77.8135696,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.913429,
        store_longitude: -77.8089,
    },
    {
        store_latitdue: -1.47885,
        store_longitude: -78.0012,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.48959,
        store_longitude: -77.9937,
    },
    {
        store_latitdue: -1.0290364,
        store_longitude: -77.80301,
        neighborhood: 'PUERTO NAPO',
    },
    {
        store_latitdue: -0.29858,
        store_longitude: -76.8557,
    },
    {
        store_latitdue: -1.4875109,
        store_longitude: -78.0728964,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.48762893,
        store_longitude: -77.9971313,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9089877,
        store_longitude: -77.8096633,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -0.3322,
        store_longitude: -77.8046,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.00377,
        store_longitude: -77.8116,
    },
    {
        store_latitdue: -1.0176624,
        store_longitude: -77.8070057,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.3296413,
        store_longitude: -77.8003742,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.9861618,
        store_longitude: -77.8200906,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4760275,
        store_longitude: -78.005152,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.997465,
        store_longitude: -77.8127,
    },
    {
        store_latitdue: -0.999412,
        store_longitude: -77.8099,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.5093791,
        store_longitude: -78.0498536,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.4977854,
        store_longitude: -77.9849043,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0107232,
        store_longitude: -77.8128503,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49128028,
        store_longitude: -78.0037695,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4940898,
        store_longitude: -77.9900738,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.488527,
        store_longitude: -78.0028583,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4877409,
        store_longitude: -78.0002653,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -2.52416,
        store_longitude: -78.1695,
    },
    {
        store_latitdue: -1.4866334,
        store_longitude: -78.0058817,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.49386,
        store_longitude: -78.0001709,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4892368,
        store_longitude: -77.9980561,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.99426006,
        store_longitude: -77.8127365,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.816979,
        store_longitude: -78.593371,
        neighborhood: 'PUNGALÁ',
    },
    {
        store_latitdue: -0.991913,
        store_longitude: -77.8133155,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.1647419,
        store_longitude: -77.8552769,
        neighborhood: 'CARLOS JULIO AROSEMENA TOLA',
    },
    {
        store_latitdue: -0.9559184,
        store_longitude: -77.8163828,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4916223,
        store_longitude: -77.9907854,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.00369238,
        store_longitude: -77.8153915,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0010731,
        store_longitude: -77.8122893,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.9846791,
        store_longitude: -77.8392231,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.4631182413,
        store_longitude: -77.9176334292,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -0.9945782,
        store_longitude: -77.8134047,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0004824,
        store_longitude: -77.8154572,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.9840674,
        store_longitude: -77.8126597,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.987805,
        store_longitude: -77.8308,
    },
    {
        store_latitdue: -0.9994557,
        store_longitude: -77.8170624,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.5034453,
        store_longitude: -78.0118498,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.472223,
        store_longitude: -77.9984663,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.996937,
        store_longitude: -77.811917,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4982515,
        store_longitude: -78.021312,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.333701,
        store_longitude: -77.81242,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.9881,
        store_longitude: -77.8142,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0425641,
        store_longitude: -77.7954304,
        neighborhood: 'PUERTO NAPO',
    },
    {
        store_latitdue: -1.488484,
        store_longitude: -77.994184,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.496719,
        store_longitude: -77.9763764,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.8930654,
        store_longitude: -77.7999948,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.4881515432,
        store_longitude: -77.9965017736,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4996455,
        store_longitude: -78.0610364,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.277792,
        store_longitude: -78.619728,
    },
    {
        store_latitdue: -1.007921,
        store_longitude: -77.812879,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4848442,
        store_longitude: -78.084151,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.9794533,
        store_longitude: -77.8518183,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.983536,
        store_longitude: -77.8109,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.3207052,
        store_longitude: -77.793668,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.00786292,
        store_longitude: -77.8131179,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.997687,
        store_longitude: -77.8164,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.958561,
        store_longitude: -77.8155,
    },
    {
        store_latitdue: -0.332575,
        store_longitude: -77.814771,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -1.482805,
        store_longitude: -77.9992883,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.480354,
        store_longitude: -78.001534,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4875679,
        store_longitude: -77.9943063,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5175171,
        store_longitude: -77.9979778,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -0.952384,
        store_longitude: -77.8163201,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.49641,
        store_longitude: -77.9977,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4906767,
        store_longitude: -77.9990151,
        neighborhood: 'PUYO',
    },

    {
        store_latitdue: -1.48836,
        store_longitude: -78.00772,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.4976526,
        store_longitude: -78.0213469,
        neighborhood: 'PUYO',
    },

    {
        store_latitdue: -1.4909243,
        store_longitude: -78.0055095,
        neighborhood: 'PUYO',
    },

    {
        store_latitdue: -1.4858953,
        store_longitude: -77.9975445,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.0022252,
        store_longitude: -77.8140963,
    },
    {
        store_latitdue: -1.46055126,
        store_longitude: -78.1146392,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.4963408253,
        store_longitude: -78.0659231556,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -0.898283,
        store_longitude: -77.8000691,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.4623041,
        store_longitude: -78.1093397,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.4819183,
        store_longitude: -77.9997856,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9941452,
        store_longitude: -77.80878,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.4978377,
        store_longitude: -78.06507,
        neighborhood: 'SHELL',
    },

    {
        store_latitdue: -1.4872641,
        store_longitude: -77.9939585,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.9179944528,
        store_longitude: -77.8122291031,
        neighborhood: 'ARCHIDONA',
    },

    {
        store_latitdue: -0.968026,
        store_longitude: -77.8561,
        neighborhood: 'TENA',
    },

    {
        store_latitdue: -0.9965972,
        store_longitude: -77.8098462,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.47957,
        store_longitude: -77.999,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.5148688,
        store_longitude: -78.0074866,
        neighborhood: 'TARQUI',
    },
    {
        store_latitdue: -1.49905,
        store_longitude: -78.0137,
    },

    {
        store_latitdue: -1.4571244355,
        store_longitude: -78.110192226,
        neighborhood: 'MERA',
    },
    {
        store_latitdue: -1.474292636,
        store_longitude: -78.00020599,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -1.4925429,
        store_longitude: -78.014218,
        neighborhood: 'PUYO',
    },

    {
        store_latitdue: -1.49805,
        store_longitude: -77.9985,
    },
    {
        store_latitdue: -0.909375,
        store_longitude: -77.809883,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.00157,
        store_longitude: -77.8116,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.99869072,
        store_longitude: -77.813301,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -0.291561,
        store_longitude: -78.4370487,
    },
    {
        store_latitdue: -0.9090137,
        store_longitude: -77.802687,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.51732,
        store_longitude: -78.058,
        neighborhood: 'SHELL',
    },
    {
        store_latitdue: -1.02586,
        store_longitude: -77.803,
        neighborhood: 'TENA',
    },

    {
        store_latitdue: -0.909316,
        store_longitude: -77.8111,
        neighborhood: 'ARCHIDONA',
    },
    {
        store_latitdue: -1.5113081,
        store_longitude: -78.0089301,
        neighborhood: 'TARQUI',
    },

    {
        store_latitdue: -0.9993057,
        store_longitude: -77.812029,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: -1.0039,
        store_longitude: -77.8138,
        neighborhood: 'TENA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.307283,
        store_longitude: -78.529,
    },
    {
        store_latitdue: -0.2974062,
        store_longitude: -78.5196878,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.285524,
        store_longitude: -78.5308,
    },
    {
        store_latitdue: -0.3147116,
        store_longitude: -78.5274551,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2718171,
        store_longitude: -78.5294435,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2912532,
        store_longitude: -78.562947,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.248034,
        store_longitude: -78.5247,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24767,
        store_longitude: -78.5221784,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.298291,
        store_longitude: -78.5247,
    },
    {
        store_latitdue: -0.28644,
        store_longitude: -78.5313,
    },
    {
        store_latitdue: -0.29855,
        store_longitude: -78.5244,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2995403,
        store_longitude: -78.5323715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.283226,
        store_longitude: -78.5367,
    },
    {
        store_latitdue: -0.3324359,
        store_longitude: -78.5447544,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.315779,
        store_longitude: -78.550523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.275387,
        store_longitude: -78.5212,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3044468,
        store_longitude: -78.5319956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3292464,
        store_longitude: -78.550101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3298749,
        store_longitude: -78.5414555,
    },
    {
        store_latitdue: -0.294381,
        store_longitude: -78.533,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2929899,
        store_longitude: -78.5191461,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27643,
        store_longitude: -78.5203638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3172834,
        store_longitude: -78.5453975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260593,
        store_longitude: -78.52175,
    },
    {
        store_latitdue: -0.3008982,
        store_longitude: -78.5385915,
    },
    {
        store_latitdue: -0.3279875,
        store_longitude: -78.5420416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3158134,
        store_longitude: -78.5507303,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3287063,
        store_longitude: -78.5443724,
    },
    {
        store_latitdue: -0.3012911,
        store_longitude: -78.5231868,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2742508,
        store_longitude: -78.5227846,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.289382,
        store_longitude: -78.4728,
    },
    {
        store_latitdue: -0.274637,
        store_longitude: -78.539309,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2743807,
        store_longitude: -78.5383626,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307205,
        store_longitude: -78.523345,
    },
    {
        store_latitdue: -0.289959,
        store_longitude: -78.543966,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2891449,
        store_longitude: -78.543274,
    },
    {
        store_latitdue: -0.287836,
        store_longitude: -78.54282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.282989,
        store_longitude: -78.536555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280209,
        store_longitude: -78.541947,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2799813,
        store_longitude: -78.5415465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277895,
        store_longitude: -78.540797,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2804191,
        store_longitude: -78.5420966,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27602,
        store_longitude: -78.533991,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311607,
        store_longitude: -78.5522416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.274342,
        store_longitude: -78.5343,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2754984,
        store_longitude: -78.5368268,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307535,
        store_longitude: -78.524,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.297889,
        store_longitude: -78.5246173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3064648,
        store_longitude: -78.5269471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306351,
        store_longitude: -78.5261,
    },
    {
        store_latitdue: -0.307168,
        store_longitude: -78.523764,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3061031,
        store_longitude: -78.5227511,
    },
    {
        store_latitdue: -0.28885,
        store_longitude: -78.527903,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286205,
        store_longitude: -78.5293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2904316,
        store_longitude: -78.527744,
    },
    {
        store_latitdue: -0.291925,
        store_longitude: -78.52192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2910788,
        store_longitude: -78.5280568,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2887712,
        store_longitude: -78.5269283,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2915784,
        store_longitude: -78.523304,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271608,
        store_longitude: -78.520549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2887369,
        store_longitude: -78.5309549,
    },
    {
        store_latitdue: -0.288236,
        store_longitude: -78.5309,
    },
    {
        store_latitdue: -0.28563,
        store_longitude: -78.531638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.284444,
        store_longitude: -78.531,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.285877,
        store_longitude: -78.534152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2856638,
        store_longitude: -78.5327746,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.328,
        store_longitude: -78.538761,
    },
    {
        store_latitdue: -0.287427,
        store_longitude: -78.536966,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.332192,
        store_longitude: -78.543512,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.298142,
        store_longitude: -78.545613,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.297732,
        store_longitude: -78.5470945,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2955594,
        store_longitude: -78.5444942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3124203,
        store_longitude: -78.5463237,
    },
    {
        store_latitdue: -0.3189501,
        store_longitude: -78.5341263,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.332406,
        store_longitude: -78.5569,
    },
    {
        store_latitdue: -0.272288,
        store_longitude: -78.523591,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272521,
        store_longitude: -78.526206,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3010376,
        store_longitude: -78.5396443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.298098,
        store_longitude: -78.539479,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254508,
        store_longitude: -78.5177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25447,
        store_longitude: -78.51803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25531,
        store_longitude: -78.51749,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2948285,
        store_longitude: -78.5319477,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260167,
        store_longitude: -78.520405,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318579,
        store_longitude: -78.5503567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3184345,
        store_longitude: -78.551912,
    },
    {
        store_latitdue: -0.2638991,
        store_longitude: -78.520887,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2638787,
        store_longitude: -78.5240843,
    },
    {
        store_latitdue: -0.2641022,
        store_longitude: -78.5237985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264043,
        store_longitude: -78.520165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.315549,
        store_longitude: -78.552492,
    },
    {
        store_latitdue: -0.325497,
        store_longitude: -78.5491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.261872,
        store_longitude: -78.520415,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261833,
        store_longitude: -78.519931,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2623169,
        store_longitude: -78.520032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25802,
        store_longitude: -78.5158,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260732,
        store_longitude: -78.519363,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3169534,
        store_longitude: -78.5349497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318969,
        store_longitude: -78.5353,
    },
    {
        store_latitdue: -0.3193284,
        store_longitude: -78.5340745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262085,
        store_longitude: -78.516725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261587,
        store_longitude: -78.517167,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250575,
        store_longitude: -78.502306,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2618119,
        store_longitude: -78.516668,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262944,
        store_longitude: -78.517859,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.328606,
        store_longitude: -78.5358577,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.274754,
        store_longitude: -78.527116,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.275034,
        store_longitude: -78.527365,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.256826,
        store_longitude: -78.511733,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2563564,
        store_longitude: -78.5110908,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255564,
        store_longitude: -78.512135,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254767,
        store_longitude: -78.512,
    },
    {
        store_latitdue: -0.2549975,
        store_longitude: -78.5134034,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3159179,
        store_longitude: -78.5494923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27317,
        store_longitude: -78.51628,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308074,
        store_longitude: -78.542242,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307303,
        store_longitude: -78.542401,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3060847,
        store_longitude: -78.540437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3042388,
        store_longitude: -78.5409358,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3294362,
        store_longitude: -78.5324317,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.298137,
        store_longitude: -78.5397043,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.301656,
        store_longitude: -78.5398,
    },
    {
        store_latitdue: -0.299747,
        store_longitude: -78.533681,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3004491,
        store_longitude: -78.532269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.297664,
        store_longitude: -78.5324101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2968486,
        store_longitude: -78.5318802,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3183094,
        store_longitude: -78.5524185,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2936764,
        store_longitude: -78.5332987,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2944439,
        store_longitude: -78.5319245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3059084,
        store_longitude: -78.5485658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3075867,
        store_longitude: -78.5468967,
    },
    {
        store_latitdue: -0.307278,
        store_longitude: -78.548074,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3087718,
        store_longitude: -78.5461206,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3076906,
        store_longitude: -78.5461536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3071373,
        store_longitude: -78.5488164,
    },
    {
        store_latitdue: -0.3061956,
        store_longitude: -78.5446632,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304687,
        store_longitude: -78.5488715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308782,
        store_longitude: -78.547759,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327987,
        store_longitude: -78.5389986,
    },
    {
        store_latitdue: -0.295981,
        store_longitude: -78.532447,
    },
    {
        store_latitdue: -0.363385,
        store_longitude: -78.548721,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.328697,
        store_longitude: -78.535731,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.362354,
        store_longitude: -78.5475,
    },
    {
        store_latitdue: -0.36405,
        store_longitude: -78.547343,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3069646,
        store_longitude: -78.5433262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.265403,
        store_longitude: -78.513246,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259958,
        store_longitude: -78.50749999,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261182,
        store_longitude: -78.512936,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25721,
        store_longitude: -78.51601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2948153,
        store_longitude: -78.5668404,
    },
    {
        store_latitdue: -0.277719,
        store_longitude: -78.528321,
    },
    {
        store_latitdue: -0.279449,
        store_longitude: -78.523628,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.277806,
        store_longitude: -78.522747,
    },
    {
        store_latitdue: -0.2793461,
        store_longitude: -78.5217411,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26471,
        store_longitude: -78.5181,
    },
    {
        store_latitdue: -0.2646229,
        store_longitude: -78.518181,
    },
    {
        store_latitdue: -0.259392,
        store_longitude: -78.5062,
    },
    {
        store_latitdue: -0.246741,
        store_longitude: -78.522177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.247559,
        store_longitude: -78.52024,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246521,
        store_longitude: -78.5218,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.250079,
        store_longitude: -78.527162,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2501807,
        store_longitude: -78.527072,
    },
    {
        store_latitdue: -0.25094,
        store_longitude: -78.528836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252083,
        store_longitude: -78.510802,
    },
    {
        store_latitdue: -0.251508,
        store_longitude: -78.511655,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253206,
        store_longitude: -78.5141,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.95983,
        store_longitude: -78.9876,
    },
    {
        store_latitdue: -0.245843,
        store_longitude: -78.513537,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2610235,
        store_longitude: -78.5285386,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2614547,
        store_longitude: -78.5280792,
    },
    {
        store_latitdue: -0.2804061,
        store_longitude: -78.5174093,
    },
    {
        store_latitdue: -0.286376,
        store_longitude: -78.52076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.283003,
        store_longitude: -78.526229,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2828409,
        store_longitude: -78.5263425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.285781,
        store_longitude: -78.520298,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2519716024,
        store_longitude: -78.5148862749,
    },
    {
        store_latitdue: -0.25051,
        store_longitude: -78.5143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2484506,
        store_longitude: -78.5134163,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25119,
        store_longitude: -78.51585,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25097,
        store_longitude: -78.5161883,
    },
    {
        store_latitdue: -0.24933,
        store_longitude: -78.51588,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268071,
        store_longitude: -78.527128,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268658,
        store_longitude: -78.528356,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.248323,
        store_longitude: -78.5048,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2497534,
        store_longitude: -78.511902,
    },
    {
        store_latitdue: -0.24807,
        store_longitude: -78.51644,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.250791,
        store_longitude: -78.5199,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251097,
        store_longitude: -78.519323,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.25045,
        store_longitude: -78.51469,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2490673,
        store_longitude: -78.5145847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.291149,
        store_longitude: -78.520811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27364,
        store_longitude: -78.51315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.331343,
        store_longitude: -78.5431,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.31752,
        store_longitude: -78.548288,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.330531,
        store_longitude: -78.532625,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3334754,
        store_longitude: -78.5298799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322561,
        store_longitude: -78.536914,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.326339,
        store_longitude: -78.541858,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.326567,
        store_longitude: -78.541256,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.30559,
        store_longitude: -78.548001,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327602,
        store_longitude: -78.548851,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3278566,
        store_longitude: -78.5469313,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3126688,
        store_longitude: -78.5334893,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278729,
        store_longitude: -78.52819,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278963,
        store_longitude: -78.523395,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27327,
        store_longitude: -78.5183,
    },
    {
        store_latitdue: -0.27538,
        store_longitude: -78.52122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.333853,
        store_longitude: -78.541905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2831332,
        store_longitude: -78.5414978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.275111,
        store_longitude: -78.532934,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2556496,
        store_longitude: -78.5192348,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2837343,
        store_longitude: -78.5320969,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3044825,
        store_longitude: -78.5479558,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3227136,
        store_longitude: -78.5362288,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.257534,
        store_longitude: -78.514623,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.278706,
        store_longitude: -78.523138,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27361,
        store_longitude: -78.51916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3148538,
        store_longitude: -78.5355678,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256345,
        store_longitude: -78.512971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.273181,
        store_longitude: -78.521616,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3871331,
        store_longitude: -78.5356984,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.25059,
        store_longitude: -78.5292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2898107,
        store_longitude: -78.5359147,
    },
    {
        store_latitdue: -0.332638,
        store_longitude: -78.546475,
    },
    {
        store_latitdue: -0.2541788,
        store_longitude: -78.5191176,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2721301,
        store_longitude: -78.5148682,
    },
    {
        store_latitdue: -0.277069,
        store_longitude: -78.522269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3044572,
        store_longitude: -78.5480903,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.330082,
        store_longitude: -78.5483,
    },
    {
        store_latitdue: -0.2939071,
        store_longitude: -78.5440024,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3088466,
        store_longitude: -78.5233977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.300335,
        store_longitude: -78.5387,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255797,
        store_longitude: -78.513712,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3118819,
        store_longitude: -78.5256227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.332827,
        store_longitude: -78.531487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.295001,
        store_longitude: -78.5311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2758388,
        store_longitude: -78.5344027,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255609,
        store_longitude: -78.5045,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25453,
        store_longitude: -78.510287,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2881954,
        store_longitude: -78.5427512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2035626,
        store_longitude: -78.482819,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.099042,
        store_longitude: -78.531814,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263581,
        store_longitude: -78.514969,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265419,
        store_longitude: -78.517345,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2486474,
        store_longitude: -78.5279346,
    },
    {
        store_latitdue: -0.254976,
        store_longitude: -78.5211,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250373,
        store_longitude: -78.525045,
    },
    {
        store_latitdue: -0.300329,
        store_longitude: -78.539111,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260479,
        store_longitude: -78.5136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3192485,
        store_longitude: -78.5495356,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307207,
        store_longitude: -78.549062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254406,
        store_longitude: -78.514121,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3323253,
        store_longitude: -78.5477897,
    },
    {
        store_latitdue: -0.2918339,
        store_longitude: -78.5323988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.335304,
        store_longitude: -78.542692,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258968,
        store_longitude: -78.525474,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261581,
        store_longitude: -78.528985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25698,
        store_longitude: -78.51827,
    },
    {
        store_latitdue: -0.292244,
        store_longitude: -78.5209,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2786927,
        store_longitude: -78.5158376,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.303688,
        store_longitude: -78.5413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.335371,
        store_longitude: -78.5412,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3176171,
        store_longitude: -78.5476896,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25707,
        store_longitude: -78.51923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.363033,
        store_longitude: -78.5483,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.306177,
        store_longitude: -78.5356,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266396,
        store_longitude: -78.528254,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327938,
        store_longitude: -78.5533,
    },
    {
        store_latitdue: -0.297087,
        store_longitude: -78.5338,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296663,
        store_longitude: -78.546566,
    },
    {
        store_latitdue: -0.280612,
        store_longitude: -78.5406,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.274624,
        store_longitude: -78.538346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268166,
        store_longitude: -78.52669,
    },
    {
        store_latitdue: -0.2860973,
        store_longitude: -78.5346233,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.317617,
        store_longitude: -78.5479,
    },
    {
        store_latitdue: -0.278544,
        store_longitude: -78.523126,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3120486,
        store_longitude: -78.5483318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276509,
        store_longitude: -78.524579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292119,
        store_longitude: -78.523541,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3187951,
        store_longitude: -78.5524761,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327891,
        store_longitude: -78.547244,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2875302,
        store_longitude: -78.5376441,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305719,
        store_longitude: -78.5422,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264104,
        store_longitude: -78.5152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304683,
        store_longitude: -78.5334456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.304209,
        store_longitude: -78.541112,
    },
    {
        store_latitdue: -0.327049,
        store_longitude: -78.535949,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2780514,
        store_longitude: -78.5159345,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280457,
        store_longitude: -78.540955,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2904791,
        store_longitude: -78.5278697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264212,
        store_longitude: -78.517795,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2948805,
        store_longitude: -78.5201174,
    },
    {
        store_latitdue: -0.326247,
        store_longitude: -78.539047,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25121147,
        store_longitude: -78.5119378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3048333,
        store_longitude: -78.5640862,
    },
    {
        store_latitdue: -0.302898,
        store_longitude: -78.538542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2785729,
        store_longitude: -78.5231744,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3071992,
        store_longitude: -78.5365007,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2728922,
        store_longitude: -78.5238721,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338443,
        store_longitude: -78.544494,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3084714,
        store_longitude: -78.5290881,
    },
    {
        store_latitdue: -0.3321596,
        store_longitude: -78.5456876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2457274,
        store_longitude: -78.5137173,
    },
    {
        store_latitdue: -0.257575,
        store_longitude: -78.5115367,
    },
    {
        store_latitdue: -0.30678,
        store_longitude: -78.5425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3176299,
        store_longitude: -78.5474296,
    },
    {
        store_latitdue: -0.272705,
        store_longitude: -78.5241,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3186872,
        store_longitude: -78.5370765,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.216664,
        store_longitude: -78.492383,
    },
    {
        store_latitdue: -0.327128,
        store_longitude: -78.5338,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.294633,
        store_longitude: -78.51857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.311324,
        store_longitude: -78.54383,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.295836,
        store_longitude: -78.518863,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3292975,
        store_longitude: -78.550355,
    },
    {
        store_latitdue: -0.307324,
        store_longitude: -78.547918,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318274,
        store_longitude: -78.5525544,
    },
    {
        store_latitdue: -0.2649791,
        store_longitude: -78.5210174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2773893,
        store_longitude: -78.5215987,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.265517,
        store_longitude: -78.527463,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.272077,
        store_longitude: -78.52954,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2793618,
        store_longitude: -78.5423917,
    },
    {
        store_latitdue: -0.2585806,
        store_longitude: -78.5060697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2990215,
        store_longitude: -78.5321434,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.271681,
        store_longitude: -78.530327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269348,
        store_longitude: -78.526375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263881,
        store_longitude: -78.5238,
    },
    {
        store_latitdue: -0.2793767,
        store_longitude: -78.5215451,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261923,
        store_longitude: -78.522405,
    },
    {
        store_latitdue: -0.2880251,
        store_longitude: -78.5295466,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293965,
        store_longitude: -78.544,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.253917,
        store_longitude: -78.50194,
    },
    {
        store_latitdue: -0.26524,
        store_longitude: -78.520831,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2846835,
        store_longitude: -78.5415014,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3233867,
        store_longitude: -78.5508985,
    },
    {
        store_latitdue: -0.254821,
        store_longitude: -78.516507,
    },
    {
        store_latitdue: -0.334022,
        store_longitude: -78.546,
    },
    {
        store_latitdue: -0.261204,
        store_longitude: -78.514749,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.307309,
        store_longitude: -78.55,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.310281,
        store_longitude: -78.541473,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.33017273,
        store_longitude: -78.5459425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280122,
        store_longitude: -78.542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2621608,
        store_longitude: -78.5281769,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2767958529,
        store_longitude: -78.5150894523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2867055,
        store_longitude: -78.5182378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327273,
        store_longitude: -78.537759,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249708,
        store_longitude: -78.5293,
    },
    {
        store_latitdue: -0.2771985,
        store_longitude: -78.5166372,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.280064,
        store_longitude: -78.5414,
    },
    {
        store_latitdue: -0.2738213,
        store_longitude: -78.5194056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296716,
        store_longitude: -78.5324012,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276095,
        store_longitude: -78.525668,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263298,
        store_longitude: -78.520816,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3173085,
        store_longitude: -78.5343582,
    },
    {
        store_latitdue: -0.297677,
        store_longitude: -78.539491,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2485759,
        store_longitude: -78.5112502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.280169,
        store_longitude: -78.5414,
    },
    {
        store_latitdue: -0.2793503,
        store_longitude: -78.5348167,
    },
    {
        store_latitdue: -0.315113,
        store_longitude: -78.550162,
    },
    {
        store_latitdue: -0.2539102,
        store_longitude: -78.5279467,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2828876,
        store_longitude: -78.5264849,
    },
    {
        store_latitdue: -0.3048425,
        store_longitude: -78.5226924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2847317,
        store_longitude: -78.5386006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2533049,
        store_longitude: -78.5139667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306708,
        store_longitude: -78.541394,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.317594,
        store_longitude: -78.5479,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2883779,
        store_longitude: -78.5421656,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2625309,
        store_longitude: -78.5289742,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3068724,
        store_longitude: -78.5331511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3666538,
        store_longitude: -78.5459135,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.280469,
        store_longitude: -78.540867,
    },
    {
        store_latitdue: -0.2473899,
        store_longitude: -78.5181688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.332308,
        store_longitude: -78.528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3263429,
        store_longitude: -78.5398379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293583,
        store_longitude: -78.52403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2635144,
        store_longitude: -78.5106597,
    },
    {
        store_latitdue: -0.2723575,
        store_longitude: -78.529321,
    },
    {
        store_latitdue: -0.265614,
        store_longitude: -78.521593,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.328251,
        store_longitude: -78.5377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249802,
        store_longitude: -78.525369,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.299794,
        store_longitude: -78.524521,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25397,
        store_longitude: -78.51949,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2748317,
        store_longitude: -78.538404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3072582,
        store_longitude: -78.5366766,
    },
    {
        store_latitdue: -0.272534,
        store_longitude: -78.521768,
    },
    {
        store_latitdue: -0.257003,
        store_longitude: -78.5183,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2758796,
        store_longitude: -78.5361174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.303585,
        store_longitude: -78.533568,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3063206,
        store_longitude: -78.5413392,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271924,
        store_longitude: -78.5237,
    },
    {
        store_latitdue: -0.260247,
        store_longitude: -78.528114,
    },
    {
        store_latitdue: -0.2877371,
        store_longitude: -78.5176119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3189003,
        store_longitude: -78.551408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306284,
        store_longitude: -78.5327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254115,
        store_longitude: -78.515377,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.330813,
        store_longitude: -78.532703,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.270815,
        store_longitude: -78.5231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.31842401,
        store_longitude: -78.5525131,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28046,
        store_longitude: -78.51562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.253716,
        store_longitude: -78.513528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24585,
        store_longitude: -78.5192,
    },
    {
        store_latitdue: -0.277965,
        store_longitude: -78.52886,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.305714,
        store_longitude: -78.546752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2550475,
        store_longitude: -78.5260809,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.31589,
        store_longitude: -78.5493,
    },
    {
        store_latitdue: -0.283357,
        store_longitude: -78.532374,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3100126,
        store_longitude: -78.5486054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2982208,
        store_longitude: -78.5481885,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2686517527,
        store_longitude: -78.5255903006,
    },
    {
        store_latitdue: -0.327273,
        store_longitude: -78.537759,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.332119,
        store_longitude: -78.5351,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3047802,
        store_longitude: -78.5221328,
    },
    {
        store_latitdue: -0.36402,
        store_longitude: -78.5461,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.373086,
        store_longitude: -78.550277,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.2629694,
        store_longitude: -78.513897,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288925,
        store_longitude: -78.5432,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3285421,
        store_longitude: -78.5423666,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3268087,
        store_longitude: -78.5532198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3154671,
        store_longitude: -78.5532722,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.302413,
        store_longitude: -78.54,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.328647,
        store_longitude: -78.550733,
    },
    {
        store_latitdue: -0.244968,
        store_longitude: -78.518253,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277342,
        store_longitude: -78.528626,
    },
    {
        store_latitdue: -0.3073942,
        store_longitude: -78.5474089,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.299524,
        store_longitude: -78.5482,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2474116,
        store_longitude: -78.5219703,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2950311,
        store_longitude: -78.5341249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.301963,
        store_longitude: -78.531346,
    },
    {
        store_latitdue: -0.3624992,
        store_longitude: -78.5483135,
    },
    {
        store_latitdue: -0.377422,
        store_longitude: -78.5522445,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.339553,
        store_longitude: -78.5404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.332807,
        store_longitude: -78.5448334,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2869939,
        store_longitude: -78.5348215,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3161886,
        store_longitude: -78.5451218,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2527207,
        store_longitude: -78.5105103,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2460754,
        store_longitude: -78.5134729,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3354383,
        store_longitude: -78.5397836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3282927,
        store_longitude: -78.5362814,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2995608,
        store_longitude: -78.548424,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3185289,
        store_longitude: -78.5505715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3071429,
        store_longitude: -78.5312701,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.30254,
        store_longitude: -78.5226,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250234,
        store_longitude: -78.5292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.288278,
        store_longitude: -78.544124,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2562884,
        store_longitude: -78.5283586,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2509701,
        store_longitude: -78.5269238,
    },
    {
        store_latitdue: -0.311782,
        store_longitude: -78.544541,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3630521,
        store_longitude: -78.5487714,
    },
    {
        store_latitdue: -0.254833,
        store_longitude: -78.515392,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.332365,
        store_longitude: -78.5481,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.247631,
        store_longitude: -78.514,
    },
    {
        store_latitdue: -0.300525,
        store_longitude: -78.5262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2621904,
        store_longitude: -78.5130762,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270748,
        store_longitude: -78.5231,
    },
    {
        store_latitdue: 0.2286457,
        store_longitude: -78.2652662,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: -0.2782633,
        store_longitude: -78.524787,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.275104,
        store_longitude: -78.5275,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.315869,
        store_longitude: -78.5496,
    },
    {
        store_latitdue: -0.279589,
        store_longitude: -78.5422,
    },
    {
        store_latitdue: -0.3407404,
        store_longitude: -78.541402,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.252379,
        store_longitude: -78.52755,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311044,
        store_longitude: -78.542299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2915124,
        store_longitude: -78.5234327,
    },
    {
        store_latitdue: -0.31205,
        store_longitude: -78.5419,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252428,
        store_longitude: -78.527992,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279802,
        store_longitude: -78.541732,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2906369,
        store_longitude: -78.5443715,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.24798,
        store_longitude: -78.5164,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303424,
        store_longitude: -78.543226,
    },
    {
        store_latitdue: -0.2999427,
        store_longitude: -78.5261845,
    },
    {
        store_latitdue: -0.2512101,
        store_longitude: -78.526797,
    },
    {
        store_latitdue: -0.264467,
        store_longitude: -78.512311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2513521,
        store_longitude: -78.5072641,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3143551,
        store_longitude: -78.5360552,
    },
    {
        store_latitdue: -0.263396,
        store_longitude: -78.5249,
    },
    {
        store_latitdue: -0.260341,
        store_longitude: -78.512629,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.34204,
        store_longitude: -78.541364,
    },
    {
        store_latitdue: -0.2796824,
        store_longitude: -78.5210341,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270766621,
        store_longitude: -78.52603772,
    },
    {
        store_latitdue: -0.299952,
        store_longitude: -78.5395,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3295398,
        store_longitude: -78.5307208,
    },
    {
        store_latitdue: -0.318791,
        store_longitude: -78.5369,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.327658,
        store_longitude: -78.548635,
    },
    {
        store_latitdue: -0.373264,
        store_longitude: -78.5495,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1391168,
        store_longitude: -78.4967565,
    },
    {
        store_latitdue: -0.3250045,
        store_longitude: -78.5514364,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3384877,
        store_longitude: -78.5429902,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.34977,
        store_longitude: -78.548957,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3742894,
        store_longitude: -78.550461,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.320648,
        store_longitude: -78.5503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2569337,
        store_longitude: -78.521462,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255298,
        store_longitude: -78.5214,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2549346,
        store_longitude: -78.5181145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254257,
        store_longitude: -78.5101,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2493786,
        store_longitude: -78.5059114,
    },
    {
        store_latitdue: -0.2527708,
        store_longitude: -78.510247,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257908,
        store_longitude: -78.5178,
    },
    {
        store_latitdue: -0.264269,
        store_longitude: -78.5107,
    },
    {
        store_latitdue: -0.2499936,
        store_longitude: -78.5156638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.247645,
        store_longitude: -78.5122,
    },
    {
        store_latitdue: -0.245437,
        store_longitude: -78.5134,
    },
    {
        store_latitdue: -0.2926993,
        store_longitude: -78.517743,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2558619,
        store_longitude: -78.5177155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.265487,
        store_longitude: -78.527446,
    },
    {
        store_latitdue: -0.265363,
        store_longitude: -78.5145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2543064,
        store_longitude: -78.5300558,
    },
    {
        store_latitdue: -0.290467,
        store_longitude: -78.545041,
    },
    {
        store_latitdue: -0.251333,
        store_longitude: -78.526756,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.260946,
        store_longitude: -78.5287,
    },
    {
        store_latitdue: -0.2957987,
        store_longitude: -78.54625,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2801758,
        store_longitude: -78.5412761,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.334932,
        store_longitude: -78.551343,
    },
    {
        store_latitdue: -0.2887091,
        store_longitude: -78.5435955,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2981312,
        store_longitude: -78.5479876,
    },
    {
        store_latitdue: -0.2983901,
        store_longitude: -78.5470195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.287148,
        store_longitude: -78.5439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286022,
        store_longitude: -78.539234,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278011,
        store_longitude: -78.540624,
    },
    {
        store_latitdue: -0.2863527,
        store_longitude: -78.5381502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2873215,
        store_longitude: -78.5435168,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.288218,
        store_longitude: -78.527,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2990105,
        store_longitude: -78.5317308,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2989491,
        store_longitude: -78.5316969,
    },
    {
        store_latitdue: -0.286914,
        store_longitude: -78.5284,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.288323,
        store_longitude: -78.5291,
    },
    {
        store_latitdue: -0.2754081,
        store_longitude: -78.5278926,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28647,
        store_longitude: -78.5313,
    },
    {
        store_latitdue: -0.307443,
        store_longitude: -78.547638,
    },
    {
        store_latitdue: -0.271106,
        store_longitude: -78.523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2799081,
        store_longitude: -78.5249367,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27805,
        store_longitude: -78.524684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278607,
        store_longitude: -78.5164,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327368,
        store_longitude: -78.5329,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.332718,
        store_longitude: -78.423873,
    },
    {
        store_latitdue: -0.332144,
        store_longitude: -78.5255,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305408,
        store_longitude: -78.542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3283209,
        store_longitude: -78.5340462,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32911,
        store_longitude: -78.530986,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311782,
        store_longitude: -78.544541,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3343624,
        store_longitude: -78.5283607,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3339873,
        store_longitude: -78.5269069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.319079,
        store_longitude: -78.5344,
    },
    {
        store_latitdue: -0.330662,
        store_longitude: -78.5253,
    },
    {
        store_latitdue: -0.335387,
        store_longitude: -78.54149,
    },
    {
        store_latitdue: -0.3382557,
        store_longitude: -78.5485195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3154432,
        store_longitude: -78.5529898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3183078,
        store_longitude: -78.5493133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3269038,
        store_longitude: -78.5504789,
    },
    {
        store_latitdue: -0.3780492,
        store_longitude: -78.5494828,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.255416,
        store_longitude: -78.504568,
    },
    {
        store_latitdue: -0.263124,
        store_longitude: -78.5105,
    },
    {
        store_latitdue: -0.2649034,
        store_longitude: -78.5150453,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2616878,
        store_longitude: -78.5165533,
    },
    {
        store_latitdue: -0.3180928,
        store_longitude: -78.5548614,
    },
    {
        store_latitdue: -0.2649635,
        store_longitude: -78.521317,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264061,
        store_longitude: -78.5235,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263957,
        store_longitude: -78.52378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2573541,
        store_longitude: -78.5167518,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2474791,
        store_longitude: -78.5070772,
    },
    {
        store_latitdue: -0.25873,
        store_longitude: -78.51764,
    },
    {
        store_latitdue: -0.256496585,
        store_longitude: -78.5215183965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25185,
        store_longitude: -78.520085,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249859,
        store_longitude: -78.5073,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.249491,
        store_longitude: -78.524117,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263552,
        store_longitude: -78.525,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255664,
        store_longitude: -78.5301,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279275,
        store_longitude: -78.5401,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3074057,
        store_longitude: -78.5476458,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2806206,
        store_longitude: -78.5406897,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3040881,
        store_longitude: -78.54146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3082306,
        store_longitude: -78.54174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2747351,
        store_longitude: -78.5333777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296602,
        store_longitude: -78.5338,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2907163,
        store_longitude: -78.5365368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2888237,
        store_longitude: -78.5308728,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2914135,
        store_longitude: -78.5205993,
    },
    {
        store_latitdue: -0.293085,
        store_longitude: -78.5268,
    },
    {
        store_latitdue: -0.279993,
        store_longitude: -78.5238763,
    },
    {
        store_latitdue: -0.278849,
        store_longitude: -78.5165,
    },
    {
        store_latitdue: -0.3046144,
        store_longitude: -78.5400944,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.339768,
        store_longitude: -78.5294,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3131994,
        store_longitude: -78.5243108,
    },
    {
        store_latitdue: -0.2935582,
        store_longitude: -78.5374499,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2918189,
        store_longitude: -78.5367888,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322044,
        store_longitude: -78.562745,
    },
    {
        store_latitdue: -0.3007226,
        store_longitude: -78.5239625,
    },
    {
        store_latitdue: -0.315713,
        store_longitude: -78.5362,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2417624,
        store_longitude: -78.5279779,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.326723,
        store_longitude: -78.53661,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3125864,
        store_longitude: -78.535928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3130244,
        store_longitude: -78.5258148,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3294393,
        store_longitude: -78.5303218,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3142414,
        store_longitude: -78.527757,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3295718,
        store_longitude: -78.5532672,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.377559,
        store_longitude: -78.55221,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3653314,
        store_longitude: -78.5488575,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3140021,
        store_longitude: -78.5500145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322412,
        store_longitude: -78.5517,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2438135,
        store_longitude: -78.5129321,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251496,
        store_longitude: -78.5211,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2548897,
        store_longitude: -78.5152011,
    },
    {
        store_latitdue: -0.255762,
        store_longitude: -78.504483,
    },
    {
        store_latitdue: -0.263272,
        store_longitude: -78.5234,
    },
    {
        store_latitdue: -0.265835,
        store_longitude: -78.511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256002,
        store_longitude: -78.504496,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252335,
        store_longitude: -78.506227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260342,
        store_longitude: -78.5136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264813,
        store_longitude: -78.5217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252679,
        store_longitude: -78.5152,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.25368,
        store_longitude: -78.51905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2502753735,
        store_longitude: -78.5081253052,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2684688,
        store_longitude: -78.5284502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269054,
        store_longitude: -78.5405,
    },
    {
        store_latitdue: -0.251162,
        store_longitude: -78.5301,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.268331,
        store_longitude: -78.5318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2657045,
        store_longitude: -78.5318784,
    },
    {
        store_latitdue: -0.25032,
        store_longitude: -78.5249,
    },
    {
        store_latitdue: -0.297782,
        store_longitude: -78.549585,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.304112,
        store_longitude: -78.543515,
    },
    {
        store_latitdue: -0.301541,
        store_longitude: -78.520197,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292287,
        store_longitude: -78.518844,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.293454,
        store_longitude: -78.522139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29906,
        store_longitude: -78.5319,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2977844,
        store_longitude: -78.5302362,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292517,
        store_longitude: -78.520881,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.340065,
        store_longitude: -78.5294,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3079859,
        store_longitude: -78.5304018,
    },
    {
        store_latitdue: -0.3190765,
        store_longitude: -78.534045,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3050952,
        store_longitude: -78.5224456,
    },
    {
        store_latitdue: -0.313408,
        store_longitude: -78.5257,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3076838,
        store_longitude: -78.5339916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3162615,
        store_longitude: -78.5364351,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.386457,
        store_longitude: -78.535191,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.332638,
        store_longitude: -78.546475,
    },
    {
        store_latitdue: -0.3341403,
        store_longitude: -78.5478445,
    },
    {
        store_latitdue: -0.3321092,
        store_longitude: -78.5419594,
    },
    {
        store_latitdue: -0.3328431837,
        store_longitude: -78.5486200589,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25053,
        store_longitude: -78.510976,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249518,
        store_longitude: -78.51039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253635,
        store_longitude: -78.504343,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251922,
        store_longitude: -78.5151,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.27950745,
        store_longitude: -78.5402374,
    },
    {
        store_latitdue: -0.262924,
        store_longitude: -78.526237,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2757789,
        store_longitude: -78.5356079,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307392,
        store_longitude: -78.547549,
    },
    {
        store_latitdue: -0.2907498,
        store_longitude: -78.5367137,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2827599,
        store_longitude: -78.5228109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.331164,
        store_longitude: -78.5361,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322533,
        store_longitude: -78.5368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2701849,
        store_longitude: -78.5340076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3033049,
        store_longitude: -78.5412654,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3055507,
        store_longitude: -78.5483746,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.165125,
        store_longitude: -78.478089,
    },
    {
        store_latitdue: -0.3130149,
        store_longitude: -78.5465574,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.329042,
        store_longitude: -78.5396,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2630102,
        store_longitude: -78.5148111,
    },
    {
        store_latitdue: -0.256003,
        store_longitude: -78.5177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2758783981,
        store_longitude: -78.5388101551,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28261,
        store_longitude: -78.540977,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.288842,
        store_longitude: -78.5434,
    },
    {
        store_latitdue: -0.283259,
        store_longitude: -78.5414368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305951,
        store_longitude: -78.545682,
    },
    {
        store_latitdue: -0.279541,
        store_longitude: -78.542111,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28877,
        store_longitude: -78.5417,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.285864,
        store_longitude: -78.529026,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2749117,
        store_longitude: -78.52647,
    },
    {
        store_latitdue: -0.2967916,
        store_longitude: -78.5191802,
    },
    {
        store_latitdue: -0.2762137,
        store_longitude: -78.5202684,
    },
    {
        store_latitdue: -0.292169,
        store_longitude: -78.531,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.274036,
        store_longitude: -78.5129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.329129,
        store_longitude: -78.5325,
    },
    {
        store_latitdue: -0.3006095,
        store_longitude: -78.5263273,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.313561,
        store_longitude: -78.52511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.302125,
        store_longitude: -78.5418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3207178,
        store_longitude: -78.5486496,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3142819,
        store_longitude: -78.5446209,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322392,
        store_longitude: -78.5539,
    },
    {
        store_latitdue: -0.3284,
        store_longitude: -78.5415,
    },
    {
        store_latitdue: -0.3292922,
        store_longitude: -78.5493769,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.261544,
        store_longitude: -78.519161,
    },
    {
        store_latitdue: -0.252482,
        store_longitude: -78.5198,
    },
    {
        store_latitdue: -0.253123,
        store_longitude: -78.5047,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2476977,
        store_longitude: -78.5261554,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2790273,
        store_longitude: -78.5116746,
    },
    {
        store_latitdue: -0.319865,
        store_longitude: -78.5337,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.304644,
        store_longitude: -78.522508,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3563431,
        store_longitude: -78.5478535,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306632,
        store_longitude: -78.5515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.46713,
        store_longitude: -76.992,
    },
    {
        store_latitdue: -0.3140066,
        store_longitude: -78.545525,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.384157,
        store_longitude: -78.5358,
    },
    {
        store_latitdue: -0.255687,
        store_longitude: -78.509128,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257078,
        store_longitude: -78.5132141,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250457,
        store_longitude: -78.5108,
    },
    {
        store_latitdue: -0.25047,
        store_longitude: -78.51672,
    },
    {
        store_latitdue: -0.2529498,
        store_longitude: -78.513581,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2879341,
        store_longitude: -78.5315434,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3013682,
        store_longitude: -78.5201999,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.30246,
        store_longitude: -78.5424,
    },
    {
        store_latitdue: -0.263687,
        store_longitude: -78.524298,
    },
    {
        store_latitdue: -0.329889,
        store_longitude: -78.5353,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3188078,
        store_longitude: -78.5524491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3271617,
        store_longitude: -78.5505245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.335213,
        store_longitude: -78.5429,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2548897,
        store_longitude: -78.5181502,
    },
    {
        store_latitdue: -0.2581697,
        store_longitude: -78.5057502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257138,
        store_longitude: -78.504577,
    },
    {
        store_latitdue: -0.256696,
        store_longitude: -78.505013,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255393,
        store_longitude: -78.505686,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24955,
        store_longitude: -78.4899,
    },
    {
        store_latitdue: -0.2516993,
        store_longitude: -78.5063674,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2569484,
        store_longitude: -78.5192544,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.247928,
        store_longitude: -78.5092,
    },
    {
        store_latitdue: -0.281485,
        store_longitude: -78.540181,
    },
    {
        store_latitdue: -0.281911,
        store_longitude: -78.512,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2760554,
        store_longitude: -78.5260939,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.290534,
        store_longitude: -78.521699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2917687,
        store_longitude: -78.5368022,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.25248,
        store_longitude: -78.5117,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255396,
        store_longitude: -78.508155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.247829,
        store_longitude: -78.5109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.280311,
        store_longitude: -78.542148,
    },
    {
        store_latitdue: -0.3107888,
        store_longitude: -78.5469706,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3203842,
        store_longitude: -78.5321194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303189,
        store_longitude: -78.5412,
    },
    {
        store_latitdue: -0.3205711,
        store_longitude: -78.5467457,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322844,
        store_longitude: -78.545188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318534,
        store_longitude: -78.549705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307151,
        store_longitude: -78.5515482,
    },
    {
        store_latitdue: -0.3249895,
        store_longitude: -78.5509528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244753,
        store_longitude: -78.512898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250294,
        store_longitude: -78.508589,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.248522,
        store_longitude: -78.510088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.247075,
        store_longitude: -78.510348,
    },
    {
        store_latitdue: -0.271865,
        store_longitude: -78.5144,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2758028,
        store_longitude: -78.5350142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.164828,
        store_longitude: -78.3165,
    },
    {
        store_latitdue: -0.328566,
        store_longitude: -78.5330783,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262772,
        store_longitude: -78.5098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.247589,
        store_longitude: -78.51,
    },
    {
        store_latitdue: -0.3027737,
        store_longitude: -78.5441943,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280471,
        store_longitude: -78.524959,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.304758,
        store_longitude: -78.5374,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.31142908,
        store_longitude: -78.5451918,
    },
    {
        store_latitdue: -0.3226855,
        store_longitude: -78.5313865,
    },
    {
        store_latitdue: -0.3790113,
        store_longitude: -78.5459581,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.257588,
        store_longitude: -78.5092,
    },
    {
        store_latitdue: -0.26534,
        store_longitude: -78.5174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.252563,
        store_longitude: -78.511372,
    },
    {
        store_latitdue: -0.285621,
        store_longitude: -78.5178,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.302456,
        store_longitude: -78.536267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318178,
        store_longitude: -78.532991,
    },
    {
        store_latitdue: -0.3058567,
        store_longitude: -78.5357432,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3158762,
        store_longitude: -78.548781,
    },
    {
        store_latitdue: -0.2545704,
        store_longitude: -78.5180189,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.249642,
        store_longitude: -78.511371,
    },
    {
        store_latitdue: -0.284268,
        store_longitude: -78.532433,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3167751,
        store_longitude: -78.5351215,
    },
    {
        store_latitdue: -0.259634,
        store_longitude: -78.5145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3295368,
        store_longitude: -78.5307609,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3150323,
        store_longitude: -78.52686,
    },
    {
        store_latitdue: -0.2504869,
        store_longitude: -78.5410016,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.265015,
        store_longitude: -78.512915,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260139,
        store_longitude: -78.5283,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312085,
        store_longitude: -78.5463,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25189,
        store_longitude: -78.51505,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2480921,
        store_longitude: -78.5097399,
    },
    {
        store_latitdue: -0.2465922,
        store_longitude: -78.509357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281826,
        store_longitude: -78.542643,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277744,
        store_longitude: -78.531346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.273371,
        store_longitude: -78.568,
    },
    {
        store_latitdue: -0.301863,
        store_longitude: -78.5398,
    },
    {
        store_latitdue: -0.3342906,
        store_longitude: -78.5246704,
    },
    {
        store_latitdue: -0.264298,
        store_longitude: -78.5238,
    },
    {
        store_latitdue: -0.264161,
        store_longitude: -78.523293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3033851,
        store_longitude: -78.5488782,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2732426,
        store_longitude: -78.5090239,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2746935,
        store_longitude: -78.5225649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.330851,
        store_longitude: -78.5238,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2657619,
        store_longitude: -78.514015,
    },
    {
        store_latitdue: -0.315667,
        store_longitude: -78.547849,
    },
    {
        store_latitdue: -0.313699,
        store_longitude: -78.546638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.314223,
        store_longitude: -78.544552,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3178472,
        store_longitude: -78.5455471,
    },
    {
        store_latitdue: -0.332204,
        store_longitude: -78.5446,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3156265,
        store_longitude: -78.5448928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.284257,
        store_longitude: -78.541773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27409,
        store_longitude: -78.52076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2555485,
        store_longitude: -78.5177296,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.319066,
        store_longitude: -78.546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.353796,
        store_longitude: -78.5284,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.317929,
        store_longitude: -78.5493,
    },
    {
        store_latitdue: 0.911244,
        store_longitude: -79.6859,
    },
    {
        store_latitdue: -0.2619607,
        store_longitude: -78.519192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.26036,
        store_longitude: -78.520725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2559593,
        store_longitude: -78.5200867,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2590209,
        store_longitude: -78.513755,
    },
    {
        store_latitdue: -0.306402441,
        store_longitude: -78.54527575,
    },
    {
        store_latitdue: -0.3058006,
        store_longitude: -78.5467018,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276631,
        store_longitude: -78.522,
    },
    {
        store_latitdue: -0.2947076,
        store_longitude: -78.5328592,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2951395,
        store_longitude: -78.5310739,
    },
    {
        store_latitdue: -0.27541,
        store_longitude: -78.52241,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.315653,
        store_longitude: -78.5253,
    },
    {
        store_latitdue: -0.187156,
        store_longitude: -78.5167,
    },
    {
        store_latitdue: -0.301358,
        store_longitude: -78.5231802,
    },
    {
        store_latitdue: -0.38587,
        store_longitude: -78.540024,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3793504,
        store_longitude: -78.5521111,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.321039,
        store_longitude: -78.552604,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3221365,
        store_longitude: -78.5518276,
    },
    {
        store_latitdue: -0.3226685,
        store_longitude: -78.5506396,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.320791,
        store_longitude: -78.551709,
    },
    {
        store_latitdue: -0.2549483,
        store_longitude: -78.5138037,
    },
    {
        store_latitdue: -0.2486081,
        store_longitude: -78.5244552,
    },
    {
        store_latitdue: -0.272287,
        store_longitude: -78.527055,
    },
    {
        store_latitdue: -0.2703555,
        store_longitude: -78.5341645,
    },
    {
        store_latitdue: -0.3232816,
        store_longitude: -78.5313817,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3039,
        store_longitude: -78.5433,
    },
    {
        store_latitdue: -0.3780722,
        store_longitude: -78.5494055,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.375389,
        store_longitude: -78.5507,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.378311,
        store_longitude: -78.5481,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.259173,
        store_longitude: -78.5251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.289707,
        store_longitude: -78.5305,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2723966,
        store_longitude: -78.5265429,
    },
    {
        store_latitdue: -0.3164172,
        store_longitude: -78.5262346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322676,
        store_longitude: -78.5305,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.300921,
        store_longitude: -78.5234,
    },
    {
        store_latitdue: -0.300622,
        store_longitude: -78.5223,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312346,
        store_longitude: -78.555,
    },
    {
        store_latitdue: -0.3141149,
        store_longitude: -78.5345475,
    },
    {
        store_latitdue: -0.3883994,
        store_longitude: -78.5431395,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.254315494,
        store_longitude: -78.51363301,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252526,
        store_longitude: -78.515732,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.287711,
        store_longitude: -78.531403,
    },
    {
        store_latitdue: -0.275587,
        store_longitude: -78.5225,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2765444,
        store_longitude: -78.5262029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28628,
        store_longitude: -78.531144,
    },
    {
        store_latitdue: -0.3778991,
        store_longitude: -78.5472921,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.316688,
        store_longitude: -78.5442,
    },
    {
        store_latitdue: -0.3154806,
        store_longitude: -78.5449338,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327702,
        store_longitude: -78.5395714,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3353213,
        store_longitude: -78.5433463,
    },
    {
        store_latitdue: -0.273971,
        store_longitude: -78.5089,
    },
    {
        store_latitdue: -0.305176,
        store_longitude: -78.540191,
    },
    {
        store_latitdue: -0.30158,
        store_longitude: -78.5357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3421388,
        store_longitude: -78.5399874,
    },
    {
        store_latitdue: -0.253285,
        store_longitude: -78.514777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252144,
        store_longitude: -78.517095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271263,
        store_longitude: -78.518447,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3077384,
        store_longitude: -78.5340216,
    },
    {
        store_latitdue: -0.2997133,
        store_longitude: -78.5241474,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3227431,
        store_longitude: -78.551928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.359266,
        store_longitude: -78.545619,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25474,
        store_longitude: -78.4909,
    },
    {
        store_latitdue: -0.089054,
        store_longitude: -78.445716,
    },
    {
        store_latitdue: -0.246399,
        store_longitude: -78.520476,
    },
    {
        store_latitdue: -0.3005155,
        store_longitude: -78.5293796,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.298244,
        store_longitude: -78.5239,
    },
    {
        store_latitdue: -0.383621,
        store_longitude: -78.538833,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3616961,
        store_longitude: -78.5488571,
    },
    {
        store_latitdue: -0.3044926571,
        store_longitude: -78.5401154682,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.35533,
        store_longitude: -78.53701,
    },
    {
        store_latitdue: -0.251496,
        store_longitude: -78.5211,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255462,
        store_longitude: -78.5178,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.307648,
        store_longitude: -78.5464,
    },
    {
        store_latitdue: -0.3400364,
        store_longitude: -78.5292011,
    },
    {
        store_latitdue: -0.3044977,
        store_longitude: -78.5395821,
    },
    {
        store_latitdue: -0.3231302,
        store_longitude: -78.5502754,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1909360481,
        store_longitude: -78.3644106612,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.318486,
        store_longitude: -78.550414,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3182425,
        store_longitude: -78.5505327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },

    {
        store_latitdue: -0.3068191,
        store_longitude: -78.5443975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.287034,
        store_longitude: -78.5334,
    },
    {
        store_latitdue: -0.30643852,
        store_longitude: -78.5264764,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.333788,
        store_longitude: -78.5253359,
    },
    {
        store_latitdue: -0.2828812,
        store_longitude: -78.5231235,
    },
    {
        store_latitdue: -0.281375,
        store_longitude: -78.5179467,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.219626,
        store_longitude: -78.5066,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.334926,
        store_longitude: -78.537733,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264836,
        store_longitude: -78.5128598,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.256625,
        store_longitude: -78.5122298,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3184886,
        store_longitude: -78.5513608,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3350327,
        store_longitude: -78.5368704,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3236426,
        store_longitude: -78.5529207,
    },
    {
        store_latitdue: -0.33768,
        store_longitude: -78.54579,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.254665,
        store_longitude: -78.528278,
    },
    {
        store_latitdue: -0.293249,
        store_longitude: -78.5437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.299358,
        store_longitude: -78.530306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.300904,
        store_longitude: -78.5234,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338882,
        store_longitude: -78.5291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3138556,
        store_longitude: -78.5526243,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261168,
        store_longitude: -78.516836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.307505,
        store_longitude: -78.529501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3334749,
        store_longitude: -78.5379125,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.306481,
        store_longitude: -78.534205,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262317,
        store_longitude: -78.528921,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.276281,
        store_longitude: -78.521,
    },
    {
        store_latitdue: -0.302063,
        store_longitude: -78.5204,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.247726,
        store_longitude: -78.5129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2517643,
        store_longitude: -78.5246054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.297816,
        store_longitude: -78.546944,
    },
    {
        store_latitdue: -0.281477,
        store_longitude: -78.5151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3085936,
        store_longitude: -78.5465594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2771199,
        store_longitude: -78.5833718,
    },
    {
        store_latitdue: -0.3070803,
        store_longitude: -78.5494295,
    },
    {
        store_latitdue: -0.278739,
        store_longitude: -78.524,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3376999,
        store_longitude: -78.5457198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.329856,
        store_longitude: -78.541379,
    },
    {
        store_latitdue: -0.307776,
        store_longitude: -78.5459,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2858308,
        store_longitude: -78.5286649,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3178792,
        store_longitude: -78.5462241,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.334743,
        store_longitude: -78.5342,
    },
    {
        store_latitdue: -0.277643,
        store_longitude: -78.543714,
    },
    {
        store_latitdue: -0.318561,
        store_longitude: -78.54968,
    },
    {
        store_latitdue: -0.283214,
        store_longitude: -78.5364,
    },
    {
        store_latitdue: -0.2723691,
        store_longitude: -78.5313929,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.319095,
        store_longitude: -78.53,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267739,
        store_longitude: -78.5407,
    },
    {
        store_latitdue: -0.256423,
        store_longitude: -78.5136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3293654,
        store_longitude: -78.5254666,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24812,
        store_longitude: -78.5164,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286129,
        store_longitude: -78.530203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2441,
        store_longitude: -78.4835,
    },
    {
        store_latitdue: -0.297483,
        store_longitude: -78.5324,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25549,
        store_longitude: -78.5106,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.263333,
        store_longitude: -78.5214,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2258714,
        store_longitude: -78.5067395,
    },
    {
        store_latitdue: -0.284479,
        store_longitude: -78.517554,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305738,
        store_longitude: -78.5358,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.255845,
        store_longitude: -78.5092,
    },
    {
        store_latitdue: -0.264223,
        store_longitude: -78.523077,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254531,
        store_longitude: -78.5154,
    },
    {
        store_latitdue: -0.257251,
        store_longitude: -78.5161,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3281119,
        store_longitude: -78.5401384,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2954715,
        store_longitude: -78.5319716,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2968566,
        store_longitude: -78.5207967,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.310172,
        store_longitude: -78.5261,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.28578148,
        store_longitude: -78.5217869282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2774527,
        store_longitude: -78.5285773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.299839,
        store_longitude: -78.5203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296907,
        store_longitude: -78.5195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304758,
        store_longitude: -78.537561,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2581025,
        store_longitude: -78.5206845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.329388,
        store_longitude: -78.536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.372974,
        store_longitude: -78.5511,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.24724,
        store_longitude: -78.51366,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3112443,
        store_longitude: -78.5415732,
    },
    {
        store_latitdue: -0.301174,
        store_longitude: -78.5386,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.324471,
        store_longitude: -78.5501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252451,
        store_longitude: -78.5174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267974,
        store_longitude: -78.529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.300482,
        store_longitude: -78.5237,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.302157,
        store_longitude: -78.541,
    },
    {
        store_latitdue: -0.250554,
        store_longitude: -78.5275,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2764439,
        store_longitude: -78.5348699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279197,
        store_longitude: -78.540051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.294553,
        store_longitude: -78.5376479,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.355081,
        store_longitude: -78.547,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2837443,
        store_longitude: -78.5351925,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308099,
        store_longitude: -78.5423,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.273871,
        store_longitude: -78.535335,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2999093,
        store_longitude: -78.5224249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.329696,
        store_longitude: -78.5552,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3233237,
        store_longitude: -78.5508397,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3073435,
        store_longitude: -78.5479208,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3179308,
        store_longitude: -78.5282074,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2987325,
        store_longitude: -78.5234671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262964,
        store_longitude: -78.513281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253911,
        store_longitude: -78.5279,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2990194,
        store_longitude: -78.5321681,
    },
    {
        store_latitdue: -0.3330308,
        store_longitude: -78.5244694,
    },
    {
        store_latitdue: -0.3339959,
        store_longitude: -78.525371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.295151,
        store_longitude: -78.5191,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.279109,
        store_longitude: -78.5234149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27618,
        store_longitude: -78.5204,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2991624,
        store_longitude: -78.5206177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.284405,
        store_longitude: -78.5192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3110596,
        store_longitude: -78.5454622,
    },
    {
        store_latitdue: -0.3295723,
        store_longitude: -78.5406032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.328057,
        store_longitude: -78.5424,
    },
    {
        store_latitdue: -0.3303734,
        store_longitude: -78.5409987,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3267552,
        store_longitude: -78.5466928,
    },
    {
        store_latitdue: -0.319791,
        store_longitude: -78.5471,
    },
    {
        store_latitdue: -0.3518875,
        store_longitude: -78.5287921,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.354038,
        store_longitude: -78.529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2843518,
        store_longitude: -78.5361568,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2999039,
        store_longitude: -78.5472965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2442096,
        store_longitude: -78.4948174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.354055,
        store_longitude: -78.5316,
    },
    {
        store_latitdue: -0.3056456,
        store_longitude: -78.5378438,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.31719,
        store_longitude: -78.528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3856431,
        store_longitude: -78.5401137,
    },
    {
        store_latitdue: -0.2739697,
        store_longitude: -78.5091378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288554,
        store_longitude: -78.5299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0632227,
        store_longitude: -78.3534537,
    },
    {
        store_latitdue: -0.3650179,
        store_longitude: -78.550427,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.312713,
        store_longitude: -78.5471,
    },
    {
        store_latitdue: -0.2470596,
        store_longitude: -78.5090301,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2489609,
        store_longitude: -78.5146238,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.1025983,
        store_longitude: -79.1061644,
    },
    {
        store_latitdue: -0.3297594,
        store_longitude: -78.5457647,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2643633,
        store_longitude: -78.5145076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264045,
        store_longitude: -78.520148,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.297099,
        store_longitude: -78.5499,
    },
    {
        store_latitdue: -0.3244446,
        store_longitude: -78.5322912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25838,
        store_longitude: -78.4958,
    },
    {
        store_latitdue: -0.2488667,
        store_longitude: -78.5260421,
    },
    {
        store_latitdue: -0.3307969,
        store_longitude: -78.5254271,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.320195,
        store_longitude: -78.548306,
    },
    {
        store_latitdue: -0.288779,
        store_longitude: -78.5177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278686,
        store_longitude: -78.539667,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.327214,
        store_longitude: -78.5413301,
    },
    {
        store_latitdue: -0.3354477,
        store_longitude: -78.5393569,
    },
    {
        store_latitdue: -0.283833,
        store_longitude: -78.5356538,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338049,
        store_longitude: -78.5619,
    },
    {
        store_latitdue: -0.255064,
        store_longitude: -78.515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261604,
        store_longitude: -78.5279,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.273336,
        store_longitude: -78.5159,
    },
    {
        store_latitdue: -0.287597,
        store_longitude: -78.536545,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.274435,
        store_longitude: -78.525866,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3172394,
        store_longitude: -78.5345645,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3302498,
        store_longitude: -78.5256698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3322295,
        store_longitude: -78.5310894,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3554849,
        store_longitude: -78.5447571,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257646,
        store_longitude: -78.508346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.162024,
        store_longitude: -78.4747,
    },
    {
        store_latitdue: -0.3129662,
        store_longitude: -78.5444136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256586,
        store_longitude: -78.526195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307579,
        store_longitude: -78.5462,
    },
    {
        store_latitdue: -0.250065,
        store_longitude: -78.525259,
    },
    {
        store_latitdue: -0.328098,
        store_longitude: -78.5386,
    },
    {
        store_latitdue: -0.3264553,
        store_longitude: -78.5467931,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.34490853,
        store_longitude: -78.5311424,
    },
    {
        store_latitdue: -0.290378,
        store_longitude: -78.5239,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2902043,
        store_longitude: -78.5242521,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.354806,
        store_longitude: -78.5453,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.324063,
        store_longitude: -78.5323,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.35482,
        store_longitude: -78.5454,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.32347,
        store_longitude: -78.5524,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.33113,
        store_longitude: -77.550711,
        neighborhood: 'GONZALO DÍAZ DE PINEDA',
    },
    {
        store_latitdue: -0.0760637,
        store_longitude: -78.4581759,
    },
    {
        store_latitdue: -0.304385,
        store_longitude: -78.54032,
    },
    {
        store_latitdue: -0.321298,
        store_longitude: -78.5517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1383435,
        store_longitude: -78.4813011,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3546893667,
        store_longitude: -78.5287784412,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254318,
        store_longitude: -78.5208,
    },
    {
        store_latitdue: -0.2634,
        store_longitude: -78.523457,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2536639,
        store_longitude: -78.5014844,
    },
    {
        store_latitdue: -0.28494,
        store_longitude: -78.5346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2989695,
        store_longitude: -78.531366,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.311656,
        store_longitude: -78.544705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.25463595,
        store_longitude: -78.5298004,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3054879,
        store_longitude: -78.5412071,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2540774,
        store_longitude: -78.506029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265066,
        store_longitude: -78.5141,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3157583,
        store_longitude: -78.5513715,
    },
    {
        store_latitdue: -0.257472,
        store_longitude: -78.5049,
    },
    {
        store_latitdue: -0.2581286,
        store_longitude: -78.5123943,
    },
    {
        store_latitdue: -0.275672,
        store_longitude: -78.5229,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2653137,
        store_longitude: -78.513249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270007,
        store_longitude: -78.5348,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.287221,
        store_longitude: -78.5374,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281632,
        store_longitude: -78.52322,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.336981,
        store_longitude: -78.544385,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3280035,
        store_longitude: -78.5414551,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2836597,
        store_longitude: -78.5322042,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3176418,
        store_longitude: -78.5478626,
    },
    {
        store_latitdue: -0.331808,
        store_longitude: -78.5333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3178796,
        store_longitude: -78.5459756,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2802,
        store_longitude: -78.5407,
    },
    {
        store_latitdue: -0.273056,
        store_longitude: -78.517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3039246,
        store_longitude: -78.5323187,
    },
    {
        store_latitdue: -0.3278148,
        store_longitude: -78.54741,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.328906,
        store_longitude: -78.5425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327372,
        store_longitude: -78.5418,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.277419,
        store_longitude: -78.523836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.324024672,
        store_longitude: -78.54979991,
    },
    {
        store_latitdue: -0.2831,
        store_longitude: -78.537061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.286882,
        store_longitude: -78.5281,
    },
    {
        store_latitdue: -0.317719,
        store_longitude: -78.5474,
    },
    {
        store_latitdue: -0.3297259,
        store_longitude: -78.5242318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3124409,
        store_longitude: -78.5441504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257223,
        store_longitude: -78.5293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.283369,
        store_longitude: -78.5362,
    },
    {
        store_latitdue: -0.300693,
        store_longitude: -78.5329,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2455299,
        store_longitude: -78.5224939,
    },
    {
        store_latitdue: -0.248062,
        store_longitude: -78.5138,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269068,
        store_longitude: -78.5252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277162,
        store_longitude: -78.5286,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3167874,
        store_longitude: -78.5279522,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.315755,
        store_longitude: -78.551268,
    },
    {
        store_latitdue: -0.253632,
        store_longitude: -78.515029,
    },
    {
        store_latitdue: -0.275778,
        store_longitude: -78.5358,
    },
    {
        store_latitdue: -0.3111881,
        store_longitude: -78.5259234,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.385042,
        store_longitude: -78.5396,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.389327,
        store_longitude: -78.5427,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.315553,
        store_longitude: -78.5524,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.25133,
        store_longitude: -78.51587,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.282667,
        store_longitude: -78.5273,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27743,
        store_longitude: -78.5247,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3062109,
        store_longitude: -78.5228562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2653153,
        store_longitude: -78.5131328,
    },
    {
        store_latitdue: -3.4527656,
        store_longitude: -79.9659991,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3106839,
        store_longitude: -78.5265061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.33551,
        store_longitude: -78.5462795,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253998,
        store_longitude: -78.5155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3280558,
        store_longitude: -78.5405446,
    },
    {
        store_latitdue: -0.2860023,
        store_longitude: -78.5393995,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.289468,
        store_longitude: -78.542367,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29853,
        store_longitude: -78.5207,
    },
    {
        store_latitdue: -0.240413,
        store_longitude: -78.5114,
    },
    {
        store_latitdue: -0.2511864,
        store_longitude: -78.5104892,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.297916,
        store_longitude: -78.521953,
    },
    {
        store_latitdue: -0.3247999,
        store_longitude: -78.5485584,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.318763,
        store_longitude: -78.5371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2507425,
        store_longitude: -78.5072688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.316888,
        store_longitude: -78.5504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250582,
        store_longitude: -78.50719,
    },
    {
        store_latitdue: -0.297753,
        store_longitude: -78.5333,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.319076,
        store_longitude: -78.5345546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3003614,
        store_longitude: -78.5473853,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2898968,
        store_longitude: -78.5437758,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292796,
        store_longitude: -78.5182,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3061565,
        store_longitude: -78.5332348,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3287348,
        store_longitude: -78.535208,
    },
    {
        store_latitdue: -0.3777671,
        store_longitude: -78.5521788,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.257075,
        store_longitude: -78.5107107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293108,
        store_longitude: -78.5443,
    },
    {
        store_latitdue: -0.267584,
        store_longitude: -78.5303,
    },
    {
        store_latitdue: -0.246044,
        store_longitude: -78.5118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2482196,
        store_longitude: -78.5068344,
    },
    {
        store_latitdue: -0.2817098,
        store_longitude: -78.5427251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2786129,
        store_longitude: -78.5162437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.316389,
        store_longitude: -78.5257,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3342261,
        store_longitude: -78.5451349,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26814517,
        store_longitude: -78.5274047,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.323016,
        store_longitude: -78.5522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2924218,
        store_longitude: -78.5201386,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.311998,
        store_longitude: -78.5617,
    },
    {
        store_latitdue: -0.3227984,
        store_longitude: -78.5461608,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144556,
        store_longitude: -78.4920941,
    },
    {
        store_latitdue: -0.3020946,
        store_longitude: -78.5389557,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3041211,
        store_longitude: -78.5413602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.324315,
        store_longitude: -78.5448855,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.296100619,
        store_longitude: -78.53240001,
    },
    {
        store_latitdue: -0.304482,
        store_longitude: -78.5398,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3380886,
        store_longitude: -78.5355891,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2978475,
        store_longitude: -78.5704585,
    },
    {
        store_latitdue: -0.251335,
        store_longitude: -78.5207,
    },
    {
        store_latitdue: -0.296629,
        store_longitude: -78.5536695,
    },
    {
        store_latitdue: -0.264902,
        store_longitude: -78.5306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28935548,
        store_longitude: -78.5297691,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286246,
        store_longitude: -78.528259,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2880771,
        store_longitude: -78.5280137,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286675,
        store_longitude: -78.5282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.334862,
        store_longitude: -78.534801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2644967,
        store_longitude: -78.5155483,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.298726,
        store_longitude: -78.5354488,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.328515,
        store_longitude: -78.5425,
    },
    {
        store_latitdue: -0.375569,
        store_longitude: -78.544606,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.329644,
        store_longitude: -78.54636,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25309,
        store_longitude: -78.5056,
    },
    {
        store_latitdue: -0.2460852,
        store_longitude: -78.5179692,
    },
    {
        store_latitdue: -0.2598868,
        store_longitude: -78.5081885,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2549702,
        store_longitude: -78.5269527,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.282788,
        store_longitude: -78.541227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338821,
        store_longitude: -78.5365784,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3057129,
        store_longitude: -78.5355595,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3158,
        store_longitude: -78.5493,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3277807,
        store_longitude: -78.5411664,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.324454,
        store_longitude: -78.5502,
    },
    {
        store_latitdue: -0.312905,
        store_longitude: -78.549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2511807,
        store_longitude: -78.5045627,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25582,
        store_longitude: -78.5214,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.27418127,
        store_longitude: -78.5204925,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3065726,
        store_longitude: -78.5230284,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.283668,
        store_longitude: -78.5305,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.31827,
        store_longitude: -78.5505,
    },
    {
        store_latitdue: -0.276444,
        store_longitude: -78.5213,
    },
    {
        store_latitdue: -0.3053213,
        store_longitude: -78.5670891,
    },
    {
        store_latitdue: -0.2398657,
        store_longitude: -78.5286169,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305739,
        store_longitude: -78.533222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2563686,
        store_longitude: -78.5177005,
    },
    {
        store_latitdue: -0.328921,
        store_longitude: -78.5324,
    },
    {
        store_latitdue: -0.3037327,
        store_longitude: -78.5222042,
    },
    {
        store_latitdue: -0.3417431,
        store_longitude: -78.529834,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3276061,
        store_longitude: -78.5490949,
    },
    {
        store_latitdue: -0.3537244,
        store_longitude: -78.5467848,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.366023,
        store_longitude: -78.5484,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.253218,
        store_longitude: -78.518323,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246113,
        store_longitude: -78.527781,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2646177,
        store_longitude: -78.5269062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.294658,
        store_longitude: -78.5484,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276887,
        store_longitude: -78.5150909,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2764568,
        store_longitude: -78.5156168,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27131,
        store_longitude: -78.5185,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280389,
        store_longitude: -78.5176087,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.322561,
        store_longitude: -78.536914,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3042417,
        store_longitude: -78.5400917,
    },
    {
        store_latitdue: -0.3287376,
        store_longitude: -78.5532669,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.333991,
        store_longitude: -78.5403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2249341,
        store_longitude: -78.529028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260446,
        store_longitude: -78.5054898,
    },
    {
        store_latitdue: -0.2632475,
        store_longitude: -78.516854,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2982002,
        store_longitude: -78.5455302,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3327255,
        store_longitude: -78.5316922,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305463,
        store_longitude: -78.5404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2454715,
        store_longitude: -78.5126217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3318201,
        store_longitude: -78.5532803,
    },
    {
        store_latitdue: -0.260065,
        store_longitude: -78.55514,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3034245,
        store_longitude: -78.5336124,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3381256,
        store_longitude: -78.5331451,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22058,
        store_longitude: -78.5219,
    },
    {
        store_latitdue: -0.3384501,
        store_longitude: -78.5428513,
    },
    {
        store_latitdue: -0.2537227,
        store_longitude: -78.5165516,
    },
    {
        store_latitdue: -0.2515478,
        store_longitude: -78.5196839,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255321,
        store_longitude: -78.5045,
    },
    {
        store_latitdue: -0.2830662,
        store_longitude: -78.5417377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3319112,
        store_longitude: -78.5602957,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.385308,
        store_longitude: -78.5377283,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2825623,
        store_longitude: -78.540982,
    },
    {
        store_latitdue: -0.3076911,
        store_longitude: -78.5462555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2905717,
        store_longitude: -78.5183491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2794439,
        store_longitude: -78.5285516,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32952,
        store_longitude: -78.530933,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.37866078,
        store_longitude: -78.5441565,
    },
    {
        store_latitdue: -0.2551814,
        store_longitude: -78.5096155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.355111,
        store_longitude: -78.5346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2486795,
        store_longitude: -78.5103032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.28237327,
        store_longitude: -78.5391464,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2615451,
        store_longitude: -78.5165544,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3114331,
        store_longitude: -78.5561214,
    },
    {
        store_latitdue: -0.313118,
        store_longitude: -78.5524,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2642914,
        store_longitude: -78.517244,
    },
    {
        store_latitdue: -0.2804224,
        store_longitude: -78.5216437,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2719305,
        store_longitude: -78.5177549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2848398,
        store_longitude: -78.5345815,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3339827,
        store_longitude: -78.5262295,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3104839,
        store_longitude: -78.5275491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.306987,
        store_longitude: -78.5487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.323016,
        store_longitude: -78.5533,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3159151,
        store_longitude: -78.5502259,
    },
    {
        store_latitdue: -0.2599564,
        store_longitude: -78.5146117,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253373,
        store_longitude: -78.508597,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2533281,
        store_longitude: -78.5056869,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2517414,
        store_longitude: -78.5120112,
    },
    {
        store_latitdue: -0.2515048,
        store_longitude: -78.5064839,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3040172,
        store_longitude: -78.5432471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.246156,
        store_longitude: -78.5296,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.146111,
        store_longitude: -78.468051,
    },
    {
        store_latitdue: -0.3155562,
        store_longitude: -78.5520636,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.251095,
        store_longitude: -78.5066419,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25436377,
        store_longitude: -78.5054174,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3009888,
        store_longitude: -78.5474806,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2985403,
        store_longitude: -78.5475186,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.280441,
        store_longitude: -78.5176,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28460257,
        store_longitude: -78.53606816,
    },
    {
        store_latitdue: -0.246802,
        store_longitude: -78.5349,
    },
    {
        store_latitdue: -0.3124995,
        store_longitude: -78.5275093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.299039,
        store_longitude: -78.5403,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2567104,
        store_longitude: -78.5194383,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25421,
        store_longitude: -78.5209,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25792,
        store_longitude: -78.5321,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2766826,
        store_longitude: -78.5419812,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2934754,
        store_longitude: -78.5192481,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.320091,
        store_longitude: -78.5502,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2488085,
        store_longitude: -78.5261582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.223185,
        store_longitude: -78.518,
    },
    {
        store_latitdue: -0.2247501,
        store_longitude: -78.5191271,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23293,
        store_longitude: -78.52715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2235453,
        store_longitude: -78.519696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.232707,
        store_longitude: -78.5246957,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.224059147,
        store_longitude: -78.51793083,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2246103,
        store_longitude: -78.518692,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225488,
        store_longitude: -78.521103,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2233658,
        store_longitude: -78.5197118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2238888,
        store_longitude: -78.5174657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2232393,
        store_longitude: -78.5206668,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2246713,
        store_longitude: -78.5195296,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127708,
        store_longitude: -78.5036,
    },
    {
        store_latitdue: -0.239486,
        store_longitude: -78.5124,
    },
    {
        store_latitdue: -0.240521,
        store_longitude: -78.505693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2346974,
        store_longitude: -78.5083485,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2496576,
        store_longitude: -78.5154031,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.237663,
        store_longitude: -78.5117,
    },
    {
        store_latitdue: -0.2353889,
        store_longitude: -78.5099657,
    },
    {
        store_latitdue: -0.238937,
        store_longitude: -78.5062,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2328488,
        store_longitude: -78.5073371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2288492,
        store_longitude: -78.5264198,
    },
    {
        store_latitdue: -0.233501,
        store_longitude: -78.5309,
    },
    {
        store_latitdue: -0.2285879747,
        store_longitude: -78.5328376293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2208798,
        store_longitude: -78.533577,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.196077,
        store_longitude: -78.4993,
    },
    {
        store_latitdue: -0.2333044,
        store_longitude: -78.5333555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2298449,
        store_longitude: -78.5333661,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3183961,
        store_longitude: -78.5570242,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2346039,
        store_longitude: -78.5332485,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23739,
        store_longitude: -78.53345,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235773,
        store_longitude: -78.533174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2331281,
        store_longitude: -78.5288212,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.241468,
        store_longitude: -78.5355,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.218328,
        store_longitude: -78.5218,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220582,
        store_longitude: -78.5229,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2210416,
        store_longitude: -78.5227136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2218959,
        store_longitude: -78.5185702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214564,
        store_longitude: -78.533086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.228378,
        store_longitude: -78.5243,
    },
    {
        store_latitdue: -0.210909,
        store_longitude: -78.524106,
    },
    {
        store_latitdue: -0.3083119,
        store_longitude: -78.542393,
    },
    {
        store_latitdue: -0.2394995,
        store_longitude: -78.537026,
    },
    {
        store_latitdue: -0.235183,
        store_longitude: -78.5393,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23949,
        store_longitude: -78.53502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223749,
        store_longitude: -78.516024,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.224911,
        store_longitude: -78.515244,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2306013,
        store_longitude: -78.514269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.231057,
        store_longitude: -78.5135,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.227646,
        store_longitude: -78.51377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.231208,
        store_longitude: -78.51363,
    },
    {
        store_latitdue: -0.2281758,
        store_longitude: -78.5143447,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2481899,
        store_longitude: -78.5294825,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246819,
        store_longitude: -78.5294,
    },
    {
        store_latitdue: -0.244276081,
        store_longitude: -78.52781116,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2435608,
        store_longitude: -78.5271651,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2458578,
        store_longitude: -78.5342021,
    },
    {
        store_latitdue: -0.241967,
        store_longitude: -78.527916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2460379,
        store_longitude: -78.5323873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2345638,
        store_longitude: -78.5239274,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239486,
        store_longitude: -78.5295,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.236318,
        store_longitude: -78.5271,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23542,
        store_longitude: -78.52711,
    },
    {
        store_latitdue: -0.0969072,
        store_longitude: -78.4442,
    },
    {
        store_latitdue: -0.2402689,
        store_longitude: -78.5258807,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2326038,
        store_longitude: -78.522684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1800651,
        store_longitude: -78.4798231,
    },
    {
        store_latitdue: -0.241185,
        store_longitude: -78.518069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.238198,
        store_longitude: -78.5162,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2391017,
        store_longitude: -78.5167064,
    },
    {
        store_latitdue: -0.2424712,
        store_longitude: -78.516736,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2418603,
        store_longitude: -78.5157229,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.238087,
        store_longitude: -78.5161,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2419421,
        store_longitude: -78.5067561,
    },
    {
        store_latitdue: -0.242377,
        store_longitude: -78.5106,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2456727,
        store_longitude: -78.506003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2432883,
        store_longitude: -78.5060952,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243058,
        store_longitude: -78.507262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240917,
        store_longitude: -78.511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24112,
        store_longitude: -78.516327,
    },
    {
        store_latitdue: -0.2436961,
        store_longitude: -78.5065174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241004,
        store_longitude: -78.5109,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.24493,
        store_longitude: -78.5093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244717,
        store_longitude: -78.4983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.247203,
        store_longitude: -78.500046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2461716,
        store_longitude: -78.4976859,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249283,
        store_longitude: -78.4975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2507105,
        store_longitude: -78.5025026,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2492853,
        store_longitude: -78.4981034,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2464119,
        store_longitude: -78.4997882,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.244819,
        store_longitude: -78.497795,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2541894,
        store_longitude: -78.5019498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2436618,
        store_longitude: -78.4970061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2458558,
        store_longitude: -78.4994792,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2678887,
        store_longitude: -78.4996378,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2715743,
        store_longitude: -78.5008303,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2689044,
        store_longitude: -78.5000626,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.263085,
        store_longitude: -78.4999,
    },
    {
        store_latitdue: -0.263719,
        store_longitude: -78.4974,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.265911,
        store_longitude: -78.498153,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.268902,
        store_longitude: -78.499975,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.270062,
        store_longitude: -78.500118,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.271066,
        store_longitude: -78.5008,
    },
    {
        store_latitdue: -0.268026,
        store_longitude: -78.49928,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.263646,
        store_longitude: -78.498603,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2773671,
        store_longitude: -78.5021428,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.263696,
        store_longitude: -78.4977,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.244154,
        store_longitude: -78.495327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.245698,
        store_longitude: -78.4919,
    },
    {
        store_latitdue: -0.2533899,
        store_longitude: -78.4839516,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.242256,
        store_longitude: -78.493,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244964,
        store_longitude: -78.4940547,
    },
    {
        store_latitdue: -0.235322,
        store_longitude: -78.489,
    },
    {
        store_latitdue: -0.2505596,
        store_longitude: -78.491335,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2452003,
        store_longitude: -78.4927389,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254065,
        store_longitude: -78.4842515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2440622,
        store_longitude: -78.4956,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.257253,
        store_longitude: -78.48983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2563486,
        store_longitude: -78.4892176,
    },
    {
        store_latitdue: -0.2548927,
        store_longitude: -78.4927019,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253403,
        store_longitude: -78.493039,
    },
    {
        store_latitdue: -0.255851,
        store_longitude: -78.4923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2473102,
        store_longitude: -78.4882649,
    },
    {
        store_latitdue: -0.2462196595,
        store_longitude: -78.4884319827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2210647,
        store_longitude: -78.5208408,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2190436,
        store_longitude: -78.5219814,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.234144,
        store_longitude: -78.5073,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.307415,
        store_longitude: -78.53594,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.256971,
        store_longitude: -78.496131,
    },
    {
        store_latitdue: -0.26148,
        store_longitude: -78.498505,
    },
    {
        store_latitdue: -0.2620353,
        store_longitude: -78.4972403,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2622503,
        store_longitude: -78.4975142,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2503273,
        store_longitude: -78.5022749,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2458198,
        store_longitude: -78.4958456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2635972,
        store_longitude: -78.498909,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.250708,
        store_longitude: -78.5015,
    },
    {
        store_latitdue: -0.2537805,
        store_longitude: -78.5008513,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3271729,
        store_longitude: -78.5403288,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253917,
        store_longitude: -78.50194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.248657,
        store_longitude: -78.4984,
    },
    {
        store_latitdue: -0.2628003,
        store_longitude: -78.4979982,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.278352,
        store_longitude: -78.555211,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2154731,
        store_longitude: -78.5265721,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.217459,
        store_longitude: -78.525117,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218299,
        store_longitude: -78.523664,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2530871,
        store_longitude: -78.4935755,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256399,
        store_longitude: -78.4909231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2519158,
        store_longitude: -78.4948786,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2528283,
        store_longitude: -78.4939745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.221575,
        store_longitude: -78.526759,
    },
    {
        store_latitdue: -0.22035,
        store_longitude: -78.5258,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218507,
        store_longitude: -78.523933,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.21903,
        store_longitude: -78.524757,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.236194,
        store_longitude: -78.5286,
    },
    {
        store_latitdue: -0.235482,
        store_longitude: -78.5271,
    },
    {
        store_latitdue: -0.23384,
        store_longitude: -78.52796,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2327,
        store_longitude: -78.5273,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2321216,
        store_longitude: -78.5274022,
    },
    {
        store_latitdue: -0.236939,
        store_longitude: -78.5292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2366454,
        store_longitude: -78.5297636,
    },
    {
        store_latitdue: -0.233104,
        store_longitude: -78.5288276,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.22687,
        store_longitude: -78.5259362,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.227407,
        store_longitude: -78.526079,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2328962,
        store_longitude: -78.527282,
    },
    {
        store_latitdue: -0.23278829,
        store_longitude: -78.5275421,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263866,
        store_longitude: -78.5302,
    },
    {
        store_latitdue: -0.2364938,
        store_longitude: -78.5298202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.236772,
        store_longitude: -78.5296,
    },
    {
        store_latitdue: -0.234141,
        store_longitude: -78.540448,
    },
    {
        store_latitdue: -0.28581401,
        store_longitude: -78.5382385,
    },
    {
        store_latitdue: -0.247217,
        store_longitude: -78.526464,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246527,
        store_longitude: -78.526452,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246351,
        store_longitude: -78.5266,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24427,
        store_longitude: -78.5267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.237099,
        store_longitude: -78.5384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2408148,
        store_longitude: -78.5280333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.236802,
        store_longitude: -78.5375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2499735,
        store_longitude: -78.5277396,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255048,
        store_longitude: -78.526912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2425053,
        store_longitude: -78.5258814,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.240458,
        store_longitude: -78.524459,
    },
    {
        store_latitdue: -0.23198,
        store_longitude: -78.52585,
    },
    {
        store_latitdue: -0.245898,
        store_longitude: -78.53406,
    },
    {
        store_latitdue: -0.239707,
        store_longitude: -78.5319,
    },
    {
        store_latitdue: -0.2759988,
        store_longitude: -78.5341299,
    },
    {
        store_latitdue: -0.239724,
        store_longitude: -78.5321,
    },
    {
        store_latitdue: -0.2410183,
        store_longitude: -78.5294267,
    },
    {
        store_latitdue: -0.2416776,
        store_longitude: -78.5301271,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2415132,
        store_longitude: -78.530693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.238957,
        store_longitude: -78.54082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241765,
        store_longitude: -78.533,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2418,
        store_longitude: -78.5361,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2456186,
        store_longitude: -78.5308301,
    },
    {
        store_latitdue: -0.2407552,
        store_longitude: -78.5328423,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240595,
        store_longitude: -78.533167,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2403008,
        store_longitude: -78.5119357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.245124,
        store_longitude: -78.5198302,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.238171,
        store_longitude: -78.5127053,
    },
    {
        store_latitdue: -0.255597,
        store_longitude: -78.512573,
    },
    {
        store_latitdue: -0.2264152,
        store_longitude: -78.5211802,
    },
    {
        store_latitdue: -0.226424,
        store_longitude: -78.521232,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2262347,
        store_longitude: -78.5209571,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226346,
        store_longitude: -78.5201,
    },
    {
        store_latitdue: -0.226745,
        store_longitude: -78.520173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2269547,
        store_longitude: -78.5203658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225576,
        store_longitude: -78.5189055,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2419882,
        store_longitude: -78.506951,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2443189,
        store_longitude: -78.5206275,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2426861,
        store_longitude: -78.507911,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239361,
        store_longitude: -78.5131,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.238675,
        store_longitude: -78.511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.238726,
        store_longitude: -78.5114,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24345,
        store_longitude: -78.5197,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.244446,
        store_longitude: -78.5231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2455299,
        store_longitude: -78.5224939,
    },
    {
        store_latitdue: -0.2277957,
        store_longitude: -78.5146095,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.243274,
        store_longitude: -78.52206,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241619,
        store_longitude: -78.513104,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2346068,
        store_longitude: -78.5193064,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.225089,
        store_longitude: -78.516101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243247,
        store_longitude: -78.51388,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243247,
        store_longitude: -78.51388,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2253368,
        store_longitude: -78.5312298,
    },
    {
        store_latitdue: -0.244271,
        store_longitude: -78.5148,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225718,
        store_longitude: -78.514833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226080757,
        store_longitude: -78.51467294,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2277957,
        store_longitude: -78.5146095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2302387,
        store_longitude: -78.512742,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.26732,
        store_longitude: -78.546819,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2341637,
        store_longitude: -78.5082634,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0926818,
        store_longitude: -78.4988,
    },
    {
        store_latitdue: -0.2450657,
        store_longitude: -78.4973648,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25804,
        store_longitude: -78.4953464,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2508896,
        store_longitude: -78.5013011,
    },
    {
        store_latitdue: -0.2468587,
        store_longitude: -78.5139254,
    },
    {
        store_latitdue: -0.2527073,
        store_longitude: -78.4936989,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2543848,
        store_longitude: -78.4924611,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244903,
        store_longitude: -78.4953,
    },
    {
        store_latitdue: -0.262061,
        store_longitude: -78.49805,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.256224,
        store_longitude: -78.5016351,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2630069,
        store_longitude: -78.4865051,
    },
    {
        store_latitdue: -0.2622504,
        store_longitude: -78.4977802,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2441933,
        store_longitude: -78.4954874,
    },
    {
        store_latitdue: -0.255831,
        store_longitude: -78.492,
    },
    {
        store_latitdue: -0.2546531,
        store_longitude: -78.4911017,
    },
    {
        store_latitdue: -0.2548013,
        store_longitude: -78.4907948,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251424,
        store_longitude: -78.499,
    },
    {
        store_latitdue: -0.3119715,
        store_longitude: -78.5597388,
    },
    {
        store_latitdue: -0.2549102,
        store_longitude: -78.4904715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2560923,
        store_longitude: -78.4908367,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2561453,
        store_longitude: -78.4908088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2536494,
        store_longitude: -78.4858074,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251682,
        store_longitude: -78.4977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2520066,
        store_longitude: -78.4948614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251593,
        store_longitude: -78.4985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2505372,
        store_longitude: -78.4886394,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243906,
        store_longitude: -78.502141,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292917,
        store_longitude: -78.5663,
    },
    {
        store_latitdue: -0.244015,
        store_longitude: -78.502272,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246832,
        store_longitude: -78.5066,
    },
    {
        store_latitdue: -0.246258,
        store_longitude: -78.50752,
    },
    {
        store_latitdue: -0.2450843,
        store_longitude: -78.5061784,
    },
    {
        store_latitdue: -0.33496,
        store_longitude: -78.5626,
    },
    {
        store_latitdue: -0.244721,
        store_longitude: -78.505474,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244104,
        store_longitude: -78.4876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240322,
        store_longitude: -78.4862109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240914,
        store_longitude: -78.4862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249047,
        store_longitude: -78.4978,
    },
    {
        store_latitdue: -0.25103,
        store_longitude: -78.4965867,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.243258,
        store_longitude: -78.512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2420618,
        store_longitude: -78.5099067,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239274,
        store_longitude: -78.4876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.238439,
        store_longitude: -78.4871,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.232835,
        store_longitude: -78.490918,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2463698,
        store_longitude: -78.5080055,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246236,
        store_longitude: -78.508,
    },
    {
        store_latitdue: -0.232365,
        store_longitude: -78.5297,
    },
    {
        store_latitdue: -0.222167,
        store_longitude: -78.5328,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22817,
        store_longitude: -78.5328,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2263586,
        store_longitude: -78.5319156,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226322,
        store_longitude: -78.531136,
    },
    {
        store_latitdue: -0.227892,
        store_longitude: -78.5297,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2275003,
        store_longitude: -78.5299208,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235271,
        store_longitude: -78.525714,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23606,
        store_longitude: -78.5271,
    },
    {
        store_latitdue: -0.217573,
        store_longitude: -78.523747,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2474898,
        store_longitude: -78.5121788,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225116,
        store_longitude: -78.525112,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.228848,
        store_longitude: -78.527309,
    },
    {
        store_latitdue: -0.2350484,
        store_longitude: -78.5275026,
    },
    {
        store_latitdue: -0.220525,
        store_longitude: -78.5251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218964,
        store_longitude: -78.5116,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.220138,
        store_longitude: -78.5239,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220781,
        store_longitude: -78.524245,
    },
    {
        store_latitdue: -0.2187085,
        store_longitude: -78.5219924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218243,
        store_longitude: -78.521984,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23294,
        store_longitude: -78.5328,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2533962,
        store_longitude: -78.5147306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.317921,
        store_longitude: -78.5458,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.220856,
        store_longitude: -78.521499,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.226968,
        store_longitude: -78.529915,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.225745,
        store_longitude: -78.5271,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.222606,
        store_longitude: -78.526054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2256,
        store_longitude: -78.528138,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.228948,
        store_longitude: -78.5277201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.223996,
        store_longitude: -78.5206,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.224049,
        store_longitude: -78.520728,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.222009,
        store_longitude: -78.520152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22685,
        store_longitude: -78.5259,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229643,
        store_longitude: -78.5251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22195,
        store_longitude: -78.5185,
    },
    {
        store_latitdue: -0.222062,
        store_longitude: -78.5185,
    },
    {
        store_latitdue: -0.223681,
        store_longitude: -78.5172,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22711,
        store_longitude: -78.529871,
    },
    {
        store_latitdue: -0.241197,
        store_longitude: -78.5351,
    },
    {
        store_latitdue: -0.242265,
        store_longitude: -78.5389,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.234653,
        store_longitude: -78.5233919,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2468112,
        store_longitude: -78.52641,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.242223,
        store_longitude: -78.5367,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261669,
        store_longitude: -78.5441,
    },
    {
        store_latitdue: -0.238832,
        store_longitude: -78.5325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28001,
        store_longitude: -78.5415,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2431094,
        store_longitude: -78.5291963,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2402967,
        store_longitude: -78.5302062,
    },
    {
        store_latitdue: -0.2438642,
        store_longitude: -78.5316658,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.243442,
        store_longitude: -78.5331,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.5335449,
        store_longitude: -78.5335449,
    },
    {
        store_latitdue: -0.2457928,
        store_longitude: -78.5324107,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2457928,
        store_longitude: -78.5323108,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2750035,
        store_longitude: -78.5404678,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.236836,
        store_longitude: -78.5393,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239755,
        store_longitude: -78.540928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2396325,
        store_longitude: -78.5422413,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2409956,
        store_longitude: -78.5327313,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241348,
        store_longitude: -78.541147,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2410009,
        store_longitude: -78.532517,
    },
    {
        store_latitdue: -0.24218,
        store_longitude: -78.525094,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2374349,
        store_longitude: -78.526364,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303361,
        store_longitude: -78.569621,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.238381,
        store_longitude: -78.514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244611,
        store_longitude: -78.515715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2457068,
        store_longitude: -78.5211827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2432804,
        store_longitude: -78.5219211,
    },
    {
        store_latitdue: -0.2256423,
        store_longitude: -78.5191123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225849,
        store_longitude: -78.5184877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229847,
        store_longitude: -78.5151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2400386,
        store_longitude: -78.5147755,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239106,
        store_longitude: -78.5322,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239418,
        store_longitude: -78.5125,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.242915,
        store_longitude: -78.495794,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24649744,
        store_longitude: -78.4964025,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246437,
        store_longitude: -78.488518,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244343,
        store_longitude: -78.4971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2446157,
        store_longitude: -78.5015806,
    },
    {
        store_latitdue: -0.243142,
        store_longitude: -78.503066,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243218,
        store_longitude: -78.503033,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244054,
        store_longitude: -78.5099,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2444868,
        store_longitude: -78.507828,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2333758,
        store_longitude: -78.4897586,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2336027,
        store_longitude: -78.4915855,
    },
    {
        store_latitdue: -0.244585,
        store_longitude: -78.511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249237,
        store_longitude: -78.499979,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.240191,
        store_longitude: -78.4865,
    },
    {
        store_latitdue: -0.2619947,
        store_longitude: -78.4989835,
    },
    {
        store_latitdue: -0.2341994,
        store_longitude: -78.4916558,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262103,
        store_longitude: -78.4971937,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2516802,
        store_longitude: -78.4985416,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2515638,
        store_longitude: -78.4910768,
    },
    {
        store_latitdue: -0.2539717,
        store_longitude: -78.4929332,
    },
    {
        store_latitdue: -0.2506085,
        store_longitude: -78.488684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.251964,
        store_longitude: -78.485,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2260885,
        store_longitude: -78.5315054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2215316,
        store_longitude: -78.5215783,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.274976,
        store_longitude: -78.5379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2250463,
        store_longitude: -78.532927,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225863,
        store_longitude: -78.528171,
    },
    {
        store_latitdue: -0.226718,
        store_longitude: -78.528822,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223466,
        store_longitude: -78.5198,
    },
    {
        store_latitdue: -0.223203,
        store_longitude: -78.5196,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2211354,
        store_longitude: -78.5227673,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220055,
        store_longitude: -78.523937,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.221267,
        store_longitude: -78.526227,
    },
    {
        store_latitdue: -0.221259,
        store_longitude: -78.525243,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226877,
        store_longitude: -78.5251,
    },
    {
        store_latitdue: -0.226556,
        store_longitude: -78.52487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.228519,
        store_longitude: -78.5277,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229638,
        store_longitude: -78.528296,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23024,
        store_longitude: -78.526042,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2364248,
        store_longitude: -78.5266881,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23678,
        store_longitude: -78.5299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246187,
        store_longitude: -78.5282,
    },
    {
        store_latitdue: -0.2423839,
        store_longitude: -78.5296401,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23848,
        store_longitude: -78.531506,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2380919,
        store_longitude: -78.5329048,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2385558,
        store_longitude: -78.5334662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2389106,
        store_longitude: -78.5332088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2376343,
        store_longitude: -78.5332501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21303,
        store_longitude: -78.4967,
    },
    {
        store_latitdue: -0.239141,
        store_longitude: -78.533271,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2406094,
        store_longitude: -78.5233331,
    },
    {
        store_latitdue: -0.2406257,
        store_longitude: -78.5398549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241657,
        store_longitude: -78.540771,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2413409,
        store_longitude: -78.5405238,
    },
    {
        store_latitdue: -0.12943,
        store_longitude: -78.4836,
    },
    {
        store_latitdue: -0.249423,
        store_longitude: -78.5304,
    },
    {
        store_latitdue: -0.300734,
        store_longitude: -78.5404,
    },
    {
        store_latitdue: -0.235187,
        store_longitude: -78.5064799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226758,
        store_longitude: -78.517246,
    },
    {
        store_latitdue: -0.227139,
        store_longitude: -78.5169,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241352,
        store_longitude: -78.531553,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2349498,
        store_longitude: -78.5079451,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.291036,
        store_longitude: -78.529316,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2399062,
        store_longitude: -78.5147676,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.238188,
        store_longitude: -78.521292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239142,
        store_longitude: -78.521715,
    },
    {
        store_latitdue: -0.24241,
        store_longitude: -78.515085,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22826,
        store_longitude: -78.5145,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2392924,
        store_longitude: -78.5119678,
    },
    {
        store_latitdue: -0.245424,
        store_longitude: -78.5137773,
    },
    {
        store_latitdue: -0.265046,
        store_longitude: -78.502157,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.23827,
        store_longitude: -78.4895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.268083,
        store_longitude: -78.4998,
    },
    {
        store_latitdue: -0.1846115,
        store_longitude: -78.5027077,
    },
    {
        store_latitdue: -0.2536017,
        store_longitude: -78.5014842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2260609,
        store_longitude: -78.4826588,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3099902,
        store_longitude: -78.5485016,
    },
    {
        store_latitdue: -0.238492,
        store_longitude: -78.4898985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2455277,
        store_longitude: -78.5028995,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.245793,
        store_longitude: -78.49054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.244947,
        store_longitude: -78.494087,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2448949,
        store_longitude: -78.4940918,
    },
    {
        store_latitdue: -0.2467171,
        store_longitude: -78.4892056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23984,
        store_longitude: -78.4879,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2419183,
        store_longitude: -78.4861167,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2431717,
        store_longitude: -78.4943246,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.227027,
        store_longitude: -78.5298,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2625567,
        store_longitude: -78.519045,
    },
    {
        store_latitdue: -0.211898,
        store_longitude: -78.428162,
    },
    {
        store_latitdue: -0.2426211,
        store_longitude: -78.4944702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2251471,
        store_longitude: -78.5278428,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2556394,
        store_longitude: -78.5169129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2642874,
        store_longitude: -78.5156274,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.222062,
        store_longitude: -78.524977,
    },
    {
        store_latitdue: -0.215694,
        store_longitude: -78.5306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215264,
        store_longitude: -78.527964,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.222068,
        store_longitude: -78.52492,
    },
    {
        store_latitdue: -0.2188842,
        store_longitude: -78.5246448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2220937,
        store_longitude: -78.5186453,
    },
    {
        store_latitdue: -0.2226845,
        store_longitude: -78.5175716,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2299132,
        store_longitude: -78.5240152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229807,
        store_longitude: -78.5237,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2284812,
        store_longitude: -78.5245194,
    },
    {
        store_latitdue: -0.220909,
        store_longitude: -78.5226,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26431724,
        store_longitude: -78.5300292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.25879,
        store_longitude: -78.5417,
    },
    {
        store_latitdue: -0.24015,
        store_longitude: -78.53396,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.236111,
        store_longitude: -78.5137,
    },
    {
        store_latitdue: -0.23726,
        store_longitude: -78.5388,
    },
    {
        store_latitdue: -0.240693,
        store_longitude: -78.523498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2422438,
        store_longitude: -78.5250751,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2460677,
        store_longitude: -78.5317584,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.233104,
        store_longitude: -78.506523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2308756,
        store_longitude: -78.5156893,
    },
    {
        store_latitdue: -0.2870629,
        store_longitude: -78.5297001,
    },
    {
        store_latitdue: -0.2385262,
        store_longitude: -78.5071609,
    },
    {
        store_latitdue: -0.242035,
        store_longitude: -78.504799,
    },
    {
        store_latitdue: -0.2387037,
        store_longitude: -78.5064413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2389664,
        store_longitude: -78.5104679,
    },
    {
        store_latitdue: -0.3012559,
        store_longitude: -78.5417361,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.255646,
        store_longitude: -78.4996,
    },
    {
        store_latitdue: -0.277791,
        store_longitude: -78.50187,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.269306,
        store_longitude: -78.5,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.257074,
        store_longitude: -78.4913,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2543702,
        store_longitude: -78.5036042,
    },
    {
        store_latitdue: -0.245274,
        store_longitude: -78.5061,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.244011,
        store_longitude: -78.4959,
    },
    {
        store_latitdue: -0.239628,
        store_longitude: -78.4891869,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2403597,
        store_longitude: -78.4861467,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2424199,
        store_longitude: -78.4895179,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2300151,
        store_longitude: -78.5262852,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2333354,
        store_longitude: -78.5306503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2634971,
        store_longitude: -78.5102951,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2408402,
        store_longitude: -78.5282381,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.24376,
        store_longitude: -78.5284,
    },
    {
        store_latitdue: -0.2411116,
        store_longitude: -78.5308841,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2831306,
        store_longitude: -78.5368323,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.22806028,
        store_longitude: -78.5127407,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2877009,
        store_longitude: -78.5178126,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3208433,
        store_longitude: -78.5311783,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.334244,
        store_longitude: -78.5368,
    },
    {
        store_latitdue: -0.2975717,
        store_longitude: -78.5246777,
    },
    {
        store_latitdue: -0.33049982,
        store_longitude: -78.5350341,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253013,
        store_longitude: -78.4899,
    },
    {
        store_latitdue: -0.263817,
        store_longitude: -78.497893,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.23913,
        store_longitude: -78.4908,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240061,
        store_longitude: -78.48933,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239142,
        store_longitude: -78.487182,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2420982,
        store_longitude: -78.505309,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2436443,
        store_longitude: -78.511626,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.242438,
        store_longitude: -78.50659,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2420808,
        store_longitude: -78.4859054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3627284,
        store_longitude: -78.5484032,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.258515,
        store_longitude: -78.5094,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.257718,
        store_longitude: -78.5277,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2352283,
        store_longitude: -78.5070701,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235204,
        store_longitude: -78.507109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239683,
        store_longitude: -78.5207396,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28521,
        store_longitude: -78.530492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.232358,
        store_longitude: -78.5165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.248601,
        store_longitude: -78.4983323,
    },
    {
        store_latitdue: -0.267987,
        store_longitude: -78.4995,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2489495,
        store_longitude: -78.4941427,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.383339,
        store_longitude: -78.5470361,
    },
    {
        store_latitdue: -0.242509,
        store_longitude: -78.4910275,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.223185,
        store_longitude: -78.518,
    },
    {
        store_latitdue: -0.228707,
        store_longitude: -78.524198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21539,
        store_longitude: -78.530902,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2379201,
        store_longitude: -78.5071955,
    },
    {
        store_latitdue: -0.2371116,
        store_longitude: -78.5075289,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2385484,
        store_longitude: -78.5305434,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27539014,
        store_longitude: -78.5117874,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259308,
        store_longitude: -78.5333,
    },
    {
        store_latitdue: -0.2408581,
        store_longitude: -78.514931,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3090772,
        store_longitude: -78.5233658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2512558,
        store_longitude: -78.4950589,
    },
    {
        store_latitdue: -0.3351036,
        store_longitude: -78.5253872,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2643726,
        store_longitude: -78.4973273,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3658148,
        store_longitude: -78.5470731,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.1562857,
        store_longitude: -78.4721857,
    },
    {
        store_latitdue: -0.2437795,
        store_longitude: -78.4879201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2548914,
        store_longitude: -78.5119835,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257455,
        store_longitude: -78.5046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.268218,
        store_longitude: -78.5284631,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2352528,
        store_longitude: -78.539511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2357485,
        store_longitude: -78.5230852,
    },
    {
        store_latitdue: -0.2982883,
        store_longitude: -78.5324,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2423428,
        store_longitude: -78.5207732,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2429551,
        store_longitude: -78.5141224,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3113803,
        store_longitude: -78.525828,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2401385,
        store_longitude: -78.4932662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.333466,
        store_longitude: -78.54542,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2591786,
        store_longitude: -78.525584,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2656131,
        store_longitude: -78.5272653,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246789,
        store_longitude: -78.529886,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.237383,
        store_longitude: -78.506948,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2380306,
        store_longitude: -78.5161318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2264407,
        store_longitude: -78.5215375,
    },
    {
        store_latitdue: -0.3020959,
        store_longitude: -78.5237828,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306745,
        store_longitude: -78.5245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223964,
        store_longitude: -78.521107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2999156,
        store_longitude: -78.5259565,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3014352,
        store_longitude: -78.5230971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3137865,
        store_longitude: -78.5359482,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129217,
        store_longitude: -78.4832242,
    },
    {
        store_latitdue: -0.2542424,
        store_longitude: -78.4849374,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3581766,
        store_longitude: -78.5471402,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.225485,
        store_longitude: -78.526873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257386,
        store_longitude: -78.521877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265371,
        store_longitude: -78.529086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2854422,
        store_longitude: -78.5200104,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239939,
        store_longitude: -78.504349,
    },
    {
        store_latitdue: -0.287844,
        store_longitude: -78.5264,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2276772,
        store_longitude: -78.5169179,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.5124863,
        store_longitude: -78.5124863,
    },
    {
        store_latitdue: -0.3354884,
        store_longitude: -78.5465032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3071569,
        store_longitude: -78.5495684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2840682,
        store_longitude: -78.5418471,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.277647,
        store_longitude: -78.5414,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2776156,
        store_longitude: -78.5412614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2416712,
        store_longitude: -78.5101153,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.320871,
        store_longitude: -78.5311912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.31468,
        store_longitude: -78.5335,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.332172,
        store_longitude: -78.5397,
    },
    {
        store_latitdue: -0.2410217,
        store_longitude: -78.4861449,
    },
    {
        store_latitdue: -0.338383,
        store_longitude: -78.5414976,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2455778,
        store_longitude: -78.5060316,
    },
    {
        store_latitdue: -0.293952,
        store_longitude: -78.4761,
    },
    {
        store_latitdue: -0.2299154,
        store_longitude: -78.5332717,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258133,
        store_longitude: -78.5112,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.351843,
        store_longitude: -78.5307,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225324,
        store_longitude: -78.5248,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2198996,
        store_longitude: -78.5216069,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2498885518,
        store_longitude: -78.5277602077,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312781,
        store_longitude: -78.5682,
    },
    {
        store_latitdue: -0.2388743,
        store_longitude: -78.5278393,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.190653,
        store_longitude: -78.5086,
    },
    {
        store_latitdue: -0.237451,
        store_longitude: -78.508182,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.019839,
        store_longitude: -79.401149,
    },
    {
        store_latitdue: -0.251417,
        store_longitude: -78.491142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3038267,
        store_longitude: -78.5413783,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2382305,
        store_longitude: -78.48951,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.376154,
        store_longitude: -78.5507,
    },
    {
        store_latitdue: -0.245604,
        store_longitude: -78.5354,
    },
    {
        store_latitdue: -0.3902486,
        store_longitude: -78.5450517,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3274476,
        store_longitude: -78.5499443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2362248,
        store_longitude: -78.5206069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3191779,
        store_longitude: -78.4601027,
    },
    {
        store_latitdue: -0.297748,
        store_longitude: -78.532353,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.239608,
        store_longitude: -78.512337,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.290735,
        store_longitude: -78.5365051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2428779,
        store_longitude: -78.5146792,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2254821,
        store_longitude: -78.517547,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292157,
        store_longitude: -78.5311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.37221285,
        store_longitude: -78.5323977,
    },
    {
        store_latitdue: -0.297839,
        store_longitude: -78.53025,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338594,
        store_longitude: -78.551,
    },
    {
        store_latitdue: -0.253311,
        store_longitude: -78.5162397,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.375119,
        store_longitude: -78.5490659,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.2581862,
        store_longitude: -78.5114753,
    },
    {
        store_latitdue: -0.26477697,
        store_longitude: -78.5205688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2414606,
        store_longitude: -78.5385569,
    },
    {
        store_latitdue: -0.2626578,
        store_longitude: -78.526104,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2837336,
        store_longitude: -78.5351709,
    },
    {
        store_latitdue: -0.252769,
        store_longitude: -78.5382,
    },
    {
        store_latitdue: -0.286938,
        store_longitude: -78.5281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2846513,
        store_longitude: -78.5314464,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258808,
        store_longitude: -78.4955077,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2707767,
        store_longitude: -78.5638317,
    },
    {
        store_latitdue: -0.254404,
        store_longitude: -78.492831,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3282636,
        store_longitude: -78.5392883,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.242651,
        store_longitude: -78.492116,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2413805,
        store_longitude: -78.5114814,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2212936,
        store_longitude: -78.5216203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2462214,
        store_longitude: -78.5267327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.298422,
        store_longitude: -78.533254,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2990113,
        store_longitude: -78.5332775,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3274429,
        store_longitude: -78.5504766,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.333030529,
        store_longitude: -78.52745173,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3161044,
        store_longitude: -78.5511848,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2504295,
        store_longitude: -78.5277572,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2416438,
        store_longitude: -78.5279287,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 1.2889499,
        store_longitude: -78.8359401,
    },
    {
        store_latitdue: -0.238002,
        store_longitude: -78.520404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3325358,
        store_longitude: -78.5238822,
    },
    {
        store_latitdue: -0.298418,
        store_longitude: -78.5406,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3023,
        store_longitude: -78.539,
    },
    {
        store_latitdue: -0.308617,
        store_longitude: -78.528998,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.254072,
        store_longitude: -78.484388,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2507901,
        store_longitude: -78.4897662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.323421,
        store_longitude: -78.5544,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2398869,
        store_longitude: -78.5263808,
    },
    {
        store_latitdue: -0.24577,
        store_longitude: -78.532648,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305452,
        store_longitude: -78.5227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2569038,
        store_longitude: -78.5399559,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.307262,
        store_longitude: -78.529022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306151,
        store_longitude: -78.5255,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240427,
        store_longitude: -78.492093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2246588,
        store_longitude: -78.5323912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2210558,
        store_longitude: -78.5232975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.222418,
        store_longitude: -78.5193,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2597866,
        store_longitude: -78.5250879,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2906196,
        store_longitude: -78.5448009,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.30111,
        store_longitude: -78.523278,
    },
    {
        store_latitdue: -0.2101128,
        store_longitude: -78.5027922,
    },
    {
        store_latitdue: -0.264358,
        store_longitude: -78.5227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.191435,
        store_longitude: -78.4978,
    },
    {
        store_latitdue: -0.27281159,
        store_longitude: -78.5218942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312378,
        store_longitude: -78.55258,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.232234,
        store_longitude: -78.4907,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3485189,
        store_longitude: -78.5488024,
    },
    {
        store_latitdue: -0.2438918,
        store_longitude: -78.5083112,
    },
    {
        store_latitdue: -0.244357,
        store_longitude: -78.507453,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2353883,
        store_longitude: -78.5274622,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2342943,
        store_longitude: -78.5337256,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23315818,
        store_longitude: -78.5334091,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2397851,
        store_longitude: -78.5423725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2711693132,
        store_longitude: -78.520160839,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.338768,
        store_longitude: -78.5291331,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3010482,
        store_longitude: -78.523223,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.23079686,
        store_longitude: -78.53477397,
    },
    {
        store_latitdue: -0.2965745,
        store_longitude: -78.544731,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241543,
        store_longitude: -78.528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2718765,
        store_longitude: -78.5234993,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292119,
        store_longitude: -78.523446,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3157136,
        store_longitude: -78.5516029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256794,
        store_longitude: -78.556791,
    },
    {
        store_latitdue: -0.2449093,
        store_longitude: -78.5183068,
    },
    {
        store_latitdue: -0.2321,
        store_longitude: -78.52571,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3633521,
        store_longitude: -78.5467317,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.2541731,
        store_longitude: -78.5143385,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229221,
        store_longitude: -78.5253,
    },
    {
        store_latitdue: -0.234134,
        store_longitude: -78.5335,
    },
    {
        store_latitdue: -0.2255854,
        store_longitude: -78.5187973,
    },
    {
        store_latitdue: -0.298538,
        store_longitude: -78.5323,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241948,
        store_longitude: -78.5159,
    },
    {
        store_latitdue: -0.291081,
        store_longitude: -78.5301,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.297203,
        store_longitude: -78.5648,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.312649,
        store_longitude: -78.525786,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251843,
        store_longitude: -78.528252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229412,
        store_longitude: -78.5255,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.241185,
        store_longitude: -78.518069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241346,
        store_longitude: -78.517647,
    },
    {
        store_latitdue: -0.3072755,
        store_longitude: -78.5354754,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.242057915,
        store_longitude: -78.50679338,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.243175,
        store_longitude: -78.5086,
    },
    {
        store_latitdue: -0.303985,
        store_longitude: -78.520811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3332499,
        store_longitude: -78.5502555,
    },
    {
        store_latitdue: -0.2543165,
        store_longitude: -78.515768,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.255676,
        store_longitude: -78.507,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2746654,
        store_longitude: -78.536574,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.24354,
        store_longitude: -78.519835,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269001,
        store_longitude: -78.4634,
    },
    {
        store_latitdue: -0.315182,
        store_longitude: -78.5230439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2494012,
        store_longitude: -78.5160298,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.256524,
        store_longitude: -78.5163,
    },
    {
        store_latitdue: -0.2443454,
        store_longitude: -78.5146293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3108707,
        store_longitude: -78.5457185,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303086,
        store_longitude: -78.5221262,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2916241,
        store_longitude: -78.5732249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.329055,
        store_longitude: -78.5302722,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3230517,
        store_longitude: -78.5454083,
    },
    {
        store_latitdue: -0.2345556,
        store_longitude: -78.490135,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2314746,
        store_longitude: -78.4913389,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -1.172962,
        store_longitude: -78.542384,
    },
    {
        store_latitdue: -0.2629782,
        store_longitude: -78.5298136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278222,
        store_longitude: -78.5143,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2851924,
        store_longitude: -78.5344066,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2897762,
        store_longitude: -78.5359129,
    },
    {
        store_latitdue: -0.2327269,
        store_longitude: -78.4832131,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.303886,
        store_longitude: -78.521008,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3040045,
        store_longitude: -78.5210205,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2536654,
        store_longitude: -78.5076862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3055512,
        store_longitude: -78.5483056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27835444,
        store_longitude: -78.5230389,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2990568,
        store_longitude: -78.5321642,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3397284,
        store_longitude: -78.5291371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.32496892,
        store_longitude: -78.5527928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22136,
        store_longitude: -78.4842,
    },
    {
        store_latitdue: -0.2659642,
        store_longitude: -78.5269594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2409258,
        store_longitude: -78.5067845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276342,
        store_longitude: -78.5283,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.319182,
        store_longitude: -78.5274,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2897793,
        store_longitude: -78.5296659,
    },
    {
        store_latitdue: -0.2389778,
        store_longitude: -78.5313882,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23793,
        store_longitude: -78.53144,
    },
    {
        store_latitdue: -0.260762,
        store_longitude: -78.5274711,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2868803,
        store_longitude: -78.5333967,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2296924,
        store_longitude: -78.5128564,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258801799,
        store_longitude: -78.49641916,
    },
    {
        store_latitdue: -0.355441,
        store_longitude: -78.5317,
    },
    {
        store_latitdue: -0.2217614,
        store_longitude: -78.5223131,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261778,
        store_longitude: -78.512662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.287013,
        store_longitude: -78.5311,
    },
    {
        store_latitdue: -0.236877,
        store_longitude: -78.5132,
    },
    {
        store_latitdue: -0.2914775,
        store_longitude: -78.5276523,
    },
    {
        store_latitdue: -0.253205,
        store_longitude: -78.494283,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2538667,
        store_longitude: -78.4941565,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254392,
        store_longitude: -78.4928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3333415,
        store_longitude: -78.5289398,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3125728,
        store_longitude: -78.524427,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3381153,
        store_longitude: -78.5355891,
    },
    {
        store_latitdue: -0.3071262,
        store_longitude: -78.5496468,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2446257,
        store_longitude: -78.5178878,
    },
    {
        store_latitdue: -0.2470691,
        store_longitude: -78.5121282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.289113,
        store_longitude: -78.5179,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3155451,
        store_longitude: -78.5547231,
    },
    {
        store_latitdue: -0.268855,
        store_longitude: -78.5271,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2362311,
        store_longitude: -78.5139151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304339,
        store_longitude: -78.5406712,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255638,
        store_longitude: -78.490567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.25004,
        store_longitude: -78.4977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253959,
        store_longitude: -78.484536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2532048,
        store_longitude: -78.5199512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306447,
        store_longitude: -78.5259,
    },
    {
        store_latitdue: -0.2422767,
        store_longitude: -78.5274145,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.272858,
        store_longitude: -78.5235789,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2755725,
        store_longitude: -78.5115954,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2333339,
        store_longitude: -78.4489112,
    },
    {
        store_latitdue: -0.25384,
        store_longitude: -78.5016,
    },
    {
        store_latitdue: -0.232717,
        store_longitude: -78.491411,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.257525,
        store_longitude: -78.517753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.299852,
        store_longitude: -78.530243,
    },
    {
        store_latitdue: -0.32772,
        store_longitude: -78.5487,
    },
    {
        store_latitdue: -0.280362,
        store_longitude: -78.5409,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2229786,
        store_longitude: -78.5055779,
    },
    {
        store_latitdue: -0.2449999,
        store_longitude: -78.5154325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2549059,
        store_longitude: -78.5045609,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2827416,
        store_longitude: -78.5377061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3262897,
        store_longitude: -78.5497241,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.331724,
        store_longitude: -78.524627,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.231284,
        store_longitude: -78.5269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2972984,
        store_longitude: -78.5469284,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253043,
        store_longitude: -78.5375,
    },
    {
        store_latitdue: -0.2830228621,
        store_longitude: -78.5418055952,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2734357,
        store_longitude: -78.5183401,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3347655,
        store_longitude: -78.5342651,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2529617,
        store_longitude: -78.4930433,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2630309,
        store_longitude: -78.4994079,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.247599,
        store_longitude: -78.51321,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2863854,
        store_longitude: -78.5385014,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2884615,
        store_longitude: -78.543975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296356,
        store_longitude: -78.566596,
    },
    {
        store_latitdue: -0.3314232,
        store_longitude: -78.5241086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250887,
        store_longitude: -78.489759,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2214799,
        store_longitude: -78.5230192,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2445053,
        store_longitude: -78.510652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2612789,
        store_longitude: -78.5219597,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288419,
        store_longitude: -78.5428,
    },
    {
        store_latitdue: -0.2956902,
        store_longitude: -78.525056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3334985,
        store_longitude: -78.5370217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2453288,
        store_longitude: -78.53226,
    },
    {
        store_latitdue: -0.3109919,
        store_longitude: -78.5456104,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3277781,
        store_longitude: -78.5476602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3047144,
        store_longitude: -78.5388505,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.240123,
        store_longitude: -78.5135,
    },
    {
        store_latitdue: -0.260546,
        store_longitude: -78.520013,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2638267,
        store_longitude: -78.526639,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250474666,
        store_longitude: -78.49092433,
    },
    {
        store_latitdue: -0.5335449,
        store_longitude: -78.5335449,
    },
    {
        store_latitdue: -0.244643,
        store_longitude: -78.499439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32684,
        store_longitude: -78.52101,
    },
    {
        store_latitdue: -0.126461,
        store_longitude: -78.4868,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2485572,
        store_longitude: -78.5158448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0675908,
        store_longitude: -78.414764,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.3066198,
        store_longitude: -78.526001,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3154555,
        store_longitude: -78.5477582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.300767,
        store_longitude: -78.5234938,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.360213,
        store_longitude: -78.5638,
    },
    {
        store_latitdue: -0.328396,
        store_longitude: -78.5349,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3191064,
        store_longitude: -78.5486488,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.252059,
        store_longitude: -78.516123,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.228355,
        store_longitude: -78.512861,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.246351,
        store_longitude: -78.5121,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26664,
        store_longitude: -78.527075,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2799253,
        store_longitude: -78.5418586,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.298756,
        store_longitude: -78.5304,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3345034,
        store_longitude: -78.5318445,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.238431,
        store_longitude: -78.5173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32218461,
        store_longitude: -78.5539734,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2448963,
        store_longitude: -78.5155954,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24083,
        store_longitude: -78.5126,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281002,
        store_longitude: -78.4705375,
    },
    {
        store_latitdue: -0.2804358,
        store_longitude: -78.5174054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2451754,
        store_longitude: -78.4979205,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3346665,
        store_longitude: -78.5419158,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.29345,
        store_longitude: -78.436,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2386093,
        store_longitude: -78.4895398,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254764,
        store_longitude: -78.515345,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2525145,
        store_longitude: -78.5113825,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1763905,
        store_longitude: -78.475947,
    },
    {
        store_latitdue: -0.241624,
        store_longitude: -78.527974,
    },
    {
        store_latitdue: -0.2390165,
        store_longitude: -78.5138445,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3181817,
        store_longitude: -78.5505441,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.24141,
        store_longitude: -78.514,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2385356,
        store_longitude: -78.5232636,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2765832,
        store_longitude: -78.5349431,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293791,
        store_longitude: -78.518,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3083994,
        store_longitude: -78.528058,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256151,
        store_longitude: -78.503892,
    },
    {
        store_latitdue: -0.3345119,
        store_longitude: -78.5255069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246334,
        store_longitude: -78.510629,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2364802,
        store_longitude: -78.5300968,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2998413,
        store_longitude: -78.5466087,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2785,
        store_longitude: -78.5273,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25246497,
        store_longitude: -78.4939956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2528678,
        store_longitude: -78.5100722,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.300484,
        store_longitude: -78.525757,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3038246,
        store_longitude: -78.5670515,
    },
    {
        store_latitdue: -0.38533833,
        store_longitude: -78.5421566,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.31138,
        store_longitude: -78.54515,
    },
    {
        store_latitdue: -0.2271566,
        store_longitude: -78.5032654,
    },
    {
        store_latitdue: -0.2654287,
        store_longitude: -78.5144637,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.274132,
        store_longitude: -78.525671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23670326,
        store_longitude: -78.5138673,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2541,
        store_longitude: -78.4943,
    },
    {
        store_latitdue: -0.3226027,
        store_longitude: -78.5379163,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.309875,
        store_longitude: -78.5261683,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.307243,
        store_longitude: -78.5492,
    },
    {
        store_latitdue: -0.2229742,
        store_longitude: -78.5206704,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.239623161,
        store_longitude: -78.5351084545,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2282725,
        store_longitude: -78.5164482,
    },
    {
        store_latitdue: -0.2781364,
        store_longitude: -78.5142765,
    },
    {
        store_latitdue: -0.2430748,
        store_longitude: -78.5267975,
    },
    {
        store_latitdue: -0.2479731,
        store_longitude: -78.5018512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2504806,
        store_longitude: -78.4918597,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2535483,
        store_longitude: -78.5070692,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263876,
        store_longitude: -78.524053,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2326151,
        store_longitude: -78.5056167,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.279927,
        store_longitude: -78.5832347,
    },
    {
        store_latitdue: -0.3384655,
        store_longitude: -78.5439995,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251789102,
        store_longitude: -78.504838552,
    },
    {
        store_latitdue: -0.2884259,
        store_longitude: -78.5430432,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253444,
        store_longitude: -78.505621,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.288262,
        store_longitude: -78.542816,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2549087,
        store_longitude: -78.5273076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2551611,
        store_longitude: -78.4977803,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3279027,
        store_longitude: -78.5378564,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3032352,
        store_longitude: -78.5412678,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3408459,
        store_longitude: -78.5414212,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3229085,
        store_longitude: -78.5457285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3242705,
        store_longitude: -78.5493692,
    },
    {
        store_latitdue: -0.3337918,
        store_longitude: -78.5479634,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.262163,
        store_longitude: -78.5218536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265089,
        store_longitude: -78.519266,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2492709,
        store_longitude: -78.5161811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.27278,
        store_longitude: -78.531,
    },
    {
        store_latitdue: -0.245885,
        store_longitude: -78.527046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.282194,
        store_longitude: -78.539,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268034,
        store_longitude: -78.5286,
    },
    {
        store_latitdue: -0.28617441,
        store_longitude: -78.5393688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2774541,
        store_longitude: -78.555595,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2900057,
        store_longitude: -78.5291275,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2924444,
        store_longitude: -78.520515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2930761,
        store_longitude: -78.5216358,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27705228,
        store_longitude: -78.5153427,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.27439257,
        store_longitude: -78.5399093,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322551,
        store_longitude: -78.5355,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.326798,
        store_longitude: -78.5373,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3152862,
        store_longitude: -78.5366624,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3072625,
        store_longitude: -78.5295735,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3068451,
        store_longitude: -78.5232119,
    },
    {
        store_latitdue: -0.333953,
        store_longitude: -78.5270952,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.36951881,
        store_longitude: -78.5422973,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3712799,
        store_longitude: -78.542406,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3240727,
        store_longitude: -78.5460291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2544513,
        store_longitude: -78.5042503,
    },
    {
        store_latitdue: -0.2515329584,
        store_longitude: -78.520305661,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.242545,
        store_longitude: -78.5286928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3082156,
        store_longitude: -78.5447779,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.24962,
        store_longitude: -78.51698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240172,
        store_longitude: -78.5299774,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25065299,
        store_longitude: -78.5303738,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2923416,
        store_longitude: -78.5204802,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2874774,
        store_longitude: -78.537285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2878724,
        store_longitude: -78.5288257,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288419377,
        store_longitude: -78.5250672,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3671553562,
        store_longitude: -78.5486230999,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.33792,
        store_longitude: -78.54725,
    },
    {
        store_latitdue: -0.3494401,
        store_longitude: -78.5348425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338825,
        store_longitude: -78.545503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3114023,
        store_longitude: -78.5504584,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.226281,
        store_longitude: -78.5126723,
    },
    {
        store_latitdue: -0.3076711,
        store_longitude: -78.5462712,
    },
    {
        store_latitdue: -0.29153,
        store_longitude: -78.5198,
    },
    {
        store_latitdue: -0.2406407,
        store_longitude: -78.5204495,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.304585,
        store_longitude: -78.539088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3125509,
        store_longitude: -78.5267633,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.351752,
        store_longitude: -78.5291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.356072,
        store_longitude: -78.5292,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3625241,
        store_longitude: -78.5472563,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.257108,
        store_longitude: -78.5061,
    },
    {
        store_latitdue: -0.256419,
        store_longitude: -78.5045,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256629,
        store_longitude: -78.514231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.087504,
        store_longitude: -76.9044,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2377003,
        store_longitude: -78.5324454,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2582414079,
        store_longitude: -78.4924600723,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.30477,
        store_longitude: -78.5373,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.349691,
        store_longitude: -78.5346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3289017,
        store_longitude: -78.5444444,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3520913,
        store_longitude: -78.5327233,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3015071,
        store_longitude: -78.5669392,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3421663,
        store_longitude: -78.5689188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278548,
        store_longitude: -78.5554,
    },
    {
        store_latitdue: -0.2109153,
        store_longitude: -78.4821586,
    },
    {
        store_latitdue: -0.2920477,
        store_longitude: -78.5198077,
    },
    {
        store_latitdue: -0.294642,
        store_longitude: -78.521,
    },
    {
        store_latitdue: -0.358630288,
        store_longitude: -78.56870455,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2717305,
        store_longitude: -78.5334307,
    },
    {
        store_latitdue: -0.2660175241,
        store_longitude: -78.5230067107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23197,
        store_longitude: -78.4832,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.258596,
        store_longitude: -78.5326,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2714137,
        store_longitude: -78.5359118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.264273,
        store_longitude: -78.5213,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.305987,
        store_longitude: -78.549761,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2719833,
        store_longitude: -78.5271833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249953,
        store_longitude: -78.4963,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2556693,
        store_longitude: -78.5189833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27985,
        store_longitude: -78.5418,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3285685,
        store_longitude: -78.5356511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2934251,
        store_longitude: -78.56693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226728,
        store_longitude: -78.525448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2948446,
        store_longitude: -78.5438761,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2757228,
        store_longitude: -78.5258477,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.24637,
        store_longitude: -78.4999,
    },
    {
        store_latitdue: -0.218288,
        store_longitude: -78.5237,
    },
    {
        store_latitdue: -0.279124,
        store_longitude: -78.5116,
    },
    {
        store_latitdue: -0.315528,
        store_longitude: -78.552521,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.23202,
        store_longitude: -78.52586,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2367371,
        store_longitude: -78.5299548,
    },

    {
        store_latitdue: -0.2953669,
        store_longitude: -78.5487351,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3009506,
        store_longitude: -78.5416234,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.299833,
        store_longitude: -78.54684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.354958,
        store_longitude: -78.5377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3293354,
        store_longitude: -78.5488436,
    },

    {
        store_latitdue: -0.2421217,
        store_longitude: -78.4881363,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3231535,
        store_longitude: -78.5507288,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2626472,
        store_longitude: -78.5297229,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3002389,
        store_longitude: -78.4473811,
        neighborhood: 'ALANGASÍ',
    },

    {
        store_latitdue: -0.3405597,
        store_longitude: -78.4539973,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.31676,
        store_longitude: -78.5277,
    },
    {
        store_latitdue: -0.2875223,
        store_longitude: -78.5306899,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.237865,
        store_longitude: -78.5096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.326726,
        store_longitude: -78.553246,
    },
    {
        store_latitdue: -0.241018,
        store_longitude: -78.5152451,
    },
    {
        store_latitdue: -0.219939,
        store_longitude: -78.526952,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.248055,
        store_longitude: -78.487407,
    },
    {
        store_latitdue: -0.244003,
        store_longitude: -78.499225,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2405041,
        store_longitude: -78.5126091,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2762417,
        store_longitude: -78.5280083,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2395027,
        store_longitude: -78.5119827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250125,
        store_longitude: -78.499184,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2409981,
        store_longitude: -78.5146898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2382128,
        store_longitude: -78.5164234,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28028666666667,
        store_longitude: -78.515895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24632329,
        store_longitude: -78.5137188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3132425,
        store_longitude: -78.5498152,
    },
    {
        store_latitdue: -0.339754,
        store_longitude: -78.5355,
    },
    {
        store_latitdue: -0.27157166666667,
        store_longitude: -78.523321666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.343678,
        store_longitude: -78.5597,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3534394,
        store_longitude: -78.5470641,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.191548,
        store_longitude: -78.49,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241364,
        store_longitude: -78.5313,
    },

    {
        store_latitdue: -0.3190015,
        store_longitude: -78.5351325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3364322,
        store_longitude: -78.5363429,
    },
    {
        store_latitdue: -0.3415107,
        store_longitude: -78.5298169,
    },

    {
        store_latitdue: -0.2402224,
        store_longitude: -78.4933641,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3338886,
        store_longitude: -78.5253498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3187419,
        store_longitude: -78.5469417,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32995833333333,
        store_longitude: -78.524611666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3733423,
        store_longitude: -78.5494393,
        neighborhood: 'CUTUGLAHUA',
    },

    {
        store_latitdue: -0.2416689,
        store_longitude: -78.4943552,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2429054,
        store_longitude: -78.4962912,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241947,
        store_longitude: -78.489398,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.231301,
        store_longitude: -78.5268,
    },

    {
        store_latitdue: -0.25635,
        store_longitude: -78.5297,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235144,
        store_longitude: -78.52573,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235496,
        store_longitude: -78.527056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225359,
        store_longitude: -78.524896,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2421378,
        store_longitude: -78.4930665,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2431503,
        store_longitude: -78.4921912,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.228985,
        store_longitude: -78.5284,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3606442,
        store_longitude: -78.5611771,
        neighborhood: 'CUTUGLAHUA',
    },

    {
        store_latitdue: -0.242422,
        store_longitude: -78.493767,
    },

    {
        store_latitdue: -0.3562887,
        store_longitude: -78.5482652,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.324874,
        store_longitude: -78.544293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2952647,
        store_longitude: -78.5603049,
    },
    {
        store_latitdue: -0.248317,
        store_longitude: -78.5122696,
    },
    {
        store_latitdue: -0.279335,
        store_longitude: -78.540178,
    },

    {
        store_latitdue: -0.251662,
        store_longitude: -78.503315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246964,
        store_longitude: -78.508723,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2472893,
        store_longitude: -78.4883792,
    },
    {
        store_latitdue: -0.2460281,
        store_longitude: -78.4981339,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256392,
        store_longitude: -78.497672,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.2414775,
        store_longitude: -78.5338243,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2364024,
        store_longitude: -78.5394172,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.273962,
        store_longitude: -78.537049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277737,
        store_longitude: -78.531361,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2781874,
        store_longitude: -78.5375767,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2737794,
        store_longitude: -78.5325876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3069486,
        store_longitude: -78.5295599,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3157322,
        store_longitude: -78.5529503,
    },
    {
        store_latitdue: -0.318526,
        store_longitude: -78.5505,
    },

    {
        store_latitdue: -0.244332,
        store_longitude: -78.4971,
    },
    {
        store_latitdue: -0.2506731,
        store_longitude: -78.500314,
    },
    {
        store_latitdue: -0.259296,
        store_longitude: -78.51325,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.24212,
        store_longitude: -78.5368,
    },

    {
        store_latitdue: -0.3016949,
        store_longitude: -78.5302082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3052184,
        store_longitude: -78.5285116,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.248792,
        store_longitude: -78.5005572,
    },
    {
        store_latitdue: -0.2414677,
        store_longitude: -78.5440367,
    },

    {
        store_latitdue: -0.28831,
        store_longitude: -78.5213,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.30525554410148,
        store_longitude: -78.542795016628,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.213398,
        store_longitude: -78.5099,
    },

    {
        store_latitdue: -0.321795,
        store_longitude: -78.5503,
    },

    {
        store_latitdue: -0.320511,
        store_longitude: -78.5538581,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3046782,
        store_longitude: -78.5388721,
    },
    {
        store_latitdue: -0.3217493,
        store_longitude: -78.5531039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.324273,
        store_longitude: -78.5522,
    },
    {
        store_latitdue: -0.3043348,
        store_longitude: -78.5407252,
    },
    {
        store_latitdue: -0.255524,
        store_longitude: -78.505869,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.299852,
        store_longitude: -78.530243,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.30054335,
        store_longitude: -78.52981348,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.301502,
        store_longitude: -78.5308024,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3032578,
        store_longitude: -78.5391881,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3030397,
        store_longitude: -78.5391379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3544063,
        store_longitude: -78.5344239,
    },
    {
        store_latitdue: -0.2549343,
        store_longitude: -78.5115744,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2534039,
        store_longitude: -78.509684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26280497,
        store_longitude: -78.52627193,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3045014,
        store_longitude: -78.5397985,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292541,
        store_longitude: -78.5229,
    },
    {
        store_latitdue: -0.3043121,
        store_longitude: -78.5406136,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25767009,
        store_longitude: -78.50818691,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.287958,
        store_longitude: -78.5260662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257944,
        store_longitude: -78.541331,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3509117,
        store_longitude: -78.5352722,
    },
    {
        store_latitdue: -0.2921673,
        store_longitude: -78.5209613,
    },

    {
        store_latitdue: -0.3031506,
        store_longitude: -78.5316817,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2368216,
        store_longitude: -78.5297531,
    },
    {
        store_latitdue: -0.3155916,
        store_longitude: -78.5362263,
    },
    {
        store_latitdue: -0.3136682,
        store_longitude: -78.5335439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229798,
        store_longitude: -78.5334,
    },
    {
        store_latitdue: -0.2294732,
        store_longitude: -78.5365475,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.287652,
        store_longitude: -78.529425,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2877424,
        store_longitude: -78.530583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3321619,
        store_longitude: -78.5421772,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2860707,
        store_longitude: -78.5310958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3080175,
        store_longitude: -78.5358536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2567267,
        store_longitude: -78.5262696,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.256731,
        store_longitude: -78.523498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3069407,
        store_longitude: -78.5326123,
    },
    {
        store_latitdue: -0.3376718,
        store_longitude: -78.5458364,
    },
    {
        store_latitdue: -0.320274,
        store_longitude: -78.5623,
    },
    {
        store_latitdue: -0.3383242,
        store_longitude: -78.5452938,
    },

    {
        store_latitdue: -0.261301,
        store_longitude: -78.518027,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254432,
        store_longitude: -78.527442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286398,
        store_longitude: -78.5312,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338139,
        store_longitude: -78.5481,
    },
    {
        store_latitdue: -0.254989,
        store_longitude: -78.5265,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2557281,
        store_longitude: -78.5272179,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.283953,
        store_longitude: -78.5359208,
    },

    {
        store_latitdue: -0.193104,
        store_longitude: -78.497057,
    },
    {
        store_latitdue: -0.3139889,
        store_longitude: -78.5288173,
    },
    {
        store_latitdue: -0.3355543,
        store_longitude: -78.5468766,
    },
    {
        store_latitdue: -0.3369642,
        store_longitude: -78.5449725,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.32987538,
        store_longitude: -78.54418945,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2562033,
        store_longitude: -78.5246251,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3353047,
        store_longitude: -78.5394955,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223997,
        store_longitude: -78.52109,
    },
    {
        store_latitdue: -0.2222,
        store_longitude: -78.518705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223408,
        store_longitude: -78.5158,
    },

    {
        store_latitdue: -0.3370703,
        store_longitude: -78.5413008,
    },

    {
        store_latitdue: -0.2247328,
        store_longitude: -78.5155581,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3321421,
        store_longitude: -78.5440623,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2256322,
        store_longitude: -78.5156241,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279052,
        store_longitude: -78.52481,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3354849,
        store_longitude: -78.5398872,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3384625,
        store_longitude: -78.5420098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.24798,
        store_longitude: -78.5072,
    },

    {
        store_latitdue: -0.2243453,
        store_longitude: -78.5153975,
    },
    {
        store_latitdue: -0.3353283,
        store_longitude: -78.5418693,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.221406,
        store_longitude: -78.5202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.275394,
        store_longitude: -78.526103,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2942282,
        store_longitude: -78.5193971,
    },

    {
        store_latitdue: -0.3328216,
        store_longitude: -78.5417488,
    },
    {
        store_latitdue: -0.335347,
        store_longitude: -78.5419728,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.260741,
        store_longitude: -78.519439,
    },
    {
        store_latitdue: -0.2493893,
        store_longitude: -78.5295667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250142,
        store_longitude: -78.525364,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.3084806,
        store_longitude: -78.5260507,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3781153,
        store_longitude: -78.5518601,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.373844,
        store_longitude: -78.5482,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.29493751,
        store_longitude: -78.53056494,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308663,
        store_longitude: -78.523144,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.221737,
        store_longitude: -78.52367,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2217278,
        store_longitude: -78.5236245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22171,
        store_longitude: -78.5217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2471432,
        store_longitude: -78.5324151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2844667,
        store_longitude: -78.5411783,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3002858,
        store_longitude: -78.5238109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.1340875,
        store_longitude: -79.9580604,
    },
    {
        store_latitdue: -0.3327305,
        store_longitude: -78.5472207,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3323665,
        store_longitude: -78.5488111,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2598834,
        store_longitude: -78.507122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254541,
        store_longitude: -78.506647,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254457,
        store_longitude: -78.509183,
    },
    {
        store_latitdue: -0.161454,
        store_longitude: -78.4749,
    },
    {
        store_latitdue: -0.2208486,
        store_longitude: -78.5236528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276852,
        store_longitude: -78.541365,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3325803,
        store_longitude: -78.54915,
    },

    {
        store_latitdue: -0.3373764,
        store_longitude: -78.545281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.33774,
        store_longitude: -78.54627,
    },

    {
        store_latitdue: -0.321111,
        store_longitude: -78.571935,
    },

    {
        store_latitdue: -0.34297,
        store_longitude: -78.5399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286138,
        store_longitude: -78.5389,
    },

    {
        store_latitdue: -0.263161,
        store_longitude: -78.516564,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338085,
        store_longitude: -78.5371376,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.224644,
        store_longitude: -78.524556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22174,
        store_longitude: -78.5218,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3279332,
        store_longitude: -78.5424059,
    },

    {
        store_latitdue: -0.2605123,
        store_longitude: -78.5152471,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2241086,
        store_longitude: -78.5251941,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.284779,
        store_longitude: -78.534491,
    },
    {
        store_latitdue: -0.33184945,
        store_longitude: -78.546313,
    },

    {
        store_latitdue: -0.286495,
        store_longitude: -78.53331,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.26478,
        store_longitude: -78.5101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.136954,
        store_longitude: -78.499031,
    },

    {
        store_latitdue: -0.2824673,
        store_longitude: -78.5382597,
    },
    {
        store_latitdue: -0.289409,
        store_longitude: -78.5347,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2299679,
        store_longitude: -78.4888418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2607937,
        store_longitude: -78.5143224,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3371573,
        store_longitude: -78.5398216,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2504661,
        store_longitude: -78.5410613,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.256589,
        store_longitude: -78.527,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2068159,
        store_longitude: -78.4816358,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265771,
        store_longitude: -78.4885,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.2664588,
        store_longitude: -78.4884746,
    },
    {
        store_latitdue: -0.23861833333333,
        store_longitude: -78.490255,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2652862,
        store_longitude: -78.4887142,
    },
    {
        store_latitdue: -0.267115,
        store_longitude: -78.489593333333,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.223889,
        store_longitude: -78.524402,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.221374,
        store_longitude: -78.524386,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2195489,
        store_longitude: -78.5227341,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240044,
        store_longitude: -78.5414,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240011,
        store_longitude: -78.5136,
    },
    {
        store_latitdue: -0.2400773,
        store_longitude: -78.5136469,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25385666666667,
        store_longitude: -78.5036,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220284,
        store_longitude: -78.525928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.230575,
        store_longitude: -78.534596666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2214946,
        store_longitude: -78.5244763,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240732,
        store_longitude: -78.5399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2402323,
        store_longitude: -78.5140613,
    },

    {
        store_latitdue: -0.326402,
        store_longitude: -78.5403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258658,
        store_longitude: -78.521132,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.312135,
        store_longitude: -78.550504,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.311586,
        store_longitude: -78.545029,
    },

    {
        store_latitdue: -0.2860244,
        store_longitude: -78.5248376,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3047340525,
        store_longitude: -78.5526534542,
    },

    {
        store_latitdue: -0.330065,
        store_longitude: -78.536508,
    },

    {
        store_latitdue: -0.274157,
        store_longitude: -78.5213,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.382358,
        store_longitude: -78.5109,
    },

    {
        store_latitdue: -0.255801,
        store_longitude: -78.5178,
    },

    {
        store_latitdue: -0.333833,
        store_longitude: -78.534064,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.331725,
        store_longitude: -78.536426,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.335185,
        store_longitude: -78.533075,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3339867,
        store_longitude: -78.536095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.333342,
        store_longitude: -78.5367002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3342117,
        store_longitude: -78.5330354,
    },
    {
        store_latitdue: -0.226615,
        store_longitude: -78.521477,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2389794,
        store_longitude: -78.5062804,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29984462938054,
        store_longitude: -78.52401986903,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2726755,
        store_longitude: -78.5140371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2232276,
        store_longitude: -78.5194436,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2528753,
        store_longitude: -78.4930727,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250698,
        store_longitude: -78.4911,
    },
    {
        store_latitdue: -0.2844183,
        store_longitude: -78.5200017,
    },
    {
        store_latitdue: -0.251717,
        store_longitude: -78.494974,
    },
    {
        store_latitdue: -0.250988,
        store_longitude: -78.5269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2454398144,
        store_longitude: -78.5101753101,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2247104,
        store_longitude: -78.5166928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2422195,
        store_longitude: -78.5066004,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240914,
        store_longitude: -78.504706,
    },
    {
        store_latitdue: -0.3253877,
        store_longitude: -78.5355842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244645,
        store_longitude: -78.5176,
    },
    {
        store_latitdue: -0.2383217,
        store_longitude: -78.5115516,
    },
    {
        store_latitdue: -0.2377537,
        store_longitude: -78.5114983,
    },
    {
        store_latitdue: -0.2515912,
        store_longitude: -78.520549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2521912,
        store_longitude: -78.5197269,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.305288,
        store_longitude: -78.5499,
    },

    {
        store_latitdue: -0.3279746,
        store_longitude: -78.5433492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.233217,
        store_longitude: -78.531,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2562,
        store_longitude: -78.52084,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3099092,
        store_longitude: -78.5260304,
    },
    {
        store_latitdue: -0.2443673,
        store_longitude: -78.5185476,
    },

    {
        store_latitdue: -0.328054,
        store_longitude: -78.5406,
    },
    {
        store_latitdue: -0.301914,
        store_longitude: -78.5632,
    },
    {
        store_latitdue: -0.1256816,
        store_longitude: -78.465907,
    },
    {
        store_latitdue: -0.264169,
        store_longitude: -78.5237,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.330948,
        store_longitude: -78.5354,
    },

    {
        store_latitdue: -0.3348833,
        store_longitude: -78.5381493,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.272852,
        store_longitude: -78.5241,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2701892,
        store_longitude: -78.5244003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257434,
        store_longitude: -78.5212,
    },
    {
        store_latitdue: -0.265354,
        store_longitude: -78.520212,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.27555,
        store_longitude: -78.523,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.271655,
        store_longitude: -78.52164,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3325016,
        store_longitude: -78.5300509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.329762,
        store_longitude: -78.5337,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32922,
        store_longitude: -78.535,
    },
    {
        store_latitdue: -0.334693,
        store_longitude: -78.5342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3109191,
        store_longitude: -78.545703,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2304916,
        store_longitude: -78.5152764,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2794804,
        store_longitude: -78.5560934,
    },
    {
        store_latitdue: -0.271384,
        store_longitude: -78.5234,
    },
    {
        store_latitdue: -0.2735386,
        store_longitude: -78.5259445,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.254975,
        store_longitude: -78.5202,
    },
    {
        store_latitdue: -0.333751,
        store_longitude: -78.5380196,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2726864,
        store_longitude: -78.5263547,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.27557974,
        store_longitude: -78.53519873,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.238835,
        store_longitude: -78.533195,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.30264,
        store_longitude: -78.4828,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.31699,
        store_longitude: -78.1235,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3648023,
        store_longitude: -78.121152,
    },
    {
        store_latitdue: 0.8189602,
        store_longitude: -77.710248,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3176524,
        store_longitude: -78.1271085,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.367482,
        store_longitude: -78.1196,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8098935,
        store_longitude: -77.7241496,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.80325,
        store_longitude: -77.72516,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8265058,
        store_longitude: -77.6922155,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8226407,
        store_longitude: -77.707603,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8254738,
        store_longitude: -77.7060312,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3620578,
        store_longitude: -78.1333758,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3609226989,
        store_longitude: -78.1283075362,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3485418,
        store_longitude: -78.1229665,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.365435,
        store_longitude: -78.1265,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5075951,
        store_longitude: -77.8652962,
        neighborhood: 'LA PAZ',
    },
    {
        store_latitdue: 0.6064294,
        store_longitude: -77.98352,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: 0.503377,
        store_longitude: -77.9049929,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.79609,
        store_longitude: -77.7349,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8139215,
        store_longitude: -77.7201801,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.329169,
        store_longitude: -78.108,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.80277,
        store_longitude: -77.7198,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5964697,
        store_longitude: -77.8351628,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.82251,
        store_longitude: -77.70288,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.362937,
        store_longitude: -78.1323,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.806915,
        store_longitude: -77.7166,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.62166,
        store_longitude: -77.9399,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.8188199,
        store_longitude: -77.710702,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8212423,
        store_longitude: -77.7071238,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8096,
        store_longitude: -77.71696,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.82514,
        store_longitude: -77.71241,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.368561,
        store_longitude: -78.118095,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.81976,
        store_longitude: -77.6969,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6307896,
        store_longitude: -77.7250881,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.8341229,
        store_longitude: -77.7092243,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7983203,
        store_longitude: -77.7337303,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.615447,
        store_longitude: -77.9396,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.8131074,
        store_longitude: -77.7154894,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.80192,
        store_longitude: -77.72783,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.638452,
        store_longitude: -77.7251,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.82785,
        store_longitude: -77.69912,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5868561,
        store_longitude: -77.8278489,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.79568,
        store_longitude: -77.74535,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.81527,
        store_longitude: -77.71489,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.33674332,
        store_longitude: -78.122927,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.7850732,
        store_longitude: -77.7440217,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.321572,
        store_longitude: -78.1242,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8230939,
        store_longitude: -77.7114932,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.4765378,
        store_longitude: -78.0795777,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.8070373,
        store_longitude: -77.7172209,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8087867,
        store_longitude: -77.7205992,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.80743,
        store_longitude: -77.72231,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8202677,
        store_longitude: -77.7129694,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.79813,
        store_longitude: -77.72971,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8118942,
        store_longitude: -77.7186719,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3400736,
        store_longitude: -78.1460575,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5063259,
        store_longitude: -77.902774,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.82493,
        store_longitude: -77.68893,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8277352,
        store_longitude: -77.6955161,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.603592,
        store_longitude: -77.8152,
        neighborhood: 'CRISTÓBAL COLÓN',
    },
    {
        store_latitdue: 0.8201182,
        store_longitude: -77.7371502,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5978,
        store_longitude: -77.83507,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.8265746,
        store_longitude: -77.6970069,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8213505,
        store_longitude: -77.7070053,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8022258,
        store_longitude: -77.7305515,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8078281,
        store_longitude: -77.7216034,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5033417,
        store_longitude: -77.9031642,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.33946,
        store_longitude: -78.1116,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.81015,
        store_longitude: -77.72371,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8365501,
        store_longitude: -77.7052804,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.590352,
        store_longitude: -77.8279,
    },
    {
        store_latitdue: 0.81812,
        store_longitude: -77.71327,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.61929,
        store_longitude: -77.938029,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.361536,
        store_longitude: -78.1335,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3481751,
        store_longitude: -78.1368215,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.7993769,
        store_longitude: -77.727686,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8127723,
        store_longitude: -77.7129803,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.346732,
        store_longitude: -78.1228,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.81008,
        store_longitude: -77.7203,
    },
    {
        store_latitdue: 0.3242616,
        store_longitude: -78.1299952,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.7949452,
        store_longitude: -77.7346613,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.620413,
        store_longitude: -77.9392,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.79781,
        store_longitude: -77.7328,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5922003,
        store_longitude: -77.8311591,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.8152008,
        store_longitude: -77.7124834,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.353897,
        store_longitude: -78.1507,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5978973,
        store_longitude: -77.8282854,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.82804,
        store_longitude: -77.69813,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5133147,
        store_longitude: -77.8649203,
        neighborhood: 'LA PAZ',
    },
    {
        store_latitdue: 0.6309159,
        store_longitude: -77.7252008,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.8199926,
        store_longitude: -77.712724,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.79638,
        store_longitude: -77.7364,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8133643,
        store_longitude: -77.7178421,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8277207,
        store_longitude: -77.6986702,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8093619,
        store_longitude: -77.7198964,
    },
    {
        store_latitdue: 0.5852159,
        store_longitude: -77.8266031,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.8307288,
        store_longitude: -77.7149441,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6148899,
        store_longitude: -77.9444216,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.782921,
        store_longitude: -77.7422,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8017555,
        store_longitude: -77.7340964,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5956909,
        store_longitude: -77.8330399,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.5014538,
        store_longitude: -77.9036403,
    },
    {
        store_latitdue: 0.82038,
        store_longitude: -77.70684,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.782992,
        store_longitude: -77.742,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3404152,
        store_longitude: -78.11038,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8015425,
        store_longitude: -77.7335627,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6539768,
        store_longitude: -77.939432,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: 0.8198629,
        store_longitude: -77.7102424,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5573459,
        store_longitude: -78.0373918,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.8355921,
        store_longitude: -77.7103676,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.354944,
        store_longitude: -78.1202,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.38945,
        store_longitude: -78.1086,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3456937,
        store_longitude: -78.1220648,
    },
    {
        store_latitdue: 0.8203671,
        store_longitude: -77.6976799,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.81317,
        store_longitude: -77.71769,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7978972,
        store_longitude: -77.7313021,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.356807,
        store_longitude: -78.1281,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6622666,
        store_longitude: -77.7149991,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.33841,
        store_longitude: -78.1399,
    },
    {
        store_latitdue: 0.5870723,
        store_longitude: -77.825629,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.3646477,
        store_longitude: -78.1196819,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.553691,
        store_longitude: -78.0395,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.798488,
        store_longitude: -77.7407371,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3599563,
        store_longitude: -78.1105606,
    },
    {
        store_latitdue: 0.8105934,
        store_longitude: -77.7166165,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.819718,
        store_longitude: -77.7403384,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3459426,
        store_longitude: -78.1364409,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.631653,
        store_longitude: -77.7250338,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.8034449,
        store_longitude: -77.7287392,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6009929,
        store_longitude: -77.9894169,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: 0.327324,
        store_longitude: -78.1353,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.80851,
        store_longitude: -77.72538,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8177516,
        store_longitude: -77.7111563,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8053781,
        store_longitude: -77.7190004,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8253477,
        store_longitude: -77.7045,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.549926,
        store_longitude: -78.0406,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.8147451,
        store_longitude: -77.716725,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.82491,
        store_longitude: -77.70981,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.670058,
        store_longitude: -77.7126,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.493733,
        store_longitude: -78.0452,
        neighborhood: 'SAN VICENTE DE PUSIR',
    },
    {
        store_latitdue: 0.3157192,
        store_longitude: -78.1221037,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6427467,
        store_longitude: -77.7248167,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.5018632,
        store_longitude: -77.9054446,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.7949657,
        store_longitude: -77.7447885,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.81504,
        store_longitude: -77.7121,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8106457,
        store_longitude: -77.7235095,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8205669,
        store_longitude: -77.710258,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.805979,
        store_longitude: -77.7264442,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8130402,
        store_longitude: -77.7135815,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3466571,
        store_longitude: -78.1228922,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6365773,
        store_longitude: -77.72473,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.435296,
        store_longitude: -77.9695,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.8213622,
        store_longitude: -77.7122732,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6613036,
        store_longitude: -77.7181369,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.8325676,
        store_longitude: -77.711525,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.661138,
        store_longitude: -77.7178,
    },
    {
        store_latitdue: 0.8058498,
        store_longitude: -77.7257466,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.4403069,
        store_longitude: -77.9553399,
        neighborhood: 'LOS ANDES',
    },
    {
        store_latitdue: 0.3358638,
        store_longitude: -78.1208644,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.7968172,
        store_longitude: -77.7401191,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6594575,
        store_longitude: -77.7185396,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.6637119,
        store_longitude: -77.7118503,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.8094739,
        store_longitude: -77.7159757,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6005779,
        store_longitude: -77.8232742,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.361963,
        store_longitude: -78.1225,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.798553,
        store_longitude: -77.7296,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.346919,
        store_longitude: -78.1387,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3546714,
        store_longitude: -78.1291679,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.360479,
        store_longitude: -78.1212,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3414232,
        store_longitude: -78.1235291,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.797199,
        store_longitude: -77.7281542,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7795931,
        store_longitude: -77.7435565,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7940303,
        store_longitude: -77.7357091,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8312369,
        store_longitude: -77.7113953,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7968991,
        store_longitude: -77.7338883,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8232809,
        store_longitude: -77.712936,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3452364,
        store_longitude: -78.1216236,
    },
    {
        store_latitdue: 0.387635,
        store_longitude: -78.1072,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.657029,
        store_longitude: -77.7205,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.361278,
        store_longitude: -78.1336,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8061481,
        store_longitude: -77.7164072,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6185917,
        store_longitude: -77.7381717,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.353381,
        store_longitude: -78.129402,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.809929,
        store_longitude: -77.7194,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7762577,
        store_longitude: -77.7419381,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6025651,
        store_longitude: -77.8375476,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.7925079,
        store_longitude: -77.7429959,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5458029,
        store_longitude: -78.0428381,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.8052665,
        store_longitude: -77.7232923,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8320524,
        store_longitude: -77.7086214,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8044358,
        store_longitude: -77.7303666,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7973568,
        store_longitude: -77.7342502,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.616899,
        store_longitude: -77.7407,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.3607817,
        store_longitude: -78.121047,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6337417,
        store_longitude: -77.7246991,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.8136908,
        store_longitude: -77.7190483,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8267321,
        store_longitude: -77.7088077,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8058661,
        store_longitude: -77.7328772,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8327564,
        store_longitude: -77.711253,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8104456,
        store_longitude: -77.7284573,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8318936,
        store_longitude: -77.704531,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.803895,
        store_longitude: -77.728,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7993505,
        store_longitude: -77.7410013,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7877227,
        store_longitude: -77.7440158,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.549205,
        store_longitude: -78.0417,
    },
    {
        store_latitdue: 0.7986898,
        store_longitude: -77.7336478,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8151547,
        store_longitude: -77.7107448,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7917364,
        store_longitude: -77.7433331,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7711531,
        store_longitude: -77.738485,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8066311,
        store_longitude: -77.7164349,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8152187,
        store_longitude: -77.7117937,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.353616,
        store_longitude: -78.1277,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6334717,
        store_longitude: -77.7249737,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.334444,
        store_longitude: -78.1189,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8294292,
        store_longitude: -77.7152415,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3296737,
        store_longitude: -78.1139346,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8182875,
        store_longitude: -77.728301,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.824493,
        store_longitude: -77.7111,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8263023,
        store_longitude: -77.6924797,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5982955,
        store_longitude: -77.9911652,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: 0.813203,
        store_longitude: -77.7163,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5082356,
        store_longitude: -77.866708,
        neighborhood: 'LA PAZ',
    },
    {
        store_latitdue: 0.6185277,
        store_longitude: -77.9411564,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.3275815,
        store_longitude: -78.1280927,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8192339,
        store_longitude: -77.6978409,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8036097,
        store_longitude: -77.7258437,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6192245,
        store_longitude: -77.9392247,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.8152547,
        store_longitude: -77.7124112,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.810664,
        store_longitude: -77.7198124,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.535938,
        store_longitude: -78.0489912,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.608535,
        store_longitude: -77.8097833,
        neighborhood: 'CRISTÓBAL COLÓN',
    },
    {
        store_latitdue: 0.3847135,
        store_longitude: -78.1105632,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.50981,
        store_longitude: -77.8652,
        neighborhood: 'LA PAZ',
    },
    {
        store_latitdue: 0.385791,
        store_longitude: -78.1277,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8303842,
        store_longitude: -77.7105629,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.389298,
        store_longitude: -78.1074,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8250886,
        store_longitude: -77.7053978,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3625908,
        store_longitude: -78.1318146,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.805606,
        store_longitude: -77.7326,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7961536,
        store_longitude: -77.7193847,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.796515,
        store_longitude: -77.7359,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5013702,
        store_longitude: -77.9117852,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.824588,
        store_longitude: -77.7114,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6354968,
        store_longitude: -77.7506,
        neighborhood: 'SANTA MARTHA DE CUBA',
    },
    {
        store_latitdue: 0.601569,
        store_longitude: -77.8378,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: -0.81567,
        store_longitude: -77.7135,
    },
    {
        store_latitdue: 0.831118,
        store_longitude: -77.705417,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.661285,
        store_longitude: -77.7178,
    },
    {
        store_latitdue: 0.8025729,
        store_longitude: -77.7301237,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5947002,
        store_longitude: -77.8323688,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.354515,
        store_longitude: -78.1301,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.81437,
        store_longitude: -77.71794,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8132695,
        store_longitude: -77.7141203,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7981379,
        store_longitude: -77.7341202,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8023412,
        store_longitude: -77.7282071,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.345214,
        store_longitude: -78.1199,
    },
    {
        store_latitdue: 0.3863243,
        store_longitude: -78.1064149,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.796101,
        store_longitude: -77.73053,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7840449,
        store_longitude: -77.7457983,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.346069,
        store_longitude: -78.1095,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.362973,
        store_longitude: -78.1119,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.80945,
        store_longitude: -77.72145,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8036455,
        store_longitude: -77.7281096,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8095858,
        store_longitude: -77.7211926,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7939427,
        store_longitude: -77.7395849,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8092263,
        store_longitude: -77.7165546,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.516849,
        store_longitude: -77.8821188,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.3540533,
        store_longitude: -78.1514564,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3360063,
        store_longitude: -78.1281606,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.797469249,
        store_longitude: -77.7344138,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7921655,
        store_longitude: -77.7420527,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.349032,
        store_longitude: -78.1313,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5850084,
        store_longitude: -77.8255218,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.317092,
        store_longitude: -78.144,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.8316456,
        store_longitude: -77.715824,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8179319,
        store_longitude: -77.7136457,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3533519,
        store_longitude: -78.1166281,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.7949541,
        store_longitude: -77.7450401,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.321595,
        store_longitude: -78.1189,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8125657,
        store_longitude: -77.7201772,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.80701,
        store_longitude: -77.7203217,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8099937,
        store_longitude: -77.7204451,
    },
    {
        store_latitdue: 0.6691034,
        store_longitude: -77.945761,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: 0.79362,
        store_longitude: -77.74004,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3585964,
        store_longitude: -78.1193612,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.7901902,
        store_longitude: -77.7438442,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7954912,
        store_longitude: -77.7371391,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.779138,
        store_longitude: -77.7430587,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8044291,
        store_longitude: -77.7277358,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6213955,
        store_longitude: -77.9389789,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.5121089,
        store_longitude: -78.2120139,
        neighborhood: 'CAHUASQUÍ',
    },
    {
        store_latitdue: 0.7960754,
        store_longitude: -77.7303949,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.828559,
        store_longitude: -77.7129933,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6526091,
        store_longitude: -77.7214966,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.6359136,
        store_longitude: -77.7250526,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.3416838,
        store_longitude: -78.1101996,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8183157,
        store_longitude: -77.7115827,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.346802,
        store_longitude: -78.117,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.387207,
        store_longitude: -78.1095,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5056812,
        store_longitude: -77.8652143,
        neighborhood: 'LA PAZ',
    },
    {
        store_latitdue: 0.6215699,
        store_longitude: -77.940859,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.800752,
        store_longitude: -77.7326,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.813862,
        store_longitude: -77.7141,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5107305,
        store_longitude: -77.866817,
        neighborhood: 'LA PAZ',
    },
    {
        store_latitdue: 0.5880362,
        store_longitude: -77.8257591,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.5496197,
        store_longitude: -78.0396588,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.81551,
        store_longitude: -77.7157,
    },
    {
        store_latitdue: 0.4650281,
        store_longitude: -78.0128915,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.6602664,
        store_longitude: -77.7183124,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.5133273,
        store_longitude: -77.8649242,
        neighborhood: 'LA PAZ',
    },
    {
        store_latitdue: 0.8102261,
        store_longitude: -77.7129213,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.324878,
        store_longitude: -78.1055,
    },
    {
        store_latitdue: 0.6337324,
        store_longitude: -77.9348535,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.802851,
        store_longitude: -77.7341,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6506606,
        store_longitude: -77.7221621,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.652756,
        store_longitude: -77.7214421,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.342106,
        store_longitude: -78.1424626,
    },
    {
        store_latitdue: 0.6638502,
        store_longitude: -77.7139976,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.3482976,
        store_longitude: -78.1155767,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8010244,
        store_longitude: -77.7312617,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.83092,
        store_longitude: -77.7054633,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.81969,
        store_longitude: -77.7092,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5151018,
        store_longitude: -77.8815257,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.603361,
        store_longitude: -77.8155956,
        neighborhood: 'CRISTÓBAL COLÓN',
    },
    {
        store_latitdue: 0.8116183,
        store_longitude: -77.7195552,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7979852,
        store_longitude: -77.7286436,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3401417,
        store_longitude: -78.1442883,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.654787,
        store_longitude: -77.722,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.819406,
        store_longitude: -77.7098,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.598131,
        store_longitude: -77.8357008,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.8098421,
        store_longitude: -77.7297938,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.345363,
        store_longitude: -78.1221,
    },
    {
        store_latitdue: 0.623473,
        store_longitude: -77.9453061,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.6060148,
        store_longitude: -77.8118308,
        neighborhood: 'CRISTÓBAL COLÓN',
    },
    {
        store_latitdue: 0.620747,
        store_longitude: -77.9464,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.809344,
        store_longitude: -77.7198945,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7989796,
        store_longitude: -77.7386497,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6214519,
        store_longitude: -77.939961,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.502178,
        store_longitude: -77.9061,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.8076099,
        store_longitude: -77.7198587,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.592134,
        store_longitude: -77.8308,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.5015542,
        store_longitude: -77.9034395,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.5926537,
        store_longitude: -77.8288238,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.6560327,
        store_longitude: -77.9368139,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: 0.81173415,
        store_longitude: -77.7145041,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.346793,
        store_longitude: -78.1218,
    },
    {
        store_latitdue: 0.621172,
        store_longitude: -77.9408,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.8040193,
        store_longitude: -77.7270226,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6253446,
        store_longitude: -77.9378135,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.8197981,
        store_longitude: -77.6971248,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3451609,
        store_longitude: -78.1145127,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6651583,
        store_longitude: -77.7160448,
    },
    {
        store_latitdue: 0.8133036,
        store_longitude: -77.7161455,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.360713,
        store_longitude: -78.134,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.588543,
        store_longitude: -77.8265684,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.7942836,
        store_longitude: -77.7376204,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.599236,
        store_longitude: -77.834,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.588735,
        store_longitude: -77.8259,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.383851,
        store_longitude: -78.1063,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.366198,
        store_longitude: -78.1255,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6202174,
        store_longitude: -77.94118,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.5079653,
        store_longitude: -77.9046256,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.3570939,
        store_longitude: -78.13271,
    },
    {
        store_latitdue: 0.8139604,
        store_longitude: -77.7159729,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.819748,
        store_longitude: -77.6975,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.825978,
        store_longitude: -77.7055,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6706073,
        store_longitude: -77.7127102,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.341719,
        store_longitude: -78.110689,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8048312,
        store_longitude: -77.7264033,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8035189,
        store_longitude: -77.7261594,
    },
    {
        store_latitdue: 0.80967,
        store_longitude: -77.7209,
    },
    {
        store_latitdue: 0.6263997,
        store_longitude: -77.7286625,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.8127764,
        store_longitude: -77.7136839,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.821639,
        store_longitude: -77.7078,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8205608,
        store_longitude: -77.7325295,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6142899,
        store_longitude: -77.9398925,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.5491636,
        store_longitude: -78.041618,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.824124,
        store_longitude: -77.706128,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.817117,
        store_longitude: -77.7148,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5980729,
        store_longitude: -77.8324979,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.8090423,
        store_longitude: -77.7189158,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3837839,
        store_longitude: -78.1090318,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8030024,
        store_longitude: -77.7297056,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5848814,
        store_longitude: -77.8242459,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.6613055,
        store_longitude: -77.7185494,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.6661596,
        store_longitude: -77.712228,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.8039806,
        store_longitude: -77.7308675,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6636156,
        store_longitude: -77.717588,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.8111938,
        store_longitude: -77.7193232,
    },
    {
        store_latitdue: 0.591277117,
        store_longitude: -77.8290903,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.825663,
        store_longitude: -77.7123,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5539536,
        store_longitude: -78.0393663,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: -0.1854374,
        store_longitude: -78.5075647,
    },
    {
        store_latitdue: 0.817799216,
        store_longitude: -77.7116417,
    },
    {
        store_latitdue: 0.471469,
        store_longitude: -78.1323648,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: 0.8198161,
        store_longitude: -77.6972274,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8055179,
        store_longitude: -77.724152,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6235552,
        store_longitude: -77.9347251,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.462214,
        store_longitude: -78.1908,
        neighborhood: 'TUMBABIRO',
    },
    {
        store_latitdue: 0.3858341,
        store_longitude: -78.1249112,
    },
    {
        store_latitdue: 0.589697,
        store_longitude: -77.8292,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.6006403,
        store_longitude: -77.7862449,
        neighborhood: 'CRISTÓBAL COLÓN',
    },
    {
        store_latitdue: 0.6208546,
        store_longitude: -77.9389757,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.8052155,
        store_longitude: -77.7271164,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.592355,
        store_longitude: -77.825826,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.781915,
        store_longitude: -77.7429,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6538094606,
        store_longitude: -77.94383605,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: 0.8085018,
        store_longitude: -77.7182213,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.592229,
        store_longitude: -77.8309,
    },
    {
        store_latitdue: 0.66034,
        store_longitude: -77.7182,
    },
    {
        store_latitdue: 0.507724,
        store_longitude: -77.9064957,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.347342,
        store_longitude: -78.1278781,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344324,
        store_longitude: -78.1217,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34513,
        store_longitude: -78.1221,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34655535,
        store_longitude: -78.121974,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3391133,
        store_longitude: -78.1487127,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.314224,
        store_longitude: -78.1199,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.310246,
        store_longitude: -78.1241,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3596009,
        store_longitude: -78.122481,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6637425,
        store_longitude: -77.7117376,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.3885589,
        store_longitude: -78.1070868,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4169113,
        store_longitude: -78.1950332,
    },
    {
        store_latitdue: 0.341003,
        store_longitude: -78.1255,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.200151125,
        store_longitude: -78.09370693,
        neighborhood: 'ANGOCHAGUA',
    },
    {
        store_latitdue: 0.5118869,
        store_longitude: -78.2120685,
        neighborhood: 'CAHUASQUÍ',
    },
    {
        store_latitdue: 0.37439975,
        store_longitude: -78.12247,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3677611,
        store_longitude: -78.1523174,
        neighborhood: 'IMBAYA',
    },
    {
        store_latitdue: 0.3556131,
        store_longitude: -78.1374987,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3492635,
        store_longitude: -78.1203517,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3462368,
        store_longitude: -78.1143509,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.365856,
        store_longitude: -78.1273,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349681,
        store_longitude: -78.134731,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.363287,
        store_longitude: -78.1107,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.341343,
        store_longitude: -78.1107,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.38975,
        store_longitude: -77.93906,
    },
    {
        store_latitdue: 0.3485442,
        store_longitude: -78.1149085,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: -1.395735,
        store_longitude: -78.4223731,
        neighborhood: 'BAÑOS',
    },
    {
        store_latitdue: 0.3595681,
        store_longitude: -78.092249,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8680907,
        store_longitude: -78.4506509,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.3559816,
        store_longitude: -78.1178471,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3353944,
        store_longitude: -78.1151282,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.355772,
        store_longitude: -78.1255,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3425018,
        store_longitude: -78.1205456,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.350676,
        store_longitude: -78.1258,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.350372,
        store_longitude: -78.1473,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.411919,
        store_longitude: -78.210651,
        neighborhood: 'SAN BLAS',
    },
    {
        store_latitdue: 0.362078,
        store_longitude: -78.1376,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.310461,
        store_longitude: -78.107521,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.330475,
        store_longitude: -78.1218,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.505532,
        store_longitude: -78.1220735,
    },
    {
        store_latitdue: 0.3679072,
        store_longitude: -78.127038,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344699,
        store_longitude: -78.1261,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.320817,
        store_longitude: -78.135,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.340236,
        store_longitude: -78.1125,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3610475,
        store_longitude: -78.1262823,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.334005,
        store_longitude: -78.117,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.342435,
        store_longitude: -78.1213,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4981739,
        store_longitude: -78.192394,
        neighborhood: 'PABLO ARENAS',
    },
    {
        store_latitdue: 0.344467,
        store_longitude: -78.1412,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.345913,
        store_longitude: -78.1454,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3920104,
        store_longitude: -77.9417031,
    },
    {
        store_latitdue: 0.318012,
        store_longitude: -78.1213,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.356701,
        store_longitude: -78.1324,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.335487,
        store_longitude: -78.1147,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.362273544,
        store_longitude: -78.1266555,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.330455,
        store_longitude: -78.1185,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.55139,
        store_longitude: -78.0376,
    },
    {
        store_latitdue: 0.3637175,
        store_longitude: -78.1080646,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3919777,
        store_longitude: -77.9422958,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.322558,
        store_longitude: -78.1244,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3430047,
        store_longitude: -78.1085255,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3258144,
        store_longitude: -78.1222049,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.336215,
        store_longitude: -78.1226,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.326846,
        store_longitude: -78.1213,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3372338,
        store_longitude: -78.1477083,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.358524,
        store_longitude: -78.120617,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.384467,
        store_longitude: -78.1079,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.417594,
        store_longitude: -78.192736,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.347165,
        store_longitude: -78.1396628,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.361592,
        store_longitude: -78.1117,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.339937,
        store_longitude: -78.1276,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3158745,
        store_longitude: -78.1245699,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.337936,
        store_longitude: -78.1214,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3490264,
        store_longitude: -78.118114,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5497616,
        store_longitude: -78.0413923,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.3656608,
        store_longitude: -78.1273112,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.365174,
        store_longitude: -78.129,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.351718,
        store_longitude: -78.1248,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4128828,
        store_longitude: -77.945149,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.44015,
        store_longitude: -77.9942,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3872555,
        store_longitude: -77.9393677,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.344852,
        store_longitude: -78.124,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.324747,
        store_longitude: -78.1307,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3889703,
        store_longitude: -77.94006,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.362885,
        store_longitude: -78.1354,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3621287,
        store_longitude: -78.1207364,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3446739,
        store_longitude: -78.1262057,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.326744,
        store_longitude: -78.1309,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34499,
        store_longitude: -78.1242,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3435676,
        store_longitude: -78.1224614,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.354263,
        store_longitude: -78.1244,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.829801,
        store_longitude: -77.7130625,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.306319,
        store_longitude: -78.1028,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.337698,
        store_longitude: -78.1236,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349084,
        store_longitude: -78.1136,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344356,
        store_longitude: -78.1229,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.336121,
        store_longitude: -78.1291,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.339444,
        store_longitude: -78.121078,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.346931,
        store_longitude: -78.1128,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3232751,
        store_longitude: -78.1228578,
    },
    {
        store_latitdue: 0.3252973,
        store_longitude: -78.1176206,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3936427,
        store_longitude: -77.9400911,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3436271,
        store_longitude: -78.119518,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.36785,
        store_longitude: -78.1278,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3524051,
        store_longitude: -78.1256871,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3342958,
        store_longitude: -78.1483127,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4129231,
        store_longitude: -77.9447446,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.8105614,
        store_longitude: -77.718402,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.368569,
        store_longitude: -78.12,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6385143,
        store_longitude: -77.7504775,
        neighborhood: 'SANTA MARTHA DE CUBA',
    },
    {
        store_latitdue: 0.3679839,
        store_longitude: -78.125643,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.1096396866,
        store_longitude: -78.14243791,
    },
    {
        store_latitdue: 0.3392684,
        store_longitude: -78.1290072,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3603734,
        store_longitude: -78.1308598,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3225459,
        store_longitude: -78.1229892,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3238,
        store_longitude: -78.1273,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3431092,
        store_longitude: -78.1229616,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.416883,
        store_longitude: -78.1943,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.386494,
        store_longitude: -78.1254,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.346156,
        store_longitude: -78.120381,
    },
    {
        store_latitdue: 0.3440244,
        store_longitude: -78.1237089,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.332989,
        store_longitude: -78.1167,
    },
    {
        store_latitdue: 0.3345636,
        store_longitude: -78.1193402,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4339158,
        store_longitude: -77.9661015,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3644139,
        store_longitude: -78.1383444,
    },
    {
        store_latitdue: 0.360672,
        store_longitude: -78.121274,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3462883,
        store_longitude: -78.1262736,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4298191,
        store_longitude: -77.964923,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.350852,
        store_longitude: -78.1336,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.352382,
        store_longitude: -78.1244,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.327458,
        store_longitude: -78.1247,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.354665,
        store_longitude: -78.1271,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.342173,
        store_longitude: -78.1152,
    },
    {
        store_latitdue: 0.344479,
        store_longitude: -78.1262789,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3471573,
        store_longitude: -78.1219275,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3466768,
        store_longitude: -78.1181305,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3710029,
        store_longitude: -78.124017,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3291283,
        store_longitude: -78.1219482,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3890067,
        store_longitude: -77.9394865,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.359788,
        store_longitude: -78.1209,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.342179,
        store_longitude: -78.1111593,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4313188,
        store_longitude: -78.0092008,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3454639,
        store_longitude: -78.120614,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3387329,
        store_longitude: -78.120344,
    },
    {
        store_latitdue: 0.282082,
        store_longitude: -78.1109,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.3685624,
        store_longitude: -78.1512942,
        neighborhood: 'IMBAYA',
    },
    {
        store_latitdue: 0.312592,
        store_longitude: -78.1239,
    },
    {
        store_latitdue: 0.382578,
        store_longitude: -78.107,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4942876,
        store_longitude: -78.1300089,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: 0.3869237,
        store_longitude: -78.1087329,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3272472,
        store_longitude: -78.1239787,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.497375,
        store_longitude: -78.1315267,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: 0.3447067,
        store_longitude: -78.1226558,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3437578,
        store_longitude: -78.1229273,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.345819,
        store_longitude: -78.1225,
    },
    {
        store_latitdue: 0.3447254,
        store_longitude: -78.1223939,
    },
    {
        store_latitdue: 0.3427559,
        store_longitude: -78.1203761,
    },
    {
        store_latitdue: 0.3158593,
        store_longitude: -78.1244144,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3525964,
        store_longitude: -78.1208003,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3359271,
        store_longitude: -78.1111574,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.329983,
        store_longitude: -78.1242,
    },
    {
        store_latitdue: 0.355522,
        store_longitude: -78.1187,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.328607,
        store_longitude: -78.1272,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34894,
        store_longitude: -78.11,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.454609,
        store_longitude: -78.21575,
        neighborhood: 'TUMBABIRO',
    },
    {
        store_latitdue: 0.7892167,
        store_longitude: -78.2942673,
        neighborhood: 'LA CAROLINA',
    },
    {
        store_latitdue: 0.342774,
        store_longitude: -78.1252,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3557207,
        store_longitude: -78.1248673,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3837178,
        store_longitude: -78.1091563,
    },
    {
        store_latitdue: 0.3721519,
        store_longitude: -78.125107,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.288822,
        store_longitude: -78.1128,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.3631207,
        store_longitude: -78.1259461,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3426712,
        store_longitude: -78.1333562,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.341433,
        store_longitude: -78.1454,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3487764,
        store_longitude: -78.1174583,
    },
    {
        store_latitdue: 0.4504152,
        store_longitude: -78.0075453,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.342111,
        store_longitude: -78.1151,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3605668,
        store_longitude: -78.1213166,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3850314,
        store_longitude: -77.9407396,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.365824,
        store_longitude: -78.1137,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3457455,
        store_longitude: -78.1380731,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3576189,
        store_longitude: -78.119665,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.345989,
        store_longitude: -78.1093657,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3692255,
        store_longitude: -78.1516528,
        neighborhood: 'IMBAYA',
    },
    {
        store_latitdue: 0.3826421,
        store_longitude: -78.110336,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.586491,
        store_longitude: -77.8270003,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.3086579,
        store_longitude: -78.105131,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349061,
        store_longitude: -78.1356,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3226276,
        store_longitude: -78.1228432,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.462549,
        store_longitude: -78.190233,
        neighborhood: 'TUMBABIRO',
    },
    {
        store_latitdue: 0.4166782,
        store_longitude: -78.1936237,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.3894994,
        store_longitude: -78.1077863,
    },
    {
        store_latitdue: 0.3313260686,
        store_longitude: -78.2236969471,
    },
    {
        store_latitdue: 0.3446426,
        store_longitude: -78.1199756,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.351715,
        store_longitude: -78.1227,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3246566,
        store_longitude: -78.1660429,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3889582,
        store_longitude: -77.9394476,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.338396,
        store_longitude: -78.1156,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: -0.0956565,
        store_longitude: -78.5127,
    },
    {
        store_latitdue: 0.621206,
        store_longitude: -77.9371,
    },
    {
        store_latitdue: 0.3440883,
        store_longitude: -78.1213255,
    },
    {
        store_latitdue: 0.3026767,
        store_longitude: -78.1225654,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.6637927,
        store_longitude: -77.71221,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.304729,
        store_longitude: -78.1234,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.341399,
        store_longitude: -78.1246,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4402394,
        store_longitude: -77.9952497,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.388516,
        store_longitude: -77.9401674,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.316664,
        store_longitude: -78.1426,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.390345,
        store_longitude: -77.939,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.361292,
        store_longitude: -78.1378,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3163492,
        store_longitude: -78.1279769,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3195751,
        store_longitude: -78.1299307,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3516289,
        store_longitude: -78.135111,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.328729,
        store_longitude: -78.1051,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.364942,
        store_longitude: -78.1268,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8202483,
        store_longitude: -77.6980673,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3373126,
        store_longitude: -78.1345937,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.812216,
        store_longitude: -77.7179,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.34876,
        store_longitude: -78.127,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.366643,
        store_longitude: -78.1168,
    },
    {
        store_latitdue: 0.3697374,
        store_longitude: -78.1256156,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.502102,
        store_longitude: -77.9040658,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.818266,
        store_longitude: -78.367324,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.341696,
        store_longitude: -78.126,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3699093,
        store_longitude: -78.1248038,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.355295,
        store_longitude: -78.1378,
    },
    {
        store_latitdue: 0.3606573,
        store_longitude: -78.1104594,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.358954,
        store_longitude: -78.1073,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4920555,
        store_longitude: -78.0475386,
        neighborhood: 'SAN VICENTE DE PUSIR',
    },
    {
        store_latitdue: 0.35364,
        store_longitude: -78.1499,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.342704,
        store_longitude: -78.1427,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3423618,
        store_longitude: -78.1148834,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4257209,
        store_longitude: -77.9629135,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.60438,
        store_longitude: -77.8393,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.309876,
        store_longitude: -78.1430003,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3342657,
        store_longitude: -78.1178383,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.330215,
        store_longitude: -78.1315,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3231759,
        store_longitude: -78.1171265,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.352221,
        store_longitude: -78.1287,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.384085,
        store_longitude: -78.1105016,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.352018,
        store_longitude: -78.1326,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3447938,
        store_longitude: -78.1398716,
    },
    {
        store_latitdue: 0.331943,
        store_longitude: -78.1152,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3528792,
        store_longitude: -78.1251887,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3352259345,
        store_longitude: -78.1236022711,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3340239,
        store_longitude: -78.1328489,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.358344,
        store_longitude: -78.1285,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4343853,
        store_longitude: -77.9676592,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3591249,
        store_longitude: -78.0939126,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3275667,
        store_longitude: -78.1146247,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3502109,
        store_longitude: -78.1469715,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3276494,
        store_longitude: -78.1293831,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3593574,
        store_longitude: -78.1237722,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8684086,
        store_longitude: -78.4494121,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.35422,
        store_longitude: -78.1513,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.315034,
        store_longitude: -78.1236,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.423992,
        store_longitude: -77.9642,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.345006,
        store_longitude: -78.121731,
    },
    {
        store_latitdue: 0.3374615,
        store_longitude: -78.1488441,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.33658,
        store_longitude: -78.1314,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.324759,
        store_longitude: -78.1353,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.338708,
        store_longitude: -78.1400917,
    },
    {
        store_latitdue: 0.392814,
        store_longitude: -77.9406,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.344855,
        store_longitude: -78.122,
    },
    {
        store_latitdue: 0.418101,
        store_longitude: -78.1989,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.343826,
        store_longitude: -78.1448,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.329269,
        store_longitude: -78.1356,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4255981,
        store_longitude: -77.9626454,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.353381,
        store_longitude: -78.1514,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.387045,
        store_longitude: -78.1259,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3658662,
        store_longitude: -78.1276357,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3492040174,
        store_longitude: -78.12133446,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.331083,
        store_longitude: -78.1166,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.389696,
        store_longitude: -77.9392,
    },
    {
        store_latitdue: 0.388478,
        store_longitude: -77.94,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.328234,
        store_longitude: -78.1352827,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.375361,
        store_longitude: -78.121,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.341979,
        store_longitude: -78.1223,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.364498,
        store_longitude: -78.1384,
    },
    {
        store_latitdue: 0.4178822,
        store_longitude: -78.1945984,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.8031494,
        store_longitude: -77.7294834,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3625933,
        store_longitude: -78.1317513,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.354321,
        store_longitude: -78.1359,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.468959,
        store_longitude: -78.018,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3889604,
        store_longitude: -77.9400341,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.4347135,
        store_longitude: -77.9669158,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3226276,
        store_longitude: -78.1343864,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3502611,
        store_longitude: -78.1473751,
    },
    {
        store_latitdue: 0.3455119,
        store_longitude: -78.1268115,
    },
    {
        store_latitdue: 0.354949,
        store_longitude: -78.1517,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.387531,
        store_longitude: -77.9391948,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3278112,
        store_longitude: -78.1359414,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344341,
        store_longitude: -78.1218353,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.323425,
        store_longitude: -78.1247,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4122889,
        store_longitude: -78.211454,
        neighborhood: 'SAN BLAS',
    },
    {
        store_latitdue: 0.3278536,
        store_longitude: -78.1287833,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5144169,
        store_longitude: -78.2127552,
        neighborhood: 'CAHUASQUÍ',
    },
    {
        store_latitdue: 0.346602,
        store_longitude: -78.1079,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3330233,
        store_longitude: -78.1213419,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3613991,
        store_longitude: -78.1227295,
    },
    {
        store_latitdue: 0.319441,
        store_longitude: -78.1061,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3912042,
        store_longitude: -77.9404227,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.354441,
        store_longitude: -78.1505,
    },
    {
        store_latitdue: 0.297154,
        store_longitude: -78.1196,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.33945437,
        store_longitude: -78.110437,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3215321,
        store_longitude: -78.1339839,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.351155,
        store_longitude: -78.1298,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.504214,
        store_longitude: -78.193,
        neighborhood: 'PABLO ARENAS',
    },
    {
        store_latitdue: 0.360346,
        store_longitude: -78.093342,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3271733,
        store_longitude: -78.1223212,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.348307,
        store_longitude: -78.121,
    },
    {
        store_latitdue: 0.3523792,
        store_longitude: -78.1493313,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3517545,
        store_longitude: -78.1103156,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.345073,
        store_longitude: -78.1222,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349351,
        store_longitude: -78.1176,
    },
    {
        store_latitdue: 0.3282795,
        store_longitude: -78.1192554,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.371331,
        store_longitude: -78.121,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.549275,
        store_longitude: -78.0449,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.430717,
        store_longitude: -78.0096,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.360277,
        store_longitude: -78.1343,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.32442,
        store_longitude: -78.1049,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.353855,
        store_longitude: -78.12737,
    },
    {
        store_latitdue: 0.336088,
        store_longitude: -78.1289,
    },
    {
        store_latitdue: 0.269818,
        store_longitude: -78.10609,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.3419799,
        store_longitude: -78.132316,
    },
    {
        store_latitdue: 0.8215688,
        store_longitude: -77.7079367,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3448449,
        store_longitude: -78.1165265,
    },
    {
        store_latitdue: 0.3869326,
        store_longitude: -77.9391078,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.453182,
        store_longitude: -78.0087,
    },
    {
        store_latitdue: 0.364487,
        store_longitude: -78.1384,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3507323,
        store_longitude: -78.1456514,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.333055,
        store_longitude: -78.1181,
    },
    {
        store_latitdue: 0.3542233,
        store_longitude: -78.1025533,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3893964,
        store_longitude: -77.9441361,
        neighborhood: 'PIMAMPIRO',
    },

    {
        store_latitdue: 0.3629755536,
        store_longitude: -78.1128197908,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.329722,
        store_longitude: -78.1285,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.346408,
        store_longitude: -78.1361,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4855161,
        store_longitude: -78.0724808,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.3529106,
        store_longitude: -78.1319211,
    },
    {
        store_latitdue: 0.4124143,
        store_longitude: -77.9444889,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.4142408,
        store_longitude: -78.2008552,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.33720348,
        store_longitude: -78.120665,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3886265,
        store_longitude: -77.9401856,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.6253319,
        store_longitude: -77.9403894,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.4353103,
        store_longitude: -77.9681225,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.4246724,
        store_longitude: -77.9633385,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.2808241,
        store_longitude: -78.111035,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.4402579,
        store_longitude: -77.9950899,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.549108,
        store_longitude: -78.0408,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.389436,
        store_longitude: -77.9412,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.4173041,
        store_longitude: -78.1945753,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.4395647,
        store_longitude: -77.9544609,
        neighborhood: 'LOS ANDES',
    },
    {
        store_latitdue: 0.314889,
        store_longitude: -78.1427,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.326887,
        store_longitude: -78.1201595,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3182,
        store_longitude: -78.1402,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3348683,
        store_longitude: -78.119711,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3365219,
        store_longitude: -78.1222303,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3294521,
        store_longitude: -78.1243933,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344346,
        store_longitude: -78.1167,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.335672,
        store_longitude: -78.1268,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3454784,
        store_longitude: -78.12157,
    },
    {
        store_latitdue: 0.3879566,
        store_longitude: -78.0847644,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.51447,
        store_longitude: -78.2122,
    },
    {
        store_latitdue: 0.3461396,
        store_longitude: -78.1069871,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.508127,
        store_longitude: -77.9044,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.8682258,
        store_longitude: -78.4493959,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.3593928,
        store_longitude: -78.119674,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3391479,
        store_longitude: -78.1283369,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.35941501,
        store_longitude: -78.122109,
    },
    {
        store_latitdue: 0.327472,
        store_longitude: -78.1194,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3883128,
        store_longitude: -77.9406809,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.27386779,
        store_longitude: -78.107346,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.3255628,
        store_longitude: -78.1061999,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.341833,
        store_longitude: -78.1322,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.359315,
        store_longitude: -78.1316151,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.424068,
        store_longitude: -78.1941,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.364883,
        store_longitude: -78.1251,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34783,
        store_longitude: -78.137071,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.354171,
        store_longitude: -78.131037,
    },
    {
        store_latitdue: 0.336423,
        store_longitude: -78.1356,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.412169,
        store_longitude: -78.1909,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.316591,
        store_longitude: -78.1279,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.385115,
        store_longitude: -78.1062,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.354886,
        store_longitude: -78.1198,
    },
    {
        store_latitdue: 0.3891357,
        store_longitude: -77.9409877,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3348772533,
        store_longitude: -78.1161189079,
    },
    {
        store_latitdue: 0.3431667,
        store_longitude: -78.124726,
    },
    {
        store_latitdue: 0.434404,
        store_longitude: -78.0095,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.4402708,
        store_longitude: -77.9948925,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.292234,
        store_longitude: -78.1341391,
    },
    {
        store_latitdue: 0.20772,
        store_longitude: -78.0919,
        neighborhood: 'ANGOCHAGUA',
    },
    {
        store_latitdue: 0.329114,
        store_longitude: -78.1057,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5999896,
        store_longitude: -77.8335259,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.34777,
        store_longitude: -78.119849,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3506765,
        store_longitude: -78.1337893,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3498503,
        store_longitude: -78.1237623,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3262338,
        store_longitude: -78.1094428,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3838418,
        store_longitude: -78.1269106,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3159127,
        store_longitude: -78.1236265,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.33096,
        store_longitude: -78.1161,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4166193,
        store_longitude: -78.1938243,
    },
    {
        store_latitdue: 0.788793,
        store_longitude: -78.2938,
        neighborhood: 'LA CAROLINA',
    },
    {
        store_latitdue: 0.461534,
        store_longitude: -78.1915,
        neighborhood: 'TUMBABIRO',
    },
    {
        store_latitdue: 0.3601774,
        store_longitude: -78.1230744,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.867652,
        store_longitude: -78.443509,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.3412095,
        store_longitude: -78.1336024,
    },
    {
        store_latitdue: 0.3447629,
        store_longitude: -78.1212119,
    },
    {
        store_latitdue: 0.34552,
        store_longitude: -78.1268,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.744292,
        store_longitude: -78.2437,
        neighborhood: 'LA CAROLINA',
    },
    {
        store_latitdue: 0.355755,
        store_longitude: -78.1184,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4159362,
        store_longitude: -78.1938683,
    },
    {
        store_latitdue: 0.3601689,
        store_longitude: -78.1320135,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3453897,
        store_longitude: -78.1217546,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3366756309,
        store_longitude: -78.12246989,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3352638,
        store_longitude: -78.1162442,
    },
    {
        store_latitdue: 0.337296,
        store_longitude: -78.1308,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.346098056,
        store_longitude: -78.1371387,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5718502,
        store_longitude: -78.1328159,
        neighborhood: 'JUAN MONTALVO',
    },
    {
        store_latitdue: 0.3857574,
        store_longitude: -78.1020537,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4241232,
        store_longitude: -77.9632326,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3530152,
        store_longitude: -78.1170138,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.625318,
        store_longitude: -77.9425,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.3277339,
        store_longitude: -78.1353512,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3384827,
        store_longitude: -78.142567,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.351393,
        store_longitude: -78.1302,
    },
    {
        store_latitdue: 0.376909,
        store_longitude: -78.1209397,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3251405,
        store_longitude: -78.1080997,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5508285,
        store_longitude: -78.0395628,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.302236,
        store_longitude: -78.1221,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.3201929,
        store_longitude: -78.1360721,
    },
    {
        store_latitdue: -0.9252884,
        store_longitude: -78.6306,
    },
    {
        store_latitdue: 0.308679,
        store_longitude: -78.1241,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.342708,
        store_longitude: -78.131975,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.281067,
        store_longitude: -78.1109591,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.335146,
        store_longitude: -78.1210042,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.364645,
        store_longitude: -78.1177362,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.287983,
        store_longitude: -78.1123,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.329413,
        store_longitude: -78.109,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.322520827,
        store_longitude: -78.11280803,
    },
    {
        store_latitdue: 0.341958,
        store_longitude: -78.132258,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.553452,
        store_longitude: -78.0399,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.305124,
        store_longitude: -78.1199,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344961,
        store_longitude: -78.1231,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4167176,
        store_longitude: -78.1930097,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.344709,
        store_longitude: -78.1217,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.369701,
        store_longitude: -78.1515,
        neighborhood: 'IMBAYA',
    },
    {
        store_latitdue: 0.4428934,
        store_longitude: -78.0063934,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.321261,
        store_longitude: -78.1191,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.361887,
        store_longitude: -78.1364,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3508117,
        store_longitude: -78.1230447,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3064763,
        store_longitude: -78.1021185,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3534673,
        store_longitude: -78.1198112,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34892,
        store_longitude: -78.1247,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3540482,
        store_longitude: -78.118734,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.352056,
        store_longitude: -78.1267,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.353252,
        store_longitude: -78.1292,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.530684564,
        store_longitude: -78.0614447,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.3898389733,
        store_longitude: -77.9403306171,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.345111712,
        store_longitude: -78.1192406,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.33667,
        store_longitude: -78.1173,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349590718,
        store_longitude: -78.1222229,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3935538,
        store_longitude: -77.9403384,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.324002,
        store_longitude: -78.1282,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.356732,
        store_longitude: -78.1322,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.488456547,
        store_longitude: -77.9347457,
        neighborhood: 'LOS ANDES',
    },
    {
        store_latitdue: 0.3333315,
        store_longitude: -78.1336908,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3595622,
        store_longitude: -78.122408,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.360055678,
        store_longitude: -78.13030791,
    },
    {
        store_latitdue: 0.496737,
        store_longitude: -78.1309,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: 0.360123,
        store_longitude: -78.1306,
    },
    {
        store_latitdue: 0.34499,
        store_longitude: -78.1242,
    },
    {
        store_latitdue: 0.5502798,
        store_longitude: -78.0410402,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.5977083,
        store_longitude: -77.8315817,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.346314,
        store_longitude: -78.127,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.327398,
        store_longitude: -78.1135,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3532022,
        store_longitude: -78.1233664,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.513197,
        store_longitude: -78.2129,
        neighborhood: 'CAHUASQUÍ',
    },
    {
        store_latitdue: 0.374367,
        store_longitude: -78.1227937,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3608221,
        store_longitude: -78.1104161,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3282432,
        store_longitude: -78.1353313,
    },
    {
        store_latitdue: 0.334775,
        store_longitude: -78.1163,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.327652,
        store_longitude: -78.1291,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.353926301,
        store_longitude: -78.1141052,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.8676727,
        store_longitude: -78.4494985,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.8683637,
        store_longitude: -78.4492133,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.278095,
        store_longitude: -78.1095,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.3488621,
        store_longitude: -78.1205052,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.362765,
        store_longitude: -78.1387,
    },
    {
        store_latitdue: 0.4947828,
        store_longitude: -78.1301908,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: 0.3161985,
        store_longitude: -78.1218012,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349143,
        store_longitude: -78.1194,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4531399,
        store_longitude: -78.0087723,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.598248,
        store_longitude: -77.835,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.36141,
        store_longitude: -78.112,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.343481,
        store_longitude: -78.1399,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3171315,
        store_longitude: -78.1234122,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3606629,
        store_longitude: -78.0939065,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.358676558,
        store_longitude: -78.1209361,
    },
    {
        store_latitdue: 0.3221167,
        store_longitude: -78.13315,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.359973,
        store_longitude: -78.1236353,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3350054,
        store_longitude: -78.1209126,
    },
    {
        store_latitdue: 0.392778,
        store_longitude: -77.9404191,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3432393,
        store_longitude: -78.1316501,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344862,
        store_longitude: -78.1217,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.362944,
        store_longitude: -78.1342,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.350478321,
        store_longitude: -78.1478881,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3352584,
        store_longitude: -78.1238991,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3471469,
        store_longitude: -78.1323648,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.345613,
        store_longitude: -78.1051,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.416709,
        store_longitude: -78.1937,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.502133,
        store_longitude: -77.9054,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.3319332,
        store_longitude: -78.1169611,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349164,
        store_longitude: -78.1148,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3236354,
        store_longitude: -78.1307016,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3068512,
        store_longitude: -78.124628,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3829841,
        store_longitude: -78.106833,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.362519,
        store_longitude: -78.1379,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.332023,
        store_longitude: -78.1117279,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3472908,
        store_longitude: -78.1384934,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.81725,
        store_longitude: -77.71,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.32611,
        store_longitude: -78.1198,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3573849,
        store_longitude: -78.1194382,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34464558,
        store_longitude: -78.126178,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.345819,
        store_longitude: -78.1225,
    },
    {
        store_latitdue: 0.310253,
        store_longitude: -78.1241,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.356827,
        store_longitude: -78.1291,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.345064,
        store_longitude: -78.131727,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8101968,
        store_longitude: -77.7173638,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.357103,
        store_longitude: -78.1305,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3177,
        store_longitude: -78.1416,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.352409,
        store_longitude: -78.1313,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.359158,
        store_longitude: -78.0924,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.493837,
        store_longitude: -78.0473,
        neighborhood: 'SAN VICENTE DE PUSIR',
    },
    {
        store_latitdue: 0.6229465,
        store_longitude: -77.9387863,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.8686136,
        store_longitude: -78.4492597,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.3521619,
        store_longitude: -78.13583,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8169103,
        store_longitude: -77.711446,
    },
    {
        store_latitdue: 0.4336053,
        store_longitude: -77.9650219,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3651884,
        store_longitude: -78.139945,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.326409,
        store_longitude: -78.1354,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3904512,
        store_longitude: -77.941721,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.58577,
        store_longitude: -77.82764,
    },
    {
        store_latitdue: 0.6651906,
        store_longitude: -77.7158676,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.4095357,
        store_longitude: -78.1884847,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.342591,
        store_longitude: -78.117,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.353301,
        store_longitude: -78.1179,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.350203,
        store_longitude: -78.1468,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34858,
        store_longitude: -78.1215,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8113126,
        store_longitude: -77.7246376,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6046874,
        store_longitude: -77.9870879,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: 0.361136,
        store_longitude: -78.1115,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.416671,
        store_longitude: -78.199259,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.485025,
        store_longitude: -78.0731,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.8024161,
        store_longitude: -77.7281604,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3624624,
        store_longitude: -78.120973,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.356712,
        store_longitude: -78.1272,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.634406,
        store_longitude: -77.7245198,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.3116743,
        store_longitude: -78.1198084,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.81851,
        store_longitude: -77.71055,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3217078,
        store_longitude: -78.1333581,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8036515,
        store_longitude: -77.7283314,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6405748,
        store_longitude: -77.7504686,
        neighborhood: 'SANTA MARTHA DE CUBA',
    },
    {
        store_latitdue: 0.810626,
        store_longitude: -77.7176879,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.59107,
        store_longitude: -77.8296,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.810517,
        store_longitude: -77.7166,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5020115,
        store_longitude: -77.9033381,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: -0.185119,
        store_longitude: -78.4917681,
    },
    {
        store_latitdue: 0.3581792,
        store_longitude: -78.1173384,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4608548,
        store_longitude: -78.2374721,
        neighborhood: 'PABLO ARENAS',
    },
    {
        store_latitdue: 0.599543,
        store_longitude: -77.8305,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.383521,
        store_longitude: -77.9392,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3290464,
        store_longitude: -78.1165551,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3048692,
        store_longitude: -78.1422091,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.312268,
        store_longitude: -78.1238,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4692402,
        store_longitude: -78.0438332,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.816987,
        store_longitude: -77.7127,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6185399,
        store_longitude: -77.9421533,
    },
    {
        store_latitdue: 0.098427,
        store_longitude: -77.7192773,
    },
    {
        store_latitdue: 0.341547,
        store_longitude: -78.1198,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.335388,
        store_longitude: -78.111679,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3441048,
        store_longitude: -78.1219268,
    },
    {
        store_latitdue: 0.342045,
        store_longitude: -78.1154,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3649942,
        store_longitude: -78.1217485,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8070043,
        store_longitude: -77.719992,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.360791,
        store_longitude: -78.1246,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.82392,
        store_longitude: -77.70617,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7889479,
        store_longitude: -78.2939802,
        neighborhood: 'LA CAROLINA',
    },
    {
        store_latitdue: 0.453126,
        store_longitude: -78.0088,
    },
    {
        store_latitdue: 0.323459,
        store_longitude: -78.1227,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.206562,
        store_longitude: -78.0925,
        neighborhood: 'ANGOCHAGUA',
    },
    {
        store_latitdue: 0.6218462,
        store_longitude: -77.9458624,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.417498,
        store_longitude: -78.1946,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.8303517,
        store_longitude: -77.712345,
    },
    {
        store_latitdue: 0.8066332,
        store_longitude: -77.7319254,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.815396201,
        store_longitude: -77.7161908,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3290104,
        store_longitude: -78.1353635,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.424891,
        store_longitude: -77.9623,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.342165,
        store_longitude: -78.1185,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.639054,
        store_longitude: -77.7517,
    },
    {
        store_latitdue: 0.794839,
        store_longitude: -77.7323,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5866686,
        store_longitude: -77.8247943,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.345202,
        store_longitude: -78.1057,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3551529,
        store_longitude: -78.123905,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.317679,
        store_longitude: -78.1256,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.350133,
        store_longitude: -78.1465,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3586779,
        store_longitude: -78.119791,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3037639733,
        store_longitude: -78.14076835,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34567084,
        store_longitude: -78.121762,
    },
    {
        store_latitdue: 0.3351866,
        store_longitude: -78.1160798,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.497583,
        store_longitude: -77.9378,
        neighborhood: 'LOS ANDES',
    },
    {
        store_latitdue: 0.494637,
        store_longitude: -78.130557,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: 0.7991466,
        store_longitude: -77.7400441,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.374248,
        store_longitude: -78.120904,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.355453,
        store_longitude: -78.1203,
    },
    {
        store_latitdue: 0.549434,
        store_longitude: -78.0416,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.7980007851,
        store_longitude: -77.74091054,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6145732,
        store_longitude: -77.9442241,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.933404,
        store_longitude: -79.671,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.3536456,
        store_longitude: -78.1103634,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.335758,
        store_longitude: -78.116,
    },
    {
        store_latitdue: 0.357436,
        store_longitude: -78.1156,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.342369,
        store_longitude: -78.1202,
    },
    {
        store_latitdue: 0.3551901,
        store_longitude: -78.1332933,
    },
    {
        store_latitdue: 0.22844,
        store_longitude: -78.2616,
    },
    {
        store_latitdue: 0.3327663,
        store_longitude: -78.1165988,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.355322,
        store_longitude: -78.1336,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.334672,
        store_longitude: -78.1302,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.367234,
        store_longitude: -78.1132,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.346633,
        store_longitude: -78.1217,
    },
    {
        store_latitdue: 0.8039573,
        store_longitude: -77.7269437,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8196444,
        store_longitude: -77.7083252,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5491984,
        store_longitude: -78.0408171,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.3128216,
        store_longitude: -78.1249993,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8154592,
        store_longitude: -77.7167949,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.4407754753,
        store_longitude: -77.99724656,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.6054916,
        store_longitude: -77.8127066,
        neighborhood: 'CRISTÓBAL COLÓN',
    },
    {
        store_latitdue: 0.3662604,
        store_longitude: -78.1123156,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3480389,
        store_longitude: -78.135285,
    },
    {
        store_latitdue: 0.3819383,
        store_longitude: -78.1058367,
    },
    {
        store_latitdue: 0.341163,
        store_longitude: -78.1103,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.226103,
        store_longitude: -78.2665,
    },
    {
        store_latitdue: 0.8163259,
        store_longitude: -77.7100586,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.333504885,
        store_longitude: -78.1394729,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5494686,
        store_longitude: -78.040592,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.493394,
        store_longitude: -78.0463,
        neighborhood: 'SAN VICENTE DE PUSIR',
    },
    {
        store_latitdue: 0.5879857,
        store_longitude: -77.826882,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.3610329,
        store_longitude: -78.1337275,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6167374,
        store_longitude: -77.9423948,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.342501,
        store_longitude: -78.1189,
    },
    {
        store_latitdue: 0.3136242,
        store_longitude: -78.1391342,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3859559,
        store_longitude: -78.1096957,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.353763,
        store_longitude: -78.1135,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3531971,
        store_longitude: -78.1207925,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4170121,
        store_longitude: -78.1944979,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.347685,
        store_longitude: -78.1216,
    },
    {
        store_latitdue: 0.3558775,
        store_longitude: -78.1276063,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.363697,
        store_longitude: -78.1301,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3858406,
        store_longitude: -78.1095927,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6213998,
        store_longitude: -77.9393951,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.3850873,
        store_longitude: -78.1060018,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6002545,
        store_longitude: -77.8341533,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.7949319,
        store_longitude: -77.7384899,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3884743,
        store_longitude: -77.9403268,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3193569,
        store_longitude: -78.1315026,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3281922,
        store_longitude: -78.1353845,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.352231919,
        store_longitude: -78.1313171,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.664418,
        store_longitude: -77.7145496,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.3691018,
        store_longitude: -78.1322109,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.7828774,
        store_longitude: -77.7422869,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.31776,
        store_longitude: -78.126399,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3598152,
        store_longitude: -78.1117211,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.348529,
        store_longitude: -78.136598,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3871977,
        store_longitude: -77.9401324,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.472565,
        store_longitude: -78.0657,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.410684,
        store_longitude: -78.223321,
        neighborhood: 'SAN BLAS',
    },
    {
        store_latitdue: 0.412271171,
        store_longitude: -78.2140426,
        neighborhood: 'SAN BLAS',
    },
    {
        store_latitdue: 0.3636193,
        store_longitude: -78.1344414,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.42326,
        store_longitude: -78.1931,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.8685632,
        store_longitude: -78.4515164,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.3384974,
        store_longitude: -78.1156281,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.2808313,
        store_longitude: -78.1107615,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.328794117,
        store_longitude: -78.1223952,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6011721,
        store_longitude: -77.8332429,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.3528488,
        store_longitude: -78.113722,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.809137,
        store_longitude: -77.7173243,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3284173,
        store_longitude: -78.1191574,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.327501,
        store_longitude: -78.1180148,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8051431,
        store_longitude: -77.7214193,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.356581,
        store_longitude: -78.1198,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3370107,
        store_longitude: -78.1343413,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.600252,
        store_longitude: -77.9874409,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: 0.7933047,
        store_longitude: -77.7379989,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6249442,
        store_longitude: -77.9386937,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.353335,
        store_longitude: -78.1104,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.355568,
        store_longitude: -78.132,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3412485,
        store_longitude: -78.1199344,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8130019,
        store_longitude: -77.7175463,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.4527524,
        store_longitude: -78.0083288,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.5075248,
        store_longitude: -77.9042631,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.798119995,
        store_longitude: -77.7290222,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3179114,
        store_longitude: -78.1233445,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.357621,
        store_longitude: -78.1191,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8004904,
        store_longitude: -77.7404441,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.204630655,
        store_longitude: -78.0924189,
        neighborhood: 'ANGOCHAGUA',
    },
    {
        store_latitdue: 0.8104742,
        store_longitude: -77.7207643,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3581713,
        store_longitude: -78.1101848,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4614996,
        store_longitude: -78.1912956,
        neighborhood: 'TUMBABIRO',
    },
    {
        store_latitdue: 0.341985,
        store_longitude: -78.1321388,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.381826526,
        store_longitude: -78.10726666,
    },
    {
        store_latitdue: 0.354734,
        store_longitude: -78.1282,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3349457,
        store_longitude: -78.1224307,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3363413,
        store_longitude: -78.1210241,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.80309,
        store_longitude: -77.7343,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3430128,
        store_longitude: -78.1108481,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.6228789,
        store_longitude: -77.9388657,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.8050012,
        store_longitude: -77.7314515,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3885376,
        store_longitude: -77.9404268,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3445523,
        store_longitude: -78.1236449,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.392768,
        store_longitude: -77.9408,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.7934888,
        store_longitude: -77.7402747,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8149854,
        store_longitude: -77.71284,
    },
    {
        store_latitdue: 0.3854933,
        store_longitude: -77.9401008,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.33793348,
        store_longitude: -78.1132431,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3396988,
        store_longitude: -78.1591042,
    },
    {
        store_latitdue: 0.35542,
        store_longitude: -78.1204,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3410311,
        store_longitude: -78.1402147,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8165517,
        store_longitude: -77.7126796,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: -0.162289,
        store_longitude: -78.317,
    },
    {
        store_latitdue: 0.3910762,
        store_longitude: -77.9449221,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3419734,
        store_longitude: -78.1151343,
    },
    {
        store_latitdue: 0.3542454,
        store_longitude: -78.1240759,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3903804,
        store_longitude: -77.9390048,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3538642,
        store_longitude: -78.1308107,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8073872,
        store_longitude: -77.7247289,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.38935,
        store_longitude: -77.94431,
    },
    {
        store_latitdue: 0.3219713,
        store_longitude: -78.1230505,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34875473,
        store_longitude: -78.126939,
    },
    {
        store_latitdue: 0.3133286,
        store_longitude: -78.123806,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.328729743,
        store_longitude: -78.1082546,
    },
    {
        store_latitdue: 0.349267697,
        store_longitude: -78.1254529,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8158981,
        store_longitude: -77.7127451,
    },
    {
        store_latitdue: 0.3552347,
        store_longitude: -78.1201348,
    },
    {
        store_latitdue: 0.809881,
        store_longitude: -77.724274,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.5976795,
        store_longitude: -77.8354226,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.36459188,
        store_longitude: -78.13571,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5155626,
        store_longitude: -77.8809854,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.38413,
        store_longitude: -78.1062,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3779209,
        store_longitude: -78.1237406,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3673727,
        store_longitude: -78.120453,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.343691,
        store_longitude: -78.1232,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344721,
        store_longitude: -78.1232,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.3414096,
        store_longitude: -78.1198821,
    },
    {
        store_latitdue: 0.6377165,
        store_longitude: -77.725241,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.3356864,
        store_longitude: -78.1130132,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3554461,
        store_longitude: -78.1296283,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3274314,
        store_longitude: -78.214538,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.32714,
        store_longitude: -78.2144,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.343305,
        store_longitude: -78.2103,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.189996,
        store_longitude: -78.228587,
        neighborhood: 'SAN RAFAEL',
    },
    {
        store_latitdue: 0.236701,
        store_longitude: -78.2638,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.1969535,
        store_longitude: -78.2378276,
        neighborhood: 'SAN RAFAEL',
    },
    {
        store_latitdue: 0.306329,
        store_longitude: -78.2664,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.231209,
        store_longitude: -78.2586,
    },
    {
        store_latitdue: 0.352363,
        store_longitude: -78.1728,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.34344089,
        store_longitude: -78.174205,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3305966,
        store_longitude: -78.17116,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.206571,
        store_longitude: -78.1873,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.179141,
        store_longitude: -78.2042,
    },
    {
        store_latitdue: 0.228468,
        store_longitude: -78.2649,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.223004,
        store_longitude: -78.2687,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2230683,
        store_longitude: -78.2653142,
    },
    {
        store_latitdue: 0.2440182,
        store_longitude: -78.2548112,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2424349,
        store_longitude: -78.255618,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2717339,
        store_longitude: -78.2343481,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.230644,
        store_longitude: -78.2611,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.230177,
        store_longitude: -78.2605,
    },
    {
        store_latitdue: 0.3404412,
        store_longitude: -78.1505375,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.223209,
        store_longitude: -78.2643,
    },
    {
        store_latitdue: 0.222342,
        store_longitude: -78.228,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.226205,
        store_longitude: -78.2270099,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.325053,
        store_longitude: -78.1969,
    },
    {
        store_latitdue: 0.2061829,
        store_longitude: -78.2004559,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2306404,
        store_longitude: -78.262278,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3598614,
        store_longitude: -78.1796793,
        neighborhood: 'SAN JOSÉ DE CHALTURA',
    },
    {
        store_latitdue: 0.2244976,
        store_longitude: -78.2643558,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.353572,
        store_longitude: -78.1954,
        neighborhood: 'SAN JOSÉ DE CHALTURA',
    },
    {
        store_latitdue: 0.3388612,
        store_longitude: -78.1843372,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.299892,
        store_longitude: -78.263726,
    },
    {
        store_latitdue: 0.1949093,
        store_longitude: -78.1888535,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.301358,
        store_longitude: -78.2676,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.3361909,
        store_longitude: -78.193028,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.3046997,
        store_longitude: -78.2570806,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.271602,
        store_longitude: -78.2338,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.27262,
        store_longitude: -78.2339,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.2826752,
        store_longitude: -78.2851387,
    },
    {
        store_latitdue: 0.299958,
        store_longitude: -78.2675,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.320738,
        store_longitude: -78.1666,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.225523,
        store_longitude: -78.2308,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3333873,
        store_longitude: -78.2208831,
    },
    {
        store_latitdue: 0.2259239,
        store_longitude: -78.266548,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.206939,
        store_longitude: -78.1882018,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.3004544,
        store_longitude: -78.2662731,
    },
    {
        store_latitdue: 0.206169,
        store_longitude: -78.1993,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.29994,
        store_longitude: -78.2675,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2307518,
        store_longitude: -78.2651529,
    },
    {
        store_latitdue: 0.215648,
        store_longitude: -78.275,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3126087,
        store_longitude: -78.2027517,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3361011,
        store_longitude: -78.1948207,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.226093,
        store_longitude: -78.2649,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2707943,
        store_longitude: -78.2460438,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.231152,
        store_longitude: -78.258647,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.23372,
        store_longitude: -78.2694,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2075541,
        store_longitude: -78.1880403,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.1970913472,
        store_longitude: -78.1917689368,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2760745,
        store_longitude: -78.2708963,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.17175,
        store_longitude: -78.2059,
        neighborhood: 'GONZÁLEZ SUÁREZ',
    },
    {
        store_latitdue: 0.2263969,
        store_longitude: -78.260913,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.27597,
        store_longitude: -78.2367,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.179654,
        store_longitude: -78.2102,
        neighborhood: 'GONZÁLEZ SUÁREZ',
    },
    {
        store_latitdue: 0.2229459,
        store_longitude: -78.234697,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.140815,
        store_longitude: -78.19,
        neighborhood: 'TUPIGACHI',
    },
    {
        store_latitdue: 0.27997,
        store_longitude: -78.2970359,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.2276874,
        store_longitude: -78.2613455,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3568934,
        store_longitude: -78.2162666,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.2412873,
        store_longitude: -78.2590704,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.284369,
        store_longitude: -78.2869,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.3309634,
        store_longitude: -78.1719757,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.32512,
        store_longitude: -78.1663,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2042192,
        store_longitude: -78.2552944,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.332064,
        store_longitude: -78.1694628,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.32587536,
        store_longitude: -78.225544,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.209295,
        store_longitude: -78.2499,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.2373691,
        store_longitude: -78.265398,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.339273,
        store_longitude: -78.171,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2102217,
        store_longitude: -78.2489163,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.3015585,
        store_longitude: -78.268417,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.23922,
        store_longitude: -78.2604705,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2303236,
        store_longitude: -78.2537316,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.280785,
        store_longitude: -78.2297,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.2068944,
        store_longitude: -78.1882974,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.35236,
        store_longitude: -78.1827,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.322261,
        store_longitude: -78.1656,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.334185,
        store_longitude: -78.2127,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3498057,
        store_longitude: -78.1685305,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2364849,
        store_longitude: -78.2694248,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2956676,
        store_longitude: -78.2642737,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.1801135,
        store_longitude: -78.2037679,
        neighborhood: 'GONZÁLEZ SUÁREZ',
    },
    {
        store_latitdue: 0.348513,
        store_longitude: -78.1653519,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3447176,
        store_longitude: -78.1707041,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.203028,
        store_longitude: -78.176,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.211567,
        store_longitude: -78.1553,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.219159,
        store_longitude: -78.2752,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3201225,
        store_longitude: -78.1673737,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2498684,
        store_longitude: -78.2530233,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.203121,
        store_longitude: -78.1762145,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.296719,
        store_longitude: -78.266577,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2104602,
        store_longitude: -78.1708465,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.195367,
        store_longitude: -78.190313,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.1802593,
        store_longitude: -78.2099561,
        neighborhood: 'GONZÁLEZ SUÁREZ',
    },
    {
        store_latitdue: 0.2752661,
        store_longitude: -78.2720632,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2316948,
        store_longitude: -78.2665087,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2363297,
        store_longitude: -78.2686983,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.23792,
        store_longitude: -78.2884,
        neighborhood: 'SAN JOSÉ DE QUICHINCHE',
    },
    {
        store_latitdue: 0.33422,
        store_longitude: -78.1701,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.1793505,
        store_longitude: -78.2023647,
        neighborhood: 'GONZÁLEZ SUÁREZ',
    },
    {
        store_latitdue: 0.2781711,
        store_longitude: -78.2830285,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.3004818,
        store_longitude: -78.2676368,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.221993,
        store_longitude: -78.259094,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.340108,
        store_longitude: -78.162269,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2359237,
        store_longitude: -78.2670591,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.243927,
        store_longitude: -78.2717,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.300115,
        store_longitude: -78.232,
        neighborhood: 'SAN ROQUE',
    },
    {
        store_latitdue: 0.1955176,
        store_longitude: -78.1879665,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2411502,
        store_longitude: -78.2589917,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3094932,
        store_longitude: -78.263304,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2193293,
        store_longitude: -78.271427,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2471666,
        store_longitude: -78.2531329,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.343575,
        store_longitude: -78.1651,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2410999,
        store_longitude: -78.256438,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3077571,
        store_longitude: -78.2647652,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.230532,
        store_longitude: -78.2669,
    },
    {
        store_latitdue: 0.2301874,
        store_longitude: -78.2551233,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.244429,
        store_longitude: -78.2543,
    },
    {
        store_latitdue: 0.2348324,
        store_longitude: -78.2529012,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3450808,
        store_longitude: -78.169933,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.299192,
        store_longitude: -78.267089,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.341124,
        store_longitude: -78.186853,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.241099,
        store_longitude: -78.2605,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2380352,
        store_longitude: -78.2880991,
        neighborhood: 'SAN JOSÉ DE QUICHINCHE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.3112489,
        store_longitude: -78.227753,
        neighborhood: 'SAN ROQUE',
    },
    {
        store_latitdue: 0.237008,
        store_longitude: -78.2887,
        neighborhood: 'SAN JOSÉ DE QUICHINCHE',
    },
    {
        store_latitdue: 0.23853,
        store_longitude: -78.2676,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.239703,
        store_longitude: -78.2499843,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.243928,
        store_longitude: -78.2558,
    },
    {
        store_latitdue: 0.2940511,
        store_longitude: -78.2671587,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2706433,
        store_longitude: -78.246341,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.3069347,
        store_longitude: -78.2305386,
        neighborhood: 'SAN ROQUE',
    },
    {
        store_latitdue: 0.330826,
        store_longitude: -78.1714,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2376939,
        store_longitude: -78.2889392,
        neighborhood: 'SAN JOSÉ DE QUICHINCHE',
    },
    {
        store_latitdue: 0.2993289,
        store_longitude: -78.260706,
    },
    {
        store_latitdue: 0.330142,
        store_longitude: -78.2247,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.2355319,
        store_longitude: -78.2581028,
    },
    {
        store_latitdue: 0.324976,
        store_longitude: -78.1672271,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2304865,
        store_longitude: -78.2623712,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3317201,
        store_longitude: -78.1712261,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.596241,
        store_longitude: -77.8335,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.3010456,
        store_longitude: -78.2648339,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2213536,
        store_longitude: -78.2663639,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2381476,
        store_longitude: -78.2594457,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.243645,
        store_longitude: -78.2593,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2993353,
        store_longitude: -78.2621412,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2550675,
        store_longitude: -78.2711946,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.232882,
        store_longitude: -78.2702,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2982406,
        store_longitude: -78.2634621,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2815756,
        store_longitude: -78.286547,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.239964,
        store_longitude: -78.2582,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.219639,
        store_longitude: -78.2641,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3308707,
        store_longitude: -78.2135018,
    },
    {
        store_latitdue: 0.232702,
        store_longitude: -78.259132,
    },
    {
        store_latitdue: 0.285273,
        store_longitude: -78.2863,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.20262,
        store_longitude: -78.1912,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.273129,
        store_longitude: -78.235948,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.232282,
        store_longitude: -78.2621,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2366503,
        store_longitude: -78.2692237,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.23725,
        store_longitude: -78.252412,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.229104,
        store_longitude: -78.2617,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.251808,
        store_longitude: -78.242,
        neighborhood: 'DR. MIGUEL EGAS CABEZAS',
    },
    {
        store_latitdue: 0.2780951,
        store_longitude: -78.2354562,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.241835,
        store_longitude: -78.2607,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3347488,
        store_longitude: -78.2214495,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.269007,
        store_longitude: -78.2431087,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.1867378,
        store_longitude: -78.2172413,
    },
    {
        store_latitdue: 0.299409,
        store_longitude: -78.2632,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.325257,
        store_longitude: -78.2097,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.2816964,
        store_longitude: -78.2832158,
    },
    {
        store_latitdue: 0.235606,
        store_longitude: -78.2624,
    },
    {
        store_latitdue: 0.3037916,
        store_longitude: -78.2588319,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.301448,
        store_longitude: -78.2674,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.206628,
        store_longitude: -78.2012,
    },
    {
        store_latitdue: 0.33713027,
        store_longitude: -78.2107579,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.271954,
        store_longitude: -78.234216,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.2341934,
        store_longitude: -78.269417,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.30073,
        store_longitude: -78.2674,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2090734,
        store_longitude: -78.2507093,
    },
    {
        store_latitdue: 0.1954043,
        store_longitude: -78.1881027,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2317299,
        store_longitude: -78.2541215,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.214369,
        store_longitude: -78.2556,
    },
    {
        store_latitdue: 0.2769356,
        store_longitude: -78.2706091,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.1963875,
        store_longitude: -78.1894731,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2272428,
        store_longitude: -78.267845,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.2985113,
        store_longitude: -78.2636548,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.3263557,
        store_longitude: -78.2048127,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.353313,
        store_longitude: -78.1947,
        neighborhood: 'SAN JOSÉ DE CHALTURA',
    },
    {
        store_latitdue: 0.2095536,
        store_longitude: -78.2498093,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.201162,
        store_longitude: -78.192,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.1961625,
        store_longitude: -78.190021,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.304688,
        store_longitude: -78.2575,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.209436,
        store_longitude: -78.2499,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.2305559,
        store_longitude: -78.2631859,
    },
    {
        store_latitdue: 0.201908,
        store_longitude: -78.2546801,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.333952,
        store_longitude: -78.1702,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2301902,
        store_longitude: -78.2631011,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.201469,
        store_longitude: -78.1843,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.3001524,
        store_longitude: -78.264704,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2284214,
        store_longitude: -78.265098,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2101116,
        store_longitude: -78.2490184,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.2058762,
        store_longitude: -78.2566409,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.250085,
        store_longitude: -78.2436,
        neighborhood: 'DR. MIGUEL EGAS CABEZAS',
    },
    {
        store_latitdue: 0.20824,
        store_longitude: -78.2031,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2447581,
        store_longitude: -78.2560736,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.196998,
        store_longitude: -78.2557,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.3040372,
        store_longitude: -78.2640943,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.196935,
        store_longitude: -78.2564,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.2500448,
        store_longitude: -78.2442014,
        neighborhood: 'DR. MIGUEL EGAS CABEZAS',
    },
    {
        store_latitdue: 0.2334314,
        store_longitude: -78.2685869,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.1959883,
        store_longitude: -78.1898947,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.232798,
        store_longitude: -78.2637,
    },
    {
        store_latitdue: 0.33073727,
        store_longitude: -78.17117,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.30369,
        store_longitude: -78.2647,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2344304,
        store_longitude: -78.2690713,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.321125,
        store_longitude: -78.2287,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.229829,
        store_longitude: -78.264,
    },
    {
        store_latitdue: 0.1943951,
        store_longitude: -78.1865216,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2249615,
        store_longitude: -78.2650777,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3045403,
        store_longitude: -78.2672409,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.203173,
        store_longitude: -78.1969,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2232008,
        store_longitude: -78.2683979,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3247815859,
        store_longitude: -78.1750202179,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2304185,
        store_longitude: -78.2579923,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2310925,
        store_longitude: -78.2607042,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.222543,
        store_longitude: -78.2667,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2028129,
        store_longitude: -78.1910443,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.322913,
        store_longitude: -78.1756,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.240807,
        store_longitude: -78.269664,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2220163,
        store_longitude: -78.2806914,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.355778,
        store_longitude: -78.1932,
        neighborhood: 'SAN JOSÉ DE CHALTURA',
    },
    {
        store_latitdue: 0.340579,
        store_longitude: -78.186355,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.239668,
        store_longitude: -78.2697,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2703461,
        store_longitude: -78.246427,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.2220175,
        store_longitude: -78.2708603,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.323338,
        store_longitude: -78.1639,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.2066755,
        store_longitude: -78.2011824,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.3021814,
        store_longitude: -78.2613615,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.3403308,
        store_longitude: -78.1813479,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.179159,
        store_longitude: -78.200904,
        neighborhood: 'GONZÁLEZ SUÁREZ',
    },
    {
        store_latitdue: 0.2153487,
        store_longitude: -78.2746646,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2351654,
        store_longitude: -78.2531636,
    },
    {
        store_latitdue: 0.2774096,
        store_longitude: -78.2327427,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.2255819,
        store_longitude: -78.2307506,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3016083,
        store_longitude: -78.2615861,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2272505,
        store_longitude: -78.270467,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.324776,
        store_longitude: -78.1649,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3015193,
        store_longitude: -78.2340731,
        neighborhood: 'SAN ROQUE',
    },
    {
        store_latitdue: 0.302018,
        store_longitude: -78.266391,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.338851,
        store_longitude: -78.1713,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.222353,
        store_longitude: -78.278897,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.27194,
        store_longitude: -78.2345,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.300847,
        store_longitude: -78.2645,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.2779509,
        store_longitude: -78.2346641,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.3007454,
        store_longitude: -78.2684111,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2713384,
        store_longitude: -78.2344089,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.301062,
        store_longitude: -78.2159,
        neighborhood: 'SAN ROQUE',
    },
    {
        store_latitdue: 0.2993762,
        store_longitude: -78.2644148,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2385829,
        store_longitude: -78.258647,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.27337,
        store_longitude: -78.2321,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.3099568,
        store_longitude: -78.2290501,
        neighborhood: 'SAN ROQUE',
    },
    {
        store_latitdue: 0.2370258,
        store_longitude: -78.2571847,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.299839,
        store_longitude: -78.2623,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.3001108,
        store_longitude: -78.2620695,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.3240608,
        store_longitude: -78.2090228,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.324069,
        store_longitude: -78.2113,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3218289,
        store_longitude: -78.2072297,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.323048,
        store_longitude: -78.2103,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3403849,
        store_longitude: -78.19846,
    },
    {
        store_latitdue: 0.3241566,
        store_longitude: -78.2101256,
    },
    {
        store_latitdue: 0.2997029,
        store_longitude: -78.263563,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.3164795,
        store_longitude: -78.2081017,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.297998,
        store_longitude: -78.268485,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.334843,
        store_longitude: -78.1698,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.323988,
        store_longitude: -78.2113,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3329133,
        store_longitude: -78.1994167,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.327559,
        store_longitude: -78.2108,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.213118,
        store_longitude: -78.2118,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3337826,
        store_longitude: -78.1810247,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3054406,
        store_longitude: -78.2662376,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.32389,
        store_longitude: -78.2133,
    },
    {
        store_latitdue: 0.3266002,
        store_longitude: -78.2049678,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3176571,
        store_longitude: -78.1935752,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.3315853,
        store_longitude: -78.2167884,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3198959,
        store_longitude: -78.2075248,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.334123,
        store_longitude: -78.2158,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.307774,
        store_longitude: -78.2075262,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.2308751,
        store_longitude: -78.2620612,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3333861,
        store_longitude: -78.2150804,
    },
    {
        store_latitdue: 0.322473,
        store_longitude: -78.2213,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.342402,
        store_longitude: -78.1203,
    },
    {
        store_latitdue: 0.324202,
        store_longitude: -78.2232,
    },
    {
        store_latitdue: 0.331563,
        store_longitude: -78.2154,
    },
    {
        store_latitdue: 0.339946,
        store_longitude: -78.2266,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.33149,
        store_longitude: -78.2224,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3375318,
        store_longitude: -78.2122756,
    },
    {
        store_latitdue: 0.3308387,
        store_longitude: -78.2161164,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.318444,
        store_longitude: -78.2063,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.332868,
        store_longitude: -78.2272,
    },
    {
        store_latitdue: 0.3279961,
        store_longitude: -78.2151521,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3280076,
        store_longitude: -78.2155333,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.328667,
        store_longitude: -78.2159,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3302747,
        store_longitude: -78.2199143,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.3303227,
        store_longitude: -78.2164182,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3305768,
        store_longitude: -78.2192184,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3310868,
        store_longitude: -78.2122546,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.331645,
        store_longitude: -78.2167,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.332172,
        store_longitude: -78.2268,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.332635,
        store_longitude: -78.2276,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3332483,
        store_longitude: -78.2272011,
    },
    {
        store_latitdue: 0.3357,
        store_longitude: -78.2235,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3338293,
        store_longitude: -78.2139317,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.335468,
        store_longitude: -78.2169,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3360218,
        store_longitude: -78.2137087,
    },
    {
        store_latitdue: 0.3388056,
        store_longitude: -78.2249505,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3389049,
        store_longitude: -78.225124,
    },
    {
        store_latitdue: 0.339398,
        store_longitude: -78.2104204,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.238417,
        store_longitude: -78.250782,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3406639,
        store_longitude: -78.2125786,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.341337,
        store_longitude: -78.2292033,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3377915,
        store_longitude: -78.2241761,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.341239,
        store_longitude: -78.2288,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.330382,
        store_longitude: -78.2164,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.293548,
        store_longitude: -78.2659,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.239596,
        store_longitude: -78.2604,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2396825,
        store_longitude: -78.2604018,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3434401,
        store_longitude: -78.2215424,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.34873448,
        store_longitude: -78.21387,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3002458,
        store_longitude: -78.2639403,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.357704,
        store_longitude: -78.2493,
    },
    {
        store_latitdue: 0.353934,
        store_longitude: -78.215,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.274887,
        store_longitude: -78.2816,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.301607,
        store_longitude: -78.2628,
    },
    {
        store_latitdue: 0.32019199,
        store_longitude: -78.199455,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.2777105,
        store_longitude: -78.2828014,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.3085029,
        store_longitude: -78.2038694,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3085604,
        store_longitude: -78.2076772,
    },
    {
        store_latitdue: 0.3407583,
        store_longitude: -78.1505196,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3140735,
        store_longitude: -78.2007936,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3352242,
        store_longitude: -78.216907,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3170196,
        store_longitude: -78.2086264,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3202459,
        store_longitude: -78.2139764,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.321577,
        store_longitude: -78.2124,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.253938,
        store_longitude: -78.2539369,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2524953,
        store_longitude: -78.2500062,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2714911,
        store_longitude: -78.2343967,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: -0.2075628,
        store_longitude: -78.4837633,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.230764,
        store_longitude: -78.2585,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.226877,
        store_longitude: -78.2627,
    },
    {
        store_latitdue: 0.2292627,
        store_longitude: -78.261907,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.250117,
        store_longitude: -78.2439,
        neighborhood: 'DR. MIGUEL EGAS CABEZAS',
    },
    {
        store_latitdue: 0.231157,
        store_longitude: -78.62924,
        neighborhood: 'GARCÍA MORENO',
    },
    {
        store_latitdue: 0.1732552,
        store_longitude: -78.4113054,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.355561,
        store_longitude: -78.513437,
        neighborhood: 'APUELA',
    },
    {
        store_latitdue: 0.3362155,
        store_longitude: -78.1557833,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.308665,
        store_longitude: -78.2316,
        neighborhood: 'SAN ROQUE',
    },
    {
        store_latitdue: 0.245506,
        store_longitude: -78.2544,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3338889,
        store_longitude: -78.2180872,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.2250343,
        store_longitude: -78.2619455,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3386876,
        store_longitude: -78.2110256,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.329459,
        store_longitude: -78.2132,
    },
    {
        store_latitdue: 0.307886,
        store_longitude: -78.2277,
        neighborhood: 'SAN ROQUE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.2261422,
        store_longitude: -78.2571072,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.223184,
        store_longitude: -78.2642,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.229806,
        store_longitude: -78.2585009,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2285207,
        store_longitude: -78.2625618,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.202647,
        store_longitude: -78.175071,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.300226,
        store_longitude: -78.264,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2348591,
        store_longitude: -78.2529889,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2411652,
        store_longitude: -78.2538,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2155119,
        store_longitude: -78.2747898,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.229700654,
        store_longitude: -78.2666091,
    },
    {
        store_latitdue: 0.2252273,
        store_longitude: -78.2698148,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2313263,
        store_longitude: -78.2661771,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.221955,
        store_longitude: -78.2695,
    },
    {
        store_latitdue: 0.2324809,
        store_longitude: -78.264572,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.549290716,
        store_longitude: -78.0400009,
        neighborhood: 'MIRA',
    },
    {
        store_latitdue: 0.3293781,
        store_longitude: -78.1119965,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.36083,
        store_longitude: -78.1211,
    },
    {
        store_latitdue: 0.7890434,
        store_longitude: -78.2935539,
        neighborhood: 'LA CAROLINA',
    },
    {
        store_latitdue: 0.3439792,
        store_longitude: -78.1228781,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.342853,
        store_longitude: -78.1402,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.403597,
        store_longitude: -78.1992,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.3697686,
        store_longitude: -78.1256745,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.362218,
        store_longitude: -78.1107388,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8274463,
        store_longitude: -77.7003266,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.473035,
        store_longitude: -78.0664101,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3337838,
        store_longitude: -78.1166957,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.7887667,
        store_longitude: -77.7124836,
        neighborhood: 'URBINA',
    },
    {
        store_latitdue: 0.3300554,
        store_longitude: -78.1165396,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3347584,
        store_longitude: -78.1115868,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.586532,
        store_longitude: -77.8245457,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.327740675,
        store_longitude: -78.1354042539,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.345755,
        store_longitude: -78.1096175,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.2215191,
        store_longitude: -78.265303,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.6339193,
        store_longitude: -77.7249491,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.236774009,
        store_longitude: -78.2658505,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.1355046,
        store_longitude: -78.1858956,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.3352048,
        store_longitude: -78.1929328,
        neighborhood: 'SAN FRANCISCO DE NATABUELA',
    },
    {
        store_latitdue: 0.3445624,
        store_longitude: -78.1218319,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3435252,
        store_longitude: -78.122371,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3435517,
        store_longitude: -78.1221662,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3427759,
        store_longitude: -78.1196297,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3450319,
        store_longitude: -78.121706,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.472599,
        store_longitude: -78.0615,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.4681168,
        store_longitude: -78.0322871,
        neighborhood: 'SAN VICENTE DE PUSIR',
    },
    {
        store_latitdue: 0.344873,
        store_longitude: -78.1222,
    },
    {
        store_latitdue: 0.350383,
        store_longitude: -78.1157,
    },
    {
        store_latitdue: 0.451162,
        store_longitude: -78.0081,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.468794,
        store_longitude: -78.0419,
        neighborhood: 'AMBUQUÍ',
    },

    {
        store_latitdue: -0.3333281,
        store_longitude: -78.4431005,
    },
    {
        store_latitdue: 0.3448119,
        store_longitude: -78.119293,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3633223447,
        store_longitude: -78.1288797535,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3463088,
        store_longitude: -78.1229175,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.3515771,
        store_longitude: -78.1253368,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.348515,
        store_longitude: -78.1218,
    },
    {
        store_latitdue: 0.3280204,
        store_longitude: -78.1381201,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3268838,
        store_longitude: -78.1380791,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3265665671,
        store_longitude: -78.1354740635,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3271022,
        store_longitude: -78.1353694,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3272069,
        store_longitude: -78.1354085,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3231864,
        store_longitude: -78.134582,
    },
    {
        store_latitdue: 0.3099618,
        store_longitude: -78.1198802,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4097018,
        store_longitude: -78.1973653,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.424123,
        store_longitude: -78.1943,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.420327,
        store_longitude: -78.1943,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.4169056,
        store_longitude: -78.1949328,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.41178,
        store_longitude: -78.2065,
    },
    {
        store_latitdue: 0.35026,
        store_longitude: -78.1252629,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3171258,
        store_longitude: -78.1422665,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3109807,
        store_longitude: -78.1443564,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.306182,
        store_longitude: -78.120091,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3908693,
        store_longitude: -77.9417786,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.303424,
        store_longitude: -78.1417,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3042832,
        store_longitude: -78.1414991,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3066105,
        store_longitude: -78.1417218,
    },
    {
        store_latitdue: 0.314917,
        store_longitude: -78.1235027,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4095199,
        store_longitude: -78.2288024,
        neighborhood: 'SAN BLAS',
    },
    {
        store_latitdue: 0.4162194,
        store_longitude: -78.1937595,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.316949,
        store_longitude: -78.1452,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3173267,
        store_longitude: -78.133026,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3197286,
        store_longitude: -78.1233252,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3503598,
        store_longitude: -78.1231207,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349752,
        store_longitude: -78.1234,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3273804,
        store_longitude: -78.1353894,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3270034,
        store_longitude: -78.1353269,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.34425569,
        store_longitude: -78.13462031,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3112244,
        store_longitude: -78.1241546,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.410227,
        store_longitude: -78.1997,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.390856848,
        store_longitude: -77.9421692714,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.4194339,
        store_longitude: -78.1934618,
        neighborhood: 'URCUQUÍ',
    },

    {
        store_latitdue: 0.4173562,
        store_longitude: -78.1946178,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.3523564,
        store_longitude: -78.1261433,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.409495,
        store_longitude: -78.229,
        neighborhood: 'SAN BLAS',
    },
    {
        store_latitdue: 0.32401,
        store_longitude: -78.1359,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3687363,
        store_longitude: -78.151202,
    },
    {
        store_latitdue: 0.7261316,
        store_longitude: -78.2185782,
        neighborhood: 'JIJÓN Y CAAMAÑO',
    },
    {
        store_latitdue: 0.7894556,
        store_longitude: -78.2944201,
        neighborhood: 'LA CAROLINA',
    },
    {
        store_latitdue: 0.7011204,
        store_longitude: -78.2035613,
        neighborhood: 'LA CAROLINA',
    },
    {
        store_latitdue: 0.3457343,
        store_longitude: -78.1191019,
    },
    {
        store_latitdue: 0.4168403,
        store_longitude: -78.1943112,
        neighborhood: 'URCUQUÍ',
    },

    {
        store_latitdue: 0.354425,
        store_longitude: -78.1484,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.340018,
        store_longitude: -78.1514,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3407684,
        store_longitude: -78.1524004,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3532426,
        store_longitude: -78.149028,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3411196,
        store_longitude: -78.1428305,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3402817,
        store_longitude: -78.115323,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3383927,
        store_longitude: -78.1166825,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3311748,
        store_longitude: -78.1187857,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.3562479,
        store_longitude: -78.1251246,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3395189,
        store_longitude: -78.1202538,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3344382485,
        store_longitude: -78.1207590116,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3391493,
        store_longitude: -78.1487745,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3398589333,
        store_longitude: -78.1232485854,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3364521,
        store_longitude: -78.1217454,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.341565,
        store_longitude: -78.1197,
    },
    {
        store_latitdue: 0.333759,
        store_longitude: -78.1166,
    },
    {
        store_latitdue: 0.3490908,
        store_longitude: -78.1307163,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.343081,
        store_longitude: -78.133,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3413038,
        store_longitude: -78.1327715,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.341829417,
        store_longitude: -78.1389964,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3244704,
        store_longitude: -78.1228261,
    },
    {
        store_latitdue: 0.3258854,
        store_longitude: -78.1286089,
    },
    {
        store_latitdue: 0.3293584,
        store_longitude: -78.1282977,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3364211,
        store_longitude: -78.1210101,
    },
    {
        store_latitdue: 0.3349158,
        store_longitude: -78.1351224,
    },
    {
        store_latitdue: 0.345528,
        store_longitude: -78.1463,
    },
    {
        store_latitdue: 0.342199862,
        store_longitude: -78.12350464,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3450928,
        store_longitude: -78.1262641,
    },
    {
        store_latitdue: 0.341944,
        store_longitude: -78.132205,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3335559,
        store_longitude: -78.1342664,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3358835,
        store_longitude: -78.1384976,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3342928,
        store_longitude: -78.1166864,
    },
    {
        store_latitdue: 0.3358469,
        store_longitude: -78.1273295,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3333317,
        store_longitude: -78.1322895,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.333972,
        store_longitude: -78.1211,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3311324,
        store_longitude: -78.1192338,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.383305,
        store_longitude: -78.1054,
    },
    {
        store_latitdue: 0.3281502,
        store_longitude: -78.1040335,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.5848814,
        store_longitude: -77.8242459,
    },

    {
        store_latitdue: 0.322188,
        store_longitude: -78.117164,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344143,
        store_longitude: -78.1259,
    },

    {
        store_latitdue: 0.344626,
        store_longitude: -78.126,
    },

    {
        store_latitdue: -0.20267,
        store_longitude: -78.489813,
    },
    {
        store_latitdue: 0.358576818,
        store_longitude: -78.1274592,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3581384,
        store_longitude: -78.1239067,
    },
    {
        store_latitdue: 0.361924484,
        store_longitude: -78.1193932146,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3643484,
        store_longitude: -78.1291777,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3627552,
        store_longitude: -78.128388,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.363872,
        store_longitude: -78.1301072,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.362562,
        store_longitude: -78.1249105,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3634659,
        store_longitude: -78.12682,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3535882,
        store_longitude: -78.1282556,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3604167,
        store_longitude: -78.1232783,
    },
    {
        store_latitdue: 0.364961,
        store_longitude: -78.1352,
    },
    {
        store_latitdue: 0.2765882,
        store_longitude: -78.1088062,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: -0.1031629,
        store_longitude: -78.4231351,
    },
    {
        store_latitdue: 0.3549233,
        store_longitude: -78.1328108,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.355846,
        store_longitude: -78.1316473,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3578753,
        store_longitude: -78.1298586,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.41152195,
        store_longitude: -77.91144983,
        neighborhood: 'SAN RAFAEL',
    },
    {
        store_latitdue: -0.933359,
        store_longitude: -78.63171,
        neighborhood: 'LATACUNGA',
    },
    {
        store_latitdue: 0.3893457,
        store_longitude: -77.9409417,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.4329717,
        store_longitude: -77.9322762,
        neighborhood: 'SAN RAFAEL',
    },
    {
        store_latitdue: 0.363029,
        store_longitude: -78.1218,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.367545,
        store_longitude: -78.1293,
    },
    {
        store_latitdue: 0.3657683,
        store_longitude: -78.1352678,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.305854,
        store_longitude: -78.1065,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.4350253,
        store_longitude: -77.96786,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.3621667,
        store_longitude: -78.1281148,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3576783,
        store_longitude: -78.1294909,
    },
    {
        store_latitdue: 0.358868761,
        store_longitude: -78.1288255,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.2055425,
        store_longitude: -78.0937954,
        neighborhood: 'ANGOCHAGUA',
    },
    {
        store_latitdue: 0.363415,
        store_longitude: -78.131419,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.310465,
        store_longitude: -78.1434,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.435397,
        store_longitude: -77.9685028,
        neighborhood: 'AMBUQUÍ',
    },
    {
        store_latitdue: 0.2762127,
        store_longitude: -78.1242173,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.2118265,
        store_longitude: -78.0944433,
        neighborhood: 'ANGOCHAGUA',
    },
    {
        store_latitdue: 0.2760113,
        store_longitude: -78.1240473,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.4406919,
        store_longitude: -77.9548263,
        neighborhood: 'LOS ANDES',
    },

    {
        store_latitdue: 0.3891718,
        store_longitude: -77.9401173,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.35236,
        store_longitude: -78.139805,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.483125,
        store_longitude: -78.117759,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: 0.72621166666667,
        store_longitude: -78.218623333333,
        neighborhood: 'JIJÓN Y CAAMAÑO',
    },
    {
        store_latitdue: 0.8683188,
        store_longitude: -78.4515746,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.501383,
        store_longitude: -78.1931721,
        neighborhood: 'PABLO ARENAS',
    },
    {
        store_latitdue: 0.339553,
        store_longitude: -78.1228641,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.49401877,
        store_longitude: -78.13113301,
        neighborhood: 'SALINAS',
    },
    {
        store_latitdue: 0.8027076,
        store_longitude: -78.3150365,
    },
    {
        store_latitdue: 0.3244,
        store_longitude: -78.2235,
    },
    {
        store_latitdue: 0.3435657,
        store_longitude: -78.1452292,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.867837,
        store_longitude: -78.4509459,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.3541131,
        store_longitude: -78.1102827,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3621183,
        store_longitude: -78.1107143,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.392205,
        store_longitude: -77.9416,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.3428932,
        store_longitude: -78.1081565,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.455458,
        store_longitude: -78.2177,
    },
    {
        store_latitdue: 0.3600391,
        store_longitude: -78.1119889,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8032425,
        store_longitude: -78.3156383,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.3618692,
        store_longitude: -78.1105592,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.33769385,
        store_longitude: -78.112094,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.3576276854,
        store_longitude: -78.1336646834,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3576777,
        store_longitude: -78.1345287,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3359501,
        store_longitude: -78.1153706,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3426185,
        store_longitude: -78.1214694,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3620549,
        store_longitude: -78.1343335,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3618871,
        store_longitude: -78.1341755,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.8326407,
        store_longitude: -78.4020126,
        neighborhood: 'LITA',
    },
    {
        store_latitdue: 0.3920587,
        store_longitude: -77.9416844,
    },

    {
        store_latitdue: 0.3505712,
        store_longitude: -78.1168558,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3346169,
        store_longitude: -78.1479662,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3554825,
        store_longitude: -78.1238228,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.335945,
        store_longitude: -78.1133,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.340784,
        store_longitude: -78.1396,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.341372,
        store_longitude: -78.1412,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.388443,
        store_longitude: -77.9401,
    },
    {
        store_latitdue: 0.8297016303,
        store_longitude: -77.7144192159,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.798741,
        store_longitude: -77.741297,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7973714,
        store_longitude: -77.7410576,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.798069,
        store_longitude: -77.728631,
    },
    {
        store_latitdue: 0.8036953,
        store_longitude: -77.7282221,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8075299,
        store_longitude: -77.7260115,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7983991,
        store_longitude: -77.7339691,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7928115,
        store_longitude: -77.7379609,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.7888966,
        store_longitude: -77.7431535,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8102187,
        store_longitude: -77.7201678,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7990256,
        store_longitude: -77.7418296,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.801407,
        store_longitude: -77.7323,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8026473,
        store_longitude: -77.7259487,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.812024,
        store_longitude: -77.7236,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8054958,
        store_longitude: -77.7290189,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7859644,
        store_longitude: -77.7436151,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7947248,
        store_longitude: -77.7387932,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.7972364,
        store_longitude: -77.7291699,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7995269369,
        store_longitude: -77.73011229,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.804617345,
        store_longitude: -77.7309951,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.805698867,
        store_longitude: -77.7326617886,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.804837,
        store_longitude: -77.7296,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8101555,
        store_longitude: -77.7300974,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8090757,
        store_longitude: -77.7249307,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7950515,
        store_longitude: -77.7383514,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.8035509,
        store_longitude: -77.7302959,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8078357,
        store_longitude: -77.7324851,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8102621,
        store_longitude: -77.7315702,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7868318,
        store_longitude: -77.7296525,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.8045604,
        store_longitude: -77.7248547,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.81735,
        store_longitude: -77.7136,
    },

    {
        store_latitdue: 0.818555,
        store_longitude: -77.7145185,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.800012579,
        store_longitude: -77.7409315,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.7842292,
        store_longitude: -77.7438757,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.803676,
        store_longitude: -77.7337,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8165316669,
        store_longitude: -77.7200810239,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8119458,
        store_longitude: -77.7179561,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8313923,
        store_longitude: -77.71613129,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8266635,
        store_longitude: -77.7120144,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: -0.053528,
        store_longitude: -78.4533778,
    },
    {
        store_latitdue: 0.8223268,
        store_longitude: -77.7062717,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8166646,
        store_longitude: -77.7150709,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8119322587,
        store_longitude: -77.7193980691,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8286527,
        store_longitude: -77.7038995,
    },
    {
        store_latitdue: 0.81388,
        store_longitude: -77.71916,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.816776,
        store_longitude: -77.7128497,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8288886,
        store_longitude: -77.7058151,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.829403,
        store_longitude: -77.7058,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8299598,
        store_longitude: -77.7150963,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.8191477,
        store_longitude: -77.7109847,
        neighborhood: 'TULCÁN',
    },

    {
        store_latitdue: 0.8123205,
        store_longitude: -77.7187047,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.6677175,
        store_longitude: -77.7122691,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.6349583,
        store_longitude: -77.7254609,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.601411,
        store_longitude: -77.8206439,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.5988004,
        store_longitude: -77.8361974,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.6006001,
        store_longitude: -77.8323241,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.59379608,
        store_longitude: -77.8329254,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.6654559308663,
        store_longitude: -77.713703233749,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.5896271,
        store_longitude: -77.8266239,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.5993842,
        store_longitude: -77.83042,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: -0.0936318,
        store_longitude: -78.4389763,
    },
    {
        store_latitdue: 0.6397528,
        store_longitude: -77.7249189,
        neighborhood: 'HUACA',
    },
    {
        store_latitdue: 0.5991797,
        store_longitude: -77.8363698,
    },
    {
        store_latitdue: 0.653097,
        store_longitude: -77.7220822,
        neighborhood: 'JULIO ANDRADE',
    },
    {
        store_latitdue: 0.5868418,
        store_longitude: -77.8276583,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.5945773992,
        store_longitude: -77.834004499,
        neighborhood: 'SAN GABRIEL',
    },
    {
        store_latitdue: 0.5889009,
        store_longitude: -77.8288305,
    },

    {
        store_latitdue: 0.6027214,
        store_longitude: -77.9886499,
        neighborhood: 'SAN ISIDRO',
    },
    {
        store_latitdue: 0.6230177,
        store_longitude: -77.9388027,
        neighborhood: 'EL ÁNGEL',
    },
    {
        store_latitdue: 0.5168108,
        store_longitude: -77.881655,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.5029158,
        store_longitude: -77.9070701,
        neighborhood: 'BOLÍVAR',
    },
    {
        store_latitdue: 0.6527637,
        store_longitude: -77.9431019,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: 0.6527945,
        store_longitude: -77.9438444,
        neighborhood: 'LA LIBERTAD',
    },
    {
        store_latitdue: 0.345413,
        store_longitude: -78.1235,
    },

    {
        store_latitdue: 0.3224115567,
        store_longitude: -78.1181466579,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.330259,
        store_longitude: -78.1121,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.355453,
        store_longitude: -78.1203,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3846612,
        store_longitude: -78.1058525,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3602913,
        store_longitude: -78.0934631,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3323902,
        store_longitude: -78.1097731,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3289362,
        store_longitude: -78.1079124,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3509884653613,
        store_longitude: -78.133687544614,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3483804,
        store_longitude: -78.1280648,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.353209,
        store_longitude: -78.129305,
    },
    {
        store_latitdue: 0.3637009,
        store_longitude: -78.113398,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.32669,
        store_longitude: -78.1213,
    },
    {
        store_latitdue: 0.3288097,
        store_longitude: -78.122097,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.358794,
        store_longitude: -78.1312,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3304684,
        store_longitude: -78.1089925,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3622301,
        store_longitude: -78.1120926,
    },
    {
        store_latitdue: 0.360908,
        store_longitude: -78.112064,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3587529,
        store_longitude: -78.0946309,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.389042,
        store_longitude: -78.1066,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3844733,
        store_longitude: -78.1091383,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.327962,
        store_longitude: -78.1077,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.375432,
        store_longitude: -78.1215,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.372022,
        store_longitude: -78.1214282,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3602343,
        store_longitude: -78.0908202,
    },
    {
        store_latitdue: 0.3562744,
        store_longitude: -78.1381071,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.323491695,
        store_longitude: -78.1158447,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3850491,
        store_longitude: -78.1055995,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.367831,
        store_longitude: -78.1106,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.3590181,
        store_longitude: -78.1073712,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.352370918,
        store_longitude: -78.13438416,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.360244,
        store_longitude: -78.0908528,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3875033,
        store_longitude: -78.1036167,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3479134,
        store_longitude: -78.1352661,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3537328,
        store_longitude: -78.1306489,
    },
    {
        store_latitdue: 0.3479323,
        store_longitude: -78.1356866,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3457512,
        store_longitude: -78.1384625,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.385763,
        store_longitude: -78.1114157,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3438757,
        store_longitude: -78.1411125,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.344218,
        store_longitude: -78.1402,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3549397,
        store_longitude: -78.116674,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3417528,
        store_longitude: -78.1439251,
    },
    {
        store_latitdue: 0.3602135,
        store_longitude: -78.1126665,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3592,
        store_longitude: -78.131,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.348363369,
        store_longitude: -78.1314392,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3527405,
        store_longitude: -78.118348,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3581898,
        store_longitude: -78.1200279,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.33970666666667,
        store_longitude: -78.12122,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.2286275,
        store_longitude: -78.2653163,
        neighborhood: 'OTAVALO',
    },

    {
        store_latitdue: 0.243021,
        store_longitude: -78.2708,
        neighborhood: 'OTAVALO',
    },

    {
        store_latitdue: 0.22933,
        store_longitude: -78.2634,
    },

    {
        store_latitdue: 0.2243764,
        store_longitude: -78.2691616,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2370398,
        store_longitude: -78.2525569,
    },
    {
        store_latitdue: 0.249064,
        store_longitude: -78.2430231,
        neighborhood: 'DR. MIGUEL EGAS CABEZAS',
    },

    {
        store_latitdue: 0.228493,
        store_longitude: -78.2648,
        neighborhood: 'OTAVALO',
    },

    {
        store_latitdue: 0.235555,
        store_longitude: -78.254372,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.331561,
        store_longitude: -78.215522,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.331775128,
        store_longitude: -78.2197723,
    },
    {
        store_latitdue: 0.398976,
        store_longitude: -78.526253,
    },
    {
        store_latitdue: 0.328631,
        store_longitude: -78.549643,
        neighborhood: 'PEÑAHERRERA',
    },
    {
        store_latitdue: 0.231086,
        store_longitude: -78.629394,
    },
    {
        store_latitdue: 0.22609,
        store_longitude: -78.2636,
    },
    {
        store_latitdue: 0.1898156,
        store_longitude: -78.2222529,
        neighborhood: 'SAN RAFAEL',
    },
    {
        store_latitdue: 0.2728085,
        store_longitude: -78.2337542,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.2413641,
        store_longitude: -78.2592508,
    },
    {
        store_latitdue: 0.1926168,
        store_longitude: -78.227718,
        neighborhood: 'SAN RAFAEL',
    },
    {
        store_latitdue: 0.2231489,
        store_longitude: -78.270639,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2085306,
        store_longitude: -78.2508394,
        neighborhood: 'EUGENIO ESPEJO',
    },
    {
        store_latitdue: 0.134711,
        store_longitude: -78.186,
    },
    {
        store_latitdue: -0.2079281,
        store_longitude: -78.5065614,
    },
    {
        store_latitdue: 0.280676,
        store_longitude: -78.2301,
        neighborhood: 'SAN JUAN DE ILUMÁN',
    },
    {
        store_latitdue: 0.2253048,
        store_longitude: -78.2716179,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.3267468,
        store_longitude: -78.2140648,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.2272143,
        store_longitude: -78.2274833,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2052439,
        store_longitude: -78.1992845,
        neighborhood: 'SAN PABLO',
    },

    {
        store_latitdue: 0.227024,
        store_longitude: -78.2605,
    },
    {
        store_latitdue: 0.1997898,
        store_longitude: -78.1933419,
    },
    {
        store_latitdue: 0.2398577,
        store_longitude: -78.2567071,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2437000152,
        store_longitude: -78.2638602081,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2446593,
        store_longitude: -78.2655039,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2338022,
        store_longitude: -78.2712779,
        neighborhood: 'OTAVALO',
    },

    {
        store_latitdue: 0.2296983,
        store_longitude: -78.2585755,
    },
    {
        store_latitdue: 0.201111,
        store_longitude: -78.1647,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2381666,
        store_longitude: -78.2671647,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.2808703,
        store_longitude: -78.2835852,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.2820174,
        store_longitude: -78.2850068,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.282493,
        store_longitude: -78.2851,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.282296,
        store_longitude: -78.285381,
        neighborhood: 'QUIROGA',
    },
    {
        store_latitdue: 0.2823218,
        store_longitude: -78.2852655,
        neighborhood: 'QUIROGA',
    },

    {
        store_latitdue: 0.324202,
        store_longitude: -78.2231088,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3327777,
        store_longitude: -78.223756,
        neighborhood: 'ATUNTAQUI',
    },

    {
        store_latitdue: 0.3577,
        store_longitude: -78.2494,
        neighborhood: 'IMANTAG',
    },
    {
        store_latitdue: 0.3560437,
        store_longitude: -78.2552788,
        neighborhood: 'IMANTAG',
    },
    {
        store_latitdue: 0.335097543,
        store_longitude: -78.16337207,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3328764,
        store_longitude: -78.1708174,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3320788,
        store_longitude: -78.1698302,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.337894,
        store_longitude: -78.1688,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3361601,
        store_longitude: -78.1690526,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3385459,
        store_longitude: -78.193225,
    },
    {
        store_latitdue: 0.334699,
        store_longitude: -78.1699,
        neighborhood: 'SAN ANTONIO',
    },

    {
        store_latitdue: 0.331277,
        store_longitude: -78.217,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3541126,
        store_longitude: -78.1244206,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.1958179,
        store_longitude: -78.1899523,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.1958345,
        store_longitude: -78.1907081,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.207762,
        store_longitude: -78.1879,
        neighborhood: 'SAN PABLO',
    },
    {
        store_latitdue: 0.2974993,
        store_longitude: -78.2758913,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.3351455,
        store_longitude: -78.2168009,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: -0.127446,
        store_longitude: -78.5011,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.313456,
        store_longitude: -78.1594,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3525021,
        store_longitude: -78.1922381,
        neighborhood: 'SAN JOSÉ DE CHALTURA',
    },
    {
        store_latitdue: 0.3562519,
        store_longitude: -78.1960399,
        neighborhood: 'SAN JOSÉ DE CHALTURA',
    },
    {
        store_latitdue: 0.300223,
        store_longitude: -78.2776,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.2393384,
        store_longitude: -78.2555496,
    },

    {
        store_latitdue: 0.31967574,
        store_longitude: -78.17271,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.327082961,
        store_longitude: -78.1726837,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3238878,
        store_longitude: -78.1685517,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: 0.3462047,
        store_longitude: -78.1762905,
    },
    {
        store_latitdue: 0.2338998,
        store_longitude: -78.2714569,
        neighborhood: 'OTAVALO',
    },
    {
        store_latitdue: 0.184695754,
        store_longitude: -78.2242441,
    },
    {
        store_latitdue: -0.217678,
        store_longitude: -78.377252,
    },
    {
        store_latitdue: 0.279192,
        store_longitude: -78.2726,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.3302216,
        store_longitude: -78.2187096,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3246866,
        store_longitude: -78.2201311,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.326652,
        store_longitude: -78.2179,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.328181,
        store_longitude: -78.2174,
    },
    {
        store_latitdue: 0.328039,
        store_longitude: -78.2197,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.330809,
        store_longitude: -78.2206,
        neighborhood: 'ATUNTAQUI',
    },

    {
        store_latitdue: 0.3092347448,
        store_longitude: -78.2066002884,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.3218093,
        store_longitude: -78.2090742,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: 0.44106,
        store_longitude: -78.1986,
        neighborhood: 'URCUQUÍ',
    },
    {
        store_latitdue: 0.3447038,
        store_longitude: -78.1162795,
    },
    {
        store_latitdue: 0.342746,
        store_longitude: -78.1229,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.346029,
        store_longitude: -78.1183,
    },
    {
        store_latitdue: 0.3358362,
        store_longitude: -78.12722,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.3494065,
        store_longitude: -78.1352007,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.349489667,
        store_longitude: -78.1351196,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },

    {
        store_latitdue: 0.334835,
        store_longitude: -78.1162,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.7971517,
        store_longitude: -77.7342485,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.3331569,
        store_longitude: -78.13459,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.81317,
        store_longitude: -77.7176,
        neighborhood: 'TULCÁN',
    },
    {
        store_latitdue: 0.348878,
        store_longitude: -78.1078,
    },

    {
        store_latitdue: 0.965998,
        store_longitude: -79.6603892,
        neighborhood: 'ESMERALDAS',
    },
    {
        store_latitdue: 0.351157,
        store_longitude: -78.1299,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: 0.346629,
        store_longitude: -78.108,
    },
    {
        store_latitdue: 0.29837,
        store_longitude: -78.266281,
        neighborhood: 'COTACACHI',
    },
    {
        store_latitdue: 0.4607389,
        store_longitude: -78.233857,
        neighborhood: 'PABLO ARENAS',
    },

    {
        store_latitdue: 0.392965482,
        store_longitude: -77.9413113,
        neighborhood: 'PIMAMPIRO',
    },
    {
        store_latitdue: 0.4512781,
        store_longitude: -78.228988,
        neighborhood: 'TUMBABIRO',
    },
    {
        store_latitdue: 0.1217264,
        store_longitude: -79.2534294,
    },
    {
        store_latitdue: -0.6705643918,
        store_longitude: -79.6082211258,
    },
    {
        store_latitdue: -0.243309,
        store_longitude: -79.136018,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.259911,
        store_longitude: -79.187722,
    },
    {
        store_latitdue: -0.2582534,
        store_longitude: -79.1868766,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2435816,
        store_longitude: -79.1974287,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2594624,
        store_longitude: -79.1852014,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.222949,
        store_longitude: -79.1968,
    },
    {
        store_latitdue: 0.0146525,
        store_longitude: -79.3850407,
    },
    {
        store_latitdue: 0.0032938,
        store_longitude: -79.4011,
    },
    {
        store_latitdue: 0.0029292,
        store_longitude: -79.3883329,
    },
    {
        store_latitdue: -0.250754,
        store_longitude: -79.1713529,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.255701,
        store_longitude: -79.1984,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2910029,
        store_longitude: -79.2144838,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.243741,
        store_longitude: -79.3403,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2476589,
        store_longitude: -79.4963941,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2477839,
        store_longitude: -79.2013276,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2597782,
        store_longitude: -79.4022301,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2716916,
        store_longitude: -79.2005734,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2590287,
        store_longitude: -79.4050332,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.277117,
        store_longitude: -79.182103,
    },
    {
        store_latitdue: -0.2384206,
        store_longitude: -79.1680541,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.237577,
        store_longitude: -79.1675,
    },
    {
        store_latitdue: -0.229852,
        store_longitude: -79.1598,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1347695,
        store_longitude: -79.4044442,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.134923,
        store_longitude: -79.404956,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.3229378,
        store_longitude: -79.2124778,
    },
    {
        store_latitdue: -0.2310772,
        store_longitude: -79.1757818,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2289373,
        store_longitude: -79.167537,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.244026,
        store_longitude: -79.153082,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.239132,
        store_longitude: -79.1758597,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.227683,
        store_longitude: -79.1865,
    },
    {
        store_latitdue: -0.240559,
        store_longitude: -79.1701,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2492502,
        store_longitude: -79.1831577,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.227517,
        store_longitude: -79.18466,
    },
    {
        store_latitdue: -0.2383909,
        store_longitude: -79.1578194,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2415031,
        store_longitude: -79.1822834,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.242327,
        store_longitude: -79.158,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.226769,
        store_longitude: -79.190123,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2347045,
        store_longitude: -79.1857477,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2277155,
        store_longitude: -79.1857603,
    },
    {
        store_latitdue: -0.247451,
        store_longitude: -79.16978,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2391627,
        store_longitude: -79.1852293,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.14625,
        store_longitude: -79.5664,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: 0.005539,
        store_longitude: -79.39553,
    },
    {
        store_latitdue: -0.110081,
        store_longitude: -79.6164,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.2583556,
        store_longitude: -79.1809921,
    },
    {
        store_latitdue: -0.261644574,
        store_longitude: -79.18648457,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2903499,
        store_longitude: -79.2140644,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2453184,
        store_longitude: -79.1988843,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.281407,
        store_longitude: -79.213,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.330379,
        store_longitude: -79.3086473,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -0.2776244,
        store_longitude: -79.2036224,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2470017,
        store_longitude: -79.1517349,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.24078,
        store_longitude: -79.2233526,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2499023,
        store_longitude: -79.4871672,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2618855,
        store_longitude: -79.4078203,
    },
    {
        store_latitdue: -0.2445667,
        store_longitude: -79.3485492,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2939319,
        store_longitude: -79.503826,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2540589,
        store_longitude: -79.2157981,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3010281,
        store_longitude: -79.2749828,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.300707,
        store_longitude: -79.2752521,
    },
    {
        store_latitdue: -0.0988592,
        store_longitude: -79.2979925,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: 0.133131,
        store_longitude: -79.4074,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.1325674,
        store_longitude: -79.4048546,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2325521,
        store_longitude: -79.1882557,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.226403,
        store_longitude: -79.197758,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2340992,
        store_longitude: -79.185714,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2300816,
        store_longitude: -79.1833512,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.229744,
        store_longitude: -79.1675,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.005289,
        store_longitude: -79.3957,
    },
    {
        store_latitdue: 0.0055593,
        store_longitude: -79.3954236,
    },
    {
        store_latitdue: 0.004554,
        store_longitude: -79.39509582,
    },
    {
        store_latitdue: -0.001146,
        store_longitude: -79.392513,
    },
    {
        store_latitdue: -0.17624,
        store_longitude: -79.54157,
    },
    {
        store_latitdue: -0.0302775,
        store_longitude: -79.4951868,
    },
    {
        store_latitdue: 0.0188079,
        store_longitude: -79.4026418,
    },
    {
        store_latitdue: -0.2662096,
        store_longitude: -79.196476,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2497549,
        store_longitude: -79.1639153,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25544,
        store_longitude: -79.1775,
    },
    {
        store_latitdue: -0.2583095,
        store_longitude: -79.1875273,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.267424,
        store_longitude: -79.20739,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2766772,
        store_longitude: -79.2031833,
    },
    {
        store_latitdue: -0.276759,
        store_longitude: -79.2031,
    },
    {
        store_latitdue: -0.2440498,
        store_longitude: -79.3385698,
    },
    {
        store_latitdue: -0.2750479,
        store_longitude: -79.4703475,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.244135,
        store_longitude: -79.345166,
    },
    {
        store_latitdue: -0.265655,
        store_longitude: -79.4805,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2622374,
        store_longitude: -79.4745063,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2629561,
        store_longitude: -79.4770714,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2614717,
        store_longitude: -79.4141367,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.27219,
        store_longitude: -79.4489,
    },
    {
        store_latitdue: -0.2270544,
        store_longitude: -79.1641344,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1324985,
        store_longitude: -79.4054124,
    },
    {
        store_latitdue: 0.1375164,
        store_longitude: -79.4045955,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.1338444,
        store_longitude: -79.4051247,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.0416636,
        store_longitude: -79.4928202,
    },
    {
        store_latitdue: -0.2259455,
        store_longitude: -79.1867518,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.017549,
        store_longitude: -79.380571,
    },
    {
        store_latitdue: -0.042353,
        store_longitude: -79.493309,
    },
    {
        store_latitdue: -0.297993,
        store_longitude: -79.22,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2520386,
        store_longitude: -79.1960877,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2588404,
        store_longitude: -79.1826421,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0428785,
        store_longitude: -79.4073552,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.238608,
        store_longitude: -79.1798,
    },
    {
        store_latitdue: -0.2568479,
        store_longitude: -79.1856981,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0410484,
        store_longitude: -79.4066357,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2601635,
        store_longitude: -79.1859974,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0081966,
        store_longitude: -79.3925584,
    },
    {
        store_latitdue: -0.25018,
        store_longitude: -79.488654,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.25228,
        store_longitude: -79.174,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.272557,
        store_longitude: -79.20633,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2222895,
        store_longitude: -79.156396,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.251912,
        store_longitude: -79.186118,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.23019,
        store_longitude: -79.1868,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0153494,
        store_longitude: -79.3981,
    },
    {
        store_latitdue: -0.247103,
        store_longitude: -79.198,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.041957,
        store_longitude: -79.4064857,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2563637,
        store_longitude: -79.2116406,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26272,
        store_longitude: -79.181516,
    },
    {
        store_latitdue: -0.2205799,
        store_longitude: -79.1974803,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.268745,
        store_longitude: -79.448457,
    },
    {
        store_latitdue: -0.2412321,
        store_longitude: -79.1570397,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2441536,
        store_longitude: -79.3455876,
    },
    {
        store_latitdue: -0.238341,
        store_longitude: -79.1649,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2709579,
        store_longitude: -79.4700936,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2493537,
        store_longitude: -79.188212,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2507292,
        store_longitude: -79.1645473,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.241444,
        store_longitude: -79.1586086,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.256633,
        store_longitude: -79.1789777,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2286023,
        store_longitude: -79.178382,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3245502,
        store_longitude: -79.2009754,
    },
    {
        store_latitdue: -0.2526618,
        store_longitude: -79.1740725,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2750093,
        store_longitude: -79.4621809,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0094488,
        store_longitude: -79.3865,
    },
    {
        store_latitdue: -0.280249,
        store_longitude: -79.469755,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0136885,
        store_longitude: -79.3864917,
    },
    {
        store_latitdue: -0.2610284,
        store_longitude: -79.4791722,
    },
    {
        store_latitdue: -0.2258021,
        store_longitude: -79.197701,
    },
    {
        store_latitdue: -0.275219,
        store_longitude: -79.469843,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.23352,
        store_longitude: -79.180843,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2733891,
        store_longitude: -79.4635304,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.317724,
        store_longitude: -79.213499,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.297523,
        store_longitude: -79.275217,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0041539,
        store_longitude: -79.3986233,
    },
    {
        store_latitdue: 0.0906784,
        store_longitude: -79.4124448,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2446315,
        store_longitude: -79.1972102,
    },
    {
        store_latitdue: -0.25006,
        store_longitude: -79.489,
    },
    {
        store_latitdue: 0.1359470039,
        store_longitude: -79.40412139,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2524712,
        store_longitude: -79.4905746,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.250114,
        store_longitude: -79.367174,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.086546,
        store_longitude: -79.280693,
    },
    {
        store_latitdue: -0.246407,
        store_longitude: -79.1706,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1324575,
        store_longitude: -79.4054303,
    },
    {
        store_latitdue: -0.2503861,
        store_longitude: -79.1802376,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0589192,
        store_longitude: -79.4451326,
    },
    {
        store_latitdue: 0.006601012,
        store_longitude: -79.39465739,
    },
    {
        store_latitdue: -0.2520552,
        store_longitude: -79.1682688,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2094328,
        store_longitude: -79.2631515,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.244332,
        store_longitude: -79.183225,
    },
    {
        store_latitdue: -0.2438741,
        store_longitude: -79.1733978,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.238515,
        store_longitude: -79.175973,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2342766,
        store_longitude: -79.1664764,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.243413,
        store_longitude: -79.345419,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0289254,
        store_longitude: -79.4962076,
    },
    {
        store_latitdue: -0.2808495,
        store_longitude: -79.2122192,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2573643,
        store_longitude: -79.1954823,
    },
    {
        store_latitdue: -0.2359888,
        store_longitude: -79.1663457,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1346319,
        store_longitude: -79.4034361,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2436687,
        store_longitude: -79.1663957,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2319135,
        store_longitude: -79.1722162,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2573363,
        store_longitude: -79.1827105,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2368,
        store_longitude: -79.1997,
    },
    {
        store_latitdue: -0.241822,
        store_longitude: -79.1776965,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2725829,
        store_longitude: -79.209039,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0194346,
        store_longitude: -79.4011512,
    },
    {
        store_latitdue: -0.1474564,
        store_longitude: -79.3948959,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.258127,
        store_longitude: -79.198055,
    },
    {
        store_latitdue: 0.1267319,
        store_longitude: -79.4073525,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.0064513,
        store_longitude: -79.3958669,
    },
    {
        store_latitdue: -0.272211,
        store_longitude: -79.44513,
    },
    {
        store_latitdue: 0.1347965,
        store_longitude: -79.4029104,
    },
    {
        store_latitdue: -0.236208,
        store_longitude: -79.18618,
    },
    {
        store_latitdue: 0.0405296,
        store_longitude: -79.4070235,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2498746,
        store_longitude: -79.1676782,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1309073,
        store_longitude: -79.4058415,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2282644,
        store_longitude: -79.1687724,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0140157,
        store_longitude: -79.400087,
    },
    {
        store_latitdue: -0.2773293,
        store_longitude: -79.4663204,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2448269,
        store_longitude: -79.1825649,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2629535,
        store_longitude: -79.1917789,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.244337,
        store_longitude: -79.347,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.242329,
        store_longitude: -79.1577831,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.250312,
        store_longitude: -79.488241,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2559855,
        store_longitude: -79.185764,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2552252,
        store_longitude: -79.1830094,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2287095,
        store_longitude: -79.185298,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2520219,
        store_longitude: -79.1865725,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0097685,
        store_longitude: -79.3826,
    },
    {
        store_latitdue: -0.27188,
        store_longitude: -79.464,
    },
    {
        store_latitdue: -0.1141159,
        store_longitude: -79.6061619,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.2250247,
        store_longitude: -79.1618017,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.278766,
        store_longitude: -79.47215,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.135718,
        store_longitude: -79.4007,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2446403,
        store_longitude: -79.1690087,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2552687,
        store_longitude: -79.1854415,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2530212,
        store_longitude: -79.2029381,
    },
    {
        store_latitdue: -0.2402863,
        store_longitude: -79.1802716,
    },
    {
        store_latitdue: 0.032558,
        store_longitude: -79.4054589,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2544,
        store_longitude: -79.1815,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.225501,
        store_longitude: -79.1928757,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.245269,
        store_longitude: -79.1673,
    },
    {
        store_latitdue: -0.265793,
        store_longitude: -79.2003895,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.264091,
        store_longitude: -79.198267,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2374538,
        store_longitude: -79.1832693,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2393279,
        store_longitude: -79.1702746,
    },
    {
        store_latitdue: -0.2472961,
        store_longitude: -79.1978999,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.244808,
        store_longitude: -79.1858,
    },
    {
        store_latitdue: -0.2830284,
        store_longitude: -79.2120225,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2821194,
        store_longitude: -79.2121308,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.012415,
        store_longitude: -79.388646,
    },
    {
        store_latitdue: -0.249094,
        store_longitude: -79.196447,
    },
    {
        store_latitdue: -0.0596991,
        store_longitude: -79.4435323,
    },
    {
        store_latitdue: -0.2402198,
        store_longitude: -79.2015468,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2618077,
        store_longitude: -79.2004685,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2522857,
        store_longitude: -79.1740091,
    },
    {
        store_latitdue: -0.2386512,
        store_longitude: -79.1659368,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0575238,
        store_longitude: -79.4458928,
    },
    {
        store_latitdue: -0.2589049,
        store_longitude: -79.1855585,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2547297,
        store_longitude: -79.1938101,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0069996,
        store_longitude: -79.3898,
    },
    {
        store_latitdue: -0.269978,
        store_longitude: -79.448987,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2790008,
        store_longitude: -79.2126141,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258586,
        store_longitude: -79.479827,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0180533,
        store_longitude: -79.3976719,
    },
    {
        store_latitdue: 0.014751,
        store_longitude: -79.398789,
    },
    {
        store_latitdue: -0.2430923,
        store_longitude: -79.1759192,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.099593,
        store_longitude: -79.2921573,
    },
    {
        store_latitdue: -0.1471854,
        store_longitude: -79.3963309,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2410211,
        store_longitude: -79.199771,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2758986,
        store_longitude: -79.4720145,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2734032,
        store_longitude: -79.4431192,
    },
    {
        store_latitdue: -0.2513211,
        store_longitude: -79.1684774,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.276992,
        store_longitude: -79.461085,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.228591,
        store_longitude: -79.197151,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1341798,
        store_longitude: -79.4039457,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2390303,
        store_longitude: -79.1700076,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.239762,
        store_longitude: -79.1649,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.236927,
        store_longitude: -79.196889,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0114003,
        store_longitude: -79.3901088,
    },
    {
        store_latitdue: -0.278982,
        store_longitude: -79.470017,
    },
    {
        store_latitdue: -0.2332394,
        store_longitude: -79.1816326,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2492166,
        store_longitude: -79.1804466,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2387897,
        store_longitude: -79.1953149,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2690123,
        store_longitude: -79.4663563,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2882183,
        store_longitude: -79.2155318,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.289815,
        store_longitude: -79.214915,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0064176,
        store_longitude: -79.3984204,
    },
    {
        store_latitdue: -0.1875726,
        store_longitude: -79.3198985,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: 0.1346939,
        store_longitude: -79.4037874,
    },
    {
        store_latitdue: -0.2388142,
        store_longitude: -79.1647061,
    },
    {
        store_latitdue: 0.0407458,
        store_longitude: -79.3992348,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.1320167,
        store_longitude: -79.4073041,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.1352816,
        store_longitude: -79.4020472,
    },
    {
        store_latitdue: -0.2389082,
        store_longitude: -79.1800507,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2710369,
        store_longitude: -79.4697212,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.007783,
        store_longitude: -79.391071,
    },
    {
        store_latitdue: 0.132089,
        store_longitude: -79.4097911,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.1319151,
        store_longitude: -79.4047201,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2616251,
        store_longitude: -79.4086673,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.252875,
        store_longitude: -79.48435,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2577274,
        store_longitude: -79.4788394,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2778007,
        store_longitude: -79.4589658,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.26071,
        store_longitude: -79.47907,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.1351976,
        store_longitude: -79.4017093,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.270157,
        store_longitude: -79.446647,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0353781,
        store_longitude: -79.4067,
    },
    {
        store_latitdue: -0.2440004,
        store_longitude: -79.159285,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2281121,
        store_longitude: -79.1770207,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.254844,
        store_longitude: -79.181439,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.264164,
        store_longitude: -79.1904,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2780696,
        store_longitude: -79.4499233,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.0849247,
        store_longitude: -79.2800287,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: 0.0410351,
        store_longitude: -79.4063215,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0384577,
        store_longitude: -79.4040644,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2592321,
        store_longitude: -79.185433,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2354685,
        store_longitude: -79.180151,
    },
    {
        store_latitdue: -0.2512715,
        store_longitude: -79.1677002,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2590007,
        store_longitude: -79.1880702,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.040473333,
        store_longitude: -79.4009483,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2729186,
        store_longitude: -79.4572104,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2529591,
        store_longitude: -79.1970439,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0869369,
        store_longitude: -79.2781554,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.23654491,
        store_longitude: -79.16670745,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1814131,
        store_longitude: -79.5452381,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: 0.038202,
        store_longitude: -79.4067099,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2397161,
        store_longitude: -79.171728,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2523146,
        store_longitude: -79.2124113,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3218209,
        store_longitude: -79.2124612,
    },
    {
        store_latitdue: 0.1373315,
        store_longitude: -79.4032837,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2334063,
        store_longitude: -79.171357,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.244108,
        store_longitude: -79.344944,
    },
    {
        store_latitdue: -0.244064,
        store_longitude: -79.180846,
    },
    {
        store_latitdue: -0.2492124,
        store_longitude: -79.1845483,
    },
    {
        store_latitdue: -0.275152,
        store_longitude: -79.4685,
    },
    {
        store_latitdue: -0.2254736,
        store_longitude: -79.192271,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2415728,
        store_longitude: -79.1821983,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2512075,
        store_longitude: -79.181953,
    },
    {
        store_latitdue: -0.2425545,
        store_longitude: -79.157585,
    },
    {
        store_latitdue: -0.2734966,
        store_longitude: -79.2079267,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.23917,
        store_longitude: -79.175576,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2393283,
        store_longitude: -79.1701658,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0191106,
        store_longitude: -79.389,
    },
    {
        store_latitdue: -0.250058,
        store_longitude: -79.1838,
    },
    {
        store_latitdue: -0.239044,
        store_longitude: -79.186424,
    },
    {
        store_latitdue: 0.1340869963,
        store_longitude: -79.4048767,
    },
    {
        store_latitdue: 0.3288973,
        store_longitude: -79.2024242,
    },
    {
        store_latitdue: -0.280981,
        store_longitude: -79.465546,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0406014,
        store_longitude: -79.4008548,
    },
    {
        store_latitdue: 0.014465,
        store_longitude: -79.390623,
    },
    {
        store_latitdue: -0.2570111,
        store_longitude: -79.1855702,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.219521,
        store_longitude: -79.1958,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2708373,
        store_longitude: -79.1978699,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3203373,
        store_longitude: -79.2119138,
    },
    {
        store_latitdue: -0.0341096,
        store_longitude: -79.4957947,
    },
    {
        store_latitdue: -0.2282588,
        store_longitude: -79.1689999,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2499285,
        store_longitude: -79.1803268,
    },
    {
        store_latitdue: -0.282112,
        store_longitude: -79.4845399,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2545054,
        store_longitude: -79.208759,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25352,
        store_longitude: -79.4833,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0032912,
        store_longitude: -79.3949698,
    },
    {
        store_latitdue: -0.244879,
        store_longitude: -79.34692,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2566627,
        store_longitude: -79.2043618,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2482796,
        store_longitude: -79.1850717,
    },
    {
        store_latitdue: -0.1475865,
        store_longitude: -79.3942102,
    },
    {
        store_latitdue: -0.2474085,
        store_longitude: -79.1671794,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0411995,
        store_longitude: -79.4072064,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2414014,
        store_longitude: -79.1858077,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2719905,
        store_longitude: -79.2111436,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2750291,
        store_longitude: -79.4682007,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2405359,
        store_longitude: -79.2014401,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.2989059,
        store_longitude: -79.2775519,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2686626,
        store_longitude: -79.4676637,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.286423,
        store_longitude: -79.217043,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2489207,
        store_longitude: -79.1887084,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.009964,
        store_longitude: -79.3891034,
    },
    {
        store_latitdue: -0.2374331,
        store_longitude: -79.1990923,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.254019,
        store_longitude: -79.490317,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.0010398,
        store_longitude: -79.3936045,
    },
    {
        store_latitdue: -0.2309292,
        store_longitude: -79.1790069,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.249376,
        store_longitude: -79.4904,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.274185,
        store_longitude: -79.449865,
    },
    {
        store_latitdue: 0.0408915,
        store_longitude: -79.3949164,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.241025,
        store_longitude: -79.183247,
    },
    {
        store_latitdue: 0.1356644,
        store_longitude: -79.3989397,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2509449,
        store_longitude: -79.1747652,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.386288,
        store_longitude: -79.3717715,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: 0.001983,
        store_longitude: -79.4014,
    },
    {
        store_latitdue: -0.0320762,
        store_longitude: -79.4963,
    },
    {
        store_latitdue: -0.248688,
        store_longitude: -79.1716,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.275821804,
        store_longitude: -79.21042567,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2373653,
        store_longitude: -79.1860653,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1420655,
        store_longitude: -79.4020588,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.273756,
        store_longitude: -79.46751,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.225645,
        store_longitude: -79.166077,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.00281,
        store_longitude: -79.4,
    },
    {
        store_latitdue: -0.2600292,
        store_longitude: -79.1960449,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.148455,
        store_longitude: -79.391466,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: 0.13882,
        store_longitude: -79.4017,
    },
    {
        store_latitdue: 0.134947,
        store_longitude: -79.4058,
    },
    {
        store_latitdue: -0.2481367,
        store_longitude: -79.1963521,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0043254,
        store_longitude: -79.3903,
    },
    {
        store_latitdue: -0.2288577,
        store_longitude: -79.1982331,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258288,
        store_longitude: -79.2096487,
    },
    {
        store_latitdue: -0.237967,
        store_longitude: -79.1804435,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0627125,
        store_longitude: -79.4425239,
    },
    {
        store_latitdue: -0.2590593,
        store_longitude: -79.1829857,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2503366,
        store_longitude: -79.1882445,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.246577,
        store_longitude: -79.165934,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2684719,
        store_longitude: -79.197888,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2337066,
        store_longitude: -79.1791548,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259559,
        store_longitude: -79.187651,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3230044,
        store_longitude: -79.2104637,
    },
    {
        store_latitdue: -0.248843,
        store_longitude: -79.172467,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.13708,
        store_longitude: -79.4017,
    },
    {
        store_latitdue: -0.249253133,
        store_longitude: -79.19637256,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3227492,
        store_longitude: -79.453919,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2436,
        store_longitude: -79.3371,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2674403,
        store_longitude: -79.1968326,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.274472,
        store_longitude: -79.450584,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.1464309,
        store_longitude: -79.3913915,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: 0.3239986,
        store_longitude: -79.2056706,
    },
    {
        store_latitdue: -0.2323541,
        store_longitude: -79.1791035,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2692345,
        store_longitude: -79.4485002,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.270801,
        store_longitude: -79.445607,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0047722,
        store_longitude: -79.3966,
    },
    {
        store_latitdue: -0.2750623,
        store_longitude: -79.2134141,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0144993,
        store_longitude: -79.3849757,
    },
    {
        store_latitdue: -0.2467498,
        store_longitude: -79.176655,
    },
    {
        store_latitdue: -0.3195438,
        store_longitude: -79.2855217,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -0.2435803,
        store_longitude: -79.183286,
    },
    {
        store_latitdue: -0.10952,
        store_longitude: -79.6165483,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: 0.0130577,
        store_longitude: -79.3911322,
    },
    {
        store_latitdue: -0.247816,
        store_longitude: -79.1724,
    },
    {
        store_latitdue: -0.2794513,
        store_longitude: -79.4582904,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.015179,
        store_longitude: -79.3892914,
    },
    {
        store_latitdue: -0.27597148,
        store_longitude: -79.21062117,
    },
    {
        store_latitdue: -0.2295925,
        store_longitude: -79.1833423,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2451523,
        store_longitude: -79.1979191,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.388337,
        store_longitude: -79.3733857,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -0.2696908,
        store_longitude: -79.1998429,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2695558,
        store_longitude: -79.4655706,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.225065,
        store_longitude: -79.197729,
    },
    {
        store_latitdue: -0.2620298,
        store_longitude: -79.200898,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2430814,
        store_longitude: -79.2075209,
    },
    {
        store_latitdue: -0.2742178,
        store_longitude: -79.2072991,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0081158,
        store_longitude: -79.3931138,
    },
    {
        store_latitdue: 0.019382,
        store_longitude: -79.4012,
    },
    {
        store_latitdue: -0.2233463,
        store_longitude: -79.1662814,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2517419,
        store_longitude: -79.1961165,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.00404,
        store_longitude: -79.3961,
    },
    {
        store_latitdue: -0.225,
        store_longitude: -79.198667,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.131941,
        store_longitude: -79.362716,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.257565,
        store_longitude: -79.197643,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2257857,
        store_longitude: -79.1898406,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2402585,
        store_longitude: -79.1837004,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259921,
        store_longitude: -79.2018,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.329702,
        store_longitude: -79.308313,
    },
    {
        store_latitdue: -0.2765592,
        store_longitude: -79.2047048,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0063731,
        store_longitude: -79.4010272,
    },
    {
        store_latitdue: -0.239916,
        store_longitude: -79.195202,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.236318,
        store_longitude: -79.1765,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2765796,
        store_longitude: -79.2034424,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.229521,
        store_longitude: -79.1821558,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2607586,
        store_longitude: -79.405915,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1089913,
        store_longitude: -79.6166168,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.2465096,
        store_longitude: -79.1821341,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.242455,
        store_longitude: -79.2001,
    },
    {
        store_latitdue: -0.2399023,
        store_longitude: -79.1999395,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.247853,
        store_longitude: -79.183744,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2642976,
        store_longitude: -79.1960762,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2495341,
        store_longitude: -79.1770535,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2499595,
        store_longitude: -79.1803372,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.23613,
        store_longitude: -79.1887,
    },
    {
        store_latitdue: -0.2510733,
        store_longitude: -79.1676536,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.250065,
        store_longitude: -79.365805,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2593078,
        store_longitude: -79.4013322,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3237025,
        store_longitude: -79.2072293,
    },
    {
        store_latitdue: -0.2592779,
        store_longitude: -79.1839549,
    },
    {
        store_latitdue: -0.3866963,
        store_longitude: -79.3720833,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: 0.0182555,
        store_longitude: -79.3792262,
    },
    {
        store_latitdue: -0.232227875,
        store_longitude: -79.21928658,
    },
    {
        store_latitdue: -0.240069,
        store_longitude: -79.1864173,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2673608,
        store_longitude: -79.2009234,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2742,
        store_longitude: -79.460265,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.136901,
        store_longitude: -79.404776,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.266938,
        store_longitude: -79.1985309,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0092492,
        store_longitude: -79.3939,
    },
    {
        store_latitdue: -0.249836,
        store_longitude: -79.1766,
    },
    {
        store_latitdue: -0.2381786,
        store_longitude: -79.2022393,
    },
    {
        store_latitdue: -0.272219,
        store_longitude: -79.445635,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.258961,
        store_longitude: -79.183607,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2723671,
        store_longitude: -79.2085616,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2532675,
        store_longitude: -79.1819954,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2393748,
        store_longitude: -79.1703118,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.266441,
        store_longitude: -79.199714,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2820893,
        store_longitude: -79.4616886,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2679801,
        store_longitude: -79.4355183,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2865273,
        store_longitude: -79.4712509,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0041825,
        store_longitude: -79.4004,
    },
    {
        store_latitdue: -0.0604252,
        store_longitude: -79.4432572,
    },
    {
        store_latitdue: -0.23783,
        store_longitude: -79.173819,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.239082,
        store_longitude: -79.1757856,
    },
    {
        store_latitdue: -0.2420689,
        store_longitude: -79.1726361,
    },
    {
        store_latitdue: -0.2312575,
        store_longitude: -79.1855087,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2317924,
        store_longitude: -79.1881198,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2293749,
        store_longitude: -79.1756383,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2359835,
        store_longitude: -79.1885145,
    },
    {
        store_latitdue: -0.252029,
        store_longitude: -79.1688149,
    },
    {
        store_latitdue: -2.2201,
        store_longitude: -79.9019,
    },
    {
        store_latitdue: -0.2393362,
        store_longitude: -79.1663067,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1449601,
        store_longitude: -79.566958,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: 0.0051106,
        store_longitude: -79.3961216,
    },
    {
        store_latitdue: 0.0045878,
        store_longitude: -79.3972,
    },
    {
        store_latitdue: 0.0097182,
        store_longitude: -79.3926745,
    },
    {
        store_latitdue: -0.2692011,
        store_longitude: -79.1954298,
    },
    {
        store_latitdue: -0.2910575,
        store_longitude: -79.2144423,
    },
    {
        store_latitdue: -0.2503928,
        store_longitude: -79.1714358,
    },
    {
        store_latitdue: -0.2912276,
        store_longitude: -79.2144647,
    },
    {
        store_latitdue: -0.280598,
        store_longitude: -79.212218,
    },
    {
        store_latitdue: -0.258019,
        store_longitude: -79.1954,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2522813,
        store_longitude: -79.4840096,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.272251,
        store_longitude: -79.4538861,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.25174,
        store_longitude: -79.4872,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2612802,
        store_longitude: -79.4140435,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2628,
        store_longitude: -79.386,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1487939,
        store_longitude: -79.3934444,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.266588,
        store_longitude: -79.159,
    },
    {
        store_latitdue: -0.2619274,
        store_longitude: -79.4768626,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.3226679,
        store_longitude: -79.2112847,
    },
    {
        store_latitdue: 0.327959,
        store_longitude: -79.2024359,
    },
    {
        store_latitdue: -0.2366368,
        store_longitude: -79.175989,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2223567,
        store_longitude: -79.1970136,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0194203,
        store_longitude: -79.3775757,
    },
    {
        store_latitdue: -0.0587976,
        store_longitude: -79.4444927,
    },
    {
        store_latitdue: 0.0411946,
        store_longitude: -79.4004,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0041782,
        store_longitude: -79.3985,
    },
    {
        store_latitdue: -0.0030691,
        store_longitude: -79.3903419,
    },
    {
        store_latitdue: -0.2585702,
        store_longitude: -79.189281,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2949185,
        store_longitude: -79.2157439,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.329776,
        store_longitude: -79.3084,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -0.2755795,
        store_longitude: -79.2056967,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2527,
        store_longitude: -79.203882,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.273311,
        store_longitude: -79.457373,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.336226,
        store_longitude: -79.75736,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.336183,
        store_longitude: -79.7573767,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.2731,
        store_longitude: -79.4361,
    },
    {
        store_latitdue: -0.2766455,
        store_longitude: -79.4727678,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2611315,
        store_longitude: -79.4126283,
        neighborhood: 'EL CARMEN',
    },

    {
        store_latitdue: -0.2581979,
        store_longitude: -79.2112587,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.271815,
        store_longitude: -79.465601,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2755038,
        store_longitude: -79.4656085,
    },
    {
        store_latitdue: -0.2394765,
        store_longitude: -79.1709307,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2471317,
        store_longitude: -79.1649607,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.063048,
        store_longitude: -79.4426406,
    },
    {
        store_latitdue: -0.2259931,
        store_longitude: -79.1875579,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2222817,
        store_longitude: -79.1979416,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2301627,
        store_longitude: -79.1745282,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0411118,
        store_longitude: -79.4945,
    },
    {
        store_latitdue: 0.0065536,
        store_longitude: -79.3981597,
    },
    {
        store_latitdue: -0.2640917,
        store_longitude: -79.1993706,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2534716,
        store_longitude: -79.2176791,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2567849,
        store_longitude: -79.2177649,
    },
    {
        store_latitdue: -0.2743082,
        store_longitude: -79.4667358,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2582945,
        store_longitude: -79.2094706,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27087,
        store_longitude: -79.4714,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2751184,
        store_longitude: -79.4643778,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.233826,
        store_longitude: -79.1712,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: 0.2975824,
        store_longitude: -79.2735202,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2275551,
        store_longitude: -79.1799946,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2435021,
        store_longitude: -79.1664536,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.238074,
        store_longitude: -79.1885,
    },
    {
        store_latitdue: -0.2297963,
        store_longitude: -79.1872055,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.278141,
        store_longitude: -79.2096,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.278454,
        store_longitude: -79.2092,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2431661,
        store_longitude: -79.1969712,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2611582,
        store_longitude: -79.405223,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2284193,
        store_longitude: -79.1711186,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1459972,
        store_longitude: -79.5625185,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.26875,
        store_longitude: -79.161588,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2463978,
        store_longitude: -79.1605504,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2252351,
        store_longitude: -79.1654437,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2318866,
        store_longitude: -79.1880845,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.242413444,
        store_longitude: -79.15803267,
    },
    {
        store_latitdue: -0.245996,
        store_longitude: -79.1682,
    },
    {
        store_latitdue: -0.2308766,
        store_longitude: -79.1766781,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2346636,
        store_longitude: -79.1841132,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0364618,
        store_longitude: -79.4942058,
    },
    {
        store_latitdue: -0.0364833,
        store_longitude: -79.4953335,
    },
    {
        store_latitdue: 0.0075176,
        store_longitude: -79.3918,
    },
    {
        store_latitdue: 0.0126926,
        store_longitude: -79.3978791,
    },
    {
        store_latitdue: -0.24991,
        store_longitude: -79.1689,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3534955,
        store_longitude: -79.5276099,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.261927,
        store_longitude: -79.4789204,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2650467,
        store_longitude: -79.4815707,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.476059,
        store_longitude: -79.5584,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.47427,
        store_longitude: -79.556865,
    },
    {
        store_latitdue: -0.4763587,
        store_longitude: -79.5537963,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.248111,
        store_longitude: -79.196398,
    },
    {
        store_latitdue: -0.2415299,
        store_longitude: -79.1717171,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2606287,
        store_longitude: -79.1694202,
    },
    {
        store_latitdue: -0.2320368,
        store_longitude: -79.4855378,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0056252,
        store_longitude: -79.3914,
    },
    {
        store_latitdue: 0.0123638,
        store_longitude: -79.3917,
    },
    {
        store_latitdue: -0.2598844,
        store_longitude: -79.198849,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2515112,
        store_longitude: -79.1719967,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2594479,
        store_longitude: -79.1658528,
    },
    {
        store_latitdue: -0.261584,
        store_longitude: -79.201141,
    },
    {
        store_latitdue: -0.259054,
        store_longitude: -79.184787,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2533818,
        store_longitude: -79.1716615,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2572945,
        store_longitude: -79.203033,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.255792,
        store_longitude: -79.2033,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.275145,
        store_longitude: -79.21244,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2568086,
        store_longitude: -79.2077362,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.261585,
        store_longitude: -79.4157,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.246796,
        store_longitude: -79.2021031,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2462152,
        store_longitude: -79.2003468,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2756396,
        store_longitude: -79.4367842,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2451604,
        store_longitude: -79.2002599,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2465059,
        store_longitude: -79.2077758,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.473705,
        store_longitude: -79.557424,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.274787,
        store_longitude: -79.467721,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.472277,
        store_longitude: -79.556715,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0046428,
        store_longitude: -79.3936,
    },
    {
        store_latitdue: 0.132505,
        store_longitude: -79.4053481,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2448051,
        store_longitude: -79.1825514,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2430999,
        store_longitude: -79.1855346,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.225362,
        store_longitude: -79.1826,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2391529,
        store_longitude: -79.1771791,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0307256,
        store_longitude: -79.4969,
    },
    {
        store_latitdue: -0.052307,
        store_longitude: -79.443519,
    },
    {
        store_latitdue: -0.2667682,
        store_longitude: -79.202674,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2594975,
        store_longitude: -79.2080426,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2482321,
        store_longitude: -79.196285,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2732216,
        store_longitude: -79.4548537,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2628309,
        store_longitude: -79.4816127,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.3141637,
        store_longitude: -79.4960461,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2791018,
        store_longitude: -79.461545,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.244894,
        store_longitude: -79.21442,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2242529,
        store_longitude: -79.1634148,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2390485,
        store_longitude: -79.1674554,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.249819,
        store_longitude: -79.1767,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.245888,
        store_longitude: -79.163983,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2426492,
        store_longitude: -79.1840554,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0078607,
        store_longitude: -79.3858767,
    },
    {
        store_latitdue: -0.2549392,
        store_longitude: -79.2028413,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2517026,
        store_longitude: -79.2145978,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27707,
        store_longitude: -79.4633,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.3048884,
        store_longitude: -79.2735639,
    },
    {
        store_latitdue: -0.233871,
        store_longitude: -79.1696,
    },
    {
        store_latitdue: -0.2426489,
        store_longitude: -79.1839806,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2743462,
        store_longitude: -79.4362013,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2530485,
        store_longitude: -79.204532,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27393,
        store_longitude: -79.46185,
    },
    {
        store_latitdue: 0.0406772,
        store_longitude: -79.4071,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.131041,
        store_longitude: -79.403931,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.3558645,
        store_longitude: -79.2107978,
        neighborhood: 'MALIMPIA',
    },
    {
        store_latitdue: -0.2496311,
        store_longitude: -79.1881611,
    },
    {
        store_latitdue: -0.238919,
        store_longitude: -79.170623,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0039715,
        store_longitude: -79.3948,
    },
    {
        store_latitdue: -0.2706243,
        store_longitude: -79.2087377,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2687705,
        store_longitude: -79.4464075,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2309746,
        store_longitude: -79.1764917,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.249544,
        store_longitude: -79.1773,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.272821,
        store_longitude: -79.4641,
    },
    {
        store_latitdue: -0.251327,
        store_longitude: -79.213642,
    },
    {
        store_latitdue: -0.27645,
        store_longitude: -79.46239,
    },
    {
        store_latitdue: -0.2776757,
        store_longitude: -79.4634702,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.274713,
        store_longitude: -79.460532,
    },
    {
        store_latitdue: -0.2492546,
        store_longitude: -79.1877191,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1323,
        store_longitude: -79.3642,
    },
    {
        store_latitdue: -0.2592897,
        store_longitude: -79.1853728,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.249977,
        store_longitude: -79.2175,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.24725,
        store_longitude: -79.348326,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.140675,
        store_longitude: -79.403358,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2306571,
        store_longitude: -79.1737678,
    },
    {
        store_latitdue: 0.1309136,
        store_longitude: -79.4038697,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2751258,
        store_longitude: -79.2094895,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2532908,
        store_longitude: -79.1705992,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1337636,
        store_longitude: -79.3807232,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.27515,
        store_longitude: -79.46348,
    },
    {
        store_latitdue: -0.1502394,
        store_longitude: -79.3878491,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.0866605,
        store_longitude: -79.280519,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.230606,
        store_longitude: -79.18651,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.055387,
        store_longitude: -79.443414,
    },
    {
        store_latitdue: -0.2645293,
        store_longitude: -79.4188262,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0037395,
        store_longitude: -79.3955,
    },
    {
        store_latitdue: -0.236197,
        store_longitude: -79.1867,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0187832,
        store_longitude: -79.3954,
    },
    {
        store_latitdue: -0.291247,
        store_longitude: -79.217358,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.242496,
        store_longitude: -79.2097,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2498464,
        store_longitude: -79.1806567,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0092494,
        store_longitude: -79.3991823,
    },
    {
        store_latitdue: -0.258452,
        store_longitude: -79.1811295,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2440122,
        store_longitude: -79.3415582,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.135257,
        store_longitude: -79.4031,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2562619,
        store_longitude: -79.2116104,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2516321,
        store_longitude: -79.1756431,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0138813,
        store_longitude: -79.3907629,
    },
    {
        store_latitdue: -0.2721927,
        store_longitude: -79.4498907,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.1471338,
        store_longitude: -79.3961947,
    },
    {
        store_latitdue: -0.2547136,
        store_longitude: -79.2171955,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2871711,
        store_longitude: -79.212951,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2598405,
        store_longitude: -79.2105498,
    },
    {
        store_latitdue: 0.259272,
        store_longitude: -79.2592,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.036146,
        store_longitude: -79.4953,
    },
    {
        store_latitdue: -0.2513039,
        store_longitude: -79.1657549,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2942033,
        store_longitude: -79.2019567,
    },
    {
        store_latitdue: -0.2579176,
        store_longitude: -79.1858728,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.4202437,
        store_longitude: -79.1974792,
        neighborhood: 'MALIMPIA',
    },
    {
        store_latitdue: 0.4167963,
        store_longitude: -79.1975855,
    },
    {
        store_latitdue: -0.2632101,
        store_longitude: -79.4168972,
    },
    {
        store_latitdue: -0.2068369,
        store_longitude: -79.1813639,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.002103,
        store_longitude: -79.3822,
    },
    {
        store_latitdue: -0.2442321,
        store_longitude: -79.1848747,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0349062,
        store_longitude: -79.4942,
    },
    {
        store_latitdue: -0.270204,
        store_longitude: -79.460813,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.1877832,
        store_longitude: -79.3182374,
    },
    {
        store_latitdue: -0.272699,
        store_longitude: -79.458566,
    },
    {
        store_latitdue: 0.1331604,
        store_longitude: -79.4032967,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.150224,
        store_longitude: -79.3909,
    },
    {
        store_latitdue: -0.2394627,
        store_longitude: -79.2424526,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27547,
        store_longitude: -79.455,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.270099,
        store_longitude: -79.4616,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2494065,
        store_longitude: -79.1879914,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1321467,
        store_longitude: -79.4102053,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.1349973,
        store_longitude: -79.3965127,
    },
    {
        store_latitdue: 0.0083819,
        store_longitude: -79.3882,
    },
    {
        store_latitdue: -0.47396987,
        store_longitude: -79.557373,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.275653,
        store_longitude: -79.4623,
    },
    {
        store_latitdue: -0.2305147,
        store_longitude: -79.1868002,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.228922,
        store_longitude: -79.1878,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.50616327,
        store_longitude: -80.0226156,
    },
    {
        store_latitdue: -0.256768,
        store_longitude: -79.2125,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.234132,
        store_longitude: -79.1829,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2258071,
        store_longitude: -79.1887849,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3308343,
        store_longitude: -79.2104132,
    },
    {
        store_latitdue: -0.261902,
        store_longitude: -79.167308,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253743,
        store_longitude: -79.1741,
    },
    {
        store_latitdue: -0.0520904,
        store_longitude: -78.7762,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.2588933,
        store_longitude: -79.17882,
    },
    {
        store_latitdue: -0.3022613,
        store_longitude: -79.2208632,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3216774,
        store_longitude: -78.9916293,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.0534386,
        store_longitude: -78.7772065,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: 0.0821916,
        store_longitude: -79.0492,
    },
    {
        store_latitdue: -0.2592786,
        store_longitude: -79.1599945,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2895836,
        store_longitude: -79.1940339,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2556489,
        store_longitude: -79.1739568,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4150777,
        store_longitude: -78.8002698,
    },
    {
        store_latitdue: 0.0222243,
        store_longitude: -78.8941742,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2699069,
        store_longitude: -79.1916437,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2701775,
        store_longitude: -79.1823765,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5710127,
        store_longitude: -79.369699,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.2605364,
        store_longitude: -79.1786176,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.051893,
        store_longitude: -79.253584,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.269052,
        store_longitude: -79.179499,
    },
    {
        store_latitdue: -0.3386911,
        store_longitude: -79.2376616,
    },
    {
        store_latitdue: -0.416969,
        store_longitude: -78.798406,
    },
    {
        store_latitdue: -0.2812936,
        store_longitude: -79.1837373,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2864876,
        store_longitude: -79.1818585,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3209358,
        store_longitude: -79.1668555,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2532379,
        store_longitude: -79.1503779,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.267829,
        store_longitude: -79.1784,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0171434,
        store_longitude: -78.7883215,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.2668009,
        store_longitude: -79.1826434,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.052219,
        store_longitude: -78.77618,
    },
    {
        store_latitdue: -0.245578,
        store_longitude: -79.172976,
    },
    {
        store_latitdue: -0.2744056,
        store_longitude: -79.1792404,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2710673,
        store_longitude: -79.192131,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.29895,
        store_longitude: -79.19437,
    },
    {
        store_latitdue: -0.321732,
        store_longitude: -78.9915,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2538226,
        store_longitude: -79.1738524,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.280566,
        store_longitude: -79.1824024,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.670735,
        store_longitude: -79.6103198,
    },
    {
        store_latitdue: -0.27045,
        store_longitude: -79.1774518,
    },
    {
        store_latitdue: 0.0832334,
        store_longitude: -79.0459272,
    },
    {
        store_latitdue: -0.2638791,
        store_longitude: -79.1575198,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.571367,
        store_longitude: -79.3689,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.2651481,
        store_longitude: -79.1903934,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2700667,
        store_longitude: -79.1813887,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.256157,
        store_longitude: -79.137721,
    },
    {
        store_latitdue: -0.264499,
        store_longitude: -79.180097,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5874118,
        store_longitude: -79.512114,
    },
    {
        store_latitdue: -0.257048,
        store_longitude: -79.1687,
    },
    {
        store_latitdue: -0.2596989,
        store_longitude: -79.146066,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.671121,
        store_longitude: -79.6101,
    },
    {
        store_latitdue: -0.3214322,
        store_longitude: -79.1658854,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2566036,
        store_longitude: -79.1529925,
    },
    {
        store_latitdue: -0.2719686,
        store_longitude: -79.1653311,
    },
    {
        store_latitdue: -0.2783869,
        store_longitude: -79.1803359,
    },
    {
        store_latitdue: 0.0519934,
        store_longitude: -79.2534566,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.5717488,
        store_longitude: -79.3698456,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.2554109,
        store_longitude: -79.1744274,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2535552,
        store_longitude: -79.1741674,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0513521,
        store_longitude: -79.2541397,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.5729892,
        store_longitude: -79.3704095,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: 0.0218061,
        store_longitude: -78.8951378,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2536883,
        store_longitude: -79.1745859,
    },
    {
        store_latitdue: -0.2545944,
        store_longitude: -79.1681637,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.088737,
        store_longitude: -79.054244,
    },
    {
        store_latitdue: -0.0780519,
        store_longitude: -78.9901062,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2537657,
        store_longitude: -79.1740376,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3223569,
        store_longitude: -78.9988017,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.255373,
        store_longitude: -79.1727918,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0879712,
        store_longitude: -79.2796,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.2546669,
        store_longitude: -79.1667371,
    },
    {
        store_latitdue: -0.5865355,
        store_longitude: -79.51209,
    },
    {
        store_latitdue: 0.1220770453,
        store_longitude: -79.25334067,
    },
    {
        store_latitdue: 0.1199768,
        store_longitude: -79.2549966,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.262823,
        store_longitude: -79.184368,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1229065,
        store_longitude: -79.2538963,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 1.09937,
        store_longitude: -79.1607,
    },
    {
        store_latitdue: -0.26908,
        store_longitude: -79.156744,
    },
    {
        store_latitdue: -0.2550501,
        store_longitude: -79.1670942,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1212486,
        store_longitude: -79.2541272,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.3224895,
        store_longitude: -78.9980589,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.5867216,
        store_longitude: -79.512163,
    },
    {
        store_latitdue: 0.0897282,
        store_longitude: -79.0498273,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2537373,
        store_longitude: -79.1746969,
    },
    {
        store_latitdue: -0.253687,
        store_longitude: -79.173395,
    },
    {
        store_latitdue: 0.1214517,
        store_longitude: -79.254218,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0520184,
        store_longitude: -79.2534647,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.252242,
        store_longitude: -79.137921,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2428066,
        store_longitude: -79.1354395,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2633305,
        store_longitude: -79.1633539,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259390518,
        store_longitude: -79.17417877,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2538933,
        store_longitude: -79.1729534,
    },
    {
        store_latitdue: -0.2887976,
        store_longitude: -79.1850384,
    },
    {
        store_latitdue: -0.2591461,
        store_longitude: -79.1808195,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2560491,
        store_longitude: -79.164397,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258201,
        store_longitude: -79.171125,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2563514,
        store_longitude: -79.1552347,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259811,
        store_longitude: -79.1738,
    },
    {
        store_latitdue: -0.2703676,
        store_longitude: -79.178223,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2587766,
        store_longitude: -79.1467408,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3209789,
        store_longitude: -79.1667434,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2556568,
        store_longitude: -79.172062,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2485732,
        store_longitude: -79.1576607,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2584841,
        store_longitude: -79.1233273,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2662567,
        store_longitude: -79.1745831,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2575692,
        store_longitude: -79.1517582,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.261025,
        store_longitude: -79.1578249,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2675985,
        store_longitude: -79.1562236,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3208898,
        store_longitude: -79.1673814,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1214713,
        store_longitude: -79.2538408,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2566032,
        store_longitude: -79.1241753,
    },
    {
        store_latitdue: -0.2722179,
        store_longitude: -79.1645174,
    },
    {
        store_latitdue: 0.0884449,
        store_longitude: -79.0593474,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.266024,
        store_longitude: -79.185785,
    },
    {
        store_latitdue: 0.1218191,
        store_longitude: -79.2534612,
    },
    {
        store_latitdue: -0.594151,
        store_longitude: -79.5136,
    },
    {
        store_latitdue: 0.083347,
        store_longitude: -79.040822,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.259846,
        store_longitude: -79.182123,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26499923,
        store_longitude: -79.15847632,
    },
    {
        store_latitdue: -0.261219,
        store_longitude: -79.16,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2593693,
        store_longitude: -79.1531481,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.279075,
        store_longitude: -79.1771,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2598643,
        store_longitude: -79.1688475,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0888501,
        store_longitude: -79.0541247,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2985,
        store_longitude: -79.217589,
    },
    {
        store_latitdue: -0.2533054,
        store_longitude: -79.151429,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2595395,
        store_longitude: -79.1549901,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2577564,
        store_longitude: -79.1612887,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2710469,
        store_longitude: -79.1752377,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4075915,
        store_longitude: -79.3064583,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.5783208,
        store_longitude: -79.3704545,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.2659325,
        store_longitude: -79.1581715,
    },
    {
        store_latitdue: -0.2596665,
        store_longitude: -79.1771988,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2579058,
        store_longitude: -79.1510698,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2645585,
        store_longitude: -79.1776323,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2704358,
        store_longitude: -79.1733526,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.419165,
        store_longitude: -78.7953241,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: -0.2563922,
        store_longitude: -79.1375804,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2608893,
        store_longitude: -79.1829473,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3225637,
        store_longitude: -78.996673,
    },
    {
        store_latitdue: -0.261165,
        store_longitude: -79.1787,
    },
    {
        store_latitdue: -0.267376,
        store_longitude: -79.1192959,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5733183,
        store_longitude: -79.3690302,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.3139595,
        store_longitude: -79.020556,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: 0.0098259,
        store_longitude: -79.3928681,
    },
    {
        store_latitdue: -0.2669558,
        store_longitude: -79.1852152,
    },
    {
        store_latitdue: -0.2649967,
        store_longitude: -79.1810165,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2648398,
        store_longitude: -79.1838269,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2911495,
        store_longitude: -79.1925765,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4187992,
        store_longitude: -78.79429,
    },
    {
        store_latitdue: -0.3468466,
        store_longitude: -79.16696,
    },
    {
        store_latitdue: -0.261329,
        store_longitude: -79.178684,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.023482,
        store_longitude: -78.8957508,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.5688245,
        store_longitude: -79.3709261,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.2642997,
        store_longitude: -79.1699474,
    },
    {
        store_latitdue: -0.2654524,
        store_longitude: -79.1578806,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0830892,
        store_longitude: -79.0472,
    },
    {
        store_latitdue: 0.0228042,
        store_longitude: -78.893971,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.016494,
        store_longitude: -78.837618,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2687037,
        store_longitude: -79.1712658,
    },
    {
        store_latitdue: -0.0523205,
        store_longitude: -78.7759193,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.26603,
        store_longitude: -79.172017,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.017185,
        store_longitude: -78.789418,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.2810758,
        store_longitude: -79.1837042,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2914129,
        store_longitude: -79.1925534,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0887454,
        store_longitude: -79.0552524,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.28899,
        store_longitude: -79.18243,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.274099,
        store_longitude: -79.192444,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2741427,
        store_longitude: -79.1936262,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.263821,
        store_longitude: -79.158568,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0883224,
        store_longitude: -79.0538991,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.0535629,
        store_longitude: -78.7742737,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.0523462,
        store_longitude: -78.7761862,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: 0.0523922,
        store_longitude: -79.2531137,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.260817,
        store_longitude: -79.1524,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3220384,
        store_longitude: -78.9963624,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2769345,
        store_longitude: -79.1723585,
    },
    {
        store_latitdue: -0.2866457,
        store_longitude: -79.1962673,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2625877,
        store_longitude: -79.1533223,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2826326,
        store_longitude: -79.1999822,
    },
    {
        store_latitdue: 0.0247255,
        store_longitude: -78.8966,
    },
    {
        store_latitdue: 0.073874,
        store_longitude: -78.991253,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.1836372,
        store_longitude: -79.0228233,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.070659,
        store_longitude: -78.692462,
    },
    {
        store_latitdue: -0.243007,
        store_longitude: -79.2001,
    },
    {
        store_latitdue: -0.291972,
        store_longitude: -79.2110723,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2727217,
        store_longitude: -79.1946468,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2529555,
        store_longitude: -79.152597,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2535959,
        store_longitude: -79.1614925,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.273064,
        store_longitude: -79.172265,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.370148,
        store_longitude: -79.264631,
    },
    {
        store_latitdue: -0.2618393,
        store_longitude: -79.1547181,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5780161,
        store_longitude: -79.3701674,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.179865,
        store_longitude: -79.095138,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.052658,
        store_longitude: -78.775454,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.4170189,
        store_longitude: -78.7984481,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: -0.2712285,
        store_longitude: -79.1738124,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.053043,
        store_longitude: -79.2533637,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2769753,
        store_longitude: -79.1861706,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2620161,
        store_longitude: -79.1531803,
    },
    {
        store_latitdue: -0.265078,
        store_longitude: -79.1176,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0888856,
        store_longitude: -79.0552529,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2545632,
        store_longitude: -79.121604,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0774647,
        store_longitude: -79.2341481,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2842897,
        store_longitude: -79.2003677,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2636967,
        store_longitude: -79.1684581,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.249789,
        store_longitude: -79.158448,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2592108,
        store_longitude: -79.1600894,
    },

    {
        store_latitdue: -0.2598802,
        store_longitude: -79.1652798,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2550523,
        store_longitude: -79.1728347,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.254108,
        store_longitude: -79.1757,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.092366,
        store_longitude: -79.0511,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0885649,
        store_longitude: -79.0544915,
    },
    {
        store_latitdue: 0.1213296,
        store_longitude: -79.254117,
    },
    {
        store_latitdue: -0.257451,
        store_longitude: -79.171693,
    },
    {
        store_latitdue: -0.2622875,
        store_longitude: -79.1547369,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2551673,
        store_longitude: -79.1698772,
    },
    {
        store_latitdue: -0.2656353,
        store_longitude: -79.1770234,
    },
    {
        store_latitdue: 0.12178,
        store_longitude: -79.2536,
    },
    {
        store_latitdue: 0.1203232,
        store_longitude: -79.254748,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2662992,
        store_longitude: -79.1491639,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5776137,
        store_longitude: -79.3718768,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: 0.0241886,
        store_longitude: -78.8961537,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.3048995,
        store_longitude: -79.1540855,
    },
    {
        store_latitdue: -0.25989,
        store_longitude: -79.170444,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2959973,
        store_longitude: -79.2158845,
    },
    {
        store_latitdue: -0.2790034,
        store_longitude: -79.2026755,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259262,
        store_longitude: -79.174381,
    },
    {
        store_latitdue: -0.260386,
        store_longitude: -79.1794,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.254365,
        store_longitude: -79.173,
    },
    {
        store_latitdue: -0.260622,
        store_longitude: -79.169551,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.349375,
        store_longitude: -78.8581205,
    },
    {
        store_latitdue: -0.16893,
        store_longitude: -79.0463,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1218877,
        store_longitude: -79.2525512,
    },
    {
        store_latitdue: -0.417154,
        store_longitude: -78.7980867,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: 0.121142,
        store_longitude: -79.253091,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.083591,
        store_longitude: -79.045855,
    },
    {
        store_latitdue: -0.417788,
        store_longitude: -78.7967168,
    },
    {
        store_latitdue: -0.0525222,
        store_longitude: -78.7746342,
    },
    {
        store_latitdue: 0.0863026,
        store_longitude: -79.0500113,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.0539806,
        store_longitude: -78.7738316,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.4152377,
        store_longitude: -78.8000866,
    },
    {
        store_latitdue: -0.2724838,
        store_longitude: -79.1489955,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3049902,
        store_longitude: -79.1530829,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.261785,
        store_longitude: -79.1843,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0863967,
        store_longitude: -79.0518522,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.1224249,
        store_longitude: -79.2552549,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.3224598,
        store_longitude: -78.9970457,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2810126,
        store_longitude: -79.2019212,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.083694,
        store_longitude: -79.046006,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2571898,
        store_longitude: -79.1654954,
    },
    {
        store_latitdue: -0.5893304,
        store_longitude: -79.513893,
    },
    {
        store_latitdue: -0.4070296,
        store_longitude: -79.3054012,
    },
    {
        store_latitdue: 0.022848,
        store_longitude: -78.898741,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2647809,
        store_longitude: -79.1489286,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.273224,
        store_longitude: -79.166267,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27514807,
        store_longitude: -79.1823077,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.255613,
        store_longitude: -79.1249,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0254181,
        store_longitude: -78.8670863,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2911901,
        store_longitude: -79.1927723,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2809587,
        store_longitude: -79.2056292,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0229983,
        store_longitude: -78.8926738,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.3213011,
        store_longitude: -78.9906019,
    },
    {
        store_latitdue: -0.2743739,
        store_longitude: -79.176509,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.262671,
        store_longitude: -79.180613,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2951168,
        store_longitude: -79.1943471,
    },
    {
        store_latitdue: -0.273188,
        store_longitude: -79.177796,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.287207,
        store_longitude: -79.18037,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.07742,
        store_longitude: -79.235586,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2696404,
        store_longitude: -79.1886322,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258976,
        store_longitude: -79.178964,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5713747,
        store_longitude: -79.3692678,
    },
    {
        store_latitdue: -0.2702183,
        store_longitude: -79.1733173,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0753415,
        store_longitude: -78.9872141,
    },
    {
        store_latitdue: 0.0774456,
        store_longitude: -79.236,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.5862947,
        store_longitude: -79.5117622,
    },
    {
        store_latitdue: -0.2614335,
        store_longitude: -79.1845059,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.278587,
        store_longitude: -79.186423,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0758624,
        store_longitude: -78.9881778,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2594154,
        store_longitude: -79.1806508,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2601385,
        store_longitude: -79.1762982,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.272005,
        store_longitude: -79.180629,
    },
    {
        store_latitdue: -0.0514756,
        store_longitude: -78.7772894,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: 0.122333,
        store_longitude: -79.25526,
    },
    {
        store_latitdue: -0.26064,
        store_longitude: -79.172054,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.256337,
        store_longitude: -79.1530337,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2735687,
        store_longitude: -79.1831233,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2659725,
        store_longitude: -79.1854928,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0690885,
        store_longitude: -79.2423506,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2623408,
        store_longitude: -79.1164439,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2675451,
        store_longitude: -79.18844,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2571367,
        store_longitude: -79.1741532,
    },
    {
        store_latitdue: -0.2599955,
        store_longitude: -79.1689321,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258913,
        store_longitude: -79.1731926,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2751429,
        store_longitude: -79.1833541,
    },
    {
        store_latitdue: -0.2662993,
        store_longitude: -79.1742189,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5705404,
        store_longitude: -79.3706146,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.148163,
        store_longitude: -78.998846,
    },
    {
        store_latitdue: 0.022835,
        store_longitude: -78.899944,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.0869707,
        store_longitude: -79.0508136,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0887951,
        store_longitude: -79.0521129,
    },
    {
        store_latitdue: 0.083824,
        store_longitude: -79.0441,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2562888,
        store_longitude: -79.1265801,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2666222,
        store_longitude: -79.1418974,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2594193,
        store_longitude: -79.174179,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2657857,
        store_longitude: -79.1762705,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2785831,
        store_longitude: -79.1761158,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6196329,
        store_longitude: -79.4258324,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.2550459,
        store_longitude: -79.1804523,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0521031,
        store_longitude: -79.2533037,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2578979,
        store_longitude: -79.1671591,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.08379,
        store_longitude: -79.043733,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2899796,
        store_longitude: -79.184142,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0880426,
        store_longitude: -79.0534301,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.258039,
        store_longitude: -79.155033,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2688263,
        store_longitude: -79.1760952,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0844424,
        store_longitude: -79.0501529,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.4168888,
        store_longitude: -78.7985576,
    },
    {
        store_latitdue: -0.2742519,
        store_longitude: -79.1921842,
    },
    {
        store_latitdue: -0.2915731,
        store_longitude: -79.2118662,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2597776,
        store_longitude: -79.1660865,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2566432,
        store_longitude: -79.1591313,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.273514,
        store_longitude: -79.186799,
    },
    {
        store_latitdue: 0.0229523,
        store_longitude: -78.8904518,
    },
    {
        store_latitdue: -0.242331,
        store_longitude: -79.1362,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.016506,
        store_longitude: -78.899644,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2591759,
        store_longitude: -79.1595522,
    },
    {
        store_latitdue: -0.2610093,
        store_longitude: -79.174922,
    },
    {
        store_latitdue: -0.186338,
        store_longitude: -79.022388,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259082,
        store_longitude: -79.1747,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0844337,
        store_longitude: -79.0470852,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2792701,
        store_longitude: -79.1863456,
    },
    {
        store_latitdue: -0.052781,
        store_longitude: -78.775178,
    },
    {
        store_latitdue: -0.2571227,
        store_longitude: -79.1680614,
    },
    {
        store_latitdue: -0.2690408,
        store_longitude: -79.1727604,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2664097,
        store_longitude: -79.1436659,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0838551,
        store_longitude: -79.0462659,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.264513,
        store_longitude: -79.145885,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.181267,
        store_longitude: -79.023034,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.265443,
        store_longitude: -79.1564605,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.256202,
        store_longitude: -79.16168,
    },
    {
        store_latitdue: -0.264177,
        store_longitude: -79.165963,
    },
    {
        store_latitdue: -0.322952,
        store_longitude: -78.9996,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.581025,
        store_longitude: -79.5051,
    },
    {
        store_latitdue: 0.122523,
        store_longitude: -79.253531,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0528,
        store_longitude: -79.2526,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0816868,
        store_longitude: -79.0466854,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.1805171,
        store_longitude: -79.0224221,
    },
    {
        store_latitdue: -0.2666012,
        store_longitude: -79.1512481,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259194,
        store_longitude: -79.157412,
    },
    {
        store_latitdue: -0.2735979,
        store_longitude: -79.1873484,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2688744,
        store_longitude: -79.1855499,
    },
    {
        store_latitdue: -0.260545,
        store_longitude: -79.1509652,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3221026,
        store_longitude: -78.9927044,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.265624,
        store_longitude: -79.177369,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1222088,
        store_longitude: -79.2532765,
    },
    {
        store_latitdue: -0.4176004,
        store_longitude: -78.7973938,
    },
    {
        store_latitdue: -0.2718018,
        store_longitude: -79.1637465,
    },
    {
        store_latitdue: 0.025762,
        store_longitude: -78.897316,
    },
    {
        store_latitdue: -0.2667947,
        store_longitude: -79.1783084,
    },
    {
        store_latitdue: 0.082037,
        store_longitude: -79.0491,
    },
    {
        store_latitdue: -0.2584506,
        store_longitude: -79.1573361,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.267698,
        store_longitude: -79.17129,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253744,
        store_longitude: -79.1614,
    },
    {
        store_latitdue: -0.2569421,
        store_longitude: -79.1568298,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2610694,
        store_longitude: -79.164789,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0798887,
        store_longitude: -79.0490378,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0875177,
        store_longitude: -79.0543209,
    },
    {
        store_latitdue: -0.270468,
        store_longitude: -79.109966,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.280162,
        store_longitude: -79.2016373,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2768935,
        store_longitude: -79.1813922,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.288339,
        store_longitude: -79.184294,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2433318,
        store_longitude: -79.1755291,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2652585,
        store_longitude: -79.1691747,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26128301,
        store_longitude: -79.11779785,
    },
    {
        store_latitdue: 0.0230271,
        store_longitude: -78.8900334,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2614939,
        store_longitude: -79.1697539,
    },
    {
        store_latitdue: -0.2701354,
        store_longitude: -79.1821777,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0877424,
        store_longitude: -79.0545707,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2552435,
        store_longitude: -79.1612854,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2599442,
        store_longitude: -79.1654316,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.261361,
        store_longitude: -79.164182,
    },
    {
        store_latitdue: -0.2631391,
        store_longitude: -79.1564225,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: 0.0839172,
        store_longitude: -79.0492,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.268379,
        store_longitude: -79.1895462,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.267416,
        store_longitude: -79.178305,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5718154,
        store_longitude: -79.3693692,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.668594,
        store_longitude: -79.6106546,
    },
    {
        store_latitdue: -0.3353922,
        store_longitude: -78.5460333,
    },
    {
        store_latitdue: -0.2586128,
        store_longitude: -79.1703458,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2597004,
        store_longitude: -79.1553739,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6207735,
        store_longitude: -79.4245037,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.3218363,
        store_longitude: -79.0002108,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.1823001,
        store_longitude: -79.0233897,
    },
    {
        store_latitdue: 0.0822893,
        store_longitude: -79.0467,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.1224032,
        store_longitude: -79.2543181,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0820903,
        store_longitude: -79.0491787,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.1209457,
        store_longitude: -79.2517024,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0887887,
        store_longitude: -79.0555412,
    },
    {
        store_latitdue: -0.052444,
        store_longitude: -79.253023,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.2679917,
        store_longitude: -79.1890218,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.132398,
        store_longitude: -78.4733,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2565517,
        store_longitude: -79.1759613,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253782,
        store_longitude: -79.1736,
    },
    {
        store_latitdue: -0.2670569,
        store_longitude: -79.1873574,
    },
    {
        store_latitdue: -0.261661,
        store_longitude: -79.1794,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.261439,
        store_longitude: -79.1799873,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2610137,
        store_longitude: -79.1823958,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2582767,
        store_longitude: -79.1729654,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2655628,
        store_longitude: -79.1825875,
    },
    {
        store_latitdue: -0.259943,
        store_longitude: -79.1671,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2573458,
        store_longitude: -79.166009,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.272725,
        store_longitude: -79.1830907,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.265106,
        store_longitude: -79.1528,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.264451,
        store_longitude: -79.1755,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2705485,
        store_longitude: -79.1764584,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.266372,
        store_longitude: -79.1601,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2702321,
        store_longitude: -79.171385,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2703865,
        store_longitude: -79.1721693,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26764392,
        store_longitude: -79.1445029,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27803,
        store_longitude: -79.1913,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.275228,
        store_longitude: -79.1847,
    },
    {
        store_latitdue: -0.5702355,
        store_longitude: -79.3675035,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.5695125,
        store_longitude: -79.3695887,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.5861769,
        store_longitude: -79.5121021,
    },

    {
        store_latitdue: -0.284769,
        store_longitude: -79.1979,
    },
    {
        store_latitdue: -0.5869616,
        store_longitude: -79.5121388,
    },
    {
        store_latitdue: -0.2853121,
        store_longitude: -79.1991612,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2920674,
        store_longitude: -79.2113986,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.587692,
        store_longitude: -79.5121,
    },
    {
        store_latitdue: -0.590249,
        store_longitude: -79.5122,
    },
    {
        store_latitdue: -0.590429,
        store_longitude: -79.5131,
    },
    {
        store_latitdue: -0.571979,
        store_longitude: -79.3683446,
    },
    {
        store_latitdue: -0.2962389,
        store_longitude: -79.1945877,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.280828,
        store_longitude: -79.192765,
    },
    {
        store_latitdue: -0.575356,
        store_longitude: -79.3728777,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.404893,
        store_longitude: -79.301354,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.405719,
        store_longitude: -79.3028,
    },
    {
        store_latitdue: -0.5705006,
        store_longitude: -79.3702785,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.405254,
        store_longitude: -79.3026,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.4040598,
        store_longitude: -79.3032654,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.4067735,
        store_longitude: -79.3048493,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.4073856,
        store_longitude: -79.3058986,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.4174472,
        store_longitude: -79.274256,
    },
    {
        store_latitdue: -0.285001,
        store_longitude: -79.178765,
    },
    {
        store_latitdue: -0.2875767,
        store_longitude: -79.1810916,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.585085,
        store_longitude: -79.5073065,
    },
    {
        store_latitdue: -0.2883797,
        store_longitude: -79.181337,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.286234,
        store_longitude: -79.179828,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.284112,
        store_longitude: -79.1794,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.315608,
        store_longitude: -79.0150314,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2584545,
        store_longitude: -79.1459372,
    },
    {
        store_latitdue: -0.257661,
        store_longitude: -79.1611,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253818,
        store_longitude: -79.1222496,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.247232,
        store_longitude: -79.1511228,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2689904,
        store_longitude: -79.1210571,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.262405,
        store_longitude: -79.1586,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3186269,
        store_longitude: -78.9539515,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2561677,
        store_longitude: -79.1597537,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2595449,
        store_longitude: -79.1521889,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0872711,
        store_longitude: -79.0515,
    },
    {
        store_latitdue: 0.0239511,
        store_longitude: -78.8937843,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.1217353,
        store_longitude: -79.2533963,
    },
    {
        store_latitdue: -0.148913,
        store_longitude: -78.9995085,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.054223,
        store_longitude: -78.774739,
    },
    {
        store_latitdue: 0.0851258,
        store_longitude: -79.0496536,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0228615,
        store_longitude: -78.8894471,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.022428,
        store_longitude: -78.893,
    },
    {
        store_latitdue: -0.0762275,
        store_longitude: -78.9886991,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.1032033,
        store_longitude: -79.1413273,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.1009212,
        store_longitude: -79.1368085,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.075213,
        store_longitude: -78.995941,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.263828,
        store_longitude: -79.1872,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2612751,
        store_longitude: -79.1604484,
    },
    {
        store_latitdue: -0.247432,
        store_longitude: -79.154071,
    },
    {
        store_latitdue: -0.2888312,
        store_longitude: -79.1854409,
    },
    {
        store_latitdue: -0.271441,
        store_longitude: -79.1891,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27116,
        store_longitude: -79.1616,
    },
    {
        store_latitdue: -0.2765012,
        store_longitude: -79.1877825,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2552144,
        store_longitude: -79.1743356,
    },
    {
        store_latitdue: -0.2687257,
        store_longitude: -79.1727899,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2813229,
        store_longitude: -79.1880231,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2891929,
        store_longitude: -79.1932445,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.619241,
        store_longitude: -79.4255606,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.5695436,
        store_longitude: -79.3664583,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.569687,
        store_longitude: -79.3651847,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.583191,
        store_longitude: -79.4508017,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.584772,
        store_longitude: -79.5111,
    },
    {
        store_latitdue: -0.5763673,
        store_longitude: -79.3699224,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.5895935,
        store_longitude: -79.5130672,
    },
    {
        store_latitdue: -0.283184,
        store_longitude: -79.1766,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2753946,
        store_longitude: -79.1642296,
    },
    {
        store_latitdue: -0.2904669,
        store_longitude: -79.1935216,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2883011,
        store_longitude: -79.1841605,
    },
    {
        store_latitdue: -0.288828,
        store_longitude: -79.18426,
    },
    {
        store_latitdue: -0.254429,
        store_longitude: -79.167476,
    },
    {
        store_latitdue: -0.25417,
        store_longitude: -79.1373,
    },
    {
        store_latitdue: -0.2561336,
        store_longitude: -79.1553986,
    },
    {
        store_latitdue: -0.2597013,
        store_longitude: -79.1231751,
    },
    {
        store_latitdue: -0.256636,
        store_longitude: -79.1396,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253251,
        store_longitude: -79.136071,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2622895,
        store_longitude: -79.1519828,
    },
    {
        store_latitdue: -0.2584227,
        store_longitude: -79.1532012,
    },
    {
        store_latitdue: -0.2112056,
        store_longitude: -79.1130626,
    },
    {
        store_latitdue: -0.0525254,
        store_longitude: -78.7755235,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.0526947,
        store_longitude: -78.7756468,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.0541082,
        store_longitude: -78.7747978,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: 0.0237213,
        store_longitude: -78.8958999,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.0535427,
        store_longitude: -78.7744,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: 0.086645,
        store_longitude: -79.053046,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0860368,
        store_longitude: -79.0546969,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2544344,
        store_longitude: -79.1675088,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2678725,
        store_longitude: -79.1832952,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2545262,
        store_longitude: -79.1677839,
    },
    {
        store_latitdue: -0.6707732,
        store_longitude: -79.6103225,
    },
    {
        store_latitdue: -0.29291,
        store_longitude: -79.1851,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2708901,
        store_longitude: -79.1926255,
    },
    {
        store_latitdue: -0.267416,
        store_longitude: -79.192556,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.255015,
        store_longitude: -79.1727853,
    },
    {
        store_latitdue: -0.286619,
        store_longitude: -79.1964,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.270269,
        store_longitude: -79.1915,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2610489,
        store_longitude: -79.1749957,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.267248,
        store_longitude: -79.1874,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2545648,
        store_longitude: -79.1743331,
    },
    {
        store_latitdue: -0.2716552,
        store_longitude: -79.1851411,
    },
    {
        store_latitdue: -0.2647741,
        store_longitude: -79.1491873,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2732357,
        store_longitude: -79.1859636,
    },
    {
        store_latitdue: -0.26342148,
        store_longitude: -79.16834802,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4082857,
        store_longitude: -79.3071514,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.3684631,
        store_longitude: -79.2634396,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.361799,
        store_longitude: -79.25846,
    },
    {
        store_latitdue: -0.4079375,
        store_longitude: -79.3059172,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.3287563,
        store_longitude: -79.2340053,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3006274,
        store_longitude: -79.2162302,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3030338,
        store_longitude: -79.2169251,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6762052,
        store_longitude: -79.6120979,
    },
    {
        store_latitdue: -0.62462046,
        store_longitude: -79.5990586,
    },
    {
        store_latitdue: -0.296845,
        store_longitude: -79.216362,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6705492,
        store_longitude: -79.607824,
    },
    {
        store_latitdue: -0.294425,
        store_longitude: -79.2022,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.412975,
        store_longitude: -79.2765,
    },
    {
        store_latitdue: -0.285449,
        store_longitude: -79.202014,
    },
    {
        store_latitdue: -0.293858,
        store_longitude: -79.182,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.280744,
        store_longitude: -79.1801,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5854851,
        store_longitude: -79.5120577,
    },
    {
        store_latitdue: -0.290055,
        store_longitude: -79.1838,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.406788,
        store_longitude: -79.3051,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.2586596,
        store_longitude: -79.1451438,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.257703,
        store_longitude: -79.1392,
    },
    {
        store_latitdue: -0.3365271,
        store_longitude: -78.8989063,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: -0.2391369,
        store_longitude: -79.1774582,
    },
    {
        store_latitdue: -0.2616585,
        store_longitude: -79.16458,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.318447,
        store_longitude: -78.9886636,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.191716,
        store_longitude: -79.0869,
    },
    {
        store_latitdue: 0.087432,
        store_longitude: -79.0520962,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.0752084,
        store_longitude: -78.9870173,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2563162,
        store_longitude: -79.1772794,
    },
    {
        store_latitdue: -0.219804,
        store_longitude: -79.1825821,
    },
    {
        store_latitdue: -0.271645,
        store_longitude: -79.178596,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2395143,
        store_longitude: -79.1701961,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2759527,
        store_longitude: -79.189476,
    },
    {
        store_latitdue: -0.260391,
        store_longitude: -79.1753,
    },
    {
        store_latitdue: -0.5816139,
        store_longitude: -79.5048479,
    },
    {
        store_latitdue: -0.2941648,
        store_longitude: -79.1931716,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3463647,
        store_longitude: -79.1425114,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.074493,
        store_longitude: -80.04799,
    },
    {
        store_latitdue: -0.2913462,
        store_longitude: -79.1835516,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.314074,
        store_longitude: -79.0187,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: 0.0612315,
        store_longitude: -78.6822025,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.0623578,
        store_longitude: -78.681819,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: -0.11819,
        store_longitude: -79.257546,
    },
    {
        store_latitdue: 0.1192679,
        store_longitude: -79.2561347,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0620131,
        store_longitude: -78.6817075,
    },
    {
        store_latitdue: -0.265268,
        store_longitude: -79.1825108,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2697094,
        store_longitude: -79.1959623,
    },
    {
        store_latitdue: -0.2752528,
        store_longitude: -79.1854508,
    },
    {
        store_latitdue: -0.2882878,
        store_longitude: -79.1948875,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2985846,
        store_longitude: -78.5605856,
    },
    {
        store_latitdue: -0.250581,
        store_longitude: -79.1525,
    },
    {
        store_latitdue: -0.3217165,
        store_longitude: -78.9913927,
    },
    {
        store_latitdue: -0.2592544,
        store_longitude: -79.1634455,
    },
    {
        store_latitdue: 0.0426432,
        store_longitude: -79.3513099,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0845067,
        store_longitude: -79.0519239,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0873916,
        store_longitude: -79.0519,
    },
    {
        store_latitdue: 0.0624043,
        store_longitude: -78.6818557,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.0639268,
        store_longitude: -78.6840201,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.063814,
        store_longitude: -78.682156,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.122487,
        store_longitude: -79.255511,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.0538709,
        store_longitude: -78.7748,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: 0.0727696,
        store_longitude: -78.6955311,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.0636229,
        store_longitude: -78.6821359,
    },
    {
        store_latitdue: 0.0665757,
        store_longitude: -78.6924053,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: -0.253693,
        store_longitude: -79.1745,
    },
    {
        store_latitdue: -0.5883683,
        store_longitude: -79.512039,
    },
    {
        store_latitdue: -0.6047473,
        store_longitude: -79.558399,
    },
    {
        store_latitdue: -0.2560875,
        store_longitude: -79.1466781,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2613115,
        store_longitude: -79.1639457,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.255739,
        store_longitude: -79.161168,
    },
    {
        store_latitdue: 0.0230221,
        store_longitude: -78.8931587,
    },
    {
        store_latitdue: 0.1427656,
        store_longitude: -78.7676768,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: -0.617974,
        store_longitude: -79.4219,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: 0.12347,
        store_longitude: -79.2585,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.133739,
        store_longitude: -79.3389,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2702032,
        store_longitude: -79.1941929,
    },
    {
        store_latitdue: -0.261387,
        store_longitude: -79.1822,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2813359,
        store_longitude: -79.1857398,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.277589,
        store_longitude: -79.180817,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2787095,
        store_longitude: -79.1761025,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.581353,
        store_longitude: -79.5006358,
    },
    {
        store_latitdue: -0.2845935,
        store_longitude: -79.1835982,
    },
    {
        store_latitdue: -0.2755928,
        store_longitude: -79.1645048,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2567545,
        store_longitude: -79.15313,
    },
    {
        store_latitdue: -0.2619909,
        store_longitude: -79.1499473,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.062491,
        store_longitude: -78.681564,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.0881211,
        store_longitude: -79.0577187,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0628043,
        store_longitude: -78.6817379,
    },
    {
        store_latitdue: 0.064028,
        store_longitude: -78.6829954,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: -0.267337,
        store_longitude: -79.1827,
    },
    {
        store_latitdue: -0.9727581,
        store_longitude: -80.7126707,
    },
    {
        store_latitdue: -0.0539525,
        store_longitude: -78.7748,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: 0.0237651,
        store_longitude: -78.8959169,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.052733,
        store_longitude: -78.775336,
    },
    {
        store_latitdue: 0.0866,
        store_longitude: -79.052487,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.16717,
        store_longitude: -79.0494,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2797601,
        store_longitude: -79.1835768,
    },
    {
        store_latitdue: -0.277036,
        store_longitude: -79.172735,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.66962,
        store_longitude: -79.6105,
    },
    {
        store_latitdue: -0.253382,
        store_longitude: -79.1233,
    },
    {
        store_latitdue: -0.3218421,
        store_longitude: -78.9998378,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.262071,
        store_longitude: -79.1533,
    },
    {
        store_latitdue: -0.3225502,
        store_longitude: -78.9964238,
    },
    {
        store_latitdue: 0.112212,
        store_longitude: -78.73759,
        neighborhood: 'GUALEA',
    },
    {
        store_latitdue: 0.0751496,
        store_longitude: -78.995822,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.286302,
        store_longitude: -79.1824,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2900604,
        store_longitude: -79.1846336,
    },
    {
        store_latitdue: -0.2532877,
        store_longitude: -79.1242628,
    },
    {
        store_latitdue: -0.2634415,
        store_longitude: -79.1636782,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.06219,
        store_longitude: -78.682288,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: -0.253663,
        store_longitude: -79.174655,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2685603,
        store_longitude: -79.173762,
    },
    {
        store_latitdue: -0.2693661,
        store_longitude: -79.1751905,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.270085,
        store_longitude: -79.1579,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2539373,
        store_longitude: -79.1233249,
    },
    {
        store_latitdue: -0.4068645,
        store_longitude: -79.3048081,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.2650875,
        store_longitude: -79.1640565,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2640705,
        store_longitude: -79.1694766,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.062868,
        store_longitude: -78.681768,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: -0.2577521,
        store_longitude: -79.1724996,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.40673,
        store_longitude: -79.3044121,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.4146083,
        store_longitude: -79.2768779,
        neighborhood: 'EL ESFUERZO',
    },
    {
        store_latitdue: -0.253809,
        store_longitude: -79.136324,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1881246,
        store_longitude: -79.0217361,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.210732,
        store_longitude: -79.1106,
    },
    {
        store_latitdue: 0.0865455,
        store_longitude: -79.050176,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.4061104,
        store_longitude: -79.3036417,
    },
    {
        store_latitdue: 0.0884673,
        store_longitude: -79.0532,
    },
    {
        store_latitdue: 0.0879752,
        store_longitude: -79.0534,
    },
    {
        store_latitdue: -0.255885,
        store_longitude: -79.1754,
    },
    {
        store_latitdue: -0.2616018,
        store_longitude: -79.15192,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1413702,
        store_longitude: -78.7671886,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: 0.087606,
        store_longitude: -79.0543867,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0901494,
        store_longitude: -78.716805,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: -0.266273,
        store_longitude: -79.1906,
    },
    {
        store_latitdue: -0.256204,
        store_longitude: -79.169405,
    },
    {
        store_latitdue: -0.6413987,
        store_longitude: -79.2303515,
        neighborhood: 'SANTA MARÍA DEL TOACHI',
    },
    {
        store_latitdue: -0.8796107,
        store_longitude: -79.4926483,
    },
    {
        store_latitdue: -0.303214,
        store_longitude: -79.2215,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2606523,
        store_longitude: -79.1177019,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0884495,
        store_longitude: -79.0541,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2763672,
        store_longitude: -79.1901116,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.275245,
        store_longitude: -79.1762,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.639748,
        store_longitude: -79.2292,
        neighborhood: 'SANTA MARÍA DEL TOACHI',
    },
    {
        store_latitdue: -0.6135411,
        store_longitude: -79.5962642,
    },
    {
        store_latitdue: -0.3048798,
        store_longitude: -79.1542314,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6704804,
        store_longitude: -79.61002379,
    },
    {
        store_latitdue: -0.6400286,
        store_longitude: -79.2315493,
    },
    {
        store_latitdue: -0.2217658,
        store_longitude: -79.15924,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2544981,
        store_longitude: -79.1617929,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.022997,
        store_longitude: -78.890339,
    },
    {
        store_latitdue: 0.0828522,
        store_longitude: -79.047,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.113455,
        store_longitude: -78.737776,
        neighborhood: 'GUALEA',
    },
    {
        store_latitdue: -0.3490054,
        store_longitude: -78.8543488,
    },
    {
        store_latitdue: -0.1804866,
        store_longitude: -79.022468,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.088793,
        store_longitude: -79.0610674,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.26229534,
        store_longitude: -79.1775333,
    },
    {
        store_latitdue: -0.2766755,
        store_longitude: -79.077509,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.643207,
        store_longitude: -79.4441,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.6432537,
        store_longitude: -79.4433236,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.2541701,
        store_longitude: -79.1641621,
    },
    {
        store_latitdue: -0.2606004,
        store_longitude: -79.1698763,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.348768,
        store_longitude: -78.854248,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: 0.121352,
        store_longitude: -79.2536,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.1222549,
        store_longitude: -79.253278,
    },
    {
        store_latitdue: -0.577259,
        store_longitude: -79.3715,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.321094,
        store_longitude: -79.1668,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2579225,
        store_longitude: -79.1507269,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0754053,
        store_longitude: -78.9877027,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2693132,
        store_longitude: -79.1728107,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2762468,
        store_longitude: -79.1762034,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2757456,
        store_longitude: -79.1762067,
    },
    {
        store_latitdue: -0.32251,
        store_longitude: -78.9969,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: 0.0227609,
        store_longitude: -78.8947298,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2541338,
        store_longitude: -79.1215785,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.252822,
        store_longitude: -79.1219,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2607555,
        store_longitude: -79.1177641,
    },
    {
        store_latitdue: -0.2535667,
        store_longitude: -79.1218422,
    },
    {
        store_latitdue: -0.254242,
        store_longitude: -79.1213,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27272,
        store_longitude: -79.1778,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.211716,
        store_longitude: -79.114496,
    },
    {
        store_latitdue: 0.325894,
        store_longitude: -79.4749,
    },
    {
        store_latitdue: -0.48296,
        store_longitude: -79.3447,
    },
    {
        store_latitdue: 0.0230263,
        store_longitude: -78.8891381,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.1204683,
        store_longitude: -79.25482,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.268733,
        store_longitude: -79.1757,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.404749,
        store_longitude: -79.2991,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.2963796,
        store_longitude: -79.2126286,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2982229,
        store_longitude: -79.1990318,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2966797,
        store_longitude: -79.2000577,
    },
    {
        store_latitdue: -0.2897912,
        store_longitude: -79.1981151,
    },
    {
        store_latitdue: -0.0538,
        store_longitude: -78.773743,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.2669326,
        store_longitude: -79.1767296,
    },
    {
        store_latitdue: 0.0228838,
        store_longitude: -78.8954,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.212279,
        store_longitude: -79.1153,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.585717,
        store_longitude: -79.512,
    },
    {
        store_latitdue: -0.054189,
        store_longitude: -78.7753709,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.2550699,
        store_longitude: -79.1218732,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.262369,
        store_longitude: -79.161311,
    },
    {
        store_latitdue: -0.0142882,
        store_longitude: -78.7982,
    },
    {
        store_latitdue: -0.5876429,
        store_longitude: -79.5122916,
    },
    {
        store_latitdue: -0.2936596,
        store_longitude: -79.1836905,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.284342,
        store_longitude: -79.1786,
    },
    {
        store_latitdue: -0.249199,
        store_longitude: -79.1422,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.279588,
        store_longitude: -79.1799,
    },
    {
        store_latitdue: -0.2529203,
        store_longitude: -79.1204328,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0227065,
        store_longitude: -78.894314,
    },
    {
        store_latitdue: -0.268427,
        store_longitude: -79.1753,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.053976,
        store_longitude: -78.7738,
    },
    {
        store_latitdue: -0.0539186,
        store_longitude: -78.7754,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.270062944,
        store_longitude: -79.19206669,
    },
    {
        store_latitdue: -0.2877358,
        store_longitude: -79.1828918,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2688747,
        store_longitude: -79.190065,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258872,
        store_longitude: -79.1474,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0881151,
        store_longitude: -78.517244,
    },
    {
        store_latitdue: -0.277643,
        store_longitude: -79.1726,
    },

    {
        store_latitdue: -0.5859683,
        store_longitude: -79.51164,
    },
    {
        store_latitdue: -0.4184863,
        store_longitude: -78.7954939,
    },
    {
        store_latitdue: 0.120731,
        store_longitude: -79.254,
    },
    {
        store_latitdue: -0.262232,
        store_longitude: -79.1834,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.585289,
        store_longitude: -79.5113,
    },
    {
        store_latitdue: -0.4049502,
        store_longitude: -79.3015037,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.306614,
        store_longitude: -79.152,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.303879,
        store_longitude: -79.1565,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5939735,
        store_longitude: -79.5135782,
    },
    {
        store_latitdue: -0.322544,
        store_longitude: -78.9963,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2384137,
        store_longitude: -79.1758119,
    },
    {
        store_latitdue: 0.0243109,
        store_longitude: -78.8963,
    },
    {
        store_latitdue: -0.2581,
        store_longitude: -79.1791,
    },
    {
        store_latitdue: -0.264265,
        store_longitude: -79.1751,
    },
    {
        store_latitdue: 0.0228434,
        store_longitude: -78.8985099,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.0537053,
        store_longitude: -78.7747661,
    },
    {
        store_latitdue: 0.019767,
        store_longitude: -78.8965,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2642708,
        store_longitude: -79.1856766,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.322424,
        store_longitude: -78.9964224,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: 0.0068897,
        store_longitude: -79.3983744,
    },
    {
        store_latitdue: 0.0207125,
        store_longitude: -78.8485328,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.288792,
        store_longitude: -79.1834,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.071067,
        store_longitude: -78.959554,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2434016,
        store_longitude: -79.185957,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2711032,
        store_longitude: -79.1829696,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6700024,
        store_longitude: -79.6107589,
    },
    {
        store_latitdue: -0.267675,
        store_longitude: -79.1715676,
    },
    {
        store_latitdue: -0.2599699,
        store_longitude: -79.1453289,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2638363,
        store_longitude: -79.1570457,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0246071,
        store_longitude: -78.8963,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.055072,
        store_longitude: -78.773044,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: 0.0884877,
        store_longitude: -79.0543,
    },
    {
        store_latitdue: -0.29267,
        store_longitude: -79.1911,
    },
    {
        store_latitdue: 0.0823098,
        store_longitude: -79.0474317,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2118307,
        store_longitude: -79.1145571,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253939425,
        store_longitude: -79.17344062,
    },
    {
        store_latitdue: -0.2726047,
        store_longitude: -79.1723068,
    },
    {
        store_latitdue: 0.1416652,
        store_longitude: -78.768101,
    },
    {
        store_latitdue: -0.264999,
        store_longitude: -79.1839,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.272721,
        store_longitude: -79.1653405,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.419915,
        store_longitude: -78.7931,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: -0.2533371,
        store_longitude: -79.1360134,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2671291,
        store_longitude: -79.1614659,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.352452,
        store_longitude: -78.842225,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: 0.0865489,
        store_longitude: -79.0505758,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.280197,
        store_longitude: -79.202,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2632604,
        store_longitude: -79.1815286,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2570808,
        store_longitude: -79.1735916,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2613028,
        store_longitude: -79.1610047,
    },
    {
        store_latitdue: -0.278234855,
        store_longitude: -79.1834443,
    },
    {
        store_latitdue: -0.277062096,
        store_longitude: -79.1907969,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.279667,
        store_longitude: -79.173357,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25986498,
        store_longitude: -79.1782913,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.252462,
        store_longitude: -79.1623659,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2590864,
        store_longitude: -79.1429984,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.620403,
        store_longitude: -79.4263,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: 0.0886424,
        store_longitude: -79.0534,
    },
    {
        store_latitdue: 0.0886864,
        store_longitude: -79.0545,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.259373,
        store_longitude: -79.1797,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.231765,
        store_longitude: -79.177355,
    },
    {
        store_latitdue: -0.2558491,
        store_longitude: -79.169398,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2692904,
        store_longitude: -79.1932396,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2759281,
        store_longitude: -79.1756663,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2743705,
        store_longitude: -79.1763343,
    },
    {
        store_latitdue: -0.2637616,
        store_longitude: -79.1687212,
    },
    {
        store_latitdue: -0.097945,
        store_longitude: -78.5014,
    },
    {
        store_latitdue: -0.265621,
        store_longitude: -79.1772,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2702096,
        store_longitude: -79.1800687,
    },
    {
        store_latitdue: -0.271816,
        store_longitude: -79.1826,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.62032918,
        store_longitude: -79.4305408,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.620954,
        store_longitude: -79.4247,
    },
    {
        store_latitdue: -0.2829182,
        store_longitude: -79.1751686,
    },
    {
        store_latitdue: -0.6046447,
        store_longitude: -79.590512,
    },

    {
        store_latitdue: -0.4196584,
        store_longitude: -78.7949881,
    },
    {
        store_latitdue: -0.417589,
        store_longitude: -78.7978,
    },
    {
        store_latitdue: -0.4186862,
        store_longitude: -78.7951619,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: -0.313963,
        store_longitude: -79.019158,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2580503,
        store_longitude: -79.1497167,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2486808,
        store_longitude: -79.1521744,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.256341965,
        store_longitude: -79.1547369,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.255679,
        store_longitude: -79.1579,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26503785,
        store_longitude: -79.1630169,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.183881,
        store_longitude: -79.0228,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.088305,
        store_longitude: -79.054155,
    },
    {
        store_latitdue: 0.0235479,
        store_longitude: -78.8939112,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.2713748,
        store_longitude: -79.1901651,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2720384,
        store_longitude: -79.1935848,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2741267,
        store_longitude: -79.1966427,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2533377,
        store_longitude: -79.1874128,
    },
    {
        store_latitdue: -0.255527,
        store_longitude: -79.1709,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.406398,
        store_longitude: -79.3024,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.5859185,
        store_longitude: -79.5122223,
    },
    {
        store_latitdue: -0.25852766,
        store_longitude: -79.1575546,
    },
    {
        store_latitdue: -0.261855,
        store_longitude: -79.1642,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.023365,
        store_longitude: -78.8965,
    },
    {
        store_latitdue: 0.08803,
        store_longitude: -79.05348,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.25635078,
        store_longitude: -79.1700995,
    },
    {
        store_latitdue: -0.257665,
        store_longitude: -79.1762,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2901788,
        store_longitude: -79.1850257,
    },
    {
        store_latitdue: 0.905453,
        store_longitude: -79.69,
    },
    {
        store_latitdue: -0.286251,
        store_longitude: -79.1965,
    },
    {
        store_latitdue: -0.2135425,
        store_longitude: -79.1964078,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27304784,
        store_longitude: -79.170742,
    },
    {
        store_latitdue: -0.40461101,
        store_longitude: -79.2982887,
    },
    {
        store_latitdue: -0.2629185,
        store_longitude: -79.1865127,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.262604,
        store_longitude: -79.1849,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.257577,
        store_longitude: -79.1756,
    },
    {
        store_latitdue: -0.2708283,
        store_longitude: -79.1922802,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.275262,
        store_longitude: -79.188,
    },
    {
        store_latitdue: -0.6051736,
        store_longitude: -79.5149218,
    },
    {
        store_latitdue: -0.5704476,
        store_longitude: -79.3675667,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.5787299,
        store_longitude: -79.3699852,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.6714662,
        store_longitude: -79.6101803,
    },
    {
        store_latitdue: -0.404754,
        store_longitude: -79.3014,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.288806,
        store_longitude: -79.1821878,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2571693,
        store_longitude: -79.1393358,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2549067,
        store_longitude: -79.1221229,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.252541,
        store_longitude: -79.162321,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0526242,
        store_longitude: -78.7744135,
    },
    {
        store_latitdue: -0.2806183,
        store_longitude: -79.1876648,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26284793,
        store_longitude: -79.1848182,
    },
    {
        store_latitdue: -0.4172994,
        store_longitude: -78.7977606,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: 0.0687396,
        store_longitude: -78.9580165,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.477028151,
        store_longitude: -79.3107925,
    },
    {
        store_latitdue: -0.2750076,
        store_longitude: -79.1937894,
    },
    {
        store_latitdue: -0.2736539,
        store_longitude: -79.1817563,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.281892,
        store_longitude: -79.1816995,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2845285,
        store_longitude: -79.2005518,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.67378,
        store_longitude: -79.610938,
    },
    {
        store_latitdue: -0.2827211,
        store_longitude: -79.2001696,
    },
    {
        store_latitdue: -0.260072193,
        store_longitude: -79.1374909,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25589,
        store_longitude: -79.1565,
    },
    {
        store_latitdue: 0.1212954,
        store_longitude: -79.2522548,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.088263,
        store_longitude: -79.054136,
    },
    {
        store_latitdue: -0.242835,
        store_longitude: -79.1357655,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.211902,
        store_longitude: -79.1148,
    },
    {
        store_latitdue: -0.262487,
        store_longitude: -79.1838,
    },
    {
        store_latitdue: -0.2416685,
        store_longitude: -79.215469,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2719588,
        store_longitude: -79.1858436,
    },
    {
        store_latitdue: -0.2896117,
        store_longitude: -79.18337,
    },
    {
        store_latitdue: -0.2648659,
        store_longitude: -79.1497889,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.281248,
        store_longitude: -79.178786,
    },
    {
        store_latitdue: -0.2852721,
        store_longitude: -79.1962414,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.603656,
        store_longitude: -79.5574,
    },
    {
        store_latitdue: -0.3204643,
        store_longitude: -78.9906074,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.3223738,
        store_longitude: -78.9980385,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2549996,
        store_longitude: -79.1232336,
    },
    {
        store_latitdue: -0.255179,
        store_longitude: -79.1674,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2666699,
        store_longitude: -79.1611836,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2693527,
        store_longitude: -79.174695,
    },
    {
        store_latitdue: -0.2794672,
        store_longitude: -79.2010512,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -4.45066,
        store_longitude: -79.4411,
        neighborhood: '27 DE ABRIL',
    },

    {
        store_latitdue: -0.3227618,
        store_longitude: -78.9964204,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: 0.122538,
        store_longitude: -79.2535,
    },
    {
        store_latitdue: -0.277945,
        store_longitude: -79.1745733,
    },
    {
        store_latitdue: -0.321921,
        store_longitude: -78.9999,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.181287,
        store_longitude: -79.0252,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1238334,
        store_longitude: -79.2540945,
    },
    {
        store_latitdue: 0.0816004,
        store_longitude: -79.0494277,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.28289,
        store_longitude: -79.20035,
    },
    {
        store_latitdue: 0.137222,
        store_longitude: -78.6761,
        neighborhood: 'NANEGAL',
    },
    {
        store_latitdue: -0.270319148,
        store_longitude: -79.18006242,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.279117,
        store_longitude: -79.1836,
    },
    {
        store_latitdue: -0.2510728,
        store_longitude: -79.1386678,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0521922,
        store_longitude: -78.7762231,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.32195,
        store_longitude: -78.9967,
        neighborhood: 'ALLURIQUÍN',
    },

    {
        store_latitdue: -0.2714021,
        store_longitude: -79.1757825,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0573819,
        store_longitude: -78.6976681,
    },
    {
        store_latitdue: -0.620316,
        store_longitude: -79.4292,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: 0.1414353,
        store_longitude: -78.7674446,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: 0.1411282,
        store_longitude: -78.7673501,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: -0.2537866,
        store_longitude: -79.152665,
    },

    {
        store_latitdue: -0.2540389,
        store_longitude: -79.1218625,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.272388,
        store_longitude: -79.1781776,
    },
    {
        store_latitdue: -0.340637,
        store_longitude: -79.272193,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.2855233,
        store_longitude: -79.2019167,
    },
    {
        store_latitdue: -0.248551,
        store_longitude: -79.142311,
    },
    {
        store_latitdue: -0.2612596,
        store_longitude: -79.1542631,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2353602,
        store_longitude: -79.1287797,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.255246,
        store_longitude: -79.1719,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.406918,
        store_longitude: -79.3048807,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.27680691,
        store_longitude: -79.077043,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26957,
        store_longitude: -79.111,
    },
    {
        store_latitdue: -0.4149945,
        store_longitude: -78.8002616,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: -0.2559281,
        store_longitude: -79.1269066,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2721109,
        store_longitude: -79.1784125,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.051004,
        store_longitude: -78.7776007,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.5848736,
        store_longitude: -79.508363,
    },
    {
        store_latitdue: -0.265932,
        store_longitude: -79.1660471,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.280261,
        store_longitude: -79.183613,
    },
    {
        store_latitdue: 0.0292684,
        store_longitude: -78.9012499,
    },
    {
        store_latitdue: -0.272596171,
        store_longitude: -79.16527484,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3370824,
        store_longitude: -79.2361974,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.315683,
        store_longitude: -78.547598,
    },
    {
        store_latitdue: -0.29072601,
        store_longitude: -79.18354198,
    },
    {
        store_latitdue: 0.0162746,
        store_longitude: -78.9001464,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.40447,
        store_longitude: -79.3,
    },
    {
        store_latitdue: -0.4167255,
        store_longitude: -79.2768214,
        neighborhood: 'EL ESFUERZO',
    },
    {
        store_latitdue: -0.5813662,
        store_longitude: -79.5060345,
    },
    {
        store_latitdue: 0.0526031,
        store_longitude: -79.2530874,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.260723,
        store_longitude: -79.1785,
    },
    {
        store_latitdue: -0.2799257,
        store_longitude: -79.183697,
    },
    {
        store_latitdue: -0.273867,
        store_longitude: -79.189211,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2913517,
        store_longitude: -79.183057,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26618146,
        store_longitude: -79.1707205,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.283687,
        store_longitude: -79.2033,
    },

    {
        store_latitdue: -0.2725548,
        store_longitude: -79.1872669,
    },
    {
        store_latitdue: -0.2807568,
        store_longitude: -79.2016154,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0527354,
        store_longitude: -78.7744,
        neighborhood: 'MINDO',
    },

    {
        store_latitdue: -0.2880198,
        store_longitude: -79.1808663,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.260439,
        store_longitude: -79.164124,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -1.0059883,
        store_longitude: -79.4669476,
    },
    {
        store_latitdue: 0.0489647,
        store_longitude: -79.3346,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.1218602,
        store_longitude: -79.2526707,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.122064,
        store_longitude: -79.2544908,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.2814008,
        store_longitude: -79.1836377,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3138817,
        store_longitude: -79.0203113,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.25367,
        store_longitude: -79.17446,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2568419,
        store_longitude: -79.1535127,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2346603,
        store_longitude: -79.1817656,
    },
    {
        store_latitdue: -0.274904,
        store_longitude: -79.2004,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5914722,
        store_longitude: -79.5133015,
    },
    {
        store_latitdue: -0.3200993,
        store_longitude: -78.9883314,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2346991,
        store_longitude: -79.1278214,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.211003,
        store_longitude: -79.1117,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1483517,
        store_longitude: -78.9990066,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.083649,
        store_longitude: -79.043981,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2639734,
        store_longitude: -79.159183,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.254938,
        store_longitude: -79.1612,
    },
    {
        store_latitdue: 0.0617253,
        store_longitude: -78.6815,
    },
    {
        store_latitdue: -0.2147968,
        store_longitude: -79.1171053,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2112729,
        store_longitude: -79.1127951,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.320845,
        store_longitude: -79.1671428,
    },
    {
        store_latitdue: -0.2686579,
        store_longitude: -79.1831002,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.586179,
        store_longitude: -79.5101953,
    },
    {
        store_latitdue: -0.292926592,
        store_longitude: -79.17994514,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0551552,
        store_longitude: -78.7733,
        neighborhood: 'MINDO',
    },

    {
        store_latitdue: -0.2935998,
        store_longitude: -79.1808979,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2635457,
        store_longitude: -79.1894821,
    },
    {
        store_latitdue: -0.254199,
        store_longitude: -79.15067,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.340839,
        store_longitude: -79.2726,
    },
    {
        store_latitdue: -0.239472,
        store_longitude: -79.163965,
    },
    {
        store_latitdue: 0.083894,
        store_longitude: -79.0441706,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.061996,
        store_longitude: -78.681652,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: -0.2571942,
        store_longitude: -79.1671271,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3201252,
        store_longitude: -78.9887367,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.053275,
        store_longitude: -78.775255,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.2568911,
        store_longitude: -79.153144,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5865068,
        store_longitude: -79.5121691,
    },
    {
        store_latitdue: -0.4070919,
        store_longitude: -79.3055392,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: 0.0890418,
        store_longitude: -79.055558,
    },
    {
        store_latitdue: -0.5801845,
        store_longitude: -79.3705781,
    },
    {
        store_latitdue: -0.04152,
        store_longitude: -79.492891,
    },
    {
        store_latitdue: -1.171936,
        store_longitude: -79.219446,
    },
    {
        store_latitdue: 0.0858893,
        store_longitude: -79.0548673,
    },

    {
        store_latitdue: -0.2609074,
        store_longitude: -79.17489,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2678831,
        store_longitude: -79.1848841,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25891137,
        store_longitude: -79.1714019,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259599,
        store_longitude: -79.1714,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26694,
        store_longitude: -79.187239,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.264683,
        store_longitude: -79.1506,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.259149,
        store_longitude: -79.1534504,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2283978,
        store_longitude: -79.1674724,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5908175,
        store_longitude: -79.5131814,
    },
    {
        store_latitdue: 0.0233972,
        store_longitude: -78.8956703,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.052497,
        store_longitude: -78.779932,
    },
    {
        store_latitdue: -0.256944,
        store_longitude: -79.1715,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.254812,
        store_longitude: -79.1679,
    },
    {
        store_latitdue: -0.2633282,
        store_longitude: -79.1632462,
    },
    {
        store_latitdue: -0.262719,
        store_longitude: -79.1654,
    },
    {
        store_latitdue: -0.2547272,
        store_longitude: -79.1592613,
    },
    {
        store_latitdue: -0.3222736,
        store_longitude: -78.9989418,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2739183,
        store_longitude: -79.1605823,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.260502,
        store_longitude: -79.1261,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2822907,
        store_longitude: -79.4600384,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.255412,
        store_longitude: -79.1636,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2532432,
        store_longitude: -79.165164,
    },
    {
        store_latitdue: -0.322039,
        store_longitude: -78.996151,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2563324,
        store_longitude: -79.1600224,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1216187,
        store_longitude: -79.2535615,
    },
    {
        store_latitdue: 0.0881448,
        store_longitude: -79.0533,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.242743,
        store_longitude: -79.1354,
    },
    {
        store_latitdue: 0.087206,
        store_longitude: -79.0517338,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0851562,
        store_longitude: -79.0481555,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.5864221,
        store_longitude: -79.5121753,
    },
    {
        store_latitdue: -0.2833856,
        store_longitude: -79.2118184,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1874663,
        store_longitude: -79.3203913,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2511827,
        store_longitude: -79.3695259,
    },
    {
        store_latitdue: -0.2452558,
        store_longitude: -79.1815289,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2575325,
        store_longitude: -79.1852734,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2517109,
        store_longitude: -79.1676924,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0035885,
        store_longitude: -79.3983004,
    },
    {
        store_latitdue: -0.2538621,
        store_longitude: -79.1975382,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.245879,
        store_longitude: -79.3481,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.234715,
        store_longitude: -79.1851,
    },
    {
        store_latitdue: -0.24682,
        store_longitude: -79.1703,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2711392,
        store_longitude: -79.4673881,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2494792,
        store_longitude: -79.2016462,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.257273,
        store_longitude: -79.2069,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2302758,
        store_longitude: -79.1971123,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1325245,
        store_longitude: -79.4059904,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.259848,
        store_longitude: -79.1989,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.174585,
        store_longitude: -79.3395,
    },
    {
        store_latitdue: -0.274757,
        store_longitude: -79.2134,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.257394,
        store_longitude: -79.2076,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2740257,
        store_longitude: -79.2124471,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.282071,
        store_longitude: -79.2089222,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2608472,
        store_longitude: -79.4044677,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2759592,
        store_longitude: -79.4716623,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2383802,
        store_longitude: -79.1935133,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.147432,
        store_longitude: -79.3949,
    },
    {
        store_latitdue: -0.276761,
        store_longitude: -79.2044302,
    },
    {
        store_latitdue: -0.2388657,
        store_longitude: -79.1784247,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2722377,
        store_longitude: -79.4602342,
    },
    {
        store_latitdue: -0.246906,
        store_longitude: -79.1582,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.134649,
        store_longitude: -79.4034,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.246509,
        store_longitude: -79.1667,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0056869,
        store_longitude: -79.3949,
    },
    {
        store_latitdue: -0.276947,
        store_longitude: -79.4678,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2442421,
        store_longitude: -79.2001616,
    },
    {
        store_latitdue: -0.242439,
        store_longitude: -79.1992,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.246592,
        store_longitude: -79.2028,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2448615,
        store_longitude: -79.1802634,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.228049,
        store_longitude: -79.184,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1357509,
        store_longitude: -79.4029432,
    },
    {
        store_latitdue: -0.00052,
        store_longitude: -79.3834,
    },
    {
        store_latitdue: -0.256953,
        store_longitude: -79.188759,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.245069,
        store_longitude: -79.1589,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2450742,
        store_longitude: -79.1834517,
    },
    {
        store_latitdue: -0.224894,
        store_longitude: -79.1851,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2398613,
        store_longitude: -79.1745452,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2405,
        store_longitude: -79.173941,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1364455,
        store_longitude: -79.4038818,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.0037507,
        store_longitude: -79.3969,
    },
    {
        store_latitdue: 0.0082775,
        store_longitude: -79.3854685,
    },
    {
        store_latitdue: -0.2803202,
        store_longitude: -79.209491,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.278046,
        store_longitude: -79.4701,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.176594,
        store_longitude: -79.5374,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.262112,
        store_longitude: -79.1917088,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.24417,
        store_longitude: -79.345331,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.276907,
        store_longitude: -79.4702,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.272686,
        store_longitude: -79.4605,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.2968396,
        store_longitude: -79.2730129,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.00184363,
        store_longitude: -79.3846511,
    },
    {
        store_latitdue: -0.275061,
        store_longitude: -79.464214,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.237609,
        store_longitude: -79.1969,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.233451,
        store_longitude: -79.1824,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2416754,
        store_longitude: -79.1808176,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.24345,
        store_longitude: -79.269299,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.275421,
        store_longitude: -79.4545,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2511222,
        store_longitude: -79.4870597,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.257523481,
        store_longitude: -79.48039963,
    },
    {
        store_latitdue: -0.2530811,
        store_longitude: -79.4840799,
    },
    {
        store_latitdue: -0.238219,
        store_longitude: -79.1665,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2439525,
        store_longitude: -79.1805864,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.221238,
        store_longitude: -79.1974,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.328636,
        store_longitude: -79.2125,
    },
    {
        store_latitdue: 0.003419,
        store_longitude: -79.398267,
    },
    {
        store_latitdue: -0.2591853,
        store_longitude: -79.1854411,
    },
    {
        store_latitdue: -0.2527634,
        store_longitude: -79.207832,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26260089,
        store_longitude: -79.2137753,
    },
    {
        store_latitdue: -0.2492607,
        store_longitude: -79.1803741,
    },
    {
        store_latitdue: -0.289699,
        store_longitude: -79.2138,
    },
    {
        store_latitdue: -0.2577632,
        store_longitude: -79.1897972,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1329701,
        store_longitude: -79.3836671,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.239941,
        store_longitude: -79.2005,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.240714,
        store_longitude: -79.171,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2442741,
        store_longitude: -79.184848,
    },
    {
        store_latitdue: -0.228656,
        store_longitude: -79.179025,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2492739,
        store_longitude: -79.1825919,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.003494,
        store_longitude: -79.395769,
    },
    {
        store_latitdue: -0.278912,
        store_longitude: -79.2069637,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.120048,
        store_longitude: -79.451412,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2559784,
        store_longitude: -79.2177235,
    },
    {
        store_latitdue: -0.698542,
        store_longitude: -80.0924,
    },
    {
        store_latitdue: -0.242583,
        store_longitude: -79.2072,
    },
    {
        store_latitdue: -0.271665,
        store_longitude: -79.4376,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2705796,
        store_longitude: -79.4367393,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.296822,
        store_longitude: -79.2754,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.244024,
        store_longitude: -79.2894156,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2717034,
        store_longitude: -79.4644396,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.222811,
        store_longitude: -79.199,
    },
    {
        store_latitdue: 0.3477571,
        store_longitude: -78.1219114,
    },
    {
        store_latitdue: -0.2042984,
        store_longitude: -79.535063,
    },
    {
        store_latitdue: -0.2759759,
        store_longitude: -79.2131309,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.249607,
        store_longitude: -79.2017,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2579746,
        store_longitude: -79.2030151,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2784683,
        store_longitude: -79.4636106,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.225252,
        store_longitude: -79.196357,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.014833,
        store_longitude: -79.387467,
    },
    {
        store_latitdue: -0.2391092,
        store_longitude: -79.1775127,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0188111,
        store_longitude: -79.3924,
    },
    {
        store_latitdue: 0.0111646,
        store_longitude: -79.3908803,
    },
    {
        store_latitdue: -0.322830695,
        store_longitude: -79.2997638,
    },
    {
        store_latitdue: 0.1352841,
        store_longitude: -79.402148,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.136774,
        store_longitude: -79.409632,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.270654,
        store_longitude: -79.1999,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2597106,
        store_longitude: -79.1839222,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2737374,
        store_longitude: -79.2056573,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2796135,
        store_longitude: -79.2126039,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2491029,
        store_longitude: -79.1707486,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.270793,
        store_longitude: -79.2065,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2832594,
        store_longitude: -79.4681164,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2382666,
        store_longitude: -79.1881618,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2440587,
        store_longitude: -79.2894514,
    },
    {
        store_latitdue: -0.2604463,
        store_longitude: -79.4767847,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.228034,
        store_longitude: -79.1984,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2571172,
        store_longitude: -79.1858637,
    },
    {
        store_latitdue: -0.310609,
        store_longitude: -79.2343,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2721697,
        store_longitude: -79.4509637,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.275,
        store_longitude: -79.46217,
    },
    {
        store_latitdue: -0.223137,
        store_longitude: -79.1658,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258523,
        store_longitude: -79.1857,
    },
    {
        store_latitdue: -0.249308,
        store_longitude: -79.1824415,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.270399,
        store_longitude: -79.4692718,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.1465793,
        store_longitude: -78.4691637,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235218,
        store_longitude: -79.1819,
    },
    {
        store_latitdue: -0.2360866,
        store_longitude: -79.1803236,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.061266,
        store_longitude: -79.443124,
    },
    {
        store_latitdue: -0.0425735,
        store_longitude: -79.4946847,
    },
    {
        store_latitdue: -0.0375998,
        store_longitude: -79.4937,
    },
    {
        store_latitdue: -0.260155,
        store_longitude: -79.199988,
    },
    {
        store_latitdue: -0.18758,
        store_longitude: -79.31983,
    },
    {
        store_latitdue: -0.277009,
        store_longitude: -79.4702,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2466549,
        store_longitude: -79.1799846,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2523349,
        store_longitude: -79.4854278,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2737256,
        store_longitude: -79.4660202,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.236566,
        store_longitude: -79.166103,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.233937,
        store_longitude: -79.1712,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2271147,
        store_longitude: -79.1849937,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.237156,
        store_longitude: -79.1758,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.4213482,
        store_longitude: -79.1973118,
    },
    {
        store_latitdue: -0.47248,
        store_longitude: -79.5568,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.3270578,
        store_longitude: -79.2019951,
    },
    {
        store_latitdue: -0.272748,
        store_longitude: -79.4633,
    },
    {
        store_latitdue: -0.2705571,
        store_longitude: -79.2053035,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2443519,
        store_longitude: -79.1696879,
    },
    {
        store_latitdue: -0.2461578,
        store_longitude: -79.1770523,
    },
    {
        store_latitdue: -0.050829,
        store_longitude: -79.4441423,
    },
    {
        store_latitdue: 0.00566131,
        store_longitude: -79.39840062,
    },
    {
        store_latitdue: -0.2643108,
        store_longitude: -79.1918158,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.278718,
        store_longitude: -79.4636,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.23987,
        store_longitude: -79.1928669,
    },
    {
        store_latitdue: -0.2780832,
        store_longitude: -79.4493706,
    },
    {
        store_latitdue: -0.236722,
        store_longitude: -79.1822,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2356833,
        store_longitude: -79.1836432,
    },
    {
        store_latitdue: -0.2518115,
        store_longitude: -79.1850148,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2493747,
        store_longitude: -79.1661438,
    },
    {
        store_latitdue: 0.0349603,
        store_longitude: -79.4069363,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.25727,
        store_longitude: -79.2061,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258435,
        store_longitude: -79.2088,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.239902,
        store_longitude: -79.1838,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3558457,
        store_longitude: -79.5306251,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2523289,
        store_longitude: -79.211905,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.004559,
        store_longitude: -79.3973731,
    },
    {
        store_latitdue: -0.249095,
        store_longitude: -79.1977,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2639188,
        store_longitude: -79.4179904,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.264222,
        store_longitude: -79.1413,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25814,
        store_longitude: -79.4783,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.339017,
        store_longitude: -78.555541,
    },
    {
        store_latitdue: -0.2721256,
        store_longitude: -79.451054,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2367378,
        store_longitude: -79.1863939,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0522371,
        store_longitude: -79.4444073,
    },
    {
        store_latitdue: 0.0113564,
        store_longitude: -79.392517,
    },
    {
        store_latitdue: 0.0003233,
        store_longitude: -79.3954593,
    },
    {
        store_latitdue: -0.2540049,
        store_longitude: -79.1972979,
    },
    {
        store_latitdue: -0.386469341,
        store_longitude: -79.37214254,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -4.0264,
        store_longitude: -79.2077,
    },
    {
        store_latitdue: 0.3241566,
        store_longitude: -79.2036737,
    },
    {
        store_latitdue: 0.0047148,
        store_longitude: -79.3935921,
    },
    {
        store_latitdue: -0.2560274,
        store_longitude: -79.1819153,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.268098,
        store_longitude: -78.552244,
    },
    {
        store_latitdue: -0.279312,
        store_longitude: -79.469825,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.229305,
        store_longitude: -79.1976,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2449427,
        store_longitude: -79.1635401,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1341754259,
        store_longitude: -79.4047961,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.942242,
        store_longitude: -80.7347,
    },
    {
        store_latitdue: 0.0092809,
        store_longitude: -79.3964,
    },
    {
        store_latitdue: 0.0072454,
        store_longitude: -79.3931268,
    },
    {
        store_latitdue: -0.258191,
        store_longitude: -79.2115,
    },
    {
        store_latitdue: -0.2581912,
        store_longitude: -79.2107126,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2852485,
        store_longitude: -79.2145853,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258465,
        store_longitude: -79.1845,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2804257,
        store_longitude: -79.46763,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.242802,
        store_longitude: -79.3454,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.006658,
        store_longitude: -79.398814,
    },
    {
        store_latitdue: -0.249795,
        store_longitude: -79.199548,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2717688,
        store_longitude: -79.4537436,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2537513,
        store_longitude: -79.1870076,
    },
    {
        store_latitdue: -0.2263715,
        store_longitude: -79.1849288,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2792066,
        store_longitude: -79.4614426,
    },
    {
        store_latitdue: 0.0747383,
        store_longitude: -80.0498331,
    },
    {
        store_latitdue: -0.25015,
        store_longitude: -79.18366,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2544475,
        store_longitude: -79.1799887,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0369528,
        store_longitude: -79.4933984,
    },
    {
        store_latitdue: 0.3321598,
        store_longitude: -79.4727912,
    },
    {
        store_latitdue: 0.1347029,
        store_longitude: -79.4041657,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.278886,
        store_longitude: -79.2111,
    },
    {
        store_latitdue: -0.2375424,
        store_longitude: -79.1984937,
    },
    {
        store_latitdue: -0.2102486,
        store_longitude: -79.4992591,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2561657,
        store_longitude: -79.2095907,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3870942,
        store_longitude: -79.3725346,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -0.2593117,
        store_longitude: -79.1854565,
    },
    {
        store_latitdue: -0.2663659,
        store_longitude: -79.1589726,
    },
    {
        store_latitdue: -0.2391879,
        store_longitude: -79.1658102,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0435159,
        store_longitude: -79.4941,
    },
    {
        store_latitdue: 0.088897,
        store_longitude: -79.410106,
    },
    {
        store_latitdue: 0.1305627,
        store_longitude: -79.3612155,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2502368,
        store_longitude: -79.2146765,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.275912,
        store_longitude: -79.4624,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.238987,
        store_longitude: -79.165,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2255215,
        store_longitude: -79.1926841,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3360856,
        store_longitude: -79.7580414,
    },
    {
        store_latitdue: -0.336056,
        store_longitude: -79.7581,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.2608071,
        store_longitude: -79.4065624,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2655158,
        store_longitude: -79.4690582,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2445455,
        store_longitude: -79.1739074,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.231484,
        store_longitude: -79.1749,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3232653,
        store_longitude: -79.214113,
    },
    {
        store_latitdue: 0.0039274,
        store_longitude: -79.4006402,
    },
    {
        store_latitdue: -0.2597322,
        store_longitude: -79.1878905,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26825788,
        store_longitude: -79.4462661,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2505098,
        store_longitude: -79.4884236,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2292171,
        store_longitude: -79.1984039,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3228951,
        store_longitude: -79.2114318,
    },
    {
        store_latitdue: -0.1774691,
        store_longitude: -79.3373031,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.22503,
        store_longitude: -79.1974,
    },
    {
        store_latitdue: -0.23524,
        store_longitude: -79.1818537,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1307947,
        store_longitude: -79.4060058,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.27228,
        store_longitude: -79.4489,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2548676,
        store_longitude: -79.2172237,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.255998,
        store_longitude: -79.165389,
    },
    {
        store_latitdue: -0.238974,
        store_longitude: -79.170559,
    },
    {
        store_latitdue: -0.2501439,
        store_longitude: -79.1639295,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.24441689,
        store_longitude: -79.1738319,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2505647,
        store_longitude: -79.1799284,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.243010386,
        store_longitude: -79.18480687,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0014061,
        store_longitude: -79.3981764,
    },
    {
        store_latitdue: 0.13634,
        store_longitude: -79.403438,
    },
    {
        store_latitdue: -0.2716201,
        store_longitude: -79.2101177,
    },
    {
        store_latitdue: 0.0063542,
        store_longitude: -79.4018119,
    },
    {
        store_latitdue: -0.2617089,
        store_longitude: -79.1969185,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2638291,
        store_longitude: -79.1981609,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2304031,
        store_longitude: -79.1804622,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2310655,
        store_longitude: -79.1739025,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0324229,
        store_longitude: -79.4960432,
    },
    {
        store_latitdue: 0.0198836,
        store_longitude: -79.3767548,
    },
    {
        store_latitdue: -0.249264,
        store_longitude: -79.164117,
    },
    {
        store_latitdue: -0.254586,
        store_longitude: -79.1768,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.24644,
        store_longitude: -79.1961,
    },
    {
        store_latitdue: -0.2776388,
        store_longitude: -79.2082648,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2707778,
        store_longitude: -79.1988756,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26521405,
        store_longitude: -79.4698486,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2267888,
        store_longitude: -79.1977231,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.01185,
        store_longitude: -79.38917,
    },
    {
        store_latitdue: -0.2060463,
        store_longitude: -79.312401,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2533782,
        store_longitude: -79.1733546,
    },
    {
        store_latitdue: 0.137148052,
        store_longitude: -79.4020004,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.26076,
        store_longitude: -79.2251,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.262249,
        store_longitude: -79.209168,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2456755,
        store_longitude: -79.2010346,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.22928,
        store_longitude: -79.1699733,
    },
    {
        store_latitdue: 0.0197205,
        store_longitude: -79.3976327,
    },
    {
        store_latitdue: -0.2736621,
        store_longitude: -79.2050803,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.270427691,
        store_longitude: -79.45074823,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.269562,
        store_longitude: -79.4663454,
    },
    {
        store_latitdue: -0.2503577,
        store_longitude: -79.1646063,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2510293,
        store_longitude: -79.1715065,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.225834,
        store_longitude: -79.1891,
    },
    {
        store_latitdue: 0.9296844,
        store_longitude: -79.6721731,
    },
    {
        store_latitdue: -0.2315719,
        store_longitude: -79.1846509,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2236434,
        store_longitude: -79.1975917,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -2.3084499,
        store_longitude: -78.1184648,
    },
    {
        store_latitdue: 0.1273521,
        store_longitude: -79.4075362,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2498743,
        store_longitude: -79.1693602,
    },
    {
        store_latitdue: -0.256999,
        store_longitude: -79.2081157,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.270389,
        store_longitude: -79.450734,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.270574,
        store_longitude: -79.579802,
        neighborhood: 'WILFRIDO LOOR MOREIRA',
    },
    {
        store_latitdue: -0.273957,
        store_longitude: -79.5605,
    },
    {
        store_latitdue: -0.28439903,
        store_longitude: -79.2128295,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2907911,
        store_longitude: -79.2180224,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2768891,
        store_longitude: -79.2028848,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26087,
        store_longitude: -79.4778,
    },
    {
        store_latitdue: -0.231136,
        store_longitude: -79.1733293,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.237596,
        store_longitude: -79.181906,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.271396405,
        store_longitude: -79.46462123,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2658676,
        store_longitude: -79.2067639,
    },
    {
        store_latitdue: -0.2511595714,
        store_longitude: -79.1714370623,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2102803,
        store_longitude: -79.2613122,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.235822,
        store_longitude: -79.1811179,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2559,
        store_longitude: -79.21074,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2598731,
        store_longitude: -79.2317667,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2507606,
        store_longitude: -79.2051466,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1008759,
        store_longitude: -79.2945399,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.24589878,
        store_longitude: -79.1635208,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2528502,
        store_longitude: -79.2104478,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2474979,
        store_longitude: -79.5002127,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.235558106,
        store_longitude: -79.20072296,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2639082,
        store_longitude: -79.1577774,
    },
    {
        store_latitdue: 0.0119922,
        store_longitude: -79.3975417,
    },
    {
        store_latitdue: -0.2557555,
        store_longitude: -79.1791384,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2602711,
        store_longitude: -79.2115886,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2232561,
        store_longitude: -79.1657139,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2604531,
        store_longitude: -79.2112195,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.280341,
        store_longitude: -79.458119,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2743812,
        store_longitude: -79.5714394,
        neighborhood: 'WILFRIDO LOOR MOREIRA',
    },
    {
        store_latitdue: -0.2251164,
        store_longitude: -79.1968273,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.249135,
        store_longitude: -79.1768933,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.238988636,
        store_longitude: -79.16756093,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.099642,
        store_longitude: -78.427278,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.23596137,
        store_longitude: -79.18887963,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0048043,
        store_longitude: -79.3869663,
    },
    {
        store_latitdue: 0.0044181,
        store_longitude: -79.3886268,
    },
    {
        store_latitdue: -0.268385,
        store_longitude: -79.4355,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.009503,
        store_longitude: -79.386472,
    },
    {
        store_latitdue: -0.258579,
        store_longitude: -79.1959391,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.136595,
        store_longitude: -79.403958,
    },
    {
        store_latitdue: 0.0070685,
        store_longitude: -79.3989,
    },
    {
        store_latitdue: -0.0025836,
        store_longitude: -79.3932755,
    },
    {
        store_latitdue: -0.2666922,
        store_longitude: -79.202102,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2740257,
        store_longitude: -79.2124471,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2550813,
        store_longitude: -79.1818921,
    },
    {
        store_latitdue: -0.2720806,
        store_longitude: -79.453437,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2460304,
        store_longitude: -79.2032939,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.147254,
        store_longitude: -79.3953,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2707459,
        store_longitude: -79.4601764,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.239075,
        store_longitude: -79.1752347,
    },
    {
        store_latitdue: -0.2321077,
        store_longitude: -79.1725402,
    },
    {
        store_latitdue: -0.1691295,
        store_longitude: -79.2154285,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2416369,
        store_longitude: -79.1865275,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2351214,
        store_longitude: -79.169883,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.035993866,
        store_longitude: -79.4052124,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2538847,
        store_longitude: -79.1982245,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2621762,
        store_longitude: -79.2102809,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.008671,
        store_longitude: -79.395498,
    },
    {
        store_latitdue: 0.2308533,
        store_longitude: -78.2652537,
    },
    {
        store_latitdue: -0.24354,
        store_longitude: -79.1711,
    },
    {
        store_latitdue: -0.238409,
        store_longitude: -79.159852,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0171201,
        store_longitude: -79.3812649,
    },
    {
        store_latitdue: -0.028902,
        store_longitude: -79.4961079,
    },
    {
        store_latitdue: -0.242889,
        store_longitude: -79.174117,
    },
    {
        store_latitdue: -0.2330394,
        store_longitude: -79.1716631,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.2981041,
        store_longitude: -79.2752269,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.0301955,
        store_longitude: -79.4948204,
    },
    {
        store_latitdue: 0.2993508,
        store_longitude: -79.2741877,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.254866,
        store_longitude: -79.187,
    },
    {
        store_latitdue: -0.2388331,
        store_longitude: -79.180168,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.237570965,
        store_longitude: -79.17784183,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2450333,
        store_longitude: -79.2009266,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2303371,
        store_longitude: -79.1769165,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25813492,
        store_longitude: -79.1890347,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2375411,
        store_longitude: -79.1780036,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2489163,
        store_longitude: -79.1837852,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2762419,
        store_longitude: -79.5725761,
        neighborhood: 'WILFRIDO LOOR MOREIRA',
    },
    {
        store_latitdue: -0.226285,
        store_longitude: -79.197721,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2463643,
        store_longitude: -79.1827118,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.237893,
        store_longitude: -79.176027,
    },
    {
        store_latitdue: -0.237924,
        store_longitude: -79.174605,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2419431,
        store_longitude: -79.1848793,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.27163487,
        store_longitude: -79.2004623,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.30297,
        store_longitude: -79.1867,
    },
    {
        store_latitdue: -0.2293079,
        store_longitude: -79.174825,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1072957,
        store_longitude: -79.2802161,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.1465822,
        store_longitude: -79.5660705,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.2686328,
        store_longitude: -79.4671832,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2445333,
        store_longitude: -79.2022135,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0718675,
        store_longitude: -79.3938355,
    },
    {
        store_latitdue: 0.330963,
        store_longitude: -79.471944,
    },

    {
        store_latitdue: -0.2778723,
        store_longitude: -79.4624824,
    },

    {
        store_latitdue: -0.256259,
        store_longitude: -79.2138,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2439763,
        store_longitude: -79.1829731,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2613861,
        store_longitude: -79.4059646,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.409989,
        store_longitude: -79.559853,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.1098151,
        store_longitude: -79.6164263,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.279272,
        store_longitude: -79.212479,
    },
    {
        store_latitdue: -0.2703325,
        store_longitude: -79.4491909,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.134964,
        store_longitude: -79.3894128,
        neighborhood: 'LA UNIÓN',
    },

    {
        store_latitdue: 0.0035408,
        store_longitude: -79.4012,
    },
    {
        store_latitdue: -0.2857925,
        store_longitude: -79.2170094,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.278573,
        store_longitude: -79.4754,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.1604705,
        store_longitude: -79.2110934,
    },
    {
        store_latitdue: 0.1106929,
        store_longitude: -79.1920171,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.267466,
        store_longitude: -79.1632,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2654513,
        store_longitude: -79.179276,
    },
    {
        store_latitdue: -0.2923619,
        store_longitude: -79.2041521,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0621725,
        store_longitude: -78.6817318,
    },
    {
        store_latitdue: -0.2599118676,
        store_longitude: -79.1762925312,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6713843,
        store_longitude: -79.610198,
    },
    {
        store_latitdue: -0.6687128,
        store_longitude: -79.6107146,
    },
    {
        store_latitdue: -0.2589317,
        store_longitude: -79.1446371,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2633909,
        store_longitude: -79.1681192,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2844899,
        store_longitude: -79.1787141,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.282124,
        store_longitude: -79.1996,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.57045137,
        store_longitude: -79.3681411,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.571469,
        store_longitude: -79.3716,
    },
    {
        store_latitdue: -0.5748114,
        store_longitude: -79.3699505,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.320579,
        store_longitude: -78.9895,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.264799,
        store_longitude: -79.149228,
    },

    {
        store_latitdue: -0.5744948,
        store_longitude: -79.3711526,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.2700032,
        store_longitude: -79.0789907,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.314078,
        store_longitude: -79.019447,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: 0.137887,
        store_longitude: -78.675665,
        neighborhood: 'NANEGAL',
    },
    {
        store_latitdue: 0.1296266,
        store_longitude: -78.656023,
        neighborhood: 'NANEGAL',
    },
    {
        store_latitdue: 0.137903333,
        store_longitude: -78.6765466,
        neighborhood: 'NANEGAL',
    },
    {
        store_latitdue: 0.137919,
        store_longitude: -78.675777,
        neighborhood: 'NANEGAL',
    },
    {
        store_latitdue: 0.1298147,
        store_longitude: -78.6683302,
        neighborhood: 'NANEGAL',
    },
    {
        store_latitdue: -0.2692129988,
        store_longitude: -79.1912391409,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.267512,
        store_longitude: -79.188205,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2742955,
        store_longitude: -79.1924975,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2579522,
        store_longitude: -79.1669941,
    },
    {
        store_latitdue: -0.1768841,
        store_longitude: -76.6420118,
    },
    {
        store_latitdue: -0.2726322,
        store_longitude: -79.1857996,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2773715,
        store_longitude: -79.1917573,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2427744,
        store_longitude: -79.1591358,
    },

    {
        store_latitdue: 0.0713551,
        store_longitude: -78.695079,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.1114561,
        store_longitude: -78.7481434,
        neighborhood: 'GUALEA',
    },
    {
        store_latitdue: -0.2822152,
        store_longitude: -79.204472,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.111708,
        store_longitude: -78.749209,
        neighborhood: 'GUALEA',
    },

    {
        store_latitdue: -0.235462,
        store_longitude: -79.129325,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.119094,
        store_longitude: -79.251738,
        neighborhood: 'PUERTO QUITO',
    },

    {
        store_latitdue: -0.2555962,
        store_longitude: -79.1760872,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2581481,
        store_longitude: -79.1768959,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0961465,
        store_longitude: -79.2900512,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.0872649,
        store_longitude: -79.2787684,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.0846468,
        store_longitude: -79.2799,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.0321414,
        store_longitude: -79.8273906,
        neighborhood: 'CHIBUNGA',
    },
    {
        store_latitdue: -0.001848,
        store_longitude: -79.842726,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: -0.00330928,
        store_longitude: -79.8647877,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.03243,
        store_longitude: -79.94555,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: -0.264798,
        store_longitude: -79.4695418,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.0418013,
        store_longitude: -79.494413,
    },
    {
        store_latitdue: -0.3499397,
        store_longitude: -79.25027,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.25835445,
        store_longitude: -79.1456756,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2509122,
        store_longitude: -79.161891,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.296331,
        store_longitude: -79.216439,
    },
    {
        store_latitdue: -0.289635,
        store_longitude: -79.2061667,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26732,
        store_longitude: -79.1574,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.266768694,
        store_longitude: -79.16042328,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.270689,
        store_longitude: -79.1829,
    },
    {
        store_latitdue: -0.264603,
        store_longitude: -79.146,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.294949,
        store_longitude: -79.2157644,
    },
    {
        store_latitdue: -0.323054,
        store_longitude: -79.2328,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3820595,
        store_longitude: -79.2750215,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -1.0192137,
        store_longitude: -79.464748,
    },
    {
        store_latitdue: -0.3208477,
        store_longitude: -79.167478,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.256133,
        store_longitude: -79.163787,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2540043,
        store_longitude: -79.1641996,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2508694,
        store_longitude: -79.1620946,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1413716,
        store_longitude: -78.7672175,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: 0.1431553,
        store_longitude: -78.7660838,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: 0.022837,
        store_longitude: -78.8949,
    },

    {
        store_latitdue: -0.2733424,
        store_longitude: -79.1813375,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.267014,
        store_longitude: -79.142527,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2638485,
        store_longitude: -79.1426777,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2657311,
        store_longitude: -79.1533245,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.264903,
        store_longitude: -79.1418,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.348329,
        store_longitude: -79.2494,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.227153,
        store_longitude: -79.185,
    },
    {
        store_latitdue: -0.26977012,
        store_longitude: -79.1895711,
    },
    {
        store_latitdue: -0.2970342,
        store_longitude: -79.2003373,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.28070796,
        store_longitude: -79.1626739,
    },
    {
        store_latitdue: -0.2628489,
        store_longitude: -79.1652645,
    },
    {
        store_latitdue: -0.2570871,
        store_longitude: -79.1524591,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2616943,
        store_longitude: -79.1622532,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4143397,
        store_longitude: -78.8005189,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: 0.0697765,
        store_longitude: -78.6947908,
    },
    {
        store_latitdue: 0.142197,
        store_longitude: -78.7643,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: -0.2797744,
        store_longitude: -79.1834883,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2663212,
        store_longitude: -79.1577229,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2645983,
        store_longitude: -79.1462095,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.26619,
        store_longitude: -79.15812,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253025,
        store_longitude: -79.1706231,
    },
    {
        store_latitdue: -0.27071943,
        store_longitude: -79.1757524,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.268778,
        store_longitude: -79.178304,
    },
    {
        store_latitdue: -0.266260039,
        store_longitude: -79.15952644,
    },
    {
        store_latitdue: -0.296758,
        store_longitude: -79.2005,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3218885,
        store_longitude: -79.1650259,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2611197,
        store_longitude: -79.1617859,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2617772,
        store_longitude: -79.1622709,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.262143,
        store_longitude: -79.1506,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0159118,
        store_longitude: -78.9003,
    },
    {
        store_latitdue: -0.2751042,
        store_longitude: -79.1832787,
    },
    {
        store_latitdue: -0.2647633,
        store_longitude: -79.1749936,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2630308,
        store_longitude: -79.1707283,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2574342,
        store_longitude: -79.1748886,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3210243,
        store_longitude: -79.1656828,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1417253,
        store_longitude: -78.7672509,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: 0.119089,
        store_longitude: -78.779257,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: -0.2373606,
        store_longitude: -79.1657294,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.407841,
        store_longitude: -79.3068154,
    },
    {
        store_latitdue: -0.5862991,
        store_longitude: -79.5103393,
    },
    {
        store_latitdue: -0.287631166,
        store_longitude: -79.18108966,
    },
    {
        store_latitdue: -0.261903064,
        store_longitude: -79.16191527,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258146,
        store_longitude: -79.161254,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.252971,
        store_longitude: -79.1502,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2532164,
        store_longitude: -79.1505309,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2530523,
        store_longitude: -79.1504763,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2613945,
        store_longitude: -79.139307,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1211132,
        store_longitude: -79.2539989,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0848733,
        store_longitude: -78.7366055,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.1413039,
        store_longitude: -78.7677613,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: -0.2632199,
        store_longitude: -79.1762005,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2692194,
        store_longitude: -79.1760737,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.267545,
        store_longitude: -79.1725998,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.266683,
        store_longitude: -79.16127,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2820655,
        store_longitude: -79.1918724,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.36107,
        store_longitude: -79.39282,
    },
    {
        store_latitdue: 0.086382,
        store_longitude: -79.0502802,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2653285,
        store_longitude: -79.1718278,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.28533,
        store_longitude: -79.20193,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0986324,
        store_longitude: -79.0497,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2647873,
        store_longitude: -79.1808227,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4054264816,
        store_longitude: -79.3024596516,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.2652858,
        store_longitude: -79.1179254,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.140672,
        store_longitude: -78.769342,
        neighborhood: 'PACTO',
    },
    {
        store_latitdue: -0.2433863,
        store_longitude: -79.1981929,
    },
    {
        store_latitdue: -0.2570852,
        store_longitude: -79.1651033,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2754457,
        store_longitude: -79.1824312,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2888312,
        store_longitude: -79.1854409,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.280105,
        store_longitude: -79.183632,
    },
    {
        store_latitdue: -0.2927923,
        store_longitude: -79.1806161,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0844515,
        store_longitude: -79.0500038,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.253292,
        store_longitude: -79.16257,
    },
    {
        store_latitdue: 0.1222443,
        store_longitude: -79.2529654,
    },
    {
        store_latitdue: -0.3383494,
        store_longitude: -79.2357949,
    },
    {
        store_latitdue: -0.2491219,
        store_longitude: -79.1592577,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.254538,
        store_longitude: -79.1613,
    },
    {
        store_latitdue: 0.0433841,
        store_longitude: -79.3640615,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.0181224,
        store_longitude: -78.8983878,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.0242798,
        store_longitude: -78.8962479,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.085683,
        store_longitude: -79.053561,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0840626,
        store_longitude: -79.0494266,
    },
    {
        store_latitdue: -0.4077369,
        store_longitude: -79.3065775,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.254759,
        store_longitude: -79.157693,
    },
    {
        store_latitdue: -1.64212,
        store_longitude: -78.6753,
    },
    {
        store_latitdue: 0.122432,
        store_longitude: -79.235801,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.668941,
        store_longitude: -79.610713,
    },
    {
        store_latitdue: -0.2872543,
        store_longitude: -79.2086929,
    },
    {
        store_latitdue: 0.1223066,
        store_longitude: -79.2531565,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.120873,
        store_longitude: -79.2542,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.5746613,
        store_longitude: -79.3699288,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.2591011,
        store_longitude: -79.1429085,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2599628,
        store_longitude: -79.1276602,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0852671,
        store_longitude: -79.0493,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },

    {
        store_latitdue: 0.0233602,
        store_longitude: -78.8936052,
    },
    {
        store_latitdue: -0.259595,
        store_longitude: -79.173627,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2726932,
        store_longitude: -79.1830892,
    },
    {
        store_latitdue: 0.0859869,
        store_longitude: -79.0511094,
    },

    {
        store_latitdue: 0.1219465,
        store_longitude: -79.2542432,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2532457,
        store_longitude: -79.1517232,
    },
    {
        store_latitdue: -0.322437,
        store_longitude: -78.997106,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: 0.0765164,
        store_longitude: -78.9992,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.263500932,
        store_longitude: -79.18384428,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4053855,
        store_longitude: -79.302778,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.4150349,
        store_longitude: -79.2764719,
    },
    {
        store_latitdue: -0.252743,
        store_longitude: -79.167566,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.022859,
        store_longitude: -78.893229,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.270499,
        store_longitude: -79.1829,
    },
    {
        store_latitdue: -0.6730518,
        store_longitude: -79.6106649,
    },
    {
        store_latitdue: -0.249317,
        store_longitude: -79.180222,
    },
    {
        store_latitdue: 0.0157285,
        store_longitude: -78.9005644,
    },
    {
        store_latitdue: 0.0230421,
        store_longitude: -78.893,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.023324,
        store_longitude: -78.892136,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: 0.023133,
        store_longitude: -78.892715,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },
    {
        store_latitdue: -0.258517,
        store_longitude: -79.171517,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1469711,
        store_longitude: -78.7837578,
        neighborhood: 'PACTO',
    },

    {
        store_latitdue: -0.256935,
        store_longitude: -79.1782983,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.229368,
        store_longitude: -79.1674,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2807819719,
        store_longitude: -79.1904377974,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5750300494,
        store_longitude: -79.3699600671,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.3225014,
        store_longitude: -78.9952391,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: 0.0230726,
        store_longitude: -78.8905428,
    },
    {
        store_latitdue: 0.170895,
        store_longitude: -78.670696,
        neighborhood: 'NANEGAL',
    },

    {
        store_latitdue: -0.2514846,
        store_longitude: -79.169675,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.5854973,
        store_longitude: -79.5116187,
    },
    {
        store_latitdue: -0.2706066,
        store_longitude: -79.0786691,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.32716026,
        store_longitude: -79.2412304,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.5837073,
        store_longitude: -79.5062364,
    },

    {
        store_latitdue: -0.235245,
        store_longitude: -79.1704,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0173044,
        store_longitude: -78.89893,
    },
    {
        store_latitdue: 0.0231672,
        store_longitude: -78.8955497,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },

    {
        store_latitdue: -0.2565462,
        store_longitude: -79.1616631,
    },
    {
        store_latitdue: 0.023089,
        store_longitude: -78.8971,
    },
    {
        store_latitdue: 0.0836785,
        store_longitude: -79.0463569,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.266829,
        store_longitude: -79.1828,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2685731,
        store_longitude: -79.190192,
    },
    {
        store_latitdue: -0.2386738,
        store_longitude: -79.1945018,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2716237,
        store_longitude: -79.1902983,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4875939,
        store_longitude: -79.3459916,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: 0.0621299,
        store_longitude: -78.6817462,
    },

    {
        store_latitdue: -0.257881,
        store_longitude: -79.157328,
    },
    {
        store_latitdue: -0.2355187,
        store_longitude: -79.1688744,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.261964,
        store_longitude: -79.1584843,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4146346,
        store_longitude: -78.8003397,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: -0.2493789,
        store_longitude: -79.2017004,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.0290427,
        store_longitude: -78.7519692,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.2619448,
        store_longitude: -79.1729712,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0661417,
        store_longitude: -78.6922353,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.06638,
        store_longitude: -78.692235,
    },
    {
        store_latitdue: -2.2656448,
        store_longitude: -79.7130419,
        neighborhood: 'ELOY ALFARO',
    },
    {
        store_latitdue: 0.0908732,
        store_longitude: -78.7167761,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.0854918,
        store_longitude: -78.7364283,
        neighborhood: 'NANEGALITO',
    },
    {
        store_latitdue: 0.0169356,
        store_longitude: -78.899165,
    },
    {
        store_latitdue: 0.075006,
        store_longitude: -78.995049,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.076346,
        store_longitude: -79.01492,
    },
    {
        store_latitdue: 0.0793142,
        store_longitude: -79.0336706,
    },
    {
        store_latitdue: 0.1580099,
        store_longitude: -79.0678303,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.1571534,
        store_longitude: -79.0676108,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.160926,
        store_longitude: -79.071501,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.0805637,
        store_longitude: -79.0482271,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.2590296,
        store_longitude: -79.1545135,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0526129,
        store_longitude: -79.2529905,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.123779,
        store_longitude: -79.2370957,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.1237967,
        store_longitude: -79.2369246,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.123392,
        store_longitude: -79.238218,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.041746,
        store_longitude: -79.364909,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.1190462,
        store_longitude: -79.2516644,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.11975,
        store_longitude: -79.2513,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.05116,
        store_longitude: -79.254622,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.120721,
        store_longitude: -79.2536,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.291947,
        store_longitude: -79.1823,
    },
    {
        store_latitdue: -0.260858,
        store_longitude: -79.157478,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2583137,
        store_longitude: -79.1475229,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25411,
        store_longitude: -79.15208,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4233124,
        store_longitude: -79.3189921,
        neighborhood: 'LUZ DE AMÉRICA',
    },
    {
        store_latitdue: -0.4075526,
        store_longitude: -79.3062854,
        neighborhood: 'LUZ DE AMÉRICA',
    },

    {
        store_latitdue: -0.406897,
        store_longitude: -79.3051,
        neighborhood: 'LUZ DE AMÉRICA',
    },

    {
        store_latitdue: -0.670323,
        store_longitude: -79.6102892,
    },
    {
        store_latitdue: -0.26408,
        store_longitude: -79.171275,
    },
    {
        store_latitdue: -0.2712789,
        store_longitude: -79.192168,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.278231,
        store_longitude: -79.192676,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3223692,
        store_longitude: -78.9947137,
        neighborhood: 'ALLURIQUÍN',
    },
    {
        store_latitdue: -0.2711378,
        store_longitude: -79.193162,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2582583,
        store_longitude: -79.1779917,
    },
    {
        store_latitdue: -0.2587305,
        store_longitude: -79.1734745,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2674895,
        store_longitude: -79.164694,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.266735,
        store_longitude: -79.202135,
    },
    {
        store_latitdue: -0.2602509,
        store_longitude: -79.1565595,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3013728,
        store_longitude: -79.2748376,
    },
    {
        store_latitdue: 0.0389077,
        store_longitude: -79.364974,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: 0.101372,
        store_longitude: -79.1371,
    },
    {
        store_latitdue: 0.0890222,
        store_longitude: -79.055373,
    },
    {
        store_latitdue: 0.101133,
        store_longitude: -79.1369,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.1030777,
        store_longitude: -79.1067336,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.102585,
        store_longitude: -79.1063,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.28133,
        store_longitude: -79.1995,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6797142,
        store_longitude: -79.6131288,
    },
    {
        store_latitdue: -0.2631274675,
        store_longitude: -79.1999727488,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.291523,
        store_longitude: -79.1925,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2873445,
        store_longitude: -79.1979731,
    },
    {
        store_latitdue: -0.2559,
        store_longitude: -79.18588,
    },
    {
        store_latitdue: -3.99903,
        store_longitude: -79.2066,
    },
    {
        store_latitdue: -0.26263449,
        store_longitude: -79.1864061,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2321472,
        store_longitude: -79.173925,
    },
    {
        store_latitdue: -0.267217,
        store_longitude: -79.1746,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.272301,
        store_longitude: -79.1651229,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: 0.0774439,
        store_longitude: -79.0006323,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: 0.159509,
        store_longitude: -79.069554,
        neighborhood: 'PEDRO VICENTE MALDONADO',
    },
    {
        store_latitdue: -0.337305,
        store_longitude: -78.5641,
    },
    {
        store_latitdue: 0.0854886,
        store_longitude: -79.0481491,
    },
    {
        store_latitdue: -0.2623612,
        store_longitude: -79.1552471,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.265659,
        store_longitude: -79.1629,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2492,
        store_longitude: -79.160065,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.247509,
        store_longitude: -79.1513013,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.256915,
        store_longitude: -79.1234712,
    },
    {
        store_latitdue: -0.2531464,
        store_longitude: -79.1502006,
    },
    {
        store_latitdue: -0.2544083,
        store_longitude: -79.1527056,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2598228,
        store_longitude: -79.1233218,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2624144,
        store_longitude: -79.115739,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2783335,
        store_longitude: -79.4626428,
    },
    {
        store_latitdue: -0.289444,
        store_longitude: -79.2061992,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2619917,
        store_longitude: -79.1726322,
    },

    {
        store_latitdue: -0.2737645,
        store_longitude: -79.182854,
    },
    {
        store_latitdue: -0.2817815,
        store_longitude: -79.1828915,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.238989,
        store_longitude: -79.170556,
    },
    {
        store_latitdue: 0.121866,
        store_longitude: -79.2547,
    },

    {
        store_latitdue: -0.2935147,
        store_longitude: -79.1820244,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.6038655,
        store_longitude: -79.5902944,
    },
    {
        store_latitdue: -0.2837809,
        store_longitude: -79.17673,
    },
    {
        store_latitdue: -0.266727,
        store_longitude: -79.180693,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2650414,
        store_longitude: -79.1756755,
    },
    {
        store_latitdue: 0.0867965,
        store_longitude: -79.05,
    },
    {
        store_latitdue: -0.2549475,
        store_longitude: -79.1528981,
    },
    {
        store_latitdue: -0.253274,
        store_longitude: -79.1509,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253448,
        store_longitude: -79.1504,
    },
    {
        store_latitdue: 0.1224434,
        store_longitude: -79.2396358,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.280463,
        store_longitude: -79.1794,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1384292,
        store_longitude: -78.6763221,
        neighborhood: 'NANEGAL',
    },
    {
        store_latitdue: 0.138757,
        store_longitude: -78.676517,
        neighborhood: 'NANEGAL',
    },

    {
        store_latitdue: -0.2697015,
        store_longitude: -79.1583878,
    },
    {
        store_latitdue: -0.2540371,
        store_longitude: -79.1244485,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2665718859,
        store_longitude: -79.1612585512,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2530484,
        store_longitude: -79.1525515,
    },
    {
        store_latitdue: -0.2942547,
        store_longitude: -79.1839327,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.289358,
        store_longitude: -79.169,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2293157,
        store_longitude: -79.1680825,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2649275,
        store_longitude: -79.182703,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2645,
        store_longitude: -79.179689,
    },
    {
        store_latitdue: -0.0542222,
        store_longitude: -78.7753585,
        neighborhood: 'MINDO',
    },
    {
        store_latitdue: -0.2683057,
        store_longitude: -79.1612588,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2916186,
        store_longitude: -79.1827006,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0006701,
        store_longitude: -79.3940278,
    },
    {
        store_latitdue: -0.278879,
        store_longitude: -79.2047926,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.238681,
        store_longitude: -79.195262,
    },
    {
        store_latitdue: -0.274174,
        store_longitude: -79.4642,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.134947,
        store_longitude: -79.4049,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.26474797,
        store_longitude: -79.1607894,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2452307,
        store_longitude: -79.1765425,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2510083,
        store_longitude: -79.1684081,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2735403,
        store_longitude: -79.464591,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.117789,
        store_longitude: -79.258702,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: 0.0518682,
        store_longitude: -79.2534139,
    },
    {
        store_latitdue: 0.012877,
        store_longitude: -79.3876,
    },
    {
        store_latitdue: 0.0168742,
        store_longitude: -79.3978548,
    },
    {
        store_latitdue: 0.0189861,
        store_longitude: -79.3862089,
    },
    {
        store_latitdue: 0.01718725,
        store_longitude: -79.38268058,
    },
    {
        store_latitdue: 0.0163083,
        store_longitude: -79.3894438,
    },
    {
        store_latitdue: 0.041338007,
        store_longitude: -79.4033279,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.2727031,
        store_longitude: -79.4610949,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.0157926,
        store_longitude: -79.400183,
    },
    {
        store_latitdue: 0.2258733,
        store_longitude: -79.4130018,
    },
    {
        store_latitdue: 0.2244743,
        store_longitude: -79.4126642,
    },
    {
        store_latitdue: 0.2549179,
        store_longitude: -79.4375463,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.1804176,
        store_longitude: -79.3900381,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.229142,
        store_longitude: -79.1688,
    },
    {
        store_latitdue: 0.0059908,
        store_longitude: -79.3862488,
    },

    {
        store_latitdue: -0.234271,
        store_longitude: -79.1713,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2350901,
        store_longitude: -79.170505,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.236847,
        store_longitude: -79.1689,
    },

    {
        store_latitdue: -0.2545535,
        store_longitude: -79.2081909,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2584062,
        store_longitude: -79.2104555,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3875436,
        store_longitude: -79.3719069,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -0.2754627,
        store_longitude: -79.2041136,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.278291,
        store_longitude: -79.4649,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.277913,
        store_longitude: -79.4646,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2765450692,
        store_longitude: -79.4653441384,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.243065,
        store_longitude: -79.1684,
    },

    {
        store_latitdue: -0.2569721,
        store_longitude: -79.1506509,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2276993,
        store_longitude: -79.1810015,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2531299,
        store_longitude: -79.171715,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: 0.0152538,
        store_longitude: -79.3803852,
    },
    {
        store_latitdue: 0.016884,
        store_longitude: -79.3916,
    },
    {
        store_latitdue: 0.0160004,
        store_longitude: -79.3828184,
    },
    {
        store_latitdue: 0.3232897,
        store_longitude: -79.2124396,
    },
    {
        store_latitdue: 0.0183281,
        store_longitude: -79.3877593,
    },
    {
        store_latitdue: 0.2980082,
        store_longitude: -79.27429,
    },
    {
        store_latitdue: 0.0194719,
        store_longitude: -79.3740718,
    },
    {
        store_latitdue: 0.0030823,
        store_longitude: -79.3992574,
    },

    {
        store_latitdue: 0.183843,
        store_longitude: -79.2421288,
    },
    {
        store_latitdue: -0.2208068,
        store_longitude: -79.1804383,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2131438,
        store_longitude: -79.1959963,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.088187,
        store_longitude: -79.279357,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.2259731,
        store_longitude: -79.1750611,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2279834,
        store_longitude: -79.1731525,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0885014,
        store_longitude: -79.2777,
    },
    {
        store_latitdue: -0.2291003,
        store_longitude: -79.1700408,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.234301,
        store_longitude: -79.1692913,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1076006,
        store_longitude: -79.2798161,
    },
    {
        store_latitdue: 0.047773,
        store_longitude: -79.183356,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2367289,
        store_longitude: -79.1692477,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2493507,
        store_longitude: -79.1976114,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2723144,
        store_longitude: -79.4416563,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.4922956,
        store_longitude: -79.5164963,
    },
    {
        store_latitdue: -0.2783828,
        store_longitude: -79.4485932,
    },
    {
        store_latitdue: 0.0887085,
        store_longitude: -79.0558,
    },
    {
        store_latitdue: -0.2743861,
        store_longitude: -79.467018,
        neighborhood: 'EL CARMEN',
    },

    {
        store_latitdue: -0.2549314,
        store_longitude: -79.1810171,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3052601,
        store_longitude: -79.2731659,
    },
    {
        store_latitdue: -0.6408418,
        store_longitude: -79.2300606,
        neighborhood: 'SANTA MARÍA DEL TOACHI',
    },
    {
        store_latitdue: 0.133403,
        store_longitude: -79.3848064,
    },
    {
        store_latitdue: 0.258799,
        store_longitude: -79.2601,
        neighborhood: 'PUERTO QUITO',
    },

    {
        store_latitdue: -0.6205793,
        store_longitude: -79.4263842,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: -0.218648,
        store_longitude: -79.1927894,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0874872,
        store_longitude: -79.2799757,
    },
    {
        store_latitdue: -0.109523,
        store_longitude: -79.616553,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.02792,
        store_longitude: -79.97038,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -0.0256353,
        store_longitude: -79.9651909,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -0.02834,
        store_longitude: -79.96884,
    },
    {
        store_latitdue: -0.0717299,
        store_longitude: -79.3900116,
    },
    {
        store_latitdue: -0.2728293,
        store_longitude: -79.4636849,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2811714,
        store_longitude: -79.4674425,
    },
    {
        store_latitdue: -0.2679365,
        store_longitude: -79.2060088,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2316508,
        store_longitude: -79.1748553,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.246076,
        store_longitude: -79.170796,
    },

    {
        store_latitdue: 0.0111282,
        store_longitude: -79.4024494,
    },
    {
        store_latitdue: 0.2852497,
        store_longitude: -79.3119684,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.133744,
        store_longitude: -79.33893,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.133512022,
        store_longitude: -79.33917896,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.1649237,
        store_longitude: -79.279766,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0858827,
        store_longitude: -79.2813,
    },

    {
        store_latitdue: -0.2680696,
        store_longitude: -79.1201962,
    },
    {
        store_latitdue: -0.2698908,
        store_longitude: -79.1202981,
    },
    {
        store_latitdue: -0.289568,
        store_longitude: -79.184094,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.408329,
        store_longitude: -79.307489,
    },

    {
        store_latitdue: -0.5701565,
        store_longitude: -79.369443,
    },

    {
        store_latitdue: -0.0846835,
        store_longitude: -79.2800369,
    },
    {
        store_latitdue: -0.0041684,
        store_longitude: -79.3950617,
    },

    {
        store_latitdue: -0.219846,
        store_longitude: -79.1958734,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2509452,
        store_longitude: -79.1715114,
    },
    {
        store_latitdue: -0.253284813,
        store_longitude: -79.12129789,
    },
    {
        store_latitdue: -0.546864903,
        store_longitude: -79.37208005,
        neighborhood: 'PATRICIA PILAR',
    },
    {
        store_latitdue: 0.2874959,
        store_longitude: -79.3132051,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.6377481,
        store_longitude: -79.2284632,
        neighborhood: 'SANTA MARÍA DEL TOACHI',
    },
    {
        store_latitdue: 0.314359,
        store_longitude: -79.4235,
    },
    {
        store_latitdue: 0.0076728,
        store_longitude: -79.3908607,
    },
    {
        store_latitdue: -0.0720629,
        store_longitude: -79.3925909,
    },
    {
        store_latitdue: -0.270966,
        store_longitude: -79.1917,
    },
    {
        store_latitdue: -0.0873614,
        store_longitude: -79.2801292,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.251141,
        store_longitude: -79.1806356,
    },
    {
        store_latitdue: -0.2250597,
        store_longitude: -79.1975748,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.963054,
        store_longitude: -79.6603,
    },
    {
        store_latitdue: -0.250621,
        store_longitude: -79.172381,
    },
    {
        store_latitdue: -0.224075,
        store_longitude: -79.16349,
    },
    {
        store_latitdue: -0.24138517,
        store_longitude: -79.1890563,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2605972,
        store_longitude: -79.1723772,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2630323,
        store_longitude: -79.1660005,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2630815,
        store_longitude: -79.1666606,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2440495,
        store_longitude: -79.1747339,
    },
    {
        store_latitdue: -0.2449702,
        store_longitude: -79.1828401,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.0729404,
        store_longitude: -80.1447752,
        neighborhood: 'JAMA',
    },
    {
        store_latitdue: -0.26968393,
        store_longitude: -79.1913054,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: 0.0090733,
        store_longitude: -79.9040367,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.0088892,
        store_longitude: -79.9035133,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: 0.315325,
        store_longitude: -79.466437,
    },
    {
        store_latitdue: -0.2596523,
        store_longitude: -79.2156669,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2613452,
        store_longitude: -79.2119786,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2612368,
        store_longitude: -79.2122128,
    },
    {
        store_latitdue: -0.2540634,
        store_longitude: -79.1786199,
    },
    {
        store_latitdue: -0.239422,
        store_longitude: -79.169998,
    },

    {
        store_latitdue: 0.0049559,
        store_longitude: -79.3974146,
    },

    {
        store_latitdue: -0.341271,
        store_longitude: -79.1733,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2897689,
        store_longitude: -79.217013,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258253,
        store_longitude: -79.2146039,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25952,
        store_longitude: -79.2146,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2602055,
        store_longitude: -79.2122236,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2610983,
        store_longitude: -79.2113217,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.274375,
        store_longitude: -79.463609,
    },
    {
        store_latitdue: -0.2755322,
        store_longitude: -79.4637707,
    },
    {
        store_latitdue: -0.2414292,
        store_longitude: -79.1715084,
    },
    {
        store_latitdue: -0.2545658,
        store_longitude: -79.1776319,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: 0.417907,
        store_longitude: -79.247082,
    },
    {
        store_latitdue: 0.416574,
        store_longitude: -79.248127,
        neighborhood: 'MALIMPIA',
    },
    {
        store_latitdue: -0.2805294,
        store_longitude: -79.4670019,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2256486,
        store_longitude: -79.1842643,
    },

    {
        store_latitdue: 0.0051758,
        store_longitude: -79.3906455,
    },
    {
        store_latitdue: -1.044,
        store_longitude: -78.5918,
    },
    {
        store_latitdue: -0.2498204,
        store_longitude: -79.4925252,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.259401,
        store_longitude: -79.18924,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.220624,
        store_longitude: -79.484931,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.9287094,
        store_longitude: -79.6717518,
    },
    {
        store_latitdue: -0.249833,
        store_longitude: -79.167478,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2512328,
        store_longitude: -79.1805981,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.071865,
        store_longitude: -80.0465,
    },
    {
        store_latitdue: -0.229339,
        store_longitude: -79.182352,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2587285,
        store_longitude: -79.1583061,
    },
    {
        store_latitdue: -0.1466414,
        store_longitude: -79.5661523,
        neighborhood: 'SAN PEDRO DE SUMA',
    },

    {
        store_latitdue: -0.2296919,
        store_longitude: -79.1725765,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2331474,
        store_longitude: -79.186658,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2341521,
        store_longitude: -79.1788546,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.098875,
        store_longitude: -79.8153396,
        neighborhood: 'CHIBUNGA',
    },
    {
        store_latitdue: -0.291792,
        store_longitude: -79.215824,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2707518,
        store_longitude: -79.4366499,
    },
    {
        store_latitdue: -0.242599,
        store_longitude: -79.1993,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: 0.0179308,
        store_longitude: -79.3982309,
    },
    {
        store_latitdue: -0.2543151,
        store_longitude: -79.2092213,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.253383,
        store_longitude: -79.2176128,
    },
    {
        store_latitdue: -0.2581085,
        store_longitude: -79.3860907,
    },
    {
        store_latitdue: -0.236967,
        store_longitude: -79.1694,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2224766,
        store_longitude: -79.1567657,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2493501,
        store_longitude: -79.1849727,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2741783,
        store_longitude: -79.2041688,
    },
    {
        store_latitdue: -0.2430046,
        store_longitude: -79.174081,
    },
    {
        store_latitdue: -0.224932,
        store_longitude: -79.1635,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2202977,
        store_longitude: -79.1708092,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0403433,
        store_longitude: -79.4071467,
    },
    {
        store_latitdue: -0.05538,
        store_longitude: -79.4434,
    },
    {
        store_latitdue: -0.2430439,
        store_longitude: -79.1998639,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.69714,
        store_longitude: -80.09523,
    },
    {
        store_latitdue: -0.2073207,
        store_longitude: -79.1801919,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.224472,
        store_longitude: -79.1687,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.891156,
        store_longitude: -79.7116,
    },

    {
        store_latitdue: 0.0106824,
        store_longitude: -79.3996243,
    },
    {
        store_latitdue: -0.0563629253,
        store_longitude: -79.4452151656,
    },
    {
        store_latitdue: -0.2622869,
        store_longitude: -79.2090521,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2678577,
        store_longitude: -79.2015974,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.228885225,
        store_longitude: -79.19862924,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.291293,
        store_longitude: -78.4812,
    },
    {
        store_latitdue: 0.0186648,
        store_longitude: -79.3928266,
    },
    {
        store_latitdue: 0.1366524,
        store_longitude: -79.4035821,
        neighborhood: 'LA UNIÓN',
    },

    {
        store_latitdue: -0.2712268,
        store_longitude: -79.5743327,
        neighborhood: 'WILFRIDO LOOR MOREIRA',
    },
    {
        store_latitdue: -0.209474,
        store_longitude: -79.1780106,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.8982841,
        store_longitude: -79.4823966,
    },

    {
        store_latitdue: -0.25323,
        store_longitude: -79.204379,
    },
    {
        store_latitdue: -0.3049176,
        store_longitude: -79.1530578,
    },
    {
        store_latitdue: -0.2316993,
        store_longitude: -79.1714905,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2208089,
        store_longitude: -79.1584042,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.21454885,
        store_longitude: -79.17362675,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2231887,
        store_longitude: -79.1658272,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2690514,
        store_longitude: -79.1955667,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0080149,
        store_longitude: -79.3925577,
    },
    {
        store_latitdue: 1.2796,
        store_longitude: -78.829,
    },
    {
        store_latitdue: 0.0058546,
        store_longitude: -79.3872659,
    },

    {
        store_latitdue: 0.3231104,
        store_longitude: -79.3664964,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.4194754,
        store_longitude: -79.2457476,
    },
    {
        store_latitdue: -0.2428412,
        store_longitude: -79.1751084,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2388567,
        store_longitude: -79.1743501,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.229164,
        store_longitude: -79.1688,
    },
    {
        store_latitdue: 0.2989001,
        store_longitude: -79.2761578,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2876981,
        store_longitude: -79.6332696,
        neighborhood: 'WILFRIDO LOOR MOREIRA',
    },
    {
        store_latitdue: 0.2997764,
        store_longitude: -79.2743,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.3139132,
        store_longitude: -79.2553975,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3142322,
        store_longitude: -79.3162224,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -0.3139702,
        store_longitude: -79.316093,
    },
    {
        store_latitdue: -0.3531513,
        store_longitude: -79.732417,
        neighborhood: 'WILFRIDO LOOR MOREIRA',
    },
    {
        store_latitdue: -0.275524,
        store_longitude: -79.572419,
        neighborhood: 'WILFRIDO LOOR MOREIRA',
    },
    {
        store_latitdue: -0.2511216,
        store_longitude: -79.489319,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2245557,
        store_longitude: -79.1962474,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.24978,
        store_longitude: -79.4887,
    },
    {
        store_latitdue: -0.1399927,
        store_longitude: -78.4999082,
    },
    {
        store_latitdue: -0.24995,
        store_longitude: -79.4889433,
    },
    {
        store_latitdue: -0.2217965,
        store_longitude: -79.1963781,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.3153903,
        store_longitude: -79.213136,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.38659,
        store_longitude: -79.3722,
    },
    {
        store_latitdue: 0.417167,
        store_longitude: -79.197508,
        neighborhood: 'MALIMPIA',
    },
    {
        store_latitdue: -0.2763339,
        store_longitude: -79.5726099,
        neighborhood: 'WILFRIDO LOOR MOREIRA',
    },
    {
        store_latitdue: -0.2268783,
        store_longitude: -79.1977,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.226727,
        store_longitude: -79.1905,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.29977563,
        store_longitude: -79.2220764,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.33622848,
        store_longitude: -79.7574844,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.2256729,
        store_longitude: -79.1908619,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.341296,
        store_longitude: -79.323742,
        neighborhood: 'PUERTO LIMÓN',
    },
    {
        store_latitdue: -0.2368950427,
        store_longitude: -79.1681426391,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.325382,
        store_longitude: -79.2021774,
    },
    {
        store_latitdue: 0.3015183,
        store_longitude: -79.27354,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.388692,
        store_longitude: -79.373403,
    },
    {
        store_latitdue: -0.2486777,
        store_longitude: -79.4843127,
        neighborhood: 'EL CARMEN',
    },

    {
        store_latitdue: -0.335985,
        store_longitude: -79.757443,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: 0.259123,
        store_longitude: -79.2602,
        neighborhood: 'PUERTO QUITO',
    },
    {
        store_latitdue: -0.2373424,
        store_longitude: -79.185622,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2738195,
        store_longitude: -79.4572601,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.268387,
        store_longitude: -79.4483271,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2902232,
        store_longitude: -79.2138313,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2888897,
        store_longitude: -79.2171674,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2817281,
        store_longitude: -79.4563468,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.1211283,
        store_longitude: -78.463155,
    },
    {
        store_latitdue: -0.2358481,
        store_longitude: -79.1818182,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.281456,
        store_longitude: -79.462024,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2356066,
        store_longitude: -79.1843736,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.239448,
        store_longitude: -79.185115,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.4782463,
        store_longitude: -79.560033,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2385714,
        store_longitude: -79.1665518,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.388482,
        store_longitude: -79.5419,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2385805,
        store_longitude: -79.1666804,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.00172,
        store_longitude: -79.862773,
    },

    {
        store_latitdue: -0.177426,
        store_longitude: -79.5408,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.3557581,
        store_longitude: -79.5312316,
    },
    {
        store_latitdue: -0.2824112,
        store_longitude: -79.4875728,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2366161,
        store_longitude: -79.1656914,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0085913,
        store_longitude: -79.9029046,
        neighborhood: 'PEDERNALES',
    },
    {
        store_latitdue: -0.2373676,
        store_longitude: -79.1674484,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.21803,
        store_longitude: -79.54137,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.1453773,
        store_longitude: -79.5668173,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.2180328,
        store_longitude: -79.5413948,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.2175731,
        store_longitude: -79.5410724,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.5868918,
        store_longitude: -79.5122035,
    },
    {
        store_latitdue: -0.238227,
        store_longitude: -79.164916,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1451128,
        store_longitude: -79.5670518,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.238524,
        store_longitude: -79.1588396,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.004734098,
        store_longitude: -79.8904065,
    },
    {
        store_latitdue: -0.253481,
        store_longitude: -79.167785,
    },
    {
        store_latitdue: -0.246994,
        store_longitude: -79.151843,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.0197542,
        store_longitude: -79.6056796,
        neighborhood: 'CHIBUNGA',
    },
    {
        store_latitdue: -0.2476186,
        store_longitude: -79.172954,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.025929466,
        store_longitude: -79.96777617,
        neighborhood: 'ATAHUALPA',
    },
    {
        store_latitdue: -0.243113,
        store_longitude: -79.169016,
    },
    {
        store_latitdue: -0.243111,
        store_longitude: -79.168351,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: 0.0136608,
        store_longitude: -79.9119206,
    },
    {
        store_latitdue: -0.247687,
        store_longitude: -79.169168,
    },
    {
        store_latitdue: -0.108873915,
        store_longitude: -79.27895184,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.2704956,
        store_longitude: -79.4640168,
    },
    {
        store_latitdue: -0.265864,
        store_longitude: -79.4628058,
    },
    {
        store_latitdue: -0.2279467,
        store_longitude: -79.1631418,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0023972,
        store_longitude: -79.3929337,
    },
    {
        store_latitdue: -0.0294186,
        store_longitude: -79.4957152,
    },
    {
        store_latitdue: -0.262063,
        store_longitude: -79.470691,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.0889958,
        store_longitude: -79.2778856,
        neighborhood: 'VALLE HERMOSO',
    },
    {
        store_latitdue: -0.0419328,
        store_longitude: -79.4874461,
    },
    {
        store_latitdue: -0.2724095,
        store_longitude: -79.4540628,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.27253286,
        store_longitude: -79.4509923,
        neighborhood: 'EL CARMEN',
    },

    {
        store_latitdue: -0.221586,
        store_longitude: -79.157809,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.697153,
        store_longitude: -80.1171,
        neighborhood: 'CHONE',
    },
    {
        store_latitdue: -0.22301418,
        store_longitude: -79.1577192339,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2201673,
        store_longitude: -79.1588026,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.23682,
        store_longitude: -79.1777,
    },
    {
        store_latitdue: -0.2468534,
        store_longitude: -79.1969988,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.2524389727,
        store_longitude: -79.1967410222,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.237714,
        store_longitude: -79.1775,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2774826,
        store_longitude: -79.4634809,
    },
    {
        store_latitdue: -0.105914,
        store_longitude: -78.4904,
    },
    {
        store_latitdue: -0.2462103,
        store_longitude: -79.1967397,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2728421,
        store_longitude: -79.4636646,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2749658,
        store_longitude: -79.4651053,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.275125,
        store_longitude: -79.4639,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.27508,
        store_longitude: -79.464362,
    },
    {
        store_latitdue: -0.2302618,
        store_longitude: -79.1736232,
    },
    {
        store_latitdue: -0.2348297,
        store_longitude: -79.179165,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.272703,
        store_longitude: -79.46393,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.274587,
        store_longitude: -79.467762,
    },

    {
        store_latitdue: -0.2522911,
        store_longitude: -79.162699,
    },
    {
        store_latitdue: -0.2409014,
        store_longitude: -79.1800096,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.006298224,
        store_longitude: -79.3986324,
    },
    {
        store_latitdue: -0.2718344,
        store_longitude: -79.4443768,
    },
    {
        store_latitdue: -0.2543381,
        store_longitude: -79.1970311,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.142655,
        store_longitude: -79.401,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.242982,
        store_longitude: -79.1845876,
    },
    {
        store_latitdue: -0.474279004,
        store_longitude: -79.55811028,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2385332,
        store_longitude: -79.1750276,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2687488,
        store_longitude: -79.1964986,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.001631,
        store_longitude: -79.395121,
    },
    {
        store_latitdue: -0.0057904,
        store_longitude: -79.3879993,
    },
    {
        store_latitdue: 0.136477,
        store_longitude: -79.4048952,
    },
    {
        store_latitdue: -0.274945,
        store_longitude: -79.450543,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.1356136,
        store_longitude: -79.4049571,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.258121,
        store_longitude: -79.196884,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0353041,
        store_longitude: -79.4068629,
    },
    {
        store_latitdue: 0.0187514,
        store_longitude: -79.4045775,
    },
    {
        store_latitdue: 0.0063526384,
        store_longitude: -79.398415,
    },
    {
        store_latitdue: 0.1427246,
        store_longitude: -79.4009056,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.2441285,
        store_longitude: -79.3388451,
    },
    {
        store_latitdue: -0.237688,
        store_longitude: -79.1775,
    },
    {
        store_latitdue: -0.281402,
        store_longitude: -79.2085,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2663364,
        store_longitude: -79.1985218,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.257567,
        store_longitude: -79.197659,
    },
    {
        store_latitdue: -0.180308,
        store_longitude: -79.3333,
    },
    {
        store_latitdue: -0.148096,
        store_longitude: -79.3922,
    },
    {
        store_latitdue: -0.1475338,
        store_longitude: -79.3946224,
    },

    {
        store_latitdue: -0.250503,
        store_longitude: -79.183032,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1320958,
        store_longitude: -79.3834489,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.25511473,
        store_longitude: -79.1804046,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1578767,
        store_longitude: -79.3551159,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2585748,
        store_longitude: -79.1857186,
    },
    {
        store_latitdue: -0.243938,
        store_longitude: -79.3430533,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2440377,
        store_longitude: -79.3454725,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2579699,
        store_longitude: -79.1829183,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.238892,
        store_longitude: -79.241352,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2561867,
        store_longitude: -79.18899,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.1874078,
        store_longitude: -79.3211722,
        neighborhood: 'SAN JACINTO DEL BÚA',
    },
    {
        store_latitdue: -0.2564145,
        store_longitude: -79.1795933,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1319745,
        store_longitude: -79.3835753,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: 0.0169097,
        store_longitude: -79.3844163,
    },
    {
        store_latitdue: -0.2427916,
        store_longitude: -79.3464102,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.244295,
        store_longitude: -79.1814246,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.1338997,
        store_longitude: -79.4016265,
    },

    {
        store_latitdue: -0.2494173,
        store_longitude: -79.1803702,
    },
    {
        store_latitdue: -0.2491023,
        store_longitude: -79.1772957,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2563993,
        store_longitude: -79.1850012,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2586588,
        store_longitude: -79.1831777,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2570649,
        store_longitude: -79.1857772,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2622688,
        store_longitude: -79.2095648,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2437548,
        store_longitude: -79.1653552,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.251208,
        store_longitude: -79.2133514,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2771802,
        store_longitude: -79.1955443,
    },
    {
        store_latitdue: -0.2253453,
        store_longitude: -79.1943173,
    },
    {
        store_latitdue: -0.2601249,
        store_longitude: -79.2080785,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.258171,
        store_longitude: -79.211042,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2543701,
        store_longitude: -79.2103843,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2565423,
        store_longitude: -79.2173675,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.252856,
        store_longitude: -79.1747,
    },
    {
        store_latitdue: -0.2434368,
        store_longitude: -79.1982394,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },

    {
        store_latitdue: -0.276597,
        store_longitude: -79.203376,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2294871,
        store_longitude: -79.1984654,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.263662,
        store_longitude: -79.1912,
    },
    {
        store_latitdue: -0.276649,
        store_longitude: -79.2032043,
    },
    {
        store_latitdue: 0.019827,
        store_longitude: -79.3964978,
    },

    {
        store_latitdue: -0.2339564,
        store_longitude: -79.1712281,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.0408612,
        store_longitude: -79.4070305,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: -0.2472962,
        store_longitude: -79.1698407,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2653134,
        store_longitude: -79.4826333,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.0593501,
        store_longitude: -79.4436936,
    },
    {
        store_latitdue: -0.25986,
        store_longitude: -79.231449,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.25534,
        store_longitude: -79.481111,
    },
    {
        store_latitdue: -0.25087,
        store_longitude: -79.16844,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2589465,
        store_longitude: -79.4816003,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2646227,
        store_longitude: -79.4811344,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2467298,
        store_longitude: -79.170301,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2399993,
        store_longitude: -79.158604,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.271522,
        store_longitude: -79.2102,
    },
    {
        store_latitdue: -0.353319,
        store_longitude: -79.5671,
    },
    {
        store_latitdue: -0.2520028,
        store_longitude: -79.1684893,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.252813,
        store_longitude: -79.1741,
    },
    {
        store_latitdue: -0.2530098,
        store_longitude: -79.1744564,
    },
    {
        store_latitdue: -0.269867,
        store_longitude: -79.210039,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: 0.143739,
        store_longitude: -79.5619,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.25215,
        store_longitude: -79.48403,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.471698,
        store_longitude: -76.9861,
    },
    {
        store_latitdue: -0.229142,
        store_longitude: -79.1706,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2441774,
        store_longitude: -79.1745393,
    },
    {
        store_latitdue: -0.229052,
        store_longitude: -79.1697,
    },
    {
        store_latitdue: -0.229055,
        store_longitude: -79.1701,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.22821,
        store_longitude: -79.172426,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.3549719,
        store_longitude: -79.5297539,
    },
    {
        store_latitdue: -0.47495,
        store_longitude: -79.5559,
    },
    {
        store_latitdue: -0.2388385,
        store_longitude: -79.1989733,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.356432,
        store_longitude: -79.531307,
    },
    {
        store_latitdue: -0.2883249,
        store_longitude: -79.2161846,
    },
    {
        store_latitdue: 0.0115644,
        store_longitude: -79.3899244,
    },
    {
        store_latitdue: 0.0125013,
        store_longitude: -79.3887,
    },
    {
        store_latitdue: -0.2757088,
        store_longitude: -79.2086909,
    },
    {
        store_latitdue: 0.0409641,
        store_longitude: -79.3963592,
        neighborhood: 'ROSA ZÁRATE',
    },

    {
        store_latitdue: 0.0407107,
        store_longitude: -79.3982216,
        neighborhood: 'ROSA ZÁRATE',
    },
    {
        store_latitdue: 0.019127,
        store_longitude: -79.3926433,
    },
    {
        store_latitdue: -0.2431689,
        store_longitude: -79.1977111,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2698263,
        store_longitude: -79.4625984,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.3360596,
        store_longitude: -79.7583374,
        neighborhood: 'FLAVIO ALFARO',
    },
    {
        store_latitdue: -0.24994,
        store_longitude: -79.216798,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2823666,
        store_longitude: -79.4697887,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.2671829,
        store_longitude: -79.1783146,
    },
    {
        store_latitdue: -0.1099654,
        store_longitude: -79.616415,
        neighborhood: 'SAN PEDRO DE SUMA',
    },
    {
        store_latitdue: -0.414234,
        store_longitude: -79.5565,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: -0.0290055,
        store_longitude: -79.4951491,
    },
    {
        store_latitdue: -0.2838545,
        store_longitude: -79.2116845,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.2639824,
        store_longitude: -79.4706245,
        neighborhood: 'EL CARMEN',
    },
    {
        store_latitdue: 0.0178175,
        store_longitude: -79.4006929,
    },
    {
        store_latitdue: -0.256018,
        store_longitude: -79.1812,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.250532,
        store_longitude: -79.1758,
    },
    {
        store_latitdue: 0.0991882,
        store_longitude: -76.8829131,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1887194,
        store_longitude: -76.6455971,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0780513,
        store_longitude: -77.2152907,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.6870955,
        store_longitude: -77.3099904,
    },
    {
        store_latitdue: -0.47181,
        store_longitude: -76.9824,
    },
    {
        store_latitdue: -0.4718851,
        store_longitude: -76.9897198,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1875009,
        store_longitude: -76.6363278,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.298898,
        store_longitude: -76.8545,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.46221,
        store_longitude: -77.8945,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -0.28527,
        store_longitude: -76.84838,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0834672,
        store_longitude: -76.8958131,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0961729,
        store_longitude: -76.8992972,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0917433,
        store_longitude: -76.8779,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0958206,
        store_longitude: -76.8974,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1884808,
        store_longitude: -76.6577572,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.6879478,
        store_longitude: -77.30988282,
    },
    {
        store_latitdue: 0.0495614,
        store_longitude: -76.6768481,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: -0.45296673,
        store_longitude: -76.9966077,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.699794,
        store_longitude: -77.3124425,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.6873,
        store_longitude: -77.31095,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.691245,
        store_longitude: -77.3082,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.0838415,
        store_longitude: -76.8818525,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0837911,
        store_longitude: -76.8995203,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.472271,
        store_longitude: -76.9824,
    },
    {
        store_latitdue: -0.2938899,
        store_longitude: -76.8574732,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.100107,
        store_longitude: -76.8833938,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4335325,
        store_longitude: -77.0054845,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2980344,
        store_longitude: -76.857707,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1899604,
        store_longitude: -76.6562253,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.046842,
        store_longitude: -77.3287,
    },
    {
        store_latitdue: -0.3330444,
        store_longitude: -76.9802994,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: -0.1885868,
        store_longitude: -76.6396653,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0873663,
        store_longitude: -76.8943104,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0105048,
        store_longitude: -77.3865216,
    },
    {
        store_latitdue: -0.3553228,
        store_longitude: -76.8151648,
        neighborhood: 'UNIÓN MILAGREÑA',
    },
    {
        store_latitdue: -0.30586516,
        store_longitude: -76.860176,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4535535,
        store_longitude: -76.9894524,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0522447,
        store_longitude: -77.308,
    },
    {
        store_latitdue: 0.0793384,
        store_longitude: -77.2116385,
    },
    {
        store_latitdue: -0.46214055,
        store_longitude: -76.9923209,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4573956,
        store_longitude: -76.9969204,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0902256,
        store_longitude: -76.8859646,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.094392249,
        store_longitude: -76.8905639,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0755579,
        store_longitude: -76.8858514,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.18584263,
        store_longitude: -76.690361,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.451178,
        store_longitude: -77.0005,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0702215,
        store_longitude: -76.9168567,
    },
    {
        store_latitdue: -0.4732446,
        store_longitude: -76.9837603,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4742012,
        store_longitude: -76.9876132,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: -0.68900287,
        store_longitude: -77.3033752,
        neighborhood: 'LORETO',
    },

    {
        store_latitdue: -0.6934476,
        store_longitude: -77.3107427,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.1998094,
        store_longitude: -76.6231507,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0966642,
        store_longitude: -76.8921621,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.471111,
        store_longitude: -76.990356,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.335723,
        store_longitude: -77.8144,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.6899836,
        store_longitude: -77.310842,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.093767,
        store_longitude: -76.8761431,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0814863,
        store_longitude: -76.9077328,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0875234,
        store_longitude: -76.8944387,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.082268,
        store_longitude: -76.8856,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.465845,
        store_longitude: -76.9963,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0785252,
        store_longitude: -76.9306086,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0928546,
        store_longitude: -76.8868431,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0838107,
        store_longitude: -76.9173799,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0636046,
        store_longitude: -76.8771005,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.0459411317,
        store_longitude: -77.531030598,
        neighborhood: 'EL REVENTADOR',
    },
    {
        store_latitdue: -0.0691113,
        store_longitude: -76.9336,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.467633,
        store_longitude: -76.9969953,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2964498,
        store_longitude: -76.8564207,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.28908682,
        store_longitude: -76.8537211,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1902833,
        store_longitude: -76.6494,
    },
    {
        store_latitdue: -0.2258391,
        store_longitude: -76.8476302,
        neighborhood: 'ENOKANQUI',
    },
    {
        store_latitdue: -0.1840522,
        store_longitude: -76.6454089,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.436378,
        store_longitude: -77.0039,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.468306,
        store_longitude: -76.9918,
    },
    {
        store_latitdue: -0.44393,
        store_longitude: -77.0129,
    },
    {
        store_latitdue: -0.3347692,
        store_longitude: -76.9968609,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: -0.3377704,
        store_longitude: -77.0060889,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: -0.4452638,
        store_longitude: -76.9973729,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1788,
        store_longitude: -76.5137,
    },
    {
        store_latitdue: 0.047701,
        store_longitude: -77.3287358,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.2958343,
        store_longitude: -76.8554646,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1879646,
        store_longitude: -76.642349,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0819572,
        store_longitude: -76.8830455,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4638616,
        store_longitude: -76.9894866,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0946506,
        store_longitude: -76.8749083,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.188791,
        store_longitude: -76.6382,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0874004,
        store_longitude: -76.8932,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.471515,
        store_longitude: -76.9873,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4657,
        store_longitude: -76.9881,
    },
    {
        store_latitdue: -0.692046,
        store_longitude: -77.3103,
    },
    {
        store_latitdue: -0.226794,
        store_longitude: -76.8484088,
        neighborhood: 'ENOKANQUI',
    },
    {
        store_latitdue: -0.299599,
        store_longitude: -76.8587,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.2984167,
        store_longitude: -76.8562667,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.298,
        store_longitude: -76.8554,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0833654,
        store_longitude: -76.8960366,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.086083,
        store_longitude: -76.8935,
    },
    {
        store_latitdue: -0.450647,
        store_longitude: -76.9957,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0918742,
        store_longitude: -76.8835953,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4369946,
        store_longitude: -77.0037904,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4443411,
        store_longitude: -76.9923711,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: -0.0649895,
        store_longitude: -76.8793659,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.33845,
        store_longitude: -77.0048,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: -0.4595718,
        store_longitude: -76.9865389,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.470741,
        store_longitude: -76.983,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.48492,
        store_longitude: -76.9869,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1840289,
        store_longitude: -76.6420861,
        neighborhood: 'SHUSHUFINDI',
    },

    {
        store_latitdue: 0.18823,
        store_longitude: -76.8474,
    },
    {
        store_latitdue: 0.083689,
        store_longitude: -76.9059,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.469314,
        store_longitude: -76.9887,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.462285,
        store_longitude: -77.0038033,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0105476,
        store_longitude: -77.3863104,
        neighborhood: 'GONZALO PIZARRO',
    },
    {
        store_latitdue: -0.4719488,
        store_longitude: -76.9834756,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2867841,
        store_longitude: -76.8510186,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4655723,
        store_longitude: -76.996989,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.188705,
        store_longitude: -76.6449,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.46061,
        store_longitude: -76.9931,
    },
    {
        store_latitdue: -0.1875602,
        store_longitude: -76.6464885,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.3067012,
        store_longitude: -76.8590474,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0993007,
        store_longitude: -76.8831662,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.29355,
        store_longitude: -76.8543,
    },
    {
        store_latitdue: 0.0851,
        store_longitude: -76.8961,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.470028,
        store_longitude: -76.9898,
    },
    {
        store_latitdue: -0.1879101,
        store_longitude: -76.65687,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.07165,
        store_longitude: -76.8942,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.48853,
        store_longitude: -76.9888,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.453395,
        store_longitude: -76.9938,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0918945,
        store_longitude: -76.8947187,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4559653,
        store_longitude: -76.9885461,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.470553,
        store_longitude: -76.9858,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4617379,
        store_longitude: -76.996776,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4725224,
        store_longitude: -76.9831843,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4412681,
        store_longitude: -77.0016052,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4244617,
        store_longitude: -77.846429,
        neighborhood: 'SAN FRANCISCO DE BORJA',
    },
    {
        store_latitdue: -0.44489,
        store_longitude: -76.9925,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4577152,
        store_longitude: -76.9879362,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.47275,
        store_longitude: -76.982,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4359318,
        store_longitude: -77.002731,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.082221,
        store_longitude: -76.8851,
    },
    {
        store_latitdue: -0.4414908,
        store_longitude: -76.9945239,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0809678,
        store_longitude: -77.2043086,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.183851,
        store_longitude: -76.6454661,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.0676833,
        store_longitude: -76.87915,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: 0.09241,
        store_longitude: -76.8942,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.47188,
        store_longitude: -76.983,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1260442,
        store_longitude: -76.3442877,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: 0.0795896,
        store_longitude: -77.2110429,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: 0.08142,
        store_longitude: -76.8823,
    },
    {
        store_latitdue: -0.470126,
        store_longitude: -76.9885,
    },
    {
        store_latitdue: 0.0823814,
        store_longitude: -76.8822541,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4488087,
        store_longitude: -77.0110151,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0783805,
        store_longitude: -76.8844,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.083238,
        store_longitude: -76.8852,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0862324,
        store_longitude: -76.8959,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.084568,
        store_longitude: -76.9115,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.08429815,
        store_longitude: -76.911323,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.2942667,
        store_longitude: -76.85295,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4508342,
        store_longitude: -77.0235173,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0952219,
        store_longitude: -76.8857988,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0956728,
        store_longitude: -76.8849035,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.083227,
        store_longitude: -76.9059,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0828843,
        store_longitude: -76.8801758,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.488843,
        store_longitude: -76.9868,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1376864,
        store_longitude: -76.8532949,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: -0.4788676,
        store_longitude: -77.0698997,
        neighborhood: 'SAN LUIS DE ARMENIA',
    },
    {
        store_latitdue: 0.0871502,
        store_longitude: -76.8908995,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4553339,
        store_longitude: -76.9886458,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.472405,
        store_longitude: -76.9828,
    },

    {
        store_latitdue: 0.0830488,
        store_longitude: -76.885978,
    },
    {
        store_latitdue: -0.462,
        store_longitude: -76.9895,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1831315,
        store_longitude: -76.6474289,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.472323,
        store_longitude: -76.9828,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4593193,
        store_longitude: -76.992156,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4532234,
        store_longitude: -76.9954207,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.472963,
        store_longitude: -76.9844,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0908765,
        store_longitude: -76.8818734,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1865586,
        store_longitude: -76.6464239,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.08534,
        store_longitude: -76.88717,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.126491,
        store_longitude: -76.3439,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: -0.441277,
        store_longitude: -77.0013,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.470133,
        store_longitude: -76.9859255,
    },
    {
        store_latitdue: -0.4638216,
        store_longitude: -77.0008785,
    },
    {
        store_latitdue: -0.4413059,
        store_longitude: -76.9992323,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.081799,
        store_longitude: -76.8828059,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.080067,
        store_longitude: -76.9181,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0845607,
        store_longitude: -77.0759339,
        neighborhood: 'JAMBELÍ',
    },
    {
        store_latitdue: -0.46622,
        store_longitude: -76.9911,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2941,
        store_longitude: -76.8657167,
    },
    {
        store_latitdue: -0.4709512,
        store_longitude: -76.9830377,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0790492,
        store_longitude: -77.2118334,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.1790648,
        store_longitude: -76.8360363,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: -0.298555,
        store_longitude: -76.8557244,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.06675,
        store_longitude: -76.8793,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: 0.093024,
        store_longitude: -76.8943169,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0679646,
        store_longitude: -76.8866173,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0790711,
        store_longitude: -77.2117821,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: 0.08703,
        store_longitude: -76.902,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.08847,
        store_longitude: -76.8896,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.081011,
        store_longitude: -76.925232,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.2915167,
        store_longitude: -76.8547,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.473048,
        store_longitude: -76.9906,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.47466,
        store_longitude: -76.989,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.472327,
        store_longitude: -76.9827,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0790778,
        store_longitude: -76.9042,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.295155,
        store_longitude: -76.8543,
    },
    {
        store_latitdue: 0.0500765,
        store_longitude: -76.6772605,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: -0.1826926,
        store_longitude: -76.6435854,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0869913,
        store_longitude: -76.9017765,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4567517,
        store_longitude: -76.9935124,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.466166,
        store_longitude: -76.9881,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0788801,
        store_longitude: -76.8954926,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.298746,
        store_longitude: -76.8576,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4411452,
        store_longitude: -76.9934368,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4663861,
        store_longitude: -76.9877221,
    },
    {
        store_latitdue: 0.06999,
        store_longitude: -76.8947,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.083221,
        store_longitude: -76.922576,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.087793,
        store_longitude: -76.902,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0830263,
        store_longitude: -76.9920575,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: -0.17859,
        store_longitude: -76.8366,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: 0.08572,
        store_longitude: -76.918,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1887643,
        store_longitude: -76.6419211,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.29626,
        store_longitude: -76.8564,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.45607,
        store_longitude: -77,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1890455,
        store_longitude: -76.6454643,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.4661105,
        store_longitude: -76.9872444,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0928367,
        store_longitude: -76.8925998,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: 0.0870932,
        store_longitude: -76.8960601,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.2921667,
        store_longitude: -76.8561,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.106345,
        store_longitude: -76.8809,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0930501,
        store_longitude: -76.8760484,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.08776,
        store_longitude: -76.87894,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0455513,
        store_longitude: -77.3307342,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: 0.0815912,
        store_longitude: -76.8831,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0498259,
        store_longitude: -76.6771106,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: 0.0497858,
        store_longitude: -76.6766587,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: -0.1912968,
        store_longitude: -76.6403785,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0782294,
        store_longitude: -76.8951794,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: -0.2941193,
        store_longitude: -76.854532,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0801472,
        store_longitude: -76.8845309,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.464996,
        store_longitude: -76.9952,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.066431604,
        store_longitude: -76.88135465,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.459036,
        store_longitude: -76.9985,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0685684,
        store_longitude: -76.8788971,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: 0.0107119,
        store_longitude: -77.3860703,
        neighborhood: 'GONZALO PIZARRO',
    },
    {
        store_latitdue: 0.0831568,
        store_longitude: -76.8859709,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.28325,
        store_longitude: -76.8564,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0844879,
        store_longitude: -76.911485,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4643092,
        store_longitude: -76.9880608,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0416196,
        store_longitude: -76.5875302,
        neighborhood: 'PACAYACU',
    },
    {
        store_latitdue: -0.4435421,
        store_longitude: -77.0001686,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.306794,
        store_longitude: -78.5499,
    },
    {
        store_latitdue: -0.1836686,
        store_longitude: -76.6519322,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0838676,
        store_longitude: -77.1275,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: -0.17965,
        store_longitude: -76.6393,
    },
    {
        store_latitdue: 0.0769893,
        store_longitude: -77.2168897,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.4665458,
        store_longitude: -76.9838329,
    },
    {
        store_latitdue: -0.291507,
        store_longitude: -76.8541,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4653777,
        store_longitude: -76.9906476,
    },
    {
        store_latitdue: -0.5017558,
        store_longitude: -76.9554762,
        neighborhood: 'EL DORADO',
    },
    {
        store_latitdue: 0.0693443,
        store_longitude: -76.8943934,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.46513,
        store_longitude: -76.9827,
    },
    {
        store_latitdue: 0.045504,
        store_longitude: -77.329782,
    },
    {
        store_latitdue: -0.4719902,
        store_longitude: -76.982469,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1857038,
        store_longitude: -76.6917983,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.190161,
        store_longitude: -76.6408101,
    },
    {
        store_latitdue: -0.468038,
        store_longitude: -77.000229,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1871827,
        store_longitude: -76.6558368,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.43627,
        store_longitude: -77.0019,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1877546,
        store_longitude: -76.642192,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.300691,
        store_longitude: -76.8582,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },

    {
        store_latitdue: -0.29119,
        store_longitude: -76.8522,
    },
    {
        store_latitdue: -0.34052,
        store_longitude: -77.0049,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: 0.0838754,
        store_longitude: -76.8851894,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.188401,
        store_longitude: -76.6464055,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.49643,
        store_longitude: -76.7757,
        neighborhood: 'TARACOA',
    },
    {
        store_latitdue: -0.438897,
        store_longitude: -77.0039,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.687078,
        store_longitude: -77.310705,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.0859934,
        store_longitude: -77.1264669,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: 0.0809588,
        store_longitude: -76.9170855,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0802611,
        store_longitude: -76.8841,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.444048,
        store_longitude: -77.0008787,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4471646,
        store_longitude: -77.0017299,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.468152,
        store_longitude: -76.9787,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1276248,
        store_longitude: -76.3422373,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: 0.0825973,
        store_longitude: -76.8880479,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.0690393,
        store_longitude: -76.8779953,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: 0.08473818,
        store_longitude: -76.951399,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: -0.4667,
        store_longitude: -76.9875,
    },
    {
        store_latitdue: -0.46134,
        store_longitude: -76.9828,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0680667,
        store_longitude: -76.8785333,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.298679,
        store_longitude: -76.8573,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.6858843,
        store_longitude: -77.3106287,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.126168,
        store_longitude: -76.3438,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: -0.50356,
        store_longitude: -76.9554,
        neighborhood: 'EL DORADO',
    },
    {
        store_latitdue: 0.0900164,
        store_longitude: -76.8802685,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.466956,
        store_longitude: -76.991725,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0913826,
        store_longitude: -76.8967141,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.44494,
        store_longitude: -77.0122,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0904612,
        store_longitude: -76.9000271,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: 0.0841946,
        store_longitude: -76.9010826,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0510115,
        store_longitude: -76.6784851,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: -0.43686,
        store_longitude: -76.9998,
    },
    {
        store_latitdue: -0.4453512,
        store_longitude: -77.0145997,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0848525,
        store_longitude: -76.8854253,
    },
    {
        store_latitdue: 0.082536,
        store_longitude: -76.8806404,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0823149,
        store_longitude: -76.901564,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0813818,
        store_longitude: -76.9059856,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4669094,
        store_longitude: -76.9916287,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1860179,
        store_longitude: -76.7794377,
    },
    {
        store_latitdue: -0.4711492,
        store_longitude: -76.9866754,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0688319,
        store_longitude: -76.8788402,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: 0.0852,
        store_longitude: -76.8935,
    },
    {
        store_latitdue: -0.4680938,
        store_longitude: -76.987913,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2994793,
        store_longitude: -76.855106,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0870323,
        store_longitude: -76.9005336,
    },
    {
        store_latitdue: 0.0928123,
        store_longitude: -76.8828931,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1889775,
        store_longitude: -76.6416353,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0653912,
        store_longitude: -76.8779,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.0413867,
        store_longitude: -76.5881,
        neighborhood: 'PACAYACU',
    },
    {
        store_latitdue: -0.4708396,
        store_longitude: -76.9848464,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.32493,
        store_longitude: -76.8781,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0799299,
        store_longitude: -76.8997789,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.294515,
        store_longitude: -76.8556,
    },
    {
        store_latitdue: -0.67925,
        store_longitude: -77.3103,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.466818,
        store_longitude: -76.9877375,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2979805,
        store_longitude: -76.8563268,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0743825,
        store_longitude: -76.9180362,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0905941,
        store_longitude: -76.8767037,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.3395585,
        store_longitude: -77.0056289,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: 0.0914072,
        store_longitude: -76.8944,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.45276,
        store_longitude: -77.0025,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.080978,
        store_longitude: -76.913832,
    },
    {
        store_latitdue: -0.4587502,
        store_longitude: -76.9991468,
    },
    {
        store_latitdue: -0.381106,
        store_longitude: -76.8824,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: 0.0833636,
        store_longitude: -76.8862233,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4526884,
        store_longitude: -77.029843,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1884801,
        store_longitude: -76.6409191,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0809123,
        store_longitude: -76.8830404,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4373843,
        store_longitude: -76.9997162,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4607526,
        store_longitude: -76.990813,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0658136,
        store_longitude: -76.8794,
    },
    {
        store_latitdue: 0.0788755,
        store_longitude: -77.2125978,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: 0.0869663,
        store_longitude: -76.9043,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0811278,
        store_longitude: -76.9145982,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.3002285,
        store_longitude: -76.8584673,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0949467,
        store_longitude: -76.8744,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1785819,
        store_longitude: -76.835997,
    },
    {
        store_latitdue: -0.1860676,
        store_longitude: -76.779229,
        neighborhood: 'SIETE DE JULIO',
    },
    {
        store_latitdue: -0.29215,
        store_longitude: -76.8583,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0913179,
        store_longitude: -76.9515732,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: 0.0923721,
        store_longitude: -76.8877091,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0968139,
        store_longitude: -76.9471,
        neighborhood: 'SANTA CECILIA',
    },

    {
        store_latitdue: 0.0759987,
        store_longitude: -77.2225,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: 0.0780511,
        store_longitude: -77.2155,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.288456,
        store_longitude: -76.8577,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.472336,
        store_longitude: -76.9822,
    },
    {
        store_latitdue: -0.299255,
        store_longitude: -76.858,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1858748,
        store_longitude: -76.6476842,
    },
    {
        store_latitdue: -0.2945965,
        store_longitude: -76.8558971,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1856613,
        store_longitude: -76.7789093,
        neighborhood: 'SIETE DE JULIO',
    },
    {
        store_latitdue: -0.6876259,
        store_longitude: -77.3084956,
    },
    {
        store_latitdue: 0.0887832,
        store_longitude: -76.8834921,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.178033,
        store_longitude: -76.8354256,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: -0.445354,
        store_longitude: -76.9994,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0833098,
        store_longitude: -76.8936883,
    },
    {
        store_latitdue: -0.123206,
        store_longitude: -76.8601,
    },
    {
        store_latitdue: -0.315363,
        store_longitude: -76.8632632,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0164566,
        store_longitude: -77.3777833,
        neighborhood: 'GONZALO PIZARRO',
    },
    {
        store_latitdue: -0.470545,
        store_longitude: -76.9871,
    },
    {
        store_latitdue: 0.096585,
        store_longitude: -76.9496,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: -0.1870604,
        store_longitude: -76.6564286,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0762558,
        store_longitude: -76.9304063,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1262382,
        store_longitude: -76.3423259,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: -0.443783,
        store_longitude: -77.0001,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0464949,
        store_longitude: -77.5288708,
        neighborhood: 'EL REVENTADOR',
    },
    {
        store_latitdue: -0.379706,
        store_longitude: -77.0134,
        neighborhood: 'NUEVO PARAÍSO',
    },
    {
        store_latitdue: -0.0837794,
        store_longitude: -76.6446,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0860131,
        store_longitude: -76.8821193,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.458728,
        store_longitude: -76.9974,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.3777552,
        store_longitude: -77.0136564,
        neighborhood: 'NUEVO PARAÍSO',
    },
    {
        store_latitdue: 0.0832942,
        store_longitude: -76.9226645,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: 0.0831045,
        store_longitude: -76.883,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4723314,
        store_longitude: -76.9841991,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.6905,
        store_longitude: -77.3111833,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.2939554,
        store_longitude: -76.8569207,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4695271,
        store_longitude: -76.9908306,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.121741,
        store_longitude: -77.125,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: -0.1984947,
        store_longitude: -76.6287954,
    },

    {
        store_latitdue: -0.303965,
        store_longitude: -76.8608,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.458883,
        store_longitude: -76.9909,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.297073,
        store_longitude: -76.8542,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1882938,
        store_longitude: -76.6405065,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0519569,
        store_longitude: -76.6780096,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: -0.3006164,
        store_longitude: -76.8585589,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0764682,
        store_longitude: -76.9065478,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0813829,
        store_longitude: -76.8849,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.2978639,
        store_longitude: -76.8570425,
    },
    {
        store_latitdue: 0.0880757,
        store_longitude: -76.8964786,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0845818,
        store_longitude: -76.9032892,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0794951,
        store_longitude: -77.2113334,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.471111,
        store_longitude: -76.9866,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0792895,
        store_longitude: -76.8844479,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.3262931,
        store_longitude: -76.8729636,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.188237,
        store_longitude: -76.6376,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.455769,
        store_longitude: -76.9979,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.6703169,
        store_longitude: -76.8777695,
        neighborhood: 'DAYUMA',
    },
    {
        store_latitdue: -0.6708483,
        store_longitude: -76.8785741,
        neighborhood: 'DAYUMA',
    },
    {
        store_latitdue: -0.667177,
        store_longitude: -76.8775,
        neighborhood: 'DAYUMA',
    },
    {
        store_latitdue: -0.4542818,
        store_longitude: -76.989275,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.45454,
        store_longitude: -77.0139,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.29664,
        store_longitude: -76.8566,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.3253942,
        store_longitude: -76.8731098,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4726528,
        store_longitude: -76.9819814,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: -0.473078,
        store_longitude: -76.9844,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.47312,
        store_longitude: -76.9847,
    },
    {
        store_latitdue: -0.29778,
        store_longitude: -76.857,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.467197,
        store_longitude: -76.9867,
    },
    {
        store_latitdue: -0.4500126,
        store_longitude: -77.0280337,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.47392,
        store_longitude: -76.985,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4378144,
        store_longitude: -77.003267,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0793884,
        store_longitude: -77.2131,
    },
    {
        store_latitdue: -0.30192,
        store_longitude: -76.8589,
    },
    {
        store_latitdue: 0.0823872,
        store_longitude: -76.9087068,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4417598,
        store_longitude: -77.017809,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0961416,
        store_longitude: -76.8835609,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0947084,
        store_longitude: -76.8848822,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.45907,
        store_longitude: -77.0013,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1824603,
        store_longitude: -76.6436054,
    },
    {
        store_latitdue: -0.465442,
        store_longitude: -76.9958,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.453661,
        store_longitude: -77.0085795,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.09136,
        store_longitude: -76.8757,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0805497,
        store_longitude: -76.8841666,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0832784,
        store_longitude: -76.9179159,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0844961,
        store_longitude: -76.8834009,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4566365,
        store_longitude: -76.9945668,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1860585,
        store_longitude: -76.7788121,
        neighborhood: 'SIETE DE JULIO',
    },
    {
        store_latitdue: -0.1253381,
        store_longitude: -76.4401858,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: -0.3315988,
        store_longitude: -76.9903353,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: 0.0849956,
        store_longitude: -76.9041914,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.07147,
        store_longitude: -76.8828,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0949185,
        store_longitude: -76.899046,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4725443,
        store_longitude: -76.9830462,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4460381,
        store_longitude: -76.9991344,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.69075,
        store_longitude: -77.3111167,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.1851361,
        store_longitude: -76.6459166,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.467234,
        store_longitude: -76.9919997,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0840931,
        store_longitude: -76.91512,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1877025,
        store_longitude: -76.6474364,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.126141,
        store_longitude: -76.3439,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: 0.0807183,
        store_longitude: -76.8976928,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.317498,
        store_longitude: -76.865,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.04467,
        store_longitude: -77.33092,
    },
    {
        store_latitdue: -0.298988,
        store_longitude: -76.8562,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0470999,
        store_longitude: -77.3288889,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: 0.0813636,
        store_longitude: -76.8838,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.045422874,
        store_longitude: -77.33066971,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.471033,
        store_longitude: -76.9833,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1888261,
        store_longitude: -76.6453385,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0902583,
        store_longitude: -76.8856837,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.437037,
        store_longitude: -77.0061,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0766189,
        store_longitude: -76.9052655,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0705078,
        store_longitude: -76.8836,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0789813,
        store_longitude: -76.9202,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4483507,
        store_longitude: -77.0156556,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0161387,
        store_longitude: -77.3773643,
        neighborhood: 'GONZALO PIZARRO',
    },
    {
        store_latitdue: 0.0908677,
        store_longitude: -76.8935371,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.1119923,
        store_longitude: -76.872723,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4737686,
        store_longitude: -76.9834417,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.6901667,
        store_longitude: -77.3112833,
    },
    {
        store_latitdue: -0.6873383,
        store_longitude: -77.3089917,
        neighborhood: 'LORETO',
    },

    {
        store_latitdue: -0.6852578,
        store_longitude: -77.3090533,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.460948,
        store_longitude: -76.991644,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2870943,
        store_longitude: -76.8523824,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4383167,
        store_longitude: -77.0029097,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.078269,
        store_longitude: -76.9084927,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.29704,
        store_longitude: -76.8573,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.2994751,
        store_longitude: -76.8550899,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0990757,
        store_longitude: -76.8976475,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1847206,
        store_longitude: -76.64968,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.080366,
        store_longitude: -76.8856265,
    },
    {
        store_latitdue: -0.462142,
        store_longitude: -76.9945,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0686364,
        store_longitude: -76.8789548,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.4462531,
        store_longitude: -77.0118185,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4486617,
        store_longitude: -77.0174687,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0818299,
        store_longitude: -76.8829,
    },
    {
        store_latitdue: -0.6886804,
        store_longitude: -77.3077413,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.190438,
        store_longitude: -76.647,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.455676,
        store_longitude: -76.9939,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0784661,
        store_longitude: -76.8863,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0456903,
        store_longitude: -77.3311155,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: 0.0682802,
        store_longitude: -76.886844,
    },
    {
        store_latitdue: -0.284058,
        store_longitude: -76.855,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.46327,
        store_longitude: -76.9828,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0771281,
        store_longitude: -76.8924947,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0948509,
        store_longitude: -76.8918789,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.6896838,
        store_longitude: -77.310154,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.0755628,
        store_longitude: -76.8859,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4724649,
        store_longitude: -76.9824982,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4559243,
        store_longitude: -76.9943631,
    },
    {
        store_latitdue: 0.0502598,
        store_longitude: -76.677148,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: 0.0782458,
        store_longitude: -76.8845,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1892488,
        store_longitude: -76.6486352,
    },
    {
        store_latitdue: -0.296292,
        store_longitude: -76.8558,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.473401,
        store_longitude: -76.9838,
    },
    {
        store_latitdue: -0.47291,
        store_longitude: -76.9818,
    },
    {
        store_latitdue: 0.0928915,
        store_longitude: -76.8872446,
    },
    {
        store_latitdue: -0.3287,
        store_longitude: -77.807,
    },
    {
        store_latitdue: -0.690126,
        store_longitude: -77.3112,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.0824294,
        store_longitude: -76.9090532,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0907442,
        store_longitude: -76.891425,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.453279,
        store_longitude: -76.9953,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0791738,
        store_longitude: -77.211962,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.1906817,
        store_longitude: -76.6508618,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.084628,
        store_longitude: -76.8806658,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1865741,
        store_longitude: -76.6499771,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.432837,
        store_longitude: -77.0059,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2994204,
        store_longitude: -76.8568824,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.34085973,
        store_longitude: -77.7978694,
    },
    {
        store_latitdue: -0.04148,
        store_longitude: -76.58832,
        neighborhood: 'PACAYACU',
    },
    {
        store_latitdue: 0.0499074,
        store_longitude: -76.6763569,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: 0.0946279,
        store_longitude: -76.8866696,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0803649,
        store_longitude: -76.884689,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.6823061,
        store_longitude: -77.3106119,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.1337993,
        store_longitude: -76.8789207,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0870335,
        store_longitude: -77.1692663,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: 0.0968663,
        store_longitude: -76.8981,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.46885,
        store_longitude: -76.9898,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4671467,
        store_longitude: -76.988256,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1864971,
        store_longitude: -76.6419185,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.339009,
        store_longitude: -77.0059,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: 0.0893906,
        store_longitude: -76.9000316,
    },
    {
        store_latitdue: -0.297768,
        store_longitude: -76.8554,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.3041229,
        store_longitude: -76.8651215,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0842633,
        store_longitude: -76.9118,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1886528,
        store_longitude: -76.6382493,
    },
    {
        store_latitdue: 0.082079,
        store_longitude: -76.991656,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: -0.4635914,
        store_longitude: -76.9900312,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.451738,
        store_longitude: -77.0096,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0471495,
        store_longitude: -77.3287075,
    },
    {
        store_latitdue: -0.4587051,
        store_longitude: -76.9956295,
    },
    {
        store_latitdue: 0.1218036,
        store_longitude: -77.1257816,
    },
    {
        store_latitdue: 0.0809212,
        store_longitude: -76.885064,
    },
    {
        store_latitdue: -0.4669663,
        store_longitude: -76.9875468,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.451407,
        store_longitude: -77.007,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.3165194,
        store_longitude: -76.8621509,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0858649,
        store_longitude: -76.8837,
    },
    {
        store_latitdue: -0.43484,
        store_longitude: -77.0049,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0822933,
        store_longitude: -76.9913217,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: 0.0896395,
        store_longitude: -76.9118,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.448372,
        store_longitude: -77.0008,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0803779,
        store_longitude: -76.8848369,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4663525,
        store_longitude: -76.9868288,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0853781,
        store_longitude: -76.9001172,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4741785,
        store_longitude: -76.9840015,
    },
    {
        store_latitdue: 0.0858096,
        store_longitude: -76.9043,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0470392,
        store_longitude: -77.3291304,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.33438,
        store_longitude: -76.9966,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: -0.3306646,
        store_longitude: -77.8162103,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.2961999,
        store_longitude: -76.8556129,
    },
    {
        store_latitdue: -0.2989,
        store_longitude: -76.8557,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1783057,
        store_longitude: -76.6420367,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.473818,
        store_longitude: -76.9835,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0391971,
        store_longitude: -76.5832333,
        neighborhood: 'PACAYACU',
    },
    {
        store_latitdue: -0.4958291,
        store_longitude: -76.7758919,
        neighborhood: 'TARACOA',
    },
    {
        store_latitdue: 0.077375,
        store_longitude: -76.9177,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0491951,
        store_longitude: -77.3247,
        neighborhood: 'LUMBAQUÍ',
    },

    {
        store_latitdue: -0.6700879,
        store_longitude: -76.8776153,
        neighborhood: 'DAYUMA',
    },
    {
        store_latitdue: 0.087284,
        store_longitude: -76.8841,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.08159773,
        store_longitude: -76.88423881,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0735851,
        store_longitude: -76.8110707,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0846206,
        store_longitude: -77.127448,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: -0.19117,
        store_longitude: -76.6379,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.3247831,
        store_longitude: -76.9261231,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.139005,
        store_longitude: -76.8511,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: -0.1879254,
        store_longitude: -76.6421789,
    },
    {
        store_latitdue: -0.0691109,
        store_longitude: -76.933125,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: 0.0388568,
        store_longitude: -77.3445354,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: 0.0829897,
        store_longitude: -76.9066028,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0776899,
        store_longitude: -76.9001,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0992,
        store_longitude: -76.88572,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.185451,
        store_longitude: -76.6455,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.1793842,
        store_longitude: -76.6385375,
    },
    {
        store_latitdue: 0.0866325,
        store_longitude: -76.9115,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4658941,
        store_longitude: -76.9991753,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.180327,
        store_longitude: -76.6444,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.456753,
        store_longitude: -76.993527,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.44486,
        store_longitude: -77.0012,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.45989,
        store_longitude: -76.9943,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.082623806,
        store_longitude: -76.9041466,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.43993,
        store_longitude: -77.0028,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.46382021,
        store_longitude: -76.9915084,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0843347,
        store_longitude: -76.9885,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: 0.0466196,
        store_longitude: -77.3298,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.299348,
        store_longitude: -76.8517,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0839268,
        store_longitude: -76.8805448,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.18681,
        store_longitude: -76.6467,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0956364,
        store_longitude: -76.8944,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.078668,
        store_longitude: -77.2131746,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: 0.0713938,
        store_longitude: -76.8829445,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.442692,
        store_longitude: -76.9985,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4626221,
        store_longitude: -76.9913965,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.470991,
        store_longitude: -76.9897,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.097891,
        store_longitude: -76.8904,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0845026,
        store_longitude: -76.9118,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.448385,
        store_longitude: -76.9977,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4443898,
        store_longitude: -77.0017043,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.429331,
        store_longitude: -77.0083,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.078299,
        store_longitude: -76.9133433,
    },
    {
        store_latitdue: -0.296658,
        store_longitude: -76.8539,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.375936,
        store_longitude: -77.0148,
        neighborhood: 'NUEVO PARAÍSO',
    },
    {
        store_latitdue: 0.0787563,
        store_longitude: -76.8856,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.22628,
        store_longitude: -76.8478,
        neighborhood: 'ENOKANQUI',
    },
    {
        store_latitdue: 0.0996081,
        store_longitude: -76.8857928,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0500826,
        store_longitude: -76.677,
    },
    {
        store_latitdue: 0.0841248,
        store_longitude: -76.9000555,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4277281,
        store_longitude: -76.9922283,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0852229,
        store_longitude: -76.8999,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: -0.1884525,
        store_longitude: -76.6480446,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0981799,
        store_longitude: -76.8592,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1850265,
        store_longitude: -76.6452778,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.19138062,
        store_longitude: -76.6361403,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0937586,
        store_longitude: -76.9515,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: 0.0793176,
        store_longitude: -76.9368,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.442208,
        store_longitude: -77.0176,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1913544,
        store_longitude: -76.636176,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.6835058,
        store_longitude: -77.3106203,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.691097,
        store_longitude: -77.3112,
    },
    {
        store_latitdue: -0.4391173,
        store_longitude: -77.0025585,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.436305,
        store_longitude: -77.0008,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.471793,
        store_longitude: -76.9849,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0485369,
        store_longitude: -77.3293464,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: 0.0868852,
        store_longitude: -77.1694284,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: 0.0825159,
        store_longitude: -76.899727,
    },
    {
        store_latitdue: -0.466021,
        store_longitude: -76.9882,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4631349,
        store_longitude: -76.9917576,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.500116,
        store_longitude: -76.954,
        neighborhood: 'EL DORADO',
    },
    {
        store_latitdue: -0.460928,
        store_longitude: -76.9983,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.452443,
        store_longitude: -77.0145,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.6999173,
        store_longitude: -77.310329,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.0411374,
        store_longitude: -76.5887,
    },
    {
        store_latitdue: -0.460588,
        store_longitude: -77.8892,
        neighborhood: 'BAEZA',
    },
    {
        store_latitdue: -0.447755,
        store_longitude: -77.0140966,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.472968,
        store_longitude: -76.9862,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0976743,
        store_longitude: -76.8864,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0898612,
        store_longitude: -76.8949977,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4400565,
        store_longitude: -76.9948356,
    },
    {
        store_latitdue: 0.0898454,
        store_longitude: -76.8778,
    },
    {
        store_latitdue: -0.29917,
        store_longitude: -76.8574,
    },
    {
        store_latitdue: -0.465488,
        store_longitude: -76.9826903,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: -0.446633,
        store_longitude: -77.0004,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.459413,
        store_longitude: -77.0009,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.474055,
        store_longitude: -76.9865,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.513521,
        store_longitude: -76.8927,
        neighborhood: 'EL DORADO',
    },
    {
        store_latitdue: 0.0467541,
        store_longitude: -77.3274889,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: 0.0815977,
        store_longitude: -76.8662,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0797,
        store_longitude: -76.8636,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.448867,
        store_longitude: -76.9954,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0967854,
        store_longitude: -76.8878,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.047816,
        store_longitude: -77.329,
    },
    {
        store_latitdue: -0.4744629,
        store_longitude: -76.9843528,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4718134,
        store_longitude: -76.9864375,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0733175,
        store_longitude: -76.887,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0833042,
        store_longitude: -76.889892,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.5031743,
        store_longitude: -76.9554341,
        neighborhood: 'EL DORADO',
    },
    {
        store_latitdue: -0.683973,
        store_longitude: -77.3073,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.283256,
        store_longitude: -76.857,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0757996,
        store_longitude: -76.8313,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4476897,
        store_longitude: -77.0098668,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.283103,
        store_longitude: -76.8617,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0457325,
        store_longitude: -77.3311,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: 0.0464703,
        store_longitude: -77.3304272,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: 0.0789557,
        store_longitude: -77.2122173,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: 0.0878266,
        store_longitude: -76.8951668,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.083243629,
        store_longitude: -76.9106483,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0986923,
        store_longitude: -76.8581873,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.0689173,
        store_longitude: -76.8788531,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.041404,
        store_longitude: -76.5879422,
        neighborhood: 'PACAYACU',
    },
    {
        store_latitdue: -0.1882427,
        store_longitude: -76.6426647,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.467392,
        store_longitude: -76.98681,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.464267,
        store_longitude: -77.0021,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.435628,
        store_longitude: -77.005,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1855584,
        store_longitude: -76.6454879,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.457435,
        store_longitude: -76.9999,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0955133,
        store_longitude: -76.8894,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.465304,
        store_longitude: -76.9915,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.340518,
        store_longitude: -77.8112,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.447863,
        store_longitude: -77.0061,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.288469,
        store_longitude: -76.8616,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.44995,
        store_longitude: -76.9985,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: 0.0842585,
        store_longitude: -77.0732,
        neighborhood: 'JAMBELÍ',
    },
    {
        store_latitdue: 0.0446662,
        store_longitude: -77.333313,
    },
    {
        store_latitdue: -0.4672873,
        store_longitude: -76.9883944,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.6844183,
        store_longitude: -77.304435,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.293782,
        store_longitude: -76.8539509,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.465131,
        store_longitude: -76.9992,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0657291,
        store_longitude: -76.8778,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.450985,
        store_longitude: -76.9943,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2907721,
        store_longitude: -76.8550083,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.438565,
        store_longitude: -77.0029,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0853515,
        store_longitude: -77.1187,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: -0.4690893,
        store_longitude: -76.9862013,
    },
    {
        store_latitdue: -0.444245,
        store_longitude: -76.9993,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4368955,
        store_longitude: -77.0067389,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.431771,
        store_longitude: -77.0063,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0824783,
        store_longitude: -76.9050426,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0979169,
        store_longitude: -76.8578,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.440117,
        store_longitude: -77.002,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.3292931,
        store_longitude: -78.4291554,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.422223,
        store_longitude: -77.8470292,
        neighborhood: 'SAN FRANCISCO DE BORJA',
    },
    {
        store_latitdue: -0.46669343,
        store_longitude: -76.989237,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.04695,
        store_longitude: -77.3284115,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.453686,
        store_longitude: -77.032488,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.6808366,
        store_longitude: -77.3080188,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.4703122,
        store_longitude: -76.9829727,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.073354,
        store_longitude: -76.8911121,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.45341,
        store_longitude: -76.9895,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.436824,
        store_longitude: -77.007,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2378225,
        store_longitude: -76.6478422,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0827971,
        store_longitude: -76.8664,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4501026,
        store_longitude: -77.0102956,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.46861253,
        store_longitude: -76.98355998,
    },

    {
        store_latitdue: -0.292587,
        store_longitude: -76.8602,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.455036,
        store_longitude: -76.9975,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1259429,
        store_longitude: -76.3437524,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: 0.0837329,
        store_longitude: -76.9177,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.288847,
        store_longitude: -76.8539,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.454574,
        store_longitude: -77,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1902245,
        store_longitude: -76.6412556,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.68889,
        store_longitude: -77.3122,
    },
    {
        store_latitdue: 0.0833517,
        store_longitude: -76.8824433,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.44072,
        store_longitude: -76.9983461,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.196498,
        store_longitude: -76.6384,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.433453,
        store_longitude: -76.9982,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.467958,
        store_longitude: -76.9865,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.457684,
        store_longitude: -76.9916,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0852324,
        store_longitude: -77.125227,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: -0.0689601,
        store_longitude: -76.8782631,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: 0.077927,
        store_longitude: -76.8951894,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0502891,
        store_longitude: -76.6774,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: -0.6871461,
        store_longitude: -77.3086386,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.46038,
        store_longitude: -76.9971,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.486699,
        store_longitude: -76.9887,
    },
    {
        store_latitdue: 0.0824343,
        store_longitude: -76.9268527,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.0652486,
        store_longitude: -76.8797823,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: 0.0816074,
        store_longitude: -76.882985,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4580586,
        store_longitude: -76.9876698,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.339398,
        store_longitude: -77.8090516,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.4516,
        store_longitude: -77.0259,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.42786,
        store_longitude: -76.9921,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0874139,
        store_longitude: -76.8944,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.096079,
        store_longitude: -76.8897,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.0423656,
        store_longitude: -76.5864569,
        neighborhood: 'PACAYACU',
    },
    {
        store_latitdue: -0.440205,
        store_longitude: -77.0019,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.6904786,
        store_longitude: -77.3098407,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.46907378,
        store_longitude: -76.9861051,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4706494,
        store_longitude: -76.9902085,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.475657,
        store_longitude: -76.9898,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0964432,
        store_longitude: -76.89037,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.104145,
        store_longitude: -76.893018,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0651541,
        store_longitude: -76.8776,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.072193,
        store_longitude: -76.8818957,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.090277,
        store_longitude: -76.8903609,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: -0.46655,
        store_longitude: -76.9879,
    },
    {
        store_latitdue: 0.092057,
        store_longitude: -76.8820085,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0473387,
        store_longitude: -77.3264466,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.259192,
        store_longitude: -76.8546,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.459541914,
        store_longitude: -76.99366326,
    },
    {
        store_latitdue: -0.4623807,
        store_longitude: -76.993775,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.1004252,
        store_longitude: -76.8904234,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0496313,
        store_longitude: -76.67682,
    },
    {
        store_latitdue: -0.472869,
        store_longitude: -76.9818933,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4679254,
        store_longitude: -76.997428,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2911667,
        store_longitude: -76.8586833,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4407995,
        store_longitude: -77.0181112,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.17851,
        store_longitude: -76.5136,
    },
    {
        store_latitdue: -0.4688654,
        store_longitude: -76.9999454,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0879569,
        store_longitude: -76.8826734,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0834099,
        store_longitude: -76.917981,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0807631,
        store_longitude: -76.915487,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.096933,
        store_longitude: -76.8933349,
    },
    {
        store_latitdue: -0.1834684,
        store_longitude: -76.791161,
        neighborhood: 'SIETE DE JULIO',
    },
    {
        store_latitdue: -0.1260512,
        store_longitude: -76.344586,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: -0.226539,
        store_longitude: -76.8486,
        neighborhood: 'ENOKANQUI',
    },
    {
        store_latitdue: -0.0669416,
        store_longitude: -76.8782285,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.30281,
        store_longitude: -76.8568,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.137842,
        store_longitude: -76.8519,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: -0.48831,
        store_longitude: -76.989,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.461239,
        store_longitude: -76.9944,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.466631,
        store_longitude: -76.9949784,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0782184,
        store_longitude: -76.886926,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4659983,
        store_longitude: -76.983568,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0995379,
        store_longitude: -76.8779177,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0832973,
        store_longitude: -76.9446,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: 0.0497841,
        store_longitude: -76.6766106,
        neighborhood: 'DURENO',
    },
    {
        store_latitdue: -0.1376227,
        store_longitude: -76.8534316,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },

    {
        store_latitdue: 0.081477,
        store_longitude: -76.8831,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4630504,
        store_longitude: -76.9887935,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0928558,
        store_longitude: -76.881408,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.08332,
        store_longitude: -76.88719,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.664408,
        store_longitude: -76.8775,
        neighborhood: 'DAYUMA',
    },
    {
        store_latitdue: -0.126129,
        store_longitude: -76.3439,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: -0.466984,
        store_longitude: -76.9879348,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.084717,
        store_longitude: -77.0757845,
        neighborhood: 'JAMBELÍ',
    },
    {
        store_latitdue: -0.449635,
        store_longitude: -77.0106,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4917495,
        store_longitude: -76.776207,
        neighborhood: 'TARACOA',
    },
    {
        store_latitdue: -0.6873605,
        store_longitude: -77.3091192,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.0944565,
        store_longitude: -76.8814468,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0839617,
        store_longitude: -76.8990109,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0723134,
        store_longitude: -76.8852829,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1855245,
        store_longitude: -76.7833821,
        neighborhood: 'SIETE DE JULIO',
    },
    {
        store_latitdue: -0.0419883,
        store_longitude: -76.5873322,
        neighborhood: 'PACAYACU',
    },
    {
        store_latitdue: -0.454015,
        store_longitude: -77.0287,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1823034,
        store_longitude: -76.6418819,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0931574,
        store_longitude: -76.8958131,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1816921,
        store_longitude: -76.641166,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.19143,
        store_longitude: -76.6406,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.4672802,
        store_longitude: -76.9884507,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4670891,
        store_longitude: -76.9868494,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0770967,
        store_longitude: -77.2673599,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.4921337,
        store_longitude: -76.775078,
        neighborhood: 'TARACOA',
    },
    {
        store_latitdue: -0.4451657,
        store_longitude: -77.0126492,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.486201,
        store_longitude: -76.9881,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.071646,
        store_longitude: -76.8952,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -2.74192,
        store_longitude: -78.848593,
    },
    {
        store_latitdue: 0.0770746,
        store_longitude: -77.267988,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.4416981,
        store_longitude: -76.9921759,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.08581,
        store_longitude: -76.88607,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0953851,
        store_longitude: -76.896,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0998633,
        store_longitude: -76.8934,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.0689924,
        store_longitude: -76.933134,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.2990027,
        store_longitude: -76.8526087,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },

    {
        store_latitdue: -0.465928,
        store_longitude: -77.0013,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.442761,
        store_longitude: -77.0044,
    },
    {
        store_latitdue: -0.429059,
        store_longitude: -77.0093,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4742227,
        store_longitude: -76.9878734,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.459282,
        store_longitude: -76.9956,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0716016,
        store_longitude: -76.8904417,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: -0.1794609,
        store_longitude: -76.6395214,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.1312674,
        store_longitude: -76.2990839,
        neighborhood: 'AGUAS NEGRAS',
    },
    {
        store_latitdue: -0.4657239,
        store_longitude: -76.9964576,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.2884527,
        store_longitude: -76.8670046,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.3216184,
        store_longitude: -76.4869226,
    },
    {
        store_latitdue: -0.467702,
        store_longitude: -76.9835,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0790487,
        store_longitude: -76.9336949,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.072854,
        store_longitude: -76.8883,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -1.28554,
        store_longitude: -78.6655,
    },
    {
        store_latitdue: -0.4576205,
        store_longitude: -76.991657,
    },
    {
        store_latitdue: -0.288485,
        store_longitude: -76.8650306,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4290624,
        store_longitude: -77.0080913,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0842072,
        store_longitude: -76.9114,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.6693136,
        store_longitude: -76.8762912,
    },
    {
        store_latitdue: -0.2851633,
        store_longitude: -76.8557617,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.687502945,
        store_longitude: -77.309088,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.0845918,
        store_longitude: -76.8871999,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.3292521,
        store_longitude: -77.7972115,
    },
    {
        store_latitdue: -0.689509,
        store_longitude: -77.3086,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.3036455,
        store_longitude: -76.8625968,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4471958,
        store_longitude: -77.0193959,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.448867,
        store_longitude: -77.0084,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4658921,
        store_longitude: -76.989778,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0364633,
        store_longitude: -76.5967,
        neighborhood: 'PACAYACU',
    },
    {
        store_latitdue: 0.0984038,
        store_longitude: -76.8835197,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0798224,
        store_longitude: -77.2123341,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: 0.0774708,
        store_longitude: -76.918456,
    },
    {
        store_latitdue: -0.1780411,
        store_longitude: -76.8355164,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: -0.6859401,
        store_longitude: -77.3134485,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.1862409,
        store_longitude: -76.6440644,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0834743,
        store_longitude: -76.8999959,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0823691,
        store_longitude: -76.9349471,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4683679,
        store_longitude: -76.988711,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.178773,
        store_longitude: -76.8358916,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: -0.2981025,
        store_longitude: -76.8569806,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0844922,
        store_longitude: -77.0772198,
    },
    {
        store_latitdue: -0.3792382,
        store_longitude: -76.8825994,
        neighborhood: 'SAN CARLOS',
    },
    {
        store_latitdue: -0.1887589,
        store_longitude: -76.6484445,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.188378666,
        store_longitude: -76.63280502,
    },
    {
        store_latitdue: -0.472281,
        store_longitude: -76.9832,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4445717,
        store_longitude: -77.0139551,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0857786,
        store_longitude: -76.8829316,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.441703,
        store_longitude: -77.0036,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: -0.4366826,
        store_longitude: -77.0072994,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.682928,
        store_longitude: -77.3083,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.4787194,
        store_longitude: -77.067695,
        neighborhood: 'SAN LUIS DE ARMENIA',
    },
    {
        store_latitdue: -0.47848,
        store_longitude: -77.0693,
        neighborhood: 'SAN LUIS DE ARMENIA',
    },
    {
        store_latitdue: -0.6998729,
        store_longitude: -77.3127957,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.47302,
        store_longitude: -77.0517,
        neighborhood: 'SAN LUIS DE ARMENIA',
    },
    {
        store_latitdue: -0.690253,
        store_longitude: -77.3112,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.1837255,
        store_longitude: -76.6455745,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.4732412,
        store_longitude: -76.9866028,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.7165371,
        store_longitude: -77.3418496,
        neighborhood: 'ÁVILA',
    },
    {
        store_latitdue: -0.688615,
        store_longitude: -77.3079075,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.09987,
        store_longitude: -76.88955,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.288382,
        store_longitude: -76.8563,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.6836167,
        store_longitude: -77.3106167,
    },
    {
        store_latitdue: -0.1337716,
        store_longitude: -76.3026511,
        neighborhood: 'AGUAS NEGRAS',
    },
    {
        store_latitdue: 0.0814957,
        store_longitude: -76.8829219,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4364511,
        store_longitude: -76.9928854,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0810258,
        store_longitude: -76.9914895,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: 0.0769481,
        store_longitude: -77.2177,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.68302,
        store_longitude: -77.3105,
    },
    {
        store_latitdue: -0.2929167,
        store_longitude: -76.8567833,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1857205,
        store_longitude: -76.7793373,
    },
    {
        store_latitdue: -0.44850325,
        store_longitude: -77.0162734,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0849083,
        store_longitude: -77.1265728,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: -0.472129,
        store_longitude: -76.9833,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.087272331,
        store_longitude: -76.9513854,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: -0.684984,
        store_longitude: -77.308937,
    },
    {
        store_latitdue: -0.461582,
        store_longitude: -76.9963,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0815065,
        store_longitude: -76.8847431,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0103268,
        store_longitude: -77.3862496,
        neighborhood: 'GONZALO PIZARRO',
    },
    {
        store_latitdue: -0.437111,
        store_longitude: -77.0024,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.125856,
        store_longitude: -76.3447,
        neighborhood: 'TARAPOA',
    },
    {
        store_latitdue: -0.12610095,
        store_longitude: -76.3430099,
        neighborhood: 'TARAPOA',
    },

    {
        store_latitdue: -0.1822196,
        store_longitude: -76.6421526,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.30289508,
        store_longitude: -76.8504381,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.7334595,
        store_longitude: -77.3697492,
    },
    {
        store_latitdue: 0.0476844,
        store_longitude: -77.3275,
    },
    {
        store_latitdue: 0.0844059,
        store_longitude: -76.9602105,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: 0.076400152,
        store_longitude: -76.8859084,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0864204,
        store_longitude: -77.1264911,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: 0.0478984,
        store_longitude: -77.3276,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.4539181,
        store_longitude: -76.9986166,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.18251,
        store_longitude: -76.6374,
    },
    {
        store_latitdue: 0.0845551,
        store_longitude: -76.884731,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0867722,
        store_longitude: -76.915842,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.085970131,
        store_longitude: -76.8876886,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0816499,
        store_longitude: -76.8663907,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.187566,
        store_longitude: -76.6486,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.291953464,
        store_longitude: -76.8570247852,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.4680104,
        store_longitude: -76.9909175,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0775417,
        store_longitude: -76.90764,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4499772,
        store_longitude: -76.9971977,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0449707,
        store_longitude: -77.3300989,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.4482788,
        store_longitude: -77.0165363,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.080773,
        store_longitude: -76.8841267,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.179847,
        store_longitude: -76.650517,
    },
    {
        store_latitdue: -0.3033823,
        store_longitude: -76.8508854,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.2979993,
        store_longitude: -76.855083,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.436337739,
        store_longitude: -76.99939728,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.466862,
        store_longitude: -76.9915,
    },
    {
        store_latitdue: -0.4725153826,
        store_longitude: -76.9822056592,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.083396,
        store_longitude: -76.88,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1861007,
        store_longitude: -76.7783343,
        neighborhood: 'SIETE DE JULIO',
    },
    {
        store_latitdue: -0.0687382,
        store_longitude: -76.8788621,
        neighborhood: 'EL ENO',
    },
    {
        store_latitdue: -0.2847879,
        store_longitude: -76.8530257,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.293512,
        store_longitude: -76.8537,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.6986118,
        store_longitude: -77.3137184,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: -0.6908689,
        store_longitude: -77.3089986,
        neighborhood: 'LORETO',
    },
    {
        store_latitdue: 0.09474,
        store_longitude: -76.87317,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.081975,
        store_longitude: -76.9157428,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0790462,
        store_longitude: -76.933695,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.08241,
        store_longitude: -76.9071,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.2948167,
        store_longitude: -76.8551167,
    },
    {
        store_latitdue: -0.0416012,
        store_longitude: -76.5881,
    },
    {
        store_latitdue: -0.281981,
        store_longitude: -76.8625,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0808448,
        store_longitude: -76.9150027,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.07139,
        store_longitude: -76.8922402,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.1836784,
        store_longitude: -76.6454444,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.443053,
        store_longitude: -76.9929,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4560206,
        store_longitude: -77.0017162,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.083905,
        store_longitude: -76.884,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0894438,
        store_longitude: -76.8964,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.334861,
        store_longitude: -77.8072,
    },
    {
        store_latitdue: 0.0759751,
        store_longitude: -77.2136937,
        neighborhood: 'EL DORADO DE CASCALES',
    },
    {
        store_latitdue: -0.19055701,
        store_longitude: -76.641838,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.1880216146,
        store_longitude: -76.6555486942,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0930475,
        store_longitude: -76.8835,
    },
    {
        store_latitdue: -0.1787246,
        store_longitude: -76.8357448,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: -0.1793178,
        store_longitude: -76.6417502,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.3096807,
        store_longitude: -77.7836103,
        neighborhood: 'SANTA ROSA',
    },
    {
        store_latitdue: -0.1910309,
        store_longitude: -76.6407734,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.2981071,
        store_longitude: -76.8551705,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.294407,
        store_longitude: -76.8577382,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0801034,
        store_longitude: -76.9090465,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.471581,
        store_longitude: -76.9832,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: -0.4670937,
        store_longitude: -76.9899086,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0817162,
        store_longitude: -76.882997,
    },
    {
        store_latitdue: -0.4735732,
        store_longitude: -76.9839404,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.6913,
        store_longitude: -77.3086833,
        neighborhood: 'LORETO',
    },

    {
        store_latitdue: -0.4500498817,
        store_longitude: -77.0277588981,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0993363,
        store_longitude: -76.8824283,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.4734154,
        store_longitude: -76.9866059,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.190267,
        store_longitude: -76.6489599,
        neighborhood: 'SHUSHUFINDI',
    },

    {
        store_latitdue: -0.45367,
        store_longitude: -77.0327,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.083198,
        store_longitude: -76.9448,
    },
    {
        store_latitdue: -0.434728,
        store_longitude: -76.9945,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0898015671,
        store_longitude: -76.9021090161,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: -0.339857,
        store_longitude: -77.8099,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: 0.0851034,
        store_longitude: -76.8940156,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: -0.1832975,
        store_longitude: -76.6429144,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.1883289,
        store_longitude: -76.6401959,
    },

    {
        store_latitdue: -0.1884396,
        store_longitude: -76.64831,
    },
    {
        store_latitdue: 0.0835626,
        store_longitude: -77.0207,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: -0.679955,
        store_longitude: -77.310707,
        neighborhood: 'LORETO',
    },

    {
        store_latitdue: -0.0827648,
        store_longitude: -76.9853204,
        neighborhood: 'RUMIPAMBA',
    },

    {
        store_latitdue: -0.3344249,
        store_longitude: -76.9957131,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: -0.1884208,
        store_longitude: -76.6501941,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.3107678,
        store_longitude: -76.8606447,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: 0.0895925,
        store_longitude: -76.9008262,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: -0.45893034,
        store_longitude: -76.9910609,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.0713246,
        store_longitude: -77.5799864,
    },

    {
        store_latitdue: -2.2410657,
        store_longitude: -79.9102185,
        neighborhood: 'GUAYAQUIL',
    },
    {
        store_latitdue: 0.0821641,
        store_longitude: -76.8842389,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0963068,
        store_longitude: -76.8835,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.2927289,
        store_longitude: -76.8565577,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.6891093,
        store_longitude: -77.311115,
    },

    {
        store_latitdue: -0.4307321,
        store_longitude: -77.0067917,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.1882256,
        store_longitude: -76.6441192,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.3314,
        store_longitude: -76.9907829,
        neighborhood: 'SAN SEBASTIÁN DEL COCA',
    },
    {
        store_latitdue: 0.0961745,
        store_longitude: -76.8835424,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.44219,
        store_longitude: -76.9996566,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -1.04243,
        store_longitude: -77.795399,
    },
    {
        store_latitdue: -0.177772,
        store_longitude: -76.8353797,
        neighborhood: 'SAN PEDRO DE LOS COFÁNES',
    },
    {
        store_latitdue: 0.0850203,
        store_longitude: -77.1239309,
        neighborhood: 'SEVILLA',
    },
    {
        store_latitdue: -0.1880649,
        store_longitude: -76.6341816,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0816162,
        store_longitude: -76.8998113,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.3007289,
        store_longitude: -76.8581782,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },

    {
        store_latitdue: -2.22204,
        store_longitude: -80.9114,
    },
    {
        store_latitdue: -0.185734,
        store_longitude: -76.6912,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0855135,
        store_longitude: -77.0756594,
        neighborhood: 'JAMBELÍ',
    },
    {
        store_latitdue: 0.0901949,
        store_longitude: -76.9517036,
        neighborhood: 'SANTA CECILIA',
    },
    {
        store_latitdue: 0.0945755,
        store_longitude: -76.8869,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.1002604,
        store_longitude: -76.8905619,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0452521,
        store_longitude: -77.3313,
        neighborhood: 'LUMBAQUÍ',
    },
    {
        store_latitdue: -0.49194,
        store_longitude: -76.7747258,
        neighborhood: 'TARACOA',
    },

    {
        store_latitdue: -0.4649745,
        store_longitude: -76.9953833,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: 0.09234,
        store_longitude: -76.88768,
    },

    {
        store_latitdue: -0.465057,
        store_longitude: -76.9877,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: 0.0826362,
        store_longitude: -76.880557,
    },
    {
        store_latitdue: 0.0789153,
        store_longitude: -77.2123823,
        neighborhood: 'EL DORADO DE CASCALES',
    },

    {
        store_latitdue: -0.333832,
        store_longitude: -77.8131,
        neighborhood: 'EL CHACO',
    },
    {
        store_latitdue: -0.1776054,
        store_longitude: -76.6458344,
        neighborhood: 'SHUSHUFINDI',
    },

    {
        store_latitdue: 0.0841104,
        store_longitude: -76.899662,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.450248,
        store_longitude: -76.9971,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.4483,
        store_longitude: -76.9979,
        neighborhood: 'EL COCA',
    },

    {
        store_latitdue: 0.0814136,
        store_longitude: -76.8834256,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.298365,
        store_longitude: -76.8575,
    },
    {
        store_latitdue: -0.3554322,
        store_longitude: -76.8150823,
        neighborhood: 'UNIÓN MILAGREÑA',
    },
    {
        store_latitdue: -0.38923152,
        store_longitude: -76.980375,
        neighborhood: 'NUEVO PARAÍSO',
    },
    {
        store_latitdue: -0.1854955,
        store_longitude: -76.7817152,
        neighborhood: 'SIETE DE JULIO',
    },
    {
        store_latitdue: -0.2244027,
        store_longitude: -76.9077576,
        neighborhood: 'LAGO SAN PEDRO',
    },
    {
        store_latitdue: -0.1838446,
        store_longitude: -76.6529874,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0832345026,
        store_longitude: -76.8795325691,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -3.327132,
        store_longitude: -79.810831,
    },

    {
        store_latitdue: -0.3034948,
        store_longitude: -76.8553297,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },

    {
        store_latitdue: -0.185854661,
        store_longitude: -76.64654341,
        neighborhood: 'SHUSHUFINDI',
    },

    {
        store_latitdue: 0.0832202,
        store_longitude: -76.894606,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0871775,
        store_longitude: -76.8943513,
        neighborhood: 'NUEVA LOJA',
    },

    {
        store_latitdue: -0.1858228,
        store_longitude: -76.6535476,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.1874365,
        store_longitude: -76.6500929,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: 0.0848164,
        store_longitude: -76.8841665,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0905265282,
        store_longitude: -76.883444041,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.085358,
        store_longitude: -76.8807,
    },
    {
        store_latitdue: 0.0944737,
        store_longitude: -76.8833986,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0931014,
        store_longitude: -76.8941978,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0830403,
        store_longitude: -76.894952,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: -0.2995915,
        store_longitude: -76.8553239,
    },
    {
        store_latitdue: -0.2880233,
        store_longitude: -76.8576704,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.1881392,
        store_longitude: -76.6426858,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.1995256,
        store_longitude: -76.6288961,
        neighborhood: 'SHUSHUFINDI',
    },

    {
        store_latitdue: 0.0915009,
        store_longitude: -76.8865848,
        neighborhood: 'NUEVA LOJA',
    },
    {
        store_latitdue: 0.0823359,
        store_longitude: -76.8843557,
    },
    {
        store_latitdue: -0.188199225,
        store_longitude: -76.64813174,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.2506415,
        store_longitude: -76.8546704,
        neighborhood: 'LA JOYA DE LOS SACHAS',
    },
    {
        store_latitdue: -0.0854341015,
        store_longitude: -76.644462585,
        neighborhood: 'SHUSHUFINDI',
    },
    {
        store_latitdue: -0.2081702,
        store_longitude: -78.4864878,
    },
    {
        store_latitdue: -0.110364,
        store_longitude: -78.4996201,
    },
    {
        store_latitdue: -0.1606195,
        store_longitude: -78.4863514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1567723,
        store_longitude: -78.4672526,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1442437,
        store_longitude: -78.4814192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0980076,
        store_longitude: -78.4217525,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1485915,
        store_longitude: -78.4535598,
    },

    {
        store_latitdue: -0.202138,
        store_longitude: -78.48986,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2027567982,
        store_longitude: -78.4907137964,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118219,
        store_longitude: -78.4553,
    },
    {
        store_latitdue: 0.0945169,
        store_longitude: -78.7400367,
    },
    {
        store_latitdue: -0.140216,
        store_longitude: -78.4721,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.146747,
        store_longitude: -78.474242,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.140634,
        store_longitude: -78.476835,
    },

    {
        store_latitdue: -0.1459528,
        store_longitude: -78.4642228,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0937191,
        store_longitude: -78.4524927,
    },
    {
        store_latitdue: -0.143954,
        store_longitude: -78.4797,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1377795799,
        store_longitude: -78.4566508234,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140377,
        store_longitude: -78.465928,
    },
    {
        store_latitdue: -0.1452144,
        store_longitude: -78.4627631,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1438834,
        store_longitude: -78.4789437,
    },

    {
        store_latitdue: -0.1363576,
        store_longitude: -78.4608328,
    },
    {
        store_latitdue: -0.1620242,
        store_longitude: -78.4824878,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.150957,
        store_longitude: -78.473126,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147881,
        store_longitude: -78.472115,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146324,
        store_longitude: -78.478886,
    },
    {
        store_latitdue: -0.1435052,
        store_longitude: -78.4534393,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143613,
        store_longitude: -78.4533,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.146107,
        store_longitude: -78.4827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1459007,
        store_longitude: -78.4558465,
    },

    {
        store_latitdue: -2.20981,
        store_longitude: -79.8896,
        neighborhood: 'GUAYAQUIL',
    },

    {
        store_latitdue: -0.155127,
        store_longitude: -78.4738,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.149426,
        store_longitude: -78.480317,
    },
    {
        store_latitdue: -0.1581683,
        store_longitude: -78.4610342,
    },

    {
        store_latitdue: -0.2207154,
        store_longitude: -78.525109,
    },

    {
        store_latitdue: -0.1595097,
        store_longitude: -78.4642029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.158699,
        store_longitude: -78.488172,
    },

    {
        store_latitdue: -0.1704044,
        store_longitude: -78.4790051,
    },
    {
        store_latitdue: -0.29144,
        store_longitude: -78.5766,
    },
    {
        store_latitdue: -0.20406095,
        store_longitude: -78.400093,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.11555,
        store_longitude: -78.489499,
    },
    {
        store_latitdue: -0.1712827,
        store_longitude: -78.4734054,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.170957,
        store_longitude: -78.475,
    },

    {
        store_latitdue: -0.163061,
        store_longitude: -78.474752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1691496,
        store_longitude: -78.4864069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.163273,
        store_longitude: -78.4749,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1789674,
        store_longitude: -78.4759372,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.126025,
        store_longitude: -78.4661,
    },
    {
        store_latitdue: -0.1643742,
        store_longitude: -78.4828839,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.178767,
        store_longitude: -78.4768,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.162735,
        store_longitude: -78.4744,
    },
    {
        store_latitdue: -0.1715427,
        store_longitude: -78.4721193,
    },
    {
        store_latitdue: -0.1784706,
        store_longitude: -78.4782282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1646119,
        store_longitude: -78.4820838,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1678344,
        store_longitude: -78.4718028,
    },
    {
        store_latitdue: -0.1590052951,
        store_longitude: -78.4881453592,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1666402,
        store_longitude: -78.4703243,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.171819,
        store_longitude: -78.4867,
    },
    {
        store_latitdue: -3.27289,
        store_longitude: -79.9465,
        neighborhood: 'MACHALA',
    },
    {
        store_latitdue: -0.170008,
        store_longitude: -78.4765,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1623666,
        store_longitude: -78.474323,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.066835,
        store_longitude: -78.452587,
    },
    {
        store_latitdue: -0.172397,
        store_longitude: -78.4761586,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.162132,
        store_longitude: -78.4744,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139966,
        store_longitude: -78.474996,
    },
    {
        store_latitdue: -0.1725031,
        store_longitude: -78.4762868,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.172386,
        store_longitude: -78.485752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1622843,
        store_longitude: -78.474416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1727565,
        store_longitude: -78.4858774,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1543578,
        store_longitude: -78.4716098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09384,
        store_longitude: -78.28303,
    },
    {
        store_latitdue: -0.193438,
        store_longitude: -78.5039,
    },
    {
        store_latitdue: -0.1675669,
        store_longitude: -78.4784468,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1702632,
        store_longitude: -78.4794009,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1601056,
        store_longitude: -78.4740032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17254,
        store_longitude: -78.485925,
    },
    {
        store_latitdue: -0.1726621,
        store_longitude: -78.4862464,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1599509,
        store_longitude: -78.4748503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.173385,
        store_longitude: -78.4779816,
    },

    {
        store_latitdue: -0.1700464,
        store_longitude: -78.4862173,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1700233,
        store_longitude: -78.4842265,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.157557,
        store_longitude: -78.4766,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.182693,
        store_longitude: -78.4749,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1576188,
        store_longitude: -78.4764041,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17011,
        store_longitude: -78.483454,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.170122,
        store_longitude: -78.4837,
    },

    {
        store_latitdue: -0.16948141,
        store_longitude: -78.4829864,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1579284,
        store_longitude: -78.4754738,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1738799,
        store_longitude: -78.4811494,
    },
    {
        store_latitdue: -0.20013,
        store_longitude: -78.49266,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.200848,
        store_longitude: -78.4890206,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1913246,
        store_longitude: -78.4832745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252226,
        store_longitude: -78.531099,
    },

    {
        store_latitdue: -0.093311,
        store_longitude: -78.4495,
    },
    {
        store_latitdue: -0.2041926,
        store_longitude: -78.4891342,
    },
    {
        store_latitdue: -3.9345813,
        store_longitude: -79.2246818,
        neighborhood: 'LOJA',
    },
    {
        store_latitdue: -0.190612,
        store_longitude: -78.4826,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.190137,
        store_longitude: -78.482243,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19551233,
        store_longitude: -78.4781494,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1838476,
        store_longitude: -78.4893281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204452,
        store_longitude: -78.490148,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.1241288,
        store_longitude: -79.9092067,
        neighborhood: 'GUAYAQUIL',
    },

    {
        store_latitdue: -0.1995164,
        store_longitude: -78.4862028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204845,
        store_longitude: -78.4924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18862,
        store_longitude: -78.481853,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1836631877,
        store_longitude: -78.4880848984,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18429,
        store_longitude: -78.4877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199205,
        store_longitude: -78.487186,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -4.3304646,
        store_longitude: -79.5543302,
        neighborhood: 'CARIAMANGA',
    },
    {
        store_latitdue: -0.2048355,
        store_longitude: -78.4924052,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1994403,
        store_longitude: -78.4886408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1959785,
        store_longitude: -78.4785471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.197295,
        store_longitude: -78.4789411,
    },
    {
        store_latitdue: -0.1858319,
        store_longitude: -78.4882198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.192891,
        store_longitude: -78.485244,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2020924,
        store_longitude: -78.4804104,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.184678,
        store_longitude: -78.4906,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1929231,
        store_longitude: -78.4851529,
    },
    {
        store_latitdue: -0.185124,
        store_longitude: -78.490123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.193033,
        store_longitude: -78.48516,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.197919,
        store_longitude: -78.496826,
    },
    {
        store_latitdue: -0.193958,
        store_longitude: -78.485562,
    },
    {
        store_latitdue: -0.2042338,
        store_longitude: -78.4829029,
    },
    {
        store_latitdue: -0.1867315,
        store_longitude: -78.4903137,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1933032,
        store_longitude: -78.4858431,
    },
    {
        store_latitdue: -0.2021519,
        store_longitude: -78.4864309,
    },
    {
        store_latitdue: -0.19965474,
        store_longitude: -78.4909439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.200097,
        store_longitude: -78.484992,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1985417,
        store_longitude: -78.4905711,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186819676,
        store_longitude: -78.4900394916,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1958659,
        store_longitude: -78.4844034,
    },
    {
        store_latitdue: -0.197951,
        store_longitude: -78.482025,
    },
    {
        store_latitdue: -0.188416,
        store_longitude: -78.4888499,
    },
    {
        store_latitdue: -0.116657,
        store_longitude: -78.487043,
    },

    {
        store_latitdue: -0.1948571,
        store_longitude: -78.4823338,
    },
    {
        store_latitdue: -0.206722,
        store_longitude: -78.500754,
    },
    {
        store_latitdue: -0.19706,
        store_longitude: -78.493343,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1562106,
        store_longitude: -78.4791693,
    },

    {
        store_latitdue: -0.198742,
        store_longitude: -78.4937,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1887961,
        store_longitude: -78.4890105,
    },
    {
        store_latitdue: -0.1916539,
        store_longitude: -78.4842088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1885884,
        store_longitude: -78.488488,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2000704,
        store_longitude: -78.4938895,
    },
    {
        store_latitdue: -0.2014185,
        store_longitude: -78.4890206,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1931859,
        store_longitude: -78.483805,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1937212,
        store_longitude: -78.4917699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1938105,
        store_longitude: -78.4915905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318598,
        store_longitude: -78.5488,
    },

    {
        store_latitdue: -0.3324264,
        store_longitude: -78.4716289,
        neighborhood: 'SANGOLQUÍ',
    },
    {
        store_latitdue: -0.19802652,
        store_longitude: -78.4892807,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.192406,
        store_longitude: -78.493408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2013266,
        store_longitude: -78.4905197,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -2.3314233,
        store_longitude: -79.4025997,
    },

    {
        store_latitdue: -0.1917634,
        store_longitude: -78.4909681,
    },
    {
        store_latitdue: -0.1910001,
        store_longitude: -78.4891876,
    },
    {
        store_latitdue: -0.2024442,
        store_longitude: -78.4908817,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.191223,
        store_longitude: -78.491477,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1910879,
        store_longitude: -78.4906249,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.212577,
        store_longitude: -78.4844,
    },
    {
        store_latitdue: -0.2130503,
        store_longitude: -78.4956507,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208757,
        store_longitude: -78.493696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213178,
        store_longitude: -78.496542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -1.570735,
        store_longitude: -79.4726212,
        neighborhood: 'CATARAMA',
    },
    {
        store_latitdue: -0.21391795,
        store_longitude: -78.4853973,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.212782,
        store_longitude: -78.48796,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2129129,
        store_longitude: -78.4863826,
    },
    {
        store_latitdue: -0.2113999,
        store_longitude: -78.4852549,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2103333,
        store_longitude: -78.4824751,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2105808,
        store_longitude: -78.4820263,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214504,
        store_longitude: -78.485203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215705,
        store_longitude: -78.4963,
    },
    {
        store_latitdue: -0.211296,
        store_longitude: -78.4853,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08876794,
        store_longitude: -78.4642105,
    },
    {
        store_latitdue: -0.208785,
        store_longitude: -78.4859,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2108326,
        store_longitude: -78.4822149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21816,
        store_longitude: -78.487201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.211161,
        store_longitude: -78.4823,
    },
    {
        store_latitdue: -0.208164,
        store_longitude: -78.4910733,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2063924,
        store_longitude: -78.496311,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.219872,
        store_longitude: -78.4871331,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.1506235,
        store_longitude: -79.588056,
        neighborhood: 'MILAGRO',
    },
    {
        store_latitdue: -0.2162024,
        store_longitude: -78.4954099,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.219057,
        store_longitude: -78.486548,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.210001,
        store_longitude: -78.4837,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214899,
        store_longitude: -78.4868,
    },
    {
        store_latitdue: -0.2060122,
        store_longitude: -78.4969999,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2186177,
        store_longitude: -78.4832889,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20809,
        store_longitude: -78.49108,
    },
    {
        store_latitdue: -0.218666,
        store_longitude: -78.481742,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2049555356,
        store_longitude: -78.4981958941,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2124565,
        store_longitude: -78.4846691,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.221351,
        store_longitude: -78.484644,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2069961,
        store_longitude: -78.4922845,
    },
    {
        store_latitdue: -0.219786,
        store_longitude: -78.485264,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.220692,
        store_longitude: -78.485401,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.992271,
        store_longitude: -79.4727,
        neighborhood: 'QUEVEDO',
    },
    {
        store_latitdue: -0.2164579,
        store_longitude: -78.4959409,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.220159,
        store_longitude: -78.4842,
    },
    {
        store_latitdue: -0.205915,
        store_longitude: -78.4902,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.219345,
        store_longitude: -78.4855,
    },
    {
        store_latitdue: -0.20559534,
        store_longitude: -78.4917891,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214488,
        store_longitude: -78.486075,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -1.0416854323,
        store_longitude: -79.6503578871,
        neighborhood: 'VELASCO IBARRA',
    },
    {
        store_latitdue: -0.1718035,
        store_longitude: -78.4878361,
    },
    {
        store_latitdue: -0.2190893,
        store_longitude: -78.4854704,
    },
    {
        store_latitdue: -0.2071242,
        store_longitude: -78.4909913,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214604,
        store_longitude: -78.486273,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209684,
        store_longitude: -78.479492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.207505,
        store_longitude: -78.478829,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2146786,
        store_longitude: -78.4863935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2150112,
        store_longitude: -78.48681,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -3.9897677,
        store_longitude: -79.3401732,
        neighborhood: 'CATAMAYO',
    },
    {
        store_latitdue: -4.03243,
        store_longitude: -79.2086,
        neighborhood: 'LOJA',
    },
    {
        store_latitdue: -0.204644,
        store_longitude: -78.476698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215419,
        store_longitude: -78.488789,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204841,
        store_longitude: -78.4935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215686,
        store_longitude: -78.488044,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206953,
        store_longitude: -78.491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },
    {
        store_latitdue: 0.133319,
        store_longitude: -78.185617,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.2156234,
        store_longitude: -78.4898664,
    },
    {
        store_latitdue: -0.21785,
        store_longitude: -78.4908021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.1381321,
        store_longitude: -79.4041867,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.227733,
        store_longitude: -78.485577,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2281019,
        store_longitude: -78.4838124,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2769199,
        store_longitude: -79.1793909,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.22831,
        store_longitude: -78.482998,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22101,
        store_longitude: -78.5071,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22798516,
        store_longitude: -78.48611,
    },
    {
        store_latitdue: -0.228164,
        store_longitude: -78.4824,
    },
    {
        store_latitdue: -0.2304631,
        store_longitude: -78.4916591,
    },
    {
        store_latitdue: -0.012467,
        store_longitude: -78.448278,
    },
    {
        store_latitdue: -0.2231308,
        store_longitude: -78.5056312,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2295888,
        store_longitude: -78.4812449,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235257,
        store_longitude: -78.4848,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2235483,
        store_longitude: -78.5052129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223424,
        store_longitude: -78.5042,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2239217,
        store_longitude: -78.5045409,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2209657,
        store_longitude: -78.5071735,
    },
    {
        store_latitdue: -0.2303295,
        store_longitude: -78.492408,
    },
    {
        store_latitdue: -0.219762,
        store_longitude: -78.507,
    },
    {
        store_latitdue: -2.81956,
        store_longitude: -78.7572,
        neighborhood: 'CHICÁN',
    },

    {
        store_latitdue: -0.22736,
        store_longitude: -78.4904,
    },
    {
        store_latitdue: -0.22892,
        store_longitude: -78.480475,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.228655,
        store_longitude: -78.4811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.224457,
        store_longitude: -78.5054,
    },

    {
        store_latitdue: -0.2248998,
        store_longitude: -78.5045269,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2205649,
        store_longitude: -78.5069146,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2172532,
        store_longitude: -78.5095596,
    },
    {
        store_latitdue: -0.229828,
        store_longitude: -78.4833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225939,
        store_longitude: -78.5046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2262643,
        store_longitude: -78.5045503,
    },
    {
        store_latitdue: -0.225524,
        store_longitude: -78.49,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.231618,
        store_longitude: -78.4831,
    },
    {
        store_latitdue: -0.2283296,
        store_longitude: -78.4908383,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2324083,
        store_longitude: -78.4874582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.227804,
        store_longitude: -78.5039048,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -1.3238274,
        store_longitude: -78.5423215,
        neighborhood: 'GARCÍA MORENO',
    },
    {
        store_latitdue: -0.2274805,
        store_longitude: -78.504236,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.231247,
        store_longitude: -78.4889162,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2774942,
        store_longitude: -79.1798353,
        neighborhood: 'SANTO DOMINGO DE LOS COLORADOS',
    },
    {
        store_latitdue: -0.20652,
        store_longitude: -78.4963,
    },
    {
        store_latitdue: -0.2230015,
        store_longitude: -78.4895161,
    },
    {
        store_latitdue: -0.2220789,
        store_longitude: -78.5039934,
    },
    {
        store_latitdue: -0.2837751,
        store_longitude: -78.5583485,
    },

    {
        store_latitdue: -0.22332,
        store_longitude: -78.489377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2288969,
        store_longitude: -78.5047,
    },
    {
        store_latitdue: -0.2232855,
        store_longitude: -78.4892869,
    },
    {
        store_latitdue: -0.226757,
        store_longitude: -78.49166,
    },
    {
        store_latitdue: -0.229785,
        store_longitude: -78.50398,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1731157,
        store_longitude: -78.4863849,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2235551,
        store_longitude: -78.4856289,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229336,
        store_longitude: -78.5017,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241112,
        store_longitude: -78.4862,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2235979,
        store_longitude: -78.4850266,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.228886,
        store_longitude: -78.5017,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.231976,
        store_longitude: -78.4832,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.228927,
        store_longitude: -78.500996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2304528,
        store_longitude: -78.492039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.231818,
        store_longitude: -78.4831,
    },
    {
        store_latitdue: -0.2303002,
        store_longitude: -78.4923905,
    },
    {
        store_latitdue: -0.2291666,
        store_longitude: -78.5001017,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.230404,
        store_longitude: -78.4927,
    },
    {
        store_latitdue: -0.228228,
        store_longitude: -78.506704,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2572883,
        store_longitude: -78.5177098,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.230436,
        store_longitude: -78.4928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2280118,
        store_longitude: -78.506903,
    },
    {
        store_latitdue: -1.6582615552,
        store_longitude: -78.6416929971,
        neighborhood: 'RIOBAMBA',
    },
    {
        store_latitdue: -0.2182435,
        store_longitude: -78.5058944,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23395,
        store_longitude: -78.483634,
    },

    {
        store_latitdue: -0.2292908,
        store_longitude: -78.500041,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.9922838,
        store_longitude: -80.6960494,
        neighborhood: 'MONTECRISTI',
    },

    {
        store_latitdue: -0.2305002,
        store_longitude: -78.4929124,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2207365,
        store_longitude: -78.5055098,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2324469,
        store_longitude: -78.4940032,
    },
    {
        store_latitdue: -0.2339818,
        store_longitude: -78.4944432,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2368921,
        store_longitude: -78.4915566,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1533994,
        store_longitude: -78.4775605,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1597567,
        store_longitude: -78.4550891,
    },
    {
        store_latitdue: -0.145236,
        store_longitude: -78.4605,
    },
    {
        store_latitdue: -0.1444741,
        store_longitude: -78.4585034,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.23723,
        store_longitude: -78.5303,
    },
    {
        store_latitdue: -0.1403977,
        store_longitude: -78.477601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1644377,
        store_longitude: -78.466652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12001,
        store_longitude: -78.466958,
    },
    {
        store_latitdue: -0.1414228,
        store_longitude: -78.4777933,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144437,
        store_longitude: -78.471654,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146426,
        store_longitude: -78.469715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1466325,
        store_longitude: -78.4542979,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146673,
        store_longitude: -78.457454,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.145905,
        store_longitude: -78.457354,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.148302,
        store_longitude: -78.461636,
    },

    {
        store_latitdue: -0.156871,
        store_longitude: -78.464155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147112,
        store_longitude: -78.4734,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1479785,
        store_longitude: -78.4607585,
    },
    {
        store_latitdue: -0.1569836,
        store_longitude: -78.464258,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156822,
        store_longitude: -78.4671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144558,
        store_longitude: -78.4825,
    },
    {
        store_latitdue: -0.147202,
        store_longitude: -78.4738,
    },
    {
        store_latitdue: -0.147186,
        store_longitude: -78.466841,
    },

    {
        store_latitdue: 0.042293,
        store_longitude: -78.1431,
    },
    {
        store_latitdue: -0.1490711,
        store_longitude: -78.4660125,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14058,
        store_longitude: -78.4788,
    },
    {
        store_latitdue: -0.1460467,
        store_longitude: -78.4640465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.157022,
        store_longitude: -78.466442,
    },
    {
        store_latitdue: -0.146196,
        store_longitude: -78.4642,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1566398055,
        store_longitude: -78.4675932303,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1469,
        store_longitude: -78.465,
    },
    {
        store_latitdue: -0.1473051,
        store_longitude: -78.4653133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.9582364,
        store_longitude: -80.7121078,
    },
    {
        store_latitdue: -0.14785,
        store_longitude: -78.465833,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.875851,
        store_longitude: -79.4906,
        neighborhood: 'SAN JACINTO DE BUENA FE',
    },
    {
        store_latitdue: -0.1471847,
        store_longitude: -78.4668343,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1457546,
        store_longitude: -78.4661536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1439389,
        store_longitude: -78.479076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146951,
        store_longitude: -78.475252,
    },
    {
        store_latitdue: -0.147701,
        store_longitude: -78.475508,
    },

    {
        store_latitdue: -0.169741,
        store_longitude: -78.4823,
    },
    {
        store_latitdue: -0.162902,
        store_longitude: -78.487053,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1800885,
        store_longitude: -78.4873259,
    },
    {
        store_latitdue: -1.6590319,
        store_longitude: -78.6385808,
        neighborhood: 'RIOBAMBA',
    },

    {
        store_latitdue: -0.1735749,
        store_longitude: -78.4811778,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1629333,
        store_longitude: -78.4744753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.170625,
        store_longitude: -78.4774,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1700615248,
        store_longitude: -78.4766077995,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1669935,
        store_longitude: -78.4707425,
    },

    {
        store_latitdue: -0.203816,
        store_longitude: -78.498577,
    },
    {
        store_latitdue: -0.166762,
        store_longitude: -78.470483,
    },
    {
        store_latitdue: -0.171634,
        store_longitude: -78.4865,
    },
    {
        store_latitdue: -0.2198688,
        store_longitude: -78.5069794,
    },
    {
        store_latitdue: -0.170452,
        store_longitude: -78.4775,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1808314,
        store_longitude: -78.4818186,
    },
    {
        store_latitdue: -0.172962,
        store_longitude: -78.486444,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.162155,
        store_longitude: -78.4758,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1650196,
        store_longitude: -78.4725722,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089352,
        store_longitude: -78.452057,
    },
    {
        store_latitdue: -0.147611,
        store_longitude: -78.4832,
    },

    {
        store_latitdue: -0.11283,
        store_longitude: -78.450754,
    },
    {
        store_latitdue: -0.3101368,
        store_longitude: -78.4843184,
    },
    {
        store_latitdue: -0.1571641,
        store_longitude: -78.474928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.157445,
        store_longitude: -78.4771,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.176248,
        store_longitude: -78.4816,
    },
    {
        store_latitdue: -0.202457,
        store_longitude: -78.493542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1877017,
        store_longitude: -78.4807817,
    },
    {
        store_latitdue: -0.194825,
        store_longitude: -78.4944,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2032345,
        store_longitude: -78.4935548,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19128,
        store_longitude: -78.4932,
    },
    {
        store_latitdue: -0.204845,
        store_longitude: -78.4924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18390567,
        store_longitude: -78.4802932,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.204446,
        store_longitude: -78.4923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.182374,
        store_longitude: -78.4805,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.19862824,
        store_longitude: -78.4857413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.203828,
        store_longitude: -78.492179,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2023,
        store_longitude: -78.490826,
    },
    {
        store_latitdue: -0.1928979,
        store_longitude: -78.4916945,
    },
    {
        store_latitdue: -0.201418,
        store_longitude: -78.4890761,
    },
    {
        store_latitdue: -0.200188,
        store_longitude: -78.488413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.200292,
        store_longitude: -78.488378,
    },
    {
        store_latitdue: -0.1866227,
        store_longitude: -78.4886884,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.19925,
        store_longitude: -78.49102,
    },

    {
        store_latitdue: -0.2008939,
        store_longitude: -78.4893945,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.197197,
        store_longitude: -78.490663,
    },
    {
        store_latitdue: -0.1940335,
        store_longitude: -78.4852914,
    },
    {
        store_latitdue: -0.20249563,
        store_longitude: -78.487991,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1971401,
        store_longitude: -78.4923201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.171355,
        store_longitude: -78.4762,
    },
    {
        store_latitdue: -0.203478,
        store_longitude: -78.490398,
    },
    {
        store_latitdue: -0.203474,
        store_longitude: -78.4901,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1983572,
        store_longitude: -78.4947794,
    },

    {
        store_latitdue: -0.2150047,
        store_longitude: -78.4862165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204041,
        store_longitude: -78.485646,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215733,
        store_longitude: -78.4864,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20748601,
        store_longitude: -78.49512501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20436,
        store_longitude: -78.4859,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2082178,
        store_longitude: -78.4957666,
    },

    {
        store_latitdue: -0.219583,
        store_longitude: -78.4853,
    },
    {
        store_latitdue: -0.218092,
        store_longitude: -78.4845,
    },
    {
        store_latitdue: -0.216276,
        store_longitude: -78.490121,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218651,
        store_longitude: -78.483811,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2160739,
        store_longitude: -78.4891456,
    },
    {
        store_latitdue: -0.21039,
        store_longitude: -78.4823,
    },
    {
        store_latitdue: -0.2060851,
        store_longitude: -78.4887308,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216123,
        store_longitude: -78.489147,
    },
    {
        store_latitdue: -0.20227,
        store_longitude: -78.49,
    },
    {
        store_latitdue: -0.205839,
        store_longitude: -78.490318,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.217059,
        store_longitude: -78.4883,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.21730803,
        store_longitude: -78.4884785,
    },
    {
        store_latitdue: -0.204652,
        store_longitude: -78.496278,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216727,
        store_longitude: -78.488318,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.203865,
        store_longitude: -78.495856,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20893,
        store_longitude: -78.4812,
    },
    {
        store_latitdue: -0.2482248,
        store_longitude: -78.488167,
    },
    {
        store_latitdue: -0.2028945,
        store_longitude: -78.4950416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.201568,
        store_longitude: -78.475883,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2029462,
        store_longitude: -78.4947232,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.201508,
        store_longitude: -78.476829,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21620699,
        store_longitude: -78.4891712,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2063597,
        store_longitude: -78.4921826,
    },
    {
        store_latitdue: -0.237064,
        store_longitude: -78.4912397,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.211728,
        store_longitude: -78.4854,
    },

    {
        store_latitdue: -0.234878,
        store_longitude: -78.4915,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.27564,
        store_longitude: -78.535539,
    },

    {
        store_latitdue: -0.2220329,
        store_longitude: -78.5075879,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2306884,
        store_longitude: -78.4765293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22308,
        store_longitude: -78.50593,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22284,
        store_longitude: -78.50605,
    },
    {
        store_latitdue: -0.21406,
        store_longitude: -78.5039,
    },
    {
        store_latitdue: -0.2251396,
        store_longitude: -78.4892598,
    },
    {
        store_latitdue: -0.2346756,
        store_longitude: -78.4968756,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2241433,
        store_longitude: -78.489492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2241226,
        store_longitude: -78.5052001,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225903,
        store_longitude: -78.5047,
    },
    {
        store_latitdue: -0.2254428,
        store_longitude: -78.5046546,
    },
    {
        store_latitdue: -0.2336697,
        store_longitude: -78.494428,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.234227,
        store_longitude: -78.497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.219459,
        store_longitude: -78.5065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240122,
        store_longitude: -78.5259134,
    },
    {
        store_latitdue: -0.15655498,
        store_longitude: -78.46811,
    },
    {
        store_latitdue: -0.22318,
        store_longitude: -78.489,
    },
    {
        store_latitdue: -0.2303697,
        store_longitude: -78.491285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223349,
        store_longitude: -78.488,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.221778,
        store_longitude: -78.489458,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2224989,
        store_longitude: -78.4850636,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2178336,
        store_longitude: -78.5056624,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2276252,
        store_longitude: -78.5030366,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.233996,
        store_longitude: -78.4872,
    },
    {
        store_latitdue: -0.21645115,
        store_longitude: -78.5038975,
    },
    {
        store_latitdue: -0.229083,
        store_longitude: -78.504727,
    },
    {
        store_latitdue: -0.233189,
        store_longitude: -78.48344,
    },
    {
        store_latitdue: -0.2267463,
        store_longitude: -78.4917753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.232482,
        store_longitude: -78.4935,
    },
    {
        store_latitdue: -0.215513,
        store_longitude: -78.501086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.226853,
        store_longitude: -78.492442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2342093,
        store_longitude: -78.4827285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216634,
        store_longitude: -78.50221,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2164108376,
        store_longitude: -78.5025501251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.234156,
        store_longitude: -78.482785,
    },
    {
        store_latitdue: -0.217504,
        store_longitude: -78.5120844,
    },
    {
        store_latitdue: -0.240633,
        store_longitude: -78.481313,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2363875,
        store_longitude: -78.4850475,
    },
    {
        store_latitdue: -0.235069,
        store_longitude: -78.4849,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1994396,
        store_longitude: -78.4794703,
    },
    {
        store_latitdue: -0.156686,
        store_longitude: -78.465999,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1489265,
        store_longitude: -78.4767608,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1444742,
        store_longitude: -78.4823509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.201719,
        store_longitude: -78.43138,
    },
    {
        store_latitdue: -0.1490496,
        store_longitude: -78.4764294,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1449478,
        store_longitude: -78.4676376,
    },
    {
        store_latitdue: -0.14578,
        store_longitude: -78.4661,
    },
    {
        store_latitdue: -0.1480233,
        store_longitude: -78.466005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.149132,
        store_longitude: -78.465856,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148939,
        store_longitude: -78.466036,
    },
    {
        store_latitdue: -0.286567,
        store_longitude: -78.557065,
    },
    {
        store_latitdue: -0.148088,
        store_longitude: -78.466053,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1546462,
        store_longitude: -78.4610365,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147033,
        store_longitude: -78.465036,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148165,
        store_longitude: -78.47221,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1543308,
        store_longitude: -78.4721393,
    },
    {
        store_latitdue: -0.1563366,
        store_longitude: -78.4886156,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144307,
        store_longitude: -78.457884,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156924,
        store_longitude: -78.4752,
    },

    {
        store_latitdue: -0.1473089,
        store_longitude: -78.4592315,
    },
    {
        store_latitdue: -0.1438029,
        store_longitude: -78.454046,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -2.2362015,
        store_longitude: -80.9137481,
        neighborhood: 'LA LIBERTAD',
    },

    {
        store_latitdue: -0.14417059,
        store_longitude: -78.479816,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.157192,
        store_longitude: -78.462569,
    },

    {
        store_latitdue: -0.16404,
        store_longitude: -78.472591,
    },

    {
        store_latitdue: -0.154038,
        store_longitude: -78.4705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.166812,
        store_longitude: -78.4705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1710178,
        store_longitude: -78.475266,
    },

    {
        store_latitdue: -0.1675758,
        store_longitude: -78.4785029,
    },
    {
        store_latitdue: -0.1792054,
        store_longitude: -78.4890492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.182415,
        store_longitude: -78.488825,
    },
    {
        store_latitdue: -0.182343,
        store_longitude: -78.489809,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1752592,
        store_longitude: -78.47826,
    },
    {
        store_latitdue: -0.178447,
        store_longitude: -78.486,
    },
    {
        store_latitdue: -0.1790865,
        store_longitude: -78.4869696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218033,
        store_longitude: -78.4853,
    },
    {
        store_latitdue: -0.1569401,
        store_longitude: -78.4883176,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.177004,
        store_longitude: -78.4877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144376,
        store_longitude: -78.4812,
    },
    {
        store_latitdue: -0.1560367,
        store_longitude: -78.488345,
    },
    {
        store_latitdue: -0.144381,
        store_longitude: -78.4696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1573357,
        store_longitude: -78.4810706,
    },
    {
        store_latitdue: -0.1653,
        store_longitude: -78.4665,
    },
    {
        store_latitdue: -0.185776,
        store_longitude: -78.4792,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.144432,
        store_longitude: -78.4714,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188898,
        store_longitude: -78.472327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140628,
        store_longitude: -78.4741,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1670266,
        store_longitude: -78.4799002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.186952,
        store_longitude: -78.4895,
    },
    {
        store_latitdue: -0.1898903,
        store_longitude: -78.4710466,
    },
    {
        store_latitdue: -0.140325,
        store_longitude: -78.4728,
    },
    {
        store_latitdue: -0.1652146,
        store_longitude: -78.4777336,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144417,
        store_longitude: -78.471,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1709459,
        store_longitude: -78.4749845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1508363,
        store_longitude: -78.471886,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17291316,
        store_longitude: -78.4807052,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.160447,
        store_longitude: -78.4874961,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2108389,
        store_longitude: -78.4828615,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.209021,
        store_longitude: -78.483772,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2086324,
        store_longitude: -78.4855493,
    },
    {
        store_latitdue: -0.211094,
        store_longitude: -78.4823,
    },
    {
        store_latitdue: -0.2092616,
        store_longitude: -78.4835687,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2079784,
        store_longitude: -78.4831943,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.208715,
        store_longitude: -78.4851,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2060285,
        store_longitude: -78.4839398,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -2.134894,
        store_longitude: -79.890856,
    },
    {
        store_latitdue: -0.2949512,
        store_longitude: -78.5027188,
    },
    {
        store_latitdue: -0.217327,
        store_longitude: -78.504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.203107,
        store_longitude: -78.4935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206575,
        store_longitude: -78.493399,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.21613,
        store_longitude: -78.500789,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213486,
        store_longitude: -78.500457,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.214071,
        store_longitude: -78.50012,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216622,
        store_longitude: -78.4965,
    },
    {
        store_latitdue: -0.210664,
        store_longitude: -78.48303,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1632894,
        store_longitude: -78.4760818,
    },

    {
        store_latitdue: -0.12724129,
        store_longitude: -78.4451675,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.0922288,
        store_longitude: -78.4516,
    },
    {
        store_latitdue: -0.14457,
        store_longitude: -78.4724543,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.156972,
        store_longitude: -78.478571,
    },

    {
        store_latitdue: -0.1382524,
        store_longitude: -78.4845963,
    },
    {
        store_latitdue: -0.224226,
        store_longitude: -78.5053,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1703701,
        store_longitude: -78.4784537,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1704489,
        store_longitude: -78.4786793,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.17355269,
        store_longitude: -78.4777297,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.151057,
        store_longitude: -78.474925,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1840552637,
        store_longitude: -78.4792934373,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1620430949,
        store_longitude: -78.4821245306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1445712,
        store_longitude: -78.4751812,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1894274681,
        store_longitude: -78.4734326677,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.188338,
        store_longitude: -78.472671,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.161036,
        store_longitude: -78.482078,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1623004,
        store_longitude: -78.4838392,
    },
    {
        store_latitdue: -0.15842,
        store_longitude: -78.466622,
    },
    {
        store_latitdue: -0.15341,
        store_longitude: -78.483694,
    },
    {
        store_latitdue: -0.164571,
        store_longitude: -78.481227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1657693,
        store_longitude: -78.4803457,
    },

    {
        store_latitdue: -0.157108,
        store_longitude: -78.480524,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18978,
        store_longitude: -78.471585,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1534209,
        store_longitude: -78.4735434,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1492086,
        store_longitude: -78.4727342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1619185,
        store_longitude: -78.4791408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1416701,
        store_longitude: -78.4710851,
    },
    {
        store_latitdue: -0.167116,
        store_longitude: -78.478349,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.165123,
        store_longitude: -78.4754125,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.240455,
        store_longitude: -78.524418,
    },
    {
        store_latitdue: -0.1630926,
        store_longitude: -78.4751644,
    },
    {
        store_latitdue: -0.1533,
        store_longitude: -78.482914,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.163141,
        store_longitude: -78.477013,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.139703,
        store_longitude: -78.474037,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144094,
        store_longitude: -78.481333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146886,
        store_longitude: -78.473979,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139871,
        store_longitude: -78.474733,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1444222,
        store_longitude: -78.4711976,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.154199,
        store_longitude: -78.473151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1576901,
        store_longitude: -78.4843225,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.187647,
        store_longitude: -78.4784186,
    },
    {
        store_latitdue: -0.160434,
        store_longitude: -78.48,
    },
    {
        store_latitdue: -0.15715,
        store_longitude: -78.475,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1620501,
        store_longitude: -78.4835023,
    },
    {
        store_latitdue: -0.1316671,
        store_longitude: -78.4870228,
    },
    {
        store_latitdue: -0.1621337,
        store_longitude: -78.48204,
    },
    {
        store_latitdue: -0.150749,
        store_longitude: -78.470763,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1836799,
        store_longitude: -78.4764154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1556228,
        store_longitude: -78.4740844,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.153422,
        store_longitude: -78.4826,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189851,
        store_longitude: -78.47249,
    },

    {
        store_latitdue: -0.16394,
        store_longitude: -78.4731,
    },
    {
        store_latitdue: -0.154741,
        store_longitude: -78.477582,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1542901,
        store_longitude: -78.4736916,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.167814,
        store_longitude: -78.4796,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.189099,
        store_longitude: -78.4774,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.151239,
        store_longitude: -78.4721,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156426,
        store_longitude: -78.4745,
    },
    {
        store_latitdue: -0.177429,
        store_longitude: -78.4866,
    },
    {
        store_latitdue: -0.15336,
        store_longitude: -78.479,
    },
    {
        store_latitdue: -0.189188,
        store_longitude: -78.4785,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.164981,
        store_longitude: -78.4801,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1430077,
        store_longitude: -78.4778269,
    },
    {
        store_latitdue: -0.1626756,
        store_longitude: -78.4799114,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1569856,
        store_longitude: -78.4715521,
    },
    {
        store_latitdue: -0.149675,
        store_longitude: -78.470324,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.142638,
        store_longitude: -78.473843,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1637716,
        store_longitude: -78.4788211,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.159703,
        store_longitude: -78.4805,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.140754,
        store_longitude: -78.474769,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188637,
        store_longitude: -78.472316,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147528,
        store_longitude: -78.47284,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148338,
        store_longitude: -78.461871,
    },

    {
        store_latitdue: -0.311412,
        store_longitude: -78.5649,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.322186,
        store_longitude: -78.5587,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3245431,
        store_longitude: -78.5637282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.365788,
        store_longitude: -78.5513,
    },
    {
        store_latitdue: -0.3051072,
        store_longitude: -78.5668858,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2663994,
        store_longitude: -78.5442353,
    },

    {
        store_latitdue: -0.2601806,
        store_longitude: -78.5527651,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2526008,
        store_longitude: -78.5380788,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338441,
        store_longitude: -78.5618,
    },
    {
        store_latitdue: -0.256674,
        store_longitude: -78.540183,
    },
    {
        store_latitdue: -0.3378202,
        store_longitude: -78.5672174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264407,
        store_longitude: -78.5383,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.247124,
        store_longitude: -78.533,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252813,
        store_longitude: -78.5471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281875,
        store_longitude: -78.5754,
    },
    {
        store_latitdue: -0.2642271,
        store_longitude: -78.545278,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3058202,
        store_longitude: -78.5603204,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2898014,
        store_longitude: -78.5514905,
    },
    {
        store_latitdue: -0.343161,
        store_longitude: -78.5576,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26889,
        store_longitude: -78.5407487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3081757,
        store_longitude: -78.5663853,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.286268,
        store_longitude: -78.5621,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3188053,
        store_longitude: -78.5706792,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.30929,
        store_longitude: -78.5706,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286511304,
        store_longitude: -78.5823643,
    },

    {
        store_latitdue: -0.2610656,
        store_longitude: -78.5451749,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270733,
        store_longitude: -78.5418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269048,
        store_longitude: -78.540588,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3396853,
        store_longitude: -78.5513489,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.241255,
        store_longitude: -78.5457,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.242738,
        store_longitude: -78.546545,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2451156,
        store_longitude: -78.5493717,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.241098,
        store_longitude: -78.5454,
    },
    {
        store_latitdue: -0.265329,
        store_longitude: -78.547654,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263633,
        store_longitude: -78.541512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2647212,
        store_longitude: -78.5496602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263516,
        store_longitude: -78.547751,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267574,
        store_longitude: -78.544397,
    },
    {
        store_latitdue: -0.2806432,
        store_longitude: -78.5562205,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268666,
        store_longitude: -78.5427,
    },
    {
        store_latitdue: -0.267039,
        store_longitude: -78.542103,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267644,
        store_longitude: -78.541084,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267461,
        store_longitude: -78.542628,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.269417,
        store_longitude: -78.545344,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270271,
        store_longitude: -78.544515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270964,
        store_longitude: -78.542971,
    },
    {
        store_latitdue: -0.272355,
        store_longitude: -78.5426,
    },
    {
        store_latitdue: -0.271224,
        store_longitude: -78.542974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2722016,
        store_longitude: -78.5412252,
    },
    {
        store_latitdue: -0.2726444,
        store_longitude: -78.5423368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2690138,
        store_longitude: -78.5404359,
    },
    {
        store_latitdue: -0.2699336,
        store_longitude: -78.5409113,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272818,
        store_longitude: -78.539,
    },
    {
        store_latitdue: -0.2737862,
        store_longitude: -78.5402751,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.274614,
        store_longitude: -78.540627,
    },
    {
        store_latitdue: -0.2681349,
        store_longitude: -78.5358795,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27024,
        store_longitude: -78.537431,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268491,
        store_longitude: -78.536519,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2704572,
        store_longitude: -78.5375056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2680108,
        store_longitude: -78.5360493,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2674601,
        store_longitude: -78.5339336,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266587,
        store_longitude: -78.5362,
    },
    {
        store_latitdue: -0.2780822,
        store_longitude: -78.5479514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260965,
        store_longitude: -78.5397,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2588743,
        store_longitude: -78.5406244,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261292,
        store_longitude: -78.539739,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2610698,
        store_longitude: -78.5408791,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2606865,
        store_longitude: -78.5390619,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260859,
        store_longitude: -78.541245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2615929,
        store_longitude: -78.5309589,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260936,
        store_longitude: -78.5325,
    },
    {
        store_latitdue: -0.259767,
        store_longitude: -78.531,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261077,
        store_longitude: -78.5318,
    },
    {
        store_latitdue: -0.2592152,
        store_longitude: -78.5320843,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2613005,
        store_longitude: -78.5358775,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257274,
        store_longitude: -78.529478,
    },
    {
        store_latitdue: -0.2649939,
        store_longitude: -78.5375422,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2650787,
        store_longitude: -78.5373911,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264065,
        store_longitude: -78.537832,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2653689,
        store_longitude: -78.537545,
    },
    {
        store_latitdue: -0.265773,
        store_longitude: -78.538093,
    },
    {
        store_latitdue: -0.26549,
        store_longitude: -78.538012,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2659163,
        store_longitude: -78.5409579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266031,
        store_longitude: -78.538852,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266157,
        store_longitude: -78.53898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267819,
        store_longitude: -78.549375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26706,
        store_longitude: -78.547537,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267049,
        store_longitude: -78.548475,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.266202,
        store_longitude: -78.548714,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267658,
        store_longitude: -78.5471,
    },
    {
        store_latitdue: -0.261584,
        store_longitude: -78.5444,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281944,
        store_longitude: -78.5609,
    },
    {
        store_latitdue: -0.2784828,
        store_longitude: -78.5563202,
    },
    {
        store_latitdue: -0.2826879,
        store_longitude: -78.5583139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278792,
        store_longitude: -78.5556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29013,
        store_longitude: -78.567546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288865,
        store_longitude: -78.569378,
    },
    {
        store_latitdue: -0.2912501,
        store_longitude: -78.5717107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.285086,
        store_longitude: -78.566361,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279975,
        store_longitude: -78.5834,
    },
    {
        store_latitdue: -0.285395,
        store_longitude: -78.556351,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.283531,
        store_longitude: -78.558777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.284465,
        store_longitude: -78.560508,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2891,
        store_longitude: -78.558364,
    },
    {
        store_latitdue: -0.2858401,
        store_longitude: -78.5594013,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.289292,
        store_longitude: -78.5581,
    },
    {
        store_latitdue: -0.287939,
        store_longitude: -78.561731,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.290623,
        store_longitude: -78.566359,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2890371,
        store_longitude: -78.561131,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2890536,
        store_longitude: -78.5611386,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296256,
        store_longitude: -78.56792,
    },
    {
        store_latitdue: -0.293198,
        store_longitude: -78.559979,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2943321,
        store_longitude: -78.5616017,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.291688,
        store_longitude: -78.561877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296009,
        store_longitude: -78.5592,
    },
    {
        store_latitdue: -0.270265,
        store_longitude: -78.547662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2975178,
        store_longitude: -78.5645981,
    },
    {
        store_latitdue: -0.3026021,
        store_longitude: -78.5569243,
    },
    {
        store_latitdue: -0.299016,
        store_longitude: -78.560254,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304319,
        store_longitude: -78.565397,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306551,
        store_longitude: -78.56655,
    },

    {
        store_latitdue: -0.2735489,
        store_longitude: -78.5473361,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280272,
        store_longitude: -78.5412,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276054,
        store_longitude: -78.543422,
    },
    {
        store_latitdue: -0.274932,
        store_longitude: -78.544804,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28164,
        store_longitude: -78.54487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278059,
        store_longitude: -78.54956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278446,
        store_longitude: -78.5499,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2790847,
        store_longitude: -78.5528759,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279411,
        store_longitude: -78.55491,
    },
    {
        store_latitdue: -0.2779932,
        store_longitude: -78.5556934,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.275603,
        store_longitude: -78.556194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2762773,
        store_longitude: -78.5588209,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3087109,
        store_longitude: -78.5668778,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27172,
        store_longitude: -78.567747,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2731142,
        store_longitude: -78.5673144,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.273827,
        store_longitude: -78.568319,
    },
    {
        store_latitdue: -0.276325,
        store_longitude: -78.567569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272084,
        store_longitude: -78.565382,
    },
    {
        store_latitdue: -0.278044,
        store_longitude: -78.563386,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.275076,
        store_longitude: -78.5647,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276835,
        store_longitude: -78.562206,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2875089,
        store_longitude: -78.5649146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292897,
        store_longitude: -78.561122,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.253501,
        store_longitude: -78.55281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.344692,
        store_longitude: -78.553372,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3044516,
        store_longitude: -78.5531755,
    },
    {
        store_latitdue: -0.3060311,
        store_longitude: -78.5534385,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3070977,
        store_longitude: -78.553552,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304838,
        store_longitude: -78.5532497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.331417,
        store_longitude: -78.560085,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3213171,
        store_longitude: -78.5541152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2917598,
        store_longitude: -78.5464094,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307716,
        store_longitude: -78.566422,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.309301,
        store_longitude: -78.5673486,
    },
    {
        store_latitdue: -0.3242607,
        store_longitude: -78.5636063,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3193603,
        store_longitude: -78.5613379,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.308133,
        store_longitude: -78.561234,
    },
    {
        store_latitdue: -0.305374,
        store_longitude: -78.560332,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308426,
        store_longitude: -78.5622971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.31503,
        store_longitude: -78.5655,
    },
    {
        store_latitdue: -0.303377,
        store_longitude: -78.567081,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311591,
        store_longitude: -78.563242,
    },
    {
        store_latitdue: -0.313905,
        store_longitude: -78.560267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3113305,
        store_longitude: -78.5647526,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.215627,
        store_longitude: -78.4866,
    },
    {
        store_latitdue: -0.298523,
        store_longitude: -78.5666,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2966987,
        store_longitude: -78.5694829,
    },
    {
        store_latitdue: -0.298439,
        store_longitude: -78.567369,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.298076,
        store_longitude: -78.570378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.297772,
        store_longitude: -78.56869,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257827,
        store_longitude: -78.532,
    },
    {
        store_latitdue: -0.343597,
        store_longitude: -78.562725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.339544,
        store_longitude: -78.565629,
    },
    {
        store_latitdue: -0.3421596,
        store_longitude: -78.5675987,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.300098,
        store_longitude: -78.56475,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2842148,
        store_longitude: -78.5522084,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303141,
        store_longitude: -78.563049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3476609,
        store_longitude: -78.5509781,
    },
    {
        store_latitdue: -0.3385516,
        store_longitude: -78.5505812,
    },

    {
        store_latitdue: -0.3343803,
        store_longitude: -78.5533182,
    },
    {
        store_latitdue: -0.336679,
        store_longitude: -78.550792,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.299406,
        store_longitude: -78.570277,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.315115,
        store_longitude: -78.56862,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.321066,
        store_longitude: -78.5719,
    },
    {
        store_latitdue: -0.327726,
        store_longitude: -78.574513,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2490348,
        store_longitude: -78.5334235,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251905,
        store_longitude: -78.531418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251968,
        store_longitude: -78.536582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252261,
        store_longitude: -78.534229,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244904,
        store_longitude: -78.532428,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2525852,
        store_longitude: -78.5357652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25297,
        store_longitude: -78.5376,
    },
    {
        store_latitdue: -0.2561323,
        store_longitude: -78.5343939,
    },
    {
        store_latitdue: -0.255714,
        store_longitude: -78.535318,
    },
    {
        store_latitdue: -0.260144,
        store_longitude: -78.535371,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25538,
        store_longitude: -78.536685,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2584186,
        store_longitude: -78.5378383,
    },
    {
        store_latitdue: -0.257822,
        store_longitude: -78.535644,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257424,
        store_longitude: -78.53692,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255847,
        store_longitude: -78.537427,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249843,
        store_longitude: -78.5320007,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.259488,
        store_longitude: -78.539875,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259819,
        store_longitude: -78.53953,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2566885,
        store_longitude: -78.5386415,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257635,
        store_longitude: -78.546134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2595425,
        store_longitude: -78.5457304,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25611,
        store_longitude: -78.545947,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2583592,
        store_longitude: -78.5448852,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2595541,
        store_longitude: -78.5457442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254209,
        store_longitude: -78.546306,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2538015,
        store_longitude: -78.5464627,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3005873,
        store_longitude: -78.5624599,
    },

    {
        store_latitdue: -0.253519,
        store_longitude: -78.552821,
    },
    {
        store_latitdue: -0.25181,
        store_longitude: -78.552849,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254881,
        store_longitude: -78.551895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2605293,
        store_longitude: -78.5462356,
    },
    {
        store_latitdue: -0.2623778,
        store_longitude: -78.5482745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260464,
        store_longitude: -78.5473633,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2640221,
        store_longitude: -78.5510231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2631577,
        store_longitude: -78.5509475,
    },

    {
        store_latitdue: -0.2585506,
        store_longitude: -78.552688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2618493,
        store_longitude: -78.5584707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258786,
        store_longitude: -78.553393,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2571346,
        store_longitude: -78.5431245,
    },
    {
        store_latitdue: -0.2720468,
        store_longitude: -78.5565776,
    },
    {
        store_latitdue: -0.260442,
        store_longitude: -78.550908,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260286,
        store_longitude: -78.555196,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2583629,
        store_longitude: -78.5600742,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2548499,
        store_longitude: -78.5617637,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25842,
        store_longitude: -78.541517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2489826,
        store_longitude: -78.5396087,
    },
    {
        store_latitdue: -0.25321,
        store_longitude: -78.53996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250242,
        store_longitude: -78.542299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254118,
        store_longitude: -78.5418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256714,
        store_longitude: -78.540266,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257593,
        store_longitude: -78.541785,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2562384,
        store_longitude: -78.5379548,
    },
    {
        store_latitdue: -0.256443,
        store_longitude: -78.5405051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2439406,
        store_longitude: -78.5437732,
    },
    {
        store_latitdue: -0.243367,
        store_longitude: -78.5423,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246784729,
        store_longitude: -78.54185619,
    },
    {
        store_latitdue: -0.245074,
        store_longitude: -78.542924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246517,
        store_longitude: -78.539566,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2456443,
        store_longitude: -78.544917,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2593275,
        store_longitude: -78.5400115,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2655563,
        store_longitude: -78.5544831,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266886,
        store_longitude: -78.55779,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2661489,
        store_longitude: -78.5537372,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266969,
        store_longitude: -78.554329,
    },
    {
        store_latitdue: -0.268293,
        store_longitude: -78.554915,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264441,
        store_longitude: -78.554761,
    },
    {
        store_latitdue: -0.268775,
        store_longitude: -78.5553,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2660847,
        store_longitude: -78.5611943,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269728,
        store_longitude: -78.5537,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266167,
        store_longitude: -78.560069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269265,
        store_longitude: -78.560863,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270154,
        store_longitude: -78.5532291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265112,
        store_longitude: -78.553435,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266187,
        store_longitude: -78.560242,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252414,
        store_longitude: -78.536081,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259897,
        store_longitude: -78.5541311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255992,
        store_longitude: -78.559154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269785,
        store_longitude: -78.540942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267706,
        store_longitude: -78.544314,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2606634,
        store_longitude: -78.5346494,
    },
    {
        store_latitdue: -0.27081627,
        store_longitude: -78.5517418,
    },
    {
        store_latitdue: -0.2726046,
        store_longitude: -78.5672364,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.343363,
        store_longitude: -78.559746,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3000375,
        store_longitude: -78.5521749,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.307309,
        store_longitude: -78.5664,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318303,
        store_longitude: -78.557498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32275,
        store_longitude: -78.5624032,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3337868,
        store_longitude: -78.5573649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3129021,
        store_longitude: -78.5537633,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2501648,
        store_longitude: -78.5323847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266729,
        store_longitude: -78.538112,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305119,
        store_longitude: -78.5669,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.30689,
        store_longitude: -78.570463,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.325421,
        store_longitude: -78.572788,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.342893,
        store_longitude: -78.55345,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2681792,
        store_longitude: -78.5462474,
    },
    {
        store_latitdue: -0.3005065,
        store_longitude: -78.5661456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257958,
        store_longitude: -78.5585,
    },
    {
        store_latitdue: -0.278359,
        store_longitude: -78.582689,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281271,
        store_longitude: -78.5635,
    },
    {
        store_latitdue: -0.300748,
        store_longitude: -78.561248,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.344582,
        store_longitude: -78.564333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.33411,
        store_longitude: -78.558368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258157,
        store_longitude: -78.558206,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252317,
        store_longitude: -78.538729,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277951,
        store_longitude: -78.5635,
    },
    {
        store_latitdue: -0.2794687,
        store_longitude: -78.5580778,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288401,
        store_longitude: -78.566889,
    },

    {
        store_latitdue: -0.3191574,
        store_longitude: -78.5722313,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254633,
        store_longitude: -78.539843,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251654,
        store_longitude: -78.554654,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.303245,
        store_longitude: -78.560279,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.284224,
        store_longitude: -78.582,
    },
    {
        store_latitdue: -0.3290195,
        store_longitude: -78.5622255,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2531136,
        store_longitude: -78.5536649,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2574937,
        store_longitude: -78.5326021,
    },
    {
        store_latitdue: -0.2625524,
        store_longitude: -78.5377837,
    },
    {
        store_latitdue: -0.2958685,
        store_longitude: -78.5690469,
    },
    {
        store_latitdue: -0.277545,
        store_longitude: -78.56181,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3729,
        store_longitude: -78.5642,
    },
    {
        store_latitdue: -0.3584075,
        store_longitude: -78.569615,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3601721,
        store_longitude: -78.5637808,
        neighborhood: 'CUTUGLAHUA',
    },

    {
        store_latitdue: -0.268718,
        store_longitude: -78.546923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.331591,
        store_longitude: -78.555139,
    },
    {
        store_latitdue: -0.334782,
        store_longitude: -78.558109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2672331,
        store_longitude: -78.5545223,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262088,
        store_longitude: -78.554148,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3104785,
        store_longitude: -78.5700396,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3123673,
        store_longitude: -78.5701612,
    },
    {
        store_latitdue: -0.298603,
        store_longitude: -78.5604154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3585992,
        store_longitude: -78.568805,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.26741,
        store_longitude: -78.558429,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269461,
        store_longitude: -78.55443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29049,
        store_longitude: -78.567925,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2674191,
        store_longitude: -78.5453385,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3179602,
        store_longitude: -78.5646254,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260444,
        store_longitude: -78.549298,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250868,
        store_longitude: -78.531539,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266678,
        store_longitude: -78.540113,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2661991,
        store_longitude: -78.553627,
    },
    {
        store_latitdue: -0.265879,
        store_longitude: -78.53885,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2619206,
        store_longitude: -78.53988,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277063,
        store_longitude: -78.563703,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2531205,
        store_longitude: -78.5421669,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2694269,
        store_longitude: -78.5664168,
    },
    {
        store_latitdue: -0.3194985,
        store_longitude: -78.5714941,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.302426,
        store_longitude: -78.562167,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3130941,
        store_longitude: -78.5481439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281942,
        store_longitude: -78.560742,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2602315,
        store_longitude: -78.5416519,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2800563,
        store_longitude: -78.5609192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.285298,
        store_longitude: -78.566,
    },
    {
        store_latitdue: -0.305023,
        store_longitude: -78.564451,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257851,
        store_longitude: -78.54165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311048,
        store_longitude: -78.5586,
    },
    {
        store_latitdue: -0.313976,
        store_longitude: -78.559691,
    },
    {
        store_latitdue: -0.3168653,
        store_longitude: -78.5655791,
    },
    {
        store_latitdue: -0.2549658,
        store_longitude: -78.5401262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29482,
        store_longitude: -78.5487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3105273,
        store_longitude: -78.5547172,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2472092,
        store_longitude: -78.5336401,
    },
    {
        store_latitdue: -0.283671,
        store_longitude: -78.581477,
    },
    {
        store_latitdue: -0.343609,
        store_longitude: -78.555291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3195607,
        store_longitude: -78.5723729,
    },
    {
        store_latitdue: -0.2577415,
        store_longitude: -78.5345617,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.346861,
        store_longitude: -78.552624,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270168,
        store_longitude: -78.5523,
    },
    {
        store_latitdue: -0.282922,
        store_longitude: -78.5828,
    },
    {
        store_latitdue: -0.339943,
        store_longitude: -78.5577,
    },

    {
        store_latitdue: -0.274437,
        store_longitude: -78.568,
    },
    {
        store_latitdue: -0.342116,
        store_longitude: -78.5577,
    },
    {
        store_latitdue: -0.309415,
        store_longitude: -78.564207,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261476,
        store_longitude: -78.551217,
    },
    {
        store_latitdue: -0.2612624,
        store_longitude: -78.5321285,
    },
    {
        store_latitdue: -0.2687913,
        store_longitude: -78.5470558,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3399794,
        store_longitude: -78.5539575,
    },
    {
        store_latitdue: -0.333133,
        store_longitude: -78.551999,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.242165,
        store_longitude: -78.544625,
    },
    {
        store_latitdue: -0.3043689,
        store_longitude: -78.5640395,
    },
    {
        store_latitdue: -0.259452,
        store_longitude: -78.539876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278416,
        store_longitude: -78.566082,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.278263,
        store_longitude: -78.565845,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.308105,
        store_longitude: -78.56095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308933,
        store_longitude: -78.567436,
    },
    {
        store_latitdue: -0.295881,
        store_longitude: -78.574543,
    },

    {
        store_latitdue: -0.3011116,
        store_longitude: -78.5673578,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3127053,
        store_longitude: -78.5549493,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260094,
        store_longitude: -78.553753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.273053,
        store_longitude: -78.568185,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3411384,
        store_longitude: -78.5538502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27799,
        store_longitude: -78.547839,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2601034,
        store_longitude: -78.5431289,
    },
    {
        store_latitdue: -0.2578191,
        store_longitude: -78.538964,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3235665,
        store_longitude: -78.5548678,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268145,
        store_longitude: -78.5601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2995046,
        store_longitude: -78.5680087,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.291433,
        store_longitude: -78.5771,
    },
    {
        store_latitdue: -0.298334,
        store_longitude: -78.560751,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3258,
        store_longitude: -78.539669,
    },
    {
        store_latitdue: -0.2619765,
        store_longitude: -78.5409906,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.329375,
        store_longitude: -78.555277,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2576523,
        store_longitude: -78.5520082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279108,
        store_longitude: -78.563853,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2560462,
        store_longitude: -78.5526097,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3220499,
        store_longitude: -78.5726625,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2767829,
        store_longitude: -78.5629267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2659732,
        store_longitude: -78.5615574,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.321757,
        store_longitude: -78.554955,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250759,
        store_longitude: -78.543,
    },
    {
        store_latitdue: -0.34273,
        store_longitude: -78.5655,
    },
    {
        store_latitdue: -0.2697557,
        store_longitude: -78.5378745,
    },
    {
        store_latitdue: -0.271168,
        store_longitude: -78.55773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3469219,
        store_longitude: -78.5586401,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.337595,
        store_longitude: -78.5621228,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2671128,
        store_longitude: -78.5342699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27775,
        store_longitude: -78.5842,
    },

    {
        store_latitdue: -0.342073,
        store_longitude: -78.5493,
    },
    {
        store_latitdue: -0.314138,
        store_longitude: -78.559704,
    },
    {
        store_latitdue: -0.2807675,
        store_longitude: -78.5639898,
    },
    {
        store_latitdue: -0.330389,
        store_longitude: -78.563308,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.306938,
        store_longitude: -78.570363,
    },
    {
        store_latitdue: -0.305374,
        store_longitude: -78.5666717,
    },
    {
        store_latitdue: -0.2864018,
        store_longitude: -78.5829043,
    },
    {
        store_latitdue: -0.2774386,
        store_longitude: -78.557421,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3209797,
        store_longitude: -78.5632092,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.310787,
        store_longitude: -78.566314,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3328943,
        store_longitude: -78.5691501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2609675,
        store_longitude: -78.5324811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281862,
        store_longitude: -78.5546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261615,
        store_longitude: -78.539101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251478,
        store_longitude: -78.5546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311943,
        store_longitude: -78.5546,
    },
    {
        store_latitdue: -0.271129,
        store_longitude: -78.545913,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2516177,
        store_longitude: -78.541393,
    },

    {
        store_latitdue: -0.3136467,
        store_longitude: -78.5597127,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.266118,
        store_longitude: -78.5357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.339209,
        store_longitude: -78.561066,
    },
    {
        store_latitdue: -0.2628405,
        store_longitude: -78.5447216,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2802342,
        store_longitude: -78.5647521,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.349732,
        store_longitude: -78.552143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.295282,
        store_longitude: -78.5661,
    },
    {
        store_latitdue: -0.2673737,
        store_longitude: -78.5426867,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2592962,
        store_longitude: -78.5346201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2748414,
        store_longitude: -78.565383,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27036,
        store_longitude: -78.5667,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2896129,
        store_longitude: -78.5513777,
    },

    {
        store_latitdue: -0.2877706,
        store_longitude: -78.565482,
    },
    {
        store_latitdue: -0.2589843,
        store_longitude: -78.5403602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254869,
        store_longitude: -78.5617054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2735276,
        store_longitude: -78.5547605,
    },
    {
        store_latitdue: -0.2233612,
        store_longitude: -78.5095215,
    },
    {
        store_latitdue: -0.271159,
        store_longitude: -78.556146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252468,
        store_longitude: -78.5385,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250688,
        store_longitude: -78.5485,
    },
    {
        store_latitdue: -0.332048,
        store_longitude: -78.555302,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2627677,
        store_longitude: -78.5577239,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278891651,
        store_longitude: -78.562674103,
    },
    {
        store_latitdue: -0.2906489,
        store_longitude: -78.5519867,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26827,
        store_longitude: -78.546569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.310381,
        store_longitude: -78.570178,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262558,
        store_longitude: -78.543731,
    },
    {
        store_latitdue: -0.27186,
        store_longitude: -78.5655,
    },

    {
        store_latitdue: -0.284172,
        store_longitude: -78.570403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2595811,
        store_longitude: -78.5527705,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262696,
        store_longitude: -78.547376,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2943523,
        store_longitude: -78.5737819,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2806798,
        store_longitude: -78.55986,
    },
    {
        store_latitdue: -0.2589593,
        store_longitude: -78.5431554,
    },
    {
        store_latitdue: -0.332324,
        store_longitude: -78.5572,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2576289,
        store_longitude: -78.5345428,
    },
    {
        store_latitdue: -0.3072871,
        store_longitude: -78.5534872,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2778951,
        store_longitude: -78.5546241,
    },
    {
        store_latitdue: -0.278728,
        store_longitude: -78.549541,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.334658,
        store_longitude: -78.5706,
    },

    {
        store_latitdue: -0.312518,
        store_longitude: -78.5657,
    },
    {
        store_latitdue: -0.276481,
        store_longitude: -78.549767,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318137,
        store_longitude: -78.554867,
    },
    {
        store_latitdue: -0.25003,
        store_longitude: -78.540438,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264231,
        store_longitude: -78.557032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3398007,
        store_longitude: -78.5512559,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2933264,
        store_longitude: -78.5533504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3060277,
        store_longitude: -78.5602926,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3422141,
        store_longitude: -78.5674229,
    },
    {
        store_latitdue: -0.3007362,
        store_longitude: -78.5702338,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258678,
        store_longitude: -78.5408,
    },
    {
        store_latitdue: -0.2549388,
        store_longitude: -78.5475965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296129,
        store_longitude: -78.57657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117293,
        store_longitude: -78.5034,
    },

    {
        store_latitdue: -0.253459,
        store_longitude: -78.551976,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292148,
        store_longitude: -78.5507,
    },
    {
        store_latitdue: -0.297666,
        store_longitude: -78.5686707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.275181,
        store_longitude: -78.5559,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.271234,
        store_longitude: -78.5399,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2584725,
        store_longitude: -78.5522105,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266074,
        store_longitude: -78.537778,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255435,
        store_longitude: -78.5425,
    },
    {
        store_latitdue: -0.2544904,
        store_longitude: -78.5343193,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271363,
        store_longitude: -78.541017,
    },
    {
        store_latitdue: -0.3128357,
        store_longitude: -78.5658922,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3465267,
        store_longitude: -78.5588689,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3039316,
        store_longitude: -78.5592035,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2487926,
        store_longitude: -78.534497,
    },
    {
        store_latitdue: -0.2858534,
        store_longitude: -78.5644402,
    },

    {
        store_latitdue: -0.300502,
        store_longitude: -78.571342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.31817,
        store_longitude: -78.5544,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3409605,
        store_longitude: -78.5608034,
    },
    {
        store_latitdue: -0.25748,
        store_longitude: -78.556113,
    },
    {
        store_latitdue: -0.3210306,
        store_longitude: -78.5664582,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3220506,
        store_longitude: -78.5686875,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2623178,
        store_longitude: -78.5568575,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3102548,
        store_longitude: -78.5640292,
    },
    {
        store_latitdue: -0.3427191,
        store_longitude: -78.55284,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311664,
        store_longitude: -78.5634,
    },
    {
        store_latitdue: -0.291602,
        store_longitude: -78.5588,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2522305,
        store_longitude: -78.5342258,
    },
    {
        store_latitdue: -0.26356,
        store_longitude: -78.5572597,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3579186,
        store_longitude: -78.5712401,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.257254,
        store_longitude: -78.539721,
    },
    {
        store_latitdue: -0.2996775,
        store_longitude: -78.561971,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303992,
        store_longitude: -78.561872,
    },
    {
        store_latitdue: -0.28162,
        store_longitude: -78.554,
    },
    {
        store_latitdue: -0.0978,
        store_longitude: -78.442802,
    },

    {
        store_latitdue: -0.269175,
        store_longitude: -78.566522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3145504,
        store_longitude: -78.5549279,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.273347,
        store_longitude: -78.5679,
    },
    {
        store_latitdue: -0.253346,
        store_longitude: -78.541621,
    },
    {
        store_latitdue: -0.263921,
        store_longitude: -78.5456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276893,
        store_longitude: -78.5629,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.242556,
        store_longitude: -78.5465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2519099,
        store_longitude: -78.5311443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2568279,
        store_longitude: -78.5565268,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2817734,
        store_longitude: -78.5538282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279438,
        store_longitude: -78.562962,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2787145,
        store_longitude: -78.5528119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3201047,
        store_longitude: -78.5714859,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3340195,
        store_longitude: -78.5523655,
    },
    {
        store_latitdue: -0.333598,
        store_longitude: -78.5697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293445,
        store_longitude: -78.567,
    },
    {
        store_latitdue: -0.274366,
        store_longitude: -78.567859,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2860684,
        store_longitude: -78.5600175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2662735,
        store_longitude: -78.5409303,
    },
    {
        store_latitdue: -0.295985,
        store_longitude: -78.563811,
    },
    {
        store_latitdue: -0.3321118,
        store_longitude: -78.5552932,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2540367,
        store_longitude: -78.5438742,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.257835,
        store_longitude: -78.5319,
    },
    {
        store_latitdue: -0.302754,
        store_longitude: -78.5528,
    },
    {
        store_latitdue: -0.3037501,
        store_longitude: -78.5530792,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261316,
        store_longitude: -78.544872,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3073948,
        store_longitude: -78.5664983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293634,
        store_longitude: -78.565036,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2845899,
        store_longitude: -78.5664442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308275,
        store_longitude: -78.570442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318334,
        store_longitude: -78.552535,
    },
    {
        store_latitdue: -0.360473,
        store_longitude: -78.562,
    },
    {
        store_latitdue: -0.2859298,
        store_longitude: -78.5698575,
    },
    {
        store_latitdue: -0.359044,
        store_longitude: -78.566817,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.260282,
        store_longitude: -78.543079,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311325,
        store_longitude: -78.5675165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2474674,
        store_longitude: -78.5346394,
    },
    {
        store_latitdue: -0.2928709,
        store_longitude: -78.5660321,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.341696,
        store_longitude: -78.5630671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266584,
        store_longitude: -78.5544,
    },
    {
        store_latitdue: -0.308241,
        store_longitude: -78.5704,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2588223,
        store_longitude: -78.5524435,
    },
    {
        store_latitdue: -0.3383076,
        store_longitude: -78.5497424,
    },
    {
        store_latitdue: -0.2628176,
        store_longitude: -78.5469751,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3658699,
        store_longitude: -78.5513553,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3106033,
        store_longitude: -78.5643115,
    },
    {
        store_latitdue: -0.3327141,
        store_longitude: -78.5470588,
    },
    {
        store_latitdue: -0.310603,
        store_longitude: -78.5643116,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2663471,
        store_longitude: -78.5358123,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.299914,
        store_longitude: -78.566119,
    },
    {
        store_latitdue: -0.250244,
        store_longitude: -78.54415,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.301393,
        store_longitude: -78.569335,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.321003,
        store_longitude: -78.5631,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3729948,
        store_longitude: -78.5640892,
    },

    {
        store_latitdue: -0.312813,
        store_longitude: -78.57041,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2437299,
        store_longitude: -78.5440926,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3404134,
        store_longitude: -78.5714035,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.338343,
        store_longitude: -78.560797,
    },
    {
        store_latitdue: -0.3286603,
        store_longitude: -78.5593716,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2730773,
        store_longitude: -78.54003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252177,
        store_longitude: -78.5467325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258634686,
        store_longitude: -78.543523,
    },
    {
        store_latitdue: -0.2559924,
        store_longitude: -78.5520305,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262228,
        store_longitude: -78.5381433,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278357,
        store_longitude: -78.582701,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2533753,
        store_longitude: -78.5500698,
    },

    {
        store_latitdue: -0.2588483,
        store_longitude: -78.5532866,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292241,
        store_longitude: -78.546701,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2659152,
        store_longitude: -78.5535743,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3376265,
        store_longitude: -78.5531745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2702946,
        store_longitude: -78.5583655,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2798122,
        store_longitude: -78.5542249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2551237,
        store_longitude: -78.5520572,
    },

    {
        store_latitdue: -0.25178,
        store_longitude: -78.533929,
    },
    {
        store_latitdue: -0.3029228,
        store_longitude: -78.5623256,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3159116,
        store_longitude: -78.5657196,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288865,
        store_longitude: -78.569378,
    },
    {
        store_latitdue: -0.2906528,
        store_longitude: -78.5813408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29868,
        store_longitude: -78.569343,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2724728,
        store_longitude: -78.5468123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312343,
        store_longitude: -78.5717,
    },

    {
        store_latitdue: -0.2767852,
        store_longitude: -78.5619474,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3107745,
        store_longitude: -78.5726688,
    },
    {
        store_latitdue: -0.299219,
        store_longitude: -78.5683,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2568689,
        store_longitude: -78.5517126,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.358288,
        store_longitude: -78.569709,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3131751,
        store_longitude: -78.5648677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322009,
        store_longitude: -78.5715,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272524,
        store_longitude: -78.5667,
    },
    {
        store_latitdue: -0.2534233,
        store_longitude: -78.5372422,
    },

    {
        store_latitdue: -0.299712,
        store_longitude: -78.562837,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3109883,
        store_longitude: -78.5724918,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.309172,
        store_longitude: -78.5728695,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28622,
        store_longitude: -78.564,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.299934,
        store_longitude: -78.567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3389234,
        store_longitude: -78.5745169,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3092,
        store_longitude: -78.563492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255389,
        store_longitude: -78.5423,
    },
    {
        store_latitdue: -0.2728922,
        store_longitude: -78.5566825,
    },
    {
        store_latitdue: -0.289778,
        store_longitude: -78.581251,
    },

    {
        store_latitdue: -0.2841797,
        store_longitude: -78.5815736,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259026,
        store_longitude: -78.553583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2574766,
        store_longitude: -78.5503997,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3427195,
        store_longitude: -78.5652844,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3027452,
        store_longitude: -78.5573095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2557025,
        store_longitude: -78.5353996,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276308,
        store_longitude: -78.5547,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.373003,
        store_longitude: -78.563917,
    },

    {
        store_latitdue: -0.256812,
        store_longitude: -78.5568,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2473382,
        store_longitude: -78.5816934,
        neighborhood: 'LLOA',
    },
    {
        store_latitdue: -0.2820548,
        store_longitude: -78.5569267,
    },

    {
        store_latitdue: -0.3660494,
        store_longitude: -78.5510822,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.310441,
        store_longitude: -78.5705,
    },
    {
        store_latitdue: -0.2605197,
        store_longitude: -78.549529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.298195,
        store_longitude: -78.5651,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.4183215,
        store_longitude: -78.7953229,
        neighborhood: 'MANUEL CORNEJO ASTORGA',
    },
    {
        store_latitdue: -0.269509,
        store_longitude: -78.5366,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.334061,
        store_longitude: -78.5523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338335,
        store_longitude: -78.56182,
    },
    {
        store_latitdue: -0.305021,
        store_longitude: -78.5701596,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304216,
        store_longitude: -78.566842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2465214951,
        store_longitude: -78.52178543,
    },
    {
        store_latitdue: -0.24716365,
        store_longitude: -78.58193455,
        neighborhood: 'LLOA',
    },
    {
        store_latitdue: -0.2603859,
        store_longitude: -78.5388624,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.285096,
        store_longitude: -78.568307,
    },
    {
        store_latitdue: -0.2910658,
        store_longitude: -78.5740652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.289103,
        store_longitude: -78.5583,
    },
    {
        store_latitdue: -0.303248,
        store_longitude: -78.565895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.291467,
        store_longitude: -78.576581,
    },
    {
        store_latitdue: -0.2979752,
        store_longitude: -78.5635726,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.258981,
        store_longitude: -78.540508,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2762271,
        store_longitude: -78.5631899,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256724,
        store_longitude: -78.5569,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.251654,
        store_longitude: -78.5398,
    },
    {
        store_latitdue: -0.258786,
        store_longitude: -78.5416,
    },
    {
        store_latitdue: -0.261403,
        store_longitude: -78.544746,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2541408,
        store_longitude: -78.5343786,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2588768,
        store_longitude: -78.53377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2585701,
        store_longitude: -78.540963,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261669,
        store_longitude: -78.5441,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262114,
        store_longitude: -78.543144,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2557351,
        store_longitude: -78.5355993,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2533323,
        store_longitude: -78.5348661,
    },
    {
        store_latitdue: -0.252723,
        store_longitude: -78.5342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257152,
        store_longitude: -78.544874,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2566103,
        store_longitude: -78.5439909,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.248066,
        store_longitude: -78.533553,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25041,
        store_longitude: -78.5365,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2503524,
        store_longitude: -78.5355717,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250152,
        store_longitude: -78.5413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251572,
        store_longitude: -78.5421,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2532578,
        store_longitude: -78.5434109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252158,
        store_longitude: -78.539893,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2701426,
        store_longitude: -78.5389486,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2622339,
        store_longitude: -78.5439857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.262346,
        store_longitude: -78.5427,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267518,
        store_longitude: -78.5456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2595444,
        store_longitude: -78.5528991,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2538419,
        store_longitude: -78.552161,
    },
    {
        store_latitdue: -0.26948,
        store_longitude: -78.541816,
    },

    {
        store_latitdue: -0.2697869,
        store_longitude: -78.544018,
    },
    {
        store_latitdue: -0.2697584,
        store_longitude: -78.5439875,
    },
    {
        store_latitdue: -0.267868,
        store_longitude: -78.5394,
    },
    {
        store_latitdue: -0.2657958,
        store_longitude: -78.5407855,
    },
    {
        store_latitdue: -0.2660013,
        store_longitude: -78.53869,
    },
    {
        store_latitdue: -0.253361,
        store_longitude: -78.553,
    },
    {
        store_latitdue: -0.2684342,
        store_longitude: -78.5414915,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2680769,
        store_longitude: -78.5378295,
    },
    {
        store_latitdue: -0.268447,
        store_longitude: -78.5466,
    },
    {
        store_latitdue: -0.2676,
        store_longitude: -78.544498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27396,
        store_longitude: -78.5406,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271508,
        store_longitude: -78.541555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271107,
        store_longitude: -78.5413,
    },
    {
        store_latitdue: -0.281454,
        store_longitude: -78.558432,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2690869,
        store_longitude: -78.5679696,
    },
    {
        store_latitdue: -0.280606,
        store_longitude: -78.5482,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268925,
        store_longitude: -78.566689,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280458,
        store_longitude: -78.5842,
    },
    {
        store_latitdue: -0.2689097,
        store_longitude: -78.5668038,
    },
    {
        store_latitdue: -0.280118,
        store_longitude: -78.5559,
    },

    {
        store_latitdue: -0.277351,
        store_longitude: -78.5555,
    },
    {
        store_latitdue: -0.2784105,
        store_longitude: -78.5592038,
    },
    {
        store_latitdue: -0.268466,
        store_longitude: -78.55085,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265899,
        store_longitude: -78.5536,
    },
    {
        store_latitdue: -0.285467,
        store_longitude: -78.5592,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2857832,
        store_longitude: -78.5648252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2694316,
        store_longitude: -78.5662251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2817731,
        store_longitude: -78.5566697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28373,
        store_longitude: -78.581644,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.288501,
        store_longitude: -78.5753442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269048,
        store_longitude: -78.5479,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278198,
        store_longitude: -78.559469,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278997,
        store_longitude: -78.550866,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279206,
        store_longitude: -78.549745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2768952,
        store_longitude: -78.5470195,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.271259,
        store_longitude: -78.5516,
    },
    {
        store_latitdue: -0.271447,
        store_longitude: -78.5509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2729236,
        store_longitude: -78.5472639,
    },
    {
        store_latitdue: -0.266391,
        store_longitude: -78.544265,
    },
    {
        store_latitdue: -0.273136,
        store_longitude: -78.546736,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2815406,
        store_longitude: -78.5576969,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292671,
        store_longitude: -78.5598,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.291915,
        store_longitude: -78.560734,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279541,
        store_longitude: -78.542111,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2830882,
        store_longitude: -78.5722522,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.273227,
        store_longitude: -78.566314,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279186,
        store_longitude: -78.5428,
    },
    {
        store_latitdue: -0.275182,
        store_longitude: -78.5449,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279525,
        store_longitude: -78.549683,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.287566,
        store_longitude: -78.5652,
    },
    {
        store_latitdue: -0.2641264,
        store_longitude: -78.5454048,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2727514,
        store_longitude: -78.5451685,
    },
    {
        store_latitdue: -0.274804,
        store_longitude: -78.543716,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2873058,
        store_longitude: -78.5649614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.274552,
        store_longitude: -78.567126,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.286629,
        store_longitude: -78.5817,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.278189,
        store_longitude: -78.554177,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3298683,
        store_longitude: -78.5766955,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.298788,
        store_longitude: -78.5668,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.298884,
        store_longitude: -78.5654,
    },
    {
        store_latitdue: -0.3074442,
        store_longitude: -78.5663171,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.30327,
        store_longitude: -78.56027,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.296344,
        store_longitude: -78.5537,
    },

    {
        store_latitdue: -0.321431,
        store_longitude: -78.5724,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308045,
        store_longitude: -78.560378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.328108,
        store_longitude: -78.5796,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303181,
        store_longitude: -78.55668,
    },
    {
        store_latitdue: -0.311458,
        store_longitude: -78.5683,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312166,
        store_longitude: -78.5701,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.301383,
        store_longitude: -78.5664,
    },
    {
        store_latitdue: -0.2922775,
        store_longitude: -78.5633302,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.289741,
        store_longitude: -78.5512,
    },

    {
        store_latitdue: -0.298263,
        store_longitude: -78.565063,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2947769,
        store_longitude: -78.5671702,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3162062,
        store_longitude: -78.5693006,
    },
    {
        store_latitdue: -0.302469,
        store_longitude: -78.5586599,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3405738,
        store_longitude: -78.5718134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322446,
        store_longitude: -78.5537,
    },

    {
        store_latitdue: -0.3389286,
        store_longitude: -78.5742682,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3378589,
        store_longitude: -78.5727857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.339689,
        store_longitude: -78.5703,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.323602,
        store_longitude: -78.5549,
    },
    {
        store_latitdue: -0.3448481,
        store_longitude: -78.5598933,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.247084,
        store_longitude: -78.522014,
    },
    {
        store_latitdue: -0.3115259,
        store_longitude: -78.5544148,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.336926,
        store_longitude: -78.565708,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.342549,
        store_longitude: -78.5517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3691102,
        store_longitude: -78.5613375,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.344478,
        store_longitude: -78.5519,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3477,
        store_longitude: -78.552794,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.372765,
        store_longitude: -78.564,
    },

    {
        store_latitdue: -0.3217532,
        store_longitude: -78.5627982,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.321385,
        store_longitude: -78.5627,
    },
    {
        store_latitdue: -0.332288,
        store_longitude: -78.5575,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243307,
        store_longitude: -78.541059,
    },
    {
        store_latitdue: -0.3659537,
        store_longitude: -78.551299,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3067145,
        store_longitude: -78.5535696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.33937,
        store_longitude: -78.561478,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.314133,
        store_longitude: -78.5596,
    },
    {
        store_latitdue: -0.3312981,
        store_longitude: -78.5641936,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.354784,
        store_longitude: -78.5505,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.354864,
        store_longitude: -78.549409,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.352299,
        store_longitude: -78.549728,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.352231,
        store_longitude: -78.55,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3477632,
        store_longitude: -78.5586314,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3464197,
        store_longitude: -78.5546383,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3466405,
        store_longitude: -78.5545882,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318542,
        store_longitude: -78.5537,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2557456,
        store_longitude: -78.5357336,
    },

    {
        store_latitdue: -0.2556041,
        store_longitude: -78.5364446,
    },
    {
        store_latitdue: -0.254985,
        store_longitude: -78.532838,
    },
    {
        store_latitdue: -0.248227,
        store_longitude: -78.5329,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2648788,
        store_longitude: -78.5539673,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266778,
        store_longitude: -78.540189,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2659,
        store_longitude: -78.5639,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270621,
        store_longitude: -78.54036,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271465,
        store_longitude: -78.541526,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.26817,
        store_longitude: -78.543943,
    },
    {
        store_latitdue: -0.262219,
        store_longitude: -78.552569,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2900179,
        store_longitude: -78.5813401,
    },

    {
        store_latitdue: -0.273103,
        store_longitude: -78.564853,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.279613,
        store_longitude: -78.544,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2766504,
        store_longitude: -78.5427857,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2910814,
        store_longitude: -78.5683885,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276541,
        store_longitude: -78.5582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2887735,
        store_longitude: -78.5693068,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2736475,
        store_longitude: -78.5436157,
    },
    {
        store_latitdue: -0.2732093,
        store_longitude: -78.5464847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2711962,
        store_longitude: -78.5455081,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271952,
        store_longitude: -78.5439231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2799268,
        store_longitude: -78.5650479,
    },
    {
        store_latitdue: -0.2840811,
        store_longitude: -78.5704532,
    },
    {
        store_latitdue: -0.278583,
        store_longitude: -78.5535,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.272342,
        store_longitude: -78.548476,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3008648,
        store_longitude: -78.5665063,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.301177,
        store_longitude: -78.5614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2992998,
        store_longitude: -78.5682016,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32992,
        store_longitude: -78.5765,
    },
    {
        store_latitdue: -0.293822,
        store_longitude: -78.5639,
    },

    {
        store_latitdue: -0.2999389,
        store_longitude: -78.5633785,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3012716,
        store_longitude: -78.5629116,
    },
    {
        store_latitdue: -0.3037871,
        store_longitude: -78.5566175,
    },

    {
        store_latitdue: -0.3011713,
        store_longitude: -78.5627536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.321248,
        store_longitude: -78.573828,
    },
    {
        store_latitdue: -0.298239,
        store_longitude: -78.572328,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.300931,
        store_longitude: -78.57029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3013836,
        store_longitude: -78.5630101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2934647,
        store_longitude: -78.5476666,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3004814,
        store_longitude: -78.5021046,
    },
    {
        store_latitdue: -0.3109102,
        store_longitude: -78.5596725,
    },
    {
        store_latitdue: -0.360857,
        store_longitude: -78.560448,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.36074125,
        store_longitude: -78.56072235,
    },

    {
        store_latitdue: -0.3323,
        store_longitude: -78.558582,
    },
    {
        store_latitdue: -0.33469,
        store_longitude: -78.5535,
    },
    {
        store_latitdue: -0.237117,
        store_longitude: -78.5304,
    },
    {
        store_latitdue: -0.323839,
        store_longitude: -78.5578,
    },
    {
        store_latitdue: -0.3327471,
        store_longitude: -78.5705096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3056806,
        store_longitude: -78.5534029,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.345028,
        store_longitude: -78.557907,
    },

    {
        store_latitdue: -0.35138863,
        store_longitude: -78.5537266,
    },
    {
        store_latitdue: -0.3155848,
        store_longitude: -78.5587491,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2461381,
        store_longitude: -78.5484455,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.334238,
        store_longitude: -78.558889,
    },
    {
        store_latitdue: -0.258398,
        store_longitude: -78.5437,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2470324,
        store_longitude: -78.5402974,
    },
    {
        store_latitdue: -0.249557,
        store_longitude: -78.5453,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2567342,
        store_longitude: -78.5508426,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2589897,
        store_longitude: -78.5467831,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25832447,
        store_longitude: -78.5514144,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246519,
        store_longitude: -78.542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2578079,
        store_longitude: -78.5415824,
    },
    {
        store_latitdue: -0.24774,
        store_longitude: -78.5333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2648816,
        store_longitude: -78.5572564,
    },
    {
        store_latitdue: -0.2481689,
        store_longitude: -78.5808058,
        neighborhood: 'LLOA',
    },
    {
        store_latitdue: -0.267824,
        store_longitude: -78.539644,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269454,
        store_longitude: -78.543865,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265886,
        store_longitude: -78.553332,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25798,
        store_longitude: -78.545422,
    },
    {
        store_latitdue: -0.2617356,
        store_longitude: -78.5384366,
    },

    {
        store_latitdue: -0.269608,
        store_longitude: -78.549434,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2899037,
        store_longitude: -78.5820865,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.278956,
        store_longitude: -78.5559,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.248995,
        store_longitude: -78.5321,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2735818,
        store_longitude: -78.548198,
    },
    {
        store_latitdue: -0.28318,
        store_longitude: -78.5555379,
    },
    {
        store_latitdue: -0.303193,
        store_longitude: -78.562614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3043582,
        store_longitude: -78.5668853,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3180136,
        store_longitude: -78.5716409,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3174151,
        store_longitude: -78.5700006,
    },
    {
        store_latitdue: -0.305267,
        store_longitude: -78.56142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3039859,
        store_longitude: -78.5554824,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3248886,
        store_longitude: -78.5637987,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.207216,
        store_longitude: -78.50912,
    },
    {
        store_latitdue: -0.332743,
        store_longitude: -78.5712,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3519434,
        store_longitude: -78.5513021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3369674,
        store_longitude: -78.5573552,
    },
    {
        store_latitdue: -0.332056,
        store_longitude: -78.560152,
    },
    {
        store_latitdue: -0.3148037,
        store_longitude: -78.5643067,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.375589,
        store_longitude: -78.5604757,
    },

    {
        store_latitdue: -0.3257234,
        store_longitude: -78.5609487,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.258959,
        store_longitude: -78.5524299,
    },
    {
        store_latitdue: -0.2729511,
        store_longitude: -78.5423893,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2838183,
        store_longitude: -78.5552266,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2992678,
        store_longitude: -78.5681923,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305832,
        store_longitude: -78.561474,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304827,
        store_longitude: -78.5688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.314342,
        store_longitude: -78.5698,
    },
    {
        store_latitdue: -0.295088,
        store_longitude: -78.575307,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2966413,
        store_longitude: -78.5747387,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2963215,
        store_longitude: -78.5600804,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2976505,
        store_longitude: -78.5629691,
    },
    {
        store_latitdue: -0.3116496,
        store_longitude: -78.5591075,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3210128,
        store_longitude: -78.5638021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.34351,
        store_longitude: -78.562718,
    },
    {
        store_latitdue: -0.3432792,
        store_longitude: -78.5627161,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.344953,
        store_longitude: -78.5579657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.330393,
        store_longitude: -78.563811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25249161,
        store_longitude: -78.5342001,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2473917,
        store_longitude: -78.5330803,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.26816,
        store_longitude: -78.561595,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277553,
        store_longitude: -78.583119,
    },

    {
        store_latitdue: -0.3030524,
        store_longitude: -78.5633556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3217863,
        store_longitude: -78.5575218,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3584799,
        store_longitude: -78.5693716,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3462195,
        store_longitude: -78.5601301,
    },
    {
        store_latitdue: -0.3185429,
        store_longitude: -78.5550439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3468285,
        store_longitude: -78.5594866,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.24947,
        store_longitude: -78.5342,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.28124,
        store_longitude: -78.5587,
    },
    {
        store_latitdue: -0.27744,
        store_longitude: -78.5527,
    },
    {
        store_latitdue: -0.294365,
        store_longitude: -78.574,
    },
    {
        store_latitdue: -0.324444,
        store_longitude: -78.572002,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.303206,
        store_longitude: -78.5722,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3029724,
        store_longitude: -78.5707397,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303215,
        store_longitude: -78.5699,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.344391,
        store_longitude: -78.5518,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3716122,
        store_longitude: -78.5638683,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3731738,
        store_longitude: -78.5596949,
        neighborhood: 'CUTUGLAHUA',
    },

    {
        store_latitdue: -0.3464959,
        store_longitude: -78.5560976,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.29116,
        store_longitude: -78.5693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293536,
        store_longitude: -78.548583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3292738,
        store_longitude: -78.5317318,
    },
    {
        store_latitdue: -0.297976,
        store_longitude: -78.561371,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.250408,
        store_longitude: -78.5363,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265473,
        store_longitude: -78.5533,
    },

    {
        store_latitdue: -0.304964,
        store_longitude: -78.5576,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2929638,
        store_longitude: -78.5753039,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2709375,
        store_longitude: -78.5426455,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.275637,
        store_longitude: -78.545158,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2945194,
        store_longitude: -78.5735657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2982537,
        store_longitude: -78.5687981,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.323762,
        store_longitude: -78.558164,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.323881,
        store_longitude: -78.5579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3440686,
        store_longitude: -78.568018,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3427506,
        store_longitude: -78.5735363,
    },
    {
        store_latitdue: -0.3386431,
        store_longitude: -78.567374,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2558568,
        store_longitude: -78.5383696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2418607,
        store_longitude: -78.5464906,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267602,
        store_longitude: -78.5355,
    },
    {
        store_latitdue: -0.254141,
        store_longitude: -78.5339,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304164,
        store_longitude: -78.5418,
    },
    {
        store_latitdue: -0.243946,
        store_longitude: -78.5397,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272197,
        store_longitude: -78.5432,
    },
    {
        store_latitdue: -0.265913,
        store_longitude: -78.554049,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272876,
        store_longitude: -78.5388,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272733,
        store_longitude: -78.540859,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2720382,
        store_longitude: -78.5430976,
    },

    {
        store_latitdue: -0.2803784,
        store_longitude: -78.560198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2725781,
        store_longitude: -78.5483095,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.280928,
        store_longitude: -78.560693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2808033,
        store_longitude: -78.5537309,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2784267,
        store_longitude: -78.5592313,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.261019,
        store_longitude: -78.5343,
    },
    {
        store_latitdue: -0.24239,
        store_longitude: -78.538,
    },
    {
        store_latitdue: -0.2519527,
        store_longitude: -78.5367476,
    },
    {
        store_latitdue: -0.251981,
        store_longitude: -78.536572,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.244045,
        store_longitude: -78.5395,
    },
    {
        store_latitdue: -0.241522,
        store_longitude: -78.5354,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.243739,
        store_longitude: -78.53338,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243761,
        store_longitude: -78.5353,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243914,
        store_longitude: -78.535109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.245529,
        store_longitude: -78.5443,
    },
    {
        store_latitdue: -0.255555,
        store_longitude: -78.542092,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.242083,
        store_longitude: -78.5365,
    },

    {
        store_latitdue: -0.2588455,
        store_longitude: -78.5601745,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.283106,
        store_longitude: -78.5575,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.290628,
        store_longitude: -78.566256,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.23716,
        store_longitude: -78.5384,
    },
    {
        store_latitdue: -0.290497,
        store_longitude: -78.5658,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.273108,
        store_longitude: -78.550253,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29926,
        store_longitude: -78.5680662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2864277,
        store_longitude: -78.5891302,
    },
    {
        store_latitdue: -0.305297,
        store_longitude: -78.564,
    },
    {
        store_latitdue: -0.2933043,
        store_longitude: -78.5612498,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.333787,
        store_longitude: -78.5642,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.339017,
        store_longitude: -78.555541,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.342686,
        store_longitude: -78.565579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.339813,
        store_longitude: -78.5565,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.339992,
        store_longitude: -78.556394,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3407333,
        store_longitude: -78.5563739,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.366162,
        store_longitude: -78.550985,
    },
    {
        store_latitdue: -0.320496,
        store_longitude: -78.554345,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.241949,
        store_longitude: -78.532969,
    },
    {
        store_latitdue: -0.2464616,
        store_longitude: -78.5348063,
    },
    {
        store_latitdue: -0.24454,
        store_longitude: -78.5338,
    },
    {
        store_latitdue: -0.24179,
        store_longitude: -78.532702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.245864,
        store_longitude: -78.535513,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2427341,
        store_longitude: -78.5394192,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277039,
        store_longitude: -78.5675,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26656,
        store_longitude: -78.568449,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3259994,
        store_longitude: -78.573341,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3579128,
        store_longitude: -78.5713186,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.250821,
        store_longitude: -78.5358,
    },
    {
        store_latitdue: -0.252408,
        store_longitude: -78.534342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250121,
        store_longitude: -78.542119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251485,
        store_longitude: -78.5422,
    },
    {
        store_latitdue: -0.273413,
        store_longitude: -78.5682,
    },
    {
        store_latitdue: -0.3156795,
        store_longitude: -78.5606422,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.340931,
        store_longitude: -78.5662886,
    },
    {
        store_latitdue: -0.360758,
        store_longitude: -78.5612,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.301709,
        store_longitude: -78.5523,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2986819,
        store_longitude: -78.5600474,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.324992,
        store_longitude: -78.5586,
    },
    {
        store_latitdue: -0.273477,
        store_longitude: -78.5565,
    },
    {
        store_latitdue: -0.2591292,
        store_longitude: -78.5430928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2431335,
        store_longitude: -78.543381,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2445651,
        store_longitude: -78.5359067,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.283911,
        store_longitude: -78.5599,
    },

    {
        store_latitdue: -0.351121,
        store_longitude: -78.5575,
    },

    {
        store_latitdue: -0.2783715,
        store_longitude: -78.5550922,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2824735,
        store_longitude: -78.5604257,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281671,
        store_longitude: -78.5638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3140042,
        store_longitude: -78.5647204,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.243629,
        store_longitude: -78.5343,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2846927,
        store_longitude: -78.5706427,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2448029,
        store_longitude: -78.5348921,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280993,
        store_longitude: -78.557357,
    },
    {
        store_latitdue: -0.302113,
        store_longitude: -78.569888,
    },
    {
        store_latitdue: -0.295413,
        store_longitude: -78.573528,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3127939,
        store_longitude: -78.5529382,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260079,
        store_longitude: -78.5337,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.334995,
        store_longitude: -78.564713,
    },
    {
        store_latitdue: -0.330505,
        store_longitude: -78.5633,
    },
    {
        store_latitdue: -0.3071169,
        store_longitude: -78.5535389,
    },
    {
        store_latitdue: -0.342331,
        store_longitude: -78.5671,
    },
    {
        store_latitdue: -0.260684,
        store_longitude: -78.5439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272823,
        store_longitude: -78.5388,
    },

    {
        store_latitdue: -0.29789,
        store_longitude: -78.5685,
    },
    {
        store_latitdue: -0.32361826,
        store_longitude: -78.5556082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253803,
        store_longitude: -78.5521,
    },
    {
        store_latitdue: -0.25425,
        store_longitude: -78.5516,
    },
    {
        store_latitdue: -0.2860747,
        store_longitude: -78.5633348,
    },

    {
        store_latitdue: -0.250145,
        store_longitude: -78.508007,
    },
    {
        store_latitdue: -0.3267294,
        store_longitude: -78.5582927,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3729507,
        store_longitude: -78.563929,
    },
    {
        store_latitdue: -0.2470012,
        store_longitude: -78.5440797,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2704282,
        store_longitude: -78.5383655,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269136,
        store_longitude: -78.545562,
    },
    {
        store_latitdue: -0.2711538,
        store_longitude: -78.5654252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.311171,
        store_longitude: -78.5628341,
    },
    {
        store_latitdue: -0.288905,
        store_longitude: -78.5524,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318709,
        store_longitude: -78.5556,
    },
    {
        store_latitdue: -0.320418,
        store_longitude: -78.5553,
    },
    {
        store_latitdue: -0.2541099,
        store_longitude: -78.5343038,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2705421,
        store_longitude: -78.5653142,
    },
    {
        store_latitdue: -0.332625,
        store_longitude: -78.5459,
    },

    {
        store_latitdue: -0.325927,
        store_longitude: -78.557527,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.32628,
        store_longitude: -78.556,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3754553,
        store_longitude: -78.5607335,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.320944,
        store_longitude: -78.5637,
    },
    {
        store_latitdue: -0.2780573,
        store_longitude: -78.5579224,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.30194,
        store_longitude: -78.562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2420542,
        store_longitude: -78.5463277,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3303832,
        store_longitude: -78.5695917,
    },
    {
        store_latitdue: -0.267495,
        store_longitude: -78.5401,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260863,
        store_longitude: -78.546957,
    },
    {
        store_latitdue: -0.3585782,
        store_longitude: -78.5687195,
    },
    {
        store_latitdue: -0.26495,
        store_longitude: -78.555691,
    },
    {
        store_latitdue: -0.285198,
        store_longitude: -78.5661,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.308009,
        store_longitude: -78.5536,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.281145,
        store_longitude: -78.5589,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2611042,
        store_longitude: -78.539482,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2771437,
        store_longitude: -78.5438659,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3036401,
        store_longitude: -78.5670177,
    },
    {
        store_latitdue: -0.292447,
        store_longitude: -78.5597773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.251847,
        store_longitude: -78.532831,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.259398,
        store_longitude: -78.540714,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2971456,
        store_longitude: -78.56441,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.342068,
        store_longitude: -78.5577,
    },
    {
        store_latitdue: -0.3476038,
        store_longitude: -78.5527349,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253304,
        store_longitude: -78.541607,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2688107,
        store_longitude: -78.542507,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2939778,
        store_longitude: -78.5538308,
    },

    {
        store_latitdue: -0.339555,
        store_longitude: -78.5701,
    },
    {
        store_latitdue: -0.2728938,
        store_longitude: -78.5566663,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2677506,
        store_longitude: -78.5442669,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2755138,
        store_longitude: -78.5423045,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3075186,
        store_longitude: -78.5612503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2997626,
        store_longitude: -78.5698371,
    },
    {
        store_latitdue: -0.2413391,
        store_longitude: -78.5345099,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2489653,
        store_longitude: -78.5294093,
    },
    {
        store_latitdue: -0.311334,
        store_longitude: -78.5649,
    },
    {
        store_latitdue: -1.03665,
        store_longitude: -78.5855,
    },
    {
        store_latitdue: -0.2948568,
        store_longitude: -78.573219,
    },
    {
        store_latitdue: -0.300045,
        store_longitude: -78.5621,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271045,
        store_longitude: -78.5379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246874,
        store_longitude: -78.5424,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2708066,
        store_longitude: -78.539476,
    },
    {
        store_latitdue: -0.28883,
        store_longitude: -78.5606,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3150528,
        store_longitude: -78.559567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3000667,
        store_longitude: -78.5701416,
    },
    {
        store_latitdue: -0.344778,
        store_longitude: -78.5593,
    },
    {
        store_latitdue: -0.25477,
        store_longitude: -78.562,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266679,
        store_longitude: -78.5484,
    },
    {
        store_latitdue: -0.2812423,
        store_longitude: -78.5536784,
    },
    {
        store_latitdue: -0.325962,
        store_longitude: -78.5739,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3292293,
        store_longitude: -78.5599344,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3107204,
        store_longitude: -78.5683347,
    },
    {
        store_latitdue: -0.288907,
        store_longitude: -78.5603312,
    },
    {
        store_latitdue: -0.289759735,
        store_longitude: -78.55162896,
    },
    {
        store_latitdue: -0.2799169,
        store_longitude: -78.5834958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.295578,
        store_longitude: -78.5721276,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269421,
        store_longitude: -78.542,
    },
    {
        store_latitdue: -0.308153,
        store_longitude: -78.5611,
    },
    {
        store_latitdue: -0.337863,
        store_longitude: -78.5556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2671814,
        store_longitude: -78.5342905,
    },

    {
        store_latitdue: -0.343618,
        store_longitude: -78.5625,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272433,
        store_longitude: -78.5484,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.302051,
        store_longitude: -78.573,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.301742,
        store_longitude: -78.573231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276128,
        store_longitude: -78.5482,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.340241,
        store_longitude: -78.561,
    },

    {
        store_latitdue: -0.284111,
        store_longitude: -78.5702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.294114,
        store_longitude: -78.5697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292966,
        store_longitude: -78.5722,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.350098,
        store_longitude: -78.5517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2655722,
        store_longitude: -78.5563112,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.293231,
        store_longitude: -78.5712143,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.294462,
        store_longitude: -78.5698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3024088,
        store_longitude: -78.5567176,
    },

    {
        store_latitdue: -0.2628793,
        store_longitude: -78.5470613,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.256084,
        store_longitude: -78.5363,
    },
    {
        store_latitdue: -0.2641047,
        store_longitude: -78.5378707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3121201,
        store_longitude: -78.5599044,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.309713,
        store_longitude: -78.568489,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2935053,
        store_longitude: -78.5702308,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.345547,
        store_longitude: -78.5638,
    },
    {
        store_latitdue: -0.36175,
        store_longitude: -78.5609,
    },
    {
        store_latitdue: -0.347977,
        store_longitude: -78.5504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3011118,
        store_longitude: -78.5666121,
    },
    {
        store_latitdue: -0.2677198,
        store_longitude: -78.5577494,
    },
    {
        store_latitdue: -0.2548935,
        store_longitude: -78.537309,
    },

    {
        store_latitdue: -0.2849918,
        store_longitude: -78.5618591,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.347258,
        store_longitude: -78.5549,
    },
    {
        store_latitdue: -0.2733425,
        store_longitude: -78.564649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.284878,
        store_longitude: -78.5688,
    },
    {
        store_latitdue: -0.222017,
        store_longitude: -78.5201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2437755,
        store_longitude: -78.5415894,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2916019,
        store_longitude: -78.5763007,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296479,
        store_longitude: -78.5537657,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3295368,
        store_longitude: -78.5307609,
    },
    {
        store_latitdue: -0.321198,
        store_longitude: -78.5719,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3067462,
        store_longitude: -78.56657,
    },
    {
        store_latitdue: -0.308992,
        store_longitude: -78.5629,
    },

    {
        store_latitdue: -3.2516005,
        store_longitude: -79.8337226,
        neighborhood: 'EL GUABO',
    },
    {
        store_latitdue: -0.2647942,
        store_longitude: -78.5370487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266696,
        store_longitude: -78.5395,
    },
    {
        store_latitdue: -0.314682,
        store_longitude: -78.5687,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2618435,
        store_longitude: -78.545454,
    },
    {
        store_latitdue: -0.279645,
        store_longitude: -78.556245,
    },
    {
        store_latitdue: -0.3187074,
        store_longitude: -78.5613029,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254016,
        store_longitude: -78.5543,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312861,
        store_longitude: -78.560405,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2526547,
        store_longitude: -78.5380345,
    },
    {
        store_latitdue: -0.338579,
        store_longitude: -78.5617,
    },
    {
        store_latitdue: -0.2659473,
        store_longitude: -78.5608078,
    },
    {
        store_latitdue: -0.251483,
        store_longitude: -78.5399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2611997,
        store_longitude: -78.5322522,
    },
    {
        store_latitdue: -0.25922,
        store_longitude: -78.5317,
    },

    {
        store_latitdue: -0.296196,
        store_longitude: -78.5592,
    },
    {
        store_latitdue: -0.24481,
        store_longitude: -78.5429,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.348046,
        store_longitude: -78.5503,
    },
    {
        store_latitdue: -0.267012,
        store_longitude: -78.5449,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2459077,
        store_longitude: -78.5452202,
    },
    {
        store_latitdue: -0.316727,
        store_longitude: -78.5596,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.315448,
        store_longitude: -78.569,
    },
    {
        store_latitdue: -0.294983,
        store_longitude: -78.5593,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3462199,
        store_longitude: -78.5574643,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2666933,
        store_longitude: -78.5381467,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3365481,
        store_longitude: -78.5718732,
    },

    {
        store_latitdue: -0.331553,
        store_longitude: -78.5581,
    },
    {
        store_latitdue: -0.289064,
        store_longitude: -78.555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.335379,
        store_longitude: -78.5414,
    },
    {
        store_latitdue: -3.9856666,
        store_longitude: -79.2098642,
    },
    {
        store_latitdue: -0.328836,
        store_longitude: -78.5594,
    },
    {
        store_latitdue: -0.290081,
        store_longitude: -78.550921,
    },
    {
        store_latitdue: -0.295835,
        store_longitude: -78.561028,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276759,
        store_longitude: -78.557785,
    },
    {
        store_latitdue: -0.276849,
        store_longitude: -78.5416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241879,
        store_longitude: -78.5435,
    },
    {
        store_latitdue: -0.258184,
        store_longitude: -78.5375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.328936,
        store_longitude: -78.5595,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2637843,
        store_longitude: -78.5375627,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278646,
        store_longitude: -78.5574,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259922,
        store_longitude: -78.5479,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2603922,
        store_longitude: -78.535132,
    },
    {
        store_latitdue: -0.272076,
        store_longitude: -78.5437,
    },
    {
        store_latitdue: -0.2569538,
        store_longitude: -78.5312608,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293161,
        store_longitude: -78.55905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2827772,
        store_longitude: -78.5721649,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.246024,
        store_longitude: -78.5827,
        neighborhood: 'LLOA',
    },
    {
        store_latitdue: -0.248197,
        store_longitude: -78.5819,
        neighborhood: 'LLOA',
    },
    {
        store_latitdue: -0.2637687,
        store_longitude: -78.5373517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.315244,
        store_longitude: -78.5689,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2598832,
        store_longitude: -78.5473017,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.26432,
        store_longitude: -78.522763,
    },
    {
        store_latitdue: -0.337691,
        store_longitude: -78.567147,
    },
    {
        store_latitdue: -0.3259983,
        store_longitude: -78.5599546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2686143,
        store_longitude: -78.5400598,
    },
    {
        store_latitdue: -0.376156,
        store_longitude: -78.5598,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.2627595,
        store_longitude: -78.5380692,
    },
    {
        store_latitdue: -0.3353793,
        store_longitude: -78.5414027,
    },
    {
        store_latitdue: -0.309321,
        store_longitude: -78.561175,
    },
    {
        store_latitdue: -0.340029,
        store_longitude: -78.5612,
    },

    {
        store_latitdue: -0.3383789,
        store_longitude: -78.5618154,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280165,
        store_longitude: -78.5822,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3158327,
        store_longitude: -78.5473506,
    },
    {
        store_latitdue: -0.2504373,
        store_longitude: -78.5199399,
    },
    {
        store_latitdue: -0.255633,
        store_longitude: -78.553257,
    },
    {
        store_latitdue: -0.312737,
        store_longitude: -78.5523,
    },
    {
        store_latitdue: -0.315724,
        store_longitude: -78.545,
    },
    {
        store_latitdue: -0.28551,
        store_longitude: -78.5601,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3310342,
        store_longitude: -78.563296,
    },

    {
        store_latitdue: -0.3095446,
        store_longitude: -78.5534372,
    },
    {
        store_latitdue: -0.332364,
        store_longitude: -78.556958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.326429,
        store_longitude: -78.5585,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257398,
        store_longitude: -78.5305,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.359273,
        store_longitude: -78.5668,
    },
    {
        store_latitdue: -0.30056,
        store_longitude: -78.5661,
    },
    {
        store_latitdue: -0.26831,
        store_longitude: -78.5373,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2939781,
        store_longitude: -78.5633745,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2702083,
        store_longitude: -78.5401632,
    },

    {
        store_latitdue: -0.3402551,
        store_longitude: -78.5577783,
    },
    {
        store_latitdue: -0.253383,
        store_longitude: -78.5368,
    },
    {
        store_latitdue: -0.269096,
        store_longitude: -78.5434,
    },
    {
        store_latitdue: -0.317979,
        store_longitude: -78.572737,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.317462,
        store_longitude: -78.5699,
    },
    {
        store_latitdue: -0.28109,
        store_longitude: -78.56,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.296239,
        store_longitude: -78.5692,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2588774,
        store_longitude: -78.5344924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254911,
        store_longitude: -78.5439,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2677,
        store_longitude: -78.5479,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.300535,
        store_longitude: -78.5611,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.266621,
        store_longitude: -78.5449,
    },
    {
        store_latitdue: -0.296141,
        store_longitude: -78.5645504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318691,
        store_longitude: -78.5606,
    },
    {
        store_latitdue: -0.272587,
        store_longitude: -78.541,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.255843,
        store_longitude: -78.5346,
    },
    {
        store_latitdue: -0.3072804,
        store_longitude: -78.5648146,
    },
    {
        store_latitdue: -0.302773,
        store_longitude: -78.556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2857925,
        store_longitude: -78.5607146,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3374996,
        store_longitude: -78.5581451,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2625524,
        store_longitude: -78.5377837,
    },
    {
        store_latitdue: -0.290046,
        store_longitude: -78.5651,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.314105,
        store_longitude: -78.5603,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2647325,
        store_longitude: -78.544303,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2874249,
        store_longitude: -78.5599133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2893223,
        store_longitude: -78.5666245,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.336671,
        store_longitude: -78.5762,
    },
    {
        store_latitdue: -0.292922,
        store_longitude: -78.5765,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3170113,
        store_longitude: -78.5536432,
    },
    {
        store_latitdue: -0.2865362,
        store_longitude: -78.5822738,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2807172,
        store_longitude: -78.5535242,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.322658,
        store_longitude: -78.5588,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2785351,
        store_longitude: -78.5536077,
    },
    {
        store_latitdue: -0.3260778,
        store_longitude: -78.5596838,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280678,
        store_longitude: -78.55583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.259348,
        store_longitude: -78.5331,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2734706,
        store_longitude: -78.5565411,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.278552,
        store_longitude: -78.544,
    },
    {
        store_latitdue: -0.287535,
        store_longitude: -78.5578,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.344922,
        store_longitude: -78.5563,
    },
    {
        store_latitdue: 0.9723526,
        store_longitude: -79.6545157,
    },
    {
        store_latitdue: -0.3002709,
        store_longitude: -78.566604,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.25561,
        store_longitude: -78.559977,
    },

    {
        store_latitdue: -0.276254,
        store_longitude: -78.547937,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3222224,
        store_longitude: -78.554206,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.278861,
        store_longitude: -78.565504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2227476,
        store_longitude: -78.5177235,
    },
    {
        store_latitdue: -0.337108,
        store_longitude: -78.5529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259003,
        store_longitude: -78.5304,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2734077,
        store_longitude: -78.5681931,
    },
    {
        store_latitdue: -0.2888043,
        store_longitude: -78.5602873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2764289,
        store_longitude: -78.5471198,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.29682868,
        store_longitude: -78.5592677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3505282,
        store_longitude: -78.551171,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2597699,
        store_longitude: -78.5542837,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.295916,
        store_longitude: -78.5649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3376935,
        store_longitude: -78.5652234,
    },
    {
        store_latitdue: -0.2579386,
        store_longitude: -78.541132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260292,
        store_longitude: -78.5417,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.26126,
        store_longitude: -78.5386,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2808053,
        store_longitude: -78.5639801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3088118,
        store_longitude: -78.5527457,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.250326,
        store_longitude: -78.5412,
    },
    {
        store_latitdue: -0.3146366,
        store_longitude: -78.5595564,
    },

    {
        store_latitdue: -0.2568196,
        store_longitude: -78.5325666,
    },
    {
        store_latitdue: -0.308939,
        store_longitude: -78.5528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.291371,
        store_longitude: -78.5814,
    },
    {
        store_latitdue: -0.3037857,
        store_longitude: -78.5572375,
    },
    {
        store_latitdue: -0.260748,
        store_longitude: -78.5528,
    },

    {
        store_latitdue: -0.267369,
        store_longitude: -78.5503,
    },
    {
        store_latitdue: -0.2879997,
        store_longitude: -78.5660492,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280131,
        store_longitude: -78.5604,
    },
    {
        store_latitdue: -0.289311,
        store_longitude: -78.5616384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2974274,
        store_longitude: -78.5700313,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2538311,
        store_longitude: -78.5486337,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257794,
        store_longitude: -78.5325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.236659,
        store_longitude: -78.5286,
    },

    {
        store_latitdue: -0.278106,
        store_longitude: -78.5552,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.305172,
        store_longitude: -78.5607,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3394091,
        store_longitude: -78.5557279,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.338174,
        store_longitude: -78.555486,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278773,
        store_longitude: -78.556,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.252975,
        store_longitude: -78.5451,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249722,
        store_longitude: -78.5419,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2539394,
        store_longitude: -78.5404378,
    },
    {
        store_latitdue: -0.264448,
        store_longitude: -78.5493,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.34304175,
        store_longitude: -78.5646271,
    },
    {
        store_latitdue: -0.3420817,
        store_longitude: -78.557626,
    },

    {
        store_latitdue: -0.28212,
        store_longitude: -78.5569,
    },
    {
        store_latitdue: -0.2840294,
        store_longitude: -78.5580704,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3056404,
        store_longitude: -78.5601171,
    },

    {
        store_latitdue: -0.3756367,
        store_longitude: -78.5603315,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.335293,
        store_longitude: -78.544783,
    },

    {
        store_latitdue: -0.31351,
        store_longitude: -78.5373,
    },

    {
        store_latitdue: -0.341338,
        store_longitude: -78.5650199,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2773631,
        store_longitude: -78.584307,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3228498,
        store_longitude: -78.5757003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.285143,
        store_longitude: -78.5682,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.243781856,
        store_longitude: -78.53436384,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2719528,
        store_longitude: -78.5430413,
    },
    {
        store_latitdue: -0.3606955,
        store_longitude: -78.561102,
    },

    {
        store_latitdue: -0.2893,
        store_longitude: -78.56773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3014606,
        store_longitude: -78.5670004,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.313466,
        store_longitude: -78.5691,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3110255,
        store_longitude: -78.5694289,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2781096,
        store_longitude: -78.5579618,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2942533,
        store_longitude: -78.5632267,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.278728,
        store_longitude: -78.5574863,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.278535,
        store_longitude: -78.5618,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.293691,
        store_longitude: -78.564203,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2980066,
        store_longitude: -78.5634782,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3025506,
        store_longitude: -78.5565174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3186083,
        store_longitude: -78.5578581,
    },
    {
        store_latitdue: -0.318966,
        store_longitude: -78.562484,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2599196,
        store_longitude: -78.5393772,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -1.17225,
        store_longitude: -78.5505,
    },
    {
        store_latitdue: -0.3327399,
        store_longitude: -78.5497697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2580245,
        store_longitude: -78.5312402,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257044,
        store_longitude: -78.53,
    },
    {
        store_latitdue: -0.2788338,
        store_longitude: -78.5836618,
    },
    {
        store_latitdue: -0.2892205,
        store_longitude: -78.5581134,
    },
    {
        store_latitdue: -0.30315425,
        store_longitude: -78.5528945,
    },
    {
        store_latitdue: -0.259663,
        store_longitude: -78.542623,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2835617,
        store_longitude: -78.5630858,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259724,
        store_longitude: -78.5428107,
    },
    {
        store_latitdue: -0.305357,
        store_longitude: -78.5486,
    },

    {
        store_latitdue: -0.2603622,
        store_longitude: -78.5361196,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.263488,
        store_longitude: -78.5472,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.280681,
        store_longitude: -78.5563,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.337697,
        store_longitude: -78.5725,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.340695,
        store_longitude: -78.5687979,
    },
    {
        store_latitdue: -0.227565,
        store_longitude: -78.5231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2765151,
        store_longitude: -78.564921,
    },
    {
        store_latitdue: -0.2941621,
        store_longitude: -78.5516516,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.253482,
        store_longitude: -78.5378757,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.252078,
        store_longitude: -78.53984,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272417,
        store_longitude: -78.542903,
    },
    {
        store_latitdue: -0.2689848,
        store_longitude: -78.5432123,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2964609,
        store_longitude: -78.5693232,
    },

    {
        store_latitdue: -0.3617142,
        store_longitude: -78.5578982,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.318074,
        store_longitude: -78.5571,
    },
    {
        store_latitdue: -0.2508969,
        store_longitude: -78.5357183,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2485,
        store_longitude: -78.5353,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075933,
        store_longitude: -78.435095,
    },

    {
        store_latitdue: -0.280785,
        store_longitude: -78.5836,
    },
    {
        store_latitdue: -0.2880529,
        store_longitude: -78.5661998,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.281555,
        store_longitude: -78.554601,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.300742,
        store_longitude: -78.565713,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.1870698,
        store_longitude: -79.517035,
    },
    {
        store_latitdue: -0.254708,
        store_longitude: -78.551903,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3080327,
        store_longitude: -78.5596361,
    },
    {
        store_latitdue: -0.341854,
        store_longitude: -78.5692,
    },
    {
        store_latitdue: -0.345731,
        store_longitude: -78.5552,
    },

    {
        store_latitdue: -0.2523763,
        store_longitude: -78.5531319,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2700126,
        store_longitude: -78.5654205,
    },
    {
        store_latitdue: -0.273283876,
        store_longitude: -78.54325533,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2988143,
        store_longitude: -78.5614337,
    },

    {
        store_latitdue: -0.2844704,
        store_longitude: -78.5584623,
    },
    {
        store_latitdue: -0.2695683,
        store_longitude: -78.547203,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3263472,
        store_longitude: -78.5629959,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2558309,
        store_longitude: -78.5350668,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2697541,
        store_longitude: -78.5523042,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2542175,
        store_longitude: -78.5632211,
    },

    {
        store_latitdue: -0.3039125,
        store_longitude: -78.567034,
    },
    {
        store_latitdue: -0.3290535,
        store_longitude: -78.5622531,
    },
    {
        store_latitdue: -0.2566428,
        store_longitude: -78.5385721,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2697838,
        store_longitude: -78.5444483,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0788876,
        store_longitude: -77.2125357,
    },
    {
        store_latitdue: -0.297104,
        store_longitude: -78.565221,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.297156,
        store_longitude: -78.5651,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2982798,
        store_longitude: -78.5324368,
    },
    {
        store_latitdue: -0.3217147,
        store_longitude: -78.554093,
    },
    {
        store_latitdue: -0.26009,
        store_longitude: -78.54825,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2789025,
        store_longitude: -78.547906,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.241361,
        store_longitude: -78.5442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2413207,
        store_longitude: -78.5453921,
    },
    {
        store_latitdue: -0.092371,
        store_longitude: -78.4501,
    },
    {
        store_latitdue: -0.263211,
        store_longitude: -78.540072,
    },

    {
        store_latitdue: -0.3337196,
        store_longitude: -78.5559473,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.282302,
        store_longitude: -78.5642,
    },
    {
        store_latitdue: -0.332253,
        store_longitude: -78.5587,
    },

    {
        store_latitdue: -0.1806532,
        store_longitude: -78.4678382,
    },
    {
        store_latitdue: -2.1338415,
        store_longitude: -79.9386952,
    },

    {
        store_latitdue: -0.3342,
        store_longitude: -78.5702942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3272022,
        store_longitude: -78.5681462,
    },
    {
        store_latitdue: -0.281003,
        store_longitude: -78.5591,
    },
    {
        store_latitdue: -0.26057732,
        store_longitude: -78.5467987,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259591,
        store_longitude: -78.549402,
    },
    {
        store_latitdue: -0.2562541,
        store_longitude: -78.5566455,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257413,
        store_longitude: -78.559072,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.321457,
        store_longitude: -78.5645,
    },
    {
        store_latitdue: -0.3330849,
        store_longitude: -78.5692173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2847381,
        store_longitude: -78.569467,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.299694,
        store_longitude: -78.5698193,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3214172,
        store_longitude: -78.5645831,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.260612,
        store_longitude: -78.5217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2592133,
        store_longitude: -78.5303044,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.279241,
        store_longitude: -78.4615,
    },
    {
        store_latitdue: -0.126407,
        store_longitude: -78.46858,
    },

    {
        store_latitdue: -0.261357,
        store_longitude: -78.5514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2597456,
        store_longitude: -78.5515771,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.28687,
        store_longitude: -78.58,
    },

    {
        store_latitdue: -0.263066,
        store_longitude: -78.5506948,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.303462,
        store_longitude: -78.5678,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3019234,
        store_longitude: -78.5632828,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2805565,
        store_longitude: -78.5597813,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.277043,
        store_longitude: -78.5606,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2491126,
        store_longitude: -78.5327939,
    },
    {
        store_latitdue: -0.2615228,
        store_longitude: -78.5578693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2644191,
        store_longitude: -78.5560521,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2947828,
        store_longitude: -78.5606492,
    },
    {
        store_latitdue: -0.257542,
        store_longitude: -78.558225,
    },
    {
        store_latitdue: -0.3088133,
        store_longitude: -78.5621314,
    },
    {
        store_latitdue: -0.3001277,
        store_longitude: -78.5708108,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2544491,
        store_longitude: -78.5616803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2480528,
        store_longitude: -78.5333477,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.249029,
        store_longitude: -78.531777,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1226473,
        store_longitude: -78.4917654,
    },

    {
        store_latitdue: -0.2478073,
        store_longitude: -78.534069,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.340863,
        store_longitude: -78.571122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.342817,
        store_longitude: -78.553,
    },
    {
        store_latitdue: -0.3408526,
        store_longitude: -78.5609172,
    },
    {
        store_latitdue: -0.263106,
        store_longitude: -78.541173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.264736,
        store_longitude: -78.548829,
    },

    {
        store_latitdue: -0.3020509,
        store_longitude: -78.5729284,
    },

    {
        store_latitdue: -0.2961209,
        store_longitude: -78.5639241,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2914942,
        store_longitude: -78.5641912,
    },
    {
        store_latitdue: -0.2515213,
        store_longitude: -78.5411615,
    },
    {
        store_latitdue: -0.2826238,
        store_longitude: -78.5410147,
    },
    {
        store_latitdue: -0.2808891,
        store_longitude: -78.5291301,
    },
    {
        store_latitdue: -0.273691,
        store_longitude: -78.5437,
    },

    {
        store_latitdue: -0.2576012,
        store_longitude: -78.539351,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2704389,
        store_longitude: -78.5524352,
    },

    {
        store_latitdue: -0.268221,
        store_longitude: -78.5466,
    },
    {
        store_latitdue: -0.252904,
        store_longitude: -78.53736,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29230566,
        store_longitude: -78.5604751,
    },
    {
        store_latitdue: -0.31332336,
        store_longitude: -78.5596811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2677596,
        store_longitude: -78.5362088,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2650273,
        store_longitude: -78.5382043,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.303425,
        store_longitude: -78.5682,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.303709,
        store_longitude: -78.55808,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3441026,
        store_longitude: -78.5507208,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.34222973,
        store_longitude: -78.56785487,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3426417,
        store_longitude: -78.5658188,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2893814,
        store_longitude: -78.5576071,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.327087,
        store_longitude: -78.5684,
    },
    {
        store_latitdue: -0.297323,
        store_longitude: -78.5647,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2666899,
        store_longitude: -78.5540519,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2891971,
        store_longitude: -78.5728149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2986835,
        store_longitude: -78.5652551,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3225389,
        store_longitude: -78.5558943,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3165847,
        store_longitude: -78.5522034,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2631835,
        store_longitude: -78.5406152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25574,
        store_longitude: -78.533568,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2833591,
        store_longitude: -78.5617922,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.28046,
        store_longitude: -78.5566,
    },
    {
        store_latitdue: -0.28854,
        store_longitude: -78.568649,
    },

    {
        store_latitdue: -0.312088099,
        store_longitude: -78.5714818,
    },
    {
        store_latitdue: -0.31290727,
        store_longitude: -78.5681076,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.356277,
        store_longitude: -78.5487,
    },
    {
        store_latitdue: -0.250345,
        store_longitude: -78.5385,
    },
    {
        store_latitdue: -0.332406,
        store_longitude: -78.5572,
    },
    {
        store_latitdue: -0.254066,
        store_longitude: -78.5339,
    },
    {
        store_latitdue: -0.256832,
        store_longitude: -78.540169,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3269669,
        store_longitude: -78.5787315,
    },

    {
        store_latitdue: -0.24482935,
        store_longitude: -78.523761,
    },

    {
        store_latitdue: -0.2589653,
        store_longitude: -78.5419994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2481613,
        store_longitude: -78.5816601,
    },
    {
        store_latitdue: -0.314298,
        store_longitude: -78.5664462,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3389961,
        store_longitude: -78.565611,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2638088,
        store_longitude: -78.5312806,
    },

    {
        store_latitdue: -0.307219,
        store_longitude: -78.5488,
    },
    {
        store_latitdue: -0.274892,
        store_longitude: -78.55859,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.27458,
        store_longitude: -78.568405,
    },
    {
        store_latitdue: -0.2947883,
        store_longitude: -78.5603676,
    },
    {
        store_latitdue: -0.3254729,
        store_longitude: -78.5657612,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260136,
        store_longitude: -78.5396,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2597851,
        store_longitude: -78.5309884,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.243499,
        store_longitude: -78.5397,
    },
    {
        store_latitdue: -0.2663609,
        store_longitude: -78.5618907,
    },
    {
        store_latitdue: -0.2537438,
        store_longitude: -78.552231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253462,
        store_longitude: -78.5519647,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3284268,
        store_longitude: -78.5790578,
    },
    {
        store_latitdue: -0.309511,
        store_longitude: -78.5538,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3339486,
        store_longitude: -78.5700952,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.27166,
        store_longitude: -78.539112,
    },

    {
        store_latitdue: -0.290048,
        store_longitude: -78.565141,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2590133,
        store_longitude: -78.5505723,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2577962,
        store_longitude: -78.5344963,
    },
    {
        store_latitdue: -0.2618261,
        store_longitude: -78.5513393,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2711994,
        store_longitude: -78.5515217,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2813104,
        store_longitude: -78.5586763,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2791861,
        store_longitude: -78.5649604,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2781696,
        store_longitude: -78.5594802,
    },
    {
        store_latitdue: -0.249918,
        store_longitude: -78.537,
    },
    {
        store_latitdue: -0.2959265,
        store_longitude: -78.5610756,
    },
    {
        store_latitdue: -0.3039736,
        store_longitude: -78.5669831,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.291592,
        store_longitude: -78.5749189,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3399487,
        store_longitude: -78.5615706,
    },
    {
        store_latitdue: -0.3362311,
        store_longitude: -78.5714614,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3387167,
        store_longitude: -78.5512691,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3434638,
        store_longitude: -78.5552567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2611146,
        store_longitude: -78.5361341,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.268076,
        store_longitude: -78.54995,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.259855,
        store_longitude: -78.548989,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.195827,
        store_longitude: -78.494479,
    },
    {
        store_latitdue: -0.272612,
        store_longitude: -78.5503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2768277,
        store_longitude: -78.549904,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.27573,
        store_longitude: -78.5505,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.293019,
        store_longitude: -78.5636,
    },
    {
        store_latitdue: -0.2905758,
        store_longitude: -78.5806422,
    },
    {
        store_latitdue: -0.2912,
        store_longitude: -78.5766742,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2929286,
        store_longitude: -78.5754289,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.254924,
        store_longitude: -78.5126,
    },
    {
        store_latitdue: -0.312364,
        store_longitude: -78.5712,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.304696,
        store_longitude: -78.5657,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3659811,
        store_longitude: -78.5512553,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.3656584,
        store_longitude: -78.551614,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.37679,
        store_longitude: -78.558918,
        neighborhood: 'CUTUGLAHUA',
    },

    {
        store_latitdue: -0.259562,
        store_longitude: -78.5528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2701546,
        store_longitude: -78.5399979,
    },
    {
        store_latitdue: -0.271208,
        store_longitude: -78.539257,
    },
    {
        store_latitdue: -0.26768,
        store_longitude: -78.536224,
    },

    {
        store_latitdue: -0.278201,
        store_longitude: -78.54783821,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3215911,
        store_longitude: -78.5735,
    },
    {
        store_latitdue: -0.3015047,
        store_longitude: -78.5524381,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.365766,
        store_longitude: -78.5508,
    },

    {
        store_latitdue: -0.252727,
        store_longitude: -78.5117,
    },

    {
        store_latitdue: -0.262208,
        store_longitude: -78.537546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2708403,
        store_longitude: -78.5568236,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.283165,
        store_longitude: -78.5548,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.283881,
        store_longitude: -78.552031,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2740767,
        store_longitude: -78.5683113,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2921572,
        store_longitude: -78.5595278,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29455,
        store_longitude: -78.562086,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292613,
        store_longitude: -78.562149,
    },
    {
        store_latitdue: -0.293879,
        store_longitude: -78.563784,
    },

    {
        store_latitdue: -0.3137421,
        store_longitude: -78.5573817,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3116711,
        store_longitude: -78.5682787,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3082584,
        store_longitude: -78.5536761,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312182,
        store_longitude: -78.5596828,
    },

    {
        store_latitdue: -0.3165319,
        store_longitude: -78.5605681,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.337188,
        store_longitude: -78.5617628,
    },
    {
        store_latitdue: -0.3438176,
        store_longitude: -78.5596158,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.337555,
        store_longitude: -78.567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3414593,
        store_longitude: -78.5658644,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.342134,
        store_longitude: -78.5495,
    },
    {
        store_latitdue: -0.327722,
        store_longitude: -78.574543,
    },
    {
        store_latitdue: -0.3223119,
        store_longitude: -78.562394,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.344497,
        store_longitude: -78.5596,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2604851,
        store_longitude: -78.5388845,
    },
    {
        store_latitdue: -0.2687667,
        store_longitude: -78.5486821,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.269889,
        store_longitude: -78.5352274,
    },
    {
        store_latitdue: -0.2852541,
        store_longitude: -78.5678248,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2913617,
        store_longitude: -78.5539944,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.320167,
        store_longitude: -78.561311,
    },
    {
        store_latitdue: -0.322113,
        store_longitude: -78.575707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3104233,
        store_longitude: -78.5705586,
    },
    {
        store_latitdue: -0.3437687,
        store_longitude: -78.5598131,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.343318,
        store_longitude: -78.5536,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3253524,
        store_longitude: -78.5561905,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.265208,
        store_longitude: -78.540127,
    },
    {
        store_latitdue: -0.267168,
        store_longitude: -78.5544,
    },
    {
        store_latitdue: -0.2680514,
        store_longitude: -78.5595271,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.296499,
        store_longitude: -78.5615,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2833094,
        store_longitude: -78.556298,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2838093,
        store_longitude: -78.5588054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2943189,
        store_longitude: -78.5618684,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.326462,
        store_longitude: -78.5736,
    },
    {
        store_latitdue: -0.3381072,
        store_longitude: -78.560284,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -1.0722141,
        store_longitude: -80.4800732,
        neighborhood: 'PORTOVIEJO',
    },
    {
        store_latitdue: -0.262381,
        store_longitude: -78.555281,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2834694,
        store_longitude: -78.5562293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29283,
        store_longitude: -78.5611,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3102336,
        store_longitude: -78.5586564,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2994544,
        store_longitude: -78.5734267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2536599,
        store_longitude: -78.5538674,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2695444,
        store_longitude: -78.5399308,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2912504,
        store_longitude: -78.5708941,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2896369,
        store_longitude: -78.5690877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3161177,
        store_longitude: -78.5479505,
    },
    {
        store_latitdue: -0.2757741,
        store_longitude: -78.5485499,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.294741,
        store_longitude: -78.564529,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.319277,
        store_longitude: -78.5583,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3038635,
        store_longitude: -78.566983,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.326068,
        store_longitude: -78.556243,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2573494,
        store_longitude: -78.5519419,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25804,
        store_longitude: -78.541342,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2363346,
        store_longitude: -78.4769731,
    },

    {
        store_latitdue: -0.234703,
        store_longitude: -78.483005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.234038,
        store_longitude: -78.476,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2408992,
        store_longitude: -78.4862227,
    },

    {
        store_latitdue: -0.139986,
        store_longitude: -78.4753,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.16308093,
        store_longitude: -78.4744942,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.161737,
        store_longitude: -78.4739,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.160083,
        store_longitude: -78.476404,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.158071,
        store_longitude: -78.473,
    },
    {
        store_latitdue: -0.0910793,
        store_longitude: -78.4694,
    },
    {
        store_latitdue: -0.1609283,
        store_longitude: -78.4737683,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1817164,
        store_longitude: -78.4804799,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1823327,
        store_longitude: -78.477368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.188695,
        store_longitude: -78.478336,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1881385,
        store_longitude: -78.479861,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1835382,
        store_longitude: -78.478709,
    },
    {
        store_latitdue: -0.201945,
        store_longitude: -78.4946,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2043838,
        store_longitude: -78.4986474,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2042113,
        store_longitude: -78.4970764,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2042238,
        store_longitude: -78.4955539,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.204753,
        store_longitude: -78.49468,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2056823,
        store_longitude: -78.4940594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2334311,
        store_longitude: -78.4835191,
    },
    {
        store_latitdue: -1.7183684,
        store_longitude: -79.9863019,
        neighborhood: 'SANTA LUCÍA',
    },
    {
        store_latitdue: -0.2342213,
        store_longitude: -78.4827663,
    },
    {
        store_latitdue: -0.233657,
        store_longitude: -78.4827,
    },
    {
        store_latitdue: -0.2343492,
        store_longitude: -78.4819844,
    },
    {
        store_latitdue: -0.158089,
        store_longitude: -78.474993,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.253971,
        store_longitude: -78.5152,
    },

    {
        store_latitdue: -0.1619687,
        store_longitude: -78.4739227,
    },

    {
        store_latitdue: -0.189719,
        store_longitude: -78.4734042,
    },
    {
        store_latitdue: -0.183559,
        store_longitude: -78.4772,
    },

    {
        store_latitdue: -0.235862,
        store_longitude: -78.4833,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.158799,
        store_longitude: -78.476309,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1582474,
        store_longitude: -78.4771838,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.155695,
        store_longitude: -78.4768,
    },

    {
        store_latitdue: -0.157196,
        store_longitude: -78.471884,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.202769,
        store_longitude: -78.494033,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -1.8655451,
        store_longitude: -79.979915,
        neighborhood: 'DAULE',
    },
    {
        store_latitdue: -0.20366,
        store_longitude: -78.4958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1801123,
        store_longitude: -78.4894737,
    },
    {
        store_latitdue: -0.2372957,
        store_longitude: -78.4816801,
    },
    {
        store_latitdue: -0.159139,
        store_longitude: -78.476654,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1592424,
        store_longitude: -78.4762406,
    },
    {
        store_latitdue: -0.2046071,
        store_longitude: -78.497184,
    },

    {
        store_latitdue: -0.2279022,
        store_longitude: -78.4823408,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143714,
        store_longitude: -78.4809081,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143782,
        store_longitude: -78.4808274,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1299936,
        store_longitude: -78.487352,
    },

    {
        store_latitdue: -0.1433846,
        store_longitude: -78.4767068,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.241357,
        store_longitude: -78.477357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2438543,
        store_longitude: -78.5401032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.245388,
        store_longitude: -78.5403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279067,
        store_longitude: -78.5437089,
    },
    {
        store_latitdue: -0.2741821,
        store_longitude: -78.5400956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29225222,
        store_longitude: -78.5477399,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3591313,
        store_longitude: -78.5696545,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.2480819,
        store_longitude: -78.5437061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2463249,
        store_longitude: -78.5420865,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3127668,
        store_longitude: -78.5672851,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3106864,
        store_longitude: -78.5729243,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.310653,
        store_longitude: -78.571903,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.243636,
        store_longitude: -78.542577,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2964295,
        store_longitude: -78.5538398,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.310015,
        store_longitude: -78.567779,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3175182,
        store_longitude: -78.5698481,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.246377,
        store_longitude: -78.5422,
    },
    {
        store_latitdue: -0.249767,
        store_longitude: -78.505925,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.225112,
        store_longitude: -78.512051,
    },
    {
        store_latitdue: -0.160018,
        store_longitude: -78.476199,
    },
    {
        store_latitdue: -0.372715,
        store_longitude: -78.560362,
    },
    {
        store_latitdue: -0.2465366,
        store_longitude: -78.5448191,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3138566,
        store_longitude: -78.568238,
    },
    {
        store_latitdue: -0.3104856,
        store_longitude: -78.5710488,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.366645,
        store_longitude: -78.5595,
        neighborhood: 'CUTUGLAHUA',
    },

    {
        store_latitdue: -0.3718333,
        store_longitude: -78.5633125,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.0809210456,
        store_longitude: -78.4770413727,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2297205,
        store_longitude: -78.4834219,
    },

    {
        store_latitdue: -0.223325,
        store_longitude: -78.487864,
    },
    {
        store_latitdue: -0.119461,
        store_longitude: -78.4796,
    },
    {
        store_latitdue: -0.223255,
        store_longitude: -78.484713,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2268014,
        store_longitude: -78.4920429,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22536,
        store_longitude: -78.492102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225208,
        store_longitude: -78.492183,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0754137,
        store_longitude: -78.4626519,
    },
    {
        store_latitdue: -0.4644966,
        store_longitude: -78.4644966,
    },
    {
        store_latitdue: -0.1438001,
        store_longitude: -78.4525994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144336,
        store_longitude: -78.450028,
    },

    {
        store_latitdue: -0.172107,
        store_longitude: -78.4925,
    },
    {
        store_latitdue: -0.194558,
        store_longitude: -78.492103,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2128017,
        store_longitude: -78.4877457,
    },
    {
        store_latitdue: -0.191343,
        store_longitude: -78.4913938,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1867245,
        store_longitude: -78.4880952,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206838,
        store_longitude: -78.4857,
    },
    {
        store_latitdue: -0.2099993,
        store_longitude: -78.4837919,
    },

    {
        store_latitdue: -0.2113364,
        store_longitude: -78.4852517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1457551,
        store_longitude: -78.4485696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -1.3260244481,
        store_longitude: -78.5438853362,
        neighborhood: 'GARCÍA MORENO',
    },
    {
        store_latitdue: -0.1658366,
        store_longitude: -78.4672253,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.165344,
        store_longitude: -78.4664,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1671605,
        store_longitude: -78.4835527,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.163745,
        store_longitude: -78.4749,
    },
    {
        store_latitdue: -0.3160561,
        store_longitude: -78.4418617,
    },
    {
        store_latitdue: -0.192081,
        store_longitude: -78.4922,
    },
    {
        store_latitdue: -0.1945007,
        store_longitude: -78.4943131,
    },
    {
        store_latitdue: -0.223649,
        store_longitude: -78.484456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145394,
        store_longitude: -78.4555,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2905689,
        store_longitude: -78.5659818,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2922991,
        store_longitude: -78.5646697,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2781191,
        store_longitude: -78.5428873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2754708,
        store_longitude: -78.5453274,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3068152,
        store_longitude: -78.5688337,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2763076,
        store_longitude: -78.5434342,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2481966,
        store_longitude: -78.5347333,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2887099,
        store_longitude: -78.5612568,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3468826,
        store_longitude: -78.5605931,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.344636,
        store_longitude: -78.5597,
    },

    {
        store_latitdue: -0.3084726,
        store_longitude: -78.5692749,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2788936,
        store_longitude: -78.5433652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2438385,
        store_longitude: -78.5355106,
    },
    {
        store_latitdue: -0.2762935,
        store_longitude: -78.5442623,
    },

    {
        store_latitdue: -0.250421,
        store_longitude: -78.533663,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.286693,
        store_longitude: -78.561536,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.315055727,
        store_longitude: -78.56096649,
    },

    {
        store_latitdue: -0.3547230687,
        store_longitude: -78.549400746,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2470132507,
        store_longitude: -78.5331886634,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.244623,
        store_longitude: -78.534862,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.346682,
        store_longitude: -78.5547,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.22943,
        store_longitude: -78.503735,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.22733,
        store_longitude: -78.504315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.229363,
        store_longitude: -78.503897,
    },

    {
        store_latitdue: -0.225879,
        store_longitude: -78.504256,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.224891,
        store_longitude: -78.503996,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2252233,
        store_longitude: -78.5055875,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.227964,
        store_longitude: -78.506138,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.4923704,
        store_longitude: -78.4923704,
    },
    {
        store_latitdue: -0.144914,
        store_longitude: -78.477375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1446118,
        store_longitude: -78.4732666,
    },
    {
        store_latitdue: -0.14392484,
        store_longitude: -78.4696409,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.147907,
        store_longitude: -78.469222,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.146886,
        store_longitude: -78.473979,
    },
    {
        store_latitdue: -0.188791,
        store_longitude: -78.5026,
    },

    {
        store_latitdue: -0.156049,
        store_longitude: -78.4884,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1564323,
        store_longitude: -78.4882896,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1672437,
        store_longitude: -78.4830606,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1701272,
        store_longitude: -78.4800122,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1935599,
        store_longitude: -78.485058,
    },
    {
        store_latitdue: -0.19097225,
        store_longitude: -78.4845176,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.191643,
        store_longitude: -78.480696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1941559,
        store_longitude: -78.4818793,
    },
    {
        store_latitdue: -0.229001,
        store_longitude: -78.500956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.21978019,
        store_longitude: -78.4868621,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2180435,
        store_longitude: -78.4852607,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.227002,
        store_longitude: -78.5066,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147523,
        store_longitude: -78.472876,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0933849,
        store_longitude: -78.4554,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1896589,
        store_longitude: -78.4811376,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2124261,
        store_longitude: -78.4848175,
    },
    {
        store_latitdue: -0.2259515,
        store_longitude: -78.5029018,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.192364,
        store_longitude: -78.481087,
    },
    {
        store_latitdue: -0.2181656,
        store_longitude: -78.4872129,
    },
    {
        store_latitdue: -0.1610693,
        store_longitude: -78.4869819,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213631,
        store_longitude: -78.4848,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2266047,
        store_longitude: -78.5022984,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260071,
        store_longitude: -78.5368,
    },
    {
        store_latitdue: -0.2691499,
        store_longitude: -78.5501205,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.271835,
        store_longitude: -78.550312,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2722047,
        store_longitude: -78.5489346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3179821,
        store_longitude: -78.5715521,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2942748,
        store_longitude: -78.5628299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.29182907,
        store_longitude: -78.5630264,
    },
    {
        store_latitdue: -0.2911032,
        store_longitude: -78.5622438,
    },
    {
        store_latitdue: -0.2920128,
        store_longitude: -78.5624806,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292312,
        store_longitude: -78.561585,
    },
    {
        store_latitdue: -0.3107088,
        store_longitude: -78.5534387,
    },
    {
        store_latitdue: -0.315194,
        store_longitude: -78.5624,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3380216,
        store_longitude: -78.5497234,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2600755,
        store_longitude: -78.5359502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.268848,
        store_longitude: -78.550947,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25642031,
        store_longitude: -78.5385055,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25980963,
        store_longitude: -78.5364317,
    },
    {
        store_latitdue: -0.2719357,
        store_longitude: -78.5499002,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2581168,
        store_longitude: -78.5339905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2551694,
        store_longitude: -78.5387587,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2610252,
        store_longitude: -78.5342332,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2612887,
        store_longitude: -78.5354131,
    },

    {
        store_latitdue: -0.2733356,
        store_longitude: -78.5511608,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2943762,
        store_longitude: -78.5604202,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3436746,
        store_longitude: -78.5597051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.261232,
        store_longitude: -78.5354,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.294017,
        store_longitude: -78.55915,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.300229,
        store_longitude: -78.5474,
    },
    {
        store_latitdue: -0.270238,
        store_longitude: -78.553135,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2704928,
        store_longitude: -78.5478552,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292348,
        store_longitude: -78.5621753,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.314254,
        store_longitude: -78.5584,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3125115,
        store_longitude: -78.5575934,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.292051,
        store_longitude: -78.5619,
    },
    {
        store_latitdue: -0.2562469,
        store_longitude: -78.5380917,
    },
    {
        store_latitdue: -0.2935943,
        store_longitude: -78.5638328,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2561931,
        store_longitude: -78.538073,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3193035958,
        store_longitude: -78.5585157573,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2596867,
        store_longitude: -78.5341107,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.267758,
        store_longitude: -78.5396,
    },

    {
        store_latitdue: -0.313521,
        store_longitude: -78.5575,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.312105,
        store_longitude: -78.5526,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3403372,
        store_longitude: -78.5505864,
    },
    {
        store_latitdue: -0.258955,
        store_longitude: -78.5337,
    },
    {
        store_latitdue: -0.2945022,
        store_longitude: -78.5626805,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2594,
        store_longitude: -78.5379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.241869,
        store_longitude: -78.536255,
    },
    {
        store_latitdue: -0.2697045,
        store_longitude: -78.5494221,
    },
    {
        store_latitdue: -0.24021,
        store_longitude: -78.534,
    },
    {
        store_latitdue: -0.2912281,
        store_longitude: -78.564622,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.313222,
        store_longitude: -78.561,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.113932,
        store_longitude: -78.492831,
    },
    {
        store_latitdue: -0.2645798,
        store_longitude: -78.551214,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.342263,
        store_longitude: -78.5536,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.339441,
        store_longitude: -78.551417,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2606504,
        store_longitude: -78.5335614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.272473,
        store_longitude: -78.5486,
    },

    {
        store_latitdue: -0.2568894,
        store_longitude: -78.5388416,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3141765,
        store_longitude: -78.5623934,
    },

    {
        store_latitdue: -0.255838,
        store_longitude: -78.541081,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.344073,
        store_longitude: -78.5501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.34533404,
        store_longitude: -78.5489356,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.311382,
        store_longitude: -78.5548,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.33701,
        store_longitude: -78.5536,
    },

    {
        store_latitdue: -0.271196,
        store_longitude: -78.548105,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318943,
        store_longitude: -78.56,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2201694,
        store_longitude: -78.507462,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1576009,
        store_longitude: -78.4613414,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1658678,
        store_longitude: -78.4718037,
    },

    {
        store_latitdue: -0.2002203,
        store_longitude: -78.488519,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.197345,
        store_longitude: -78.493576,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1979355,
        store_longitude: -78.489837,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2148258,
        store_longitude: -78.488411,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2112154,
        store_longitude: -78.4821993,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1601231,
        store_longitude: -78.4660829,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.199745,
        store_longitude: -78.485033,
    },

    {
        store_latitdue: -0.19697675,
        store_longitude: -78.4919509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1817811,
        store_longitude: -78.4881607,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.223303,
        store_longitude: -78.483836,
    },
    {
        store_latitdue: -0.124894,
        store_longitude: -78.506154,
    },
    {
        store_latitdue: -0.2123922,
        store_longitude: -78.4826574,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212882,
        store_longitude: -78.4829706,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.212816,
        store_longitude: -78.48246,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3158162,
        store_longitude: -78.5658223,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.156668,
        store_longitude: -78.48,
    },
    {
        store_latitdue: -0.3117914,
        store_longitude: -78.5646953,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216326,
        store_longitude: -78.488282,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1553199,
        store_longitude: -78.4783746,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3323635,
        store_longitude: -78.5596189,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.250657,
        store_longitude: -78.5499,
    },
    {
        store_latitdue: -0.253403,
        store_longitude: -78.544664,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3140917,
        store_longitude: -78.5630768,
    },
    {
        store_latitdue: -0.3343247,
        store_longitude: -78.5591293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2642095,
        store_longitude: -78.5368887,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3084251,
        store_longitude: -78.5625005,
    },

    {
        store_latitdue: -0.40477621,
        store_longitude: -78.5463732,
        neighborhood: 'TAMBILLO',
    },

    {
        store_latitdue: -0.229252,
        store_longitude: -78.4874,
    },

    {
        store_latitdue: -0.2337103,
        store_longitude: -78.498512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2334891,
        store_longitude: -78.4989726,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3040249,
        store_longitude: -78.5602063,
    },
    {
        store_latitdue: -0.1541544,
        store_longitude: -78.4694965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3026792,
        store_longitude: -78.5574528,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14876,
        store_longitude: -78.468652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1506536,
        store_longitude: -78.4685174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.149714,
        store_longitude: -78.467835,
    },

    {
        store_latitdue: -0.156294,
        store_longitude: -78.4721,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1811438,
        store_longitude: -78.4800294,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17749,
        store_longitude: -78.4785,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.201658,
        store_longitude: -78.4832,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1995687,
        store_longitude: -78.4778532,
    },
    {
        store_latitdue: -0.2087646,
        store_longitude: -78.4819555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205797,
        store_longitude: -78.481352,
    },
    {
        store_latitdue: -0.204928,
        store_longitude: -78.484217,
    },

    {
        store_latitdue: -0.2180227,
        store_longitude: -78.5011981,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.217928,
        store_longitude: -78.500183,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2163797,
        store_longitude: -78.4959405,
    },

    {
        store_latitdue: -0.2175954,
        store_longitude: -78.5065361,
    },
    {
        store_latitdue: -0.213364,
        store_longitude: -78.5006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.213202,
        store_longitude: -78.5007,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -1.81432,
        store_longitude: -79.497,
        neighborhood: 'BABAHOYO',
    },
    {
        store_latitdue: -0.2182131,
        store_longitude: -78.4927679,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.218701,
        store_longitude: -78.5086,
    },

    {
        store_latitdue: -0.2806562,
        store_longitude: -78.5436946,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.342768,
        store_longitude: -78.5650705,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2798592,
        store_longitude: -78.5540861,
    },

    {
        store_latitdue: -0.361472,
        store_longitude: -78.5687,
        neighborhood: 'CUTUGLAHUA',
    },
    {
        store_latitdue: -0.201552,
        store_longitude: -78.478542,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1997739,
        store_longitude: -78.4772198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.206774,
        store_longitude: -78.479873,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -1.4948765,
        store_longitude: -78.0004316,
        neighborhood: 'PUYO',
    },
    {
        store_latitdue: -0.218086,
        store_longitude: -78.500944,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.194019,
        store_longitude: -78.493882,
    },
    {
        store_latitdue: -0.229943,
        store_longitude: -78.4922,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156002,
        store_longitude: -78.469239,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2126674,
        store_longitude: -78.4923839,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.157539,
        store_longitude: -78.476598,
    },
    {
        store_latitdue: -0.1814025218,
        store_longitude: -78.4784555063,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.216369,
        store_longitude: -78.495,
    },
    {
        store_latitdue: -0.1507202,
        store_longitude: -78.4718424,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1538057,
        store_longitude: -78.4676817,
    },
    {
        store_latitdue: -0.153958,
        store_longitude: -78.466957,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.153897,
        store_longitude: -78.472855,
    },
    {
        store_latitdue: -0.1498143,
        store_longitude: -78.4697936,
    },

    {
        store_latitdue: -0.1544172,
        store_longitude: -78.474924,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1740248,
        store_longitude: -78.4744958,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1812015,
        store_longitude: -78.478451,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1777039,
        store_longitude: -78.47201,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.175608,
        store_longitude: -78.476603,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20376,
        store_longitude: -78.4887,
    },

    {
        store_latitdue: -0.2017361,
        store_longitude: -78.4822991,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.216506,
        store_longitude: -78.4961,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.236099,
        store_longitude: -78.4852,
    },
    {
        store_latitdue: -0.232071,
        store_longitude: -78.4943,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2313623623,
        store_longitude: -78.4933578596,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.234186,
        store_longitude: -78.497149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235371,
        store_longitude: -78.494377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.235685,
        store_longitude: -78.4946,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1523729,
        store_longitude: -78.4810483,
    },
    {
        store_latitdue: -0.132054,
        store_longitude: -78.4723,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.148656,
        store_longitude: -78.4823,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.146393,
        store_longitude: -78.478706,
    },
    {
        store_latitdue: -0.1495038,
        store_longitude: -78.4765704,
    },
    {
        store_latitdue: -0.13966969,
        store_longitude: -78.48286456,
    },
    {
        store_latitdue: -0.1437107,
        store_longitude: -78.4774675,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1476282,
        store_longitude: -78.4831093,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1716,
        store_longitude: -78.486445,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17185,
        store_longitude: -78.4866,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17605,
        store_longitude: -78.4821,
    },

    {
        store_latitdue: -0.2729823,
        store_longitude: -78.5648259,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1766892,
        store_longitude: -78.4869129,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2004704898,
        store_longitude: -78.4954068325,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.200504,
        store_longitude: -78.495367,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2002532,
        store_longitude: -78.4927582,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.201548,
        store_longitude: -78.4898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.205836,
        store_longitude: -78.494,
    },
    {
        store_latitdue: -0.2024783,
        store_longitude: -78.4936893,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2021155,
        store_longitude: -78.492735,
    },
    {
        store_latitdue: -0.202413,
        store_longitude: -78.491945,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2079455,
        store_longitude: -78.4918176,
    },

    {
        store_latitdue: -0.194,
        store_longitude: -78.49,
    },

    {
        store_latitdue: -0.2326537,
        store_longitude: -78.4916439,
    },
    {
        store_latitdue: -0.2342,
        store_longitude: -78.4916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1442251,
        store_longitude: -78.4806424,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107179,
        store_longitude: -78.44341,
    },

    {
        store_latitdue: -0.1772316,
        store_longitude: -78.4869545,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1767795,
        store_longitude: -78.4860659,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1802917,
        store_longitude: -78.4888821,
    },
    {
        store_latitdue: -0.1818718,
        store_longitude: -78.4886206,
    },
    {
        store_latitdue: -0.181617,
        store_longitude: -78.4882,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2029318,
        store_longitude: -78.4910384,
    },
    {
        store_latitdue: -0.2015456,
        store_longitude: -78.4898486,
    },
    {
        store_latitdue: -0.2029379,
        store_longitude: -78.4903781,
    },
    {
        store_latitdue: -0.236508,
        store_longitude: -78.477191,
    },

    {
        store_latitdue: -0.1998934,
        store_longitude: -78.4966301,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2933534,
        store_longitude: -78.501568,
        neighborhood: 'CONOCOTO',
    },
    {
        store_latitdue: -0.229884,
        store_longitude: -78.493319,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.233624,
        store_longitude: -78.491428,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147762,
        store_longitude: -78.476219,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1757407,
        store_longitude: -78.4854979,
    },
    {
        store_latitdue: -0.1807824,
        store_longitude: -78.4893384,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.467848,
        store_longitude: -76.9792,
        neighborhood: 'EL COCA',
    },
    {
        store_latitdue: -0.042443,
        store_longitude: -78.4898425,
    },
    {
        store_latitdue: -0.206312,
        store_longitude: -78.488594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260878,
        store_longitude: -78.5523,
    },
    {
        store_latitdue: -0.230385,
        store_longitude: -78.492111,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2326222,
        store_longitude: -78.4941047,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2319233,
        store_longitude: -78.4960801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.232692078,
        store_longitude: -78.49137878,
    },
    {
        store_latitdue: -0.150084,
        store_longitude: -78.484,
    },
    {
        store_latitdue: -0.1776941,
        store_longitude: -78.4867495,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.320664,
        store_longitude: -78.5504,
    },

    {
        store_latitdue: -0.2007758,
        store_longitude: -78.4910986,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2007797,
        store_longitude: -78.4893658,
    },
    {
        store_latitdue: -0.203797796,
        store_longitude: -78.4905113574,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2322489,
        store_longitude: -78.4907253,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1452349,
        store_longitude: -78.4783851,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.173336,
        store_longitude: -78.4865,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2017199,
        store_longitude: -78.4861867,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2349355,
        store_longitude: -78.4941395,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257438,
        store_longitude: -78.5586,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2571235,
        store_longitude: -78.555598,
    },
    {
        store_latitdue: -0.278845,
        store_longitude: -78.564127,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.323496,
        store_longitude: -78.5716,
    },
    {
        store_latitdue: -0.3282795,
        store_longitude: -78.5790595,
    },
    {
        store_latitdue: -0.2750679,
        store_longitude: -78.5382557,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.062965,
        store_longitude: -78.470043,
    },
    {
        store_latitdue: -0.3322306,
        store_longitude: -78.569868,
    },

    {
        store_latitdue: -0.259583,
        store_longitude: -78.5519,
    },
    {
        store_latitdue: -0.255784,
        store_longitude: -78.561111,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.276001,
        store_longitude: -78.561,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2751329,
        store_longitude: -78.5612588,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3326158,
        store_longitude: -78.5703348,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.279666,
        store_longitude: -78.563483,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.258748,
        store_longitude: -78.5525,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2760948,
        store_longitude: -78.5705709,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2766774,
        store_longitude: -78.5683888,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2960178,
        store_longitude: -78.570819,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.260845,
        store_longitude: -78.5377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2936933,
        store_longitude: -78.5698319,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3292107,
        store_longitude: -78.5776524,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2551172,
        store_longitude: -78.5597083,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2576143,
        store_longitude: -78.5598636,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2595339,
        store_longitude: -78.5549751,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.154611,
        store_longitude: -78.4778,
    },

    {
        store_latitdue: -0.2571539,
        store_longitude: -78.5573669,
    },
    {
        store_latitdue: -0.2577811,
        store_longitude: -78.5429012,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.257144,
        store_longitude: -78.557226,
    },

    {
        store_latitdue: -0.250579,
        store_longitude: -78.5338,
    },

    {
        store_latitdue: -0.2801695,
        store_longitude: -78.5607339,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2802827,
        store_longitude: -78.5605114,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.298741,
        store_longitude: -78.5654,
    },
    {
        store_latitdue: -0.2982137,
        store_longitude: -78.5723914,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.33017,
        store_longitude: -78.4984,
    },
    {
        store_latitdue: -0.2968563,
        store_longitude: -78.5698269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2523879,
        store_longitude: -78.5360178,
    },
    {
        store_latitdue: -0.2500251,
        store_longitude: -78.5361867,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2809545,
        store_longitude: -78.5574315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.285213,
        store_longitude: -78.558965,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.285259,
        store_longitude: -78.556935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3301032,
        store_longitude: -78.5551891,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3181525,
        store_longitude: -78.554798,
    },
    {
        store_latitdue: -0.293788,
        store_longitude: -78.56399,
    },
    {
        store_latitdue: -0.25086084,
        store_longitude: -78.5352301,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.252418,
        store_longitude: -78.5341,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.297798,
        store_longitude: -78.539488,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3374363,
        store_longitude: -78.5451691,
    },
    {
        store_latitdue: -0.2804196,
        store_longitude: -78.5567229,
    },
    {
        store_latitdue: -0.3004532,
        store_longitude: -78.5702963,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3378811,
        store_longitude: -78.5536411,
    },
    {
        store_latitdue: -0.329227,
        store_longitude: -78.5596,
    },
    {
        store_latitdue: -0.3258362,
        store_longitude: -78.5584806,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.280944,
        store_longitude: -78.5573682,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3047993,
        store_longitude: -78.5480855,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2601212,
        store_longitude: -78.5492162,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3010798,
        store_longitude: -78.569377,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2619936,
        store_longitude: -78.5585656,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.254554,
        store_longitude: -78.531437,
    },

    {
        store_latitdue: -0.300222,
        store_longitude: -78.56671,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2698734,
        store_longitude: -78.5467422,
    },

    {
        store_latitdue: -2.1138005,
        store_longitude: -79.8996587,
    },
    {
        store_latitdue: -0.301057,
        store_longitude: -78.574,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.257231,
        store_longitude: -78.537221,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.279669,
        store_longitude: -78.5629,
    },
    {
        store_latitdue: -0.325774,
        store_longitude: -78.5608,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.225443,
        store_longitude: -78.515876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3081951,
        store_longitude: -78.5536424,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3054319,
        store_longitude: -78.553323,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.324505,
        store_longitude: -78.5693,
    },
    {
        store_latitdue: -0.3210449,
        store_longitude: -78.5613824,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.257684,
        store_longitude: -78.5302,
    },

    {
        store_latitdue: -0.3211174113,
        store_longitude: -78.562737219,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.25691998,
        store_longitude: -78.5314331,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.292022,
        store_longitude: -78.564215,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2005757,
        store_longitude: -78.4335126,
        neighborhood: 'CUMBAYÁ',
    },

    {
        store_latitdue: -0.2825509,
        store_longitude: -78.5743342,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.3026762,
        store_longitude: -78.5569674,
    },

    {
        store_latitdue: -0.3289687,
        store_longitude: -78.5594862,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.245332,
        store_longitude: -78.58395,
        neighborhood: 'LLOA',
    },

    {
        store_latitdue: -0.328664,
        store_longitude: -78.5445374,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.27442,
        store_longitude: -78.5419,
    },

    {
        store_latitdue: -0.288778,
        store_longitude: -78.5693092,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.284358,
        store_longitude: -78.5809,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.130415,
        store_longitude: -78.500763,
    },
    {
        store_latitdue: -0.295111,
        store_longitude: -78.568532,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.3180079,
        store_longitude: -78.5555838,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.318075,
        store_longitude: -78.5552,
    },
    {
        store_latitdue: -0.270749,
        store_longitude: -78.542006,
    },

    {
        store_latitdue: -0.281332,
        store_longitude: -78.582628,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.295484,
        store_longitude: -78.5687715,
    },
    {
        store_latitdue: -0.253184,
        store_longitude: -78.5535,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1443179,
        store_longitude: -78.4706266,
    },
    {
        store_latitdue: -0.27295,
        store_longitude: -78.5423,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2912816,
        store_longitude: -78.5729157,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.25873,
        store_longitude: -78.5516,
    },

    {
        store_latitdue: -0.2640202,
        store_longitude: -78.5535155,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.270135,
        store_longitude: -78.540737,
    },
    {
        store_latitdue: -0.3207603,
        store_longitude: -78.5592579,
    },

    {
        store_latitdue: -0.256711,
        store_longitude: -78.5266,
    },

    {
        store_latitdue: -0.321848,
        store_longitude: -78.557946,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2759445967,
        store_longitude: -78.5145020485,
    },
    {
        store_latitdue: -0.282839,
        store_longitude: -78.582723,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1230846,
        store_longitude: -78.4927453,
    },

    {
        store_latitdue: -0.2705334,
        store_longitude: -78.5421944,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.258175,
        store_longitude: -78.555196,
    },
    {
        store_latitdue: -0.3435959,
        store_longitude: -78.562434,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.222719,
        store_longitude: -78.518746,
    },
    {
        store_latitdue: -0.262589,
        store_longitude: -78.55298,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.2629949,
        store_longitude: -78.5521651,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.248088241,
        store_longitude: -78.58017862,
        neighborhood: 'LLOA',
    },

    {
        store_latitdue: -0.2844651,
        store_longitude: -78.5664249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265141,
        store_longitude: -78.561442,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.243713,
        store_longitude: -78.5817149,
        neighborhood: 'LLOA',
    },
    {
        store_latitdue: -0.267893,
        store_longitude: -78.558404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.265804,
        store_longitude: -78.5637,
    },

    {
        store_latitdue: -0.179092,
        store_longitude: -78.4804,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.4272788,
        store_longitude: -79.3404897,
        neighborhood: 'LA TRONCAL',
    },
    {
        store_latitdue: -0.1989207,
        store_longitude: -78.4961359,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1641442891,
        store_longitude: -78.4628602015,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.18565,
        store_longitude: -78.4804,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.185993,
        store_longitude: -78.480338,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1934386,
        store_longitude: -78.4853149,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.114181,
        store_longitude: -78.4313,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.086808,
        store_longitude: -78.296515,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0805613,
        store_longitude: -78.2949279,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.090831,
        store_longitude: -78.283998,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.1248399,
        store_longitude: -78.3188595,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.13396963,
        store_longitude: -78.2951126,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1364679,
        store_longitude: -78.3046096,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1523709,
        store_longitude: -78.309761,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.15239053,
        store_longitude: -78.3097459,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.06752,
        store_longitude: -78.41474,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.056485,
        store_longitude: -78.4242,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0638096,
        store_longitude: -78.4199,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080698,
        store_longitude: -78.42259,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0532697,
        store_longitude: -78.4208,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0686179,
        store_longitude: -78.408953,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0664278,
        store_longitude: -78.4179737,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.066449,
        store_longitude: -78.417189,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0665952,
        store_longitude: -78.4146665,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0675972,
        store_longitude: -78.4255143,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.058651,
        store_longitude: -78.421849,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0637205,
        store_longitude: -78.4223476,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0684774,
        store_longitude: -78.4105,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.068748,
        store_longitude: -78.450999,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.06566452,
        store_longitude: -78.4527359,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.067356,
        store_longitude: -78.4522857,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0577503,
        store_longitude: -78.451712,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: 0.0027169,
        store_longitude: -78.4607458,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0765686,
        store_longitude: -78.4446723,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0996161,
        store_longitude: -78.4413246,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.106157,
        store_longitude: -78.4263494,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0860124,
        store_longitude: -78.4231138,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0880218,
        store_longitude: -78.425953,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0963831,
        store_longitude: -78.4343175,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0982734076,
        store_longitude: -78.4541320665,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094533,
        store_longitude: -78.4435,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1011066,
        store_longitude: -78.422692,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1054262,
        store_longitude: -78.4206256,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.102013,
        store_longitude: -78.4350236,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0932161,
        store_longitude: -78.4353,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1046848,
        store_longitude: -78.4298677,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0969219,
        store_longitude: -78.4416413,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0918551,
        store_longitude: -78.422719,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0985957,
        store_longitude: -78.4224,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0916315,
        store_longitude: -78.4366864,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0950547617,
        store_longitude: -78.4306561947,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0798582733,
        store_longitude: -78.4131524559,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097316,
        store_longitude: -78.420033,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1008737,
        store_longitude: -78.4227791,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1059206,
        store_longitude: -78.4262684,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.105653,
        store_longitude: -78.433,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08353986,
        store_longitude: -78.4184951,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1065378,
        store_longitude: -78.4272699,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.115785,
        store_longitude: -78.4141,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0754897451,
        store_longitude: -78.4403064474,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0955407,
        store_longitude: -78.4286,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0899095,
        store_longitude: -78.2850381,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.09656279,
        store_longitude: -78.4385986,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0893075,
        store_longitude: -78.4449086,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0894984,
        store_longitude: -78.4431923,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.058395,
        store_longitude: -78.4207057,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.107706,
        store_longitude: -78.4285,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0912601115,
        store_longitude: -78.4254071116,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0909064,
        store_longitude: -78.426,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0701622,
        store_longitude: -78.4336,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.062906,
        store_longitude: -78.3520682,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0964575,
        store_longitude: -78.4430511,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.112906,
        store_longitude: -78.413357,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0496724,
        store_longitude: -78.4332,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1815284,
        store_longitude: -78.4423386,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.0806269,
        store_longitude: -78.4329616,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0756742,
        store_longitude: -78.4313419,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0783026,
        store_longitude: -78.4316239,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0753955,
        store_longitude: -78.4402441,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0777349,
        store_longitude: -78.437546,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.075877,
        store_longitude: -78.4378,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0759032,
        store_longitude: -78.4404,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0703365,
        store_longitude: -78.4381553,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.087148,
        store_longitude: -78.4166669,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0896341,
        store_longitude: -78.4297,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.088775,
        store_longitude: -78.4274,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.082854,
        store_longitude: -78.418115,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0883294,
        store_longitude: -78.4273,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.07837,
        store_longitude: -78.417515,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0880471,
        store_longitude: -78.4258,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08041565,
        store_longitude: -78.4185943,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0839198,
        store_longitude: -78.4225,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0765247,
        store_longitude: -78.4171066,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0778389,
        store_longitude: -78.4171043,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0802492,
        store_longitude: -78.4180871,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.111312,
        store_longitude: -78.3928,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101676,
        store_longitude: -78.4086,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1016166,
        store_longitude: -78.4086068,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.096525,
        store_longitude: -78.404401,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0724364,
        store_longitude: -78.4312214,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0688375,
        store_longitude: -78.4258877,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0635562,
        store_longitude: -78.4290911,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.06354,
        store_longitude: -78.4297,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0007396,
        store_longitude: -78.5098674,
        neighborhood: 'CALACALÍ',
    },
    {
        store_latitdue: -0.065931,
        store_longitude: -78.4253,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0583631,
        store_longitude: -78.4291947,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0636382,
        store_longitude: -78.426501,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0761995,
        store_longitude: -78.4352,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.061719,
        store_longitude: -78.433152,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.066461,
        store_longitude: -78.43313,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.05937797,
        store_longitude: -78.4270858,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0712619,
        store_longitude: -78.4247831,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0615567,
        store_longitude: -78.4249,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0929044,
        store_longitude: -78.4118097,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0960114,
        store_longitude: -78.4117,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.093672,
        store_longitude: -78.410811,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0965641,
        store_longitude: -78.4128265,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.05474287,
        store_longitude: -78.3440047,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.107516,
        store_longitude: -78.4479,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.105577,
        store_longitude: -78.450055,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1071711,
        store_longitude: -78.4482939,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09817,
        store_longitude: -78.445037,
    },
    {
        store_latitdue: -0.099079,
        store_longitude: -78.444815,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0929855,
        store_longitude: -78.4529746,
    },
    {
        store_latitdue: -0.107236,
        store_longitude: -78.4526,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0994689,
        store_longitude: -78.4530314,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09461487,
        store_longitude: -78.4496683,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0969995,
        store_longitude: -78.4549016,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091367,
        store_longitude: -78.445639,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0969793,
        store_longitude: -78.452685,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0918945,
        store_longitude: -78.4495277,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.096941,
        store_longitude: -78.453611,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10215,
        store_longitude: -78.432747,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0939199,
        store_longitude: -78.448,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10759364,
        store_longitude: -78.4553754,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0921775,
        store_longitude: -78.4534424,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10181828,
        store_longitude: -78.453392,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.107499,
        store_longitude: -78.4528,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0972588,
        store_longitude: -78.4547325,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0937405,
        store_longitude: -78.4524367,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0998234,
        store_longitude: -78.4515614,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1012477,
        store_longitude: -78.453543,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101075,
        store_longitude: -78.451643,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1018,
        store_longitude: -78.451367,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092946,
        store_longitude: -78.442694,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0983086,
        store_longitude: -78.4528794,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.110724,
        store_longitude: -78.4351,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.087582,
        store_longitude: -78.456202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0978905,
        store_longitude: -78.4511,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.107481,
        store_longitude: -78.454804,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0907959,
        store_longitude: -78.4491473,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09822705,
        store_longitude: -78.4434051,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0958631,
        store_longitude: -78.4533936,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1027843,
        store_longitude: -78.4235575,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1057664,
        store_longitude: -78.4541466,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0874715,
        store_longitude: -78.4114945,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0962405,
        store_longitude: -78.450534,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.087145,
        store_longitude: -78.448699,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0881534,
        store_longitude: -78.4489227,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0844164,
        store_longitude: -78.4104,
    },
    {
        store_latitdue: -0.0898263,
        store_longitude: -78.4233321,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0897729,
        store_longitude: -78.418,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0498211,
        store_longitude: -78.2838399,
        neighborhood: 'GUAYLLABAMBA',
    },

    {
        store_latitdue: -0.0567983,
        store_longitude: -78.4538635,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.0499769,
        store_longitude: -78.2856234,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0498891,
        store_longitude: -78.274514,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0459574,
        store_longitude: -78.3056537,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0533479,
        store_longitude: -78.4252686,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1032968,
        store_longitude: -78.2947532,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.10256,
        store_longitude: -78.295331,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0818317,
        store_longitude: -78.4428283,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08143116,
        store_longitude: -78.4471685,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.081966,
        store_longitude: -78.441034,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0801457,
        store_longitude: -78.4444926,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0642673,
        store_longitude: -78.3159566,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.054694,
        store_longitude: -78.344089,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.055598,
        store_longitude: -78.3289322,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0713663,
        store_longitude: -78.3567,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0635901,
        store_longitude: -78.3552533,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.1071083,
        store_longitude: -78.3006635,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1251292,
        store_longitude: -78.3179939,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.0425483,
        store_longitude: -78.3434107,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.096462,
        store_longitude: -78.310333,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0634349,
        store_longitude: -78.3651398,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0701208,
        store_longitude: -78.3639407,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.138571,
        store_longitude: -78.422631,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.136017,
        store_longitude: -78.4206,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1384355,
        store_longitude: -78.4213893,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.0651673,
        store_longitude: -78.4071029,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1382617,
        store_longitude: -78.4420559,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1351695,
        store_longitude: -78.4379228,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1387363,
        store_longitude: -78.4378276,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.138965,
        store_longitude: -78.4402,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1412645,
        store_longitude: -78.4417159,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1385262,
        store_longitude: -78.4211569,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.140174,
        store_longitude: -78.4323,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.138727,
        store_longitude: -78.4333027,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1387592,
        store_longitude: -78.4374396,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.129627,
        store_longitude: -78.44462,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.13835893,
        store_longitude: -78.4384453,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1370477,
        store_longitude: -78.4356854,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.11257402,
        store_longitude: -78.4332122,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.131772,
        store_longitude: -78.4461801,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1260441,
        store_longitude: -78.4444373,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.155072,
        store_longitude: -78.472694,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126996,
        store_longitude: -78.4393,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1271392,
        store_longitude: -78.4462207,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1380756,
        store_longitude: -78.4423163,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1356076,
        store_longitude: -78.4432241,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1257007,
        store_longitude: -78.437902,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.130675,
        store_longitude: -78.444372,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1182738,
        store_longitude: -78.4370779,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0821464,
        store_longitude: -78.2883018,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.1261522,
        store_longitude: -78.4195109,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.121586,
        store_longitude: -78.4164,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1217027,
        store_longitude: -78.4136982,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08313225,
        store_longitude: -78.2974472,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.1155691,
        store_longitude: -78.449631,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.126306,
        store_longitude: -78.419348,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.119988,
        store_longitude: -78.4265,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1154572,
        store_longitude: -78.4365895,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1297483,
        store_longitude: -78.4132883,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1215139,
        store_longitude: -78.4340521,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.114276,
        store_longitude: -78.43792,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.119275,
        store_longitude: -78.452652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1055556,
        store_longitude: -78.420416,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.114165,
        store_longitude: -78.431,
    },
    {
        store_latitdue: -0.1121856,
        store_longitude: -78.4409855,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.109337,
        store_longitude: -78.415356,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1112186,
        store_longitude: -78.442294,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1254287,
        store_longitude: -78.42311,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.123929,
        store_longitude: -78.429489,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.110724,
        store_longitude: -78.4351,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1162994,
        store_longitude: -78.4371209,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.153371667,
        store_longitude: -78.46642167,
    },

    {
        store_latitdue: -0.1253351,
        store_longitude: -78.4226605,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1280773,
        store_longitude: -78.4167007,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1090411,
        store_longitude: -78.4400672,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1268148,
        store_longitude: -78.4194285,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.07208062,
        store_longitude: -78.4488754,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.119661,
        store_longitude: -78.42,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1127271,
        store_longitude: -78.450818,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.072368,
        store_longitude: -78.447456,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.108234,
        store_longitude: -78.442385,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.083086,
        store_longitude: -78.4372339,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0904707,
        store_longitude: -78.4353687,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0693167,
        store_longitude: -78.4337,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.089705,
        store_longitude: -78.4419999,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08454319,
        store_longitude: -78.4290254,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.085567,
        store_longitude: -78.444152,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.059247,
        store_longitude: -78.462917,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.087954,
        store_longitude: -78.443308,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.083516,
        store_longitude: -78.436275,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0834147,
        store_longitude: -78.4367659,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1626547,
        store_longitude: -78.3080302,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1588973,
        store_longitude: -78.4400163,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1575539,
        store_longitude: -78.4365927,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158208,
        store_longitude: -78.444618,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1620821,
        store_longitude: -78.4452962,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1558303,
        store_longitude: -78.4355532,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157335,
        store_longitude: -78.43983,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158001,
        store_longitude: -78.442024,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.156162,
        store_longitude: -78.435873,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157867,
        store_longitude: -78.4446,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.15798392,
        store_longitude: -78.4376399,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.156513,
        store_longitude: -78.441351,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157393,
        store_longitude: -78.437413,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1566774,
        store_longitude: -78.4372564,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.11414,
        store_longitude: -78.45029,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1978365,
        store_longitude: -78.3678424,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1775366,
        store_longitude: -78.3549643,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.0897487,
        store_longitude: -78.4405,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0647544,
        store_longitude: -78.3597686,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.22663534,
        store_longitude: -78.3390641,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.111797,
        store_longitude: -78.293,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1099734,
        store_longitude: -78.2974336,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1101707,
        store_longitude: -78.2977127,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.109846,
        store_longitude: -78.295429,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.101421,
        store_longitude: -78.314499,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1075502,
        store_longitude: -78.2966481,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1125,
        store_longitude: -78.296,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.112118,
        store_longitude: -78.297,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0725337,
        store_longitude: -78.4748907,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.125628,
        store_longitude: -78.2933666,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1882172,
        store_longitude: -78.4476557,
        neighborhood: 'NAYÓN',
    },

    {
        store_latitdue: -0.0012866,
        store_longitude: -78.4582,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.181115,
        store_longitude: -78.4413966,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.091695,
        store_longitude: -78.4404,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1738367,
        store_longitude: -78.4447667,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.181392,
        store_longitude: -78.4447,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.171638,
        store_longitude: -78.442989,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.0933124,
        store_longitude: -78.414,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.024113,
        store_longitude: -78.265,
        neighborhood: 'OTÓN',
    },
    {
        store_latitdue: -0.1945082,
        store_longitude: -78.3277015,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.162015,
        store_longitude: -78.3087114,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.200438,
        store_longitude: -78.3275974,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1647107,
        store_longitude: -78.3161606,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1493065,
        store_longitude: -78.4406563,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.032731,
        store_longitude: -78.445883,
        neighborhood: 'POMASQUI',
    },
    {
        store_latitdue: -0.1623309,
        store_longitude: -78.3169925,
    },
    {
        store_latitdue: -0.164821,
        store_longitude: -78.3157105,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.158075,
        store_longitude: -78.318612,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1634208,
        store_longitude: -78.3163242,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1639336,
        store_longitude: -78.308891,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.163241,
        store_longitude: -78.3071,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.146158,
        store_longitude: -78.32867,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.164667,
        store_longitude: -78.316299,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.1577868,
        store_longitude: -78.3211425,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.148146,
        store_longitude: -78.4376,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.147458,
        store_longitude: -78.4457,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1464904,
        store_longitude: -78.444648,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.0812479,
        store_longitude: -78.4112763,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0808139,
        store_longitude: -78.4120397,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.146054,
        store_longitude: -78.425,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.146515,
        store_longitude: -78.4249,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.080885,
        store_longitude: -78.410902,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0808581,
        store_longitude: -78.41441,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0854943,
        store_longitude: -78.4109223,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0816749,
        store_longitude: -78.4136861,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0807759,
        store_longitude: -78.4108,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080517,
        store_longitude: -78.409907,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0847544,
        store_longitude: -78.4104878,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0808394,
        store_longitude: -78.4144732,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.081273,
        store_longitude: -78.413367,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0911424,
        store_longitude: -78.4526248,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.106936,
        store_longitude: -78.442161,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1069412,
        store_longitude: -78.4418662,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1057308,
        store_longitude: -78.4338961,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10562,
        store_longitude: -78.433345,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.105195,
        store_longitude: -78.429759,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.104925,
        store_longitude: -78.428616,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1093255,
        store_longitude: -78.4257087,
    },
    {
        store_latitdue: -0.107693,
        store_longitude: -78.4404,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.102834,
        store_longitude: -78.421112,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100915,
        store_longitude: -78.421329,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100838,
        store_longitude: -78.421448,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101241,
        store_longitude: -78.4221,
    },
    {
        store_latitdue: -0.0971875,
        store_longitude: -78.4201401,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.103467,
        store_longitude: -78.424289,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.102773,
        store_longitude: -78.4242138,
    },
    {
        store_latitdue: -0.1037521,
        store_longitude: -78.4246049,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.103644,
        store_longitude: -78.42467,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1007599,
        store_longitude: -78.4216064,
    },
    {
        store_latitdue: -0.105573,
        store_longitude: -78.420342,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.106346,
        store_longitude: -78.419057,
    },
    {
        store_latitdue: -0.11137,
        store_longitude: -78.413967,
    },
    {
        store_latitdue: -0.114138,
        store_longitude: -78.412695,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0927533,
        store_longitude: -78.4132,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092533,
        store_longitude: -78.410966,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092968,
        store_longitude: -78.409965,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1058849,
        store_longitude: -78.3988981,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.107517,
        store_longitude: -78.396188,
    },
    {
        store_latitdue: -0.099474,
        store_longitude: -78.4223,
    },
    {
        store_latitdue: -0.0973796,
        store_longitude: -78.4198558,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.098424,
        store_longitude: -78.421234,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099354,
        store_longitude: -78.420042,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1006817,
        store_longitude: -78.4216348,
    },
    {
        store_latitdue: -0.092201,
        store_longitude: -78.4194,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.092127,
        store_longitude: -78.424382,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100037,
        store_longitude: -78.429422,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1002162,
        store_longitude: -78.4292709,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0993061,
        store_longitude: -78.4279,
    },
    {
        store_latitdue: -0.094714,
        store_longitude: -78.433768,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092965,
        store_longitude: -78.4348,
    },
    {
        store_latitdue: -0.093379,
        store_longitude: -78.431509,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.098313,
        store_longitude: -78.422858,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.098286,
        store_longitude: -78.42294,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095031,
        store_longitude: -78.427249,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.093563,
        store_longitude: -78.429202,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.090935,
        store_longitude: -78.4313,
    },
    {
        store_latitdue: -0.086376,
        store_longitude: -78.418836,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.082469,
        store_longitude: -78.420909,
    },
    {
        store_latitdue: -0.07948,
        store_longitude: -78.417765,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.074109,
        store_longitude: -78.417434,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.069846,
        store_longitude: -78.414935,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.068165,
        store_longitude: -78.414611,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.06441,
        store_longitude: -78.413314,
    },
    {
        store_latitdue: -0.065079,
        store_longitude: -78.417822,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.062829,
        store_longitude: -78.421147,
    },
    {
        store_latitdue: -0.063714,
        store_longitude: -78.421401,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0745489,
        store_longitude: -78.4166098,
    },
    {
        store_latitdue: -0.063609,
        store_longitude: -78.423084,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0654612,
        store_longitude: -78.425368,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.068287,
        store_longitude: -78.425614,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.063449,
        store_longitude: -78.4275091,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.063557,
        store_longitude: -78.427606,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0635412,
        store_longitude: -78.4292694,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0635356,
        store_longitude: -78.4298255,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.06403,
        store_longitude: -78.43232,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0640731,
        store_longitude: -78.4334204,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.063162,
        store_longitude: -78.434121,
    },
    {
        store_latitdue: -0.105687,
        store_longitude: -78.426185,
    },
    {
        store_latitdue: -0.090846,
        store_longitude: -78.415496,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.089984,
        store_longitude: -78.436681,
    },
    {
        store_latitdue: -0.0857532,
        store_longitude: -78.4286202,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0874002,
        store_longitude: -78.4297409,
    },
    {
        store_latitdue: -0.0825707,
        store_longitude: -78.4273113,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10562,
        store_longitude: -78.433345,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.089818,
        store_longitude: -78.414457,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.111023,
        store_longitude: -78.4319,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0844694,
        store_longitude: -78.4292811,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076759,
        store_longitude: -78.4168,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094217,
        store_longitude: -78.418061,
    },
    {
        store_latitdue: -0.09366,
        store_longitude: -78.411402,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.057024,
        store_longitude: -78.429206,
    },
    {
        store_latitdue: -0.087016,
        store_longitude: -78.427983,
    },
    {
        store_latitdue: -0.0959175,
        store_longitude: -78.419191,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09021,
        store_longitude: -78.423035,
    },
    {
        store_latitdue: -0.109931,
        store_longitude: -78.42427,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.112977,
        store_longitude: -78.420318,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0664114,
        store_longitude: -78.4276,
    },
    {
        store_latitdue: -0.0638794,
        store_longitude: -78.4211231,
    },
    {
        store_latitdue: -0.0977301,
        store_longitude: -78.432542,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097381,
        store_longitude: -78.435784,
    },
    {
        store_latitdue: -0.1019503,
        store_longitude: -78.4092488,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1072675,
        store_longitude: -78.4280647,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0773543,
        store_longitude: -78.417083,
    },
    {
        store_latitdue: -0.0946744,
        store_longitude: -78.4101,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0986967,
        store_longitude: -78.4305273,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10524,
        store_longitude: -78.420924,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0903574,
        store_longitude: -78.4227039,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.105329,
        store_longitude: -78.4014497,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.085516,
        store_longitude: -78.42363,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099334,
        store_longitude: -78.423659,
    },
    {
        store_latitdue: -0.0940907,
        store_longitude: -78.4359354,
    },
    {
        store_latitdue: -0.0683796,
        store_longitude: -78.4295,
    },
    {
        store_latitdue: -0.067793,
        store_longitude: -78.429951,
    },
    {
        store_latitdue: -0.1075,
        store_longitude: -78.4455,
    },
    {
        store_latitdue: -0.0663835,
        store_longitude: -78.4292125,
    },
    {
        store_latitdue: -0.065778,
        store_longitude: -78.429627,
    },
    {
        store_latitdue: -0.0999732,
        store_longitude: -78.4465835,
    },
    {
        store_latitdue: -0.0772773,
        store_longitude: -78.4265219,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094874,
        store_longitude: -78.425981,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0638446,
        store_longitude: -78.4301126,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1079907,
        store_longitude: -78.4397421,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0975558,
        store_longitude: -78.4203858,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.106901,
        store_longitude: -78.4419,
    },
    {
        store_latitdue: -0.0667,
        store_longitude: -78.415776,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1014845,
        store_longitude: -78.4206663,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101267,
        store_longitude: -78.4252,
    },
    {
        store_latitdue: -0.0943073,
        store_longitude: -78.4129756,
    },
    {
        store_latitdue: -0.0929429,
        store_longitude: -78.4117,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0659484,
        store_longitude: -78.4269859,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0985754,
        store_longitude: -78.4304,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0929931,
        store_longitude: -78.4117,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1010544,
        store_longitude: -78.4218928,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.105742,
        store_longitude: -78.426305,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0633355,
        store_longitude: -78.4325132,
    },
    {
        store_latitdue: -0.0720872,
        store_longitude: -78.4240959,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10018,
        store_longitude: -78.422791,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.096109,
        store_longitude: -78.4196066,
    },
    {
        store_latitdue: -0.0659808,
        store_longitude: -78.4294912,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0669505,
        store_longitude: -78.4297926,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0641475,
        store_longitude: -78.4332774,
    },
    {
        store_latitdue: -0.0640589,
        store_longitude: -78.4174365,
    },
    {
        store_latitdue: -0.0635425,
        store_longitude: -78.4349171,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.3321977,
        store_longitude: -78.572609,
    },
    {
        store_latitdue: -0.0642357,
        store_longitude: -78.4366201,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.056126,
        store_longitude: -78.428314,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0683411,
        store_longitude: -78.431,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.067371,
        store_longitude: -78.430952,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.090322,
        store_longitude: -78.418934,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10281,
        store_longitude: -78.434059,
    },
    {
        store_latitdue: -0.09596,
        store_longitude: -78.419764,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0792311,
        store_longitude: -78.4246513,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1013168,
        store_longitude: -78.4275988,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0648617,
        store_longitude: -78.4178,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0848804,
        store_longitude: -78.4198,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094531,
        store_longitude: -78.409539,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.100115,
        store_longitude: -78.422704,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0640337,
        store_longitude: -78.434073,
    },
    {
        store_latitdue: -0.08289,
        store_longitude: -78.426008,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.105865,
        store_longitude: -78.42,
    },
    {
        store_latitdue: -0.061465,
        store_longitude: -78.4263,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091934,
        store_longitude: -78.4362,
    },
    {
        store_latitdue: -0.057601,
        store_longitude: -78.424275,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0527659,
        store_longitude: -78.424005,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0544711,
        store_longitude: -78.4260206,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0730421,
        store_longitude: -78.4166856,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0644826,
        store_longitude: -78.4298,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0643624,
        store_longitude: -78.4129386,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.088674,
        store_longitude: -78.4208,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0825137,
        store_longitude: -78.4182244,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10138,
        store_longitude: -78.4239,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1080338,
        store_longitude: -78.4217535,
    },
    {
        store_latitdue: -0.112121,
        store_longitude: -78.414205,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0569667,
        store_longitude: -78.4204961,
    },
    {
        store_latitdue: -0.0919138,
        store_longitude: -78.4362,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0583128,
        store_longitude: -78.4207362,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0641521,
        store_longitude: -78.4336,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0806215,
        store_longitude: -78.4181,
    },
    {
        store_latitdue: -0.0988475,
        store_longitude: -78.4769,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.087145,
        store_longitude: -78.448699,
    },
    {
        store_latitdue: -0.087123333333333,
        store_longitude: -78.449805,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0863434,
        store_longitude: -78.4500215,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0910974,
        store_longitude: -78.4459,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095056,
        store_longitude: -78.443646,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.096271,
        store_longitude: -78.440584,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0965628,
        store_longitude: -78.4369656,
    },
    {
        store_latitdue: -0.0972558,
        store_longitude: -78.4362038,
    },
    {
        store_latitdue: -0.0942633,
        store_longitude: -78.438255,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0918015,
        store_longitude: -78.4392836,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0548575,
        store_longitude: -78.4232332,
    },
    {
        store_latitdue: -0.091594,
        store_longitude: -78.440381,
    },
    {
        store_latitdue: -0.106371,
        store_longitude: -78.453804,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.106383,
        store_longitude: -78.454222,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.106313,
        store_longitude: -78.453936,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.103068,
        store_longitude: -78.451047,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101417,
        store_longitude: -78.452484,
    },
    {
        store_latitdue: -0.100677,
        store_longitude: -78.4534846,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100475,
        store_longitude: -78.453,
    },
    {
        store_latitdue: -0.100875,
        store_longitude: -78.452088,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0995312,
        store_longitude: -78.4529957,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099025,
        store_longitude: -78.452055,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.098365,
        store_longitude: -78.452093,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0963678,
        store_longitude: -78.451875,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099404,
        store_longitude: -78.451289,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10027,
        store_longitude: -78.451251,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0993058,
        store_longitude: -78.4499,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0958448,
        store_longitude: -78.4505712,
    },
    {
        store_latitdue: -0.0959718,
        store_longitude: -78.474552,
    },
    {
        store_latitdue: -0.095205,
        store_longitude: -78.45236,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094628,
        store_longitude: -78.453083,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0938427,
        store_longitude: -78.4512171,
    },
    {
        store_latitdue: -0.09389,
        store_longitude: -78.450765,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.093903,
        store_longitude: -78.450582,
    },
    {
        store_latitdue: -0.0930271,
        store_longitude: -78.453156,
    },
    {
        store_latitdue: -0.092674,
        store_longitude: -78.4529,
    },
    {
        store_latitdue: -0.0923,
        store_longitude: -78.4557,
    },
    {
        store_latitdue: -0.092305,
        store_longitude: -78.455709,
    },
    {
        store_latitdue: -0.0895459,
        store_longitude: -78.4536455,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.090665,
        store_longitude: -78.453195,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.089296,
        store_longitude: -78.4519,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09244,
        store_longitude: -78.449417,
    },
    {
        store_latitdue: -0.092526,
        store_longitude: -78.449115,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0924263,
        store_longitude: -78.4482293,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0912685,
        store_longitude: -78.4496326,
    },
    {
        store_latitdue: -0.089831,
        store_longitude: -78.449457,
    },
    {
        store_latitdue: -0.090262,
        store_longitude: -78.450116,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.105839,
        store_longitude: -78.462104,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.104179,
        store_longitude: -78.460072,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0978388,
        store_longitude: -78.4602857,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0964172,
        store_longitude: -78.4405821,
    },
    {
        store_latitdue: -0.086071,
        store_longitude: -78.448705,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094688,
        store_longitude: -78.454313,
    },
    {
        store_latitdue: -0.09279,
        store_longitude: -78.460963,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093597,
        store_longitude: -78.451178,
    },
    {
        store_latitdue: -0.092717,
        store_longitude: -78.460726,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102698,
        store_longitude: -78.43213,
    },
    {
        store_latitdue: -0.107798,
        store_longitude: -78.449926,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1064208,
        store_longitude: -78.4545714,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0960752,
        store_longitude: -78.4504986,
    },
    {
        store_latitdue: -0.096263,
        store_longitude: -78.450376,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.103957,
        store_longitude: -78.432542,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095379,
        store_longitude: -78.450628,
    },
    {
        store_latitdue: -0.101234,
        store_longitude: -78.432637,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10105,
        store_longitude: -78.438843,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09934,
        store_longitude: -78.442647,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099644,
        store_longitude: -78.441442,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095825,
        store_longitude: -78.455042,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.103759,
        store_longitude: -78.4482,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092769,
        store_longitude: -78.4463,
    },
    {
        store_latitdue: -0.09764,
        store_longitude: -78.443108,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1077722,
        store_longitude: -78.451816,
    },
    {
        store_latitdue: -0.0916586,
        store_longitude: -78.4518599,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0915451,
        store_longitude: -78.4485993,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094479,
        store_longitude: -78.443382,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1081005,
        store_longitude: -78.454978,
    },
    {
        store_latitdue: -0.0898977,
        store_longitude: -78.4537554,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.082869,
        store_longitude: -78.453967,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0898805,
        store_longitude: -78.4442285,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0904135,
        store_longitude: -78.4498056,
    },
    {
        store_latitdue: -0.0918714,
        store_longitude: -78.4390888,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095741,
        store_longitude: -78.450761,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.093167,
        store_longitude: -78.451123,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.087402,
        store_longitude: -78.4481,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0961385,
        store_longitude: -78.4522776,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0914688,
        store_longitude: -78.4495439,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101239,
        store_longitude: -78.4535,
    },
    {
        store_latitdue: -0.0960734,
        store_longitude: -78.4495439,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092257,
        store_longitude: -78.453079,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0892519,
        store_longitude: -78.4522309,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100319,
        store_longitude: -78.453969,
    },
    {
        store_latitdue: -0.10392333333333,
        store_longitude: -78.459071666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1022926,
        store_longitude: -78.461548,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0965002,
        store_longitude: -78.4509966,
    },
    {
        store_latitdue: -0.094876,
        store_longitude: -78.446497,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101097,
        store_longitude: -78.4478,
    },

    {
        store_latitdue: -0.099196,
        store_longitude: -78.4512639,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1077883,
        store_longitude: -78.449458,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0978908,
        store_longitude: -78.4497099,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0896847,
        store_longitude: -78.4498,
    },
    {
        store_latitdue: -0.10665,
        store_longitude: -78.4556,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0987119,
        store_longitude: -78.45202,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1019288,
        store_longitude: -78.4318527,
    },
    {
        store_latitdue: -0.095776,
        store_longitude: -78.453147,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.094386,
        store_longitude: -78.437771,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101291,
        store_longitude: -78.4387,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0973866,
        store_longitude: -78.4501723,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0962252,
        store_longitude: -78.4510212,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0924147,
        store_longitude: -78.4475147,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0918903,
        store_longitude: -78.4205159,
    },
    {
        store_latitdue: -0.0977022,
        store_longitude: -78.4512736,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.103493,
        store_longitude: -78.4572,
    },
    {
        store_latitdue: -0.0879889,
        store_longitude: -78.44772,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.105429,
        store_longitude: -78.449837,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.336622,
        store_longitude: -78.566,
    },

    {
        store_latitdue: -0.108239,
        store_longitude: -78.4502215,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.109924,
        store_longitude: -78.445167,
    },
    {
        store_latitdue: -0.111701,
        store_longitude: -78.444465,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.112561,
        store_longitude: -78.4466,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.112937,
        store_longitude: -78.4483,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.111062,
        store_longitude: -78.450576,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.110943,
        store_longitude: -78.45075,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.111958,
        store_longitude: -78.449675,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.114927,
        store_longitude: -78.450937,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1115723,
        store_longitude: -78.4418617,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113798,
        store_longitude: -78.4387513,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.115827,
        store_longitude: -78.436193,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.115762,
        store_longitude: -78.436124,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.116151,
        store_longitude: -78.430086,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.114984,
        store_longitude: -78.43,
    },
    {
        store_latitdue: -0.1285747,
        store_longitude: -78.4166565,
    },
    {
        store_latitdue: -0.12329,
        store_longitude: -78.426907,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.128564,
        store_longitude: -78.415185,
    },
    {
        store_latitdue: -0.128445,
        store_longitude: -78.41671,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.122625,
        store_longitude: -78.416556,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.12118,
        store_longitude: -78.4167,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.119581,
        store_longitude: -78.420488,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.118327,
        store_longitude: -78.422176,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113946,
        store_longitude: -78.4302,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113134,
        store_longitude: -78.4315,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1162397,
        store_longitude: -78.4402012,
    },
    {
        store_latitdue: -0.119299,
        store_longitude: -78.440156,
    },
    {
        store_latitdue: -0.12233,
        store_longitude: -78.442,
    },
    {
        store_latitdue: -0.125693,
        store_longitude: -78.44443,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1236189,
        store_longitude: -78.4468921,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1275555,
        store_longitude: -78.441758,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.127834,
        store_longitude: -78.4447,
    },
    {
        store_latitdue: -0.130228,
        store_longitude: -78.444543,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.135659,
        store_longitude: -78.4433,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.139118,
        store_longitude: -78.442753,
    },
    {
        store_latitdue: -0.1390509,
        store_longitude: -78.4409632,
    },
    {
        store_latitdue: -0.138726,
        store_longitude: -78.436889,
    },
    {
        store_latitdue: -0.138817,
        store_longitude: -78.435939,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.138739,
        store_longitude: -78.434332,
    },
    {
        store_latitdue: -0.110288,
        store_longitude: -78.445338,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1124471,
        store_longitude: -78.4330645,
    },
    {
        store_latitdue: -0.131571,
        store_longitude: -78.441196,
    },
    {
        store_latitdue: -0.1144044,
        store_longitude: -78.4502772,
    },
    {
        store_latitdue: -0.127801,
        store_longitude: -78.444554,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1117895,
        store_longitude: -78.4416521,
    },
    {
        store_latitdue: -0.140097,
        store_longitude: -78.41213,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1191695,
        store_longitude: -78.4270995,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1384282,
        store_longitude: -78.4153875,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.137043,
        store_longitude: -78.436044,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.136169,
        store_longitude: -78.4382,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1101068,
        store_longitude: -78.4452774,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.139197,
        store_longitude: -78.436013,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.121129,
        store_longitude: -78.416735,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.131128,
        store_longitude: -78.4440319,
    },
    {
        store_latitdue: -0.1269399,
        store_longitude: -78.4477166,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1157005,
        store_longitude: -78.4529535,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.120821,
        store_longitude: -78.4283,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1110282,
        store_longitude: -78.4427409,
    },
    {
        store_latitdue: -0.121349,
        store_longitude: -78.429798,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1130367,
        store_longitude: -78.4482221,
    },
    {
        store_latitdue: -0.1061071,
        store_longitude: -78.4369085,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.112734,
        store_longitude: -78.4324,
    },
    {
        store_latitdue: -0.1129354,
        store_longitude: -78.4484013,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113392,
        store_longitude: -78.450563,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1270361,
        store_longitude: -78.4443012,
    },
    {
        store_latitdue: -0.114786,
        store_longitude: -78.430266,
    },
    {
        store_latitdue: -0.1403312,
        store_longitude: -78.4391176,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.138597,
        store_longitude: -78.441969,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1259833,
        store_longitude: -78.4435095,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.131484,
        store_longitude: -78.40898,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.127566,
        store_longitude: -78.4418,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1186425,
        store_longitude: -78.431784,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1220023,
        store_longitude: -78.4303978,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1123768,
        store_longitude: -78.4488154,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.132106,
        store_longitude: -78.4087,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.140487,
        store_longitude: -78.4375,
    },
    {
        store_latitdue: -0.134894,
        store_longitude: -78.441494,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1207388,
        store_longitude: -78.4334165,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.135941,
        store_longitude: -78.422829,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.114378,
        store_longitude: -78.4379,
    },
    {
        store_latitdue: -0.1218391,
        store_longitude: -78.4142823,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0889136,
        store_longitude: -78.4523892,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.06837,
        store_longitude: -78.433454,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0706766,
        store_longitude: -78.4338214,
    },
    {
        store_latitdue: -0.0710711,
        store_longitude: -78.4339,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0761113,
        store_longitude: -78.4336951,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.075935,
        store_longitude: -78.433162,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.075149,
        store_longitude: -78.432655,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0765667,
        store_longitude: -78.4314,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0758601,
        store_longitude: -78.438547,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0755479,
        store_longitude: -78.438507,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0763811,
        store_longitude: -78.4345,
    },
    {
        store_latitdue: -0.07825,
        store_longitude: -78.439,
    },
    {
        store_latitdue: -0.0795222,
        store_longitude: -78.4408856,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0794144,
        store_longitude: -78.4420891,
    },
    {
        store_latitdue: -0.0781889,
        store_longitude: -78.4400186,
    },
    {
        store_latitdue: -0.0787761,
        store_longitude: -78.4388242,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0823268,
        store_longitude: -78.443166,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0833523,
        store_longitude: -78.4367,
    },
    {
        store_latitdue: -0.084819,
        store_longitude: -78.443655,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.084714,
        store_longitude: -78.443796,
    },
    {
        store_latitdue: -0.0866607,
        store_longitude: -78.4435,
    },
    {
        store_latitdue: -0.085668,
        store_longitude: -78.437642,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0879035,
        store_longitude: -78.4434832,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0896925,
        store_longitude: -78.4404,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.089054,
        store_longitude: -78.445716,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0770351,
        store_longitude: -78.443643,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0864679,
        store_longitude: -78.442043,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.077402,
        store_longitude: -78.431685,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.071124,
        store_longitude: -78.437695,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0718711,
        store_longitude: -78.4349965,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.070633,
        store_longitude: -78.43525,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0653147,
        store_longitude: -78.4529875,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0779152,
        store_longitude: -78.4316491,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0794853,
        store_longitude: -78.4454833,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076143,
        store_longitude: -78.433672,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.07198,
        store_longitude: -78.437567,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0889944,
        store_longitude: -78.4397949,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0.036896,
        store_longitude: -78.1475,
    },
    {
        store_latitdue: -0.0722195,
        store_longitude: -78.4384165,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0892037,
        store_longitude: -78.4427733,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.075864,
        store_longitude: -78.4373,
    },
    {
        store_latitdue: -0.0883333,
        store_longitude: -78.4395946,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0658,
        store_longitude: -78.452814,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.081435,
        store_longitude: -78.441006,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.077594,
        store_longitude: -78.438604,
    },
    {
        store_latitdue: -0.0763734,
        store_longitude: -78.4314,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0847436,
        store_longitude: -78.4368947,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0774051,
        store_longitude: -78.4354658,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0773804,
        store_longitude: -78.442538,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.07135,
        store_longitude: -78.439254,
    },
    {
        store_latitdue: -0.08384045,
        store_longitude: -78.43403041,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0710287,
        store_longitude: -78.4500957,
    },
    {
        store_latitdue: -0.0845635,
        store_longitude: -78.4368,
    },
    {
        store_latitdue: -0.0807029,
        store_longitude: -78.4434,
    },
    {
        store_latitdue: -0.075493,
        store_longitude: -78.430693,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0747071,
        store_longitude: -78.4306952,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0688324,
        store_longitude: -78.4514772,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0742834,
        store_longitude: -78.433262,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0683004,
        store_longitude: -78.4334162,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0863065,
        store_longitude: -78.4413109,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.070545,
        store_longitude: -78.436186,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0829621,
        store_longitude: -78.43676,
    },
    {
        store_latitdue: -0.083371,
        store_longitude: -78.43654,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0822187,
        store_longitude: -78.4124743,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0796,
        store_longitude: -78.4148046,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0799085,
        store_longitude: -78.4141039,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0798898,
        store_longitude: -78.4139474,
    },
    {
        store_latitdue: -0.080836,
        store_longitude: -78.411879,
    },
    {
        store_latitdue: -0.0789461,
        store_longitude: -78.4148106,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0803985,
        store_longitude: -78.4114295,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0813763,
        store_longitude: -78.4089834,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0798206,
        store_longitude: -78.4083312,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080157,
        store_longitude: -78.413474,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.078553,
        store_longitude: -78.415054,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.077769,
        store_longitude: -78.411605,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0788795,
        store_longitude: -78.4149371,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.084549,
        store_longitude: -78.411761,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.085,
        store_longitude: -78.4202,
    },
    {
        store_latitdue: -0.0800452,
        store_longitude: -78.4139,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0767474,
        store_longitude: -78.4098467,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0822388,
        store_longitude: -78.4094,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.07875,
        store_longitude: -78.414199,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.072565,
        store_longitude: -78.4086783,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0751854,
        store_longitude: -78.4090735,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0742529,
        store_longitude: -78.4090044,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.082084,
        store_longitude: -78.298189,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0809495,
        store_longitude: -78.2919045,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0786616,
        store_longitude: -78.300438,
    },
    {
        store_latitdue: -0.081936,
        store_longitude: -78.292422,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0803437,
        store_longitude: -78.2897305,
    },
    {
        store_latitdue: -0.0530904,
        store_longitude: -78.2848939,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0798717,
        store_longitude: -78.2909,
    },
    {
        store_latitdue: -0.0812598,
        store_longitude: -78.292,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0679683,
        store_longitude: -78.2893611,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0644926,
        store_longitude: -78.2892,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0646184,
        store_longitude: -78.2889,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0804931,
        store_longitude: -78.2898,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.06564,
        store_longitude: -78.277634,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.1015832,
        store_longitude: -78.2991683,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.078728,
        store_longitude: -78.299438,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0522705,
        store_longitude: -78.2857232,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0630779,
        store_longitude: -78.2877726,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0864885,
        store_longitude: -78.2935346,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0818112,
        store_longitude: -78.297163,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0780612,
        store_longitude: -78.2907383,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0640731,
        store_longitude: -78.2905,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.067,
        store_longitude: -78.291307,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0491019,
        store_longitude: -78.2865,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.082605,
        store_longitude: -78.2937083,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0800251,
        store_longitude: -78.2976032,
        neighborhood: 'ASCÁZUBI',
    },

    {
        store_latitdue: -0.079067,
        store_longitude: -78.291152,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0808327,
        store_longitude: -78.2918051,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0820983,
        store_longitude: -78.2939944,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0808316,
        store_longitude: -78.2917297,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0852877,
        store_longitude: -78.2930667,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0866071,
        store_longitude: -78.2885116,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0842295,
        store_longitude: -78.2896547,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0877221,
        store_longitude: -78.2893387,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0893347,
        store_longitude: -78.2860917,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.090437,
        store_longitude: -78.284076,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.087637,
        store_longitude: -78.290198,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.08441,
        store_longitude: -78.291952,
    },
    {
        store_latitdue: -0.065073,
        store_longitude: -78.288355,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.084034,
        store_longitude: -78.291878,
    },
    {
        store_latitdue: -0.224386,
        store_longitude: -78.396453,
    },
    {
        store_latitdue: -0.126225,
        store_longitude: -78.315487,
    },
    {
        store_latitdue: -0.1230661,
        store_longitude: -78.3236446,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1608042,
        store_longitude: -78.3306987,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1268542,
        store_longitude: -78.3137229,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1563914,
        store_longitude: -78.3243616,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.15812837993287,
        store_longitude: -78.318624739917,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.125101,
        store_longitude: -78.3173,
    },
    {
        store_latitdue: -0.131158,
        store_longitude: -78.3051,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1308257,
        store_longitude: -78.3050522,
    },
    {
        store_latitdue: -0.125104,
        store_longitude: -78.320048,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1303939,
        store_longitude: -78.3103823,
    },
    {
        store_latitdue: -0.1250304,
        store_longitude: -78.3181213,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1269225,
        store_longitude: -78.3138216,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.127593,
        store_longitude: -78.3116,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.129175077,
        store_longitude: -78.308959007,
    },
    {
        store_latitdue: -0.128298,
        store_longitude: -78.30915,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1289055,
        store_longitude: -78.3121278,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1270882,
        store_longitude: -78.3129972,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.128923,
        store_longitude: -78.312257,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.132875,
        store_longitude: -78.29951,
    },
    {
        store_latitdue: -0.1307278,
        store_longitude: -78.3028765,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1305004,
        store_longitude: -78.3034645,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.128471,
        store_longitude: -78.3073,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.130255,
        store_longitude: -78.306055,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1304983,
        store_longitude: -78.3058817,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1248347,
        store_longitude: -78.318812,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1998169,
        store_longitude: -78.3810885,
    },
    {
        store_latitdue: -0.130261,
        store_longitude: -78.3105,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1251175,
        store_longitude: -78.3180934,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.157889,
        store_longitude: -78.308,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.15027244,
        store_longitude: -78.3187484,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1235342,
        store_longitude: -78.3166473,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.157399,
        store_longitude: -78.308792,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.133878,
        store_longitude: -78.29512,
    },
    {
        store_latitdue: -0.12718,
        store_longitude: -78.312662,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.125707,
        store_longitude: -78.31663,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.128897,
        store_longitude: -78.314197,
    },
    {
        store_latitdue: -0.1248894,
        store_longitude: -78.3187153,
        neighborhood: 'CHECA',
    },

    {
        store_latitdue: -0.127674,
        store_longitude: -78.310913,
        neighborhood: 'CHECA',
    },

    {
        store_latitdue: -0.1366546,
        store_longitude: -78.2902616,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1348217,
        store_longitude: -78.2952983,
    },

    {
        store_latitdue: -0.1236491,
        store_longitude: -78.3158638,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1301216,
        store_longitude: -78.3104961,
    },

    {
        store_latitdue: -0.0882973,
        store_longitude: -78.4199,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.062046,
        store_longitude: -78.424621,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0695404,
        store_longitude: -78.4190872,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.107548,
        store_longitude: -78.3962,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0864905,
        store_longitude: -78.4184,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0998663,
        store_longitude: -78.4226,
    },
    {
        store_latitdue: -0.089774490361968,
        store_longitude: -78.418001867746,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0630564,
        store_longitude: -78.4328,
    },
    {
        store_latitdue: -0.096645,
        store_longitude: -78.425204,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0624598,
        store_longitude: -78.4294759,
    },
    {
        store_latitdue: -0.071635,
        store_longitude: -78.431205,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0713153,
        store_longitude: -78.4310338,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0712894,
        store_longitude: -78.4325,
    },
    {
        store_latitdue: -0.068772,
        store_longitude: -78.421436,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097291,
        store_longitude: -78.426376,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0944545,
        store_longitude: -78.4279,
    },
    {
        store_latitdue: -0.0685497,
        store_longitude: -78.418168,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0591532,
        store_longitude: -78.4222889,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.083183,
        store_longitude: -78.418628,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0925358,
        store_longitude: -78.4307,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.093736,
        store_longitude: -78.410676,
    },
    {
        store_latitdue: -0.0553615,
        store_longitude: -78.424184,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.068314,
        store_longitude: -78.4214011,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0807344,
        store_longitude: -78.422447,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0635187,
        store_longitude: -78.4227,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.069532,
        store_longitude: -78.422737,
    },
    {
        store_latitdue: -0.0634934,
        store_longitude: -78.4278723,
    },
    {
        store_latitdue: -0.0541765,
        store_longitude: -78.4359563,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.067728,
        store_longitude: -78.414643,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1034107,
        store_longitude: -78.4199778,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0675227,
        store_longitude: -78.4254936,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0634765,
        store_longitude: -78.4236668,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0713901,
        store_longitude: -78.4133411,
    },
    {
        store_latitdue: -0.100542,
        store_longitude: -78.4077,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1123462,
        store_longitude: -78.4509911,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.05961,
        store_longitude: -78.42146,
    },
    {
        store_latitdue: -0.0537394,
        store_longitude: -78.4203654,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.054958,
        store_longitude: -78.42064,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0640327,
        store_longitude: -78.4324368,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.061524,
        store_longitude: -78.420765,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0902937,
        store_longitude: -78.4355286,
    },
    {
        store_latitdue: -0.100804,
        store_longitude: -78.421472,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1053546,
        store_longitude: -78.4259744,
    },
    {
        store_latitdue: -0.0903504,
        store_longitude: -78.4265805,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0928556,
        store_longitude: -78.4348964,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0921546,
        store_longitude: -78.43312,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.105142,
        store_longitude: -78.4258,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0996388,
        store_longitude: -78.4223,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1042822,
        store_longitude: -78.4251816,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1072167,
        store_longitude: -78.4324985,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0996026,
        store_longitude: -78.4273387,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0938636,
        store_longitude: -78.4324337,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.084796,
        store_longitude: -78.4398352,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0600773,
        store_longitude: -78.4316229,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.090404,
        store_longitude: -78.423489,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0640592,
        store_longitude: -78.4329696,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097102,
        store_longitude: -78.420021,
    },
    {
        store_latitdue: -0.0988445,
        store_longitude: -78.4217,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0634674,
        store_longitude: -78.424411,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.100063,
        store_longitude: -78.4294,
    },
    {
        store_latitdue: -0.0943122,
        store_longitude: -78.4114,
    },
    {
        store_latitdue: -0.0846808,
        store_longitude: -78.419813,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100673,
        store_longitude: -78.4235,
    },
    {
        store_latitdue: -0.0957551,
        store_longitude: -78.4285,
    },
    {
        store_latitdue: -0.0993919,
        store_longitude: -78.4218585,
    },
    {
        store_latitdue: -0.078997157032087,
        store_longitude: -78.424489257007,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0969353,
        store_longitude: -78.4282137,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.086019,
        store_longitude: -78.4317808,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1010826,
        store_longitude: -78.4219097,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0972446,
        store_longitude: -78.4201927,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.105107,
        store_longitude: -78.4297,
    },
    {
        store_latitdue: -0.0958368,
        store_longitude: -78.4351184,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0586583,
        store_longitude: -78.4294,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0640758,
        store_longitude: -78.4174,
    },
    {
        store_latitdue: -0.101272,
        store_longitude: -78.4386,
    },
    {
        store_latitdue: -0.0959039,
        store_longitude: -78.4260542,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0714903,
        store_longitude: -78.432342,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0985957,
        store_longitude: -78.4224,
    },
    {
        store_latitdue: -0.0937985,
        store_longitude: -78.4388,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0996013,
        store_longitude: -78.4381227,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0713237,
        store_longitude: -78.4388043,
    },
    {
        store_latitdue: -0.0759842,
        store_longitude: -78.4351037,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0637598,
        store_longitude: -78.42,
    },
    {
        store_latitdue: -0.102127,
        store_longitude: -78.431181,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0572787,
        store_longitude: -78.4340257,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0861615,
        store_longitude: -78.4223,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0895576,
        store_longitude: -78.4366,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1027843,
        store_longitude: -78.4235575,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.127088,
        store_longitude: -78.444508,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.07716,
        store_longitude: -78.4431,
    },
    {
        store_latitdue: -0.0916049,
        store_longitude: -78.4366668,
    },
    {
        store_latitdue: -0.0875839,
        store_longitude: -78.4447,
    },
    {
        store_latitdue: -0.1090274,
        store_longitude: -78.4547131,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0644936,
        store_longitude: -78.4134,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080492,
        store_longitude: -78.4363,
    },
    {
        store_latitdue: -0.0586805,
        store_longitude: -78.4237,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.084527,
        store_longitude: -78.410319,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0891482,
        store_longitude: -78.4206,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0974577,
        store_longitude: -78.4422,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1024475,
        store_longitude: -78.4208048,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0974308,
        store_longitude: -78.442,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0937758,
        store_longitude: -78.4114933,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0995424,
        store_longitude: -78.4273198,
    },
    {
        store_latitdue: -0.0969539,
        store_longitude: -78.4415043,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.082042,
        store_longitude: -78.428052,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091863,
        store_longitude: -78.432837,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0781021,
        store_longitude: -78.4318097,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0704162,
        store_longitude: -78.4140402,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0658044,
        store_longitude: -78.412,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0932578,
        store_longitude: -78.438277,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0643949,
        store_longitude: -78.4139,
    },
    {
        store_latitdue: -0.066943,
        store_longitude: -78.452484,
    },
    {
        store_latitdue: -0.0998922,
        store_longitude: -78.4225,
    },
    {
        store_latitdue: -0.09167149,
        store_longitude: -78.4443327,
    },
    {
        store_latitdue: -0.069033,
        store_longitude: -78.433503,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.054252,
        store_longitude: -78.424208,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0915955,
        store_longitude: -78.4164535,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0961704,
        store_longitude: -78.4195413,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097566,
        store_longitude: -78.4426,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.069404,
        store_longitude: -78.451656,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09903362,
        store_longitude: -78.42732516,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0677236,
        store_longitude: -78.4522712,
    },
    {
        store_latitdue: -0.077063,
        store_longitude: -78.4208,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0736202,
        store_longitude: -78.440971,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0702943,
        store_longitude: -78.4337909,
    },
    {
        store_latitdue: -0.0616738,
        store_longitude: -78.4528,
    },
    {
        store_latitdue: -0.1269025,
        store_longitude: -78.4445871,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.0955723,
        store_longitude: -78.4286951,
    },
    {
        store_latitdue: -0.0692695,
        store_longitude: -78.4516,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0936305,
        store_longitude: -78.4326351,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.127784,
        store_longitude: -78.4445,
    },
    {
        store_latitdue: -0.089818,
        store_longitude: -78.414457,
    },
    {
        store_latitdue: -0.0826775,
        store_longitude: -78.4259399,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0751439,
        store_longitude: -78.4381935,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0934497,
        store_longitude: -78.4313,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0635356,
        store_longitude: -78.4298255,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1001116,
        store_longitude: -78.4293954,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10331,
        store_longitude: -78.425085,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0833364,
        store_longitude: -78.4307494,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.08749045,
        store_longitude: -78.4278069,
    },
    {
        store_latitdue: -0.079826,
        store_longitude: -78.436099,
    },
    {
        store_latitdue: -0.0940828,
        store_longitude: -78.4432,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.130396,
        store_longitude: -78.442632,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1016823,
        store_longitude: -78.4204972,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0739921,
        store_longitude: -78.4281134,
    },
    {
        store_latitdue: -0.0694281,
        store_longitude: -78.4517,
    },
    {
        store_latitdue: -0.101416,
        store_longitude: -78.4358,
    },
    {
        store_latitdue: -0.0972005,
        store_longitude: -78.4413633,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0673381,
        store_longitude: -78.4332,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0747026,
        store_longitude: -78.4324,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0548442,
        store_longitude: -78.4242,
    },
    {
        store_latitdue: -0.0730424,
        store_longitude: -78.4372368,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0996954,
        store_longitude: -78.4427,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0610592,
        store_longitude: -78.4519,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0.0402477,
        store_longitude: -78.1416432,
    },
    {
        store_latitdue: -0.064136,
        store_longitude: -78.4338182,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0700933,
        store_longitude: -78.4337,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1075092,
        store_longitude: -78.4476866,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1357401,
        store_longitude: -78.4436175,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.0949284,
        store_longitude: -78.4366,
    },
    {
        store_latitdue: -0.0641121,
        store_longitude: -78.4331,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.103389,
        store_longitude: -78.4046,
    },
    {
        store_latitdue: -0.0903254,
        store_longitude: -78.4268,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.089705,
        store_longitude: -78.4179358,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0971633,
        store_longitude: -78.4419,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.066842,
        store_longitude: -78.452585,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0924507,
        store_longitude: -78.4109427,
    },
    {
        store_latitdue: -0.1007084,
        store_longitude: -78.406287,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1426652,
        store_longitude: -78.4375216,
    },
    {
        store_latitdue: -0.0703146,
        store_longitude: -78.4338,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1408002,
        store_longitude: -78.4417062,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0769473,
        store_longitude: -78.4209163,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0941914,
        store_longitude: -78.4131,
    },
    {
        store_latitdue: -0.0963443,
        store_longitude: -78.4404157,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0.063471,
        store_longitude: -78.428981,
    },
    {
        store_latitdue: -0.0839887,
        store_longitude: -78.431087,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0899876,
        store_longitude: -78.4143,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.105008,
        store_longitude: -78.434,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0959084,
        store_longitude: -78.4116221,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1031715,
        store_longitude: -78.4050311,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100858,
        store_longitude: -78.4235,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0943669,
        store_longitude: -78.4279756,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0910991,
        store_longitude: -78.4313937,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.074937,
        store_longitude: -78.431157,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0961612,
        store_longitude: -78.4081373,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0668523,
        store_longitude: -78.4297556,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0957752,
        store_longitude: -78.4081,
    },
    {
        store_latitdue: -0.105234,
        store_longitude: -78.4017941,
    },
    {
        store_latitdue: -0.07857,
        store_longitude: -78.4389751,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0768529,
        store_longitude: -78.4164,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1058496,
        store_longitude: -78.3992103,
    },
    {
        store_latitdue: -0.128128,
        store_longitude: -78.4448,
    },
    {
        store_latitdue: -0.111243,
        store_longitude: -78.3958,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0545042,
        store_longitude: -78.4154,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0910377,
        store_longitude: -78.4183,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.111715,
        store_longitude: -78.46366,
    },
    {
        store_latitdue: -0.0803135,
        store_longitude: -78.4342519,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.078941,
        store_longitude: -78.4164327,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0736435,
        store_longitude: -78.4186507,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0922878,
        store_longitude: -78.4126093,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0723973,
        store_longitude: -78.435196,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0547019,
        store_longitude: -78.4241396,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.075753,
        store_longitude: -78.4382036,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0636113,
        store_longitude: -78.4303514,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094398,
        store_longitude: -78.4315588,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0602812,
        store_longitude: -78.432397,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0578515,
        store_longitude: -78.428875,
    },
    {
        store_latitdue: -0.0725771,
        store_longitude: -78.4228818,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0551543,
        store_longitude: -78.4242047,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0632346,
        store_longitude: -78.45159,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.068587,
        store_longitude: -78.451576,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0750157,
        store_longitude: -78.4277625,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1000311,
        store_longitude: -78.4295098,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.079266,
        store_longitude: -78.4319513,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0828158,
        store_longitude: -78.4260027,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0963519,
        store_longitude: -78.4317,
    },
    {
        store_latitdue: -0.096205,
        store_longitude: -78.431651,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -2.881432747,
        store_longitude: -78.96190862,
    },
    {
        store_latitdue: -0.0955487,
        store_longitude: -78.4098,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1027116,
        store_longitude: -78.4253086,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0847432,
        store_longitude: -78.4346681,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099719,
        store_longitude: -78.4382116,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0758091,
        store_longitude: -78.435,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0973365,
        store_longitude: -78.4412727,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0888131,
        store_longitude: -78.42847,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0903566,
        store_longitude: -78.4226791,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0633163,
        store_longitude: -78.4247134,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0708031,
        store_longitude: -78.4317049,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080209,
        store_longitude: -78.4354186,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.090476,
        store_longitude: -78.435397,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100785,
        store_longitude: -78.4215,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0870347,
        store_longitude: -78.4751,
    },
    {
        store_latitdue: -0.0903592,
        store_longitude: -78.4353205,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1016,
        store_longitude: -78.4247,
    },
    {
        store_latitdue: -0.0829079,
        store_longitude: -78.2924,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0898361,
        store_longitude: -78.4233617,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0934144,
        store_longitude: -78.412392,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0924633,
        store_longitude: -78.4479693,
    },
    {
        store_latitdue: -0.0677628,
        store_longitude: -78.433288,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0669053,
        store_longitude: -78.4508,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1013005,
        store_longitude: -78.4251231,
    },
    {
        store_latitdue: -0.0715807,
        store_longitude: -78.4305189,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0768579,
        store_longitude: -78.4316271,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.081116706132889,
        store_longitude: -78.434279775247,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0749575,
        store_longitude: -78.4382242,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1033259,
        store_longitude: -78.4251101,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.073924,
        store_longitude: -78.439539,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076124,
        store_longitude: -78.440698,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0733148,
        store_longitude: -78.4331184,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.089164,
        store_longitude: -78.4206,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0935388,
        store_longitude: -78.4121869,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0724659,
        store_longitude: -78.4399537,
    },
    {
        store_latitdue: -0.0632938,
        store_longitude: -78.4292892,
    },
    {
        store_latitdue: -0.0548536,
        store_longitude: -78.42086,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0800976,
        store_longitude: -78.4380479,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0729272,
        store_longitude: -78.4467,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0945419,
        store_longitude: -78.4376701,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.102552,
        store_longitude: -78.420811,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0933951,
        store_longitude: -78.4111695,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0803731,
        store_longitude: -78.4188755,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0981532,
        store_longitude: -78.4265,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.122589,
        store_longitude: -78.466583,
    },
    {
        store_latitdue: -0.0544338,
        store_longitude: -78.4153376,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1054847,
        store_longitude: -78.3926333,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1086908,
        store_longitude: -78.3970454,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0671251,
        store_longitude: -78.4219,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0640701,
        store_longitude: -78.4173924,
    },
    {
        store_latitdue: -0.0950238,
        store_longitude: -78.4094214,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0747221,
        store_longitude: -78.4384172,
    },

    {
        store_latitdue: -0.1098185,
        store_longitude: -78.3942124,
    },
    {
        store_latitdue: -0.0921934,
        store_longitude: -78.4330257,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.109505,
        store_longitude: -78.394455,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.068217,
        store_longitude: -78.451822,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.068091,
        store_longitude: -78.451946,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0914314,
        store_longitude: -78.4162277,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0578843,
        store_longitude: -78.4305342,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.073998,
        store_longitude: -78.430914,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10112666666667,
        store_longitude: -78.431275,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1017504,
        store_longitude: -78.4317507,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0743658,
        store_longitude: -78.4169249,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0770832,
        store_longitude: -78.4170221,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0962198,
        store_longitude: -78.4347374,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0790094,
        store_longitude: -78.4246,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0775618,
        store_longitude: -78.4263094,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0919346,
        store_longitude: -78.4362708,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.103282,
        store_longitude: -78.425163,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0611653,
        store_longitude: -78.4133994,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.060958,
        store_longitude: -78.4154778,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.063773,
        store_longitude: -78.4321334,
    },
    {
        store_latitdue: -0.0713449,
        store_longitude: -78.4154544,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1005435,
        store_longitude: -78.42317,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0642507,
        store_longitude: -78.4326959,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.060693,
        store_longitude: -78.45243,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0739772,
        store_longitude: -78.4309965,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0679821,
        store_longitude: -78.4519157,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.05481,
        store_longitude: -78.4241,
    },
    {
        store_latitdue: -0.064091,
        store_longitude: -78.433186,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.084697076,
        store_longitude: -78.42874963,
    },
    {
        store_latitdue: -0.0735468,
        store_longitude: -78.4392765,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09042693,
        store_longitude: -78.4351501,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.056891844,
        store_longitude: -78.43425897,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1043948,
        store_longitude: -78.448286,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0704421,
        store_longitude: -78.4337,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0984603,
        store_longitude: -78.426227,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.064035,
        store_longitude: -78.4368652,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0758719,
        store_longitude: -78.4351,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0839606,
        store_longitude: -78.4309403,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.102224,
        store_longitude: -78.431886,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0769996,
        store_longitude: -78.4269088,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0547485,
        store_longitude: -78.4241683,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0840839,
        store_longitude: -78.4307867,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0694492,
        store_longitude: -78.4227395,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0634887,
        store_longitude: -78.4209927,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0639968,
        store_longitude: -78.4203929,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100608,
        store_longitude: -78.421621,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0952836,
        store_longitude: -78.427,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0759196,
        store_longitude: -78.4394083,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0924308,
        store_longitude: -78.4381138,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0918385,
        store_longitude: -78.4366052,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099867,
        store_longitude: -78.4228637,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.064132,
        store_longitude: -78.4333279,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0858792,
        store_longitude: -78.4286575,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0890332,
        store_longitude: -78.4278274,
    },
    {
        store_latitdue: -0.088837,
        store_longitude: -78.415688,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0687765,
        store_longitude: -78.4513283,
    },
    {
        store_latitdue: -0.0833852,
        store_longitude: -78.4347,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.052324,
        store_longitude: -78.452352,
    },
    {
        store_latitdue: -0.073146,
        store_longitude: -78.416657,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0874777,
        store_longitude: -78.4190678,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10541393,
        store_longitude: -78.4258009,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0527,
        store_longitude: -78.424,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.072394,
        store_longitude: -78.43058,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0609435,
        store_longitude: -78.4303875,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.277397,
        store_longitude: -78.5286,
    },
    {
        store_latitdue: -0.0637685,
        store_longitude: -78.4213,
    },
    {
        store_latitdue: -0.0638019,
        store_longitude: -78.4316286,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0608615,
        store_longitude: -78.4152292,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095586,
        store_longitude: -78.424913,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0802202,
        store_longitude: -78.4118755,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.108242,
        store_longitude: -78.2949,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.134333,
        store_longitude: -78.443688,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.2193138,
        store_longitude: -78.3390462,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.058716,
        store_longitude: -78.422754,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0942068,
        store_longitude: -78.431843,
    },
    {
        store_latitdue: -0.0947684,
        store_longitude: -78.431332,
    },
    {
        store_latitdue: -0.099937,
        store_longitude: -78.424373,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0807246,
        store_longitude: -78.4225823,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092696,
        store_longitude: -78.441395,
    },
    {
        store_latitdue: -0.102491,
        store_longitude: -78.4322984,
    },
    {
        store_latitdue: -0.0905352,
        store_longitude: -78.4184543,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0914095,
        store_longitude: -78.4342662,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.079777,
        store_longitude: -78.4179412,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0933769,
        store_longitude: -78.4368727,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099028,
        store_longitude: -78.43661,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1016976,
        store_longitude: -78.4243343,
    },
    {
        store_latitdue: -0.0767964,
        store_longitude: -78.4171023,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0922651,
        store_longitude: -78.4243566,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0587423,
        store_longitude: -78.4219937,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.098229,
        store_longitude: -78.425124,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0914737,
        store_longitude: -78.4305416,
    },
    {
        store_latitdue: -0.096436,
        store_longitude: -78.43946,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0762584,
        store_longitude: -78.4210048,
    },
    {
        store_latitdue: -0.1001707,
        store_longitude: -78.4268762,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0668768,
        store_longitude: -78.4229114,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091686666666667,
        store_longitude: -78.436465,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100825,
        store_longitude: -78.428467,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0924133,
        store_longitude: -78.4356366,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0742298,
        store_longitude: -78.4166739,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0568377,
        store_longitude: -78.4184302,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0974612,
        store_longitude: -78.4239,
    },
    {
        store_latitdue: -0.140938,
        store_longitude: -78.483,
    },
    {
        store_latitdue: -0.097798333333333,
        store_longitude: -78.431695,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095021,
        store_longitude: -78.4209644,
    },
    {
        store_latitdue: -0.0764688,
        store_longitude: -78.4260202,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1003264,
        store_longitude: -78.4075471,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0657892,
        store_longitude: -78.4528541,
    },
    {
        store_latitdue: -0.0807884,
        store_longitude: -78.4179307,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.074067,
        store_longitude: -78.424174,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.139692,
        store_longitude: -78.4989,
    },
    {
        store_latitdue: -0.06425,
        store_longitude: -78.438217,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.093596,
        store_longitude: -78.4358946,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.05886,
        store_longitude: -78.4223517,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091467,
        store_longitude: -78.425334,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0785184,
        store_longitude: -78.4174622,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0641251,
        store_longitude: -78.4330627,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0718921,
        store_longitude: -78.4253648,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0932495,
        store_longitude: -78.4348785,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09246,
        store_longitude: -78.4401867,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.115363,
        store_longitude: -78.452641,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.077151,
        store_longitude: -78.427968,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.064895,
        store_longitude: -78.451787,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0634943,
        store_longitude: -78.4234001,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.068012,
        store_longitude: -78.4146788,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1486452,
        store_longitude: -78.4906843,
    },
    {
        store_latitdue: -0.10821,
        store_longitude: -78.419799,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0704192,
        store_longitude: -78.451362,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100844,
        store_longitude: -78.420821,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0938467,
        store_longitude: -78.438672,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.075937,
        store_longitude: -78.43519,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.073518,
        store_longitude: -78.430924,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0730819,
        store_longitude: -78.4308,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0921279,
        store_longitude: -78.4406756,
    },
    {
        store_latitdue: -0.094422,
        store_longitude: -78.409426,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.073664,
        store_longitude: -78.433951,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.063772,
        store_longitude: -78.421592,
    },
    {
        store_latitdue: -0.0904348,
        store_longitude: -78.4532322,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.064144,
        store_longitude: -78.4337,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0849074,
        store_longitude: -78.4298579,
    },
    {
        store_latitdue: -0.082811832427979,
        store_longitude: -78.419952392578,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1816462,
        store_longitude: -78.367285,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.07515969,
        store_longitude: -78.4348127,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.067717,
        store_longitude: -78.4330261,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.069003,
        store_longitude: -78.433551,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.084915,
        store_longitude: -78.422609,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080269,
        store_longitude: -78.411816,
    },
    {
        store_latitdue: -0.0637313,
        store_longitude: -78.4312007,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080613,
        store_longitude: -78.435176,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080305,
        store_longitude: -78.43315,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.075263,
        store_longitude: -78.440705,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.147045,
        store_longitude: -78.4736,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090489,
        store_longitude: -78.422594,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076029,
        store_longitude: -78.440639,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.090735,
        store_longitude: -78.418349,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0922781,
        store_longitude: -78.4196,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.2168185304,
        store_longitude: -78.5033547878,
    },
    {
        store_latitdue: -0.102014,
        store_longitude: -78.4835,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085941970348358,
        store_longitude: -78.419784545898,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076246,
        store_longitude: -78.440525,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.067662,
        store_longitude: -78.424415,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0591158,
        store_longitude: -78.4145572,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0680522,
        store_longitude: -78.4146258,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0585268,
        store_longitude: -78.4159868,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.074437,
        store_longitude: -78.448734,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08218,
        store_longitude: -78.426083,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0653443,
        store_longitude: -78.4295486,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.085652,
        store_longitude: -78.421832,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0550345,
        store_longitude: -78.4213787,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0880576,
        store_longitude: -78.4215957,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0636974,
        store_longitude: -78.4215442,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1005132,
        store_longitude: -78.4234476,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.123351,
        store_longitude: -78.470811,
    },
    {
        store_latitdue: -0.09646,
        store_longitude: -78.425618,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.077145,
        store_longitude: -78.431621,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0754805,
        store_longitude: -78.4312389,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076938,
        store_longitude: -78.433249,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0739,
        store_longitude: -78.434588,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0.324877,
        store_longitude: -78.212125,
        neighborhood: 'ATUNTAQUI',
    },
    {
        store_latitdue: -0.10252,
        store_longitude: -78.425169,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0777432,
        store_longitude: -78.4172693,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080932,
        store_longitude: -78.413051,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.11614,
        store_longitude: -78.4882386,
    },
    {
        store_latitdue: -0.0823659,
        store_longitude: -78.4094645,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0512905,
        store_longitude: -78.4229257,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0892528158,
        store_longitude: -78.4206290916,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0507095,
        store_longitude: -78.4242008,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091113,
        store_longitude: -78.431365,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0936881,
        store_longitude: -78.4387937,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.081305,
        store_longitude: -78.435271,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.07444856,
        store_longitude: -78.4485736,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.07199,
        store_longitude: -78.450614,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.090788,
        store_longitude: -78.418278,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0936315,
        store_longitude: -78.43897,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0654983,
        store_longitude: -78.432965,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08294,
        store_longitude: -78.419425,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.096799,
        store_longitude: -78.4411473,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.074352,
        store_longitude: -78.434633,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0767781,
        store_longitude: -78.4170011,
    },
    {
        store_latitdue: -0.091751,
        store_longitude: -78.416431,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0677597,
        store_longitude: -78.4309145,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.073745,
        store_longitude: -78.434449,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0705206,
        store_longitude: -78.4245799,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0930993,
        store_longitude: -78.4139,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.093221,
        store_longitude: -78.412768,
    },
    {
        store_latitdue: -0.092465,
        store_longitude: -78.410012,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1020764,
        store_longitude: -78.4072263,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.073394,
        store_longitude: -78.438841,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0665199,
        store_longitude: -78.4332005,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0876331,
        store_longitude: -78.4194329,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0837629,
        store_longitude: -78.4339251,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.077288333333333,
        store_longitude: -78.426528333333,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.06791,
        store_longitude: -78.418112,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.064323,
        store_longitude: -78.4129236,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0996501,
        store_longitude: -78.4382042,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0718755,
        store_longitude: -78.4173955,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0659817,
        store_longitude: -78.4294114,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.069671,
        store_longitude: -78.4212289,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0683477,
        store_longitude: -78.4207858,
    },

    {
        store_latitdue: -0.104065,
        store_longitude: -78.424671,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.124975,
        store_longitude: -78.517767,
    },
    {
        store_latitdue: -0.0664886,
        store_longitude: -78.4199609,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.051681,
        store_longitude: -78.415414,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.066215,
        store_longitude: -78.425032,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0962174,
        store_longitude: -78.4403226,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1029189,
        store_longitude: -78.436542,
    },

    {
        store_latitdue: -0.06426715,
        store_longitude: -78.4148025,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.067807721,
        store_longitude: -78.4331031432,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1075126,
        store_longitude: -78.4453541,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.089624,
        store_longitude: -78.436505,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113663,
        store_longitude: -78.4748,
    },
    {
        store_latitdue: -0.0610889,
        store_longitude: -78.4525989,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0967888,
        store_longitude: -78.3950318,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0803334,
        store_longitude: -78.4181199,
    },
    {
        store_latitdue: -0.1006177,
        store_longitude: -78.4258754,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.098543,
        store_longitude: -78.4245851,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095465,
        store_longitude: -78.430155,
    },

    {
        store_latitdue: -0.1021825,
        store_longitude: -78.4205128,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.11294238,
        store_longitude: -78.3949124,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1059056,
        store_longitude: -78.3992151,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0716196,
        store_longitude: -78.4301265,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0576449,
        store_longitude: -78.4309,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0758297,
        store_longitude: -78.4478646,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.054873,
        store_longitude: -78.420979,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0684352,
        store_longitude: -78.4166563,
    },

    {
        store_latitdue: -0.106578,
        store_longitude: -78.4271,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.220565,
        store_longitude: -78.483928,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.298168,
        store_longitude: -78.4604747,
        neighborhood: 'SANGOLQUÍ',
    },

    {
        store_latitdue: -0.1010057,
        store_longitude: -78.4226686,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.071744,
        store_longitude: -78.438838,
    },
    {
        store_latitdue: -0.0530557,
        store_longitude: -78.4229028,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0528406,
        store_longitude: -78.4232178,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.057834,
        store_longitude: -78.4287577,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0633902,
        store_longitude: -78.4291,
    },
    {
        store_latitdue: -0.089093,
        store_longitude: -78.439954,
    },

    {
        store_latitdue: -0.123551,
        store_longitude: -78.4828,
    },
    {
        store_latitdue: -0.1008776,
        store_longitude: -78.4235049,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0978359,
        store_longitude: -78.4209,
    },

    {
        store_latitdue: -0.0936765,
        store_longitude: -78.4523513,
    },

    {
        store_latitdue: -0.0572576,
        store_longitude: -78.4207,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0676367,
        store_longitude: -78.433405,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.06785,
        store_longitude: -78.428144,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0851947,
        store_longitude: -78.4373758,
    },

    {
        store_latitdue: -0.0869667,
        store_longitude: -78.4446269,
    },

    {
        store_latitdue: -0.0979927,
        store_longitude: -78.5144804,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.158844,
        store_longitude: -78.463895,
    },

    {
        store_latitdue: -0.0602099,
        store_longitude: -78.4336,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0820896,
        store_longitude: -78.4284384,
    },

    {
        store_latitdue: -0.09053,
        store_longitude: -78.4478883,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0921623,
        store_longitude: -78.4531812,
    },
    {
        store_latitdue: -0.0891756,
        store_longitude: -78.4511,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.093211,
        store_longitude: -78.451138,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0797249,
        store_longitude: -78.440035,
    },
    {
        store_latitdue: -0.0944037,
        store_longitude: -78.452428,
    },
    {
        store_latitdue: -0.102723,
        store_longitude: -78.4515,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10005333333333,
        store_longitude: -78.437783333333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0898532,
        store_longitude: -78.4503192,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.105665,
        store_longitude: -78.43973,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094135,
        store_longitude: -78.4382,
    },
    {
        store_latitdue: -0.0956379,
        store_longitude: -78.4381703,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0742008,
        store_longitude: -78.4171725,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.103965,
        store_longitude: -78.4307,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0857371,
        store_longitude: -78.4559173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091051,
        store_longitude: -78.469365,
    },
    {
        store_latitdue: -0.0945558,
        store_longitude: -78.4525755,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.103946,
        store_longitude: -78.457409,
    },
    {
        store_latitdue: -0.0962571,
        store_longitude: -78.4549,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1028402,
        store_longitude: -78.4319484,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0930806,
        store_longitude: -78.4497355,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097711,
        store_longitude: -78.4519795,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.106383,
        store_longitude: -78.454222,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.105429,
        store_longitude: -78.449837,
    },
    {
        store_latitdue: -0.095321,
        store_longitude: -78.4507,
    },
    {
        store_latitdue: -0.0922362,
        store_longitude: -78.4530211,
    },
    {
        store_latitdue: -0.100695,
        store_longitude: -78.4435,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0856017,
        store_longitude: -78.4488729,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1040576,
        store_longitude: -78.4589773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0848858,
        store_longitude: -78.4370118,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.103043,
        store_longitude: -78.4481,
    },
    {
        store_latitdue: -0.0884033,
        store_longitude: -78.4478,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0928389,
        store_longitude: -78.4511403,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1039483,
        store_longitude: -78.4586864,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0912401,
        store_longitude: -78.4414414,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0648443,
        store_longitude: -78.4296327,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0824539,
        store_longitude: -78.4539,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.108285,
        store_longitude: -78.4555,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100589,
        store_longitude: -78.4526,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0756922,
        store_longitude: -78.4394,
    },
    {
        store_latitdue: -0.0975264,
        store_longitude: -78.4446,
    },
    {
        store_latitdue: -0.139411,
        store_longitude: -78.482484,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.093352,
        store_longitude: -78.452934,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10588,
        store_longitude: -78.4534,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0957095,
        store_longitude: -78.4566,
    },
    {
        store_latitdue: -0.098747,
        store_longitude: -78.45203,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.092236,
        store_longitude: -78.452428,
    },
    {
        store_latitdue: -0.1041387,
        store_longitude: -78.4598616,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089866,
        store_longitude: -78.4525631,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0950786,
        store_longitude: -78.4542,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1041486,
        store_longitude: -78.4571711,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10628,
        store_longitude: -78.4535,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0887231,
        store_longitude: -78.4396265,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09217,
        store_longitude: -78.4524,
    },
    {
        store_latitdue: -0.093696,
        store_longitude: -78.4523281,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -1.70323,
        store_longitude: -79.2777,
    },
    {
        store_latitdue: -0.0928977,
        store_longitude: -78.4524,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1007995,
        store_longitude: -78.4535602,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0905338,
        store_longitude: -78.4498,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092158,
        store_longitude: -78.4533,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1086557,
        store_longitude: -78.4554243,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1126747,
        store_longitude: -78.4200588,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0920964,
        store_longitude: -78.4532779,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097618,
        store_longitude: -78.4519,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.102133,
        store_longitude: -78.451439,
    },
    {
        store_latitdue: -0.0989805,
        store_longitude: -78.4519,
    },
    {
        store_latitdue: -0.104747,
        store_longitude: -78.4482,
    },
    {
        store_latitdue: -0.111229,
        store_longitude: -78.296213,
    },

    {
        store_latitdue: -0.0894582,
        store_longitude: -78.4432326,
    },
    {
        store_latitdue: -0.0999289,
        store_longitude: -78.4499,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.108567,
        store_longitude: -78.455,
    },
    {
        store_latitdue: -0.0972721,
        store_longitude: -78.4542,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0921261,
        store_longitude: -78.4535019,
    },
    {
        store_latitdue: -0.095363,
        store_longitude: -78.453504,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0883983,
        store_longitude: -78.4488,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0912407,
        store_longitude: -78.4496622,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0923985,
        store_longitude: -78.4486,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.216595,
        store_longitude: -78.404299,
    },
    {
        store_latitdue: -0.090407,
        store_longitude: -78.4583,
    },
    {
        store_latitdue: -0.0926404,
        store_longitude: -78.452,
    },
    {
        store_latitdue: -0.0881159,
        store_longitude: -78.4489,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.10359,
        store_longitude: -78.448143333333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.090725,
        store_longitude: -78.4528,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1046267,
        store_longitude: -78.4690281,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.100661,
        store_longitude: -78.4554,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.092542,
        store_longitude: -78.4424,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0966425,
        store_longitude: -78.4589,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0883618,
        store_longitude: -78.4394611,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0868101,
        store_longitude: -78.4496,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100582,
        store_longitude: -78.452101,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0976206,
        store_longitude: -78.4512292,
    },
    {
        store_latitdue: -0.0960714,
        store_longitude: -78.4532,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0966583,
        store_longitude: -78.4509,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09518438,
        store_longitude: -78.4543075,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0938235,
        store_longitude: -78.4546373,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1063257,
        store_longitude: -78.4539925,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101598,
        store_longitude: -78.4518,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0940005,
        store_longitude: -78.4555,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.087241666666667,
        store_longitude: -78.455946666667,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.092176,
        store_longitude: -78.453089,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0924906,
        store_longitude: -78.4495676,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0919689,
        store_longitude: -78.4484,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0952764,
        store_longitude: -78.4671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09685,
        store_longitude: -78.444374,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.100337,
        store_longitude: -78.4536,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0939391,
        store_longitude: -78.4473,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0936852,
        store_longitude: -78.4531,
    },
    {
        store_latitdue: -0.0890558,
        store_longitude: -78.4483049,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0961999,
        store_longitude: -78.4497627,
    },
    {
        store_latitdue: -0.09756932,
        store_longitude: -78.4527609,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0985688,
        store_longitude: -78.4511338,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0848436,
        store_longitude: -78.4494316,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0927614,
        store_longitude: -78.4499881,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0914024,
        store_longitude: -78.4444,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0934449,
        store_longitude: -78.4480783,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0931973,
        store_longitude: -78.4516918,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.115388,
        store_longitude: -78.449804,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.10527,
        store_longitude: -78.4483,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1054238,
        store_longitude: -78.4552913,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092654,
        store_longitude: -78.442463,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0920855,
        store_longitude: -78.4548,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091467,
        store_longitude: -78.524444,
    },
    {
        store_latitdue: -0.0940164,
        store_longitude: -78.4496768,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0972066,
        store_longitude: -78.4533977,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0924314,
        store_longitude: -78.4479059,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0909604,
        store_longitude: -78.4496407,
    },
    {
        store_latitdue: -0.0976375,
        store_longitude: -78.4516545,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091836,
        store_longitude: -78.452697,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0923323,
        store_longitude: -78.4515094,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.00882,
        store_longitude: -78.445828,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0954103,
        store_longitude: -78.4575,
    },
    {
        store_latitdue: -0.096212,
        store_longitude: -78.451326,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1453954,
        store_longitude: -78.4555284,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0923578,
        store_longitude: -78.4481702,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0918859,
        store_longitude: -78.4527,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.090686,
        store_longitude: -78.452764,
    },
    {
        store_latitdue: -0.096043,
        store_longitude: -78.449583,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0946687,
        store_longitude: -78.4494935,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.105491,
        store_longitude: -78.4484,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0951789,
        store_longitude: -78.4533424,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1074073,
        store_longitude: -78.4475811,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1038739,
        store_longitude: -78.4327203,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091459,
        store_longitude: -78.453227,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0924953,
        store_longitude: -78.460458,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0887707,
        store_longitude: -78.4500763,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.108482,
        store_longitude: -78.45501,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.088727,
        store_longitude: -78.448254,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0996642,
        store_longitude: -78.4458589,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1018119,
        store_longitude: -78.4624175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0906018,
        store_longitude: -78.4442285,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0971528,
        store_longitude: -78.4445252,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0967171,
        store_longitude: -78.4605,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0959292,
        store_longitude: -78.4538837,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0968542,
        store_longitude: -78.4410866,
    },
    {
        store_latitdue: -0.0968641,
        store_longitude: -78.4518996,
    },
    {
        store_latitdue: -0.098575,
        store_longitude: -78.443475,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.090678,
        store_longitude: -78.4533793,
    },
    {
        store_latitdue: -0.1992724,
        store_longitude: -78.4281552,
    },
    {
        store_latitdue: -0.107425,
        store_longitude: -78.447489,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.003379,
        store_longitude: -78.5141,
    },
    {
        store_latitdue: -0.0973572,
        store_longitude: -78.4502709,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09330547,
        store_longitude: -78.4519122,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.099539,
        store_longitude: -78.452892,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0975379,
        store_longitude: -78.4540748,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0882791,
        store_longitude: -78.44574,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0889625,
        store_longitude: -78.4460672,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092104,
        store_longitude: -78.453572,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.091174,
        store_longitude: -78.4411918,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.104246,
        store_longitude: -78.453,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092175,
        store_longitude: -78.449554,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0923418,
        store_longitude: -78.4517,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0888575,
        store_longitude: -78.4464278,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.096733,
        store_longitude: -78.44967,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1357267,
        store_longitude: -78.4435914,
    },
    {
        store_latitdue: -0.0928724,
        store_longitude: -78.4525691,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0961857,
        store_longitude: -78.4521525,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1021393,
        store_longitude: -78.452899,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1029793,
        store_longitude: -78.4481124,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0957864,
        store_longitude: -78.4681022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0803169,
        store_longitude: -78.4361693,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092427,
        store_longitude: -78.4492239,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092812,
        store_longitude: -78.4495342,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.092198,
        store_longitude: -78.453179,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097488,
        store_longitude: -78.44204,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097625,
        store_longitude: -78.444939,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0998929,
        store_longitude: -78.4534024,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1075,
        store_longitude: -78.448551666667,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08894,
        store_longitude: -78.4485159,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.098637,
        store_longitude: -78.4546958,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0935397,
        store_longitude: -78.4544626,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0863934,
        store_longitude: -78.4500396,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1090375,
        store_longitude: -78.4546026,
    },
    {
        store_latitdue: -0.095832,
        store_longitude: -78.453584,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0952625,
        store_longitude: -78.4525943,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0836166,
        store_longitude: -78.4435486,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0867433,
        store_longitude: -78.4446,
    },
    {
        store_latitdue: -0.093853,
        store_longitude: -78.451717,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095886,
        store_longitude: -78.453141,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1019523,
        store_longitude: -78.45069,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0967919,
        store_longitude: -78.4539713,
    },
    {
        store_latitdue: -0.107658,
        store_longitude: -78.455434,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0939464,
        store_longitude: -78.4497953,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0961647,
        store_longitude: -78.4513616,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0917335,
        store_longitude: -78.4495991,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.097581,
        store_longitude: -78.45253,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1035748,
        store_longitude: -78.448195,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0975233,
        store_longitude: -78.4411134,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0962702,
        store_longitude: -78.4494218,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09615983068943,
        store_longitude: -78.451499938965,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.096074116666667,
        store_longitude: -78.451787516667,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.096159,
        store_longitude: -78.451452,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.071436,
        store_longitude: -78.427007,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0906069,
        store_longitude: -78.4186,
    },
    {
        store_latitdue: -0.1010438,
        store_longitude: -78.4227867,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.101203,
        store_longitude: -78.4252,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0640415,
        store_longitude: -78.4333031,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.110405,
        store_longitude: -78.419581,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0743754,
        store_longitude: -78.4167,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.065875,
        store_longitude: -78.4255,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0869319,
        store_longitude: -78.4168,
    },
    {
        store_latitdue: -0.0894314,
        store_longitude: -78.4207629,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0931605,
        store_longitude: -78.4317461,
    },
    {
        store_latitdue: -0.0961605,
        store_longitude: -78.4523541,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.111715,
        store_longitude: -78.46366,
    },
    {
        store_latitdue: -0.0972403,
        store_longitude: -78.454703,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0974526,
        store_longitude: -78.4507143,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0937957,
        store_longitude: -78.4518633,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.202198,
        store_longitude: -78.4917,
    },
    {
        store_latitdue: -0.0972724636,
        store_longitude: -78.4435230493,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.115168,
        store_longitude: -78.497463,
    },

    {
        store_latitdue: -0.103871,
        store_longitude: -78.4574,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.079551,
        store_longitude: -78.4415,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0762706,
        store_longitude: -78.4405281,
    },

    {
        store_latitdue: -0.1160969908,
        store_longitude: -78.4365640953,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0884724,
        store_longitude: -78.4266,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0989016,
        store_longitude: -78.4512214,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09786896,
        store_longitude: -78.4511108,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0663845,
        store_longitude: -78.3651137,
    },
    {
        store_latitdue: -0.0684394,
        store_longitude: -78.4306,
    },
    {
        store_latitdue: -0.2065832,
        store_longitude: -78.4965456,
    },
    {
        store_latitdue: -0.092434,
        store_longitude: -78.4422224,
    },

    {
        store_latitdue: -0.089272,
        store_longitude: -78.451683,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0989928,
        store_longitude: -78.4521342,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1024737,
        store_longitude: -78.4514281,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.2635741,
        store_longitude: -78.4794382,
        neighborhood: 'CONOCOTO',
    },

    {
        store_latitdue: -0.1073607,
        store_longitude: -78.4473094,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0940907,
        store_longitude: -78.4529,
    },

    {
        store_latitdue: -0.099236,
        store_longitude: -78.4538829,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0447874,
        store_longitude: -78.2854426,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0491782,
        store_longitude: -78.28519,
    },
    {
        store_latitdue: -0.0492844,
        store_longitude: -78.2852053,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0492098,
        store_longitude: -78.2851722,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0440943,
        store_longitude: -78.2851,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0442993,
        store_longitude: -78.2851206,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.04488542,
        store_longitude: -78.2862775,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: 0.048432,
        store_longitude: -78.286612,
    },
    {
        store_latitdue: -0.0504285,
        store_longitude: -78.2793976,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0593789,
        store_longitude: -78.3452,
    },
    {
        store_latitdue: -0.0449831,
        store_longitude: -78.287411,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0444472,
        store_longitude: -78.2856,
    },
    {
        store_latitdue: -0.0490948,
        store_longitude: -78.285747,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0443966,
        store_longitude: -78.2852232,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0657772,
        store_longitude: -78.2784918,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0515665,
        store_longitude: -78.2787971,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0520034,
        store_longitude: -78.278215,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0487939,
        store_longitude: -78.285494,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },

    {
        store_latitdue: -0.050112,
        store_longitude: -78.2856013,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.053105,
        store_longitude: -78.284981,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.064646,
        store_longitude: -78.288719,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.053271,
        store_longitude: -78.284759,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.049979,
        store_longitude: -78.2836,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.049107,
        store_longitude: -78.2822547,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.050315,
        store_longitude: -78.285564,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.053664,
        store_longitude: -78.274587,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.050225,
        store_longitude: -78.285651666667,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.052718333333333,
        store_longitude: -78.285186666667,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0536845,
        store_longitude: -78.2843938,
        neighborhood: 'GUAYLLABAMBA',
    },

    {
        store_latitdue: 0.0473959,
        store_longitude: -78.140958,
    },
    {
        store_latitdue: -3.03768,
        store_longitude: -78.1460867,
    },
    {
        store_latitdue: 0.0401658,
        store_longitude: -78.1412654,
    },
    {
        store_latitdue: -0.0605362,
        store_longitude: -78.1695,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0431245,
        store_longitude: -78.1478,
    },
    {
        store_latitdue: 0.0376702,
        store_longitude: -78.1409,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0416943,
        store_longitude: -78.14622,
    },
    {
        store_latitdue: -0.0660376,
        store_longitude: -78.1664714,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: -0.0278001,
        store_longitude: -78.1711,
    },
    {
        store_latitdue: 0.046291,
        store_longitude: -78.1433,
    },
    {
        store_latitdue: 0.0481227,
        store_longitude: -78.1413786,
    },
    {
        store_latitdue: 0.0339866,
        store_longitude: -78.14,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0263736,
        store_longitude: -78.1462,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0262973,
        store_longitude: -78.14638,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0169089,
        store_longitude: -78.1426458,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0660549,
        store_longitude: -78.1664331,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.038953,
        store_longitude: -78.1376775,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0385258,
        store_longitude: -78.136167,
    },
    {
        store_latitdue: -0.027834,
        store_longitude: -78.170943,
    },
    {
        store_latitdue: 0.0407024,
        store_longitude: -78.1376,
    },
    {
        store_latitdue: 0.0434201,
        store_longitude: -78.144086,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0390755,
        store_longitude: -78.1388,
    },
    {
        store_latitdue: 0.0431578,
        store_longitude: -78.1403,
    },
    {
        store_latitdue: 0.0466918,
        store_longitude: -78.1393,
    },
    {
        store_latitdue: 0.0451362,
        store_longitude: -78.1377,
    },
    {
        store_latitdue: 0.0472881,
        store_longitude: -78.1376,
    },
    {
        store_latitdue: 0.0393728,
        store_longitude: -78.1429,
    },
    {
        store_latitdue: 0.0361015,
        store_longitude: -78.146458,
    },
    {
        store_latitdue: 0.0448921,
        store_longitude: -78.1437123,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.01597226,
        store_longitude: -78.148132,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0406053,
        store_longitude: -78.15,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.044685,
        store_longitude: -78.140399,
    },
    {
        store_latitdue: 0.034143,
        store_longitude: -78.1509332,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.036171,
        store_longitude: -78.141,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.040816,
        store_longitude: -78.1555,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0492009,
        store_longitude: -78.135833,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0491706,
        store_longitude: -78.136308,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0758767,
        store_longitude: -78.1208739,
    },
    {
        store_latitdue: 0.0168209,
        store_longitude: -78.1578968,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0489366,
        store_longitude: -78.1386046,
    },
    {
        store_latitdue: 0.0468055,
        store_longitude: -78.1444362,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.02544632,
        store_longitude: -78.148653,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0155783,
        store_longitude: -78.1585,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0135869,
        store_longitude: -78.1488,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0125168,
        store_longitude: -78.1508,
    },
    {
        store_latitdue: 0.01255,
        store_longitude: -78.1509498,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0902372,
        store_longitude: -78.151358,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.340626,
        store_longitude: -78.1464,
    },
    {
        store_latitdue: -0.0012519,
        store_longitude: -78.1764,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: 0.0138875,
        store_longitude: -78.1495,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0397542,
        store_longitude: -78.1426,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0314335,
        store_longitude: -78.1436,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.081937,
        store_longitude: -78.1157,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0264822,
        store_longitude: -78.1433,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0331045,
        store_longitude: -78.1480783,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0440632,
        store_longitude: -78.1575,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.042215,
        store_longitude: -78.1461,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0159585,
        store_longitude: -78.144,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.139876,
        store_longitude: -78.0771,
    },
    {
        store_latitdue: 0.0429372,
        store_longitude: -78.1386,
    },
    {
        store_latitdue: 0.0452373,
        store_longitude: -78.1421,
    },
    {
        store_latitdue: 0.047531,
        store_longitude: -78.1367,
    },
    {
        store_latitdue: 0.046753,
        store_longitude: -78.225696,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0086554,
        store_longitude: -78.1796,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0425094,
        store_longitude: -78.1437845,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.011046,
        store_longitude: -78.150979,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.209806,
        store_longitude: -78.2498,
    },
    {
        store_latitdue: 0.0455176,
        store_longitude: -78.1365,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0403401,
        store_longitude: -78.150212,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0187488,
        store_longitude: -78.1372,
    },
    {
        store_latitdue: 0.0425593,
        store_longitude: -78.1462138,
    },
    {
        store_latitdue: 0.0367325,
        store_longitude: -78.1425,
    },
    {
        store_latitdue: 0.036665,
        store_longitude: -78.1382966,
    },
    {
        store_latitdue: 0.0257787,
        store_longitude: -78.1445,
    },
    {
        store_latitdue: 0.041379,
        store_longitude: -78.1448,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0451219,
        store_longitude: -78.1414821,
    },
    {
        store_latitdue: 0.0448913,
        store_longitude: -78.1429,
    },
    {
        store_latitdue: 0.0310582,
        store_longitude: -78.1347,
    },
    {
        store_latitdue: 0.0470531,
        store_longitude: -78.1416589,
    },
    {
        store_latitdue: 0.0240925,
        store_longitude: -78.1452,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0240517,
        store_longitude: -78.1449433,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0351667,
        store_longitude: -78.1483,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0656451,
        store_longitude: -78.1358,
    },
    {
        store_latitdue: 0.0473692,
        store_longitude: -78.1374877,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0288348,
        store_longitude: -78.1428,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.016392,
        store_longitude: -78.14312,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0430192,
        store_longitude: -78.1432,
    },
    {
        store_latitdue: 0.0434052,
        store_longitude: -78.1462,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0715364,
        store_longitude: -78.1334,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0401564,
        store_longitude: -78.1444,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.2259,
        store_longitude: -79.194305,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0457476,
        store_longitude: -78.1406,
    },

    {
        store_latitdue: 0.0273994,
        store_longitude: -78.1517,
    },
    {
        store_latitdue: 0.0434739,
        store_longitude: -78.143336,
    },
    {
        store_latitdue: 0.0187343,
        store_longitude: -78.161,
    },
    {
        store_latitdue: 0.0180823,
        store_longitude: -78.1619,
    },
    {
        store_latitdue: 0.037153,
        store_longitude: -78.150084,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0370807,
        store_longitude: -78.1493,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0427337,
        store_longitude: -78.1469,
    },
    {
        store_latitdue: 0.0354131,
        store_longitude: -78.1450762,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0429483,
        store_longitude: -78.1402,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.042215,
        store_longitude: -78.1461,
    },
    {
        store_latitdue: 0.039767,
        store_longitude: -78.1448,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0404097,
        store_longitude: -78.1494,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0634924,
        store_longitude: -78.1348,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0399933,
        store_longitude: -78.1443,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0405717,
        store_longitude: -78.1498,
    },
    {
        store_latitdue: 0.0229085,
        store_longitude: -78.1403,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0244865,
        store_longitude: -78.1466054,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0190808,
        store_longitude: -78.1376,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0190165,
        store_longitude: -78.1376299,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0250315,
        store_longitude: -78.142,
    },
    {
        store_latitdue: 0.0136337,
        store_longitude: -78.1431,
    },
    {
        store_latitdue: 0.132956,
        store_longitude: -78.1851,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.137401,
        store_longitude: -78.191,
    },
    {
        store_latitdue: 0.0441074,
        store_longitude: -78.1422,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0424094,
        store_longitude: -78.14164,
    },
    {
        store_latitdue: 0.0406396,
        store_longitude: -78.1433881,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0456141,
        store_longitude: -78.1435,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0514528,
        store_longitude: -78.1414,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0166502,
        store_longitude: -78.1333128,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0440219,
        store_longitude: -78.1438,
    },
    {
        store_latitdue: 0.0284157,
        store_longitude: -78.150606,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0460502,
        store_longitude: -78.145693,
    },
    {
        store_latitdue: 0.0301039,
        store_longitude: -78.1529934,
    },
    {
        store_latitdue: 0.0393088,
        store_longitude: -78.1512,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0472775,
        store_longitude: -78.1394,
    },
    {
        store_latitdue: 0.0414692,
        store_longitude: -78.1479178,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0251812,
        store_longitude: -78.1421,
    },
    {
        store_latitdue: 0.0313069,
        store_longitude: -78.1390066,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.040243,
        store_longitude: -78.1416,
    },
    {
        store_latitdue: 0.046947,
        store_longitude: -78.1395,
    },
    {
        store_latitdue: 0.0493625,
        store_longitude: -78.1458394,
    },
    {
        store_latitdue: 0.0404054,
        store_longitude: -78.1457714,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0276368,
        store_longitude: -78.1711,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: 0.1395699,
        store_longitude: -78.0775079,
    },
    {
        store_latitdue: 0.0406916,
        store_longitude: -78.1473968,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.045555,
        store_longitude: -78.1364,
    },
    {
        store_latitdue: 0.0331641,
        store_longitude: -78.1455,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0318811,
        store_longitude: -78.1451,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.02751,
        store_longitude: -78.1514416,
    },
    {
        store_latitdue: 0.0451211,
        store_longitude: -78.1414902,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0205227,
        store_longitude: -78.1385727,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0285404,
        store_longitude: -78.1579,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0355036,
        store_longitude: -78.1435956,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0480025,
        store_longitude: -78.1415687,
    },
    {
        store_latitdue: 0.0438415,
        store_longitude: -78.1413,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.039364,
        store_longitude: -78.1453241,
    },
    {
        store_latitdue: 0.03610827,
        store_longitude: -78.1396102,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.01738,
        store_longitude: -78.1582,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0322699,
        store_longitude: -78.153,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0470316,
        store_longitude: -78.1387,
    },
    {
        store_latitdue: 0.0464347,
        store_longitude: -78.1385,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0438411,
        store_longitude: -78.1638,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.038095,
        store_longitude: -78.1409,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0104322,
        store_longitude: -78.1225882,
    },
    {
        store_latitdue: 0.0402846,
        store_longitude: -78.1507,
    },
    {
        store_latitdue: -3.60489,
        store_longitude: -79.212,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.04065291,
        store_longitude: -78.1465361,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0453406,
        store_longitude: -78.1398964,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0311704,
        store_longitude: -78.1539,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0344197,
        store_longitude: -78.1715967,
    },
    {
        store_latitdue: 0.0506219,
        store_longitude: -78.1419,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0191473,
        store_longitude: -78.1377,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0113459,
        store_longitude: -78.1511761,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0159025,
        store_longitude: -78.1437412,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0362495,
        store_longitude: -78.1365,
    },
    {
        store_latitdue: 0.04222,
        store_longitude: -78.14046,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0690883,
        store_longitude: -78.1346,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.043865,
        store_longitude: -78.1469,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0322433,
        store_longitude: -78.1459,
    },
    {
        store_latitdue: 0.0464486,
        store_longitude: -78.1448523,
    },
    {
        store_latitdue: 0.0411877,
        store_longitude: -78.146704,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0212655,
        store_longitude: -78.1472,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0330446,
        store_longitude: -78.1456236,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0482395,
        store_longitude: -78.1385,
    },
    {
        store_latitdue: 0.04672,
        store_longitude: -78.1389484,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0431411,
        store_longitude: -78.1437,
    },
    {
        store_latitdue: 0.0448129,
        store_longitude: -78.1368188,
    },

    {
        store_latitdue: 0.045314,
        store_longitude: -78.1366446,
    },
    {
        store_latitdue: -0.0086312,
        store_longitude: -78.1796,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0409048,
        store_longitude: -78.1448351,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.04322,
        store_longitude: -78.1438796,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0266944,
        store_longitude: -78.1434201,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.1295485,
        store_longitude: -78.1875239,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0175573,
        store_longitude: -78.135433,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0274448,
        store_longitude: -78.1549432,
    },
    {
        store_latitdue: 0.0553798,
        store_longitude: -78.1432167,
    },
    {
        store_latitdue: 0.044022,
        store_longitude: -78.1417,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.033136,
        store_longitude: -78.1416,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0245845,
        store_longitude: -78.1469667,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.04618,
        store_longitude: -78.136265,
    },
    {
        store_latitdue: 0.0493833,
        store_longitude: -78.1392513,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0469086,
        store_longitude: -78.142254,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.159342,
        store_longitude: -78.4773,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0355059,
        store_longitude: -78.1478516,
    },
    {
        store_latitdue: 0.0471523,
        store_longitude: -78.1444705,
    },
    {
        store_latitdue: 0.0242518,
        store_longitude: -78.1477,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0430028,
        store_longitude: -78.146175,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0406057,
        store_longitude: -78.1534,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.0616963,
        store_longitude: -78.1691,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: 0.0471642,
        store_longitude: -78.1401,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.041772641,
        store_longitude: -78.1440505,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.043783,
        store_longitude: -78.143958,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0314186,
        store_longitude: -78.1463201,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.0475431,
        store_longitude: -78.1380983,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.0431506,
        store_longitude: -78.147578,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.234055,
        store_longitude: -78.2614,
    },

    {
        store_latitdue: 0.0112732,
        store_longitude: -78.1511,
    },
    {
        store_latitdue: 0.0384927,
        store_longitude: -78.1432,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.0473374,
        store_longitude: -78.2158473,
        neighborhood: 'TABACUNDO',
    },

    {
        store_latitdue: 0.0265951,
        store_longitude: -78.1502,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.020563,
        store_longitude: -78.147788,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.0330616,
        store_longitude: -78.1481,
    },
    {
        store_latitdue: 0.0171196,
        store_longitude: -78.1428,
    },

    {
        store_latitdue: 0.048949,
        store_longitude: -78.142787,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.0275991,
        store_longitude: -78.151868,
    },
    {
        store_latitdue: 0.0277542,
        store_longitude: -78.1582808,
    },
    {
        store_latitdue: 0.032517186,
        store_longitude: -78.1499362,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.04309,
        store_longitude: -78.1396,
    },

    {
        store_latitdue: 0.027242,
        store_longitude: -78.1543,
    },

    {
        store_latitdue: 0.0262196,
        store_longitude: -78.1497,
    },
    {
        store_latitdue: -0.0618636,
        store_longitude: -78.1688,
    },
    {
        store_latitdue: -0.0624767,
        store_longitude: -78.1656,
    },

    {
        store_latitdue: -0.008549,
        store_longitude: -78.179529,
    },

    {
        store_latitdue: 0.0136619,
        store_longitude: -78.1488,
    },
    {
        store_latitdue: 0.0343279,
        store_longitude: -78.1452,
    },
    {
        store_latitdue: 0.0312904,
        store_longitude: -78.1404433,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.0281333,
        store_longitude: -78.1445629,
    },

    {
        store_latitdue: 0.0718464,
        store_longitude: -78.1332,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0.04,
        store_longitude: -78.1487,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0378588,
        store_longitude: -78.1484,
    },

    {
        store_latitdue: 0.025429,
        store_longitude: -78.142348,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.08319136,
        store_longitude: -78.1516206,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: 0.0425982,
        store_longitude: -78.4010947,
        neighborhood: 'PUÉLLARO',
    },

    {
        store_latitdue: 0.0283618,
        store_longitude: -78.1462,
    },
    {
        store_latitdue: 0.04875046,
        store_longitude: -78.1419982,
    },
    {
        store_latitdue: 0.0453904,
        store_longitude: -78.1354,
    },

    {
        store_latitdue: -0.033101,
        store_longitude: -78.171702,
        neighborhood: 'CANGAHUA',
    },

    {
        store_latitdue: 0.0402829,
        store_longitude: -78.1417697,
    },

    {
        store_latitdue: 0.0369419,
        store_longitude: -78.1431367,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0146962,
        store_longitude: -78.1575139,
    },
    {
        store_latitdue: 0.0180502,
        store_longitude: -78.1591713,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0164162,
        store_longitude: -78.1609,
    },
    {
        store_latitdue: 0.0391631,
        store_longitude: -78.143,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.040353,
        store_longitude: -78.1409,
    },
    {
        store_latitdue: 0.037556,
        store_longitude: -78.1429,
    },
    {
        store_latitdue: 0.0006456,
        store_longitude: -78.2228,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: -0.0642733,
        store_longitude: -78.3580904,
    },
    {
        store_latitdue: -0.057343,
        store_longitude: -78.331481,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0584955,
        store_longitude: -78.347299,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.05685812,
        store_longitude: -78.3487965,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0530585,
        store_longitude: -78.3437,
    },
    {
        store_latitdue: -0.0604933,
        store_longitude: -78.3451564,
    },
    {
        store_latitdue: -0.05525,
        store_longitude: -78.3296,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0647762,
        store_longitude: -78.360091,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0645321,
        store_longitude: -78.3525788,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.05367871,
        store_longitude: -78.3563232,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0544516,
        store_longitude: -78.3564684,
    },
    {
        store_latitdue: -0.0569575,
        store_longitude: -78.3609914,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.060247,
        store_longitude: -78.346788,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0649402,
        store_longitude: -78.3608486,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0521218,
        store_longitude: -78.3489,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0455696,
        store_longitude: -78.3056122,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0571777,
        store_longitude: -78.3448656,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.054392,
        store_longitude: -78.3530807,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: 0.109845,
        store_longitude: -78.423,
        neighborhood: 'PERUCHO',
    },

    {
        store_latitdue: -0.0541885,
        store_longitude: -78.3532,
    },
    {
        store_latitdue: -0.0641787,
        store_longitude: -78.3579,
    },
    {
        store_latitdue: -0.0531195,
        store_longitude: -78.3437452,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0646328,
        store_longitude: -78.3591482,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.108203,
        store_longitude: -78.2978,
    },
    {
        store_latitdue: -0.0639871,
        store_longitude: -78.3566663,
    },
    {
        store_latitdue: -0.064902,
        store_longitude: -78.360564,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0640356,
        store_longitude: -78.357,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0638908,
        store_longitude: -78.3611832,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.063634,
        store_longitude: -78.360853,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0667683,
        store_longitude: -78.3529,
    },
    {
        store_latitdue: -0.0639042,
        store_longitude: -78.3518182,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0496662,
        store_longitude: -78.3049498,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0564876,
        store_longitude: -78.3446593,
    },
    {
        store_latitdue: -0.0581443,
        store_longitude: -78.3455891,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.057146666666667,
        store_longitude: -78.359695,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0612259,
        store_longitude: -78.3445,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0637231,
        store_longitude: -78.3607017,
    },
    {
        store_latitdue: -0.0633218,
        store_longitude: -78.3544286,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0833102,
        store_longitude: -78.4184,
    },
    {
        store_latitdue: -0.0649228,
        store_longitude: -78.3636989,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.064925,
        store_longitude: -78.36074,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0628247,
        store_longitude: -78.3523323,
    },
    {
        store_latitdue: -0.0565486,
        store_longitude: -78.3446338,
    },
    {
        store_latitdue: -0.0590313,
        store_longitude: -78.3451541,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0640991,
        store_longitude: -78.3611,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0633056,
        store_longitude: -78.3540525,
    },
    {
        store_latitdue: -0.0582716,
        store_longitude: -78.3454,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.054888,
        store_longitude: -78.35629,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0572522,
        store_longitude: -78.3451,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: 0.0569744,
        store_longitude: -78.3396897,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: -0.0637141,
        store_longitude: -78.3387,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.064841666666667,
        store_longitude: -78.3604,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.070411,
        store_longitude: -78.363863,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0628202,
        store_longitude: -78.3516166,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.057978,
        store_longitude: -78.34526,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0560429,
        store_longitude: -78.3436,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0500979,
        store_longitude: -78.30461,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0456636,
        store_longitude: -78.3050657,
    },
    {
        store_latitdue: -0.0450395,
        store_longitude: -78.3059769,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0648419,
        store_longitude: -78.3641,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0695602,
        store_longitude: -78.3537197,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0538008,
        store_longitude: -78.3439,
    },
    {
        store_latitdue: -0.0647429,
        store_longitude: -78.3642736,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.05276,
        store_longitude: -78.358655,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0649181,
        store_longitude: -78.3441388,
    },
    {
        store_latitdue: -0.05924931,
        store_longitude: -78.345273,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0563055,
        store_longitude: -78.3515856,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.1117031,
        store_longitude: -78.2974853,
    },
    {
        store_latitdue: -0.058860395103693,
        store_longitude: -78.344673156738,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.056959,
        store_longitude: -78.352206,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0635185,
        store_longitude: -78.3626398,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.063135,
        store_longitude: -78.352952,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0590834,
        store_longitude: -78.3451829,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0542602,
        store_longitude: -78.3538123,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.049048,
        store_longitude: -78.287781,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.064713,
        store_longitude: -78.363509,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0604779,
        store_longitude: -78.3558,
    },
    {
        store_latitdue: -0.070946,
        store_longitude: -78.356441,
    },
    {
        store_latitdue: -0.0539432,
        store_longitude: -78.3438573,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.06061,
        store_longitude: -78.3469,
    },
    {
        store_latitdue: -0.071264,
        store_longitude: -78.346057,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.174321,
        store_longitude: -78.42498,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.05607,
        store_longitude: -78.3615,
    },
    {
        store_latitdue: -0.0650974,
        store_longitude: -78.3649847,
    },
    {
        store_latitdue: -0.0542993,
        store_longitude: -78.3636365,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.053356,
        store_longitude: -78.359113,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.042891,
        store_longitude: -78.34334,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.042554,
        store_longitude: -78.342376,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0658168,
        store_longitude: -78.3565297,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.053804,
        store_longitude: -78.3542256,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.055064,
        store_longitude: -78.35291,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0650046,
        store_longitude: -78.3611204,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0637156,
        store_longitude: -78.3613777,
    },
    {
        store_latitdue: -0.062549,
        store_longitude: -78.432849,
    },
    {
        store_latitdue: -0.06421,
        store_longitude: -78.357844,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.107991,
        store_longitude: -78.298358,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0598647,
        store_longitude: -78.3443305,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.05420471,
        store_longitude: -78.363655,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0633358,
        store_longitude: -78.3540611,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.056462,
        store_longitude: -78.344541,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.1749484,
        store_longitude: -78.4249379,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1022753,
        store_longitude: -78.3119316,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.08282,
        store_longitude: -78.3429783,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.062984,
        store_longitude: -78.352737,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0588771,
        store_longitude: -78.3461411,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0526078,
        store_longitude: -78.343544,
    },
    {
        store_latitdue: -0.05692,
        store_longitude: -78.344012,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.059361,
        store_longitude: -78.3436332,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.060378,
        store_longitude: -78.346675,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.063054695725441,
        store_longitude: -78.353149414062,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.06024,
        store_longitude: -78.346698,
    },
    {
        store_latitdue: -0.060630988329649,
        store_longitude: -78.356132507324,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.2028333,
        store_longitude: -76.2608,
    },
    {
        store_latitdue: -0.06377051025629,
        store_longitude: -78.35636138916,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.070666,
        store_longitude: -78.365185,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.063793,
        store_longitude: -78.338668,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0578051,
        store_longitude: -78.3453308,
        neighborhood: 'GUAYLLABAMBA',
    },

    {
        store_latitdue: -0.0617424,
        store_longitude: -78.3490655,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0648809,
        store_longitude: -78.361857,
        neighborhood: 'GUAYLLABAMBA',
    },

    {
        store_latitdue: -0.0637194,
        store_longitude: -78.3560201,
        neighborhood: 'GUAYLLABAMBA',
    },

    {
        store_latitdue: -0.097198,
        store_longitude: -78.50955,
    },
    {
        store_latitdue: -0.0634343,
        store_longitude: -78.3626883,
        neighborhood: 'GUAYLLABAMBA',
    },

    {
        store_latitdue: -0.045677,
        store_longitude: -78.305056,
        neighborhood: 'GUAYLLABAMBA',
    },

    {
        store_latitdue: -0.063258,
        store_longitude: -78.3610207,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0702591,
        store_longitude: -78.3632946,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: 0.1732873,
        store_longitude: -78.411218,
    },
    {
        store_latitdue: 0.173718,
        store_longitude: -78.4083,
    },
    {
        store_latitdue: 0.17043,
        store_longitude: -78.4128,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.172148,
        store_longitude: -78.411058,
    },
    {
        store_latitdue: 0.173031,
        store_longitude: -78.4113,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },

    {
        store_latitdue: 0.0424351,
        store_longitude: -78.2893,
        neighborhood: 'TOCACHI',
    },
    {
        store_latitdue: 0.0454238,
        store_longitude: -78.2887,
        neighborhood: 'TOCACHI',
    },

    {
        store_latitdue: -0.067958,
        store_longitude: -78.421348,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1105075,
        store_longitude: -78.4194743,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.128479,
        store_longitude: -78.415211,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.124998,
        store_longitude: -78.4231,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1111889,
        store_longitude: -78.4232944,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1249728,
        store_longitude: -78.4399884,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1128786,
        store_longitude: -78.4484088,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.121256,
        store_longitude: -78.4158,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.138705,
        store_longitude: -78.4214,
    },
    {
        store_latitdue: -0.113872,
        store_longitude: -78.4346,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1145064,
        store_longitude: -78.4380702,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.135797,
        store_longitude: -78.4442,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.138547,
        store_longitude: -78.417372,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.116729,
        store_longitude: -78.4374752,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1348125,
        store_longitude: -78.4434703,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.112387,
        store_longitude: -78.4463,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.121837,
        store_longitude: -78.42653,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.118448,
        store_longitude: -78.426726,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.127565,
        store_longitude: -78.4415,
    },
    {
        store_latitdue: -0.1116067,
        store_longitude: -78.4419361,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1137412,
        store_longitude: -78.4498609,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.120623,
        store_longitude: -78.4416,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1164783,
        store_longitude: -78.45214,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.127993,
        store_longitude: -78.416483,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1272456,
        store_longitude: -78.4447335,
        neighborhood: 'YASUNÍ',
    },

    {
        store_latitdue: -0.111614,
        store_longitude: -78.4419,
    },
    {
        store_latitdue: -0.1141301,
        store_longitude: -78.4313208,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1186621,
        store_longitude: -78.4319273,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.138451,
        store_longitude: -78.4152,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.116341,
        store_longitude: -78.4372,
    },
    {
        store_latitdue: -0.109517,
        store_longitude: -78.4446,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113123,
        store_longitude: -78.4503,
    },
    {
        store_latitdue: -0.116178,
        store_longitude: -78.4522,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.128419,
        store_longitude: -78.415405,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.112556,
        store_longitude: -78.42,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.010552,
        store_longitude: -78.446748,
    },
    {
        store_latitdue: -0.113044,
        store_longitude: -78.448039,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.11103,
        store_longitude: -78.4426,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113196,
        store_longitude: -78.450227,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.106879,
        store_longitude: -78.4371884,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.110394,
        store_longitude: -78.4454,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.11354628,
        store_longitude: -78.44970921,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1134616,
        store_longitude: -78.4496963,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.118652,
        store_longitude: -78.4319,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.121821,
        store_longitude: -78.416256,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.107059,
        store_longitude: -78.4428,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.11254,
        store_longitude: -78.4405,
    },
    {
        store_latitdue: -0.112807,
        store_longitude: -78.4485,
    },

    {
        store_latitdue: -0.111063,
        store_longitude: -78.4187,
    },
    {
        store_latitdue: -0.114855,
        store_longitude: -78.428993,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.120768,
        store_longitude: -78.419439,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0747536,
        store_longitude: -78.431168,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.11197,
        store_longitude: -78.449488,
    },
    {
        store_latitdue: -0.1149,
        store_longitude: -78.4509,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1063402,
        store_longitude: -78.4257194,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1162295,
        store_longitude: -78.4371476,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1126771,
        store_longitude: -78.4487728,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.134727,
        store_longitude: -78.4435,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1095658,
        store_longitude: -78.428123,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1078974,
        store_longitude: -78.4323991,
    },
    {
        store_latitdue: -0.1105984,
        store_longitude: -78.4315994,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.114382,
        store_longitude: -78.4351,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.118045,
        store_longitude: -78.4387,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.112761,
        store_longitude: -78.4486,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.110342,
        store_longitude: -78.440533,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.114874,
        store_longitude: -78.444557,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.112273,
        store_longitude: -78.4331,
    },
    {
        store_latitdue: -0.111065,
        store_longitude: -78.4464151,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1143959,
        store_longitude: -78.4380731,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.131847,
        store_longitude: -78.4093,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.131478,
        store_longitude: -78.4101164,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1390612,
        store_longitude: -78.4410787,
    },
    {
        store_latitdue: -0.138894,
        store_longitude: -78.4388,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.110565,
        store_longitude: -78.4314,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1207047,
        store_longitude: -78.4254502,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1081857,
        store_longitude: -78.4195961,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1124155,
        store_longitude: -78.4490112,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.13711,
        store_longitude: -78.4385,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1114619,
        store_longitude: -78.4227894,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.119987,
        store_longitude: -78.4299,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.140951,
        store_longitude: -78.441763,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1413802,
        store_longitude: -78.4422443,
    },
    {
        store_latitdue: -0.136838,
        store_longitude: -78.4384,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.138769,
        store_longitude: -78.4319,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.10659833333333,
        store_longitude: -78.427358333333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1071208,
        store_longitude: -78.4413945,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.111254,
        store_longitude: -78.4423,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.120695,
        store_longitude: -78.4418,
    },
    {
        store_latitdue: -0.135195279,
        store_longitude: -78.4379622,
    },
    {
        store_latitdue: -0.113292,
        store_longitude: -78.4205,
    },
    {
        store_latitdue: -0.117691,
        store_longitude: -78.4261,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.106327,
        store_longitude: -78.436706,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1472681,
        store_longitude: -78.4654157,
    },
    {
        store_latitdue: -0.12801197269465,
        store_longitude: -78.417102873092,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1127386,
        store_longitude: -78.4332154,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.117507,
        store_longitude: -78.4238,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1407724,
        store_longitude: -78.4412564,
    },
    {
        store_latitdue: -0.117619,
        store_longitude: -78.426,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1385843,
        store_longitude: -78.4243782,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.2023061,
        store_longitude: -78.4934318,
    },
    {
        store_latitdue: -0.1184781,
        store_longitude: -78.4393856,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.120615,
        store_longitude: -78.4333,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113828,
        store_longitude: -78.429946,
    },
    {
        store_latitdue: -0.136938,
        store_longitude: -78.4425487,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.112948,
        store_longitude: -78.4484,
    },
    {
        store_latitdue: -0.113361,
        store_longitude: -78.4426591,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1597825,
        store_longitude: -78.4365295,
    },
    {
        store_latitdue: -0.11728972,
        store_longitude: -78.4434127,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.118986,
        store_longitude: -78.448199,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1140283,
        store_longitude: -78.438636,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.063728027582213,
        store_longitude: -78.421389425828,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1139349,
        store_longitude: -78.4126498,
    },
    {
        store_latitdue: -0.10794771,
        store_longitude: -78.41644884,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.044495,
        store_longitude: -78.285206,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.107387,
        store_longitude: -78.44466,
    },
    {
        store_latitdue: -0.1217089,
        store_longitude: -78.4405478,
    },
    {
        store_latitdue: -0.115765,
        store_longitude: -78.4451,
    },
    {
        store_latitdue: -0.1060213,
        store_longitude: -78.4263317,
    },
    {
        store_latitdue: -0.1153768,
        store_longitude: -78.4512623,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.12589,
        store_longitude: -78.442085,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.116832,
        store_longitude: -78.424833,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.13864,
        store_longitude: -78.4319267,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1225691,
        store_longitude: -78.4400841,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.122388,
        store_longitude: -78.4399008,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.13372409,
        store_longitude: -78.4437668,
    },
    {
        store_latitdue: -0.159303,
        store_longitude: -78.476181,
    },
    {
        store_latitdue: -0.1096342,
        store_longitude: -78.444963,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1051634,
        store_longitude: -78.4257164,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1109126,
        store_longitude: -78.4299595,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1079177,
        store_longitude: -78.4395404,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.114675,
        store_longitude: -78.428726,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.111852,
        store_longitude: -78.446929,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.121428,
        store_longitude: -78.42419,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.112299,
        store_longitude: -78.450258,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.116883,
        store_longitude: -78.4346,
    },
    {
        store_latitdue: -0.1211598,
        store_longitude: -78.4195709,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1115031,
        store_longitude: -78.4502271,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1170036,
        store_longitude: -78.4379205,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1748886,
        store_longitude: -78.4805973,
    },
    {
        store_latitdue: -0.138152,
        store_longitude: -78.442207,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.127314,
        store_longitude: -78.445198,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1135836,
        store_longitude: -78.4319284,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.125756,
        store_longitude: -78.4413903,
    },
    {
        store_latitdue: -0.1266559,
        store_longitude: -78.4445006,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.135798,
        store_longitude: -78.43824,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1270626,
        store_longitude: -78.44643,
    },
    {
        store_latitdue: -0.131486,
        store_longitude: -78.444002,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1155043,
        store_longitude: -78.4334514,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1314477,
        store_longitude: -78.408814,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1281784,
        store_longitude: -78.4169737,
    },
    {
        store_latitdue: -0.1073964,
        store_longitude: -78.435294,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.133948,
        store_longitude: -78.443792,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.138413,
        store_longitude: -78.4211554,
    },
    {
        store_latitdue: -0.114884,
        store_longitude: -78.435732,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1056336,
        store_longitude: -78.4261387,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.125382,
        store_longitude: -78.4456,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.112336,
        store_longitude: -78.4238,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.127562,
        store_longitude: -78.442672,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1353475,
        store_longitude: -78.445382,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.114251,
        store_longitude: -78.443796,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.12725,
        store_longitude: -78.4450722,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.11439,
        store_longitude: -78.437935,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1218423,
        store_longitude: -78.4425448,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1101498,
        store_longitude: -78.4454556,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.110355,
        store_longitude: -78.44541,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1281342,
        store_longitude: -78.4445343,
    },
    {
        store_latitdue: -0.128252,
        store_longitude: -78.417153,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.114651,
        store_longitude: -78.437891,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1223627,
        store_longitude: -78.4401226,
    },
    {
        store_latitdue: -0.1388345,
        store_longitude: -78.4380753,
    },
    {
        store_latitdue: -0.1152663,
        store_longitude: -78.444786,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1552384,
        store_longitude: -78.4739079,
    },
    {
        store_latitdue: -0.1139443,
        store_longitude: -78.4388142,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.122123,
        store_longitude: -78.416374,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1136872,
        store_longitude: -78.4129724,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.095609,
        store_longitude: -78.453132,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1119688,
        store_longitude: -78.4419507,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.137895,
        store_longitude: -78.4266517,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.11615,
        store_longitude: -78.452,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1401678,
        store_longitude: -78.4391371,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1058411,
        store_longitude: -78.420104,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.139017,
        store_longitude: -78.442432,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.117172,
        store_longitude: -78.430823,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.122348,
        store_longitude: -78.4344,
    },
    {
        store_latitdue: -0.13155,
        store_longitude: -78.410245,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1318425267,
        store_longitude: -78.4456953406,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1227969,
        store_longitude: -78.426791,
    },
    {
        store_latitdue: -0.1383205,
        store_longitude: -78.4390948,
        neighborhood: 'LLANO CHICO',
    },

    {
        store_latitdue: -0.111505,
        store_longitude: -78.442089,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.121372,
        store_longitude: -78.4276083,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1208516,
        store_longitude: -78.4418502,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.138489,
        store_longitude: -78.415201,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1163792,
        store_longitude: -78.437033,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1219606,
        store_longitude: -78.4429693,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1395039,
        store_longitude: -78.4390024,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1151395,
        store_longitude: -78.4422109,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.113119,
        store_longitude: -78.431686,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.107146,
        store_longitude: -78.442675,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1181904,
        store_longitude: -78.4340962,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.120024,
        store_longitude: -78.4298,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.107003,
        store_longitude: -78.427609,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1090331,
        store_longitude: -78.4261739,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1057574,
        store_longitude: -78.4200993,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.112355,
        store_longitude: -78.4239,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.11251121759415,
        store_longitude: -78.448547363281,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.107029,
        store_longitude: -78.44269,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0991003,
        store_longitude: -78.438904,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1020765,
        store_longitude: -78.4349819,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1305255,
        store_longitude: -78.4444719,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1149209,
        store_longitude: -78.444498,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.11272914707661,
        store_longitude: -78.448593139648,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.122391,
        store_longitude: -78.430637,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.12324833333333,
        store_longitude: -78.426885,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.14569,
        store_longitude: -78.425,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1456518,
        store_longitude: -78.4249738,
        neighborhood: 'ZÁMBIZA',
    },

    {
        store_latitdue: -0.1183148,
        store_longitude: -78.4391707,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1269506,
        store_longitude: -78.4445528,
    },
    {
        store_latitdue: -0.1354822744,
        store_longitude: -78.4434439614,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1125473,
        store_longitude: -78.432597,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1192943,
        store_longitude: -78.4326438,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.137842,
        store_longitude: -78.4266,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.112415,
        store_longitude: -78.440643,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1207446,
        store_longitude: -78.4417431,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1487825,
        store_longitude: -78.4769847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.16536,
        store_longitude: -78.4778,
    },

    {
        store_latitdue: -0.1186091,
        store_longitude: -78.4699372,
    },

    {
        store_latitdue: -0.117142,
        store_longitude: -78.4368387,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.117059,
        store_longitude: -78.4513896,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.06353,
        store_longitude: -78.429511,
    },

    {
        store_latitdue: -0.1129368,
        store_longitude: -78.4484492,
    },

    {
        store_latitdue: -0.1014991,
        store_longitude: -78.4314369,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.11288597,
        store_longitude: -78.442955,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0635581,
        store_longitude: -78.4233914,
    },
    {
        store_latitdue: -0.2776731,
        store_longitude: -78.524733,
    },

    {
        store_latitdue: -0.138777,
        store_longitude: -78.436194,
        neighborhood: 'LLANO CHICO',
    },

    {
        store_latitdue: -0.1946164,
        store_longitude: -78.4848016,
    },

    {
        store_latitdue: 0.025073,
        store_longitude: -78.89673,
        neighborhood: 'SAN MIGUEL DE LOS BANCOS',
    },

    {
        store_latitdue: -0.11595189,
        store_longitude: -78.4260559,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1079601,
        store_longitude: -78.4324168,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0659639,
        store_longitude: -78.429318,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.110679,
        store_longitude: -78.4513518,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.143273,
        store_longitude: -78.430274,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.091951,
        store_longitude: -78.4245847,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0875842,
        store_longitude: -78.4446589,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.074233,
        store_longitude: -78.438372,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0824281,
        store_longitude: -78.4370595,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.073987,
        store_longitude: -78.4329127,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0854108,
        store_longitude: -78.4451311,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0805614,
        store_longitude: -78.4347164,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0840877,
        store_longitude: -78.437584,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.077117,
        store_longitude: -78.4278071,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.074688,
        store_longitude: -78.435624,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0760168,
        store_longitude: -78.4313653,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.083486,
        store_longitude: -78.436312,
    },
    {
        store_latitdue: -0.0726399,
        store_longitude: -78.4469,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076106,
        store_longitude: -78.4312,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0774296,
        store_longitude: -78.4453949,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0758213,
        store_longitude: -78.4351,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0850033,
        store_longitude: -78.4471,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.082361,
        store_longitude: -78.4434063,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0744233,
        store_longitude: -78.4396047,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0766435,
        store_longitude: -78.439684,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0901117,
        store_longitude: -78.4514542,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0677075,
        store_longitude: -78.4522543,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0689919,
        store_longitude: -78.4514387,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0647943,
        store_longitude: -78.4522979,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0538449,
        store_longitude: -78.4550162,
    },
    {
        store_latitdue: -0.0769993,
        store_longitude: -78.4453561,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0692088,
        store_longitude: -78.4514,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0788758,
        store_longitude: -78.4323817,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0613458,
        store_longitude: -78.4333016,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0716528,
        store_longitude: -78.4355,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0824967,
        store_longitude: -78.4425,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.083808333333333,
        store_longitude: -78.439106666667,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0798901,
        store_longitude: -78.4360244,
    },
    {
        store_latitdue: -0.0761873,
        store_longitude: -78.4350733,
    },
    {
        store_latitdue: -0.079359,
        store_longitude: -78.4358971,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0706654,
        store_longitude: -78.4373,
    },
    {
        store_latitdue: -0.0724326,
        store_longitude: -78.435,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.087522,
        store_longitude: -78.4446606,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0876903,
        store_longitude: -78.439,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0874438,
        store_longitude: -78.44357,
    },
    {
        store_latitdue: -0.077061,
        store_longitude: -78.431616,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.085929,
        store_longitude: -78.443307,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0820093,
        store_longitude: -78.4395896,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0818123,
        store_longitude: -78.4431189,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0841033,
        store_longitude: -78.441355,
    },
    {
        store_latitdue: -0.08475,
        store_longitude: -78.436717,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0864191,
        store_longitude: -78.4392108,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0817359,
        store_longitude: -78.4430709,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0811197,
        store_longitude: -78.4430479,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.089368333333333,
        store_longitude: -78.440078333333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0800891,
        store_longitude: -78.444664,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08253,
        store_longitude: -78.437234,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0871824,
        store_longitude: -78.4435302,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080963,
        store_longitude: -78.4446735,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0766226,
        store_longitude: -78.447354,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0807353,
        store_longitude: -78.4384307,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0874037,
        store_longitude: -78.444662,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0794751,
        store_longitude: -78.4385404,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0802725,
        store_longitude: -78.4461035,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0757069,
        store_longitude: -78.4373866,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.073722,
        store_longitude: -78.435317,
    },
    {
        store_latitdue: -0.079374,
        store_longitude: -78.43808,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0865032,
        store_longitude: -78.4383183,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0757869,
        store_longitude: -78.4311969,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.306113,
        store_longitude: -78.532319,
    },
    {
        store_latitdue: -0.0749001,
        store_longitude: -78.4468,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0737906,
        store_longitude: -78.4478198,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0724396,
        store_longitude: -78.447734,
    },
    {
        store_latitdue: -0.071164,
        store_longitude: -78.449051,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.143352,
        store_longitude: -78.476866,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0848251,
        store_longitude: -78.4398483,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0763702,
        store_longitude: -78.4453507,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.086249,
        store_longitude: -78.4445533,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08953,
        store_longitude: -78.4430109,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08956,
        store_longitude: -78.443234,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.088035,
        store_longitude: -78.442974,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076626,
        store_longitude: -78.431585,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0723637,
        store_longitude: -78.4307,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.07388813,
        store_longitude: -78.4345474,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0763427,
        store_longitude: -78.4341,
    },
    {
        store_latitdue: -0.0803308,
        store_longitude: -78.4362643,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.076402,
        store_longitude: -78.434086,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0768267,
        store_longitude: -78.4444433,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0758591,
        store_longitude: -78.4362,
    },
    {
        store_latitdue: -0.0772024,
        store_longitude: -78.4353074,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0726943,
        store_longitude: -78.4386287,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0765395,
        store_longitude: -78.4477901,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.09472769,
        store_longitude: -78.43692195,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0774783,
        store_longitude: -78.4422242,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.084426,
        store_longitude: -78.4469439,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.23198,
        store_longitude: -78.52585,
    },
    {
        store_latitdue: -0.0842261,
        store_longitude: -78.4475323,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0841865,
        store_longitude: -78.4422818,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.084813,
        store_longitude: -78.439626,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0814342,
        store_longitude: -78.4430527,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080944,
        store_longitude: -78.443078,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0820396,
        store_longitude: -78.4132,
    },
    {
        store_latitdue: 0.011164,
        store_longitude: -78.4400105,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.0815522,
        store_longitude: -78.4471406,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0808842,
        store_longitude: -78.4483,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0825092,
        store_longitude: -78.4371379,
    },
    {
        store_latitdue: -0.08482419,
        store_longitude: -78.43984427,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0888056,
        store_longitude: -78.4397326,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.079889,
        store_longitude: -78.437859,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.067638926208019,
        store_longitude: -78.452117919922,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.069081,
        store_longitude: -78.433494,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1183283,
        store_longitude: -78.4552428,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082724,
        store_longitude: -78.4372726,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: 0.040825,
        store_longitude: -78.249947,
    },

    {
        store_latitdue: -0.0637139,
        store_longitude: -78.4342435,
    },
    {
        store_latitdue: -0.12489,
        store_longitude: -78.4601,
    },

    {
        store_latitdue: -0.095747,
        store_longitude: -78.519908,
    },

    {
        store_latitdue: -0.0770473,
        store_longitude: -78.4444785,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0875731,
        store_longitude: -78.4386,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: 0.0582261,
        store_longitude: -78.3415,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0559828,
        store_longitude: -78.3381449,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0416339,
        store_longitude: -78.3463,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0397128,
        store_longitude: -78.3449291,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0442212,
        store_longitude: -78.3496,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: -0.1751171,
        store_longitude: -78.3691138,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.043702,
        store_longitude: -78.3498,
    },
    {
        store_latitdue: 0.0404776,
        store_longitude: -78.3449542,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0496704,
        store_longitude: -78.346,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1699123,
        store_longitude: -78.3705012,
    },
    {
        store_latitdue: -0.170129,
        store_longitude: -78.3745,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: 0.0555976,
        store_longitude: -78.337,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0548397,
        store_longitude: -78.3421,
        neighborhood: 'MALCHINGUÍ',
    },

    {
        store_latitdue: 0.0433478,
        store_longitude: -78.3500941,
        neighborhood: 'MALCHINGUÍ',
    },

    {
        store_latitdue: 0.0568977,
        store_longitude: -78.3404449,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0521037,
        store_longitude: -78.3445119,
        neighborhood: 'MALCHINGUÍ',
    },

    {
        store_latitdue: 0.030919,
        store_longitude: -78.346011,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0345332,
        store_longitude: -78.3478261,
        neighborhood: 'MALCHINGUÍ',
    },

    {
        store_latitdue: -0.0957802,
        store_longitude: -78.5045152,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: 0.0562613,
        store_longitude: -78.3409386,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0404219,
        store_longitude: -78.3449259,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0414017,
        store_longitude: -78.3522433,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: -0.1563308,
        store_longitude: -78.4381838,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.15799263,
        store_longitude: -78.4434949,
    },
    {
        store_latitdue: -0.1558024,
        store_longitude: -78.4358418,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1580606,
        store_longitude: -78.4399843,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.16815,
        store_longitude: -78.4305687,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1582025,
        store_longitude: -78.4374562,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158106,
        store_longitude: -78.4446,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158245,
        store_longitude: -78.439,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.2048017,
        store_longitude: -78.3655754,
    },
    {
        store_latitdue: -0.1744005,
        store_longitude: -78.4248744,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158116,
        store_longitude: -78.438875,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158218,
        store_longitude: -78.4398596,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1575261,
        store_longitude: -78.439946,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1745068,
        store_longitude: -78.424739,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157312,
        store_longitude: -78.4401,
    },
    {
        store_latitdue: -0.1723482,
        store_longitude: -78.4472002,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1581203,
        store_longitude: -78.4395732,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.181568,
        store_longitude: -78.4425,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157932,
        store_longitude: -78.4428,
    },
    {
        store_latitdue: -0.159278,
        store_longitude: -78.4449,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.150682,
        store_longitude: -78.4718,
    },
    {
        store_latitdue: -0.1575177,
        store_longitude: -78.4369778,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1576427,
        store_longitude: -78.4391847,
    },
    {
        store_latitdue: -0.1582618,
        store_longitude: -78.441583,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.0961565,
        store_longitude: -78.4195,
    },
    {
        store_latitdue: -0.161941,
        store_longitude: -78.4464612,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158124,
        store_longitude: -78.441059,
    },
    {
        store_latitdue: -0.174519,
        store_longitude: -78.424806,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158007,
        store_longitude: -78.443247,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158123,
        store_longitude: -78.4398019,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1578599,
        store_longitude: -78.4423597,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157333,
        store_longitude: -78.438628,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1574123,
        store_longitude: -78.4382944,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.15745882,
        store_longitude: -78.4385312,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1580709,
        store_longitude: -78.4417162,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.16067291,
        store_longitude: -78.4450225,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.15735,
        store_longitude: -78.4385,
    },
    {
        store_latitdue: -0.1589345,
        store_longitude: -78.4400268,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157573,
        store_longitude: -78.439244,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1553666,
        store_longitude: -78.4342594,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158225,
        store_longitude: -78.4372917,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.209112,
        store_longitude: -78.401605,
    },
    {
        store_latitdue: -0.1811426,
        store_longitude: -78.4454068,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157697,
        store_longitude: -78.44147,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157385,
        store_longitude: -78.440061,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.155798,
        store_longitude: -78.435438,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157303,
        store_longitude: -78.4391,
        neighborhood: 'NAYÓN',
    },

    {
        store_latitdue: -0.1550696,
        store_longitude: -78.4345043,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1811273,
        store_longitude: -78.442741,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158121,
        store_longitude: -78.440073,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.175405,
        store_longitude: -78.446795,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1573489,
        store_longitude: -78.4383832,
    },

    {
        store_latitdue: -0.2104488,
        store_longitude: -78.3620831,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.177001,
        store_longitude: -78.3614,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2029767,
        store_longitude: -78.363255,
    },
    {
        store_latitdue: -0.1891278,
        store_longitude: -78.3685678,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1743148,
        store_longitude: -78.3593971,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.178111,
        store_longitude: -78.358651,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2036369,
        store_longitude: -78.3766938,
    },
    {
        store_latitdue: -0.201297,
        store_longitude: -78.3669,
    },
    {
        store_latitdue: -0.172685,
        store_longitude: -78.3268,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.2048017,
        store_longitude: -78.3655754,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.171398,
        store_longitude: -78.3704,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.224868,
        store_longitude: -78.3403,
    },
    {
        store_latitdue: -0.19341,
        store_longitude: -78.3643879,
    },
    {
        store_latitdue: -0.1767859,
        store_longitude: -78.3601312,
        neighborhood: 'PUEMBO',
    },

    {
        store_latitdue: -0.178659,
        store_longitude: -78.3622,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1682787,
        store_longitude: -78.3710096,
    },
    {
        store_latitdue: -0.178481,
        store_longitude: -78.358482,
    },
    {
        store_latitdue: -0.175434,
        store_longitude: -78.3589,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1755037,
        store_longitude: -78.3589608,
    },
    {
        store_latitdue: -0.178951,
        store_longitude: -78.3579,
    },
    {
        store_latitdue: -0.214212,
        store_longitude: -78.359944,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.177568,
        store_longitude: -78.3587,
    },
    {
        store_latitdue: -0.201801,
        store_longitude: -78.3649,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1759353,
        store_longitude: -78.3598189,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.163419,
        store_longitude: -78.374078,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2045762,
        store_longitude: -78.3634948,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.202429,
        store_longitude: -78.369,
    },
    {
        store_latitdue: -0.20497833192348,
        store_longitude: -78.36597442627,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1842216,
        store_longitude: -78.3702257,
    },
    {
        store_latitdue: -0.167288,
        store_longitude: -78.369191,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.19267075,
        store_longitude: -78.3649759,
    },
    {
        store_latitdue: -0.1837358,
        store_longitude: -78.3663319,
    },
    {
        store_latitdue: -0.18274,
        store_longitude: -78.3668,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1944824,
        store_longitude: -78.3671421,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.221627,
        store_longitude: -78.351947,
    },
    {
        store_latitdue: -0.2000935,
        store_longitude: -78.3685295,
    },
    {
        store_latitdue: -0.168302,
        store_longitude: -78.370956,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2120891,
        store_longitude: -78.3403184,
    },
    {
        store_latitdue: -0.216958,
        store_longitude: -78.339742,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.194529,
        store_longitude: -78.3669,
    },
    {
        store_latitdue: -0.19974884,
        store_longitude: -78.3675155,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.19247,
        store_longitude: -78.36495,
    },
    {
        store_latitdue: -0.1836071,
        store_longitude: -78.3677324,
    },
    {
        store_latitdue: -0.175238,
        store_longitude: -78.3653913,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1806994,
        store_longitude: -78.3622951,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.178197,
        store_longitude: -78.3632,
    },
    {
        store_latitdue: -0.17618,
        store_longitude: -78.3585,
    },
    {
        store_latitdue: -0.1782521,
        store_longitude: -78.3583836,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1782501,
        store_longitude: -78.3583596,
    },
    {
        store_latitdue: -0.2000629,
        store_longitude: -78.3459014,
    },
    {
        store_latitdue: -0.1787873,
        store_longitude: -78.3575707,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.175608,
        store_longitude: -78.359187,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.18290209,
        store_longitude: -78.3668212,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.194217,
        store_longitude: -78.366863,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.3814525,
        store_longitude: -78.3667879,
        neighborhood: 'PÍNTAG',
    },
    {
        store_latitdue: -0.17581,
        store_longitude: -78.359387,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2025728,
        store_longitude: -78.3635946,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2184866,
        store_longitude: -78.3547543,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.180916,
        store_longitude: -78.3628,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.184185,
        store_longitude: -78.3699,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1998664,
        store_longitude: -78.3675302,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1785113,
        store_longitude: -78.3584837,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.19417,
        store_longitude: -78.365736,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.179663,
        store_longitude: -78.3604895,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.209702,
        store_longitude: -78.3653,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2022552,
        store_longitude: -78.3688216,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.17863315343857,
        store_longitude: -78.36213684082,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1785166,
        store_longitude: -78.3585218,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.17856129,
        store_longitude: -78.35821831,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2146586,
        store_longitude: -78.3396372,
    },
    {
        store_latitdue: -0.2114673,
        store_longitude: -78.3609397,
    },
    {
        store_latitdue: -0.201415,
        store_longitude: -78.4315,
    },
    {
        store_latitdue: -0.183945,
        store_longitude: -78.366215,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.18616625,
        store_longitude: -78.3430709,
    },
    {
        store_latitdue: -0.1790981,
        store_longitude: -78.3578298,
        neighborhood: 'PUEMBO',
    },

    {
        store_latitdue: -2.8939352,
        store_longitude: -78.9876748,
    },

    {
        store_latitdue: -0.194109,
        store_longitude: -78.366268,
        neighborhood: 'PUEMBO',
    },

    {
        store_latitdue: -0.19426,
        store_longitude: -78.329,
    },
    {
        store_latitdue: -0.162085,
        store_longitude: -78.3747,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2248453,
        store_longitude: -78.3363268,
    },
    {
        store_latitdue: -0.2186454,
        store_longitude: -78.3393817,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2180873,
        store_longitude: -78.339549,
    },
    {
        store_latitdue: -0.21534806,
        store_longitude: -78.3396759,
    },
    {
        store_latitdue: -0.214048,
        store_longitude: -78.339884,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2349603,
        store_longitude: -78.3373316,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.225041,
        store_longitude: -78.3386,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2265813,
        store_longitude: -78.3403071,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2219886,
        store_longitude: -78.3528325,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.215558,
        store_longitude: -78.3361,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2233927,
        store_longitude: -78.3407914,
    },
    {
        store_latitdue: -0.215428,
        store_longitude: -78.336,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.237398,
        store_longitude: -78.3265,
    },
    {
        store_latitdue: -0.2203115,
        store_longitude: -78.3382725,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2262228,
        store_longitude: -78.3399676,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2107,
        store_longitude: -78.3412,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.223562,
        store_longitude: -78.3412,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.198311,
        store_longitude: -78.3469,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.267384,
        store_longitude: -78.346561,
    },

    {
        store_latitdue: -0.2023949,
        store_longitude: -78.3447495,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.215528,
        store_longitude: -78.4041,
    },
    {
        store_latitdue: -0.224022,
        store_longitude: -78.340896,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2147922,
        store_longitude: -78.3363642,
    },
    {
        store_latitdue: -0.213815,
        store_longitude: -78.3399,
    },
    {
        store_latitdue: -0.2164703,
        store_longitude: -78.4043807,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.22622197,
        store_longitude: -78.3399887,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.203948,
        store_longitude: -78.344076,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.223518,
        store_longitude: -78.341,
    },
    {
        store_latitdue: -0.2203103,
        store_longitude: -78.3383015,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.214911,
        store_longitude: -78.336014,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.220062,
        store_longitude: -78.3381,
    },
    {
        store_latitdue: -0.0579383,
        store_longitude: -78.3454,
    },
    {
        store_latitdue: -0.222247,
        store_longitude: -78.34,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.224838,
        store_longitude: -78.3403,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2255752,
        store_longitude: -78.3397293,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2297183,
        store_longitude: -78.3409714,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.227033,
        store_longitude: -78.3386,
    },
    {
        store_latitdue: -0.2192964,
        store_longitude: -78.3376263,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2310221,
        store_longitude: -78.3418,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.211813,
        store_longitude: -78.3406,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2114378,
        store_longitude: -78.3405565,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.14082,
        store_longitude: -78.4418,
    },
    {
        store_latitdue: -0.2193012,
        store_longitude: -78.3390722,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2412024,
        store_longitude: -78.3294547,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.241364,
        store_longitude: -78.3289,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.24111833333333,
        store_longitude: -78.329741666667,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2422316,
        store_longitude: -78.327036,
    },
    {
        store_latitdue: -0.233235,
        store_longitude: -78.343316666667,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.24649,
        store_longitude: -78.331046666667,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2474936,
        store_longitude: -78.3346598,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.244309,
        store_longitude: -78.33076,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.245768,
        store_longitude: -78.3327,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2346979,
        store_longitude: -78.3372415,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.223593,
        store_longitude: -78.341283,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.245972,
        store_longitude: -78.333039,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2227993,
        store_longitude: -78.33891,
    },
    {
        store_latitdue: -0.226329,
        store_longitude: -78.340136,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.224794,
        store_longitude: -78.3363,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2242483,
        store_longitude: -78.33911,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2106661,
        store_longitude: -78.3412423,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.22544,
        store_longitude: -78.3373,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2146828,
        store_longitude: -78.3368846,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.9968269,
        store_longitude: -80.6942214,
    },
    {
        store_latitdue: -0.1293203,
        store_longitude: -78.2924452,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.226087,
        store_longitude: -78.33937,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2269101,
        store_longitude: -78.3392091,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2166082,
        store_longitude: -78.3397817,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.227303,
        store_longitude: -78.3384,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2358498,
        store_longitude: -78.3307683,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.215532,
        store_longitude: -78.339744,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.20968994498253,
        store_longitude: -78.341636657715,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2279078,
        store_longitude: -78.3392254,
    },
    {
        store_latitdue: -0.22716,
        store_longitude: -78.339195,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2098491,
        store_longitude: -78.3310098,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.22392,
        store_longitude: -78.337411,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.225398,
        store_longitude: -78.340616,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2227913,
        store_longitude: -78.3506958,
    },
    {
        store_latitdue: -0.2353109,
        store_longitude: -78.336177,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.217061,
        store_longitude: -78.3398,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2274266,
        store_longitude: -78.3385009,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2248991,
        store_longitude: -78.3364756,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.233437,
        store_longitude: -78.343354,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.24087606,
        store_longitude: -78.3299407,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2242242,
        store_longitude: -78.3411336,
    },
    {
        store_latitdue: -0.22236529,
        store_longitude: -78.3398795,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.1092757,
        store_longitude: -78.2972573,
    },

    {
        store_latitdue: -0.2264958,
        store_longitude: -78.3389458,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.22816653,
        store_longitude: -78.339277,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.22637,
        store_longitude: -78.3402133,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.229501,
        store_longitude: -78.3408,
    },
    {
        store_latitdue: -0.22271767258644,
        store_longitude: -78.338813781738,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.21915471553802,
        store_longitude: -78.33943939209,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.247414,
        store_longitude: -78.335642,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.226695,
        store_longitude: -78.3391,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2295172,
        store_longitude: -78.3425341,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2271803,
        store_longitude: -78.3384671,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2251928,
        store_longitude: -78.33889,
    },
    {
        store_latitdue: -0.2260073,
        store_longitude: -78.3394298,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.226734,
        store_longitude: -78.3388,
    },
    {
        store_latitdue: -0.2267457,
        store_longitude: -78.3387447,
    },
    {
        store_latitdue: -0.227065,
        store_longitude: -78.339933,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2306236,
        store_longitude: -78.3420898,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2290055,
        store_longitude: -78.3384299,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.246515,
        store_longitude: -78.33297,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.227714,
        store_longitude: -78.340471,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2412312,
        store_longitude: -78.3292042,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.246921,
        store_longitude: -78.333689,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.245016,
        store_longitude: -78.3317,
    },
    {
        store_latitdue: -0.2309752,
        store_longitude: -78.337333,
    },
    {
        store_latitdue: -0.210187,
        store_longitude: -78.341382,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2102074,
        store_longitude: -78.341399,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },

    {
        store_latitdue: -0.2202168,
        store_longitude: -78.3737474,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.2275833,
        store_longitude: -78.3395043,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.227736,
        store_longitude: -78.33947,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.246756,
        store_longitude: -78.3341,
        neighborhood: 'PIFO',
    },

    {
        store_latitdue: -0.308009,
        store_longitude: -78.5665,
    },

    {
        store_latitdue: -0.225964,
        store_longitude: -78.3396345,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.229642,
        store_longitude: -78.34101,
        neighborhood: 'PIFO',
    },

    {
        store_latitdue: -0.123091,
        store_longitude: -78.323572,
    },
    {
        store_latitdue: -0.2453468,
        store_longitude: -78.3323708,
        neighborhood: 'PIFO',
    },

    {
        store_latitdue: 0.03569,
        store_longitude: -78.4046,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.041278,
        store_longitude: -78.4053923,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.0408531,
        store_longitude: -78.4071,
    },
    {
        store_latitdue: 0.06616,
        store_longitude: -78.4031,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.0652484,
        store_longitude: -78.4033,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.0665499,
        store_longitude: -78.40309,
    },
    {
        store_latitdue: 0.109498,
        store_longitude: -78.4232085,
        neighborhood: 'PERUCHO',
    },
    {
        store_latitdue: 0.110004,
        store_longitude: -78.422949,
        neighborhood: 'PERUCHO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0625018,
        store_longitude: -78.4056075,
    },
    {
        store_latitdue: 0.0415662,
        store_longitude: -78.4064331,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.042589,
        store_longitude: -78.401881,
        neighborhood: 'PUÉLLARO',
    },

    {
        store_latitdue: 0.1089514,
        store_longitude: -78.425215,
        neighborhood: 'PERUCHO',
    },
    {
        store_latitdue: 0.066756,
        store_longitude: -78.403,
    },

    {
        store_latitdue: 0.109042,
        store_longitude: -78.423134,
        neighborhood: 'PERUCHO',
    },

    {
        store_latitdue: -0.12509,
        store_longitude: -78.2934,
    },

    {
        store_latitdue: -0.1301622,
        store_longitude: -78.2925239,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.136761,
        store_longitude: -78.290705,
    },
    {
        store_latitdue: -0.0481892,
        store_longitude: -78.3087494,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0822414,
        store_longitude: -78.3102032,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.127886,
        store_longitude: -78.2958,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.109191,
        store_longitude: -78.2931,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.110393,
        store_longitude: -78.2967,
    },
    {
        store_latitdue: -0.10948,
        store_longitude: -78.2962,
    },
    {
        store_latitdue: -0.10941,
        store_longitude: -78.2949,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.11292,
        store_longitude: -78.2955,
    },
    {
        store_latitdue: -0.109671,
        store_longitude: -78.2985,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1118266,
        store_longitude: -78.2975482,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.109308,
        store_longitude: -78.2976,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1287707,
        store_longitude: -78.2942069,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1243455,
        store_longitude: -78.2930161,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.108613,
        store_longitude: -78.2966922,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1109578,
        store_longitude: -78.2988016,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.161402,
        store_longitude: -78.3176,
    },
    {
        store_latitdue: -0.1095392,
        store_longitude: -78.2963763,
    },
    {
        store_latitdue: -0.109972,
        store_longitude: -78.2976,
    },
    {
        store_latitdue: -0.1124889,
        store_longitude: -78.2961213,
    },
    {
        store_latitdue: -0.109032,
        store_longitude: -78.2983,
    },
    {
        store_latitdue: -0.09706105,
        store_longitude: -78.2900035,
    },
    {
        store_latitdue: -0.112018,
        store_longitude: -78.2976,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.108028,
        store_longitude: -78.2984,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.109643,
        store_longitude: -78.296106,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.107319,
        store_longitude: -78.300067,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1047801,
        store_longitude: -78.297662,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1098849,
        store_longitude: -78.2969902,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1166043,
        store_longitude: -78.4910882,
    },
    {
        store_latitdue: -0.10726,
        store_longitude: -78.3001,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.100313,
        store_longitude: -78.300945,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.12926224,
        store_longitude: -78.2928023,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1082176,
        store_longitude: -78.2977804,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.109925,
        store_longitude: -78.2997,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1104891,
        store_longitude: -78.2944208,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1012008,
        store_longitude: -78.2968661,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0821986,
        store_longitude: -78.3091469,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1097626,
        store_longitude: -78.295329,
    },
    {
        store_latitdue: -0.1073869,
        store_longitude: -78.2994948,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.10811374336481,
        store_longitude: -78.297874450684,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1073575,
        store_longitude: -78.2998797,
    },
    {
        store_latitdue: -0.101179,
        store_longitude: -78.315217,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0821423,
        store_longitude: -78.3090211,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.097073333333333,
        store_longitude: -78.28993,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1124484,
        store_longitude: -78.2954922,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.101038,
        store_longitude: -78.3153,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.2262623,
        store_longitude: -78.3392071,
    },
    {
        store_latitdue: -0.101211,
        store_longitude: -78.29869,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1106376,
        store_longitude: -78.2978399,
    },
    {
        store_latitdue: -0.103428,
        store_longitude: -78.3108,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0817998,
        store_longitude: -78.3087435,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1022971,
        store_longitude: -78.2952683,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.103132,
        store_longitude: -78.309997,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1119591,
        store_longitude: -78.2946639,
        neighborhood: 'EL QUINCHE',
    },

    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.108376,
        store_longitude: -78.297266,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.11223,
        store_longitude: -78.2975529,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.111819,
        store_longitude: -78.295082,
    },
    {
        store_latitdue: -0.103488,
        store_longitude: -78.316212,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1095976,
        store_longitude: -78.2969209,
    },
    {
        store_latitdue: -0.0971672,
        store_longitude: -78.2905589,
    },
    {
        store_latitdue: -0.12629,
        store_longitude: -78.2937888,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1123294,
        store_longitude: -78.2975738,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0977121,
        store_longitude: -78.289243,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0636167,
        store_longitude: -78.3607786,
    },
    {
        store_latitdue: -0.1052616,
        store_longitude: -78.2975255,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.111576,
        store_longitude: -78.297735,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1242535,
        store_longitude: -78.2917286,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.10973681509495,
        store_longitude: -78.300109863281,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.125305,
        store_longitude: -78.3021,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.200071,
        store_longitude: -78.3334,
    },
    {
        store_latitdue: -0.079131,
        store_longitude: -78.310283,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1121659,
        store_longitude: -78.2961876,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0822921,
        store_longitude: -78.3100335,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0798546,
        store_longitude: -78.2910884,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.1106146,
        store_longitude: -78.2980352,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.062907272949815,
        store_longitude: -78.352574994788,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.10954,
        store_longitude: -78.296845,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.101972,
        store_longitude: -78.31295,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.102947,
        store_longitude: -78.3102211,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1075774,
        store_longitude: -78.2995852,
    },
    {
        store_latitdue: -0.1039916,
        store_longitude: -78.2971929,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.11191,
        store_longitude: -78.2945553,
    },
    {
        store_latitdue: -0.103094,
        store_longitude: -78.314821,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0847983,
        store_longitude: -78.3290495,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.108841,
        store_longitude: -78.29751,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1079482,
        store_longitude: -78.2980091,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1078522,
        store_longitude: -78.2979965,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.108763,
        store_longitude: -78.296273,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.108304,
        store_longitude: -78.295741,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.11002,
        store_longitude: -78.295326,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1095649,
        store_longitude: -78.2969132,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1100037,
        store_longitude: -78.2970138,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.107094,
        store_longitude: -78.300498,
    },
    {
        store_latitdue: -0.101567,
        store_longitude: -78.313481,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.136317,
        store_longitude: -78.295535,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.049276,
        store_longitude: -78.285105,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.107498,
        store_longitude: -78.299322,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1125125,
        store_longitude: -78.294525,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1090892,
        store_longitude: -78.297645,
    },
    {
        store_latitdue: -0.11022799,
        store_longitude: -78.296256,
    },
    {
        store_latitdue: -0.1136027,
        store_longitude: -78.2940727,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1095216,
        store_longitude: -78.3003398,
        neighborhood: 'EL QUINCHE',
    },

    {
        store_latitdue: -0.112231,
        store_longitude: -78.2794,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.113185,
        store_longitude: -78.2811,
        neighborhood: 'EL QUINCHE',
    },

    {
        store_latitdue: -0.0846838,
        store_longitude: -78.2929,
        neighborhood: 'ASCÁZUBI',
    },

    {
        store_latitdue: -0.223746,
        store_longitude: -78.3411,
    },

    {
        store_latitdue: -0.107275,
        store_longitude: -78.300158,
        neighborhood: 'EL QUINCHE',
    },

    {
        store_latitdue: -0.113507,
        store_longitude: -78.2941,
        neighborhood: 'EL QUINCHE',
    },

    {
        store_latitdue: -0.104681,
        store_longitude: -78.29838,
        neighborhood: 'EL QUINCHE',
    },

    {
        store_latitdue: -0.1246651,
        store_longitude: -78.2911235,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1086037,
        store_longitude: -78.2974061,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.110246,
        store_longitude: -78.2948,
    },
    {
        store_latitdue: -0.110086,
        store_longitude: -78.2916,
    },

    {
        store_latitdue: -0.179237,
        store_longitude: -78.355672,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: 0.0454488,
        store_longitude: -78.201,
    },
    {
        store_latitdue: 0.0496032,
        store_longitude: -78.2173,
    },
    {
        store_latitdue: 0.0479668,
        store_longitude: -78.2093754,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0452136,
        store_longitude: -78.2081473,
    },
    {
        store_latitdue: 0.035453,
        store_longitude: -78.26131,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.0356018,
        store_longitude: -78.2569,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.0359899,
        store_longitude: -78.256446,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0.0467955,
        store_longitude: -78.2120291,
    },
    {
        store_latitdue: 0.0623403,
        store_longitude: -78.2003,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0418727,
        store_longitude: -78.247,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.04901,
        store_longitude: -78.2173,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0458366,
        store_longitude: -78.2103,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0469167,
        store_longitude: -78.2185561,
    },
    {
        store_latitdue: 0.0467518,
        store_longitude: -78.204088,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.047057,
        store_longitude: -78.2183,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0398243,
        store_longitude: -78.2495,
        neighborhood: 'LA ESPERANZA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.046513,
        store_longitude: -78.2259,
    },
    {
        store_latitdue: 0.0462913,
        store_longitude: -78.2213,
    },
    {
        store_latitdue: 0.0466333,
        store_longitude: -78.2009633,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0517071,
        store_longitude: -78.216,
    },
    {
        store_latitdue: 0.0522794,
        store_longitude: -78.2156,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0470502,
        store_longitude: -78.2210329,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0471161,
        store_longitude: -78.2107807,
    },
    {
        store_latitdue: 0.040945,
        store_longitude: -78.220054,
    },
    {
        store_latitdue: 0.211566,
        store_longitude: -78.2517,
    },
    {
        store_latitdue: 0.0468675,
        store_longitude: -78.2257,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0451847,
        store_longitude: -78.2256,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0455731,
        store_longitude: -78.2154684,
    },
    {
        store_latitdue: 0.0499788,
        store_longitude: -78.2217015,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0455963,
        store_longitude: -78.215648,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.045209,
        store_longitude: -78.2079742,
    },
    {
        store_latitdue: -0.192777,
        store_longitude: -78.340285,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.1858743,
        store_longitude: -78.3455413,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: 0.0472198,
        store_longitude: -78.2101,
        neighborhood: 'TABACUNDO',
    },

    {
        store_latitdue: 0.046813,
        store_longitude: -78.200944,
    },
    {
        store_latitdue: 0.0462794,
        store_longitude: -78.2008385,
        neighborhood: 'TABACUNDO',
    },

    {
        store_latitdue: 0.0452429,
        store_longitude: -78.2197287,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0457911,
        store_longitude: -78.2015902,
        neighborhood: 'TABACUNDO',
    },

    {
        store_latitdue: 0.0378139,
        store_longitude: -78.2121883,
    },

    {
        store_latitdue: 0.0468154,
        store_longitude: -78.2129324,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0472137,
        store_longitude: -78.2121834,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0460951,
        store_longitude: -78.2006,
    },

    {
        store_latitdue: 0.0506548,
        store_longitude: -78.2171,
        neighborhood: 'TABACUNDO',
    },

    {
        store_latitdue: 0.0442677,
        store_longitude: -78.2199,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.046387,
        store_longitude: -78.2275364,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0442185,
        store_longitude: -78.23374,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.263672,
        store_longitude: -78.2444,
    },
    {
        store_latitdue: 0.045205,
        store_longitude: -78.1984,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0497868,
        store_longitude: -78.2172624,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0478936,
        store_longitude: -78.2208947,
        neighborhood: 'TABACUNDO',
    },

    {
        store_latitdue: 0.0491625,
        store_longitude: -78.2172766,
    },

    {
        store_latitdue: 0.0470738,
        store_longitude: -78.2183208,
    },

    {
        store_latitdue: -0.181403,
        store_longitude: -78.4423,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1796146,
        store_longitude: -78.4404346,
    },
    {
        store_latitdue: -0.1883265,
        store_longitude: -78.447671,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.17936,
        store_longitude: -78.440013333333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1804158,
        store_longitude: -78.4409236,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1801227,
        store_longitude: -78.4406423,
    },
    {
        store_latitdue: -0.1794439,
        store_longitude: -78.4400628,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.170297,
        store_longitude: -78.4435,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1871775,
        store_longitude: -78.4481706,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.179336,
        store_longitude: -78.439984,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.181088,
        store_longitude: -78.442928,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.181338,
        store_longitude: -78.442643,
        neighborhood: 'NAYÓN',
    },

    {
        store_latitdue: -0.1796355,
        store_longitude: -78.4402187,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.179201,
        store_longitude: -78.439278,
    },
    {
        store_latitdue: -0.179337,
        store_longitude: -78.4392,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1725155,
        store_longitude: -78.4471864,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1796577,
        store_longitude: -78.4402784,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1795648,
        store_longitude: -78.4405495,
    },
    {
        store_latitdue: -0.181133,
        store_longitude: -78.44279,
    },

    {
        store_latitdue: -0.18496806,
        store_longitude: -78.34426638,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.18219,
        store_longitude: -78.338813,
        neighborhood: 'TABABELA',
    },

    {
        store_latitdue: -0.1274188,
        store_longitude: -78.317751,
    },
    {
        store_latitdue: -0.164825,
        store_longitude: -78.3111028,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1949064,
        store_longitude: -78.3308427,
    },
    {
        store_latitdue: -0.1987951,
        store_longitude: -78.3322586,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.161375,
        store_longitude: -78.3197,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.182395,
        store_longitude: -78.3122,
    },
    {
        store_latitdue: -0.163934,
        store_longitude: -78.3072,
    },
    {
        store_latitdue: -0.163928,
        store_longitude: -78.309522,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1655027,
        store_longitude: -78.32332,
    },
    {
        store_latitdue: -0.196286,
        store_longitude: -78.326,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1616138,
        store_longitude: -78.3089217,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.213667,
        store_longitude: -78.328064,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.177507,
        store_longitude: -78.329155,
    },
    {
        store_latitdue: -0.162198,
        store_longitude: -78.321381,
    },
    {
        store_latitdue: -0.1963082,
        store_longitude: -78.3313936,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1670677,
        store_longitude: -78.3082432,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.166574,
        store_longitude: -78.3088,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.2184159,
        store_longitude: -78.3394901,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.162763,
        store_longitude: -78.3209,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.165323,
        store_longitude: -78.31765,
    },
    {
        store_latitdue: -0.163893,
        store_longitude: -78.3094,
    },
    {
        store_latitdue: -0.1633759,
        store_longitude: -78.3130534,
    },
    {
        store_latitdue: -0.161684,
        store_longitude: -78.308902,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1621783,
        store_longitude: -78.3197823,
    },
    {
        store_latitdue: -0.167422,
        store_longitude: -78.3197,
    },
    {
        store_latitdue: -0.162118,
        store_longitude: -78.3201,
    },
    {
        store_latitdue: -0.162231,
        store_longitude: -78.3188738,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1667208,
        store_longitude: -78.3249111,
    },
    {
        store_latitdue: -0.1239319,
        store_longitude: -78.3216495,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.195689,
        store_longitude: -78.3312,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.16887,
        store_longitude: -78.3263,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.344182,
        store_longitude: -78.5513,
    },
    {
        store_latitdue: -0.109718,
        store_longitude: -78.300198,
    },
    {
        store_latitdue: -0.1884001,
        store_longitude: -78.3368669,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.1636211,
        store_longitude: -78.327734,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.163089,
        store_longitude: -78.3163,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.162198,
        store_longitude: -78.321381,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1766519,
        store_longitude: -78.3283894,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.195663,
        store_longitude: -78.3355,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.164013,
        store_longitude: -78.3111,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1967231,
        store_longitude: -78.325558,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1942129,
        store_longitude: -78.3307758,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1629769,
        store_longitude: -78.3170767,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.168205,
        store_longitude: -78.316919,
    },
    {
        store_latitdue: -0.162967,
        store_longitude: -78.3168,
    },

    {
        store_latitdue: -0.1616779,
        store_longitude: -78.3175921,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.129275,
        store_longitude: -78.309,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1591,
        store_longitude: -78.3189,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.156353,
        store_longitude: -78.3238,
    },
    {
        store_latitdue: -0.1271928,
        store_longitude: -78.2942918,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.12699166666667,
        store_longitude: -78.296805,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1194513,
        store_longitude: -78.3000076,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1199224,
        store_longitude: -78.2992257,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.16296,
        store_longitude: -78.3167,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1884007,
        store_longitude: -78.337129,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.181963,
        store_longitude: -78.3388,
    },
    {
        store_latitdue: -0.162851,
        store_longitude: -78.319,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.176178,
        store_longitude: -78.397096,
    },
    {
        store_latitdue: -0.16289,
        store_longitude: -78.316658333333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1632272,
        store_longitude: -78.3162935,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.2644487767,
        store_longitude: -78.5318737105,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1941759,
        store_longitude: -78.330688,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1627426,
        store_longitude: -78.3200263,
    },
    {
        store_latitdue: -0.16764,
        store_longitude: -78.322,
    },
    {
        store_latitdue: -0.1618951,
        store_longitude: -78.3174561,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1942729,
        store_longitude: -78.3280078,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.166046,
        store_longitude: -78.3243,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.161889,
        store_longitude: -78.3174,
    },
    {
        store_latitdue: -0.1634599,
        store_longitude: -78.3127359,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.15416,
        store_longitude: -78.313828,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.175839,
        store_longitude: -78.3284,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1967681,
        store_longitude: -78.331298,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1669195,
        store_longitude: -78.3236753,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1673609,
        store_longitude: -78.3173748,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.161289,
        store_longitude: -78.3289745,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.151282,
        store_longitude: -78.335,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1987766,
        store_longitude: -78.3319993,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.199514,
        store_longitude: -78.332496,
    },
    {
        store_latitdue: -0.1629071,
        store_longitude: -78.3182046,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.163132,
        store_longitude: -78.3151,
    },
    {
        store_latitdue: -0.194403,
        store_longitude: -78.3666,
    },
    {
        store_latitdue: -0.162153,
        store_longitude: -78.319476,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1579037,
        store_longitude: -78.3306909,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.190022,
        store_longitude: -78.3407938,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.13127,
        store_longitude: -78.3046,
    },
    {
        store_latitdue: -0.1573842,
        store_longitude: -78.3244347,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1997089,
        store_longitude: -78.3265382,
    },
    {
        store_latitdue: -0.170695,
        store_longitude: -78.350393,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.1654739,
        store_longitude: -78.316558,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1774554,
        store_longitude: -78.3291789,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.16301333333333,
        store_longitude: -78.318338333333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.15606,
        store_longitude: -78.3184303,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1562343,
        store_longitude: -78.3182651,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1899165,
        store_longitude: -78.3307042,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1772984,
        store_longitude: -78.3281736,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1586377,
        store_longitude: -78.3209575,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1623321,
        store_longitude: -78.3214169,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1910933,
        store_longitude: -78.32184,
    },
    {
        store_latitdue: -0.1902809,
        store_longitude: -78.3406928,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.1504742,
        store_longitude: -78.3189214,
        neighborhood: 'CHECA',
    },

    {
        store_latitdue: -0.162948,
        store_longitude: -78.317352,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.15756,
        store_longitude: -78.321144,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1655373,
        store_longitude: -78.3158569,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1651915,
        store_longitude: -78.3236107,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.16216,
        store_longitude: -78.3206,
    },
    {
        store_latitdue: -0.2123796,
        store_longitude: -78.3269165,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.17666,
        store_longitude: -78.328592,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.157218,
        store_longitude: -78.322,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.18402,
        store_longitude: -78.343056,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.18434019386768,
        store_longitude: -78.343399047852,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.1751362,
        store_longitude: -78.3302892,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1642391,
        store_longitude: -78.3083085,
    },
    {
        store_latitdue: -0.1613072,
        store_longitude: -78.3088677,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1620791,
        store_longitude: -78.3205385,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.163633,
        store_longitude: -78.3202,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.212308,
        store_longitude: -78.326852,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.197474,
        store_longitude: -78.329619,
    },
    {
        store_latitdue: -0.161063,
        store_longitude: -78.32118,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1750439,
        store_longitude: -78.3302738,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.147599831,
        store_longitude: -78.33692169,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.145308,
        store_longitude: -78.339579,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.162137,
        store_longitude: -78.323192,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1771649,
        store_longitude: -78.3284662,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1750969,
        store_longitude: -78.3278457,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.16248,
        store_longitude: -78.318146666667,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1637895,
        store_longitude: -78.3105823,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1519151,
        store_longitude: -78.3332645,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.172899,
        store_longitude: -78.346926,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.172365,
        store_longitude: -78.3457,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.176057,
        store_longitude: -78.329024,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.176008,
        store_longitude: -78.328398,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.2274513,
        store_longitude: -78.3432859,
    },
    {
        store_latitdue: -0.148968,
        store_longitude: -78.336716,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1523834,
        store_longitude: -78.3330775,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.165388,
        store_longitude: -78.324259,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.161689,
        store_longitude: -78.315894,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.132142,
        store_longitude: -78.342449,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.128825,
        store_longitude: -78.344163,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.2281604,
        store_longitude: -78.3392628,
    },
    {
        store_latitdue: -0.128779,
        store_longitude: -78.3443,
    },
    {
        store_latitdue: -0.0793569,
        store_longitude: -78.2985796,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.1455062,
        store_longitude: -78.3394886,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1616723,
        store_longitude: -78.3175167,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.162083,
        store_longitude: -78.317494,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1618847,
        store_longitude: -78.3226467,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.107276,
        store_longitude: -78.2999,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.16248166666667,
        store_longitude: -78.321121666667,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1623272,
        store_longitude: -78.317145,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.1685472,
        store_longitude: -78.3124463,
    },
    {
        store_latitdue: -0.16489173,
        store_longitude: -78.3229598,
    },
    {
        store_latitdue: -0.1757954,
        store_longitude: -78.3286407,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.199776,
        store_longitude: -78.332999,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1621515,
        store_longitude: -78.3194791,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1620992,
        store_longitude: -78.3209907,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1648456496694,
        store_longitude: -78.322966902247,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.16181209,
        store_longitude: -78.3155572,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1571175,
        store_longitude: -78.2996895,
    },
    {
        store_latitdue: -0.16334716,
        store_longitude: -78.3132247,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1672803,
        store_longitude: -78.3135988,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.152905,
        store_longitude: -78.315638,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1623512,
        store_longitude: -78.3181093,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1652175,
        store_longitude: -78.3234833,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.201227,
        store_longitude: -78.334734,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1629401,
        store_longitude: -78.3169672,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.199957,
        store_longitude: -78.333085,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1714576,
        store_longitude: -78.3418297,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.1879175,
        store_longitude: -78.3256059,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1869229,
        store_longitude: -78.3256115,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1629992,
        store_longitude: -78.3172342,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1627445,
        store_longitude: -78.320496,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.16144,
        store_longitude: -78.320511,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1654043,
        store_longitude: -78.3188839,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1629876,
        store_longitude: -78.3172915,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1654085,
        store_longitude: -78.3241414,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1591798,
        store_longitude: -78.321059,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.163665,
        store_longitude: -78.313142,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.1631095,
        store_longitude: -78.3141042,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.1608903,
        store_longitude: -78.3095567,
        neighborhood: 'YARUQUÍ',
    },

    {
        store_latitdue: -0.162277,
        store_longitude: -78.3196,
    },

    {
        store_latitdue: 0.8769397,
        store_longitude: -79.8268462,
        neighborhood: 'ATACAMES',
    },

    {
        store_latitdue: -0.148531,
        store_longitude: -78.444501,
    },
    {
        store_latitdue: -0.140524,
        store_longitude: -78.4417394,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1391174,
        store_longitude: -78.4419262,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.14739,
        store_longitude: -78.4441,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1583368,
        store_longitude: -78.4364048,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.149713,
        store_longitude: -78.4363,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1487376,
        store_longitude: -78.4417489,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.0669185,
        store_longitude: -78.4523,
    },
    {
        store_latitdue: -0.1487079,
        store_longitude: -78.4420959,
    },
    {
        store_latitdue: -0.138702,
        store_longitude: -78.431747,
    },
    {
        store_latitdue: -0.12617,
        store_longitude: -78.443788,
    },

    {
        store_latitdue: -0.0733929,
        store_longitude: -78.4098,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0736075,
        store_longitude: -78.4088607,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0796326,
        store_longitude: -78.414564,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0844975,
        store_longitude: -78.4112368,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08076799,
        store_longitude: -78.4121057,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.094251,
        store_longitude: -78.437961,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0840312,
        store_longitude: -78.4130256,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0747926,
        store_longitude: -78.4087051,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0847544,
        store_longitude: -78.4104878,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.088687,
        store_longitude: -78.420777,
    },
    {
        store_latitdue: -0.080158,
        store_longitude: -78.413555,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0640874,
        store_longitude: -78.417216,
    },

    {
        store_latitdue: -0.080614,
        store_longitude: -78.412054,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0821687,
        store_longitude: -78.428,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0827289,
        store_longitude: -78.4269,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0737964,
        store_longitude: -78.4092759,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0727751,
        store_longitude: -78.4096359,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080038,
        store_longitude: -78.4135254,
    },
    {
        store_latitdue: -0.1071687,
        store_longitude: -78.4176964,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0536849,
        store_longitude: -78.4197389,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0726152,
        store_longitude: -78.4234349,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0853282,
        store_longitude: -78.4238,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0774906,
        store_longitude: -78.4161754,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080191,
        store_longitude: -78.413333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0702835,
        store_longitude: -78.4140502,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0814071,
        store_longitude: -78.4143,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0815359,
        store_longitude: -78.4102,
    },
    {
        store_latitdue: -0.28217,
        store_longitude: -78.538956,
    },
    {
        store_latitdue: -0.08014,
        store_longitude: -78.411085,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0789008,
        store_longitude: -78.4358,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0825044,
        store_longitude: -78.4095949,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0801811,
        store_longitude: -78.4131773,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0776625,
        store_longitude: -78.4118225,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08663,
        store_longitude: -78.411312,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0723321,
        store_longitude: -78.408589,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0789834,
        store_longitude: -78.4133264,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0806614,
        store_longitude: -78.4086832,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1152591,
        store_longitude: -78.435838,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0790774,
        store_longitude: -78.4133273,
    },
    {
        store_latitdue: -0.0861708,
        store_longitude: -78.4111599,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.08185,
        store_longitude: -78.412584,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0818861,
        store_longitude: -78.4131904,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080175,
        store_longitude: -78.413259,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.083824,
        store_longitude: -78.412926,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.080672,
        store_longitude: -78.412336,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0865573,
        store_longitude: -78.4112487,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.081036,
        store_longitude: -78.414244,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0864688,
        store_longitude: -78.4114292,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0788157,
        store_longitude: -78.4166448,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.087287,
        store_longitude: -78.411609,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1135367,
        store_longitude: -78.414225,
    },
    {
        store_latitdue: -0.080129,
        store_longitude: -78.413376,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0827543,
        store_longitude: -78.4116611,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.081176,
        store_longitude: -78.41418,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1236191,
        store_longitude: -78.5198604,
    },
    {
        store_latitdue: -0.080803,
        store_longitude: -78.4120785,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.1361807,
        store_longitude: -78.467194,
    },

    {
        store_latitdue: -0.0742147,
        store_longitude: -78.4089464,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.0870575,
        store_longitude: -78.4123815,
        neighborhood: 'CALDERÓN',
    },

    {
        store_latitdue: -0.1117582,
        store_longitude: -78.4652922,
    },
    {
        store_latitdue: -0.0790309,
        store_longitude: -78.413424,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0814329,
        store_longitude: -78.4116375,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.0883459,
        store_longitude: -78.4269,
    },
    {
        store_latitdue: -0.084134,
        store_longitude: -78.291834,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0835449,
        store_longitude: -78.2917274,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.081662,
        store_longitude: -78.29043,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.086206,
        store_longitude: -78.29102,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.084227,
        store_longitude: -78.292591,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.082524,
        store_longitude: -78.293215,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.080649,
        store_longitude: -78.296758,
    },
    {
        store_latitdue: -0.080346,
        store_longitude: -78.297179,
    },
    {
        store_latitdue: -0.080889,
        store_longitude: -78.291611,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.084782,
        store_longitude: -78.295197,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.068622,
        store_longitude: -78.288957,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.064722,
        store_longitude: -78.288493,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.063976,
        store_longitude: -78.290694,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.064634,
        store_longitude: -78.2888522,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0853972,
        store_longitude: -78.2935847,
        neighborhood: 'ASCÁZUBI',
    },
    {
        store_latitdue: -0.0676876,
        store_longitude: -78.2893878,
        neighborhood: 'ASCÁZUBI',
    },

    {
        store_latitdue: -0.0839485,
        store_longitude: -78.2918,
    },
    {
        store_latitdue: -0.080889,
        store_longitude: -78.291611,
    },
    {
        store_latitdue: -0.064242,
        store_longitude: -78.289849,
    },
    {
        store_latitdue: -0.0885871,
        store_longitude: -78.2923661,
    },
    {
        store_latitdue: 0.0468313,
        store_longitude: -78.2146,
    },
    {
        store_latitdue: -0.202412,
        store_longitude: -78.3689,
    },
    {
        store_latitdue: -0.1270107,
        store_longitude: -78.3134001,
    },
    {
        store_latitdue: -0.122917,
        store_longitude: -78.323605,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.127062,
        store_longitude: -78.3131,
    },
    {
        store_latitdue: -0.1282387,
        store_longitude: -78.3092735,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1272131,
        store_longitude: -78.3131224,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.128703,
        store_longitude: -78.3122,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1248058,
        store_longitude: -78.3191209,
    },
    {
        store_latitdue: -0.12503,
        store_longitude: -78.3183,
    },
    {
        store_latitdue: -0.1271219,
        store_longitude: -78.3130981,
    },
    {
        store_latitdue: -0.122731,
        store_longitude: -78.327384,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.129848,
        store_longitude: -78.3105046,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.127227,
        store_longitude: -78.313,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.127547,
        store_longitude: -78.3173,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.12774,
        store_longitude: -78.3111705,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.129248,
        store_longitude: -78.3087,
    },
    {
        store_latitdue: -0.1303939,
        store_longitude: -78.3103823,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.128513,
        store_longitude: -78.3121,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1250518,
        store_longitude: -78.3187269,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.128824,
        store_longitude: -78.3122318,
    },
    {
        store_latitdue: -0.128611,
        store_longitude: -78.3073,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.1265437,
        store_longitude: -78.3205656,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.044573,
        store_longitude: -78.2852747,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0447116,
        store_longitude: -78.2855,
    },
    {
        store_latitdue: -0.044178,
        store_longitude: -78.285082,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0446448,
        store_longitude: -78.284711,
    },
    {
        store_latitdue: -0.0527305,
        store_longitude: -78.2853,
    },
    {
        store_latitdue: -0.0537641,
        store_longitude: -78.2843388,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0542566,
        store_longitude: -78.2837778,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.054469,
        store_longitude: -78.283555,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.063625,
        store_longitude: -78.355568,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.05038,
        store_longitude: -78.282794,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0502359,
        store_longitude: -78.3051058,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0501229,
        store_longitude: -78.3048486,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0486461,
        store_longitude: -78.2849066,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0456888,
        store_longitude: -78.286679,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0455803,
        store_longitude: -78.291,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.045331,
        store_longitude: -78.305625,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.04933,
        store_longitude: -78.305,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.05558,
        store_longitude: -78.282837,
    },
    {
        store_latitdue: -0.0488432,
        store_longitude: -78.2848374,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0456833,
        store_longitude: -78.28655,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0491605,
        store_longitude: -78.3049733,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0446411,
        store_longitude: -78.2852784,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0505393,
        store_longitude: -78.2856,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0425115,
        store_longitude: -78.290599,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0476936,
        store_longitude: -78.281396,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0418057,
        store_longitude: -78.2927,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0456095,
        store_longitude: -78.283106,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: -0.0485179,
        store_longitude: -78.3070325,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0523985,
        store_longitude: -78.285661,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.04426191,
        store_longitude: -78.2854003,
        neighborhood: 'SANTA ROSA DE CUZUBAMBA',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.034136,
        store_longitude: -78.147,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.040802,
        store_longitude: -78.1434,
    },
    {
        store_latitdue: 0.038145,
        store_longitude: -78.1424,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0373599,
        store_longitude: -78.1444981,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0247529,
        store_longitude: -78.1447,
    },
    {
        store_latitdue: 0.0367289,
        store_longitude: -78.14369,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.047201,
        store_longitude: -78.1403,
    },
    {
        store_latitdue: 0.045342,
        store_longitude: -78.14,
    },
    {
        store_latitdue: 0.0447629,
        store_longitude: -78.140377,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0445495,
        store_longitude: -78.1400474,
        neighborhood: 'CAYAMBE',
    },

    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.043263,
        store_longitude: -78.1417,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.042199,
        store_longitude: -78.1412,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.046691,
        store_longitude: -78.1408,
    },
    {
        store_latitdue: 0.042031,
        store_longitude: -78.1422,
    },
    {
        store_latitdue: 0.0369631,
        store_longitude: -78.1493,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0395184,
        store_longitude: -78.1469638,
    },
    {
        store_latitdue: 0.0368964,
        store_longitude: -78.1475,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0368254,
        store_longitude: -78.1475882,
    },
    {
        store_latitdue: 0.0214,
        store_longitude: -78.147089,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0428393,
        store_longitude: -78.1469727,
    },
    {
        store_latitdue: 0.0429838,
        store_longitude: -78.1449967,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0314786,
        store_longitude: -78.1462686,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0248252,
        store_longitude: -78.1446842,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.035474,
        store_longitude: -78.1425,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0342252,
        store_longitude: -78.1443455,
    },
    {
        store_latitdue: 0.0339844,
        store_longitude: -78.1440168,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0332189,
        store_longitude: -78.1487186,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0458962,
        store_longitude: -78.2016,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0484348,
        store_longitude: -78.1406923,
    },
    {
        store_latitdue: 0.0405002,
        store_longitude: -78.1517577,
    },
    {
        store_latitdue: 0.040273,
        store_longitude: -78.150336,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.039787,
        store_longitude: -78.1525,
    },
    {
        store_latitdue: 0.0406656,
        store_longitude: -78.1536021,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0332592,
        store_longitude: -78.1491,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.038032,
        store_longitude: -78.1508,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.042136,
        store_longitude: -78.1461,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.041896,
        store_longitude: -78.1445,
    },
    {
        store_latitdue: 0.034743,
        store_longitude: -78.1451,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.038488,
        store_longitude: -78.153,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0308766,
        store_longitude: -78.1444,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0455269,
        store_longitude: -78.142046,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0422953,
        store_longitude: -78.1436543,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0377367,
        store_longitude: -78.1516,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.037552,
        store_longitude: -78.1513,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0420587,
        store_longitude: -78.1446,
    },
    {
        store_latitdue: 0.046464,
        store_longitude: -78.1385,
    },
    {
        store_latitdue: 0.047391,
        store_longitude: -78.142,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.06754184,
        store_longitude: -78.134881,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0445666,
        store_longitude: -78.1430431,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0376653,
        store_longitude: -78.1433,
    },
    {
        store_latitdue: 0.017916,
        store_longitude: -78.1627,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0433705,
        store_longitude: -78.1383408,
    },
    {
        store_latitdue: 0.0463804,
        store_longitude: -78.1367,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0486885,
        store_longitude: -78.142395,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.06784,
        store_longitude: -78.1353,
    },
    {
        store_latitdue: 0.0436712,
        store_longitude: -78.1403635,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.061603,
        store_longitude: -78.169183,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: 0.0440304,
        store_longitude: -78.1415909,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0470127,
        store_longitude: -78.1445145,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0427156,
        store_longitude: -78.1446899,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.046445,
        store_longitude: -78.1468,
    },
    {
        store_latitdue: 0.0448504,
        store_longitude: -78.1433826,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.047016,
        store_longitude: -78.147,
    },
    {
        store_latitdue: -0.0619429,
        store_longitude: -78.1687,
    },
    {
        store_latitdue: 0.1389637,
        store_longitude: -78.0797362,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: 0.1397932,
        store_longitude: -78.077333,
    },
    {
        store_latitdue: -0.027336,
        store_longitude: -78.260241,
        neighborhood: 'OTÓN',
    },
    {
        store_latitdue: -0.0273582,
        store_longitude: -78.2600383,
        neighborhood: 'OTÓN',
    },
    {
        store_latitdue: 0.0457256,
        store_longitude: -78.1433953,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0266491,
        store_longitude: -78.1439,
    },
    {
        store_latitdue: 0.0389206,
        store_longitude: -78.1418191,
    },
    {
        store_latitdue: 0.133333,
        store_longitude: -78.185658,
    },
    {
        store_latitdue: 0.0430403,
        store_longitude: -78.14583,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0364652,
        store_longitude: -78.1438001,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0441989,
        store_longitude: -78.164009,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0437756,
        store_longitude: -78.1635,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.045525,
        store_longitude: -78.1368,
    },
    {
        store_latitdue: 0.034743,
        store_longitude: -78.1451,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0434549,
        store_longitude: -78.1417,
    },
    {
        store_latitdue: 0.1388486,
        store_longitude: -78.0797562,
    },
    {
        store_latitdue: 0.0271159,
        store_longitude: -78.154177,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0678359,
        store_longitude: -78.1353356,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.0082466,
        store_longitude: -78.1817,
        neighborhood: 'CANGAHUA',
    },
    {
        store_latitdue: 0.0385751,
        store_longitude: -78.1471816,
    },
    {
        store_latitdue: 0.041384,
        store_longitude: -78.1455,
    },
    {
        store_latitdue: 0.0309754,
        store_longitude: -78.1427,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.071608,
        store_longitude: -78.13334,
    },
    {
        store_latitdue: 0.043172,
        store_longitude: -78.1402,
    },
    {
        store_latitdue: 0.033136,
        store_longitude: -78.1416,
    },
    {
        store_latitdue: 0.0456111,
        store_longitude: -78.1393,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0373199,
        store_longitude: -78.14242,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.139858,
        store_longitude: -78.077253,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0461329,
        store_longitude: -78.1391,
    },
    {
        store_latitdue: 0.047378,
        store_longitude: -78.1375,
    },
    {
        store_latitdue: 0.0409743,
        store_longitude: -78.1481056,
    },
    {
        store_latitdue: 0.1537893,
        store_longitude: -78.0670298,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: 0.0300792,
        store_longitude: -78.1539633,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.044946,
        store_longitude: -78.141093,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0435455,
        store_longitude: -78.1446,
    },
    {
        store_latitdue: 0.0415745,
        store_longitude: -78.1462566,
    },
    {
        store_latitdue: 0.03683313,
        store_longitude: -78.14867,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0418637,
        store_longitude: -78.1523924,
    },
    {
        store_latitdue: 0.040982,
        store_longitude: -78.1558,
    },
    {
        store_latitdue: 0.0413891,
        store_longitude: -78.1497725,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.040465,
        store_longitude: -78.1517325,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.038369,
        store_longitude: -78.1440746,
    },
    {
        store_latitdue: 0.0414393,
        store_longitude: -78.1452111,
    },
    {
        store_latitdue: 0.04043289,
        store_longitude: -78.149314,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0391165,
        store_longitude: -78.1476557,
    },
    {
        store_latitdue: 0.0433294,
        store_longitude: -78.1523189,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0459068,
        store_longitude: -78.2011632,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.028705,
        store_longitude: -78.1444,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0231725,
        store_longitude: -78.145968,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0466492,
        store_longitude: -78.1422934,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0428563,
        store_longitude: -78.1449,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0407725,
        store_longitude: -78.1552407,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.038753,
        store_longitude: -78.1535,
    },
    {
        store_latitdue: 0.047062,
        store_longitude: -78.1448,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.039097,
        store_longitude: -78.14487,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0503913,
        store_longitude: -78.1383,
    },
    {
        store_latitdue: 0.0458242,
        store_longitude: -78.141,
    },
    {
        store_latitdue: 0.046648,
        store_longitude: -78.140751,
    },
    {
        store_latitdue: 0.0442803,
        store_longitude: -78.1415,
    },
    {
        store_latitdue: 0.0490809,
        store_longitude: -78.1389043,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.305533,
        store_longitude: -78.5421,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },

    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.046907,
        store_longitude: -78.1412,
    },
    {
        store_latitdue: 0.02029496,
        store_longitude: -78.148018,
    },
    {
        store_latitdue: 0.0460921,
        store_longitude: -78.14,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.091928,
        store_longitude: -78.1504,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0277965,
        store_longitude: -78.152374,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0418851,
        store_longitude: -78.1413,
    },
    {
        store_latitdue: 0.044675,
        store_longitude: -78.139698,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.035119,
        store_longitude: -78.144161,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0439839,
        store_longitude: -78.1384171,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0398261,
        store_longitude: -78.1473265,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0460999,
        store_longitude: -78.144086,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.107762,
        store_longitude: -78.097704,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: 0.0803559,
        store_longitude: -78.1160386,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0278086,
        store_longitude: -78.1532422,
    },
    {
        store_latitdue: 0.0394251,
        store_longitude: -78.1524,
    },
    {
        store_latitdue: 0.047293,
        store_longitude: -78.1387,
    },
    {
        store_latitdue: 0.112953,
        store_longitude: -78.090906,
        neighborhood: 'OLMEDO',
    },
    {
        store_latitdue: 0.0469612,
        store_longitude: -78.1443761,
    },
    {
        store_latitdue: 0.0500108,
        store_longitude: -78.1376871,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0397618,
        store_longitude: -78.1423,
    },
    {
        store_latitdue: 0.0508355,
        store_longitude: -78.1419,
    },
    {
        store_latitdue: 0.0460377,
        store_longitude: -78.1353,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -2.811186,
        store_longitude: -78.994611,
    },
    {
        store_latitdue: 0.01774057,
        store_longitude: -78.160535,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0376869,
        store_longitude: -78.1430249,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.065799,
        store_longitude: -78.134309,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: 0.0405667,
        store_longitude: -78.1448,
        neighborhood: 'CAYAMBE',
    },
    {
        store_latitdue: -0.0647228,
        store_longitude: -78.3596463,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0647282,
        store_longitude: -78.3598523,
    },
    {
        store_latitdue: -0.063916666666667,
        store_longitude: -78.355823333333,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.06335,
        store_longitude: -78.354122,
    },
    {
        store_latitdue: -0.0606323,
        store_longitude: -78.3468383,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0582975,
        store_longitude: -78.3455335,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.053094,
        store_longitude: -78.343672,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0651619,
        store_longitude: -78.3614982,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0651855,
        store_longitude: -78.3620041,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.064788,
        store_longitude: -78.3618308,
    },
    {
        store_latitdue: -0.062732,
        store_longitude: -78.3603565,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.058193,
        store_longitude: -78.35136,
    },
    {
        store_latitdue: -0.0595658,
        store_longitude: -78.3448545,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.059488,
        store_longitude: -78.346319,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0587615,
        store_longitude: -78.3451454,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.062097,
        store_longitude: -78.3501544,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0715418,
        store_longitude: -78.3660355,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.063137,
        store_longitude: -78.360524,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0664795,
        store_longitude: -78.3534231,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0645882,
        store_longitude: -78.3592,
    },
    {
        store_latitdue: -0.0636464,
        store_longitude: -78.3551048,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0512787,
        store_longitude: -78.343249,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0637533,
        store_longitude: -78.3385409,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.062398,
        store_longitude: -78.342749,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.058588,
        store_longitude: -78.3451495,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.059198,
        store_longitude: -78.344,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.064723,
        store_longitude: -78.359989,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0615925,
        store_longitude: -78.3477,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0526173,
        store_longitude: -78.3436982,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0589256,
        store_longitude: -78.3445478,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0601977,
        store_longitude: -78.3437123,
    },
    {
        store_latitdue: -0.0538082,
        store_longitude: -78.3519475,
    },
    {
        store_latitdue: -0.0551072,
        store_longitude: -78.351786,
    },
    {
        store_latitdue: -0.059554,
        store_longitude: -78.346331,
    },
    {
        store_latitdue: -0.0478467,
        store_longitude: -78.3417215,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.062602,
        store_longitude: -78.3602876,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0581736,
        store_longitude: -78.3454952,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0630631,
        store_longitude: -78.3532559,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.059403,
        store_longitude: -78.34617,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0636476,
        store_longitude: -78.3621707,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0629629,
        store_longitude: -78.3525,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.059637,
        store_longitude: -78.346245,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0645397,
        store_longitude: -78.3590642,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.05229,
        store_longitude: -78.285356,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0649611,
        store_longitude: -78.3607037,
    },
    {
        store_latitdue: -0.0556313,
        store_longitude: -78.3525,
    },
    {
        store_latitdue: -0.0559412,
        store_longitude: -78.3484865,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0552824,
        store_longitude: -78.3442718,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: -0.0480617,
        store_longitude: -78.3416506,
        neighborhood: 'GUAYLLABAMBA',
    },
    {
        store_latitdue: 0.173706,
        store_longitude: -78.411166,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.1737641,
        store_longitude: -78.4110923,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.1716476,
        store_longitude: -78.4116405,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },

    {
        store_latitdue: 0.1718385,
        store_longitude: -78.4108484,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.176881,
        store_longitude: -78.4072,
    },
    {
        store_latitdue: 0.176573,
        store_longitude: -78.406209,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.168582,
        store_longitude: -78.413717,
    },
    {
        store_latitdue: 0.171473,
        store_longitude: -78.413,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.1707336,
        store_longitude: -78.4136642,
    },
    {
        store_latitdue: 0.1721549,
        store_longitude: -78.4108414,
    },
    {
        store_latitdue: 0.177047,
        store_longitude: -78.4071,
    },
    {
        store_latitdue: 0.1759362,
        store_longitude: -78.4066798,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.169018,
        store_longitude: -78.413689,
    },
    {
        store_latitdue: 0.1652094,
        store_longitude: -78.4178415,
    },
    {
        store_latitdue: 0.163054,
        store_longitude: -78.414758,
    },
    {
        store_latitdue: 0.110679,
        store_longitude: -78.422857,
        neighborhood: 'PERUCHO',
    },
    {
        store_latitdue: 0.1716373,
        store_longitude: -78.4107386,
        neighborhood: 'SAN JOSÉ DE MINAS',
    },
    {
        store_latitdue: 0.044561,
        store_longitude: -78.3492,
    },
    {
        store_latitdue: 0.055119,
        store_longitude: -78.341735,
    },
    {
        store_latitdue: 0.0528934,
        store_longitude: -78.3439,
    },
    {
        store_latitdue: 0.053316,
        store_longitude: -78.3437,
    },
    {
        store_latitdue: 0.043461,
        store_longitude: -78.35,
    },
    {
        store_latitdue: 0.0490391,
        store_longitude: -78.3465,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.048561,
        store_longitude: -78.346962,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0578308,
        store_longitude: -78.3398586,
        neighborhood: 'MALCHINGUÍ',
    },
    {
        store_latitdue: 0.0578544,
        store_longitude: -78.3397073,
    },
    {
        store_latitdue: -0.161734,
        store_longitude: -78.374817,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1679828,
        store_longitude: -78.3709842,
    },
    {
        store_latitdue: -0.1637,
        store_longitude: -78.373952,
    },
    {
        store_latitdue: -0.168024693,
        store_longitude: -78.37103826,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.170959,
        store_longitude: -78.369359,
    },
    {
        store_latitdue: -0.162858,
        store_longitude: -78.374348,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.175531,
        store_longitude: -78.368606,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.174662,
        store_longitude: -78.365592,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1733644,
        store_longitude: -78.3741033,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.162858,
        store_longitude: -78.374348,
    },
    {
        store_latitdue: -0.1581198,
        store_longitude: -78.437614,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1587622,
        store_longitude: -78.4393872,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.157338,
        store_longitude: -78.438878,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158309,
        store_longitude: -78.440819,
    },
    {
        store_latitdue: -0.158156,
        store_longitude: -78.440792,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.15826,
        store_longitude: -78.438936,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.15711,
        store_longitude: -78.440681,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.162122,
        store_longitude: -78.445518,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.15597,
        store_longitude: -78.4413306,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1558348,
        store_longitude: -78.4355609,
        neighborhood: 'NAYÓN',
    },

    {
        store_latitdue: -0.15754,
        store_longitude: -78.436103,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1596133,
        store_longitude: -78.4364414,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.170296,
        store_longitude: -78.440437,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158111,
        store_longitude: -78.437739,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1573434,
        store_longitude: -78.4399926,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1574753,
        store_longitude: -78.4377294,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.158183,
        store_longitude: -78.4395,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1559552,
        store_longitude: -78.4374913,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.159675,
        store_longitude: -78.433576,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.1742604,
        store_longitude: -78.4245769,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.172789,
        store_longitude: -78.423616,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.8471271,
        store_longitude: -80.1633124,
    },
    {
        store_latitdue: -0.17596,
        store_longitude: -78.359536,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1763755,
        store_longitude: -78.3583265,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.175609,
        store_longitude: -78.3597,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.178719,
        store_longitude: -78.358054,
    },
    {
        store_latitdue: -0.1781184,
        store_longitude: -78.3584846,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.17704,
        store_longitude: -78.359,
    },
    {
        store_latitdue: -0.1776643,
        store_longitude: -78.358924,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.175979,
        store_longitude: -78.3587,
    },
    {
        store_latitdue: -0.17711,
        store_longitude: -78.359046,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2028898,
        store_longitude: -78.3687948,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.193838,
        store_longitude: -78.365,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.199543,
        store_longitude: -78.3677,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.179746,
        store_longitude: -78.357463,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.178483,
        store_longitude: -78.358519,
        neighborhood: 'PUEMBO',
    },

    {
        store_latitdue: -0.1787617,
        store_longitude: -78.3581077,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.176306,
        store_longitude: -78.365055,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.178845,
        store_longitude: -78.3596,
    },
    {
        store_latitdue: -0.184168,
        store_longitude: -78.369383,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.181367,
        store_longitude: -78.356568,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.206911,
        store_longitude: -78.3669,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.203201,
        store_longitude: -78.363093,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.193648,
        store_longitude: -78.364983,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.200744,
        store_longitude: -78.3669,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1756155,
        store_longitude: -78.3587388,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1769118,
        store_longitude: -78.3646918,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.199879,
        store_longitude: -78.3691,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.214735,
        store_longitude: -78.359597,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.1748409,
        store_longitude: -78.3581151,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1758011,
        store_longitude: -78.3594648,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.2021979,
        store_longitude: -78.3636735,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.1836844,
        store_longitude: -78.3663719,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.231,
        store_longitude: -78.337081,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.231819,
        store_longitude: -78.337,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.229353,
        store_longitude: -78.336555,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.228744,
        store_longitude: -78.3372,
    },
    {
        store_latitdue: -0.225433,
        store_longitude: -78.339831,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2243743,
        store_longitude: -78.3374032,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.214628,
        store_longitude: -78.339765,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2243542,
        store_longitude: -78.3407552,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.234054,
        store_longitude: -78.334514,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.215943,
        store_longitude: -78.339697,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2205493,
        store_longitude: -78.3525906,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2240964,
        store_longitude: -78.3409069,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.231941,
        store_longitude: -78.3419058,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2284133,
        store_longitude: -78.3429256,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2279473,
        store_longitude: -78.340577,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.230373,
        store_longitude: -78.3417,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.203199,
        store_longitude: -78.344441,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.221715,
        store_longitude: -78.351908,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2002369,
        store_longitude: -78.3457217,
    },
    {
        store_latitdue: -0.2225087,
        store_longitude: -78.3508536,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2245723,
        store_longitude: -78.3405378,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.224467,
        store_longitude: -78.33755,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2251687,
        store_longitude: -78.3404684,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.201915,
        store_longitude: -78.3798,
    },
    {
        store_latitdue: -0.2264913,
        store_longitude: -78.3390531,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.229823,
        store_longitude: -78.33903,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2282212,
        store_longitude: -78.3393932,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.22113,
        store_longitude: -78.352147,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.214048,
        store_longitude: -78.339884,
    },
    {
        store_latitdue: -0.2201241,
        store_longitude: -78.3387167,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.210774,
        store_longitude: -78.3411,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.2199024,
        store_longitude: -78.3387798,
        neighborhood: 'PIFO',
    },

    {
        store_latitdue: -0.218849,
        store_longitude: -78.3371,
    },
    {
        store_latitdue: -0.2291956,
        store_longitude: -78.3367657,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.209307,
        store_longitude: -78.3418752,
    },
    {
        store_latitdue: -0.224636,
        store_longitude: -78.340529,
    },
    {
        store_latitdue: -0.201188,
        store_longitude: -78.34534,
        neighborhood: 'PUEMBO',
    },
    {
        store_latitdue: -0.23523,
        store_longitude: -78.3365,
    },
    {
        store_latitdue: -0.22503,
        store_longitude: -78.336662,
        neighborhood: 'PIFO',
    },

    {
        store_latitdue: 0.0659992,
        store_longitude: -78.4032,
    },
    {
        store_latitdue: 0.0658675,
        store_longitude: -78.4033063,
    },
    {
        store_latitdue: 0.0635331,
        store_longitude: -78.4038835,
    },
    {
        store_latitdue: 0.068633,
        store_longitude: -78.402959,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.066081,
        store_longitude: -78.403132,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.065307,
        store_longitude: -78.403264,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.041039,
        store_longitude: -78.407,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.0420108,
        store_longitude: -78.4073621,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.0662122,
        store_longitude: -78.4024278,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0.0425032,
        store_longitude: -78.4020398,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0340157,
        store_longitude: -78.4048172,
        neighborhood: 'PUÉLLARO',
    },
    {
        store_latitdue: -0.107675,
        store_longitude: -78.299164,
    },
    {
        store_latitdue: -0.1079589,
        store_longitude: -78.298052,
    },
    {
        store_latitdue: -0.1083843,
        store_longitude: -78.2974296,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.110232,
        store_longitude: -78.298989,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.111548,
        store_longitude: -78.2977,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1103966,
        store_longitude: -78.2944444,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.110309,
        store_longitude: -78.293197,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.101421,
        store_longitude: -78.314499,
    },
    {
        store_latitdue: -0.1071355,
        store_longitude: -78.3006505,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.102763,
        store_longitude: -78.310531,
    },
    {
        store_latitdue: -0.1101151,
        store_longitude: -78.2931546,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1101467,
        store_longitude: -78.2989342,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.109964,
        store_longitude: -78.295516,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.11063,
        store_longitude: -78.2987,
    },
    {
        store_latitdue: -0.107812,
        store_longitude: -78.29718,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.113619,
        store_longitude: -78.294087,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.110041,
        store_longitude: -78.2931807,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.10105,
        store_longitude: -78.297083,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.083831,
        store_longitude: -78.342906,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.081889,
        store_longitude: -78.308357,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.086596,
        store_longitude: -78.309329,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.079032,
        store_longitude: -78.340859,
    },
    {
        store_latitdue: -0.103117,
        store_longitude: -78.314792,
    },
    {
        store_latitdue: -0.081173,
        store_longitude: -78.308816,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.079625,
        store_longitude: -78.341062,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.078936,
        store_longitude: -78.340818,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.129088,
        store_longitude: -78.292552,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.125157,
        store_longitude: -78.293104,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.109877,
        store_longitude: -78.297571,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.109684,
        store_longitude: -78.292106,
    },
    {
        store_latitdue: -0.127394,
        store_longitude: -78.296635,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0974077,
        store_longitude: -78.29,
    },

    {
        store_latitdue: -0.0968651,
        store_longitude: -78.2885671,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.112203,
        store_longitude: -78.296944,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0815017,
        store_longitude: -78.3091562,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.13571,
        store_longitude: -78.295443,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.133739,
        store_longitude: -78.295755,
        neighborhood: 'CHECA',
    },
    {
        store_latitdue: -0.110147,
        store_longitude: -78.298983,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.082128,
        store_longitude: -78.310343,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1134966,
        store_longitude: -78.2940455,
    },
    {
        store_latitdue: -0.111623,
        store_longitude: -78.2934233,
    },
    {
        store_latitdue: -0.128427,
        store_longitude: -78.294928,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.10156,
        store_longitude: -78.313705,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1061263,
        store_longitude: -78.3018417,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1994004,
        store_longitude: -78.3324044,
    },
    {
        store_latitdue: -0.107777,
        store_longitude: -78.298432,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1077475,
        store_longitude: -78.295005,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1134298,
        store_longitude: -78.2939858,
    },
    {
        store_latitdue: -0.0578838,
        store_longitude: -78.3453,
    },
    {
        store_latitdue: -0.096989,
        store_longitude: -78.290723,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1033837,
        store_longitude: -78.2935091,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1124575,
        store_longitude: -78.29561,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1244416,
        store_longitude: -78.2956109,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1263536,
        store_longitude: -78.2982518,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1104034,
        store_longitude: -78.2944548,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.109699,
        store_longitude: -78.3004034,
    },
    {
        store_latitdue: -0.0815605,
        store_longitude: -78.3422836,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.112799,
        store_longitude: -78.296336,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.124202,
        store_longitude: -78.292844,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.126806,
        store_longitude: -78.291575,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.1127896,
        store_longitude: -78.2962478,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.101955,
        store_longitude: -78.3127,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.0974746,
        store_longitude: -78.2883917,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0467173,
        store_longitude: -78.2257649,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.04639,
        store_longitude: -78.2274,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0459619,
        store_longitude: -78.201492,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0474326,
        store_longitude: -78.2092,
    },
    {
        store_latitdue: 0.0486045,
        store_longitude: -78.2086,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.047261,
        store_longitude: -78.214988,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0470399,
        store_longitude: -78.215546,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: -0.475122,
        store_longitude: -78.22336,
    },
    {
        store_latitdue: 0.0484764,
        store_longitude: -78.2212539,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0471209,
        store_longitude: -78.221791,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.047044,
        store_longitude: -78.218411,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0446189,
        store_longitude: -78.225838,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.043186,
        store_longitude: -78.2119892,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.050733,
        store_longitude: -78.2157,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.050806,
        store_longitude: -78.221893,
    },
    {
        store_latitdue: 0.048537,
        store_longitude: -78.220566,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0467176,
        store_longitude: -78.2122,
    },
    {
        store_latitdue: 0.0461489,
        store_longitude: -78.219439,
    },
    {
        store_latitdue: 0.0403677,
        store_longitude: -78.2498,
    },
    {
        store_latitdue: 0.0471747,
        store_longitude: -78.2164799,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1622991,
        store_longitude: -78.3177621,
    },
    {
        store_latitdue: 0.047228,
        store_longitude: -78.2166315,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0465581,
        store_longitude: -78.2121,
    },
    {
        store_latitdue: 0.0625955,
        store_longitude: -78.2002635,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.062478,
        store_longitude: -78.200267,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0623868,
        store_longitude: -78.2003314,
    },
    {
        store_latitdue: 0.0475418,
        store_longitude: -78.2092,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0467,
        store_longitude: -78.2008891,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.046322,
        store_longitude: -78.228424,
    },
    {
        store_latitdue: 0.227226,
        store_longitude: -78.2673,
    },
    {
        store_latitdue: 0.0466123,
        store_longitude: -78.2121894,
    },
    {
        store_latitdue: 0.0459619,
        store_longitude: -78.201537,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0462185,
        store_longitude: -78.1950261,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: 0.0492002,
        store_longitude: -78.2172,
    },
    {
        store_latitdue: 0.0433518,
        store_longitude: -78.2282,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0457309,
        store_longitude: -78.210866,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0404099,
        store_longitude: -78.220076,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.04683,
        store_longitude: -78.200832,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0506668,
        store_longitude: -78.2165,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0470382,
        store_longitude: -78.2205,
    },
    {
        store_latitdue: 0.0400697,
        store_longitude: -78.2202319,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0468628,
        store_longitude: -78.2126,
    },
    {
        store_latitdue: 0.0835161,
        store_longitude: -78.2024,
        neighborhood: 'TUPIGACHI',
    },
    {
        store_latitdue: 0.0469954,
        store_longitude: -78.2167,
    },
    {
        store_latitdue: 0.045334,
        store_longitude: -78.219439,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: 0.0474057,
        store_longitude: -78.2149,
        neighborhood: 'TABACUNDO',
    },
    {
        store_latitdue: -0.180112,
        store_longitude: -78.438337,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.181004,
        store_longitude: -78.444436,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.180209,
        store_longitude: -78.4383,
    },
    {
        store_latitdue: -0.1790247,
        store_longitude: -78.4398185,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.174978,
        store_longitude: -78.446543,
        neighborhood: 'NAYÓN',
    },
    {
        store_latitdue: -0.172935,
        store_longitude: -78.447062,
        neighborhood: 'NAYÓN',
    },

    {
        store_latitdue: -0.188587,
        store_longitude: -78.33653,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.186211,
        store_longitude: -78.343143,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.189913,
        store_longitude: -78.340834,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.181629,
        store_longitude: -78.346375,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.1873392,
        store_longitude: -78.3422325,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.186211,
        store_longitude: -78.343143,
    },
    {
        store_latitdue: -0.1844716,
        store_longitude: -78.3446329,
    },
    {
        store_latitdue: -0.162071,
        store_longitude: -78.320318,
    },
    {
        store_latitdue: -0.1628141,
        store_longitude: -78.3169281,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.175621,
        store_longitude: -78.328139,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.195423,
        store_longitude: -78.330969,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.198652,
        store_longitude: -78.32593,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.196411,
        store_longitude: -78.331445,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1995154,
        store_longitude: -78.332672,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.163861,
        store_longitude: -78.311206,
    },
    {
        store_latitdue: -0.162507,
        store_longitude: -78.3162553,
    },
    {
        store_latitdue: -0.160911,
        store_longitude: -78.320164,
    },
    {
        store_latitdue: -0.162497,
        store_longitude: -78.3198,
    },
    {
        store_latitdue: -0.1622611,
        store_longitude: -78.3187418,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1619306,
        store_longitude: -78.3187874,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.16177,
        store_longitude: -78.318804,
    },
    {
        store_latitdue: -0.165416,
        store_longitude: -78.3236598,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.161266,
        store_longitude: -78.3175,
    },
    {
        store_latitdue: -0.157007,
        store_longitude: -78.317874,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.164382,
        store_longitude: -78.32248,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1620393,
        store_longitude: -78.3188685,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.203177,
        store_longitude: -78.330313,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1994551,
        store_longitude: -78.3324123,
    },
    {
        store_latitdue: -0.1582347,
        store_longitude: -78.3210526,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1996094,
        store_longitude: -78.3326718,
    },
    {
        store_latitdue: -0.164026,
        store_longitude: -78.309969,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1997578,
        store_longitude: -78.3330116,
    },
    {
        store_latitdue: -0.164849,
        store_longitude: -78.3164732,
    },
    {
        store_latitdue: -0.128497,
        store_longitude: -78.294395,
        neighborhood: 'EL QUINCHE',
    },
    {
        store_latitdue: -0.175923,
        store_longitude: -78.328266,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.194322,
        store_longitude: -78.328313,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1653655,
        store_longitude: -78.3240075,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.165201,
        store_longitude: -78.310899,
    },
    {
        store_latitdue: -0.1667595,
        store_longitude: -78.3144105,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1682882,
        store_longitude: -78.3122607,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.161992,
        store_longitude: -78.3194,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.191549,
        store_longitude: -78.329942,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1997278,
        store_longitude: -78.3270836,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.162841,
        store_longitude: -78.31637,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.2162424,
        store_longitude: -78.40736,
    },

    {
        store_latitdue: -0.1617412,
        store_longitude: -78.3172378,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1648325,
        store_longitude: -78.3230474,
    },
    {
        store_latitdue: -0.198719,
        store_longitude: -78.328512,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.218846,
        store_longitude: -78.339,
    },

    {
        store_latitdue: -0.1437063,
        store_longitude: -78.3413069,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1709119,
        store_longitude: -78.3322223,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1620597,
        store_longitude: -78.316188,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.1900673,
        store_longitude: -78.3308298,
        neighborhood: 'TABABELA',
    },
    {
        store_latitdue: -0.166408,
        store_longitude: -78.327755,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.167821,
        store_longitude: -78.330038,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.195494,
        store_longitude: -78.327,
        neighborhood: 'YARUQUÍ',
    },
    {
        store_latitdue: -0.199981,
        store_longitude: -78.333068,
    },
    {
        store_latitdue: -0.189455,
        store_longitude: -78.3341,
        neighborhood: 'TABABELA',
    },

    {
        store_latitdue: -0.1488683,
        store_longitude: -78.4433849,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1492071,
        store_longitude: -78.440147,
    },
    {
        store_latitdue: -0.148607,
        store_longitude: -78.439615,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.147753,
        store_longitude: -78.442145,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1499825,
        store_longitude: -78.436873,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1493267,
        store_longitude: -78.4373295,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.14957,
        store_longitude: -78.440734,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.148477,
        store_longitude: -78.442397,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.148381,
        store_longitude: -78.44269,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.148395,
        store_longitude: -78.442618,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1491138,
        store_longitude: -78.443174,
    },
    {
        store_latitdue: -0.1482731,
        store_longitude: -78.4431985,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1487971,
        store_longitude: -78.4415128,
    },
    {
        store_latitdue: -0.147753,
        store_longitude: -78.442145,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.14941,
        store_longitude: -78.437078,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.149574,
        store_longitude: -78.436607,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.149977,
        store_longitude: -78.435287,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.149416,
        store_longitude: -78.439568,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1487636,
        store_longitude: -78.4414471,
    },
    {
        store_latitdue: -0.148484,
        store_longitude: -78.441095,
    },
    {
        store_latitdue: -0.148895,
        store_longitude: -78.440525,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.1493358,
        store_longitude: -78.4398926,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.149138,
        store_longitude: -78.440555,
        neighborhood: 'ZÁMBIZA',
    },
    {
        store_latitdue: -0.2376393493,
        store_longitude: -78.3323133364,
    },
    {
        store_latitdue: -0.244661,
        store_longitude: -78.330734,
        neighborhood: 'PIFO',
    },
    {
        store_latitdue: -0.0506477,
        store_longitude: -78.4566,
    },
    {
        store_latitdue: -0.2028913,
        store_longitude: -78.4954297,
    },
    {
        store_latitdue: -0.1337349,
        store_longitude: -78.4768364,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115302,
        store_longitude: -78.470299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11585894040763,
        store_longitude: -78.478519711643,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.141059,
        store_longitude: -78.4810995,
    },
    {
        store_latitdue: -0.124128,
        store_longitude: -78.4665,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130035,
        store_longitude: -78.466415,
    },
    {
        store_latitdue: -0.1109501,
        store_longitude: -78.4737631,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0830686,
        store_longitude: -78.4702847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085637,
        store_longitude: -78.4734509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114981,
        store_longitude: -78.4614,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.121904,
        store_longitude: -78.4932,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113603,
        store_longitude: -78.491501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127443,
        store_longitude: -78.486164,
    },
    {
        store_latitdue: -0.1413091,
        store_longitude: -78.5015509,
    },
    {
        store_latitdue: -0.08653,
        store_longitude: -78.4747006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.141216,
        store_longitude: -78.4602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135104,
        store_longitude: -78.4677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1260743,
        store_longitude: -78.4658418,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128267,
        store_longitude: -78.469704,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115099,
        store_longitude: -78.4612,
    },
    {
        store_latitdue: -0.102871,
        store_longitude: -78.468399,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1293151,
        store_longitude: -78.4696585,
    },
    {
        store_latitdue: -0.117541,
        store_longitude: -78.4852,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112111,
        store_longitude: -78.460098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128763,
        store_longitude: -78.4865,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082386,
        store_longitude: -78.470001,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0857909,
        store_longitude: -78.4735,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089287,
        store_longitude: -78.4707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129745,
        store_longitude: -78.476227,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0999955,
        store_longitude: -78.4222815,
    },
    {
        store_latitdue: -0.137823,
        store_longitude: -78.4785,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.137628,
        store_longitude: -78.4775,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135048,
        store_longitude: -78.486573,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1124234,
        store_longitude: -78.4828699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075403600931168,
        store_longitude: -78.460379932076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1175597,
        store_longitude: -78.4885886,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12098,
        store_longitude: -78.4687,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122029,
        store_longitude: -78.470665,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0766445,
        store_longitude: -78.4682805,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115884,
        store_longitude: -78.488602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.156054,
        store_longitude: -78.4749727,
    },
    {
        store_latitdue: -0.1233959,
        store_longitude: -78.465092,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1318577,
        store_longitude: -78.4660065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125721,
        store_longitude: -78.482327,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1170554,
        store_longitude: -78.4583286,
    },
    {
        store_latitdue: -0.1214242,
        store_longitude: -78.4629152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1413,
        store_longitude: -78.4819,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10469315573573,
        store_longitude: -78.483675252646,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127495,
        store_longitude: -78.4872,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1349432,
        store_longitude: -78.4531108,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1495232,
        store_longitude: -78.4814109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121684,
        store_longitude: -78.4693,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118952,
        store_longitude: -78.4865,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145113,
        store_longitude: -78.4716,
    },
    {
        store_latitdue: -0.10281912982464,
        store_longitude: -78.486043224111,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113483,
        store_longitude: -78.491798,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.89201,
        store_longitude: -78.9818,
    },
    {
        store_latitdue: -0.0753771,
        store_longitude: -78.4599447,
    },
    {
        store_latitdue: -0.088168,
        store_longitude: -78.469002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0751732,
        store_longitude: -78.4617,
    },
    {
        store_latitdue: -0.0781924,
        store_longitude: -78.4736752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0750275,
        store_longitude: -78.4582,
    },
    {
        store_latitdue: -0.180299,
        store_longitude: -78.494609,
    },
    {
        store_latitdue: -0.1254409,
        store_longitude: -78.4507567,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.0756376,
        store_longitude: -78.4583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1218349,
        store_longitude: -78.4931346,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1228669,
        store_longitude: -78.4852279,
    },
    {
        store_latitdue: -0.115934,
        store_longitude: -78.4596,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1199842,
        store_longitude: -78.4515297,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.077443,
        store_longitude: -78.471603,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12757,
        store_longitude: -78.4511,
    },
    {
        store_latitdue: -0.0753241,
        store_longitude: -78.4609,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0881846,
        store_longitude: -78.4742,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.111531,
        store_longitude: -78.4649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0746174,
        store_longitude: -78.4594,
    },
    {
        store_latitdue: -0.11930025648326,
        store_longitude: -78.464869447052,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110365,
        store_longitude: -78.445194,
    },
    {
        store_latitdue: -0.127773,
        store_longitude: -78.459503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852048,
        store_longitude: -78.4712,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114213,
        store_longitude: -78.4946,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117576,
        store_longitude: -78.483704,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11996728833765,
        store_longitude: -78.467135578394,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1200913,
        store_longitude: -78.4652269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140153,
        store_longitude: -78.4761,
    },
    {
        store_latitdue: -0.0749593,
        store_longitude: -78.46446,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1282121,
        store_longitude: -78.4695291,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113561,
        store_longitude: -78.4749214,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11671246960759,
        store_longitude: -78.483443073928,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11751,
        store_longitude: -78.4836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11494509,
        store_longitude: -78.4861221,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1158126,
        store_longitude: -78.4955478,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123222,
        store_longitude: -78.4688,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11365,
        store_longitude: -78.492699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1203542,
        store_longitude: -78.4587035,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1207914,
        store_longitude: -78.4581788,
    },
    {
        store_latitdue: -0.128166,
        store_longitude: -78.462097,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.074852323159575,
        store_longitude: -78.458181358874,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1446994,
        store_longitude: -78.482056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121279,
        store_longitude: -78.4683,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128556,
        store_longitude: -78.4767,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114805,
        store_longitude: -78.461845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119834,
        store_longitude: -78.452202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0740102,
        store_longitude: -78.4605191,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1113875,
        store_longitude: -78.4886994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11742556001991,
        store_longitude: -78.4836671222,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103813,
        store_longitude: -78.4695,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113897,
        store_longitude: -78.4875,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1041817,
        store_longitude: -78.4901119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1198624,
        store_longitude: -78.4670966,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1309249,
        store_longitude: -78.4739127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1170467,
        store_longitude: -78.4920567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13673319015652,
        store_longitude: -78.457736698911,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1195446,
        store_longitude: -78.4678423,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103513,
        store_longitude: -78.4699,
    },
    {
        store_latitdue: -0.129325,
        store_longitude: -78.460602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11149854864925,
        store_longitude: -78.488310612738,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107375,
        store_longitude: -78.492241,
    },
    {
        store_latitdue: -0.192239,
        store_longitude: -78.509099,
    },
    {
        store_latitdue: -0.10291703045368,
        store_longitude: -78.489302610978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1145451,
        store_longitude: -78.489279,
    },
    {
        store_latitdue: -0.11705833333333,
        store_longitude: -78.492193333333,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1393978,
        store_longitude: -78.472141,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1405576011166,
        store_longitude: -78.46130663529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1354817,
        store_longitude: -78.48625,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10184112936258,
        store_longitude: -78.485780032352,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12001,
        store_longitude: -78.466958,
    },
    {
        store_latitdue: -0.115987,
        store_longitude: -78.4803121,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0934583,
        store_longitude: -78.4786017,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1192745,
        store_longitude: -78.4852362,
    },
    {
        store_latitdue: -0.1332955,
        store_longitude: -78.4549149,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11425,
        store_longitude: -78.493896,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1071302,
        store_longitude: -78.47312559,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0751815,
        store_longitude: -78.4657897,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113713,
        store_longitude: -78.4920378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138412,
        store_longitude: -78.4591,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1201361,
        store_longitude: -78.480984,
    },
    {
        store_latitdue: -0.117394,
        store_longitude: -78.4664,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1264549,
        store_longitude: -78.4731072,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135944,
        store_longitude: -78.481903,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1374475,
        store_longitude: -78.47962,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082527,
        store_longitude: -78.475624,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.16283572717545,
        store_longitude: -78.474283582651,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1152594,
        store_longitude: -78.491884,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.094395,
        store_longitude: -78.4799495,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0990806,
        store_longitude: -78.4765163,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1170791,
        store_longitude: -78.4561115,
    },
    {
        store_latitdue: -0.0796204,
        store_longitude: -78.4614,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1147202,
        store_longitude: -78.4872677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1284803,
        store_longitude: -78.4702404,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0797497,
        store_longitude: -78.4147552,
    },
    {
        store_latitdue: -0.123108,
        store_longitude: -78.485271,
    },
    {
        store_latitdue: -0.0827782,
        store_longitude: -78.4769567,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13417,
        store_longitude: -78.482602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1085435,
        store_longitude: -78.2974248,
    },
    {
        store_latitdue: -0.0814791,
        store_longitude: -78.4087282,
    },
    {
        store_latitdue: -0.0799309,
        store_longitude: -78.468,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1135297,
        store_longitude: -78.4946098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.126233,
        store_longitude: -78.4919,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1211038,
        store_longitude: -78.4657831,
    },
    {
        store_latitdue: -0.129767,
        store_longitude: -78.468916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103026,
        store_longitude: -78.4843456,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1139517,
        store_longitude: -78.46282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1028792,
        store_longitude: -78.4845353,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0754055,
        store_longitude: -78.4628349,
    },
    {
        store_latitdue: -0.0754107,
        store_longitude: -78.4628261,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.105835,
        store_longitude: -78.481133,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.136662,
        store_longitude: -78.466512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1160088,
        store_longitude: -78.4790973,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096501,
        store_longitude: -78.52027,
    },
    {
        store_latitdue: -0.1246341,
        store_longitude: -78.4858698,
    },
    {
        store_latitdue: -0.1138333,
        store_longitude: -78.4925717,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1240998,
        store_longitude: -78.4708858,
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1159233,
        store_longitude: -78.4582134,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2252605,
        store_longitude: -78.5119424,
    },
    {
        store_latitdue: -0.123286,
        store_longitude: -78.4888772,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1172847,
        store_longitude: -78.4942845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1248117,
        store_longitude: -78.467735,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0859129,
        store_longitude: -78.4754346,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.225009,
        store_longitude: -78.5152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130106,
        store_longitude: -78.4739,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138847,
        store_longitude: -78.4706,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.073917,
        store_longitude: -78.455299,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1161814,
        store_longitude: -78.4882173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1972041,
        store_longitude: -78.51223859,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1538127,
        store_longitude: -78.4728735,
    },
    {
        store_latitdue: -0.204732,
        store_longitude: -78.498512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1286798,
        store_longitude: -78.4861379,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1170533,
        store_longitude: -78.4919485,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.113516,
        store_longitude: -78.4917593,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1355161,
        store_longitude: -78.4866948,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2207746,
        store_longitude: -78.5110314,
    },
    {
        store_latitdue: -0.157975,
        store_longitude: -78.475565,
    },
    {
        store_latitdue: -0.114474,
        store_longitude: -78.4877822,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1283922,
        store_longitude: -78.4875648,
    },
    {
        store_latitdue: -0.103569,
        store_longitude: -78.4698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0753354,
        store_longitude: -78.4644354,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123245,
        store_longitude: -78.4889,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0767828,
        store_longitude: -78.4671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124299,
        store_longitude: -78.4674445,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.133954,
        store_longitude: -78.465369,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124175,
        store_longitude: -78.48545,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1272722,
        store_longitude: -78.4937891,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1182149,
        store_longitude: -78.4626274,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0779748094,
        store_longitude: -78.47323525,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1406833,
        store_longitude: -78.4797926,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124074,
        store_longitude: -78.4654254,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1239877,
        store_longitude: -78.467091,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0767839,
        store_longitude: -78.4674,
    },

    {
        store_latitdue: -0.1283376,
        store_longitude: -78.470065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110684,
        store_longitude: -78.478995,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1164751,
        store_longitude: -78.4585984,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852789,
        store_longitude: -78.4674994,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1868705,
        store_longitude: -78.480597,
    },
    {
        store_latitdue: -0.1241094,
        store_longitude: -78.4669529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129185,
        store_longitude: -78.48734,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1295855,
        store_longitude: -78.4837791,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1321299,
        store_longitude: -78.4937129,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125101,
        store_longitude: -78.486099,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1215336,
        store_longitude: -78.4660893,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12641441,
        store_longitude: -78.4678426,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1147201,
        store_longitude: -78.4928779,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -2.20009,
        store_longitude: -79.1208,
    },
    {
        store_latitdue: -0.1157977,
        store_longitude: -78.4695851,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1372497,
        store_longitude: -78.4862893,
    },
    {
        store_latitdue: -0.0873747,
        store_longitude: -78.4725167,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1127728,
        store_longitude: -78.4866431,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1251895,
        store_longitude: -78.467979,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0983929,
        store_longitude: -78.4789054,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118284,
        store_longitude: -78.455105,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1135674,
        store_longitude: -78.4738901,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852853,
        store_longitude: -78.4682623,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1319874,
        store_longitude: -78.4524125,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.102709,
        store_longitude: -78.469223,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10426,
        store_longitude: -78.4257,
    },
    {
        store_latitdue: -0.0951394,
        store_longitude: -78.4813231,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0989851,
        store_longitude: -78.4765,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1563002,
        store_longitude: -78.4795164,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1113197,
        store_longitude: -78.4642245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1290709,
        store_longitude: -78.4599821,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09642,
        store_longitude: -78.4724966,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11772877,
        store_longitude: -78.4831835,
    },
    {
        store_latitdue: 0.3373629,
        store_longitude: -78.1349332,
        neighborhood: 'SAN MIGUEL DE IBARRA',
    },
    {
        store_latitdue: -0.0783185,
        store_longitude: -78.4739,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0902024,
        store_longitude: -78.4742935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1133081,
        store_longitude: -78.48873,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.104332,
        store_longitude: -78.4917,
    },
    {
        store_latitdue: -0.114789,
        store_longitude: -78.4898,
    },
    {
        store_latitdue: -0.1234489,
        store_longitude: -78.487254,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11383,
        store_longitude: -78.492585,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1187993,
        store_longitude: -78.4625263,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1159825,
        store_longitude: -78.4788184,
    },
    {
        store_latitdue: -0.1177312,
        store_longitude: -78.4823063,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1364439,
        store_longitude: -78.4864368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0882006,
        store_longitude: -78.4755585,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1125585,
        store_longitude: -78.4884649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1367734,
        store_longitude: -78.4556471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123096,
        store_longitude: -78.4657139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124993,
        store_longitude: -78.4674,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1223083,
        store_longitude: -78.4663533,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12159,
        store_longitude: -78.479251,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238669,
        store_longitude: -78.4793741,
    },
    {
        store_latitdue: -0.116771,
        store_longitude: -78.4815,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0885001,
        store_longitude: -78.4693478,
    },
    {
        store_latitdue: -0.0873123,
        store_longitude: -78.4724377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1068984,
        store_longitude: -78.4792156,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.20689,
        store_longitude: -78.374184,
        neighborhood: 'TUMBACO',
    },
    {
        store_latitdue: -0.11602993,
        store_longitude: -78.4739554,
    },
    {
        store_latitdue: -0.085367,
        store_longitude: -78.468102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11223,
        store_longitude: -78.4914,
    },
    {
        store_latitdue: -0.0882972,
        store_longitude: -78.4691,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.104728469,
        store_longitude: -78.4865400195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135532,
        store_longitude: -78.4800547,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0752684,
        store_longitude: -78.4603007,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083208,
        store_longitude: -78.470213,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121535,
        store_longitude: -78.47033,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0768474,
        store_longitude: -78.4664305,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0767344,
        store_longitude: -78.4671953,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082985,
        store_longitude: -78.4702018,
    },
    {
        store_latitdue: -0.07790212,
        store_longitude: -78.4572813,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127428,
        store_longitude: -78.4859253,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0763244,
        store_longitude: -78.4658978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0753832,
        store_longitude: -78.4625314,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0886542,
        store_longitude: -78.4266,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.085372,
        store_longitude: -78.4723293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1136717,
        store_longitude: -78.4918367,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119736,
        store_longitude: -78.452308,
    },
    {
        store_latitdue: -0.1206353,
        store_longitude: -78.4846583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09104,
        store_longitude: -78.469461,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12342629,
        store_longitude: -78.4878005,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12016,
        store_longitude: -78.4843,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1165632,
        store_longitude: -78.4583977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125199,
        store_longitude: -78.4644,
    },
    {
        store_latitdue: -0.1287785,
        store_longitude: -78.4856534,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1186564,
        store_longitude: -78.4670347,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0865499,
        store_longitude: -78.4723175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 985134939,
        store_longitude: -78.4689407,
        neighborhood: 'CHITO',
    },
    {
        store_latitdue: -0.11928,
        store_longitude: -78.45344,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1284779,
        store_longitude: -78.4602424,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11658584,
        store_longitude: -78.4870576,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122063,
        store_longitude: -78.493698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14051,
        store_longitude: -78.465393,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0834854024,
        store_longitude: -78.4664817899,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103755,
        store_longitude: -78.489782,
    },
    {
        store_latitdue: -0.0853425,
        store_longitude: -78.4681448,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126372,
        store_longitude: -78.4677838,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12539863586426,
        store_longitude: -78.464913368225,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1270758,
        store_longitude: -78.4868895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0836735,
        store_longitude: -78.4777887,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1339393052,
        store_longitude: -78.463326195,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1136983,
        store_longitude: -78.4920033,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0753975,
        store_longitude: -78.4622096,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1175016,
        store_longitude: -78.4849976,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1137817,
        store_longitude: -78.4920817,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1210586,
        store_longitude: -78.465732,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135409,
        store_longitude: -78.4569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.0154443,
        store_longitude: -78.4400329,
        neighborhood: 'SAN ANTONIO',
    },
    {
        store_latitdue: -0.129699,
        store_longitude: -78.4598119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090565,
        store_longitude: -78.4702317,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.17922183871269,
        store_longitude: -78.491264343262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1298738,
        store_longitude: -78.4664229,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1266655,
        store_longitude: -78.4649786,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1380754,
        store_longitude: -78.4807621,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122873,
        store_longitude: -78.4635191,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1311411,
        store_longitude: -78.4682442,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089780064,
        store_longitude: -78.4693122997,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0786587,
        store_longitude: -78.4749269,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0875822,
        store_longitude: -78.4758184,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1135677,
        store_longitude: -78.4886407,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1220237,
        store_longitude: -78.4910439,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1220049,
        store_longitude: -78.4706422,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119945,
        store_longitude: -78.4671729,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105459,
        store_longitude: -78.499641,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1353831,
        store_longitude: -78.4705375,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1254762,
        store_longitude: -78.4612063,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116688,
        store_longitude: -78.4835,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.075994,
        store_longitude: -78.45709,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1254515,
        store_longitude: -78.4703302,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13679,
        store_longitude: -78.4733,
    },
    {
        store_latitdue: -0.138236,
        store_longitude: -78.480513,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1294616,
        store_longitude: -78.4758905,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085231,
        store_longitude: -78.468971,
    },

    {
        store_latitdue: -0.0780169,
        store_longitude: -78.4733382,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.8164393,
        store_longitude: -80.0471449,
        neighborhood: 'GALERA',
    },
    {
        store_latitdue: -0.1307085,
        store_longitude: -78.4718508,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127602,
        store_longitude: -78.450938,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.12721103,
        store_longitude: -78.4552939,
    },

    {
        store_latitdue: -0.0752502,
        store_longitude: -78.4581802,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1172118,
        store_longitude: -78.4885303,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.134526847,
        store_longitude: -78.4867694941,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1241647225,
        store_longitude: -78.4654477984,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0918969,
        store_longitude: -78.5248,
    },

    {
        store_latitdue: -0.1201199,
        store_longitude: -78.463258,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1291049,
        store_longitude: -78.4982862,
    },
    {
        store_latitdue: -0.1312417134,
        store_longitude: -78.4785467014,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.127349,
        store_longitude: -78.465541,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1402893,
        store_longitude: -78.4704199,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0839146,
        store_longitude: -78.4741285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0811191,
        store_longitude: -78.4715216,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075426,
        store_longitude: -78.46259,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0790402,
        store_longitude: -78.4730235,
    },

    {
        store_latitdue: -0.0889965,
        store_longitude: -78.4734655,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1135594,
        store_longitude: -78.4749977,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11595769,
        store_longitude: -78.4793014,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1020599,
        store_longitude: -78.4689488,
    },
    {
        store_latitdue: -0.11815,
        store_longitude: -78.4828,
    },

    {
        store_latitdue: -0.1167455,
        store_longitude: -78.462841,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115302,
        store_longitude: -78.4607,
    },
    {
        store_latitdue: -0.11632464,
        store_longitude: -78.4606624,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1165021,
        store_longitude: -78.4557137,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1151242,
        store_longitude: -78.4595844,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1271768,
        store_longitude: -78.4636731,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0915187,
        store_longitude: -78.4684801,
    },
    {
        store_latitdue: -0.07551354,
        store_longitude: -78.4572221,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0858357,
        store_longitude: -78.4702973,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.116682717,
        store_longitude: -78.4938285872,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1146373,
        store_longitude: -78.4868968,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.135483,
        store_longitude: -78.4796,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1289669,
        store_longitude: -78.4874797,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1313136,
        store_longitude: -78.4802147,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1117287,
        store_longitude: -78.4636378,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131521,
        store_longitude: -78.4681064,
    },
    {
        store_latitdue: -0.1226087,
        store_longitude: -78.4528106,
        neighborhood: 'LLANO CHICO',
    },

    {
        store_latitdue: -0.131273,
        store_longitude: -78.4766,
    },
    {
        store_latitdue: -0.1378318,
        store_longitude: -78.4788706,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0849943,
        store_longitude: -78.4773,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0893603,
        store_longitude: -78.4693151,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0877804,
        store_longitude: -78.4724,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.095113,
        store_longitude: -78.453213,
    },

    {
        store_latitdue: -0.214004,
        store_longitude: -78.4059,
        neighborhood: 'TUMBACO',
    },

    {
        store_latitdue: -0.129238,
        store_longitude: -78.487327,
    },

    {
        store_latitdue: -0.113561,
        store_longitude: -78.4775968,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.12873,
        store_longitude: -78.5094,
    },

    {
        store_latitdue: -0.1252129,
        store_longitude: -78.4819409,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1404832,
        store_longitude: -78.4612421,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1347952,
        store_longitude: -78.4650828,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112227,
        store_longitude: -78.297,
    },
    {
        store_latitdue: -0.088233,
        store_longitude: -78.476,
    },

    {
        store_latitdue: -0.0843471,
        store_longitude: -78.4775,
    },

    {
        store_latitdue: -0.11503241,
        store_longitude: -78.4899597,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.09000662,
        store_longitude: -78.474533,
    },

    {
        store_latitdue: -0.1140137,
        store_longitude: -78.4933555,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1158607,
        store_longitude: -78.4890147,
    },
    {
        store_latitdue: -0.10039,
        store_longitude: -78.4534,
    },

    {
        store_latitdue: -0.1094914,
        store_longitude: -78.4723011,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1027119,
        store_longitude: -78.468001,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1225325,
        store_longitude: -78.4536823,
        neighborhood: 'LLANO CHICO',
    },

    {
        store_latitdue: -0.07538398,
        store_longitude: -78.4579391,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1165384,
        store_longitude: -78.4874473,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1330470848,
        store_longitude: -78.4847156697,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1022423,
        store_longitude: -78.4835738,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1187451,
        store_longitude: -78.4867415,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.11437,
        store_longitude: -78.4379,
    },
    {
        store_latitdue: -0.11897197,
        store_longitude: -78.4779405,
    },

    {
        store_latitdue: -0.1162512,
        store_longitude: -78.4679125,
    },
    {
        store_latitdue: -0.119578,
        store_longitude: -78.4678,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.12074558,
        store_longitude: -78.484593,
    },
    {
        store_latitdue: -0.09091,
        store_longitude: -78.47541,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126607,
        store_longitude: -78.4884,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11218331,
        store_longitude: -78.4765014,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1255915,
        store_longitude: -78.4688409,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118457,
        store_longitude: -78.462715,
    },

    {
        store_latitdue: -0.1159567,
        store_longitude: -78.4804414,
    },
    {
        store_latitdue: -0.2308884,
        store_longitude: -78.514259,
    },

    {
        store_latitdue: -0.1209754,
        store_longitude: -78.4843585,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1349027,
        store_longitude: -78.4567761,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.136396,
        store_longitude: -78.4571192,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.081173,
        store_longitude: -78.502332,
    },

    {
        store_latitdue: -0.126267,
        store_longitude: -78.4872,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.0799771,
        store_longitude: -78.477031,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1200675,
        store_longitude: -78.4905264,
    },

    {
        store_latitdue: -0.118645,
        store_longitude: -78.4687576,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0941451,
        store_longitude: -78.4382,
    },
    {
        store_latitdue: -0.130317,
        store_longitude: -78.474959,
    },

    {
        store_latitdue: -0.167256,
        store_longitude: -78.4835,
    },
    {
        store_latitdue: -0.106591,
        store_longitude: -78.427276,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.275568,
        store_longitude: -79.2042,
    },

    {
        store_latitdue: -0.1459327,
        store_longitude: -78.4924729,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127034,
        store_longitude: -78.487065,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.12789,
        store_longitude: -78.4628,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.003824,
        store_longitude: -79.389731,
    },

    {
        store_latitdue: -0.124046,
        store_longitude: -78.4584,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238201,
        store_longitude: -78.458933,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.155303,
        store_longitude: -78.4726,
    },

    {
        store_latitdue: -0.1192555,
        store_longitude: -78.4677935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09208,
        store_longitude: -78.48004,
    },
    {
        store_latitdue: -0.104567,
        store_longitude: -78.485234,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11675,
        store_longitude: -78.486752,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1118571,
        store_longitude: -78.4887336,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.101901,
        store_longitude: -78.47697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1164043,
        store_longitude: -78.489861,
    },
    {
        store_latitdue: -0.122344,
        store_longitude: -78.485193,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1004236,
        store_longitude: -78.4724476,
    },
    {
        store_latitdue: -0.126283,
        store_longitude: -78.46661,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075513,
        store_longitude: -78.458057,
    },
    {
        store_latitdue: -0.1019,
        store_longitude: -78.488612,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0881644,
        store_longitude: -78.4744,
    },
    {
        store_latitdue: -0.1218183,
        store_longitude: -78.464214,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114409,
        store_longitude: -78.492074,
    },
    {
        store_latitdue: -0.1048232,
        store_longitude: -78.4916658,
    },
    {
        store_latitdue: -0.129101,
        store_longitude: -78.465252,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116959,
        store_longitude: -78.457552,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0912055,
        store_longitude: -78.469512,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122504,
        store_longitude: -78.469446,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0912555,
        store_longitude: -78.4806126,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1046314,
        store_longitude: -78.4687532,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085281,
        store_longitude: -78.469195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135066,
        store_longitude: -78.456751,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.108303,
        store_longitude: -78.48413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1169007,
        store_longitude: -78.4591889,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1164561,
        store_longitude: -78.458373,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1200566,
        store_longitude: -78.4651296,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.081995,
        store_longitude: -78.468053,
    },
    {
        store_latitdue: -0.127574,
        store_longitude: -78.464591,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112958,
        store_longitude: -78.4482,
    },
    {
        store_latitdue: -0.127458,
        store_longitude: -78.4511757,
    },
    {
        store_latitdue: -0.1267773,
        store_longitude: -78.4648043,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1157345,
        store_longitude: -78.4895128,
    },
    {
        store_latitdue: -0.1015438,
        store_longitude: -78.4866174,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1112574,
        store_longitude: -78.489724,
    },
    {
        store_latitdue: -0.128127,
        store_longitude: -78.464455,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114376,
        store_longitude: -78.494223,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116856,
        store_longitude: -78.457703,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0974214,
        store_longitude: -78.4760429,
    },
    {
        store_latitdue: -0.085462,
        store_longitude: -78.4692,
    },
    {
        store_latitdue: -0.123602,
        store_longitude: -78.471603,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130731,
        store_longitude: -78.464978,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086467,
        store_longitude: -78.469203,
    },
    {
        store_latitdue: -0.1355146,
        store_longitude: -78.4798175,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083224,
        store_longitude: -78.4732119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124781,
        store_longitude: -78.4652817,
    },
    {
        store_latitdue: -0.0779429,
        store_longitude: -78.4659466,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114901,
        store_longitude: -78.4862243,
    },
    {
        store_latitdue: -0.1158611,
        store_longitude: -78.4810267,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129443,
        store_longitude: -78.476712,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11770497,
        store_longitude: -78.4821525,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.07537895,
        store_longitude: -78.45975387,
    },
    {
        store_latitdue: -0.104722,
        store_longitude: -78.484689,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129987,
        store_longitude: -78.469767,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.07918,
        store_longitude: -78.4735,
    },
    {
        store_latitdue: -0.115083,
        store_longitude: -78.460974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115942,
        store_longitude: -78.488503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139355,
        store_longitude: -78.477762,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125177,
        store_longitude: -78.467166,
    },
    {
        store_latitdue: -0.1082811,
        store_longitude: -78.4879568,
    },
    {
        store_latitdue: -0.139904,
        store_longitude: -78.478046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0882276,
        store_longitude: -78.4748933,
    },
    {
        store_latitdue: -0.120262,
        store_longitude: -78.465385,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12901,
        store_longitude: -78.4837853,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122877,
        store_longitude: -78.466149,
    },
    {
        store_latitdue: -0.093556,
        store_longitude: -78.478601,
    },
    {
        store_latitdue: -0.148518,
        store_longitude: -78.47231,
    },
    {
        store_latitdue: -0.077822,
        store_longitude: -78.472504,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138195,
        store_longitude: -78.480642,
    },
    {
        store_latitdue: -0.1240853,
        store_longitude: -78.4699337,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1206187,
        store_longitude: -78.4649576,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119635,
        store_longitude: -78.467822,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11946,
        store_longitude: -78.468106,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120696,
        store_longitude: -78.468511,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121802,
        store_longitude: -78.46768,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121928,
        store_longitude: -78.4678,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122029,
        store_longitude: -78.467139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121896,
        store_longitude: -78.46624,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12247,
        store_longitude: -78.470497,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124514,
        store_longitude: -78.467694,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0,
        store_longitude: 0,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.1297178,
        store_longitude: -78.4694784,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127044,
        store_longitude: -78.468305,
    },
    {
        store_latitdue: -0.1259795,
        store_longitude: -78.4660194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129382,
        store_longitude: -78.466671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0973033,
        store_longitude: -78.420277,
    },
    {
        store_latitdue: -0.116869,
        store_longitude: -78.457685,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120519,
        store_longitude: -78.465458,
    },
    {
        store_latitdue: -0.1201377,
        store_longitude: -78.465336,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118733,
        store_longitude: -78.4688744,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1175609,
        store_longitude: -78.4676146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1345244,
        store_longitude: -78.4654722,
    },
    {
        store_latitdue: -0.128479,
        store_longitude: -78.470216,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123199,
        store_longitude: -78.483035,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124349,
        store_longitude: -78.483166,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.087212,
        store_longitude: -78.469254,
    },
    {
        store_latitdue: -0.0750489,
        store_longitude: -78.462939,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852059,
        store_longitude: -78.4679098,
    },
    {
        store_latitdue: -0.1172696,
        store_longitude: -78.4662127,
    },
    {
        store_latitdue: -0.1158263,
        store_longitude: -78.4583426,
    },
    {
        store_latitdue: -0.1177483,
        store_longitude: -78.4561349,
    },
    {
        store_latitdue: -0.118294,
        store_longitude: -78.4553,
    },
    {
        store_latitdue: -0.118214,
        store_longitude: -78.455142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12373,
        store_longitude: -78.467133,
    },
    {
        store_latitdue: -0.124987,
        store_longitude: -78.486249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120619,
        store_longitude: -78.465593,
    },
    {
        store_latitdue: -0.12906,
        store_longitude: -78.460098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129346,
        store_longitude: -78.460159,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129432,
        store_longitude: -78.460368,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124105,
        store_longitude: -78.466508,
    },
    {
        store_latitdue: -0.1242354,
        store_longitude: -78.4699793,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082142,
        store_longitude: -78.467208,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082801,
        store_longitude: -78.470659,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0864,
        store_longitude: -78.476777,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084746,
        store_longitude: -78.476998,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0846537,
        store_longitude: -78.4773662,
    },
    {
        store_latitdue: -0.0755237,
        store_longitude: -78.4578532,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.074736,
        store_longitude: -78.459445,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122081,
        store_longitude: -78.463094,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.137713,
        store_longitude: -78.675827,
    },
    {
        store_latitdue: -0.1137899,
        store_longitude: -78.4728802,
    },
    {
        store_latitdue: -0.1316387,
        store_longitude: -78.4836096,
    },
    {
        store_latitdue: -0.117455,
        store_longitude: -78.48352,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0816401,
        store_longitude: -78.473521,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131625,
        store_longitude: -78.4802,
    },
    {
        store_latitdue: -0.121359,
        store_longitude: -78.465797,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082301,
        store_longitude: -78.46992,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1291643,
        store_longitude: -78.4872991,
    },
    {
        store_latitdue: -0.1298086,
        store_longitude: -78.4689022,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.133777,
        store_longitude: -78.476943,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115,
        store_longitude: -78.492615,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1159646,
        store_longitude: -78.4910936,
    },
    {
        store_latitdue: -0.127715,
        store_longitude: -78.487198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116054,
        store_longitude: -78.490501,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1161997,
        store_longitude: -78.4696755,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.074361,
        store_longitude: -78.459919,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13204,
        store_longitude: -78.4691,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090287,
        store_longitude: -78.47147,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123176,
        store_longitude: -78.5179,
    },
    {
        store_latitdue: -0.1185043,
        store_longitude: -78.4643058,
    },
    {
        store_latitdue: -0.1197039,
        store_longitude: -78.4815601,
    },
    {
        store_latitdue: -0.127652,
        store_longitude: -78.461583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12235,
        store_longitude: -78.46845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138698,
        store_longitude: -78.485962,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1409576,
        store_longitude: -78.4807195,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13192,
        store_longitude: -78.4691,
    },
    {
        store_latitdue: -0.126263,
        store_longitude: -78.462024,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1150973,
        store_longitude: -78.4900176,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135837,
        store_longitude: -78.460963,
    },
    {
        store_latitdue: -0.135476,
        store_longitude: -78.481268,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129465,
        store_longitude: -78.464249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116775,
        store_longitude: -78.4835,
    },
    {
        store_latitdue: -0.103135,
        store_longitude: -78.5119,
    },
    {
        store_latitdue: -0.127761,
        store_longitude: -78.452016,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.078603,
        store_longitude: -78.474676,
    },
    {
        store_latitdue: -0.135556,
        store_longitude: -78.485719,
    },
    {
        store_latitdue: -0.137196,
        store_longitude: -78.480599,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0830814,
        store_longitude: -78.475595,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129523,
        store_longitude: -78.476695,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127339,
        store_longitude: -78.462111,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1070899,
        store_longitude: -78.4733827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124859,
        store_longitude: -78.484642,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1211459,
        store_longitude: -78.4657736,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110545,
        store_longitude: -78.461098,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.110488,
        store_longitude: -78.460503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130071,
        store_longitude: -78.458702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1186123,
        store_longitude: -78.4698899,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116555,
        store_longitude: -78.460851,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116966,
        store_longitude: -78.457685,
    },

    {
        store_latitdue: -0.113203,
        store_longitude: -78.4619,
    },
    {
        store_latitdue: -0.113358,
        store_longitude: -78.477885,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1177955,
        store_longitude: -78.4710104,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1129552,
        store_longitude: -78.4683662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12656,
        store_longitude: -78.461403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083699,
        store_longitude: -78.47238,
    },
    {
        store_latitdue: -0.1229607,
        store_longitude: -78.4927076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122828,
        store_longitude: -78.465196,
    },
    {
        store_latitdue: -0.074182,
        store_longitude: -78.456245,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.147679,
        store_longitude: -78.475559,
    },
    {
        store_latitdue: -0.1133874,
        store_longitude: -78.465315,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08213,
        store_longitude: -78.4678,
    },
    {
        store_latitdue: -0.1114648,
        store_longitude: -78.4826818,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1116937,
        store_longitude: -78.4886906,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.081681,
        store_longitude: -78.468002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.086399,
        store_longitude: -78.472343,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083799,
        store_longitude: -78.473017,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.07551608607173,
        store_longitude: -78.457945240662,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.074459,
        store_longitude: -78.457847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075366,
        store_longitude: -78.460605,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.074994,
        store_longitude: -78.460546,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0777781,
        store_longitude: -78.472469,
    },
    {
        store_latitdue: -0.0781969,
        store_longitude: -78.4679132,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0825264,
        store_longitude: -78.4734,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0758963,
        store_longitude: -78.4671429,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10026,
        store_longitude: -78.483139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.101733,
        store_longitude: -78.481051,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1278471,
        store_longitude: -78.4873082,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128267,
        store_longitude: -78.4881,
    },
    {
        store_latitdue: -0.1282985,
        store_longitude: -78.4882411,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123109,
        store_longitude: -78.4929303,
    },
    {
        store_latitdue: -0.096335,
        store_longitude: -78.479293,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114709,
        store_longitude: -78.488,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121593,
        store_longitude: -78.464601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117501,
        store_longitude: -78.48118,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11747694108635,
        store_longitude: -78.483601910993,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118587,
        store_longitude: -78.482999,
    },
    {
        store_latitdue: -0.1197168,
        store_longitude: -78.4814532,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12102,
        store_longitude: -78.480427,
    },
    {
        store_latitdue: -0.125945,
        store_longitude: -78.482483,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127218,
        store_longitude: -78.469643,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129237,
        store_longitude: -78.483308,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121922,
        store_longitude: -78.487961,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1313451,
        store_longitude: -78.4838828,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131764,
        store_longitude: -78.4828754,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1342582,
        store_longitude: -78.4866833,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138592,
        store_longitude: -78.485691,
    },
    {
        store_latitdue: -0.089743,
        store_longitude: -78.472433,
    },
    {
        store_latitdue: -0.10337,
        store_longitude: -78.485006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1033982,
        store_longitude: -78.4861899,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13705141,
        store_longitude: -78.46561137,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1359329,
        store_longitude: -78.4608596,
    },
    {
        store_latitdue: -0.136269,
        store_longitude: -78.457423,
    },
    {
        store_latitdue: -0.135247,
        store_longitude: -78.457584,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1405754,
        store_longitude: -78.4827877,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119912,
        store_longitude: -78.4652,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121706,
        store_longitude: -78.465514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130604,
        store_longitude: -78.472355,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.13551,
        store_longitude: -78.4798,
    },
    {
        store_latitdue: -0.131206,
        store_longitude: -78.478513,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.4742188,
        store_longitude: -78.4742188,
    },
    {
        store_latitdue: -0.123442,
        store_longitude: -78.474413,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.079988,
        store_longitude: -78.466125,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090143,
        store_longitude: -78.469299,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11274284217507,
        store_longitude: -78.494374584407,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118861,
        store_longitude: -78.4888,
    },
    {
        store_latitdue: -0.1229051,
        store_longitude: -78.491477,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129227,
        store_longitude: -78.481697,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12822,
        store_longitude: -78.48569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116696,
        store_longitude: -78.488432,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131206,
        store_longitude: -78.478513,
    },
    {
        store_latitdue: -0.137309,
        store_longitude: -78.465538,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12054086197168,
        store_longitude: -78.466120362282,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1297039,
        store_longitude: -78.4694696,
    },
    {
        store_latitdue: -0.136116,
        store_longitude: -78.465448,
    },
    {
        store_latitdue: -0.131206,
        store_longitude: -78.478513,
    },
    {
        store_latitdue: -0.116062,
        store_longitude: -78.478717,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.082124,
        store_longitude: -78.467796,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.084281,
        store_longitude: -78.47532,
    },
    {
        store_latitdue: -0.129268,
        store_longitude: -78.461233,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122094,
        store_longitude: -78.466272,
    },
    {
        store_latitdue: -0.0987887,
        store_longitude: -78.4768,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112966,
        store_longitude: -78.4846,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103675,
        store_longitude: -78.486056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1292588,
        store_longitude: -78.4872262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1338076,
        store_longitude: -78.486649,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140166,
        store_longitude: -78.484686,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1402011,
        store_longitude: -78.4846868,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126117,
        store_longitude: -78.468761,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125944,
        store_longitude: -78.464191,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0849192,
        store_longitude: -78.4757,
    },
    {
        store_latitdue: -0.0831126,
        store_longitude: -78.470374,
    },
    {
        store_latitdue: -0.0852289,
        store_longitude: -78.4678823,
    },
    {
        store_latitdue: -0.126439,
        store_longitude: -78.465249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1246385,
        store_longitude: -78.4655311,
    },
    {
        store_latitdue: -0.1252318,
        store_longitude: -78.4669692,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075878,
        store_longitude: -78.466105,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.084108,
        store_longitude: -78.468083,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117415,
        store_longitude: -78.465698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0746571,
        store_longitude: -78.4582,
    },
    {
        store_latitdue: -0.136954,
        store_longitude: -78.4934,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117201,
        store_longitude: -78.492046,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1185863,
        store_longitude: -78.4640465,
    },
    {
        store_latitdue: -0.080309,
        store_longitude: -78.467974,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114008,
        store_longitude: -78.493051,
    },
    {
        store_latitdue: -0.0758296,
        store_longitude: -78.4670455,
    },

    {
        store_latitdue: -0.194306,
        store_longitude: -78.498644,
    },
    {
        store_latitdue: -0.129122,
        store_longitude: -78.48436,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138278,
        store_longitude: -78.484627,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1409039,
        store_longitude: -78.4800566,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11657,
        store_longitude: -78.4823,
    },
    {
        store_latitdue: -0.1211613,
        store_longitude: -78.4875259,
    },
    {
        store_latitdue: -0.1366046,
        store_longitude: -78.4933942,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.14807,
        store_longitude: -78.490619,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124993,
        store_longitude: -78.467903,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113772,
        store_longitude: -78.491937,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1259563,
        store_longitude: -78.4732264,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08725,
        store_longitude: -78.472383,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1345509,
        store_longitude: -78.4584847,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115834,
        store_longitude: -78.4601,
    },
    {
        store_latitdue: -0.1161162,
        store_longitude: -78.4593515,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.077585,
        store_longitude: -78.465919,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0892368,
        store_longitude: -78.474519,
    },
    {
        store_latitdue: -0.127643,
        store_longitude: -78.487099,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.104817,
        store_longitude: -78.485357,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.112408,
        store_longitude: -78.493698,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121905,
        store_longitude: -78.466202,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075888,
        store_longitude: -78.467194,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0753498,
        store_longitude: -78.4641501,
    },
    {
        store_latitdue: -0.119312,
        store_longitude: -78.4683,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127988,
        store_longitude: -78.469311,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0752983,
        store_longitude: -78.4599,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116168,
        store_longitude: -78.493935,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1176662,
        store_longitude: -78.4562119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.137154,
        store_longitude: -78.475181,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10832243598998,
        store_longitude: -78.48411379382,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1187925,
        store_longitude: -78.46701,
    },
    {
        store_latitdue: -0.0907229,
        store_longitude: -78.4694402,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075371,
        store_longitude: -78.4641355,
    },

    {
        store_latitdue: -0.116451,
        store_longitude: -78.487517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1266218,
        store_longitude: -78.4650517,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.081767,
        store_longitude: -78.468,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116264,
        store_longitude: -78.471801,
    },
    {
        store_latitdue: -0.082357,
        store_longitude: -78.4726269,
    },
    {
        store_latitdue: -0.134303,
        store_longitude: -78.466132,
    },
    {
        store_latitdue: -0.1024491,
        store_longitude: -78.4691128,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124972,
        store_longitude: -78.469888,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852931,
        store_longitude: -78.4692,
    },
    {
        store_latitdue: -0.122256,
        store_longitude: -78.4664,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.109552,
        store_longitude: -78.4722,
    },
    {
        store_latitdue: -0.113206,
        store_longitude: -78.4784,
    },
    {
        store_latitdue: -0.121597,
        store_longitude: -78.4646,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118339,
        store_longitude: -78.47007,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1150354,
        store_longitude: -78.4597472,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0861876,
        store_longitude: -78.4702923,
    },
    {
        store_latitdue: -0.1186975,
        store_longitude: -78.466056,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.079261,
        store_longitude: -78.473529,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1221228,
        store_longitude: -78.4663651,
    },
    {
        store_latitdue: -0.1160188,
        store_longitude: -78.4795826,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0879439,
        store_longitude: -78.4694343,
    },
    {
        store_latitdue: -0.127287,
        store_longitude: -78.464615,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128987,
        store_longitude: -78.474602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.103091,
        store_longitude: -78.469834,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.10168,
        store_longitude: -78.472433,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0900848,
        store_longitude: -78.4762,
    },
    {
        store_latitdue: -0.138151,
        store_longitude: -78.4801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1379223,
        store_longitude: -78.4763044,
    },
    {
        store_latitdue: -0.091107,
        store_longitude: -78.4727,
    },
    {
        store_latitdue: -0.0911708,
        store_longitude: -78.4694188,
    },
    {
        store_latitdue: -0.134473,
        store_longitude: -78.465755,
    },
    {
        store_latitdue: -0.127502,
        store_longitude: -78.468802,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130838,
        store_longitude: -78.4737,
    },
    {
        store_latitdue: -0.12904,
        store_longitude: -78.4835,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1235666,
        store_longitude: -78.4742845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0786143,
        store_longitude: -78.4678793,
    },
    {
        store_latitdue: -0.131476,
        store_longitude: -78.4514,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1112738,
        store_longitude: -78.4651151,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0873863697052,
        store_longitude: -78.472235798836,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11758707929403,
        store_longitude: -78.467577304691,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105424,
        store_longitude: -78.484259,
        neighborhood: 'QUITO',
    },

    {
        store_latitdue: -0.1366715,
        store_longitude: -78.4588002,
    },
    {
        store_latitdue: -0.1220541,
        store_longitude: -78.4692139,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12318833333333,
        store_longitude: -78.46886,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1495546,
        store_longitude: -78.4901982,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0862047,
        store_longitude: -78.4709441,
    },

    {
        store_latitdue: -0.123909,
        store_longitude: -78.458846,
    },
    {
        store_latitdue: -0.124606,
        store_longitude: -78.458801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121787,
        store_longitude: -78.458806,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1225121,
        store_longitude: -78.4666228,
    },
    {
        store_latitdue: -0.140096,
        store_longitude: -78.477898,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.111411,
        store_longitude: -78.488386,
    },
    {
        store_latitdue: -0.1338704,
        store_longitude: -78.4567176,
    },
    {
        store_latitdue: -0.13432842,
        store_longitude: -78.4662244,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12953,
        store_longitude: -78.476569,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1284948,
        store_longitude: -78.4625997,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1294868,
        store_longitude: -78.4595934,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127835,
        store_longitude: -78.462774,
    },
    {
        store_latitdue: -0.126877,
        store_longitude: -78.4681089,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127132,
        store_longitude: -78.450519,
    },
    {
        store_latitdue: -0.129269,
        store_longitude: -78.4611917,
    },
    {
        store_latitdue: -0.1240658,
        store_longitude: -78.4666657,
    },
    {
        store_latitdue: -0.111438,
        store_longitude: -78.482803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11612838,
        store_longitude: -78.4901811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123309,
        store_longitude: -78.470354,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11937485542148,
        store_longitude: -78.465934451669,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129004,
        store_longitude: -78.4842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127738,
        store_longitude: -78.4514,
    },
    {
        store_latitdue: -0.1350405,
        store_longitude: -78.4864969,
    },
    {
        store_latitdue: -0.12456,
        store_longitude: -78.4707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135068,
        store_longitude: -78.4648,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115194,
        store_longitude: -78.4448,
    },
    {
        store_latitdue: -0.118856,
        store_longitude: -78.466,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08827,
        store_longitude: -78.4743,
    },
    {
        store_latitdue: -0.1160877,
        store_longitude: -78.4792411,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128166,
        store_longitude: -78.450081,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.10168,
        store_longitude: -78.472433,
    },
    {
        store_latitdue: -0.114893,
        store_longitude: -78.486249,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1180433,
        store_longitude: -78.4673367,
    },
    {
        store_latitdue: -0.115992,
        store_longitude: -78.4802,
    },
    {
        store_latitdue: -0.0753818,
        store_longitude: -78.4622739,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1183059,
        store_longitude: -78.4836746,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.083222,
        store_longitude: -78.4701659,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1275311,
        store_longitude: -78.4686674,
    },
    {
        store_latitdue: -0.0880908,
        store_longitude: -78.4735802,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.145753,
        store_longitude: -78.4636,
    },
    {
        store_latitdue: -0.082802,
        store_longitude: -78.470405,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.07557496894151,
        store_longitude: -78.457237221301,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116997,
        store_longitude: -78.491829,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1156809,
        store_longitude: -78.457355,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085222,
        store_longitude: -78.468803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1170354,
        store_longitude: -78.4920135,
    },
    {
        store_latitdue: -0.130253,
        store_longitude: -78.4526,
    },
    {
        store_latitdue: -0.129605,
        store_longitude: -78.4602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140563,
        store_longitude: -78.4787,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.096082,
        store_longitude: -78.479319,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116282,
        store_longitude: -78.488349,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11240295600146,
        store_longitude: -78.489794712514,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12873,
        store_longitude: -78.4497,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.0753915,
        store_longitude: -78.4618382,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1213475,
        store_longitude: -78.4692679,
    },
    {
        store_latitdue: -0.131326,
        store_longitude: -78.4515,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.133954,
        store_longitude: -78.4667,
    },
    {
        store_latitdue: -0.203363,
        store_longitude: -78.4907,
    },
    {
        store_latitdue: -0.089913,
        store_longitude: -78.474495,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0891238,
        store_longitude: -78.4724,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11380990035832,
        store_longitude: -78.487706361338,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125498,
        store_longitude: -78.468102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0824523,
        store_longitude: -78.4680973,
    },
    {
        store_latitdue: -0.122248,
        store_longitude: -78.463699,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107536,
        store_longitude: -78.49153,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12364459689707,
        store_longitude: -78.467225767672,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1261807,
        store_longitude: -78.4607247,
    },
    {
        store_latitdue: -0.117321,
        store_longitude: -78.460602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.101528,
        store_longitude: -78.4832,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0881727,
        store_longitude: -78.4756501,
    },
    {
        store_latitdue: -0.081657,
        store_longitude: -78.468102,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.075417,
        store_longitude: -78.465424,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1036202,
        store_longitude: -78.4866119,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114399,
        store_longitude: -78.491675,
    },
    {
        store_latitdue: -0.116634,
        store_longitude: -78.458199,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1173871,
        store_longitude: -78.4836809,
    },
    {
        store_latitdue: -0.117506,
        store_longitude: -78.481191,
    },
    {
        store_latitdue: -0.1113144,
        store_longitude: -78.4604233,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128536,
        store_longitude: -78.459021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0884453,
        store_longitude: -78.4761443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114496,
        store_longitude: -78.4943,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116554,
        store_longitude: -78.4571,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127574,
        store_longitude: -78.464591,
    },
    {
        store_latitdue: -0.117774,
        store_longitude: -78.4845,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105222,
        store_longitude: -78.4867,
    },
    {
        store_latitdue: -0.1198882,
        store_longitude: -78.4644826,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125807,
        store_longitude: -78.4865,
    },
    {
        store_latitdue: -0.075372,
        store_longitude: -78.464867,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.143915,
        store_longitude: -78.479637,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0856554,
        store_longitude: -78.4722,
    },
    {
        store_latitdue: -0.1126654,
        store_longitude: -78.4657065,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117026,
        store_longitude: -78.4836,
    },
    {
        store_latitdue: -0.136714,
        store_longitude: -78.454239,
    },
    {
        store_latitdue: -0.12772,
        store_longitude: -78.487198,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1393612,
        store_longitude: -78.4607683,
    },
    {
        store_latitdue: -0.0814634,
        store_longitude: -78.4724,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117478,
        store_longitude: -78.465816,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1113429,
        store_longitude: -78.4674594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11203,
        store_longitude: -78.4328067,
    },
    {
        store_latitdue: -0.100763,
        store_longitude: -78.4811,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120792,
        store_longitude: -78.488503,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.097977,
        store_longitude: -78.47601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1393232,
        store_longitude: -78.470218,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0905926,
        store_longitude: -78.4727545,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127456,
        store_longitude: -78.4862,
    },
    {
        store_latitdue: -0.0785699,
        store_longitude: -78.4703354,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118236,
        store_longitude: -78.4703,
    },
    {
        store_latitdue: -0.081247,
        store_longitude: -78.4734255,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11816735845059,
        store_longitude: -78.475525444373,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115593,
        store_longitude: -78.4893,
    },
    {
        store_latitdue: -0.1160613,
        store_longitude: -78.4883002,
    },
    {
        store_latitdue: -0.1271692,
        store_longitude: -78.4643165,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140553,
        store_longitude: -78.463819,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0852304,
        store_longitude: -78.4688604,
    },
    {
        store_latitdue: -0.123659,
        store_longitude: -78.4594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.091358,
        store_longitude: -78.472214,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.104551,
        store_longitude: -78.469101,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1325564,
        store_longitude: -78.4674829,
    },
    {
        store_latitdue: -0.081732,
        store_longitude: -78.4760303,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1350175,
        store_longitude: -78.4566812,
    },
    {
        store_latitdue: -0.1071002,
        store_longitude: -78.4731186,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1105717,
        store_longitude: -78.4607853,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0874922,
        store_longitude: -78.4692114,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.120876,
        store_longitude: -78.4895,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113663,
        store_longitude: -78.4748,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1186818,
        store_longitude: -78.466946,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1296632,
        store_longitude: -78.4534801,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.1277009,
        store_longitude: -78.4511458,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.138984,
        store_longitude: -78.4861,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116733,
        store_longitude: -78.458099,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1120998,
        store_longitude: -78.4641432,
    },
    {
        store_latitdue: -0.0864569,
        store_longitude: -78.4723106,
    },
    {
        store_latitdue: -0.140056,
        store_longitude: -78.4774,
    },
    {
        store_latitdue: -0.13001291546971,
        store_longitude: -78.452041950077,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.11453,
        store_longitude: -78.4876,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1269978,
        store_longitude: -78.467969,
    },
    {
        store_latitdue: -0.0836951,
        store_longitude: -78.4732,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116702,
        store_longitude: -78.488429,
    },
    {
        store_latitdue: -0.128714,
        store_longitude: -78.47,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.144181,
        store_longitude: -78.481576,
    },
    {
        store_latitdue: -0.1253891,
        store_longitude: -78.4700059,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.101909,
        store_longitude: -78.4226,
        neighborhood: 'CALDERÓN',
    },
    {
        store_latitdue: -0.127514,
        store_longitude: -78.4688,
    },
    {
        store_latitdue: -0.1277803,
        store_longitude: -78.4595579,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1366518,
        store_longitude: -78.4607083,
    },
    {
        store_latitdue: -0.116351,
        store_longitude: -78.458702,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238392,
        store_longitude: -78.5034902,
    },
    {
        store_latitdue: -0.2952464,
        store_longitude: -78.5775942,
    },
    {
        store_latitdue: -0.124669,
        store_longitude: -78.4663,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1293069,
        store_longitude: -78.5145677,
    },
    {
        store_latitdue: -0.12577,
        store_longitude: -78.4694,
    },
    {
        store_latitdue: -0.121976,
        store_longitude: -78.4687,
    },
    {
        store_latitdue: -0.137815,
        store_longitude: -78.480003,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11934623122215,
        store_longitude: -78.464871123433,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0888586,
        store_longitude: -78.4683002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0808579,
        store_longitude: -78.4739445,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.111427,
        store_longitude: -78.4645,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1178237,
        store_longitude: -78.467622,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102766,
        store_longitude: -78.4891,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0744103,
        store_longitude: -78.4585398,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.132293,
        store_longitude: -78.459787,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120141,
        store_longitude: -78.4653,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0875536,
        store_longitude: -78.4724,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117909,
        store_longitude: -78.4558,
    },
    {
        store_latitdue: -0.1159215,
        store_longitude: -78.4800128,
        neighborhood: 'YASUNÍ',
    },
    {
        store_latitdue: -0.0753938,
        store_longitude: -78.4634909,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115341,
        store_longitude: -78.489601,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.11833,
        store_longitude: -78.463423,
    },
    {
        store_latitdue: -0.088281,
        store_longitude: -78.476,
    },
    {
        store_latitdue: -0.0753448,
        store_longitude: -78.465,
    },
    {
        store_latitdue: -0.1415404,
        store_longitude: -78.463638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119029,
        store_longitude: -78.468776,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12239,
        store_longitude: -78.4707,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1341453,
        store_longitude: -78.4661007,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.114218,
        store_longitude: -78.4938,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.105751,
        store_longitude: -78.4811,
    },
    {
        store_latitdue: -0.137295,
        store_longitude: -78.4561,
    },
    {
        store_latitdue: -0.0835482,
        store_longitude: -78.4755,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1267544,
        store_longitude: -78.4684502,
    },
    {
        store_latitdue: -0.08539,
        store_longitude: -78.466904,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.085547,
        store_longitude: -78.4674,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1192003,
        store_longitude: -78.4538103,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1286332,
        store_longitude: -78.4634006,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.090648,
        store_longitude: -78.481041,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117545,
        store_longitude: -78.4671,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.123838,
        store_longitude: -78.4673,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1028787,
        store_longitude: -78.4891966,
    },
    {
        store_latitdue: -0.1165882,
        store_longitude: -78.4942823,
    },
    {
        store_latitdue: -0.09126,
        store_longitude: -78.4734592,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1058332,
        store_longitude: -78.4810894,
    },
    {
        store_latitdue: -0.081687,
        store_longitude: -78.468002,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.088128,
        store_longitude: -78.4753949,
    },
    {
        store_latitdue: -0.1204251,
        store_longitude: -78.4889997,
    },
    {
        store_latitdue: -0.0974912,
        store_longitude: -78.4828554,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124059,
        store_longitude: -78.467499,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: 0.1322284,
        store_longitude: -79.4104298,
        neighborhood: 'LA UNIÓN',
    },
    {
        store_latitdue: -0.0853707,
        store_longitude: -78.4757,
    },
    {
        store_latitdue: -0.101144,
        store_longitude: -78.47509,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124761,
        store_longitude: -78.468696,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0830242,
        store_longitude: -78.4670393,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.107181,
        store_longitude: -78.4874,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.15606,
        store_longitude: -78.468498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119185,
        store_longitude: -78.462502,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.119297,
        store_longitude: -78.4841472,
    },
    {
        store_latitdue: -0.111402,
        store_longitude: -78.492379,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139928,
        store_longitude: -78.475403,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0790452,
        store_longitude: -78.4758,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116601,
        store_longitude: -78.4583,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0754026,
        store_longitude: -78.4651,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.133795,
        store_longitude: -78.4619171,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.137604,
        store_longitude: -78.4775,
    },
    {
        store_latitdue: -0.0877782,
        store_longitude: -78.4762,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0740177,
        store_longitude: -78.4568126,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1225297,
        store_longitude: -78.4665068,
    },
    {
        store_latitdue: -0.117348,
        store_longitude: -78.49395,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.138587,
        store_longitude: -78.492976,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0830266,
        store_longitude: -78.4670594,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130787,
        store_longitude: -78.473801,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0750001,
        store_longitude: -78.4647677,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1116413,
        store_longitude: -78.4922146,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118286,
        store_longitude: -78.4645,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.120688,
        store_longitude: -78.4868,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118143,
        store_longitude: -78.4827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113502,
        store_longitude: -78.4615,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121959,
        store_longitude: -78.4916,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122309,
        store_longitude: -78.465,
    },
    {
        store_latitdue: -0.113949,
        store_longitude: -78.4692,
    },
    {
        store_latitdue: -0.1183802,
        store_longitude: -78.4716708,
    },
    {
        store_latitdue: -0.10364,
        store_longitude: -78.4862,
    },
    {
        store_latitdue: -0.1382269,
        store_longitude: -78.4698771,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116667,
        store_longitude: -78.459602,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2207746,
        store_longitude: -78.5110314,
    },
    {
        store_latitdue: -0.139815,
        store_longitude: -78.4747662,
    },
    {
        store_latitdue: -0.1307605,
        store_longitude: -78.4647861,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.2158673,
        store_longitude: -78.4955982,
    },
    {
        store_latitdue: -0.1279206,
        store_longitude: -78.4628141,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12164,
        store_longitude: -78.470897,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0770102,
        store_longitude: -78.4170854,
    },
    {
        store_latitdue: -0.136731,
        store_longitude: -78.460377,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12122,
        store_longitude: -78.470173,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.089307,
        store_longitude: -78.470773,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129122,
        store_longitude: -78.48436,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.139951,
        store_longitude: -78.47578,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.118427,
        store_longitude: -78.455021,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.135796,
        store_longitude: -78.480321,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1274034,
        store_longitude: -78.4871032,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124138,
        store_longitude: -78.4662294,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1491857,
        store_longitude: -78.4903025,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126551,
        store_longitude: -78.465128,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124109,
        store_longitude: -78.470827,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.102967,
        store_longitude: -78.482109,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0818948,
        store_longitude: -78.4679613,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.14084853697568,
        store_longitude: -78.460488477722,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.076848,
        store_longitude: -78.466975,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121908,
        store_longitude: -78.4705471,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.074413,
        store_longitude: -78.457957,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1238352,
        store_longitude: -78.4861179,
    },
    {
        store_latitdue: -0.087633,
        store_longitude: -78.46925,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.128562,
        store_longitude: -78.481289,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1377811,
        store_longitude: -78.4807443,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121148,
        store_longitude: -78.470076,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1256171,
        store_longitude: -78.4702366,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.115073,
        store_longitude: -78.461212,
    },
    {
        store_latitdue: -0.1290329,
        store_longitude: -78.4738684,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.081464,
        store_longitude: -78.474607,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122086,
        store_longitude: -78.487142,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.131962,
        store_longitude: -78.492686,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126174,
        store_longitude: -78.468629,
    },
    {
        store_latitdue: -0.090116858482361,
        store_longitude: -78.476151823997,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121729,
        store_longitude: -78.483954,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126501,
        store_longitude: -78.488412,
    },
    {
        store_latitdue: -0.111278,
        store_longitude: -78.493102,
    },
    {
        store_latitdue: -0.1177887,
        store_longitude: -78.4628949,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1161606,
        store_longitude: -78.4891288,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1127104,
        store_longitude: -78.4744732,
    },
    {
        store_latitdue: -0.127472,
        store_longitude: -78.485992,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.101998,
        store_longitude: -78.488956,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127132,
        store_longitude: -78.450519,
        neighborhood: 'LLANO CHICO',
    },
    {
        store_latitdue: -0.144055,
        store_longitude: -78.477455,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.09095,
        store_longitude: -78.469372,
    },
    {
        store_latitdue: -0.077391,
        store_longitude: -78.470233,
    },
    {
        store_latitdue: -0.0907452,
        store_longitude: -78.4694061,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1352155,
        store_longitude: -78.4613299,
    },
    {
        store_latitdue: -0.13145,
        store_longitude: -78.471262,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.113824,
        store_longitude: -78.48285,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0750311,
        store_longitude: -78.460152,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.08575,
        store_longitude: -78.4692,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.106911,
        store_longitude: -78.473621,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1176253,
        store_longitude: -78.4651494,
    },
    {
        store_latitdue: -0.085152,
        store_longitude: -78.468498,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.122277,
        store_longitude: -78.464486,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.12305,
        store_longitude: -78.465886,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.136725,
        store_longitude: -78.4608,
    },
    {
        store_latitdue: -0.1303373,
        store_longitude: -78.4749801,
    },
    {
        store_latitdue: -0.075381,
        store_longitude: -78.464066,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1179457,
        store_longitude: -78.4557536,
    },
    {
        store_latitdue: -0.1047751,
        store_longitude: -78.4845915,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129517,
        store_longitude: -78.459218,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.116614,
        store_longitude: -78.490808,
    },
    {
        store_latitdue: -0.1410089,
        store_longitude: -78.4808117,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1205008,
        store_longitude: -78.4819824,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.109215,
        store_longitude: -78.4842,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.121119,
        store_longitude: -78.462805,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.124082,
        store_longitude: -78.483716,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.077199,
        store_longitude: -78.470638,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140542,
        store_longitude: -78.478683,
    },
    {
        store_latitdue: -0.0861067,
        store_longitude: -78.476875,
    },
    {
        store_latitdue: -0.134906,
        store_longitude: -78.46465,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.130035,
        store_longitude: -78.466415,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.127967,
        store_longitude: -78.469332,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.088769,
        store_longitude: -78.476392,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.126809,
        store_longitude: -78.467565,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.0799177,
        store_longitude: -78.4625803,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.117543,
        store_longitude: -78.467095,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.140113,
        store_longitude: -78.477925,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.129217,
        store_longitude: -78.461127,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1160445,
        store_longitude: -78.4799376,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.125982,
        store_longitude: -78.460835,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1404933,
        store_longitude: -78.4699824,
    },
    {
        store_latitdue: -0.107591,
        store_longitude: -78.491563,
        neighborhood: 'QUITO',
    },
    {
        store_latitdue: -0.1179545,
        store_longitude: -78.4710687,
    },
];
