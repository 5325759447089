import { FlyToInterpolator } from '@deck.gl/core';
import { AccountItem } from '../../personalizations/types';
import { getAccountListUrl } from '../../personalizations/useAccountList';
import {
    EntityResolutionItem,
    HermesCompetitiveProduct,
    HermesMarketingItem,
    HermesMetadata,
    MetadataItem,
} from './HermesMetadata';
import { nestleProductList } from '../mockData/nestleProductList';
import { ECStores } from '../mockData/ECStores';

const ENTITY_RESOLUTION: EntityResolutionItem[] = [
    { title: 'Trix Cereal 18x330g', sub: 'Stores' },
    { title: 'Nestle Trix Fruity Shaped Breakfast Cereal 330g', sub: 'Products' },
    { title: 'Trix Breakfast Cereal 18x330g', sub: 'Product availability' },
    { title: 'Trix BR Cereal 330g', sub: 'Sell-in orders' },
];

const METADATA: MetadataItem[] = [
    { left: 'Package type', right: 'Box' },
    { left: 'Package dimensions', right: '‎27.8 x 19.2 x 5 cm; 330 Grams' },
    { left: 'Package color', right: 'Red' },
    { left: 'Category', right: 'Cereal' },
    { left: 'Brand', right: 'Trix' },
    { left: 'Product class', right: 'Grocery' },
    { left: 'Flavor', right: 'Fruity' },
];

const COMPETITIVE_PRODUCTS: HermesCompetitiveProduct[] = [
    {
        name: 'Kellogg’s Froot Loops 330g',
        price: '180.00₱',
        star: '5',
        image: '/images/nestle/f1.png',
    },
    {
        name: "Kellogg's Corn Pops 330g",
        price: '220.00₱',
        star: '4.8',
        image: '/images/nestle/p.png',
    },
    {
        name: 'Post Golden Crisp 330g ',
        price: '180.00₱',
        star: '4.8',
        image: '/images/nestle/g.png',
    },
    {
        name: 'General Mills Lucky Charms 330g ',
        price: '300.00₱',
        star: '4.6',
        image: '/images/nestle/l.png',
    },
    {
        name: 'Kellogg’s Frosted Flakes 330g',
        price: '180.00₱',
        star: '4.2',
        image: '/images/nestle/f2.png',
    },
    {
        name: 'General Mills Cocoa Puffs ',
        price: '220.00₱',
        star: '3.8',
        image: '/images/nestle/c.png',
    },
];

const MARKETING: HermesMarketingItem[] = [
    {
        title: 'Trix Tik Tok Challenge',
        subtitle: 'Video',
        image: '/images/nestle/1.png',
    },
    {
        title: 'It’s Fun! It’s Fruity!',
        subtitle: 'Image',
        image: '/images/nestle/2.png',
    },
    {
        title: 'Bursting with Fruity Flavor',
        subtitle: 'Image',
        image: '/images/nestle/3.png',
    },
    {
        title: 'The Trix Globe NFT Series',
        subtitle: 'Image',
        image: '/images/nestle/4.png',
    },
    {
        title: 'Trix Silly Rabbit',
        subtitle: 'Image',
        image: '/images/nestle/5.png',
    },
];

const VIEWPORT = {
    altitude: 1.5,
    bearing: 0,
    height: 701,
    latitude: -0.208946,
    longitude: -78.507751,
    maxPitch: 60,
    maxZoom: 20,
    minPitch: 0,
    minZoom: 0,
    normalize: true,
    pitch: 0,
    position: [0, 0, 0],
    width: 477,
    zoom: 6,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};
export const MARKS = [
    { value: 0, label: 'New Year’s Day ' },
    { value: 41, label: 'Chinese New Year ' },
    { value: 55, label: 'EDSA People Power Revolution Anniversary ' },
    { value: 90, label: 'Maundy Thursday ' },
    { value: 91, label: 'Good Friday ' },
    { value: 99, label: 'Araw ng Kagitingan (Day of Valor) ' },
    { value: 120, label: 'Labor Day ' },
    { value: 132, label: 'Eid’l Fitr (Feast of Ramadan) ' },
    { value: 156, label: 'Independence Day ' },
    { value: 170, label: 'Sinulog Festival ' },
    { value: 200, label: 'Eid’l Adha (Feast of Sacrifice) ' },
    { value: 49, label: 'Behavior change' },
    { value: 82, label: 'Behavior change' },
    { value: 112, label: 'Behavior change' },
    { value: 150, label: 'Behavior change' },
    { value: 193, label: 'Behavior change' },
];

export const MARK_TO_VAL = {
    1: 1,
    42: 2,
    49: 3,
    55: 4,
    82: 5,
    90: 6,
    91: 7,
    99: 8,
    112: 9,
    120: 10,
    132: 11,
    154: 12,
    156: 13,
    170: 14,
    193: 15,
    200: 16,
};

export const NESTLE_HERMES_METADATA: HermesMetadata = {
    hermes: {
        organization: 'Nestle',
    },
    ontology: {
        productList: nestleProductList,
        product: {
            productName: 'Trix Cereal 18x330g',
            productSku: '12492596',
            country: 'Ecuador',
            characteristics: {
                details:
                    'Trix cereal is fun, fruity with 6 different Fruity shapes. Made with Wholegrain it is a source of calcium, zinc and B vitamins. Full of fruity flavors, what a fun way to start the day May contain cereals containing Gluten, Nuts , Soybeans and Cow’s milk. Store in a cool, dry place. After opening, fold inner bag to keep balance of contents fresh.',
                entityResolution: ENTITY_RESOLUTION,
                metadata: METADATA,
            },
            contextual: {
                productData: {
                    type: 'data',
                    data: ECStores.map(item => ({
                        productName: '',
                        latitude: item.store_latitdue,
                        longitude: item.store_longitude,
                        description: '',
                        neighborhood: item?.neighborhood,
                    })),
                    // type: 'hook',
                    // parameters: {
                    //     url: getAccountListUrl('EC-NESTLE', 1000),
                    // },
                },
                productDataParser: (item: AccountItem) => ({
                    productName: item.account_name,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    description: item.province,
                    neighborhood: item.neighborhood,
                }),
                performance: {
                    totalRevenue: '385,487.69₱',
                    avaragePrice: '158.80₱',
                    productsOrdered: '2,427',
                },
                competitiveProducts: COMPETITIVE_PRODUCTS,
                marketing: MARKETING,
            },
        },
        viewport: VIEWPORT,
        slider: {
            marks: MARKS,
            markToVal: MARK_TO_VAL,
        },
    },
    chat: {
        suggestions: [
            'How many Nestle accounts also exist in the accounts master?',
            'What are all of the occurrences of Trix breakfast cereal in our product datasets?',
            'What are other ways I can improve the dataset?',
        ],
    },
};
