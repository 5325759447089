import { useSessionStorage } from '@mantine/hooks';
import { HERMES_METADATA } from './HermesMetadata/HermesMetadataForBrand';

export const useHermesMetadata = () => {
    const [brand, setBrand] = useSessionStorage<string>({
        key: 'hermes-brand',
        defaultValue: JSON.parse(sessionStorage.getItem('hermes-brand')) ?? 'nestle',
    });

    const hermesMetadata = HERMES_METADATA[brand ?? 'nestle'];
    // console.log(HERMES_METADATA, brand, hermesMetadata);

    return {
        hermesMetadata,
        brand,
        setBrand,
    };
};
